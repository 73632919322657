import React from 'react';
import CustomButton from '../custom_button/CustomButton';

const PageNotFound = ({ onPress }) => {
  return (
    <div className="container">
      <div className="main-content-container">
        <div className="text-center mt-3 common-text">Page Not Found</div>
        <div className="d-flex align-items-center justify-content-center py-3">
          <CustomButton buttonStyle="mr-3 mt-3" onClick={onPress}>
            GO TO HOME
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
