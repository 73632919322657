import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { PODCAST_CHANNEL_BY_ID } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import PodcastContent from '../../podcast/components/PodcastContent';
import { selectDIConfigData } from '../../../state/MasterData';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';

const PodcastTab = () => {
  const diConfigData = useSelector(selectDIConfigData);
  const channelId = diConfigData.podcast_channel_id;
  const [loading, setLoading] = useState(true);
  const [channelData, setChannelData] = useState({});

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.DI_PODCAST_TAB_VISIT, '', ANALYTICS_ITEM_NAMES.DI);
  }, []);

  const callPodcastApi = useCallback(async () => {
    const params = {
      channel_id: channelId,
    };
    try {
      setLoading(true);
      const apiResponse = await apiRequest(PODCAST_CHANNEL_BY_ID, REQUEST_TYPE.GET, params);
      setLoading(false);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setChannelData(apiResponse.response.data.channel);
        }
      }
    } catch (err) {
      handleError(err, params, PODCAST_CHANNEL_BY_ID, NAVIGATION_ROUTES.DI);
      setLoading(false);
    }
  }, [channelId]);

  useEffect(() => {
    callPodcastApi();
  }, [callPodcastApi]);

  return (
    <div className="main-content-container">
      <ActivityLoader visible={loading} />
      <PodcastContent channelId={channelId} channelData={channelData} />
    </div>
  );
};

export default PodcastTab;
