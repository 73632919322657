import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectEmployeeData } from '../../state/EmployeeData';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  MESSAGES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import { formatDateDDMonthYYYY, showToast } from '../../utils/common';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';

const RewardDetailScreen = ({ location }) => {
  const { cardNumber, orderId, cardPrice, expiry, pin, redeemAmazon, message } = location.state;
  const [disclaimerList] = useState([
    'Amazon.in is not a sponsor of this promotion. Amazon Pay Gift Cards ("GCs") are issued by QwikCilver Solutions Private Limited (“QwikCilver”).',
    'GCs may be used only for the purchase of eligible products/services on Amazon.in or other partner sites',
    'GC balances must be used within 1 year of the date of purchase/activation and any unspent balance shall expire in 1 year from the date of purchase.',
    'GCs cannot be transferred for value or redeemed for cash.',
    'QwikCilver, Amazon Seller Services Private Limited (“Amazon”) or their affiliates are not responsible if a GC is lost, stolen, destroyed or used without permission.',
    'To redeem your GC, visit www.amazon.in/addgiftcard. For complete terms and conditions, see www.amazon.in/giftcardtnc. All Amazon ®, ™ & © are IP of Amazon or its affiliates.',
    'Refer Detailed Amazon Pay Gift Card T&C here: www.amazon.in/giftcardtnc”.',
  ]);

  const [showHideFirstView, setShowHideFirstView] = useState(false);
  const [showHideSecondView, setShowHideSecondView] = useState(false);
  const [showHideThirdView, setShowHideThirdView] = useState(false);

  const employeeData = useSelector(selectEmployeeData);
  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.REWARD_DETAILS_SCREEN,
      NAVIGATION_ROUTES.REWARD_DETAILS_SCREEN
    );
  }, []);

  useEffect(() => {
    if (cardNumber) {
      setShowHideFirstView(true);
      setShowHideSecondView(false);
      setShowHideThirdView(false);
    } else {
      setShowHideFirstView(false);
      setShowHideSecondView(true);
      setShowHideThirdView(false);
    }
    if (redeemAmazon && redeemAmazon === true && cardNumber === undefined) {
      setShowHideFirstView(false);
      setShowHideSecondView(false);
      setShowHideThirdView(true);
    } else {
      if (employeeData.geography.abbreviation !== 'IND') {
        setShowHideFirstView(false);
        setShowHideSecondView(true);
        setShowHideThirdView(false);
      }
    }
  }, [cardNumber, redeemAmazon, employeeData.geography.abbreviation]);

  const renderSelectedEmployee = (item, index) => {
    return (
      <div key={`${index}`} className="cheers-date-view-style mt-1 mb-1">
        <div className="cheers-dot-text">{'\u25CF '}</div>
        <div className="cheers-item-text ml-2">{item}</div>
      </div>
    );
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CHEERS} />
      <BackButton />
      <div className="main-content-container">
        {showHideFirstView && (
          <div>
            <div className="cheers-container">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_REWARD_CARD,
                  ACCESSIBILITY_IDENTIFIERS.CHEERS
                )}
                className="embed-responsive embed-responsive-16by9"
              />
              {orderId && <div className="cheers-order-id-text">{`Order Id: ${orderId}`}</div>}
              {cardNumber && <div className="cheers-gc-id-text">{`GC ID: ${cardNumber}`}</div>}
              {pin && <div className="cheers-gc-code-text">{`GC Code: ${pin}`}</div>}
              {cardPrice && (
                <div className="cheer-card-price-text">{`₹ ${cardPrice.split('.')[0]}`}</div>
              )}
              {expiry && (
                <div className="cheer-card-expiry-text">{`Expiry Date: ${formatDateDDMonthYYYY(
                  expiry
                )}`}</div>
              )}
            </div>
            <div className="text-center cheers-tap-to-copy">Tap to copy</div>
            <div className="mt-3 cheers-team-micro-view">
              <div className="col-sm-4 cheers-team-micro-view">
                {orderId && (
                  <CopyToClipboard
                    text={orderId}
                    onCopy={() => showToast(MESSAGES.ORDER_ID_COPIED)}>
                    <div className="cheers-grid-view p-2 common-cursor-pointer">
                      <div className="text-center cheers-order-id">Order ID</div>
                      <div className="text-center cheers-pin-text-style">{`${orderId.substring(
                        0,
                        4
                      )}XXXX`}</div>
                    </div>
                  </CopyToClipboard>
                )}
              </div>
              <div className="col-sm-4 cheers-team-micro-view">
                {cardNumber && (
                  <CopyToClipboard
                    text={cardNumber}
                    onCopy={() => showToast(MESSAGES.GC_ID_COPIED)}>
                    <div className="cheers-grid-view p-2 common-cursor-pointer">
                      <div className="text-center cheers-order-id">GC ID</div>
                      <div className="text-center cheers-pin-text-style">{`${cardNumber.substring(
                        0,
                        4
                      )}XXXX`}</div>
                    </div>
                  </CopyToClipboard>
                )}
              </div>
              <div className="col-sm-4 cheers-team-micro-view">
                {pin && (
                  <CopyToClipboard text={pin} onCopy={() => showToast(MESSAGES.GC_CODE_COPIED)}>
                    <div className="cheers-grid-view p-2 common-cursor-pointer">
                      <div className="text-center cheers-order-id">GC Code</div>
                      <div className="text-center cheers-pin-text-style">{`${pin.substring(
                        0,
                        4
                      )}XXXX`}</div>
                    </div>
                  </CopyToClipboard>
                )}
              </div>
            </div>
            <div className="mt-3 text-center cheers-desclaimer">Disclaimer</div>
            <div className="mt-2">
              {disclaimerList &&
                disclaimerList.map((data, index) => renderSelectedEmployee(data, index))}
            </div>
          </div>
        )}
        {showHideSecondView && (
          <div>
            <div>Please write to</div>
            <div>Cheers@microland.com</div>
            <div>for your Redemption details</div>
          </div>
        )}
        {showHideThirdView && <div>{message}</div>}
      </div>
    </div>
  );
};

export default RewardDetailScreen;
