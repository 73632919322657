import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';

import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  FILTER_IDENTIFIERS,
  REQUEST_TYPE,
  ERROR_MESSAGES,
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../common/constants/AppConstants';
import Filter from '../../common/ui/filter/Filter';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { GET_DIRECTORY_DATA } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import {
  saveProgressLoadingState,
  selectBaseUrl,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../state/UIState';
import { getHeaderComponentUrl, getFeatureComponentUrl } from '../../utils/accessibility';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';

const DirectoryScreen = () => {
  const dispatch = useDispatch();
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const accessibilityData = useSelector(selectAccessibilityData);
  const baseUrl = useSelector(selectBaseUrl);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [directoryData, setDirectoryData] = useState([]);
  const [error, setError] = useState(false);
  const textInputRef = useRef();
  const pageIdRef = useRef(1);
  const appliedFiltersRef = useRef({});
  const searchTextRef = useRef('');
  const [dataLength, setDataLength] = useState(0);
  const loadMoreFlagRef = useRef(true);
  const [noMatchFlag, setNoMatchFlag] = useState(false);

  useEffect(() => {
    if (pageIdRef.current !== 1) {
      loadMoreFlagRef.current = false;
    }
  }, [dataLength]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.DIRECTORY, NAVIGATION_ROUTES.DIRECTORY);
  }, []);

  const callDirectoriesApi = useCallback(
    async (searchTextValue = '') => {
      setError(false);
      dispatch(saveShowEmptyContentView(true));
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      setNoMatchFlag(false);
      let params = { page_id: pageIdRef.current };

      params = { ...params, ...{ query: searchTextValue } };

      if (Object.keys(appliedFiltersRef.current).length) {
        params = { ...params, ...appliedFiltersRef.current };
      }
      try {
        const apiResponse = await apiRequest(GET_DIRECTORY_DATA, REQUEST_TYPE.GET, params);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            const data = apiResponse.response.data.feeds;
            if (data?.length === 0) {
              loadMoreFlagRef.current = false;
            }
            setDataLength(data.length);
            if (pageIdRef.current === 1) {
              setDirectoryData(data);
            } else {
              setDirectoryData((oldArray) => [...oldArray, ...data]);
            }
          }
          if (apiResponse.response.data.feeds.length === 0 && pageIdRef.current === 1) {
            setNoMatchFlag(true);
          }
        }
      } catch (err) {
        handleError(err, params, GET_DIRECTORY_DATA, NAVIGATION_ROUTES.DIRECTORY);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        setError(true);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (baseUrl) {
      callDirectoriesApi();
    }
  }, [baseUrl, callDirectoriesApi]);

  const onPressDirectoryProfileHandler = (employeeId) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.DIRECTORY_PROFILE_SELECTED,
      employeeId.toString(),
      ANALYTICS_ITEM_NAMES.DIRECTORY_PROFILE
    );
  };

  const showProfiles = () => {
    return directoryData.map((profile) => {
      return (
        <div className="col-6 col-sm-3 col-lg-2 p-3" key={profile.employee_id}>
          <UserProfilePhoto
            imageBaseUrl={profile.profile_picture.base_url}
            imagePath={profile.profile_picture.image_path}
            imageClass="common-user-profile-photo-xl mb-2"
            showBadge={true}
            profileData={profile}
            prependOnClick={() => onPressDirectoryProfileHandler(profile.employee_id)}
          />
          <p className="directory-profile-name">{profile.first_name}</p>
          <p className="directory-profile-name">{profile.last_name}</p>
        </div>
      );
    });
  };

  const onClickSearchHandler = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.DIRECTORY_SEARCH_CLICKED,
      '',
      ANALYTICS_ITEM_NAMES.DIRECTORY_SEARCH
    );
    setIsSearchEnabled(true);
  };

  const debouncedCallback = (event) => {
    if (event && event.target.value.length > 2) {
      pageIdRef.current = 1;
      loadMoreFlagRef.current = true;
      callDirectoriesApi(event.target.value);
    }
    searchTextRef.current = event.target.value;
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );

  const onClickFilterHandler = (selectedFilters) => {
    let tempFilterObject = {};
    Object.keys(selectedFilters).map((filterKey) => {
      if (selectedFilters[filterKey].length) {
        tempFilterObject[filterKey] = selectedFilters[filterKey].join();
      }
    });
    appliedFiltersRef.current = tempFilterObject;
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    setDataLength(0);
    callDirectoriesApi(searchTextRef.current);
  };

  const loadMoreHandler = () => {
    if (loadMoreFlagRef.current) {
      pageIdRef.current = pageIdRef.current + 1;
      callDirectoriesApi(searchTextRef.current);
    }
  };

  const onSearchSubmitHandler = (event) => {
    event.preventDefault();
    logEvent(ANALYTICS_EVENT_TYPES.DIRECTORY_SEARCH_QUERY, '', searchTextRef.current);
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    callDirectoriesApi(searchTextRef.current);
  };

  const resetSearchHandler = useCallback(() => {
    textInputRef.current.reset();
    searchTextRef.current = '';
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    callDirectoriesApi();
    setIsSearchEnabled(false);
  }, [callDirectoriesApi]);

  const showEmptyListView = () => {
    return (
      <DefaultContentView
        message={ERROR_MESSAGES.DIRECTORY_NO_DATA}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right">
        {isSearchEnabled ? (
          <form
            className="directory-search-form"
            onSubmit={onSearchSubmitHandler}
            ref={textInputRef}>
            <input
              autoFocus
              className="ml-3 directory-search-bar"
              type="text"
              placeholder="Search.."
              onChange={debounceInputHandler}
            />
          </form>
        ) : (
          <div />
        )}
        {isSearchEnabled ? (
          <div className="gcdo-icon-row mr-3" onClick={resetSearchHandler}>
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
              )}
              className="header-icon"
            />
          </div>
        ) : (
          <div className="gcdo-icon-row mr-3" onClick={onClickSearchHandler}>
            <img
              src={getHeaderComponentUrl(accessibilityData, ACCESSIBILITY_HEADER_COMPONENTS.SEARCH)}
              className="header-icon"
            />
          </div>
        )}
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.DIRECTORY} />
      <div className="main-content-container">
        <Filter
          topFilter={FILTER_IDENTIFIERS.GEOGRAPHIES}
          geographies={true}
          locations={true}
          levels={true}
          layers={true}
          serviceTenures={true}
          bloodGroups={true}
          onClick={onClickFilterHandler}
        />

        {pageIdRef.current === 1 && noMatchFlag ? (
          showEmptyListView()
        ) : (
          <div>
            <div className="row overflow-hidden">{showProfiles()}</div>
            {loadMoreFlagRef.current && !!directoryData.length && (
              <LoadMoreBtn
                onClick={() => loadMoreHandler(searchTextRef.current)}
                isLoading={progressLoadingState.isProgressLoading}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DirectoryScreen;
