import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
} from '../../../common/constants/AppConstants';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';

const CpoMessageTab = (props) => {
  const [name, setName] = useState('');
  const [messages, setMessages] = useState([]);
  const employeeData = useSelector(selectEmployeeData);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.WOMENS_DAY_CPOMESSAGE_TAB,
      props.item,
      ANALYTICS_ITEM_NAMES.WOMENS_DAY
    );
  }, [props.item]);

  useEffect(() => {
    setName(employeeData.first_name + ' ' + employeeData.last_name);
  }, [employeeData.first_name, employeeData.last_name]);

  useEffect(() => {
    if (employeeData.gender === 'F' && props.config.chief_message_female) {
      setMessages([...props.config.chief_message_female]);
    } else {
      if (props.config.chief_message_male) {
        setMessages([...props.config.chief_message_male]);
      }
    }
  }, [employeeData.gender, props.config]);

  const renderItem = (item) => {
    return (
      <div>
        <HtmlView html={item} htmlStyle="womens-day-cpo-message-text womens-day-message-item" />
      </div>
    );
  };

  const signatureCompnent = () => {
    return (
      <img
        src={props?.config?.image_base_url + props?.config?.cpo_signature_image}
        className="womens-day-cpo-signature-size"
      />
    );
  };

  return (
    <div className="mainContainer">
      <div className="womens-day-cpo-name-text">{`Dear ${name}`}</div>
      {messages.map((item) => {
        return <div key={item}>{renderItem(item)}</div>;
      })}
      {props?.config?.show_cpo_signature === true && signatureCompnent()}
    </div>
  );
};

export default CpoMessageTab;
