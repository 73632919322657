import React, { useCallback, useEffect, useRef, useState } from 'react';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import ActivityLoader from '../../common/ui/activity_loader/ActivityLoader';
import PodcastListItem from './components/PodcastListItem';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../common/constants/AppConstants';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import { logEvent } from '../../utils/FirebaseAnalyticsUtils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { apiRequest, handleError } from '../../services/Service';
import { PODCASTS_SEARCH } from '../../services/ApiUrls';
import { useDispatch, useSelector } from 'react-redux';
import { saveSearchText, selectSearchText } from '../../state/UIState';
import debounce from 'lodash.debounce';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';

const PodcastsSearchScreen = () => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [podcasts, setPodcasts] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  const searchData = useSelector(selectSearchText);
  const [searchText, setSearchText] = useState(searchData ?? '');
  const dispatch = useDispatch();
  const [isSearchEnabled, setIsSearchEnabled] = useState(true);
  const accessibilityData = useSelector(selectAccessibilityData);

  const onClickSearchHandler = () => {
    setIsSearchEnabled(true);
  };

  const resetValue = () => {
    setIsSearchEnabled(false);
    setPageId(1);
    setSearchText('');
    setPodcasts([]);
    setLoadMoreBtn(false);
    history.goBack();
  };

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.PODCAST_CHANNELS_TAB_VISIT, '', ANALYTICS_ITEM_NAMES.PODCASTS);
  }, []);

  const callPodcastsApi = useCallback(async (pageId, query = '') => {
    setError(false);

    setLoading(true);
    const get_params = {
      page_id: pageId,
      query: query,
    };
    try {
      const apiResponse = await apiRequest(PODCASTS_SEARCH, REQUEST_TYPE.GET, get_params);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;

            if (data && data.episodes && data.episodes.length > 0) {
              if (pageId === 1) {
                setPodcasts(data.episodes);
              } else {
                setPodcasts((oldArray) => [...oldArray, ...data.episodes]);
              }
              setLoadMoreBtn(true);
            } else {
              setLoadMoreBtn(false);
            }
          }
        }
      }
      setLoading(false);
    } catch (err) {
      handleError(err, get_params, PODCASTS_SEARCH, NAVIGATION_ROUTES.PODCASTS_SEARCH);
      console.error(err);
      setLoading(false);

      setError(true);
    }
  }, []);

  useEffect(() => {
    if (searchText) {
      callSubmitFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedCallback = (event) => {
    setSearchText(event.target.value);
    if (event && event.target.value.length > 2) {
      dispatch(saveSearchText(event.target.value));
      setPageId(1);
      setLoadMoreBtn(true);
      callPodcastsApi(1, event.target.value);
    }
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );
  const onInputChange = (event) => {
    setSearchText(event.target.value);
    debounceInputHandler(event);
  };

  const callSubmitFunction = (event) => {
    event?.preventDefault();
    if (searchText !== '') {
      dispatch(saveSearchText(searchText));
      setPodcasts([]);
      setPageId(1);
      setLoadMoreBtn(true);
      callPodcastsApi(1, searchText);
    }
  };

  const loadMore = () => {
    callPodcastsApi(pageId + 1, searchText);
    setPageId(pageId + 1);
  };

  useEffect(() => {
    if (location?.hash) {
      let hashtag = location?.hash || '';
      setSearchText(hashtag);
      setPageId(1);
      setLoadMoreBtn(true);
      callPodcastsApi(1, hashtag);
    }
  }, [callPodcastsApi]);

  const showEmptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_PODCAST_YET} />;
  };

  const onPress = (item) => {
    history.push(`${NAVIGATION_ROUTES.PODCASTS_EPISODE_DETAIL}/${item.episode_id}`, {
      isPlay: false,
    });
  };

  const renderItem = (item, index) => {
    return <PodcastListItem onPress={onPress} item={item} key={`${index}`} />;
  };

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
        <div
          className={
            isSearchEnabled ? 'search-bar-with-icon search-bar-border' : 'search-bar-with-icon'
          }>
          {isSearchEnabled ? (
            <form className="directory-search-bar" onSubmit={callSubmitFunction}>
              <input
                autoFocus
                className="ml-2 directory-search-bar"
                type="text"
                placeholder="Search.."
                onChange={onInputChange}
                value={searchText}
              />
            </form>
          ) : (
            <div className="directory-search-bar" />
          )}
          {isSearchEnabled ? (
            <div className="gcdo-icon-row mr-2" onClick={resetValue}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
                )}
                className="header-icon"
              />
            </div>
          ) : (
            <div className="gcdo-icon-row mr-2" onClick={onClickSearchHandler}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH
                )}
                className="header-icon"
              />
            </div>
          )}
        </div>
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.PODCASTS} />
      <ActivityLoader visible={loading} />
      {podcasts && (
        <div className="mb-3">
          {!podcasts.length && !loading && showEmptyListView()}
          {!!podcasts.length && (
            <div>
              <div className="row overflow-hidden main-content-container">
                {podcasts.map((item, index) => renderItem(item, index))}
              </div>
              {loadMoreBtn && (
                <LoadMoreBtn buttonStyle="my-3" onClick={loadMore} isLoading={loading} />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PodcastsSearchScreen;
