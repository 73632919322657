import React, { useState, useCallback, useRef, useEffect } from 'react';
import CommentBox from '../../common/ui/comment_box/CommentBox';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';
import { CEO_CHAT_ID, GET_DIRECTORY_DATA } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectProgressLoadingState,
} from '../../state/UIState';
import { showToast } from '../../utils/common';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import TagEmployeeView from '../wall/components/TagPeopleView';

const CeoReplyScreen = ({ history, location }) => {
  const { post = {} } = location?.state ?? {};
  const [tags, setTags] = useState(
    post && post.tags && post.tags.length > 0 ? post.tags.map((employeeNode) => employeeNode) : []
  );
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [commentText, setCommentText] = useState('');
  const question = location.state.question;
  const [employeeData, setEmployeeData] = useState([]);
  const pageIdRef = useRef(1);
  const appliedFiltersRef = useRef({});
  const [dataLength, setDataLength] = useState(0);
  const loadMoreFlagRef = useRef(true);
  const dispatch = useDispatch();
  const [showTagEmployeeView, setShowTagEmployeeView] = useState(false);

  useEffect(() => {
    if (pageIdRef.current !== 1) {
      loadMoreFlagRef.current = false;
    }
  }, [dataLength]);

  const callEmployees = useCallback(
    async (text = '') => {
      dispatch(saveShowEmptyContentView(true));
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      if (pageIdRef.current == 1) {
        setEmployeeData([]);
      }
      let params = { page_id: pageIdRef.current };
      try {
        params = { ...params, ...{ query: text } };
        if (Object.keys(appliedFiltersRef.current).length) {
          params = { ...params, ...appliedFiltersRef.current };
        }
        const apiResponse = await apiRequest(GET_DIRECTORY_DATA, REQUEST_TYPE.GET, params);
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            const data = apiResponse.response.data.feeds;
            setDataLength(data.length);
            if (pageIdRef.current === 1) {
              setEmployeeData(data);
            } else {
              setEmployeeData((oldArray) => [...oldArray, ...data]);
            }
          }
        }
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      } catch (err) {
        handleError(err, params, GET_DIRECTORY_DATA, NAVIGATION_ROUTES.CEO_CHAT);
        //error
      }
    },
    [dispatch]
  );

  useEffect(() => {
    callEmployees();
  }, [callEmployees]);

  const sendCommentFunction = async () => {
    if (tags.length > 5) {
      showToast(ERROR_MESSAGES.CEO_TAG_EMPLOYEE_MAX_LIMIT);
    } else {
      logEvent(ANALYTICS_EVENT_TYPES.CEO_LATEST_REPLY, question.question_id, '');
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      let tagString = '';
      if (tags.length > 0) {
        var tagArray = [];
        for (var i = 0; i < tags.length; i++) {
          tagArray.push(tags[i].employee_id);
        }
        tagString = tagArray.join(',');
      }
      const formData = new URLSearchParams();
      formData.append('answer', commentText);
      formData.append('tags', tagString);
      try {
        const apiResponse = await apiRequest(
          CEO_CHAT_ID + question.question_id + '/answer',
          REQUEST_TYPE.POST,
          formData
        );
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            const message = apiResponse.response.message;
            if (message) {
              showToast(message);
              history.goBack();
            }
          }
        }
      } catch (err) {
        handleError(
          err,
          formData,
          CEO_CHAT_ID + question.question_id + '/answer',
          NAVIGATION_ROUTES.CEO_CHAT
        );
        //error
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    }
  };

  const deleteEmployee = (employee) => {
    if (tags.includes(employee)) {
      const index = tags.indexOf(employee);
      if (index > -1) {
        setTags(tags.filter((item) => item !== tags[index]));
      }
    }
  };
  const onEmployeeSelected = (tags) => {
    if (tags.length > 0) {
      setTags([...tags]);
      setShowTagEmployeeView(false);
    }
  };

  const renderTagEmployee = () => {
    return (
      <div>
        {showTagEmployeeView && (
          <TagEmployeeView onEmployeeSelected={onEmployeeSelected} tags={tags} />
        )}
        <div className="d-flex flex-wrap mt-3">
          {tags.length > 0 &&
            tags.map((item, index) => {
              return (
                <div key={`${index}`} className="col-6 col-sm-3 col-lg-2 mb-4">
                  <div>
                    <UserProfilePhoto
                      imageBaseUrl={item.profile_picture.base_url}
                      imagePath={item.profile_picture.image_path}
                      imageClass="common-user-profile-photo-lg"
                    />
                    <div className="text-center mt-1">{item.first_name + ' ' + item.last_name}</div>
                  </div>
                  <div
                    className="d-flex position-absolute community-bg-view-image"
                    onClick={() => deleteEmployee(item)}>
                    <img
                      src={getFeatureComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.SOM_EMPLOYEE_CANCEL,
                        ACCESSIBILITY_IDENTIFIERS.SOM
                      )}
                      className="header-icon mr-3"
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CEO_CHAT} />
      <BackButton containerStyle="pt-3" />
      <div className="m-2">
        <CommentBox
          getCommentText={sendCommentFunction}
          onChangehandler={(val) => {
            setCommentText(val);
          }}
          showLoader={progressLoadingState.isProgressLoading}
        />
      </div>
      <div
        onClick={() => {
          setShowTagEmployeeView(true);
        }}
        className="d-flex common-cursor-pointer ml-2">
        <img
          src={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_TAG_PEOPLE,
            ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
          )}
          className="header-icon mr-2"
        />
        <div className="communities-tag-people">Tag Member(s)</div>
      </div>
      <div className="d-flex justify-content-center">
        <div className="common-container-md">{renderTagEmployee()}</div>
      </div>
    </div>
  );
};

export default CeoReplyScreen;
