import React, { useState, useEffect } from 'react';
import {
  ACTION_TYPES,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
} from '../../../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectCurrentPointsData } from '../../../../state/CheersData';
import CustomButton from '../../../../common/ui/custom_button/CustomButton';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import { selectAccessData } from '../../../../state/UserAccessData';

const TotalPointsTab = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [countText, setCountText] = useState();

  const currentPoints = useSelector(selectCurrentPointsData);
  const accessData = useSelector(selectAccessData);
  const [showRedeemButton, setShowRedeemButton] = useState(true);
  const [showDonateButton, setShowDonateButton] = useState(true);
  const cheersAccessData = accessData?.data?.find((item) => item.feature_key === '_cheers');

  useEffect(() => {
    if (cheersAccessData) {
      const actions = cheersAccessData?.actions;
      setShowRedeemButton(actions?.includes('_redeem'));
      setShowDonateButton(actions?.includes('_donate'));
    }
  }, [cheersAccessData]);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.TOTAL_POINTS_TAB_VISIT, '', ANALYTICS_ITEM_NAMES.CHEERS);
  }, []);

  useEffect(() => {
    setCountText(currentPoints);
  }, [currentPoints]);

  useEffect(() => {
    dispatch({ type: ACTION_TYPES.GET_CHEERS_POINTS, payload: { type: 'current' } });
  }, [dispatch]);

  const onPressRedeem = () => {
    history.push(NAVIGATION_ROUTES.REDEEM_POINTS_SCREEN, {
      currentBalance: countText,
    });
  };

  const onPressDonate = () => {
    history.push(NAVIGATION_ROUTES.DONATE_POINTS_SCREEN, {});
  };

  return (
    <div className="main-content-container">
      <div className="cheers-upper-view">
        <div>
          <div className="text-center cheers-total-count-text">{countText}</div>
          <div className="text-center mt-2 cheers-total-point-bal">Total Point Balance</div>
        </div>
      </div>
      <div className="cheers-below-view">
        {showRedeemButton && (
          <div>
            <div className="text-center mt-3 cheers-redeem-gift">
              Redeem your points with Gift Vouchers
            </div>
            <CustomButton
              buttonStyle="cheers-button-style mt-2"
              onClick={onPressRedeem}
              disabled={parseInt(currentPoints) <= 0}>
              REDEEM
            </CustomButton>
          </div>
        )}
      </div>
      {showDonateButton && (
        <div className="cheers-outer-view justify-content-between cheers-bottom-view">
          <div className="cheers-donate-points-text ml-2 mr-2">Donate your points</div>
          <CustomButton
            buttonStyle="cheers-button-style mr-3 mt-1 mb-1"
            onClick={onPressDonate}
            disabled={parseInt(currentPoints) <= 0}>
            Donate
          </CustomButton>
        </div>
      )}
    </div>
  );
};

export default React.memo(TotalPointsTab);
