import React from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import Divider from '../../../common/ui/divider/Divider';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { formatRelativeTimeFromNow } from '../../../utils/common';

const EpisodesListItem = ({ onPress, item, index }) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  return (
    <div>
      <div
        className="d-flex justify-content-between mt-3 common-cursor-pointer align-items-center"
        onClick={() => onPress(item, false)}>
        <div>
          <div className="podcast-title">{`${index} : ${item.title}`}</div>
          <div className="podcast-host-location">
            {formatRelativeTimeFromNow(item.pub_datetime)}
          </div>
        </div>
        <div
          className="common-cursor-pointer d-flex align-items-center flex-column"
          onClick={(e) => {
            e.stopPropagation();
            onPress(item, true);
          }}>
          <img
            className="podcast-play-icon"
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_CIRCULAR_PLAY,
              ACCESSIBILITY_IDENTIFIERS.COMMON
            )}
          />
          <div className="podcast-episode-media-type">{item.media_type}</div>
        </div>
      </div>
      <Divider style="mt-2" />
    </div>
  );
};

export default EpisodesListItem;
