import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getHeaderComponentUrl } from '../../../utils/accessibility';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { replaceNonAsciiCharsWithSpace } from '../../../utils/common';

const ResultsListItem = ({ item, onPressCommentButton, onPressLikeButton, identifier }) => {
  const [text, setText] = useState('Read more');

  const accessibilityData = useSelector(selectAccessibilityData);

  const onPressRead = () => {
    if (text === 'Read more') {
      setText('Read less');
    } else {
      setText('Read more');
    }
  };

  return (
    <div className="material-card p-3 mb-3">
      <UserProfilePhoto
        imageBaseUrl={item.employee.profile_picture.base_url}
        imagePath={item.employee.profile_picture.image_path}
        imageClass="common-user-profile-photo-xl"
        employeeId={item.employee.employee_id}
      />
      <div className="mb-1 anniversary-name text-center">
        {item.employee.first_name + ' ' + item.employee.last_name}
      </div>
      <div className="mt-1 anniversary-position-text text-center">
        {item.employee.position_title}
      </div>
      <div className="mt-1 text-center">
        {text === 'Read more'
          ? replaceNonAsciiCharsWithSpace(item.description.substring(0, 80)) + '...'
          : replaceNonAsciiCharsWithSpace(item.description)}
        <div className="anniversary-moment-read-text" onClick={onPressRead}>
          {text}
        </div>
      </div>
      <div className="row mt-3 d-flex justify-content-center">
        {item.likes.is_liked ? (
          <div>
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_ACTIVE_LIKE,
                ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
              )}
              className="ml-3 anniversary-normal-icon"
            />
          </div>
        ) : (
          <div onClick={() => onPressLikeButton(item)}>
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_INACTIVE_LIKE,
                ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
              )}
              className="ml-3 anniversary-normal-icon"
            />
          </div>
        )}
        <div className="ml-3">{item.likes.count}</div>
        <div onClick={() => onPressCommentButton(item)}>
          <img
            src={getHeaderComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_POST_COMMENT,
              ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
            )}
            className="ml-3 anniversary-normal-icon"
          />
        </div>
        {item.comment_count !== 0 && <div className="ml-3">{item.comment_count}</div>}
      </div>
    </div>
  );
};

export default ResultsListItem;
