import React, { useEffect, useState } from 'react';
import { formatDateDDMonthYYYY, getUrlWithSpecificRendition } from '../../../utils/common';
import { Image } from 'react-bootstrap';
import Divider from '../../../common/ui/divider/Divider';
import { SCREEN_DENSITY_QUALIFIERS } from '../../../common/constants/AppConstants';

const GalleryTabItem = ({ item, onPressItem, baseUrl, onPressMore }) => {
  const [images, setImages] = useState([]);
  const [countLeft, setCountLeft] = useState(0);

  useEffect(() => {
    if (item.title !== 'recent') {
      if (item.data.length < 7) {
        setImages(item.data);
        setCountLeft(0);
      } else {
        setImages(item.data.slice(0, 6));
        setCountLeft(item.data.length - 6);
      }
    } else {
      setImages(item.data);
    }
  }, [item.data, item.title]);

  return (
    <div>
      {item.title !== 'recent' && (
        <div className="breakouts-gallery-date">{formatDateDDMonthYYYY(item.title)}</div>
      )}
      <div className="row align-items-center">
        {images.map((imageItem, index) => {
          return (
            <div key={`${index}`} className="col-sm-4 mt-3 common-cursor-pointer">
              <div
                onClick={() => {
                  onPressItem(item.data, index, imageItem);
                }}>
                <Image
                  src={getUrlWithSpecificRendition(
                    baseUrl,
                    SCREEN_DENSITY_QUALIFIERS.HDPI,
                    imageItem
                  )}
                  className="breakout-gallery-image"
                />
              </div>
              {index === 5 && item.title !== 'recent' && countLeft > 0 && (
                <div onClick={() => onPressMore(item, baseUrl)} className="breakouts-bg-view">
                  <div className="breakouts-count-left-text">{`+${countLeft} ${
                    countLeft === 1 ? 'photo' : 'photos'
                  }`}</div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <Divider style="mt-3 mb-3" />
    </div>
  );
};

export default GalleryTabItem;
