import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { APPLY_FOR_IJP, GET_REFER_DETAIL, SAVE_JOB } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { saveProgressLoadingState } from '../../state/UIState';
import { formatDateDDMonthYYYY, showToast } from '../../utils/common';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { selectConfigurations } from '../../state/MasterData';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import HtmlView from '../../common/ui/html_view/HTMLView';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';
import IjprAppliedDialog from './components/IjprAppliedDialog';
import BackButton from '../../common/ui/common_back_button/BackButton';
import ReferConsentDialog from './components/ReferConsentDialog';
import IjpReferFormDialog from './components/IjpReferFormDialog';
import IjpApplyDialog from './components/IjpApplyDialog';
import { selectEmployeeData } from '../../state/EmployeeData';
import CommonAlert from './components/CommonAlert';
import IjpRequestAssistDialog from './components/IjpRequestAssistDialog';
import { selectAccessData } from '../../state/UserAccessData';

const IjpReferDetailScreen = () => {
  const dispatch = useDispatch();
  const { ijpId } = useParams();
  const accessibilityData = useSelector(selectAccessibilityData);
  const configurations = useSelector(selectConfigurations);
  const [showMoreReferrals, setShowMoreReferrals] = useState(false);
  const [isIjprAppliedDialogVisible, setIsIjprAppliedDialogVisible] = useState(false);
  const [isReferConsentDialogVisible, setIsReferConsentDialogVisible] = useState(false);
  const [isIjpApplyDialogVisible, setIsIjpApplyDialogVisible] = useState(false);
  const [isIjpReferFormDialogVisible, setIsIjpReferFormDialogDialogVisible] = useState(false);
  const employeeData = useSelector(selectEmployeeData);
  const [commonAlertVisibility, setCommonAlertVisibility] = useState(false);
  const [commonAlertData, setCommonAlertData] = useState({});
  const [isIjpRequestAssistDialogVisible, setIsIjpRequestAssistDialogVisible] = useState(false);
  const [ijprDetailData, setIjprDetailData] = useState([]);
  const [hasApplied, setHasApplied] = useState(false);
  const [error, setError] = useState(false);
  const accessData = useSelector(selectAccessData);
  const [showApplyButton, setShowApplyButton] = useState(true);
  const [showReferButton, setShowReferButton] = useState(true);
  const [showSavedJobs, setShowSavedJobs] = useState(true);
  const ijpAccessData = accessData?.data?.find((item) => item.feature_key === '_ijpr');

  useEffect(() => {
    if (ijpAccessData) {
      const actions = ijpAccessData?.actions;
      setShowApplyButton(actions?.includes('_apply'));
      setShowReferButton(actions?.includes('_refer'));
      setShowSavedJobs(actions?.includes('_bookmark'));
    }
  }, [ijpAccessData]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.IJP_DETAIL, NAVIGATION_ROUTES.IJP_REFER_DETAIL);
  }, []);

  const callIjprDetailApi = useCallback(async () => {
    dispatch(saveProgressLoadingState({ isProgressLoading: true }));
    const params = { ijp_id: ijpId };
    try {
      const apiResponse = await apiRequest(GET_REFER_DETAIL, REQUEST_TYPE.GET, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          setIjprDetailData(apiResponse.response.data.ijp);
        }
      }
    } catch (err) {
      handleError(err, params, GET_REFER_DETAIL, NAVIGATION_ROUTES.IJP_REFER_DETAIL);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch, ijpId]);

  const renderScreen = useCallback(async () => {
    callIjprDetailApi();
  }, [callIjprDetailApi]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const onPressReferHandler = () => {
    if (ijprDetailData?.actions?.referral?.can_refer) {
      logEvent(
        ANALYTICS_EVENT_TYPES.IJP_REFERRALS_REFER_CLICK,
        ijprDetailData.job_code.toString(),
        ANALYTICS_ITEM_NAMES.IJPR
      );
      setIsReferConsentDialogVisible(true);
    } else {
      setCommonAlertData({ ...(ijprDetailData?.actions?.referral ?? {}), title: 'Alert' });
      setCommonAlertVisibility(true);
    }
  };

  const onPressShowMoreHandler = () => {
    setShowMoreReferrals((previousValue) => !previousValue);
  };

  const onPressAskOrgHandler = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.IJP_ASK_HELP_CLICK,
      ijprDetailData.job_code.toString(),
      ANALYTICS_ITEM_NAMES.IJPR
    );
    window.open(configurations._helpdesk_url, '_blank');
  };

  const onPressSaveJobHandler = () => {
    callSaveJobApi();
  };

  const callSaveJobApi = async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const apiURl = SAVE_JOB + '/' + ijprDetailData?.ijp_id + '/bookmark';
      const apiResponse = await apiRequest(apiURl, REQUEST_TYPE.POST, {});
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          const message = apiResponse.response.message;
          if (message) {
            showToast(message);
            callIjprDetailApi();
          }
        }
      }
    } catch (err) {
      handleError(
        err,
        {},
        SAVE_JOB + '/' + ijprDetailData?.ijp_id + '/bookmark',
        NAVIGATION_ROUTES.IJP_REFER_DETAIL
      );
      console.error(err);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      setError(true);
    }
  };

  const onPressApplyHandler = () => {
    if (ijprDetailData?.actions?.ijp?.can_apply) {
      logEvent(
        ANALYTICS_EVENT_TYPES.IJP_APPLY_CLICK,
        ijprDetailData.job_code.toString(),
        ANALYTICS_ITEM_NAMES.IJPR
      );
      setIsIjpApplyDialogVisible(true);
    } else {
      setCommonAlertData({ ...(ijprDetailData?.actions?.ijp ?? {}), title: 'Alert' });
      setCommonAlertVisibility(true);
    }
  };

  const onPressConfirmedApplyHandler = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.IJP_APPLY_CONFIRM_CLICK,
      ijprDetailData.job_code.toString(),
      ANALYTICS_ITEM_NAMES.IJPR
    );
    callApplyForIjpApi();
  };

  const callApplyForIjpApi = async () => {
    const params = new URLSearchParams();
    params.append('job_code', ijprDetailData.job_code);
    params.append('employee_code', employeeData.employee_code);
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.APPLY_FOR_JOB,
        })
      );
      const apiResponse = await apiRequest(APPLY_FOR_IJP, REQUEST_TYPE.POST, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      setIsIjpApplyDialogVisible(false);
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          const message = apiResponse.response.message;
          if (message) {
            setIsIjprAppliedDialogVisible(true);
            setHasApplied(true);
          }
        }
      }
    } catch (err) {
      handleError(err, params, APPLY_FOR_IJP, NAVIGATION_ROUTES.IJP_REFER_DETAIL);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  return (
    <div className="container">
      <IjpApplyDialog
        isIjpApplyDialogVisible={isIjpApplyDialogVisible}
        setIsIjpApplyDialogVisible={setIsIjpApplyDialogVisible}
        onPressConfirmedApplyHandler={onPressConfirmedApplyHandler}
      />

      <IjprAppliedDialog
        isIjprAppliedDialogVisible={isIjprAppliedDialogVisible}
        setIsIjprAppliedDialogVisible={setIsIjprAppliedDialogVisible}
        isIjpSectionTypeActive={false}
      />
      <CommonAlert
        setAlertVisiblity={setCommonAlertVisibility}
        alertVisibility={commonAlertVisibility}
        title={commonAlertData?.title}
        message={commonAlertData?.message}
      />

      <IjpRequestAssistDialog
        isIjpRequestAssistDialogVisible={isIjpRequestAssistDialogVisible}
        setIsIjpRequestAssistDialogVisible={setIsIjpRequestAssistDialogVisible}
        ijpData={ijprDetailData}
      />

      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.IJPR} />
      {ijprDetailData && !!Object.keys(ijprDetailData).length && (
        <div>
          <IjpReferFormDialog
            jobCode={ijprDetailData.job_code}
            isIjpReferFormDialogVisible={isIjpReferFormDialogVisible}
            setIsIjpReferFormDialogDialogVisible={setIsIjpReferFormDialogDialogVisible}
            setIsIjprAppliedDialogVisible={setIsIjprAppliedDialogVisible}
          />
          <ReferConsentDialog
            jobCode={ijprDetailData.job_code}
            isReferConsentDialogVisible={isReferConsentDialogVisible}
            setIsReferConsentDialogVisible={setIsReferConsentDialogVisible}
            setIsIjpReferFormDialogDialogVisible={setIsIjpReferFormDialogDialogVisible}
          />
          <div className="header-view justify-content-between">
            <BackButton isFullRow={false} />
            <div className="d-flex flex-direction-row">
              {showApplyButton && (
                <>
                  <CustomButton
                    disabled={ijprDetailData?.application?.applied || hasApplied}
                    onClick={onPressApplyHandler}>
                    APPLY
                  </CustomButton>
                </>
              )}
              <div className="mr-2" />
              {showReferButton && (
                <>
                  <CustomButton onClick={onPressReferHandler}>REFER</CustomButton>
                </>
              )}
            </div>
          </div>
          <div className="mt-3 mb-4">
            <div className="d-flex align-items-center">
              <h4 className="ijpr-detail-title mb-0">{ijprDetailData.job_title}</h4>
              {showSavedJobs && (
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ijprDetailData.is_bookmarked
                      ? ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_BOOKMARK_FILLED_GREY
                      : ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_BOOKMARK_OUTLINE_GREY,
                    ACCESSIBILITY_IDENTIFIERS.COMMON
                  )}
                  className="ijpr-detail-saved-job-icon ml-1 common-cursor-pointer"
                  onClick={() => {
                    onPressSaveJobHandler();
                  }}
                />
              )}
            </div>
            <div className="d-flex">
              <div className="ijpr-detail-subtitle">
                {ijprDetailData.country_title} - {ijprDetailData.location_title}
              </div>
              <div className="ijpr-detail-date text-nowrap ml-3">
                {formatDateDDMonthYYYY(ijprDetailData.created_date)}
              </div>
            </div>
          </div>
          {ijprDetailData.referrals.candidates &&
            ijprDetailData.referrals.candidates.length > 0 && (
              <div className="ijpr-detail-referer-box-container p-3 mb-4">
                <div className="ijpr-detail-referer-box-title d-flex">
                  {"You've referred: "}
                  {showMoreReferrals
                    ? ijprDetailData.referrals.candidates
                        .map((candidate) => candidate.candidate_name.trim())
                        .join(', ') + ' '
                    : ijprDetailData.referrals.candidates[0].candidate_name.trim() + ' '}
                  {ijprDetailData.referrals.candidates.length > 1 && (
                    <div
                      className="ijpr-detail-referer-box-show-more-text common-cursor-pointer"
                      onClick={onPressShowMoreHandler}>
                      {showMoreReferrals
                        ? 'see less'
                        : `+${ijprDetailData.referrals.candidates.length - 1} more`}
                    </div>
                  )}
                </div>
              </div>
            )}
          {/* Ijp Applied Details */}
          {!!ijprDetailData?.application && !!ijprDetailData?.application?.applied && (
            <div className="ijpr-application-status-container mb-4">
              <div className="mt-1 mb-0 ijpr-application-status-text">
                <span> Applied On: </span>
                <span className="ijpr-application-status-value">
                  {ijprDetailData?.application?.applied_on ?? ''}
                </span>
              </div>

              <div className="mt-1 mb-0 ijpr-application-status-text">
                <span>Application Stage: </span>
                <span className="ijpr-application-status-value">
                  {ijprDetailData?.application?.stage ?? ''}
                </span>
              </div>

              <div className="mt-1 mb-0 ijpr-application-status-text">
                <span> Application Status: </span>
                <span className="ijpr-application-status-value">
                  {ijprDetailData?.application?.status ?? ''}
                </span>
              </div>
            </div>
          )}

          <div className="d-flex justify-content-between mb-4">
            <div className="mr-3">
              <div className="ijpr-detail-field-box-title">Indent no</div>
              <div className="ijpr-detail-field-box-subtitle">{ijprDetailData?.job_code}</div>
            </div>
            <div className="mr-3">
              <div className="ijpr-detail-field-box-title">Experience</div>
              <div className="ijpr-detail-field-box-subtitle">
                {ijprDetailData?.minimum_expirence} - {ijprDetailData?.maximum_expirence} yrs
              </div>
            </div>
            <div className="mr-3">
              <div className="ijpr-detail-field-box-title">Business Unit</div>
              <div className="ijpr-detail-field-box-subtitle">
                {ijprDetailData?.business_unit_title}
              </div>
            </div>
            <div>
              <div className="ijpr-detail-field-box-title">Department</div>
              <div className="ijpr-detail-field-box-subtitle">
                {ijprDetailData?.department_title}
              </div>
            </div>
          </div>

          <div className="mb-4">
            <h5 className="ijpr-detail-section-title">Hiring Manager</h5>
            <div className="ijpr-detail-hirer-container d-flex p-3">
              <div className="mr-3">
                <UserProfilePhoto
                  imageBaseUrl={ijprDetailData.hiring_manager.profile_picture.base_url}
                  imagePath={ijprDetailData.hiring_manager.profile_picture.image_path}
                  employeeId={ijprDetailData.hiring_manager.employee_id}
                  imageClass="common-user-profile-photo-md"
                />
              </div>
              <div className="d-flex flex-column justify-content-center">
                <div className="ijpr-detail-hirer-title">
                  {ijprDetailData.hiring_manager.first_name +
                    `${
                      ijprDetailData.hiring_manager.middle_name
                        ? ' ' + ijprDetailData.hiring_manager.middle_name + ' '
                        : ' '
                    }` +
                    ijprDetailData.hiring_manager.last_name}
                </div>
                <div className="ijpr-detail-hirer-subtitle">
                  {ijprDetailData.hiring_manager.email}
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <h5 className="ijpr-detail-section-title">Job Description</h5>
            <HtmlView html={ijprDetailData.job_description} />
          </div>
          <div className="ijpr-detail-request-help-container d-flex align-items-center p-3 mb-4">
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_QUERY,
                ACCESSIBILITY_IDENTIFIERS.COMMON
              )}
              className="ijpr-detail-request-help-icon mr-1"
            />
            <div className="ijpr-detail-request-help-title">
              For any queries, please check{' '}
              <span
                onClick={onPressAskOrgHandler}
                className="ijpr-detail-request-help-highlighted-text common-cursor-pointer">
                Ask Microland
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default IjpReferDetailScreen;
