import React from 'react';
import BackButton from '../../ui/common_back_button/BackButton';
import SectionHeader from '../../ui/section_header/SectionHeader';

const HTMLViewScreen = ({ location }) => {
  const { html, identifier } = location.state;

  const contentClickHandler = (e) => {
    const targetLink = e.target.closest('a');
    if (!targetLink) return;
    e.preventDefault();
    let win = window.open(targetLink.href, '_blank');
    win.focus();
  };

  return (
    <div className="container">
      <SectionHeader identifier={identifier} />
      <BackButton />
      <div
        dangerouslySetInnerHTML={{ __html: html }}
        className="main-content-container"
        onClick={contentClickHandler}
      />
    </div>
  );
};

export default HTMLViewScreen;
