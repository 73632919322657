import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_TITLE,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import { GET_NOTIFICATION_LIST } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import {
  saveProgressLoadingState,
  saveSearchText,
  saveShowEmptyContentView,
  selectSearchText,
  selectShowEmptyContentView,
} from '../../state/UIState';
import NotificationItem from './components/NotificationItem';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import { notificationListingNavigation } from '../../navigation/NavigationHandler';
import { resetNotificationCount } from '../../state/NotificationData';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { resetAllTabs } from '../../state/TabData';
import { saveCommunityData } from '../../state/CommunityData';
import ActivityLoader from '../../common/ui/activity_loader/ActivityLoader';
import { saveTravelRequestData } from '../../state/TravelRequestMasterData';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import debounce from 'lodash.debounce';

const NotificationSearchScreen = ({ history }) => {
  const dispatch = useDispatch();
  const [notificationData, setNotificationData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadMoreFlag, setLoadMoreFlag] = useState(true);
  const [pageId, setPageId] = useState(1);
  const searchData = useSelector(selectSearchText);
  const [searchText, setSearchText] = useState(searchData ?? '');
  const [isSearchEnabled, setIsSearchEnabled] = useState(true);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [showEmptyView, setShowEmptyView] = useState(false);

  const showEmptyContentView = useSelector(selectShowEmptyContentView);

  const callNotificationApi = useCallback(
    async (id, loadMore, searchQuery = searchText) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        const params = {
          page_id: id,
          query: searchQuery,
        };
        try {
          setShowEmptyView(false);
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          setLoading(true);
          const apiResponse = await apiRequest(GET_NOTIFICATION_LIST, REQUEST_TYPE.GET, params);
          setLoading(false);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                setNotificationData((oldArray) => [
                  ...oldArray,
                  ...apiResponse.response.data.feeds,
                ]);
                setLoadMoreFlag(true);
                dispatch(resetNotificationCount());
                if (apiResponse.response.data.feeds?.length === 0) {
                  setShowEmptyView(true);
                }
              } else {
                setShowEmptyView(true);
                dispatch(saveProgressLoadingState({ isProgressLoading: false }));
                setLoadMoreFlag(false);
              }
            }
          }
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        } catch (err) {
          handleError(
            err,
            params,
            GET_NOTIFICATION_LIST,
            NAVIGATION_ROUTES.NOTIFICATION_SEARCH_SCREEN
          );
          setShowEmptyView(true);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          setLoading(false);
        }
      }
    },
    [dispatch, searchText, notificationData]
  );

  useEffect(() => {
    if (notificationData.length === 0) {
      setLoadMoreFlag(false);
    }
  }, [notificationData.length]);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.NOTIFICATION_SEARCH,
      NAVIGATION_ROUTES.NOTIFICATION_SEARCH_SCREEN
    );
  }, []);

  const debouncedCallback = (event) => {
    setSearchText(event.target.value);
    if (event && event.target.value.length > 2) {
      dispatch(saveSearchText(event.target.value));
      setPageId(1);
      setLoadMoreFlag(true);
      setNotificationData([]);
      callNotificationApi(1, true, event.target.value);
    }
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );

  const onPressItem = (item) => {
    if (Object.keys(item.data).length > 0) {
      dispatch(resetAllTabs());
      dispatch(saveTravelRequestData({}));
      dispatch(saveCommunityData({}));
      notificationListingNavigation(item.data, history);
    }
  };

  const onPressLoadMore = () => {
    setPageId((prevPageId) => prevPageId + 1);
    callNotificationApi(pageId + 1, loadMoreFlag);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.SEARCH_NO_RESULTS} />;
  };

  const callSubmitFunction = (event) => {
    event?.preventDefault();
    dispatch(saveSearchText(searchText));
    if (searchText !== '') {
      logEvent(ANALYTICS_EVENT_TYPES.NOTIFICATION_SEARCH, searchText, ANALYTICS_ITEM_NAMES.SEARCH);
      setPageId(1);
      setLoadMoreFlag(true);
      callNotificationApi(1, true, searchText);
    }
  };

  const onClickSearchHandler = () => {
    setIsSearchEnabled(true);
  };

  const resetValue = () => {
    if (searchText === '') {
      history.goBack();
    }
    setSearchText('');
  };

  useEffect(() => {
    if (searchText) {
      callSubmitFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
        <div
          className={
            isSearchEnabled ? 'search-bar-with-icon search-bar-border' : 'search-bar-with-icon'
          }>
          <form className="directory-search-bar" onSubmit={callSubmitFunction}>
            <input
              autoFocus
              className="ml-2 directory-search-bar"
              type="text"
              placeholder="Search.."
              onChange={(e) => {
                setSearchText(e.target.value);
                debounceInputHandler(e);
              }}
              value={searchText}
            />
          </form>

          {isSearchEnabled ? (
            <div className="gcdo-icon-row mr-2" onClick={resetValue}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
                )}
                className="header-icon"
              />
            </div>
          ) : (
            <div className="gcdo-icon-row mr-2" onClick={onClickSearchHandler}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH
                )}
                className="header-icon"
              />
            </div>
          )}
        </div>
      </div>
      <SectionHeader title={SCREEN_TITLE.NOTIFICATIONS} />
      <BackButton />
      <div className="main-content-container">
        {searchText !== '' && showEmptyView && !notificationData.length && !loading ? (
          emptyListView()
        ) : (
          <div>
            <div>
              {notificationData.map((item, index) => {
                return <NotificationItem onPressItem={onPressItem} item={item} key={`${index}`} />;
              })}
            </div>
            <ActivityLoader visible={loading} />
            {loadMoreFlag && <LoadMoreBtn onClick={onPressLoadMore} isLoading={loading} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationSearchScreen;
