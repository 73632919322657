import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectEmployeeData } from '../../../../state/EmployeeData';
import {
  formatDateDDMonthYYYY,
  formatTimeAMPM,
  isEmptyText,
  isToday,
  showToast,
} from '../../../../utils/common';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../constants/AppConstants';
import { saveProgressLoadingState } from '../../../../state/UIState';
import { COMMENT_BY_ID } from '../../../../services/ApiUrls';
import { apiRequest } from '../../../../services/Service';
import { saveCommentCountData } from '../../../../state/CommentData';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../../utils/accessibility';
import HtmlView from '../../../ui/html_view/HTMLView';
import UserProfilePhoto from '../../../ui/user_profile_photo/UserProfilePhoto';
import CommentPopupMenu from '../../../ui/comment_popup_menu/CommentPopupMenu';
import CommentReplyItem from './CommentReplyItem';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import { useHistory } from 'react-router-dom';
import CustomEditCommentBox from './CustomEditCommentBox';
import { EditorState } from 'draft-js';
import {
  extractDataFromEditorState,
  getCommentMentionsState,
  getMentionsState,
} from '../../../components/hashtagsAndMentionsInput/hashtagUtils';
import CustomCommentBox from '../../../ui/comment_box/CustomCommentBox';

const CommentItemWishes = (props) => {
  const {
    commentId,
    commentReplies,
    likeType,
    referId,
    callCommentsApi,
    commentLikeCount,
    commentHasLiked,
    onPressWhoLikedHandler,
    likeUnlikeCommentApi,
    mentions = [],
    commentText = '',
  } = props;
  const dispatch = useDispatch();
  const [editedCommentText, setEditedCommentText] = useState();
  const [editFlag, setEditFlag] = useState();
  const [isReplyModeActive, setIsReplyModeActive] = useState(false);
  const [replyText, setReplyText] = useState('');
  const [replyEditFlag, setReplyEditFlag] = useState();
  const [selectedReplyCommentData, setSelectedReplyCommentData] = useState({});
  const accessibilityData = useSelector(selectAccessibilityData);
  const employeeData = useSelector(selectEmployeeData);
  const loggedInEmployeeId = employeeData.employee_id;
  const history = useHistory();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [image, setImage] = useState('');
  const [imageBaseUrl, setImageBaseUrl] = useState('');
  const [prevMentions, setPrevMentions] = useState([]);
  const [replyEditorState, setReplyEditorState] = useState(EditorState.createEmpty());
  const [replyMentions, setReplyMentions] = useState([]);
  const [replyImage, setReplyImage] = useState('');

  useEffect(() => {
    if (props.message.includes('<img')) {
      const index = props.message.indexOf('<img');
      setEditedCommentText(props.message.substring(0, index));
      const pos = props.message.indexOf('https');
      const imageUrl = props.message.substring(pos, props.message.length - 2);
      const fileNameIndex = imageUrl.lastIndexOf('/');
      setImage(imageUrl.substring(fileNameIndex + 1, imageUrl.length));
      setImageBaseUrl(imageUrl.substring(0, fileNameIndex + 1));
    } else {
      setEditedCommentText(props.message);
    }
    const tempMentions = [];
    Object.values(mentions).map((user) => {
      if (user) {
        tempMentions.push({
          type: 'mention',
          name: user.full_name + ' ',
          avatar: '',
          employee_id: user.employee_id,
        });
      }
    });
    setPrevMentions(tempMentions);
    const editorState = getMentionsState(commentText, tempMentions);
    setEditorState(editorState);
    setEditedCommentText(props.message);
  }, [props.message]);

  const replyCommentApi = async () => {
    const { description, addedMentions } = extractDataFromEditorState(replyEditorState);
    let tempDes = description;
    tempDes = tempDes.replace(/ {2,}(?![\n])/g, ' ');

    addedMentions.map((user) => {
      tempDes = tempDes.replace(user.name, `<rsu>{{${user.employee_id}}}</rsu> `);
    });

    replyMentions.map((user) => {
      tempDes = tempDes.replace(user.name, `<rsu>{{${user.employee_id}}}</rsu> `);
    });

    if (replyImage != '') {
      tempDes = tempDes + ` <egami>${replyImage}</egami>`;
    }
    if (!isEmptyText(tempDes)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      try {
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        const formData = new URLSearchParams();
        formData.append('type', likeType);
        formData.append('comment', tempDes);
        formData.append('parent_comment_id', commentId);
        const apiResponse = await apiRequest(COMMENT_BY_ID + referId, REQUEST_TYPE.POST, formData);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            const message = apiResponse.response.message;
            if (message) {
              showToast(message);
              setIsReplyModeActive(false);
              setReplyText('');
              setReplyEditorState(EditorState.createEmpty());
              setReplyImage('');
              dispatch(saveCommentCountData(apiResponse.response.data.comment_count));
              await callCommentsApi(1);
            }
          }
        }
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      } catch (err) {
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    }
  };

  const onMention = (mention) => {
    setPrevMentions((prev) => [...prev, mention]);
  };

  const onChangeEdittedTextInput = (val) => {
    const { description, addedMentions } = extractDataFromEditorState(editorState);
    const editorState2 = getCommentMentionsState(description + val, [
      ...addedMentions,
      ...prevMentions,
    ]);
    setEditorState(editorState2);
    setEditedCommentText(description + val);
  };

  const editCommentFunction = async () => {
    const { description, addedMentions } = extractDataFromEditorState(editorState);
    let tempDes = description;
    tempDes = tempDes.replace(/ {2,}(?![\n])/g, ' ');

    addedMentions.map((user) => {
      tempDes = tempDes.replace(user.name, `<rsu>{{${user.employee_id}}}</rsu> `);
    });

    prevMentions.map((user) => {
      tempDes = tempDes.replace(user.name, `<rsu>{{${user.employee_id}}}</rsu> `);
    });

    if (image != '') {
      tempDes = tempDes + ` <egami>${image}</egami>`;
    }
    await props.editComment(props.commentId, tempDes);
    setEditFlag(false);
  };

  const onPressLikeHandler = () => {
    likeUnlikeCommentApi(commentId);
  };

  const onPressReplyHandler = () => {
    setIsReplyModeActive(true);
  };

  const handleHtmlClicks = (e) => {
    const target = e.target.getAttribute('href');
    if (target && target.startsWith('/')) {
      e.stopPropagation();
      e.preventDefault();
      if (target.includes(NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL)) {
        logEvent(
          ANALYTICS_EVENT_TYPES.USER_MENTION_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.USER_MENTION
        );
        history.push(target);
      } else {
        logEvent(
          ANALYTICS_EVENT_TYPES.HASHTAG_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.HASHTAG
        );
        history.push(target);
      }
    }
  };

  const getReplyText = (val) => {
    const { description, addedMentions } = extractDataFromEditorState(replyEditorState);
    const editorState2 = getCommentMentionsState(description + val, [...addedMentions]);
    setReplyEditorState(editorState2);
  };

  const onReplyMention = (mention) => {
    setReplyMentions((prev) => [...prev, mention]);
  };

  return (
    <div className="my-3">
      <div className="d-flex">
        <div className="d-flex">
          <UserProfilePhoto
            imageBaseUrl={props.employee.profile_picture.base_url}
            imagePath={props.employee.profile_picture.image_path}
            imageClass="common-user-profile-photo-xxs"
            employeeId={props.employee.employee_id}
          />
          {(isReplyModeActive || commentReplies.length > 0) && (
            <div className="common-comment-item-vertical-line" />
          )}
        </div>
        <div className="ml-2 flex-grow-1">
          <div className="d-flex align-items-center">
            <p className="common-comment-item-name-text mb-0">
              {props.employee.first_name + ' ' + props.employee.last_name}
            </p>
            {!!props.employee.pronouns && (
              <p className="common-comment-item-pronoun-text ml-1 mb-0">
                {'(' + props.employee.pronouns + ')'}
              </p>
            )}
            <p className="common-comment-item-date-text mb-0 ml-2 align-self-end">
              {isToday(props.date) ? formatTimeAMPM(props.date) : formatDateDDMonthYYYY(props.date)}
            </p>
          </div>

          <div>
            {editFlag ? (
              <div className="py-2">
                <CustomEditCommentBox
                  onChangehandler={onChangeEdittedTextInput}
                  showLoader={props.showLoader}
                  commentText={editedCommentText.replace(/<\/?a[^>]*>/g, '')}
                  callCommentFunction={editCommentFunction}
                  callCancelFunction={() => {
                    setEditFlag(false);
                    setEditedCommentText(props.message);
                  }}
                  setImage={setImage}
                  image={image}
                  editorState={editorState}
                  enableImageUpload={true}
                  setEditorState={setEditorState}
                  enableHashtags={false}
                  enableUserMentions={true}
                  imageBaseUrl={imageBaseUrl}
                  setImageBaseUrl={setImageBaseUrl}
                  onMention={onMention}
                />
              </div>
            ) : (
              <>
                <div className="d-flex">
                  <div className="d-flex">
                    <div
                      className={`py-2 px-3 my-1 common-comment-item-message-text-outer common-comment-item-other-comment-background`}>
                      <HtmlView
                        onClick={handleHtmlClicks}
                        html={props.message.replace(/\n/gi, '')}
                        htmlStyle="common-comment-item-message-text"
                      />
                    </div>
                    {loggedInEmployeeId === props.employee.employee_id && !editFlag && (
                      <div className="px-2">
                        <CommentPopupMenu
                          editFuncHandler={() => {
                            setEditFlag(true);
                          }}
                          deleteFuncHandler={() => {
                            props.deleteComment(props.commentId);
                          }}
                          showDeleteLoader={props.showDeleteLoader}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div onClick={onPressLikeHandler}>
                    <img
                      src={getFeatureComponentUrl(
                        accessibilityData,
                        commentHasLiked
                          ? ACCESSIBILITY_FEATURE_COMPONENTS.CEO_CHAT_ACTIVE_LIKE
                          : ACCESSIBILITY_FEATURE_COMPONENTS.CEO_CHAT_INACTIVE_LIKE,
                        ACCESSIBILITY_IDENTIFIERS.CEO_CHAT
                      )}
                      className="common-comment-item-action-item-icon common-cursor-pointer"
                    />
                  </div>
                  <div
                    className="ml-3 pt-1 common-comment-item-action-item-text common-cursor-pointer"
                    onClick={onPressReplyHandler}>
                    Reply
                  </div>
                  {!!commentLikeCount && (
                    <div
                      className="ml-3 pt-1 common-comment-item-action-item-text common-cursor-pointer"
                      onClick={() => {
                        commentLikeCount > 0 ? onPressWhoLikedHandler(commentId) : () => {};
                      }}>
                      <div>
                        {commentLikeCount} {commentLikeCount === 1 ? 'Like' : 'Likes'}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div>
        {isReplyModeActive && (
          <CustomCommentBox
            getCommentText={replyCommentApi}
            onChangehandler={getReplyText}
            showLoader={props.showLoader}
            setEditorState={setReplyEditorState}
            editorState={replyEditorState}
            enableUserMentions={true}
            enableHashtags={false}
            enableImageUpload={true}
            image={replyImage}
            setImage={setReplyImage}
            editMode={true}
            onMention={onReplyMention}
          />
        )}
        {commentReplies.map((replyData, index) => (
          <CommentReplyItem
            key={index.toString()}
            showLoader={props.showLoader}
            replyIndex={index}
            replyTotalCount={commentReplies.length}
            replyData={replyData}
            replyEditFlag={replyEditFlag}
            setReplyEditFlag={setReplyEditFlag}
            deleteComment={props.deleteComment}
            editComment={props.editComment}
            showDeleteLoader={props.showDeleteLoader}
            onPressWhoLikedHandler={onPressWhoLikedHandler}
            likeUnlikeCommentApi={likeUnlikeCommentApi}
            selectedReplyCommentData={selectedReplyCommentData}
            setSelectedReplyCommentData={setSelectedReplyCommentData}
          />
        ))}
      </div>
    </div>
  );
};

export default CommentItemWishes;
