import React, { useCallback, useEffect, useState } from 'react';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ACTION_TYPES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { POSH } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import HtmlView from '../../common/ui/html_view/HTMLView';
import { useDispatch, useSelector } from 'react-redux';
import { saveProgressLoadingState, selectBaseUrl } from '../../state/UIState';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { selectAccessData } from '../../state/UserAccessData';

const PoshScreen = ({ location, history }) => {
  const [posh, setPosh] = useState();
  const [id] = useState(new URLSearchParams(location.search).get('id'));
  const dispatch = useDispatch();
  const baseUrl = useSelector(selectBaseUrl);
  const accessData = useSelector(selectAccessData);
  const [showReferPolicy, setShowReferPolicy] = useState(true);
  const [showRaiseConcern, setShowRaiseConcern] = useState(true);
  const poshAccessData = accessData?.data?.find((item) => item.feature_key === '_posh');

  useEffect(() => {
    const actions = poshAccessData?.actions;
    setShowReferPolicy(actions?.includes('_referpolicy'));
    setShowRaiseConcern(actions?.includes('_raiseconcern'));
  }, [poshAccessData?.actions]);

  const onPressPolicy = () => {
    history.push(`${NAVIGATION_ROUTES.POLICIES}/${posh.refer_policy_id}`);
  };

  const renderScreen = useCallback(() => {
    callPoshApi();
  }, []);

  useEffect(() => {
    if (baseUrl !== '' && id && id !== '') {
      dispatch({ type: ACTION_TYPES.GET_TOKEN, payload: { id: id } });
    }
  }, [baseUrl, dispatch, id]);

  useEffect(() => {
    if (baseUrl !== '') {
      renderScreen();
    }
  }, [baseUrl, renderScreen]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.POSH, NAVIGATION_ROUTES.POSH);
  }, []);

  const callPoshApi = async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const apiResponse = await apiRequest(POSH, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.feed) {
            const data = apiResponse.response.data.feed;
            setPosh(data);
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(err, {}, POSH, NAVIGATION_ROUTES.POSH);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  const onPressRaiseConcern = () => {
    history.push(NAVIGATION_ROUTES.POSH_RAISE_CONCERN);
  };

  return (
    <div className="main-content-container container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.POSH} />
      <HtmlView html={posh && posh.description} htmlStyle="posh-html-content mt-3" />

      {posh && (
        <div className="d-flex align-items-center justify-content-center flex-row mt-5">
          {showReferPolicy && (
            <CustomButton buttonStyle="posh-refer-btn mr-3" onClick={onPressPolicy}>
              {'Refer Policy'}
            </CustomButton>
          )}
          {showRaiseConcern && (
            <CustomButton buttonStyle="posh-concern-btn ml-3" onClick={onPressRaiseConcern}>
              {'Raise Concern'}
            </CustomButton>
          )}
        </div>
      )}
    </div>
  );
};

export default PoshScreen;
