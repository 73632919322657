import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { selectWebBaseUrl } from '../../state/MasterData';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { getSecuredPdfUrl } from '../../utils/common';
import { saveProgressLoadingState } from '../../state/UIState';
import { apiRequest, handleError } from '../../services/Service';
import { COMMENT_BY_ID, CUSTOMERS_BY_ID } from '../../services/ApiUrls';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessData } from '../../state/UserAccessData';
import { selectAccessibilityData } from '../../state/AccessibilityData';

const OurCustomersResearchersDetails = ({ history, location }) => {
  const { apiType, customerId, dataType, likeType, type, parentName } = location.state;
  const [customer, setCustomer] = useState({});
  const dispatch = useDispatch();
  const [liked, setLiked] = useState();
  const [likeCount, setLikeCount] = useState();
  const [commentCount, setCommentCount] = useState();
  const webUrl = useSelector(selectWebBaseUrl);
  const accessData = useSelector(selectAccessData);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [showLike, setShowLike] = useState(true);
  const [showComment, setShowcomment] = useState(true);
  const customerAccessData = accessData?.data?.find((item) => item.feature_key === '_ourCustomers');

  useEffect(() => {
    const actions = customerAccessData?.actions;
    setShowLike(actions?.includes('_like'));
    setShowcomment(actions?.includes('_comment'));
  }, [customerAccessData?.actions]);

  const postLike = async () => {
    logEvent(ANALYTICS_EVENT_TYPES.OUR_CUSTOMERS_LIKE, customer.id, ANALYTICS_ITEM_NAMES.LIKE);
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
      })
    );
    const formData = new URLSearchParams();
    formData.append('type', likeType);
    try {
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + customer.id + '/like',
        REQUEST_TYPE.POST,
        formData
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            setLikeCount(data.like_count);
            setLiked(data.liked);
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(
        err,
        formData,
        COMMENT_BY_ID + customer.id + '/like',
        NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  const renderHeader = () => {
    return (
      <div className="broadcast-header-row justify-content-end-view">
        {showLike && (
          <>
            <div className="broadcast-icon-row mr-3" onClick={postLike}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  liked
                    ? ACCESSIBILITY_HEADER_COMPONENTS.ACTIVE_LIKE
                    : ACCESSIBILITY_HEADER_COMPONENTS.INACTIVE_LIKE
                )}
                className="header-icon"
              />
            </div>
            <div
              className="mr-3 ourcustomer-like-comment common-cursor-pointer"
              onClick={() => {
                likeCount
                  ? history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
                      referId: customer.id,
                      parentType: likeType,
                    })
                  : () => {};
              }}>
              {likeCount ? likeCount : '0'}
            </div>{' '}
          </>
        )}
        {showComment && (
          <>
            {' '}
            <div
              className="broadcast-icon-row mr-3"
              onClick={() => {
                history.push(NAVIGATION_ROUTES.COMMENTS, {
                  referId: customer.id,
                  likeType: likeType,
                  fromRoute: NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT,
                });
              }}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.COMMENT
                )}
                className="header-icon"
              />
            </div>
            <div
              className="mr-3 ourcustomer-like-comment common-cursor-pointer"
              onClick={() => {
                logEvent(
                  ANALYTICS_EVENT_TYPES.OUR_CUSTOMER_COMMENT,
                  customer.id,
                  ANALYTICS_ITEM_NAMES.COMMENT
                );
                history.push(NAVIGATION_ROUTES.COMMENTS, {
                  referId: customer.id,
                  likeType: likeType,
                  fromRoute: NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT,
                });
              }}>
              {commentCount ? commentCount : '0'}
            </div>
          </>
        )}
      </div>
    );
  };

  const callCustomerData = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequest(
        CUSTOMERS_BY_ID + apiType + '/' + customerId,
        REQUEST_TYPE.GET,
        {}
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data[dataType]) {
            const data = apiResponse.response.data[dataType];
            setCustomer(data);
            setLiked(data.liked);
            setLikeCount(data.like_count);
            setCommentCount(data.comment_count);
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(
        err,
        {},
        CUSTOMERS_BY_ID + apiType + '/' + customerId,
        NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [apiType, customerId, dataType, dispatch]);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.OUR_CUSTOMER,
      NAVIGATION_ROUTES.OUR_CUSTOMER_RESEARCHERS_CONTENT
    );
  }, []);

  useEffect(() => {
    callCustomerData();
  }, [callCustomerData]);

  return (
    <div className="container">
      <SectionHeader title={parentName ? parentName : 'LOADING...'} />
      <div className="header-view justify-content-between">
        <BackButton isFullRow={false} />
        {renderHeader()}
      </div>
      <div className="main-content-container">
        <div className="iframe-container">
          {Object.keys(customer).length > 0 && customer?.encoded_url && (
            <iframe src={getSecuredPdfUrl(webUrl, customer?.encoded_url)} allowFullScreen />
          )}
        </div>
      </div>
    </div>
  );
};

export default OurCustomersResearchersDetails;
