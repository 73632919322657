import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveShowEmptyContentView, selectShowEmptyContentView } from '../../../state/UIState';
import {
  formatDateDDMonthYYYY,
  formatSecondsIntoMinutes,
  formatSecondsIntoTimeForVideos,
  getUrlWithSpecificRendition,
} from '../../../utils/common';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  FILTER_IDENTIFIERS,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import Filter from '../../../common/ui/filter/Filter';
import { apiRequest, handleError } from '../../../services/Service';
import { EVENTS_BY_TYPE } from '../../../services/ApiUrls';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import { useHistory } from 'react-router';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import PlayIconOverlay from '../../../common/ui/play_icon_overlay/PlayIconOverlay';

const DIEventsTab = ({ getEventDate }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [eventDates, setEventDates] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('upcoming');
  const loadMoreFlag = useRef(true);
  const pageId = useRef(1);
  const [pastEvents, setPastEvents] = useState([]);
  const accessibilityData = useSelector(selectAccessibilityData);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.DI_EVENTS_TAB_VISIT, '', ANALYTICS_ITEM_NAMES.DI);
  }, []);

  useEffect(() => {
    if (eventDates?.length > 0) {
      getEventDate(eventDates);
    }
  }, [eventDates, getEventDate]);

  const callPastEventsApi = useCallback(async () => {
    setLoading(true);
    dispatch(saveShowEmptyContentView(true));
    let params = {
      page_id: pageId.current,
      route_type: selectedEvent,
      channel_type: '1',
    };
    if (Object.keys(appliedFilters)?.length) {
      params = { ...params, ...appliedFilters };
    }
    try {
      const apiResponse = await apiRequest(EVENTS_BY_TYPE, REQUEST_TYPE.GET, params);
      setLoading(false);
      if (pageId.current === 1) {
        setPastEvents([]);
      }
      if (Object.keys(apiResponse)?.length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const dates = apiResponse.response.data.dates;
            setEventDates(dates);
            const data = apiResponse.response.data;
            if (data?.events?.length > 0) {
              setPastEvents((oldArray) => [...oldArray, ...apiResponse.response.data.events]);
              loadMoreFlag.current = true;
            } else {
              loadMoreFlag.current = false;
            }
          }
        }
      }
    } catch (err) {
      handleError(err, params, EVENTS_BY_TYPE, NAVIGATION_ROUTES.DI);
      setLoading(false);
    }
  }, [appliedFilters, dispatch, selectedEvent]);

  useEffect(() => {
    callPastEventsApi();
  }, [callPastEventsApi]);

  const filterData = useCallback((selectedFilters) => {
    let tempFilterObject = {};
    if (selectedFilters) {
      Object.keys(selectedFilters).map((filterKey) => {
        if (selectedFilters?.[filterKey]?.length) {
          tempFilterObject[filterKey] = selectedFilters[filterKey].join();
        }
      });
    }
    setAppliedFilters(tempFilterObject);
  }, []);

  const emptyListView = () => {
    return (
      <DefaultContentView
        message={
          selectedEvent === 'upcoming'
            ? ERROR_MESSAGES.NO_DI_UPCOMING_EVENTS
            : ERROR_MESSAGES.NO_DI_PAST_EVENTS
        }
      />
    );
  };

  const loadMore = () => {
    if (loadMoreFlag.current) {
      pageId.current = pageId.current + 1;
      callPastEventsApi();
    }
  };

  const onPressItem = (item) => {
    history.push(`${NAVIGATION_ROUTES.EVENTS}/${item.event_id}`);
  };

  const renderUpcomingPasSwitch = () => {
    return (
      <div className="d-flex mb-3 di-outer-view">
        {selectedEvent === 'upcoming' ? (
          <div className="d-flex align-items-center justify-content-center flex-fill di-left-selected">
            <div className="di-left-selected-text">Upcoming</div>
          </div>
        ) : (
          <div
            className="d-flex align-items-center justify-content-center flex-fill common-cursor-pointer"
            onClick={() => {
              pageId.current = 1;
              loadMoreFlag.current = true;
              setPastEvents([]);
              setSelectedEvent('upcoming');
            }}>
            <div className="di-right-selected-text">Upcoming</div>
          </div>
        )}
        {selectedEvent === 'past' ? (
          <div className="d-flex align-items-center justify-content-center flex-fill di-right-selected">
            <div className="di-left-selected-text">Past</div>
          </div>
        ) : (
          <div
            className="d-flex align-items-center justify-content-center flex-fill common-cursor-pointer"
            onClick={() => {
              pageId.current = 1;
              loadMoreFlag.current = true;
              setPastEvents([]);
              setSelectedEvent('past');
            }}>
            <div className="di-right-selected-text">Past</div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="main-content-container">
      <div>
        <Filter
          topFilter={FILTER_IDENTIFIERS.GEOGRAPHIES}
          geographies={true}
          locations={true}
          levels={true}
          layers={true}
          onClick={filterData}
        />
      </div>
      <div>
        {renderUpcomingPasSwitch()}
        {showEmptyContentView && !pastEvents?.length && !loading ? (
          emptyListView()
        ) : (
          <div>
            <div className="row">
              {pastEvents.map((item, index) => {
                return (
                  <div
                    key={`${index}`}
                    className="col-sm-4 mb-2 common-cursor-pointer"
                    onClick={() => onPressItem(item)}>
                    <div>
                      {item?.content_load_type === '3' && <PlayIconOverlay />}
                      <img
                        src={getUrlWithSpecificRendition(
                          item.promo_image.base_url,
                          SCREEN_DENSITY_QUALIFIERS.HDPI,
                          item.promo_image.image_path
                        )}
                        className="embed-responsive embed-responsive-16by9"
                      />
                      <div className="row ml-1 mt-1">
                        <img
                          src={getFeatureComponentUrl(
                            accessibilityData,
                            ACCESSIBILITY_FEATURE_COMPONENTS.NEWS_CALENDER,
                            ACCESSIBILITY_IDENTIFIERS.NEWS
                          )}
                          className="event-calender-style"
                        />
                        <div className="d-flex flex-row">
                          <div className="events-event-date">
                            {formatDateDDMonthYYYY(item.start_date)}
                          </div>
                          <div className="broadcast-event-date">
                            {item.time_to_read && item.time_to_read != 0
                              ? formatSecondsIntoMinutes(item.time_to_read)
                              : null}
                          </div>
                          {!!item?.video_duration &&
                            item?.content_load_type === '3' &&
                            item?.video_duration > 0 && (
                              <div className="broadcast-event-date-new ">
                                {formatSecondsIntoTimeForVideos(item?.video_duration)}
                              </div>
                            )}
                          {!!item.page_views && (
                            <div className="broadcast-event-date">
                              {item.page_views
                                ? item.page_views > 1
                                  ? `${item.page_views} views`
                                  : `${item.page_views} view`
                                : null}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="event-title">{item.title}</div>
                    <div className="events-summary">{item.summary}</div>
                  </div>
                );
              })}
            </div>
            <ActivityLoader visible={loading} />
            {loadMoreFlag.current && (
              <div className="mb-4 mt-4">
                <LoadMoreBtn onClick={loadMore} isLoading={loading} />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DIEventsTab;
