import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { selectCommunities } from '../../state/MasterData';
import { COMMUNITIES_BY_CATEGORY } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { saveProgressLoadingState } from '../../state/UIState';
import CommunityTab from './tabs/CommunityTab';
import { saveCommunitiesTabData, selectCommunitiesTabData } from '../../state/TabData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';

const CommunitiesMainScreen = ({ location }) => {
  const dispatch = useDispatch();
  const [tabKey] = useState(
    location && location.state && location.state.tabKey && location.state.tabKey
  );

  const communitiesTitles = useSelector(selectCommunities);
  const communitiesTabData = useSelector(selectCommunitiesTabData);

  const [communities, setCommunities] = useState();

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.COMMUNITIES, NAVIGATION_ROUTES.COMMUNITIES);
    callCommunitiesApi();
  }, []);

  useEffect(() => {
    if (communitiesTitles.length && communitiesTabData === '' && tabKey) {
      dispatch(saveCommunitiesTabData(tabKey));
    } else if (communitiesTitles.length && communitiesTabData === '') {
      dispatch(saveCommunitiesTabData(communitiesTitles[0].key));
    }
  }, [dispatch, communitiesTabData, communitiesTitles, tabKey]);

  const callCommunitiesApi = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequest(COMMUNITIES_BY_CATEGORY, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.communities) {
            const data = apiResponse.response.data.communities;
            if (data.length > 0) {
              setCommunities(data);
            }
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(err, {}, COMMUNITIES_BY_CATEGORY, NAVIGATION_ROUTES.COMMUNITIES);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch]);

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.COMMUNITIES} />
      {communities?.length > 0 && <CommunityTab data={communities} />}
    </div>
  );
};

export default CommunitiesMainScreen;
