import React from 'react';
import CustomButton from '../custom_button/CustomButton';

const LoadMoreBtn = (props) => {
  const { btnText = 'Load More', buttonStyle = '' } = props;
  return (
    <CustomButton
      buttonStyle={`btn-load-more ${buttonStyle}`}
      onClick={props.onClick}
      disabled={props.isLoading}>
      {btnText}
    </CustomButton>
  );
};

export default LoadMoreBtn;
