import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import Colors from '../../common/themes/Colors';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoaderState, saveProgressLoadingState, saveSearchText } from '../../state/UIState';
import { showToast } from '../../utils/common';
import { apiRequestEStore, apiRequestUser } from '../../services/Service';
import { Carousel, Modal } from 'react-bootstrap';
import HtmlView from '../../common/ui/html_view/HTMLView';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import {
  saveCartItemsCount,
  saveDefaultAddress,
  saveSelectedColor,
  saveSelectedItemSku,
  saveSelectedSize,
  selectCartItemsCount,
  selectSelectedColor,
  selectSelectedItemSku,
  selectSelectedSize,
  selectTempAddress,
} from '../../state/EStoreData';
import { selectEStoreConfig } from '../../state/MasterData';
import Collections from './components/Collections';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import {
  CARTS_MINE,
  CARTS_MINE_ITEMS,
  CUSTOMERS_ME,
  GET_CHILDREN_OF_CUSTOM_CONFIG_PRODUCT,
  INVENTORY_LEFT,
  PRODUCTS_ATTRIBUTES,
  PRODUCT_BY_SKU,
} from '../../services/ApiUrls';

const MiStoreProductDetailsScreen = () => {
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [price, setPrice] = useState(null);
  const [images, setImages] = useState([]);
  const [productName, setProductName] = useState('');
  const [title, setTitle] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [description, setDescription] = useState('');
  const [productSku, setProductSku] = useState('');
  const [type, setType] = useState('');
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const selectedSize = useSelector(selectSelectedSize);
  const selectedColor = useSelector(selectSelectedColor);
  const selectedItemSku = useSelector(selectSelectedItemSku);
  const [defaultAddress, setDefaultAddress] = useState({});
  // const tempAddress = useSelector(selectTempAddress);
  const [addedToCart, setAddedToCart] = useState(false);
  const [showDefaultContent, setShowDefaultContent] = useState(false);
  const [children, setChildren] = useState([]);
  const [availableColors, setAvailableColors] = useState([]);
  const [availableSizes, setAvailableSizes] = useState([]);
  const [typeId, setTypeId] = useState('simple');
  const [loaders, setLoaders] = useState([]);
  const [sizeChart, setSizeChart] = useState('');
  const [currentActiveSlideIndex, setCurrentActiveSlideIndex] = useState(0);
  const [productStock, setProductStock] = useState(0);
  const [showSizeChart, setShowSizeChart] = useState(false);
  const { sku } = useParams();
  const history = useHistory();
  const accessibilityData = useSelector(selectAccessibilityData);
  const cartItemsCount = useSelector(selectCartItemsCount);
  const tempAddress = useSelector(selectTempAddress);
  const estoreConfig = useSelector(selectEStoreConfig);
  const dispatch = useDispatch();

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.MICRO_STORE_PRODUCT_DETAILS_SCREEN,
      NAVIGATION_ROUTES.MICRO_STORE_PRODUCT_SCREEN
    );
  }, []);

  const callSubmitFunction = (event) => {
    event?.preventDefault();
    dispatch(saveSearchText(searchText));
    history.push(NAVIGATION_ROUTES.MICRO_STORE_ALL_PRODUCTS_SCREEN);
  };

  useEffect(() => {
    if (loaders.length > 0) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
    } else {
      dispatch(resetLoaderState());
    }
  }, [dispatch, loaders]);

  const onInputChange = (event) => {
    setSearchText(event.target.value);
  };

  const resetValue = () => {
    setSearchText();
    setIsSearchEnabled(false);
  };

  const onClickSearchHandler = () => {
    setIsSearchEnabled(true);
  };

  const updateSize = useCallback(
    (size, color) => {
      logEvent(ANALYTICS_EVENT_TYPES.ESTORE_SIZE_SELECT, size?.value, ANALYTICS_ITEM_NAMES.SIZE);
      const sizeBasedItems = children.filter((child) => child.size === size?.value);
      const tempAvailableColors = [];
      sizeBasedItems.map((item) => {
        tempAvailableColors.push(item.color);
      });
      const selectedItem = sizeBasedItems.filter((child) => child.color === color?.value);
      if (selectedItem.length > 0) {
        const shortDesc = selectedItem[0].short_description;
        const dec = selectedItem[0].description;
        setPrice(selectedItem[0].price);
        setProductName(selectedItem[0].name);
        setProductSku(selectedItem[0].sku);
        shortDesc && setShortDescription(shortDesc);
        dec && setDescription(dec);
        setType(selectedItem[0].type_id);
        setImages(selectedItem[0].media_gallery);
        if (selectedItem[0].is_salable) {
          setProductStock(selectedItem[0].quantity_and_stock_status);
        } else {
          setProductStock(0);
        }
      }
      setAvailableColors(tempAvailableColors);
      dispatch(saveSelectedSize(size));
      setAddedToCart(false);
    },
    [children, dispatch]
  );

  const updateColor = useCallback(
    (color, size) => {
      logEvent(ANALYTICS_EVENT_TYPES.ESTORE_COLOR_SELECT, color.value, ANALYTICS_ITEM_NAMES.COLOR);
      const colorBasedItems = children.filter((child) => child.color === color.value);
      const tempAvailableSizes = [];
      colorBasedItems.map((item) => {
        tempAvailableSizes.push(item.size);
      });
      const selectedItem = colorBasedItems.filter((child) => child.size === size.value);
      if (selectedItem.length > 0) {
        const shortDesc = selectedItem[0].short_description;
        const dec = selectedItem[0].description;
        setImages(selectedItem[0].media_gallery);
        setPrice(selectedItem[0].price);
        setProductName(selectedItem[0].name);
        setProductSku(selectedItem[0].sku);
        shortDesc && setShortDescription(shortDesc);
        dec && setDescription(dec);
        setType(selectedItem[0].type_id);
        if (selectedItem[0].is_salable) {
          setProductStock(selectedItem[0].quantity_and_stock_status);
        } else {
          setProductStock(0);
        }
      }
      setAvailableSizes(tempAvailableSizes);
      dispatch(saveSelectedColor(color));
      setAddedToCart(false);
    },
    [children, dispatch]
  );

  const fetchInventory = useCallback(
    async (skuList) => {
      setLoaders((prev) => [...prev, 1]);
      try {
        const apiResponse = await apiRequestEStore(INVENTORY_LEFT, REQUEST_TYPE.POST, {
          skus: skuList,
        });
        if (apiResponse.response) {
          const quantity = apiResponse.response?.find((res) => res.sku === sku)?.qty;
          setProductStock(quantity);
        }
        setLoaders((prev) => [...prev].slice(0, -1));
      } catch (e) {
        dispatch(resetLoaderState());
      }
    },
    [dispatch, sku]
  );

  const addItemToCart = async () => {
    if (addedToCart) {
      logEvent(ANALYTICS_EVENT_TYPES.ESTORE_CART_CLICK, '', ANALYTICS_ITEM_NAMES.CART);
      history.push(NAVIGATION_ROUTES.MICRO_STORE_CART_SCREEN);
    } else {
      logEvent(ANALYTICS_EVENT_TYPES.ESTORE_ADD_TO_CART, productSku, ANALYTICS_ITEM_NAMES.CART);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      try {
        const apiCartResponse = await apiRequestUser(CARTS_MINE, REQUEST_TYPE.POST);

        if (apiCartResponse.response) {
          const formData = {
            cartItem: {
              sku: productSku,
              qty: 1,
              name: productName,
              price: price,
              product_type: type,
              quote_id: apiCartResponse.response,
            },
          };
          const apiResponse = await apiRequestUser(CARTS_MINE_ITEMS, REQUEST_TYPE.POST, formData);

          if (Object.keys(apiResponse).length && apiResponse.response?.status === false) {
            showToast(apiResponse.response?.message ?? 'Something went wrong');
            dispatch(resetLoaderState());
            return;
          }
          if (Object.keys(apiResponse).length && apiResponse.response) {
            showToast('Item added to your cart');
            dispatch(saveCartItemsCount(cartItemsCount + 1));
            setAddedToCart(true);
          }
        }

        dispatch(resetLoaderState());
      } catch (err) {
        dispatch(resetLoaderState());
        showToast(estoreConfig.product_to_cart_error);
      }
    }
  };

  const fetchSizes = useCallback(
    async (id, label) => {
      setLoaders((prev) => [...prev, 1]);
      try {
        const apiResponse = await apiRequestEStore(
          `${PRODUCTS_ATTRIBUTES}?searchCriteria[pageSize]=10&searchCriteria[filterGroups][0][filters][0][field]=attribute_id&searchCriteria[filterGroups][0][filters][0][value]=${id}`,
          REQUEST_TYPE.GET
        );

        if (Object.keys(apiResponse).length) {
          if (apiResponse.response && Object.keys(apiResponse.response).length > 0) {
            const items = apiResponse.response.items[0]?.options;
            if (items.length > 0) {
              const filteredItems = items.filter((item) => item.label !== ' ');
              if (label === 'Size') {
                setSizes(filteredItems);
              } else if (label === 'Color') {
                setColors(filteredItems);
              }
            }
          }
        }
        setLoaders((prev) => [...prev].slice(0, -1));
      } catch (err) {
        dispatch(resetLoaderState());
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (children.length > 0 && sizes.length > 0 && colors.length > 0) {
      const color = children[0].color;
      const size = children[0].size;
      const tempSelectedSize =
        Object.keys(selectedSize).length > 0
          ? selectedSize
          : sizes.find((item) => size === item.value);
      const tempSelectedColor =
        Object.keys(selectedColor).length > 0
          ? selectedColor
          : colors.find((item) => color === item.value);
      setPrice(children[0].price);
      if (tempSelectedSize && Object.keys(tempSelectedSize).length > 0) {
        updateSize(tempSelectedSize, tempSelectedColor);
      }
      if (tempSelectedColor && Object.keys(tempSelectedColor).length > 0) {
        updateColor(tempSelectedColor, tempSelectedSize);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, sizes, colors, selectedSize, selectedColor]);

  const fetchChildren = useCallback(async () => {
    setLoaders((prev) => [...prev, 1]);
    try {
      const apiResponse = await apiRequestEStore(
        GET_CHILDREN_OF_CUSTOM_CONFIG_PRODUCT(sku),
        REQUEST_TYPE.GET
      );
      if (Object.keys(apiResponse).length) {
        setChildren(apiResponse.response);
      }
      setLoaders((prev) => [...prev].slice(0, -1));
    } catch (err) {
      dispatch(resetLoaderState());
    }
  }, [dispatch, sku]);

  const fetchProduct = useCallback(async () => {
    setLoaders((prev) => [...prev, 1]);
    try {
      if (selectedItemSku !== sku) {
        dispatch(saveSelectedColor({}));
        dispatch(saveSelectedSize({}));
      }
      const [apiResponse, addressesResponse] = await Promise.all([
        apiRequestEStore(`${PRODUCT_BY_SKU}${sku}`, REQUEST_TYPE.GET),
        apiRequestUser(CUSTOMERS_ME, REQUEST_TYPE.GET),
      ]);

      if (Object.keys(apiResponse).length) {
        setAddedToCart(false);
        if (apiResponse.response && Object.keys(apiResponse.response).length > 0) {
          const data = apiResponse.response;
          setTypeId(data.type_id);
          const shortDesc =
            data.custom_attributes.find((item) => item.attribute_code === 'short_description')
              ?.value ?? '';
          const dec =
            data.custom_attributes.find((item) => item.attribute_code === 'description')?.value ??
            '';
          data.extension_attributes?.configurable_product_options?.forEach(async (item) => {
            await fetchSizes(item.attribute_id, item.label);
          });
          const sizeDetails =
            data.custom_attributes.find((item) => item.attribute_code === 'size_chart_web')
              ?.value ?? '';
          if (sizeDetails) {
            setSizeChart(
              sizeDetails
                .replace(/&lt;/, '<')
                .replace(/&gt;/, '>')
                .replace(/<img/, '<img style="width:100%"')
            );
          }
          setTitle(data.name);
          setShortDescription(shortDesc);
          setDescription(dec);
          if (data.type_id === 'configurable') {
            fetchChildren();
          } else {
            fetchInventory([sku]);
            setImages(data.media_gallery_entries);
            setProductName(data.name);
            setType(data.type_id);
            setProductSku(data.sku);
            setPrice(data.price);
          }
        }
      }
      if (Object.keys(addressesResponse.response).length) {
        addressesResponse.response?.addresses.map((address) => {
          if (address.default_shipping) {
            setDefaultAddress(address);
            dispatch(saveDefaultAddress(address));
          }
        });
      }
      setLoaders((prev) => [...prev].slice(0, -1));
    } catch (err) {
      setShowDefaultContent(true);
      dispatch(resetLoaderState());
    }
  }, [dispatch, fetchChildren, fetchInventory, fetchSizes, selectedItemSku, sku]);

  useEffect(() => {
    if (sku) {
      dispatch(saveSelectedItemSku(sku));
      fetchProduct();
    }
  }, [dispatch, fetchProduct, sku]);

  const handleChange = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ESTORE_CART_CHANGE_ADDRESS,
      '',
      ANALYTICS_ITEM_NAMES.CART_CHANGE_ADDRESS
    );
    history.push(NAVIGATION_ROUTES.MI_STORE_SELECT_ADDRESS_SCREEN);
  };

  const closeSizeChart = () => {
    logEvent(ANALYTICS_EVENT_TYPES.ESTORE_SIZE_CHART_CLOSE, '', ANALYTICS_ITEM_NAMES.SIZE_CHART);
    setShowSizeChart(false);
  };

  const handleSizeChart = () => {
    logEvent(ANALYTICS_EVENT_TYPES.ESTORE_SIZE_CHART_OPEN, '', ANALYTICS_ITEM_NAMES.SIZE_CHART);
    setShowSizeChart(true);
  };

  return (
    <div className="container">
      <Modal
        contentClassName="estore-size-chart-modal mx-auto"
        show={showSizeChart}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={closeSizeChart}
        centered>
        <Modal.Body>
          <div>
            <div className="attendance-modal-close-icon">
              <img
                onClick={closeSizeChart}
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.SF_CLOSE_ICON,
                  ACCESSIBILITY_IDENTIFIERS.CONTINUOUS_FEEDBACK
                )}
                className="attendance-close-icon"
              />
            </div>
            <div
              dangerouslySetInnerHTML={{ __html: sizeChart }}
              className="w-100"
              // onClick={contentClickHandler}
            />
          </div>
        </Modal.Body>
      </Modal>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICRO_STORE} />
      {!!title && (
        <div className="store-common-comment-header-view">
          <div
            className="d-flex align-items-center common-cursor-pointer"
            onClick={() => history.goBack()}>
            <IoArrowBackCircleOutline
              size="2.25em"
              className="mr-2"
              color={Colors.silverChaliceLight}
            />
            <span className="common-back-button-title">{title ?? sku}</span>
          </div>

          <div className="d-flex flex-row align-items-center">
            <div>
              <div
                className={
                  isSearchEnabled
                    ? 'search-bar-with-icon search-bar-border'
                    : 'search-bar-with-icon'
                }>
                {isSearchEnabled ? (
                  <form className="directory-search-bar" onSubmit={callSubmitFunction}>
                    <input
                      autoFocus
                      className="ml-2 directory-search-bar"
                      type="text"
                      placeholder="Search.."
                      onChange={onInputChange}
                      value={searchText}
                    />
                  </form>
                ) : (
                  <div className="directory-search-bar" />
                )}
                {isSearchEnabled ? (
                  <div className="gcdo-icon-row mr-2" onClick={resetValue}>
                    <img
                      src={getHeaderComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
                      )}
                      className="header-icon"
                    />
                  </div>
                ) : (
                  <div className="gcdo-icon-row mr-2" onClick={onClickSearchHandler}>
                    <img
                      src={getHeaderComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_HEADER_COMPONENTS.SEARCH
                      )}
                      className="header-icon"
                    />
                  </div>
                )}
              </div>
            </div>
            <div
              className="gcdo-icon-row mr-3"
              onClick={() => {
                logEvent(ANALYTICS_EVENT_TYPES.ESTORE_CART_CLICK, '', ANALYTICS_ITEM_NAMES.CART);
                history.push(NAVIGATION_ROUTES.MICRO_STORE_CART_SCREEN);
              }}>
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.CART_BLUE,
                  ACCESSIBILITY_IDENTIFIERS.MICRO_STORE
                )}
                className="header-icon-estore ml-1"
              />
              {cartItemsCount > 0 && <div className="cart-items-count-chip">{cartItemsCount}</div>}
            </div>
          </div>
        </div>
      )}
      {showDefaultContent && (
        <DefaultContentView message={ERROR_MESSAGES.PRODUCT_DETAILS_NOT_FOUND} />
      )}
      {loaders.length == 0 && (
        <div className="e-store-product-list-main">
          <div className="w-75 p-2">
            <div className="estore-produt-details-and-images">
              <div className="product-details-screen-images">
                <Carousel
                  activeIndex={currentActiveSlideIndex}
                  nextIcon={
                    images.length > 1 && (
                      <span aria-hidden="true" className="carousel-control-next-icon" />
                    )
                  }
                  prevIcon={
                    images.length > 1 && (
                      <span aria-hidden="true" className="carousel-control-prev-icon" />
                    )
                  }
                  onSelect={(selectedIndex) => setCurrentActiveSlideIndex(selectedIndex)}>
                  {images.map((imageItem, index) => {
                    return (
                      <Carousel.Item key={`${index}`}>
                        <div className="d-flex justify-content-center">
                          <img
                            className="estore-carousel-image"
                            src={
                              typeId === 'configurable'
                                ? imageItem
                                : `${estoreConfig.image_base_url}${imageItem.file}`
                            }
                          />
                        </div>
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </div>
              <div className="product-details-screen-details">
                <div className="estore-product-details-block">
                  <div className="estore-title-price-font">{title}</div>
                  <HtmlView html={shortDescription} htmlStyle="estore-regular-text-12" />
                  <div className="estore-price-out-of-stock mt-2">
                    {price > 0 && (
                      <div className="estore-title-price-font">{`\u20b9 ${price}`}</div>
                    )}
                    {productStock < 5 && (
                      <div className="estore-out-of-stock-font">
                        {productStock <= 0 ? 'Out of stock' : `Only ${productStock} left`}
                      </div>
                    )}
                  </div>
                </div>
                {sizes.length > 0 && (
                  <div className="estore-product-details-block">
                    <div className="estore-size-color-title-block">
                      <div className="estore-size-color-font">Size: {selectedSize.label}</div>
                      {sizeChart ? (
                        <div onClick={handleSizeChart} className="estore-size-color-chart-font">
                          Size chart
                        </div>
                      ) : null}
                    </div>
                    <div className="d-flex flex-row">
                      {sizes.map((size, index) => (
                        <div
                          className={
                            availableSizes.includes(size.value)
                              ? selectedSize.value === size.value
                                ? 'estore-size-item-active'
                                : 'estore-size-item-inactive'
                              : 'estore-size-item-disabled'
                          }
                          onClick={
                            availableSizes.includes(size.value)
                              ? () => updateSize(size, selectedColor)
                              : null
                          }
                          key={index}>
                          {size.label}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {colors.length > 0 && (
                  <div className="estore-product-details-block">
                    <div className="estore-size-color-title-block">
                      <div className="estore-size-color-font">Color: {selectedColor.label}</div>
                    </div>
                    <div className="e-store-colors-block row">
                      {colors
                        .filter((item) => availableColors.includes(item.value))
                        .map((color, index) => (
                          <div
                            onClick={
                              availableColors.includes(color.value)
                                ? () => updateColor(color, selectedSize)
                                : null
                            }
                            className={
                              availableColors.includes(color.value)
                                ? selectedColor.value === color.value
                                  ? 'estore-color-item-active'
                                  : 'estore-color-item-inactive'
                                : 'estore-color-item-disabled'
                            }
                            key={index}>
                            {color.label}
                          </div>
                        ))}
                    </div>
                  </div>
                )}
                <div className="estore-product-details-block">
                  <div className="estore-size-color-title-block">
                    <div className="estore-size-color-font">DELIVERY </div>
                  </div>
                  <div>{estoreConfig.estimated_delivery}</div>
                  {Object.keys(tempAddress).length > 0 || Object.keys(defaultAddress).length > 0 ? (
                    <div className="estore-product-detail-address-block">
                      <div className="estore-product-detail-address-text">
                        {Object.keys(tempAddress).length > 0
                          ? `${tempAddress.postcode} (${tempAddress.firstname} ${tempAddress.lastname})`
                          : `${defaultAddress.postcode} (${defaultAddress.firstname} ${defaultAddress.lastname})`}
                      </div>
                      <div
                        onClick={handleChange}
                        className="estore-size-color-chart-font common-cursor-pointer">
                        Change
                      </div>
                    </div>
                  ) : (
                    <div
                      onClick={handleChange}
                      className="estore-product-details-select-address mt-2">
                      <div className="estore-bold-text">SELECT ADDRESS</div>
                    </div>
                  )}
                </div>
                <div className="estore-product-details-block">
                  <div className="d-flex w-100 justify-content-center">
                    <div className="w-75">
                      <CustomButton
                        disabled={productStock === 0}
                        buttonStyle="estore-add-to-cart-button"
                        onClick={addItemToCart}>
                        {addedToCart ? 'GO TO YOUR BAG' : 'ADD TO BAG'}
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {description.length > 0 && (
              <div className="estore-product-details-container mt-3 p-2">
                <div className="estore-size-color-title-block">
                  <div className="estore-size-color-font">PRODUCT DETAILS</div>
                </div>
                <HtmlView
                  html={description.replace(/&lt;/g, '<').replace(/&gt;/g, '>')}
                  htmlStyle="breakout-content-html0"
                />
              </div>
            )}
          </div>
          <Collections />
        </div>
      )}
    </div>
  );
};

export default MiStoreProductDetailsScreen;
