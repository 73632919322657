import React from 'react';
import { getUrlWithSpecificRendition } from '../../../utils/common';
import { ACCESSIBILITY_IDENTIFIERS, SCREEN_DENSITY_QUALIFIERS } from '../../constants/AppConstants';
import BackButton from '../../ui/common_back_button/BackButton';
import SectionHeader from '../../ui/section_header/SectionHeader';

const GalleryScreen = ({ location }) => {
  const { baseUrl, pictures } = location.state;
  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.GALLERY} />
      <BackButton />
      <div className="main-content-container">
        <div className="row">
          {pictures.map((item, index) => {
            return (
              <div key={`${index}`} className="col-sm-4 mb-3">
                <img
                  src={getUrlWithSpecificRendition(
                    baseUrl,
                    SCREEN_DENSITY_QUALIFIERS.HDPI,
                    item.picture_path
                  )}
                  className="embed-responsive embed-responsive-16by9"
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default GalleryScreen;
