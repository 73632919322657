import React from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import Divider from '../../../common/ui/divider/Divider';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getHeaderComponentUrl } from '../../../utils/accessibility';

const PolicyListItem = ({ onPressItem, item }) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  return (
    <div
      className="mb-3 common-cursor-pointer"
      onClick={() => {
        onPressItem(item);
      }}>
      <div className="policy-date-view-style justify-content-between">
        <div className="policy-title">{item.title}</div>
        <img
          src={getHeaderComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_RIGHT_ARROW,
            ACCESSIBILITY_IDENTIFIERS.COMMON
          )}
          className="policy-icon"
        />
      </div>
      <Divider style="mt-3" />
    </div>
  );
};

export default PolicyListItem;
