import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { LinkedinIcon, TwitterIcon, EmailIcon, FacebookIcon } from 'react-share';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  ERROR_MESSAGES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { GET_ECARD, SEND_BUSINESS_CARD } from '../../../services/ApiUrls';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import {
  selectBaseUrl,
  selectShowEmptyContentView,
  saveProgressLoadingState,
  selectProgressLoadingState,
} from '../../../state/UIState';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import {
  getDeviceScreenDensityQualifierAsPath,
  shareImage,
  showToast,
} from '../../../utils/common';
import { logEvent2 } from '../../../utils/FirebaseAnalyticsUtils';
import { Form, Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import CustomTextInput from '../../../common/ui/custom_text_input/CustomTextInput';
import { selectSingleLineTextSize } from '../../../state/MasterData';
import { apiRequest, handleError } from '../../../services/Service';

const EcardTab = (props) => {
  const { onlyShareButton = false, onPressShare } = props;
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const accessibilityData = useSelector(selectAccessibilityData);
  const baseUrl = useSelector(selectBaseUrl);
  const history = useHistory();
  const [deviceScreenDensityQualifierPath] = useState(getDeviceScreenDensityQualifierAsPath());
  const [showModal, setShowModal] = useState(false);
  const maxSingleLineCharLimit = useSelector(selectSingleLineTextSize);
  const sendEmailFormRef = useRef();
  const dispatch = useDispatch();
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    logEvent2({
      eventType: ANALYTICS_EVENT_TYPES.ECARDS_TAB_VISIT,
      itemId: props.item,
      itemName: ANALYTICS_ITEM_NAMES.ECARDS,
      categoryId: props.item,
      categoryName: props.title,
    });
  }, [props.item, props.title]);

  const emptyListView = () => {
    return (
      <DefaultContentView
        message={NO_DATA_TEXT.NO_DATA_FOUND}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  const onPressItem = (e, item, index) => {
    e.stopPropagation();
    history.push(NAVIGATION_ROUTES.ECARDS_CONTENT, {
      baseUrl: props.data.gallary.base_url,
      cards: props.data.gallary.cards,
      cardIndex: index,
      categoryId: props.item,
      categoryName: props.title,
    });
  };

  const logShareEvent = (item) => {
    logEvent2({
      eventType: ANALYTICS_EVENT_TYPES.E_CARDS_SHARE,
      itemId: item.card_is.toString(),
      itemName: item.picture_path.toString(),
      categoryId: props.item,
      categoryName: props.title,
    });
  };

  const ecardsArray =
    props.data &&
    props.data.gallary &&
    props.data.gallary.cards &&
    props.data.gallary.base_url &&
    props.data.gallary.cards.map((item, index) => {
      return (
        <div
          key={`${index}`}
          className="col-sm-4 mb-2 common-cursor-pointer ecards-image-div"
          onClick={(e) => onPressItem(e, item, index)}>
          <div>
            <img
              src={
                props.data.gallary.base_url + deviceScreenDensityQualifierPath + item.picture_path
              }
              className="embed-responsive embed-responsive-16by9"
            />
          </div>
          <div className="ecards-social-icons">
            {onlyShareButton && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  logShareEvent(item);
                  onPressShare(
                    props.data.gallary.base_url +
                      deviceScreenDensityQualifierPath +
                      item.picture_path
                  );
                }}>
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.ECARDS_SHARE,
                    ACCESSIBILITY_IDENTIFIERS.ECARDS
                  )}
                  className="ecards-download-icon"
                />
              </div>
            )}
            {!onlyShareButton && (
              <LinkedinIcon
                size={32}
                round={true}
                className="mb-1 common-cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  shareCard(
                    'Linkedin',
                    item,
                    props.data.gallary.base_url +
                      deviceScreenDensityQualifierPath +
                      item.picture_path
                  );
                }}
              />
            )}
            {!onlyShareButton && (
              <FacebookIcon
                size={32}
                round={true}
                className="mb-1 common-cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  shareCard(
                    'Facebook',
                    item,
                    props.data.gallary.base_url +
                      deviceScreenDensityQualifierPath +
                      item.picture_path
                  );
                }}
              />
            )}
            {!onlyShareButton && (
              <TwitterIcon
                size={32}
                round={true}
                className="mb-1 common-cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  shareCard(
                    'Twitter',
                    item,
                    props.data.gallary.base_url +
                      deviceScreenDensityQualifierPath +
                      item.picture_path
                  );
                }}
              />
            )}
            {!onlyShareButton && (
              <EmailIcon
                size={32}
                round={true}
                className="mb-1 common-cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  logShareEvent(item);
                  setShowModal(true);
                  setImageUrl(
                    props.data.gallary.base_url +
                      deviceScreenDensityQualifierPath +
                      item.picture_path
                  );
                }}
              />
            )}
            {!onlyShareButton && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  logShareEvent(item);
                  window.open(`${baseUrl}${GET_ECARD}${item.card_is}`, '_parent').focus();
                }}
                className="common-cursor-pointer">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.ECARDS_DOWNLOAD_CARD,
                    ACCESSIBILITY_IDENTIFIERS.ECARDS
                  )}
                  className="ecards-download-icon"
                />
              </div>
            )}
          </div>
        </div>
      );
    });

  const shareCard = (platform, item, url) => {
    logShareEvent(item);
    shareImage(platform, url);
  };

  const sendEmailValidationSchema = yup.object().shape({
    email: yup.string().required(ERROR_MESSAGES.UPDATE_EMAIL).email(ERROR_MESSAGES.INVALID_EMAIL),
    subject: yup.string().required(ERROR_MESSAGES.INVALID_SUBJECT),
  });

  const onPressSubmitHandler = (formValues) => {
    sendEmailApi(formValues);
  };

  const renderModal = () => {
    return (
      <div>
        <div className="modal-header facilities-custom-modal-header d-flex justify-content-center align-items-center">
          <h6 className="modal-title facilities-custom-modal-title w-100 text-center">
            {'Share e-card'}
          </h6>
          <button
            type="button"
            className="close"
            onClick={() => {
              setShowModal(false);
            }}>
            &times;
          </button>
        </div>
        <Formik
          validationSchema={sendEmailValidationSchema}
          onSubmit={onPressSubmitHandler}
          innerRef={sendEmailFormRef}
          initialValues={{
            email: '',
            subject: '',
          }}>
          {(formikProps) => (
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                logEvent2(
                  ANALYTICS_EVENT_TYPES.SHARE_BUSINESS_CARD_EMAIL,
                  '',
                  ANALYTICS_ITEM_NAMES.FACILITY
                );
                if (formikProps.submitCount > 0 && !formikProps.isValid) {
                  const formErrors = Object.values(formikProps.errors);
                  if (formErrors.length > 0) {
                    showToast(formErrors[0]);
                  }
                } else {
                  if (!formikProps.isValid) {
                    showToast(ERROR_MESSAGES.FORM_ERRORS);
                  }
                  formikProps.handleSubmit();
                }
              }}>
              <div>
                <div className="d-flex align-items-center w-100 px-3 mt-3">
                  <h6 className="text-center mr-5">{'To'}</h6>
                  <div className="w-100 ml-1">
                    <CustomTextInput
                      name="email"
                      isTitle={false}
                      maxLength={maxSingleLineCharLimit}
                      style="facilities-text-input w-100"
                      placeholder="Enter email"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      errorMessage={formikProps.errors.email}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center w-100 px-3">
                  <h6 className="text-center mr-3">{'Subject'}</h6>
                  <div className="w-100">
                    <CustomTextInput
                      name="subject"
                      isTitle={false}
                      maxLength={maxSingleLineCharLimit}
                      style="facilities-text-input w-100"
                      placeholder="Enter subject"
                      onChange={formikProps.handleChange}
                      errorMessage={formikProps.errors.subject}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center w-100 px-3">
                  <h6 className="text-center mr-4">{'Body'}</h6>
                  <div className="w-100 facilities-image-input ml-2">
                    <img
                      src={imageUrl}
                      className="facilities-business-card-image px-2 py-1 h-100"
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-end m-3">
                  <button
                    type="button"
                    className="common-custom-button-senary py-2 px-3 text-capitalize"
                    onClick={() => {
                      setShowModal(false);
                    }}>
                    {'Cancel'}
                  </button>
                  <button
                    type="submit"
                    className="common-custom-button-primary py-2 px-3"
                    disabled={progressLoadingState.isProgressLoading}>
                    {'Send'}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  const sendEmailApi = useCallback(
    async (formValues) => {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const formData = new URLSearchParams(formValues);
      formData.append('type', '_E-CARD');
      formData.append('image_path', imageUrl);
      try {
        const apiResponse = await apiRequest(SEND_BUSINESS_CARD, REQUEST_TYPE.POST, formData);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.message) {
              setShowModal(false);
              showToast(apiResponse.response.message);
            }
          }
        }
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      } catch (err) {
        handleError(err, formData, SEND_BUSINESS_CARD, NAVIGATION_ROUTES.ECARDS);
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    },
    [dispatch, imageUrl]
  );

  return (
    <div>
      {showEmptyContentView && ecardsArray && !ecardsArray.length && !props.loading ? (
        emptyListView()
      ) : (
        <div>
          {props.data && props.data.message && (
            <div className="ecards-message-text mb-3">{props.data && props.data.message}</div>
          )}
          <div className="row">{ecardsArray}</div>
        </div>
      )}
      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowModal(false)}>
        <Modal.Body>{renderModal()}</Modal.Body>
      </Modal>
    </div>
  );
};

export default EcardTab;
