import React from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import Divider from '../../../common/ui/divider/Divider';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getHeaderComponentUrl } from '../../../utils/accessibility';

const AllocatePointPeerListItem = ({ name, onPressPlus, onPressMinus, points }) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  return (
    <div className="mt-2 mb-2">
      <div className="cheers-date-view-style">
        <div className="col-sm-6 text-center cheers-allocate-point-name">{name}</div>
        <div className="col-sm-6 cheers-team-micro-view">
          {points > 100 ? (
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_SUBTRACT_POINT,
                ACCESSIBILITY_IDENTIFIERS.CHEERS
              )}
              className="cheers-normal-icon"
              onClick={() => onPressMinus()}
            />
          ) : (
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_SUBTRACT_POINT_DISABLED,
                ACCESSIBILITY_IDENTIFIERS.CHEERS
              )}
              className="cheers-normal-icon"
            />
          )}
          <div className="ml-3 mr-3">{points}</div>
          {points < 1000 ? (
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_ADD_POINT,
                ACCESSIBILITY_IDENTIFIERS.CHEERS
              )}
              className="cheers-normal-icon"
              onClick={() => onPressPlus()}
            />
          ) : (
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_ADD_POINT_DISABLED,
                ACCESSIBILITY_IDENTIFIERS.CHEERS
              )}
              className="cheers-normal-icon"
            />
          )}
        </div>
      </div>
      <Divider style="mt-1 mb-1" />
    </div>
  );
};

export default AllocatePointPeerListItem;
