import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Range } from 'rc-slider';
import Select from 'react-select';
import 'rc-slider/assets/index.css';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  API_PARAMS,
  FILTER_ANALYTICS_PREFIXES,
  MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import MultiSelectButton from '../../../common/ui/filter/components/MultiSelectButton';
import { GET_IJP_FILTERS } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { saveProgressLoadingState } from '../../../state/UIState';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import {
  saveIjprFormattedSelectedFiltersData,
  saveIjprSelectedFilterData,
  selectIjprSelectedFilterData,
} from '../../../state/IjprData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';

const IjprFilter = (props) => {
  const { onClickApply } = props;
  const dispatch = useDispatch();
  const location = useLocation();
  const accessibilityData = useSelector(selectAccessibilityData);
  const selectedFilterDataRedux = useSelector(selectIjprSelectedFilterData);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [ijprFilterListingData, setIjprFilterListingData] = useState({});
  const [selectedFilterTitles, setSelectedFilterTitles] = useState([]);
  const [geographiesSelected, setGeographiesSelected] = useState(
    selectedFilterDataRedux.geography_id ? selectedFilterDataRedux.geography_id : []
  );
  const [locationsSelected, setLocationsSelected] = useState(
    selectedFilterDataRedux.location_id ? selectedFilterDataRedux.location_id : []
  );
  const [layerPickerValue, setLayerPickerValue] = useState(
    selectedFilterDataRedux.layers ? selectedFilterDataRedux.layers : []
  );
  const [layerPickerItems, setLayerPickerItems] = useState([]);
  const [designationPickerValue, setDesignationPickerValue] = useState(
    selectedFilterDataRedux.designations ? selectedFilterDataRedux.designations : []
  );
  const [designationPickerItems, setDesignationPickerItems] = useState([]);
  const [BUPickerValue, setBUPickerValue] = useState(
    selectedFilterDataRedux.business_units ? selectedFilterDataRedux.business_units : []
  );
  const [BUPickerItems, setBUPickerItems] = useState([]);
  const [departmentPickerValue, setDepartmentPickerValue] = useState(
    selectedFilterDataRedux.departments ? selectedFilterDataRedux.departments : []
  );
  const [departmentPickerItems, setDepartmentPickerItems] = useState([]);
  const [experienceValue, setExperienceValue] = useState([
    selectedFilterDataRedux.min_exp ? Number(selectedFilterDataRedux.min_exp) : 0,
    selectedFilterDataRedux.max_exp ? Number(selectedFilterDataRedux.max_exp) : 25,
  ]);
  const highLightedButtonStyle = 'filter-selected-button';
  const highLightedTextStyle = 'filter-selected-text';
  const unhighLightedButtonStyle = 'filter-unselected-button';
  const unhighLightedTextStyle = 'filter-unselected-text';

  const callIjprFilterListingApi = useCallback(async () => {
    dispatch(saveProgressLoadingState({ isProgressLoading: true }));
    try {
      const apiResponse = await apiRequest(GET_IJP_FILTERS, REQUEST_TYPE.GET, {});
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          const data = apiResponse.response.filters;
          setIjprFilterListingData(data);
          setLayerPickerItems(data.layers);
          setDesignationPickerItems(data.designations);
          setBUPickerItems(data.business_units);
          setDepartmentPickerItems(data.departments);
        }
      }
    } catch (err) {
      handleError(err, {}, GET_IJP_FILTERS, NAVIGATION_ROUTES.IJPR);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch]);

  const renderScreen = useCallback(async () => {
    callIjprFilterListingApi();
  }, [callIjprFilterListingApi]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const clearAllFilters = useCallback(() => {
    dispatch(saveIjprSelectedFilterData({}));
    dispatch(saveIjprFormattedSelectedFiltersData({}));
    setGeographiesSelected([]);
    setLocationsSelected([]);
    setLayerPickerValue([]);
    setDesignationPickerValue([]);
    setBUPickerValue([]);
    setDepartmentPickerValue([]);
    setExperienceValue([
      ijprFilterListingData.experience.minimum,
      ijprFilterListingData.experience.maximum,
    ]);
  }, [dispatch, ijprFilterListingData]);

  const onPressClearFilterHandler = () => {
    clearAllFilters();
    onClickApply({});
    setIsFilterVisible(false);
  };

  useEffect(() => {
    if (
      location.pathname === NAVIGATION_ROUTES.IJPR &&
      Object.keys(ijprFilterListingData) &&
      Object.keys(ijprFilterListingData).length
    ) {
      clearAllFilters();
    }
  }, [clearAllFilters, ijprFilterListingData, location.pathname]);

  const isMinExperienceValueChanged = () => {
    const experienceMinValue =
      ijprFilterListingData &&
      ijprFilterListingData.experience &&
      ijprFilterListingData.experience.minimum
        ? ijprFilterListingData.experience.minimum
        : 0;
    return experienceValue[0] !== experienceMinValue;
  };

  const isMaxExperienceValueChanged = () => {
    const experienceMaxValue =
      ijprFilterListingData &&
      ijprFilterListingData.experience &&
      ijprFilterListingData.experience.maximum
        ? ijprFilterListingData.experience.maximum
        : 25;
    return experienceValue[1] !== experienceMaxValue;
  };

  const isButtonDisabled = () => {
    if (
      geographiesSelected.length ||
      locationsSelected.length ||
      layerPickerValue.length ||
      designationPickerValue.length ||
      BUPickerValue.length ||
      departmentPickerValue.length ||
      isMinExperienceValueChanged() ||
      isMaxExperienceValueChanged()
    ) {
      return false;
    }
    return true;
  };

  const generateSelectedFiltersPayload = () => {
    dispatch(
      saveIjprSelectedFilterData({
        geography_id: geographiesSelected,
        location_id: locationsSelected,
        layers: layerPickerValue,
        designations: designationPickerValue,
        business_units: BUPickerValue,
        departments: departmentPickerValue,
        min_exp: [experienceValue[0].toFixed(1)],
        max_exp: [experienceValue[1].toFixed(1)],
      })
    );
    let selectedFilters = {};
    selectedFilters.geography_id = geographiesSelected;
    selectedFilters.location_id = locationsSelected;
    selectedFilters.layers = layerPickerValue.map((dataNode) => dataNode.value);
    selectedFilters.designations = designationPickerValue.map((dataNode) => dataNode.value);
    selectedFilters.business_units = BUPickerValue.map((dataNode) => dataNode.value);
    selectedFilters.departments = departmentPickerValue.map((dataNode) => dataNode.value);
    selectedFilters.min_exp = [experienceValue[0].toFixed(1)];
    selectedFilters.max_exp = [experienceValue[1].toFixed(1)];
    dispatch(saveIjprFormattedSelectedFiltersData(selectedFilters));
    return selectedFilters;
  };

  const generateSelectedFilterTitlePayload = () => {
    let allFiltersApplied = [
      ...geographiesSelected,
      ...locationsSelected,
      ...layerPickerValue,
      ...designationPickerValue,
      ...BUPickerValue,
      ...departmentPickerValue,
    ];
    if (isMinExperienceValueChanged() || isMaxExperienceValueChanged()) {
      allFiltersApplied.push(
        `${experienceValue[0].toFixed(1)} to ${experienceValue[1].toFixed(1)} years expperience`
      );
    }
    return allFiltersApplied;
  };

  const onLayerValueChange = (value) => {
    setLayerPickerValue(value);
  };
  const onDesignationValueChange = (value) => {
    setDesignationPickerValue(value);
  };
  const onBUValueChange = (value) => {
    setBUPickerValue(value);
  };
  const onDepartmentValueChange = (value) => {
    setDepartmentPickerValue(value);
  };

  const onClickFilterButtonHandler = () => {
    logEvent(ANALYTICS_EVENT_TYPES.IJP_FILTER_CLICK, '', ANALYTICS_ITEM_NAMES.IJPR);
    setIsFilterVisible((val) => !val);
  };

  const renderFilterTitle = () => {
    const selectedFilterTitles = generateSelectedFilterTitlePayload();
    if (selectedFilterTitles.length === 1) {
      return '1 filter applied';
    } else if (selectedFilterTitles.length > 1) {
      return `${selectedFilterTitles.length} filters applied`;
    } else {
      return MESSAGES.NO_FILTERS;
    }
  };

  return (
    <div>
      <div className="row">
        <div className={'col-8 col-sm-6'}>
          <p className="applied-filter-text">{renderFilterTitle()}</p>
        </div>
        <div
          onClick={onClickFilterButtonHandler}
          className="col-4 col-sm-6 d-flex flex-row-reverse">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_FILTER,
              ACCESSIBILITY_IDENTIFIERS.COMMON
            )}
            className="filter-icon"
          />
        </div>
      </div>
      {isFilterVisible && (
        <div className="mb-4">
          {ijprFilterListingData && !!Object.keys(ijprFilterListingData).length && (
            <>
              <div>
                <h5 className="ijpr-filter-title">Geography</h5>
                <div>
                  <div className="d-flex flex-row flex-wrap">
                    {ijprFilterListingData.geographies.map((geography, index) => {
                      return (
                        <MultiSelectButton
                          key={index}
                          filterAnalyticsPrefix={FILTER_ANALYTICS_PREFIXES.GEOGRAPHY}
                          selectedItems={geographiesSelected}
                          onSelectHandler={setGeographiesSelected}
                          childSelectedItems={locationsSelected}
                          childSelectedItemsHandler={setLocationsSelected}
                          childLookup={ijprFilterListingData.locations}
                          childLookupTag={API_PARAMS.EMPLOYEES.LOCATION_ID}
                          childLookupParentTag={API_PARAMS.EMPLOYEES.GEOGRAPHY_ID}
                          selectedTitles={selectedFilterTitles}
                          filterTitlesHandler={setSelectedFilterTitles}
                          valueTitle={geography.title}
                          valueId={geography.geography_id}
                          highLightedButtonStyle={highLightedButtonStyle}
                          highLightedTextStyle={highLightedTextStyle}
                          unhighLightedButtonStyle={unhighLightedButtonStyle}
                          unhighLightedTextStyle={unhighLightedTextStyle}
                        />
                      );
                    })}
                  </div>
                </div>
                {!!geographiesSelected.length && (
                  <div>
                    <h5 className="ijpr-filter-title">Location</h5>
                    <div className="d-flex flex-row flex-wrap">
                      {ijprFilterListingData.locations.map((location, index) => {
                        if (geographiesSelected.includes(location.geography_id)) {
                          return (
                            <MultiSelectButton
                              key={index}
                              filterAnalyticsPrefix={FILTER_ANALYTICS_PREFIXES.LOCATION}
                              selectedItems={locationsSelected}
                              onSelectHandler={setLocationsSelected}
                              selectedTitles={selectedFilterTitles}
                              filterTitlesHandler={setSelectedFilterTitles}
                              valueTitle={location.title}
                              valueId={location.location_id}
                              highLightedButtonStyle={highLightedButtonStyle}
                              highLightedTextStyle={highLightedTextStyle}
                              unhighLightedButtonStyle={unhighLightedButtonStyle}
                              unhighLightedTextStyle={unhighLightedTextStyle}
                            />
                          );
                        }
                      })}
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 pb-1 mb-3">
                    <h5 className="ijpr-filter-title">Sub-band</h5>
                    <Select
                      closeMenuOnSelect={false}
                      isMulti={true}
                      isSearchable={true}
                      placeholder="Select Sub-bands"
                      value={layerPickerValue}
                      onChange={onLayerValueChange}
                      options={layerPickerItems.map((layerNode) => {
                        return {
                          label: layerNode.title,
                          value: layerNode.code,
                        };
                      })}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 pb-1 mb-3">
                    <h5 className="ijpr-filter-title ">Designation</h5>
                    <Select
                      closeMenuOnSelect={false}
                      isMulti={true}
                      isSearchable={true}
                      placeholder="Select Designation"
                      value={designationPickerValue}
                      defaultValue={designationPickerValue}
                      onChange={onDesignationValueChange}
                      options={designationPickerItems.map((designationNode) => {
                        return {
                          label: designationNode.title,
                          value: designationNode.code,
                        };
                      })}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 pb-1 mb-3">
                    <h5 className="ijpr-filter-title ">Business Unit</h5>
                    <Select
                      closeMenuOnSelect={false}
                      isMulti={true}
                      isSearchable={true}
                      placeholder="Select Business Unit"
                      value={BUPickerValue}
                      onChange={onBUValueChange}
                      options={BUPickerItems.map((BUNode) => {
                        return {
                          label: BUNode.title,
                          value: BUNode.code,
                        };
                      })}
                    />
                  </div>
                  <div className="col-12 col-sm-6 col-md-4 col-lg-3 pb-1 mb-3">
                    <h5 className="ijpr-filter-title ">Department</h5>
                    <Select
                      closeMenuOnSelect={false}
                      isMulti={true}
                      isSearchable={true}
                      placeholder="Select Department"
                      value={departmentPickerValue}
                      onChange={onDepartmentValueChange}
                      options={departmentPickerItems.map((departmentNode) => {
                        return {
                          label: departmentNode.title,
                          value: departmentNode.code,
                        };
                      })}
                    />
                  </div>
                  <div className="col-12 col-sm-12 pb-1 mb-3">
                    <h5 className="ijpr-filter-title ">Experience</h5>
                    <div className="d-flex justify-content-between align-items-center">
                      <h6 className="pr-3 pt-2 ijpr-filter-range-marker">{experienceValue[0]}</h6>
                      <Range
                        min={ijprFilterListingData.experience.minimum}
                        max={ijprFilterListingData.experience.maximum}
                        step={0.1}
                        value={experienceValue}
                        onChange={(val) => {
                          setExperienceValue(val);
                        }}
                      />
                      <div className="pl-3 pt-2 ijpr-filter-range-marker text-right">
                        {experienceValue[1]}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <CustomButton
                  buttonMainContainer="mr-3"
                  disabled={isButtonDisabled()}
                  onClick={() => {
                    logEvent(
                      ANALYTICS_EVENT_TYPES.IJP_FILTER_APPLY_CLICK,
                      '',
                      ANALYTICS_ITEM_NAMES.IJPR
                    );
                    const selectedFiltersPayload = generateSelectedFiltersPayload();
                    onClickApply(selectedFiltersPayload);
                    setIsFilterVisible(false);
                  }}>
                  Apply
                </CustomButton>
                <CustomButton onClick={onPressClearFilterHandler} disabled={isButtonDisabled()}>
                  Clear
                </CustomButton>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default IjprFilter;
