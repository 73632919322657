import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { Form, Modal } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import * as yup from 'yup';
import 'react-phone-input-2/lib/style.css';

import {
  ERROR_MESSAGES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  ANALYTICS_SCREEN_NAMES,
  CUSTOM_VALIDATION_TEST_NAMES,
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  DEFAULT_REGION_CODE,
  DEFAULT_ISD_CODE,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  WARNING_MESSAGES,
  SUPPORTED_DOCUMENT_FORMAT_PDF,
  ENCODING_TYPE,
} from '../../../common/constants/AppConstants';
import { convertBase64, showToast } from '../../../utils/common';
import { apiRequest, handleError } from '../../../services/Service';
import { REFER_FOR_IJP, UPLOAD_MEDIA } from '../../../services/ApiUrls';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import {
  selectIjprConfigData,
  selectMaxFileUploadSizeConfig,
  selectMultilineTextSize,
} from '../../../state/MasterData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../state/UIState';
import { logEvent, trackScreen } from '../../../utils/FirebaseAnalyticsUtils';
import { isValidLinkedinSocialUrl } from '../../../utils/formValidation';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import CustomTextInput from '../../../common/ui/custom_text_input/CustomTextInput';
import { store } from '../../../config/store';

const IjpReferFormDialog = (props) => {
  const {
    jobCode,
    isIjpReferFormDialogVisible,
    setIsIjpReferFormDialogDialogVisible,
    setIsIjprAppliedDialogVisible,
  } = props;
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);
  const ijprConfigData = useSelector(selectIjprConfigData);
  const maxMultiLineCharLimit = useSelector(selectMultilineTextSize);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const ijpReferFormRef = useRef();
  const maxFileUploadSizeInMB = useSelector(selectMaxFileUploadSizeConfig);

  useEffect(() => {
    // Analytics
    trackScreen(ANALYTICS_SCREEN_NAMES.IJP_REFER, NAVIGATION_ROUTES.IJP_REFER);
  }, []);

  const callReferForIjpApi = useCallback(
    async (formValues) => {
      let params = new URLSearchParams(formValues);
      params.append('is_base64', 'no');
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
            progressMessage: LOADING_MESSAGES.REFER_FOR_JOB,
          })
        );
        const apiResponse = await apiRequest(REFER_FOR_IJP, REQUEST_TYPE.POST, params);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            const message = apiResponse.response.message;
            if (message) {
              setIsIjpReferFormDialogDialogVisible(false);
              setIsIjprAppliedDialogVisible(true);
            }
          }
        }
      } catch (err) {
        handleError(params, REFER_FOR_IJP, NAVIGATION_ROUTES.IJPR);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    },
    [dispatch, setIsIjpReferFormDialogDialogVisible, setIsIjprAppliedDialogVisible]
  );

  const onChangeUploadResume = async (event) => {
    const uploadedFile = event.target.files[0];
    if (Number(uploadedFile.size) / (1024 * 1024) >= maxFileUploadSizeInMB) {
      showToast(WARNING_MESSAGES.FILE_SIZE_EXCEEDED(maxFileUploadSizeInMB));
    } else {
      return new Promise((resolve, reject) => {
        const upload = async () => {
          const formData = new FormData();
          formData.append('file_type', 'document');
          formData.append('feature', 'ijpr');
          formData.append('file', event.target.files[0]);
          try {
            dispatch(
              saveProgressLoadingState({
                isProgressLoading: true,
              })
            );
            const apiResponse = await apiRequest(
              UPLOAD_MEDIA,
              REQUEST_TYPE.POST,
              formData,
              ENCODING_TYPE.FORM_DATA
            );
            if (apiResponse.response?.status) {
              resolve(apiResponse.response.data);
              ijpReferFormRef.current.setFieldValue('resume_names', uploadedFile.name);
              ijpReferFormRef.current.setFieldValue('resumes', apiResponse.response.data.fileName);
            } else {
              store.dispatch(
                saveProgressLoadingState({
                  isProgressLoading: false,
                })
              );
            }
            dispatch(
              saveProgressLoadingState({
                isProgressLoading: false,
              })
            );
          } catch (e) {
            dispatch(
              saveProgressLoadingState({
                isProgressLoading: false,
              })
            );
            showToast('Something went wrong');
            reject({ message: e.message ?? 'Something went wrong' });
          }
        };
        upload();
      });
    }
  };

  const onPressSubmitHandler = (formValues) => {
    callReferForIjpApi(formValues);
  };

  const onPressPhoneIsdHandler = (country) => {
    ijpReferFormRef.current.setFieldValue('mobile_region_code', country.countryCode);
    ijpReferFormRef.current.setFieldValue('mobile_isd_code', country.dialCode);
  };

  const onPressRemoveResume = () => {
    ijpReferFormRef.current.setFieldValue('resume_names', '');
    ijpReferFormRef.current.setFieldValue('resumes', '');
  };

  const onPressUploadResume = (event) => {
    event.target.value = null;
  };

  const ijpReferValidationSchema = yup.object().shape({
    resume_names: yup.string().required(ERROR_MESSAGES.UPDATE_RESUME),
    resumes: yup.string().required(ERROR_MESSAGES.UPDATE_RESUME),
    first_name: yup
      .string()
      .required(ERROR_MESSAGES.UPDATE_FIRST_NAME)
      .max(maxMultiLineCharLimit, ERROR_MESSAGES.FIRST_NAME_CHAR_LIMIT_EXCEEDED),
    last_name: yup
      .string()
      .max(maxMultiLineCharLimit, ERROR_MESSAGES.LAST_NAME_CHAR_LIMIT_EXCEEDED),
    email: yup.string().required(ERROR_MESSAGES.UPDATE_EMAIL).email(ERROR_MESSAGES.INVALID_EMAIL),
    mobile_no: yup
      .string()
      .required(ERROR_MESSAGES.UPDATE_PHONE)
      .max(16, ERROR_MESSAGES.INVALID_PHONE_NUMBER)
      .min(6, ERROR_MESSAGES.INVALID_PHONE_NUMBER),
    employer_name: yup
      .string()
      .max(maxMultiLineCharLimit, ERROR_MESSAGES.EMPLOYER_NAME_CHAR_LIMIT_EXCEEDED),
    linkedin_url: yup
      .string()
      .url(ERROR_MESSAGES.INVALID_LINKEDIN_LINK)
      .test(
        CUSTOM_VALIDATION_TEST_NAMES.IS_VALID_LINKEDIN_SOCIAL_URL,
        ERROR_MESSAGES.INVALID_LINKEDIN_LINK,
        (val) => {
          if (val === undefined) {
            return true;
          }
          return isValidLinkedinSocialUrl(val);
        }
      ),
    candidate_location: yup
      .string()
      .max(maxMultiLineCharLimit, ERROR_MESSAGES.CANDIDATE_LOCATION_CHAR_LIMIT_EXCEEDED),
    relation_description: yup.string().required(ERROR_MESSAGES.UPDATE_RELATION_DESCRIPTION),
    relation_tenure: yup.string().required(ERROR_MESSAGES.UPDATE_RELATION_TENURE),
  });

  return (
    <Modal
      onHide={() => setIsIjpReferFormDialogDialogVisible((val) => !val)}
      show={isIjpReferFormDialogVisible}
      centered
      size="lg"
      backdrop="static"
      keyboard={false}>
      <Modal.Header className="d-flex align-items-center py-3" closeButton>
        <Modal.Title>Please fill candidate’s details below</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <div>
          <Formik
            validationSchema={ijpReferValidationSchema}
            onSubmit={onPressSubmitHandler}
            validateOnMount={true}
            innerRef={ijpReferFormRef}
            initialValues={{
              job_code: jobCode,
              resume_names: '',
              resumes: '',
              mobile_region_code: DEFAULT_REGION_CODE,
              mobile_isd_code: DEFAULT_ISD_CODE,
              first_name: '',
              last_name: '',
              email: '',
              mobile_no: '',
              employer_name: '',
              linkedin_url: '',
              candidate_location: '',
              relation_description: '',
              relation_tenure: ijprConfigData.relation_tenure_data[0].value,
              time_to_contact: ijprConfigData.time_to_contact_data[0].value,
              time_zone: ijprConfigData.time_zone_data[0].value,
            }}>
            {(formikProps) => (
              <Form
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  logEvent(
                    ANALYTICS_EVENT_TYPES.IJP_REFERRALS_FORM_SUBMIT_CLICK,
                    jobCode.toString(),
                    ANALYTICS_ITEM_NAMES.IJPR
                  );
                  if (formikProps.submitCount > 0 && !formikProps.isValid) {
                    const formErrors = Object.values(formikProps.errors);
                    if (formErrors.length > 0) {
                      showToast(formErrors[0]);
                    }
                  } else {
                    if (!formikProps.isValid) {
                      showToast(ERROR_MESSAGES.FORM_ERRORS);
                    }
                    formikProps.handleSubmit();
                  }
                }}>
                <div>
                  <div>
                    <label>
                      Resume/CV<span className="my-profile-required"> (required)</span>
                    </label>
                    <div className="d-flex align-items-center mb-3">
                      <Form.File
                        label="Supported upload format: 3mb max in .pdf"
                        lang="en"
                        accept={SUPPORTED_DOCUMENT_FORMAT_PDF}
                        custom
                        onClick={onPressUploadResume}
                        onChange={onChangeUploadResume}
                      />
                    </div>
                    {!!formikProps.values.resume_names && !!formikProps.values.resumes && (
                      <div className="d-flex align-items-center justify-content-between mb-3 p-2 ijpr-refer-form-dialog-uploaded-item-container">
                        <div className="d-flex align-items-center">
                          <img
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_DOCUMENT,
                              ACCESSIBILITY_IDENTIFIERS.COMMON
                            )}
                            className="ijpr-refer-form-dialog-doc-icon mr-2"
                          />
                          <p className="ijpr-refer-form-dialog-uploaded-item-title mb-0">
                            {formikProps.values.resume_names}
                          </p>
                        </div>
                        <div onClick={onPressRemoveResume}>
                          <p className="ijpr-refer-form-dialog-uploaded-item-remove-text mb-0 common-cursor-pointer">
                            Remove
                          </p>
                        </div>
                      </div>
                    )}
                    <CustomTextInput
                      required={true}
                      name="first_name"
                      title="First Name"
                      placeholder="E.g., Jane"
                      errorMessage={formikProps.errors.first_name}
                      value={formikProps.values.first_name}
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      isValid={
                        !!formikProps.submitCount &&
                        formikProps.touched.first_name &&
                        !formikProps.errors.first_name
                      }
                      isInvalid={
                        !!formikProps.submitCount &&
                        formikProps.touched.first_name &&
                        !!formikProps.errors.first_name
                      }
                    />
                    <CustomTextInput
                      name="last_name"
                      title="Last Name"
                      placeholder="E.g., Doe"
                      errorMessage={formikProps.errors.last_name}
                      value={formikProps.values.last_name}
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      isValid={
                        !!formikProps.submitCount &&
                        formikProps.touched.last_name &&
                        !formikProps.errors.last_name
                      }
                      isInvalid={
                        !!formikProps.submitCount &&
                        formikProps.touched.last_name &&
                        !!formikProps.errors.last_name
                      }
                    />
                    <CustomTextInput
                      required={true}
                      name="email"
                      title="Email ID"
                      placeholder="E.g., janedoe@mail.com"
                      errorMessage={formikProps.errors.email}
                      value={formikProps.values.email}
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      isValid={
                        !!formikProps.submitCount &&
                        formikProps.touched.email &&
                        !formikProps.errors.email
                      }
                      isInvalid={
                        !!formikProps.submitCount &&
                        formikProps.touched.email &&
                        !!formikProps.errors.email
                      }
                    />
                    <div>
                      <label>
                        Phone Number<span className="my-profile-required"> (required)</span>
                      </label>

                      <div className="d-flex">
                        <div className="my-profile-editable-phone-input-container my-profile-editable-phone-code mr-3">
                          <PhoneInput
                            enableSearch={true}
                            countryCodeEditable={false}
                            country={formikProps.values.mobile_region_code}
                            value={formikProps.values.mobile_isd_code}
                            onChange={(dialCode, country) => {
                              onPressPhoneIsdHandler(country);
                            }}
                          />
                        </div>

                        <CustomTextInput
                          required={true}
                          name="mobile_no"
                          placeholder="E.g., 9999999999"
                          isTitle={false}
                          errorMessage={formikProps.errors.mobile_no}
                          value={formikProps.values.mobile_no}
                          type="tel"
                          style="my-profile-edit-editable-text-input"
                          formGroupClassName="flex-fill"
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                          isValid={
                            !!formikProps.submitCount &&
                            formikProps.touched.mobile_no &&
                            !formikProps.errors.mobile_no
                          }
                          isInvalid={
                            !!formikProps.submitCount &&
                            formikProps.touched.mobile_no &&
                            !!formikProps.errors.mobile_no
                          }
                        />
                      </div>
                    </div>
                    <CustomTextInput
                      name="employer_name"
                      title="Employer Name"
                      placeholder="E.g., Microsoft"
                      errorMessage={formikProps.errors.employer_name}
                      value={formikProps.values.employer_name}
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      isValid={
                        !!formikProps.submitCount &&
                        formikProps.touched.employer_name &&
                        !formikProps.errors.employer_name
                      }
                      isInvalid={
                        !!formikProps.submitCount &&
                        formikProps.touched.employer_name &&
                        !!formikProps.errors.employer_name
                      }
                    />
                    <CustomTextInput
                      name="linkedin_url"
                      title="LinkedIn URL"
                      placeholder="https://www.linkedin.com/in/xyz"
                      errorMessage={formikProps.errors.linkedin_url}
                      value={formikProps.values.linkedin_url}
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      isValid={
                        !!formikProps.submitCount &&
                        formikProps.touched.linkedin_url &&
                        !formikProps.errors.linkedin_url
                      }
                      isInvalid={
                        !!formikProps.submitCount &&
                        formikProps.touched.linkedin_url &&
                        !!formikProps.errors.linkedin_url
                      }
                    />
                    <CustomTextInput
                      name="candidate_location"
                      title="Candidate Location"
                      placeholder="E.g., Bangalore"
                      errorMessage={formikProps.errors.candidate_location}
                      value={formikProps.values.candidate_location}
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      isValid={
                        !!formikProps.submitCount &&
                        formikProps.touched.candidate_location &&
                        !formikProps.errors.candidate_location
                      }
                      isInvalid={
                        !!formikProps.submitCount &&
                        formikProps.touched.candidate_location &&
                        !!formikProps.errors.candidate_location
                      }
                    />
                    <CustomTextInput
                      required={true}
                      name="relation_description"
                      title="How do you know this candidate?"
                      placeholder="E.g., Friend/ Family/ Colleague"
                      errorMessage={formikProps.errors.relation_description}
                      value={formikProps.values.relation_description}
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      isValid={
                        !!formikProps.submitCount &&
                        formikProps.touched.relation_description &&
                        !formikProps.errors.relation_description
                      }
                      isInvalid={
                        !!formikProps.submitCount &&
                        formikProps.touched.relation_description &&
                        !!formikProps.errors.relation_description
                      }
                    />
                    <CustomTextInput
                      required={true}
                      name="relation_tenure"
                      title="Since how long have you known this candidate?"
                      as="select"
                      custom={true}
                      errorMessage={formikProps.errors.relation_tenure}
                      value={formikProps.values.relation_tenure}
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      style="my-profile-edit-editable-text-input"
                      isValid={
                        !!formikProps.submitCount &&
                        formikProps.touched.relation_tenure &&
                        !formikProps.errors.relation_tenure
                      }
                      isInvalid={
                        !!formikProps.submitCount &&
                        formikProps.touched.relation_tenure &&
                        !!formikProps.errors.relation_tenure
                      }>
                      {ijprConfigData.relation_tenure_data.map((dataNode, index) => (
                        <option value={dataNode.value} key={index}>
                          {dataNode.label}
                        </option>
                      ))}
                    </CustomTextInput>

                    <h6>Ideal time to contact the candidate:</h6>
                    <CustomTextInput
                      name="time_zone"
                      title="Time zone"
                      as="select"
                      custom={true}
                      errorMessage={formikProps.errors.time_zone}
                      value={formikProps.values.time_zone}
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      style="my-profile-edit-editable-text-input"
                      isValid={
                        !!formikProps.submitCount &&
                        formikProps.touched.time_zone &&
                        !formikProps.errors.time_zone
                      }
                      isInvalid={
                        !!formikProps.submitCount &&
                        formikProps.touched.time_zone &&
                        !!formikProps.errors.time_zone
                      }>
                      {ijprConfigData.time_zone_data.map((dataNode, index) => (
                        <option value={dataNode.value} key={index}>
                          {dataNode.label}
                        </option>
                      ))}
                    </CustomTextInput>
                    <CustomTextInput
                      name="time_to_contact"
                      title="Time"
                      as="select"
                      custom={true}
                      errorMessage={formikProps.errors.time_to_contact}
                      value={formikProps.values.time_to_contact}
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      style="my-profile-edit-editable-text-input"
                      isValid={
                        !!formikProps.submitCount &&
                        formikProps.touched.time_to_contact &&
                        !formikProps.errors.time_to_contact
                      }
                      isInvalid={
                        !!formikProps.submitCount &&
                        formikProps.touched.time_to_contact &&
                        !!formikProps.errors.time_to_contact
                      }>
                      {ijprConfigData.time_to_contact_data.map((dataNode, index) => (
                        <option value={dataNode.value} key={index}>
                          {dataNode.label}
                        </option>
                      ))}
                    </CustomTextInput>
                  </div>
                  <div>
                    <CustomButton
                      disabled={progressLoadingState.isProgressLoading}
                      type="submit"
                      buttonMainContainer="d-flex justify-content-end mt-4">
                      SUBMIT
                    </CustomButton>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default IjpReferFormDialog;
