import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { DI_GALLERY } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectShowEmptyContentView } from '../../../state/UIState';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import GalleryImageView from '../../gallery/components/GalleryImageView';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';

const DIGalleryTab = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [loadMoreFlag, setLoadMoreFlag] = useState(true);
  const pageId = useRef(1);
  const [gallery, setGallery] = useState([]);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.DI_GALLERY_TAB_VISIT, '', ANALYTICS_ITEM_NAMES.DI);
  }, []);

  const callGalleryApi = useCallback(async () => {
    const params = {
      page_id: pageId.current,
    };
    try {
      setLoading(true);
      const apiResponse = await apiRequest(DI_GALLERY, REQUEST_TYPE.GET, params);
      setLoading(false);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          const data = apiResponse.response.data;
          if (data) {
            if (data.length > 0) {
              setGallery((oldArray) => [...oldArray, ...apiResponse.response.data]);
              setLoadMoreFlag(true);
            } else {
              setLoadMoreFlag(false);
            }
          }
        }
      }
    } catch (err) {
      handleError(err, params, DI_GALLERY, NAVIGATION_ROUTES.DI);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    callGalleryApi();
  }, [callGalleryApi]);

  const onPressMore = (item, baseUrl) => {
    history.push(NAVIGATION_ROUTES.GALLERY_COMMON, {
      baseUrl: baseUrl,
      pictures: item,
    });
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.DI_NO_IMAGES} />;
  };

  const renderItem = (item, index) => {
    return <GalleryImageView key={`${index}`} item={item} onPressMore={onPressMore} />;
  };

  const onPressLoadMore = () => {
    if (loadMoreFlag) {
      pageId.current = pageId.current + 1;
      callGalleryApi();
    }
  };

  return (
    <div className="main-content-container">
      {showEmptyContentView && !gallery.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          <div className="row">{gallery.map((item, index) => renderItem(item, index))}</div>
          <ActivityLoader visible={loading} />
          {loadMoreFlag && <LoadMoreBtn onClick={onPressLoadMore} isLoading={loading} />}
        </div>
      )}
    </div>
  );
};

export default DIGalleryTab;
