import React from 'react';
import { useHistory } from 'react-router';

import { NAVIGATION_ROUTES } from '../../../common/constants/AppConstants';
import { formatRelativeTimeFromNow } from '../../../utils/common';

const PinnedPostListItem = (props) => {
  const { pinnedPostsData } = props;
  const history = useHistory();

  return (
    <div
      className="mb-3 common-cursor-pointer"
      onClick={() => history.push(`${NAVIGATION_ROUTES.POST_DETAIL}/${pinnedPostsData.post_id}`)}>
      <div className="p-3 communities-pinned-item-outer-container">
        <div className="communities-pinned-item-inner-container pl-2">
          <div className="communities-pinned-item-top-text">
            {pinnedPostsData.pinned_by ? `${pinnedPostsData.pinned_by} pinned ` : 'Pinned '}
            {formatRelativeTimeFromNow(pinnedPostsData.pinned_on)}
          </div>
          {!!pinnedPostsData.title && <div>{pinnedPostsData.title}</div>}
        </div>
      </div>
    </div>
  );
};

export default PinnedPostListItem;
