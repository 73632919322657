import React, { useCallback, useEffect, useState } from 'react';

import {
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { saveProgressLoadingState } from '../../../state/UIState';
import { apiRequest, handleError } from '../../../services/Service';
import { GET_WOMENSDAY_COMMITMENT, UPLOAD_BUSINESS_CARD } from '../../../services/ApiUrls';
import { getDevicePixelRatio, getProfanityFilter, showToast } from '../../../utils/common';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import { selectProfinityFilterWarning } from '../../../state/MasterData';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';

const CommitmentTab = (props) => {
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const dispatch = useDispatch();
  const year = props?.year ?? '';
  const [commitmentTexts, setCommitmentTexts] = useState(['', '', '']);
  const [commitment, setCommitment] = useState([]);
  const [isCommited, setIsCommited] = useState(false);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const profinityFilterWarning = useSelector(selectProfinityFilterWarning);

  const pixelRatio = getDevicePixelRatio();

  const commitmentCertificateText = {
    position: 'absolute',
    top: `${130 / pixelRatio}px`,
    left: `${35 / pixelRatio}px`,
    color: '#000000',
    fontSize: `${32 / pixelRatio}px`,
    fontFamily: "'Poppins', sans-serif",
    width: `${1150 / pixelRatio}px`,
    paddingRight: `${10 / pixelRatio}px`,
  };

  const certificateStyle = {
    position: 'absolute',
    height: `${800 / pixelRatio}px`,
    width: `${1200 / pixelRatio}px`,
  };

  const downloadCertificate = async (e) => {
    e.preventDefault();
    document.getElementById('download-cheers-certificate-received').classList.add('d-block');
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.GENERATING_BUSINESS_CARD,
      })
    );
    setTimeout(() => {
      html2canvas(document.getElementById('download-cheers-certificate-received'), {
        height: 800 / pixelRatio,
        width: 1200 / pixelRatio,
        useCORS: true,
        onclone: function (clonedDoc) {
          clonedDoc.getElementById('download-cheers-certificate-received').classList.add('d-block');
        },
      })
        .then((canvas) => {
          document
            .getElementById('download-cheers-certificate-received')
            .classList.remove('d-block');

          let xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = function () {
            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(xhr.response);
            a.download = 'certificate.jpeg';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            a.remove();
          };
          xhr.open('GET', canvas.toDataURL('image/jpeg')); // This is to download the canvas Image
          xhr.send();
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          // commenting out below code as it is not required
          // uploadBusinessCard(canvas.toDataURL(), e);
        })
        .catch(() => {
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        });
    }, 1000);
  };

  const uploadBusinessCard = async (imageUrl, e) => {
    const params = new URLSearchParams();
    params.append('file', imageUrl);
    params.append('type', '_CHEERS_IMAGE');
    try {
      const apiResponse = await apiRequest(UPLOAD_BUSINESS_CARD, REQUEST_TYPE.POST, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status === true) {
          const message = apiResponse.response.message;
          if (message) {
            e.stopPropagation();
            saveAs(
              apiResponse.response.data.business_card.base_url +
                apiResponse.response.data.business_card.image_path,
              'certificate.jpeg'
            );
          }
        }
      }
    } catch (err) {
      handleError(err, params, UPLOAD_BUSINESS_CARD, NAVIGATION_ROUTES.WOMENS_DAY);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const getCommitment = useCallback(async () => {
    const params = {
      year: year,
    };
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(GET_WOMENSDAY_COMMITMENT, REQUEST_TYPE.GET, params);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse?.response?.status === true) {
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (apiResponse?.response?.data) {
            const data = apiResponse?.response?.data?.feeds ?? [];
            setIsCommited(apiResponse?.response?.data?.is_commited);
            if (data.length > 0) {
              setCommitment(data);
            }
          }
        }
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    } catch (err) {
      handleError(err, params, GET_WOMENSDAY_COMMITMENT, NAVIGATION_ROUTES.WOMENS_DAY);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch, year]);

  const postCommitment = useCallback(async () => {
    const formData = new URLSearchParams();
    commitmentTexts.forEach((text) => {
      formData.append('commitment_text', text);
    });
    formData.append('year', year);
    try {
      if (commitmentTexts.some((text) => getProfanityFilter().isProfane(text))) {
        setShowWarningDialog(true);
        return;
      }
      if (commitmentTexts.some((text) => text.trim() === '')) {
        showToast('Commitment text should not be empty');
        return;
      }
      const isTextExceedsLimit = commitmentTexts.some(
        (text) => text.length > props?.config?.max_character_count
      );
      if (isTextExceedsLimit) {
        showToast(
          `Maximum allowed characters per commitment is ${props?.config?.max_character_count}`
        );
        return;
      }
      const isDuplicateText = () => {
        const textSet = new Set();
        for (const text of commitmentTexts) {
          if (textSet.has(text)) {
            return true;
          }
          textSet.add(text);
        }
        return false;
      };

      if (isDuplicateText()) {
        showToast('Each commitment text should be unique');
        return;
      }
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(GET_WOMENSDAY_COMMITMENT, REQUEST_TYPE.POST, formData);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse?.response?.status === true) {
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          setShowSuccessDialog(true);
          getCommitment();
        }
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    } catch (err) {
      handleError(err, formData, GET_WOMENSDAY_COMMITMENT, NAVIGATION_ROUTES.WOMENS_DAY);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [commitmentTexts, dispatch, getCommitment, props?.config?.max_character_count, year]);

  useEffect(() => {
    getCommitment();
  }, [getCommitment]);

  const handleCommitmentTextChange = (index, value) => {
    const updatedTexts = commitmentTexts.map((text, i) => (i === index ? value : text));
    setCommitmentTexts(updatedTexts);
  };

  const renderTextAreas = () => {
    return commitmentTexts.map((text, index) => (
      <div
        key={`${index}`}
        className="d-flex flex-row w-100 align-items-center justify-content-center pt-1">
        <p className="text-area-number-text mr-2 mt-3">{index + 1}.</p>
        <textarea
          name={`description${index}`}
          id={`description${index}`}
          spellCheck="false"
          value={text}
          onChange={(e) => handleCommitmentTextChange(index, e.target.value)}
          className="commitment-text-area"
          maxLength={`${props?.config?.max_character_count}`}
        />
      </div>
    ));
  };

  const successDialog = () => {
    return (
      <div className="feedback-success-dialog d-flex flex-column align-items-center justify-content-center w-100">
        <div className="feedback-success-title-text mt-3">Submitted successfully!</div>
        <div className="feedback-success-subtitle-text mt-1 pt-1">
          Thank you for your commitment to
        </div>
        <div className="feedback-success-subtitle-text">#InspireInclusion</div>
        <div className="d-flex flex-row w-100 align-items-center justify-content-center">
          <div
            onClick={() => {
              setShowSuccessDialog(false);
            }}
            className="commitment-success-modal-confirm d-flex flex-row align-items-center justify-content-center mt-4 align-self-center mb-3 common-cursor-pointer">
            OK
          </div>
        </div>
      </div>
    );
  };

  const warningDialog = () => {
    return (
      <div>
        <div className="common-comment-box-profinity-warning-text text-center imagesReview mt-2 mb-3">
          WARNING!
        </div>
        <div className="common-comment-box-profinity-text text-center imagesReview mt-2 mb-3">
          {profinityFilterWarning}
        </div>
        <div className="text-center">
          <CustomButton
            buttonStyle="common-comment-box-profinity-button mt-2"
            onClick={() => {
              setShowWarningDialog(false);
            }}>
            OK
          </CustomButton>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="d-flex flex-row w-100 align-items-center justify-content-center">
        <div>
          <img
            src={props?.config?.image_base_url + props?.config?.banner_image}
            style={{ height: 200, width: 600 }}
          />
        </div>
      </div>
      <div className="d-flex flex-row w-100 align-items-center justify-content-center mt-4 mb-n1">
        <div
          className={
            isCommited
              ? 'commitment-text-commited-container commitment-text-style'
              : 'commitment-text-style commitment-text-container'
          }>
          I commit to #InspireInclusion through.....
        </div>
      </div>
      {!isCommited ? (
        <>
          {renderTextAreas()}
          <div className="d-flex flex-row w-100 align-items-center justify-content-center">
            <div className="commit-button-container mt-3">
              <div
                className="commit-button-style d-flex flex-row align-items-center justify-content-center common-cursor-pointer"
                onClick={postCommitment}>
                I COMMIT
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex flex-column w-100 align-items-center justify-content-center mt-3">
            {commitment?.map((item, index) => {
              return (
                <p key={`${index}`} style={{ width: 600 }} className={index !== 0 && 'mt-n2'}>{`${
                  index + 1
                }.${' ' + item?.commitment_text}`}</p>
              );
            })}
          </div>
          <div className="d-flex flex-row w-100 align-items-center justify-content-center">
            <div className="commit-download-button-container mt-1">
              <div
                className="commit-download-style d-flex flex-row align-items-center justify-content-center common-cursor-pointer"
                onClick={downloadCertificate}>
                DOWNLOAD COMMITMENT
              </div>
            </div>
          </div>
        </>
      )}
      <div className="hide-certificate">
        {isCommited && (
          <div id={'download-cheers-certificate-received'} style={{ ...certificateStyle }}>
            <img
              src={props?.config?.image_base_url + props?.config?.commitment_template}
              width="100%"
              height="100%"
            />
            <div style={{ ...commitmentCertificateText }}>
              {commitment?.map((item, index) => {
                return (
                  <p key={`${index}`} className="mb-2">
                    {item?.commitment_text}
                  </p>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <Modal
        show={showWarningDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowWarningDialog(false)}>
        <Modal.Body>{warningDialog()}</Modal.Body>
      </Modal>
      <Modal
        contentClassName="feedback-success-modal mx-auto"
        show={showSuccessDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          null;
        }}>
        <Modal.Body>{successDialog()}</Modal.Body>
      </Modal>
    </div>
  );
};

export default CommitmentTab;
