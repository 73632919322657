import React from 'react';

const HashtagSuggestion = ({ mention, theme, searchValue, isFocused, ...parentProps }) => {
  return (
    <div {...parentProps}>
      <div
        className={
          mention.index !== 0
            ? theme?.mentionSuggestionsEntryContainer
            : theme?.mentionSuggestionsEntryContainer2
        }>
        <div className={theme?.mentionSuggestionsEntryContainerLeft}></div>
        <div className={theme?.mentionSuggestionsEntryContainerRight}>
          <div className={theme?.mentionSuggestionsEntryText}>#{mention.name}</div>
        </div>
      </div>
    </div>
  );
};

export default HashtagSuggestion;
