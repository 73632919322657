import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SUPPORTED_IMAGE_FORMATS,
  SUPPORTED_IMAGE_FORMATS_WEB,
  WARNING_MESSAGES,
} from '../../../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../../../services/Service';
import { GET_GALLERY_PROJECTS, UPLOAD_IMAGES_GALLERY } from '../../../../services/ApiUrls';
import ProjectGalleryListItem from '../../components/ProjectGalleryListItem';
import LoadMoreBtn from '../../../../common/ui/load_more_btn/LoadMoreBtn';
import CustomButton from '../../../../common/ui/custom_button/CustomButton';
import { Modal } from 'react-bootstrap';
import { getFeatureComponentUrl } from '../../../../utils/accessibility';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import {
  TwitterShareButton,
  TwitterIcon,
  LinkedinIcon,
  LinkedinShareButton,
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../../../state/UIState';
import { getDeviceScreenDensityQualifierAsPath, showToast } from '../../../../utils/common';
import DefaultContentView from '../../../../common/ui/default_content_view/DefaultContentView';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import { selectMaxFileUploadSizeConfig } from '../../../../state/MasterData';
import { uploadDocuments } from '../../../../utils/commonApiRequests';

const ProjectGalleryTab = ({ microgiveInitiativeDetailId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [gallery, setGallery] = useState([]);
  const [baseUrl, setBaseUrl] = useState('');
  const [showUploadImageDialog, setShowUploadImageDialog] = useState(false);
  const [showImageUploadSuccessDialog, setShowImageUploadSuccessDialog] = useState(false);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [picUrl, setPicUrl] = useState('');
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const [numberOfImages, setNumberOfImages] = useState(0);
  const selectImagesInputRef = useRef(null);

  const accessibilityData = useSelector(selectAccessibilityData);
  const [deviceScreenDensityQualifierPath] = useState(getDeviceScreenDensityQualifierAsPath());
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const maxFileUploadSizeInMB = useSelector(selectMaxFileUploadSizeConfig);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagesLength, setSelectedImagesLength] = useState(0);
  const [noMatchFlag, setNoMatchFlag] = useState(false);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.PROJECTS_GALLERY_TAB_VISIT,
      microgiveInitiativeDetailId,
      ANALYTICS_ITEM_NAMES.MICROGIVE
    );
  }, [microgiveInitiativeDetailId]);

  const callGalleryApi = useCallback(
    async (id, loadMore) => {
      dispatch(saveShowEmptyContentView(true));
      setNoMatchFlag(false);
      if (loadMore) {
        const params = {
          page_id: id,
        };
        try {
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          const apiResponse = await apiRequest(
            GET_GALLERY_PROJECTS + microgiveInitiativeDetailId + '/gallery',
            REQUEST_TYPE.GET,
            params
          );
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              setBaseUrl(apiResponse.response.data.feeds.base_url);
              if (apiResponse.response.data.feeds.pictures.length > 0) {
                setGallery((oldArray) => [
                  ...oldArray,
                  ...apiResponse.response.data.feeds.pictures,
                ]);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
              } else {
                if (apiResponse.response.data.feeds.pictures.length === 0 && pageId === 1) {
                  setNoMatchFlag(true);
                }
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(
            err,
            params,
            GET_GALLERY_PROJECTS + microgiveInitiativeDetailId + '/gallery',
            NAVIGATION_ROUTES.PROJECT_TABS_SCREEN
          );
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      }
    },
    [dispatch, microgiveInitiativeDetailId]
  );

  useEffect(() => {
    callGalleryApi(1, true);
  }, [callGalleryApi]);

  const callUploadImagesApi = useCallback(
    async (imagesArr) => {
      setShowUploadImageDialog(false);
      const params = new URLSearchParams();
      params.append('files', JSON.stringify(imagesArr));
      try {
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        const apiResponse = await apiRequest(
          UPLOAD_IMAGES_GALLERY + microgiveInitiativeDetailId + '/upload',
          REQUEST_TYPE.POST,
          params
        );
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            setSelectedImages([]);
            setSelectedImagesLength(0);
            setShowImageUploadSuccessDialog(true);
            setNumberOfImages(imagesArr.length);
          }
        }
      } catch (err) {
        handleError(
          err,
          params,
          UPLOAD_IMAGES_GALLERY + microgiveInitiativeDetailId + '/upload',
          NAVIGATION_ROUTES.PROJECT_TABS_SCREEN
        );
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    },
    [dispatch, microgiveInitiativeDetailId]
  );

  const imageUploadDialog = () => {
    return (
      <div>
        <div className="microgive-team-micro-view">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_UPLOAD_PHOTO,
              ACCESSIBILITY_IDENTIFIERS.MICROGIVE
            )}
            className="microgive-upload-icon common-cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              selectImagesInputRef.current.click();
            }}
          />
        </div>
        <input
          type="file"
          onChange={onFileChange}
          accept={SUPPORTED_IMAGE_FORMATS}
          id="input"
          ref={selectImagesInputRef}
          multiple
          onClick={(event) => {
            event.target.value = null;
          }}
          className="d-none"
        />
        <div
          onClick={(e) => {
            e.stopPropagation();
            selectImagesInputRef.current.click();
          }}
          className="text-center microgive-image-to-upload mt-2 common-cursor-pointer">
          Select images to be uploaded
        </div>
        <div className="text-center microgive-images-review mt-2 mb-3">
          All images to be reviewed by MicrolandOne App Admin.
        </div>
      </div>
    );
  };

  const onPressViewGallery = () => {
    setShowImageUploadSuccessDialog(false);
    setNumberOfImages(0);
    setPageId(1);
    setLoadMoreFlag(true);
    setGallery([]);
    callGalleryApi(1, true);
  };

  const imageUploadSuccessDialog = () => {
    return (
      <div>
        <div className="microgive-team-micro-view">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_SUCCESS,
              ACCESSIBILITY_IDENTIFIERS.MICROGIVE
            )}
            className="microgive-upload-icon mr-4"
          />
        </div>
        <div className="microgive-images-got-uploaded text-center mt-2">
          {numberOfImages === 1 ? 'Image uploaded' : 'Images uploaded'}
        </div>
        <div className="text-center mt-2 microgive-images-under-review">
          Your Images are under review
        </div>
        <div className="text-center mt-2 microgive-images-reviewed-by">
          All images to be reviewed by MLOne App Admin.
        </div>
        <CustomButton
          buttonStyle="microgive-button-style mt-3"
          onClick={onPressViewGallery}
          showLoader={progressLoadingState.isProgressLoading}>
          View Gallery
        </CustomButton>
      </div>
    );
  };

  const shareDialog = () => {
    return (
      <div className="row">
        <div className="mx-auto">
          <TwitterShareButton url={picUrl}>
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
          <LinkedinShareButton url={picUrl} className="ml-3">
            <LinkedinIcon size={32} round={true} />
          </LinkedinShareButton>
          <FacebookShareButton url={picUrl} className="ml-3">
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <WhatsappShareButton url={picUrl} className="ml-3">
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
          <EmailShareButton url={picUrl} className="ml-3">
            <EmailIcon size={32} round={true} />
          </EmailShareButton>
        </div>
      </div>
    );
  };

  const onPressItem = (item) => {
    history.push(NAVIGATION_ROUTES.MICRIGIVE_GALLERY_TAGS_DETAIL_SCREEN, {
      baseUrl: baseUrl,
      picturePath: item.picture_path,
      pictureId: item.picture_id,
      taggedList: item.tags,
      microgiveInitiativeDetailId: microgiveInitiativeDetailId,
      fromRoute: NAVIGATION_ROUTES.PROJECT_TABS_SCREEN,
    });
  };

  const onPressTag = (item) => {
    history.push(NAVIGATION_ROUTES.TAG_PEOPLE_SCREEN, {
      parentRoute: NAVIGATION_ROUTES.PROJECT_TABS_SCREEN,
      tags: item.tags,
      picture_id: item.picture_id,
      microgiveInitiativeDetailId: microgiveInitiativeDetailId,
    });
  };

  const onPressShare = (item) => {
    setPicUrl(baseUrl + deviceScreenDensityQualifierPath + item.picture_path);
    setShowShareDialog(true);
  };

  const onPressUploadPhotos = () => {
    setShowUploadImageDialog(true);
  };

  useEffect(() => {
    if (selectedImages.length === selectedImagesLength && selectedImagesLength !== 0) {
      callUploadImagesApi(selectedImages);
    }
  }, [callUploadImagesApi, selectedImages, selectedImagesLength]);

  const onFileChange = (event) => {
    let isAllFilesSupported = true;
    let isAnyFileSizeLarge = false;
    [...event.target.files].forEach((element) => {
      if (!SUPPORTED_IMAGE_FORMATS_WEB.includes(element.type)) {
        isAllFilesSupported = false;
      }
      if (Number(element.size) / (1024 * 1024) > maxFileUploadSizeInMB) {
        isAnyFileSizeLarge = true;
      }
    });
    if (!isAllFilesSupported) {
      showToast(WARNING_MESSAGES.FILE_NOT_SUPPORTED);
      setShowUploadImageDialog(false);
    } else if (isAnyFileSizeLarge) {
      showToast(WARNING_MESSAGES.FILE_SIZE_EXCEEDED(maxFileUploadSizeInMB));
      setShowUploadImageDialog(false);
    } else {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      setSelectedImagesLength(event.target.files?.length);
      [...event.target.files].map((image) => {
        uploadDocuments(image, 'microgive')
          .then((response) => {
            setSelectedImages((prev) => [...prev, { filename: response.fileName, type: 'image' }]);
          })
          .catch((message) => {
            showToast(message);
            dispatch(
              saveProgressLoadingState({
                isProgressLoading: false,
              })
            );
          });
      });
    }
  };

  const onPressLoadMore = () => {
    callGalleryApi(pageId, loadMoreFlag);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_IMAGES_TO_SHOW} />;
  };

  return (
    <div className="main-content-container">
      <Modal
        show={showUploadImageDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowUploadImageDialog(false)}>
        <Modal.Body>{imageUploadDialog()}</Modal.Body>
      </Modal>
      <Modal
        show={showImageUploadSuccessDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={onPressViewGallery}>
        <Modal.Body>{imageUploadSuccessDialog()}</Modal.Body>
      </Modal>
      <Modal
        show={showShareDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowShareDialog(false)}>
        <Modal.Body>{shareDialog()}</Modal.Body>
      </Modal>
      <div className="d-flex flex-row-reverse mi-hr-activity-btn-container">
        <CustomButton
          buttonStyle={`btn-load-more common-cursor-pointer`}
          onClick={onPressUploadPhotos}>
          Upload Photos
        </CustomButton>
      </div>
      {pageId === 1 && noMatchFlag ? (
        emptyListView()
      ) : (
        <div>
          <div className="row">
            {gallery.map((item, index) => {
              return (
                <ProjectGalleryListItem
                  item={item}
                  baseUrl={baseUrl}
                  onPress={onPressItem}
                  onPressShare={onPressShare}
                  onPressTag={onPressTag}
                  key={`${index}`}
                />
              );
            })}
          </div>
          {loadMoreFlag && (
            <LoadMoreBtn
              onClick={onPressLoadMore}
              isLoading={progressLoadingState.isProgressLoading}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ProjectGalleryTab;
