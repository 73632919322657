import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  IJPR_ACTION_TYPE,
  NAVIGATION_ROUTES,
} from '../../../common/constants/AppConstants';
import {
  saveIjpReferFormattedSelectedFiltersData,
  saveIjpReferSelectedFilterData,
} from '../../../state/IjprData';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { showToast } from '../../../utils/common';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import IjpReferFilter from '../components/IjpReferFilter';
import { selectAccessData } from '../../../state/UserAccessData';

const ReferralsTab = (props) => {
  const { data, getTabData, tabKey, tab } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);
  const [searchText, setSearchText] = useState('');
  const [type, setType] = useState('');
  const accessData = useSelector(selectAccessData);
  const [showFilterButton, setShowFilterButton] = useState(true);
  const ijpAccessData = accessData?.data?.find((item) => item.feature_key === '_ijpr');

  useEffect(() => {
    if (ijpAccessData) {
      const actions = ijpAccessData?.actions;
      setShowFilterButton(actions?.includes('_filter'));
    }
  }, [ijpAccessData]);

  useEffect(() => {
    if (type === '' || type === 'all') {
      getTabData(tabKey, 1);
    } else {
      // fetch the only jobs which the user is eligible for
      getTabData(tabKey, 1, true);
    }
  }, [tabKey, getTabData, type]);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.IJP_TAB_VISIT, tab?.title ?? '', ANALYTICS_ITEM_NAMES.IJPR);
  }, [tab]);

  const showEmptyListView = () => {
    return (
      <DefaultContentView
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_EMPTY_STATE,
          ACCESSIBILITY_IDENTIFIERS.COMMON
        )}
        iconSize="lg"
        message={ERROR_MESSAGES.IJPR_NO_JOBS_AVAILABLE}
      />
    );
  };

  const renderAllOpeningsListItem = () => {
    return (
      <div
        onClick={() => {
          logEvent(ANALYTICS_EVENT_TYPES.IJP_TAB_ALL_OPENINGS_CLICK, '', ANALYTICS_ITEM_NAMES.IJPR);
          if (type === '' || type === 'all') {
            history.push(NAVIGATION_ROUTES.IJP_REFER_SEARCH_RESULT);
          } else {
            history.push(NAVIGATION_ROUTES.IJP_REFER_ELIGIBLE_SEARCH_RESULT, { type: type });
          }
        }}
        className="ijp-category-box-container mb-1 common-cursor-pointer">
        <div className="py-3 px-4 d-flex justify-content-between">
          <div className="ijp-category-box-title-highlighted text-center}">All Openings</div>
          <div className="ijp-category-box-title-highlighted text-center">
            {data.ijps.totalCount}
          </div>
        </div>
      </div>
    );
  };

  const renderIjprCategoryListItem = (item, index) => {
    return (
      <div
        key={index}
        onClick={() => {
          logEvent(
            ANALYTICS_EVENT_TYPES.IJP_TAB_LIST_ITEM_CLICK,
            item.department_refid.toString(),
            ANALYTICS_ITEM_NAMES.IJPR
          );
          if (type === '' || type === 'all') {
            history.push(NAVIGATION_ROUTES.IJP_REFER_SEARCH_RESULT);
          } else {
            history.push(NAVIGATION_ROUTES.IJP_REFER_ELIGIBLE_SEARCH_RESULT, { type: type });
          }
          dispatch(
            saveIjpReferSelectedFilterData({
              departments: [{ label: item.department, value: item.department_refid }],
            })
          );
          dispatch(
            saveIjpReferFormattedSelectedFiltersData({ departments: [item.department_refid] })
          );
        }}
        className="ijp-category-box-container mb-1 common-cursor-pointer">
        <div className="py-3 px-4 d-flex justify-content-between">
          <div className="ijp-category-box-title text-center">{item.department}</div>
          <div className="ijp-category-box-title text-center">{item.rcount}</div>
        </div>
      </div>
    );
  };

  const onPressSearchHandler = (event) => {
    event.preventDefault();
    if (!searchText.trim().length) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      logEvent(
        ANALYTICS_EVENT_TYPES.IJP_SEARCH_QUERY,
        searchText.toString(),
        ANALYTICS_ITEM_NAMES.IJPR
      );
      history.push(NAVIGATION_ROUTES.IJP_REFER_SEARCH_RESULT, {
        searchQueryText: searchText,
        ijprActionType: IJPR_ACTION_TYPE.APPLY,
      });
    }
  };

  const onPressRecentSearchHandler = (recentSearchItemTitle) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.IJP_RECENT_SEARCH_KEYWORD_CLICK,
      recentSearchItemTitle.toString(),
      ANALYTICS_ITEM_NAMES.IJPR
    );

    if (type === '' || type === 'all') {
      history.push(NAVIGATION_ROUTES.IJP_REFER_SEARCH_RESULT, {
        searchQueryText: recentSearchItemTitle,
        ijprActionType: IJPR_ACTION_TYPE.APPLY,
      });
    } else {
      if (type === '' || type === 'all') {
        history.push(NAVIGATION_ROUTES.IJP_REFER_ELIGIBLE_SEARCH_RESULT, {
          searchQueryText: recentSearchItemTitle,
          ijprActionType: IJPR_ACTION_TYPE.APPLY,
          type: type,
        });
      }
    }
  };

  const filterActionHandler = (selectedFilters) => {
    if (type === '' || type === 'all') {
      history.push(NAVIGATION_ROUTES.IJP_REFER_SEARCH_RESULT, { selectedFilters: selectedFilters });
    } else {
      history.push(NAVIGATION_ROUTES.IJP_REFER_ELIGIBLE_SEARCH_RESULT, {
        selectedFilters: selectedFilters,
        type: type,
      });
    }
  };

  return (
    <div className="py-3">
      {data && Object.keys(data) && Object.keys(data).length && (
        <div>
          <div>
            {showFilterButton && <IjpReferFilter onClickApply={filterActionHandler} />}
            <div>
              <h5 className="ijp-tab-landing-title text-center mt-5">
                BRING YOUR OWN BUDDIES (EMPLOYEE REFERRAL)
              </h5>
              <h5 className="ijp-tab-landing-title text-center">INTERNAL JOB POSTING (IJP)</h5>
              <div className="d-flex flex-direction-row align-items-center">
                <form onSubmit={onPressSearchHandler} className="ijp-form-min-width">
                  <div className="ijp-tab-search-outer-container d-flex justify-content-center align-items-center mt-5 mb-3">
                    <img
                      className="ijp-search-bar-left-icon ml-2"
                      src={getFeatureComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.IJPR_SEARCH,
                        ACCESSIBILITY_IDENTIFIERS.IJPR
                      )}
                    />
                    <input
                      autoFocus
                      className="py-2 ijp-search-bar-input px-2"
                      type="text"
                      maxLength={100}
                      placeholder="Search by keywords"
                      onChange={(event) => {
                        setSearchText(event.target.value);
                      }}
                    />

                    <CustomButton buttonStyle="py-2 px-3" onClick={onPressSearchHandler}>
                      Search
                    </CustomButton>
                  </div>
                </form>

                <div className="d-flex align-items-center mt-5 mb-3 px-2  ">
                  {(type === 'all' || type === '') && (
                    <div
                      className="common-cursor-pointer bold-and-underline-text ijp-job-display-type-text"
                      onClick={() => {
                        setType('show_jobs_for_me');
                      }}>
                      IJPs available for me
                    </div>
                  )}
                  {type === 'show_jobs_for_me' && (
                    <div
                      className="common-cursor-pointer bold-and-underline-text ijp-job-display-type-text"
                      onClick={() => {
                        setType('all');
                      }}>
                      Show All Jobs
                    </div>
                  )}
                </div>
              </div>

              {!!data.recent_searches && !!data.recent_searches.length && (
                <div className="d-flex flex-wrap mb-5">
                  <div className="ijp-tab-search-bottom-text-title">Recent searches: </div>

                  {data.recent_searches.map((searchItemTitle, index) => (
                    <div
                      className="d-flex ijp-tab-search-bottom-text-parent-subtitle"
                      key={index.toString()}
                      onClick={() => {
                        onPressRecentSearchHandler(searchItemTitle);
                      }}>
                      {index !== 0 ? ', ' : ''}
                      <div className="ijp-tab-search-bottom-text-child-subtitle ml-1 common-cursor-pointer">
                        {searchItemTitle}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {renderAllOpeningsListItem()}
            {data.ijps.list.map((ijprCategoryItem, index) =>
              renderIjprCategoryListItem(ijprCategoryItem, index)
            )}
          </div>

          {data?.ijps?.list?.length === 0 && showEmptyListView()}
        </div>
      )}
    </div>
  );
};

export default ReferralsTab;
