import React from 'react';
import { useSelector } from 'react-redux';

import { ACCESSIBILITY_FEATURE_COMPONENTS } from '../../../../common/constants/AppConstants';
import { getFeatureComponentUrl } from '../../../../utils/accessibility';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { selectFooterData } from '../../../../state/DashboardData';

const Footer = () => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const footerData = useSelector(selectFooterData);

  const renderIcon = (icon, index) => {
    return (
      <a key={index} className="mr-2" href={icon.url.trim()} target="_blank" rel="noreferrer">
        <img
          src={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.ICON_URL,
            icon.identifier
          )}
          className="footer-icon"
        />
      </a>
    );
  };
  return <div className="d-flex">{footerData.map((icon, index) => renderIcon(icon, index))}</div>;
};

export default Footer;
