import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  FEATURE_TYPE,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { COMMENT_BY_ID } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { getUrlWithSpecificRendition } from '../../utils/common';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';

const EventGalleryScreen = ({ location, history }) => {
  const params = location.state;
  const baseUrl = params.gallary?.base_url;
  const [likeCount, setLikeCount] = useState(params.likes.count);
  const [liked, setLiked] = useState(params.likes.is_liked);
  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.EVENTS_GALLERY, NAVIGATION_ROUTES.EVENTS_GALLERY);
  }, []);

  const galleryArray =
    params.gallary.pictures &&
    params.gallary.pictures.map((item, index) => {
      return (
        <div key={`${index}`} className="col-sm-4 mb-2">
          <div>
            <img
              src={getUrlWithSpecificRendition(
                baseUrl,
                SCREEN_DENSITY_QUALIFIERS.HDPI,
                item.picture_path
              )}
              className="embed-responsive embed-responsive-16by9"
            />
            <div className="event-title">{item.picture_caption}</div>
          </div>
        </div>
      );
    });

  const postLikeFunction = async () => {
    const formData = new URLSearchParams();
    formData.append('type', FEATURE_TYPE.EVENTS);
    try {
      logEvent(
        ANALYTICS_EVENT_TYPES.EVENTS_LIKE_GALLERY,
        params.eventId,
        ANALYTICS_ITEM_NAMES.LIKE
      );
      const commentApiResponse = await apiRequest(
        COMMENT_BY_ID + params.eventId + '/like',
        REQUEST_TYPE.POST,
        formData
      );
      if (Object.keys(commentApiResponse).length > 0) {
        if (commentApiResponse.response.status === true) {
          if (commentApiResponse.response.data) {
            const data = commentApiResponse.response.data;
            if (data) {
              history.replace(location.pathname, {
                ...params,
                likes: { is_liked: data.liked, count: data.like_count },
              });
              setLikeCount(data.like_count);
              setLiked(data.liked);
            }
          }
        }
      }
    } catch (err) {
      handleError(
        err,
        formData,
        COMMENT_BY_ID + params.eventId + '/like',
        NAVIGATION_ROUTES.EVENTS_GALLERY
      );
      console.error(err);
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.EVENTS} />
      <div className="header-view pt-0 mb-2 mt-3 justify-content-between">
        <BackButton isFullRow={false} />
        <div className="broadcast-header-row justify-content-end-view">
          <div className="event-icon-row mr-3">
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                liked
                  ? ACCESSIBILITY_HEADER_COMPONENTS.ACTIVE_LIKE
                  : ACCESSIBILITY_HEADER_COMPONENTS.INACTIVE_LIKE
              )}
              className="header-icon"
              onClick={postLikeFunction}
            />
            <div
              className="event-icon-text"
              onClick={() => {
                if (likeCount > 0) {
                  history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
                    referId: params.eventId,
                    parentType: FEATURE_TYPE.EVENTS,
                  });
                }
              }}>
              {likeCount}
            </div>
          </div>
        </div>
      </div>
      <div className="main-content-container">
        <div>
          <div className="event-gallary-icon-row mb-2">
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.EVENTS_GALLERY,
                ACCESSIBILITY_IDENTIFIERS.EVENTS
              )}
              className="header-icon"
            />
            <div className="event-icon-text">{params.gallary.pictures.length}</div>
          </div>
        </div>
        <div className="row">{galleryArray}</div>
      </div>
    </div>
  );
};

export default EventGalleryScreen;
