import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  TRAVEL_REQUEST_FILTER_STATUS,
  TRAVEL_REQUEST_SORTING_STATUS,
  TRAVEL_REQUEST_STATUS_IDENTIFIERS,
  TRAVEL_REQUEST_TAB_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import Divider from '../../../common/ui/divider/Divider';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { GET_ALL_REQUESTS, GET_REQUEST_BY_ID } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { saveTravelRequestTabData } from '../../../state/TabData';
import { saveTravelRequestData } from '../../../state/TravelRequestMasterData';
import { saveShowEmptyContentView } from '../../../state/UIState';
import { getHeaderComponentUrl } from '../../../utils/accessibility';
import { formatDateDDMonthYYYY } from '../../../utils/common';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import { renderRequestStatusBgColor } from '../../../utils/travelRequest';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const AllRequestTab = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const employeeData = useSelector(selectEmployeeData);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [loadMoreFlag, setLoadMoreFlag] = useState(true);
  const [pageId, setPageId] = useState(1);
  const [loading, setLoading] = useState(true);
  const [allRequestData, setAllRequestData] = useState([]);
  const [selectedFilter, setFilter] = useState('');
  const [selectedSort, setSort] = useState('');
  const [showOrderDropdown, setShowOrderDropdown] = useState(false);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const sortDropDownPopoverRef = useRef();
  const filterDropDownPopoverRef = useRef();

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.TRAVEL_REQUEST_ALL_REQUESTS_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.TRAVEL_REQUEST
    );
  }, []);

  const callAllRequestApi = useCallback(
    async (id, loadMore, status = '', sort = '') => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        let params = {
          page_id: id,
        };
        if (status !== '') {
          params.status = status;
        }
        if (sort !== '') {
          params.sort = sort;
        }
        if (id === 1) {
          setAllRequestData([]);
        }
        try {
          setLoading(true);
          const apiResponse = await apiRequest(GET_ALL_REQUESTS, REQUEST_TYPE.GET, params);
          setLoading(false);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.requests.length > 0) {
                id === 1
                  ? setAllRequestData(apiResponse.response.requests)
                  : setAllRequestData((oldArray) => [
                      ...oldArray,
                      ...apiResponse.response.requests,
                    ]);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(err, params, GET_ALL_REQUESTS, NAVIGATION_ROUTES.TRAVEL_REQUEST);
          setLoading(false);
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    callAllRequestApi(1, true, selectedFilter, selectedSort);
  }, [callAllRequestApi, selectedFilter, selectedSort]);

  useEffect(() => {
    if (pageId > 1) {
      callAllRequestApi(pageId, loadMoreFlag, selectedFilter, selectedSort);
    }
  }, [callAllRequestApi, loadMoreFlag, pageId]);

  const onPressLoadMore = () => {
    setPageId((prev) => prev + 1);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        showOrderDropdown &&
        sortDropDownPopoverRef.current &&
        !sortDropDownPopoverRef.current.contains(e.target)
      ) {
        setShowOrderDropdown(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showOrderDropdown]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        showFilterDropdown &&
        filterDropDownPopoverRef.current &&
        !filterDropDownPopoverRef.current.contains(e.target)
      ) {
        setShowFilterDropdown(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showFilterDropdown]);

  const callGetRequestByIdApi = useCallback(
    async (requestId) => {
      setLoading(true);
      let params = { request_id: requestId };
      try {
        const apiResponse = await apiRequest(GET_REQUEST_BY_ID, REQUEST_TYPE.GET, params);
        setLoading(false);
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            dispatch(saveTravelRequestData(apiResponse.response.request));
            dispatch(saveTravelRequestTabData(TRAVEL_REQUEST_TAB_IDENTIFIERS.PLAN_TRAVEL));
            history.replace(NAVIGATION_ROUTES.TRAVEL_REQUEST, {
              isEditRequest: true,
              requestFormData: apiResponse.response.request,
            });
          }
        }
      } catch (err) {
        handleError(err, params, GET_REQUEST_BY_ID, NAVIGATION_ROUTES.TRAVEL_REQUEST);
        setLoading(false);
      }
    },
    [dispatch, history]
  );

  const onPressItem = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.TR_ALL_REQUESTS_TAB_ITEM_CLICK,
      item.request_id.toString(),
      ANALYTICS_ITEM_NAMES.TRAVEL_REQUEST
    );
    if (
      item.authorization_id &&
      item.status.identifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.PRE_APPROVED
    ) {
      dispatch(saveTravelRequestData(item));
      dispatch(saveTravelRequestTabData(TRAVEL_REQUEST_TAB_IDENTIFIERS.PLAN_TRAVEL));
      history.replace(NAVIGATION_ROUTES.TRAVEL_REQUEST, {
        isEditRequest: true,
        requestFormData: item,
      });
    } else if (item.status.identifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.DRAFT) {
      callGetRequestByIdApi(item.request_id);
    } else {
      history.push(NAVIGATION_ROUTES.TRAVEL_REQUEST_REVIEW + '/' + item.request_id);
    }
  };

  const renderRequestView = (item, index) => {
    return (
      <div key={index} className="common-cursor-pointer" onClick={() => onPressItem(item)}>
        <div className="d-flex">
          <div className="flex-fill">
            <h5 className="mb-0 travel-request-purpose">{item.purpose}</h5>
            <p className="mb-0 travel-request-request-id-date">
              {item.request_code ? `${item.request_code} | ` : ''}
              {formatDateDDMonthYYYY(item.start_date, false)} to{' '}
              {formatDateDDMonthYYYY(item.end_date, false)}
            </p>
            {!item.authorization_id &&
              item.created_by.employee_code !== employeeData.employee_code && (
                <p className="mb-0">
                  Raised by {item.created_by.first_name} {item.created_by.last_name}
                </p>
              )}
            {!item.authorization_id &&
              item.employee.employee_code !== employeeData.employee_code && (
                <div className="d-flex align-items-center mt-3">
                  <UserProfilePhoto
                    imageBaseUrl={item.employee.profile_picture.base_url}
                    imagePath={item.employee.profile_picture.image_path}
                    imageClass="common-user-profile-photo-xxs"
                    employeeId={item.employee.employee_id}
                  />
                  <p className="mb-0 ml-2 travel-request-request-id-date">
                    {item.employee.first_name} {item.employee.last_name}&apos;s travel request
                  </p>
                </div>
              )}
            <div className="d-flex align-items-center mt-3">
              <div
                className={`travel-request-request-indicator-base mr-2 ${renderRequestStatusBgColor(
                  item.status.identifier
                )}`}
              />
              <p className="mb-0 travel-request-status">{item.status.title}</p>
            </div>
          </div>
          <img
            src={getHeaderComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.POLICIES_RIGHT_ARROW,
              ACCESSIBILITY_IDENTIFIERS.POLICIES
            )}
            className="policy-icon mt-2"
          />
        </div>
        <Divider style="my-3" />
      </div>
    );
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_REQUESTS_TO_SHOW} />;
  };

  const onSortItemPress = (value) => {
    return () => {
      setShowOrderDropdown(false);
      setSort(value);
    };
  };

  const onFilterItemPress = (value) => {
    return () => {
      setShowFilterDropdown(false);
      setFilter(value);
    };
  };

  const sortItemsPopover = () => {
    return (
      <Popover className="tr-dropdown-popover p-0">
        <Popover.Content className="p-0">
          <div ref={sortDropDownPopoverRef}>
            {TRAVEL_REQUEST_SORTING_STATUS.map((item, index) => {
              return (
                <div
                  key={'sort_' + index}
                  className={`common-cursor-pointer tr-dropdown-item-style ${
                    item.value === selectedSort ? 'tr-dropdown-item-active' : ''
                  } p-2`}
                  onClick={onSortItemPress(item.value)}>
                  {item.label}
                </div>
              );
            })}
          </div>
        </Popover.Content>
      </Popover>
    );
  };

  const filterItemsPopover = () => {
    return (
      <Popover className="tr-dropdown-popover p-0">
        <Popover.Content className="p-0">
          <div ref={filterDropDownPopoverRef}>
            {TRAVEL_REQUEST_FILTER_STATUS.map((item, index) => {
              return (
                <div
                  key={'filter_status_' + index}
                  className={`common-cursor-pointer tr-dropdown-item-style ${
                    item.value === selectedFilter ? 'tr-dropdown-item-active' : ''
                  } p-2`}
                  onClick={onFilterItemPress(item.value)}>
                  {item.label}
                </div>
              );
            })}
          </div>
        </Popover.Content>
      </Popover>
    );
  };

  return (
    <div className="p-3">
      <div className="travel-request-filter-wrapper d-flex py-3 mb-3 flex-row-reverse align-items-center">
        <OverlayTrigger
          rootClose
          trigger="click"
          placement="bottom"
          show={showOrderDropdown}
          overlay={sortItemsPopover()}>
          <div
            className="tr-dropdown-button-style common-cursor-pointer flex-row align-items-center pl-4"
            onClick={() => setShowOrderDropdown(true)}>
            <span className="tr-dropdown-button-text">Order By</span>
            <span className="tr-dropdown-arrow px-2 ">{showOrderDropdown ? '▲' : '▼'}</span>
          </div>
        </OverlayTrigger>
        <OverlayTrigger
          rootClose
          trigger="click"
          placement="bottom"
          show={showFilterDropdown}
          overlay={filterItemsPopover()}>
          <div
            className="tr-dropdown-button-style common-cursor-pointer"
            onClick={() => setShowFilterDropdown(true)}>
            <span className="tr-dropdown-button-text">Show Only</span>
            <span className="tr-dropdown-arrow px-2">{showFilterDropdown ? '▲' : '▼'}</span>
          </div>
        </OverlayTrigger>
      </div>
      <ActivityLoader visible={loading} />
      {allRequestData && (
        <div className="mb-3">
          {!allRequestData.length && !loading && emptyListView()}
          {!!allRequestData.length && (
            <div>
              {allRequestData.length > 0 &&
                allRequestData.map((item, index) => renderRequestView(item, index))}
            </div>
          )}
        </div>
      )}
      {loadMoreFlag && !!allRequestData.length && (
        <LoadMoreBtn onClick={onPressLoadMore} buttonStyle="my-3" isLoading={loading} />
      )}
    </div>
  );
};

export default React.memo(AllRequestTab);
