import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ACTION_TYPES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
  SCREEN_TITLE,
  WISH_TYPES,
} from '../../common/constants/AppConstants';
import { GET_MY_WISHES_LIST } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { saveProgressLoadingState, selectBaseUrl } from '../../state/UIState';
import {
  getFeatureComponentUrl,
  getFeatureComponentUrlLdpi,
  getHeaderComponentUrl,
} from '../../utils/accessibility';
import { Modal, Form } from 'react-bootstrap';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import {
  selectConfigurations,
  selectDearMLTabs,
  selectWishesFilters,
} from '../../state/MasterData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import { formatDateDoMMM, getUrlWithSpecificRendition } from '../../utils/common';
import Lottie from 'react-lottie';
import axios from 'axios';

const AllWishesScreen = ({ history, location }) => {
  const pageId = useRef(1);
  const dispatch = useDispatch();
  const [wishes, setWishesh] = useState([]);
  const baseUrl = useSelector(selectBaseUrl);
  const [showDateFilterDialog, setShowDateFilterDialog] = useState(false);
  const [showTypeFilters, setShowTypeFilters] = useState(false);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [id] = useState(new URLSearchParams(location.search).get('id'));
  const [dateResponse, setDateResponce] = useState([]);
  const dearMLTabData = useSelector(selectDearMLTabs);
  const { profileData } = location.state;
  const [year, setYear] = useState('');
  const [wishType, setWishType] = useState([]);
  const wishesFilters = useSelector(selectWishesFilters);
  const [lottieJSON, setLottieJSON] = useState('');
  const configurationsData = useSelector(selectConfigurations);
  const dashboardConfig = configurationsData._dashboard_config.celebrationLottie;
  const [yearOccurances, setYearOccurances] = useState([]);

  let tempRoutes = [];
  for (let tab of dearMLTabData) {
    tempRoutes.push({ channel_id: tab.channel_id, sub_channel_id: tab.sub_channel_id });
  }
  const [routes] = useState(tempRoutes);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.ALL_WISHES, NAVIGATION_ROUTES.ALL_WISHES);
  }, []);

  useEffect(() => {
    if (lottieJSON === '') {
      if (dashboardConfig) {
        const fileBaseUrl = dashboardConfig.animation_file_base_url_web;
        const filePath = dashboardConfig.animation_file_path;
        if (fileBaseUrl && filePath) {
          axios({
            method: REQUEST_TYPE.GET,
            url: fileBaseUrl + filePath,
          })
            .then((response) => {
              if (response.status === 200) {
                if (response.data) {
                  setLottieJSON(response.data);
                }
              }
            })
            .catch(() => {});
        }
      }
    }
  }, [dashboardConfig]);

  useEffect(() => {
    if (baseUrl !== '' && id && id !== '') {
      dispatch({ type: ACTION_TYPES.GET_TOKEN, payload: { id: id } });
    }
  }, [baseUrl, dispatch, id]);

  const callAllWishesApi = useCallback(
    async (year, wishType) => {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const formData = new URLSearchParams();
      formData.append('page_id', pageId.current);
      formData.append('employee_id', profileData.employee_id);
      if (year) formData.append('year', year);
      if (wishType) formData.append('type', JSON.stringify([...wishType]));
      try {
        const apiResponse = await apiRequest(GET_MY_WISHES_LIST, REQUEST_TYPE.POST, formData);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.data) {
              const data = apiResponse.response.data;
              if (data.length > 0) {
                if (pageId.current === 1) {
                  setWishesh(data);
                } else {
                  setWishesh((oldData) => [...oldData, ...data]);
                }
                const filterDates = apiResponse.response.years;
                if (filterDates.length > 0) {
                  setDateResponce(filterDates);
                  let tempArray = [];
                  let tempData = [];
                  data.map((wish, index) => {
                    if (!tempArray.includes(wish.year)) {
                      tempData.push(index);
                      tempArray.push(wish.year);
                    }
                    if (index == data.length - 1) {
                      setYearOccurances(tempData);
                    }
                  });
                }
                pageId.current = pageId.current + 1;
              } else {
                setWishesh([]);
                pageId.current = 1;
              }
            }
          }
        }
      } catch (err) {
        handleError(err, formData, GET_MY_WISHES_LIST, NAVIGATION_ROUTES.WISHES);
        console.error(err);
      }
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    },
    [dispatch, routes]
  );

  const renderScreen = useCallback(() => {
    callAllWishesApi('');
  }, [callAllWishesApi]);

  useEffect(() => {
    if (baseUrl !== '') {
      renderScreen();
    }
  }, [baseUrl, renderScreen]);

  const callFilteredArticles = (year, wishType) => {
    pageId.current = 1;
    callAllWishesApi(year, wishType);
    setShowDateFilterDialog(false);
  };

  const CustomYearView = () => {
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Label>Select Year</Form.Label>
            <div className="dear-employer-year-container">
              <Form.Control
                as="select"
                custom
                value={year}
                onChange={(event) => {
                  setYear(event.target.value ?? '');
                }}>
                {dateResponse &&
                  dateResponse.map((item) => {
                    return (
                      <option key={item} defaultValue={item === year}>
                        {item}
                      </option>
                    );
                  })}
              </Form.Control>
            </div>
          </Form.Group>
        </Form>
        <div className="dear-employer-row">
          <div>
            <CustomButton
              buttonStyle="dear-employer-calender-button-style"
              onClick={() => {
                setYear('');
                callFilteredArticles('', wishType);
              }}>
              CLEAR FILTERS
            </CustomButton>
          </div>
          <div className="dear-employer-row">
            <CustomButton
              buttonStyle="dear-employer-calender-button-style"
              onClick={() => {
                setShowDateFilterDialog(false);
              }}>
              CANCEL
            </CustomButton>
            <CustomButton
              buttonStyle="dear-employer-calender-button-style"
              onClick={() => {
                callFilteredArticles(year, wishType);
              }}>
              DONE
            </CustomButton>
          </div>
        </div>
      </div>
    );
  };

  const getSubText = (item) => {
    switch (item.type) {
      case WISH_TYPES.BIRTHDAY:
        return `Birthday: ${formatDateDoMMM(item.event_date)}`;
      case WISH_TYPES.ADVANCEMENT:
        return `Advancement: ${formatDateDoMMM(item.event_date)}`;
      case WISH_TYPES.ANNIVERSARY:
        return `Service Anniversary: ${formatDateDoMMM(item.event_date)}`;
      case WISH_TYPES.NEW_JOINEE:
        return `New joinee: ${formatDateDoMMM(item.event_date)}`;
      default:
        return `Birthday: ${formatDateDoMMM(item.event_date)}`;
    }
  };

  return (
    <div className="container">
      <SectionHeader title={SCREEN_TITLE.ALL_WISHES} />
      <BackButton />
      <div className="dear-employer-header-row header-view justify-content-end">
        <div className="dear-employer-icon-row">
          <img
            src={getHeaderComponentUrl(accessibilityData, ACCESSIBILITY_HEADER_COMPONENTS.CALENDER)}
            className="header-icon mr-3"
            onClick={() => {
              setShowDateFilterDialog(true);
              if (!year) {
                setYear(dateResponse && dateResponse[0]);
              }
            }}
          />
        </div>
        <div className="news-icon-row mr-3">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_FILTER,
              ACCESSIBILITY_IDENTIFIERS.COMMON
            )}
            className="filter-icon"
            onClick={() => {
              setShowTypeFilters(!showTypeFilters);
            }}
          />
        </div>
      </div>
      <div className="py-3 mx-auto common-container-md">
        <div>
          {showTypeFilters && (
            <div className="year-all-wishes-container">
              <div className="d-flex flex-row flex-wrap">
                {wishesFilters.map((wish, index) => {
                  return (
                    <div
                      onClick={() => {
                        let temp = wishType;
                        if (temp.includes(wish.value)) {
                          temp.splice(
                            temp.findIndex((item) => item === wish.value),
                            1
                          );
                        } else {
                          temp.push(wish.value);
                        }
                        setWishType(temp);
                        callFilteredArticles(year, temp);
                      }}
                      key={index}
                      className={
                        wishType.includes(wish.value)
                          ? 'filter-selected-button'
                          : 'filter-unselected-button'
                      }>
                      <h5
                        className={
                          wishType == wish.value ? 'filter-selected-text' : 'filter-unselected-text'
                        }>
                        {wish.title}
                      </h5>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {wishes.map((item, index) => (
            <div key={index} className="mb-4">
              {yearOccurances.includes(index) && (
                <div className="year-all-wishes-container">
                  <div className="year-all-wishes-devider" />
                  <div className="year-all-wishes-item">{`${item.year}`}</div>
                  <div className="year-all-wishes-devider" />
                </div>
              )}
              <img
                onClick={() => {
                  history.push(`${NAVIGATION_ROUTES.CELEBRATION_WISHES}`, {
                    profileData,
                    celebrationDetails: item,
                    year: item.year,
                    fromRoute: NAVIGATION_ROUTES.ALL_WISHES,
                  });
                }}
                src={getUrlWithSpecificRendition(
                  item.image.base_url + '/',
                  SCREEN_DENSITY_QUALIFIERS.HDPI,
                  item.image.image_path
                )}
                className="embed-responsive embed-responsive-16by9 common-cursor-pointer"
              />
              <div
                className="employee-details-container common-cursor-pointer"
                onClick={() => {
                  history.push(`${NAVIGATION_ROUTES.CELEBRATION_WISHES}`, {
                    profileData,
                    celebrationDetails: item,
                    year: item.year,
                    fromRoute: NAVIGATION_ROUTES.ALL_WISHES,
                  });
                }}>
                <div className="wishes-employee-details">
                  <img
                    className={`d-block common-user-profile-photo-sm mr-3`}
                    src={
                      profileData.profile_picture.image_path
                        ? getUrlWithSpecificRendition(
                            profileData.profile_picture.base_url,
                            SCREEN_DENSITY_QUALIFIERS.LDPI,
                            profileData.profile_picture.image_path
                          )
                        : getFeatureComponentUrlLdpi(
                            accessibilityData,
                            ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
                            ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                          )
                    }
                  />
                  <div className="profile-lottie-container">
                    <div>
                      <div className="communities-post-employee-name">{`${profileData.first_name} ${profileData.middle_name} ${profileData.last_name}`}</div>
                      <div className="wishes-sub-text">{getSubText(item)}</div>
                    </div>

                    <div
                      style={{
                        background: dashboardConfig ? dashboardConfig.gradient_colors_web : '',
                      }}>
                      {lottieJSON !== '' && (
                        <Lottie
                          options={{
                            loop: true,
                            autoplay: true,
                            animationData: lottieJSON,
                          }}
                          isClickToPauseDisabled
                          style={{ height: 60 }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="send-wish-container">
                  <div className="send-wish-button">Send Wish</div>
                </div>
              </div>
            </div>
          ))}
          {wishes.length == 0 && <DefaultContentView message={'No wishes found'} />}
        </div>
      </div>
      <div className="main-content-container">
        <Modal
          show={showDateFilterDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => setShowDateFilterDialog(false)}>
          <Modal.Body>{CustomYearView()}</Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default AllWishesScreen;
