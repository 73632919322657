import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  SCREEN_TITLE,
} from '../../common/constants/AppConstants';
import { Tab } from 'react-bootstrap';
import PolicyTab from './tabs/PolicyTab';
import { savePoliciesTabData, selectPoliciesTabData } from '../../state/TabData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { useParams } from 'react-router-dom';
import BackButton from '../../common/ui/common_back_button/BackButton';
import {
  savePoliciesSearchTextData,
  savePoliciesLoadMoreButtonData,
  savePoliciesSearchResultData,
  savePoliciesPageData,
} from '../../state/PoliciesData';
import NavTabs from '../../common/ui/tabs/NavTabs';

const PolicyTabsScreen = ({ location, history }) => {
  const dispatch = useDispatch();
  const { geographyId } = useParams();
  const accessibilityData = useSelector(selectAccessibilityData);
  const policiesTabData = useSelector(selectPoliciesTabData);
  const categories = location.state.categories;
  const geographyTitle = location.state.geographyTitle.toString().toUpperCase();

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.POLICIES, NAVIGATION_ROUTES.POLICIES);
  }, []);

  useEffect(() => {
    if (policiesTabData === '') {
      dispatch(savePoliciesTabData(categories[0].category_id));
    }
  }, [dispatch, policiesTabData, categories]);

  const renderTabs = (tab) => {
    switch (tab.category_id) {
      default:
        return <PolicyTab tabKey={tab.category_id} geographyId={geographyId} />;
    }
  };

  return (
    <div className="container">
      <SectionHeader title={SCREEN_TITLE.POLICIES + ' - ' + geographyTitle} />
      <div>
        <div className="header-view justify-content-between">
          <BackButton isFullRow={false} />
          <img
            src={getHeaderComponentUrl(accessibilityData, ACCESSIBILITY_HEADER_COMPONENTS.SEARCH)}
            className="header-icon common-cursor-pointer"
            onClick={() => {
              dispatch(savePoliciesSearchTextData(''));
              dispatch(savePoliciesLoadMoreButtonData(false));
              dispatch(savePoliciesPageData(1));
              dispatch(savePoliciesSearchResultData([]));
              history.push(NAVIGATION_ROUTES.POLICY_SEARCH_SCREEN);
            }}
          />
        </div>
        <div>
          {categories && categories.length > 0 && (
            <NavTabs
              activeKey={policiesTabData}
              onSelect={(k) => dispatch(savePoliciesTabData(k))}
              unmountOnExit>
              {categories.map((tab, index) => {
                return (
                  <Tab key={`${index}`} eventKey={tab.category_id} title={tab.title}>
                    {renderTabs(tab, index)}
                  </Tab>
                );
              })}
            </NavTabs>
          )}
        </div>
      </div>
    </div>
  );
};

export default PolicyTabsScreen;
