import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { GET_ESG_CONTESTS } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { saveShowEmptyContentView, selectShowEmptyContentView } from '../../../state/UIState';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { formatDateDDMonthYYYY, getUrlWithSpecificRendition } from '../../../utils/common';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';

const EsgContestsTab = (props) => {
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const [contests, setContests] = useState([]);
  const [loading, setLoading] = useState(true);
  const accessibilityData = useSelector(selectAccessibilityData);
  const history = useHistory();
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ESG_TAB_VISIT,
      props?.item?.title,
      ANALYTICS_ITEM_NAMES.CONTESTS
    );
  }, [props?.item?.title]);

  const callContestApi = useCallback(async () => {
    setError(false);
    setLoading(true);
    dispatch(saveShowEmptyContentView(true));
    const params = {
      page_id: 1,
      channel_id: props?.item?.channel_id,
      sub_channel_id: props?.item?.sub_channel_id,
    };
    try {
      const apiResponse = await apiRequest(GET_ESG_CONTESTS, REQUEST_TYPE.GET, params);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse?.response?.data?.feed ?? [];
            if (data.length > 0) {
              setContests(data);
            }
          }
        }
      }

      setLoading(false);
    } catch (err) {
      handleError(err, params, GET_ESG_CONTESTS, NAVIGATION_ROUTES.ESG);
      setLoading(false);
      setError(true);
    }
  }, [dispatch, props?.item?.channel_id, props?.item?.sub_channel_id]);

  const renderScreen = useCallback(async () => {
    callContestApi();
  }, [callContestApi]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const emptyListView = () => {
    return <DefaultContentView message={NO_DATA_TEXT.NO_DATA_FOUND} />;
  };

  const onPressItem = (item) => {
    history.push(`${NAVIGATION_ROUTES.CONTESTS}/${item.contest_id}`);
  };

  const contestArray =
    contests &&
    contests.map((item, index) => {
      return (
        <div
          key={`${index}`}
          className="col-sm-4 mb-2 common-cursor-pointer"
          onClick={() => onPressItem(item)}>
          <div>
            <img
              src={getUrlWithSpecificRendition(
                item.promo_image.base_url,
                SCREEN_DENSITY_QUALIFIERS.HDPI,
                item.promo_image.image_path
              )}
              className="embed-responsive embed-responsive-16by9"
            />
            <div className="row ml-1 mt-1">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.NEWS_CALENDER,
                  ACCESSIBILITY_IDENTIFIERS.NEWS
                )}
                className="contests-calender-style"
              />
              <div className="contests-event-date">
                {formatDateDDMonthYYYY(item.start_date + ' ' + item.start_time)}
              </div>
            </div>
          </div>
          <div className="contests-title">{item.title}</div>
          <div className="contests-summary">{item.summary}</div>
        </div>
      );
    });

  return (
    <div>
      {showEmptyContentView && !contestArray.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          <div className="row">{contestArray}</div>
          <ActivityLoader visible={loading} />
        </div>
      )}
    </div>
  );
};

export default React.memo(EsgContestsTab);
