import React from 'react';
import Divider from '../../../common/ui/divider/Divider';
import { formatDateDDMonthYYYY } from '../../../utils/common';

const PointsSummaryListItem = ({ item, onPressItem }) => {
  return (
    <div
      className={`mt-2 mb-2 ${item.title === 'Redeemed' && 'common-cursor-pointer'}`}
      onClick={() => onPressItem(item)}>
      <div className="cheers-date-view-style justify-content-between">
        <div>
          <div className="cheers-points-title">{item.title}</div>
          <div className="cheers-date-time">{formatDateDDMonthYYYY(item.datetime)}</div>
        </div>
        <div className="cheers-date-view-style">
          {item.title === 'Credited' ? (
            <div className="cheers-points-summary-credit">{`+ ${item.points}`}</div>
          ) : (
            <div className="cheers-points-summary-debit">{`- ${item.points}`}</div>
          )}
          <div className="ml-1 cheers-pts-text">pts</div>
        </div>
      </div>
      <Divider style="mt-2" />
    </div>
  );
};

export default PointsSummaryListItem;
