import React, {
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { EditorState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, {
  defaultSuggestionsFilter,
  MentionData,
  MentionPluginTheme,
} from '@draft-js-plugins/mention';
import editorStyles from './CustomMentionEditor.module.css';
import mentionsStyles from './MentionsStyles.module.css';
import { extractDataFromEditorState } from '../../../common/components/hashtagsAndMentionsInput/hashtagUtils';
import 'draft-js/dist/Draft.css';
export interface EntryComponentProps {
  className?: string;
  onMouseDown(event: MouseEvent): void;
  onMouseUp(event: MouseEvent): void;
  onMouseEnter(event: MouseEvent): void;
  role: string;
  id: string;
  'aria-selected'?: boolean | 'false' | 'true';
  theme?: MentionPluginTheme;
  mention: MentionData;
  isFocused: boolean;
  searchValue?: string;
}

function MentionItem(props: EntryComponentProps): ReactElement {
  const { mention, theme, searchValue, isFocused, ...parentProps } = props;

  return (
    <div {...parentProps}>
      <div className={theme?.mentionSuggestionsEntryContainer}>
        <div className={theme?.mentionSuggestionsEntryContainerLeft}>
          <img
            src={mention.avatar}
            className={theme?.mentionSuggestionsEntryAvatar}
            role="presentation"
          />
        </div>

        <div className={theme?.mentionSuggestionsEntryContainerRight}>
          <div className={theme?.mentionSuggestionsEntryText}>{mention.name}</div>
        </div>
      </div>
    </div>
  );
}

export default function CustomMentionEditor(props): ReactElement {
  const {
    mentions,
    onChangeChatTextHandler,
    editorState,
    setUserMentionList,
    style,
    onSetEditorState,
  } = props;
  const ref = useRef();
  const [editorState1, setEditorState] = useState(editorState || EditorState.createEmpty());
  const [open, setOpen] = useState(false);
  const [mentionsList, setMentionsList] = useState(mentions);
  const [suggestions, setSuggestions] = useState(mentions);
  const [selectedUserList, setSelectedUserList] = useState([...mentions]);
  const [text, setText] = useState('');
  useEffect(() => {
    setMentionsList(mentions);
    setEditorState(editorState);
  }, [mentions, editorState]);
  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      theme: mentionsStyles,
      mentionPrefix: '',
      supportWhitespace: true,
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  function replaceNames(paragraph, objects) {
    const regexPattern = objects.map((obj) => `(?<!\\[)\\b${obj.name}\\b(?!\\()`).join('|');
    const regex = new RegExp(regexPattern, 'gi');
    paragraph = paragraph.replace(regex, (match) => {
      const matchedObject = objects.find((obj) => obj.name.toLowerCase() === match.toLowerCase());
      if (matchedObject) {
        return `@[${matchedObject.name}](${matchedObject.uid})`;
      }
      return match;
    });

    return paragraph;
  }

  const onChange = useCallback((_editorState: EditorState) => {
    setEditorState(_editorState);
    onSetEditorState(_editorState);
    const result = extractDataFromEditorState(_editorState);
    onChangeChatTextHandler(replaceNames(result.description, selectedUserList));
  }, []);
  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);
  const onSearchChange = useCallback(
    ({ value }: { value: string }) => {
      setSuggestions(defaultSuggestionsFilter(value, mentionsList));
    },
    [mentionsList]
  );

  return (
    <div
      className={style == 'edit' ? editorStyles.editor_edit : editorStyles.editor}
      onClick={() => {
        ref.current.focus();
      }}>
      <Editor
        editorKey={'editor'}
        editorState={editorState1}
        onChange={onChange}
        plugins={plugins}
        ref={ref}
        placeholder="Type a message.."
      />
      <MentionSuggestions
        open={open}
        onOpenChange={onOpenChange}
        suggestions={suggestions}
        onSearchChange={onSearchChange}
        onAddMention={(value) => {
          setSelectedUserList((prevState) => {
            prevState.push(value);
            return prevState;
          });
          setUserMentionList((prevState) => {
            prevState.push(value);
            return prevState;
          });
        }}
        entryComponent={MentionItem}
        popoverContainer={({ children }) => <div>{children}</div>}
      />
    </div>
  );
}
