import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../../utils/accessibility';
import { formatStartAndEndDate, replaceUnicodeSetWithBullet } from '../../../../utils/common';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  NAVIGATION_ROUTES,
} from '../../../constants/AppConstants';
import { Modal } from 'react-bootstrap';
import ReactStars from 'react-rating-stars-component';
import HtmlView from '../../../ui/html_view/HTMLView';
import CustomButton from '../../../ui/custom_button/CustomButton';
import Divider from '../../../ui/divider/Divider';
import InfoLinksItem from '../../../../screen/breakouts/components/InfoLinksItem';
import { selectEmployeeData } from '../../../../state/EmployeeData';
import InfoVideoItemBreakouts from '../../../../screen/breakouts/components/InfoVideoItem breakouts';
import SpeakersAttendeesListItem from '../../../../screen/breakouts/components/SpeakersAttendeesListItem';

const SessionDetailTab = ({
  data,
  fromBreakoutsSessionDetailRoute,
  callSubmitSessionRatingApi,
  identifier,
}) => {
  const history = useHistory();
  const accessibilityData = useSelector(selectAccessibilityData);
  const [showRatingDialog, setShowRatingDialog] = useState(false);
  const [ratingValue, setRatingValue] = useState(5);
  const employeeData = useSelector(selectEmployeeData);
  const isEmployeeSpeaker = data.speakers.find(
    (employee) => employee.employee_id === employeeData.employee_id
  );

  const ratingCompleted = (ratingStar) => {
    setRatingValue(ratingStar);
  };

  const onPressLink = (linkItem) => {
    let win = window.open(linkItem.url, '_blank');
    win.focus();
  };

  const onPressPdf = (pdfItem) => {
    let win = window.open(pdfItem.url, '_blank');
    win.focus();
  };

  const onPressVideo = (videoItem) => {
    history.push(`${NAVIGATION_ROUTES.FULL_SCREEN_VIDEO}/${videoItem.url}`);
  };

  const renderYoutubeItem = (data, index) => {
    return <InfoLinksItem item={data} isYtUrls={true} onPress={onPressVideo} key={`${index}`} />;
  };

  const renderLinksItem = (data, index) => {
    return <InfoLinksItem item={data} isPdfUrls={false} onPress={onPressLink} key={`${index}`} />;
  };

  const renderPdfItem = (data, index) => {
    return <InfoLinksItem item={data} isPdfUrls={true} onPress={onPressPdf} key={`${index}`} />;
  };

  const ratingDialog = () => {
    return (
      <div>
        <div className="text-center breakouts-session-text">Session</div>
        <div className="text-center mt-2 breakouts-selected-session-text">{data.title}</div>
        <hr className="mt-3 mb-3" />
        <div className="text-center breakouts-your-rating-text">Your Rating</div>
        <div className="breakouts-team-micro-view">
          <ReactStars count={5} onChange={ratingCompleted} size={40} activeColor="#ffd700" />
        </div>
        <CustomButton
          buttonStyle="breakouts-button-style mt-3"
          onClick={() => callSubmitSessionRatingApi(data.session_id, ratingValue)}>
          Submit
        </CustomButton>
      </div>
    );
  };

  return (
    <div className="main-content-container">
      <Modal
        show={showRatingDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowRatingDialog(false)}>
        <Modal.Body>{ratingDialog()}</Modal.Body>
      </Modal>
      {!isEmployeeSpeaker && (
        <div className="d-flex flex-row-reverse mi-hr-activity-btn-container">
          <CustomButton
            buttonStyle="breakouts-button-style mr-0"
            onClick={() => {
              history.push(NAVIGATION_ROUTES.ASK_SESSION_QUESTION, {
                data: [data],
                fromBreakoutsSessionDetailRoute: fromBreakoutsSessionDetailRoute ? true : false,
                identifier: identifier,
              });
            }}>
            Ask Question
          </CustomButton>
        </div>
      )}
      <div className="material-card-no-hover p-3">
        <div className="breakouts-card-main-title">{data.title}</div>
        <div className="breakouts-agenda-date-text">
          {formatStartAndEndDate(data.start_datetime, data.end_datetime)}
        </div>
        <div className="breakouts-rating-view mt-2">
          {!!data.ratings.avg_rate && (
            <div className="new-joinees-higher-levels-card-rate-container d-inline-block p-2 mr-3">
              <div className="d-flex align-items-center ">
                <p className="new-joinees-higher-levels-card-rate-text mr-2 mb-0">
                  {data.ratings.avg_rate}
                </p>
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_RATE_STAR,
                    ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                  )}
                  className="new-joinees-higher-levels-session-rate-icon"
                />
              </div>
            </div>
          )}
          <CustomButton
            buttonStyle="breakouts-button-style mr-3"
            onClick={() => {
              setShowRatingDialog(true);
            }}>
            Rate
          </CustomButton>
        </div>
        <Divider style="mt-3 mb-3" />
        <div className="breakouts-card-main-title mb-3">Description</div>
        {fromBreakoutsSessionDetailRoute ? (
          <HtmlView html={data.summary} htmlStyle="breakout-content-html" />
        ) : (
          <div className="breakouts-session-description-text">
            {replaceUnicodeSetWithBullet(data.summary)}
          </div>
        )}
        {fromBreakoutsSessionDetailRoute &&
          (data.media.pdf.length > 0 ||
            data.media.text.length > 0 ||
            data.media.youtube.length > 0) && (
            <>
              <Divider style="mt-3 mb-3" />
              {data.media.text.length > 0 && (
                <>
                  <p className="breakouts-info-title mt-3 mb-3">Media</p>
                  {data.media.text.map((data, index) => renderLinksItem(data, index))}
                </>
              )}
              {data.media.pdf.length > 0 && (
                <>
                  <p className="breakouts-info-title mt-3 mb-3">Documents</p>
                  {data.media.pdf.map((data, index) => renderPdfItem(data, index))}
                </>
              )}
              {data.media.youtube.length > 0 && (
                <>
                  <p className="breakouts-info-title mt-3 mb-3">Video</p>
                  {data.media.youtube.map((data, index) => renderYoutubeItem(data, index))}
                </>
              )}
            </>
          )}
        <Divider style="mt-3 mb-3" />
        <div className="d-flex">Speakers</div>
        {data.speakers.map((item, index) => {
          return <SpeakersAttendeesListItem item={item} key={`${index}`} />;
        })}
      </div>
    </div>
  );
};

export default React.memo(SessionDetailTab);
