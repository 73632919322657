import React, { useRef } from 'react';
import { NAVIGATION_ROUTES } from '../../constants/AppConstants';

const CustomButton = (props) => {
  const hasOnClickEventStartedRef = useRef(false);

  const onClickHandler = async (e) => {
    hasOnClickEventStartedRef.current = true;
    if (props.onClick) {
      await props.onClick(e);
    }
    hasOnClickEventStartedRef.current = false;
  };

  const isDisabled = () => {
    if (props.disabled) {
      return true;
    }
    if (props.type === 'submit' && props.showLoader) {
      return true;
    } else if (props.showLoader && hasOnClickEventStartedRef.current) {
      return true;
    }
    return false;
  };

  const shouldShowLoader = () => {
    if (props.type === 'submit' && props.showLoader) {
      return true;
    } else if (props.showLoader && hasOnClickEventStartedRef.current) {
      return true;
    }
    return false;
  };

  return (
    <div className={`${props.buttonMainContainer}`}>
      <button
        disabled={isDisabled()}
        type={props.type ? props.type : 'button'}
        className={
          props?.fromRoute === NAVIGATION_ROUTES.ASK_ORG
            ? `${props.buttonStyle} common-custom-button-container ask-ml-custom-button`
            : props?.isSecondaryButton
            ? `${props.buttonStyle} common-custom-button-container common-custom-button-secondary`
            : `${props.buttonStyle} common-custom-button-container common-custom-button-primary`
        }
        onClick={onClickHandler}>
        <div
          className={
            props?.fromRoute === NAVIGATION_ROUTES.ASK_ORG
              ? 'ask-org-common-custom-button-inner-container'
              : 'common-custom-button-inner-container'
          }>
          {shouldShowLoader() && (
            <span
              className="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
            />
          )}
          {props.children}
        </div>
      </button>
    </div>
  );
};

export default CustomButton;
