import React from 'react';
import { useLocation } from 'react-router';
import useWindowDimensions from '../../../custom_hooks/useWindowDimensions';
import { CURRENT_MOBILE_APP_VERSION, NAVIGATION_ROUTES } from '../../constants/AppConstants';
import CustomButton from '../custom_button/CustomButton';
import Divider from '../divider/Divider';

const DownloadAppPopup = () => {
  const location = useLocation();
  const { width } = useWindowDimensions();

  const onPressAndroidHandler = () => {
    window.open(process.env.REACT_APP_ANDROID_APP_INSTALL_URL, '_self');
  };

  const onPressIosHandler = () => {
    window.open(process.env.REACT_APP_IOS_APP_INSTALL_URL, '_self');
  };

  if (
    width < 769 &&
    !location.pathname.includes(NAVIGATION_ROUTES.BROADCASTS + '/')
    // && !location.pathname.includes(NAVIGATION_ROUTES.PUBLIC_PROFILE)
  ) {
    document.body.style.overflow = 'hidden';
    return (
      <div className="common-download-app-popup-main-container">
        <div className="common-download-app-popup-container">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-12 text-center">
                <div>
                  <h4 className="common-download-app-popup-title mb-5">
                    Download Microland<span className="common-primary-color">One</span> App
                  </h4>
                  <p className="common-download-app-popup-subtitle">
                    For first time download of Microland
                    <span className="common-primary-color">One</span> App from your mobile device,
                    follow below instructions
                  </p>
                  <li className="common-download-app-popup-normal-text">
                    Launch mobile browser and navigate to{' '}
                    <a href={process.env.REACT_APP_BASE_URL}>
                      <u>{process.env.REACT_APP_BASE_URL}</u>
                    </a>
                  </li>
                  <li className="common-download-app-popup-normal-text">
                    Login with your credentials
                  </li>
                </div>

                <Divider style="mt-4 mb-4" />

                <div>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <p className="common-download-app-popup-title m-0">iOS</p>
                    <CustomButton onClick={onPressIosHandler}>
                      Install (v{CURRENT_MOBILE_APP_VERSION})
                    </CustomButton>
                  </div>
                  <p className="common-download-app-popup-subtitle mb-2">
                    Click{' '}
                    <span className="common-primary-background-color text-white">
                      Install (v{CURRENT_MOBILE_APP_VERSION})
                    </span>
                  </p>
                  <p className="common-download-app-popup-subtitle">
                    Post Installation Navigate: Settings -{'>'} General -{'>'} Device Management -
                    {'>'} Enterprise App, Click on Microland Ltd. and Trust.
                  </p>
                </div>

                <Divider style="mt-4 mb-4" />

                <div>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <p className="common-download-app-popup-title m-0">Android</p>
                    <CustomButton onClick={onPressAndroidHandler}>
                      Install (v{CURRENT_MOBILE_APP_VERSION})
                    </CustomButton>
                  </div>
                  <p className="common-download-app-popup-subtitle mb-0">
                    Click{' '}
                    <span className="common-primary-background-color text-white">
                      Install (v{CURRENT_MOBILE_APP_VERSION})
                    </span>
                  </p>
                  <p className="common-download-app-popup-subtitle mb-0">OR</p>
                  <p className="common-download-app-popup-subtitle">
                    Alternatively, Go to Google Play store -{'>'} Search for Microland
                    <span className="common-primary-color">One</span> -{'> '}
                    Install
                  </p>
                </div>

                <Divider style="mt-4 mb-4" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    document.body.style.overflow = 'visible';
    return null;
  }
};

export default DownloadAppPopup;
