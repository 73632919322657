import React from 'react';

const ConnectingBorder = (props) => {
  return (
    <div>
      <div className="common-profile-manager-border-vertical-container">
        <div className="common-profile-manager-border-vertical common-profile-orgstructure-border" />
      </div>
      <div className="common-profile-manager-horizontal-border-container">
        <div className="common-profile-manager-horizontal-border common-profile-orgstructure-border" />
      </div>
    </div>
  );
};

export default ConnectingBorder;
