import React, { useRef, useCallback } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { ERROR_MESSAGES, REQUEST_TYPE } from '../../common/constants/AppConstants';
import { selectSingleLineTextSize } from '../../state/MasterData';
import { Formik } from 'formik';
import * as yup from 'yup';
import CustomTextInput from '../../common/ui/custom_text_input/CustomTextInput';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { apiRequest } from '../../services/Service';
import { SEND_BUSINESS_CARD } from '../../services/ApiUrls';
import { showToast } from '../../utils/common';

const ShareEmailModal = (props) => {
  const maxSingleLineCharLimit = useSelector(selectSingleLineTextSize);
  const sendEmailFormRef = useRef();
  const dispatch = useDispatch();
  const progressLoadingState = useSelector(selectProgressLoadingState);

  const sendEmailValidationSchema = yup.object().shape({
    email: yup.string().required(ERROR_MESSAGES.UPDATE_EMAIL).email(ERROR_MESSAGES.INVALID_EMAIL),
    subject: yup.string().required(ERROR_MESSAGES.INVALID_SUBJECT),
  });

  const onPressSubmitHandler = (formValues) => {
    sendEmailApi(formValues);
  };

  const renderModal = () => {
    return (
      <div>
        <div className="modal-header facilities-custom-modal-header d-flex justify-content-center align-items-center">
          <h6 className="modal-title facilities-custom-modal-title w-100 text-center">
            {'Share wallpaper'}
          </h6>
          <button
            type="button"
            className="close"
            onClick={() => {
              props.hideModal();
            }}>
            &times;
          </button>
        </div>
        <Formik
          validationSchema={sendEmailValidationSchema}
          onSubmit={onPressSubmitHandler}
          innerRef={sendEmailFormRef}
          initialValues={{
            email: '',
            subject: '',
          }}>
          {(formikProps) => (
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                props.logEvent();
                if (formikProps.submitCount > 0 && !formikProps.isValid) {
                  const formErrors = Object.values(formikProps.errors);
                  if (formErrors.length > 0) {
                    showToast(formErrors[0]);
                  }
                } else {
                  if (!formikProps.isValid) {
                    showToast(ERROR_MESSAGES.FORM_ERRORS);
                  }
                  formikProps.handleSubmit();
                }
              }}>
              <div>
                <div className="d-flex align-items-center w-100 px-3 mt-3">
                  <h6 className="text-center mr-5">{'To'}</h6>
                  <div className="w-100 ml-1">
                    <CustomTextInput
                      name="email"
                      isTitle={false}
                      maxLength={maxSingleLineCharLimit}
                      style="facilities-text-input w-100"
                      placeholder="Enter email"
                      onChange={formikProps.handleChange}
                      errorMessage={formikProps.errors.email}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center w-100 px-3">
                  <h6 className="text-center mr-3">{'Subject'}</h6>
                  <div className="w-100">
                    <CustomTextInput
                      name="subject"
                      isTitle={false}
                      maxLength={maxSingleLineCharLimit}
                      style="facilities-text-input w-100"
                      placeholder="Enter subject"
                      onChange={formikProps.handleChange}
                      errorMessage={formikProps.errors.subject}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center w-100 px-3">
                  <h6 className="text-center mr-4">{'Body'}</h6>
                  <div className="w-100 facilities-image-input ml-2">
                    <img
                      src={props?.imageUrl}
                      className="facilities-business-card-image px-2 py-1 h-100 w-100"
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-end m-3">
                  <button
                    type="button"
                    className="common-custom-button-senary py-2 px-3 text-capitalize"
                    onClick={() => {
                      props.hideModal();
                    }}>
                    {'Cancel'}
                  </button>
                  <button
                    type="submit"
                    className="common-custom-button-primary py-2 px-3"
                    disabled={progressLoadingState.isProgressLoading}>
                    {'Send'}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  const sendEmailApi = useCallback(
    async (formValues) => {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      try {
        const formData = new URLSearchParams(formValues);
        formData.append('type', '_WALLPAPER');
        formData.append('image_path', props.imageUrl);
        const apiResponse = await apiRequest(SEND_BUSINESS_CARD, REQUEST_TYPE.POST, formData);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.message) {
              props.hideModal();
              showToast(apiResponse.response.message);
            }
          }
        }
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      } catch (err) {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    },
    [dispatch, props.imageUrl]
  );

  return (
    <div>
      <Modal
        show={props.showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => props.hideModal()}>
        <Modal.Body>{renderModal()}</Modal.Body>
      </Modal>
    </div>
  );
};

export default ShareEmailModal;
