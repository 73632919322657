import React from 'react';

const HtmlView = (props) => {
  const contentClickHandler = (e) => {
    const targetLink = e.target.closest('a');
    if (!targetLink) return;
    if (isExternalLink(targetLink.href)) {
      e.preventDefault();
      let win = window.open(targetLink.href, '_blank');
      win.focus();
    } else {
      return false;
    }
  };

  const isExternalLink = (targetLink) => {
    return new URL(targetLink).origin !== location.origin;
  };

  const { html, htmlStyle, onClick = contentClickHandler } = props;

  return (
    <div
      dangerouslySetInnerHTML={{ __html: html }}
      className={`${htmlStyle} common-embebded-image-style table-style`}
      onClick={onClick}
    />
  );
};

export default HtmlView;
