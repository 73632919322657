import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  FEATURE_TYPE,
  LOADING_MESSAGES,
  MI_HR_CONTENT_IDENTIFIERS,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import { COMMENT_BY_ID, GET_ESG_POLLS_AND_SURVEYS, POLLS_BY_ID } from '../../../services/ApiUrls';
import { apiRequest } from '../../../services/Service';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { saveProgressLoadingState, selectShowEmptyContentView } from '../../../state/UIState';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { showToast } from '../../../utils/common';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import PollsListItem from '../components/PollsListItem';
import SurveysListItem from '../components/SurveysListItem';
import { selectAccessData } from '../../../state/UserAccessData';

const MldayPollsAndSurveysTab = (props) => {
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const [miHrItemList, setMiHRItemList] = useState([]);
  const [loading, setLoading] = useState(true);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  const history = useHistory();
  const [dataLength, setDataLength] = useState(0);
  const loadMoreFlag = useRef(true);
  const pageId = useRef(1);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [showEmpty, setShowEmpty] = useState(false);
  const accessData = useSelector(selectAccessData);
  const [showLikeButton, setShowLikeButton] = useState(true);
  const [showCommentButton, setShowCommentButton] = useState(true);
  const [showParticipate, setShowParticipate] = useState(true);
  const [showViewResults, setShowViewResults] = useState(true);
  const pollsAccessData = accessData?.data?.find((item) => item.feature_key === '_polls');

  useEffect(() => {
    const actions = pollsAccessData?.actions;
    setShowViewResults(actions?.includes('_viewResults'));
    setShowLikeButton(actions?.includes('_like'));
    setShowCommentButton(actions?.includes('_comment'));
    setShowParticipate(actions?.includes('_participate'));
  }, [pollsAccessData?.actions]);

  useEffect(() => {
    if (pageId.current !== 1) {
      loadMoreFlag.current = false;
      setLoadMoreBtn(false);
    }
  }, [dataLength]);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ESG_TAB_VISIT,
      props?.item?.title ?? '',
      ANALYTICS_ITEM_NAMES.ESG
    );
  }, [props?.item?.title]);

  const callPollsAndSurveysApi = useCallback(async () => {
    setError(false);
    setLoadMoreBtn(true);
    setLoading(true);
    setShowEmpty(false);
    try {
      const get_params = {
        page_id: pageId.current,
        channel_id: 24,
        sub_channel_id: 34,
      };
      const apiResponse = await apiRequest(GET_ESG_POLLS_AND_SURVEYS, REQUEST_TYPE.GET, get_params);
      if (pageId.current === 1) {
        setMiHRItemList([]);
      }
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse?.response?.status === true) {
          if (apiResponse?.response?.data?.feed) {
            const data = apiResponse?.response?.data?.feed ?? [];
            setDataLength(data?.length);
            if (data?.length > 0) {
              setMiHRItemList((oldArray) => [...oldArray, ...data]);
            } else {
              if (data.length === 0 && pageId.current === 1) {
                loadMoreFlag.current = false;
                setLoadMoreBtn(false);
                setShowEmpty(true);
              } else {
                setShowEmpty(false);
              }
            }
          }
        }
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      setError(true);
      setShowEmpty(true);
    }
  }, []);

  useEffect(() => {
    callPollsAndSurveysApi();
  }, [callPollsAndSurveysApi]);

  const loadMore = () => {
    if (loadMoreFlag.current) {
      pageId.current = pageId.current + 1;
      callPollsAndSurveysApi();
    }
  };

  const emptyListView = () => {
    return (
      <DefaultContentView
        message={NO_DATA_TEXT.NO_DATA_FOUND}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  const onPressViewResult = (item) => {
    history.push(NAVIGATION_ROUTES.POLLS_RESULTS, {
      pollId: item.poll_id,
    });
  };

  const submitVote = async (pollId, optionId) => {
    logEvent(ANALYTICS_EVENT_TYPES.POLLS_VOTE, pollId, ANALYTICS_ITEM_NAMES.POLLS);
    dispatch(saveProgressLoadingState({ isProgressLoading: true }));
    try {
      const formData = new URLSearchParams();
      formData.append('option_id', optionId);
      const apiResponse = await apiRequest(
        POLLS_BY_ID + pollId + '/vote',
        REQUEST_TYPE.POST,
        formData
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          showToast(apiResponse.response.message);
          callPollsAndSurveysApi();
        }
      }
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressParticipate = (item) => {
    history.push(NAVIGATION_ROUTES.SURVEYS_PARTICIPATE, {
      surveyId: item.survey_id,
    });
  };

  const callPollLikeApi = async (referId) => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
      })
    );

    try {
      const params = new URLSearchParams();
      params.append('type', FEATURE_TYPE.POLL);

      const apiResponse = await apiRequest(
        COMMENT_BY_ID + referId + '/like',
        REQUEST_TYPE.POST,
        params
      );

      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );

      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            const message = apiResponse.response.message;

            if (message) {
              showToast(message);
              let updatedPolls = miHrItemList.map((pollItem) =>
                pollItem.poll_id === referId
                  ? {
                      ...pollItem,
                      liked: data.liked,
                      like_count: data.like_count,
                    }
                  : pollItem
              );

              setMiHRItemList(updatedPolls);
            }
          }
        }
      }
    } catch (err) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      console.error(err);
    }
  };

  const callSurveyLikeApi = async (referId) => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
      })
    );

    try {
      const params = new URLSearchParams();
      params.append('type', FEATURE_TYPE.SURVEY);

      const apiResponse = await apiRequest(
        COMMENT_BY_ID + referId + '/like',
        REQUEST_TYPE.POST,
        params
      );

      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );

      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            const message = apiResponse.response.message;

            if (message) {
              showToast(message);
              let updatedSurveys = miHrItemList.map((surveyItem) =>
                surveyItem.survey_id === referId
                  ? {
                      ...surveyItem,
                      liked: data.liked,
                      like_count: data.like_count,
                    }
                  : surveyItem
              );

              setMiHRItemList(updatedSurveys);
            }
          }
        }
      }
    } catch (err) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      console.error(err);
    }
  };

  const onPressPollLikeButton = (item) => {
    logEvent(ANALYTICS_EVENT_TYPES.POLL_LIKE, item.poll_id.toString(), ANALYTICS_ITEM_NAMES.LIKE);
    callPollLikeApi(item.poll_id);
  };

  const onPressSurveyLikeButton = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.SURVEY_LIKE,
      item.survey_id.toString(),
      ANALYTICS_ITEM_NAMES.LIKE
    );
    callSurveyLikeApi(item.survey_id);
  };

  const onPressPollCommentButton = (item) => {
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: item.poll_id,
      likeType: FEATURE_TYPE.POLL,
      fromRoute: NAVIGATION_ROUTES.POLLS_AND_SURVEYS,
    });
  };

  const onPressSurveyCommentButton = (item) => {
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: item.survey_id,
      likeType: FEATURE_TYPE.SURVEY,
      fromRoute: NAVIGATION_ROUTES.POLLS_AND_SURVEYS,
    });
  };

  return (
    <div>
      <div className="row mt-3">
        {miHrItemList.map((item, index) => {
          switch (item?.type) {
            case MI_HR_CONTENT_IDENTIFIERS.POLL:
              return (
                <PollsListItem
                  item={item}
                  key={`${index}`}
                  onPressViewResult={onPressViewResult}
                  submitVote={submitVote}
                  onPressLikeButton={onPressPollLikeButton}
                  onPressCommentButton={onPressPollCommentButton}
                  showLikeButton={showLikeButton}
                  showCommentButton={showCommentButton}
                  showParticipate={showParticipate}
                  showViewResults={showViewResults}
                />
              );

            case MI_HR_CONTENT_IDENTIFIERS.SURVEY:
              return (
                <SurveysListItem
                  item={item}
                  key={`${index}`}
                  onPressParticipate={onPressParticipate}
                  onPressLikeButton={onPressSurveyLikeButton}
                  onPressCommentButton={onPressSurveyCommentButton}
                  showLikeButton={showLikeButton}
                  showCommentButton={showCommentButton}
                  showParticipate={showParticipate}
                />
              );
            default:
              break;
          }
        })}
      </div>

      {showEmpty && !loading ? (
        <>{emptyListView()}</>
      ) : (
        <div>
          <ActivityLoader visible={loading} />
          {loadMoreBtn && (
            <div className="mb-4 mt-4">
              <LoadMoreBtn onClick={loadMore} isLoading={loading} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(MldayPollsAndSurveysTab);
