import React from 'react';

const CustomProgressBar = (props) => {
  const { progressBarStyle, progressBarInnerStyle } = props;

  return (
    <div className={`polls-progress-bar-container ${progressBarStyle}`}>
      <div
        className={`polls-progress-bar-inner-container ${progressBarInnerStyle}`}
        style={{
          ...{
            width: `${(props.selectedChecklistItemCount / props.totalChecklistItemCount) * 100}%`,
          },
        }}
      />
      <div className="text-center polls-percentage-view">{props.children}</div>
    </div>
  );
};

export default CustomProgressBar;
