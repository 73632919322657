import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NO_DATA_TEXT,
} from '../../../common/constants/AppConstants';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';

const SmartDeliveryTab = (props) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const contentConfig = props?.item?.content_config ?? {};

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.GCDO_TAB_VISIT,
      props.item.category_id,
      ANALYTICS_ITEM_NAMES.GCDO
    );
  }, [props?.item?.category_id]);

  const emptyListView = () => {
    return (
      <DefaultContentView
        message={NO_DATA_TEXT.NO_DATA_FOUND}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  return (
    <div>
      {contentConfig?.show_content === false ||
        (contentConfig?.html_content?.length === 0 && emptyListView())}
      <HtmlView html={contentConfig?.html_content} htmlStyle="new-joinees-description-conatiner" />
    </div>
  );
};

export default SmartDeliveryTab;
