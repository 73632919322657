import React from 'react';
import { SiGoogleplay, SiAppstore } from 'react-icons/si';

import CustomButton from '../../common/ui/custom_button/CustomButton';
import appMockup from '../../assets/images/appMockup.png';
import Divider from '../../common/ui/divider/Divider';

const DownloadAppScreen = () => {
  const onPressAndroidHandler = () => {
    window.open(process.env.REACT_APP_ANDROID_APP_INSTALL_URL, '_self');
  };

  const onPressIosHandler = () => {
    window.open(process.env.REACT_APP_IOS_APP_INSTALL_URL, '_self');
  };

  return (
    <div className="container-fluid p-0">
      <div className="container pt-5">
        <div className="row">
          <div className="col-12 col-sm-7 pt-5 mb-3">
            <div>
              <h1>
                Microland<span className="common-primary-color">One</span>
              </h1>
              <p className="lead">
                Navigate to{' '}
                <a href={process.env.REACT_APP_BASE_URL}>{process.env.REACT_APP_BASE_URL}</a> on
                your mobile device and login with your credentials. Follow the steps below depending
                on your mobile platform
              </p>
            </div>

            <Divider style="mt-3 mb-3" />

            <div>
              <h3>iOS</h3>
              <p>
                Click on the button below to install MirolandOne on your iDevice. Post Installation,
                navigate to Settings General -{'>'} Device Management -{'>'} Enterprise App, Click
                on Microland Ltd. and Trust.
              </p>

              <CustomButton onClick={onPressIosHandler} buttonStyle="promo-button-style">
                <SiAppstore size="1em" color="#fff" className="mr-1" />
                iOS
              </CustomButton>
            </div>

            <Divider style="mt-3 mb-3" />

            <div>
              <h3>Android</h3>
              <p>
                Click on the button below to install MirolandOne on your Android device. You will be
                redirected to Google Play store download page of MicrolandOne.
              </p>
              <CustomButton onClick={onPressAndroidHandler} buttonStyle="promo-button-style">
                <SiGoogleplay size="1em" color="#fff" className="mr-1" />
                Android
              </CustomButton>
            </div>
          </div>

          <div className="col-12 col-sm-1" />
          <div className="col-12 col-sm-4 d-none d-sm-flex d-flex align-items-center">
            <img className="promo-download-app-image" src={appMockup} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadAppScreen;
