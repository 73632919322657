import React, { useEffect, useState } from 'react';
import {
  ANALYTICS_EVENT_TYPES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { apiRequestEStore } from '../../../services/Service';
import { resetLoaderState } from '../../../state/UIState';
import { useDispatch, useSelector } from 'react-redux';
import { CATEGORY_LIST } from '../../../services/ApiUrls';
import { selectEStoreConfig } from '../../../state/MasterData';
import { logEvent2 } from '../../../utils/FirebaseAnalyticsUtils';

const Collections = () => {
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();
  const estoreConfig = useSelector(selectEStoreConfig);

  useEffect(() => {
    const fatchCollections = async () => {
      try {
        const apiResponse = await apiRequestEStore(
          `${CATEGORY_LIST}?searchCriteria[pageSize]=100&searchCriteria[currentPage]=1`,
          REQUEST_TYPE.GET
        );

        if (Object.keys(apiResponse).length) {
          if (apiResponse.response && apiResponse.response.items?.length > 0) {
            const tempCategories = apiResponse.response.items?.map((item) => {
              const image =
                item.custom_attributes?.find((attr) => attr.attribute_code === 'image')?.value ??
                '';
              return { id: item.id, name: item.name, image };
            });
            const categoriesTemp = tempCategories.filter((item) => item.id !== 1);
            setCategories(categoriesTemp);
          }
        }
        dispatch(resetLoaderState());
      } catch (err) {
        dispatch(resetLoaderState());
      }
    };
    fatchCollections();
  }, [dispatch]);

  const handleCategory = (item) => {
    logEvent2({
      eventType: ANALYTICS_EVENT_TYPES.ESTORE_COLLECTION_CLICK,
      categoryId: item.id,
      categoryName: item.name,
    });
    history.push(`${NAVIGATION_ROUTES.MICRO_STORE_ALL_PRODUCTS_SCREEN}/${item.id}`);
  };

  const renderCategory = (category, index) => {
    return (
      <div
        onClick={() => handleCategory(category)}
        key={index}
        className="estore-all-products-collection-item common-cursor-pointer">
        <img
          className="estore-collection-item-image"
          src={`${estoreConfig.category_base_url}${category.image}`}
        />
      </div>
    );
  };

  return (
    <div className="estore-collection-container">
      <div className="estore-colloections-title">COLLECTION</div>
      {categories.map(renderCategory)}
    </div>
  );
};

export default Collections;
