import React from 'react';
import { useSelector } from 'react-redux';
import { ACCESSIBILITY_HEADER_COMPONENTS } from '../../../common/constants/AppConstants';
import Divider from '../../../common/ui/divider/Divider';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getHeaderComponentUrl } from '../../../utils/accessibility';
import { formatDateDDMonthYYYYTimeAMPM } from '../../../utils/common';

const UpdatesListItem = ({ item, onPressCommentButton }) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  return (
    <div className="mb-3">
      <HtmlView html={item.summary} />
      <div className="microgive-created-on-text mt-1">{`${formatDateDDMonthYYYYTimeAMPM(
        item.created_on
      )}`}</div>
      <div className="row mt-2 mb-2 pl-2 microgive-outer-view">
        <img
          onClick={() => onPressCommentButton(item)}
          src={getHeaderComponentUrl(accessibilityData, ACCESSIBILITY_HEADER_COMPONENTS.COMMENT)}
          className="header-icon ml-2"
        />
        <div className="broadcast-icon-text">{item.comment_count}</div>
      </div>
      <Divider style="mb-3" />
    </div>
  );
};

export default UpdatesListItem;
