import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../common/constants/AppConstants';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { getUrlWithSpecificRendition } from '../../utils/common';

const AnniversaryGalleryFullImageScreen = ({ location }) => {
  const { baseUrl, cards, cardIndex } = location.state;
  const [index, setIndex] = useState(cardIndex);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.ANNIVERSARY_DAY_GALLERY_FULL_IMAGE,
      NAVIGATION_ROUTES.ANNIVERSARY_DAY_GALLERY_FULL_IMAGE
    );
  }, []);

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY} />
      <BackButton />
      <div className="common-container-md mx-auto">
        <div className="womens-day-gallery-full-container">
          <Carousel
            activeIndex={index}
            nextIcon={
              cards.length > 1 && <span aria-hidden="true" className="carousel-control-next-icon" />
            }
            prevIcon={
              cards.length > 1 && <span aria-hidden="true" className="carousel-control-prev-icon" />
            }
            onSelect={handleSelect}>
            {cards.map((item, index) => {
              return (
                <Carousel.Item key={`${index}`}>
                  <div className="d-flex justify-content-center">
                    <img
                      className="womens-day-gallery-full-image"
                      src={getUrlWithSpecificRendition(
                        baseUrl + 'images_ml33/images/',
                        SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
                        item.picture_path
                      )}
                    />
                  </div>
                  <Carousel.Caption>
                    <p>{item?.picture_caption}</p>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default AnniversaryGalleryFullImageScreen;
