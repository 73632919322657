import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import CommentBox from '../../../common/ui/comment_box/CommentBox';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import { WOMENS_DAY_DISCUSSION, WOMENS_DAY_POST_DISCUSSION } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../state/UIState';
import { isEmptyText, showToast } from '../../../utils/common';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import DiscussionTabItem from '../components/DiscussionTabItem';

const ConvoTab = (props) => {
  const [discussions, setDiscussions] = useState([]);
  const [commentText, setCommentText] = useState();
  const [showLoadMoreFlag, setShowLoadMoreFlag] = useState(true);
  const loadMoreFlag = useRef(true);
  const pageId = useRef(1);
  const dispatch = useDispatch();
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [loading, setLoading] = useState(true);
  const year = props?.year ?? '';

  const callDiscussionListApi = useCallback(async () => {
    if (loadMoreFlag.current) {
      setLoading(true);
      const params = {
        page_id: pageId.current,
        year: year,
      };
      try {
        const apiResponse = await apiRequest(WOMENS_DAY_DISCUSSION, REQUEST_TYPE.GET, params);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.data.feeds.length > 0) {
              if (pageId.current === 1) {
                setDiscussions([]);
              }
              setDiscussions((oldArray) => [
                ...apiResponse.response.data.feeds.reverse(),
                ...oldArray,
              ]);
              pageId.current = pageId.current + 1;
              loadMoreFlag.current = true;
            } else {
              loadMoreFlag.current = false;
              setShowLoadMoreFlag(false);
            }
          }
        }
        setLoading(false);
      } catch (err) {
        handleError(err, params, WOMENS_DAY_DISCUSSION, NAVIGATION_ROUTES.WOMENS_DAY);
        setLoading(false);
      }
    }
  }, [year]);

  useEffect(() => {
    callDiscussionListApi();
  }, [callDiscussionListApi]);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.WOMENS_DAY_CONVO_TAB,
      props.item,
      ANALYTICS_ITEM_NAMES.WOMENS_DAY
    );
  }, [props.item]);

  const callCommentApi = async () => {
    const params = new URLSearchParams();
    params.append('description', commentText);
    params.append('year', year);
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(WOMENS_DAY_POST_DISCUSSION, REQUEST_TYPE.POST, params);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setCommentText('');
          pageId.current = 1;
          loadMoreFlag.current = true;
          callDiscussionListApi();
        }
      }
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    } catch (err) {
      handleError(err, params, WOMENS_DAY_POST_DISCUSSION, NAVIGATION_ROUTES.WOMENS_DAY);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressSend = () => {
    if (!isEmptyText(commentText)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      callCommentApi();
    }
  };

  const onPressLoadPrevious = () => {
    callDiscussionListApi();
  };

  return (
    <div>
      <div className="womens-day-chart-list-style">
        <ActivityLoader visible={loading} />
        {showLoadMoreFlag && (
          <LoadMoreBtn
            onClick={onPressLoadPrevious}
            btnText="Load Previous"
            isLoading={progressLoadingState.isProgressLoading}
          />
        )}
        {discussions.map((item, index) => {
          return <DiscussionTabItem key={`${index}`} item={item} />;
        })}
      </div>
      <div className="womens-day-comment-box">
        <CommentBox
          getCommentText={onPressSend}
          onChangehandler={(val) => {
            setCommentText(val);
          }}
          commentText={commentText}
          showLoader={progressLoadingState.isProgressLoading}
        />
      </div>
    </div>
  );
};

export default ConvoTab;
