import React from 'react';
import { useHistory } from 'react-router-dom';
import { NAVIGATION_ROUTES, REQUEST_TYPE } from '../../../common/constants/AppConstants';
import { INCREASE_VIEW_COUNT_EXTERNAL_LINK } from '../../../services/ApiUrls';
import { apiRequest } from '../../../services/Service';
import GridView from './Grid';

const MLTVItem = (props) => {
  const history = useHistory();

  const onPressItem = async (item) => {
    if (item.content_load_type === '1') {
      try {
        await apiRequest(
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          REQUEST_TYPE.GET,
          {}
        );
      } catch (err) {
        //ignore if the view count request is not successful
        console.warn(err);
      }
      window.open(item.url);
    } else {
      history.push(`${NAVIGATION_ROUTES.MLTV}/${item.article_id}`);
    }
  };

  return <GridView feed={props.feed} onPressItem={onPressItem} />;
};

export default MLTVItem;
