import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NO_DATA_TEXT,
} from '../../../common/constants/AppConstants';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { selectWebBaseUrl } from '../../../state/MasterData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { getSecuredPdfUrl } from '../../../utils/common';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';

const EBookTab = (props) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const webUrl = useSelector(selectWebBaseUrl);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.SOM_EBOOK_TAB, props.item, ANALYTICS_ITEM_NAMES.SOM);
  }, [props.item]);

  const showEmptyListView = () => {
    return (
      <DefaultContentView
        message={NO_DATA_TEXT.NO_DATA_FOUND}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  return (
    <div>
      {props.data && props.data.encoded_url ? (
        <div className="iframe-container">
          <iframe src={getSecuredPdfUrl(webUrl, props.data.encoded_url)} allowFullScreen />
        </div>
      ) : (
        showEmptyListView()
      )}
    </div>
  );
};

export default EBookTab;
