import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { NAVIGATION_ROUTES } from '../../../common/constants/AppConstants';

import Divider from '../../../common/ui/divider/Divider';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { selectEmployeeData } from '../../../state/EmployeeData';

const DirectoryItem = (props) => {
  const history = useHistory();
  const employeeData = useSelector(selectEmployeeData);

  const onPressItem = (item) => {
    if (item.employee_id === employeeData.employee_id) {
      history.push(NAVIGATION_ROUTES.MY_PROFILE_DETAIL);
    } else {
      history.push(`${NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL}/${item.employee_id}`);
    }
  };

  const wallsArray =
    props.feed &&
    props.feed.map((item, index) => {
      return (
        <div key={`${index}`} className="common-cursor-pointer" onClick={() => onPressItem(item)}>
          <div
            className="row common_quick_suggestion_profile_container common-cursor-pointer"
            onClick={() => onPressItem(item)}>
            <UserProfilePhoto
              imageBaseUrl={item?.profile_picture?.base_url}
              imagePath={item?.profile_picture?.image_path}
              imageClass="common-user-profile-photo-sm"
              employeeId={item.employee_id}
            />
            <div className="common_quick_suggestion_title ml-3">
              {`${item?.first_name + ' '}${item?.middle_name + ' '}${item?.last_name}`}
            </div>
          </div>
          <Divider style="mt-2 mb-2" />
        </div>
      );
    });

  return <div>{wallsArray}</div>;
};

export default DirectoryItem;
