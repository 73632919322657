import React, { useState } from 'react';
import { formatDateDDMonthYYYY, formatTimeAMPM, isToday } from '../../../utils/common';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
} from '../../../common/constants/AppConstants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const AnswerView = (props) => {
  const history = useHistory();
  const [textString, setTextString] = useState(
    props.question.length <= 256 ? props.question : props.question.substring(0, 256) + '...'
  );
  const [textShown, setTextShown] = useState(false);

  const toggleReadFlag = () => {
    if (textShown) {
      setTextShown(false);
      setTextString(props.question.substring(0, 256) + '...');
    } else {
      setTextShown(true);
      setTextString(props.question);
    }
  };

  const handleHtmlClicks = (e) => {
    const target = e.target.getAttribute('href');
    if (target && target.startsWith('/')) {
      e.stopPropagation();
      e.preventDefault();
      if (target.includes(NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL)) {
        logEvent(
          ANALYTICS_EVENT_TYPES.USER_MENTION_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.USER_MENTION
        );
        history.push(target);
      } else {
        logEvent(
          ANALYTICS_EVENT_TYPES.HASHTAG_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.HASHTAG
        );
        history.push(target);
      }
    }
  };

  return (
    <div className="ceo-chat-main-question-div">
      <div className="ceo-chat-answer-row ceo-chat-answer-row-container">
        <div className="ceo-chat-answer-row">
          <div>
            <UserProfilePhoto
              imageBaseUrl={props.baseUrl}
              imagePath={props.imagePath}
              employeeId={props.employeeId}
              imageClass="common-user-profile-photo-sm"
            />
          </div>
          <div className="ceo-chat-employee-details">
            <div className="d-flex justify-content-between align-items-center">
              <p className="ceo-chat-question-emp-name-text mb-0">{props.employeeName}</p>
              {!!props.pronouns && (
                <p className="ceo-chat-question-emp-pronoun-text mb-0 ml-1">
                  {'(' + props.pronouns + ')'}
                </p>
              )}
            </div>
            <div className="ceo-chat-ques-emp-date-text">
              {isToday(props.askedOn)
                ? formatTimeAMPM(props.askedOn)
                : formatDateDDMonthYYYY(props.askedOn)}
            </div>
          </div>
        </div>
      </div>

      <div className="ceo-chat-main-question-div">
        <div className="ceo-chat-main-question-div ceo-chat-question-text">
          <HtmlView html={textString} onClick={handleHtmlClicks} />
          {props.question.length > 256 && (
            <span
              className={`common-cursor-pointer mt-2 ${
                props.isQuestion ? 'ceo-chat-read-style' : 'ceo-chat-answer-read-style'
              }`}
              onClick={() => {
                toggleReadFlag();
              }}>
              {textShown ? 'Read Less' : 'Read More'}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
export default AnswerView;
