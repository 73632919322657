import React, { useEffect, useState, useCallback } from 'react';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import BackButton from '../../common/ui/common_back_button/BackButton';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_TITLE,
} from '../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import { apiRequest } from '../../services/Service';
import { GET_INITIATIVE_DETAIL_BY_ID, MICROGIVE_FEEDBACK } from '../../services/ApiUrls';
import { saveProgressLoadingState } from '../../state/UIState';
import { showToast } from '../../utils/common';
import { Modal } from 'react-bootstrap';
import ActivityLoader from '../../common/ui/activity_loader/ActivityLoader';
import { selectAssetCommentCharacterLimit } from '../../state/MasterData';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import ReactStars from 'react-rating-stars-component';

const MicrogiveFeedbackScreen = (props) => {
  const { event_id } = props.location.state;
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [event, setEvent] = useState();
  const [feedback, setFeedBack] = useState('');
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const MAX_CHAR_ALLOWED = useSelector(selectAssetCommentCharacterLimit);
  const isFeedbackEmpty = feedback === null || feedback === undefined || /^\s*$/.test(feedback);
  const [ratingValue, setRatingValue] = useState(5);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.FEEDBACK_SCREEN, NAVIGATION_ROUTES.FEEDBACK_SCREEN);
  }, []);

  const callProjectDetailApi = useCallback(async () => {
    try {
      const apiResponse = await apiRequest(
        GET_INITIATIVE_DETAIL_BY_ID + event_id,
        REQUEST_TYPE.GET,
        {}
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setEvent(apiResponse.response.data);
        }
      }
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch, event_id]);

  useEffect(() => {
    callProjectDetailApi();
  }, [callProjectDetailApi]);

  const callSubmitFeedbackApi = async () => {
    try {
      setError(false);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.SUBMIT_FEEDBACK,
        })
      );
      logEvent(
        ANALYTICS_EVENT_TYPES.SUBMIT_FEEDBACK,
        event_id.toString(),
        ANALYTICS_ITEM_NAMES.FEEDBACK
      );
      const params = new URLSearchParams();
      params.append('microgive_initiative_detail_id', event_id);
      params.append('message', feedback);
      params.append('rate', ratingValue);
      const apiResponse = await apiRequest(MICROGIVE_FEEDBACK, REQUEST_TYPE.POST, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (apiResponse.response.status === true) {
        showToast(apiResponse.response.message);
        setShowSuccessDialog(true);
      } else {
        showToast(apiResponse.response.message);
      }
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      setError(false);
    }
  };

  const ratingCompleted = (ratingStar) => {
    setRatingValue(ratingStar);
  };

  const successDialog = () => {
    return (
      <div className="feedback-success-dialog d-flex flex-column align-items-center justify-content-center w-100">
        <div className="feedback-success-title-text mt-3">Submitted successfully!</div>
        <div className="feedback-success-subtitle-text mt-1 pt-1">
          Thanks for your valuable feedback.
        </div>
        <div className="d-flex flex-row w-100 align-items-center justify-content-center">
          <div
            onClick={() => {
              setShowSuccessDialog(false);
              props.history.goBack();
            }}
            className="feedback-ok-button-container d-flex flex-row align-items-center justify-content-center mt-4 align-self-center mb-3 common-cursor-pointer">
            OK
          </div>
        </div>
      </div>
    );
  };

  return event ? (
    <div className="container">
      <SectionHeader title={SCREEN_TITLE.MICROGIVE} />
      <BackButton />
      <div className="ml-3">
        <div
          className={
            event?.initiative_details?.enable_feedback === true
              ? 'feedback-title-container p-3 mt-3'
              : 'feedback-title-container-bottom-border p-3 mt-3'
          }>
          <div className="feedback-title-text mb-1">{event?.initiative_details?.event_name}</div>
          <div className="feedback-subtitle-text mb-1">Volunteer</div>
          {event?.initiative_details?.enable_feedback === false && (
            <div className="feedback-subtitle-text">{`Feedback is not available.`}</div>
          )}
        </div>
        {event?.initiative_details?.enable_feedback === true && (
          <div className="feedback-textbox-container p-3">
            <div className="row pl-3">
              <div className="feedback-text-style mr-1">Feedback</div>
              <div className="feedback-star-text-style">*</div>
            </div>
            <div className="common-comment-box mb-2 mt-1">
              <textarea
                name="description"
                id="description"
                rows="4"
                spellCheck="false"
                value={feedback}
                onChange={(event) => setFeedBack(event.target.value)}
              />
            </div>
            <div className="breakouts-team-micro-view">
              <ReactStars
                count={5}
                onChange={ratingCompleted}
                size={40}
                activeColor="#ffd700"
                value={ratingValue}
              />
            </div>
            <div className="d-flex flex-row align-items-end justify-content-end w-100">
              <div
                onClick={() => {
                  if (feedback?.length > MAX_CHAR_ALLOWED) {
                    showToast(`Maximum ${MAX_CHAR_ALLOWED} characters are allowed`);
                  } else {
                    isFeedbackEmpty
                      ? showToast('Feedback should not be empty')
                      : callSubmitFeedbackApi();
                  }
                }}
                className="feedback-submit-button-style d-flex flex-row align-items-center justify-content-center common-cursor-pointer">
                SUBMIT
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        contentClassName="feedback-success-modal mx-auto"
        show={showSuccessDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          null;
        }}>
        <Modal.Body>{successDialog()}</Modal.Body>
      </Modal>
    </div>
  ) : (
    <ActivityLoader visible={!event} />
  );
};

export default MicrogiveFeedbackScreen;
