import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEcardsTabs } from '../../state/MasterData';
import { saveProgressLoadingState, selectBaseUrl } from '../../state/UIState';
import { Tab } from 'react-bootstrap';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { saveEcardsTabData, selectEcardsTabData } from '../../state/TabData';
import EcardTab from './tabs/EcardTab';
import { apiRequest, handleError } from '../../services/Service';
import { ECARDS } from '../../services/ApiUrls';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import NavTabs from '../../common/ui/tabs/NavTabs';

const EcardMainScreen = ({ location }) => {
  const ecardsTabs = useSelector(selectEcardsTabs);
  const ecardsTabdata = useSelector(selectEcardsTabData);
  const dispatch = useDispatch();
  const baseUrl = useSelector(selectBaseUrl);
  const [loading, setLoading] = useState(true);
  const temp_cards = {};
  for (var val in ecardsTabs) {
    temp_cards[ecardsTabs[val].category_id] = [];
  }
  const [ecards, setEcards] = useState(temp_cards);
  const [tabKey] = useState(
    location && location.state && location.state.tabKey && location.state.tabKey
  );

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.ECARDS, NAVIGATION_ROUTES.ECARDS);
  }, []);

  const callECardsApi = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequest(ECARDS, REQUEST_TYPE.GET, {});
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.downloadables) {
            const data = apiResponse.response.data.downloadables;
            setLoading(false);
            if (data.length > 0) {
              const ecardsArray = {};
              for (var i in ecardsTabs) {
                const ecardData = data.find(
                  (card) => card.downloadable_id === Number(ecardsTabs[i].category_id)
                );
                if (ecardData) {
                  ecardsArray[ecardsTabs[i].category_id] = ecardData;
                }
              }
              setEcards(ecardsArray);
            }
          }
        }
      }
    } catch (err) {
      handleError(err, {}, ECARDS, NAVIGATION_ROUTES.ECARDS);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (baseUrl !== '') {
      callECardsApi();
    }
  }, [baseUrl, callECardsApi]);

  useEffect(() => {
    if (ecardsTabs.length && ecardsTabdata === '' && tabKey) {
      dispatch(saveEcardsTabData(tabKey));
    } else if (ecardsTabs.length && ecardsTabdata === '') {
      dispatch(saveEcardsTabData(ecardsTabs[0].category_id));
    }
  }, [dispatch, ecardsTabdata, ecardsTabs, tabKey]);

  const renderTabs = (tab) => {
    switch (tab.category_id) {
      case tab.category_id:
        return (
          <EcardTab
            data={ecards[tab.category_id]}
            item={tab.category_id}
            loading={loading}
            title={tab.title}
          />
        );
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.ECARDS} />
      {baseUrl !== '' && (
        <div>
          <NavTabs
            activeKey={ecardsTabdata}
            onSelect={(k) => dispatch(saveEcardsTabData(k))}
            unmountOnExit>
            {ecardsTabs &&
              ecardsTabs.map((tab, index) => {
                return (
                  <Tab
                    key={`${index}`}
                    eventKey={tab.category_id}
                    title={tab.title.toUpperCase()}
                    className="main-content-container">
                    {renderTabs(tab, index)}
                  </Tab>
                );
              })}
          </NavTabs>
        </div>
      )}
    </div>
  );
};

export default EcardMainScreen;
