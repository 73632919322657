import React from 'react';
import { useSelector } from 'react-redux';
import { selectTermsConditionsUrl } from '../../state/MasterData';

const TermsConditionsScreen = () => {
  const termsConditions = useSelector(selectTermsConditionsUrl);

  return (
    <div className="container main-content-container">
      <div className="iframe-container">
        <iframe src={termsConditions} allowFullScreen height="1000px"></iframe>
      </div>
    </div>
  );
};

export default TermsConditionsScreen;
