import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import ActivityLoader from '../../../../common/ui/activity_loader/ActivityLoader';
import DefaultContentView from '../../../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../../../common/ui/load_more_btn/LoadMoreBtn';
import { apiRequest } from '../../../../services/Service';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectShowEmptyContentView,
} from '../../../../state/UIState';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import ResultsListItem from '../../components/ResultsListItem';

const ResultTab = ({ identifier }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [resultsList, setResultsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);

  const showEmptyContentView = useSelector(selectShowEmptyContentView);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.DIGITAL_WARRIOR_RESULTS_TAB_VISIT,
      identifier,
      ANALYTICS_ITEM_NAMES.DIGITAL_WARRIOR
    );
  }, [identifier]);

  const callEventsApi = useCallback(
    async (id, loadMore) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        try {
          setLoading(true);
          const params = {
            page_id: id,
          };
          const apiResponse = await apiRequest(identifier + '/results', REQUEST_TYPE.GET, params);
          setLoading(false);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.results.length > 0) {
                setResultsList((oldArray) => [...oldArray, ...apiResponse.response.data.results]);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          setLoading(false);
        }
      }
    },
    [dispatch, identifier]
  );

  useEffect(() => {
    callEventsApi(1, true);
  }, [callEventsApi]);

  const onPressCommentButton = (item) => {
    logEvent(ANALYTICS_EVENT_TYPES.RESULTS_COMMENT, item.result_id, ANALYTICS_ITEM_NAMES.COMMENT);
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: item.result_id,
      likeType: 'MTFR',
      fromRoute: NAVIGATION_ROUTES.DIGITAL_WARRIOR_SCREEN,
    });
  };

  const onPressLikeButton = (item) => {
    if (item.likes && !item.likes.is_liked) {
      logEvent(ANALYTICS_EVENT_TYPES.RESULTS_LIKE, item.result_id, ANALYTICS_ITEM_NAMES.LIKE);
      callLikeApi(item.result_id);
    }
  };

  const callLikeApi = async (resultId) => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: 'Updating Like Count...',
        })
      );
      const params = new URLSearchParams();
      params.append('type', 'MTFR');
      const apiResponse = await apiRequest(
        'comment/' + resultId + '/like',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          updateLikeCount(resultId);
        }
      }
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const updateLikeCount = (resultId) => {
    let arr = resultsList;
    let oldCount = arr.find((element) => element.result_id === resultId).likes.count;
    let objIndex = arr.findIndex((element) => element.result_id === resultId);
    arr[objIndex].likes.count = oldCount + 1;
    arr[objIndex].likes.is_liked = true;
    setResultsList([...arr]);
  };

  const onPressLoadMore = () => {
    callEventsApi(pageId, loadMoreFlag);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.SEARCH_NO_RESULTS} />;
  };

  return (
    <div className="main-content-container">
      {showEmptyContentView && !resultsList.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          {resultsList.map((item, index) => {
            return (
              <ResultsListItem
                key={`${index}`}
                item={item}
                onPressCommentButton={onPressCommentButton}
                onPressLikeButton={onPressLikeButton}
                identifier={identifier}
              />
            );
          })}
          <ActivityLoader visible={loading} />
          {loadMoreFlag && <LoadMoreBtn onClick={onPressLoadMore} isLoading={loading} />}
        </div>
      )}
    </div>
  );
};

export default React.memo(ResultTab);
