import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import Divider from '../../../common/ui/divider/Divider';
import { saveProgressLoadingState } from '../../../state/UIState';
import { formatDateDDMonthYYYY, getDevicePixelRatio } from '../../../utils/common';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { apiRequest, handleError } from '../../../services/Service';
import { UPLOAD_BUSINESS_CARD } from '../../../services/ApiUrls';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { logEvent2 } from '../../../utils/FirebaseAnalyticsUtils';

const MyDonationsListItem = ({ item, index, downloadImagePath }) => {
  const dispatch = useDispatch();
  const employeeData = useSelector(selectEmployeeData);
  const pixelRatio = getDevicePixelRatio();
  const empNameStyle = {
    position: 'absolute',
    top: `${368 / pixelRatio}px`,
    left: `${45 / pixelRatio}px`,
    color: '#ed1d23',
    fontSize: `${38 / pixelRatio}px`,
    fontFamily: 'Lato-Black',
    flexWrap: 'wrap',
    overflowWrap: 'break-word',
    width: `${600 / pixelRatio}px`,
    textOverflow: 'ellipsis',
  };
  const longerEmpNameStyle = {
    top: `${350 / pixelRatio}px`,
    left: `${45 / pixelRatio}px`,
    color: '#ed1d23',
    lineHeight: `${32 / pixelRatio}px`,
    fontSize: `${34 / pixelRatio}px`,
  };

  const eventTitle = {
    position: 'absolute',
    top: `${486 / pixelRatio}px`,
    left: `${45 / pixelRatio}px`,
    color: 'black',
    fontSize: `${28 / pixelRatio}px`,
    fontFamily: 'Lato-Black',
    flexWrap: 'wrap',
    lineHeight: `${32 / pixelRatio}px`,
    overflowWrap: 'break-word',
    width: `${620 / pixelRatio}px`,
    textOverflow: 'ellipsis',
  };

  const donationCertificateDate = {
    position: 'absolute',
    bottom: `${54 / pixelRatio}px`,
    left: `${590 / pixelRatio}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#000',
    fontSize: `${26 / pixelRatio}px`,
    fontFamily: 'Lato-Regular',
  };

  const certificateStyle = {
    position: 'absolute',
    height: `${800 / pixelRatio}px`,
    width: `${1200 / pixelRatio}px`,
    left: `${-20000 / pixelRatio}px`,
  };

  const downloadCertificate = async (e) => {
    logEvent2({
      eventType: ANALYTICS_EVENT_TYPES.MICROGIVE_CERTIFICATE_SHARE,
      itemId: item?.initiative_id ?? '',
      itemName: item?.title ?? '',
      categoryId: ANALYTICS_ITEM_NAMES.MICROGIVE,
      categoryName: ANALYTICS_ITEM_NAMES.MICROGIVE,
    });
    e.preventDefault();
    document.getElementById(`download-cheers-certificate-${index}`).classList.add('d-block');
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.GENERATING_BUSINESS_CARD,
      })
    );
    setTimeout(() => {
      html2canvas(document.getElementById(`download-cheers-certificate-${index}`), {
        height: 800 / pixelRatio,
        width: 1200 / pixelRatio,
        useCORS: true,
        onclone: function (clonedDoc) {
          clonedDoc.getElementById(`download-cheers-certificate-${index}`).classList.add('d-block');
        },
      })
        .then((canvas) => {
          document
            .getElementById(`download-cheers-certificate-${index}`)
            .classList.remove('d-block');

          let xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = function () {
            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(xhr.response);
            a.download = 'certificate.jpeg';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            a.remove();
          };
          xhr.open('GET', canvas.toDataURL('image/jpeg')); // This is to download the canvas Image
          xhr.send();
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          // commenting out below code as it is not required
          // uploadBusinessCard(canvas.toDataURL(), e);
        })
        .catch(() => {
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        });
    }, 1000);
  };

  const uploadBusinessCard = async (imageUrl, e) => {
    const params = new URLSearchParams();
    params.append('file', imageUrl);
    params.append('type', '_CHEERS_IMAGE');
    try {
      const apiResponse = await apiRequest(UPLOAD_BUSINESS_CARD, REQUEST_TYPE.POST, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status === true) {
          const message = apiResponse.response.message;
          if (message) {
            e.stopPropagation();
            saveAs(
              apiResponse.response.data.business_card.base_url +
                apiResponse.response.data.business_card.image_path,
              'certificate.jpeg'
            );
          }
        }
      }
    } catch (err) {
      handleError(err, params, UPLOAD_BUSINESS_CARD, NAVIGATION_ROUTES.MY_DONATIONS_SCREEN);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };
  return (
    <div className="pt-2 pb-2">
      <div>
        <div className="microgive-date-view-style justify-content-between">
          <div className="w-50">
            <div className="microgive-volunteer-title">{item.title}</div>
            <div className="microgive-donation-date-text">
              {formatDateDDMonthYYYY(item.donated_on)}
            </div>
          </div>

          <div className="d-flex flex-row align-items-center justify-content-between w-25">
            {item?.certificate_image?.image_path ? (
              <div
                className="d-flex flex-row align-items-center common-cursor-pointer flex-wrap justify-content-center"
                onClick={downloadCertificate}>
                <div className="cheers-download-certificate">Download Certificate</div>
                <img src={downloadImagePath} className="cheers-download-icon ml-2" />
              </div>
            ) : (
              <div className="d-flex flex-row align-items-center" />
            )}
            <div className="microgive-volunteer-title">{`${item.abbreviation} ${item.amount}`}</div>
          </div>
        </div>
        {item?.certificate_image?.image_path && (
          <div id={`download-cheers-certificate-${index}`} style={{ ...certificateStyle }}>
            <img
              src={item?.certificate_image?.base_url + item?.certificate_image?.image_path}
              width="100%"
              height="100%"
            />
            <div
              style={
                (
                  employeeData?.first_name +
                  ' ' +
                  (employeeData?.middle_name ? employeeData?.middle_name + ' ' : ' ') +
                  employeeData?.last_name
                ).length > 30
                  ? { ...empNameStyle, ...longerEmpNameStyle }
                  : { ...empNameStyle }
              }>
              {`${
                employeeData?.first_name +
                ' ' +
                (employeeData?.middle_name ? employeeData?.middle_name + ' ' : ' ') +
                employeeData?.last_name
              }`}
            </div>
            <div style={{ ...donationCertificateDate }}>
              {formatDateDDMonthYYYY(item?.donated_on).replace(/ /g, '-')}
            </div>
            <div style={{ ...eventTitle }}>
              {item?.title?.endsWith('.') ||
              item?.title?.endsWith('!') ||
              item?.title?.endsWith('?')
                ? `${item?.title}`
                : `${item?.title}.`}
            </div>
          </div>
        )}
      </div>
      <Divider style="mt-2" />
    </div>
  );
};

export default MyDonationsListItem;
