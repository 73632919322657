import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { saveProgressLoadingState } from '../../state/UIState';
import { apiRequestUser } from '../../services/Service';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import BackButton from '../../common/ui/common_back_button/BackButton';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { useHistory } from 'react-router';
import { saveDeliveryEmail, saveTempAddress } from '../../state/EStoreData';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { CUSTOMERS_ME } from '../../services/ApiUrls';

const SelectAddressScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [addresses, setAddresses] = useState([]);
  const [email, setEmail] = useState('');

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.MICRO_STORE_SELECT_ADDRESS_SCREEN,
      NAVIGATION_ROUTES.MI_STORE_SELECT_ADDRESS_SCREEN
    );
  }, []);

  const fetchContent = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequestUser(CUSTOMERS_ME, REQUEST_TYPE.GET);
      if (Object.keys(apiResponse.response).length) {
        setAddresses(apiResponse.response?.addresses);
        setEmail(apiResponse.response?.email);
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    fetchContent();
  }, []);

  const setSelectedAddress = (address) => {
    dispatch(saveTempAddress(address));
    dispatch(saveDeliveryEmail(email));
    history.goBack();
  };

  const goToAddAddress = () => {
    logEvent(ANALYTICS_EVENT_TYPES.ESTORE_ADD_ADDRESS, '', ANALYTICS_ITEM_NAMES.ADD_NEW_ADDRESS);
    history.push(NAVIGATION_ROUTES.MI_STORE_ADD_ADDRESS_SCREEN);
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICRO_STORE} />
      <BackButton backTitle="ADDRESS" />
      <div className="e-store-cart-block">
        {addresses.length == 0 && (
          <div className="estore-address-items-container">
            <DefaultContentView message={ERROR_MESSAGES.NO_ADDRESSES_FOUND} />
          </div>
        )}
        {addresses.length > 0 && (
          <div className="estore-address-items-container">
            {addresses.map((address, index) => (
              <div key={index}>
                <div className="estore-default-select-address-container p-2">
                  <div>
                    <div className="estore-bold-text">{`${address.firstname} ${address.lastname}`}</div>
                    {address.street.map((street, index) => (
                      <div key={index} className="estore-regular-text">
                        {street}
                      </div>
                    ))}
                    <div className="estore-regular-text">{`${address.city}-${address.postcode}`}</div>
                    <div className="d-flex estore-regular-text mt-2">
                      Mobile:
                      <div className="ml-1 estore-bold-text">{address.telephone}</div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      logEvent(
                        ANALYTICS_EVENT_TYPES.ESTORE_SELECT_ADDRESS_CLICK,
                        address,
                        ANALYTICS_ITEM_NAMES.SELECT_ADDRESS
                      );
                      setSelectedAddress(address);
                    }}
                    className="estore-cart-select-or-default-button border-radius-4 common-cursor-pointer">
                    <div className="estore-bold-text">Select</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="estore-confirm-address-button">
          <div className="estore-cart-place-order-button w-50">
            <CustomButton buttonStyle="estore-add-to-cart-button" onClick={goToAddAddress}>
              ADD NEW ADDRESS
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectAddressScreen;
