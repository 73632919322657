import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';

const DocumentChatView = ({ item }) => {
  const [url, setUrl] = useState('');
  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    if (item && item.url) {
      setUrl(item.url);
    }
  }, [item]);

  return (
    <div className="d-flex align-items-center">
      <img
        className="chat-doc-icon"
        src={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_PDF_BLACK_OUTLINE,
          ACCESSIBILITY_IDENTIFIERS.COMMON
        )}
      />
      <a
        target="_blank"
        rel="noreferrer"
        href={url}
        className="chat-base-text-sm chat-limit-two-lines ml-2">
        {item.doc_name}
      </a>
    </div>
  );
};

export default DocumentChatView;
