import { call, put, takeEvery } from 'redux-saga/effects';
import { ACTION_TYPES, NAVIGATION_ROUTES, REQUEST_TYPE } from '../common/constants/AppConstants';
import { ACCESSIBILITY_DATA } from '../services/ApiUrls';
import { apiRequestGenericToken } from '../services/Service';
import { saveAccessibilityData } from '../state/AccessibilityData';

export function* getAccessibility(action) {
  // if (!window.location.href.includes(NAVIGATION_ROUTES.PUBLIC_PROFILE)) {
  try {
    const apiResponse = yield call(
      apiRequestGenericToken,
      ACCESSIBILITY_DATA,
      REQUEST_TYPE.GET,
      action.payload
    );
    if (apiResponse) {
      if (apiResponse.response.status === true) {
        yield put(saveAccessibilityData(apiResponse.response.data));
      }
    }
  } catch (e) {
    yield put(saveAccessibilityData([]));
  }
  // }
}

export function* watchAccessibility() {
  yield takeEvery(ACTION_TYPES.GET_ACCESSIBILITY, getAccessibility);
}
