import React, { useEffect, useState } from 'react';
import { Tabs } from 'react-bootstrap';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import colors from '../../themes/Colors';

const NavTabs = (props) => {
  const [showNavigator, setShowNavigator] = useState(false);

  const scrollLeft = () => {
    const tabs = document.getElementsByClassName('nav-tabs')[0];
    if (tabs) {
      tabs.scrollTo({
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    const tabs = document.getElementsByClassName('nav-tabs')[0];
    if (tabs) {
      tabs.scrollTo({
        left: tabs.scrollWidth,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    checkNavigatorVisibility();
  }, []);

  const checkNavigatorVisibility = () => {
    const tabs = document.getElementsByClassName('nav-tabs')[0];
    if (tabs) {
      setShowNavigator(tabs.scrollWidth - tabs.clientWidth > 0);
    }
  };

  window.addEventListener('resize', function () {
    checkNavigatorVisibility();
  });

  const onClickLeftArrowHandler = () => {
    scrollLeft();
  };

  const onClickRightArrowHandler = () => {
    scrollRight();
  };

  return (
    <div className="d-flex">
      {showNavigator && (
        <div id="left-scroll-container" className="common-tab-navigator-container py-2 mr-1">
          <div onClick={onClickLeftArrowHandler} className="common-cursor-pointer">
            <MdKeyboardArrowLeft size="1.6rem" color={colors.black} />
          </div>
        </div>
      )}
      <div className="common-wrapper-container" id="tabs">
        <Tabs {...props}>{props.children}</Tabs>
      </div>
      {showNavigator && (
        <div id="right-scroll-container" className="common-tab-navigator-container py-2 ml-1">
          <div onClick={onClickRightArrowHandler} className="common-cursor-pointer">
            <MdKeyboardArrowRight size="1.6rem" color={colors.black} />
          </div>
        </div>
      )}
    </div>
  );
};

export default NavTabs;
