import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { selectDI } from '../../state/MasterData';
import { Tab } from 'react-bootstrap';
import AnnouncementsTab from './tabs/AnnouncementsTab';
import DeiChampionsTab from './tabs/DeiChampionsTab';
import PledgeTab from './tabs/PledgeTab';
import DiscussionTab from './tabs/DiscussionTab';
import { saveDiTabData, selectDiTabData } from '../../state/TabData';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import DIEventsTab from './tabs/DIEventsTab';
import DIGalleryTab from './tabs/DIGalleryTab';
import CalendarDialog from '../../common/ui/calender_dialog/CalendarDialog';
import { getDateInYYYYMMDD } from '../../utils/common';
import PodcastTab from './tabs/PodcastTab';
import NavTabs from '../../common/ui/tabs/NavTabs';
import SearchComponent from '../../common/ui/search/SearchComponent';
import { saveSearchText } from '../../state/UIState';

const DIScreen = ({ history }) => {
  const dispatch = useDispatch();

  const diTitles = useSelector(selectDI);
  const diTabData = useSelector(selectDiTabData);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [showNewsDialog, setShowNewsDialog] = useState(false);
  const [eventsDate, setEventsDate] = useState([]);
  const [eventDateSelected, setEventDateSelected] = useState();

  const [tabKey] = useState(
    location && location.state && location.state.tabKey && location.state.tabKey
  );

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.DI, NAVIGATION_ROUTES.DI);
  }, []);

  useEffect(() => {
    if (diTitles.length && diTabData === '' && tabKey) {
      dispatch(saveDiTabData(tabKey));
    } else if (diTitles.length && diTabData === '') {
      dispatch(saveDiTabData(diTitles[0].key));
    }
  }, [dispatch, diTabData, diTitles, tabKey]);

  const toggleDialog = (value) => {
    setShowNewsDialog(value);
  };

  const renderTabs = (tab) => {
    switch (tab.key) {
      case 'announcements':
        return (
          <AnnouncementsTab
            showNewsDialog={showNewsDialog}
            toggleDialog={toggleDialog}
            channelId={tab.channel_id}
            subChannelId={tab.sub_channel_id}
          />
        );
      case 'pledge':
        return <PledgeTab />;
      case 'podcast':
        return <PodcastTab />;
      case 'discussions':
        return <DiscussionTab />;
      case 'events':
        return (
          <DIEventsTab
            getEventDate={(val) => {
              getEventDate(val);
            }}
          />
        );
      case 'gallery':
        return <DIGalleryTab />;
      case 'dei_champions':
        return <DeiChampionsTab />;
    }
  };

  const onEventDateSelected = (date) => {
    setEventDateSelected(date);
    let formattedDate = getDateInYYYYMMDD(date);
    history.push(NAVIGATION_ROUTES.DI_EVENT_DATE, {
      date: formattedDate,
    });
  };

  const getEventDate = (dates) => {
    setEventsDate(dates);
  };

  const handleSubmitFunction = (searchText) => {
    dispatch(saveSearchText(searchText));
    history.push(NAVIGATION_ROUTES.DI_SEARCH, {
      searchQuery: searchText,
    });
  };

  const renderHeader = () => {
    switch (diTabData) {
      case 'announcements':
        return (
          <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
            <SearchComponent handleSubmitFunction={handleSubmitFunction} />
          </div>
        );
      case 'pledge':
        return null;
      case 'discussions':
        return null;
      case 'events':
        return (
          <div className="gcdo-header-row header-view-absolute justify-content-end float-right">
            <div className="gcdo-icon-row mr-3">
              <CalendarDialog
                markedDates={eventsDate}
                onDateSelected={onEventDateSelected}
                selectedDate={eventDateSelected}
                popperPosition="bottom-end"
              />
            </div>
          </div>
        );
    }
  };

  return (
    <div className="container">
      {renderHeader()}
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.DNI} />
      <div>
        <NavTabs
          activeKey={diTabData}
          onSelect={(k) => {
            dispatch(saveDiTabData(k));
          }}
          defaultActiveKey={diTabData}
          unmountOnExit>
          {diTitles.map((tab, index) => {
            return (
              <Tab key={`${index}`} eventKey={tab.key} title={tab.title}>
                {renderTabs(tab, index)}
              </Tab>
            );
          })}
        </NavTabs>
      </div>
    </div>
  );
};

export default DIScreen;
