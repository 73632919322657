import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import 'js-video-url-parser/lib/provider/youtube';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ENCODING_TYPE,
  ERROR_MESSAGES,
  LOADING_MESSAGES,
  MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
  SUPPORTED_DOCUMENT_FORMAT_PDF,
  SUPPORTED_IMAGE_FORMATS,
  SUPPORTED_IMAGE_FORMATS_WEB,
  SUPPORTED_PDF_FORMATS_WEB,
  WARNING_MESSAGES,
} from '../../common/constants/AppConstants';
import { checkIsValidYoutubeURL, getUrlWithSpecificRendition, showToast } from '../../utils/common';
import {
  EDIT_THE_WALL_POST,
  GET_DIRECTORY_DATA,
  GET_HASHTAGS,
  NEW_CREATE_POST,
  NEW_EDIT_POST,
} from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { websiteValidationRegex } from '../../utils/formValidation';
import { getFeatureComponentUrl, getFeatureComponentUrlLdpi } from '../../utils/accessibility';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import Divider from '../../common/ui/divider/Divider';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';
import TagEmployeeView from './components/TagPeopleView';
import { logEvent } from '../../utils/FirebaseAnalyticsUtils';
import {
  selectMaxFileUploadSizeConfig,
  selectTrendingHashtags,
  selectwallPostConfig,
} from '../../state/MasterData';
import { uploadDocuments } from '../../utils/commonApiRequests';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { EditorState } from 'draft-js';
import CustomMentionEditor from '../../common/components/hashtagsAndMentionsInput/HashtagMentionsInput';
import {
  extractDataFromEditorState,
  getMentionsState,
} from '../../common/components/hashtagsAndMentionsInput/hashtagUtils';

const TheWallNewPostScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { post = {} } = location?.state ?? {};
  const [isEditMode] = useState(Object.keys(post).length > 0);
  const [link, setLink] = useState('');
  const [links, setLinks] = useState(
    post && post.links && post.links.length > 0 ? post.links.map((linkNode) => linkNode.url) : []
  );
  const [videoLink, setVideoLink] = useState('');
  const [videoLinks, setVideoLinks] = useState(
    post && post.videos && post.videos.length > 0
      ? post.videos.map((videoNode) => videoNode.url)
      : []
  );
  const [remoteImageLinks, setRemoteImageLinks] = useState(
    post && post.images && post.images.length > 0 ? post.images.map((imageNode) => imageNode) : []
  );
  const [imageLinks, setImageLinks] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);
  const [remotePdfLinks, setRemotePdfLinks] = useState(
    post && post.pdfs && post.pdfs.length > 0 ? post.pdfs.map((fileNode) => fileNode) : []
  );
  const [pdfLinks, setPdfLinks] = useState([]);
  const [removedPdfs, setRemovedPdfs] = useState([]);
  const [tags, setTags] = useState(
    post && post.tags && post.tags.length > 0 ? post.tags.map((employeeNode) => employeeNode) : []
  );
  const accessibilityData = useSelector(selectAccessibilityData);
  const [showTagEmployeeView, setShowTagEmployeeView] = useState(false);
  const [imageBaseUrl, setImageBaseUrl] = useState('');
  const [pdfBaseUrl, setPdfBaseUrl] = useState('');
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const addImageFileRef = useRef(null);

  const maxFileUploadSizeInMB = useSelector(selectMaxFileUploadSizeConfig);
  const wallPostConfig = useSelector(selectwallPostConfig);
  const trendingHashtags = useSelector(selectTrendingHashtags);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [mentionsList, setMentionsList] = useState([]);
  const [hashtagsList, setHashtagsList] = useState([]);
  const [tempHashtag, setTempHashtag] = useState('');
  const [tempMention, setTempMention] = useState('');

  const cleanupStates = () => {
    setRemovedImages([]);
    setRemovedPdfs([]);
  };

  useEffect(() => {
    if (post.description) {
      const mentions = [];
      Object.values(post.taggged_employees).map((emp) => {
        mentions.push({ name: emp.full_name, type: 'mention', employee_id: emp.employee_id });
      });
      const editorState = getMentionsState(post.description_text, mentions);
      setEditorState(editorState);
    }
  }, []);

  const publishPostApi = async (description) => {
    if (description === '') {
      showToast(MESSAGES.DESCRIPTION_EMPTY);
    } else {
      const formData = new FormData();
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
            progressMessage: LOADING_MESSAGES.PUBLISHING,
          })
        );
        let tagString = '';
        if (tags.length > 0) {
          var tagArray = [];
          for (var i = 0; i < tags.length; i++) {
            tagArray.push(tags[i].employee_id);
          }
          tagString = tagArray.join(',');
        }
        formData.append('description', description);
        if (pdfLinks.length > 0) {
          formData.append('pdfs', JSON.stringify(pdfLinks));
        }
        formData.append('tags', tagString);
        if (links.length > 0) {
          links.forEach((item, index) => {
            formData.append(`links[${index}]`, `${item}`);
          });
        }
        if (videoLinks.length > 0) {
          videoLinks.forEach((item, index) => {
            formData.append(`videos[${index}]`, `${item}`);
          });
        }
        if (imageLinks.length > 0) {
          formData.append('images', JSON.stringify(imageLinks));
        }
        if (isEditMode) {
          removedImages.forEach((item, index) => {
            formData.append(`remove_images[${index}]`, `'${item}'`);
          });
          removedPdfs.forEach((item, index) => {
            formData.append(`remove_images[${removedImages.length + index}]`, `'${item}'`);
          });
        }
        const apiResponse = await apiRequest(
          isEditMode ? NEW_EDIT_POST + post.post_id + '/' + EDIT_THE_WALL_POST : NEW_CREATE_POST,
          REQUEST_TYPE.POST,
          formData,
          ENCODING_TYPE.FORM_DATA
        );
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.message) {
              showToast(apiResponse.response.message);
              if (isEditMode) {
                history.go(-1);
                history.replace(`${NAVIGATION_ROUTES.THE_WALL_POST_DETAIL}/${post?.post_id}`, {
                  refresh: true,
                });
              } else {
                history.replace(`${NAVIGATION_ROUTES.THE_WALL}`, {
                  refresh: true,
                });
              }
              cleanupStates();
            }
          }
        }
      } catch (err) {
        handleError(
          err,
          formData,
          isEditMode ? NEW_EDIT_POST + post.post_id + '/' + EDIT_THE_WALL_POST : NEW_CREATE_POST,
          NAVIGATION_ROUTES.THE_WALL_CREATE_POST
        );
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    }
  };

  const deleteLink = (item) => {
    if (links.includes(item)) {
      const index = links.indexOf(item);
      if (index > -1) {
        setLinks(links.filter((element) => element !== links[index]));
      }
    }
  };

  const deleteVideoLink = (item) => {
    if (videoLinks.includes(item)) {
      const index = videoLinks.indexOf(item);
      if (index > -1) {
        setVideoLinks(videoLinks.filter((element) => element !== videoLinks[index]));
      }
    }
  };

  const deleteRemoteImage = (item) => {
    setRemovedImages((prev) => [...prev, ...[item.filename]]);
    if (remoteImageLinks.some((element) => element.filename === item.filename)) {
      const index = remoteImageLinks.findIndex((element) => element.filename === item.filename);
      if (index > -1) {
        setRemoteImageLinks(
          remoteImageLinks.filter((element) => element !== remoteImageLinks[index])
        );
      }
    }
  };

  const deleteImage = (item) => {
    if (imageLinks.includes(item)) {
      const index = imageLinks.indexOf(item);
      if (index > -1) {
        setImageLinks(imageLinks.filter((element) => element !== imageLinks[index]));
      }
    }
  };

  const deleteEmployee = (employee) => {
    if (tags.includes(employee)) {
      const index = tags.indexOf(employee);
      if (index > -1) {
        setTags(tags.filter((item) => item !== tags[index]));
      }
    }
  };

  const onPressAddLink = () => {
    if (link === '') {
      showToast(MESSAGES.ENTER_URL);
    } else if (!websiteValidationRegex(link)) {
      showToast(MESSAGES.INVALID_URL);
    } else if (links.includes(link)) {
      showToast(MESSAGES.LINK_ADDED);
    } else if (links.length === (wallPostConfig?.web_urls_limit ?? 10)) {
      showToast(WARNING_MESSAGES.MAXIMUM_NO_OF_WEB_URLS(wallPostConfig?.web_urls_limit ?? 10));
      return;
    } else {
      setLinks((prev) => [...prev, link]);
    }
    setLink('');
  };

  const onPressVideoLink = () => {
    if (videoLink === '') {
      showToast(MESSAGES.ENTER_URL);
    } else if (!checkIsValidYoutubeURL(videoLink)) {
      showToast(MESSAGES.INVALID_URL);
    } else if (videoLinks.includes(videoLink)) {
      showToast(MESSAGES.LINK_ADDED);
    } else if (videoLinks.length === (wallPostConfig?.youtube_urls_limit ?? 10)) {
      showToast(
        WARNING_MESSAGES.MAXIMUM_NO_OF_YOUTUBE_URLS(wallPostConfig?.youtube_urls_limit ?? 10)
      );
      return;
    } else {
      setVideoLinks((prev) => [...prev, videoLink]);
    }
    setVideoLink('');
  };

  const onEmployeeSelected = (tags) => {
    if (tags.length > 0) {
      setTags([...tags]);
      setShowTagEmployeeView(false);
    }
  };

  const onFileChange = async (event) => {
    try {
      const images = [...event.target.files];
      let isAllFilesSupported = true;
      let isAnyFileSizeLarge = false;
      let isFileAlreadyPresent = false;

      images.forEach((element) => {
        if (!SUPPORTED_IMAGE_FORMATS_WEB.includes(element.type)) {
          isAllFilesSupported = false;
        }
        if (Number(element.size) / (1024 * 1024) > maxFileUploadSizeInMB) {
          isAnyFileSizeLarge = true;
        }
        if (imageLinks.some((item) => item.name === element.name)) {
          isFileAlreadyPresent = true;
        }
      });
      if (!isAllFilesSupported) {
        showToast(WARNING_MESSAGES.FILE_NOT_SUPPORTED);
      } else if (isAnyFileSizeLarge) {
        showToast(WARNING_MESSAGES.FILE_SIZE_EXCEEDED(maxFileUploadSizeInMB));
      } else if (isFileAlreadyPresent) {
        showToast(ERROR_MESSAGES.WALL_FILE_ALREADY_PRESENT);
      } else {
        if (
          images?.length + imageLinks?.length + remoteImageLinks?.length >
          (wallPostConfig?.images_limit ?? 10)
        ) {
          showToast(WARNING_MESSAGES.MAXIMUM_NO_OF_IMAGE_FILES(wallPostConfig?.images_limit ?? 10));
          return;
        }
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
          })
        );
        images.forEach((element, index) => {
          uploadDocuments(element, 'wallposts')
            .then((response) => {
              if (imageBaseUrl === '') {
                setImageBaseUrl(response.base_url);
              }
              setImageLinks((prev) => [...prev, { filename: response.fileName, type: 'image' }]);
            })
            .catch((error) => {
              showToast(error.message);
            })
            .finally(() => {
              if (images.length - 1 === index) {
                dispatch(
                  saveProgressLoadingState({
                    isProgressLoading: false,
                  })
                );
              }
            });
        });
      }
    } catch (err) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  const onPressAddImage = (e) => {
    e.stopPropagation();
    addImageFileRef.current.click();
  };

  const renderImage = () => {
    return (
      <div>
        <div
          onClick={onPressAddImage}
          style={{ zIndex: 0 }}
          className="border align-items-center justify-content-center p-3 d-flex common-cursor-pointer rounded">
          <input
            type="file"
            ref={addImageFileRef}
            onChange={onFileChange}
            accept={SUPPORTED_IMAGE_FORMATS}
            id="input"
            multiple
            name={'file'}
            className="d-none"
            onClick={(event) => {
              event.target.value = null;
            }}
          />
          <div className="communities-add-image">Add Image</div>
        </div>
        <div className="mt-3 flex-row">
          {remoteImageLinks.length > 0 &&
            remoteImageLinks.map((element, index) => {
              return (
                <div key={index} className="d-inline-block position-relative mr-2">
                  <img
                    src={getUrlWithSpecificRendition(
                      element.base_url,
                      SCREEN_DENSITY_QUALIFIERS.HDPI,
                      element.filename
                    )}
                    alt="Image"
                    className="community-post-image mb-2"
                  />
                  <img
                    src={getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_CLOSE,
                      ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                    )}
                    className="community-cross-icon common-cursor-pointer"
                    onClick={() => deleteRemoteImage(element)}
                  />
                </div>
              );
            })}
          {imageLinks.length > 0 &&
            imageLinks.map((element, index) => {
              return (
                <div key={index} className="d-inline-block position-relative mr-2">
                  <img
                    src={getUrlWithSpecificRendition(
                      imageBaseUrl,
                      SCREEN_DENSITY_QUALIFIERS.HDPI,
                      element.filename
                    )}
                    alt="Image"
                    className="community-post-image mb-2"
                  />
                  <img
                    src={getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_CLOSE,
                      ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                    )}
                    className="community-cross-icon common-cursor-pointer"
                    onClick={() => deleteImage(element)}
                  />
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const onChangeUploadFileHandler = (event) => {
    const pdfFiles = [...event.target.files];
    let isAllFilesSupported = true;
    let isAnyFileSizeLarge = false;
    pdfFiles.forEach((element) => {
      if (!SUPPORTED_PDF_FORMATS_WEB.includes(element.type)) {
        isAllFilesSupported = false;
      }
      if (Number(element.size) / (1024 * 1024) >= maxFileUploadSizeInMB) {
        isAnyFileSizeLarge = true;
      }
    });

    if (!isAllFilesSupported) {
      showToast(WARNING_MESSAGES.FILE_NOT_SUPPORTED);
    } else if (isAnyFileSizeLarge) {
      showToast(WARNING_MESSAGES.FILE_SIZE_EXCEEDED(maxFileUploadSizeInMB));
    } else if (pdfFiles.length > 10) {
      showToast(WARNING_MESSAGES.MAXIMUM_NO_OF_PDF_FILES(wallPostConfig?.pdfs_limit ?? 10));
    } else {
      if (
        pdfFiles?.length + pdfLinks?.length + remotePdfLinks?.length >
        (wallPostConfig?.pdfs_limit ?? 10)
      ) {
        showToast(WARNING_MESSAGES.MAXIMUM_NO_OF_PDF_FILES(wallPostConfig?.pdfs_limit ?? 10));
        return;
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      pdfFiles.forEach(async (element, index) => {
        const formData = new FormData();
        formData.append('file_type', 'document');
        formData.append('feature', 'wallposts');
        formData.append('file', element);
        uploadDocuments(element, 'wallposts', 'document')
          .then((response) => {
            if (pdfBaseUrl === '') {
              setPdfBaseUrl(response.base_url);
            }
            setPdfLinks((prev) => [
              ...prev,
              ...[
                {
                  filename: response.fileName,
                  type: 'pdf',
                  fileNameOrg: response.fileNameOrg,
                },
              ],
            ]);
          })
          .catch((error) => {
            console.log(error);
            showToast(error.message);
          })
          .finally(() => {
            if (pdfFiles.length - 1 === index) {
              dispatch(
                saveProgressLoadingState({
                  isProgressLoading: false,
                })
              );
            }
          });
      });
    }
  };

  const onPressUploadFileHandler = (event) => {
    event.target.value = null;
  };

  const onPressRemoveFile = (index) => {
    setPdfLinks(pdfLinks.filter((pdfNode, pdfNNodeIndex) => pdfNNodeIndex !== index));
  };

  const onPressRemoveRemoteFile = (pdfItem, index) => {
    setRemovedPdfs((oldData) => [...oldData, ...[pdfItem.filename]]);
    setRemotePdfLinks(remotePdfLinks.filter((pdfNode, pdfNNodeIndex) => pdfNNodeIndex !== index));
  };

  const renderUploadAttachments = () => {
    return (
      <div>
        <Form.Label className="communities-label">Attach Files</Form.Label>
        <div className="d-flex align-items-center mb-3">
          <Form.File
            label={`Supported upload formats: ${maxFileUploadSizeInMB}mb max in .pdf`}
            lang="en"
            accept={SUPPORTED_DOCUMENT_FORMAT_PDF}
            custom
            multiple={true}
            onClick={onPressUploadFileHandler}
            onChange={onChangeUploadFileHandler}
          />
        </div>
        {remotePdfLinks &&
          remotePdfLinks.length > 0 &&
          remotePdfLinks.map((pdfItem, index) => {
            return (
              <div
                key={index.toString()}
                className="d-flex align-items-center justify-content-between mb-2 p-2 ijpr-refer-form-dialog-uploaded-item-container">
                <div className="d-flex align-items-center">
                  <img
                    src={getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_DOCUMENT,
                      ACCESSIBILITY_IDENTIFIERS.COMMON
                    )}
                    className="ijpr-refer-form-dialog-doc-icon mr-2"
                  />
                  <p className="ijpr-refer-form-dialog-uploaded-item-title mb-0">
                    {pdfItem.pdf_name}
                  </p>
                </div>
                <div onClick={() => onPressRemoveRemoteFile(pdfItem, index)}>
                  <p className="ijpr-refer-form-dialog-uploaded-item-remove-text mb-0 common-cursor-pointer">
                    Remove
                  </p>
                </div>
              </div>
            );
          })}
        {pdfLinks &&
          pdfLinks.length > 0 &&
          pdfLinks.map((pdfItem, index) => (
            <div
              key={index.toString()}
              className="d-flex align-items-center justify-content-between mb-2 p-2 ijpr-refer-form-dialog-uploaded-item-container">
              <div className="d-flex align-items-center">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_DOCUMENT,
                    ACCESSIBILITY_IDENTIFIERS.COMMON
                  )}
                  className="ijpr-refer-form-dialog-doc-icon mr-2"
                />
                <p className="ijpr-refer-form-dialog-uploaded-item-title mb-0">
                  {pdfItem.fileNameOrg}
                </p>
              </div>
              <div onClick={() => onPressRemoveFile(index)}>
                <p className="ijpr-refer-form-dialog-uploaded-item-remove-text mb-0 common-cursor-pointer">
                  Remove
                </p>
              </div>
            </div>
          ))}
      </div>
    );
  };

  const renderVideo = () => {
    return (
      <div>
        <Form.Group>
          <Form.Label className="communities-label">Video URL</Form.Label>
          <div className="d-flex align-items-center">
            <Form.Control
              type="text"
              onChange={(event) => setVideoLink(event.target.value)}
              value={videoLink}
            />
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_ADD_LINK,
                ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
              )}
              className="header-icon ml-3"
              onClick={onPressVideoLink}
            />
          </div>
        </Form.Group>
        {videoLinks.length > 0 &&
          videoLinks.map((item, index) => {
            return (
              <div key={`${index}`} className="d-flex justify-content-between mb-3">
                <div className="flex-fill">
                  <a
                    className="community-video-link-text"
                    target="_blank"
                    rel="noreferrer"
                    href={item}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}>
                    {item}
                  </a>
                  <Divider style="mt-2" />
                </div>
                <div className="ml-3 pr-1">
                  <img
                    src={getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_CLOSE,
                      ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                    )}
                    onClick={() => deleteVideoLink(item)}
                    className="community-cross-remove-link common-cursor-pointer"
                  />
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const renderUrl = () => {
    return (
      <div>
        <Form.Group>
          <Form.Label className="communities-label">Website URL</Form.Label>
          <div className="d-flex align-items-center">
            <Form.Control
              type="text"
              onChange={(event) => setLink(event.target.value)}
              value={link}
            />
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_ADD_LINK,
                ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
              )}
              className="header-icon ml-3"
              onClick={onPressAddLink}
            />
          </div>
        </Form.Group>
        {links.length > 0 &&
          links.map((item, index) => {
            return (
              <div key={`${index}`} className="d-flex justify-content-between mb-3">
                <div className="flex-fill text-truncate">
                  <a
                    className="community-video-link-text"
                    target="_blank"
                    rel="noreferrer"
                    href={item}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}>
                    {item}
                  </a>
                  <Divider style="mt-2" />
                </div>
                <div className="ml-3 pr-1">
                  <img
                    src={getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_CLOSE,
                      ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                    )}
                    onClick={() => deleteLink(item)}
                    className="community-cross-remove-link common-cursor-pointer"
                  />
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const renderTagEmployee = () => {
    return (
      <div>
        <div
          onClick={() => {
            setShowTagEmployeeView(true);
          }}
          className="d-flex common-cursor-pointer">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_TAG_PEOPLE,
              ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
            )}
            className="header-icon mr-2"
          />
          <div className="communities-tag-people">Tag Member(s)</div>
        </div>
        {showTagEmployeeView && (
          <TagEmployeeView onEmployeeSelected={onEmployeeSelected} tags={tags} />
        )}
        <div className="d-flex flex-wrap mt-3">
          {tags.length > 0 &&
            tags.map((item, index) => {
              return (
                <div key={`${index}`} className="col-6 col-sm-3 col-lg-2 mb-4">
                  <div>
                    <UserProfilePhoto
                      imageBaseUrl={item.profile_picture.base_url}
                      imagePath={item.profile_picture.image_path}
                      imageClass="common-user-profile-photo-lg"
                    />
                    <div className="text-center mt-1">{item.first_name + ' ' + item.last_name}</div>
                  </div>
                  <div
                    className="d-flex position-absolute community-bg-view-image"
                    onClick={() => deleteEmployee(item)}>
                    <img
                      src={getFeatureComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.SOM_EMPLOYEE_CANCEL,
                        ACCESSIBILITY_IDENTIFIERS.SOM
                      )}
                      className="header-icon mr-3"
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const onPressSubmitHandler = async () => {
    const { description, addedMentions } = extractDataFromEditorState(editorState);
    let tempDes = description;

    tempDes = tempDes.replace(/ {2,}(?![\n])/g, ' ');

    addedMentions.map((user) => {
      tempDes = tempDes.replace(user.name, `<rsu>{{${user.employee_id}}}</rsu> `);
    });

    if (Object.keys(post).length > 0 && post?.taggged_employees) {
      Object.values(post.taggged_employees).map((user) => {
        tempDes = tempDes.replace(user.full_name, `<rsu>{{${user.employee_id}}}</rsu> `);
      });
    }

    logEvent(
      isEditMode
        ? ANALYTICS_EVENT_TYPES.THE_WALL_UPDATE_POST_CLICK
        : ANALYTICS_EVENT_TYPES.THE_WALL_PUBLISH_NEW_POST_CLICK,
      isEditMode ? post.post_id.toString() : '',
      ANALYTICS_ITEM_NAMES.THE_WALL
    );
    publishPostApi(tempDes);
  };

  const onChange = (editorState) => {
    setEditorState(editorState);
  };

  const onMentionsSearchChange = async ({ value }) => {
    if (
      value.length > 2 &&
      (tempMention === '' || !value.startsWith(tempMention)) &&
      value.charAt(0) !== ' '
    ) {
      let requestParams = {
        page_id: 1,
        query: value,
        ignore_me: 1,
      };
      try {
        setMentionsList([]);
        const apiResponse = await apiRequest(GET_DIRECTORY_DATA, REQUEST_TYPE.GET, requestParams);
        const tags = [];
        const feeds = apiResponse.response.data?.feeds;
        if (feeds.length <= 0) {
          setTempMention(value);
        }
        feeds.map((item, index) => {
          const profile = item.profile_picture?.image_path
            ? item.profile_picture.base_url + 'ldpi/' + item.profile_picture.image_path
            : getFeatureComponentUrlLdpi(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
                ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
              );
          tags.push({
            type: 'mention',
            name: `${item.first_name} ${item.last_name} `,
            avatar: profile,
            employee_id: item.employee_id,
          });
          if (feeds.length === index + 1) {
            setMentionsList([...tags]);
          }
        });
      } catch (err) {
        handleError(err, requestParams, GET_DIRECTORY_DATA, NAVIGATION_ROUTES.THE_WALL_CREATE_POST);
      }
    } else {
      setMentionsList([]);
    }
  };

  const onHashtagsSearchChange = async ({ value }) => {
    if (value.includes(' ')) {
      setHashtagsList([]);
      return;
    }
    if (value.length > 2 && (tempHashtag === '' || !value.startsWith(tempHashtag))) {
      let requestParams = {
        page_id: 1,
        query: value,
      };
      try {
        const apiResponse = await apiRequest(GET_HASHTAGS, REQUEST_TYPE.GET, requestParams);
        const tags = [];
        const tagsResp = apiResponse.response.data.tags;
        if (tagsResp.length <= 0) {
          setTempHashtag(value);
        }
        tagsResp.map((item, index) => {
          tags.push({ ...item, name: item + ' ', type: 'hashtag', index });
        });
        setHashtagsList([...tags]);
      } catch (err) {
        handleError(err, requestParams, GET_HASHTAGS, NAVIGATION_ROUTES.THE_WALL_CREATE_POST);
      }
    } else if (trendingHashtags?.length > 0 && value.length < 3) {
      const tags = [];
      trendingHashtags.map((item, index) => {
        tags.push({ name: item + ' ', type: 'hashtag', index });
      });
      setHashtagsList([...tags]);
    } else {
      setHashtagsList([]);
    }
  };

  const resetFilters = (props) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.HASHTAG_SUGGESTION_TAP,
      0,
      props.name,
      ANALYTICS_ITEM_NAMES.HASHTAG
    );
    // setMentionsList([]);
    setHashtagsList([]);
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.THE_WALL} />
      <BackButton />
      <div className="main-content-container">
        <div className="d-flex justify-content-center">
          <div className="common-container-md">
            <Form.Group>
              <Form.Label className="communities-label">{`Description (Max length ${
                wallPostConfig?.description_limit ?? 1000
              } characters)`}</Form.Label>
              <CustomMentionEditor
                mentions={mentionsList}
                hashtags={hashtagsList}
                editorState={editorState}
                onChange={onChange}
                onMentionsSearchChange={onMentionsSearchChange}
                onHashtagsSearchChange={onHashtagsSearchChange}
                resetFilters={resetFilters}
                enableUserMentions={true}
                enableHashtags={true}
              />
            </Form.Group>
            {renderImage()}
            {renderUploadAttachments()}
            {renderVideo()}
            {renderUrl()}
            {renderTagEmployee()}

            <CustomButton
              buttonMainContainer="d-flex justify-content-end"
              onClick={onPressSubmitHandler}
              showLoader={progressLoadingState.isProgressLoading}>
              {isEditMode ? 'Update Post' : 'Publish Post'}
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TheWallNewPostScreen;
