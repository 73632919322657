import React from 'react';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { formatDateDDMonthYYYY, formatNumberToClosestAbbreviation } from '../../../utils/common';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../constants/AppConstants';

const LoadDateText = (props) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  return (
    <div className="d-flex flex-row justify-content-between align-items-center">
      <div className="row ml-1 mt-1 align-items-center">
        <img
          src={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.NEWS_CALENDER,
            ACCESSIBILITY_IDENTIFIERS.NEWS
          )}
          className="common-load-date-text-calender-style"
        />
        <div className="common-load-date-text-event-date">
          {formatDateDDMonthYYYY(props.pubDatetime)}
        </div>
      </div>
      {!!props.pageViews && props.pubDatetime && (
        <div className="common-load-date-text-event-date mt-1">
          {formatNumberToClosestAbbreviation(props.pageViews, 1)} view
          {props.pageViews === 1 ? '' : 's'}
        </div>
      )}
    </div>
  );
};

export default LoadDateText;
