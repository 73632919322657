import React from 'react';
import { getUrlWithSpecificRendition } from '../../../utils/common';
import { SCREEN_DENSITY_QUALIFIERS } from '../../../common/constants/AppConstants';
import PlayIconOverlay from '../../../common/ui/play_icon_overlay/PlayIconOverlay';

const VolunteerListItem = ({ item, onPress }) => {
  return (
    <div className="col-sm-4 common-cursor-pointer" onClick={() => onPress(item)}>
      <div className="microgive-view-style">
        {item?.content_load_type === '3' && <PlayIconOverlay />}
        <img
          src={getUrlWithSpecificRendition(
            item.promo_image.base_url,
            SCREEN_DENSITY_QUALIFIERS.HDPI,
            item.promo_image.image_path
          )}
          className="embed-responsive embed-responsive-16by9"
        />
        <div className="microgive-title">{item.title}</div>
        <div className="microgive-short-description">{item.short_description}</div>
      </div>
    </div>
  );
};

export default VolunteerListItem;
