import { TRAVEL_REQUEST_STATUS_IDENTIFIERS } from '../common/constants/AppConstants';

export const renderRequestStatusBgColor = (requestStatusIdentifier) => {
  if (
    requestStatusIdentifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.PRE_APPROVED ||
    requestStatusIdentifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.APPROVE ||
    requestStatusIdentifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.BOOKING_IN_PRGRESS ||
    requestStatusIdentifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.BOOKED
  ) {
    return 'travel-request-request-indicator-approved';
  } else if (
    requestStatusIdentifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.REQUEST_CANCELLED ||
    requestStatusIdentifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.REJECTED ||
    requestStatusIdentifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.BOOKING_CANCELLED ||
    requestStatusIdentifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.BOOKING_CANCELLATION_REQUESTED
  ) {
    return 'travel-request-request-indicator-cancelled';
  } else if (
    requestStatusIdentifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.DRAFT ||
    requestStatusIdentifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.AWAITING_APPROVAL
  ) {
    return 'travel-request-request-indicator-awaiting-approval';
  } else if (requestStatusIdentifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.MODIFICATION) {
    return 'travel-request-request-indicator-mod-requested';
  }
  return 'travel-request-request-indicator-awaiting-approval';
};

export const renderRequestStatusStyles = (requestStatusIdentifier) => {
  if (
    requestStatusIdentifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.PRE_APPROVED ||
    requestStatusIdentifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.APPROVE ||
    requestStatusIdentifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.BOOKING_IN_PRGRESS ||
    requestStatusIdentifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.BOOKED
  ) {
    return 'travel-request-comment-box-approved';
  } else if (
    requestStatusIdentifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.REQUEST_CANCELLED ||
    requestStatusIdentifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.REJECTED ||
    requestStatusIdentifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.BOOKING_CANCELLED ||
    requestStatusIdentifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.BOOKING_CANCELLATION_REQUESTED
  ) {
    return 'travel-request-comment-box-cancelled';
  } else if (
    requestStatusIdentifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.DRAFT ||
    requestStatusIdentifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.AWAITING_APPROVAL
  ) {
    return 'travel-request-comment-box-awaiting-approval';
  } else if (requestStatusIdentifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.MODIFICATION) {
    return 'travel-request-comment-box-mod-requested';
  }
  return 'travel-request-comment-box-mod-requested';
};
