import React from 'react';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../constants/AppConstants';

export default function PlayIconOverlay({ customizeForNewJoineees = false }) {
  const accessibilityData = useSelector(selectAccessibilityData);
  return (
    <div
      className={
        customizeForNewJoineees
          ? `new-joinee-article-style common-cursor-pointer play-icon-container play-icon-container-paddings`
          : `embed-responsive embed-responsive-16by9 play-icon-container`
      }>
      <img
        src={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_PLAY_VIDEO_WHITE,
          ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
        )}
        className="video-play-icon"
      />
    </div>
  );
}
