import { ACCESSIBILITY_IDENTIFIERS } from '../common/constants/AppConstants';
import {
  formatPathWithBaseUrl,
  formatUrlWithDeviceScreenDensityQualifier,
  formatUrlWithDeviceScreenDensityQualifierLdpi,
  formatUrlWithDeviceScreenDensityQualifierXxxHdpi,
} from './common';

export const formatUrlWithHighlightTag = (url) => {
  let transformedUrl = url.split('/');
  transformedUrl[transformedUrl.length - 1] =
    transformedUrl[transformedUrl.length - 1].split('.')[0] +
    'Red.' +
    transformedUrl[transformedUrl.length - 1].split('.')[1];
  transformedUrl = transformedUrl.join('/');
  return transformedUrl;
};

export const getFeatureComponent = (accessibilityData, component, identifier) => {
  const feature = accessibilityData.find((data) => data.identifier === identifier);
  if (feature) {
    return feature[component];
  }
};

export const getHeaderComponentUrl = (
  accessibilityData,
  component,
  identifier = ACCESSIBILITY_IDENTIFIERS.HEADER
) => {
  const header = accessibilityData.find((data) => data.identifier === identifier);
  if (header && header[component]) {
    const path = formatUrlWithDeviceScreenDensityQualifier(header[component]);
    const url = formatPathWithBaseUrl(path);
    return url;
  }
};

export const getFeatureComponentUrl = (accessibilityData, component, identifier) => {
  const feature = accessibilityData.find((data) => data.identifier === identifier);
  if (feature && feature[component]) {
    const path = formatUrlWithDeviceScreenDensityQualifier(feature[component]);
    const url = formatPathWithBaseUrl(path);
    return url;
  }
};

export const getFeatureComponentUrlLdpi = (accessibilityData, component, identifier) => {
  const feature = accessibilityData.find((data) => data.identifier === identifier);
  if (feature && feature[component]) {
    const path = formatUrlWithDeviceScreenDensityQualifierLdpi(feature[component]);
    const url = formatPathWithBaseUrl(path);
    return url;
  }
};

export const getFeatureComponentUrlXxxHdpi = (accessibilityData, component, identifier) => {
  const feature = accessibilityData.find((data) => data.identifier === identifier);
  if (feature && feature[component]) {
    const path = formatUrlWithDeviceScreenDensityQualifierXxxHdpi(feature[component]);
    const url = formatPathWithBaseUrl(path);
    return url;
  }
};

export const getHighlightedMenuIcon = (accessibilityData, component, identifier) => {
  const feature = accessibilityData.find((data) => data.identifier === identifier);
  if (feature && feature[component]) {
    const path = formatUrlWithDeviceScreenDensityQualifier(feature[component]);
    const formattedPath = formatUrlWithHighlightTag(path);
    const url = formatPathWithBaseUrl(formattedPath);
    return url;
  }
};
