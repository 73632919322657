import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GivenTab from '../myCheersTabs/GivenTab';
import ReceivedTab from '../myCheersTabs/ReceivedTab';
import { selectMyCheers } from '../../../../state/MasterData';
import { Tab } from 'react-bootstrap';
import { saveCheersMyCheersTabData, selectCheersMyCheersTabData } from '../../../../state/TabData';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
} from '../../../../common/constants/AppConstants';
import NavTabs from '../../../../common/ui/tabs/NavTabs';

const MyCheersTab = () => {
  const dispatch = useDispatch();
  const myCheers = useSelector(selectMyCheers);
  const myCheersTabData = useSelector(selectCheersMyCheersTabData);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.MY_CHEERS_TAB_VISIT, '', ANALYTICS_ITEM_NAMES.CHEERS);
  }, []);

  useEffect(() => {
    if (myCheers.length && myCheersTabData === '') {
      dispatch(saveCheersMyCheersTabData(myCheers[0].key));
    }
  }, [dispatch, myCheers, myCheersTabData]);

  const renderTabs = (tab) => {
    switch (tab.key) {
      case 'received':
        return <ReceivedTab />;
      case 'given':
        return <GivenTab />;
    }
  };

  return (
    <div>
      <NavTabs activeKey={myCheersTabData} onSelect={(k) => dispatch(saveCheersMyCheersTabData(k))}>
        {myCheers.map((tab, index) => {
          return (
            <Tab key={`${index}`} eventKey={tab.key} title={tab.title}>
              {renderTabs(tab)}
            </Tab>
          );
        })}
      </NavTabs>
    </div>
  );
};

export default React.memo(MyCheersTab);
