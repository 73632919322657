import { createSlice } from '@reduxjs/toolkit';

const saveNotificationCountReducer = (state, action) => {
  state.notificationCount = action.payload;
};

const updateNotificationCountReducer = (state, action) => {
  state.notificationCount = state.notificationCount ? state.notificationCount + 1 : 1;
};

const resetNotificationCountReducer = (state, action) => {
  state.notificationCount = 0;
};

// REDUCER
const notificationDataSlice = createSlice({
  name: 'notificationData',
  initialState: {
    notificationCount: undefined,
  },
  reducers: {
    saveNotificationCount: saveNotificationCountReducer,
    updateNotificationCount: updateNotificationCountReducer,
    resetNotificationCount: resetNotificationCountReducer,
  },
});

// ACTIONS
const { saveNotificationCount, updateNotificationCount, resetNotificationCount } =
  notificationDataSlice.actions;

// SELECTOR
const selectNotificationCount = ({ notificationData }) => notificationData.notificationCount;

const notificationDataSliceReducer = notificationDataSlice.reducer;

export {
  notificationDataSliceReducer,
  saveNotificationCount,
  selectNotificationCount,
  updateNotificationCount,
  resetNotificationCount,
};
