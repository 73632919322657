import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { formatDateDDMonthYYYY, getUrlWithSpecificRendition } from '../../../utils/common';

const AccoladesListItem = ({ item, onPress }) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  return (
    <div className="mt-2 mb-2 col-sm-4 common-cursor-pointer" onClick={() => onPress(item)}>
      <div className="ourcustomers-cross-axis-view">
        <img
          src={getUrlWithSpecificRendition(
            item.company_logo.base_url,
            SCREEN_DENSITY_QUALIFIERS.LDPI,
            item.company_logo.image_path
          )}
          className="common-user-profile-photo-lg"
        />
        <div className="ml-3 ourcustomers-cross-axis-view">
          <div>
            <div className="ourcustomers-flex mt-1">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_CALENDER,
                  ACCESSIBILITY_IDENTIFIERS.MICROGIVE
                )}
                className="ourcustomers-calender-style"
              />
              <div className="ourcustomers-event-date">{formatDateDDMonthYYYY(item.date)}</div>
            </div>
            <div className="mt-1 ourcustomers-author">{item.author}</div>
            <div className="mt-1 ourcustomers-accolades-designation">{item.designation}</div>
            <div className="mt-1 ourcustomers-accolades-title">{item.title}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccoladesListItem;
