import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { selectSalesIncentive } from '../../state/MasterData';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import HtmlView from '../../common/ui/html_view/HTMLView';

const SalesIncentiveScreen = () => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const salesIncentive = useSelector(selectSalesIncentive);

  const onPressLogin = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.SALES_INCENTIVE_OPEN_WEBVIEW,
      '',
      ANALYTICS_ITEM_NAMES.SALES_INCENTIVE
    );
    let win = window.open(`${salesIncentive._sales_incentive_url}`);
    win.focus();
  };

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.SALES_INCENTIVE, NAVIGATION_ROUTES.SALES_INCENTIVE);
  }, []);

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.SALES_INCENTIVE} />
      <div className="main-content-container">
        {salesIncentive && (
          <div>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.SALES_INCENTIVE_BANNER,
                ACCESSIBILITY_IDENTIFIERS.SALES_INCENTIVE
              )}
              className="sales-incentive-banner-image"
            />
            <HtmlView html={salesIncentive._sales_incentive_config} htmlStyle="mt-3" />
            <div className="d-flex justify-content-center">
              <CustomButton onClick={onPressLogin}>Login</CustomButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SalesIncentiveScreen;
