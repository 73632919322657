import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  FILTER_IDENTIFIERS,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import Filter from '../../../common/ui/filter/Filter';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { EMPLOYEES } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getHeaderComponentUrl } from '../../../utils/accessibility';

const TagEmployee = ({ onEmployeeSelected, tags }) => {
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeArray, setEmployeeArray] = useState([]);

  const [searchText, setSearchText] = useState('');
  const pageIdRef = useRef(1);
  const loadMoreFlagRef = useRef(true);
  const appliedFiltersRef = useRef({});

  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    setEmployeeArray(tags);
  }, [tags]);

  const callEmployees = useCallback(async (text) => {
    if (loadMoreFlagRef.current === true) {
      let params = { page_id: pageIdRef.current };
      if (text !== '') {
        params = { ...params, ...{ query: text } };
      }
      if (Object.keys(appliedFiltersRef.current).length) {
        params = { ...params, ...appliedFiltersRef.current };
      }
      try {
        const apiResponse = await apiRequest(EMPLOYEES, REQUEST_TYPE.GET, params);
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            if (apiResponse.response.data.feeds.length > 0) {
              setEmployeeData((oldArray) => [...oldArray, ...apiResponse.response.data.feeds]);
              loadMoreFlagRef.current = true;
              pageIdRef.current = pageIdRef.current + 1;
            } else {
              loadMoreFlagRef.current = false;
            }
          }
        }
      } catch (err) {
        handleError(err, params, EMPLOYEES, NAVIGATION_ROUTES.TAG_EMPLOYEE);
        //
      }
    }
  }, []);

  useEffect(() => {
    callEmployees('');
  }, [callEmployees]);

  const addEmployee = (employee) => {
    if (employeeArray.some((person) => person.employee_id === employee.employee_id)) {
      const index = employeeArray.findIndex(
        (person) => person.employee_id === employee.employee_id
      );
      if (index > -1) {
        setEmployeeArray(employeeArray.filter((item) => item !== employeeArray[index]));
      }
    } else {
      setEmployeeArray((prev) => [...prev, employee]);
    }
  };

  const onClickFilterHandler = (selectedFilters) => {
    let tempFilterObject = {};
    Object.keys(selectedFilters).map((filterKey) => {
      if (selectedFilters[filterKey].length) {
        tempFilterObject[filterKey] = selectedFilters[filterKey].join();
      }
    });
    appliedFiltersRef.current = tempFilterObject;
    setEmployeeData([]);
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    callEmployees(searchText);
  };

  const loadMoreHandler = () => {
    callEmployees(searchText);
  };

  const onSearchSubmitHandler = (event) => {
    event.preventDefault();
    setEmployeeData([]);
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    callEmployees(searchText);
  };

  const onTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const onClickSearchHandler = () => {
    setIsSearchEnabled(true);
  };

  const resetSearchHandler = useCallback(() => {
    setIsSearchEnabled(false);
    setSearchText('');
    setEmployeeData([]);
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    callEmployees('');
  }, [callEmployees]);

  const showProfiles = () => {
    return employeeData.map((profile, index) => {
      return (
        <div key={`${index}`} className="anniversary-emp-image-div col-6 col-sm-3 col-lg-2">
          <UserProfilePhoto
            imageBaseUrl={profile.profile_picture.base_url}
            imagePath={profile.profile_picture.image_path}
            employeeId={profile.employee_id}
            imageClass="common-user-profile-photo-xl"
          />
          <p className="anniversary-profile-name">{profile.first_name}</p>
          <p className="anniversary-profile-name">{profile.last_name}</p>
          <div className="anniversary-check-row">
            <input
              type="checkbox"
              id="employeeCheck"
              name="employeeCheck"
              className="anniversary-employee-check-box"
              onChange={() => addEmployee(profile)}
              checked={
                employeeArray.some((person) => person.employee_id === profile.employee_id)
                  ? true
                  : false
              }
            />
            <div>Tag</div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="mt-3 mb-3">
      <div className="header-view d-flex justify-content-between">
        {isSearchEnabled ? (
          <form className="microgive-search-form" onSubmit={onSearchSubmitHandler}>
            <input
              autoFocus
              className="ml-3 microgive-search-bar"
              type="text"
              placeholder="Search.."
              onChange={onTextChange}
              value={searchText}
            />
          </form>
        ) : (
          <div />
        )}
        {isSearchEnabled ? (
          <div onClick={resetSearchHandler}>
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
              )}
              className="header-icon mr-3"
            />
          </div>
        ) : (
          <div onClick={onClickSearchHandler}>
            <img
              src={getHeaderComponentUrl(accessibilityData, ACCESSIBILITY_HEADER_COMPONENTS.SEARCH)}
              className="header-icon mr-3"
            />
          </div>
        )}
      </div>
      <div className="anniversary-body-container">
        <Filter
          topFilter={FILTER_IDENTIFIERS.GEOGRAPHIES}
          geographies={true}
          locations={true}
          levels={true}
          layers={true}
          serviceTenures={true}
          bloodGroups={true}
          onClick={onClickFilterHandler}
        />
      </div>
      <div className="anniversary-employee-view mt-2">
        <div className="row">{showProfiles()}</div>
        <div className="load-more">
          <LoadMoreBtn onClick={loadMoreHandler} />
        </div>
      </div>
      {employeeArray.length > 0 && (
        <div
          onClick={() => onEmployeeSelected(employeeArray)}
          className="anniversary-button mr-3 mt-2">
          Continue
        </div>
      )}
    </div>
  );
};

export default TagEmployee;
