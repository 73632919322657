import React, { useEffect, useCallback, useState } from 'react';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  FEATURE_TYPE,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../../services/Service';
import { COMMENT_BY_ID, GET_SURVEYS } from '../../../services/ApiUrls';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import SurveysListItem from '../components/SurveysListItem';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectShowEmptyContentView,
} from '../../../state/UIState';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import { showToast } from '../../../utils/common';
import { selectAccessData } from '../../../state/UserAccessData';

const SurveysTab = ({ tabKey }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [surveys, setSurveys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const accessData = useSelector(selectAccessData);
  const [showLikeButton, setShowLikeButton] = useState(true);
  const [showCommentButton, setShowCommentButton] = useState(true);
  const [showParticipate, setShowParticipate] = useState(true);
  const pollsAccessData = accessData?.data?.find((item) => item.feature_key === '_polls');

  useEffect(() => {
    const actions = pollsAccessData?.actions;
    setShowLikeButton(actions?.includes('_like'));
    setShowCommentButton(actions?.includes('_comment'));
    setShowParticipate(actions?.includes('_participate'));
  }, [pollsAccessData?.actions]);

  const showEmptyContentView = useSelector(selectShowEmptyContentView);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.SURVEYS_TAB_VISIT,
      tabKey,
      ANALYTICS_ITEM_NAMES.POLLS_AND_SURVEYS
    );
  }, [tabKey]);

  const callSurveysApi = useCallback(
    async (id, loadMore) => {
      setLoading(true);
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        const get_params = {
          page_id: id,
        };
        try {
          const apiResponse = await apiRequest(GET_SURVEYS, REQUEST_TYPE.GET, get_params);
          setLoading(false);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.surveys) {
                const data = apiResponse.response.data.surveys;
                if (data.length > 0) {
                  if (id === 1) {
                    setSurveys([]);
                  }
                  setSurveys((oldArray) => [...oldArray, ...data]);
                  setPageId((prev) => prev + 1);
                  setLoadMoreFlag(true);
                } else {
                  setLoadMoreFlag(false);
                }
              }
            }
          }
        } catch (err) {
          handleError(err, get_params, GET_SURVEYS, 'surveys');
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    callSurveysApi(1, true);
  }, [callSurveysApi]);

  const callLikeApi = async (referId) => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
      })
    );
    const params = new URLSearchParams();
    params.append('type', FEATURE_TYPE.SURVEY);
    try {
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + referId + '/like',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            const message = apiResponse.response.message;
            if (message) {
              showToast(message);
              let element = surveys.find((item) => item.survey_id === referId);
              element.liked = data.liked;
              element.like_count = data.like_count;
              setSurveys(surveys.map((item) => (referId === item.survey_id ? element : item)));
            }
          }
        }
      }
    } catch (err) {
      handleError(err, params, COMMENT_BY_ID + referId + '/like', 'surveys');
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  const onPressLikeButton = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.SURVEY_LIKE,
      item.survey_id.toString(),
      ANALYTICS_ITEM_NAMES.LIKE
    );
    callLikeApi(item.survey_id);
  };

  const onPressCommentButton = (item) => {
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: item.survey_id,
      likeType: FEATURE_TYPE.SURVEY,
      fromRoute: NAVIGATION_ROUTES.POLLS_AND_SURVEYS,
    });
  };

  const onPressParticipate = (item) => {
    if (item.survey_type === 'external') {
      window.open(item.external_url, '_blank');
    } else {
      history.push(NAVIGATION_ROUTES.SURVEYS_PARTICIPATE, {
        surveyId: item.survey_id,
      });
    }
  };

  const onPressLoadMore = () => {
    callSurveysApi(pageId, loadMoreFlag);
  };

  const emptyListView = () => {
    return <DefaultContentView message={NO_DATA_TEXT.NO_DATA_FOUND} />;
  };

  return (
    <div className="main-content-container">
      {showEmptyContentView && !surveys.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          <div className="row">
            {surveys.map((item, index) => {
              return (
                <SurveysListItem
                  item={item}
                  key={`${index}`}
                  onPressParticipate={onPressParticipate}
                  onPressLikeButton={onPressLikeButton}
                  onPressCommentButton={onPressCommentButton}
                  showLikeButton={showLikeButton}
                  showCommentButton={showCommentButton}
                  showParticipate={showParticipate}
                />
              );
            })}
          </div>
          <ActivityLoader visible={loading} />
          {loadMoreFlag && <LoadMoreBtn onClick={onPressLoadMore} isLoading={loading} />}
        </div>
      )}
    </div>
  );
};

export default SurveysTab;
