import React, { useEffect } from 'react';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import { trackScreen } from '../../../utils/FirebaseAnalyticsUtils';
import {
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import { getUrlWithSpecificRendition } from '../../../utils/common';

export default function AboutTab({ data }) {
  useEffect(() => {
    // Analytics
    trackScreen(ANALYTICS_SCREEN_NAMES.MI_CHILD_CARE_ABOUT_TAB, NAVIGATION_ROUTES.MI_CHILD_CARE);
  }, []);

  return (
    <div>
      <img
        src={getUrlWithSpecificRendition(
          data?.image?.base_url,
          SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
          data?.image?.image_path
        )}
        className="embed-responsive embed-responsive-16by9"
      />
      <HtmlView html={data?.body || 'No data'} htmlStyle="breakout-content-html mt-3" />
    </div>
  );
}
