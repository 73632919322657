import React, { useState, useEffect, useCallback } from 'react';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import { selectOurCustomersAllTitles } from '../../state/MasterData';
import { saveOurCustomerAllTabData, selectOurCustomerAllTabData } from '../../state/TabData';
import { saveProgressLoadingState, selectBaseUrl } from '../../state/UIState';
import { Tab } from 'react-bootstrap';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import NavTabs from '../../common/ui/tabs/NavTabs';
import AllKnowTab from './AllKnowTab';
import CustomerSpecificTab from './tabs/CustomerSpecificTab';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { apiRequest, handleError } from '../../services/Service';
import { COMMENT_BY_ID, CUSTOMERS_BY_ID } from '../../services/ApiUrls';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { useHistory } from 'react-router-dom';
import { selectAccessData } from '../../state/UserAccessData';

const OurCustomerAllMainScreen = ({ location }) => {
  const params = location.state;
  const history = useHistory();
  const ourCustomersTabs = useSelector(selectOurCustomersAllTitles);
  const ourCustomersTabdata = useSelector(selectOurCustomerAllTabData);
  const dispatch = useDispatch();
  const [tabKey] = useState(
    location && location.state && location.state.tabKey && location.state.tabKey
  );
  const accessibilityData = useSelector(selectAccessibilityData);
  const baseUrl = useSelector(selectBaseUrl);
  const [liked, setLiked] = useState();
  const [likeCount, setLikeCount] = useState();
  const [commentCount, setCommentCount] = useState();
  const [customerTabList, setCustomerTabList] = useState([]);
  const accessData = useSelector(selectAccessData);
  const [showLike, setShowLike] = useState(true);
  const [showComment, setShowcomment] = useState(true);
  const [showMicroViewTab, setShowMicroViewTab] = useState(true);
  const customerAccessData = accessData?.data?.find((item) => item.feature_key === '_ourCustomers');

  useEffect(() => {
    const actions = customerAccessData?.actions;
    setShowLike(actions?.includes('_like'));
    setShowcomment(actions?.includes('_comment'));
    setShowMicroViewTab(actions?.includes('_microview'));
  }, [customerAccessData?.actions]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.OUR_CUSTOMER, NAVIGATION_ROUTES.OUR_CUSTOMER);
  }, []);

  const callOurCustomerApi = useCallback(async () => {
    try {
      const apiResponse = await apiRequest(
        CUSTOMERS_BY_ID + params?.apiType + '/' + params?.customerId,
        REQUEST_TYPE.GET,
        {}
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.know) {
            const data = apiResponse.response.data.know;
            setLiked(data?.liked);
            setLikeCount(data?.like_count);
            setCommentCount(data?.comment_count);
          }
        }
      }
    } catch (err) {
      handleError(
        err,
        {},
        CUSTOMERS_BY_ID + params?.apiType + '/' + params?.customerId,
        NAVIGATION_ROUTES.OUR_CUSTOMER_ALL_SCREEN
      );
      null;
    }
  }, []);

  const handleTabs = useCallback(() => {
    if (ourCustomersTabs.length > 0) {
      let list = ourCustomersTabs.filter((item) => item?.is_active === true);
      setCustomerTabList(list);
    }
  }, [ourCustomersTabs]);

  useEffect(() => {
    handleTabs();
  }, [handleTabs, ourCustomersTabs]);

  useEffect(() => {
    callOurCustomerApi();
  }, [callOurCustomerApi]);

  useEffect(() => {
    if (ourCustomersTabs.length && ourCustomersTabdata === '' && tabKey) {
      dispatch(saveOurCustomerAllTabData(tabKey));
    } else if (ourCustomersTabs.length && ourCustomersTabdata === '') {
      dispatch(saveOurCustomerAllTabData(ourCustomersTabs[0].key));
    }
  }, [dispatch, ourCustomersTabdata, ourCustomersTabs, tabKey]);

  const renderTabs = (tab) => {
    switch (tab.key) {
      case 'know':
        return (
          <AllKnowTab
            type={tab.key}
            title={tab.title}
            customerId={params?.customerId}
            dataType={params?.dataType}
            parentName={params?.parentName}
            apiType={params?.apiType}
            likeType={params?.likeType}
          />
        );
      default:
        return (
          <CustomerSpecificTab type={tab.key} title={tab.title} customerId={params?.customerId} />
        );
    }
  };

  const postLike = async () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.OUR_CUSTOMERS_LIKE,
      params?.customerId,
      ANALYTICS_ITEM_NAMES.LIKE
    );
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
      })
    );
    try {
      const formData = new URLSearchParams();
      formData.append('type', params?.likeType);
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + params?.customerId + '/like',
        REQUEST_TYPE.POST,
        formData
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            setLikeCount(data.like_count);
            setLiked(data.liked);
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  const renderHeader = () => {
    return (
      <div className="broadcast-header-row justify-content-end">
        {showLike && (
          <>
            <div className="broadcast-icon-row mr-3" onClick={postLike}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  liked
                    ? ACCESSIBILITY_HEADER_COMPONENTS.ACTIVE_LIKE
                    : ACCESSIBILITY_HEADER_COMPONENTS.INACTIVE_LIKE
                )}
                className="header-icon"
              />
            </div>
            <div
              className="mr-3 ourcustomer-like-comment common-cursor-pointer"
              onClick={() => {
                likeCount
                  ? history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
                      referId: params?.customerId,
                      parentType: params?.likeType,
                    })
                  : () => {};
              }}>
              {likeCount ? likeCount : '0'}
            </div>
          </>
        )}
        {showComment && (
          <>
            <div
              className="broadcast-icon-row mr-3"
              onClick={() => {
                history.push(NAVIGATION_ROUTES.COMMENTS, {
                  referId: params?.customerId,
                  likeType: params?.likeType,
                  fromRoute: NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT,
                });
              }}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.COMMENT
                )}
                className="header-icon"
              />
            </div>
            <div
              className="mr-3 ourcustomer-like-comment common-cursor-pointer"
              onClick={() => {
                logEvent(
                  ANALYTICS_EVENT_TYPES.OUR_CUSTOMER_COMMENT,
                  params?.customerId,
                  ANALYTICS_ITEM_NAMES.COMMENT
                );
                history.push(NAVIGATION_ROUTES.COMMENTS, {
                  referId: params?.customerId,
                  likeType: params?.likeType,
                  fromRoute: NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT,
                });
              }}>
              {commentCount ? commentCount : '0'}
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="container">
      <SectionHeader title={params ? params?.headerName : 'LOADING...'} />
      <div className="header-view justify-content-between">
        <BackButton isFullRow={false} />
        {params && renderHeader()}
      </div>
      {baseUrl !== '' && (
        <NavTabs
          activeKey={ourCustomersTabdata}
          onSelect={(k) => dispatch(saveOurCustomerAllTabData(k))}
          unmountOnExit>
          {customerTabList &&
            customerTabList.map((tab, index) => {
              if (tab.key === 'researches' && !showMicroViewTab) {
                return null;
              }
              return (
                <Tab
                  key={`${index}`}
                  eventKey={tab.key}
                  title={tab.title}
                  className={
                    tab.key === 'know' ? 'main-content-container' : 'main-content-container-padding'
                  }>
                  {renderTabs(tab, index)}
                </Tab>
              );
            })}
        </NavTabs>
      )}
    </div>
  );
};

export default OurCustomerAllMainScreen;
