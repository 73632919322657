import { call, put, takeEvery } from 'redux-saga/effects';
import { ACTION_TYPES, NAVIGATION_ROUTES, REQUEST_TYPE } from '../common/constants/AppConstants';
import { DASHBOARD } from '../services/ApiUrls';
import { apiRequest, handleError } from '../services/Service';
import { saveDashboardData } from '../state/DashboardData';

export function* getDashboard(action) {
  try {
    const apiResponse = yield call(apiRequest, DASHBOARD, REQUEST_TYPE.GET, action.payload);
    if (apiResponse) {
      if (apiResponse.response.status === true) {
        yield put(saveDashboardData(apiResponse.response.data));
      }
    }
  } catch (e) {
    handleError(e, action.payload, DASHBOARD, NAVIGATION_ROUTES.HOME);
    yield put(saveDashboardData([]));
  }
}

export function* watchDashboard() {
  yield takeEvery(ACTION_TYPES.GET_DASHBOARD, getDashboard);
}
