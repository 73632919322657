import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  FEATURE_TYPE,
  LOADING_MESSAGES,
  REQUEST_TYPE,
  NAVIGATION_ROUTES,
  ANALYTICS_SCREEN_NAMES,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  SCREEN_DENSITY_QUALIFIERS,
  ACCESSIBILITY_FEATURE_COMPONENTS,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import HtmlView from '../../common/ui/html_view/HTMLView';
import LoadDateText from '../../common/ui/load_date_text/LoadDateText';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import {
  COMMENT_BY_ID,
  ARTICLE_BY_ID,
  INCREASE_VIEW_COUNT_EXTERNAL_LINK,
} from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { selectWebBaseUrl } from '../../state/MasterData';
import { saveProgressLoadingState, saveSearchText } from '../../state/UIState';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import {
  getSecuredPdfUrl,
  getUrlWithSpecificRendition,
  getYoutubeIdFromUrl,
  getYoutubeVideoImageFromUrl,
} from '../../utils/common';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import CustomYoutubePlayer from '../../common/ui/youtube_video/CustomYoutubePlayer';
import { selectAccessData } from '../../state/UserAccessData';
import ReactPlayer from 'react-player';
import { VscChromeClose } from 'react-icons/vsc';
import { Modal } from 'react-bootstrap';

const MiHRContentScreen = ({ location, history }) => {
  const params = location.state;
  const articleId = useParams().articleId;
  const dispatch = useDispatch();
  const [article, setArticle] = useState();
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState();
  const [commentCount, setCommentCount] = useState();
  const accessibilityData = useSelector(selectAccessibilityData);
  const [showPdf, setShowPdf] = useState(false);
  const webUrl = useSelector(selectWebBaseUrl);
  const accessData = useSelector(selectAccessData);
  const [showLikeButton, setShowLikeButton] = useState(true);
  const [showCommentButton, setShowCommentButton] = useState(true);
  const mihrAccessData = accessData?.data?.find((item) => item.feature_key === '_miHRSupport');
  const [showVideoItem, setShowVideoItem] = useState(false);

  const handleVideoItemClose = () => setShowVideoItem(false);
  const handleVideoItemShow = () => setShowVideoItem(true);

  useEffect(() => {
    if (mihrAccessData) {
      const actions = mihrAccessData?.actions;
      setShowLikeButton(actions?.includes('_like'));
      setShowCommentButton(actions?.includes('_comment'));
    }
  }, [mihrAccessData]);

  const getArticleDetailsApi = useCallback(async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      logEvent(ANALYTICS_EVENT_TYPES.MI_HR_READ, articleId, '');
      const apiResponse = await apiRequest(ARTICLE_BY_ID + articleId, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.article) {
            const data = apiResponse.response.data.article;
            setArticle(data);
            setLiked(data.liked);
            setCommentCount(data.comment_count);
            setLikeCount(data.like_count);
            if (data.content_load_type === '1') {
              try {
                const es = await apiRequest(
                  INCREASE_VIEW_COUNT_EXTERNAL_LINK + articleId,
                  REQUEST_TYPE.GET,
                  {}
                );
              } catch (err) {
                handleError(
                  err,
                  {},
                  INCREASE_VIEW_COUNT_EXTERNAL_LINK + articleId,
                  NAVIGATION_ROUTES.MI_HR_DETAILS
                );
                //ignore if the view count request is not successful
                console.warn(err);
              }
              window.open(data.url);
              history.goBack();
            }
            if (data.pdf.pdf_path && data.content_load_type === '2') {
              setShowPdf(true);
            }
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(err, {}, ARTICLE_BY_ID + articleId, NAVIGATION_ROUTES.MI_HR_DETAILS);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, articleId, history]);

  const renderScreen = useCallback(async () => {
    getArticleDetailsApi();
  }, [getArticleDetailsApi]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.MI_HR_DETAILS_SCREEN, NAVIGATION_ROUTES.MI_HR_SUPPORT);
  }, []);

  //like post api
  const postLikeFunction = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
      })
    );
    logEvent(
      ANALYTICS_EVENT_TYPES.MI_HR_UPDATE_LIKE,
      article.article_id,
      ANALYTICS_ITEM_NAMES.LIKE
    );
    const formData = new URLSearchParams();
    formData.append('type', FEATURE_TYPE.ARTICLES);
    try {
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + article.article_id + '/like',
        REQUEST_TYPE.POST,
        formData
      );

      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            setLikeCount(data.like_count);
            setLiked(data.liked);
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(
        err,
        formData,
        COMMENT_BY_ID + article.article_id + '/like',
        NAVIGATION_ROUTES.MI_HR_DETAILS
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, article]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const handleHtmlClicks = (e) => {
    const target = e.target.getAttribute('href');
    if (target) {
      if (target.startsWith('/') || target.startsWith('?')) {
        if (target.includes(NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL)) {
          logEvent(
            ANALYTICS_EVENT_TYPES.USER_MENTION_CLICK,
            e?.target?.innerText,
            ANALYTICS_ITEM_NAMES.USER_MENTION
          );
          e.stopPropagation();
          e.preventDefault();
          history.push(target);
        } else {
          logEvent(
            ANALYTICS_EVENT_TYPES.HASHTAG_CLICK,
            e?.target?.innerText,
            ANALYTICS_ITEM_NAMES.HASHTAG
          );
          dispatch(saveSearchText(''));
          e.preventDefault();
          history.push(`${NAVIGATION_ROUTES.MI_HR_SEARCH}${target}`);
        }
      } else {
        e.stopPropagation();
        e.preventDefault();
        window.open(target, '_blank');
      }
    }
  };

  return (
    <div className="container">
      <SectionHeader
        identifier={
          params?.headerTitle ? params?.headerTitle : ACCESSIBILITY_IDENTIFIERS.MI_HR_SUPPORT
        }
      />
      <div className="header-view justify-content-between">
        <BackButton isFullRow={false} />
        <Modal
          show={showVideoItem}
          onHide={() => setShowVideoItem(false)}
          className="home-fullscreen-modal-dialog fade-scale"
          aria-labelledby="example-custom-modal-styling-title">
          <Modal.Body className="animate-bottom">
            <div
              className="home-fullscreen-modal-dialog-close-button-container"
              onClick={handleVideoItemClose}>
              <VscChromeClose size="4em" color="#fff" />
            </div>

            <ReactPlayer
              url={article?.url}
              width="100%"
              height="100%"
              playing={true}
              controls={true}
              className="home-video-container"
            />
          </Modal.Body>
        </Modal>
        {article && (
          <div className="broadcast-header-row justify-content-end">
            {article.enable_like && showLikeButton && (
              <div className="broadcast-icon-row mr-3">
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    liked
                      ? ACCESSIBILITY_HEADER_COMPONENTS.ACTIVE_LIKE
                      : ACCESSIBILITY_HEADER_COMPONENTS.INACTIVE_LIKE
                  )}
                  className="header-icon"
                  onClick={postLikeFunction}
                />
                <div
                  className="broadcast-icon-text"
                  onClick={() => {
                    if (likeCount > 0) {
                      history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
                        referId: articleId,
                        parentType: FEATURE_TYPE.ARTICLES,
                      });
                    }
                  }}>
                  {likeCount}
                </div>
              </div>
            )}
            {article.enable_comment && showCommentButton && (
              <div className="broadcast-icon-row mr-3">
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_HEADER_COMPONENTS.COMMENT
                  )}
                  className="header-icon"
                  onClick={() => {
                    history.push(NAVIGATION_ROUTES.COMMENTS, {
                      referId: article.article_id,
                      likeType: FEATURE_TYPE.ARTICLES,
                    });
                  }}
                />
                <div
                  className="broadcast-icon-text"
                  onClick={() => {
                    history.push(NAVIGATION_ROUTES.COMMENTS, {
                      referId: article.article_id,
                      likeType: FEATURE_TYPE.ARTICLES,
                    });
                  }}>
                  {commentCount}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {article &&
        showPdf &&
        article &&
        article.pdf &&
        article.pdf.pdf_path !== '' &&
        article?.encoded_url && (
          <div className="iframe-container">
            <iframe src={getSecuredPdfUrl(webUrl, article?.encoded_url)} allowFullScreen />
          </div>
        )}
      {article && (
        <div className="main-content-container">
          {!showPdf && (
            <div>
              <div>
                {article?.content_load_type === '3' ? (
                  <div className="thumbnail-container-content">
                    <img
                      src={
                        article.video_type === 'YT'
                          ? getYoutubeVideoImageFromUrl(article.url)
                          : `${article?.image.base_url}xxhdpi/${article?.image.image_path}`
                      }
                      alt={article.title}
                      className="embed-responsive embed-responsive-16by9"
                      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                    />

                    <img
                      src={getFeatureComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_PLAY_VIDEO_WHITE,
                        ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
                      )}
                      onClick={handleVideoItemShow}
                      alt="Play Icon"
                      className="mltv-play-button-img-content common-cursor-pointer"
                    />
                  </div>
                ) : (
                  <img
                    src={getUrlWithSpecificRendition(
                      article.image.base_url,
                      SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
                      article.image.image_path
                    )}
                    className="embed-responsive embed-responsive-16by9"
                  />
                )}
                <LoadDateText pubDatetime={article.pub_datetime} pageViews={article.page_views} />
              </div>
              <div className="broadcast-title">{article.title}</div>
            </div>
          )}
          {!article.body !== '' && !showPdf && (
            <HtmlView
              html={article.body}
              htmlStyle="mt-3 broadcast-content-html"
              onClick={handleHtmlClicks}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MiHRContentScreen;
