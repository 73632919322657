import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Picker from 'emoji-picker-react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { EditorState } from 'draft-js';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  CHAT_LEFT_PANEL_IDENTIFIERS,
  DIALOG_TYPE,
  ERROR_MESSAGES,
  MESSAGES,
  NAVIGATION_ROUTES,
  NOTIFICATION_MESSAGE_TYPES,
  SUCCESS_MESSAGES,
  SUPPORTED_DOCUMENT_FORMAT_PDF,
  SUPPORTED_IMAGE_FORMATS,
  WARNING_MESSAGES,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import {
  saveCurrentGroupId,
  saveForwardedMessage,
  selectUserChatGroups,
} from '../../../state/ChatData';
import { selectEmployeeData } from '../../../state/EmployeeData';
import {
  chatFormatDateDDMonthYYYY,
  chatFormatTimeAMPM,
  chatIsToday,
  chatIsYesterday,
  clearChatHistory,
  createOneOnOneChatGroup,
  createOrUpdateUser,
  deleteGroup,
  deleteMessage,
  editMessage,
  exitMemberFromGroup,
  fetchMessagesByGroupPagination,
  fetchUserByIdOnce,
  sendMessageToGroup,
  sendNotificationMessageToGroup,
  uId,
  updateUnReadCountLastSeenMessageTimestamp,
  uploadDocument,
  uploadImage,
} from '../../../utils/ChatHandler';
import { convertBase64, isEmptyText, showToast } from '../../../utils/common';
import ChatMessageListItem from './ChatMessageListItem';
import { getFeatureComponentUrl, getFeatureComponentUrlLdpi } from '../../../utils/accessibility';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Divider from '../../../common/ui/divider/Divider';
import ChatConfirmModal from './ChatConfirmModal';
import horizontalDotsLoaderAnimation from '../../../assets/files/horizontalDotsLoader.json';
import { saveProgressLoadingState, selectOrgDomain } from '../../../state/UIState';
import Lottie from 'react-lottie';
import ECardModal from './ECardModal';
import {
  selectChatCharacterLimit,
  selectConfigurations,
  selectMaxFileUploadSizeConfig,
} from '../../../state/MasterData';
import ChatEditMessageModal from './ChatEditMessageModal';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import UserProfilePhotoForChats from '../../../common/ui/user_profile_photo/UserProfilePhotoForChats';
import CustomMentionEditor from './UserMention';
import {
  extractDataFromEditorState,
  getMentionsState,
  getMentionsStateForChat,
  getMentionsStateForChat1,
  getMentionsStateForChatEdit,
} from '../../../common/components/hashtagsAndMentionsInput/hashtagUtils';

const ConversationRoom = (props) => {
  const {
    selectedChat,
    setSelectedChat,
    setLeftPanelType,
    mentionList,
    onUserChange,
    onSetUserChange,
    onGroupUserChange,
  } = props;
  const { chatType, groupId, userId = '', otherUser = {} } = selectedChat;
  const dispatch = useDispatch();
  const history = useHistory();
  const [isECardModalVisible, setIsECardModalVisible] = useState(false);
  const [discussions, setDiscussions] = useState([]);
  const [showClearChatDialog, setShowClearChatDialog] = useState(false);
  const [showExitGroupDialog, setShowExitGroupDialog] = useState(false);
  const [showDeleteGroupDialog, setShowDeleteGroupDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSelfUserAdmin, setIsSelfUserAdmin] = useState(false);
  const [chatDetails, setChatDetails] = useState({});
  const [isEmojiDialogVisible, setIsEmojiDialogVisible] = useState(false);
  const [chatText, setChatText] = useState('');
  const [isReplyModeActive, setIsReplyModeActive] = useState(false);
  const [replyModeCommentData, setReplyModeCommentData] = useState({});
  const [replyModeEmployeeData, setReplyModeEmployeeData] = useState({});
  const [selectedMessageData, setSelectedMessageData] = useState({});
  const [showEditMessageDialog, setShowEditMessageDialog] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState('');
  const [showDeleteMessageDialog, setShowDeleteMessageDialog] = useState(false);
  const [exitedOtherUserDetail, setExitedOtherUserDetail] = useState({});
  const [isGroupExist, setIsGroupExist] = useState(false);
  const userChatGroups = useSelector(selectUserChatGroups);
  const exitStatus = useRef(1);
  const emojiRef = useRef(null);
  const elementRef = useRef();
  const addImageFileRef = useRef();
  const addDocumentRef = useRef();
  const employeeData = useSelector(selectEmployeeData);
  const accessibilityData = useSelector(selectAccessibilityData);
  const orgDomain = useSelector(selectOrgDomain);
  const maxFileUploadSizeInMB = useSelector(selectMaxFileUploadSizeConfig);
  const verticalLoaderStyleConfig = { cursor: 'default' };
  const configurations = useSelector(selectConfigurations);
  const newChatEmptyContent = configurations?._chat_config?.new_chat_empty_content ?? '';
  const miVoiceChatEmptyContent = configurations?._chat_config?.mivoice_chat_empty_content ?? '';
  const [userMentionList, setUserMentionList] = useState([]);
  const MAX_CHAR_LIMIT = useSelector(selectChatCharacterLimit);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [editorStateEdit, setEditorStateEdit] = useState(() => EditorState.createEmpty());
  useEffect(() => {
    const timer = () => setTimeout(() => elementRef.current.scrollIntoView({ block: 'end' }), 1500);
    const timerId = timer();
    return () => {
      clearTimeout(timerId);
    };
  }, [selectedChat]);

  useEffect(() => {
    elementRef.current.scrollIntoView({ block: 'end' });
  }, [discussions]);

  useEffect(() => {
    if (onUserChange) {
      setEditorState(EditorState.createEmpty());
      onSetUserChange(false);
    }
  }, [onUserChange]);

  useEffect(() => {
    dispatch(saveCurrentGroupId(groupId));
    return () => {
      dispatch(saveCurrentGroupId(''));
    };
  }, [dispatch, groupId]);

  const getChatMessages = useCallback(
    (deleteTill) => {
      if (deleteTill) {
        fetchMessagesByGroupPagination(groupId, deleteTill, (arr) => {
          arr.sort((a, b) => {
            return b.timestamp - a.timestamp;
          });
          const groups = arr.reduce((previousValue, currentValue) => {
            const date = chatIsToday(currentValue.timestamp)
              ? 'Today'
              : chatIsYesterday(currentValue.timestamp)
              ? 'Yesterday'
              : chatFormatDateDDMonthYYYY(currentValue.timestamp);
            if (!previousValue[date]) {
              previousValue[date] = [];
            }
            previousValue[date].push(currentValue);
            return previousValue;
          }, {});
          const groupArrays = Object.keys(groups).map((date) => {
            return {
              title: date,
              data: groups[date].reverse(),
            };
          });
          setDiscussions(groupArrays.reverse());
        });
      }
    },
    [groupId]
  );

  useEffect(() => {
    const uid = uId(employeeData);
    if (Object.keys(userChatGroups).length > 0) {
      let groupDetail = userChatGroups[groupId];
      if (groupDetail && Object.keys(groupDetail).length > 0) {
        setIsGroupExist(true);
        if (groupDetail.members[uid]) {
          let isAdmin = groupDetail.members[uid].admin;
          setIsSelfUserAdmin(isAdmin);
          getChatMessages(groupDetail.members[uid].delete_till);
        }
      } else {
        setDiscussions([]);
      }
    }
  }, [employeeData, getChatMessages, groupId, userChatGroups, onGroupUserChange]);

  useEffect(() => {
    const uid = uId(employeeData);
    if (Object.keys(userChatGroups).length > 0) {
      let groupDetail = userChatGroups[groupId];
      if (groupDetail && Object.keys(groupDetail).length > 0) {
        let memberDetail = groupDetail.members[uid];
        if (!memberDetail.active || memberDetail.active === false) {
          setIsGroupExist(false);
          if (chatType === DIALOG_TYPE.GROUP_CHAT) {
            if (exitStatus.current === 1) {
              showToast(MESSAGES.YOU_REMOVED_FROM_GROUP);
              setSelectedChat({});
            }
          }
        }
      } else {
        setIsGroupExist(false);
        if (chatType === DIALOG_TYPE.GROUP_CHAT) {
          if (exitStatus.current === 1) {
            showToast(MESSAGES.GROUP_DELETED_BY_ADMIN);
            setSelectedChat({});
          }
        }
      }
    }
  }, [chatType, employeeData, groupId, setSelectedChat, userChatGroups]);

  useEffect(() => {
    if (chatType === DIALOG_TYPE.ONE_ON_ONE_CHAT) {
      if (userId !== '') {
        fetchUserByIdOnce(userId, (isUserExist, otherUserDetail) => {
          if (isUserExist) {
            const tempDetails = otherUserDetail;
            tempDetails.uid = `${tempDetails?.uid}`.includes('uid-')
              ? tempDetails?.uid
              : `uid-${tempDetails?.uid}`;
            setExitedOtherUserDetail(otherUserDetail);
          } else {
            if (Object.keys(otherUser).length > 0) {
              let userObj = {
                image_url:
                  otherUser.profile_picture.image_path !== ''
                    ? otherUser.profile_picture.base_url + otherUser.profile_picture.image_path
                    : '',
                name: otherUser.first_name + ' ' + otherUser.last_name,
                uid: `${userId}`.includes('uid-') ? userId : `uid-${userId}`,
                user_location: otherUser.location.title,
              };
              createOrUpdateUser(userObj, () => {
                setExitedOtherUserDetail(userObj);
              });
            }
          }
        });
      }
    }
  }, [chatType, otherUser, userId]);

  useEffect(() => {
    const onChildChanged = firebase
      .database()
      .ref(`${orgDomain}/chat/messages/${groupId}`)
      .on('child_changed', (snapshot) => {
        const childChangedValue = snapshot.val();
        const date = chatIsToday(childChangedValue.timestamp)
          ? 'Today'
          : chatIsYesterday(childChangedValue.timestamp)
          ? 'Yesterday'
          : chatFormatDateDDMonthYYYY(childChangedValue.timestamp);
        let outerArr = [...discussions];
        const outerIndex = outerArr.findIndex((element) => element.title === date);
        let innerArr = outerArr.find((element) => element.title === date).data;
        const innerIndex = innerArr.findIndex(
          (element) => element.message_id === childChangedValue.message_id
        );
        if (innerIndex > -1) {
          if (childChangedValue.is_deleted && childChangedValue.is_deleted === true) {
            innerArr[innerIndex].is_deleted = true;
            innerArr[innerIndex].is_edited = false;
          } else if (childChangedValue.is_edited && childChangedValue.is_edited === true) {
            innerArr[innerIndex].message = childChangedValue.message;
            innerArr[innerIndex].is_edited = true;
          }
          outerArr[outerIndex].data = innerArr;
          setDiscussions([...outerArr]);
        }
      });
    return () => {
      firebase
        .database()
        .ref(`${orgDomain}/chat/messages/${groupId}`)
        .off('child_changed', onChildChanged);
    };
  }, [discussions, groupId, orgDomain]);

  const createPrivateChat = useCallback(
    (
      otherUserObj,
      message,
      messageType,
      fileBlob,
      isEcard,
      isForwarded,
      docPath,
      docType,
      docName
    ) => {
      setChatText('');
      createOneOnOneChatGroup(employeeData, otherUserObj, () => {
        setChatText('');
        if (isReplyModeActive) {
          setIsReplyModeActive(false);
          const replyForwardParams = {};
          const replyForwardObj = { ...replyForwardParams, ...replyModeCommentData };
          if (messageType === '2' && !isEcard) {
            uploadImage(fileBlob, (downloadUrl, error, totalBytes, bytesTransferred) => {
              if (downloadUrl) {
                sendMessageToGroup(
                  groupId,
                  employeeData,
                  message,
                  messageType,
                  downloadUrl,
                  replyForwardObj,
                  isForwarded,
                  docType,
                  docName,
                  () => {
                    setLoading(false);
                  }
                );
              }
            });
          } else if (messageType === '3') {
            uploadDocument(
              docPath,
              docType,
              docName,
              (downloadUrl, error, totalBytes, bytesTransferred) => {
                if (downloadUrl) {
                  sendMessageToGroup(
                    groupId,
                    employeeData,
                    message,
                    messageType,
                    downloadUrl,
                    replyForwardObj,
                    isForwarded,
                    docType,
                    docName,
                    () => {
                      setLoading(false);
                    }
                  );
                }
              }
            );
          } else {
            sendMessageToGroup(
              groupId,
              employeeData,
              message,
              messageType,
              fileBlob,
              replyForwardObj,
              isForwarded,
              docType,
              docName,
              () => {
                setLoading(false);
              }
            );
          }
        } else {
          if (messageType === '2' && !isEcard) {
            uploadImage(fileBlob, (downloadUrl, error, totalBytes, bytesTransferred) => {
              if (downloadUrl) {
                sendMessageToGroup(
                  groupId,
                  employeeData,
                  message,
                  messageType,
                  downloadUrl,
                  {},
                  isForwarded,
                  docType,
                  docName,
                  () => {
                    setLoading(false);
                  }
                );
              }
            });
          } else if (messageType === '3') {
            uploadDocument(
              docPath,
              docType,
              docName,
              (downloadUrl, error, totalBytes, bytesTransferred) => {
                if (downloadUrl) {
                  sendMessageToGroup(
                    groupId,
                    employeeData,
                    message,
                    messageType,
                    downloadUrl,
                    {},
                    isForwarded,
                    docType,
                    docName,
                    () => {
                      setLoading(false);
                    }
                  );
                }
              }
            );
          } else {
            sendMessageToGroup(
              groupId,
              employeeData,
              message,
              messageType,
              fileBlob,
              {},
              isForwarded,
              docType,
              docName,
              () => {
                setLoading(false);
              }
            );
          }
        }
      });
    },
    [employeeData, groupId, isReplyModeActive, replyModeCommentData]
  );

  const sendMessageGroup = useCallback(
    (message, messageType, fileBlob, isEcard, isForwarded, docPath, docType, docName) => {
      setChatText('');
      if (isReplyModeActive) {
        setIsReplyModeActive(false);
        const replyForwardParams = {};
        const replyForwardObj = { ...replyForwardParams, ...replyModeCommentData };
        if (messageType === '2' && !isEcard) {
          uploadImage(fileBlob, (downloadUrl, error, totalBytes, bytesTransferred) => {
            if (downloadUrl) {
              sendMessageToGroup(
                groupId,
                employeeData,
                message,
                messageType,
                downloadUrl,
                replyForwardObj,
                isForwarded,
                docType,
                docName,
                () => {
                  setLoading(false);
                }
              );
            }
          });
        } else if (messageType === '3') {
          uploadDocument(
            docPath,
            docType,
            docName,
            (downloadUrl, error, totalBytes, bytesTransferred) => {
              if (downloadUrl) {
                sendMessageToGroup(
                  groupId,
                  employeeData,
                  message,
                  messageType,
                  downloadUrl,
                  replyForwardObj,
                  isForwarded,
                  docType,
                  docName,
                  () => {
                    setLoading(false);
                  }
                );
              }
            }
          );
        } else {
          sendMessageToGroup(
            groupId,
            employeeData,
            message,
            messageType,
            fileBlob,
            replyForwardObj,
            isForwarded,
            docType,
            docName,
            () => {
              setLoading(false);
            }
          );
        }
      } else {
        if (messageType === '2' && !isEcard) {
          uploadImage(fileBlob, (downloadUrl, error, totalBytes, bytesTransferred) => {
            if (downloadUrl) {
              sendMessageToGroup(
                groupId,
                employeeData,
                message,
                messageType,
                downloadUrl,
                {},
                isForwarded,
                docType,
                docName,
                () => {
                  setLoading(false);
                }
              );
            }
          });
        } else if (messageType === '3') {
          uploadDocument(
            docPath,
            docType,
            docName,
            (downloadUrl, error, totalBytes, bytesTransferred) => {
              if (downloadUrl) {
                sendMessageToGroup(
                  groupId,
                  employeeData,
                  message,
                  messageType,
                  downloadUrl,
                  {},
                  isForwarded,
                  docType,
                  docName,
                  () => {
                    setLoading(false);
                  }
                );
              }
            }
          );
        } else {
          sendMessageToGroup(
            groupId,
            employeeData,
            message,
            messageType,
            fileBlob,
            {},
            isForwarded,
            docType,
            docName,
            () => {
              setLoading(false);
            }
          );
        }
      }
    },
    [employeeData, groupId, isReplyModeActive, replyModeCommentData]
  );

  useEffect(() => {
    let onValueChange, otherUserId;
    if (chatType === DIALOG_TYPE.ONE_ON_ONE_CHAT) {
      const uid = uId(employeeData);
      const arr = groupId.split('_');
      otherUserId = arr.find((id) => id !== uid);
      onValueChange = firebase
        .database()
        .ref(`${orgDomain}/chat/users/${otherUserId}`)
        .on('value', (snapshot) => {
          if (snapshot.exists()) {
            try {
              const user = snapshot.val();
              let id = user.uid.split('-')[1];
              setChatDetails({
                id: id,
                title: user.name,
                groupImage: user.image_url,
                status: user.online
                  ? 'Online'
                  : user.last_seen_online
                  ? `Last seen ${
                      chatIsToday(user.last_seen_online)
                        ? chatFormatTimeAMPM(user.last_seen_online)
                        : chatFormatDateDDMonthYYYY(user.last_seen_online)
                    }`
                  : 'Offline',
              });
            } catch (err) {
              showToast(ERROR_MESSAGES.DEFAULT);
            }
          } else {
            if (Object.keys(otherUser).length > 0) {
              setChatDetails({
                id: otherUser.employee_id,
                title: otherUser.first_name + '' + otherUser.last_name,
                groupImage:
                  otherUser.profile_picture.base_url + otherUser.profile_picture.image_path,
                status: 'Offline',
              });
            }
          }
        });
    } else if (chatType === DIALOG_TYPE.GROUP_CHAT) {
      if (Object.keys(userChatGroups).length > 0) {
        let groupDetail = userChatGroups[groupId];
        if (groupDetail && Object.keys(groupDetail).length > 0) {
          let totalMembers = 0;
          Object.values(groupDetail.members).forEach((element) => {
            if (element.active && element.active === true) {
              totalMembers = totalMembers + 1;
            }
          });
          setChatDetails({
            title: groupDetail.name,
            groupImage: groupDetail.image_url,
            status: `${totalMembers} Participants`,
          });
        }
      }
    }
    return () => {
      if (otherUserId && otherUserId !== '') {
        firebase
          .database()
          .ref(`${orgDomain}/chat/users/${otherUserId}`)
          .off('value', onValueChange);
      }
    };
  }, [chatType, employeeData, groupId, orgDomain, otherUser, userChatGroups]);

  useEffect(() => {
    let uid = uId(employeeData);
    const onValueChange = firebase
      .database()
      .ref(`${orgDomain}/chat/group/${groupId}/members/${uid}/unread_group_count`)
      .on('value', (snapshot) => {
        if (snapshot.exists()) {
          const unReadCount = snapshot.val();
          if (unReadCount > 0) {
            updateUnReadCountLastSeenMessageTimestamp(groupId, uid, () => {});
          }
        }
      });
    return () => {
      firebase
        .database()
        .ref(`${orgDomain}/chat/group/${groupId}/members/${uid}/unread_group_count`)
        .off('value', onValueChange);
    };
  }, [employeeData, groupId, orgDomain]);

  const handleClickOutside = (event) => {
    if (emojiRef.current && !emojiRef.current.contains(event.target)) {
      setIsEmojiDialogVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const messageMoreOptionsPopover = (item, empData) => {
    return (
      <Popover>
        {/* {(!item.message_type || item.message_type === '1') && (
          <>
            <Popover.Content>
              <div
                className="common-cursor-pointer pr-3"
                onClick={() => {
                  document.body.click();
                  onPressMessageOption(MESSAGE_OPTIONS.REPLY, item, {
                    name: 'You',
                  });
                }}>
                Copy
              </div>
            </Popover.Content>
            <Divider />
          </>
        )} */}
        {(!item.message_type ||
          item.message_type === '1' ||
          item.message_type === '2' ||
          item.message_type === '3') && (
          <>
            <Popover.Content>
              <div
                className="common-cursor-pointer pr-3"
                onClick={() => {
                  document.body.click();
                  setIsReplyModeActive(true);
                  setReplyModeCommentData(item);
                  setReplyModeEmployeeData(empData);
                }}>
                Reply
              </div>
            </Popover.Content>
            <Divider />
          </>
        )}
        {(!item.message_type ||
          item.message_type === '1' ||
          item.message_type === '2' ||
          item.message_type === '3') && (
          <>
            <Popover.Content>
              <div
                className="common-cursor-pointer pr-3"
                onClick={() => {
                  document.body.click();
                  dispatch(saveForwardedMessage(item));
                  setLeftPanelType(CHAT_LEFT_PANEL_IDENTIFIERS.FORWARD_VIEW);
                }}>
                Forward
              </div>
            </Popover.Content>
          </>
        )}
        {item.sender_id === uId(employeeData) &&
          (!item.message_type || item.message_type === '1') &&
          employeeData?.special_account === 0 &&
          (Object.keys(otherUser).length > 0 ? otherUser?.special_account === 0 : true) &&
          !item.is_forwarded && (
            <>
              <Divider />
              <Popover.Content>
                <div
                  className="common-cursor-pointer pr-3"
                  onClick={() => {
                    document.body.click();
                    setSelectedMessage(item.message);
                    setSelectedMessageData(item);
                    setEditorStateEdit(getMentionsStateForChatEdit(item.message, mentionList));
                    setShowEditMessageDialog(true);
                  }}>
                  Edit
                </div>
              </Popover.Content>
            </>
          )}
        {item.sender_id === uId(employeeData) &&
          employeeData?.special_account === 0 &&
          (Object.keys(otherUser).length > 0 ? otherUser?.special_account === 0 : true) &&
          (!item.message_type ||
            item.message_type === '1' ||
            item.message_type === '2' ||
            item.message_type === '3') && (
            <>
              <Divider />
              <Popover.Content>
                <div
                  className="common-cursor-pointer chat-delete-text pr-3"
                  onClick={() => {
                    document.body.click();
                    setShowDeleteMessageDialog(true);
                    setSelectedMessageData(item);
                  }}>
                  Delete
                </div>
              </Popover.Content>
            </>
          )}
        {(!item.message_type ||
          item.message_type === '1' ||
          item.message_type === '2' ||
          item.message_type === '3') && (
          <>
            <Divider />
            <Popover.Content>
              <div
                className="common-cursor-pointer pr-3"
                onClick={() => {
                  window.navigator.clipboard.writeText(item.message);
                  document.body.click();
                }}>
                Copy
              </div>
            </Popover.Content>
          </>
        )}
      </Popover>
    );
  };

  const renderChatMesssageItem = (chatDataNode) => {
    return (
      <ChatMessageListItem
        key={`${chatDataNode.message_id}`}
        item={chatDataNode}
        chatType={chatType}
        messageMoreOptionsPopover={messageMoreOptionsPopover}
      />
    );
  };

  const renderDiscussionItem = (item) => {
    return (
      <div key={item.title.toString()}>
        <div className="d-flex align-items-center mb-2">
          <Divider style="chat-divider-thin flex-grow-1" />
          <div className="px-3">{item.title}</div>
          <Divider style="chat-divider-thin flex-grow-1" />
        </div>
        <div>{item.data.map((chatDataNode) => renderChatMesssageItem(chatDataNode))}</div>
      </div>
    );
  };

  const onChangeChatTextHandler = (value) => {
    setChatText(value);
  };
  const createEmptyValue = () => {
    setEditorState(() => EditorState.createEmpty());
  };
  const onPressEmojiHandler = (event, emojiObject) => {
    setChatText(chatText + emojiObject.emoji);
    const data = extractDataFromEditorState(editorState);
    const editorValue1 = getMentionsStateForChat(
      data.description + emojiObject.emoji,
      [...data.addedHashtags, ...userMentionList],
      editorState
    );
    const contentState = editorValue1.getCurrentContent();
    setEditorState(editorValue1);
  };
  const onSetEditorState = (editorValue) => {
    setEditorState(editorValue);
  };
  const submitDeleteMessage = () => {
    setShowDeleteMessageDialog(false);
    deleteMessage(groupId, selectedMessageData, () => {});
  };
  function replaceNames(paragraph, objects) {
    objects.forEach((object) => {
      const regex = new RegExp(`@\\b${object.name}\\b`, 'gi');
      paragraph = paragraph.replace(regex, `@[${object.name}](${object.uid})`);
    });
    return paragraph;
  }
  const submitEditMessage = (newMessage) => {
    if (!isEmptyText(newMessage)) {
      showToast(MESSAGES.ENTER_MESSAGE);
    } else {
      setShowEditMessageDialog(false);
      editMessage(groupId, selectedMessageData, newMessage, () => {});
    }
  };

  const onSubmitChatMessageHandler = (event) => {
    event.preventDefault();
    if (!isEmptyText(chatText)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else if (chatText?.length > MAX_CHAR_LIMIT) {
      showToast(`Max characters to be entered is ${MAX_CHAR_LIMIT}`);
    } else {
      switch (chatType) {
        case DIALOG_TYPE.ONE_ON_ONE_CHAT:
          setLoading(true);
          if (isGroupExist) {
            sendMessageGroup(chatText.trim(), '1', '', false, false, '', '', '');
            createEmptyValue();
          } else {
            createPrivateChat(
              exitedOtherUserDetail,
              chatText.trim(),
              '1',
              '',
              false,
              false,
              '',
              '',
              ''
            );
            createEmptyValue();
          }
          break;
        case DIALOG_TYPE.GROUP_CHAT:
          setLoading(true);
          sendMessageGroup(chatText.trim(), '1', '', false, false, '', '', '');
          createEmptyValue();
          break;
        default:
          break;
      }
    }
  };

  const newChatEmptyListView = () => {
    return (
      <>
        <div className="align-item-center h-100">
          <HtmlView html={newChatEmptyContent} htmlStyle="h-100" />
        </div>
      </>
    );
  };

  const onPressMoreOptionsHandler = () => (
    <Popover>
      {(chatType === DIALOG_TYPE.ONE_ON_ONE_CHAT || chatType === DIALOG_TYPE.GROUP_CHAT) && (
        <>
          <Popover.Content>
            <div
              className="common-cursor-pointer"
              onClick={() => {
                document.body.click();
                setShowClearChatDialog(true);
              }}>
              Clear Chat
            </div>
          </Popover.Content>
          {chatType === DIALOG_TYPE.GROUP_CHAT && <Divider />}
        </>
      )}
      {chatType === DIALOG_TYPE.GROUP_CHAT && (
        <>
          <Popover.Content>
            <div
              className="common-cursor-pointer"
              onClick={() => {
                document.body.click();
                exitStatus.current = 2;
                setShowExitGroupDialog(true);
              }}>
              Exit Group
            </div>
          </Popover.Content>
          <Divider />
        </>
      )}
      {chatType === DIALOG_TYPE.GROUP_CHAT && isSelfUserAdmin && (
        <>
          <Popover.Content>
            <div
              className="common-cursor-pointer"
              onClick={() => {
                document.body.click();
                exitStatus.current = 3;
                setShowDeleteGroupDialog(true);
              }}>
              Delete Group
            </div>
          </Popover.Content>
        </>
      )}
    </Popover>
  );

  const onFileChange = (event) => {
    [...event.target.files].forEach((element) => {
      if (!SUPPORTED_IMAGE_FORMATS.includes(element.type)) {
        showToast(WARNING_MESSAGES.FILE_NOT_SUPPORTED);
      } else if (Number(element.size) / (1024 * 1024) > maxFileUploadSizeInMB) {
        showToast(WARNING_MESSAGES.FILE_SIZE_EXCEEDED(maxFileUploadSizeInMB));
      } else {
        switch (chatType) {
          case DIALOG_TYPE.ONE_ON_ONE_CHAT:
            setLoading(true);
            if (isGroupExist) {
              sendMessageGroup('', '2', element, false, false, '', '', '');
            } else {
              createPrivateChat(exitedOtherUserDetail, '', '2', element, false, false, '', '', '');
            }
            break;
          case DIALOG_TYPE.GROUP_CHAT:
            setLoading(true);
            sendMessageGroup('', '2', element, false, false, '', '', '');
            break;
          default:
            break;
        }
      }
    });
    document.body.click();
  };

  const onDocumentChange = (event) => {
    [...event.target.files].forEach((element) => {
      convertBase64(element).then((base64Data) => {
        if (!SUPPORTED_DOCUMENT_FORMAT_PDF.includes(element.type)) {
          showToast(WARNING_MESSAGES.FILE_NOT_SUPPORTED);
        } else if (Number(element.size) / (1024 * 1024) > maxFileUploadSizeInMB) {
          showToast(WARNING_MESSAGES.FILE_SIZE_EXCEEDED(maxFileUploadSizeInMB));
        } else {
          switch (chatType) {
            case DIALOG_TYPE.ONE_ON_ONE_CHAT:
              setLoading(true);
              if (isGroupExist) {
                sendMessageGroup(
                  '',
                  '3',
                  '',
                  false,
                  false,
                  base64Data.split(',')[1],
                  element.type,
                  element.name
                );
              } else {
                createPrivateChat(
                  exitedOtherUserDetail,
                  '',
                  '3',
                  '',
                  false,
                  false,
                  base64Data.split(',')[1],
                  element.type,
                  element.name
                );
              }
              break;
            case DIALOG_TYPE.GROUP_CHAT:
              setLoading(true);
              sendMessageGroup(
                '',
                '3',
                '',
                false,
                false,
                base64Data.split(',')[1],
                element.type,
                element.name
              );
              break;
            default:
              break;
          }
        }
      });
    });
    document.body.click();
  };

  const onPressPlusButtonHandler = () => (
    <Popover>
      <>
        <Popover.Content>
          <div
            className="common-cursor-pointer px-2"
            onClick={(e) => {
              e.stopPropagation();
              addImageFileRef.current.click();
            }}>
            Add Image
          </div>
          <input
            type="file"
            ref={addImageFileRef}
            onChange={onFileChange}
            accept={SUPPORTED_IMAGE_FORMATS}
            id="input"
            className="d-none"
            onClick={(event) => {
              event.target.value = null;
            }}
          />
        </Popover.Content>
        <Divider />
      </>
      <>
        <Popover.Content>
          <div
            className="common-cursor-pointer px-2"
            onClick={(e) => {
              e.stopPropagation();
              addDocumentRef.current.click();
            }}>
            Add Document
          </div>
          <input
            type="file"
            ref={addDocumentRef}
            onChange={onDocumentChange}
            accept={SUPPORTED_DOCUMENT_FORMAT_PDF}
            id="input"
            className="d-none"
            onClick={(event) => {
              event.target.value = null;
            }}
          />
        </Popover.Content>
        <Divider />
      </>
      <>
        <Popover.Content>
          <div
            className="common-cursor-pointer px-2"
            onClick={() => {
              document.body.click();
              setIsECardModalVisible(true);
            }}>
            Add E-card
          </div>
        </Popover.Content>
      </>
    </Popover>
  );

  const onPressRoomHeaderHandler = () => {
    // current user should not be able to access special/mivoice users' account detail but the vice-versa is true
    if (otherUser?.special_account === 1 && chatType === DIALOG_TYPE.ONE_ON_ONE_CHAT) {
      return;
    }
    if (chatType === DIALOG_TYPE.ONE_ON_ONE_CHAT) {
      history.push(`${NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL}/${chatDetails.id}`);
    } else {
      setLeftPanelType(CHAT_LEFT_PANEL_IDENTIFIERS.GROUP_DETAIL_VIEW);
    }
  };

  const renderRoomHeader = () => {
    return (
      <div className="px-3 py-2 chat-header d-flex justify-content-between align-items-center">
        {selectedChat && Object.keys(selectedChat).length > 0 && (
          <div
            onClick={onPressRoomHeaderHandler}
            className="d-flex align-items-center common-cursor-pointer">
            <div className="mr-3">
              <UserProfilePhotoForChats
                imagePath={
                  chatDetails.groupImage
                    ? chatDetails.groupImage
                    : chatType === DIALOG_TYPE.GROUP_CHAT
                    ? getFeatureComponentUrlLdpi(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_CIRCULAR_GROUP_GREY,
                        ACCESSIBILITY_IDENTIFIERS.COMMON
                      )
                    : ''
                }
                imageClass="common-user-profile-photo-xs"
              />
            </div>
            <div>
              <div className="chat-bold-text chat-base-text-md">{chatDetails.title}</div>
              <div className="chat-grey-text chat-base-text-sm">{chatDetails.status}</div>
            </div>
          </div>
        )}
        {selectedChat &&
          Object.keys(selectedChat).length > 0 &&
          discussions?.length > 0 &&
          employeeData?.special_account === 0 &&
          otherUser?.special_account === 0 && (
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="bottom"
              overlay={onPressMoreOptionsHandler()}>
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.COMMENTS_MORE_VERTICAL,
                  ACCESSIBILITY_IDENTIFIERS.COMMON
                )}
                className="common-comment-popup-more-vertical-icon-size common-cursor-pointer"
              />
            </OverlayTrigger>
          )}
      </div>
    );
  };

  const _renderMiVoiceInstructions = () => {
    return (
      <div className="align-item-center h-100">
        <HtmlView html={miVoiceChatEmptyContent} htmlStyle="h-100" />
      </div>
    );
  };

  const renderEmptyComp = () => {
    if (
      discussions?.length === 0 &&
      chatDetails &&
      selectedChat?.chatType === DIALOG_TYPE.ONE_ON_ONE_CHAT &&
      employeeData?.special_account === 0 &&
      selectedChat?.otherUser?.special_account === 0 &&
      Object.keys(chatDetails).length > 0
    ) {
      return newChatEmptyListView();
    }

    if (
      discussions?.length === 0 &&
      chatDetails &&
      selectedChat?.chatType === DIALOG_TYPE.ONE_ON_ONE_CHAT &&
      (employeeData?.special_account === 1 || selectedChat?.otherUser?.special_account === 1) &&
      Object.keys(chatDetails).length > 0
    ) {
      return _renderMiVoiceInstructions();
    }
  };
  const renderRoomBody = () => {
    return (
      <div className="px-2 pt-2 chat-input-main-body-container">
        {discussions &&
          discussions.length > 0 &&
          discussions.map((item) => renderDiscussionItem(item))}
        {renderEmptyComp()}
        <div ref={elementRef} />
      </div>
    );
  };
  const extractUsername = (text) => {
    const regex = /@\[([^[\]]+)\]\([^)]+\)/g;
    const matches = text.match(regex);
    if (matches) {
      return text.replace(regex, (match) => {
        const username = match.match(/@\[([^[\]]+)\]/)[1];
        return username;
      });
    }
    return text;
  };
  const renderMessageText = () => {
    return (
      <div>
        {!replyModeCommentData.message_type || replyModeCommentData.message_type === '1' ? (
          <p className="mb-0 chat-limit-one-line">
            {extractUsername(replyModeCommentData.message)}
          </p>
        ) : replyModeCommentData.message_type === '2' ? (
          <p className="mb-0">Image</p>
        ) : replyModeCommentData.message_type === '3' ? (
          <p className="mb-0 chat-limit-one-line">{replyModeCommentData.doc_name}</p>
        ) : (
          <div />
        )}
      </div>
    );
  };

  const onPressCancelReplyHandler = () => {
    setIsReplyModeActive(false);
    setReplyModeCommentData({});
    setReplyModeEmployeeData({});
  };

  const renderRoomTextInput = () => {
    return (
      selectedChat &&
      Object.keys(selectedChat).length > 0 && (
        <div className="chat-input-main-container">
          {isReplyModeActive && (
            <div className="chat-reply-container py-2 px-3">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex flex-grow-1 justify-content-between align-items-center common-word-break-all">
                  <div>
                    <p className="mb-0 chat-semi-bold-text">{replyModeEmployeeData.name}</p>
                    {renderMessageText()}
                  </div>
                  {replyModeCommentData.message_type === '2' && (
                    <div className="mx-2">
                      <img className="chat-outgoing-reply-icon" src={replyModeCommentData.url} />
                    </div>
                  )}
                </div>
                <p
                  className="mb-0 chat-theme-highlighted-text common-cursor-pointer"
                  onClick={onPressCancelReplyHandler}>
                  Cancel
                </p>
              </div>
            </div>
          )}

          <div className="d-flex m-2 align-items-center chat-texinput-outer-container">
            <form className="directory-search-form" onSubmit={onSubmitChatMessageHandler}>
              <CustomMentionEditor
                mentions={mentionList}
                onChangeChatTextHandler={onChangeChatTextHandler}
                editorState={editorState}
                setUserMentionList={setUserMentionList}
                onSetEditorState={onSetEditorState}
                onUserChange={onUserChange}
                onSetUserChange={onSetUserChange}
              />
            </form>
            <div ref={emojiRef} className="common-cursor-pointer position-relative">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.EMOJI_SMILE_ICON,
                  ACCESSIBILITY_IDENTIFIERS.COMMON
                )}
                className="chat-emoji-icon mr-2"
                onClick={() => setIsEmojiDialogVisible((val) => !val)}
              />
              {isEmojiDialogVisible && (
                <div className="chat-emoji-outer-container position-absolute">
                  <Picker native={true} preload={true} onEmojiClick={onPressEmojiHandler} />
                </div>
              )}
            </div>
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="top"
              overlay={onPressPlusButtonHandler()}>
              <div className="common-cursor-pointer mr-2">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_ADD_LINK,
                    ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                  )}
                  className="chat-plus-icon"
                />
              </div>
            </OverlayTrigger>
            <div className="common-cursor-pointer mr-2">
              {loading ? (
                <Lottie
                  height={24}
                  width={24}
                  options={{
                    loop: true,
                    autoplay: true,
                    animationData: horizontalDotsLoaderAnimation,
                  }}
                  isClickToPauseDisabled
                  style={verticalLoaderStyleConfig}
                />
              ) : (
                <img
                  onClick={onSubmitChatMessageHandler}
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.NEWS_SEND_COMMENT,
                    ACCESSIBILITY_IDENTIFIERS.NEWS
                  )}
                  className="chat-send-icon"
                />
              )}
            </div>
          </div>
        </div>
      )
    );
  };

  const onPressShareHandler = (eCardUrl) => {
    if (eCardUrl) {
      switch (chatType) {
        case DIALOG_TYPE.ONE_ON_ONE_CHAT:
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          if (isGroupExist) {
            sendMessageGroup('', '2', eCardUrl, true, false, '', '', '');
          } else {
            createPrivateChat(exitedOtherUserDetail, '', '2', eCardUrl, true, false, '', '', '');
          }
          break;
        case DIALOG_TYPE.GROUP_CHAT:
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          sendMessageGroup('', '2', eCardUrl, true, false, '', '', '');
          break;
        default:
          break;
      }
    }
    setIsECardModalVisible(false);
    dispatch(saveProgressLoadingState({ isProgressLoading: false }));
  };

  return (
    <div
      className="chat-sidebar-container chat-sidebar-border-right chat-convo-room-main-container"
      style={{
        backgroundImage: `url(
        ${getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_HEXAGON_WALLPAPER,
          ACCESSIBILITY_IDENTIFIERS.COMMON
        )}
      )`,
      }}>
      <ECardModal
        isECardModalVisible={isECardModalVisible}
        setIsECardModalVisible={setIsECardModalVisible}
        onPressShareHandler={onPressShareHandler}
      />
      <ChatConfirmModal
        isDialogVisible={showDeleteMessageDialog}
        setIsDialogVisible={() => setShowDeleteMessageDialog(false)}
        title=""
        subtitle="Are you sure you want to delete the message?"
        leftButtonText="Cancel"
        onClickLeftButton={() => setShowDeleteMessageDialog(false)}
        rightButtonText="Yes"
        onClickRightButton={submitDeleteMessage}
      />
      <ChatEditMessageModal
        isDialogVisible={showEditMessageDialog}
        setIsDialogVisible={() => setShowEditMessageDialog(false)}
        leftButtonText="Cancel"
        onClickLeftButton={() => setShowEditMessageDialog(false)}
        rightButtonText="Save"
        messageValue={selectedMessage}
        onClickRightButton={submitEditMessage}
        editorState={editorStateEdit}
        onUserChange={onUserChange}
        mentionList={mentionList}
        userMentionList={userMentionList}
        onSetEditorState={setEditorStateEdit}
        setUserMentionList={setUserMentionList}
      />
      <ChatConfirmModal
        isDialogVisible={showClearChatDialog}
        setIsDialogVisible={() => setShowClearChatDialog(false)}
        title="Clear chat?"
        subtitle="All the messages will be deleted. Proceed?"
        leftButtonText="Cancel"
        onClickLeftButton={() => setShowClearChatDialog(false)}
        rightButtonText="Ok"
        onClickRightButton={() => {
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          let uid = uId(employeeData);
          clearChatHistory(groupId, uid, () => {
            showToast(SUCCESS_MESSAGES.CHAT_CLEARED);
            dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          });
          setShowClearChatDialog(false);
        }}
      />
      <ChatConfirmModal
        isDialogVisible={showExitGroupDialog}
        setIsDialogVisible={() => setShowExitGroupDialog(false)}
        title="Exit group?"
        subtitle="You will no longer be able to send messages to this group. Proceed?"
        leftButtonText="Cancel"
        onClickLeftButton={() => {
          exitStatus.current = 1;
          setShowExitGroupDialog(false);
        }}
        rightButtonText="Ok"
        onClickRightButton={() => {
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          let uid = uId(employeeData);
          exitMemberFromGroup(groupId, uid, uid, isSelfUserAdmin, (value) => {
            dispatch(saveProgressLoadingState({ isProgressLoading: false }));
            if (value === true) {
              let membersArr = [];
              membersArr.push({
                name: employeeData.first_name + ' ' + employeeData.last_name,
                uid: uid,
              });
              sendNotificationMessageToGroup(
                groupId,
                employeeData,
                NOTIFICATION_MESSAGE_TYPES.USER_LEFT,
                membersArr,
                () => {}
              );
              setSelectedChat({});
              setChatDetails({});
              showToast(MESSAGES.EXITED_GROUP);
            } else {
              showToast(MESSAGES.CANNOT_EXIT_GROUP);
            }
            exitStatus.current = 1;
          });
          setShowExitGroupDialog(false);
        }}
      />
      <ChatConfirmModal
        isDialogVisible={showDeleteGroupDialog}
        setIsDialogVisible={() => setShowDeleteGroupDialog(false)}
        title="Delete group?"
        subtitle="You will no longer be able to send messages to this group. Proceed?"
        leftButtonText="Cancel"
        onClickLeftButton={() => {
          exitStatus.current = 1;
          setShowDeleteGroupDialog(false);
        }}
        rightButtonText="Ok"
        onClickRightButton={() => {
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          deleteGroup(groupId, () => {
            dispatch(saveProgressLoadingState({ isProgressLoading: false }));
            setSelectedChat({});
            setChatDetails({});
            showToast(MESSAGES.GROUP_DELETED);
            exitStatus.current = 1;
          });
          setShowDeleteGroupDialog(false);
        }}
      />
      {renderRoomHeader()}
      {renderRoomBody()}
      {renderRoomTextInput()}
    </div>
  );
};

export default ConversationRoom;
