import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectWebBaseUrl } from '../../state/MasterData';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { getSecuredPdfUrl } from '../../utils/common';
import ActivityLoader from '../../common/ui/activity_loader/ActivityLoader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { ANALYTICS_SCREEN_NAMES, NAVIGATION_ROUTES } from '../../common/constants/AppConstants';

const InvoiceScreen = () => {
  const webUrl = useSelector(selectWebBaseUrl);
  const [isLoaded, setIsLoaded] = useState(true);
  const location = useLocation();

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.MICRO_STORE_INVOICE_SCREEN,
      NAVIGATION_ROUTES.MI_STORE_INVOICE_SCREEN
    );
  }, []);

  return (
    <div className="main-content-container container">
      <ActivityLoader visible={isLoaded} />
      <div className="iframe-container">
        <iframe
          src={getSecuredPdfUrl(webUrl, location.state.encodedUrl)}
          allowFullScreen
          onLoad={() => setIsLoaded(false)}
        />
      </div>
    </div>
  );
};

export default InvoiceScreen;
