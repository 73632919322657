import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  FILTER_IDENTIFIERS,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { GET_BREAKOUTS, GET_BREAKOUTS_ATTENDEES } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../../state/UIState';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import SpeakersAttendeesListItem from '../components/SpeakersAttendeesListItem';
import { getHeaderComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import Filter from '../../../common/ui/filter/Filter';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';

const AttendeesTab = ({ breakoutId }) => {
  const dispatch = useDispatch();
  const [attendeesList, setAttendeesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const pageIdRef = useRef(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(true);
  const searchTextRef = useRef('');
  const textInputRef = useRef();
  const appliedFiltersRef = useRef({});
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const accessibilityData = useSelector(selectAccessibilityData);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.BREAKOUTS_ATTENDEES_TAB_VISIT,
      breakoutId,
      ANALYTICS_ITEM_NAMES.BREAKOUTS
    );
  }, [breakoutId]);

  const onClickSearchHandler = () => {
    logEvent(ANALYTICS_EVENT_TYPES.THE_WALL_SEARCH_CLICKED, '', ANALYTICS_ITEM_NAMES.THE_WALL);
    setIsSearchEnabled(true);
  };

  const callAttendeesApi = useCallback(async () => {
    dispatch(saveShowEmptyContentView(true));
    let params = {
      page_id: pageIdRef.current,
    };
    try {
      if (Object.keys(appliedFiltersRef.current).length) {
        params = { ...params, ...appliedFiltersRef.current };
      }
      if (searchTextRef.current) {
        params = { ...params, query: searchTextRef.current };
      }
      setLoading(true);
      const apiResponse = await apiRequest(
        GET_BREAKOUTS + '/' + breakoutId + GET_BREAKOUTS_ATTENDEES,
        REQUEST_TYPE.GET,
        params
      );
      setLoading(false);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data?.members?.length > 0) {
            if (pageIdRef.current === 1) {
              setAttendeesList(apiResponse.response.data?.members);
            } else {
              setAttendeesList((prevState) => [
                ...prevState,
                ...apiResponse.response.data?.members,
              ]);
            }
          } else if (pageIdRef.current === 1) {
            setAttendeesList([]);
          }
          setLoadMoreFlag(apiResponse.response.data?.members?.length > 0);
        }
      }
    } catch (err) {
      handleError(
        err,
        params,
        GET_BREAKOUTS + '/' + breakoutId + GET_BREAKOUTS_ATTENDEES,
        NAVIGATION_ROUTES.BREAKOUTS
      );
      setLoading(false);
    }
  }, [breakoutId, dispatch]);

  useEffect(() => {
    callAttendeesApi();
  }, [callAttendeesApi]);

  const resetSearchHandler = useCallback(() => {
    pageIdRef.current = 1;
    setAttendeesList([]);
    searchTextRef.current = '';
    setLoadMoreFlag(true);
    callAttendeesApi();
    setIsSearchEnabled(false);
  }, [callAttendeesApi]);

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_ATTENDEES} />;
  };

  const onSearchSubmitHandler = (event) => {
    event.preventDefault();
    logEvent(ANALYTICS_EVENT_TYPES.DIRECTORY_SEARCH_QUERY, '', searchTextRef.current);
    pageIdRef.current = 1;
    setAttendeesList([]);
    setLoadMoreFlag(true);
    callAttendeesApi();
  };

  const callSubmitFunction = (event) => {
    searchTextRef.current = event.target.value;
    if (event.target.value.length > 2) {
      setAttendeesList([]);
      onSearchSubmitHandler(event);
    }
  };

  const onClickFilterHandler = (selectedFilters) => {
    let tempFilterObject = {};
    Object.keys(selectedFilters).map((filterKey) => {
      if (selectedFilters[filterKey].length) {
        tempFilterObject[filterKey] = selectedFilters[filterKey].join();
      }
    });
    appliedFiltersRef.current = tempFilterObject;
    pageIdRef.current = 1;
    setLoadMoreFlag(true);
    setAttendeesList([]);
    callAttendeesApi();
  };

  const loadMoreHandler = () => {
    if (loadMoreFlag) {
      pageIdRef.current = pageIdRef.current + 1;
      callAttendeesApi();
    }
  };

  return (
    <div className="main-content-container">
      <div className="header-view pt-3">
        {isSearchEnabled && (
          <form className="d-flex flex-grow-1" onSubmit={onSearchSubmitHandler} ref={textInputRef}>
            <input
              autoFocus
              className="ml-3 directory-search-bar"
              type="text"
              placeholder="Search.."
              onChange={(event) => callSubmitFunction(event)}
            />
          </form>
        )}

        {isSearchEnabled ? (
          <div onClick={resetSearchHandler}>
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
              )}
              className="header-icon mr-3"
            />
          </div>
        ) : (
          <div onClick={onClickSearchHandler}>
            <img
              src={getHeaderComponentUrl(accessibilityData, ACCESSIBILITY_HEADER_COMPONENTS.SEARCH)}
              className="header-icon mr-3"
            />
          </div>
        )}
      </div>
      <div className="main-content-container">
        <Filter
          topFilter={FILTER_IDENTIFIERS.GEOGRAPHIES}
          geographies={true}
          locations={true}
          levels={true}
          layers={true}
          serviceTenures={true}
          bloodGroups={true}
          onClick={onClickFilterHandler}
        />
      </div>
      <ActivityLoader visible={loading} />
      {showEmptyContentView && !attendeesList.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          {attendeesList.map((item, index) => {
            return <SpeakersAttendeesListItem item={item} key={`${index}`} />;
          })}
          {loadMoreFlag && !!attendeesList.length && (
            <LoadMoreBtn
              onClick={() => loadMoreHandler(searchTextRef.current)}
              isLoading={progressLoadingState.isProgressLoading}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AttendeesTab;
