import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { saveAnniversaryTabData } from '../../state/TabData';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';

const AddContestsThanksScreen = ({ history, location }) => {
  const dispatch = useDispatch();
  const { identifier } = location.state;
  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.ADD_CONTESTS_THANKS_SCREEN,
      NAVIGATION_ROUTES.ADD_CONTESTS_THANKS_SCREEN
    );
  }, []);

  const onPressMoments = () => {
    dispatch(saveAnniversaryTabData('moments'));
    history.go(-2);
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY} />
      <div className="main-content-container">
        <div className="microgive-team-micro-view">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_SUCCESS,
              ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
            )}
            className="microgive-upload-icon mr-4"
          />
        </div>
        <div className="microgive-images-got-uploaded text-center mt-2">
          Check out your entry along with posts by fellow Microlanders
        </div>
        <CustomButton buttonStyle="microgive-button-style mt-3" onClick={onPressMoments}>
          Moments
        </CustomButton>
      </div>
    </div>
  );
};

export default AddContestsThanksScreen;
