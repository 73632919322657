import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { GET_MY_DONATIONS, GET_SUMMARY } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../state/UIState';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import MyDonationsListItem from './components/MyDonationsListItem';

const MyDonationsScreen = () => {
  const dispatch = useDispatch();
  const [myDonations, setMyDonations] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const [feeds, setFeeds] = useState({});
  const [noMatchFlag, setNoMatchFlag] = useState(false);

  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const accessibilityData = useSelector(selectAccessibilityData);
  const downloadImagePath = getFeatureComponentUrl(
    accessibilityData,
    ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_DOWNLOAD_CERTIFICATE,
    ACCESSIBILITY_IDENTIFIERS.CHEERS
  );

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.MY_DONATIONS_SCREEN, NAVIGATION_ROUTES.MY_DONATIONS_SCREEN);
  }, []);

  const callVolunteerLogApi = useCallback(
    async (id, loadMore) => {
      dispatch(saveShowEmptyContentView(true));
      setNoMatchFlag(false);
      if (loadMore) {
        const params = {
          page_id: id,
        };
        try {
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          const apiResponse = await apiRequest(GET_MY_DONATIONS, REQUEST_TYPE.GET, params);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                setMyDonations((oldArray) => [...oldArray, ...apiResponse.response.data.feeds]);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
              } else {
                if (apiResponse.response.data.feeds.length === 0 && pageId === 1) {
                  setNoMatchFlag(true);
                }
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(err, params, GET_MY_DONATIONS, NAVIGATION_ROUTES.MY_DONATIONS_SCREEN);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      }
    },
    [dispatch]
  );

  const callCurrentSummaryApi = useCallback(async () => {
    const params = {
      current: 0,
    };
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(GET_SUMMARY, REQUEST_TYPE.GET, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setFeeds(apiResponse.response.data.feeds);
          callVolunteerLogApi(1, true);
        }
      }
    } catch (err) {
      handleError(err, params, GET_SUMMARY, NAVIGATION_ROUTES.MY_DONATIONS_SCREEN);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [callVolunteerLogApi, dispatch]);

  useEffect(() => {
    callCurrentSummaryApi();
  }, [callCurrentSummaryApi]);

  const onPressLoadMore = () => {
    callVolunteerLogApi(pageId, loadMoreFlag);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.SEARCH_NO_RESULTS} />;
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICROGIVE} />
      <BackButton />
      <div className="microgive-upper-view">
        <div className="col-sm-12">
          <div className="text-center microgive-total-hrs-text">
            {Object.keys(feeds).length > 0 && `${feeds.donation.donation_count}`}
          </div>
          <div className="text-center microgive-no-time-volunteer-text">No. of times donated</div>
        </div>
      </div>
      {pageId === 1 && noMatchFlag ? (
        emptyListView()
      ) : (
        <div>
          <div className="pl-3 pr-3 pt-1 pb-1">
            {myDonations.map((item, index) => {
              return (
                <MyDonationsListItem
                  key={`${index}`}
                  item={item}
                  index={index}
                  downloadImagePath={downloadImagePath}
                />
              );
            })}
          </div>
          {loadMoreFlag && (
            <LoadMoreBtn
              onClick={onPressLoadMore}
              buttonStyle="mb-2"
              isLoading={progressLoadingState.isProgressLoading}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MyDonationsScreen;
