import { createSlice } from '@reduxjs/toolkit';

const saveEmployeeDataReducer = (state, action) => {
  state.data = action.payload;
};

const saveEmployeeLoginDataReducer = (state, action) => {
  state.autoLoginData = action.payload;
};

const savePreEmployeeDataReducer = (state, action) => {
  state.preEmployeeData = action.payload;
};

const savePledgeCountReducer = (state, action) => {
  state.pledgeCount = action.payload;
};

const updatePledgeReducer = (state, action) => {
  let { data } = state;
  data.is_pledged = action.payload;
  state.data = data;
};

// REDUCER
const employeeDataSlice = createSlice({
  name: 'employeeData',
  initialState: {
    data: {},
    autoLoginData: {},
    preEmployeeData: {},
    pledgeCount: 0,
  },
  reducers: {
    saveEmployeeData: saveEmployeeDataReducer,
    saveEmployeeLoginData: saveEmployeeLoginDataReducer,
    savePreEmployeeData: savePreEmployeeDataReducer,
    savePledgeCount: savePledgeCountReducer,
    updatePledge: updatePledgeReducer,
  },
});

// ACTIONS
const {
  saveEmployeeData,
  savePreEmployeeData,
  updatePledge,
  savePledgeCount,
  saveEmployeeLoginData,
} = employeeDataSlice.actions;

// SELECTOR
const selectEmployeeData = ({ employeeData }) => employeeData.data;
const selectEmployeeLoginData = ({ employeeData }) => employeeData.autoLoginData;
const selectPreEmployeeData = ({ employeeData }) => employeeData.preEmployeeData;
const selectPledgeCount = ({ employeeData }) => employeeData.pledgeCount;
const selectEmployeeId = ({ employeeData }) => employeeData.autoLoginData.employee_id;

const employeeDataSliceReducer = employeeDataSlice.reducer;

export {
  employeeDataSliceReducer,
  saveEmployeeData,
  selectEmployeeData,
  savePreEmployeeData,
  selectPreEmployeeData,
  updatePledge,
  savePledgeCount,
  selectPledgeCount,
  selectEmployeeId,
  saveEmployeeLoginData,
  selectEmployeeLoginData,
};
