import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import Divider from '../../../common/ui/divider/Divider';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { getFeatureComponentUrl, getFeatureComponentUrlLdpi } from '../../../utils/accessibility';
import { fetchUserByIdOnce, uId } from '../../../utils/ChatHandler';

const ForwardMessageListItem = (props) => {
  const { item, isEmployeeSelected, onPress } = props;
  const [dialogImage, setDialogImage] = useState(item.image_url);
  const [dialogName, setDialogName] = useState(item.name);
  const accessibilityData = useSelector(selectAccessibilityData);
  const employeeData = useSelector(selectEmployeeData);

  useEffect(() => {
    if (item.group) {
      setDialogName(item.name);
      if (item.image_url && item.image_url !== '') {
        setDialogImage(item.image_url);
      } else {
        setDialogImage(
          getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_CIRCULAR_GROUP_GREY,
            ACCESSIBILITY_IDENTIFIERS.COMMON
          )
        );
      }
    } else if (item.group === false) {
      let uid = uId(employeeData);
      let arr = item.groupId.split('_');
      const otherUserId = arr.find((id) => id !== uid);
      fetchUserByIdOnce(otherUserId, (isExist, user) => {
        if (isExist) {
          setDialogName(user.name);
          setDialogImage(user.image_url);
        } else {
          setDialogName('');
          setDialogImage(
            getFeatureComponentUrlLdpi(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
              ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
            )
          );
        }
      });
    }
  }, [accessibilityData, employeeData, item.group, item.groupId, item.image_url, item.name]);

  return (
    <div
      onClick={() => onPress({ ...item, ...{ dialogName: dialogName, dialogImage: dialogImage } })}>
      <div className="d-flex p-3 align-items-center justify-content-between common-cursor-pointer chat-sidebar-item">
        <div className={`d-flex align-items-center`}>
          <div className="d-flex mr-2 position-relative">
            <img
              src={
                dialogImage !== ''
                  ? dialogImage
                  : getFeatureComponentUrlLdpi(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
                      ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                    )
              }
              className="common-user-profile-photo-xs"
            />
            {isEmployeeSelected && (
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.SOM_EMPLOYEE_SELECT,
                  ACCESSIBILITY_IDENTIFIERS.SOM
                )}
                className="chat-selected-employee-close-icon position-absolute"
              />
            )}
          </div>
          <div className="overflow-hidden flex-grow-1">
            <div className="chat-bold-text chat-base-text-sm">{dialogName}</div>
          </div>
        </div>
      </div>
      <Divider style="chat-divider-thin" />
    </div>
  );
};

export default ForwardMessageListItem;
