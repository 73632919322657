import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveProgressLoadingState } from '../../state/UIState';
import { apiRequestEStore, apiRequestUser } from '../../services/Service';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { useHistory } from 'react-router';
import { selectEStoreConfig } from '../../state/MasterData';
import Collections from './components/Collections';
import { formatDateDDMonthYYYY } from '../../utils/common';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { CUSTOMERS_ME, GET_ORDERS_BY_ID, GET_PRODUCT_IMAGES } from '../../services/ApiUrls';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';

const OrderHistoryListScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [orders, setOrders] = useState([]);
  const [images, setImages] = useState([]);
  const estoreConfig = useSelector(selectEStoreConfig);
  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.MICRO_STORE_ORDER_LIST_SCREEN,
      NAVIGATION_ROUTES.MI_STORE_ORDER_LIST_SCREEN
    );
  }, []);

  const fetchItemImages = useCallback(
    async (skuList, orderList) => {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      try {
        const apiResponse = await apiRequestEStore(GET_PRODUCT_IMAGES, REQUEST_TYPE.POST, {
          skus: skuList,
        });
        setImages(apiResponse.response);
        setOrders(orderList);
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      } catch (err) {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    },
    [dispatch]
  );

  const getImage = (sku) => {
    const image = images.find((img) => img.sku === sku);
    return image?.image ?? '';
  };

  const getParentSku = (sku) => {
    const image = images.find((img) => img.sku === sku);
    return image?.parent_sku ?? '';
  };

  const getShortDescription = (sku) => {
    const item = images.find((img) => img.sku === sku);
    return item?.short_description ?? '';
  };

  const fetchOrders = useCallback(
    async (customerId) => {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      try {
        const apiResponse = await apiRequestEStore(
          `${GET_ORDERS_BY_ID}?searchCriteria[filterGroups][0][filters][0][field]=customer_id&searchCriteria[filterGroups][0][filters][0][value]=${customerId}&&searchCriteria[sortOrders][0][field]=created_at&searchCriteria[sortOrders][0][direction]=desc`,
          REQUEST_TYPE.GET
        );
        if (Object.keys(apiResponse.response).length) {
          const skus = [];
          apiResponse.response.items.map((order) => {
            order.items.map((item) => {
              skus.push(item.sku);
            });
          });
          fetchItemImages(skus, apiResponse.response.items);
        }
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      } catch (err) {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    },
    [dispatch, fetchItemImages]
  );

  const fetchContent = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequestUser(CUSTOMERS_ME, REQUEST_TYPE.GET);
      if (Object.keys(apiResponse.response).length) {
        fetchOrders(apiResponse.response.id);
      }
    } catch (err) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, fetchOrders]);

  useEffect(() => {
    fetchContent();
  }, [fetchContent]);

  const handleOrder = (item, index) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ESTORE_ORDER_LIST_ITEM_CLICK,
      item?.items[0]?.order_id,
      ANALYTICS_ITEM_NAMES.ORDERS_LIST_ITEM_CLICK
    );
    const tempImages = [];
    item.items.map((element) => {
      tempImages.push({
        image: getImage(element.sku),
        sku: element.sku,
        shortDescription: getShortDescription(element.sku),
        size: getSize(element.sku),
        parentSku: getParentSku(element.sku),
      });
    });
    history.push(NAVIGATION_ROUTES.MI_STORE_SHIPMENT_DETAILS, {
      orderDetails: item,
      index,
      images: tempImages,
    });
  };

  const getSize = (sku) => {
    const image = images.find((img) => img.sku === sku);
    if (image.size_value && image.attributes.length) {
      const sizeObj = image.attributes?.find((attr) => attr.label === 'Size');
      sizeObj.attributes_map[`${sizeObj.id}:${image.size_value}`];
      return sizeObj.attributes_map[`${sizeObj.id}:${image.size_value}`].label;
    } else {
      return null;
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICRO_STORE} />
      <BackButton backTitle="ORDERS" />
      <div className="e-store-product-list-main">
        <div className="w-75 p-2">
          {orders.length > 0 ? (
            orders.map((order, index) => (
              <div key={index}>
                {order.items.map((item, pos) => (
                  <div
                    onClick={() => handleOrder(order, pos)}
                    key={pos}
                    className="estore-order-history-container common-cursor-pointer p-3">
                    <div className="order-item-image-block">
                      <img className="order-item-image-block" src={getImage(item.sku)} />
                    </div>
                    <div className="w-100">
                      <div className="delivery-estimate-header pb-2">
                        <div className="order-item-name-arrow">
                          <div className="estore-bold-text">{item.name}</div>
                          <img
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_RIGHT_ARROW,
                              ACCESSIBILITY_IDENTIFIERS.COMMON
                            )}
                            className="estore-account-item-icon"
                          />
                        </div>
                        <div className="estore-regular-text margin-bottom-3">{`Qty: ${item.qty_ordered}`}</div>
                        {getSize(item.sku) && (
                          <div className="estore-regular-text margin-bottom-3">{`Size: ${getSize(
                            item.sku
                          )}`}</div>
                        )}
                        {/* <div className="estore-regular-text pb-2">Size: Onesize</div> */}
                        <div className="estore-bold-text pt-2">{`₹ ${
                          item.price * item.qty_ordered
                        }`}</div>
                      </div>
                      <div className="estore-regular-text margin-bottom-3 pt-2">{`Ordered on: ${formatDateDDMonthYYYY(
                        order.created_at
                      )}`}</div>
                      <div>
                        {order.status.length > 0 && (
                          <div className="estore-regular-text margin-bottom-3">
                            {order.status === 'canceled'
                              ? 'Cancelled'
                              : order.status[0].toUpperCase() + order.status.substring(1)}
                          </div>
                        )}
                        {(order.status === 'pending' || order.status === 'processing') && (
                          <div className="estore-regular-text">
                            {order.extension_attributes?.shipping_assignments[0]?.shipping
                              .method === 'tablerate_bestway'
                              ? estoreConfig.estimated_delivery_banglore
                              : estoreConfig.estimated_delivery}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))
          ) : (
            <div>
              <DefaultContentView message={ERROR_MESSAGES.ESTORE_NO_ORDERS_FOUND} />
            </div>
          )}
        </div>
        <Collections />
      </div>
    </div>
  );
};

export default OrderHistoryListScreen;
