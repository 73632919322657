import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { showToast } from '../../../utils/common';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import Divider from '../../../common/ui/divider/Divider';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import { saveProgressLoadingState } from '../../../state/UIState';
import { SAVE_JOB } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';

const ReferralBookmarkTab = (props) => {
  const { data = [], getTabData } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const accessibilityData = useSelector(selectAccessibilityData);
  const [error, setError] = useState('');
  useEffect(() => {
    getTabData(1);
  }, [getTabData]);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.IJP_BOOKMARK_TAB_VISIT, '', ANALYTICS_ITEM_NAMES.IJPR);
  }, []);

  const showEmptyListView = () => {
    return (
      <DefaultContentView
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_EMPTY_STATE,
          ACCESSIBILITY_IDENTIFIERS.COMMON
        )}
        iconSize="lg"
        message={ERROR_MESSAGES.IJPR_NO_JOBS_SAVED}
      />
    );
  };

  const onPressIjpListItem = (ijpId, jobCode) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.IJP_BOOKMARK_JOB_POST_CLICK,
      jobCode.toString(),
      ANALYTICS_ITEM_NAMES.IJPR
    );
    history.push(NAVIGATION_ROUTES.IJP_REFER_DETAIL + '/' + ijpId);
  };

  const onPressSaveJobHandler = (ijpId) => {
    callSaveJobApi(ijpId);
  };

  const callSaveJobApi = async (ijpId) => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const apiURl = SAVE_JOB + '/' + ijpId + '/bookmark';
      const apiResponse = await apiRequest(apiURl, REQUEST_TYPE.POST, {});
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          const message = apiResponse.response.message;
          if (message) {
            showToast(message);
            getTabData(1);
          }
        }
      }
    } catch (err) {
      handleError(err, {}, SAVE_JOB + '/' + ijpId + '/bookmark', NAVIGATION_ROUTES.IJPR);
      console.error(err);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      setError(true);
    }
  };

  const renderIjprListItem = (item, index) => {
    return (
      <div key={index}>
        <div className="d-flex py-3 justify-content-between">
          <div
            className="w-100 common-cursor-pointer"
            onClick={() => onPressIjpListItem(item.ijp_id, item.job_code)}>
            <h5 className="ijp-search-item-title">{item.job_title}</h5>
            <p className="ijp-search-item-subtitle mb-0">
              {' '}
              {item.job_code} | {item.country_title} - {item.location_title} |{' '}
              {item.minimum_expirence} - {item.maximum_expirence} yrs
            </p>
          </div>
          <div
            className="d-flex mt-1 common-cursor-pointer"
            onClick={() => onPressSaveJobHandler(item.ijp_id)}>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_BOOKMARK_FILLED_GREY,
                ACCESSIBILITY_IDENTIFIERS.COMMON
              )}
              className="ijpr-saved-job-item-right-icon"
            />
          </div>
        </div>
        <Divider />
      </div>
    );
  };

  return (
    <div className="container">
      <ActivityLoader visible={!!props.isLoading} />
      {data && (
        <div className="mb-3">
          {!data.length && !props.isLoading && showEmptyListView()}
          {!!data.length && (
            <div>
              {data.map((ijp, index) => renderIjprListItem(ijp, index))}
              {props.loadMoreFlag && (
                <LoadMoreBtn
                  buttonStyle="my-3"
                  onClick={() => props.paginationHandler()}
                  isLoading={props.isLoading}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(ReferralBookmarkTab);
