import React, { useEffect, useState } from 'react';
import { Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import { LinkedinIcon, TwitterIcon, FacebookIcon, EmailIcon } from 'react-share';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
} from '../../../common/constants/AppConstants';
import { logEvent, logEvent2 } from '../../../utils/FirebaseAnalyticsUtils';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { selectWallpapersInfoHtmlData } from '../../../state/MasterData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { getDeviceScreenDensityQualifierAsPath, shareImage } from '../../../utils/common';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import CustomLightbox from '../../../common/ui/custom_lightbox/CustomLightbox';
import ShareEmailModal from '../../../common/components/shareEmailModal';

const WallpaperTab = (props) => {
  const { data, categoryId, title, loading } = props;
  const accessibilityData = useSelector(selectAccessibilityData);
  const wallpapersInfoHtmlData = useSelector(selectWallpapersInfoHtmlData);
  const [wallpaperData, setWallpaperData] = useState([]);
  const [isImageDialogVisible, setIsImageDialogVisible] = useState(false);
  const [imageDialogIndex, setImageDialogIndex] = useState(0);
  const [imageDialogData, setImageDialogData] = useState([]);
  const [isPortraitMode, setIsPortraitMode] = useState(false);
  const [deviceScreenDensityQualifierPath] = useState(getDeviceScreenDensityQualifierAsPath());
  const [imageUrl, setImageUrl] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (data && Object.keys(data).length !== 0) {
      if (isPortraitMode === true) {
        setWallpaperData(data.gallery.portrait);
      } else {
        setWallpaperData(data.gallery.landscape);
      }
    }
  }, [data, isPortraitMode]);

  useEffect(() => {
    setImageDialogData(
      wallpaperData.map(
        (image) => data.gallery?.base_url + deviceScreenDensityQualifierPath + image.picture_path
      )
    );
  }, [data?.gallery, deviceScreenDensityQualifierPath, wallpaperData]);

  useEffect(() => {
    logEvent2({
      eventType: ANALYTICS_EVENT_TYPES.WALLPAPERS_TAB_VISIT,
      itemId: categoryId.toString(),
      itemName: ANALYTICS_ITEM_NAMES.WALLPAPERS,
      categoryId: categoryId.toString(),
      categoryName: title,
    });
  }, [categoryId, title]);

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_WALLPAPERS} />;
  };

  const onPressWallpaper = (e) => {
    e.stopPropagation();
    setIsImageDialogVisible(true);
  };

  const logShareEvent = (wallpaper) => {
    logEvent2({
      eventType: ANALYTICS_EVENT_TYPES.WALLPAPERS_SHARE,
      itemId: wallpaper.wallpaper_id.toString(),
      itemName: wallpaper.picture_path.toString(),
      categoryId: categoryId.toString(),
      categoryName: title,
    });
  };

  const renderWallpaper = (wallpaper) => {
    return (
      <div
        key={wallpaper.wallpaper_id.toString()}
        className="col-md-6 col-lg-4 mb-2 common-cursor-pointer"
        onClick={(e) => onPressWallpaper(e)}>
        <div className="wallpaper-image position-relative d-inline-block">
          <img
            src={data.gallery.base_url + deviceScreenDensityQualifierPath + wallpaper.picture_path}
            className="embed-responsive embed-responsive-16by9 "
          />
        </div>
        <div
          className={`${
            isPortraitMode === true
              ? 'ecards-social-icons mb-2'
              : 'ecards-social-icons-landscape mr-1 mb-1 align-items-center w-100 d-flex justify-content-end flex-row position-absolute'
          }`}>
          <LinkedinIcon
            size={32}
            round={true}
            onClick={(e) => {
              e.stopPropagation();
              shareWallpaper('Linkedin', wallpaper, data.gallery.base_url + wallpaper.picture_path);
            }}
            className={`${isPortraitMode === true ? 'mb-1' : 'mr-1'}`}
          />
          <FacebookIcon
            size={32}
            round={true}
            onClick={(e) => {
              e.stopPropagation();
              shareWallpaper('Facebook', wallpaper, data.gallery.base_url + wallpaper.picture_path);
            }}
            className={`${isPortraitMode === true ? 'mb-1' : 'mr-1'}`}
          />
          <TwitterIcon
            size={32}
            round={true}
            onClick={(e) => {
              e.stopPropagation();
              shareWallpaper('Twitter', wallpaper, data.gallery.base_url + wallpaper.picture_path);
            }}
            className={`${isPortraitMode === true ? 'mb-1' : 'mr-1'}`}
          />
          <EmailIcon
            size={32}
            round={true}
            onClick={(e) => {
              e.stopPropagation();
              logShareEvent(wallpaper);
              setShowModal(true);
              setImageUrl(data.gallery.base_url + wallpaper.picture_path);
            }}
            className={`${isPortraitMode === true ? 'mb-1' : 'mr-1'}`}
          />
          <div
            onClick={(e) => {
              e.stopPropagation();
              saveAs(data.gallery.base_url + wallpaper.picture_path, wallpaper.picture_path);
              logEvent2({
                eventType: ANALYTICS_EVENT_TYPES.WALLPAPERS_DOWNLOAD,
                itemId: wallpaper.wallpaper_id.toString(),
                itemName: wallpaper.picture_path.toString(),
                categoryId: categoryId.toString(),
                categoryName: title,
              });
            }}>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.ECARDS_DOWNLOAD_CARD,
                ACCESSIBILITY_IDENTIFIERS.ECARDS
              )}
              className="ecards-download-icon"
            />
          </div>
        </div>
      </div>
    );
  };

  const instructionsPopover = (
    <Popover className="wallpaper-info-popover-container">
      <Popover.Content>
        <HtmlView htmlStyle="wallpaper-info-html" html={wallpapersInfoHtmlData} />
      </Popover.Content>
    </Popover>
  );

  const shareWallpaper = (platform, wallpaper, url) => {
    logShareEvent(wallpaper);
    shareImage(platform, url);
  };

  return (
    <div>
      {data && (
        <div>
          {isImageDialogVisible && (
            <CustomLightbox
              mainSrc={imageDialogData[imageDialogIndex]}
              nextSrc={
                imageDialogData.length > 1
                  ? imageDialogData[(imageDialogIndex + 1) % imageDialogData.length]
                  : null
              }
              prevSrc={
                imageDialogData.length > 1
                  ? imageDialogData[
                      (imageDialogIndex + imageDialogData.length - 1) % imageDialogData.length
                    ]
                  : null
              }
              onMovePrevRequest={() =>
                setImageDialogIndex(
                  (imageDialogIndex + imageDialogData.length - 1) % imageDialogData.length
                )
              }
              onMoveNextRequest={() =>
                setImageDialogIndex((imageDialogIndex + 1) % imageDialogData.length)
              }
              onCloseRequest={() => setIsImageDialogVisible(false)}
            />
          )}
          <div className="header-view d-flex justify-content-between align-items-center">
            {data && data.gallery && data.gallery.portrait && data.gallery.portrait.length > 0 ? (
              <div className="d-flex">
                <p className="mb-0 mr-3">Select format:</p>
                <Form.Group className="d-flex align-items-center m-0">
                  <Form.Check.Label
                    className={`mr-2 ${isPortraitMode ? 'wallpaper-faded-element' : ''}`}>
                    Web
                  </Form.Check.Label>
                  <div className="wallpaper-switch">
                    <Form.Check
                      className="common-cursor-pointer custom-switch custom-switch-md mt-n2"
                      type="switch"
                      id="wallpaper-custom-switch"
                      checked={isPortraitMode}
                      onChange={(event) => {
                        setIsPortraitMode(event.target.checked);
                        if (event.target.checked) {
                          logEvent(
                            ANALYTICS_EVENT_TYPES.WALLPAPERS_SWITCH_CLICK,
                            'Mobile',
                            ANALYTICS_ITEM_NAMES.WALLPAPERS
                          );
                        } else {
                          logEvent(
                            ANALYTICS_EVENT_TYPES.WALLPAPERS_SWITCH_CLICK,
                            'Web',
                            ANALYTICS_ITEM_NAMES.WALLPAPERS
                          );
                        }
                      }}
                    />
                  </div>
                  <Form.Check.Label
                    className={`ml-2 ${isPortraitMode ? '' : 'wallpaper-faded-element'}`}>
                    Mobile
                  </Form.Check.Label>
                </Form.Group>
              </div>
            ) : (
              <div />
            )}
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="bottom-end"
              overlay={instructionsPopover}>
              <div className="d-flex flex-row mi-hr-activity-btn-container common-cursor-pointer mt-2 pt-1">
                <p className="mb-0 wallpaper-theme-text-semi-bold">Instructions</p>
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_INFO,
                    ACCESSIBILITY_IDENTIFIERS.COMMON
                  )}
                  className="wallpaper-info-icon ml-2 mt-1"
                />
              </div>
            </OverlayTrigger>
          </div>
          {!wallpaperData.length && !loading && emptyListView()}
          {!!wallpaperData.length && (
            <div className="my-3">
              <div className="row">
                {wallpaperData.map((wallpaper) => renderWallpaper(wallpaper))}
              </div>
            </div>
          )}
        </div>
      )}
      <ShareEmailModal
        showModal={showModal}
        imageUrl={imageUrl}
        hideModal={() => setShowModal(false)}
        logEvent={() => {
          logEvent2(
            ANALYTICS_EVENT_TYPES.SHARE_BUSINESS_CARD_EMAIL,
            '',
            ANALYTICS_ITEM_NAMES.FACILITY
          );
        }}
      />
    </div>
  );
};

export default WallpaperTab;
