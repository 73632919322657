import React from 'react';
import { getUrlWithSpecificRendition } from '../../../utils/common';
import { SCREEN_DENSITY_QUALIFIERS } from '../../../common/constants/AppConstants';
import PlayIconOverlay from '../../../common/ui/play_icon_overlay/PlayIconOverlay';

const PodcastListItem = ({ onPress, item }) => {
  return (
    <div className="col-sm-4 mb-3 common-cursor-pointer" onClick={() => onPress(item)}>
      <div>
        {item?.content_load_type === '3' && <PlayIconOverlay />}
        <img
          src={getUrlWithSpecificRendition(
            item.promo_image.base_url,
            SCREEN_DENSITY_QUALIFIERS.HDPI,
            item.promo_image.image_path
          )}
          className="embed-responsive embed-responsive-16by9"
        />
        <div className="podcast-title mt-1">{item.title}</div>
        {item.isNew && (
          <div className="d-flex">
            <div className="podcast-new-view pl-2 pr-2 align-items-center">
              <div className="podcast-new-text">NEW EPISODE</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PodcastListItem;
