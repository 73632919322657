import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import { DI_PLEDGE_STATUS, DI_TAKE_PLEDGE } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectDIPledge } from '../../../state/MasterData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../state/UIState';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';

const PledgeTab = () => {
  const dispatch = useDispatch();

  const [isPledged, setIsPledged] = useState();
  const [pledgeCount, setPledgeCount] = useState('');
  const [isShowDoneButton, setIsShowDoneButton] = useState(false);
  const [isShowThanksMessage, setIsShowThanksMessage] = useState(false);
  const [showTakePledgeButton, setShowTakePledgeButton] = useState(false);

  const pledgeMessage = useSelector(selectDIPledge).microlanders_pldge;
  const pledgeMessageThanks = useSelector(selectDIPledge).pledge_thanks;
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.DI_PLEDGE_TAB_VISIT, '', ANALYTICS_ITEM_NAMES.DI);
  }, []);

  useEffect(() => {
    if (isPledged === undefined) {
      setShowTakePledgeButton(false);
    } else if (isPledged === 0) {
      setShowTakePledgeButton(true);
    } else if (isPledged === 1) {
      setShowTakePledgeButton(false);
    }
  }, [isPledged]);

  const callPledgeCountApi = useCallback(async () => {
    try {
      const apiResponse = await apiRequest(DI_PLEDGE_STATUS, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setIsPledged(apiResponse.response.data.is_pledged);
          setPledgeCount(apiResponse.response.data.pledge_count);
        }
      }
    } catch (err) {
      handleError(err, {}, DI_PLEDGE_STATUS, NAVIGATION_ROUTES.DI);
      //
    }
  }, []);

  useEffect(() => {
    callPledgeCountApi();
  }, [callPledgeCountApi]);

  const callSubmitPledgeApi = async () => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const params = new URLSearchParams();
      const apiResponse = await apiRequest(DI_TAKE_PLEDGE, REQUEST_TYPE.POST, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setIsShowDoneButton(true);
          setIsShowThanksMessage(true);
          callPledgeCountApi();
        }
      }
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPress = () => {
    callSubmitPledgeApi();
  };

  const onPressDone = () => {
    setIsShowDoneButton(false);
    setIsShowThanksMessage(false);
  };

  return (
    <div className="main-content-container">
      <div className="di-pledge-header justify-content-center align-items-center">
        <div className="col-sm-12 pt-3 pb-3">
          <div className="text-center di-pledge-count">
            {isPledged === 1 ? `You & ${pledgeCount - 1}` : `${pledgeCount}`}
          </div>
          <div className="text-center di-be-next-text">
            {isPledged === 1
              ? 'Microlanders have taken the pledge.'
              : 'Microlanders have taken the pledge. Be the next!!'}
          </div>
        </div>
      </div>
      {!isShowThanksMessage &&
        pledgeMessage.length > 0 &&
        pledgeMessage.map((item, index) => {
          return (
            <div key={`${index}`} className="mt-3 mb-3 di-i-pledge-text">
              {item}
            </div>
          );
        })}
      {showTakePledgeButton === true && (
        <div className="d-flex justify-content-center">
          <CustomButton onClick={onPress} showLoader={progressLoadingState.isProgressLoading}>
            Take the pledge
          </CustomButton>
        </div>
      )}
      {isShowThanksMessage &&
        pledgeMessageThanks.length > 0 &&
        pledgeMessageThanks.map((item, index) => {
          return (
            <div key={`${index}`} className="mt-3 mb-3 di-i-pledge-text">
              {item}
            </div>
          );
        })}
      {isShowDoneButton && isPledged === 1 && (
        <div className="d-flex justify-content-center">
          <CustomButton onClick={onPressDone}>Done</CustomButton>
        </div>
      )}
    </div>
  );
};

export default PledgeTab;
