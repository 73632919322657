import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab } from 'react-bootstrap';
import queryString from 'query-string';

import {
  ACTION_TYPES,
  MY_PROFILE_IDENTIFIERS,
  REQUEST_TYPE,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import { saveEmployeeData, selectEmployeeData, selectEmployeeId } from '../../state/EmployeeData';
import { apiRequest, handleError } from '../../services/Service';
import {
  GET_ASSETS,
  GET_EMPLOYEE_BY_ID,
  GET_ID_CARD,
  GET_MY_WISHES_LIST,
} from '../../services/ApiUrls';
import {
  resetLoaderState,
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectBaseUrl,
} from '../../state/UIState';
import { selectMyProfileDetailTabs } from '../../state/MasterData';
import { saveMyProfileDetailTabData, selectMyProfileDetailTabData } from '../../state/TabData';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import MyAssetsTab from './tabs/MyAssetsTab';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import MyInsuranceTab from './tabs/MyInsuranceTab';
import MyIdCardTab from './tabs/MyIdCardTab';
import EmployeeProfileDetailScreen from './EmployeeProfileDetailScreen';
import MyAppSettingsTab from './tabs/MyAppSettingsTab';
import MyWishesTab from './tabs/MyWishesTab';
import NavTabs from '../../common/ui/tabs/NavTabs';
import { selectAccessData } from '../../state/UserAccessData';

const MyProfileDetailScreen = (props) => {
  const { location, history } = props;
  const dispatch = useDispatch();
  const [myProfileData, setMyProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [id] = useState(new URLSearchParams(location.search).get('id'));
  const myProfileDetailTabTitles = useSelector(selectMyProfileDetailTabs);
  const myProfileDetailTabData = useSelector(selectMyProfileDetailTabData);
  const baseUrl = useSelector(selectBaseUrl);
  const employeeData = useSelector(selectEmployeeData);
  const employee_id = useSelector(selectEmployeeId);
  const [wishes, setWishes] = useState([]);
  const [filterYears, setFilterYears] = useState([]);
  const isMounted = useRef(false);
  const [shouldCall, setShouldCall] = useState(false);
  const [noMatchFlag, setNoMatchFlag] = useState(false);
  const [showInsuranceTab, setShowInsuranceTab] = useState(true);
  const [showAssetsTab, setShowAssetsTab] = useState(true);
  const [showIdCardTab, setShowIdCardTab] = useState(true);
  const accessData = useSelector(selectAccessData);
  const profileAccessData = accessData?.data?.find((item) => item.feature_key === '_profile');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (profileAccessData) {
      const actions = profileAccessData?.actions;
      setShowInsuranceTab(actions?.includes('_insurance'));
      setShowAssetsTab(actions?.includes('_assets'));
      setShowIdCardTab(actions?.includes('_idCard'));
    }
  }, [profileAccessData]);

  const getEmployeeDataById = useCallback(async () => {
    if (employee_id && isMounted.current === false) {
      try {
        const apiResponse = await apiRequest(
          GET_EMPLOYEE_BY_ID + employee_id,
          REQUEST_TYPE.GET,
          {}
        );
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            if (Object.keys(apiResponse.response.data.employee).length) {
              dispatch(saveEmployeeData(apiResponse.response.data.employee));
              dispatch(resetLoaderState());
              isMounted.current = true;
            }
          }
        }
      } catch (err) {
        handleError(err, {}, GET_EMPLOYEE_BY_ID + employee_id, NAVIGATION_ROUTES.MY_PROFILE_DETAIL);
      }
    }
  }, [dispatch, employee_id]);

  useEffect(() => {
    if (isMounted.current === false && employee_id) {
      setShouldCall(true);
    }
  }, [employeeData, employee_id]);

  useEffect(() => {
    dispatch(saveProgressLoadingState({ isProgressLoading: true }));
  }, []);

  useEffect(() => {
    shouldCall && getEmployeeDataById();
  }, [getEmployeeDataById, shouldCall]);

  useEffect(() => {
    if (baseUrl !== '' && id && id !== '') {
      dispatch({ type: ACTION_TYPES.GET_TOKEN, payload: { id: id } });
    }
  }, [baseUrl, dispatch, id]);

  useEffect(() => {
    if (myProfileDetailTabTitles.length && myProfileDetailTabData === '') {
      if (location.search) {
        const { tab } = queryString.parse(location.search);
        if (tab && myProfileDetailTabTitles.find((tabNode) => tabNode.key === tab)) {
          dispatch(saveMyProfileDetailTabData(tab));
        } else {
          dispatch(saveMyProfileDetailTabData(myProfileDetailTabTitles[0].key));
          history.replace({
            pathname: NAVIGATION_ROUTES.MY_PROFILE_DETAIL,
            search: `?tab=${myProfileDetailTabTitles[0].key}`,
          });
        }
      } else {
        dispatch(saveMyProfileDetailTabData(myProfileDetailTabTitles[0].key));
        history.replace({
          pathname: NAVIGATION_ROUTES.MY_PROFILE_DETAIL,
          search: `?tab=${myProfileDetailTabTitles[0].key}`,
        });
      }
    }
  }, [
    dispatch,
    history,
    location.search,
    myProfileDetailTabData,
    myProfileDetailTabTitles,
    employeeData,
  ]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.MY_PROFILE_DETAIL, NAVIGATION_ROUTES.MY_PROFILE_DETAIL);
  }, []);

  const callMyProfileApi = useCallback(
    async (tabType) => {
      dispatch(saveShowEmptyContentView(true));
      setIsLoading(true);
      const params = { empCode: employeeData.employee_code };
      try {
        let data = [];
        if (tabType === MY_PROFILE_IDENTIFIERS.PROFILE) {
          data = employeeData;
        } else if (tabType === MY_PROFILE_IDENTIFIERS.ASSETS) {
          const apiResponse = await apiRequest(GET_ASSETS, REQUEST_TYPE.GET, {});
          if (Object.keys(apiResponse).length) {
            if (apiResponse.response.status) {
              if (apiResponse.response.data.feed.ErrorMessage) {
                setErrorMessage(apiResponse.response.data.feed.ErrorMessage);
              }
              data = apiResponse.response.data.feed.entitlements;
            }
          }
        } else if (tabType === MY_PROFILE_IDENTIFIERS.INSURANCE) {
          const apiResponse = await apiRequest(GET_ASSETS, REQUEST_TYPE.GET, {});
          if (Object.keys(apiResponse).length) {
            if (apiResponse.response.status) {
              if (
                apiResponse.response.data.feed.entitlements &&
                apiResponse.response.data.feed.entitlements.length > 0
              ) {
                const assets = apiResponse.response.data.feed.entitlements.find((res) => {
                  return res.category_id === 3;
                });
                if (assets) {
                  data = assets.assets.find((details) => {
                    return details.list_id === 370;
                  }).details;
                }
              }
            }
          }
        } else if (tabType === MY_PROFILE_IDENTIFIERS.IDCARD) {
          const apiResponse = await apiRequest(GET_ID_CARD, REQUEST_TYPE.GET, params);
          if (Object.keys(apiResponse).length) {
            if (apiResponse.response) {
              data = apiResponse.response;
            }
          }
        }
        setIsLoading(false);
        setMyProfileData((tabData) => {
          return { ...tabData, ...{ [tabType]: data } };
        });
      } catch (err) {
        handleError(
          err,
          tabType === MY_PROFILE_IDENTIFIERS.IDCARD ? params : {},
          tabType === MY_PROFILE_IDENTIFIERS.ASSETS
            ? GET_ASSETS
            : tabType === MY_PROFILE_IDENTIFIERS.INSURANCE
            ? GET_ASSETS
            : tabType === MY_PROFILE_IDENTIFIERS.IDCARD
            ? GET_ID_CARD
            : '',
          NAVIGATION_ROUTES.MY_PROFILE_DETAIL
        );
        setIsLoading(false);
      }
    },
    [dispatch, employeeData]
  );

  const callWishesApi = useCallback(async () => {
    if (employee_id) {
      setNoMatchFlag(false);
      const formData = new URLSearchParams();
      formData.append('page_id', 1);
      formData.append('employee_id', employee_id);
      try {
        const apiResponse = await apiRequest(GET_MY_WISHES_LIST, REQUEST_TYPE.POST, formData);
        setIsLoading(false);
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status === true) {
            apiResponse.response.data;
            setFilterYears(apiResponse.response.years);
            setWishes(apiResponse.response.data);
          }
          if (apiResponse.response.data.length === 0) {
            setNoMatchFlag(true);
          }
        }
      } catch (err) {
        handleError(err, formData, GET_MY_WISHES_LIST, NAVIGATION_ROUTES.MY_PROFILE_DETAIL);
        setIsLoading(false);
      }
    }
  }, [employee_id]);

  const renderScreen = useCallback(
    async (tabType) => {
      callMyProfileApi(tabType);
    },
    [callMyProfileApi]
  );

  const renderWishes = useCallback(async () => {
    employee_id && callWishesApi();
  }, [callWishesApi, employee_id]);

  const renderTab = (tab) => {
    switch (tab.key) {
      case MY_PROFILE_IDENTIFIERS.PROFILE:
        return <EmployeeProfileDetailScreen />;
      case MY_PROFILE_IDENTIFIERS.ASSETS:
        return (
          <MyAssetsTab
            data={myProfileData[tab.key] ? myProfileData[tab.key] : []}
            getTabData={renderScreen}
            isLoading={isLoading}
            tabKey={tab.key}
            errorMessage={errorMessage}
          />
        );
      case MY_PROFILE_IDENTIFIERS.INSURANCE:
        return <MyInsuranceTab />;
      case MY_PROFILE_IDENTIFIERS.IDCARD:
        return (
          <MyIdCardTab
            data={myProfileData[tab.key] ? myProfileData[tab.key] : {}}
            getTabData={renderScreen}
            isLoading={isLoading}
            tabKey={tab.key}
          />
        );
      case MY_PROFILE_IDENTIFIERS.APP_SETTINGS:
        return <MyAppSettingsTab data={employeeData.preferences} setData={setMyProfileData} />;
      case MY_PROFILE_IDENTIFIERS.WISHES:
        return (
          <MyWishesTab
            filterYears={filterYears}
            data={wishes}
            getTabData={renderWishes}
            isLoading={isLoading}
            noMatchFlag={noMatchFlag}
          />
        );
    }
  };

  return (
    <div className="container">
      <div>
        <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.PROFILE} />
        {employeeData && Object.keys(employeeData).length > 0 && isMounted.current && (
          <NavTabs
            activeKey={myProfileDetailTabData}
            onSelect={(k) => {
              dispatch(saveMyProfileDetailTabData(k));
              history.push({
                pathname: NAVIGATION_ROUTES.MY_PROFILE_DETAIL,
                search: `?tab=${k}`,
              });
            }}
            unmountOnExit>
            {myProfileDetailTabTitles.map((tab, index) => {
              if (
                (tab.key === MY_PROFILE_IDENTIFIERS.INSURANCE && !showInsuranceTab) ||
                (tab.key === MY_PROFILE_IDENTIFIERS.ASSETS && !showAssetsTab) ||
                (tab.key === MY_PROFILE_IDENTIFIERS.IDCARD && !showIdCardTab)
              ) {
                return null;
              }
              return (
                <Tab key={`${index}`} eventKey={tab.key} title={tab.title}>
                  {renderTab(tab, index)}
                </Tab>
              );
            })}
          </NavTabs>
        )}
      </div>
    </div>
  );
};

export default MyProfileDetailScreen;
