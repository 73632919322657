import React, { useEffect, useState, useCallback } from 'react';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { GET_SELECT_DATES, REGISTER_EVENT } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import SelectDateListItem from './components/SelectDateListItem';
import { Modal } from 'react-bootstrap';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../state/UIState';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';

const SelectDateScreen = ({ history, location }) => {
  const dispatch = useDispatch();
  const { initiativeId = '', eventDetail = {} } = location?.state ?? {};
  const [projects, setProjects] = useState([]);
  const [certificate, setCertificate] = useState({});
  const [showRegisterDialog, setShowRegisterDialog] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showEditSuccessModal, setShowEditSuccessModal] = useState(false);
  const [showAddGuestDialog, setShowAddGuestDialog] = useState(false);
  const [guestCount, setGuestCount] = useState(0);
  const [isDecreamentButtonDisabled, setIsDecreamentButtonDisabled] = useState(false);
  const [isEditDecreamentButtonDisabled, setIsEditDecreamentButtonDisabled] = useState(false);
  const [isIncreamentButtonDisabled, setIsIncreamentButtonDisabled] = useState(false);
  const [isEditIncreamentButtonDisabled, setIsEditIncreamentButtonDisabled] = useState(false);
  const [joiningType, setJoiningType] = useState('None');
  const [editJoiningType, setEditJoiningType] = useState('');
  const [showCancelConfirmDialog, setShowCancelConfirmDialog] = useState(false);
  const [itemToCancel, setItemtoCancel] = useState({});

  const [guestData, setGuestData] = useState(guestCount);
  const [itemToRegister, setItemtoRegister] = useState({});
  const [itemToEdit, setItemtoEdit] = useState({});
  const [cancelSuccessData, setCancelSuccessData] = useState({});
  const [editSuccessData, setEditSuccessData] = useState({});
  const [editErrorData, setEditErrorData] = useState('');
  const [registerSuccessData, setRegisterSuccessData] = useState({});
  const [registerErrorData, setRegisterErrorData] = useState('');

  const accessibilityData = useSelector(selectAccessibilityData);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.SELECT_DATE_SCREEN, NAVIGATION_ROUTES.SELECT_DATE_SCREEN);
  }, []);

  const callSelectDateApi = useCallback(async () => {
    dispatch(saveShowEmptyContentView(true));
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(GET_SELECT_DATES + initiativeId, REQUEST_TYPE.GET, {});

      if (Object.keys(apiResponse)?.length > 0) {
        if (apiResponse?.response?.status === true) {
          if (apiResponse?.response?.data?.event?.details?.length > 0) {
            setProjects(apiResponse?.response?.data.event.details);
            setCertificate(apiResponse?.response?.data.event.certificate_image);
          }
        }
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      } else {
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    } catch (err) {
      handleError(err, {}, GET_SELECT_DATES + initiativeId, NAVIGATION_ROUTES.SELECT_DATE_SCREEN);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch, initiativeId]);

  useEffect(() => {
    callSelectDateApi();
  }, [callSelectDateApi]);

  const callAddGuestRegisterApi = async () => {
    const params = new URLSearchParams();
    let initiative_detail_id = itemToRegister?.microgive_initiative_detail_id ?? '';
    params.append('requested_guests', guestCount);
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(
        REGISTER_EVENT + initiative_detail_id + '/register',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setRegisterSuccessData(apiResponse?.response?.data ?? {});
          setShowAddGuestDialog(false);
          callSelectDateApi();
        } else if (apiResponse.response.status === false) {
          setRegisterErrorData(apiResponse?.response?.error);
        }
      }
    } catch (err) {
      handleError(
        err,
        params,
        REGISTER_EVENT + initiative_detail_id + '/register',
        NAVIGATION_ROUTES.SELECT_DATE_SCREEN
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const callRegisterApi = async (initiativeDetailId) => {
    const params = new URLSearchParams();
    params.append('empty', '');
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(
        REGISTER_EVENT + initiativeDetailId + '/register',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setRegisterSuccessData(apiResponse?.response?.data ?? {});
          setShowAddGuestDialog(false);
          callSelectDateApi();
        } else if (apiResponse.response.status === false) {
          setRegisterErrorData(apiResponse?.response?.error);
        }
      }
    } catch (err) {
      handleError(
        err,
        params,
        REGISTER_EVENT + initiativeDetailId + '/register',
        NAVIGATION_ROUTES.SELECT_DATE_SCREEN
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const callEditApi = async () => {
    const params = new URLSearchParams();
    let initiative_detail_id = itemToEdit?.microgive_initiative_detail_id ?? '';
    params.append('requested_guests', guestData);
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(
        REGISTER_EVENT + initiative_detail_id + '/edit',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          callSelectDateApi();
          setShowEditDialog(false);
          setShowEditSuccessModal(true);
          setEditSuccessData(apiResponse?.response ?? {});
        } else if (apiResponse.response.status === false) {
          setEditErrorData(apiResponse?.response?.error);
          setShowEditDialog(false);
          setShowEditSuccessModal(true);
        }
      }
    } catch (err) {
      handleError(
        err,
        params,
        REGISTER_EVENT + initiative_detail_id + '/edit',
        NAVIGATION_ROUTES.SELECT_DATE_SCREEN
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const callCancelApi = async () => {
    const params = new URLSearchParams();
    let initiative_detail_id = itemToCancel?.microgive_initiative_detail_id ?? '';
    params.append('empty', '');
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: 'Cancelling registration...',
        })
      );
      const apiResponse = await apiRequest(
        REGISTER_EVENT + initiative_detail_id + '/withdraw',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isPrsetShowAddGuestDialogogressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setCancelSuccessData(apiResponse?.response ?? {});
          setShowCancelDialog(true);
        }
      }
    } catch (err) {
      handleError(
        err,
        params,
        REGISTER_EVENT + initiative_detail_id + '/withdraw',
        NAVIGATION_ROUTES.SELECT_DATE_SCREEN
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const valueReset = useCallback(() => {
    if (joiningType === 'None' || editJoiningType === 'None') {
      setGuestCount(0);
      setGuestData(0);
    }
  }, [joiningType, editJoiningType]);

  useEffect(() => {
    valueReset();
  }, [valueReset]);

  const onPressRegister = (item) => {
    if (item.is_guests_allowed === 1 && item.max_guest_allowed != 0) {
      setShowAddGuestDialog(true);
      setItemtoRegister(item);
      setIsIncreamentButtonDisabled(false);
    } else {
      callRegisterApi(item.microgive_initiative_detail_id);
      setShowRegisterDialog(true);
    }
  };

  const onPressConfirmCancel = async () => {
    setShowCancelConfirmDialog(false);
    await callCancelApi();
    setGuestCount(0);
  };

  const onEnableConfirmCancel = (item) => {
    setItemtoCancel(item);
    setShowCancelConfirmDialog(true);
  };

  const onPressCancel = () => {
    setShowCancelConfirmDialog(true);
  };

  const onPressEdit = (item) => {
    setShowEditDialog(true);
    setGuestData(item.confirmed_guests);
  };

  const onPressDecreament = () => {
    if (guestCount === 0) return;
    setGuestCount(guestCount - 1);
  };

  const onPressEditDecreament = () => {
    if (guestData === 0) return;
    setGuestData(guestData - 1);
  };

  useEffect(() => {
    if (itemToRegister?.show_waitlist && guestCount === itemToRegister?.max_guest_allowed) {
      setIsIncreamentButtonDisabled(true);
      setIsDecreamentButtonDisabled(false);
    } else if (
      (guestCount === itemToRegister?.max_guest_allowed ||
        guestCount + (itemToRegister?.participants_count + 1) >=
          itemToRegister?.max_registrations_allowed) &&
      itemToRegister?.show_waitlist === false
    ) {
      setIsIncreamentButtonDisabled(true);
      setIsDecreamentButtonDisabled(false);
    } else if (guestCount === 0 || guestCount === 1) {
      setIsIncreamentButtonDisabled(false);
      setIsDecreamentButtonDisabled(true);
    } else {
      setIsIncreamentButtonDisabled(false);
      setIsDecreamentButtonDisabled(false);
    }
  }, [guestCount, itemToRegister]);

  useEffect(() => {
    let totalAvailableSeats = itemToEdit.max_registrations_allowed - itemToEdit.participants_count;
    let delta = Math.abs(itemToEdit.confirmed_guests - guestData);
    if (
      guestData === itemToEdit?.max_guest_allowed ||
      ((itemToEdit.confirmed_guests > guestData
        ? delta > totalAvailableSeats + itemToEdit.confirmed_guests
        : delta >= totalAvailableSeats) &&
        itemToEdit.is_waitlist === false)
    ) {
      setIsEditIncreamentButtonDisabled(true);
      setIsEditDecreamentButtonDisabled(false);
    } else if (guestData === 0 || guestData === 1) {
      setIsEditIncreamentButtonDisabled(false);
      setIsEditDecreamentButtonDisabled(true);
    } else {
      setIsEditIncreamentButtonDisabled(false);
      setIsEditDecreamentButtonDisabled(false);
    }
  }, [guestData, itemToEdit]);

  useEffect(() => {
    if (
      itemToRegister.participants_count + 1 === itemToRegister.max_registrations_allowed &&
      itemToRegister?.show_waitlist === false
    ) {
      setIsIncreamentButtonDisabled(true);
      setIsDecreamentButtonDisabled(true);
      setGuestCount(0);
    }
  }, [itemToRegister]);

  const onPressIncreament = () => {
    setGuestCount(guestCount + 1);
  };

  const onPressEditIncreament = () => {
    setGuestData(guestData + 1);
  };

  const onPressEditCancel = () => {
    setShowEditDialog(false);
  };

  const onPressEditConfirm = () => {
    callEditApi();
  };

  const onPressAddguestCancel = () => {
    setGuestCount(0);
    setShowAddGuestDialog(false);
  };

  const onPressHome = () => {
    setShowRegisterDialog(false);
    setShowCancelDialog(false);
    history.goBack();
  };

  const onPressCancelRegister = () => {
    setShowRegisterDialog(false);
    setShowCancelDialog(false);
    callSelectDateApi();
    setGuestCount(0);
  };

  const onPressEditModalCancel = () => {
    setShowEditSuccessModal(false);
  };

  const onPressAddguestConfirm = async () => {
    await callAddGuestRegisterApi();
    setShowAddGuestDialog(false);
    setShowRegisterDialog(true);
  };

  const addGuestDialog = () => {
    return (
      <div>
        <div className="microgive-edit-dialog d-flex flex-column">
          <div className="microgive-edit-header-text d-flex flex-row justify-content-center">
            Anyone joining you?
          </div>
          <div className="form-check mt-5 ">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexCheckDefault2"
              onChange={() => setJoiningType('None')}
              checked={joiningType === 'None'}
            />
            <label className="form-check-label ml-2 active" htmlFor="flexRadioDefault1">
              No one is joining
            </label>
          </div>
          <div className="form-check mt-3 d-flex flex-row">
            <div className="d-flex flex-row justify-content-between flex-fill pr-3">
              <div>
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault"
                  onChange={() => {
                    setJoiningType(''), setGuestCount(1);
                  }}
                  checked={joiningType === ''}
                  disabled={
                    itemToRegister.participants_count + 1 >=
                      itemToRegister.max_registrations_allowed &&
                    itemToRegister?.show_waitlist === false
                  }
                />
                <label className="form-check-label ml-2 active" htmlFor="flexRadioDefault1">
                  Guests joining
                </label>
              </div>
              <div className="d-flex flex-row">
                <button
                  className="microgive-addguest-decreament justify-content-center d-flex flex-row"
                  onClick={onPressDecreament}
                  disabled={isDecreamentButtonDisabled || joiningType === 'None'}>
                  -
                </button>
                <div className="ml-3 mr-3 pt-1">{guestCount}</div>
                <button
                  className={
                    !isIncreamentButtonDisabled
                      ? 'microgive-addguest-increament justify-content-center d-flex flex-row'
                      : 'microgive-addguest-disabled-increament justify-content-center d-flex flex-row'
                  }
                  onClick={onPressIncreament}
                  disabled={joiningType === 'None' || isIncreamentButtonDisabled}>
                  +
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row mt-5 justify-content-center">
            <CustomButton
              buttonStyle="microgive-edit-sub-button-style mt-1"
              onClick={onPressAddguestCancel}>
              Cancel
            </CustomButton>
            <CustomButton
              buttonStyle="microgive-edit-sub-button-style mt-1 ml-5"
              onClick={onPressAddguestConfirm}>
              Confirm
            </CustomButton>
          </div>
        </div>
      </div>
    );
  };

  const editDialog = () => {
    return (
      <div>
        <div className="microgive-edit-dialog d-flex flex-column">
          <div className="microgive-edit-header-text d-flex flex-row justify-content-center">
            Anyone joining you?
          </div>
          <div className="form-check mt-5 ">
            <input
              className="form-check-input"
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              onChange={() => setEditJoiningType('None')}
              checked={editJoiningType === 'None'}
            />
            <label className="form-check-label ml-2 active" htmlFor="flexRadioDefault1">
              No one is joining
            </label>
          </div>
          <div className="form-check mt-3 d-flex">
            <div className="d-flex flex-row justify-content-between flex-fill pr-3">
              <div>
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault"
                  onClick={() => {
                    setGuestData(itemToEdit?.confirmed_guests);
                  }}
                  onInput={() => {
                    setGuestData(itemToEdit?.confirmed_guests);
                  }}
                  onChange={() => {
                    setEditJoiningType('');
                  }}
                  checked={editJoiningType === ''}
                />
                <label className="form-check-label ml-2" htmlFor="flexRadioDefault1">
                  Guests joining
                </label>
              </div>
              <div className="d-flex flex-row">
                <button
                  className="microgive-addguest-decreament justify-content-center d-flex flex-row"
                  onClick={onPressEditDecreament}
                  disabled={isEditDecreamentButtonDisabled || editJoiningType === 'None'}>
                  -
                </button>
                <div className="ml-3 mr-3">{guestData}</div>
                <button
                  className={
                    !isEditIncreamentButtonDisabled
                      ? 'microgive-addguest-increament justify-content-center d-flex flex-row'
                      : 'microgive-addguest-disabled-increament justify-content-center d-flex flex-row'
                  }
                  onClick={onPressEditIncreament}
                  disabled={editJoiningType === 'None' || isEditIncreamentButtonDisabled}>
                  +
                </button>
              </div>
            </div>
          </div>
          <div className="d-flex flex-row mt-5 justify-content-center">
            <CustomButton
              buttonStyle="microgive-edit-sub-button-style mt-1"
              onClick={onPressEditCancel}>
              Cancel
            </CustomButton>
            <CustomButton
              buttonStyle="microgive-edit-sub-button-style mt-1 ml-5"
              onClick={() => onPressEditConfirm(itemToEdit)}>
              Confirm
            </CustomButton>
          </div>
        </div>
      </div>
    );
  };

  const cancelConfirmationDialog = () => {
    return (
      <div>
        <div className="microgive-cancel-confirmation-dialog">
          <div className="d-flex flex-row align-items-center justify-content-center">
            <div className="confirm-cancel-header-text">Are you sure you want to cancel?</div>
          </div>
          <div className="yes-close-button-container d-flex flex-row">
            <div
              className="yes-close-button d-flex flex-row align-items-center justify-content-center"
              onClick={() => onPressConfirmCancel(itemToCancel)}>
              Yes
            </div>
            <div
              className="yes-close-button d-flex flex-row align-items-center justify-content-center"
              onClick={() => setShowCancelConfirmDialog(false)}>
              Close
            </div>
          </div>
        </div>
      </div>
    );
  };

  const registerDialog = () => {
    return (
      <div>
        <div className="d-flex flex-row-reverse">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_CLOSE_EVENT_DIALOG,
              ACCESSIBILITY_IDENTIFIERS.MICROGIVE
            )}
            className="microgive-header-icon common-cursor-pointer"
            onClick={() => setShowRegisterDialog(false)}
          />
        </div>
        <div className="text-center mt-3 microgive-register-message-text">
          {registerSuccessData?.message ?? registerErrorData}
        </div>
        <CustomButton buttonStyle="microgive-button-style mt-3" onClick={onPressHome}>
          Home
        </CustomButton>
      </div>
    );
  };

  const cancelDialog = () => {
    return (
      <div>
        <div className="d-flex flex-row-reverse">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_CLOSE_EVENT_DIALOG,
              ACCESSIBILITY_IDENTIFIERS.MICROGIVE
            )}
            className="microgive-header-icon common-cursor-pointer"
            onClick={onPressCancelRegister}
          />
        </div>
        <div className="text-center mt-3 microgive-registerion-cancel-text">
          {cancelSuccessData?.message}
        </div>
        <CustomButton buttonStyle="microgive-button-style mt-3" onClick={onPressHome}>
          Home
        </CustomButton>
      </div>
    );
  };

  const editSuccessModal = () => {
    return (
      <div>
        <div className="d-flex flex-row-reverse">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_CLOSE_EVENT_DIALOG,
              ACCESSIBILITY_IDENTIFIERS.MICROGIVE
            )}
            className="microgive-header-icon common-cursor-pointer"
            onClick={onPressEditModalCancel}
          />
        </div>
        <div className="text-center mt-3 microgive-registerion-cancel-text">
          {editSuccessData?.message ?? editErrorData}
        </div>
        <CustomButton buttonStyle="microgive-button-style mt-3" onClick={onPressHome}>
          Home
        </CustomButton>
      </div>
    );
  };

  const onPressUpdates = (item) => {
    history.push(NAVIGATION_ROUTES.UPDATES_SCREEN, {
      initiativeId: initiativeId,
      initiativeDetailId: item.microgive_initiative_detail_id,
    });
  };

  const onPressParticipants = (item) => {
    history.push(NAVIGATION_ROUTES.VOLUNTEER_PARTICIPATES_SCREEN, {
      microgiveInitiativeDetailId: item.microgive_initiative_detail_id,
    });
  };

  const onPressMapLink = (mapLink) => {
    if ((mapLink !== '' && mapLink.startsWith('http')) || mapLink.startsWith('https')) {
      let win = window.open(mapLink, '_blank');
      win.focus();
    }
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.SEARCH_NO_RESULTS} />;
  };

  const onEnableConfirmEdit = (item) => {
    setItemtoEdit(item);
    setGuestData(item?.confirmed_guests);
    setShowEditDialog(true);
  };

  const onEnableRegister = (item) => {
    setItemtoRegister(item);
    setGuestCount(0);
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICROGIVE} />
      <BackButton />
      <div className="main-content-container">
        <Modal
          show={showRegisterDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => setShowRegisterDialog(false)}>
          <Modal.Body>{registerDialog()}</Modal.Body>
        </Modal>
        <Modal
          show={showCancelDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={(() => setShowCancelDialog(false), onPressCancelRegister)}>
          <Modal.Body>{cancelDialog()}</Modal.Body>
        </Modal>
        <Modal
          show={showEditSuccessModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => setShowEditSuccessModal(false)}>
          <Modal.Body>{editSuccessModal()}</Modal.Body>
        </Modal>
        <Modal
          contentClassName="custom-modal"
          show={showEditDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => setShowEditDialog(false)}>
          <Modal.Body>{editDialog()}</Modal.Body>
        </Modal>
        <Modal
          contentClassName="custom-modal"
          show={showCancelConfirmDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => setShowCancelConfirmDialog(false)}>
          <Modal.Body>{cancelConfirmationDialog()}</Modal.Body>
        </Modal>
        <Modal
          contentClassName="custom-modal"
          show={showAddGuestDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => setShowEditDialog(false)}>
          <Modal.Body>{addGuestDialog()}</Modal.Body>
        </Modal>
        {showEmptyContentView && !projects.length && !progressLoadingState.isProgressLoading ? (
          emptyListView()
        ) : (
          <div>
            {projects.map((item, index) => {
              return (
                <SelectDateListItem
                  key={`${index}`}
                  index={index}
                  item={item}
                  onPressParticipants={onPressParticipants}
                  onPressRegister={onPressRegister}
                  onPressUpdates={onPressUpdates}
                  onPressCancel={onPressCancel}
                  onPressMapLink={onPressMapLink}
                  onPressEdit={onPressEdit}
                  onPressAddguestConfirm={onPressAddguestConfirm}
                  certificate={certificate}
                  onEnableConfirmEdit={onEnableConfirmEdit}
                  onPressEditConfirm={onPressEditConfirm}
                  onEnableRegister={onEnableRegister}
                  onEnableConfirmCancel={onEnableConfirmCancel}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectDateScreen;
