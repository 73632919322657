import React from 'react';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ACCESSIBILITY_FEATURE_COMPONENTS,
} from '../../../common/constants/AppConstants';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';

const InfoVideoItem = ({ item, onPress }) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  return (
    <div
      onClick={() => {
        onPress(item);
      }}
      className="d-flex align-items-center mb-3 common-cursor-pointer">
      {!!item.url && (
        <img
          src={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_VIDEO_PLAY_BUTTON,
            ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
          )}
          className="breakout-video-button-image mr-2 ml-1"
          onClick={() => {
            onPress(item);
          }}
        />
      )}
      <p className="new-joinee-media-link mt-1 mb-0">{item.label}</p>
    </div>
  );
};
export default InfoVideoItem;
