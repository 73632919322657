import React, { useState } from 'react';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  FEATURE_TYPE,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../services/Service';
import { COMMENT_BY_ID } from '../../services/ApiUrls';
import { isEmptyText, showToast } from '../../utils/common';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { useDispatch, useSelector } from 'react-redux';
import { logEvent } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { EditorState } from 'draft-js';
import {
  extractDataFromEditorState,
  getCommentMentionsState,
} from '../../common/components/hashtagsAndMentionsInput/hashtagUtils';
import CustomCommentBox from '../../common/ui/comment_box/CustomCommentBox';

const CeoChatAddCommentScreen = ({ location, history }) => {
  const question = location.state.question;
  const dispatch = useDispatch();
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [mentions, setMentions] = useState([]);

  const getCommentTextFunction = async () => {
    const { description, addedMentions } = extractDataFromEditorState(editorState);
    let tempDes = description;
    tempDes = tempDes.replace(/ {2,}(?![\n])/g, ' ');

    addedMentions.map((user) => {
      tempDes = tempDes.replace(user.name, `<rsu>{{${user.employee_id}}}</rsu> `);
    });

    mentions.map((user) => {
      tempDes = tempDes.replace(user.name, `<rsu>{{${user.employee_id}}}</rsu> `);
    });

    if (!isEmptyText(tempDes)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      const formData = new URLSearchParams();
      formData.append('type', FEATURE_TYPE.CEO_CHAT);
      formData.append('comment', tempDes);
      try {
        logEvent(
          ANALYTICS_EVENT_TYPES.CEO_QUESTION_COMMENT,
          question.question_id,
          ANALYTICS_ITEM_NAMES.COMMENT
        );
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        const apiResponse = await apiRequest(
          COMMENT_BY_ID + question.question_id,
          REQUEST_TYPE.POST,
          formData
        );
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            setEditorState(EditorState.createEmpty());
            if (apiResponse.response) {
              history.goBack();
            }
          }
        }
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      } catch (err) {
        handleError(
          err,
          formData,
          COMMENT_BY_ID + question.question_id,
          NAVIGATION_ROUTES.CEO_CHAT
        );
        console.error(err);
      }
    }
  };

  const onMention = (mention) => {
    setMentions((prev) => [...prev, mention]);
  };

  const getCommentText = (val) => {
    const { description, addedMentions } = extractDataFromEditorState(editorState);
    const editorState2 = getCommentMentionsState(description + val, [
      ...addedMentions,
      ...mentions,
    ]);
    setEditorState(editorState2);
  };

  return (
    <div className="main-content-container container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CEO_CHAT} />
      <BackButton containerStyle="pt-3" />
      <CustomCommentBox
        getCommentText={getCommentTextFunction}
        onChangehandler={getCommentText}
        showLoader={progressLoadingState.isProgressLoading}
        setEditorState={setEditorState}
        editorState={editorState}
        enableUserMentions={true}
        enableHashtags={true}
        onMention={onMention}
      />
    </div>
  );
};

export default CeoChatAddCommentScreen;
