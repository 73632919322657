import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { saveProgressLoadingState } from '../../../../state/UIState';
import { apiRequest } from '../../../../services/Service';
import { NEO_RSVP } from '../../../../services/ApiUrls';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import {
  convertISTDateToLocalFormat,
  formatDateDDMonthYYYY,
  formatHHMMSSTimeLocalAMPM,
  showToast,
} from '../../../../utils/common';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const NeoDashboardComponet = ({ item }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [rsvpData, setRsvpData] = useState();

  const callNeoRsvp = useCallback(
    async (response) => {
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
          })
        );
        const formData = new URLSearchParams();
        formData.append('neo_id', item?.neo_id);
        formData.append('response', response);
        const apiResponse = await apiRequest(NEO_RSVP, REQUEST_TYPE.POST, formData);
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
        if (apiResponse.response.status) {
          setRsvpData(apiResponse.response.data);
          showToast(apiResponse.response.message);
        } else {
          showToast(apiResponse.response.error);
        }
      } catch (e) {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
        console.error(e);
        showToast(e);
      }
    },
    [dispatch, item?.neo_id]
  );

  return (
    <div className="w-100 mt-2">
      <div className="d-flex flex-row mt-1">
        <div
          className="common-cursor-pointer"
          style={{ width: '40%' }}
          onClick={() =>
            history.push(`${NAVIGATION_ROUTES.NEO_SESSION_DETAIL}/${item?.neo_id}`, {
              neo_id: item.neo_id,
              backTitle: `SESSION SCHEDULE: ${convertISTDateToLocalFormat(
                item?.date
              ).toUpperCase()}`,
            })
          }>
          <div className="neo-dashboard-title mb-3">
            {' '}
            SESSION SCHEDULE: {convertISTDateToLocalFormat(item?.date)}
          </div>
          <div className="d-flex flex-row pb-1">
            <div className="neo-db-sub-title mr-1">Timings:</div>
            <div className="neo-db-text">{`${formatHHMMSSTimeLocalAMPM(
              item?.start_time
            )} to ${formatHHMMSSTimeLocalAMPM(item?.end_time)}`}</div>
          </div>
          <div className="d-flex flex-row mb-3">
            <div className="neo-db-sub-title mr-1">Location:</div>
            <div className="neo-db-text">{item?.location}</div>
          </div>
        </div>
        <div className="neo-vertical-divider mx-4" />
        <div style={{ width: '50%' }} className="d-flex justify-content-center">
          <div>
            <div className="mr-2 neo-detail-confirm-text d-flex align-self-center">
              Please confirm your presence for this session.
            </div>
            <div className="d-flex justify-content-center align-items-center w-100">
              <div className="align-self-center mt-2">
                <div className="d-flex flex-row">
                  <div
                    onClick={() => {
                      logEvent(
                        ANALYTICS_EVENT_TYPES.NEO_RSVP_YES,
                        '',
                        ANALYTICS_ITEM_NAMES.NEW_JOINEES_HIGHER_LEVELS
                      );
                      callNeoRsvp(1);
                    }}
                    className="mr-2 neo-detail-yes-btn d-flex align-items-center justify-content-center common-cursor-pointer">
                    YES
                  </div>
                  <div
                    onClick={() => {
                      logEvent(
                        ANALYTICS_EVENT_TYPES.NEO_RSVP_NO,
                        '',
                        ANALYTICS_ITEM_NAMES.NEW_JOINEES_HIGHER_LEVELS
                      );
                      callNeoRsvp(0);
                    }}
                    className="d-flex align-items-center justify-content-center neo-detail-no-btn common-cursor-pointer">
                    NO
                  </div>
                </div>
                {item?.response || rsvpData?.response ? (
                  <div className="d-flex flex-row mt-1 w-100">
                    <div className="neo-rsvp-text mr-1"> Latest Status:</div>
                    {(rsvpData?.response || item?.response) === 'Confirmed' ? (
                      <div className="neo-rsvp-confirm-text mr-1">
                        {rsvpData?.response || item?.response}
                      </div>
                    ) : (
                      <div className="neo-rsvp-reject-text mr-1">
                        {rsvpData?.response || item?.response}
                      </div>
                    )}
                    <div className="neo-rsvp-text mr-1">
                      on{' '}
                      {formatDateDDMonthYYYY(
                        rsvpData?.response_registered_on || item?.response_registered_on
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NeoDashboardComponet;
