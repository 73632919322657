import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoaderState, saveProgressLoadingState } from '../../state/UIState';
import { apiRequest, apiRequestEStore } from '../../services/Service';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  BUTTON_TITLES,
  MODAL_TITLES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { useHistory } from 'react-router';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { formatDateDDMonthYYYY, getOrderId } from '../../utils/common';
import CustomTracker from './components/CustomTracker';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { Modal } from 'react-bootstrap';
import CustomModal from '../../common/ui/custom_modal/CustomModal';
import { selectEStoreConfig } from '../../state/MasterData';
import DeliveryNote from './components/DeliveryNote';
import Collections from './components/Collections';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { CANCEL_ORDER, ORDER_INVOICE, SHIPMENTS } from '../../services/ApiUrls';
import HtmlView from '../../common/ui/html_view/HTMLView';

const ShipmentDetailsScreen = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const accessibilityData = useSelector(selectAccessibilityData);
  const { orderDetails, index, images } = location.state;
  const [currentTrackStep, setCurrentTrackStep] = useState(0);
  const [orderedOn] = useState(formatDateDDMonthYYYY(orderDetails.items[index].created_at));
  const [shippedOn, setShippedOn] = useState('Pending');
  const [deliveredOn, setDeliveredOn] = useState('Pending');
  const [canceledOn, setCanceledOn] = useState('Pending');
  const [returnInitiatedOn, setReturnInitiatedOn] = useState('Pending');
  const [labels, setLabels] = useState(['Ordered', 'Shipped', 'Delivered']);
  const [showCancel, setShowCancel] = useState(false);
  const [showConformationDialog, setShowConformationDialog] = useState(false);
  const [steps, setSteps] = useState([]);
  const [isInvoiced, setIsInvoiced] = useState(false);
  const estoreConfig = useSelector(selectEStoreConfig);
  const [showDeliveryNote, setShowDeliveryNote] = useState(false);
  const shippingAddress =
    orderDetails.extension_attributes?.shipping_assignments[0]?.shipping?.address;

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.MICRO_STORE_SHIPMENT_SCREEN,
      NAVIGATION_ROUTES.MI_STORE_SHIPMENT_DETAILS
    );
  }, []);

  useEffect(() => {
    setShowDeliveryNote(
      orderDetails?.extension_attributes?.shipping_assignments[0]?.shipping.method ===
        'tablerate_bestway'
    );
  }, [orderDetails?.extension_attributes?.shipping_assignments]);

  useEffect(() => {
    setIsInvoiced(
      orderDetails.status != 'closed' &&
        orderDetails.status != 'canceled' &&
        orderDetails.items[index]?.qty_invoiced > 0
    );
  }, [index, orderDetails.items, orderDetails.status]);

  useEffect(() => {
    const tempSteps = [];
    labels.map((label) => {
      if (label === 'Ordered') {
        tempSteps.push({
          label: label,
          description: orderedOn,
        });
      } else if (label === 'Shipped') {
        tempSteps.push({
          label: label,
          description: shippedOn,
        });
      } else if (label === 'Delivered') {
        tempSteps.push({
          label: label,
          description: deliveredOn,
        });
      } else if (label === 'Canceled') {
        tempSteps.push({
          label: label,
          description: canceledOn,
        });
      } else if (label === 'Return Initiated') {
        tempSteps.push({
          label: label,
          description: returnInitiatedOn,
        });
      } else if (label === 'Refunded') {
        tempSteps.push({
          label: label,
          description: 'Pending',
        });
      }
    });
    setSteps(tempSteps);
  }, [labels, orderedOn, returnInitiatedOn, canceledOn, shippedOn, deliveredOn]);

  const getImage = (sku) => {
    const image = images.find((img) => img.sku === sku);
    return image?.image ?? '';
  };

  const getSize = (sku) => {
    const image = images.find((img) => img.sku === sku);
    return image.size;
  };

  const getShortDescription = (sku) => {
    const item = images.find((img) => img.sku === sku);
    return item?.shortDescription ?? '';
  };

  const fetchShipment = useCallback(async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const apiResponse = await apiRequestEStore(
        `${SHIPMENTS}?searchCriteria[filterGroups][0][filters][0][field]=order_id&searchCriteria[filterGroups][0][filters][0][value]=${orderDetails.items[index].order_id}&searchCriteria[filterGroups][0][filters][0][conditionType]=eq&searchCriteria[pageSize]=10&searchCriteria[currentPage]=1`,
        REQUEST_TYPE.GET
      );
      if (Object.keys(apiResponse.response).length) {
        const items = apiResponse.response.items;
        const tracks = [];
        if (items.length > 0) {
          items[0]?.tracks?.map((track) => {
            const title = track.title.toLowerCase();
            if (title === 'delivered') {
              setDeliveredOn(formatDateDDMonthYYYY(track.updated_at));
            } else if (title === 'shipped') {
              setShippedOn(formatDateDDMonthYYYY(track.updated_at));
            }
            tracks.push(title);
          });
          if (tracks.includes('delivered')) {
            setCurrentTrackStep(2);
          } else if (tracks.includes('shipped')) {
            setCurrentTrackStep(1);
          }
        }
        if (tracks.includes('delivered') && orderDetails.status === 'closed') {
          setLabels(['Ordered', 'Shipped', 'Delivered', 'Return Initiated', 'Refunded']);
          setCurrentTrackStep(3);
          const returnInitiated = orderDetails.status_histories.find(
            (item) => item.entity_name === 'creditmemo'
          );
          setReturnInitiatedOn(formatDateDDMonthYYYY(returnInitiated.created_at));
        } else if (tracks.includes('shipped') && orderDetails.status === 'canceled') {
          setLabels(['Ordered', 'Shipped', 'Canceled']);
          setCurrentTrackStep(2);
          setCanceledOn(formatDateDDMonthYYYY(orderDetails.updated_at));
        } else if (orderDetails.status === 'canceled') {
          setLabels(['Ordered', 'Canceled']);
          setCurrentTrackStep(1);
          setCanceledOn(formatDateDDMonthYYYY(orderDetails.updated_at));
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [
    dispatch,
    index,
    orderDetails.items,
    orderDetails.status,
    orderDetails.status_histories,
    orderDetails.updated_at,
  ]);

  const cancelOrder = useCallback(async () => {
    setShowCancel(false);
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    logEvent(
      ANALYTICS_EVENT_TYPES.ESTORE_SHIPMENT_DETAIL_CANCLE_ORDER_CLICK,
      orderDetails.items[index].order_id,
      ANALYTICS_ITEM_NAMES.SHIPMENT_SCREEN_CANCEL_ORDER_CLICKED
    );
    try {
      const body = {
        appendComment: false,
      };
      const apiResponse = await apiRequestEStore(
        CANCEL_ORDER(orderDetails.items[index].order_id),
        REQUEST_TYPE.POST,
        body
      );
      if (apiResponse.response) {
        setShowConformationDialog(true);
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, index, orderDetails.items]);

  useEffect(() => {
    fetchShipment();
  }, [fetchShipment]);

  const handleOrder = () => {
    const image = images.find((img) => img.sku === orderDetails.items[index].sku);
    const parentSku = image.parentSku;
    logEvent(ANALYTICS_EVENT_TYPES.ESTORE_PRODUCT_CLICK, parentSku, ANALYTICS_ITEM_NAMES.PRODUCT);
    history.push(`${NAVIGATION_ROUTES.MICRO_STORE_PRODUCT_SCREEN}/${parentSku}`);
  };

  const viewOrderDetails = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ESTORE_SHIPMENT_DETAIL_VIEW_ORDER_CLICK,
      '',
      ANALYTICS_ITEM_NAMES.SHIPMENT_SCREEN_VIEW_ORDER_CLICKED
    );
    history.push(NAVIGATION_ROUTES.MI_STORE_ORDER_DETAILS, { orderDetails, images });
  };

  const handleDownloadInvoice = async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      logEvent(
        ANALYTICS_EVENT_TYPES.ESTORE_SHIPMENT_DETAIL_DOWNLOAD_INVOICE_CLICK,
        orderDetails.items[index].order_id,
        ANALYTICS_ITEM_NAMES.SHIPMENT_SCREEN_DOWNLOAD_INVOICE_CLICKED
      );
      const apiResponse = await apiRequest(
        `${ORDER_INVOICE}?order_id=${orderDetails.items[index].order_id}`,
        REQUEST_TYPE.GET
      );

      if (Object.keys(apiResponse).length && apiResponse.response?.status) {
        history.push(NAVIGATION_ROUTES.MI_STORE_INVOICE_SCREEN, {
          encodedUrl: apiResponse.response.data.invoice_pdf,
        });
      }
      dispatch(resetLoaderState());
    } catch (err) {
      dispatch(resetLoaderState());
    }
  };

  const continueShopping = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ESTORE_SHIPMENT_DETAIL_CONTINUE_SHOPING_CLICK,
      '',
      ANALYTICS_ITEM_NAMES.SHIPMENT_SCREEN_CONTINUE_SHOPING_CLICKED
    );
    history.goBack();
  };

  const conformationDialog = () => {
    return (
      <div className="p-4 d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex flex-row">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.STORE_ORDER_SUCCESS,
              ACCESSIBILITY_IDENTIFIERS.MICRO_STORE
            )}
            className="estore-order-success-icon"
          />
          <div className="store-order-success-message">Order cancellation initiated!</div>
        </div>
        <div>Confirmation will be sent to your email.</div>
        <div onClick={continueShopping} className="store-continue-shopping mt-4">
          CONTINUE SHOPPING
        </div>
      </div>
    );
  };

  const handleCancelAlert = (status) => {
    if (status) {
      logEvent(
        ANALYTICS_EVENT_TYPES.ESTORE_SHIPMENT_CANCEL,
        '',
        ANALYTICS_ITEM_NAMES.SHIPMENT_SCREEN_REQUEST_CANCEL_ORDER_CLICKED
      );
    } else {
      logEvent(
        ANALYTICS_EVENT_TYPES.ESTORE_SHIPMENT_CANCEL_NO,
        '',
        ANALYTICS_ITEM_NAMES.SHIPMENT_SCREEN_REQUEST_CANCEL_ORDER_CLICKED
      );
    }
    setShowCancel(status);
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICRO_STORE} />
      <BackButton backTitle="ORDER TRACKING" />
      <CustomModal
        visible={showCancel}
        onClose={() => handleCancelAlert(false)}
        description={estoreConfig.web_modal_messages.cancel_order}
        title={MODAL_TITLES.CONFORMATION}
        secondaryButtonTitle={BUTTON_TITLES.NO}
        primaryButtonTitle={BUTTON_TITLES.YES}
        onClickSecondaryButton={() => handleCancelAlert(false)}
        onClickPrimaryButton={() => cancelOrder()}
      />
      <Modal
        contentClassName="feedback-success-modal mx-auto"
        show={showConformationDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body>{conformationDialog()}</Modal.Body>
      </Modal>
      <div className="e-store-product-list-main">
        <div className="shipment-details-main-container">
          <div className="estore-home-item-image">
            <div className="estore-order-history-container p-2">
              <div className="order-item-image-block common-cursor-pointer" onClick={handleOrder}>
                <img
                  src={getImage(orderDetails.items[index].sku)}
                  className="order-item-image-block"
                />
              </div>
              <div className="w-100">
                <div className="pb-2">
                  <div className="order-item-name-arrow">
                    <div className="estore-bold-text">{orderDetails.items[index].name}</div>
                    <div className="estore-regular-text">{`Order No. ${getOrderId(
                      orderDetails.items[index].order_id
                    )}`}</div>
                  </div>
                  <div className="d-flex flex-row justify-content-between">
                    <HtmlView
                      html={getShortDescription(orderDetails.items[index].sku)}
                      htmlStyle="estore-regular-text"
                    />
                    <div className="estore-regular-text">{`Ordered on ${orderedOn}`}</div>
                  </div>

                  <div className="estore-regular-text margin-bottom-3">{`Qty: ${orderDetails.items[index].qty_ordered}`}</div>
                  {getSize(orderDetails.items[index].sku) && (
                    <div className="estore-regular-text margin-bottom-3">{`Size: ${getSize(
                      orderDetails.items[index].sku
                    )}`}</div>
                  )}
                  {orderDetails.status.length > 0 && (
                    <div className="estore-regular-text margin-bottom-3 mb-1">
                      {orderDetails.status === 'canceled'
                        ? 'Cancelled'
                        : orderDetails.status[0].toUpperCase() + orderDetails.status.substring(1)}
                    </div>
                  )}
                  {/* <div className="estore-regular-text pb-2">Size: Onesize</div> */}
                  <div className="estore-bold-text pt-1">{`₹ ${
                    orderDetails.items[index].price * orderDetails.items[index].qty_ordered
                  }`}</div>
                  {(orderDetails.status === 'pending' || orderDetails.status === 'processing') && (
                    <div className="estore-regular-text pt-2">
                      {showDeliveryNote
                        ? estoreConfig.estimated_delivery_banglore
                        : estoreConfig.estimated_delivery}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <table className="estore-shipping-details-container">
              <tr>
                <td className="estore-shipping-details-header table-right-border">
                  <div className="estore-bold-text">SHIPMENT TRACKING</div>
                </td>
                <td className="estore-shipping-details-header">
                  <div className="estore-bold-text">BILLING ADDRESS</div>
                </td>
              </tr>
              <tr>
                <td className="estore-shipping-details-block table-right-border">
                  <div className="estore-shipping-details">
                    <CustomTracker activeStep={currentTrackStep} steps={steps} />
                  </div>
                </td>
                <td className="estore-shipping-details-block">
                  <div>
                    <div className="estore-shipping-details">
                      <div className="d-flex estore-bold-text">
                        {orderDetails.billing_address.firstname}{' '}
                        {orderDetails.billing_address.lastname}
                      </div>
                      <div className="estore-regular-text">
                        {orderDetails.billing_address.street.join(', ')}
                      </div>
                      <div className="estore-regular-text">
                        {orderDetails.billing_address.city} -{' '}
                        {orderDetails.billing_address.postcode}
                      </div>
                      <div className="estore-regular-text">
                        {orderDetails.billing_address.region}
                      </div>
                      <div className="d-flex estore-regular-text">
                        Mobile:
                        <div className="ml-1 estore-bold-text">
                          {orderDetails.billing_address.telephone}
                        </div>
                      </div>
                    </div>
                    <div className="estore-shipping-details">
                      <div className="estore-bold-text delivery-estimate-header pb-2 mb-2">
                        SHIPPING ADDRESS
                      </div>
                      <div>
                        <div className="d-flex estore-bold-text">
                          {shippingAddress.firstname} {shippingAddress.lastname}
                        </div>
                        <div className="estore-regular-text">
                          {shippingAddress.street.join(', ')}
                        </div>
                        <div className="estore-regular-text">
                          {shippingAddress.city} - {shippingAddress.postcode}
                        </div>
                        <div className="estore-regular-text">{shippingAddress.region}</div>
                        <div className="d-flex estore-regular-text">
                          Mobile:
                          <div className="ml-1 estore-bold-text">{shippingAddress.telephone}</div>
                        </div>
                      </div>
                    </div>
                    {/* {showDeliveryNote && <DeliveryNote />} */}
                  </div>
                </td>
              </tr>
            </table>
            <div className="shipment-details-bottom-container">
              {orderDetails.status === 'pending' && currentTrackStep !== 2 && (
                <div
                  onClick={() => handleCancelAlert(true)}
                  className="shipment-details-bottom-item store-right-border">
                  <div>Request for order cancellation</div>
                  <img
                    src={getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_RIGHT_ARROW,
                      ACCESSIBILITY_IDENTIFIERS.COMMON
                    )}
                    className="shipment-details-bottom-item-arrow"
                  />
                </div>
              )}
              <div
                onClick={viewOrderDetails}
                className={`shipment-screen-view-order-details ${
                  isInvoiced ? 'store-right-border' : ''
                }`}>
                <div>View order details</div>
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_RIGHT_ARROW,
                    ACCESSIBILITY_IDENTIFIERS.COMMON
                  )}
                  className="shipment-details-bottom-item-arrow"
                />
              </div>
              {isInvoiced && (
                <div onClick={handleDownloadInvoice} className="shipment-details-bottom-item">
                  <div>Download invoice</div>
                  <img
                    src={getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_RIGHT_ARROW,
                      ACCESSIBILITY_IDENTIFIERS.COMMON
                    )}
                    className="shipment-details-bottom-item-arrow"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <Collections />
      </div>
    </div>
  );
};

export default ShipmentDetailsScreen;
