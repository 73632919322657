import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import Divider from '../../common/ui/divider/Divider';
import { GET_MY_MANAGER_AWARDS, SUBMIT_NOMINATON } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { selectEmployeeData } from '../../state/EmployeeData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { getAwardIcon, removeHtmlContent } from '../../utils/common';
import MyManagerListItem from './components/MyManagerListItem';
import { Form, Modal } from 'react-bootstrap';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { selectCitationCharLimit } from '../../state/MasterData';

const MyManagerScreen = ({ history }) => {
  const dispatch = useDispatch();
  const [cheers, setCheers] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [buttonIsDisable, setButtonIsDisable] = useState(true);
  const [iconName, setIconName] = useState();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [citationId, setCitationId] = useState();
  const [citation, setCitation] = useState('');

  const citationLimit = useSelector(selectCitationCharLimit);
  const employeeData = useSelector(selectEmployeeData);
  const accessibilityData = useSelector(selectAccessibilityData);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [showEmptyView, setShowEmptyView] = useState(false);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.MY_MANAGER_SCREEN, NAVIGATION_ROUTES.MY_MANAGER_SCREEN);
  }, []);

  const callMyReporterApi = useCallback(async () => {
    try {
      setShowEmptyView(false);
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(GET_MY_MANAGER_AWARDS, REQUEST_TYPE.GET, {});
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (
            apiResponse?.response?.data?.length > 0 &&
            apiResponse?.response?.data[0]?.feeds?.length === 0
          ) {
            setShowEmptyView(true);
          }
          if (
            apiResponse.response.data.length > 0 &&
            apiResponse.response.data[0].feeds.length > 0
          ) {
            setCheers(apiResponse.response.data[0].feeds[0]);
            setIconName(getAwardIcon(apiResponse.response.data[0].feeds[0].icon_name));
          }
        } else {
          setShowEmptyView(true);
        }
      }
    } catch (err) {
      setShowEmptyView(true);
      handleError(err, {}, GET_MY_MANAGER_AWARDS, NAVIGATION_ROUTES.MY_MANAGER_SCREEN);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch]);

  useEffect(() => {
    callMyReporterApi();
  }, [callMyReporterApi]);

  const callSubmitNominationApi = async () => {
    const params = new URLSearchParams();
    params.append('type', 'mymanager');
    params.append('employees', employeeData.employee_id);
    params.append('award_to', 'IN');
    params.append('award_key', cheers.award_key);
    params.append('citation_id', citationId);
    params.append('citation_text', citation);
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.SUBMIT_NOMINATIONS,
        })
      );
      const apiResponse = await apiRequest(SUBMIT_NOMINATON, REQUEST_TYPE.POST, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setShowSuccessDialog(true);
        }
      }
    } catch (err) {
      handleError(err, params, SUBMIT_NOMINATON, NAVIGATION_ROUTES.MY_MANAGER_SCREEN);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onRadioButtonPressed = (item, index) => {
    setCitationId(item.citation_id);
    setSelectedIndex(index);
    setButtonIsDisable(citation === '');
  };

  const handleCitation = (citationText) => {
    setCitation(citationText);
    setButtonIsDisable(citationId == undefined || citationId === null || citationText === '');
  };

  const renderFilters = (data, index) => {
    return (
      <MyManagerListItem
        item={data}
        onRadioButtonPressed={onRadioButtonPressed}
        index={index}
        selectedIndex={selectedIndex}
        key={`${index}`}
      />
    );
  };

  const onPressViewEligibleAward = () => {
    callSubmitNominationApi();
  };

  const onPressNominateAgain = () => {
    setShowSuccessDialog(false);
    history.goBack();
  };

  const onPressCheersHome = () => {
    setShowSuccessDialog(false);
    history.go(-2);
  };

  const onPressBackDrop = () => {
    setShowSuccessDialog(false);
    history.go(-2);
  };

  const successDialog = () => {
    return (
      <div>
        <div className="cheers-team-micro-view">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_SUCCESS,
              ACCESSIBILITY_IDENTIFIERS.CHEERS
            )}
            className="cheers-success-icon"
          />
        </div>
        <div className="text-center mt-3 cheers-register-message-text">Nomination Submitted</div>
        <CustomButton buttonStyle="cheers-button-style mt-3" onClick={onPressNominateAgain}>
          Nominate Again
        </CustomButton>
        <CustomButton buttonStyle="cheers-button-style mt-3" onClick={onPressCheersHome}>
          Cheers Home
        </CustomButton>
      </div>
    );
  };

  const emptyListView = () => {
    return (
      <DefaultContentView message="You have already nominated your Manager for this Quarter" />
    );
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CHEERS} />
      <BackButton />
      <div className="main-content-container">
        <Modal
          show={showSuccessDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={onPressBackDrop}>
          <Modal.Body>{successDialog()}</Modal.Body>
        </Modal>
        {Object.keys(cheers).length > 0 ? (
          <div>
            <div className="cheers-team-micro-view">
              <div className="cheers-nomination-type">Nomination Type:</div>
              <div className="ml-1 cheers-nomination-text">My Manager</div>
            </div>
            <div className="cheers-team-micro-view">
              <div className="text-center mt-2 cheers-award-type">
                {cheers.award_frequency === 'Monthly' ? 'Last Month' : 'Last Quarter'}
              </div>
            </div>
            <div className="text-center mt-3 cheers-based-on-criteria-text">
              Based on the criteria selected
            </div>
            <div className="cheers-team-micro-view mt-2 mb-2">
              <div className="cheers-outer-view cheers-icon-text-view">
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    iconName,
                    ACCESSIBILITY_IDENTIFIERS.CHEERS
                  )}
                  className="cheers-normal-icon"
                />
                <div className="ml-2 cheers-award-title-text">{cheers.title}</div>
              </div>
            </div>
            <div className="text-center mt-2 cheers-award-title-text">{`(${cheers.points} Points)`}</div>
            <Divider style="mt-2 mb-2" />
            <div className="peers-citation-block">
              <div>
                <div className="cheers-select-option-text">Why is this award for?</div>
                {cheers.citations.length > 0 &&
                  cheers.citations.map((data, index) => renderFilters(data, index))}
              </div>
              <Form.Group style={{ width: '50%' }}>
                <Form.Label className="cheers-select-option-text">Citation*</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  maxLength={citationLimit}
                  placeholder={`Enter your comments (Max ${citationLimit} Characters)`}
                  value={removeHtmlContent(citation)}
                  onChange={(event) => handleCitation(event.target.value)}
                />
              </Form.Group>
            </div>
          </div>
        ) : (
          showEmptyView && emptyListView()
        )}
        {Object.keys(cheers).length > 0 && (
          <CustomButton
            buttonStyle="cheers-button-style mr-3 mt-2"
            onClick={onPressViewEligibleAward}
            disabled={buttonIsDisable}
            showLoader={progressLoadingState.isProgressLoading}>
            Submit nomination
          </CustomButton>
        )}
      </div>
    </div>
  );
};

export default MyManagerScreen;
