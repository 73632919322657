import React, { useState } from 'react';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  MESSAGES,
  REQUEST_TYPE,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import { POSH_COMPLAINT } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { useDispatch, useSelector } from 'react-redux';
import { saveProgressLoadingState } from '../../state/UIState';
import { isEmptyText, showToast } from '../../utils/common';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent } from '../../utils/FirebaseAnalyticsUtils';
import CustomTextInput from '../../common/ui/custom_text_input/CustomTextInput';
import { selectMultilineTextSize } from '../../state/MasterData';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { Modal } from 'react-bootstrap';

const RaiseConcernScreen = ({ history }) => {
  const maxMultiLineCharLimit = useSelector(selectMultilineTextSize);
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const raisePoshConcernApi = async (messageText) => {
    logEvent(ANALYTICS_EVENT_TYPES.POSH_COMPLAINT, '', ANALYTICS_ITEM_NAMES.COMPLAINT);
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    const params = new URLSearchParams();
    if (messageText) {
      params.append('comment', messageText);
    }
    try {
      const apiResponse = await apiRequest(POSH_COMPLAINT, REQUEST_TYPE.POST, params);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            showToast(apiResponse.response.data.message);
            history.goBack();
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(err, params, POSH_COMPLAINT, NAVIGATION_ROUTES.POSH);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  const onPressNoButton = () => {
    setShowModal(false);
    history.goBack();
  };

  const onPressYesButton = () => {
    setShowModal(false);
    raisePoshConcernApi(message.trim());
  };

  const renderModal = () => {
    return (
      <div>
        <div className="modal-header posh-custom-modal-header">
          <h4 className="modal-title posh-custom-modal-title">{'Confirmation'}</h4>
        </div>
        <div className="modal-body posh-custom-modal-body px-5">
          {MESSAGES.POSH_RAISE_CONCERN_CONFIRMATION_MESSAGE}
        </div>
        <div className="posh-custom-modal-footer">
          <button
            type="button"
            className="common-custom-button-senary py-2 px-5"
            onClick={onPressNoButton}>
            {'No, cancel'}
          </button>
          <button
            type="button"
            className="common-custom-button-quinary py-2 px-5 text-capitalize"
            onClick={onPressYesButton}>
            {'Yes'}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="main-content-container container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.POSH} />
      <BackButton />
      <div className="d-flex mt-4 flex-column">
        <p>{MESSAGES.POSH_RAISE_CONCERN_MESSAGE}</p>
        <div className="mb-2">
          <CustomTextInput
            name="comment"
            isTitle={false}
            maxLength={maxMultiLineCharLimit}
            style="posh-raise-concern-editable-text-area"
            placeholder="Enter your concern"
            as="textarea"
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
          <div className="d-flex align-items-center justify-content-end flex-row mt-4">
            <button
              type="button"
              className="common-custom-button-quaternary py-2 px-5 mr-3"
              onClick={() => {
                history.goBack();
              }}>
              {'Cancel'}
            </button>
            <button
              type="button"
              className="common-custom-button-septenary py-2 px-5 ml-3"
              onClick={() => {
                if (!isEmptyText(message)) {
                  showToast(ERROR_MESSAGES.EMPTY_CONCERN);
                } else {
                  setShowModal(true);
                }
              }}>
              {'Submit'}
            </button>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowModal(false)}>
        <Modal.Body>{renderModal()}</Modal.Body>
      </Modal>
    </div>
  );
};

export default RaiseConcernScreen;
