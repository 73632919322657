import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  CHAT_LEFT_PANEL_IDENTIFIERS,
  LOADING_MESSAGES,
  MESSAGES,
  NOTIFICATION_MESSAGE_TYPES,
  SUPPORTED_IMAGE_FORMATS,
  WARNING_MESSAGES,
} from '../../../common/constants/AppConstants';
import {
  exitMemberFromGroup,
  fetchUserByIdOnce,
  sendNotificationMessageToGroup,
  uId,
  updateGroupImage,
  updateGroupName,
  uploadGroupImage,
} from '../../../utils/ChatHandler';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { selectUserChatGroups } from '../../../state/ChatData';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { selectChatGroupMembersLimit } from '../../../state/MasterData';
import { saveProgressLoadingState } from '../../../state/UIState';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { isEmptyText, showToast } from '../../../utils/common';
import BackButton from '../../../common/ui/common_back_button/BackButton';
import Divider from '../../../common/ui/divider/Divider';
import AddedMembersListItem from './AddedMembersListItem';
import ChatConfirmModal from './ChatConfirmModal';
import ChatEditGroupDetailModal from './ChatEditGroupDetailModal';

const GroupDetailView = (props) => {
  const {
    groupMemberList,
    setGroupMemberList,
    groupId,
    setLeftPanelType,
    setGroupChange,
    setUserMentionList,
  } = props;
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);
  const employeeData = useSelector(selectEmployeeData);
  const userChatGroups = useSelector(selectUserChatGroups);
  const maxSelected = useSelector(selectChatGroupMembersLimit);
  const [groupDetail, setGroupDetail] = useState({});
  const [groupMembers, setGroupMembers] = useState({});
  const [groupName, setGroupName] = useState('');
  const [groupImage, setGroupImage] = useState('');
  const [showRemoveMemberDialog, setShowRemoveMemberDialog] = useState(false);
  const [showEditGroupNameDialog, setShowEditGroupNameDialog] = useState(false);
  const [removeUserId, setRemoveUserId] = useState('');
  const [removeUserName, setRemoveUserName] = useState('');
  const [isSelfUserAdmin, setIsSelfUserAdmin] = useState(false);
  const exitStatus = useRef(1);
  const addImageFileRef = useRef();

  useEffect(() => {
    const uid = uId(employeeData);
    if (Object.keys(userChatGroups).length > 0) {
      let groupModel = userChatGroups[groupId];
      if (groupModel && Object.keys(groupModel).length > 0) {
        if (groupModel.members[uid]) {
          setGroupDetail(groupModel);
          let isAdmin = groupModel.members[uid].admin;
          setIsSelfUserAdmin(isAdmin);
        }
      }
    }
  }, [employeeData, groupId, userChatGroups]);

  useEffect(() => {
    const uid = uId(employeeData);
    if (Object.keys(userChatGroups).length > 0) {
      let groupModel = userChatGroups[groupId];
      if (groupModel && Object.keys(groupModel).length > 0) {
        let memberDetail = groupModel.members[uid];
        if (!memberDetail.active || memberDetail.active === false) {
          if (exitStatus.current === 1) {
            setLeftPanelType(CHAT_LEFT_PANEL_IDENTIFIERS.DEFAULT_VIEW);
          }
        }
      } else {
        if (exitStatus.current === 1) {
          setLeftPanelType(CHAT_LEFT_PANEL_IDENTIFIERS.DEFAULT_VIEW);
        }
      }
    }
  }, [employeeData, groupId, setLeftPanelType, userChatGroups]);

  useEffect(() => {
    if (Object.keys(groupDetail).length > 0) {
      setGroupName(groupDetail.name);
      setGroupImage(groupDetail.image_url);
      setGroupMembers(groupDetail.members);
    }
  }, [groupDetail]);

  useEffect(() => {
    let membersList = [];
    let membersList1 = [];
    let i = 0;
    let totalMembers = 0;
    let employeeId = uId(employeeData);
    Object.values(groupMembers).forEach((data) => {
      if (data.active && data.active === true) {
        totalMembers = totalMembers + 1;
        fetchUserByIdOnce(data.uid, (isExist, user) => {
          if (isExist) {
            i = i + 1;
            membersList.push({
              id: user.uid,
              image: user.image_url,
              name: user.name,
              location: user.user_location,
              admin: data.admin,
            });
            const selfUid = uId(employeeData);
            if (user.uid !== selfUid) {
              membersList1.push({
                name: user.name,
                uid: user.uid,
                avatar: user.image_url,
              });
            }
            if (i === totalMembers) {
              const a1 = membersList.filter((element) => element.id === employeeId);
              const a2 = membersList.filter(
                (element) => element.admin === true && element.id !== employeeId
              );
              a2.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              });
              const a3 = membersList.filter(
                (element) => element.admin === false && element.id !== employeeId
              );
              a3.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              });
              setGroupMemberList([...a1, ...a2, ...a3]);
              setUserMentionList((list) => [...membersList1]);
            }
          }
        });
      }
    });
  }, [employeeData, groupMembers, setGroupMemberList]);

  const renderAddedMembers = (data, index) => {
    return (
      <AddedMembersListItem
        item={data}
        key={index}
        groupMembers={groupDetail.members}
        groupId={groupId}
        removeMember={(id, name) => {
          setRemoveUserId(id);
          setRemoveUserName(name);
          setShowRemoveMemberDialog(true);
        }}
      />
    );
  };

  const onPressGroupPhotoHandler = () => {
    addImageFileRef.current.click();
  };

  const onFileChange = (event) => {
    [...event.target.files].forEach((element) => {
      if (element.size > 5 * 1024 * 1024) {
        showToast(WARNING_MESSAGES.FILE_SIZE_EXCEEDED);
      } else {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
            progressMessage: LOADING_MESSAGES.UPLOADING_GROUP_ICON,
          })
        );
        uploadGroupImage(element, (downloadUrl, error) => {
          if (downloadUrl) {
            updateGroupImage(groupId, downloadUrl, () => {
              dispatch(saveProgressLoadingState({ isProgressLoading: false }));
            });
          } else {
            dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          }
        });
      }
    });
  };

  const onSaveGroupTextHandler = (value) => {
    if (!isEmptyText(value)) {
      showToast(MESSAGES.ENTER_GROUP_NAME);
    } else {
      updateGroupName(groupId, value, () => {
        let uid = uId(employeeData);
        let membersArr = [];
        membersArr.push({
          name: employeeData.first_name + ' ' + employeeData.last_name,
          uid: uid,
        });
        sendNotificationMessageToGroup(
          groupId,
          employeeData,
          NOTIFICATION_MESSAGE_TYPES.CHANGE_GROUP_NAME,
          membersArr,
          () => {}
        );
        setShowEditGroupNameDialog(false);
        showToast(MESSAGES.GROUP_NAME_UPDATED);
      });
    }
  };

  const onPressEditHandler = () => (
    <Popover>
      <>
        <Popover.Content>
          <div
            className="common-cursor-pointer"
            onClick={() => {
              document.body.click();
              onPressGroupPhotoHandler();
            }}>
            Change Photo
          </div>
        </Popover.Content>
        <Divider />
        <Popover.Content>
          <div
            className="common-cursor-pointer"
            onClick={() => {
              document.body.click();
              updateGroupImage(groupId, '', () => {});
            }}>
            Remove Photo
          </div>
        </Popover.Content>
      </>
    </Popover>
  );

  return (
    <div>
      <div className="d-flex px-3 py-2 justify-content-between align-items-center chat-header">
        <div className="d-flex align-items-center">
          <BackButton
            isFullRow={false}
            backTitle=""
            containerStyle="py-0"
            navigateBackFuncHandler={() => {
              setLeftPanelType(CHAT_LEFT_PANEL_IDENTIFIERS.DEFAULT_VIEW);
            }}
          />
          <div className="chat-bold-text">Group Info</div>
        </div>
      </div>
      <div>
        <ChatConfirmModal
          isDialogVisible={showRemoveMemberDialog}
          setIsDialogVisible={() => setShowRemoveMemberDialog(false)}
          subtitle="You are removing a member from the group. Proceed?"
          leftButtonText="Cancel"
          onClickLeftButton={() => setShowRemoveMemberDialog(false)}
          rightButtonText="Ok"
          onClickRightButton={() => {
            setShowRemoveMemberDialog(false);
            dispatch(
              saveProgressLoadingState({
                isProgressLoading: true,
                progressMessage: LOADING_MESSAGES.UPDATING,
              })
            );
            let uid = uId(employeeData);
            exitMemberFromGroup(groupId, removeUserId, uid, false, () => {
              let membersArr = [];
              membersArr.push({
                name: removeUserName,
                uid: removeUserId,
              });
              sendNotificationMessageToGroup(
                groupId,
                employeeData,
                NOTIFICATION_MESSAGE_TYPES.REMOVE_MEMBER,
                membersArr,
                () => {}
              );
              dispatch(saveProgressLoadingState({ isProgressLoading: false }));
              setRemoveUserId('');
              setRemoveUserName('');
              setGroupChange((prev) => !prev);
            });
          }}
        />
        <ChatConfirmModal
          isDialogVisible={showRemoveMemberDialog}
          setIsDialogVisible={() => setShowRemoveMemberDialog(false)}
          subtitle="You are removing a member from the group. Proceed?"
          leftButtonText="Cancel"
          onClickLeftButton={() => setShowRemoveMemberDialog(false)}
          rightButtonText="Ok"
          onClickRightButton={() => {
            setShowRemoveMemberDialog(false);
            dispatch(
              saveProgressLoadingState({
                isProgressLoading: true,
                progressMessage: LOADING_MESSAGES.UPDATING,
              })
            );
            let uid = uId(employeeData);
            exitMemberFromGroup(groupId, removeUserId, uid, false, () => {
              let membersArr = [];
              membersArr.push({
                name: removeUserName,
                uid: removeUserId,
              });
              sendNotificationMessageToGroup(
                groupId,
                employeeData,
                NOTIFICATION_MESSAGE_TYPES.REMOVE_MEMBER,
                membersArr,
                () => {}
              );
              setGroupChange((prev) => !prev);
              dispatch(saveProgressLoadingState({ isProgressLoading: false }));
              setRemoveUserId('');
            });
          }}
        />
        <ChatEditGroupDetailModal
          isDialogVisible={showEditGroupNameDialog}
          setIsDialogVisible={() => setShowEditGroupNameDialog(false)}
          leftButtonText="Cancel"
          onClickLeftButton={() => setShowEditGroupNameDialog(false)}
          rightButtonText="Save"
          groupTextValue={groupName}
          onClickRightButton={onSaveGroupTextHandler}
        />

        <div className="chat-left-panel-container">
          {Object.keys(groupDetail).length > 0 && (
            <div>
              <input
                type="file"
                ref={addImageFileRef}
                onChange={onFileChange}
                accept={SUPPORTED_IMAGE_FORMATS}
                id="input"
                className="d-none"
                onClick={(event) => {
                  event.target.value = null;
                }}
              />
              <div>
                <div className="p-3 d-flex justify-content-center align-items-center chat-avatar-box position-relative">
                  <img
                    onClick={onPressGroupPhotoHandler}
                    src={
                      groupImage !== ''
                        ? groupImage
                        : getFeatureComponentUrl(
                            accessibilityData,
                            ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_CIRCULAR_GROUP_GREY,
                            ACCESSIBILITY_IDENTIFIERS.COMMON
                          )
                    }
                    className="chat-rounded-avatar common-cursor-pointer"
                  />
                  <OverlayTrigger
                    rootClose
                    trigger="click"
                    placement="bottom-end"
                    overlay={onPressEditHandler()}>
                    <div className="chat-rounded-avatar-option px-2 common-cursor-pointer mb-3 mr-3">
                      Edit
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
              <Divider style="chat-divider-thin" />
              <div className="d-flex justify-content-between align-items-center py-2 px-3">
                <div className="chat-bold-text text-truncate">{groupName}</div>
                <div
                  className="common-cursor-pointer"
                  onClick={() => setShowEditGroupNameDialog(true)}>
                  <img
                    src={getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.HOME_EDIT_FAVORITES,
                      ACCESSIBILITY_IDENTIFIERS.HOME
                    )}
                    className="chat-group-detail-edit ml-2"
                  />
                </div>
              </div>
              <Divider style="chat-divider-thin" />
              <div className="px-3 py-3">
                <div className="d-flex justify-content-between mb-3">
                  <div className="chat-base-text-sm chat-bold-text">{`${groupMemberList.length} Members`}</div>
                  {isSelfUserAdmin && (
                    <div>
                      <div
                        onClick={() => {
                          if (groupMemberList.length < maxSelected) {
                            setLeftPanelType(
                              CHAT_LEFT_PANEL_IDENTIFIERS.GROUP_DETAIL_ADD_MEMBERS_VIEW
                            );
                          }
                        }}>
                        <div className="chat-base-text-sm chat-bold-text chat-theme-highlighted-text">
                          <div
                            className={`${
                              groupMemberList.length >= maxSelected
                                ? 'chat-theme-highlighted-text-disabled common-cursor-not-allowed'
                                : 'chat-theme-highlighted-text common-cursor-pointer'
                            }`}>
                            Add Member
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {groupMemberList.map((data, index) => renderAddedMembers(data, index))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(GroupDetailView);
