import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { GET_PEER_AWARDS } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { selectCheersAwardTypeStatusData } from '../../state/MasterData';
import { saveProgressLoadingState } from '../../state/UIState';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import { showToast } from '../../utils/common';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import PeerEmployeeSelection from './components/PeerEmployeeSelection';
import SelectedEmployeeListItem from './components/SelectedEmployeeListItem';
import SelfListItem from './components/SelfListItem';

const PeerScreen = ({ history }) => {
  const dispatch = useDispatch();
  const [cheersList, setCheersList] = useState([]);
  const [monthlyList, setMonthlyList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [showTagEmployeeView, setShowTagEmployeeView] = useState(false);
  const [tagList, setTagList] = useState([]);
  const [isSelectedTeam, setIsSelectedTeam] = useState(false);
  const [isSelectedIndividual, setIsSelectedIndividual] = useState(false);
  const [isSelectedMonetary, setIsSelectedMonetary] = useState(false);
  const [isSelectedNonMonetary, setIsSelectedNonMonetary] = useState(false);
  const [isSelectedLastMonth, setIsSelectedLastMonth] = useState(false);
  const [isSelectedLastQuarter, setIsSelectedLastQuarter] = useState(false);
  const [showHideLastQuarter, setShowHideLastQuarter] = useState(false);

  const [isSecondViewVisible, setIsSecondViewVisible] = useState(false);
  const [isThirdViewVisible, setIsThirdViewVisible] = useState(false);
  const [isForthViewVisible, setIsForthViewVisible] = useState(false);
  const [isFifthViewVisible, setIsFifthViewVisible] = useState(false);
  const [awardType, setAwardType] = useState('');
  const [awardFrequency, setAwardFrequency] = useState('');
  const [awardKey, setAwardKey] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [buttonIsDisable, setButtonIsDisable] = useState(true);
  const [text, setText] = useState('Select Recipients');
  const [selectedItem, setSelectedItem] = useState({});

  const accessibilityData = useSelector(selectAccessibilityData);
  const awardTypeStatue = useSelector(selectCheersAwardTypeStatusData);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.PEER_SCREEN, NAVIGATION_ROUTES.PEER_SCREEN);
  }, []);

  const callPeerAwardsApi = useCallback(async () => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(GET_PEER_AWARDS, REQUEST_TYPE.GET, {});
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data[0].feeds.length > 0) {
            setCheersList(apiResponse.response.data[0].feeds);
          }
        }
      }
    } catch (err) {
      handleError(err, {}, GET_PEER_AWARDS, NAVIGATION_ROUTES.PEER_SCREEN);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch]);

  useEffect(() => {
    callPeerAwardsApi();
  }, [callPeerAwardsApi]);

  const onRadioButtonPressed = (item, index) => {
    setSelectedIndex(index);
    setIsFifthViewVisible(true);
    setAwardKey(item.award_key);
    setSelectedItem(item);
    setButtonIsDisable(true);
    setSelectedEmployee([]);
    setTagList([]);
  };

  const renderFilters = (data, index) => {
    return (
      <SelfListItem
        item={data}
        onRadioButtonPressed={onRadioButtonPressed}
        key={`${index}`}
        index={index}
        selectedIndex={selectedIndex}
      />
    );
  };

  const renderSelectedEmployee = (item, index) => {
    return <SelectedEmployeeListItem item={item} key={`${index}`} />;
  };

  const filterList = (type, frequency) => {
    if (isSelectedIndividual) {
      let arr = [];
      cheersList.forEach((element) => {
        if (
          element.award_frequency === frequency &&
          element.award_type === type &&
          element.award_individual
        ) {
          arr.push(element);
        }
      });
      let filteredArr = arr.filter((item) => item?.citations?.length !== 0);
      setMonthlyList(filteredArr);
    } else {
      let arr = [];
      cheersList.forEach((element) => {
        if (
          element.award_frequency === frequency &&
          element.award_type === type &&
          element.award_team
        ) {
          arr.push(element);
        }
      });
      let filteredArr = arr.filter((item) => item?.citations?.length !== 0);
      setMonthlyList(filteredArr);
    }
  };

  const onPressTeam = () => {
    setIsSecondViewVisible(true);
    setIsThirdViewVisible(false);
    setIsForthViewVisible(false);
    setIsFifthViewVisible(false);
    setShowTagEmployeeView(false);
    setButtonIsDisable(true);

    setIsSelectedTeam(true);
    setIsSelectedIndividual(false);
    setIsSelectedMonetary(false);
    setIsSelectedNonMonetary(false);
    setIsSelectedLastMonth(false);
    setIsSelectedLastQuarter(false);
    setSelectedIndex(null);
    setSelectedEmployee([]);
    setText('Select Recipients');
    setTagList([]);
  };

  const onPressIndividual = () => {
    setIsSecondViewVisible(true);
    setIsThirdViewVisible(false);
    setIsForthViewVisible(false);
    setIsFifthViewVisible(false);
    setShowTagEmployeeView(false);
    setButtonIsDisable(true);

    setIsSelectedTeam(false);
    setIsSelectedIndividual(true);
    setIsSelectedMonetary(false);
    setIsSelectedNonMonetary(false);
    setIsSelectedLastMonth(false);
    setIsSelectedLastQuarter(false);
    setSelectedIndex(null);
    setSelectedEmployee([]);
    setText('Select Recipients');
    setTagList([]);
  };

  const onPressMonetary = () => {
    setShowHideLastQuarter(true);
    setIsThirdViewVisible(true);
    setIsForthViewVisible(false);
    setIsFifthViewVisible(false);
    setShowTagEmployeeView(false);
    setButtonIsDisable(true);

    setIsSelectedMonetary(true);
    setIsSelectedNonMonetary(false);
    setIsSelectedLastMonth(false);
    setIsSelectedLastQuarter(false);
    setSelectedIndex(null);
    setSelectedEmployee([]);
    setText('Select Recipients');
    setTagList([]);

    setAwardType('Monetary');
    filterList('Monetary', awardFrequency);
  };

  const onPressNonMonetary = () => {
    setShowHideLastQuarter(false);
    setIsThirdViewVisible(true);
    setIsForthViewVisible(false);
    setIsFifthViewVisible(false);
    setShowTagEmployeeView(false);
    setButtonIsDisable(true);

    setIsSelectedMonetary(false);
    setIsSelectedNonMonetary(true);
    setIsSelectedLastMonth(false);
    setIsSelectedLastQuarter(false);
    setSelectedIndex(null);
    setSelectedEmployee([]);
    setText('Select Recipients');
    setTagList([]);

    setAwardType('Non-Monetary');
    filterList('Non-Monetary', awardFrequency);
  };

  const onPressLastMonth = () => {
    setIsForthViewVisible(true);
    setIsFifthViewVisible(false);
    setShowTagEmployeeView(false);
    setButtonIsDisable(true);

    setIsSelectedLastMonth(true);
    setIsSelectedLastQuarter(false);
    setSelectedIndex(null);
    setSelectedEmployee([]);
    setText('Select Recipients');
    setTagList([]);

    setAwardFrequency('Monthly');
    filterList(awardType, 'Monthly');
  };

  const onPressLastQuarter = () => {
    setIsForthViewVisible(true);
    setIsFifthViewVisible(false);
    setShowTagEmployeeView(false);
    setButtonIsDisable(true);

    setIsSelectedLastMonth(false);
    setIsSelectedLastQuarter(true);
    setSelectedIndex(null);
    setSelectedEmployee([]);
    setText('Select Recipients');
    setTagList([]);

    setAwardFrequency('Quarterly');
    filterList(awardType, 'Quarterly');
  };

  const firstView = () => {
    return (
      <div>
        <div className="cheers-select-option-text">Who are you awarding?</div>
        <div className="cheers-date-view-style mt-2">
          {isSelectedTeam ? (
            <CustomButton buttonStyle="cheers-selected-button-style mr-3">Team</CustomButton>
          ) : (
            <CustomButton
              buttonStyle="cheers-not-selected-button-style mr-3"
              onClick={() => onPressTeam()}>
              Team
            </CustomButton>
          )}
          {isSelectedIndividual ? (
            <CustomButton buttonStyle="cheers-selected-button-style">Individual</CustomButton>
          ) : (
            <CustomButton
              buttonStyle="cheers-not-selected-button-style"
              onClick={() => onPressIndividual()}>
              Individual
            </CustomButton>
          )}
        </div>
      </div>
    );
  };

  const secondView = () => {
    return (
      <div>
        <div className="mt-2 cheers-select-option-text">Select Award Type</div>
        <div className="cheers-date-view-style mt-2">
          {awardTypeStatue.peer.monetary &&
            (isSelectedMonetary ? (
              <CustomButton buttonStyle="cheers-selected-button-style mr-3">Monetary</CustomButton>
            ) : (
              <CustomButton
                buttonStyle="cheers-not-selected-button-style mr-3"
                onClick={() => onPressMonetary()}>
                Monetary
              </CustomButton>
            ))}
          {awardTypeStatue.peer.non_monetary &&
            (isSelectedNonMonetary ? (
              <CustomButton buttonStyle="cheers-selected-button-style">Non-Monetary</CustomButton>
            ) : (
              <CustomButton
                buttonStyle="cheers-not-selected-button-style"
                onClick={() => onPressNonMonetary()}>
                Non-Monetary
              </CustomButton>
            ))}
        </div>
      </div>
    );
  };

  const thirdView = () => {
    return (
      <div>
        <div className="mt-2 cheers-select-option-text">What time frame is this award for?</div>
        <div className="cheers-date-view-style mt-2">
          {isSelectedLastMonth ? (
            <CustomButton buttonStyle="cheers-selected-button-style mr-3">Last Month</CustomButton>
          ) : (
            <CustomButton
              buttonStyle="cheers-not-selected-button-style mr-3"
              onClick={() => onPressLastMonth()}>
              Last Month
            </CustomButton>
          )}
          {showHideLastQuarter === true && (
            <div>
              {isSelectedLastQuarter ? (
                <CustomButton buttonStyle="cheers-selected-button-style">Last Quarter</CustomButton>
              ) : (
                <CustomButton
                  buttonStyle="cheers-not-selected-button-style"
                  onClick={() => onPressLastQuarter()}>
                  Last Quarter
                </CustomButton>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const forthView = () => {
    return (
      <div>
        <div className="mt-2 cheers-select-option-text">Why is this award for?</div>
        {monthlyList.length > 0 && monthlyList.map((data, index) => renderFilters(data, index))}
      </div>
    );
  };

  const fifthView = () => {
    return (
      <div className="mt-2">
        <div className="cheers-date-view-style justify-content-between">
          <div className="cheers-person-selected">{text}</div>
          <div
            onClick={() => {
              setShowTagEmployeeView(true);
            }}>
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_TAG_EMPLOYEE,
                ACCESSIBILITY_IDENTIFIERS.CHEERS
              )}
              className="cheers-normal-icon common-cursor-pointer"
            />
          </div>
        </div>
        {selectedEmployee &&
          selectedEmployee.map((data, index) => renderSelectedEmployee(data, index))}
      </div>
    );
  };

  const onPressViewEligibleAward = () => {
    history.push(NAVIGATION_ROUTES.VIEW_PEER_AWARD_SCREEN, {
      selectedItem: selectedItem,
      awardKey: awardKey,
      awardType: awardType,
      selectedEmployee: selectedEmployee,
      isIndividual: isSelectedIndividual,
    });
  };

  const onEmployeeSelected = (tags) => {
    if (isSelectedTeam && tags.length === 1) {
      showToast('Please select more than one recipient');
      return;
    }
    setShowTagEmployeeView(false);
    setTagList([...tags]);
    let arr = [];
    tags.forEach((element) => {
      let obj = {
        name: element.first_name + ' ' + element.last_name,
        baseUrl: element.profile_picture.base_url,
        imagePath: element.profile_picture.image_path,
        positionTitle: element.position_title,
        points: 100,
        empId: element.employee_id,
      };
      arr.push(obj);
    });
    setSelectedEmployee(arr);
    setButtonIsDisable(false);
    if (tags.length === 1) {
      setText(`${tags.length} Recipient has been selected`);
    } else {
      setText(`${tags.length} Recipients have been selected`);
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CHEERS} />
      <BackButton />
      <div className="main-content-container">
        {firstView()}
        {isSecondViewVisible && secondView()}
        {isThirdViewVisible && thirdView()}
        {isForthViewVisible && forthView()}
        {showTagEmployeeView && (
          <PeerEmployeeSelection
            onEmployeeSelected={onEmployeeSelected}
            tags={tagList}
            awardKey={awardKey}
            isIndividual={isSelectedIndividual}
          />
        )}
        {isFifthViewVisible && fifthView()}
        <CustomButton
          buttonStyle="cheers-button-style mr-3 mt-2"
          onClick={onPressViewEligibleAward}
          disabled={buttonIsDisable}>
          View eligible award
        </CustomButton>
      </div>
    </div>
  );
};

export default PeerScreen;
