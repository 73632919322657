import { createSlice } from '@reduxjs/toolkit';

const saveMenuDataReducer = (state, action) => {
  state.menu = action.payload;
  state.isCheersAvailable = getIsCheersAvailable(action.payload);
};

// REDUCER
const menuDataSlice = createSlice({
  name: 'menuData',
  initialState: {
    menu: {},
    isCheersAvailable: true,
  },
  reducers: {
    saveMenuData: saveMenuDataReducer,
  },
});

//function to check is cheers availabke
const getIsCheersAvailable = (menuData) => {
  let isCheersAvailable = false;
  let index = menuData?.data?.section_list?.findIndex(
    (section) => section?.identifier === '_section_lifeML'
  );
  if (index > -1) {
    let lifeMLSection = menuData?.data?.section_list[index];
    let cheersIndex = lifeMLSection?.features?.findIndex(
      (menuItem) => menuItem?.identifier === '_cheers'
    );
    if (cheersIndex > -1) {
      isCheersAvailable = true;
    } else {
      isCheersAvailable = false;
    }
  }
  return isCheersAvailable;
};

// ACTIONS
const { saveMenuData } = menuDataSlice.actions;

const selectMenuData = ({ menuData }) => {
  return menuData.menu;
};

const selectIsCheersAvailableData = ({ menuData }) => {
  return menuData.isCheersAvailable;
};

const menuDataSliceReducer = menuDataSlice.reducer;

export { menuDataSliceReducer, saveMenuData, selectMenuData, selectIsCheersAvailableData };
