import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { GET_SUMMARY, GET_VOLUNTEER_LOG } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../state/UIState';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { formatTimeHrsMinsVolunteer } from '../../utils/common';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import VolunteerLogListItem from './components/VolunteerLogListItem';

const VolunteeringLogScreen = () => {
  const dispatch = useDispatch();
  const [volunteerList, setVolunteerList] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const [feeds, setFeeds] = useState({});
  const [noMatchFlag, setNoMatchFlag] = useState(false);

  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const accessibilityData = useSelector(selectAccessibilityData);
  const downloadImagePath = getFeatureComponentUrl(
    accessibilityData,
    ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_DOWNLOAD_ICON_RED,
    ACCESSIBILITY_IDENTIFIERS.COMMON
  );
  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.VOLUNTEERING_LOG_SCREEN,
      NAVIGATION_ROUTES.VOLUNTEERING_LOG_SCREEN
    );
  }, []);

  const callVolunteerLogApi = useCallback(
    async (id, loadMore) => {
      dispatch(saveShowEmptyContentView(true));
      setNoMatchFlag(false);
      if (loadMore) {
        const params = {
          page_id: id,
        };
        try {
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          const apiResponse = await apiRequest(GET_VOLUNTEER_LOG, REQUEST_TYPE.GET, params);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                setVolunteerList((oldArray) => [...oldArray, ...apiResponse.response.data.feeds]);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
              } else {
                if (apiResponse.response.data.feeds.length === 0 && pageId === 1) {
                  setNoMatchFlag(true);
                }
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(err, params, GET_VOLUNTEER_LOG, NAVIGATION_ROUTES.VOLUNTEERING_LOG_SCREEN);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      }
    },
    [dispatch]
  );

  const callCurrentSummaryApi = useCallback(async () => {
    try {
      const apiResponse = await apiRequest(GET_SUMMARY, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setFeeds(apiResponse.response.data.feeds);
          callVolunteerLogApi(1, true);
        }
      }
    } catch (err) {
      //
    }
  }, [callVolunteerLogApi]);

  useEffect(() => {
    callCurrentSummaryApi();
  }, [callCurrentSummaryApi]);

  const onPressLoadMore = () => {
    callVolunteerLogApi(pageId, loadMoreFlag);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.SEARCH_NO_RESULTS} />;
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICROGIVE} />
      <BackButton />
      <div className="row pl-3 microgive-upper-view pt-2 pb-2">
        <div className="col-sm-6">
          <div className="microgive-total-hrs-text text-center">
            {Object.keys(feeds).length > 0 && `${feeds.volunteer.volunteer_count}`}
          </div>
          <div className="microgive-no-time-volunteer-text text-center">
            No. of times volunteered
          </div>
        </div>
        <div className="col-sm-6">
          <div className="microgive-donation-count-text text-center">
            {Object.keys(feeds).length > 0 &&
              formatTimeHrsMinsVolunteer(
                feeds.volunteer.volunteer_hours.total_hours,
                feeds.volunteer.volunteer_hours.total_minutes
              )}
          </div>
          <div className="microgive-total-working-hrs-text text-center">
            Total Volunteering hours
          </div>
        </div>
      </div>
      {pageId === 1 && noMatchFlag ? (
        emptyListView()
      ) : (
        <div>
          {volunteerList.map((item, index) => {
            return (
              <VolunteerLogListItem
                key={`${index}`}
                item={item}
                index={index}
                downloadImagePath={downloadImagePath}
              />
            );
          })}
          {loadMoreFlag && (
            <LoadMoreBtn
              onClick={onPressLoadMore}
              isLoading={progressLoadingState.isProgressLoading}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default VolunteeringLogScreen;
