import React from 'react';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';

const SelectedEmployeeListItem = ({ item }) => {
  return (
    <div className="col-sm-12 mb-3">
      <div className="cheers-view-style row">
        <UserProfilePhoto
          imageBaseUrl={item.baseUrl}
          imagePath={item.imagePath}
          imageClass="common-user-profile-photo-lg"
        />
        <div className="ml-3 cheers-outer-view">
          <div>
            <div className="mb-1 cheers-name">{item.name}</div>
            <div className="mt-1 cheers-position-text">{item.positionTitle}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectedEmployeeListItem;
