import React, { useEffect, useRef, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { selectEmployeeData } from '../../state/EmployeeData';
import { logEvent2, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { LinkedinIcon, FacebookIcon, EmailIcon, TwitterIcon } from 'react-share';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { shareImageBase64 } from '../../utils/common';
import ShareEmailModal from '../../common/components/shareEmailModal';
import { saveProgressLoadingState } from '../../state/UIState';
import html2canvas from 'html2canvas';

const WallpaperContentScreen = ({ location }) => {
  const { cardIndex, cards, wallpaperTextColor } = location.state;
  const [index, setIndex] = useState(cardIndex);
  const employeeData = useSelector(selectEmployeeData);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [isPortraitMode, setIsPortraitMode] = useState(true);
  const shareEmailImageUrl = useRef('');
  const [showEmailModal, setShowEmailModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.WALLPAPER_CONTENT_SCREEN,
      NAVIGATION_ROUTES.WALLPAPER_CONTENT_SCREEN
    );
  }, []);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const shareImageUrlOverEmail = (imageUrl) => {
    shareEmailImageUrl.current = imageUrl;
    setShowEmailModal(true);
  };

  const shareWallpaper = (index, e, platform, item) => {
    logEvent2({
      eventType: ANALYTICS_EVENT_TYPES.WALLPAPERS_SHARE,
      itemId: '',
      itemName: '',
      categoryId: ANALYTICS_ITEM_NAMES.ANNIVERSARY,
      categoryName: ANALYTICS_ITEM_NAMES.ANNIVERSARY,
    });
    downloadOrShareWallpaper(index, e, 'share', platform, item);
  };

  const downloadImage = async (index, e, item) => {
    logEvent2({
      eventType: ANALYTICS_EVENT_TYPES.WALLPAPERS_DOWNLOAD,
      itemId: '',
      itemName: '',
      categoryId: ANALYTICS_ITEM_NAMES.ANNIVERSARY,
      categoryName: ANALYTICS_ITEM_NAMES.ANNIVERSARY,
    });
    downloadOrShareWallpaper(index, e, 'download', '', item);
  };

  const downloadOrShareWallpaper = async (index, e, type, platform, item) => {
    var leafname = item.split('\\').pop().split('/').pop();
    e.preventDefault();
    document.getElementById(`wallpaper-content-download-${index}`).classList.add('d-block');
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    setTimeout(() => {
      html2canvas(document.getElementById(`wallpaper-content-download-${index}`), {
        useCORS: true,
        onclone: function (clonedDoc) {
          clonedDoc.getElementById(`wallpaper-content-download-${index}`).classList.add('d-block');
        },
      })
        .then(async (canvas) => {
          document
            .getElementById(`wallpaper-content-download-${index}`)
            .classList.remove('d-block');

          let xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = function () {
            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(xhr.response);
            a.download = leafname;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            a.remove();
          };
          if (type === 'download') {
            xhr.open('GET', canvas.toDataURL('image/jpeg'));
          } else if (platform === 'email') {
            shareImageUrlOverEmail(canvas.toDataURL('image/jpeg'));
          } else {
            await shareImageBase64(platform, canvas.toDataURL('image/jpeg'));
          }
          xhr.send();
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        })
        .catch(() => {
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        });
    }, 1000);
  };

  const getMobileNumber = () => {
    if (
      !!employeeData.emergency_contact &&
      !!employeeData.emergency_contact?.phone?.isd_code &&
      !!employeeData.emergency_contact?.phone?.number
    ) {
      return `+${
        employeeData.emergency_contact.phone.isd_code
      } ${employeeData.emergency_contact?.phone?.number?.slice(
        0,
        5
      )} ${employeeData.emergency_contact?.phone?.number?.slice(
        5,
        employeeData.emergency_contact?.phone?.number.length
      )}`;
    } else if (Object.keys(employeeData?.mobile_number).length > 0) {
      return `+${employeeData.mobile_number?.isd_code} ${employeeData.mobile_number?.number?.slice(
        0,
        5
      )} ${employeeData.mobile_number?.number?.slice(
        5,
        employeeData.mobile_number?.number.length
      )}`;
    } else {
      return '';
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY} />
      <BackButton />
      <div className="anniversary-gallery-full-container">
        <Carousel
          nextIcon={
            cards.length > 1 && <span aria-hidden="true" className="carousel-control-next-icon" />
          }
          prevIcon={
            cards.length > 1 && <span aria-hidden="true" className="carousel-control-prev-icon" />
          }
          activeIndex={index}
          onSelect={handleSelect}>
          {cards.map((item, index) => {
            return (
              <Carousel.Item key={`${index}`}>
                <div className="anniversary-gallery-full-image">
                  <div className="anniversary-wallpaper-view-content">
                    <div
                      id={`wallpaper-content-download-${index}`}
                      className="d-flex justify-content-center hide-certificate">
                      <img src={item} className="wallpaper-image-screenshot" />
                      <div className="anniversary-info-view-content ml-2 mr-2">
                        <div>
                          <div
                            style={{ color: wallpaperTextColor }}
                            className="text-center">{`${employeeData.first_name} ${employeeData.last_name}`}</div>
                          <div
                            style={{ color: wallpaperTextColor }}
                            className="text-center pb-1 pt-1">
                            {employeeData.email}
                          </div>
                          <div style={{ color: wallpaperTextColor }} className="text-center">
                            Emergency contact
                          </div>
                          <div style={{ color: wallpaperTextColor }} className="text-center">
                            {getMobileNumber()}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <img src={item} className="anniversary-wallpaper-full-image" />
                      <div className="anniversary-info-view-content ml-2 mr-2">
                        <div>
                          <div
                            style={{ color: wallpaperTextColor }}
                            className="text-center">{`${employeeData.first_name} ${employeeData.last_name}`}</div>
                          <div
                            style={{ color: wallpaperTextColor }}
                            className="text-center pb-1 pt-1">
                            {employeeData.email}
                          </div>
                          <div style={{ color: wallpaperTextColor }} className="text-center">
                            Emergency contact
                          </div>
                          <div style={{ color: wallpaperTextColor }} className="text-center">
                            {getMobileNumber()}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`${
                        isPortraitMode === true
                          ? 'ecards-social-icons mb-5'
                          : 'ecards-social-icons-landscape mr-1 mb-4 align-items-center w-100 d-flex justify-content-end flex-row position-absolute'
                      }`}>
                      <LinkedinIcon
                        size={32}
                        round={true}
                        onClick={(e) => {
                          e.stopPropagation();
                          shareWallpaper(index, e, 'Linkedin', item);
                        }}
                        className={`${isPortraitMode === true ? 'mb-1' : 'mr-1'}`}
                      />
                      <FacebookIcon
                        size={32}
                        round={true}
                        onClick={(e) => {
                          e.stopPropagation();
                          shareWallpaper(index, e, 'Facebook', item);
                        }}
                        className={`${isPortraitMode === true ? 'mb-1' : 'mr-1'}`}
                      />

                      <TwitterIcon
                        size={32}
                        round={true}
                        onClick={(e) => {
                          e.stopPropagation();
                          shareWallpaper(index, e, 'Twitter', item);
                        }}
                        className={`${isPortraitMode === true ? 'mb-1' : 'mr-1'}`}
                      />
                      <EmailIcon
                        size={32}
                        round={true}
                        onClick={(e) => {
                          e.stopPropagation();
                          shareWallpaper(index, e, 'email', item);
                        }}
                        className={`${isPortraitMode === true ? 'mb-1' : 'mr-1'}`}
                      />
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          downloadImage(index, e, item);
                        }}>
                        <img
                          src={getFeatureComponentUrl(
                            accessibilityData,
                            ACCESSIBILITY_FEATURE_COMPONENTS.ECARDS_DOWNLOAD_CARD,
                            ACCESSIBILITY_IDENTIFIERS.ECARDS
                          )}
                          className="ecards-download-icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </div>
      <ShareEmailModal
        showModal={showEmailModal}
        imageUrl={shareEmailImageUrl.current}
        hideModal={() => setShowEmailModal(false)}
        logEvent={() => {
          logEvent2({
            eventType: ANALYTICS_EVENT_TYPES.WALLPAPERS_SHARE,
            itemId: '',
            itemName: '',
            categoryId: ANALYTICS_ITEM_NAMES.ANNIVERSARY,
            categoryName: ANALYTICS_ITEM_NAMES.ANNIVERSARY,
          });
        }}
      />
    </div>
  );
};

export default WallpaperContentScreen;
