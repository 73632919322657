import React from 'react';
import Lottie from 'react-lottie';
import { useSelector } from 'react-redux';

import { selectProgressLoadingState } from '../../../state/UIState';
import ProgressLoaderAnimation from '../../../assets/files/progressLoader.json';

const ProgressDialog = () => {
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const lottieStyleConfig = { cursor: 'default' };

  if (progressLoadingState.isProgressLoading) {
    return (
      <div className="common-progress-dialog">
        <div className="d-flex align-items-center">
          <Lottie
            height={110}
            width={110}
            options={{
              loop: true,
              autoplay: true,
              animationData: ProgressLoaderAnimation,
            }}
            isClickToPauseDisabled
            style={lottieStyleConfig}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ProgressDialog;
