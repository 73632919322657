import React from 'react';

const FilterHeader = (props) => {
  return (
    <div className="d-flex justify-content-between">
      <h5 className="filter-header-text">{props.filterTitle}</h5>
    </div>
  );
};

export default FilterHeader;
