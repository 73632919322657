import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NOTIFICATION_MESSAGE_TYPES } from '../../../common/constants/AppConstants';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { fetchUserByIdOnce, uId } from '../../../utils/ChatHandler';

const NotificationCell = ({ item }) => {
  const [message, setMessage] = useState('');
  const employeeData = useSelector(selectEmployeeData);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    fetchUserByIdOnce(item.sender_id, (isExist, user) => {
      if (isExist) {
        setUserName(user.name);
      } else {
        setUserName('');
      }
    });
  }, [item.sender_id]);

  useEffect(() => {
    if (item) {
      if (item.message_type === NOTIFICATION_MESSAGE_TYPES.NEW_GROUP) {
        if (item.sender_id === uId(employeeData)) {
          setMessage('You created this group');
        } else {
          setMessage(`${userName} created this group`);
        }
      } else if (item.message_type === NOTIFICATION_MESSAGE_TYPES.ADD_MEMBER) {
        let membersName = '';
        if (item.members.length > 1) {
          if (item.members.length - 1 > 1) {
            membersName = `${item.members[0].name} and ${item.members.length - 1} others`;
          } else {
            membersName = `${item.members[0].name} and ${item.members.length - 1} other`;
          }
        } else {
          membersName = `${item.members[0].name}`;
        }
        if (item.sender_id === uId(employeeData)) {
          setMessage(`You added ${membersName}`);
        } else {
          setMessage(`${userName} added ${membersName}`);
        }
      } else if (item.message_type === NOTIFICATION_MESSAGE_TYPES.REMOVE_MEMBER) {
        let membersName = '';
        if (item.members[0].uid === uId(employeeData)) {
          membersName = 'You';
        } else {
          membersName = item.members[0].name;
        }
        if (item.sender_id === uId(employeeData)) {
          setMessage(`You removed ${membersName}`);
        } else {
          setMessage(`${userName} removed ${membersName}`);
        }
      } else if (item.message_type === NOTIFICATION_MESSAGE_TYPES.USER_LEFT) {
        let membersName = '';
        if (item.members[0].uid === uId(employeeData)) {
          membersName = 'You left';
        } else {
          membersName = `${item.members[0].name} left`;
        }
        setMessage(membersName);
      } else if (item.message_type === NOTIFICATION_MESSAGE_TYPES.CHANGE_GROUP_NAME) {
        if (item.sender_id === uId(employeeData)) {
          setMessage('You changed the group name');
        } else {
          setMessage(`${userName} changed the group name`);
        }
      } else if (item.message_type === NOTIFICATION_MESSAGE_TYPES.CHANGE_GROUP_IMAGE) {
        if (item.sender_id === uId(employeeData)) {
          setMessage('You changed the group image');
        } else {
          setMessage(`${userName} changed the group image`);
        }
      }
    }
  }, [employeeData, item, userName]);

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="px-3 py-1 mb-2 chat-base-text-sm chat-notification-cell-container">
        {message}
      </div>
    </div>
  );
};

export default NotificationCell;
