import { call, put, takeEvery } from 'redux-saga/effects';
import { ACTION_TYPES, REQUEST_TYPE } from '../common/constants/AppConstants';
import { GET_USER_TOKEN, GET_USER_TOKEN_BY_EMAIL } from '../services/ApiUrls';
import { apiRequestGetToken } from '../services/Service';
import { saveJwtToken } from '../state/UIState';

export function* getToken(action) {
  try {
    const apiResponse = yield call(
      apiRequestGetToken,
      GET_USER_TOKEN,
      REQUEST_TYPE.GET,
      action.payload
    );
    if (apiResponse) {
      if (apiResponse.response.status === true) {
        yield put(saveJwtToken(apiResponse.response.token));
      }
    }
  } catch (e) {
    yield put(saveJwtToken(''));
  }
}

export function* watchToken() {
  // yield takeEvery(ACTION_TYPES.GET_TOKEN, getToken);
}

export function* getTokenByEmail(action) {
  try {
    const apiResponse = yield call(
      apiRequestGetToken,
      GET_USER_TOKEN_BY_EMAIL,
      REQUEST_TYPE.GET,
      action.payload
    );
    if (apiResponse) {
      if (apiResponse.response.status === true) {
        yield put(saveJwtToken(apiResponse.response.token));
      }
    }
  } catch (e) {
    yield put(saveJwtToken(''));
  }
}

export function* watchTokenByEmail() {
  yield takeEvery(ACTION_TYPES.GET_TOKEN_BY_EMAIL, getTokenByEmail);
}
