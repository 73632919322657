import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { CHEERS_REDEEM_POINTS } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { Modal } from 'react-bootstrap';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { showToast } from '../../utils/common';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';

const RedeemPointsScreen = ({ history, location }) => {
  const dispatch = useDispatch();
  const { currentBalance } = location.state;
  const [amount, setAmount] = useState('');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const accessibilityData = useSelector(selectAccessibilityData);

  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.REDEEM_POINTS_SCREEN,
      NAVIGATION_ROUTES.REDEEM_POINTS_SCREEN
    );
  }, []);

  const callRedeemPointsApi = async () => {
    const params = new URLSearchParams();
    params.append('points', parseInt(amount));
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(CHEERS_REDEEM_POINTS, REQUEST_TYPE.POST, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          history.replace(NAVIGATION_ROUTES.POINTS_REDEEMED_SCREEN, {
            points: amount,
            data: apiResponse.response.data,
          });
        }
      }
    } catch (err) {
      handleError(err, params, CHEERS_REDEEM_POINTS, NAVIGATION_ROUTES.REDEEM_POINTS_SCREEN);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressRedeem = () => {
    if (amount.trim() === '' || amount === '0' || parseInt(amount) < 100) {
      showToast(ERROR_MESSAGES.MINIMUM_POINTS_TO_REDEEM);
    } else if (parseInt(amount) % 10 !== 0) {
      showToast(ERROR_MESSAGES.POINTS_TO_REDEEM_SHOULD_BE_MULTIPLES_OF_10);
    } else if (parseInt(amount) > 100000) {
      showToast(ERROR_MESSAGES.MAXIMUM_POINTS_TO_REDEEM);
    } else {
      setShowConfirmDialog(true);
    }
  };

  const onPressConfirm = () => {
    callRedeemPointsApi();
    setShowConfirmDialog(false);
  };

  const onTextChange = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      setAmount(event.target.value);
    }
  };

  const onPressCancelRegister = () => {
    setShowConfirmDialog(false);
  };

  const confirmDialog = () => {
    return (
      <div>
        <div className="d-flex flex-row-reverse">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_CLOSE,
              ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
            )}
            className="header-icon"
            onClick={onPressCancelRegister}
          />
        </div>
        <div className="text-center mt-3 cheers-register-message-text">Confirmation</div>
        <div className="text-center mt-3 cheers-you-redeeming">{`You are Redeeming ${amount} Points from your point balance.`}</div>
        <CustomButton
          buttonStyle="cheers-button-style mt-3"
          onClick={onPressConfirm}
          showLoader={progressLoadingState.isProgressLoading}>
          Confirm
        </CustomButton>
      </div>
    );
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CHEERS} />
      <BackButton />
      <div className="main-content-container">
        <Modal
          show={showConfirmDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => setShowConfirmDialog(false)}>
          <Modal.Body>{confirmDialog()}</Modal.Body>
        </Modal>
        <div className="cheers-upper-view">
          <div>
            <div className="text-center cheers-total-count-text">{currentBalance}</div>
            <div className="text-center mt-2 cheers-total-point-bal">Total Point Balance</div>
          </div>
        </div>
        <div className="cheers-below-view">
          <div>
            <div className="text-center mt-3 cheers-points-tobe-redeemed">
              Points to be Redeemed:
            </div>
            <div className="cheers-team-micro-view mt-3">
              <input
                type="text"
                className="ml-3 w-100 centered-placeholder"
                onChange={onTextChange}
                value={amount}
                placeholder="Enter between 100 to 100000"
              />
            </div>
            <div className="text-center mt-3 cheers-redeem-points">
              *Redeeming points should be multiple of 10
            </div>
          </div>
        </div>
        <CustomButton buttonStyle="cheers-button-style mt-2 mr-3" onClick={onPressRedeem}>
          Redeem
        </CustomButton>
      </div>
    </div>
  );
};

export default RedeemPointsScreen;
