import React, { useState, useEffect } from 'react';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import { selectOurCustomersTitles } from '../../state/MasterData';
import { saveOurCustomerTabData, selectOurCustomerTabData } from '../../state/TabData';
import { saveSearchText, selectBaseUrl } from '../../state/UIState';
import { Tab } from 'react-bootstrap';
import OurCustomerTab from './tabs/OurCustomerTab';
import AccoladesTab from './tabs/AccoladesTab';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import NavTabs from '../../common/ui/tabs/NavTabs';
import VisitsTab from './tabs/VisitsTab';
import WinsTab from './tabs/WinsTab';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { useHistory } from 'react-router-dom';
import SearchComponent from '../../common/ui/search/SearchComponent';
import MicroViewTab from './tabs/MicroViewTab';
import { selectAccessData } from '../../state/UserAccessData';

const OurCustomerMainScreen = ({ location }) => {
  const ourCustomersTabs = useSelector(selectOurCustomersTitles);
  const ourCustomersTabdata = useSelector(selectOurCustomerTabData);
  const dispatch = useDispatch();
  const [tabKey] = useState(
    location && location.state && location.state.tabKey && location.state.tabKey
  );
  const baseUrl = useSelector(selectBaseUrl);
  const accessibilityData = useSelector(selectAccessibilityData);
  const history = useHistory();
  const accessData = useSelector(selectAccessData);
  const [showMicroViewTab, setShowMicroViewTab] = useState(true);

  const customerAccessData = accessData?.data?.find((item) => item.feature_key === '_ourCustomers');

  useEffect(() => {
    const actions = customerAccessData?.actions;
    setShowMicroViewTab(actions?.includes('_microview'));
  }, [customerAccessData?.actions]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.OUR_CUSTOMER, NAVIGATION_ROUTES.OUR_CUSTOMER);
  }, []);

  useEffect(() => {
    if (ourCustomersTabs.length && ourCustomersTabdata === '' && tabKey) {
      dispatch(saveOurCustomerTabData(tabKey));
    } else if (ourCustomersTabs.length && ourCustomersTabdata === '') {
      dispatch(saveOurCustomerTabData(ourCustomersTabs[0].key));
    }
  }, [dispatch, ourCustomersTabdata, ourCustomersTabs, tabKey]);

  const renderTabs = (tab) => {
    switch (tab.key) {
      case 'accolades':
        return <AccoladesTab type={tab.key} title={tab.title} />;
      case 'visits':
        return <VisitsTab type={tab.key} title={tab.title} />;
      case 'wins':
        return <WinsTab type={tab.key} title={tab.title} />;
      case 'testimonials':
        return <AccoladesTab type={tab.key} title={tab.title} />;
      case 'researches':
        return <MicroViewTab type={tab.key} title={tab.title} />;
      default:
        return <OurCustomerTab type={tab.key} title={tab.title} />;
    }
  };

  const handleSubmitFunction = (searchText) => {
    dispatch(saveSearchText(searchText));
    history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_KNOW_SEARCH_SCREEN, {
      type: ourCustomersTabdata,
      searchQuery: searchText,
    });
  };

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
        <SearchComponent handleSubmitFunction={handleSubmitFunction} />
      </div>

      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.OUR_CUSTOMERS} />
      {baseUrl !== '' && (
        <NavTabs
          activeKey={ourCustomersTabdata}
          onSelect={(k) => dispatch(saveOurCustomerTabData(k))}
          unmountOnExit>
          {ourCustomersTabs &&
            ourCustomersTabs.map((tab, index) => {
              if (tab.key === 'researches' && !showMicroViewTab) {
                return null;
              }
              return (
                <Tab
                  key={`${index}`}
                  eventKey={tab.key}
                  title={tab.title}
                  className="main-content-container">
                  {renderTabs(tab, index)}
                </Tab>
              );
            })}
        </NavTabs>
      )}
    </div>
  );
};

export default OurCustomerMainScreen;
