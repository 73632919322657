import React from 'react';

const InfoTabItem = ({ item, onPressItem }) => {
  return (
    <div onClick={() => onPressItem(item)} className="mb-3 common-cursor-pointer">
      <div className="material-card p-3">
        <div className="text-center breakouts-info-title">{item.title}</div>
      </div>
    </div>
  );
};

export default InfoTabItem;
