import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { GET_PARTICIPANTS } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../state/UIState';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import ParticipatesListItem from './components/ParticipatesListItem';

const VolunteerParticipatesScreen = ({ location }) => {
  const dispatch = useDispatch();
  const { microgiveInitiativeDetailId } = location.state;
  const [volunteerList, setVolunteerList] = useState([]);
  const [participantCount, setParticipantCount] = useState(0);
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);

  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.VOLUNTEER_PARTICIPATES_SCREEN,
      NAVIGATION_ROUTES.VOLUNTEER_PARTICIPATES_SCREEN
    );
  }, []);

  const callVolunteerLogApi = useCallback(
    async (id, loadMore) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        const params = {
          page_id: id,
        };
        try {
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          const apiResponse = await apiRequest(
            GET_PARTICIPANTS + microgiveInitiativeDetailId + '/participants',
            REQUEST_TYPE.GET,
            params
          );
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.participants.length > 0) {
                setVolunteerList((oldArray) => [
                  ...oldArray,
                  ...apiResponse.response.data.participants,
                ]);
                setParticipantCount(apiResponse?.response?.data?.participant_count);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(
            err,
            params,
            GET_PARTICIPANTS + microgiveInitiativeDetailId + '/participants',
            NAVIGATION_ROUTES.VOLUNTEER_PARTICIPATES_SCREEN
          );
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      }
    },
    [dispatch, microgiveInitiativeDetailId]
  );

  useEffect(() => {
    callVolunteerLogApi(1, true);
  }, [callVolunteerLogApi]);

  const onPressLoadMore = () => {
    callVolunteerLogApi(pageId, loadMoreFlag);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.SEARCH_NO_RESULTS} />;
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICROGIVE} />
      <BackButton />
      <div className="main-content-container">
        <div className="microgive-total-donors-text">
          {volunteerList.length > 0 && participantCount === 1
            ? `${participantCount} Participant`
            : `${participantCount} Participants`}
        </div>
        {showEmptyContentView &&
        !volunteerList.length &&
        !progressLoadingState.isProgressLoading ? (
          emptyListView()
        ) : (
          <div>
            {volunteerList.map((item, index) => {
              return <ParticipatesListItem item={item} key={`${index}`} />;
            })}
            {loadMoreFlag && (
              <LoadMoreBtn
                onClick={onPressLoadMore}
                isLoading={progressLoadingState.isProgressLoading}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default VolunteerParticipatesScreen;
