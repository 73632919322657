import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router';

import {
  WISH_TYPES,
  ERROR_MESSAGES,
  REQUEST_TYPE,
  SCREEN_TITLE,
  MESSAGES,
} from '../../constants/AppConstants';
import { showToast, isEmptyText } from '../../../utils/common';
import { apiRequest } from '../../../services/Service';
import { GET_CELEBRATION_MESSAGES, GET_WISHES, SEND_MESSAGE } from '../../../services/ApiUrls';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmployeeData } from '../../../state/EmployeeData';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../../state/UIState';
import CelebrationMessagesModal from './components/CelebrationMessagesModal';
import CommentBox from '../../ui/comment_box/CommentBox';
import SectionHeader from '../section_header/SectionHeader';
import WishesItem from './components/WishesItem';
import DefaultContentView from '../default_content_view/DefaultContentView';
import DefaultBirthdayContentView from '../default_content_view/DefaultBirthdayContentView';

const WishesScreen = ({ navigation, location }) => {
  const dispatch = useDispatch();
  const { wishType, employeeId, year } = useParams();
  const employeeData = useSelector(selectEmployeeData);
  const [wishesData, setWishesData] = useState([]);
  const [celebrationMessages, setCelebrationMessages] = useState({});
  const [pageId, setPageId] = useState(1);
  const [commentText, setCommentText] = useState('');
  const [error, setError] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isUserOwnWishScreen, setIsUserOwnWishScreen] = useState(true);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);

  useEffect(() => {
    if (employeeData && Object.keys(employeeData).length > 0) {
      if (`${employeeId}` === `${employeeData.employee_id}`) {
        setIsUserOwnWishScreen(true);
      } else {
        setIsUserOwnWishScreen(false);
      }
    }
  }, [employeeData, employeeId]);

  useEffect(() => {
    if (!isUserOwnWishScreen) {
      setModalVisible(true);
    } else {
      setModalVisible(false);
    }
  }, [isUserOwnWishScreen]);

  const callApi = useCallback(async () => {
    dispatch(saveShowEmptyContentView(true));
    try {
      setError(false);
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      let params = {
        page_id: pageId,
        employee_id: employeeId,
        year: year,
      };
      if (pageId === 1) {
        const [wishesApiResponse, celebrationMessageApiResponse] = await Promise.all([
          apiRequest(GET_WISHES + wishType, REQUEST_TYPE.GET, params),
          apiRequest(GET_CELEBRATION_MESSAGES + employeeId, REQUEST_TYPE.GET, {}),
        ]);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(wishesApiResponse).length) {
          if (wishesApiResponse.response.status) {
            const data = wishesApiResponse.response.data.feeds;
            setWishesData(data);
            setPageId((prevCount) => prevCount + 1);
          }
        }
        if (Object.keys(celebrationMessageApiResponse).length) {
          if (celebrationMessageApiResponse.response.status) {
            const data = celebrationMessageApiResponse.response.data.messages;
            setCelebrationMessages(data);
          }
        }
      } else {
        const wishesApiResponse = await apiRequest(GET_WISHES + wishType, REQUEST_TYPE.GET, params);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(wishesApiResponse).length) {
          if (wishesApiResponse.response.status) {
            const data = wishesApiResponse.response.data.feeds;
            if (data.length) {
              setWishesData((oldArray) => [...oldArray, ...data]);
              setPageId((prevCount) => prevCount + 1);
            }
          }
        }
      }
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      setError(true);
    }
  }, [dispatch, employeeId, pageId, wishType, year]);

  useEffect(() => {
    callApi();
  }, [callApi]);

  const onToggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const onChangeTextInput = (val) => {
    setCommentText(val);
  };

  const refreshScreen = async () => {
    setCommentText('');
    const params = {
      page_id: 1,
      employee_id: employeeId,
      year: year,
    };
    const wishesApiResponse = await apiRequest(GET_WISHES + wishType, REQUEST_TYPE.GET, params);
    if (Object.keys(wishesApiResponse).length) {
      if (wishesApiResponse.response.status) {
        const data = wishesApiResponse.response.data.feeds;
        setWishesData(data);
        setPageId(2);
      }
    }
  };

  const sendCommentFunction = async () => {
    if (!isEmptyText(commentText)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      try {
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        const params = new URLSearchParams();
        params.append('employee_id', employeeId);
        params.append('message', commentText);
        params.append('year', year);
        const apiResponse = await apiRequest(SEND_MESSAGE + wishType, REQUEST_TYPE.POST, params);
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            const message = apiResponse.response.message;
            if (message) {
              showToast(message);
              await refreshScreen();
            }
          }
        }
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      } catch (err) {
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        setError(true);
      }
    }
  };

  const getMessagesFromWishType = () => {
    let messages = [];
    if (wishType === WISH_TYPES.BIRTHDAY) {
      messages = celebrationMessages.BIRTHDAY;
    } else if (wishType === WISH_TYPES.ANNIVERSARY) {
      messages = celebrationMessages.ANNIVERSARY;
    } else if (wishType === WISH_TYPES.ADVANCEMENT) {
      messages = celebrationMessages.ADVANCEMENT;
    } else if (wishType === WISH_TYPES.NEW_JOINEE) {
      messages = celebrationMessages.NEWJOINEE;
    }
    return messages;
  };

  const renderWishes = (item, index) => {
    return (
      <WishesItem
        key={`${index}`}
        date={item.sent_on}
        employee={item.employee}
        message={item.message}
      />
    );
  };

  const emptyListView = () => {
    return <DefaultContentView message={MESSAGES.NO_WISHES} />;
  };

  const emptyListViewBirthday = (type) => {
    return (
      <div>
        <DefaultBirthdayContentView
          message={`Be the first to wish ${location.state.employeeName}`}
          type={type}
        />
      </div>
    );
  };

  return (
    <div className="container main-content-container">
      <CelebrationMessagesModal
        modalVisible={modalVisible && pageId > 1}
        toggleModal={onToggleModal}
        onChangehandler={onChangeTextInput}
        data={getMessagesFromWishType()}
      />
      <SectionHeader containerClass="mb-3" title={SCREEN_TITLE.WISHES} />
      <div>
        {!wishesData.length && showEmptyContentView && !progressLoadingState.isProgressLoading ? (
          employeeData.employee_id === employeeId ? (
            emptyListViewBirthday(wishType)
          ) : (
            emptyListView()
          )
        ) : (
          <div>
            {wishesData.map((wish, index) => {
              return renderWishes(wish, index);
            })}
          </div>
        )}
      </div>

      <CommentBox
        onChangehandler={onChangeTextInput}
        commentText={commentText}
        getCommentText={sendCommentFunction}
        showLoader={progressLoadingState.isProgressLoading}
        iconName={isUserOwnWishScreen ? undefined : 'wishes'}
        openWishDialog={onToggleModal}
      />
    </div>
  );
};

export default WishesScreen;
