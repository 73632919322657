import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { GET_MY_REPORTERS } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectNominationList } from '../../state/MasterData';
import { saveProgressLoadingState } from '../../state/UIState';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import NominationsListItem from './components/NominationsListItem';

const NominationsScreen = ({ history }) => {
  const dispatch = useDispatch();
  const [canApprove, setCanApprove] = useState(false);
  const [renderList, setRenderList] = useState(false);

  const nominationsList = useSelector(selectNominationList);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.NOMINATIONS_SCREEN, NAVIGATION_ROUTES.NOMINATIONS_SCREEN);
  }, []);

  const callMyReporterApi = useCallback(async () => {
    const params = {
      page_id: '1',
      award_key: '',
      query: '',
    };
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(GET_MY_REPORTERS, REQUEST_TYPE.GET, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setRenderList(true);
          if (apiResponse.response.data.feeds.length === 0) {
            setCanApprove(true);
          }
        }
      }
    } catch (err) {
      handleError(err, params, GET_MY_REPORTERS, NAVIGATION_ROUTES.NOMINATIONS_SCREEN);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch]);

  useEffect(() => {
    callMyReporterApi();
  }, [callMyReporterApi]);

  const onPress = (item, index) => {
    switch (item.key) {
      case 'self':
        history.push(NAVIGATION_ROUTES.SELF_SCREEN);
        break;
      case 'my_manager':
        history.push(NAVIGATION_ROUTES.MY_MANAGER_SCREEN);
        break;
      case 'peer':
        history.push(NAVIGATION_ROUTES.PEER_SCREEN, {});
        break;
      case 'my_team':
        history.push(NAVIGATION_ROUTES.MY_TEAM_SCREEN, {});
        break;
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CHEERS} />
      <BackButton />
      {renderList === true && (
        <div className="main-content-container">
          {nominationsList.map((item, index) => {
            return (
              <NominationsListItem
                item={item}
                index={index}
                onPress={onPress}
                canApprove={canApprove}
                key={`${index}`}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default NominationsScreen;
