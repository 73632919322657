import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { getFeatureComponentUrl, getFeatureComponentUrlLdpi } from '../../../utils/accessibility';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  NAVIGATION_ROUTES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../constants/AppConstants';
import { getUrlWithSpecificRendition } from '../../../utils/common';

const UserProfilePhoto = (props) => {
  const history = useHistory();
  const accessibilityData = useSelector(selectAccessibilityData);
  const employeeData = useSelector(selectEmployeeData);
  const [badgeUrl, setBadgeUrl] = useState('');

  const {
    imageBaseUrl = '',
    imagePath,
    employeeId,
    imageStyle = {},
    imageClass = '',
    showBadge = false,
    prependOnClick = () => {},
    onClick = (e) => {
      e.stopPropagation();
      prependOnClick();
      if (props.fromRoute === NAVIGATION_ROUTES.MY_PROFILE_DETAIL) {
        null;
      } else if (employeeId) {
        if (employeeId === employeeData.employee_id) {
          history.push(NAVIGATION_ROUTES.MY_PROFILE_DETAIL);
        } else {
          history.push(`${NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL}/${employeeId}`);
        }
      } else if (props.profileData && Object.keys(props.profileData).length) {
        if (props.profileData.employee_id === employeeData.employee_id) {
          history.push(NAVIGATION_ROUTES.MY_PROFILE_DETAIL);
        } else {
          history.push(
            `${NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL}/${props.profileData.employee_id}`
          );
        }
      }
    },
  } = props;

  useEffect(() => {
    if (showBadge && props.profileData && Object.keys(props.profileData)) {
      let badge;
      let serviceYears = props.profileData.service_years;
      if (serviceYears >= 1 && serviceYears < 5)
        badge = ACCESSIBILITY_FEATURE_COMPONENTS.DIRECTORY_BADGE_0_4;
      else if (serviceYears >= 5 && serviceYears < 10)
        badge = ACCESSIBILITY_FEATURE_COMPONENTS.DIRECTORY_BADGE_5_9;
      else if (serviceYears >= 10 && serviceYears < 15)
        badge = ACCESSIBILITY_FEATURE_COMPONENTS.DIRECTORY_BADGE_10_14;
      else if (serviceYears >= 15 && serviceYears < 20)
        badge = ACCESSIBILITY_FEATURE_COMPONENTS.DIRECTORY_BADGE_15_19;
      else if (serviceYears >= 20 && serviceYears < 25)
        badge = ACCESSIBILITY_FEATURE_COMPONENTS.DIRECTORY_BADGE_20_24;
      else if (serviceYears >= 25 && serviceYears < 30)
        badge = ACCESSIBILITY_FEATURE_COMPONENTS.DIRECTORY_BADGE_25_29;
      else if (serviceYears >= 30 && serviceYears < 35)
        badge = ACCESSIBILITY_FEATURE_COMPONENTS.DIRECTORY_BADGE_30;
      else if (serviceYears >= 35) badge = ACCESSIBILITY_FEATURE_COMPONENTS.DIRECTORY_BADGE_35;
      if (badge) {
        setBadgeUrl(
          getFeatureComponentUrl(accessibilityData, badge, ACCESSIBILITY_IDENTIFIERS.DIRECTORY)
        );
      }
    }
  }, [accessibilityData, props.profileData, showBadge]);

  return (
    <div onClick={onClick}>
      <div className="common-user-profile-photo-container">
        <img
          className={`mx-auto d-block ${imageClass}`}
          src={
            imagePath
              ? getUrlWithSpecificRendition(imageBaseUrl, SCREEN_DENSITY_QUALIFIERS.LDPI, imagePath)
              : getFeatureComponentUrlLdpi(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
                  ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                )
          }
          style={imageStyle}
        />
        {showBadge &&
          !!badgeUrl &&
          !!props.profileData &&
          !!Object.keys(props.profileData) &&
          !!props.profileData.service_years && (
            <div>
              <img className="common-user-profile-photo-badge" src={badgeUrl} />
              <p
                className={
                  props.profileData.service_years >= 1
                    ? 'common-user-profile-photo-1yr-badge-text'
                    : 'common-user-profile-photo-badge-text'
                }>
                {props.profileData.service_years}+
              </p>
            </div>
          )}
      </div>
    </div>
  );
};

export default UserProfilePhoto;
