import { createAction, createSlice } from '@reduxjs/toolkit';

const signOutAction = createAction('signout');

const saveCurrentPointsDataReducer = (state, action) => {
  state.currentPoints = action.payload;
};

// REDUCER
const cheersDataSlice = createSlice({
  name: 'cheersData',
  initialState: {
    currentPoints: '',
  },
  extraReducers: {
    [signOutAction]: (state) => {
      return {
        ...state,
        currentPoints: '',
      };
    },
  },
  reducers: {
    saveCurrentPointsData: saveCurrentPointsDataReducer,
  },
});

// ACTIONS
const { saveCurrentPointsData } = cheersDataSlice.actions;

// SELECTOR
const selectCurrentPointsData = ({ cheersData }) => cheersData.currentPoints;

const cheersDataSliceReducer = cheersDataSlice.reducer;

export { cheersDataSliceReducer, cheersDataSlice, saveCurrentPointsData, selectCurrentPointsData };
