import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ENCODING_TYPE,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  TRAVEL_REQUEST_STATUS_IDENTIFIERS,
  TRAVEL_REQUEST_TAB_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import {
  saveTravelRequestData,
  selectTravellerData,
  selectTravelRequestDashboardData,
} from '../../../state/TravelRequestMasterData';
import { ADD_EDIT_TRAVEL_REQUEST, GET_REQUEST_BY_ID } from '../../../services/ApiUrls';
import { getFormattedDate, showToast } from '../../../utils/common';
import { apiRequest, handleError } from '../../../services/Service';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { saveProgressLoadingState } from '../../../state/UIState';
import { useHistory, useLocation } from 'react-router-dom';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import PlanTravelContent from '../components/PlanTravelContent';
import TravelRequestJustificationDialog from '../components/TravelRequestJustificationDialog';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import { saveTravelRequestTabData } from '../../../state/TabData';
import { selectMultilineTextSize } from '../../../state/MasterData';
import TravelRequestConfirmDialog from '../components/TravelRequestConfirmDialog';

const PlanTravelTab = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [isJustificationDialogVisible, setIsJustificationDialogVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [actionRequests, setActionRequests] = useState([]);
  const [isNoTravelConfirmDialogVisible, setIsNoTravelConfirmDialogVisible] = useState(false);
  const [isNoAccConfirmDialogVisible, setIsNoAccConfirmDialogVisible] = useState(false);
  const [isNoInputConfirmDialogVisible, setIsNoInputConfirmDialogVisible] = useState(false);
  const travelRequestDashboardData = useSelector(selectTravelRequestDashboardData);
  const employeeData = useSelector(selectEmployeeData);
  const travellerData = useSelector(selectTravellerData);
  const maxMultiLineCharLimit = useSelector(selectMultilineTextSize);
  const submitButtonTypeRef = useRef(1);
  const travelRequestFormRef = useRef();

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.TRAVEL_REQUEST_PLAN_TRAVEL_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.TRAVEL_REQUEST
    );
  }, []);

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.isClearForm &&
      travelRequestFormRef &&
      travelRequestFormRef.current &&
      travelRequestFormRef.current.values
    ) {
      travelRequestFormRef.current.resetForm();
    }
  }, [location]);

  useEffect(() => {
    if (
      location &&
      location.state &&
      location.state.isEditRequest &&
      location.state.requestFormData &&
      Object.keys(location.state.requestFormData) &&
      Object.keys(location.state.requestFormData).length > 0 &&
      travelRequestFormRef &&
      travelRequestFormRef.current &&
      travelRequestFormRef.current.values
    ) {
      let modifiedRequestFormData = { ...location.state.requestFormData };
      if (
        !location.state.requestFormData.tickets ||
        location.state.requestFormData.tickets.length === 0
      ) {
        modifiedRequestFormData = {
          ...modifiedRequestFormData,
          ...{
            tickets: [
              {
                from_city: '',
                to_city: '',
                destination_pref: 'D',
                destination_pref_date: '',
                destination_pref_time: '',
                travel_mode_id: '',
                flight_class: {},
                train_coach: {},
                bus_coach: {},
                comments: '',
                travellers:
                  travelRequestFormRef.current.values.on_behalf === 'E' &&
                  travelRequestFormRef.current.values.employee &&
                  Object.keys(travelRequestFormRef.current.values.employee) &&
                  Object.keys(travelRequestFormRef.current.values.employee).length > 0 &&
                  travelRequestFormRef.current.values.employee.employee_code !==
                    employeeData.employee_code
                    ? [travelRequestFormRef.current.values.employee]
                    : [travellerData],
              },
            ],
          },
        };
      }
      if (
        !location.state.requestFormData.accommodations ||
        location.state.requestFormData.accommodations.length === 0
      ) {
        modifiedRequestFormData = {
          ...modifiedRequestFormData,
          ...{
            accommodations: [
              {
                accommodation_booking_id: '',
                destination: '',
                check_in_date: '',
                check_out_date: '',
                property_type_id: '',
                number_of_rooms: 0,
                number_adults: 0,
                number_childrens: 0,
                comments: '',
                travellers:
                  travelRequestFormRef.current.values.on_behalf === 'E' &&
                  travelRequestFormRef.current.values.employee &&
                  Object.keys(travelRequestFormRef.current.values.employee) &&
                  Object.keys(travelRequestFormRef.current.values.employee).length > 0 &&
                  travelRequestFormRef.current.values.employee.employee_code !==
                    employeeData.employee_code
                    ? [travelRequestFormRef.current.values.employee]
                    : [travellerData],
              },
            ],
          },
        };
      }
      travelRequestFormRef.current.setValues(modifiedRequestFormData);
    }
  }, [employeeData.employee_code, location, travellerData]);

  useEffect(() => {
    if (travelRequestDashboardData?.action_requests?.length > 0) {
      setActionRequests(travelRequestDashboardData?.action_requests);
    }
  }, [travelRequestDashboardData]);

  const travelPlanValidationSchema = yup.object().shape({
    purpose: yup
      .string()
      .required('Please enter a purpose')
      .min(2, 'Purpose cannot be less than 2 characters'),
    tickets: yup
      .array()
      .of(
        yup.object().shape(
          {
            from_city: yup.string().when(['to_city'], {
              is: (a) => a !== undefined,
              then: yup
                .string()
                .required('Please enter from city')
                .min(2, 'From city cannot be less than 2 characters')
                .max(
                  maxMultiLineCharLimit,
                  'From city has exceeded the character limit. Please try shortening the text'
                ),
            }),
            to_city: yup.string().when(['from_city'], {
              is: (a) => a !== undefined,
              then: yup
                .string()
                .required('Please enter to city')
                .min(2, 'To city cannot be less than 2 characters')
                .max(
                  maxMultiLineCharLimit,
                  'To city has exceeded the character limit. Please try shortening the text'
                ),
            }),
            destination_pref_date: yup.string().when(['from_city', 'to_city'], {
              is: (a, b) => a !== undefined || b !== undefined,
              then: yup.string().required('Please select travel date'),
            }),
            destination_pref_time: yup.string().when(['from_city', 'to_city'], {
              is: (a, b) => a !== undefined || b !== undefined,
              then: yup.string().required('Please select travel time'),
            }),
            travel_mode_id: yup.string().when(['from_city', 'to_city'], {
              is: (a, b) => a !== undefined || b !== undefined,
              then: yup.string().required('Please select travel mode'),
            }),
            travellers: yup.array().when(['from_city', 'to_city'], {
              is: (a, b) => a !== undefined || b !== undefined,
              then: yup
                .array()
                .of(
                  yup.object().shape({
                    first_name: yup
                      .string()
                      .required(
                        'One or more of your traveller first name is missing, please update'
                      )
                      .max(
                        maxMultiLineCharLimit,
                        'One or more of your traveller first name has exceeded the character limit. Please try shortening the first name'
                      ),
                    last_name: yup
                      .string()
                      .required('One or more of your traveller last name is missing, please update')
                      .max(
                        maxMultiLineCharLimit,
                        'One or more of your traveller last name has exceeded the character limit. Please try shortening the last name'
                      ),
                    gender: yup
                      .string()
                      .required(
                        'One or more of your traveller gender detail is missing, please update'
                      ),
                    mobile_number: yup
                      .string()
                      .required(
                        'One or more of your traveller mobile number is missing, please update'
                      )
                      .max(
                        16,
                        'One or more of your traveller has an invalid mobile number, please update'
                      )
                      .min(
                        6,
                        'One or more of your traveller has an invalid mobile number, please update'
                      )
                      .nullable(),
                  })
                )
                .required('You must add atleast 1 traveller to proceed')
                .min(1, 'You must add atleast 1 traveller to proceed'),
            }),
          },
          ['to_city', 'from_city']
        )
      )
      .required('You must add atleast 1 travel to proceed')
      .min(1, 'You must add atleast 1 travel to proceed'),

    accommodations: yup
      .array()
      .of(
        yup.object().shape(
          {
            destination: yup
              .string()
              .min(2, 'Destination cannot be less than 2 characters')
              .max(
                maxMultiLineCharLimit,
                'Destination has exceeded the character limit. Please try shortening the text'
              ),
            check_in_date: yup.string().when(['destination'], {
              is: (a) => a !== undefined,
              then: yup.string().required('Please select check in date'),
            }),
            check_out_date: yup.string().when(['destination'], {
              is: (a) => a !== undefined,
              then: yup.string().required('Please select check out date'),
            }),
            property_type_id: yup.string().when(['destination'], {
              is: (a) => a !== undefined,
              then: yup.string().required('Please select property type'),
            }),
            travellers: yup.array().when(['destination'], {
              is: (a) => a !== undefined,
              then: yup
                .array()
                .of(
                  yup.object().shape({
                    first_name: yup
                      .string()
                      .required('One or more of your guests first name is missing, please update')
                      .max(
                        maxMultiLineCharLimit,
                        'One or more of your guests first name has exceeded the character limit. Please try shortening the first name'
                      ),
                    last_name: yup
                      .string()
                      .required('One or more of your guests last name is missing, please update')
                      .max(
                        maxMultiLineCharLimit,
                        'One or more of your guests last name has exceeded the character limit. Please try shortening the last name'
                      ),
                    gender: yup
                      .string()
                      .required(
                        'One or more of your guests gender detail is missing, please update'
                      ),
                    mobile_number: yup
                      .string()
                      .required(
                        'One or more of your guests mobile number is missing, please update'
                      )
                      .max(
                        16,
                        'One or more of your guests has an invalid mobile number, please update'
                      )
                      .min(
                        6,
                        'One or more of your guests has an invalid mobile number, please update'
                      )
                      .nullable(),
                  })
                )
                .required('You must add atleast 1 guest to proceed')
                .min(1, 'You must add atleast 1 guest to proceed'),
            }),
          },
          ['destination']
        )
      )
      .required('You must add atleast 1 guest to proceed')
      .min(1, 'You must add atleast 1 guest to proceed'),
  });

  const callAddEditRequestApi = useCallback(
    async (formValues) => {
      const params = { ...formValues };
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
          })
        );
        const apiResponse = await apiRequest(
          ADD_EDIT_TRAVEL_REQUEST,
          REQUEST_TYPE.POST,
          JSON.stringify(params),
          ENCODING_TYPE.RAW
        );
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            const message = apiResponse.response.message;
            if (message) {
              showToast(message);
              travelRequestFormRef.current.setValues({
                ...apiResponse.response.request,
                ...{ tickets: formValues.tickets },
                ...{ accommodations: formValues.accommodations },
              });
              if (submitButtonTypeRef.current === 2) {
                if (apiResponse.response.request.justification_required) {
                  setIsJustificationDialogVisible(true);
                } else {
                  history.push(
                    NAVIGATION_ROUTES.TRAVEL_REQUEST_REVIEW +
                      '/' +
                      apiResponse.response.request.request_id
                  );
                }
              }
            }
          }
        }
      } catch (err) {
        handleError(err, params, ADD_EDIT_TRAVEL_REQUEST, NAVIGATION_ROUTES.TRAVEL_REQUEST);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    },
    [dispatch, history]
  );

  const onSubmitTravelPlanForm = (formValues) => {
    if (
      (formValues.tickets.length < 1 ||
        formValues.tickets.every((travel) => !travel.from_city || !travel.to_city)) &&
      (formValues.accommodations.length < 1 ||
        formValues.accommodations.every((accommodation) => !accommodation.destination))
    ) {
      setIsNoInputConfirmDialogVisible(true);
    } else if (
      formValues.tickets.length < 1 ||
      formValues.tickets.every((travel) => !travel.from_city || !travel.to_city)
    ) {
      setIsNoTravelConfirmDialogVisible(true);
    } else if (
      formValues.accommodations.length < 1 ||
      formValues.accommodations.every((accommodation) => !accommodation.destination)
    ) {
      setIsNoAccConfirmDialogVisible(true);
    } else {
      callAddEditRequestApi(formValues);
    }
  };

  const callGetRequestByIdApi = useCallback(
    async (requestId) => {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      let params = { request_id: requestId };
      try {
        const apiResponse = await apiRequest(GET_REQUEST_BY_ID, REQUEST_TYPE.GET, params);
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            dispatch(saveTravelRequestData(apiResponse.response.request));
            dispatch(saveTravelRequestTabData(TRAVEL_REQUEST_TAB_IDENTIFIERS.PLAN_TRAVEL));
            history.replace(NAVIGATION_ROUTES.TRAVEL_REQUEST, {
              isEditRequest: true,
              requestFormData: apiResponse.response.request,
            });
          }
        }
      } catch (err) {
        handleError(err, params, GET_REQUEST_BY_ID, NAVIGATION_ROUTES.TRAVEL_REQUEST);
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    },
    [dispatch, history]
  );

  const onRequestProceedPress = (item) => {
    if (
      item.authorization_id &&
      item.status.identifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.PRE_APPROVED
    ) {
      dispatch(saveTravelRequestData(item));
      dispatch(saveTravelRequestTabData(TRAVEL_REQUEST_TAB_IDENTIFIERS.PLAN_TRAVEL));
      history.replace(NAVIGATION_ROUTES.TRAVEL_REQUEST, {
        isEditRequest: true,
        requestFormData: item,
      });
    } else if (item.status.identifier === TRAVEL_REQUEST_STATUS_IDENTIFIERS.DRAFT) {
      callGetRequestByIdApi(item.request_id);
    } else {
      history.push(NAVIGATION_ROUTES.TRAVEL_REQUEST_REVIEW + '/' + item.request_id);
    }
  };

  const renderActionRequestList = () => {
    return (
      <div className="d-flex flex-column travel-request-updates-container p-3 overflow-hidden">
        <div className="d-flex justify-content-start">
          <h5 className="mb-2 travel-request-updates-style">UPDATES</h5>
        </div>

        {actionRequests.length > 0 ? (
          actionRequests.map((item, index) => {
            return (
              <div
                key={'action_request_item' + index}
                className="action-request-item-style p-3 mb-3">
                <div className="d-flex">
                  <div className="flex-fill text-wrap">
                    <h5 className="mb-0 travel-request-purpose">{item.status.title}</h5>
                    <div className="d-flex align-items-center">
                      <p className="mb-0 travel-quick-request-purpose">{item.purpose}</p>
                    </div>
                    <p className="mb-0 travel-request-request-id-date">
                      {getFormattedDate(item.start_date, "DD MMM'YY")} -{' '}
                      {getFormattedDate(item.end_date, "DD MMM'YY")}
                    </p>
                  </div>
                  <div className="d-flex flex-column align-items-end ml-4 justify-content-center">
                    <p
                      className="mt-2 travel-request-primary-text-semi-bold common-cursor-pointer"
                      onClick={() => onRequestProceedPress(item)}>
                      PROCEED
                    </p>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="d-flex flex-grow-1 flex-column align-items-center justify-content-center">
            <p className="travel-empty-updates">No updates at the moment</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="main-content-container">
      <TravelRequestConfirmDialog
        isRquestConfirmDialogVisible={isNoTravelConfirmDialogVisible}
        setIsRquestConfirmDialogVisible={setIsNoTravelConfirmDialogVisible}
        title="Confirmation"
        subtitle="Are you sure you want to proceed without travel?"
        onPressFirstButton={() => setIsNoTravelConfirmDialogVisible(false)}
        onPressSecondButton={() => {
          setIsNoTravelConfirmDialogVisible(false);
          callAddEditRequestApi(travelRequestFormRef.current.values);
        }}
        firstButtonText="Cancel"
        secondButtonText="Proceed"
      />
      <TravelRequestConfirmDialog
        isRquestConfirmDialogVisible={isNoAccConfirmDialogVisible}
        setIsRquestConfirmDialogVisible={setIsNoAccConfirmDialogVisible}
        title="Confirmation"
        subtitle="Are you sure you want to proceed without accomodation?"
        onPressFirstButton={() => setIsNoAccConfirmDialogVisible(false)}
        onPressSecondButton={() => {
          setIsNoAccConfirmDialogVisible(false);
          callAddEditRequestApi(travelRequestFormRef.current.values);
        }}
        firstButtonText="Cancel"
        secondButtonText="Proceed"
      />
      <TravelRequestConfirmDialog
        isRquestConfirmDialogVisible={isNoInputConfirmDialogVisible}
        setIsRquestConfirmDialogVisible={setIsNoInputConfirmDialogVisible}
        title="No travel or accomodation added"
        subtitle="Please add a travel or accomodation to proceed"
        onPressSecondButton={() => {
          setIsNoInputConfirmDialogVisible(false);
        }}
        secondButtonText="Okay"
        isOnlySecondButtonEnabled={true}
      />
      <div>
        {travelRequestDashboardData &&
          Object.keys(travelRequestDashboardData) &&
          Object.keys(travelRequestDashboardData).length > 0 && (
            <div className="row">
              <div className="col-12 col-sm-8 col-lg-8 mb-3">
                <ActivityLoader visible={!!isLoading} />
                <Formik
                  validationSchema={travelPlanValidationSchema}
                  onSubmit={onSubmitTravelPlanForm}
                  validateOnMount={false}
                  innerRef={travelRequestFormRef}
                  initialValues={{
                    request_id: '',
                    request_code: '',
                    travel_type: 'O',
                    travel_range: 'D',
                    billing_type: 'N',
                    on_behalf: 'S',
                    project_code: '',
                    purpose: '',
                    authorization_id: '',
                    employee_code: '',
                    tickets: [
                      {
                        from_city: '',
                        to_city: '',
                        destination_pref: 'D',
                        destination_pref_date: '',
                        destination_pref_time: '',
                        travel_mode_id: '',
                        flight_class: {},
                        train_coach: {},
                        bus_coach: {},
                        comments: '',
                        travellers: [travellerData],
                      },
                    ],
                    accommodations: [
                      {
                        accommodation_booking_id: '',
                        destination: '',
                        check_in_date: '',
                        check_out_date: '',
                        property_type_id: '',
                        number_of_rooms: 0,
                        number_adults: 0,
                        number_childrens: 0,
                        comments: '',
                        travellers: [travellerData],
                      },
                    ],
                  }}>
                  {(formikProps) => (
                    <div>
                      <TravelRequestJustificationDialog
                        isJustificationDialogVisible={isJustificationDialogVisible}
                        setIsJustificationDialogVisible={setIsJustificationDialogVisible}
                        requestData={formikProps.values}
                      />
                      <PlanTravelContent
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        submitButtonTypeRef={submitButtonTypeRef}
                      />
                    </div>
                  )}
                </Formik>
              </div>
              <div className="col-12 col-sm-4 col-lg-4">{renderActionRequestList()}</div>
            </div>
          )}
      </div>
    </div>
  );
};

export default React.memo(PlanTravelTab);
