import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { selectBusinessDashboard } from '../../state/MasterData';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import HtmlView from '../../common/ui/html_view/HTMLView';

const BusinessDashboard = () => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const businessDashboard = useSelector(selectBusinessDashboard);

  const onPressLogin = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.BUSINESS_DASHBOARD_OPEN_WEBVIEW,
      '',
      ANALYTICS_ITEM_NAMES.BUSINESS_DASHBOARD
    );
    let win = window.open(`${businessDashboard._business_dashboard_url}`);
    win.focus();
  };

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.BUSINESS_DASHBOARD, NAVIGATION_ROUTES.BUSINESS_DASHBOARD);
  }, []);

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.BUSINESS_DASHBOARD} />
      <div className=" main-content-container">
        {businessDashboard && (
          <div>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.BUSINESS_DASHBOARD_BANNER,
                ACCESSIBILITY_IDENTIFIERS.BUSINESS_DASHBOARD
              )}
              className="business-dashboard-banner-image"
            />
            <HtmlView html={businessDashboard._business_dashboard_config} htmlStyle="mt-3" />
            <div className="d-flex justify-content-center">
              <CustomButton onClick={onPressLogin}>Login</CustomButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BusinessDashboard;
