import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { apiRequestEStore } from '../../services/Service';
import {
  resetLoaderState,
  saveProgressLoadingState,
  saveSearchText,
  selectProgressLoadingState,
  selectSearchText,
} from '../../state/UIState';
import { useParams, useHistory } from 'react-router';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import debounce from 'lodash.debounce';
import { IoArrowBackCircleOutline } from 'react-icons/io5';
import colors from '../../common/themes/Colors';
import { selectCartItemsCount } from '../../state/EStoreData';
import { selectEStoreConfig } from '../../state/MasterData';
import { logEvent, logEvent2, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { CATEGORY_LIST, GET_CHILDREN_OF_CONFIG_PRODUCT, PRODUCTS } from '../../services/ApiUrls';
import HtmlView from '../../common/ui/html_view/HTMLView';

const MiStoreAllProductsScreen = () => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const [categories, setCategories] = useState([]);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const estoreConfig = useSelector(selectEStoreConfig);
  const pageIdRef = useRef(1);
  const pageSize = 10;
  const sortType = useRef('');
  const { categoryId } = useParams();
  const history = useHistory();
  const searchData = useSelector(selectSearchText);
  const accessibilityData = useSelector(selectAccessibilityData);
  const cartItemsCount = useSelector(selectCartItemsCount);
  const [searchText, setSearchText] = useState(searchData ?? '');
  const [category, setCategory] = useState(categoryId);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [loaders, setLoaders] = useState([]);
  const [categoryTitle, setCategoryTitle] = useState('');

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.MICRO_STORE_ALL_PRODUCTS_SCREEN,
      NAVIGATION_ROUTES.MICRO_STORE_ALL_PRODUCTS_SCREEN
    );
  }, []);

  useEffect(() => {
    if (category) {
      const categoryTemp = categories.find((item) => item.id == category);
      if (categoryTemp) {
        setCategoryTitle(categoryTemp.name);
      }
    }
  }, [categories, category]);

  const fetchConfigurableProductPrice = useCallback(
    async (sku) => {
      setLoaders((prev) => [...prev, 1]);
      try {
        const apiResponse = await apiRequestEStore(
          GET_CHILDREN_OF_CONFIG_PRODUCT(sku),
          REQUEST_TYPE.GET
        );

        setLoaders((prev) => [...prev].slice(0, -1));
        if (Object.keys(apiResponse).length) {
          return apiResponse.response[0].price;
        } else {
          return 0;
        }
      } catch (err) {
        dispatch(resetLoaderState());
        setLoaders((prev) => [...prev].slice(0, -1));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    const fatchCollections = async () => {
      setLoaders((prev) => [...prev, 1]);
      try {
        const apiResponse = await apiRequestEStore(
          `${CATEGORY_LIST}?searchCriteria[pageSize]=100&searchCriteria[currentPage]=1`,
          REQUEST_TYPE.GET
        );

        if (Object.keys(apiResponse).length) {
          if (apiResponse.response && apiResponse.response.items?.length > 0) {
            const tempCategories = apiResponse.response.items?.map((item) => {
              const image =
                item.custom_attributes?.find((attr) => attr.attribute_code === 'image')?.value ??
                '';
              return { id: item.id, name: item.name, image };
            });
            const categoriesTemp = tempCategories.filter((item) => item.id !== 1);
            setCategories(categoriesTemp);
          }
        }
        setLoaders((prev) => [...prev].slice(0, -1));
      } catch (err) {
        setLoaders((prev) => [...prev].slice(0, -1));
      }
    };
    fatchCollections();
  }, [dispatch]);

  const fetchProducts = useCallback(
    async (query = '') => {
      setLoaders((prev) => [...prev, 1]);
      try {
        let endpoint = `${PRODUCTS}?searchCriteria[filter_groups][0][filters][0][field]=visibility&searchCriteria[filter_groups][0][filters][0][value]=1&searchCriteria[filter_groups][0][filters][0][condition_type]=neq&searchCriteria[filter_groups][1][filters][0][field]=status&searchCriteria[filter_groups][1][filters][0][value]=1&searchCriteria[filter_groups][1][filters][0][condition_type]=eq`;
        if (query.length > 0) {
          if (searchData) {
            dispatch(saveSearchText(''));
          }
          endpoint += `&searchCriteria[filter_groups][2][filters][0][field]=name&searchCriteria[filter_groups][2][filters][0][value]=%25${query}%25&searchCriteria[filter_groups][2][filters][0][conditionType]=like`;
        } else {
          endpoint += `&searchCriteria[pageSize]=${pageSize}&searchCriteria[currentPage]=${pageIdRef.current}`;
        }
        // if (sortType.current === 'new') {
        //   endpoint += `&searchCriteria[sortOrders][0][field]=created_at&searchCriteria[sortOrders][0][direction]=desc`;
        // } else if (sortType.current !== '') {
        //   endpoint += `&searchCriteria[sortOrders][0][field]=price&searchCriteria[sortOrders][0][direction]=${sortType.current}`;
        // }
        const apiResponse = await apiRequestEStore(endpoint, REQUEST_TYPE.GET);
        if (pageIdRef.current === 1) {
          setProducts([]);
        }
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response && apiResponse.response.items?.length > 0) {
            const tempProducts = [];
            for (const item of apiResponse.response.items) {
              if (item.visibility !== 1) {
                const images = [];
                let thumbnail = '';
                item.media_gallery_entries.map((image) => {
                  images.push({ type: image.media_type, file: image.file });
                  if (image.types.includes('thumbnail')) {
                    thumbnail = image.file;
                  }
                });
                const shortDescription = item.custom_attributes?.find(
                  (attr) => attr.attribute_code === 'short_description'
                )?.value;
                const price =
                  item.type_id === 'configurable'
                    ? await fetchConfigurableProductPrice(item.sku)
                    : item.price;
                tempProducts.push({
                  id: item.id,
                  sku: item.sku,
                  name: item.name,
                  price: price,
                  images,
                  shortDescription,
                  thumbnail,
                });
              }
            }
            if (pageIdRef.current === 1) {
              setProducts(tempProducts);
            } else {
              setProducts((prev) => [...prev, ...tempProducts]);
            }
          }
          if (apiResponse.response.items?.length < pageSize) {
            setLoadMoreEnabled(false);
          } else {
            setLoadMoreEnabled(true);
          }
        }
        setLoaders((prev) => [...prev].slice(0, -1));
      } catch (err) {
        setLoaders((prev) => [...prev].slice(0, -1));
        console.log(err);
      }
    },
    [dispatch, fetchConfigurableProductPrice, searchData]
  );

  const fetchProductsByCategory = useCallback(async () => {
    try {
      setLoaders((prev) => [...prev, 1]);
      const apiResponse = await apiRequestEStore(
        `${PRODUCTS}?searchCriteria[filter_groups][0][filters][0][field]=category_id&searchCriteria[filter_groups][0][filters][0][value]=${category}&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=visibility&searchCriteria[filter_groups][1][filters][0][value]=1&searchCriteria[filter_groups][1][filters][0][condition_type]=neq&searchCriteria[filter_groups][2][filters][0][field]=status&searchCriteria[filter_groups][2][filters][0][value]=1&searchCriteria[filter_groups][2][filters][0][condition_type]=eq`,
        REQUEST_TYPE.GET
      );
      setProducts([]);
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response && apiResponse.response?.items.length > 0) {
          const tempProducts = [];
          for (const item of apiResponse.response.items) {
            if (item.visibility !== 1) {
              const images = [];
              let thumbnail = '';
              item.media_gallery_entries.map((image) => {
                images.push({ type: image.media_type, file: image.file });
                if (image.types.includes('thumbnail')) {
                  thumbnail = image.file;
                }
              });
              const shortDescription = item.custom_attributes?.find(
                (attr) => attr.attribute_code === 'short_description'
              )?.value;
              const price =
                item.type_id === 'configurable'
                  ? await fetchConfigurableProductPrice(item.sku)
                  : item.price;
              tempProducts.push({
                id: item.id,
                sku: item.sku,
                name: item.name,
                price: price,
                images,
                shortDescription,
                thumbnail,
              });
            }
          }
          setProducts(tempProducts);
        }
      }
      setLoadMoreEnabled(false);
      setLoaders((prev) => [...prev].slice(0, -1));
    } catch (err) {
      setLoaders((prev) => [...prev].slice(0, -1));
      console.log(err);
    }
  }, [category, fetchConfigurableProductPrice]);

  useEffect(() => {
    if (loaders.length > 0) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
    } else {
      dispatch(resetLoaderState());
    }
  }, [dispatch, loaders]);

  useEffect(() => {
    if (!category && !searchText) {
      pageIdRef.current = 1;
      fetchProducts();
    }
  }, [fetchProducts, searchText, category]);

  useEffect(() => {
    if (category) {
      fetchProductsByCategory();
    }
  }, [fetchProductsByCategory, category]);

  useEffect(() => {
    if (searchText && searchText > 2) {
      setIsSearchEnabled(true);
      pageIdRef.current = 1;
      fetchProducts(searchText);
    }
  }, [dispatch, fetchProducts, searchText]);

  const loadMore = () => {
    pageIdRef.current = pageIdRef.current + 1;
    if (category) {
      fetchProductsByCategory();
    } else {
      fetchProducts();
    }
  };

  const handleCategory = (item) => {
    logEvent2({
      eventType: ANALYTICS_EVENT_TYPES.ESTORE_COLLECTION_CLICK,
      categoryId: item.id,
      categoryName: item.name,
    });
    setCategory(item.id);
  };

  const renderCategory = (category, index) => {
    return (
      <div
        onClick={() => handleCategory(category)}
        key={index}
        className="estore-all-products-collection-item common-cursor-pointer">
        <img
          className="estore-collection-item-image"
          src={`${estoreConfig.category_base_url}${category.image}`}
        />
      </div>
    );
  };

  const onPressItem = (item) => {
    logEvent(ANALYTICS_EVENT_TYPES.ESTORE_PRODUCT_CLICK, item.sku, ANALYTICS_ITEM_NAMES.PRODUCT);
    history.push(`${NAVIGATION_ROUTES.MICRO_STORE_PRODUCT_SCREEN}/${item.sku}`);
  };

  const newsArray =
    products &&
    products.map((item, index) => {
      return (
        <div
          key={`${index}`}
          className="estore-product-grid-item"
          onClick={() => onPressItem(item)}>
          {(item.images?.length || item.thumbnail) && (
            <img
              src={`${estoreConfig.image_base_url}${
                item.thumbnail ? item.thumbnail : item.images[0]?.file
              }`}
              className="estore-home-item-image"
            />
          )}
          <div className="estore-home-item-title-price-list">
            <div className="estore-product-name-list">{item.name}</div>
            <HtmlView
              html={item.shortDescription}
              htmlStyle="estore-regular-text-12 som-event-image-container"
            />
            {item.price > 0 && (
              <div className="estore-product-name-list pt-2">{`\u20b9 ${item.price}`}</div>
            )}
          </div>
        </div>
      );
    });

  const emptyListView = () => {
    return (
      <div style={{ alignSelf: 'center', width: '100%' }}>
        <DefaultContentView message={NO_DATA_TEXT.NO_DATA_FOUND} />
      </div>
    );
  };

  const onClickSearchHandler = () => {
    setIsSearchEnabled(true);
  };

  const debouncedCallback = (event) => {
    if (event && event.target.value.length > 2) {
      pageIdRef.current = 1;
      setLoadMoreEnabled(true);
      fetchProducts(event.target.value);
    }
    setSearchText(event.target.value);
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );

  const callSubmitFunction = (event) => {
    event?.preventDefault();
    if (searchText !== '') {
      pageIdRef.current = 1;
      setLoadMoreEnabled(true);
      setIsSearchEnabled(true);
      fetchProducts(searchText);
    }
  };

  const onInputChange = (event) => {
    setSearchText(event.target.value);
    debounceInputHandler(event);
    if (event.target.value === '') {
      setLoadMoreEnabled(false);
      setProducts([]);
    }
  };

  useEffect(() => {
    if (searchText) {
      callSubmitFunction();
    }
  }, []);

  const resetValue = () => {
    pageIdRef.current = 1;
    setIsSearchEnabled(false);
    setSearchText('');
    fetchProducts();
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICRO_STORE} />
      <div className="store-common-comment-header-view">
        <div
          className="d-flex align-items-center common-cursor-pointer"
          onClick={() => history.goBack()}>
          <IoArrowBackCircleOutline
            size="2.25em"
            className="mr-2"
            color={colors.silverChaliceLight}
          />
          <span className="common-back-button-title">
            {category && categoryTitle ? categoryTitle.toUpperCase() : 'ALL PRODUCTS'}
          </span>
        </div>
        <div className="d-flex flex-row align-items-center">
          <div>
            <div
              className={
                isSearchEnabled ? 'search-bar-with-icon search-bar-border' : 'search-bar-with-icon'
              }>
              {isSearchEnabled ? (
                <form className="directory-search-bar" onSubmit={callSubmitFunction}>
                  <input
                    autoFocus
                    className="ml-2 directory-search-bar"
                    type="text"
                    placeholder="Search.."
                    onChange={onInputChange}
                    value={searchText}
                  />
                </form>
              ) : (
                <div className="directory-search-bar" />
              )}
              {isSearchEnabled ? (
                <div className="gcdo-icon-row mr-2" onClick={resetValue}>
                  <img
                    src={getHeaderComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
                    )}
                    className="header-icon"
                  />
                </div>
              ) : (
                <div className="gcdo-icon-row mr-2" onClick={onClickSearchHandler}>
                  <img
                    src={getHeaderComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_HEADER_COMPONENTS.SEARCH
                    )}
                    className="header-icon"
                  />
                </div>
              )}
            </div>
          </div>
          <div
            className="gcdo-icon-row mr-3"
            onClick={() => {
              logEvent(ANALYTICS_EVENT_TYPES.ESTORE_CART_CLICK, '', ANALYTICS_ITEM_NAMES.CART);
              history.push(NAVIGATION_ROUTES.MICRO_STORE_CART_SCREEN);
            }}>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.CART_BLUE,
                ACCESSIBILITY_IDENTIFIERS.MICRO_STORE
              )}
              className="header-icon-estore ml-1"
            />
            {cartItemsCount > 0 && <div className="cart-items-count-chip">{cartItemsCount}</div>}
          </div>
        </div>
      </div>
      <div className="e-store-product-list-main">
        <div className="w-75 p-2">
          {products.length === 0 || newsArray.length === 0 ? (
            emptyListView()
          ) : (
            <div className="estore-all-products-grid">{newsArray}</div>
          )}
          {loadMoreEnabled && products.length > 0 && (
            <div className="mb-4 mt-4">
              <LoadMoreBtn onClick={loadMore} isLoading={progressLoadingState.isProgressLoading} />
            </div>
          )}
        </div>
        <div className="estore-collection-container">
          <div className="estore-colloections-title">COLLECTION</div>
          {categories.map(renderCategory)}
        </div>
      </div>
    </div>
  );
};

export default MiStoreAllProductsScreen;
