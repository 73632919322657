import React from 'react';
import { Modal } from 'react-bootstrap';
import CustomButton from '../../../common/ui/custom_button/CustomButton';

const CommonAlert = ({
  setAlertVisiblity,
  alertVisibility = false,
  title = 'Alert',
  message = '',
}) => {
  return (
    <Modal onHide={() => setAlertVisiblity(false)} show={alertVisibility} centered keyboard={false}>
      <Modal.Body className="p-4">
        <h5 className="ijp-refer-consent-modal-title text-center mb-4">{title}</h5>
        <p className="ijp-apply-modal-subtitle text-center mb-4">{message}</p>
        <div className="d-flex justify-content-center">
          <CustomButton
            onClick={() => setAlertVisiblity(false)}
            buttonStyle="common-custom-button-quaternary">
            OK
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CommonAlert;
