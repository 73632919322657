import React, { useState, useEffect, useCallback } from 'react';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../../services/Service';
import { GET_POLICIES } from '../../../services/ApiUrls';
import PolicyListItem from '../components/PolicyListItem';
import { useHistory } from 'react-router';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';

const PolicyTab = ({ tabKey, geographyId }) => {
  const history = useHistory();
  const [policy, setPolicy] = useState([]);
  const [loadMoreFlag, setLoadMoreFlag] = useState(true);
  const [pageId, setPageId] = useState(1);
  const [geoId, setGeoId] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.POLICIES_TAB_VISIT, tabKey, ANALYTICS_ITEM_NAMES.POLICIES);
  }, [tabKey]);

  useEffect(() => {
    if (geoId !== geographyId) {
      setGeoId(geographyId);
      setPageId(1);
      setLoadMoreFlag(true);
      setPolicy([]);
    }
  }, [geoId, geographyId]);

  const callPolicyApi = useCallback(async (id, loadMore, categoryId, geoId) => {
    if (loadMore) {
      const params = {
        page_id: id,
        category_id: categoryId,
        geography_id: geoId,
      };
      try {
        setLoading(true);
        const apiResponse = await apiRequest(GET_POLICIES, REQUEST_TYPE.GET, params);
        setLoading(false);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.data.feeds.length > 0) {
              setPolicy((oldArray) => [...oldArray, ...apiResponse.response.data.feeds]);
              setLoadMoreFlag(true);
            } else {
              setLoadMoreFlag(false);
            }
          }
        }
      } catch (err) {
        handleError(err, params, GET_POLICIES, NAVIGATION_ROUTES.POLICIES);
        setLoading(false);
      }
    }
  }, []);

  useEffect(() => {
    if (geoId !== undefined) {
      callPolicyApi(pageId, loadMoreFlag, tabKey, geoId);
    }
  }, [callPolicyApi, loadMoreFlag, pageId, tabKey, geoId]);

  const onPressItem = (item) => {
    history.push(`${NAVIGATION_ROUTES.POLICY_DETAIL_SCREEN}/${item.policy_id}`);
  };

  const onPressLoadMore = () => {
    setPageId((prev) => prev + 1);
  };

  return (
    <div className="px-1 py-3">
      <div>
        {policy.map((item, index) => {
          return <PolicyListItem onPressItem={onPressItem} item={item} key={`${index}`} />;
        })}
        <ActivityLoader visible={loading} />
        {loadMoreFlag && !!policy.length && (
          <LoadMoreBtn onClick={onPressLoadMore} isLoading={loading} />
        )}
      </div>
    </div>
  );
};

export default PolicyTab;
