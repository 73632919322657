import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
} from '../../../common/constants/AppConstants';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import Divider from '../../../common/ui/divider/Divider';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import { selectIjprConsent } from '../../../state/MasterData';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';

const ReferConsentDialog = (props) => {
  const {
    jobCode,
    isReferConsentDialogVisible,
    setIsReferConsentDialogVisible,
    setIsIjpReferFormDialogDialogVisible,
  } = props;
  const [isConsentAccepted, setIsConsentAccepted] = useState(false);
  const ijprConsent = useSelector(selectIjprConsent);

  const onPressContinueHandler = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.IJP_REFERRALS_CONSENT_ACCEPTED,
      jobCode.toString(),
      ANALYTICS_ITEM_NAMES.IJPR
    );
    setIsIjpReferFormDialogDialogVisible(true);
    setIsReferConsentDialogVisible(false);
  };

  return (
    <Modal
      onHide={() => setIsReferConsentDialogVisible((val) => !val)}
      show={isReferConsentDialogVisible}
      centered
      keyboard={false}>
      <Modal.Body className="p-0">
        <div className="p-4">
          <h5 className="ijp-refer-consent-modal-title text-center mb-4">Consent</h5>
          <p className="ijp-refer-consent-modal-subtitle ijp-refer-consent-modal-scroll-box">
            {
              'By submitting this form you understand that Microland and its affiliates (collectively “Microland”, "us" or “we”) will, in its capacity as data controller, process personal data of your referral provided through this form, as well as that collected during the recruitment process (from you or from other sources) and/or that automatically collected from you (i.e. via cookies), to, mainly, (i) process employment application; (ii) subject to your express consent below, conduct \'profiling\' about fit for particular job opportunities to make personalized job recommendations based on preferences and/or help recruiters match candidates against job opportunities; and (iii) carry out aggregated reports / analysis regarding candidates’ activity data. You are entitled to exercise your rights with regards to referral’s personal data. This may include, depending of your country of residence, the right to access, to rectification, to erasure, to object to, and/or to request restriction of processing, to data portability, to not be subject to automated decision-making (including profiling), to withdraw your consent and to lodge a complaint with the data protection authority by contacting our Data Privacy Officer at dataprotection@microland.com. \n\n By checking this box I EXPRESSLY CONSENT that Microland processes personal data of my referral (detailed above) to be considered for future openings to assess fit for particular job opportunities (see (ii) above). Please note you may withdraw your consent at any time.'
            }
          </p>
          <HtmlView html={ijprConsent} htmlStyle="common-comment-item-message-text" />
        </div>
        <Divider />
        <div className="p-4">
          <p className="ijp-refer-consent-modal-subtitle">
            Please confirm you understand and accept the above statement
          </p>
          <div className="d-flex justify-content-around align-items-center mb-4">
            <div className="d-flex align-items-center">
              <input
                type="radio"
                checked={isConsentAccepted === true}
                className="mr-2"
                value={true}
                onChange={() => setIsConsentAccepted(true)}
              />
              <label className="ijp-refer-consent-modal-subtitle mb-0">Yes, I accept</label>
            </div>
            <div className="d-flex align-items-center">
              <input
                type="radio"
                checked={isConsentAccepted === false}
                className="mr-2"
                value={false}
                onChange={() => setIsConsentAccepted(false)}
              />
              <label className="ijp-refer-consent-modal-subtitle mb-0">No, I decline</label>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <CustomButton
              disabled={!isConsentAccepted}
              onClick={onPressContinueHandler}
              buttonStyle="common-custom-button-quaternary">
              Continue
            </CustomButton>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ReferConsentDialog;
