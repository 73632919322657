import React, { Component } from 'react';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import editorStyles from './CustomMentionEditor.module.css';
import mentionsStyles from './MentionsStyles.module.css';
import hashtagStyles from './hashtagStyles.module.css';
import HashtagSuggestion from './HashtagSuggestion';
import MentionSuggestion from './MentionSuggestion';

export default class CustomMentionEditor extends Component {
  constructor(props) {
    super(props);

    this.mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      theme: mentionsStyles,
      mentionPrefix: '',
      mentionTrigger: '@',
      supportWhitespace: true,
    });
    this.mentionPlugin2 = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      theme: hashtagStyles,
      mentionPrefix: '#',
      mentionTrigger: '#',
      supportWhitespace: true,
    });

    this.plugins = [this.mentionPlugin, this.mentionPlugin2];

    this.myRef = React.createRef();
  }
  state = {
    openSuggestions: false,
    openHashtags: false,
  };

  componentDidMount = () => {
    if (this.props.enableUserMentions) {
      this.plugins = [...this.plugins, this.mentionPlugin];
    }
    if (this.props.enableHashtags) {
      this.plugins = [...this.plugins, this.mentionPlugin2];
    }
  };

  openSuggestions = (_open) => {
    this.setState({
      openSuggestions: _open,
    });
  };

  openHashtags = (_open) => {
    this.setState({
      openHashtags: _open,
    });
  };

  PopoverContainer = (props) => {
    return <div className={editorStyles.popoverContainerStyle}>{props.children}</div>;
  };

  render() {
    const MentionSuggestions = this.mentionPlugin.MentionSuggestions;
    const MentionSuggestions2 = this.mentionPlugin2.MentionSuggestions;
    return (
      <div>
        <div
          style={{ paddingBottom: this.props.enablePadding ? 50 : 0 }}
          className={this.props.editMode ? editorStyles.editor2 : editorStyles.editor}
          onClick={() => {
            this.myRef.current.focus();
          }}>
          <Editor
            ref={this.myRef}
            editorKey={'editor'}
            editorState={this.props.editorState}
            onChange={this.props.onChange}
            placeholder={this.props.placeholder ?? ''}
            plugins={this.plugins}
            style={{ color: 'black' }}
          />
          {this.props.enableUserMentions && (
            <MentionSuggestions
              open={this.state.openSuggestions}
              onSearchChange={this.props.onMentionsSearchChange}
              suggestions={this.props.mentions}
              entryComponent={MentionSuggestion}
              onOpenChange={this.openSuggestions}
              popoverContainer={this.PopoverContainer}
              onAddMention={this.props.resetFilters}
            />
          )}
          {this.props.enableHashtags && (
            <MentionSuggestions2
              open={this.state.openHashtags}
              onOpenChange={this.openHashtags}
              onSearchChange={this.props.onHashtagsSearchChange}
              suggestions={this.props.hashtags}
              entryComponent={HashtagSuggestion}
              popoverContainer={this.PopoverContainer}
              onAddMention={this.props.resetFilters}
            />
          )}
        </div>
      </div>
    );
  }
}
