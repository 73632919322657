import React from 'react';
import EmployeeOrgView from './EmployeeOrgView';

const ReportiesView = (props) => {
  const renderReportee = (item, index) => {
    return (
      <div
        onClick={() => {
          props.callManagerApi(item.employee.employee_code);
        }}
        className="common-cursor-pointer d-flex flex-row align-items-center">
        <div>
          <div className="common-profile-reportee-border-vertical-container">
            <div className="common-profile-reportee-border-vertical common-profile-orgstructure-border" />
          </div>
          <div className="common-profile-reportee-horizontal-border-container">
            <div className="common-profile-reportee-horizontal-border common-profile-orgstructure-border" />
          </div>
        </div>
        <EmployeeOrgView
          employee={item.employee}
          style={`common-profile-org-reportee-container ${
            index === 0
              ? 'common-profile-org-reportee-top-radius'
              : '' || index === props.employee.length - 1
              ? 'common-profile-org-reportee-bottom-radius'
              : ''
          }
          `}
        />
      </div>
    );
  };

  return (
    <div>
      {props.employee &&
        props.employee.map((item, index) => {
          return <div key={`${index}`}>{item && renderReportee(item, index)}</div>;
        })}
    </div>
  );
};

export default ReportiesView;
