import { call, put, takeEvery } from 'redux-saga/effects';
import { ACTION_TYPES, REQUEST_TYPE } from '../common/constants/AppConstants';
import { GET_MENU_DATA } from '../services/ApiUrls';
import { apiRequest } from '../services/Service';
import { saveMenuData } from '../state/MenuData';

export function* getMenu(action) {
  try {
    const apiResponse = yield call(apiRequest, GET_MENU_DATA, REQUEST_TYPE.GET, action.payload);
    if (apiResponse) {
      if (apiResponse.response.status === true) {
        yield put(saveMenuData(apiResponse.response));
      }
    }
  } catch (e) {
    yield put(saveMenuData({}));
  }
}

export function* watchMenu() {
  yield takeEvery(ACTION_TYPES.GET_MENU, getMenu);
}
