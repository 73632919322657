import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
} from '../../../../common/constants/AppConstants';
import Divider from '../../../../common/ui/divider/Divider';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { selectWebBaseUrl } from '../../../../state/MasterData';
import { getFeatureComponentUrl } from '../../../../utils/accessibility';
import { getSecuredPdfUrl } from '../../../../utils/common';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';

const LowerLevelsOverviewTab = ({ data }) => {
  const history = useHistory();
  const accessibilityData = useSelector(selectAccessibilityData);
  const webUrl = useSelector(selectWebBaseUrl);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.NEW_JOINEES_OVERVIEW_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.NEW_JOINEES_LOWER_LEVELS
    );
  }, []);

  const onPressVideo = (videoItem) => {
    return (event) => {
      history.push(`${NAVIGATION_ROUTES.FULL_SCREEN_VIDEO}/${videoItem}`);
    };
  };

  <div className="d-flex align-self-end">
    <img
      src={getFeatureComponentUrl(
        accessibilityData,
        ACCESSIBILITY_FEATURE_COMPONENTS.MORE_INFO,
        ACCESSIBILITY_IDENTIFIERS.COMMON
      )}
      className="new-joinees-more_info_icon"
    />
  </div>;

  if (data) {
    return (
      <div className="column ">
        {data.map((item, index) => {
          return (
            <div key={index} className="mb-3">
              <div className="new-joinees-lower-levels-overview-background-container">
                <div className="new-joinees-lower-levels-overview-foreground-container"></div>
              </div>
              {item.media.map((pdfObject, index) => {
                return (
                  <div key={index}>
                    <a
                      href={getSecuredPdfUrl(webUrl, pdfObject.pdf_url)}
                      target="_blank"
                      rel="noreferrer">
                      <div className="new-joinees-lower-levels-overview-pdf-container d-flex flex-row align-items-end">
                        {!!pdfObject?.pdf_url && (
                          <img
                            href={getSecuredPdfUrl(webUrl, pdfObject.pdf_url)}
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_PDF_ATTACHMENT,
                              ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                            )}
                            className="new-joinees-lower-levels-overview-pdf-icon mb-2"
                          />
                        )}
                        {!!pdfObject?.pdf_url && pdfObject?.label && (
                          <div className="new-joinees-lower-levels-overview-pdf-title align-self-center">
                            {pdfObject.label}
                          </div>
                        )}
                        {!!pdfObject?.pdf_url && pdfObject?.label && (
                          <img
                            href={getSecuredPdfUrl(webUrl, pdfObject.pdf_url)}
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.MORE_INFO,
                              ACCESSIBILITY_IDENTIFIERS.COMMON
                            )}
                            className="new-joinees-more_info_icon ml-auto align-self-center"
                          />
                        )}
                      </div>
                    </a>

                    {!!pdfObject?.youtube_url && (
                      <div className="new-joinees-lower-levels-overview-pdf-container d-flex flex-row">
                        <img
                          onClick={onPressVideo(pdfObject?.youtube_url)}
                          src={getFeatureComponentUrl(
                            accessibilityData,
                            ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_VIDEO_PLAY_BUTTON,
                            ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                          )}
                          className="new-joinees-lower-levels-overview-video-button-image mt-n1"
                        />
                        {pdfObject?.label && (
                          <div
                            onClick={onPressVideo(pdfObject?.youtube_url)}
                            className="new-joinees-lower-levels-overview-pdf-title align-self-center pl-1">
                            {pdfObject.label}
                          </div>
                        )}
                        {!!pdfObject?.youtube_url && pdfObject?.label && (
                          <img
                            onClick={onPressVideo(pdfObject?.youtube_url)}
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.MORE_INFO,
                              ACCESSIBILITY_IDENTIFIERS.COMMON
                            )}
                            className="new-joinees-more_info_icon ml-auto align-self-center"
                          />
                        )}
                      </div>
                    )}
                    {(!!pdfObject?.youtube_url || !!pdfObject?.pdf_url) && <Divider />}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  } else return null;
};

export default LowerLevelsOverviewTab;
