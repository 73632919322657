import React, { useState, useEffect, useCallback } from 'react';
import { apiRequest, handleError } from '../../../../services/Service';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import { selectAnniversaryConfig } from '../../../../state/MasterData';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import LoadMoreBtn from '../../../../common/ui/load_more_btn/LoadMoreBtn';
import MomentsListItem from '../../components/MomentsListItem';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectShowEmptyContentView,
} from '../../../../state/UIState';
import CustomButton from '../../../../common/ui/custom_button/CustomButton';
import DefaultContentView from '../../../../common/ui/default_content_view/DefaultContentView';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../../common/ui/activity_loader/ActivityLoader';

const MomentsTab = ({ identifier }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [discussions, setDiscussions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const [pageId, setPageId] = useState(1);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const anniversaryConfig = useSelector(selectAnniversaryConfig).find(
    (element) => element.identifier === identifier
  );

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ANNIVERSARY_MOMENTS_VISIT,
      identifier,
      ANALYTICS_ITEM_NAMES.ANNIVERSARY
    );
  }, [identifier]);

  const callMomentsApi = useCallback(
    async (pId, loadMore) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        const params = {
          page_id: pId,
        };
        try {
          setLoading(true);
          const apiResponse = await apiRequest(
            identifier + '/challenge/posts',
            REQUEST_TYPE.GET,
            params
          );
          setLoading(false);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.posts.length > 0) {
                setDiscussions((oldArray) => [...oldArray, ...apiResponse.response.data.posts]);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(
            err,
            params,
            identifier + '/challenge/posts',
            NAVIGATION_ROUTES.ANNIVERSARY_SCREEN
          );
          setLoading(false);
        }
      }
    },
    [dispatch, identifier]
  );

  useEffect(() => {
    callMomentsApi(1, true);
  }, [callMomentsApi]);

  const onPressCommentButton = (item) => {
    logEvent(ANALYTICS_EVENT_TYPES.MOMENTS_COMMENT, item.post_id, ANALYTICS_ITEM_NAMES.COMMENT);
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: item.post_id,
      likeType: 'MP',
      fromRoute: NAVIGATION_ROUTES.ANNIVERSARY_SCREEN,
    });
  };

  const onPressLikeButton = (item) => {
    if (item.likes && !item.likes.is_liked) {
      logEvent(ANALYTICS_EVENT_TYPES.MOMENTS_LIKE, item.post_id, ANALYTICS_ITEM_NAMES.LIKE);
      callLikeApi(item.post_id);
    }
  };

  const callLikeApi = async (postId) => {
    const params = new URLSearchParams();
    params.append('type', 'MP');
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: 'Updating Like Count...',
        })
      );
      const apiResponse = await apiRequest(
        'comment/' + postId + '/like',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          updateLikeCount(postId);
        }
      }
    } catch (err) {
      handleError(err, params, 'comment/' + postId + '/like', NAVIGATION_ROUTES.ANNIVERSARY_SCREEN);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const updateLikeCount = (postId) => {
    let arr = discussions;
    let oldCount = arr.find((element) => element.post_id === postId).likes.count;
    let objIndex = arr.findIndex((element) => element.post_id === postId);
    arr[objIndex].likes.count = oldCount + 1;
    arr[objIndex].likes.is_liked = true;
    setDiscussions([...arr]);
  };

  const onPressTagDetail = (item) => {
    history.push(NAVIGATION_ROUTES.MOMENT_TAG_DETAIL_SCREEN, {
      item: item,
      identifier: identifier,
    });
  };

  const onPressParticipate = () => {
    if (
      anniversaryConfig &&
      anniversaryConfig.participate_tabs &&
      anniversaryConfig.participate_tabs.length > 0
    ) {
      let arr = anniversaryConfig.participate_tabs;
      history.push(NAVIGATION_ROUTES.CONTESTS_DETAIL_SCREEN, {
        identifier: identifier,
        tabs: arr,
        contestId: arr[0].contest_id,
        title: arr[0].title,
      });
    }
  };

  const onPressLoadMore = () => {
    callMomentsApi(pageId, loadMoreFlag);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.SEARCH_NO_RESULTS} />;
  };

  return (
    <div className="main-content-container">
      <CustomButton buttonStyle="anniversary-button mr-0 mb-3" onClick={onPressParticipate}>
        Participate
      </CustomButton>
      {showEmptyContentView && !discussions.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          {discussions.map((item, index) => {
            return (
              <MomentsListItem
                key={`${index}`}
                item={item}
                identifier={identifier}
                onPressCommentButton={onPressCommentButton}
                onPressLikeButton={onPressLikeButton}
                onPressTagDetail={onPressTagDetail}
              />
            );
          })}
          <ActivityLoader visible={loading} />
          {loadMoreFlag && <LoadMoreBtn onClick={onPressLoadMore} isLoading={loading} />}
        </div>
      )}
    </div>
  );
};

export default MomentsTab;
