import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';

import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { getFeatureComponentUrl, getHighlightedMenuIcon } from '../../../../utils/accessibility';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
} from '../../../constants/AppConstants';
import HtmlView from '../../html_view/HTMLView';
import { removeHtmlContent } from '../../../../utils/common';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import { navigatePage } from '../../../../utils/featureNavigation';
import {
  saveTraveller,
  saveWallSearchText,
  selectTraveller,
  selectWallSearchText,
} from '../../../../state/UIState';
import { saveNewJoineesTabCategoryFilterKey } from '../../../../state/TabData';

const Feature = ({ section, feature, featureIndex, eventKey, setIsFavoriteModalVisible }) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const wallSearcchText = useSelector(selectWallSearchText);
  const travellerData = useSelector(selectTraveller);
  const dispatch = useDispatch();

  const onPressFeatureHandler = (feature) => {
    if (wallSearcchText !== '') {
      dispatch(saveWallSearchText(''));
    }
    dispatch(saveNewJoineesTabCategoryFilterKey(''));
    if (Object.keys(travellerData).length > 0) {
      dispatch(saveTraveller({}));
    }
    logEvent(
      ANALYTICS_EVENT_TYPES.MENU_FEATURE_NAVIGATE,
      feature.id.toString(),
      ANALYTICS_ITEM_NAMES.MENU,
      removeHtmlContent(feature.title)
    );
    navigatePage(feature);
  };

  return (
    <div>
      <div onClick={() => onPressFeatureHandler(feature)}>
        <NavDropdown.Item
          eventKey={eventKey}
          onClick={() => {
            $('.dropdown-menu').removeClass('show');
          }}
          className={`header-feature-item ${
            feature.isHighlighted
              ? 'header-feature-item-highlighted'
              : 'header-feature-unhighlighted'
          } `}>
          <div className="d-flex align-items-center">
            <img
              className="mr-2 header-feature-item-image"
              src={
                feature.isHighlighted
                  ? getHighlightedMenuIcon(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.MENUSCREEN_ICON_URL,
                      feature.identifier
                    )
                  : getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.MENUSCREEN_ICON_URL,
                      feature.identifier
                    )
              }
            />
            <HtmlView html={feature.title} onClick={() => {}} />
            {feature.isNew && (
              <img
                className="ml-2 header-feature-item-image"
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.HOME_NEW_POST,
                  ACCESSIBILITY_IDENTIFIERS.HOME
                )}
              />
            )}
          </div>
        </NavDropdown.Item>
        {(section.features.length - 1 !== featureIndex ||
          section.identifier === ACCESSIBILITY_IDENTIFIERS.FAVORITES_SECTION) && (
          <NavDropdown.Divider className="my-0" />
        )}
      </div>

      {section.identifier === ACCESSIBILITY_IDENTIFIERS.FAVORITES_SECTION &&
        section.features.length - 1 === featureIndex && (
          <NavDropdown.Item
            eventKey="favorite-edit"
            onClick={() => {
              $('.dropdown-menu').removeClass('show');
              setIsFavoriteModalVisible(true);
            }}
            className="header-feature-item header-feature-item-highlighted text-center">
            <div className="d-flex align-items-center justify-content-center">
              <div>EDIT</div>
            </div>
          </NavDropdown.Item>
        )}
    </div>
  );
};

export default Feature;
