import React from 'react';
import { Form } from 'react-bootstrap';

const CustomCheckBox = (props) => {
  const { disabled = false, containerClassName = '' } = props;

  return (
    <Form.Group className={`mb-0 ${containerClassName}`}>
      <Form.Check
        required={props.required}
        label={props.label}
        checked={props.value}
        feedback={props.feedback}
        isInvalid={props.isInvalid}
        onChange={props.onValueChange}
        disabled={disabled}
      />
    </Form.Group>
  );
};

export default CustomCheckBox;
