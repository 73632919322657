import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  MICROGIVE_EVENT_TYPE,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { selectMicrogive, selectMicroGiveAboutUrl } from '../../state/MasterData';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import DonationTab from './tabs/microgiveTabs/DonationTab';
import MicrogiverTab from './tabs/microgiveTabs/MicrogiverTab';
import ProjectsTab from './tabs/microgiveTabs/ProjectsTab';
import VolunteerTab from './tabs/microgiveTabs/VolunteerTab';
import { Tab } from 'react-bootstrap';
import CalendarDialog from '../../common/ui/calender_dialog/CalendarDialog';
import {
  saveMicrogiveDonationCategoryId,
  saveMicrogiveTabData,
  saveMicrogiveVolunteerCategoryId,
  selectMicrogiveTabData,
} from '../../state/TabData';
import { getDateInYYYYMMDD } from '../../utils/common';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import NavTabs from '../../common/ui/tabs/NavTabs';
import SearchComponent from '../../common/ui/search/SearchComponent';
import { saveSearchText } from '../../state/UIState';

const MicrogiveScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [donationMarkedDates, setDonationMarkedDates] = useState([]);
  const [donationSelectedDate, setDonationSelectedDate] = useState(new Date());
  const [volunteerMarkedDates, setVolunteerMarkedDates] = useState([]);
  const [volunteerSelectedDate, setVolunteerSelectedDate] = useState(new Date());
  const microgiveTitles = useSelector(selectMicrogive);
  const microGiveAboutUrl = useSelector(selectMicroGiveAboutUrl);
  const accessibilityData = useSelector(selectAccessibilityData);
  const microgiveTabData = useSelector(selectMicrogiveTabData);
  const [eventType, setEventType] = useState('');

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.MICRO_GIVE, NAVIGATION_ROUTES.MICRO_GIVE);
  }, []);

  useEffect(() => {
    if (microgiveTitles.length && microgiveTabData === '') {
      dispatch(saveMicrogiveTabData(microgiveTitles[0].key));
    }
  }, [dispatch, microgiveTabData, microgiveTitles]);

  const onSetDonationMarkedDates = (markedDates) => {
    setDonationMarkedDates(markedDates);
  };

  const onSetVolunteerMarkedDates = (markedDates) => {
    setVolunteerMarkedDates(markedDates);
  };

  const renderTabs = useCallback((tab) => {
    switch (tab.key) {
      case 'donation':
        return <DonationTab tabKey={tab.key} onSetDonationMarkedDates={onSetDonationMarkedDates} />;
      case 'microgiver':
        return <MicrogiverTab tabKey={tab.key} />;
      case 'projects':
        return <ProjectsTab tabKey={tab.key} />;
      case 'volunteer':
        return (
          <VolunteerTab tabKey={tab.key} onSetVolunteerMarkedDates={onSetVolunteerMarkedDates} />
        );
    }
  }, []);

  const onDonationDateSelected = (date) => {
    setDonationSelectedDate(date);
    let formattedDate = getDateInYYYYMMDD(date);
    history.push(NAVIGATION_ROUTES.DONATION_EVENT_BY_DATE_SCREEN, {
      title: formattedDate,
      date: formattedDate,
      type: MICROGIVE_EVENT_TYPE.DONATION,
    });
  };

  const onVolunteeerDateSelected = (date) => {
    setVolunteerSelectedDate(date);
    let formattedDate = getDateInYYYYMMDD(date);
    history.push(NAVIGATION_ROUTES.DONATION_EVENT_BY_DATE_SCREEN, {
      title: formattedDate,
      date: formattedDate,
      type: MICROGIVE_EVENT_TYPE.VOLUNTEER,
    });
  };

  const handleSubmitFunction = (searchText) => {
    dispatch(saveSearchText(searchText));
    history.push(NAVIGATION_ROUTES.DONATION_SEARCH_SCREEN, {
      type: MICROGIVE_EVENT_TYPE.DONATION,
      searchQuery: searchText,
    });
  };

  const handleSubmitFunction2 = (searchText) => {
    dispatch(saveSearchText(searchText));
    history.push(NAVIGATION_ROUTES.PROJECTS_SEARCH_SCREEN, {
      searchQuery: searchText,
    });
  };
  const handleSubmitFunction3 = (searchText) => {
    dispatch(saveSearchText(searchText));
    history.push(NAVIGATION_ROUTES.DONATION_SEARCH_SCREEN, {
      type: MICROGIVE_EVENT_TYPE.VOLUNTEER,
      searchQuery: searchText,
    });
  };

  useEffect(() => {
    switch (microgiveTabData) {
      case 'donation':
        setEventType(MICROGIVE_EVENT_TYPE.DONATION);
        break;
      case 'projects':
        setEventType('');
        break;
      case 'volunteer':
        setEventType(MICROGIVE_EVENT_TYPE.DONATION);
        break;
      default:
        setEventType('');
    }
  }, [microgiveTabData]);

  const renderHeader = () => {
    switch (microgiveTabData) {
      case 'donation':
        return (
          <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
            <SearchComponent handleSubmitFunction={handleSubmitFunction} />
            <div className="gcdo-icon-row mr-3">
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.MORE_INFO
                )}
                className="header-icon mr-3"
                onClick={onPressAbout}
              />
              <CalendarDialog
                markedDates={donationMarkedDates}
                onDateSelected={onDonationDateSelected}
                selectedDate={donationSelectedDate}
                popperPosition="bottom-end"
              />
            </div>
          </div>
        );
      case 'microgiver':
        return null;
      case 'projects':
        return (
          <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
            <SearchComponent handleSubmitFunction={handleSubmitFunction2} />
            <div className="gcdo-icon-row mr-3">
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.MORE_INFO
                )}
                className="header-icon mr-3"
                onClick={onPressAbout}
              />
            </div>
          </div>
        );
      case 'volunteer':
        return (
          <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
            <SearchComponent handleSubmitFunction={handleSubmitFunction3} />
            <div className="gcdo-icon-row mr-3">
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.MORE_INFO
                )}
                className="header-icon mr-3"
                onClick={onPressAbout}
              />
              <CalendarDialog
                markedDates={volunteerMarkedDates}
                onDateSelected={onVolunteeerDateSelected}
                selectedDate={volunteerSelectedDate}
                popperPosition="bottom-end"
              />
            </div>
          </div>
        );
    }
  };

  const onPressAbout = useCallback(() => {
    history.push(NAVIGATION_ROUTES.HTML_VIEW_SCREEN, {
      html: microGiveAboutUrl,
      identifier: ACCESSIBILITY_IDENTIFIERS.MICROGIVE,
    });
  }, [history, microGiveAboutUrl]);

  return (
    <div className="container">
      {renderHeader()}
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICROGIVE} />

      <div>
        <div>
          <NavTabs
            activeKey={microgiveTabData}
            onSelect={(k) => {
              dispatch(saveMicrogiveTabData(k));
              dispatch(saveMicrogiveDonationCategoryId(0));
              dispatch(saveMicrogiveVolunteerCategoryId(0));
            }}
            defaultActiveKey={microgiveTabData}
            unmountOnExit>
            {microgiveTitles.map((tab, index) => {
              return (
                <Tab key={`${index}`} eventKey={tab.key} title={tab.title}>
                  {renderTabs(tab, index)}
                </Tab>
              );
            })}
          </NavTabs>
        </div>
      </div>
    </div>
  );
};

export default MicrogiveScreen;
