import React, { useCallback, useEffect, useRef, useState } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/functions';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  CHAT_LEFT_PANEL_IDENTIFIERS,
  LOADING_MESSAGES,
  MESSAGES,
  NAVIGATION_ROUTES,
  NOTIFICATION_MESSAGE_TYPES,
  REQUEST_TYPE,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../../common/constants/AppConstants';
import { EMPLOYEES } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { selectChatGroupMembersLimit } from '../../../state/MasterData';
import { saveProgressLoadingState } from '../../../state/UIState';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../../utils/accessibility';
import {
  addMemberToAGroup,
  createOrUpdateUser,
  sendNotificationMessageToGroup,
  uId,
} from '../../../utils/ChatHandler';
import { showToast } from '../../../utils/common';
import { selectUserChatGroups } from '../../../state/ChatData';
import NewChatListItem from './NewChatListItem';
import BackButton from '../../../common/ui/common_back_button/BackButton';
import Divider from '../../../common/ui/divider/Divider';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';

const GroupDetailAddParticipantsView = (props) => {
  const { groupMemberList, groupId, setLeftPanelType, setGroupChange } = props;
  const dispatch = useDispatch();
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [dataLength, setDataLength] = useState(0);
  const loadMoreFlagRef = useRef(true);
  const pageIdRef = useRef(1);
  const textInputRef = useRef();
  const searchTextRef = useRef('');
  const employeeListItemRef = useRef();
  const exitStatus = useRef(1);
  const userChatGroups = useSelector(selectUserChatGroups);
  const accessibilityData = useSelector(selectAccessibilityData);
  const employeeData = useSelector(selectEmployeeData);
  const maxSelected = useSelector(selectChatGroupMembersLimit);
  const [maximumSelected, setMaximumSelected] = useState([]);
  const employeesAlreadyTaggedList = groupMemberList.map((item) => {
    return item.id.split('-')[1];
  });

  useEffect(() => {
    if (pageIdRef.current !== 1) {
      loadMoreFlagRef.current = false;
    }
  }, [dataLength]);

  useEffect(() => {
    if (maxSelected) {
      setMaximumSelected(maxSelected - employeesAlreadyTaggedList.length);
    }
  }, [employeesAlreadyTaggedList.length, maxSelected]);

  const callEmployeeApi = useCallback(async () => {
    const params = {
      page_id: pageIdRef.current,
      query: searchTextRef.current,
    };
    try {
      loadMoreFlagRef.current = false;
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(EMPLOYEES, REQUEST_TYPE.GET, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      loadMoreFlagRef.current = true;
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setDataLength(apiResponse.response.data.feeds.length);
          if (pageIdRef.current === 1) {
            let empList = apiResponse.response.data.feeds;
            if (empList.some((person) => person.employee_id === employeeData.employee_id)) {
              empList = empList.filter((obj) => obj.employee_id !== employeeData.employee_id);
            }
            setEmployeeList(empList);
          } else {
            let empList = apiResponse.response.data.feeds;
            if (empList.some((person) => person.employee_id === employeeData.employee_id)) {
              empList = empList.filter((obj) => obj.employee_id !== employeeData.employee_id);
            }
            setEmployeeList((oldArray) => [...oldArray, ...empList]);
          }
        }
      }
    } catch (err) {
      handleError(err, params, EMPLOYEES, NAVIGATION_ROUTES.CHAT);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch, employeeData.employee_id]);

  const renderScreen = useCallback(() => {
    callEmployeeApi();
  }, [callEmployeeApi]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const debouncedCallback = (event) => {
    if (event && event.target.value.length > 2) {
      event.preventDefault();
      pageIdRef.current = 1;
      loadMoreFlagRef.current = true;
      searchTextRef.current = event.target.value;
      employeeListItemRef.current.scrollTo(0, 0);
      setDataLength(0);
      callEmployeeApi();
    } else {
      searchTextRef.current = event.target.value;
    }
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );

  useEffect(() => {
    if (Object.keys(userChatGroups).length > 0) {
      let groupModel = userChatGroups[groupId];
      if (groupModel && Object.keys(groupModel).length > 0) {
        //
      } else {
        if (exitStatus.current === 1) {
          setLeftPanelType(CHAT_LEFT_PANEL_IDENTIFIERS.DEFAULT_VIEW);
        }
      }
    }
  }, [groupId, setLeftPanelType, userChatGroups]);

  const onPressNewChatListItemHandler = (item) => {
    if (!employeesAlreadyTaggedList.includes(item.employee_id.toString())) {
      if (
        selectedEmployeeList.some(
          (member) => member.employee_id.toString() === item.employee_id.toString()
        )
      ) {
        setSelectedEmployeeList(
          selectedEmployeeList.filter(
            (member) => member.employee_id.toString() !== item.employee_id.toString()
          )
        );
      } else {
        if (selectedEmployeeList.length < maximumSelected) {
          setSelectedEmployeeList((prev) => [item, ...prev]);
        } else {
          showToast(`You cannot add more than ${maxSelected} people to the group`);
        }
      }
    } else {
      showToast(MESSAGES.ALREADY_MEMBER);
    }
  };

  const renderEmployee = (item, index) => {
    return (
      <NewChatListItem
        key={index.toString()}
        isEmployeeSelected={selectedEmployeeList
          .map((element) => element.employee_id)
          .includes(item.employee_id)}
        isEmployeeAlreadyAdded={employeesAlreadyTaggedList.includes(item.employee_id.toString())}
        item={item}
        onPress={onPressNewChatListItemHandler}
      />
    );
  };

  const callChangeFunction = (event) => {
    searchTextRef.current = event.target.value;
  };

  const onSearchSubmitHandler = (event) => {
    event.preventDefault();
    logEvent(
      ANALYTICS_EVENT_TYPES.CHAT_ADD_PARTICIPANTS_SEARCH,
      searchTextRef.current,
      ANALYTICS_ITEM_NAMES.SEARCH
    );
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    employeeListItemRef.current.scrollTo(0, 0);
    setDataLength(0);
    callEmployeeApi();
  };

  const resetSearchHandler = useCallback(() => {
    textInputRef.current.reset();
    searchTextRef.current = '';
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    employeeListItemRef.current.scrollTo(0, 0);
    setDataLength(0);
    setIsSearch(false);
    callEmployeeApi();
  }, [callEmployeeApi]);

  const onClickSearchHandler = () => {
    setIsSearch(true);
  };

  const onScrollEmployeeListItem = () => {
    if (employeeListItemRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = employeeListItemRef.current;
      if (Math.ceil(scrollTop) + clientHeight === scrollHeight) {
        if (loadMoreFlagRef.current) {
          pageIdRef.current = pageIdRef.current + 1;
          callEmployeeApi();
        }
      }
    }
  };

  const deleteEmployee = (employee) => {
    if (selectedEmployeeList.includes(employee)) {
      const index = selectedEmployeeList.indexOf(employee);
      if (index > -1) {
        setSelectedEmployeeList(
          selectedEmployeeList.filter((item) => item !== selectedEmployeeList[index])
        );
      }
    }
  };

  const onPressAddMembers = () => {
    if (selectedEmployeeList.length > 0) {
      if (selectedEmployeeList.length > maximumSelected) {
        showToast(`Number of members should be less than or equals to ${maximumSelected}`);
      } else {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
            progressMessage: LOADING_MESSAGES.ADDING_MEMBERS,
          })
        );
        let i = selectedEmployeeList.length;
        let members = [];
        let membersArr = [];
        selectedEmployeeList.forEach((element) => {
          const userId = uId(element);
          members.push(userId);
          membersArr.push({
            name: element.first_name + ' ' + element.last_name,
            uid: userId,
          });
          const otherUser = {
            image_url:
              element.profile_picture.image_path !== ''
                ? element.profile_picture.base_url + element.profile_picture.image_path
                : '',
            name: element.first_name + ' ' + element.last_name,
            uid: userId,
            user_location: element.location.title,
          };
          createOrUpdateUser(otherUser, () => {
            addMemberToAGroup(groupId, userId, () => {
              i--;
              if (i === 0) {
                firebase
                  .functions()
                  .httpsCallable('AddMembers')({
                    groupId: groupId,
                    members: members,
                  })
                  .then((response) => {});
                sendNotificationMessageToGroup(
                  groupId,
                  employeeData,
                  NOTIFICATION_MESSAGE_TYPES.ADD_MEMBER,
                  membersArr,
                  () => {}
                );
                dispatch(saveProgressLoadingState({ isProgressLoading: false }));
                setLeftPanelType(CHAT_LEFT_PANEL_IDENTIFIERS.GROUP_DETAIL_VIEW);
                setGroupChange((prev) => !prev);
              }
            });
          });
        });
      }
    }
  };

  return (
    <div>
      <div className="d-flex px-3 py-2 justify-content-between align-items-center chat-header">
        <div className="d-flex align-items-center">
          <BackButton
            isFullRow={false}
            backTitle=""
            containerStyle="py-0"
            navigateBackFuncHandler={() => {
              setLeftPanelType(CHAT_LEFT_PANEL_IDENTIFIERS.GROUP_DETAIL_VIEW);
            }}
          />
          {!isSearch && <div className="chat-bold-text">Add members</div>}
        </div>
        <div className="flex-grow-1">
          <div className="d-flex justify-content-between">
            {isSearch ? (
              <form className="w-100" onSubmit={onSearchSubmitHandler} ref={textInputRef}>
                <input
                  autoFocus
                  className="chat-search-bar p-2"
                  type="text"
                  placeholder="Search contact..."
                  onChange={debounceInputHandler}
                />
              </form>
            ) : (
              <div />
            )}
            {isSearch ? (
              <div
                className="d-flex align-items-center chat-search-cross-container pr-1"
                onClick={resetSearchHandler}>
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
                  )}
                  className="header-icon"
                />
              </div>
            ) : (
              <div className="pr-1" onClick={onClickSearchHandler}>
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_SEARCH_GREY,
                    ACCESSIBILITY_IDENTIFIERS.COMMON
                  )}
                  className="chat-icon-search common-cursor-pointer"
                />
              </div>
            )}
          </div>
        </div>

        <div
          onClick={onPressAddMembers}
          className={`ml-2 ${
            selectedEmployeeList.length <= 0
              ? 'chat-theme-highlighted-text-disabled common-cursor-not-allowed'
              : 'chat-theme-highlighted-text common-cursor-pointer'
          }`}>
          Add
        </div>
      </div>

      <div
        ref={employeeListItemRef}
        onScroll={() => onScrollEmployeeListItem()}
        className="chat-left-panel-container">
        <div>
          <div className="d-flex flex-wrap mt-2 chat-employee-selected-outer-container overflow-auto px-3 pt-2">
            {selectedEmployeeList.length > 0 &&
              selectedEmployeeList.map((item, index) => {
                return (
                  <div className="d-flex mr-2" key={`${index}`}>
                    <div className="d-flex align-items-center chat-selected-employee-container mb-2">
                      <UserProfilePhoto
                        imageBaseUrl={item.profile_picture.base_url}
                        imagePath={item.profile_picture.image_path}
                        imageClass="common-user-profile-photo-xxs"
                      />
                      <div className="chat-base-text-sm pt-1 ml-1 mr-2">
                        {item.first_name + ' ' + item.last_name}
                      </div>
                      <div
                        className="common-cursor-pointer pr-1"
                        onClick={() => deleteEmployee(item)}>
                        <img
                          src={getFeatureComponentUrl(
                            accessibilityData,
                            ACCESSIBILITY_FEATURE_COMPONENTS.SOM_EMPLOYEE_CANCEL,
                            ACCESSIBILITY_IDENTIFIERS.SOM
                          )}
                          className="chat-selected-employee-close-icon"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {selectedEmployeeList.length > 0 && <Divider style="chat-divider-thin mt-2 mb-3" />}
          {selectedEmployeeList.length <= maximumSelected && (
            <div
              className={`chat-base-text-sm chat-bold-text px-3 ${
                selectedEmployeeList.length === maximumSelected ? 'chat-theme-highlighted-text' : ''
              }`}>{`${selectedEmployeeList.length}/${maximumSelected} selected`}</div>
          )}
        </div>

        {employeeList.map((item, index) => renderEmployee(item, index))}
      </div>
    </div>
  );
};

export default React.memo(GroupDetailAddParticipantsView);
