import React, { useState, useEffect, useCallback } from 'react';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  FEATURE_TYPE,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../../../services/Service';
import {
  GET_CHEERS,
  GET_PENDING_COUNT,
  SUBMIT_LIKE_COMMENT_CHEERS,
} from '../../../../services/ApiUrls';
import { useDispatch, useSelector } from 'react-redux';
import RewardsCentralListItem from '../../components/RewardsCentralListItem';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectShowEmptyContentView,
} from '../../../../state/UIState';
import { useHistory } from 'react-router';
import LoadMoreBtn from '../../../../common/ui/load_more_btn/LoadMoreBtn';
import CustomButton from '../../../../common/ui/custom_button/CustomButton';
import DefaultContentView from '../../../../common/ui/default_content_view/DefaultContentView';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../../common/ui/activity_loader/ActivityLoader';
import { selectAccessData } from '../../../../state/UserAccessData';

const RewardsCentralTab = ({ onTabChange }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [cheersList, setCheersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const [pageId, setPageId] = useState(1);
  const [countText, setCountText] = useState('');
  const [canApprove, setCanApprove] = useState();
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const accessData = useSelector(selectAccessData);
  const [showLikeButton, setShowLikeButton] = useState(true);
  const [showCommentButton, setShowCommentButton] = useState(true);
  const cheersAccessData = accessData?.data?.find((item) => item.feature_key === '_cheers');

  useEffect(() => {
    if (cheersAccessData) {
      const actions = cheersAccessData?.actions;
      setShowLikeButton(actions?.includes('_like'));
      setShowCommentButton(actions?.includes('_comment'));
    }
  }, [cheersAccessData]);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.REWARD_CENTRAL_TAB_VISIT, '', ANALYTICS_ITEM_NAMES.CHEERS);
  }, []);

  const callCheersListApi = useCallback(
    async (pageid, loadMore) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        const params = {
          page_id: pageid,
        };
        try {
          setLoading(true);
          const apiResponse = await apiRequest(GET_CHEERS, REQUEST_TYPE.GET, params);
          setLoading(false);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                setCheersList((oldArray) => [...oldArray, ...apiResponse.response.data.feeds]);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(err, params, GET_CHEERS, NAVIGATION_ROUTES.CHEERS_SCREEN);
          setLoading(false);
        }
      }
    },
    [dispatch]
  );

  const callPendingCountApi = useCallback(async () => {
    const params = {
      type: '',
    };
    try {
      const apiResponse = await apiRequest(GET_PENDING_COUNT, REQUEST_TYPE.GET, params);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setCanApprove(apiResponse.response.data.can_approve);
          if (apiResponse.response.data.count === 0) {
            setCountText('No pending nominations for approval');
          } else {
            setCountText(`${apiResponse.response.data.count} Nominations pending for approval`);
          }
        }
      }
    } catch (err) {
      handleError(err, params, GET_PENDING_COUNT, NAVIGATION_ROUTES.CHEERS_SCREEN);
      //
    }
  }, []);

  useEffect(() => {
    callCheersListApi(1, true);
    callPendingCountApi();
  }, [callCheersListApi, callPendingCountApi]);

  const callLikeApi = async (cheersEmployeeAwardId) => {
    const params = new URLSearchParams();
    params.append('type', FEATURE_TYPE.CHEERS);
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: 'Updating Like Count...',
        })
      );
      const apiResponse = await apiRequest(
        SUBMIT_LIKE_COMMENT_CHEERS + cheersEmployeeAwardId + '/like',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          updateLikeCount(cheersEmployeeAwardId);
        }
      }
    } catch (err) {
      handleError(
        err,
        params,
        SUBMIT_LIKE_COMMENT_CHEERS + cheersEmployeeAwardId + '/like',
        NAVIGATION_ROUTES.CHEERS_SCREEN
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const updateLikeCount = (cheersEmployeeAwardId) => {
    let arr = cheersList;
    let oldCount = arr.find((element) => element.cheer_employee_award_id === cheersEmployeeAwardId)
      .likes.count;
    let objIndex = arr.findIndex(
      (element) => element.cheer_employee_award_id === cheersEmployeeAwardId
    );
    let isLiked = arr[objIndex].likes.is_liked;
    arr[objIndex].likes.count = isLiked ? oldCount - 1 : oldCount + 1;
    arr[objIndex].likes.is_liked = !isLiked;
    setCheersList([...arr]);
  };

  const updateLikeCountRN = (topperId) => {
    let arr = cheersList;
    let oldCount = arr.find((element) => element.topper_id === topperId).likes.count;
    let objIndex = arr.findIndex((element) => element.topper_id === topperId);
    let isLiked = arr[objIndex].likes.is_liked;
    arr[objIndex].likes.count = isLiked ? oldCount - 1 : oldCount + 1;
    arr[objIndex].likes.is_liked = !isLiked;
    setCheersList([...arr]);
  };

  const callLikeApiRN = async (topperId, type) => {
    const params = new URLSearchParams();
    params.append('type', type);
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: 'Updating Like Count...',
        })
      );
      const apiResponse = await apiRequest(
        SUBMIT_LIKE_COMMENT_CHEERS + topperId + '/like',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          updateLikeCountRN(topperId);
        }
      }
    } catch (err) {
      handleError(
        err,
        params,
        SUBMIT_LIKE_COMMENT_CHEERS + topperId + '/like',
        NAVIGATION_ROUTES.CHEERS_SCREEN
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressLike = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.NOMINATIONS_RECIPIENT_LIKE,
      item.topper_id,
      ANALYTICS_ITEM_NAMES.LIKE
    );
    item.type === 'recipient'
      ? callLikeApiRN(item.topper_id, FEATURE_TYPE.CHEERS_RECIPIENT)
      : callLikeApiRN(item.topper_id, FEATURE_TYPE.CHEERS_NOMINATORS);
  };

  const onPressLikeButton = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.REWARD_CENTRAL_LIKE,
      item.cheer_employee_award_id,
      ANALYTICS_ITEM_NAMES.LIKE
    );
    callLikeApi(item.cheer_employee_award_id);
  };

  const onPressCommentButton = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.REWARD_CENTRAL_COMMENTS,
      item.cheer_employee_award_id,
      ANALYTICS_ITEM_NAMES.COMMENT
    );
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: item.cheer_employee_award_id,
      likeType: FEATURE_TYPE.CHEERS,
      fromRoute: NAVIGATION_ROUTES.CHEERS_SCREEN,
      type: 'reward',
    });
  };

  const onPressComment = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.NOMINATIONS_RECIPIENT_COMMENT,
      item.topper_id,
      ANALYTICS_ITEM_NAMES.COMMENT
    );
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: item.topper_id,
      likeType:
        item.type === 'recipient' ? FEATURE_TYPE.CHEERS_RECIPIENT : FEATURE_TYPE.CHEERS_NOMINATORS,
      fromRoute: NAVIGATION_ROUTES.CHEERS_SCREEN,
      type: 'nominatorRecipient',
    });
  };

  const onPressOthers = (nominationsList) => {
    history.push(NAVIGATION_ROUTES.TAGGED_PEOPLE_SCREEN, {
      nominationsList: nominationsList,
      from: 'rewardCentral',
      title: 'Nominated by',
    });
  };

  const onPressViewNominations = () => {
    history.push(NAVIGATION_ROUTES.VIEW_NOMINATIONS_SCREEN);
  };

  const onPressLoadMore = () => {
    callCheersListApi(pageId, loadMoreFlag);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_DATA_FOUND} />;
  };

  return (
    <div className="main-content-container">
      {canApprove && canApprove === true && (
        <div className="cheers-outer-view justify-content-between cheers-bottom-view mb-2">
          <div className="cheers-donate-points-text ml-2 mr-2">{countText}</div>
          <CustomButton
            buttonStyle="cheers-button-style mr-3 mt-1 mb-1"
            onClick={onPressViewNominations}>
            View Nominations
          </CustomButton>
        </div>
      )}
      {showEmptyContentView && !cheersList.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          {cheersList.map((item, index) => {
            return (
              <RewardsCentralListItem
                item={item}
                index={index}
                onPressCommentButton={onPressCommentButton}
                onPressLikeButton={onPressLikeButton}
                onPressComment={onPressComment}
                onPressLike={onPressLike}
                onPressOthers={onPressOthers}
                key={`${index}`}
                onTabChange={onTabChange}
                showCommentButton={showCommentButton}
                showLikeButton={showLikeButton}
              />
            );
          })}
          <ActivityLoader visible={loading} />
          {loadMoreFlag && <LoadMoreBtn onClick={onPressLoadMore} isLoading={loading} />}
        </div>
      )}
    </div>
  );
};

export default React.memo(RewardsCentralTab);
