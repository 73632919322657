import FileResizer from 'react-image-file-resizer';
import { ENCODING_TYPE, REQUEST_TYPE } from '../common/constants/AppConstants';
import { UPLOAD_MEDIA } from '../services/ApiUrls';
import { apiRequest } from '../services/Service';
import { store } from '../config/store';
import { saveProgressLoadingState } from '../state/UIState';

export const uploadDocuments = async (image, feature, docType = 'image') => {
  return new Promise((resolve, reject) => {
    let resizedImage;
    const upload = async () => {
      try {
        resizedImage = await optimiseImage(image, image.type.includes('gif') ? 'gif' : docType);
      } catch (err) {
        reject({ message: err?.message ?? '' });
      }
      const formData = new FormData();
      formData.append('file_type', docType);
      formData.append('feature', feature);
      formData.append('file', resizedImage);
      try {
        const apiResponce = await apiRequest(
          UPLOAD_MEDIA,
          REQUEST_TYPE.POST,
          formData,
          ENCODING_TYPE.FORM_DATA
        );
        // if (apiResponce?.response?.error && apiResponce.response?.status === false) {
        //   reject({ message: apiResponce?.response?.error });
        // } else if (apiResponce.response?.status) {
        //   resolve(apiResponce.response.data);
        // }
        if (apiResponce.response?.status) {
          resolve(apiResponce.response.data);
        } else {
          store.dispatch(
            saveProgressLoadingState({
              isProgressLoading: false,
            })
          );
        }
      } catch (e) {
        reject({ message: e.message ?? 'Something went wrong' });
      }
    };
    upload();
  });
};

const optimiseImage = (image, docType) => {
  return new Promise((resolve, reject) => {
    if (docType === 'image') {
      const img = createImageBitmap(image);
      img
        .then((value) => {
          if (
            value.height < value.width
              ? value.height > 800 || value.width > 1200
              : value.height > 1200 || value.width > 800
          ) {
            const isMoreWide = value.height < value.width;
            const height = isMoreWide ? 800 : 1200;
            const width = isMoreWide ? 1200 : 800;
            FileResizer.imageFileResizer(
              image,
              width,
              height,
              'JPEG',
              100,
              0,
              (value) => {
                resolve(value);
              },
              'file'
            );
          } else {
            resolve(image);
          }
        })
        .catch((err) => {
          reject({ ...err, message: 'Invalid file contents.' });
        });
    } else {
      resolve(image);
    }
  });
};
