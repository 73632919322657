import React from 'react';

const PdfViewerScreen = ({ location }) => {
  const params = location.state;
  return (
    <div className="main-content-container container">
      <div className="iframe-container">
        {params.pdfUrl && <iframe src={params.pdfUrl} allowFullScreen />}
      </div>
    </div>
  );
};

export default PdfViewerScreen;
