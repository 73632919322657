import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import CustomMentionEditor from './UserMention';
const ChatEditMessageModal = (props) => {
  const {
    isDialogVisible,
    setIsDialogVisible,
    leftButtonText,
    onClickLeftButton,
    rightButtonText,
    messageValue,
    onClickRightButton,
    mentionList,
    editorState,
    onSetEditorState,
  } = props;
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
    setNewMessage(messageValue);
  }, [messageValue]);

  return (
    <Modal
      onHide={() => setIsDialogVisible((val) => !val)}
      show={isDialogVisible}
      centered
      backdrop="static"
      keyboard={false}>
      <Modal.Body className="p-4">
        <div className="ijp-apply-modal-title text-center mb-4">Edit Message</div>
        <div className="w-100 px-3 py-2 mb-4">
          <CustomMentionEditor
            mentions={mentionList}
            onChangeChatTextHandler={setNewMessage}
            editorState={editorState}
            setUserMentionList={props.setUserMentionList}
            onSetEditorState={onSetEditorState}
            style={'edit'}
          />
        </div>
        <div className="d-flex justify-content-center">
          <CustomButton
            onClick={() => {
              onClickLeftButton();
            }}
            buttonMainContainer="mr-4"
            buttonStyle="common-custom-button-ternary">
            {leftButtonText}
          </CustomButton>
          <CustomButton
            onClick={() => {
              onClickRightButton(newMessage);
            }}
            buttonStyle="common-custom-button-quaternary">
            {rightButtonText}
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChatEditMessageModal;
