import React, { useEffect, useState } from 'react';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { getFeatureComponentUrlXxxHdpi, getHeaderComponentUrl } from '../../utils/accessibility';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { getConfigurations } from '../../utils/ReduxState';
import { saveSearchText, selectSearchText } from '../../state/UIState';
import { useHistory } from 'react-router-dom';
import { generatev4UniqueID, showToast } from '../../utils/common';
import AskMlChatWidget from './AskMlChatWidget';
import { selectEmployeeData } from '../../state/EmployeeData';
import { selectChatGptConfig } from '../../state/MasterData';
import { selectAccessData } from '../../state/UserAccessData';

const AskMlScreen = () => {
  const history = useHistory();
  const searchData = useSelector(selectSearchText);
  const [searchText, setSearchText] = useState(
    history?.location?.state?.preserve ? searchData : ''
  );
  const accessibilityData = useSelector(selectAccessibilityData);
  const dispatch = useDispatch();
  const [showWidget, setShowWidget] = useState(false);
  const chatGptConfig = useSelector(selectChatGptConfig);
  const employeeConfig = useSelector(selectEmployeeData);
  const accessData = useSelector(selectAccessData);
  const [showChatBot, setShowChatBot] = useState(true);
  const [uniqueUUID, setUniqueUUID] = useState('');

  const askOrgAccessData = accessData?.data?.find((item) => item.feature_key === '_askOrg');

  useEffect(() => {
    const actions = askOrgAccessData?.actions;
    setShowChatBot(actions?.includes('_chatbot'));
  }, [askOrgAccessData?.actions]);

  const toggleWidget = () => {
    setShowWidget(!showWidget);
  };

  const onTextChange = (event) => {
    setSearchText(event.target.value);
  };
  const handleHelpdesk = () => {
    const targetUrl = getConfigurations()._helpdesk_url;
    window.open(targetUrl, '_blank');
  };

  const handleSubmitFunction = (e) => {
    e.preventDefault();
    if (searchText.trim() === '') {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      dispatch(saveSearchText(searchText));
      history.push({
        pathname: NAVIGATION_ROUTES.ASK_ORG_SEARCH_RESULTS_SCREEN,
        state: searchText,
      });
    }
  };

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
        <div className="gcdo-icon-row">
          <CustomButton
            onClick={handleHelpdesk}
            fromRoute={NAVIGATION_ROUTES.ASK_ORG}
            buttonMainContainer="text-nowrap ceo-chat-ask-button"
            isSecondaryButton={true}>
            {'INITIATE REQUEST'}
          </CustomButton>
        </div>
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.ASK_ORG} />
      {showWidget && (
        <div
          className={
            'd-flex common-cursor-pointer chat-bot-floating-close-icon p-2 align-items-center justify-content-center'
          }>
          <div
            className="position-relative flex-grow-1 d-flex justify-content-center align-items-center"
            onClick={() => toggleWidget()}>
            <img
              className={'ask-org-close-float-icon'}
              src={
                'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgMzU3IDM1NyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzU3IDM1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnIGlkPSJjbGVhciI+CgkJPHBvbHlnb24gcG9pbnRzPSIzNTcsMzUuNyAzMjEuMywwIDE3OC41LDE0Mi44IDM1LjcsMCAwLDM1LjcgMTQyLjgsMTc4LjUgMCwzMjEuMyAzNS43LDM1NyAxNzguNSwyMTQuMiAzMjEuMywzNTcgMzU3LDMyMS4zICAgICAyMTQuMiwxNzguNSAgICIgZmlsbD0iI0ZGRkZGRiIvPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo='
              }
            />
          </div>
          {showWidget ? <AskMlChatWidget uniqueUUID={uniqueUUID} /> : null}
        </div>
      )}

      <div className="ask-org-string mt-5">
        Need help/assistance with something? Type your query in the
      </div>
      <div className="ask-org-string mb-4 pb-1">search bar to learn more.</div>
      <div className="d-flex flex-row align-items-center w-100 justify-content-center">
        <div className="search-bar-with-icon-ask_ml search-bar-border mr-3">
          <form className="directory-search-bar" onSubmit={handleSubmitFunction}>
            <input
              autoFocus
              className="ml-2 directory-search-bar"
              type="text"
              placeholder="Type keywords related to your query"
              onChange={onTextChange}
              value={searchText}
            />
          </form>
          <div className="gcdo-icon-row mr-2">
            <img
              onClick={handleSubmitFunction}
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_V2
              )}
              className="header-icon"
            />
          </div>
        </div>
      </div>

      {employeeConfig?.gpt_visible == 1 && showChatBot && (
        <div>
          <div className="ask-ml-blue-text mt-4 pt-2">
            {chatGptConfig?.ask_ml_home_config?.chat_gpt_guide_header || ''}
          </div>
          <div className="ask-org-string pt-1">
            {chatGptConfig?.ask_ml_home_config?.chat_gpt_guide_description || ''}
          </div>
          <div className="d-flex flex-row align-items-center justify-content-center">
            <img
              src={getFeatureComponentUrlXxxHdpi(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.CHAT_BOT,
                ACCESSIBILITY_IDENTIFIERS.ASK_ORG
              )}
              className="ask-org-chat-bot-icon common-cursor-pointer mt-2"
              onClick={() => {
                const id = generatev4UniqueID();
                setUniqueUUID(id);
                setShowWidget(true);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default AskMlScreen;
