import React, { forwardRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getHeaderComponentUrl } from '../../../utils/accessibility';
import { ACCESSIBILITY_HEADER_COMPONENTS } from '../../constants/AppConstants';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CalendarDialog = (props) => {
  const [selectedDates, setSelectedDates] = useState([]);
  const [years, setYears] = useState([]);
  const [months] = useState([
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]);

  const accessibilityData = useSelector(selectAccessibilityData);

  const getYear = (date) => {
    const dateObject = new Date(date);
    return dateObject.toDateString().split(' ')[3];
  };

  const getMonth = (date) => {
    const dateObject = new Date(date);
    let index = 0;
    const mon = dateObject.toDateString().split(' ')[1];
    switch (mon) {
      case 'Jan':
        index = 0;
        break;
      case 'Feb':
        index = 1;
        break;
      case 'Mar':
        index = 2;
        break;
      case 'Apr':
        index = 3;
        break;
      case 'May':
        index = 4;
        break;
      case 'Jun':
        index = 5;
        break;
      case 'Jul':
        index = 6;
        break;
      case 'Aug':
        index = 7;
        break;
      case 'Sep':
        index = 8;
        break;
      case 'Oct':
        index = 9;
        break;
      case 'Nov':
        index = 10;
        break;
      case 'Dec':
        index = 11;
        break;
    }
    return index;
  };

  useEffect(() => {
    let years = [];
    const maxDate = props.maxDate ? props.maxDate.split('-')[0] : 2100;
    for (let i = 1900; i <= maxDate; i++) {
      years.push(String(i));
    }
    setYears(years);
  }, [props.maxDate]);

  useEffect(() => {
    let dates = [];
    props.markedDates &&
      props.markedDates.forEach((element) => {
        dates.push(new Date(element));
      });
    setSelectedDates(dates);
  }, [props.markedDates]);

  // eslint-disable-next-line react/display-name
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div>
      <img
        src={getHeaderComponentUrl(accessibilityData, ACCESSIBILITY_HEADER_COMPONENTS.CALENDER)}
        className="header-icon mr-3"
        onClick={onClick}
        ref={ref}
      />
    </div>
  ));

  return (
    <DatePicker
      popperPlacement={props.popperPosition}
      selected={props.selectedDate}
      onChange={(date) => props.onDateSelected(date)}
      customInput={<ExampleCustomInput />}
      highlightDates={[
        {
          'react-datepicker__day--highlighted-custom-1': selectedDates,
        },
      ]}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: 10,
            display: 'flex',
            justifyContent: 'center',
          }}>
          <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {'<'}
          </button>
          <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {'>'}
          </button>
        </div>
      )}
    />
  );
};

export default CalendarDialog;
