import React from 'react';
import Divider from '../../../common/ui/divider/Divider';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';

const MomentTagDetailListItem = ({ item }) => {
  return (
    <div className="mb-3">
      <div className="microgive-view-style microgive-outer-view ">
        <UserProfilePhoto
          imageBaseUrl={item.profile_picture.base_url}
          imagePath={item.profile_picture.image_path}
          imageClass="common-user-profile-photo-md"
          employeeId={item.employee_id}
        />
        <div className="ml-3 microgive-outer-view">
          <div>
            <div className="mb-1 microgive-name">{`${item.first_name} ${item.last_name}`}</div>
            <div className="mt-1 microgive-position-text">{item.department_name}</div>
          </div>
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default MomentTagDetailListItem;
