import React from 'react';
import { Modal } from 'react-bootstrap';
import CustomButton from '../custom_button/CustomButton';

const AcknowledgementResponseModal = ({
  setShowAcknowledgementResponseModal,
  acknowledgementResponse,
  showAcknowledgementResponseModal,
}) => {
  const successDialog = () => {
    return (
      <div className="feedback-success-dialog d-flex flex-column align-items-center justify-content-center w-100">
        <div className="acknowledgement-success-title-text mt-3 align-center">
          {acknowledgementResponse?.message || 'Thank you for your response.'}
        </div>
        <div className="d-flex flex-row w-100 align-items-center justify-content-center mt-4">
          <CustomButton
            onClick={() => {
              setShowAcknowledgementResponseModal(false);
            }}>
            GO TO HOME
          </CustomButton>
        </div>
      </div>
    );
  };
  return (
    <Modal
      onHide={() => {}}
      show={showAcknowledgementResponseModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header className="justify-content-center text-uppercase">
        <Modal.Title>
          {acknowledgementResponse?.is_skipped ? 'Important!' : 'Submitted successfully!'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{successDialog()}</Modal.Body>
    </Modal>
  );
};
export default AcknowledgementResponseModal;
