import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import RewardsCentralTab from './tabs/cheersTabs/RewardsCentralTab';
import LeadersBoardTab from './tabs/cheersTabs/LeadersBoardTab';
import MyCheersTab from './tabs/cheersTabs/MyCheersTab';
import { selectCheersTitles } from '../../state/MasterData';
import { Tab } from 'react-bootstrap';
import {
  saveCheersLeadersBoardTabData,
  saveCheersMyCheersTabData,
  saveCheersTabData,
  selectCheersTabData,
} from '../../state/TabData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import NavTabs from '../../common/ui/tabs/NavTabs';
import { selectAccessData } from '../../state/UserAccessData';

const CheersScreen = ({ history }) => {
  const dispatch = useDispatch();
  const cheersTitles = useSelector(selectCheersTitles);
  const cheersTabData = useSelector(selectCheersTabData);
  const accessData = useSelector(selectAccessData);
  const [showNominateButton, setShowNominateButton] = useState(true);
  const cheersAccessData = accessData?.data?.find((item) => item.feature_key === '_cheers');

  useEffect(() => {
    if (cheersAccessData) {
      const actions = cheersAccessData?.actions;
      setShowNominateButton(actions?.includes('_nominate'));
    }
  }, [cheersAccessData]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.CHEERS_SCREEN, NAVIGATION_ROUTES.CHEERS_SCREEN);
  }, []);

  useEffect(() => {
    if (cheersTitles.length && cheersTabData === '') {
      dispatch(saveCheersTabData(cheersTitles[0].key));
    }
  }, [cheersTabData, cheersTitles, dispatch]);

  const onPressNominate = useCallback(() => {
    history.push(NAVIGATION_ROUTES.NOMINATIONS_SCREEN);
  }, [history]);

  const onTabChange = (leaderBoardKey, key) => {
    dispatch(saveCheersTabData(key));
    dispatch(saveCheersLeadersBoardTabData(leaderBoardKey));
  };

  const renderTabs = (tab) => {
    switch (tab.key) {
      case 'rewardsCentral':
        return <RewardsCentralTab onTabChange={onTabChange} />;
      case 'leadersBoard':
        return <LeadersBoardTab />;
      case 'myCheers':
        return <MyCheersTab />;
    }
  };

  return (
    <div className="container">
      <SectionHeader
        containerClass="d-flex flex-row justify-content-between"
        identifier={ACCESSIBILITY_IDENTIFIERS.CHEERS}
        onClick={onPressNominate}
        showButton={showNominateButton}
        buttonTitle="Nominate"
      />
      <div>
        <div>
          <NavTabs
            activeKey={cheersTabData}
            onSelect={(k) => {
              dispatch(saveCheersLeadersBoardTabData(''));
              dispatch(saveCheersMyCheersTabData(''));
              dispatch(saveCheersTabData(k));
            }}
            unmountOnExit>
            {cheersTitles.map((tab, index) => {
              return (
                <Tab key={`${index}`} eventKey={tab.key} title={tab.title}>
                  {renderTabs(tab)}
                </Tab>
              );
            })}
          </NavTabs>
        </div>
      </div>
    </div>
  );
};

export default CheersScreen;
