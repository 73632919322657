import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
} from '../../../common/constants/AppConstants';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import { selectConfigurations } from '../../../state/MasterData';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';

const FaqTab = ({ url }) => {
  const configurations = useSelector(selectConfigurations);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.BTO_FAQ_TAB_VISIT, '', ANALYTICS_ITEM_NAMES.BTO);
  }, []);

  const onPressAskMicroland = () => {
    const targetUrl = configurations._helpdesk_url;
    window.open(targetUrl, '_blank').focus();
  };

  return (
    <div>
      <div className="header-view pt-0 mb-3">
        <CustomButton buttonStyle="breakouts-upload-photos-btn" onClick={onPressAskMicroland}>
          Ask Microland
        </CustomButton>
      </div>
      <div className="iframe-container">
        <iframe src={url} allowFullScreen height="1000px"></iframe>
      </div>
    </div>
  );
};

export default FaqTab;
