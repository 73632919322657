const AutoLink = ({ text }) => {
  const delimiter =
    /((?:https?:\/\/|www\.)[^\s/$.?#][^\s).,$]?[^\s)]*(?:[\s.,;:?!]?(?=\s|$)))|(\S+@\S+\.\S+)/gi;

  return (
    <>
      {text?.split(delimiter)?.map((word, index) => {
        const isURL = word?.match(/^(https?:\/\/|www\.)[^\s).,$]?[^\s)]*(?:[\s.,;:?!]?(?=\s|$))/i);
        const isEmail = word?.match(/^\S+@\S+\.\S+/i);

        if (isURL) {
          let url = isURL[0];
          url = url.replace(/[\s.,;:?!]+$/, '');
          return (
            <a
              key={`${index}`}
              href={url.startsWith('http') ? url : `http://${url}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: 'block',
                maxWidth: '90%',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}>
              {url}
            </a>
          );
        } else if (isEmail) {
          return (
            <a key={`${index}`} href={`mailto:${isEmail[0]}`}>
              {isEmail[0]}
            </a>
          );
        }

        return word;
      })}
    </>
  );
};

export default AutoLink;
