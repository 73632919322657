import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
} from '../../../common/constants/AppConstants';
import { logEvent2 } from '../../../utils/FirebaseAnalyticsUtils';
import { getSecuredPdfUrl } from '../../../utils/common';
import { selectWebBaseUrl } from '../../../state/MasterData';

const NewsLetterTab = (props) => {
  const { filepath, title } = props;
  const webUrl = useSelector(selectWebBaseUrl);

  useEffect(() => {
    logEvent2({
      eventType: ANALYTICS_EVENT_TYPES.VARIABLE_PAY_TAB_VISIT,
      itemName: ANALYTICS_ITEM_NAMES.VARIABLE_PAY,
      categoryName: title,
    });
  }, [title]);

  const emptyListView = () => {
    return (
      <div className="main-content-container">
        <div className="iframe-container">
          <iframe src={getSecuredPdfUrl(webUrl, filepath)} allowFullScreen />
        </div>
      </div>
    );
  };

  return emptyListView();
};

export default NewsLetterTab;
