import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
} from '../../../common/constants/AppConstants';
import Divider from '../../../common/ui/divider/Divider';
import { formatDateDDMonthYYYY, removeHtmlContent, replaceBrTag } from '../../../utils/common';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';

const CeoChatItem = (props) => {
  const history = useHistory();

  const onPressItem = (item) => {
    history.push(`${NAVIGATION_ROUTES.CEO_CHAT}/${item.question_id}`);
  };

  const handleHtmlClicks = (e) => {
    const target = e.target.getAttribute('href');
    if (target && target.startsWith('/')) {
      e.stopPropagation();
      e.preventDefault();
      if (target.includes(NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL)) {
        logEvent(
          ANALYTICS_EVENT_TYPES.USER_MENTION_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.USER_MENTION
        );
        history.push(target);
      } else {
        logEvent(
          ANALYTICS_EVENT_TYPES.HASHTAG_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.HASHTAG
        );
        history.push(target);
      }
    }
  };

  const ceochatArray =
    props.feed &&
    props.feed.map((item, index) => {
      return (
        <div key={`${index}`} className="common-cursor-pointer" onClick={() => onPressItem(item)}>
          <div className="ceo-chat-answer-row justify-content-between mt-3 mb-2">
            <div className="ceo-chat-question-emp-name-text mb-0">
              {item.first_name + ' ' + item.last_name}
            </div>
            <div className="ceo-chat-ques-emp-date-text">
              {formatDateDDMonthYYYY(item.asked_on)}
            </div>
          </div>
          <div className="ceo-chat-main-question-div">
            <HtmlView
              html={
                item.question.length <= 256
                  ? item.question
                  : item.question.substring(0, 256) + '...'
              }
              onClick={handleHtmlClicks}
            />
            {removeHtmlContent(item.question).length > 256 && (
              <div className="thewall-post-read-color">Read More</div>
            )}
          </div>
          <Divider style="mt-2 mb-2" />
        </div>
      );
    });

  return <div>{ceochatArray}</div>;
};

export default CeoChatItem;
