import React from 'react';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import {
  formatDateDDMonthYYYY,
  formatTimeAMPM,
  isToday,
  removeHtmlContent,
} from '../../../utils/common';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
} from '../../../common/constants/AppConstants';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import { useHistory } from 'react-router-dom';

const Questiondiv = (props) => {
  const history = useHistory();

  const handleHtmlClicks = (e) => {
    const target = e.target.getAttribute('href');
    if (target && target.startsWith('/')) {
      e.stopPropagation();
      e.preventDefault();
      if (target.includes(NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL)) {
        logEvent(
          ANALYTICS_EVENT_TYPES.USER_MENTION_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.USER_MENTION
        );
        history.push(target);
      } else {
        logEvent(
          ANALYTICS_EVENT_TYPES.HASHTAG_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.HASHTAG
        );
        history.push(target);
      }
    }
  };

  return (
    <div className="ceo-chat-main-question-div">
      <div className="ceo-chat-ceo-question-row ceo-chat-row-container">
        <div className="ceo-chat-ceo-question-row ceo-chat-image-container">
          <div>
            {props.employee.profile_picture && (
              <UserProfilePhoto
                imageBaseUrl={props.employee.profile_picture.base_url}
                imagePath={props.employee.profile_picture.image_path}
                employeeId={props.employee.employee_id}
                imageClass={props.imageClassName}
              />
            )}
          </div>

          <div className="ceo-chat-employee-details">
            <div className="d-flex justify-content-between align-items-center">
              <p className="ceo-chat-question-emp-name-text mb-0">
                {props.employee.first_name + ' ' + props.employee.last_name}
              </p>
              {!!props.employee.pronouns && (
                <p className="ceo-chat-question-emp-pronoun-text mb-0 ml-1">
                  {'(' + props.employee.pronouns + ')'}
                </p>
              )}
            </div>
            <div className="ceo-chat-ques-emp-date-text">
              {isToday(props.askedOn)
                ? formatTimeAMPM(props.askedOn)
                : formatDateDDMonthYYYY(props.askedOn)}
            </div>
          </div>
        </div>
        {props.latest_reply && <div className="ceo-chat-latest-reply-text">Latest Reply</div>}
      </div>

      <div className="ceo-chat-main-question-div">
        <div className="ceo-chat-question-text">
          <HtmlView
            onClick={handleHtmlClicks}
            html={
              props.question.length > 256
                ? props.question.substring(0, 256) + '...'
                : props.question
            }
          />
          {removeHtmlContent(props.question).length > 256 && (
            <div
              className={`common-cursor-pointer mt-1 ${
                props.latest_reply ? 'ceo-chat-answer-read-style' : 'ceo-chat-read-style'
              }`}>
              Read More
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Questiondiv;
