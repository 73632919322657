import React from 'react';

const MyManagerListItem = ({ item, onRadioButtonPressed, index, selectedIndex }) => {
  return (
    <div className="form-check">
      <input
        type="radio"
        className="form-check-input"
        checked={index === selectedIndex ? true : false}
        onChange={() => {
          onRadioButtonPressed(item, index);
        }}
      />
      <label className="form-check-label cheers-self-desc-text">{item.text}</label>
    </div>
  );
};

export default MyManagerListItem;
