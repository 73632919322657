import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import {
  formatDateDDMonthYYYY,
  formatSecondsIntoMinutes,
  formatSecondsIntoTimeForVideos,
  getDeviceScreenDensityQualifierAsPath,
  getUrlWithSpecificRendition,
} from '../../../utils/common';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { saveShowEmptyContentView, selectShowEmptyContentView } from '../../../state/UIState';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { useHistory } from 'react-router-dom';
import { apiRequest, handleError } from '../../../services/Service';
import { ARTICLES, INCREASE_VIEW_COUNT_EXTERNAL_LINK } from '../../../services/ApiUrls';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectArticleFilters, selectDeiAnnouncementCategories } from '../../../state/MasterData';
import { Modal, Form } from 'react-bootstrap';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import DonationFilterItem from '../../microgive/components/DonationFilterItem';
import PlayIconOverlay from '../../../common/ui/play_icon_overlay/PlayIconOverlay';

const AnnouncementsTab = ({ showNewsDialog, toggleDialog, channelId, subChannelId }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  const [dataLength, setDataLength] = useState(0);
  const loadMoreFlag = useRef(true);
  const pageId = useRef(1);
  const [deviceScreenDensityQualifierPath] = useState(getDeviceScreenDensityQualifierAsPath());

  const dateResponse = useSelector(selectArticleFilters);
  const [selectedDate, setSelectedDate] = useState('');
  const [filteredDate, setFilteredDate] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [dateArray, setDateArray] = useState([]);

  const announcementCategories = useSelector(selectDeiAnnouncementCategories);

  useEffect(() => {
    const arr = [];
    for (var date in dateResponse) {
      arr.push(dateResponse[date].title);
    }
    setDateArray(arr);
    if (dateResponse && dateResponse[0]) {
      setSelectedDate(dateResponse && dateResponse[0].title);
    }
  }, [dateResponse]);

  useEffect(() => {
    if (pageId.current !== 1) {
      loadMoreFlag.current = false;
      setLoadMoreBtn(false);
    }
  }, [dataLength]);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.DI_ANNOUNCEMENTS_TAB_VISIT, '', ANALYTICS_ITEM_NAMES.DI);
  }, []);

  const callAnnouncementsApi = useCallback(
    async (catID = selectedCategory) => {
      setLoadMoreBtn(true);
      setLoading(true);
      dispatch(saveShowEmptyContentView(true));
      const get_params = {
        page_id: pageId.current,
        year: filteredDate,
        channel_id: channelId,
        sub_channel_id: catID,
      };
      try {
        const apiResponse = await apiRequest(ARTICLES, REQUEST_TYPE.GET, get_params);
        if (pageId.current === 1) {
          setAnnouncements([]);
        }
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.data.feeds) {
              const data = apiResponse.response.data.feeds;
              setDataLength(data.length);
              if (data.length > 0) {
                setAnnouncements((oldArray) => [...oldArray, ...data]);
              }
            }
          }
        }
        setLoading(false);
      } catch (err) {
        handleError(err, get_params, ARTICLES, NAVIGATION_ROUTES.DI);
        setLoading(false);
      }
    },
    [dispatch, filteredDate]
  );

  const renderScreen = useCallback(async () => {
    callAnnouncementsApi();
  }, [callAnnouncementsApi]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const loadMore = () => {
    if (loadMoreFlag.current) {
      pageId.current = pageId.current + 1;
      callAnnouncementsApi();
    }
  };

  const emptyListView = () => {
    return (
      <DefaultContentView
        message={NO_DATA_TEXT.NO_DATA_FOUND}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  const onPressCategory = (item) => {
    setSelectedCategory(item.category_id);
    setAnnouncements([]);
    pageId.current = 1;
    loadMoreFlag.current = true;
    callAnnouncementsApi(item.category_id);
  };

  const onPressItem = async (item) => {
    if (item.content_load_type === '1') {
      try {
        const es = await apiRequest(
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          REQUEST_TYPE.GET,
          {}
        );
      } catch (err) {
        handleError(
          err,
          {},
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          NAVIGATION_ROUTES.DI
        );
        //ignore if the view count request is not successful
        console.warn(err);
      }
      window.open(item.url);
    } else {
      history.push(`${NAVIGATION_ROUTES.DI}/${item.article_id}`);
    }
  };

  const newsArray =
    announcements &&
    announcements.map((item, index) => {
      return (
        <div
          key={`${index}`}
          className="col-sm-4 mb-2 common-cursor-pointer"
          onClick={() => onPressItem(item)}>
          <div>
            {item?.content_load_type === '3' && <PlayIconOverlay />}
            <img
              src={getUrlWithSpecificRendition(
                item.image.base_url,
                SCREEN_DENSITY_QUALIFIERS.HDPI,
                item.image.image_path
              )}
              className="embed-responsive embed-responsive-16by9"
            />
            <div className="row ml-1 mt-1">
              {/* <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.NEWS_CALENDER,
                  ACCESSIBILITY_IDENTIFIERS.NEWS
                )}
                className="di-calender-style"
              /> */}
              <div className="d-flex flex-row justify-content-between w-100 pr-3">
                {!!item?.time_to_read && (
                  <div className="broadcast-event-date-new ">
                    {item.time_to_read != 0 ? formatSecondsIntoMinutes(item.time_to_read) : null}
                  </div>
                )}
                {!!item?.video_duration &&
                  item?.content_load_type === '3' &&
                  item?.video_duration > 0 && (
                    <div className="broadcast-event-date-new ">
                      {formatSecondsIntoTimeForVideos(item?.video_duration)}
                    </div>
                  )}
                <div className="broadcast-event-date-new ">
                  {formatDateDDMonthYYYY(item.pub_datetime)}
                </div>
                {!!item.page_views && (
                  <div className="broadcast-event-date-new ">
                    {item.page_views > 1 ? `${item.page_views} views` : `${item.page_views} view`}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="di-title">{item.title}</div>
        </div>
      );
    });

  const CustomYearView = () => {
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Label>Select Year</Form.Label>
            <div className="di-year-container">
              <Form.Control
                as="select"
                custom
                value={selectedDate}
                onChange={(event) => {
                  setSelectedDate(event.target.value);
                }}>
                {dateArray &&
                  dateArray.map((item) => {
                    return (
                      <option key={item} defaultValue={item === selectedDate}>
                        {item}
                      </option>
                    );
                  })}
              </Form.Control>
            </div>
          </Form.Group>
        </Form>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <CustomButton
              buttonStyle="di-calender-button-style"
              onClick={() => {
                setSelectedDate('');
                toggleDialog(false);
                if (filteredDate !== '') {
                  pageId.current = 1;
                  loadMoreFlag.current = true;
                  setAnnouncements([]);
                  setFilteredDate('');
                }
              }}>
              CLEAR FILTERS
            </CustomButton>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <CustomButton
              buttonStyle="di-calender-button-style"
              onClick={() => {
                toggleDialog(false);
              }}>
              CANCEL
            </CustomButton>
            <CustomButton
              buttonStyle="di-calender-button-style"
              onClick={() => {
                pageId.current = 1;
                loadMoreFlag.current = true;
                setAnnouncements([]);
                const tempDate = dateResponse.find((res) => res.title === selectedDate).value;
                setFilteredDate(tempDate);
                toggleDialog(false);
              }}>
              DONE
            </CustomButton>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="main-content-container">
      <Modal
        show={showNewsDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => toggleDialog(false)}>
        <Modal.Body>{CustomYearView()}</Modal.Body>
      </Modal>
      <div className="microgive-category-container">
        {announcementCategories.map((item, index) => {
          return (
            <DonationFilterItem
              item={item}
              onPress={onPressCategory}
              key={`${index}`}
              categoryId={selectedCategory}
            />
          );
        })}
      </div>
      {showEmptyContentView && !newsArray.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          <div className="row">{newsArray}</div>
          <ActivityLoader visible={loading} />
          {loadMoreBtn && (
            <div className="mb-4 mt-4">
              <LoadMoreBtn onClick={loadMore} isLoading={loading} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(AnnouncementsTab);
