import { createSlice } from '@reduxjs/toolkit';

const saveCurrentGroupIdReducer = (state, action) => {
  state.currentGroupId = action.payload;
};

const saveUserChatGroupsReducer = (state, action) => {
  let groupModel = action.payload;
  state.userChatGroups[groupModel.groupId] = groupModel;
};

const removeGroupReducer = (state, action) => {
  let groupId = action.payload;
  let userChatGroups = state.userChatGroups;
  delete userChatGroups[groupId];
  state.userChatGroups = userChatGroups;
};

const saveTotalActiveGroupsReducer = (state, action) => {
  let groups = action.payload;
  let totalActiveGroups = 0;
  Object.values(groups).forEach((value) => {
    if (value === true) {
      totalActiveGroups += 1;
    }
  });
  state.totalActiveGroups = totalActiveGroups;
};

const saveForwardedMessageReducer = (state, action) => {
  state.forwardedMessage = action.payload;
};

// REDUCER
const chatDataSlice = createSlice({
  name: 'chatData',
  initialState: {
    currentGroupId: '',
    userChatGroups: {},
    totalActiveGroups: 0,
    forwardedMessage: '',
  },
  reducers: {
    saveCurrentGroupId: saveCurrentGroupIdReducer,
    saveUserChatGroups: saveUserChatGroupsReducer,
    removeGroup: removeGroupReducer,
    saveTotalActiveGroups: saveTotalActiveGroupsReducer,
    saveForwardedMessage: saveForwardedMessageReducer,
  },
});

// ACTIONS
const {
  saveCurrentGroupId,
  saveUserChatGroups,
  removeGroup,
  saveTotalActiveGroups,
  saveForwardedMessage,
} = chatDataSlice.actions;

// SELECTOR
const selectCurrentGroupId = ({ chatData }) => chatData.currentGroupId;
const selectUserChatGroups = ({ chatData }) => chatData.userChatGroups;
const selectTotalActiveGroups = ({ chatData }) => chatData.totalActiveGroups;
const selectForwardedMessage = ({ chatData }) => chatData.forwardedMessage;

const chatDataSliceReducer = chatDataSlice.reducer;

export {
  chatDataSliceReducer,
  saveCurrentGroupId,
  saveUserChatGroups,
  selectCurrentGroupId,
  selectUserChatGroups,
  removeGroup,
  saveTotalActiveGroups,
  selectTotalActiveGroups,
  saveForwardedMessage,
  selectForwardedMessage,
};
