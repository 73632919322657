import React, { useEffect, useState } from 'react';
import HtmlView from '../../common/ui/html_view/HTMLView';
import {
  getDeviceScreenDensityQualifierAsPath,
  getUrlWithSpecificRendition,
} from '../../utils/common';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../common/constants/AppConstants';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';

const ForwordScreen = ({ location }) => {
  const [employee] = useState(location.state.employee);
  const [deviceScreenDensityQualifierPath] = useState(getDeviceScreenDensityQualifierAsPath());

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.SOM_FOREWORD, NAVIGATION_ROUTES.SOM_FOREWORD);
  }, []);

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.SOM} />
      <BackButton containerStyle="pt-3" />
      {employee && (
        <div className="som-forward-screen-center-size">
          <div className="som-forward-screen-image-outer">
            <img
              src={getUrlWithSpecificRendition(
                employee.promo_image.base_url,
                SCREEN_DENSITY_QUALIFIERS.HDPI,
                employee.promo_image.image_path
              )}
              className="som-forward-screen-image"
            />
          </div>
          <div className="som-forward-summary-text som-forward-screen-summary-container">
            <HtmlView html={employee.summary.replace(/\n/g, '<br>')} />
          </div>
          <div className="som-foreword-name-text som-forward-screen-name-container">
            {employee.name}
          </div>

          <HtmlView html={employee.description} htmlStyle="som-forward-screen-web-view" />
        </div>
      )}
    </div>
  );
};

export default ForwordScreen;
