import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
} from '../../../common/constants/AppConstants';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import { selectEmployeeData } from '../../../state/EmployeeData';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import { getSecuredPdfUrl } from '../../../utils/common';
import { selectWebBaseUrl } from '../../../state/MasterData';

const MyInsuranceTab = () => {
  const employeeData = useSelector(selectEmployeeData);
  const [isLoaded, setIsLoaded] = useState(true);
  const webUrl = useSelector(selectWebBaseUrl);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.MY_PROFILE_INSURANCE_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.MY_PROFILE
    );
  }, []);

  return (
    <div className="main-content-container">
      <ActivityLoader visible={isLoaded} />
      <div className="iframe-container">
        <iframe
          src={getSecuredPdfUrl(webUrl, employeeData?.insurance_details ?? '')}
          allowFullScreen
          onLoad={() => setIsLoaded(false)}
        />
      </div>
    </div>
  );
};

export default React.memo(MyInsuranceTab);
