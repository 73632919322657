import React, { memo, useEffect, useState } from 'react';

const PreviousImageChatView = ({ item, setShowPreviousImageDialog }) => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (item && item.url) {
      setImageUrl(item.url);
    }
  }, [item]);

  return (
    <div className="d-flex my-1">
      <div
        onClick={() => setShowPreviousImageDialog(true)}
        className="py-2 px-2 common-cursor-pointer chat-outgoing-reply-container d-flex justify-content-between align-items-center">
        <p className="mb-0">Image</p>
        <div className="ml-3">
          <div>
            {imageUrl !== '' ? <img src={imageUrl} className="chat-outgoing-reply-icon" /> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(PreviousImageChatView);
