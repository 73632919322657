import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  FEATURE_TYPE,
} from '../../../common/constants/AppConstants';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import { GET_UPDATES_LIST, SUBMIT_LIKE_COMMENT_BREAKOUT } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectShowEmptyContentView,
} from '../../../state/UIState';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import UpdatesTabItem from '../components/UpdatesTabItem';

const UpdatesTab = ({ breakoutId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [updatesList, setUpdatesList] = useState([]);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const [pageId, setPageId] = useState(1);
  const [loading, setLoading] = useState(true);

  const showEmptyContentView = useSelector(selectShowEmptyContentView);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.BREAKOUTS_UPDATES_TAB_VISIT,
      breakoutId,
      ANALYTICS_ITEM_NAMES.BREAKOUTS
    );
  }, [breakoutId]);

  const callUpdatesApi = useCallback(
    async (id, loadMore) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        const params = {
          page_id: id,
        };
        try {
          setLoading(true);
          const apiResponse = await apiRequest(
            GET_UPDATES_LIST + breakoutId + '/update',
            REQUEST_TYPE.GET,
            params
          );
          setLoading(false);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                setUpdatesList((oldArray) => [...oldArray, ...apiResponse.response.data.feeds]);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(
            err,
            params,
            GET_UPDATES_LIST + breakoutId + '/update',
            NAVIGATION_ROUTES.BREAKOUTS
          );
          setLoading(false);
        }
      }
    },
    [breakoutId, dispatch]
  );

  useEffect(() => {
    callUpdatesApi(1, true);
  }, [callUpdatesApi]);

  const callLikeApi = async (update_id) => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: 'Updating Like Count...',
        })
      );
      const params = new URLSearchParams();
      params.append('type', FEATURE_TYPE.BREAKOUT_UPDATE);
      const apiResponse = await apiRequest(
        SUBMIT_LIKE_COMMENT_BREAKOUT + update_id + '/like',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setPageId(1);
          setUpdatesList([]);
          setLoadMoreFlag(true);
          callUpdatesApi(1, true);
        }
      }
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressLikeButton = (item) => {
    if (item.likes && !item.likes.is_liked) {
      logEvent(
        ANALYTICS_EVENT_TYPES.BREAKOUTS_UPDATES_LIKE,
        item.update_id,
        ANALYTICS_ITEM_NAMES.LIKE
      );
      callLikeApi(item.update_id);
    }
  };

  const onPressCommentButton = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.BREAKOUTS_UPDATES_COMMENT,
      item.update_id,
      ANALYTICS_ITEM_NAMES.COMMENT
    );
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: item.update_id,
      likeType: FEATURE_TYPE.BREAKOUT_UPDATE,
      fromRoute: NAVIGATION_ROUTES.BREAKOUTS,
    });
  };

  const onPressLoadMore = () => {
    callUpdatesApi(pageId, loadMoreFlag);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.STAY_TUNED_FOR_UPDATES} />;
  };

  const onPressLikedBy = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.BREAKOUTS_UPDATES_LIKE,
      item.update_id,
      ANALYTICS_ITEM_NAMES.LIKE
    );

    history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
      referId: item.update_id,
      parentType: FEATURE_TYPE.BREAKOUT_UPDATE,
    });
  };

  return (
    <div className="main-content-container">
      {showEmptyContentView && !updatesList.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          {updatesList.map((item, index) => {
            return (
              <UpdatesTabItem
                item={item}
                onPressLikeButton={onPressLikeButton}
                onPressCommentButton={onPressCommentButton}
                onPressLikedBy={onPressLikedBy}
                key={`${index}`}
              />
            );
          })}
          <ActivityLoader visible={loading} />
          {loadMoreFlag && !!updatesList.length && (
            <LoadMoreBtn onClick={onPressLoadMore} isLoading={loading} />
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(UpdatesTab);
