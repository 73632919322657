import React, { useEffect } from 'react';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import Divider from '../../common/ui/divider/Divider';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';
import { formatDateDDMonthYYYY, formatTimeAMPM } from '../../utils/common';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import TaggedPeopleListItem from './components/TaggedPeopleListItem';

const TaggedPeopleScreen = ({ location }) => {
  const { nominationsList, from, employeeDetail } = location.state;

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.TAGGED_PEOPLE_SCREEN,
      NAVIGATION_ROUTES.TAGGED_PEOPLE_SCREEN
    );
  }, []);

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CHEERS} />
      <BackButton />
      {from !== 'given' ? (
        <div>
          {nominationsList.map((item, index) => {
            return <TaggedPeopleListItem item={item} key={`${index}`} />;
          })}
        </div>
      ) : (
        <div className="col-sm-12 mb-3">
          <div className="microgive-view-style cheers-date-view-style">
            <UserProfilePhoto
              imageBaseUrl={employeeDetail.employee.profile_picture.base_url}
              imagePath={employeeDetail.employee.profile_picture.image_path}
              imageClass="common-user-profile-photo-lg"
              employeeId={employeeDetail.employee.employee_id}
            />
            <div className="ml-3 microgive-outer-view col-sm-6">
              <div>
                <div className="mb-1 microgive-name">{`${employeeDetail.employee.first_name} ${employeeDetail.employee.last_name} `}</div>
                <div className="mt-1 microgive-position-text">
                  {employeeDetail.employee.position_title}
                </div>
              </div>
            </div>
            <div className="ml-3 microgive-outer-view">
              <div>
                <div className="mb-1 cheers-date-time-text">
                  {formatTimeAMPM(employeeDetail.created_on)}
                </div>
                <div className="mt-1 cheers-date-time-text">
                  {formatDateDDMonthYYYY(employeeDetail.created_on)}
                </div>
              </div>
            </div>
          </div>
          <Divider />
        </div>
      )}
    </div>
  );
};

export default TaggedPeopleScreen;
