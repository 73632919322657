import React from 'react';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';

const SpeakersAttendeesListItem = ({ item }) => {
  return (
    <div>
      <div className="breakouts-donor-item row ml-1 mr-1">
        <UserProfilePhoto
          imageBaseUrl={item.profile_picture.base_url}
          imagePath={item.profile_picture.image_path}
          employeeId={item.employee_id}
          imageClass="common-user-profile-photo-md"
        />
        <div className="ml-3 breakouts-outer-view">
          <div>
            <div className="mb-1">{`${item?.first_name ?? ''} ${item?.middle_name ?? ''} ${
              item?.last_name ?? ''
            } `}</div>
            <div className="mt-1">{item.position_title}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpeakersAttendeesListItem;
