import React from 'react';

const EsgContentFilterItem = ({ item, onPress, filterId }) => {
  return (
    <div>
      {filterId === item.filter_id ? (
        <div className="microgive-categories-selected">{item?.title}</div>
      ) : (
        <div className="microgive-categories" onClick={() => onPress(item)}>
          {item.title}
        </div>
      )}
    </div>
  );
};

export default EsgContentFilterItem;
