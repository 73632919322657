import { useDispatch, useSelector } from 'react-redux';
import { BUTTON_TITLES, MODAL_TITLES } from '../../constants/AppConstants';
import CustomModal from '../custom_modal/CustomModal';
import { saveShowUnAuthorised, selectShowUnAuthorised } from '../../../state/EStoreData';
import { useHistory } from 'react-router-dom';
import { selectEStoreConfig } from '../../../state/MasterData';

const StoreUnAuthorisedError = () => {
  const dispatch = useDispatch();
  const showModal = useSelector(selectShowUnAuthorised);
  const history = useHistory();
  const eStoreConfig = useSelector(selectEStoreConfig);

  const goToHome = () => {
    dispatch(saveShowUnAuthorised(false));
    history.goBack();
  };

  return (
    <CustomModal
      visible={showModal}
      description={eStoreConfig.web_modal_messages.unauthorised_description}
      title={MODAL_TITLES.ALERT}
      primaryButtonTitle={BUTTON_TITLES.OK}
      onClickPrimaryButton={() => goToHome()}
      onClick={goToHome}
    />
  );
};

export default StoreUnAuthorisedError;
