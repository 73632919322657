import React, { useCallback, useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { resetLoaderState, saveProgressLoadingState } from '../../../state/UIState';
import { useDispatch, useSelector } from 'react-redux';
import { apiRequestEStore } from '../../../services/Service';
import { useHistory } from 'react-router';
import { selectEStoreConfig } from '../../../state/MasterData';
import { logEvent, trackScreen } from '../../../utils/FirebaseAnalyticsUtils';
import {
  GET_CHILDREN_OF_CONFIG_PRODUCT,
  MOST_VIEWED_PRODUCTS,
  PRODUCTS,
} from '../../../services/ApiUrls';

const StoreHomeTab = () => {
  const [currentActiveSlideIndex, setCurrentActiveSlideIndex] = useState(0);
  const [products, setProducts] = useState([]);
  const [trendingProducts, setTrendingProducts] = useState([]);
  const history = useHistory();
  const estoreConfig = useSelector(selectEStoreConfig);
  const dispatch = useDispatch();
  const [loaders, setLoaders] = useState([]);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.MICRO_STORE_HOME_TAB,
      NAVIGATION_ROUTES.MICRO_STORE_MAIN_SCREEN
    );
  }, []);

  const fetchConfigurableProductPrice = useCallback(async (sku) => {
    setLoaders((prev) => [...prev, 1]);
    try {
      const apiResponse = await apiRequestEStore(
        GET_CHILDREN_OF_CONFIG_PRODUCT(sku),
        REQUEST_TYPE.GET
      );

      setLoaders((prev) => [...prev].slice(0, -1));
      if (Object.keys(apiResponse).length) {
        return apiResponse.response[0]?.price;
      } else {
        return 0;
      }
    } catch (err) {
      setLoaders((prev) => [...prev].slice(0, -1));
    }
  }, []);

  const fetchProducts = useCallback(async () => {
    setLoaders((prev) => [...prev, 1]);
    try {
      const [featuredResponse, trendingResponse] = await Promise.all([
        apiRequestEStore(
          `${PRODUCTS}?searchCriteria[filter_groups][0][filters][0][field]=featured&searchCriteria[filter_groups][0][filters][0][value]=1&searchCriteria[filter_groups][0][filters][0][condition_type]=eq&searchCriteria[filter_groups][1][filters][0][field]=visibility&searchCriteria[filter_groups][1][filters][0][value]=1&searchCriteria[filter_groups][1][filters][0][condition_type]=neq&searchCriteria[pageSize]=${estoreConfig.featured_products_count}&searchCriteria[currentPage]=1&searchCriteria[filter_groups][2][filters][0][field]=status&searchCriteria[filter_groups][2][filters][0][value]=1&searchCriteria[filter_groups][2][filters][0][condition_type]=eq`,
          REQUEST_TYPE.GET
        ),
        apiRequestEStore(
          `${MOST_VIEWED_PRODUCTS}?pageSize=${estoreConfig.trending_products_count}`,
          REQUEST_TYPE.GET
        ),
      ]);
      if (trendingResponse.response.length) {
        const tempProducts = [];
        for (const item of trendingResponse.response) {
          const price =
            item.price === 0 ? await fetchConfigurableProductPrice(item.sku) : item.price;
          tempProducts.push({
            ...item,
            price,
          });
        }
        setTrendingProducts(tempProducts);
      }
      if (Object.keys(featuredResponse).length) {
        if (featuredResponse.response && featuredResponse.response.items?.length > 0) {
          const tempProducts = [];
          for (const item of featuredResponse.response.items) {
            const images = [];
            let thumbnail = '';
            item.media_gallery_entries.map((image) => {
              images.push({ type: image.media_type, file: image.file, thumbnail });
              if (image.types.includes('thumbnail')) {
                thumbnail = image.file;
              }
            });
            const price =
              item.type_id === 'configurable'
                ? await fetchConfigurableProductPrice(item.sku)
                : item.price;
            tempProducts.push({
              id: item.id,
              sku: item.sku,
              name: item.name,
              price: price,
              images,
              thumbnail,
            });
          }
          setProducts(tempProducts);
          setLoaders((prev) => [...prev].slice(0, -1));
        } else {
          setLoaders((prev) => [...prev].slice(0, -1));
        }
      } else {
        setLoaders((prev) => [...prev].slice(0, -1));
      }
    } catch (err) {
      console.log(err);
      setLoaders((prev) => [...prev].slice(0, -1));
    }
  }, [
    estoreConfig.featured_products_count,
    estoreConfig.trending_products_count,
    fetchConfigurableProductPrice,
  ]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  const handleViewAll = () => {
    logEvent(ANALYTICS_EVENT_TYPES.ESTORE_ALL_PRODUCTS, '', ANALYTICS_ITEM_NAMES.ALL_PRODUCTS);
    history.push(NAVIGATION_ROUTES.MICRO_STORE_ALL_PRODUCTS_SCREEN);
  };

  const onPressItem = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ESTORE_FEATURED_PRODUCT_CLICK,
      item.sku,
      ANALYTICS_ITEM_NAMES.FEATURED_PRODUCT
    );
    history.push(`${NAVIGATION_ROUTES.MICRO_STORE_PRODUCT_SCREEN}/${item.sku}`);
  };

  useEffect(() => {
    if (loaders.length > 0) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
    } else {
      dispatch(resetLoaderState());
    }
  }, [dispatch, loaders]);

  return (
    <div>
      <Carousel
        activeIndex={currentActiveSlideIndex}
        className="pb-1"
        nextIcon={
          estoreConfig.banners_web.length > 1 && (
            <span aria-hidden="true" className="carousel-control-next-icon" />
          )
        }
        prevIcon={
          estoreConfig.banners_web.length > 1 && (
            <span aria-hidden="true" className="carousel-control-prev-icon" />
          )
        }
        onSelect={(selectedIndex) => setCurrentActiveSlideIndex(selectedIndex)}>
        {estoreConfig.banners_web.map((imageItem, index) => {
          return (
            <Carousel.Item key={`${index}`}>
              <div className="d-flex justify-content-center">
                <img
                  className="estore-carousel-image"
                  src={imageItem.base_url + imageItem.filename}
                />
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
      <div className="estore-products-block">
        {products.length > 0 && (
          <div className="estore-home-tab-featured-products">
            <div className="estore-home-tab-products-header">
              <div className="estore-product-type-title">FEATURED PRODUCTS</div>
              <div
                onClick={handleViewAll}
                className="estore-product-type-title view-all-underline common-cursor-pointer">
                ALL PRODUCTS
              </div>
            </div>
            <div className="home-tab-product-list">
              {products.map((product, index) => (
                <div
                  onClick={() => onPressItem(product)}
                  key={index}
                  className="home-tab-product-item">
                  <img
                    src={`${estoreConfig.image_base_url}${
                      product.thumbnail ? product.thumbnail : product.images[0]?.file
                    }`}
                    className="estore-home-item-image"
                  />
                  <div className="estore-home-item-title-price">
                    <div className="estore-product-name">{product.name}</div>
                    {product.price > 0 && (
                      <div className="estore-product-name">{`\u20b9 ${product.price}`}</div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="estore-products-block">
        {trendingProducts.length > 0 && (
          <div className="estore-home-tab-featured-products">
            <div className="estore-home-tab-products-header">
              <div className="estore-product-type-title">TRENDING PRODUCTS</div>
              <div
                onClick={handleViewAll}
                className="estore-product-type-title view-all-underline common-cursor-pointer">
                ALL PRODUCTS
              </div>
            </div>
            <div className="home-tab-product-list">
              {trendingProducts.map((product, index) => (
                <div
                  onClick={() => onPressItem(product)}
                  key={index}
                  className="home-tab-product-item">
                  <img src={product.image} className="estore-home-item-image" />
                  <div className="estore-home-item-title-price">
                    <div className="estore-product-name">{product.name}</div>
                    {product.price > 0 && (
                      <div className="estore-product-name">{`\u20b9 ${product.price}`}</div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StoreHomeTab;
