import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NO_DATA_TEXT,
} from '../../../common/constants/AppConstants';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import StarEmployeeView from '../components/StarEmployeeView';

const StarTab = (props) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.SOM_STAR_TAB, props.item, ANALYTICS_ITEM_NAMES.SOM);
  }, [props.item]);

  const getYearView = () => {
    const elements = [];
    var keys = Object.keys(props.data);
    keys.sort(function (a, b) {
      return b - a;
    });
    for (var i = 0; i < keys.length; i++) {
      if (props.data[keys[i]].length > 0) {
        elements.push(
          <StarEmployeeView key={keys[i]} year={keys[i]} employees={props.data[keys[i]]} />
        );
      }
    }
    return <div>{elements}</div>;
  };

  const showEmptyListView = () => {
    return (
      <DefaultContentView
        message={NO_DATA_TEXT.NO_DATA_FOUND}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  return (
    <div>
      {props.data && Object.values(props.data).every((o) => o.length === 0) ? (
        showEmptyListView()
      ) : (
        <div>{props.data && getYearView()}</div>
      )}
    </div>
  );
};

export default StarTab;
