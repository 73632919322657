import React from 'react';
import { Modal } from 'react-bootstrap';
import { SUCCESS_MESSAGES } from '../../../common/constants/AppConstants';
import CustomButton from '../../../common/ui/custom_button/CustomButton';

const IjprAppliedDialog = (props) => {
  const { isIjprAppliedDialogVisible, setIsIjprAppliedDialogVisible, isIjpSectionTypeActive } =
    props;

  return (
    <Modal
      onHide={() => setIsIjprAppliedDialogVisible((val) => !val)}
      show={isIjprAppliedDialogVisible}
      centered
      backdrop="static"
      keyboard={false}>
      <Modal.Body className="p-4">
        <h5 className="ijp-applied-modal-title text-center mb-4">
          {isIjpSectionTypeActive
            ? SUCCESS_MESSAGES.IJPR_JOB_APPLIED_TITLE
            : SUCCESS_MESSAGES.IJPR_REFERRAL_APPLIED_TITLE}
        </h5>
        <p className="ijp-applied-modal-subtitle text-center">
          {isIjpSectionTypeActive
            ? SUCCESS_MESSAGES.IJPR_JOB_APPLIED_SUBTITLE
            : SUCCESS_MESSAGES.IJPR_REFERRAL_APPLIED_SUBTITLE}
        </p>
        <div className="d-flex justify-content-center">
          <CustomButton
            onClick={() => {
              setIsIjprAppliedDialogVisible(false);
            }}
            buttonStyle="common-custom-button-quaternary">
            OK
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default IjprAppliedDialog;
