import React, { useCallback, useEffect, useState } from 'react';
import HtmlView from '../../common/ui/html_view/HTMLView';
import {
  ACCESSIBILITY_IDENTIFIERS,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_TITLE,
} from '../../common/constants/AppConstants';
import { useDispatch } from 'react-redux';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { apiRequestEStore } from '../../services/Service';
import { saveProgressLoadingState, saveSearchText } from '../../state/UIState';
import BackButtonStore from '../../common/ui/common_back_button/BackButtonStore';
import { useHistory, useParams } from 'react-router';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { GET_ESTORE_POLICY_BY_ID } from '../../services/ApiUrls';

const MiStorePolicyScreen = () => {
  const dispatch = useDispatch();
  const { policyId } = useParams();
  const [content, setContent] = useState('');
  const history = useHistory();

  const getHeaderTitle = useCallback((id) => {
    switch (id) {
      case '5':
        return SCREEN_TITLE.RETURN_POLICY;
      case '6':
        return SCREEN_TITLE.MI_STORE_TC;
      case '7':
        return SCREEN_TITLE.MI_STORE_FAQ;
      case '8':
        return SCREEN_TITLE.PRIVACY_POLICY;
    }
  }, []);

  useEffect(() => {
    trackScreen(getHeaderTitle(policyId), NAVIGATION_ROUTES.MI_STORE_INVOICE_SCREEN);
  }, [getHeaderTitle, policyId]);

  const fetchContent = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequestEStore(
        `${GET_ESTORE_POLICY_BY_ID}${policyId}`,
        REQUEST_TYPE.GET
      );
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response) {
          setContent(apiResponse.response.content);
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [policyId, dispatch]);

  useEffect(() => {
    fetchContent();
  }, [fetchContent]);

  const handleSubmitFunction = (searchText) => {
    dispatch(saveSearchText(searchText));
    history.push(NAVIGATION_ROUTES.MICRO_STORE_ALL_PRODUCTS_SCREEN);
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICRO_STORE} />
      <BackButtonStore
        backTitle={getHeaderTitle(policyId)?.toUpperCase()}
        onSubmit={handleSubmitFunction}
      />
      <div className="pl-2 pr-2">
        <HtmlView
          html={content.replace(/&lt;/g, '<').replace(/&gt;/g, '>')}
          htmlStyle="breakout-content-html mt-2"
        />
      </div>
    </div>
  );
};

export default MiStorePolicyScreen;
