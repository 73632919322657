import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FILTER_IDENTIFIERS,
  REQUEST_TYPE,
  CELEBRATION_IDENTIFIERS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import { selectCelebrationsTabs } from '../../state/MasterData';
import { apiRequest } from '../../services/Service';
import { getCurrentYear, getDateInYYYYMMDD, isLeapYear } from '../../utils/common';
import { GET_CELEBRATIONS_DATA, GET_CELEBRATIONS_DATA_FROM_DATE } from '../../services/ApiUrls';
import { saveShowEmptyContentView } from '../../state/UIState';
import { Tab } from 'react-bootstrap';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import CelebrationTab from './tabs/CelebrationTab';
import CalendarDialog from '../../common/ui/calender_dialog/CalendarDialog';
import Filter from '../../common/ui/filter/Filter';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import ChatbotWidget from '../../common/ui/chatbot_widget/ChatbotWidget';
import NavTabs from '../../common/ui/tabs/NavTabs';
import { selectAccessData } from '../../state/UserAccessData';

const CelebrationsScreen = ({ location, history }) => {
  const dispatch = useDispatch();
  const celebrationsTabData = useSelector(selectCelebrationsTabs);
  const appliedFiltersRef = useRef({});
  const isFilterAppliedRef = useRef(false);
  const isCelebrationByDateAppliedRef = useRef({});
  const isFilterLoadedRef = useRef({});
  const calendarDateRef = useRef({});
  const calendarDateRangeRef = useRef({});
  const [key, setKey] = useState(CELEBRATION_IDENTIFIERS.BIRTHDAYS);
  const [isChatbotActionable, setIsChatbotActionable] = useState(false);
  const [celebrationsData, setCelebrationsData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [celebrationDates, setCelebrationDates] = useState({});
  const [markedDates, setMarkedDates] = useState([]);
  const todayDate = new Date();
  const [selectedDate, setSelectedDate] = useState(todayDate);
  const currentYear = getCurrentYear();
  const accessData = useSelector(selectAccessData);
  const [showChatbot, setShowChatbot] = useState(true);
  const [showFilterButton, setShowFilterButton] = useState(true);
  const celebrationsAccessData = accessData?.data?.find(
    (item) => item.feature_key === '_celebrations'
  );

  useEffect(() => {
    if (celebrationsAccessData) {
      const actions = celebrationsAccessData?.actions;
      setShowChatbot(actions?.includes('_chatbot'));
      setShowFilterButton(actions?.includes('_filter'));
    }
  }, [celebrationsAccessData]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.CELEBRATIONS, NAVIGATION_ROUTES.CELEBRATIONS);
  }, []);

  const formatCelebrationsDates = useCallback(
    (data, celebrationType) => {
      let tempCelebrationDates = [];
      data.dates.forEach((date, index) => {
        if (date === '02-29') {
          if (isLeapYear(currentYear)) {
            tempCelebrationDates[index] = currentYear + '-' + date;
          }
        } else {
          tempCelebrationDates[index] = currentYear + '-' + date;
        }
      });
      setCelebrationDates((celebrationsDates) => {
        return { ...celebrationsDates, ...{ [celebrationType]: tempCelebrationDates } };
      });
    },
    [currentYear]
  );

  const callCelebrationsApi = useCallback(
    async (celebrationType) => {
      dispatch(saveShowEmptyContentView(true));
      setIsLoading(true);
      try {
        if (isFilterAppliedRef.current) {
          isFilterLoadedRef.current = {
            ...isFilterLoadedRef.current,
            ...{ [celebrationType]: true },
          };
        }
        isCelebrationByDateAppliedRef.current = {
          ...isCelebrationByDateAppliedRef.current,
          ...{ [celebrationType]: calendarDateRef.current[celebrationType] },
        };

        let params = {
          ...appliedFiltersRef.current,
        };

        if (calendarDateRangeRef.current[celebrationType]) {
          params = {
            ...params,
            ...calendarDateRangeRef.current[celebrationType],
          };
        }

        let apiResponse = {};
        if (calendarDateRef.current[celebrationType] && celebrationType !== 'advancements') {
          params = {
            ...params,
            date: getDateInYYYYMMDD(calendarDateRef.current[celebrationType]),
          };
          apiResponse = await apiRequest(
            GET_CELEBRATIONS_DATA_FROM_DATE + celebrationType,
            REQUEST_TYPE.GET,
            params
          );
        } else {
          apiResponse = await apiRequest(
            GET_CELEBRATIONS_DATA + celebrationType,
            REQUEST_TYPE.GET,
            params
          );
        }
        setIsLoading(false);
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            const data = apiResponse.response.data;
            setCelebrationsData((celebrations) => {
              return { ...celebrations, ...{ [celebrationType]: data } };
            });
            formatCelebrationsDates(data, celebrationType);
          }
        }
      } catch (err) {
        setIsLoading(false);
      }
    },
    [dispatch, formatCelebrationsDates]
  );

  const renderCelebrationProfiles = useCallback(() => {
    callCelebrationsApi(key);
  }, [callCelebrationsApi, key]);

  const onPressCalendarHandler = useCallback(
    (date) => {
      logEvent(
        ANALYTICS_EVENT_TYPES.CELEBRATIONS_SHOW_CALENDER,
        '',
        ANALYTICS_ITEM_NAMES.CELEBRATIONS
      );
      calendarDateRef.current = { ...calendarDateRef.current, [key]: date };
      renderCelebrationProfiles();
    },
    [key, renderCelebrationProfiles]
  );

  const chatbotFilterHandler = useCallback(() => {
    appliedFiltersRef.current = location.state.chatbotParams.filters.filter_ids;
    isFilterAppliedRef.current = true;
    isFilterLoadedRef.current = {};
    calendarDateRangeRef.current[key] = {};
    calendarDateRef.current[key] = '';
    if (location.state.chatbotParams.timeType === 'interval') {
      calendarDateRangeRef.current = {
        ...calendarDateRangeRef.current,
        [key]: location.state.chatbotParams.dateRange,
      };
    } else {
      calendarDateRef.current = {
        ...calendarDateRef.current,
        [key]: new Date(location.state.chatbotParams.date),
      };
    }
    renderCelebrationProfiles();
  }, [key, location.state, renderCelebrationProfiles]);

  useEffect(() => {
    if (
      location.state &&
      location.state.chatbotParams &&
      location.state.chatbotParams.celebrationType
    ) {
      setIsChatbotActionable(true);
      setKey(location.state.chatbotParams.celebrationType);
    }
  }, [location.state]);

  useEffect(() => {
    if (
      location.state &&
      location.state.isChatbotActionable &&
      location.state.chatbotParams &&
      key === location.state.chatbotParams.celebrationType
    ) {
      chatbotFilterHandler();
    }
  }, [chatbotFilterHandler, key, location.state]);

  useEffect(() => {
    if (celebrationDates[key]) {
      setMarkedDates(celebrationDates[key]);
    }
    setSelectedDate(calendarDateRef.current[key]);
  }, [calendarDateRef, celebrationDates, key]);

  useEffect(() => {
    if (isFilterAppliedRef.current && !isFilterLoadedRef.current[key]) {
      callCelebrationsApi(key);
    }
  }, [callCelebrationsApi, key]);

  const renderScreen = useCallback(
    (celebrationType) => {
      callCelebrationsApi(celebrationType);
    },
    [callCelebrationsApi]
  );

  const filterData = (selectedFilters) => {
    let tempFilterObject = {};
    Object.keys(selectedFilters).map((filterKey) => {
      if (selectedFilters[filterKey].length) {
        tempFilterObject[filterKey] = selectedFilters[filterKey].join();
      }
    });
    appliedFiltersRef.current = tempFilterObject;
    isFilterAppliedRef.current = true;
    isFilterLoadedRef.current = {};
    if (isCelebrationByDateAppliedRef.current[key]) {
      renderCelebrationProfiles();
    } else {
      callCelebrationsApi(key);
    }
  };

  const resetStates = () => {
    history.replace();
    appliedFiltersRef.current = {};
    isFilterAppliedRef.current = false;
    isCelebrationByDateAppliedRef.current = {};
    isFilterLoadedRef.current = {};
    calendarDateRef.current = {};
    calendarDateRangeRef.current = {};
    setMarkedDates([]);
    setSelectedDate(todayDate);
  };

  const renderTab = (tab) => {
    return (
      <CelebrationTab
        data={celebrationsData[tab.key]}
        getTabData={renderScreen}
        isLoading={isLoading}
        tabKey={tab.key}
        tabTitle={tab.title}
      />
    );
  };

  return (
    <div className="container">
      {/* Remove chat boat as it has some backend generic token dependency */}
      {/* {showChatbot && <ChatbotWidget />} */}
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right">
        {!isChatbotActionable && (
          <div className="gcdo-icon-row mr-3">
            <CalendarDialog
              markedDates={markedDates}
              onDateSelected={onPressCalendarHandler}
              selectedDate={selectedDate}
              popperPosition="bottom-end"
            />
          </div>
        )}
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS} />
      <div className="pt-3">
        {showFilterButton && (
          <Filter
            topFilter={FILTER_IDENTIFIERS.GEOGRAPHIES}
            geographies={true}
            locations={true}
            levels={true}
            layers={true}
            serviceTenures={key !== CELEBRATION_IDENTIFIERS.NEW_JOINEE}
            onClick={filterData}
            isChatbotActionable={isChatbotActionable}
            setIsChatbotActionable={setIsChatbotActionable}
            resetStates={resetStates}
            isNewJoinees={key === CELEBRATION_IDENTIFIERS.NEW_JOINEE}
          />
        )}
        <NavTabs activeKey={key} onSelect={(k) => setKey(k)} unmountOnExit>
          {celebrationsTabData.map((tab, index) => {
            return (
              <Tab key={`${index}`} eventKey={tab.key} title={tab.title}>
                {renderTab(tab, index)}
              </Tab>
            );
          })}
        </NavTabs>
      </div>
    </div>
  );
};

export default CelebrationsScreen;
