import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  MICROGIVE_EVENT_TYPE,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../../common/constants/AppConstants';
import CustomButton from '../../../../common/ui/custom_button/CustomButton';
import HtmlView from '../../../../common/ui/html_view/HTMLView';
import { GET_INITIATIVE_DETAIL } from '../../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../../services/Service';
import { saveProgressLoadingState, saveSearchText } from '../../../../state/UIState';
import { getUrlWithSpecificRendition } from '../../../../utils/common';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import { selectAccessData } from '../../../../state/UserAccessData';

const VolunteerDetailTab = ({ initiativeId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [event, setEvent] = useState({});
  const accessData = useSelector(selectAccessData);
  const [showRegisterButton, setShowRegisterButton] = useState(true);
  const microgiveAccessData = accessData?.data?.find((item) => item.feature_key === '_microGive');

  useEffect(() => {
    const actions = microgiveAccessData?.actions;
    setShowRegisterButton(actions?.includes('_volunteer'));
  }, [microgiveAccessData.actions]);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.VOLUNTEER_DETAIL_TAB_VISIT,
      initiativeId,
      ANALYTICS_ITEM_NAMES.MICROGIVE
    );
  }, [initiativeId]);

  const callDonationDetailApi = useCallback(async () => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(
        GET_INITIATIVE_DETAIL + initiativeId,
        REQUEST_TYPE.GET,
        {}
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setEvent(apiResponse.response.data.event);
        }
      }
    } catch (err) {
      handleError(
        err,
        {},
        GET_INITIATIVE_DETAIL + initiativeId,
        NAVIGATION_ROUTES.VOLUNTEER_TABS_SCREEN
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch, initiativeId]);

  useEffect(() => {
    callDonationDetailApi();
  }, [callDonationDetailApi]);

  const onPressSelectDate = () => {
    history.push(NAVIGATION_ROUTES.SELECT_DATE_SCREEN, {
      initiativeId: initiativeId,
      eventDetail: event,
    });
  };

  const handleHtmlClicks = (e) => {
    const target = e.target.getAttribute('href');
    if (target) {
      if (target.startsWith('/') || target.startsWith('?')) {
        if (target.includes(NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL)) {
          logEvent(
            ANALYTICS_EVENT_TYPES.USER_MENTION_CLICK,
            e?.target?.innerText,
            ANALYTICS_ITEM_NAMES.USER_MENTION
          );
          e.stopPropagation();
          e.preventDefault();
          history.push(target);
        } else {
          logEvent(
            ANALYTICS_EVENT_TYPES.HASHTAG_CLICK,
            e?.target?.innerText,
            ANALYTICS_ITEM_NAMES.HASHTAG
          );
          dispatch(saveSearchText(''));
          e.preventDefault();
          history.push(`${NAVIGATION_ROUTES.DONATION_SEARCH_SCREEN}${target}`, {
            type: MICROGIVE_EVENT_TYPE.VOLUNTEER,
          });
        }
      } else {
        e.stopPropagation();
        e.preventDefault();
        window.open(target, '_blank');
      }
    }
  };

  return (
    <div className="main-content-container">
      {showRegisterButton && (
        <div className="d-flex flex-row-reverse mi-hr-activity-btn-container">
          <CustomButton buttonStyle="microgive-button-style" onClick={onPressSelectDate}>
            Register
          </CustomButton>
        </div>
      )}
      {!!Object.keys(event).length > 0 && (
        <div>
          <img
            src={
              event.promo_image &&
              getUrlWithSpecificRendition(
                event.promo_image.base_url,
                SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
                event.promo_image.image_path
              )
            }
            className="embed-responsive embed-responsive-16by9"
          />
          <div className="microgive-title">{event.title}</div>
          <HtmlView html={event.description} onClick={handleHtmlClicks} htmlStyle="mt-2 mb-2" />
        </div>
      )}
    </div>
  );
};

export default VolunteerDetailTab;
