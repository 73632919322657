import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ENCODING_TYPE,
  FEATURE_TYPE,
  LOADING_MESSAGES,
  MI_HR_CONTENT_IDENTIFIERS,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import {
  ARTICLES,
  COMMENT_BY_ID,
  GET_MI_HR_ACTIVITIES,
  GET_MI_HR_CONTESTS,
  INCREASE_VIEW_COUNT_EXTERNAL_LINK,
  POLLS_BY_ID,
  UPDATE_MI_HR_ACTIVITIES,
} from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { saveMiHRTabCategoryFilterId, selectMiHRTabFilterCategoryId } from '../../../state/TabData';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectShowEmptyContentView,
} from '../../../state/UIState';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import {
  formatDateDDMonthYYYY,
  formatSecondsIntoMinutes,
  formatSecondsIntoTimeForVideos,
  getUrlWithSpecificRendition,
  showToast,
} from '../../../utils/common';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import ContestsListItem from '../components/ContestListItem';
import MiHRActivityComponent from '../components/MiHRActivityComponent';
import MiHRContentFilterItem from '../components/MiHRContentFilterItem';
import PollsListItem from '../components/PollsListItem';
import SurveysListItem from '../components/SurveysListItem';
import PlayIconOverlay from '../../../common/ui/play_icon_overlay/PlayIconOverlay';

const MiHRTab = (props) => {
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const [miHrItemList, setMiHRItemList] = useState([]);
  const [miHrContestList, setMiHrContestList] = useState([]);
  const [miHrActivitiesList, setMiHrActivitiesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  const history = useHistory();
  const [dataLength, setDataLength] = useState(0);
  const loadMoreFlag = useRef(true);
  const pageId = useRef(1);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const contentFiltersList = props?.item?.filters ?? [];
  const miHRTabCategoryFilterId = useSelector(selectMiHRTabFilterCategoryId);
  const selectedDate = useRef('');

  useEffect(() => {
    if (pageId.current !== 1) {
      loadMoreFlag.current = false;
      setLoadMoreBtn(false);
    }
  }, [dataLength]);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.MI_HR_TAB_VISIT,
      props?.item?.category_id ?? '',
      ANALYTICS_ITEM_NAMES.MI_HR
    );
  }, [props.item.category_id]);

  const callMiHRApi = useCallback(async () => {
    setError(false);
    setLoadMoreBtn(true);
    setLoading(true);
    dispatch(saveShowEmptyContentView(true));
    const get_params = {
      page_id: pageId.current,
      year: selectedDate.current ? selectedDate.current : '',
      channel_id: props.item.channel_id,
      sub_channel_id: props.item.sub_channel_id,
      filter_id: miHRTabCategoryFilterId,
    };
    try {
      const apiResponse = await apiRequest(ARTICLES, REQUEST_TYPE.GET, get_params);
      if (pageId.current === 1) {
        setMiHRItemList([]);
      }
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.feeds) {
            const data = apiResponse.response.data.feeds;
            setDataLength(data.length);
            if (data.length > 0) {
              setMiHRItemList((oldArray) => [...oldArray, ...data]);
            } else {
              if (data.length === 0 && pageId.current === 1) {
                loadMoreFlag.current = false;
                setLoadMoreBtn(false);
              }
            }
          }
        }
      }

      setLoading(false);
    } catch (err) {
      handleError(err, get_params, ARTICLES, NAVIGATION_ROUTES.MI_HR_SUPPORT);
      setLoading(false);
      setError(true);
    }
  }, [dispatch, props.item.channel_id, props.item.sub_channel_id, miHRTabCategoryFilterId]);

  const renderScreen = useCallback(async () => {
    callMiHRApi();
  }, [callMiHRApi]);

  useEffect(() => {
    if (props?.item?.type === MI_HR_CONTENT_IDENTIFIERS.ARTICLE) {
      if (props?.selectedDate[props.item.category_id]) {
        selectedDate.current = props?.selectedDate[props.item.category_id];
      } else {
        selectedDate.current = '';
      }
      if (props.clearFilters[props.item.category_id]) {
        selectedDate.current = '';
      }
      pageId.current = 1;
      loadMoreFlag.current = true;
      const reciever = renderScreen();
      return () => reciever;
    }
  }, [
    props.clearFilters,
    props?.item.category_id,
    props?.item?.type,
    props?.selectedDate,
    renderScreen,
  ]);

  const callMiHRContestApi = useCallback(async () => {
    setError(false);
    setLoadMoreBtn(true);
    setLoading(true);
    dispatch(saveShowEmptyContentView(true));
    try {
      const apiResponse = await apiRequest(GET_MI_HR_CONTESTS, REQUEST_TYPE.GET, {});
      if (pageId.current === 1) {
        setMiHrContestList([]);
      }
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse?.response?.data) {
            const data = apiResponse?.response?.data?.contests ?? [];
            setMiHrContestList(data);
          }
        }
      }
      setLoading(false);
    } catch (err) {
      handleError(err, {}, GET_MI_HR_CONTESTS, NAVIGATION_ROUTES.MI_HR_SUPPORT);
      setLoading(false);
      setError(true);
      dispatch(saveShowEmptyContentView(false));
    }
  }, [dispatch]);

  const callMiHRActivityApi = useCallback(async () => {
    setError(false);
    setLoading(true);
    dispatch(saveShowEmptyContentView(true));
    try {
      const apiResponse = await apiRequest(GET_MI_HR_ACTIVITIES, REQUEST_TYPE.GET, {});
      if (pageId.current === 1) {
        setMiHrActivitiesList([]);
      }
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse?.response?.data) {
            const data = apiResponse?.response?.data?.weeks ?? [];
            setMiHrActivitiesList(data);
          }
        }
      }
      setLoading(false);
    } catch (err) {
      handleError(err, {}, GET_MI_HR_ACTIVITIES, NAVIGATION_ROUTES.MI_HR_SUPPORT);
      setLoading(false);
      setError(true);
      dispatch(saveShowEmptyContentView(false));
    }
  }, [dispatch]);

  // type = contest
  useEffect(() => {
    if (props?.item?.type === MI_HR_CONTENT_IDENTIFIERS.CONTEST) {
      callMiHRContestApi();
    }
  }, [props, callMiHRContestApi]);

  // type = activity
  useEffect(() => {
    if (props?.item?.type === MI_HR_CONTENT_IDENTIFIERS.ACTIVITY) {
      loadMoreFlag.current = false;
      callMiHRActivityApi();
    }
  }, [props, callMiHRActivityApi]);

  useEffect(() => {
    pageId.current = 1;
    loadMoreFlag.current = true;
  }, [props.clearFilters, props.item.category_id, props.selectedDate]);

  const loadMore = () => {
    if (loadMoreFlag.current) {
      pageId.current = pageId.current + 1;
      callMiHRApi();
    }
  };

  const emptyListView = () => {
    if (
      props?.item?.type === MI_HR_CONTENT_IDENTIFIERS.ACTIVITY &&
      miHrActivitiesList.length == 0
    ) {
      return (
        <DefaultContentView
          message={NO_DATA_TEXT.NO_DATA_FOUND}
          iconUri={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
            ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
          )}
        />
      );
    } else {
      return (
        <DefaultContentView
          message={NO_DATA_TEXT.NO_DATA_FOUND}
          iconUri={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
            ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
          )}
        />
      );
    }
  };

  const onPressItem = async (item) => {
    if (item.content_load_type === '1') {
      try {
        const es = await apiRequest(
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          REQUEST_TYPE.GET,
          {}
        );
      } catch (err) {
        handleError(
          err,
          {},
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          NAVIGATION_ROUTES.MI_HR_SUPPORT
        );
        //ignore if the view count request is not successful
        console.warn(err);
      }
      window.open(item.url);
    } else {
      history.push(`${NAVIGATION_ROUTES.MI_HR_SUPPORT}/${item.article_id}`);
    }
  };

  const newsArray = miHrItemList?.map((item, index) => {
    return (
      <div
        key={`${index}`}
        className="col-sm-4 mb-2 common-cursor-pointer"
        onClick={() => onPressItem(item)}>
        <div>
          {item?.content_load_type === '3' && <PlayIconOverlay />}
          <img
            src={getUrlWithSpecificRendition(
              item.image.base_url,
              SCREEN_DENSITY_QUALIFIERS.HDPI,
              item.image.image_path
            )}
            className="embed-responsive embed-responsive-16by9"
          />
          <div className="row ml-1 mt-1">
            {/* <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.NEWS_CALENDER,
                ACCESSIBILITY_IDENTIFIERS.NEWS
              )}
              className="broadcast-calender-style"
            /> */}
            <div className="d-flex flex-row justify-content-between w-100 pr-3">
              {!!item?.time_to_read && (
                <div className="broadcast-event-date-new ">
                  {item.time_to_read != 0 ? formatSecondsIntoMinutes(item.time_to_read) : null}
                </div>
              )}
              {!!item?.video_duration &&
                item?.content_load_type === '3' &&
                item?.video_duration > 0 && (
                  <div className="broadcast-event-date-new ">
                    {formatSecondsIntoTimeForVideos(item?.video_duration)}
                  </div>
                )}
              <div className="broadcast-event-date-new ">
                {formatDateDDMonthYYYY(item.pub_datetime)}
              </div>
              {!!item.page_views && (
                <div className="broadcast-event-date-new ">
                  {item.page_views > 1 ? `${item.page_views} views` : `${item.page_views} view`}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="broadcast-title">{item.title}</div>
      </div>
    );
  });

  const onPress = (item) => {
    setMiHRItemList([]);
    dispatch(saveMiHRTabCategoryFilterId(item?.filter_id));
    pageId.current = 1;
    loadMoreFlag.current = true;
  };

  const onPressViewResult = (item) => {
    history.push(NAVIGATION_ROUTES.POLLS_RESULTS, {
      pollId: item.poll_id,
    });
  };

  const submitVote = async (pollId, optionId) => {
    logEvent(ANALYTICS_EVENT_TYPES.POLLS_VOTE, pollId, ANALYTICS_ITEM_NAMES.POLLS);
    dispatch(saveProgressLoadingState({ isProgressLoading: true }));
    const formData = new URLSearchParams();
    formData.append('option_id', optionId);
    try {
      const apiResponse = await apiRequest(
        POLLS_BY_ID + pollId + '/vote',
        REQUEST_TYPE.POST,
        formData
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          showToast(apiResponse.response.message);
          callMiHRContestApi();
        }
      }
    } catch (err) {
      handleError(err, formData, POLLS_BY_ID + pollId + '/vote', NAVIGATION_ROUTES.MI_HR_SUPPORT);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressParticipate = (item) => {
    history.push(NAVIGATION_ROUTES.SURVEYS_PARTICIPATE, {
      surveyId: item.survey_id,
    });
  };
  const onPressContestItem = (item) => {
    history.push(`${NAVIGATION_ROUTES.CONTESTS}/${item.id}`);
  };

  const onSubmitActivityData = (item) => {
    logEvent(ANALYTICS_EVENT_TYPES.MI_HR_ACTIVITIES_UPDATE, '', ANALYTICS_ITEM_NAMES.MI_HR);
    callActivityUpdateApi(item);
  };

  const callActivityUpdateApi = useCallback(
    async (list) => {
      const formData = new FormData();
      list.forEach((level1) => {
        if (level1?.actvities?.length) {
          level1?.actvities.forEach((level2) => {
            if (level2?.checked === true) {
              formData.append('activities[]', level2?.activity_id);
            }
          });
        }
      });
      setError(false);
      setLoading(true);
      try {
        const apiResponse = await apiRequest(
          UPDATE_MI_HR_ACTIVITIES,
          REQUEST_TYPE.POST,
          formData,
          ENCODING_TYPE.FORM_DATA
        );

        setMiHrActivitiesList([]);

        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse?.response?.message) {
              showToast(apiResponse?.response?.message);
              dispatch(saveShowEmptyContentView(false));
              callMiHRActivityApi();
            }
          }
        }
        setLoading(false);
      } catch (err) {
        handleError(err, formData, UPDATE_MI_HR_ACTIVITIES, NAVIGATION_ROUTES.MI_HR_SUPPORT);
        setLoading(false);
        setError(true);
        dispatch(saveShowEmptyContentView(false));
      }
    },
    [dispatch, callMiHRActivityApi]
  );

  const callPollLikeApi = async (referId) => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
      })
    );
    const params = new URLSearchParams();
    params.append('type', FEATURE_TYPE.POLL);
    try {
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + referId + '/like',
        REQUEST_TYPE.POST,
        params
      );

      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );

      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            const message = apiResponse.response.message;

            if (message) {
              showToast(message);
              let updatedPolls = miHrItemList.map((pollItem) =>
                pollItem.poll_id === referId
                  ? {
                      ...pollItem,
                      liked: data.liked,
                      like_count: data.like_count,
                    }
                  : pollItem
              );

              setMiHRItemList(updatedPolls);
            }
          }
        }
      }
    } catch (err) {
      handleError(err, params, COMMENT_BY_ID + referId + '/like', NAVIGATION_ROUTES.MI_HR_SUPPORT);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      console.error(err);
    }
  };

  const callSurveyLikeApi = async (referId) => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
      })
    );
    const params = new URLSearchParams();
    params.append('type', FEATURE_TYPE.SURVEY);
    try {
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + referId + '/like',
        REQUEST_TYPE.POST,
        params
      );

      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );

      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            const message = apiResponse.response.message;

            if (message) {
              showToast(message);
              let updatedSurveys = miHrItemList.map((surveyItem) =>
                surveyItem.survey_id === referId
                  ? {
                      ...surveyItem,
                      liked: data.liked,
                      like_count: data.like_count,
                    }
                  : surveyItem
              );

              setMiHRItemList(updatedSurveys);
            }
          }
        }
      }
    } catch (err) {
      handleError(err, params, COMMENT_BY_ID + referId + '/like', NAVIGATION_ROUTES.MI_HR_SUPPORT);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      console.error(err);
    }
  };

  const onPressPollLikeButton = (item) => {
    logEvent(ANALYTICS_EVENT_TYPES.POLL_LIKE, item.poll_id.toString(), ANALYTICS_ITEM_NAMES.LIKE);
    callPollLikeApi(item.poll_id);
  };

  const onPressSurveyLikeButton = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.SURVEY_LIKE,
      item.survey_id.toString(),
      ANALYTICS_ITEM_NAMES.LIKE
    );
    callSurveyLikeApi(item.survey_id);
  };

  const onPressPollCommentButton = (item) => {
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: item.poll_id,
      likeType: FEATURE_TYPE.POLL,
      fromRoute: NAVIGATION_ROUTES.POLLS_AND_SURVEYS,
    });
  };

  const onPressSurveyCommentButton = (item) => {
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: item.survey_id,
      likeType: FEATURE_TYPE.SURVEY,
      fromRoute: NAVIGATION_ROUTES.POLLS_AND_SURVEYS,
    });
  };

  return (
    <div>
      <ActivityLoader visible={loading} />
      {contentFiltersList.length > 0 && (
        <div className="microgive-category-container">
          {contentFiltersList.map((item) => (
            <MiHRContentFilterItem
              key={item?.filter_id}
              item={item}
              filterId={miHRTabCategoryFilterId}
              onPress={onPress}
            />
          ))}
        </div>
      )}
      <div className="row">
        {miHrContestList.map((item, index) => {
          switch (item?.type) {
            case MI_HR_CONTENT_IDENTIFIERS.POLL:
              return (
                <PollsListItem
                  item={item}
                  key={`${index}`}
                  onPressViewResult={onPressViewResult}
                  submitVote={submitVote}
                />
              );

            case MI_HR_CONTENT_IDENTIFIERS.SURVEY:
              return (
                <SurveysListItem
                  item={item}
                  key={`${index}`}
                  onPressParticipate={onPressParticipate}
                />
              );

            case MI_HR_CONTENT_IDENTIFIERS.CONTEST:
              return (
                <>
                  <ContestsListItem
                    index={index}
                    key={`${index}`}
                    item={item}
                    onPress={onPressContestItem}
                  />
                </>
              );
            default:
              break;
          }
        })}
      </div>

      {props?.item?.type === MI_HR_CONTENT_IDENTIFIERS.ACTIVITY && (
        <MiHRActivityComponent
          activityList={miHrActivitiesList}
          onSubmitActivityData={onSubmitActivityData}
        />
      )}
      {showEmptyContentView &&
      !newsArray.length &&
      !loading &&
      props?.item?.type !== MI_HR_CONTENT_IDENTIFIERS.ACTIVITY ? (
        <>{emptyListView()}</>
      ) : showEmptyContentView &&
        !miHrActivitiesList.length &&
        !loading &&
        props?.item?.type === MI_HR_CONTENT_IDENTIFIERS.ACTIVITY ? (
        <>{emptyListView()}</>
      ) : (
        <div>
          <div className="row">{newsArray}</div>
          {loadMoreBtn && (
            <div className="mb-4 mt-4">
              <LoadMoreBtn onClick={loadMore} isLoading={loading} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(MiHRTab);
