import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  FEATURE_TYPE,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { formatDateDDMonthYYYY, getAwardIcon, getDevicePixelRatio } from '../../../utils/common';
import { selectEmployeeData } from '../../../state/EmployeeData';
import Divider from '../../../common/ui/divider/Divider';
import { useHistory } from 'react-router';
import html2canvas from 'html2canvas';
import { apiRequest, handleError } from '../../../services/Service';
import { UPLOAD_BUSINESS_CARD } from '../../../services/ApiUrls';
import { saveProgressLoadingState } from '../../../state/UIState';
import { saveAs } from 'file-saver';
import { logEvent2 } from '../../../utils/FirebaseAnalyticsUtils';
import HtmlView from '../../../common/ui/html_view/HTMLView';

const ReceivedListItem = ({
  item,
  onPressCommentButton,
  onPressLikeButton,
  onPressOthers,
  index,
  showLikeButton,
  showCommentButton,
}) => {
  const history = useHistory();
  const [showHideDetails, setShowHideDetails] = useState('Show Details');
  const [iconName] = useState(getAwardIcon(item.award_details.icon_name));

  const accessibilityData = useSelector(selectAccessibilityData);
  const employeeData = useSelector(selectEmployeeData);
  const dispatch = useDispatch();
  const pixelRatio = getDevicePixelRatio();
  const longerEmpNameStyle = {
    position: 'absolute',
    top: `${450 / pixelRatio}px`,
    left: `${175 / pixelRatio}px`,
    color: '#ed1d23',
    fontSize: `${40 / pixelRatio}px`,
    fontFamily: 'Lato-Black',
    whiteSpace: 'nowrap',
    overFlow: 'hidden',
    width: `${980 / pixelRatio}px`,
    textOverflow: 'ellipsis',
  };

  const empNameStyle = {
    position: 'absolute',
    top: `${450 / pixelRatio}px`,
    left: `${175 / pixelRatio}px`,
    color: '#ed1d23',
    fontSize: `${57 / pixelRatio}px`,
    fontFamily: 'Lato-Black',
  };

  const awardMonthStyle = {
    position: 'absolute',
    top: `${307 / pixelRatio}px`,
    left: `${457 / pixelRatio}px`,
    color: '#3B3B3B',
    fontSize: `${28 / pixelRatio}px`,
    fontFamily: 'ProximaNova-Regular',
    lineHeight: `${18 / pixelRatio}px`,
    fontWeight: '600',
  };

  const cheersCertificateDate = {
    position: 'absolute',
    bottom: `${33 / pixelRatio}px`,
    left: `${500 / pixelRatio}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#000',
    fontSize: `${22 / pixelRatio}px`,
    fontFamily: 'Lato-Regular',
  };

  const certificateStyle = {
    position: 'absolute',
    height: `${800 / pixelRatio}px`,
    width: `${1200 / pixelRatio}px`,
  };

  const onPressShowDetails = (btnType) => {
    if (btnType === 'Show Details') {
      setShowHideDetails('Hide Details');
    } else if (btnType === 'Hide Details') {
      setShowHideDetails('Show Details');
    }
  };

  const downloadCertificate = async (e) => {
    logEvent2({
      eventType: ANALYTICS_EVENT_TYPES.CHEERS_CERTIFICATE_DOWNLOAD,
      itemId: item?.award_details?.award_id ?? '',
      itemName: item?.award_details?.title ?? '',
      categoryId: ANALYTICS_ITEM_NAMES.CHEERS,
      categoryName: ANALYTICS_ITEM_NAMES.CHEERS,
    });
    e.preventDefault();
    document
      .getElementById(`download-cheers-certificate-received-${index}`)
      .classList.add('d-block');
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.GENERATING_BUSINESS_CARD,
      })
    );
    setTimeout(() => {
      html2canvas(document.getElementById(`download-cheers-certificate-received-${index}`), {
        height: 800 / pixelRatio,
        width: 1200 / pixelRatio,
        useCORS: true,
        onclone: function (clonedDoc) {
          clonedDoc
            .getElementById(`download-cheers-certificate-received-${index}`)
            .classList.add('d-block');
        },
      })
        .then((canvas) => {
          document
            .getElementById(`download-cheers-certificate-received-${index}`)
            .classList.remove('d-block');

          let xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = function () {
            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(xhr.response);
            a.download = 'certificate.jpeg';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            a.remove();
          };
          xhr.open('GET', canvas.toDataURL('image/jpeg')); // This is to download the canvas Image
          xhr.send();
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          // commenting out below code as it is not required
          // uploadBusinessCard(canvas.toDataURL(), e);
        })
        .catch(() => {
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        });
    }, 1000);
  };

  const uploadBusinessCard = async (imageUrl, e) => {
    const params = new URLSearchParams();
    params.append('file', imageUrl);
    params.append('type', '_CHEERS_IMAGE');
    try {
      const apiResponse = await apiRequest(UPLOAD_BUSINESS_CARD, REQUEST_TYPE.POST, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status === true) {
          const message = apiResponse.response.message;
          if (message) {
            e.stopPropagation();
            saveAs(
              apiResponse.response.data.business_card.base_url +
                apiResponse.response.data.business_card.image_path,
              'certificate.jpeg'
            );
          }
        }
      }
    } catch (err) {
      handleError(err, params, UPLOAD_BUSINESS_CARD, NAVIGATION_ROUTES.CHEERS_SCREEN);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  return (
    <div className="material-card p-3 mb-3">
      <div className="d-block">
        <div className="cheers-outer-view">
          <div className="cheers-icon-text-view cheers-outer-view">
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                iconName,
                ACCESSIBILITY_IDENTIFIERS.CHEERS
              )}
              className="cheers-normal-icon"
            />
            <div className="ml-2 cheers-award-title-text">{item.award_details.title}</div>
          </div>
          {item.award_details.award_type !== 'Non-Monetary' ? (
            <div className="ml-3 cheers-reward-points-second-text cheers-date-view-style cheers-center-view">{`(${item.points} Points)`}</div>
          ) : (
            <div className="ml-3 cheers-center-view" />
          )}

          <div className="cheers-date-view-style">
            {showLikeButton && (
              <>
                {item?.likes?.is_liked ? (
                  <div onClick={() => onPressLikeButton(item)}>
                    <img
                      src={getHeaderComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_ACTIVE_LIKE,
                        ACCESSIBILITY_IDENTIFIERS.CHEERS
                      )}
                      className="cheers-normal-icon ml-3 common-cursor-pointer"
                    />
                  </div>
                ) : (
                  <div onClick={() => onPressLikeButton(item)}>
                    <img
                      src={getHeaderComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_INACTIVE_LIKE,
                        ACCESSIBILITY_IDENTIFIERS.CHEERS
                      )}
                      className="cheers-normal-icon ml-3 common-cursor-pointer"
                    />
                  </div>
                )}
                <div
                  className="ml-3 likesCountText common-cursor-pointer"
                  onClick={() => {
                    item?.likes?.count > 0
                      ? history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
                          referId: item.cheer_employee_award_id,
                          parentType: FEATURE_TYPE.CHEERS,
                        })
                      : () => {};
                  }}>
                  {item?.likes?.count}
                </div>
              </>
            )}
            {showCommentButton && (
              <>
                <div onClick={() => onPressCommentButton(item)}>
                  <img
                    src={getHeaderComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_POST_COMMENT,
                      ACCESSIBILITY_IDENTIFIERS.CHEERS
                    )}
                    className="cheers-normal-icon ml-3 common-cursor-pointer"
                  />
                </div>
                <div className="ml-3 commentCountText">{item?.comment_count}</div>
              </>
            )}
          </div>
        </div>
        {item?.show_certificate && (
          <div
            className="cheers-download-certificate-view float-right"
            onClick={downloadCertificate}>
            <div className="cheers-download-certificate">Download Certificate</div>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_DOWNLOAD_CERTIFICATE,
                ACCESSIBILITY_IDENTIFIERS.CHEERS
              )}
              className="cheers-download-icon ml-2"
            />
          </div>
        )}
      </div>
      <div className="cheers-date-text mt-2">{formatDateDDMonthYYYY(item.created_on)}</div>
      <div className="cheers-date-view-style">
        <div className="cheers-award-type mt-2">{`${item.award_details.award_type}`}</div>
      </div>
      <Divider style="mt-2 mb-2" />
      {showHideDetails === 'Hide Details' && (
        <div className="cheers-desc-text-style">{item.award_details.description}</div>
      )}
      {showHideDetails === 'Hide Details' && <Divider style="mt-2 mb-2" />}
      {showHideDetails === 'Hide Details' && (
        <div>
          <div className="cheers-date-view-style">
            <div className="cheers-given-nominate-text">Nominated by:</div>
            {item.nominations.length > 0 && (
              <div
                onClick={() => {
                  onPressOthers(item.nominations);
                }}
                className="cheers-given-nominate-text ml-1 common-cursor-pointer">
                {item.nominations[0].nominated_by.employee_code === employeeData.employee_code
                  ? ' Self'
                  : ` ${item.nominations[0].nominated_by.first_name} ${item.nominations[0].nominated_by.last_name}`}
              </div>
            )}
            {showHideDetails === 'Hide Details' && item.nominations.length > 1 && (
              <div
                onClick={() => {
                  onPressOthers(item.nominations);
                }}
                className="cheers-given-nominate-text ml-1 common-cursor-pointer">
                {item.nominations.length === 2
                  ? ` + (${item.nominations.length - 1}) Other`
                  : ` + (${item.nominations.length - 1}) Others`}
              </div>
            )}
            {showHideDetails === 'Hide Details' && item.nominations.length === 0 && (
              <div className="cheers-given-nominate-text ml-1">{' Admin'}</div>
            )}
          </div>
          <div className="cheers-date-view-style">
            <div className="cheers-given-nominate-text">{'Awarded for: '}</div>
            <div className="cheers-given-nominate-text ml-1">
              <HtmlView html={item.citation} />
            </div>
          </div>
          {item.citation_text && <div className="mt-2 cheers-select-option-text">Citation</div>}
          {item.citation_text && (
            <div className="mb-2 cheers-citation-text">
              <HtmlView html={item.citation_text} />
            </div>
          )}
        </div>
      )}
      <div className="cheers-date-view-style">
        {showHideDetails === 'Show Details' && (
          <div className="cheers-given-nominate-text">Nominated by:</div>
        )}
        {showHideDetails === 'Show Details' && item.nominations.length > 0 && (
          <div
            onClick={() => {
              onPressOthers(item.nominations);
            }}
            className="cheers-given-nominate-text ml-1 common-cursor-pointer">
            {item.nominations[0].nominated_by.employee_code === employeeData.employee_code
              ? ' Self'
              : ` ${item.nominations[0].nominated_by.first_name} ${item.nominations[0].nominated_by.last_name}`}
          </div>
        )}
        {showHideDetails === 'Show Details' && item.nominations.length > 1 && (
          <div
            onClick={() => {
              onPressOthers(item.nominations);
            }}
            className="cheers-given-nominate-text ml-1 common-cursor-pointer">
            {item.nominations.length === 2
              ? ` + (${item.nominations.length - 1}) Other`
              : ` + (${item.nominations.length - 1}) Others`}
          </div>
        )}
        {showHideDetails === 'Show Details' && item.nominations.length === 0 && (
          <div className="cheers-given-nominate-text ml-1">{' Admin'}</div>
        )}
        <div className="cheers-show-hide-view">
          <div
            className="cheers-date-view-style common-cursor-pointer"
            onClick={() => onPressShowDetails(showHideDetails)}>
            <div className="cheers-show-hide-detail">{showHideDetails}</div>
            {showHideDetails === 'Show Details' ? (
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_ARROW_DOWN,
                  ACCESSIBILITY_IDENTIFIERS.CHEERS
                )}
                className="cheers-normal-icon ml-2"
              />
            ) : (
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_ARROW_UP,
                  ACCESSIBILITY_IDENTIFIERS.CHEERS
                )}
                className="cheers-normal-icon ml-2"
              />
            )}
          </div>
        </div>
      </div>
      <div className="hide-certificate">
        {item?.show_certificate && (
          <div id={`download-cheers-certificate-received-${index}`} style={{ ...certificateStyle }}>
            <img
              src={
                item.award_details?.certificate?.base_url +
                item.award_details?.certificate?.image_path
              }
              width="100%"
              height="100%"
            />
            <div
              style={
                item.employee?.employee_name.length > 25
                  ? { ...longerEmpNameStyle }
                  : { ...empNameStyle }
              }>
              {item.employee?.employee_name}
            </div>
            {!!item?.award_for_month && (
              <div style={{ ...awardMonthStyle }}>{item?.award_for_month}</div>
            )}
            <div style={{ ...cheersCertificateDate }}>
              {formatDateDDMonthYYYY(item.created_on).replace(/ /g, '-')}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReceivedListItem;
