import React from 'react';
import {
  ML_DAY_IDENTIFIERS,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import {
  formatDateDDMonthYYYY,
  formatSecondsIntoMinutes,
  formatSecondsIntoTimeForVideos,
  getUrlWithSpecificRendition,
} from '../../../utils/common';
import PlayIconOverlay from '../../../common/ui/play_icon_overlay/PlayIconOverlay';

const ArticlesListItem = ({ item, onPress, identifier }) => {
  return (
    <div className="col-sm-4 common-cursor-pointer" onClick={() => onPress(item)}>
      <div className="anniversary-view-style">
        {item?.content_load_type === '3' && <PlayIconOverlay />}
        <img
          src={
            identifier === ML_DAY_IDENTIFIERS.ML_34_IDENTIFIER
              ? item?.image?.base_url + item?.image?.image_path
              : getUrlWithSpecificRendition(
                  item?.image?.base_url,
                  SCREEN_DENSITY_QUALIFIERS.HDPI,
                  item?.image?.image_path
                )
          }
          className="embed-responsive embed-responsive-16by9"
        />
        <div className="anniversary-title">{item?.title}</div>
        <div className="row ml-1 mt-1">
          <div className="d-flex flex-row justify-content-between w-100 pr-3">
            {!!item?.time_to_read && (
              <div className="broadcast-event-date-new ">
                {item.time_to_read != 0 ? formatSecondsIntoMinutes(item.time_to_read) : null}
              </div>
            )}
            {!!item?.video_duration &&
              item?.content_load_type === '3' &&
              item?.video_duration > 0 && (
                <div className="broadcast-event-date-new ">
                  {formatSecondsIntoTimeForVideos(item?.video_duration)}
                </div>
              )}
            <div className="broadcast-event-date-new ">
              {formatDateDDMonthYYYY(item.pub_datetime)}
            </div>
            {!!item.page_views && (
              <div className="broadcast-event-date-new ">
                {item.page_views > 1 ? `${item.page_views} views` : `${item.page_views} view`}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticlesListItem;
