import React, { useEffect, useState, useCallback } from 'react';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  FILTER_IDENTIFIERS,
  NAVIGATION_ROUTES,
  OUR_CUSTOMERS_API_TYPES,
  OUR_CUSTOMERS_DATA_TYPES,
  OUR_CUSTOMERS_LIKE_TYPES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../../services/Service';
import { CUSTOMERS_BY_ID } from '../../../services/ApiUrls';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import OurCustomerListItem from '../components/OurCustomerListItem';
import YearFilterItem from '../components/YearFilterItem';
import { useHistory } from 'react-router';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import Filter from '../../../common/ui/filter/Filter';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { useSelector } from 'react-redux';
import { selectAccessData } from '../../../state/UserAccessData';

const VisitsTab = ({ type, title }) => {
  const history = useHistory();
  const [yearCustomers, setYearCustomers] = useState({});
  const [years, setYears] = useState([]);
  const [yearActive, setYearsActive] = useState({});
  const [yearPageId, setYearPageId] = useState({ LATEST: 1 });
  const [yearLoadMore, setYearLoadMore] = useState({ LATEST: true });
  const [curYear, setCurYear] = useState('LATEST');
  const [loading, setLoading] = useState(true);
  const [geographyId, setGeographyId] = useState('');
  const [locationId, setLocationId] = useState('');
  const [regionId, setRegionId] = useState('');
  const [dealYear, setDealYear] = useState('');
  const [facilityId, setFacilityId] = useState();
  const [dealYearList, setDealYearList] = useState([]);
  const [feeds, setfeeds] = useState([]);
  const accessData = useSelector(selectAccessData);
  const [showFilter, setShowFilter] = useState(true);
  const customerAccessData = accessData?.data?.find((item) => item.feature_key === '_ourCustomers');

  useEffect(() => {
    const actions = customerAccessData?.actions;
    setShowFilter(actions?.includes('_filter'));
  }, [customerAccessData?.actions]);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.OUR_CUSTOMERS_TAB_VISIT,
      type,
      ANALYTICS_ITEM_NAMES.OUR_CUSTOMER
    );
  }, [type]);

  const setYearData = (data) => {
    if (years.length === 0) {
      let arr = data.years;
      arr.unshift('LATEST');
      setYears(arr);
      let yearsStatus = {};
      let yearPageId = {};
      let yearCustomers = {};
      let yearLoadMore = {};
      arr.forEach((element, index) => {
        if (index === 0) {
          yearsStatus[element] = true;
          yearPageId[element] = 1;
          yearCustomers[element] = [];
          yearLoadMore[element] = true;
        } else {
          yearsStatus[element] = false;
          yearPageId[element] = 1;
          yearCustomers[element] = [];
          yearLoadMore[element] = true;
        }
      });
      setYearsActive(yearsStatus);
      setYearCustomers(yearCustomers);
      setYearPageId(yearPageId);
      setYearLoadMore(yearLoadMore);
    }
  };

  const callOurCustomerApi = useCallback(async () => {
    const get_params = {
      page_id: yearPageId[curYear] ?? 1,
      year: curYear,
      geography_id: geographyId,
      location_id: locationId,
      deal_year: dealYear,
      facility_id: facilityId,
      region_id: regionId,
    };
    try {
      setLoading(true);
      const apiResponse = await apiRequest(CUSTOMERS_BY_ID + type, REQUEST_TYPE.GET, get_params);
      setLoading(false);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            setfeeds(data.feeds);
            if (data?.deal_years?.length > 0) {
              setDealYearList(data?.deal_years);
            }
            if (data.years && data.years.length > 0) {
              setYearData(data);
            }
            if (data.feeds && data.feeds.length > 0) {
              let tempCustomers = [];
              if (yearCustomers[curYear]) {
                const existingCustomers = yearCustomers[curYear];
                tempCustomers = data.feeds.filter(
                  (feed) => !existingCustomers.some((customer) => customer.id === feed.id)
                );
                tempCustomers = [...existingCustomers, ...tempCustomers];
              } else {
                tempCustomers = data.feeds;
              }
              setYearCustomers((prev) => ({
                ...prev,
                [curYear]: tempCustomers,
              }));
            } else {
              setYearLoadMore((prev) => ({ ...prev, [curYear]: false }));
            }
          }
        }
      }
    } catch (err) {
      handleError(err, get_params, CUSTOMERS_BY_ID + type, NAVIGATION_ROUTES.OUR_CUSTOMER);
      setLoading(false);
    }
  }, [curYear, yearPageId, type, geographyId, locationId, dealYear, facilityId, regionId]);

  useEffect(() => {
    callOurCustomerApi();
  }, [callOurCustomerApi]);

  const makeActiveClass = async (item) => {
    var tempYearActive = {};
    for (let key in yearActive) {
      if (key === item) {
        tempYearActive[key] = true;
      } else {
        tempYearActive[key] = false;
      }
    }
    setYearsActive({ ...tempYearActive });
    setCurYear(item);
  };

  const onPressLoadMore = () => {
    const tempPageId =
      yearPageId[curYear] !== undefined ? yearPageId[curYear] + 1 : (yearPageId[curYear] = 2);
    setYearPageId((prev) => ({ ...prev, [curYear]: tempPageId }));
  };

  const onPressMore = () => {};

  const onPress = (item) => {
    history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT, {
      customerId: item.id,
      dataType: OUR_CUSTOMERS_DATA_TYPES[type],
      parentName: title,
      apiType: OUR_CUSTOMERS_API_TYPES[type],
      type: type,
      likeType: OUR_CUSTOMERS_LIKE_TYPES[type],
    });
  };

  const renderItem = (item, index) => {
    return (
      <OurCustomerListItem
        item={item}
        key={`${index}`}
        onPressMore={onPressMore}
        onPress={onPress}
      />
    );
  };

  const filterData = (selectedFilters) => {
    setYearPageId({ LATEST: 1 });
    const hasSelectedFilters = Object.keys(selectedFilters).length > 0;
    if (hasSelectedFilters) {
      if (selectedFilters.geography_id?.toString() !== geographyId) {
        setGeographyId(selectedFilters.geography_id?.toString() ?? '');
      }
      if (selectedFilters.location_id?.toString() !== locationId) {
        setLocationId(selectedFilters.location_id?.toString() ?? '');
      }
      if (selectedFilters.region_id?.toString() !== regionId) {
        setRegionId(selectedFilters.region_id?.toString() ?? '');
      }
      if (selectedFilters.years?.toString() !== dealYear) {
        setDealYear(selectedFilters.years?.toString() ?? '');
      }
      if (selectedFilters.facility_id !== facilityId) {
        setFacilityId(selectedFilters.facility_id ?? '');
      }
      setYearCustomers({});
    } else if (regionId !== '' || facilityId !== '') {
      setGeographyId('');
      setDealYear('');
      setFacilityId('');
      setLocationId('');
      setRegionId('');
      setYearPageId({ LATEST: 1 });
      setYearLoadMore({ LATEST: true });
      setYearCustomers({});
    }
  };

  return (
    <div>
      {showFilter && (
        <div>
          <Filter
            topFilter={FILTER_IDENTIFIERS.GEOGRAPHIES}
            facilities={true}
            regions={true}
            onClick={filterData}
          />
        </div>
      )}
      {years.length > 0 && (
        <div className="ourcustomers-category-container">
          {years.map((item, index) => {
            return (
              <YearFilterItem
                item={item}
                onPress={makeActiveClass}
                key={`${index}`}
                isSelected={yearActive[item] ? true : false}
              />
            );
          })}
        </div>
      )}
      <div className="row">
        {(years?.length > 0 &&
          yearCustomers &&
          Object?.keys(yearCustomers)?.length > 0 &&
          (yearPageId[curYear] === 1 ? feeds.length > 0 : true) &&
          yearCustomers[curYear]?.map((item, index) => renderItem(item, index))) ||
          (loading === false && (
            <div className="w-100">
              <DefaultContentView message={ERROR_MESSAGES.NO_DATA_FOUND} />
            </div>
          ))}
      </div>
      <ActivityLoader visible={loading} />
      {yearLoadMore[curYear] === true && (
        <LoadMoreBtn onClick={onPressLoadMore} isLoading={loading} />
      )}
    </div>
  );
};

export default React.memo(VisitsTab);
