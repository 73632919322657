import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab } from 'react-bootstrap';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { selectVariablePayTabs } from '../../state/MasterData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { saveVariablePayTabData, selectVariablePayTabData } from '../../state/TabData';
import { apiRequest, handleError } from '../../services/Service';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import NavTabs from '../../common/ui/tabs/NavTabs';
import { GET_VARIABLE_PAY } from '../../services/ApiUrls';
import VariablePayTab from './tabs/VariablePayTab';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import NewsLetterTab from './tabs/NewsLetterTab';

const VariablePayScreen = () => {
  const dispatch = useDispatch();
  const routes = useSelector(selectVariablePayTabs).filter((tab) => tab.active);
  const [tabs, setTabs] = useState([]);
  const [variablePayData, setVariablePayData] = useState({});
  const variablePayTabData = useSelector(selectVariablePayTabData);
  const isLoading = useSelector(selectProgressLoadingState);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.VARIABLE_PAY, NAVIGATION_ROUTES.VARIABLE_PAY);
  }, []);

  useEffect(() => {
    if (Object.keys(variablePayData).length > 0 && routes.length > 0) {
      const tempRoutes = [];
      routes.map((route) => {
        if (
          (route.key === 'plan' && variablePayData.plan.visibility) ||
          (route.key === 'actuals' && variablePayData.actual.visibility) ||
          (route.key === 'payout' && variablePayData.payout.visibility)
        ) {
          tempRoutes.push(route);
        }
      });
      if (variablePayData?.plan?.target_letter?.filepath) {
        tempRoutes.push({ key: 'newsletter', title: 'MY TARGET LETTER' });
      }
      if (variablePayData?.actual?.variable_pay_statement?.filepath) {
        tempRoutes.push({ key: 'statement', title: 'VARIABLE PAY STATEMENT' });
      }
      if (tabs.length === 0) {
        setTabs(tempRoutes);
      }
      if (variablePayTabData === '' && tempRoutes.length > 0) {
        dispatch(saveVariablePayTabData(tempRoutes[0].key));
      }
    }
  }, [dispatch, routes, tabs.length, variablePayData, variablePayTabData]);

  const callVariablePay = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequest(GET_VARIABLE_PAY, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          const data = apiResponse.response.data.variables;
          setVariablePayData(data);
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(err, {}, GET_VARIABLE_PAY, NAVIGATION_ROUTES.VARIABLE_PAY);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    callVariablePay();
  }, [callVariablePay]);

  useEffect(() => {
    switch (variablePayTabData) {
      case 'plan':
        logEvent(
          ANALYTICS_EVENT_TYPES.VARIABLE_PAY_PLAN_TAB_VISIT,
          '',
          ANALYTICS_ITEM_NAMES.VARIABLE_PAY
        );
        return;
      case 'actuals':
        logEvent(
          ANALYTICS_EVENT_TYPES.VARIABLE_PAY_ACTUALS_TAB_VISIT,
          '',
          ANALYTICS_ITEM_NAMES.VARIABLE_PAY
        );
        return;
      case 'payout':
        logEvent(
          ANALYTICS_EVENT_TYPES.VARIABLE_PAY_PAYOUT_TAB_VISIT,
          '',
          ANALYTICS_ITEM_NAMES.VARIABLE_PAY
        );
        return;
      case 'newsletter':
        logEvent(
          ANALYTICS_EVENT_TYPES.VARIABLE_PAY_TARGET_LETTER_TAB_VISIT,
          '',
          ANALYTICS_ITEM_NAMES.VARIABLE_PAY
        );
        return;
      case 'statement':
        logEvent(
          ANALYTICS_EVENT_TYPES.VARIABLE_PAY_STATEMENT_TAB_VISIT,
          '',
          ANALYTICS_ITEM_NAMES.VARIABLE_PAY
        );
        return;
    }
  }, [variablePayTabData]);

  const renderScene = (route) => {
    switch (route.key) {
      case 'plan':
        return (
          <VariablePayTab
            data={variablePayData.plan}
            tabKey={route.key}
            template={variablePayData.template}
          />
        );
      case 'actuals':
        return (
          <VariablePayTab
            data={variablePayData.actual}
            tabKey={route.key}
            template={variablePayData.template}
          />
        );
      case 'payout':
        return (
          <VariablePayTab
            data={variablePayData.payout}
            tabKey={route.key}
            template={variablePayData.template}
          />
        );
      case 'newsletter':
        return (
          <NewsLetterTab
            filepath={variablePayData?.plan?.target_letter?.filepath}
            tabKey={route.key}
          />
        );
      case 'statement':
        return (
          <NewsLetterTab
            filepath={variablePayData?.actual?.variable_pay_statement?.filepath}
            tabKey={route.key}
          />
        );
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.VARIABLE_PAY} />
      <NavTabs
        activeKey={variablePayTabData}
        onSelect={(k) => dispatch(saveVariablePayTabData(k))}
        unmountOnExit>
        {tabs &&
          tabs.map((tab, index) => {
            return (
              <Tab key={`${index}`} eventKey={tab.key} title={tab.title.toUpperCase()}>
                {renderScene(tab, index)}
              </Tab>
            );
          })}
      </NavTabs>
      {tabs.length === 0 && !isLoading?.isProgressLoading && (
        <DefaultContentView message={ERROR_MESSAGES.NO_DATA_FOUND} />
      )}
    </div>
  );
};

export default VariablePayScreen;
