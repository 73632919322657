import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  NAVIGATION_ROUTES,
  OUR_CUSTOMERS_API_TYPES,
  OUR_CUSTOMERS_DATA_TYPES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import Divider from '../../../common/ui/divider/Divider';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { formatDateDDMonthYYYY, getUrlWithSpecificRendition } from '../../../utils/common';

const OurCustomersItem = (props) => {
  const history = useHistory();
  const accessibilityData = useSelector(selectAccessibilityData);

  const onPress = (item) => {
    history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT, {
      customerId: item.accolade_id,
      dataType: OUR_CUSTOMERS_DATA_TYPES['accolades'],
      parentName: 'ACCOLADES',
      apiType: OUR_CUSTOMERS_API_TYPES['accolades'],
      type: 'accolades',
      likeType: OUR_CUSTOMERS_API_TYPES['accolades'],
    });
  };

  const ourCustomersArray =
    props.feed &&
    props.feed.map((item, index) => {
      return (
        <div className="common-cursor-pointer" onClick={() => onPress(item)} key={`${index}`}>
          <div className="ourcustomers-cross-axis-view">
            <img
              src={getUrlWithSpecificRendition(
                item.base_url,
                SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
                item.logo_path
              )}
              className="common-user-profile-photo-lg"
            />
            <div className="ml-3 ourcustomers-cross-axis-view">
              <div>
                <div className="ourcustomers-flex mt-1">
                  <img
                    src={getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_CALENDER,
                      ACCESSIBILITY_IDENTIFIERS.MICROGIVE
                    )}
                    className="ourcustomers-calender-style"
                  />
                  <div className="ourcustomers-event-date">{formatDateDDMonthYYYY(item.date)}</div>
                </div>
                <div className="mt-1 ourcustomers-author">{item.author}</div>
                <div className="mt-1 ourcustomers-accolades-designation">{item.designation}</div>
                <div className="mt-1 ourcustomers-accolades-title">{item.title}</div>
              </div>
            </div>
          </div>
          <Divider style="mt-2 mb-2" />
        </div>
      );
    });

  return <div>{ourCustomersArray}</div>;
};

export default OurCustomersItem;
