import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import useWindowDimensions from '../../../custom_hooks/useWindowDimensions';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { selectCompanyWebsiteLink, selectThirdPartyWebsiteLink } from '../../../state/MasterData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  NAVIGATION_ROUTES,
} from '../../constants/AppConstants';

import SocialMediaSection from './components/SocialMediaSection';

const Footer = () => {
  const { width } = useWindowDimensions();
  const accessibilityData = useSelector(selectAccessibilityData);
  const thirdPartyWebsiteLink = useSelector(selectThirdPartyWebsiteLink);
  const companyWebsiteLink = useSelector(selectCompanyWebsiteLink);

  const getCurrentYear = () => {
    return new Date().getFullYear().toString();
  };

  return (
    <footer className="footer-container p-3">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-4 d-flex justify-content-center mb-2">
            <a target="_blank" rel="noreferrer" href={companyWebsiteLink} className="mr-3">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_COMPANY_LOGO,
                  ACCESSIBILITY_IDENTIFIERS.COMMON
                )}
                className="footer-microland-link-icon"
              />
            </a>
            <a target="_blank" rel="noreferrer" href={thirdPartyWebsiteLink}>
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.THIRD_PARTY_WEBSITE,
                  ACCESSIBILITY_IDENTIFIERS.COMMON
                )}
                className="footer-microlander-link-icon"
              />
            </a>
          </div>
          <div className="col-12 col-md-4 d-flex justify-content-center">
            <SocialMediaSection />
          </div>
          <div
            className={`col-12 col-md-4 d-flex align-items-center ${
              width < 769 ? 'mt-2 justify-content-center' : 'justify-content-end'
            }`}>
            <Link
              to={NAVIGATION_ROUTES.TERMS_CONDITIONS_SCREEN}
              className="mr-3 footer-item-text footer-text-underline">
              Terms & conditions
            </Link>
            <div className="footer-item-text">{`© Microland Ltd. ${getCurrentYear()}`}</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
