import React from 'react';
import { useSelector } from 'react-redux';
import { formatDateDDMonthYYYY, getUrlWithSpecificRendition } from '../../../utils/common';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';

const OurCustomerListItem = ({ item, onPressMore, onPress }) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  return (
    <div className="col-sm-4 mb-3 common-cursor-pointer" onClick={() => onPress(item)}>
      <div className="ourcustomers-main">
        <img
          src={getUrlWithSpecificRendition(
            item.base_url,
            SCREEN_DENSITY_QUALIFIERS.HDPI,
            item.promo_image
          )}
          className="embed-responsive embed-responsive-16by9"
        />
        {item.gallary && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              onPressMore(item.gallary, item.base_url);
            }}
            className="ourcustomers-bg-view">
            <div className="ourcustomers-count-left-text">
              {item.gallary.pictures.length === 1
                ? `${item.gallary.pictures.length} photo`
                : `${item.gallary.pictures.length} photos`}
            </div>
          </div>
        )}
        <div className="ourcustomers-bg-view-bottom pt-1 pb-1 pl-3 pr-3">
          {item.title && (
            <div>
              <div className="ourcustomers-title-main">{item.title}</div>
              <div className="ourcustomers-flex mt-1">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_CALENDER,
                    ACCESSIBILITY_IDENTIFIERS.MICROGIVE
                  )}
                  className="ourcustomers-calender-style"
                />
                <div className="ourcustomers-event-date">{formatDateDDMonthYYYY(item.date)}</div>
              </div>
            </div>
          )}
          {item.name && (
            <div className="row mt-1 justify-content-between mr-1 ml-1">
              <div className="ourcustomers-title">{item.name}</div>
              <div className="ourcustomers-title">{item.region}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OurCustomerListItem;
