import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import {
  getFeatureComponentUrl,
  getFeatureComponentUrlLdpi,
} from '../../../../utils/accessibility';
import {
  formatStartAndEndDate,
  formatDateDDMonthYYYYTimeAMPM,
  getUrlWithSpecificRendition,
} from '../../../../utils/common';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../constants/AppConstants';
import CustomButton from '../../../ui/custom_button/CustomButton';
import DefaultContentView from '../../../ui/default_content_view/DefaultContentView';
import Divider from '../../../ui/divider/Divider';
import HtmlView from '../../../ui/html_view/HTMLView';
import { selectEmployeeData } from '../../../../state/EmployeeData';
import ActivityLoader from '../../../ui/activity_loader/ActivityLoader';
import useWindowDimensions from '../../../../custom_hooks/useWindowDimensions';

const QuestionTab = ({
  data,
  fromBreakoutsSessionDetailRoute,
  identifier,
  breakoutId,
  questionsFeed,
  noQuestionsFoundStatus,
  loading,
  sessionId,
}) => {
  const history = useHistory();
  const accessibilityData = useSelector(selectAccessibilityData);
  const employeeData = useSelector(selectEmployeeData);
  const { width } = useWindowDimensions();
  const isEmployeeSpeaker = data.speakers.find(
    (employee) => employee.employee_id === employeeData.employee_id
  );

  const handleEvent = (question) => {
    history.push(
      `${NAVIGATION_ROUTES.SESSION_DETAILS}/${sessionId}/${question.speaker_id}/${question.question_id}`
    );
  };

  return (
    <div className={'main-content-container'}>
      {!isEmployeeSpeaker && (
        <div className="d-flex flex-row-reverse mi-hr-activity-btn-container">
          <CustomButton
            buttonStyle="breakouts-button-style mr-0"
            onClick={() => {
              history.push(NAVIGATION_ROUTES.ASK_SESSION_QUESTION, {
                data: [data],
                fromBreakoutsSessionDetailRoute: fromBreakoutsSessionDetailRoute ? true : false,
                identifier: identifier,
              });
            }}>
            Ask Question
          </CustomButton>
        </div>
      )}
      <div>
        <div>
          <ActivityLoader visible={loading} />
          <div className="mb-3">
            <h6 className="new-joinees-higher-levels-card-main-title mb-1">{data.title}</h6>
            <p className="new-joinees-higher-levels-card-sub-title mb-2">
              {formatStartAndEndDate(data.start_datetime, data.end_datetime)}
            </p>
          </div>
          {questionsFeed &&
            questionsFeed.length > 0 &&
            questionsFeed.map((question, index) => {
              return (
                <div key={`${index}`} className="material-card mb-3">
                  <div className="question-bg-color pl-4 pr-2 pb-1 pt-1">
                    <div className="row justify-content-between pt-1 pr-3">
                      <div className="row mr-1 ml-1 questioner-answerer-block">
                        {question.anonymous ? (
                          <img
                            src={getFeatureComponentUrlLdpi(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
                              ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                            )}
                            className={'breakouts-profile-image-small'}
                          />
                        ) : (
                          <img
                            src={
                              question.employee.profile_picture.image_path !== ''
                                ? getUrlWithSpecificRendition(
                                    question.employee.profile_picture.base_url,
                                    SCREEN_DENSITY_QUALIFIERS.LDPI,
                                    question.employee.profile_picture.image_path
                                  )
                                : getFeatureComponentUrlLdpi(
                                    accessibilityData,
                                    ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
                                    ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                                  )
                            }
                            className="breakouts-profile-image-small"
                          />
                        )}
                        <div className="ml-3 outer-view mr-3">
                          <div>
                            <div className="breakouts-session-questioner-title">
                              {question.anonymous ? 'Anonymous' : question.employee.employee_name}
                            </div>
                            <div className="breakouts-session-questioner-subtitle">
                              {question.anonymous ? 'Hidden' : question.employee.position_title}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-1 breakouts-session-questioner-date-posted">
                        <div>{formatDateDDMonthYYYYTimeAMPM(question.asked_on)}</div>
                        <div className="breakouts-questioner-answerer-line" />
                      </div>
                      <div className="row mr-1 ml-1 questioner-answerer-block d-flex justify-content-end">
                        <div className="ml-3 outer-view mr-3">
                          <div className="breakouts-session-questioner-right-title">
                            {question.speaker.first_name +
                              ' ' +
                              question.speaker.middle_name +
                              ' ' +
                              question.speaker.last_name}
                          </div>
                          <div className="breakouts-session-questioner-subtitle-end">
                            {question.speaker.position_title.length > 40 && width < 1200
                              ? `${question.speaker.position_title.substring(0, 37)}...`
                              : question.speaker.position_title}
                          </div>
                        </div>
                        <img
                          src={
                            question.speaker.profile_picture.image_path !== ''
                              ? getUrlWithSpecificRendition(
                                  question.speaker.profile_picture.base_url,
                                  SCREEN_DENSITY_QUALIFIERS.LDPI,
                                  question.speaker.profile_picture.image_path
                                )
                              : getFeatureComponentUrlLdpi(
                                  accessibilityData,
                                  ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
                                  ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                                )
                          }
                          className={'breakouts-profile-image-small'}
                        />
                      </div>
                    </div>
                    <HtmlView
                      html={question.question}
                      htmlStyle="new-joinees-higher-levels-questions-questioner-question mt-3 mb-3"
                    />
                  </div>
                  {question.answers.length > 0 && (
                    <div className="ml-3 mb-3 pl-2">
                      <div className="row mt-3 justify-content-between">
                        <div className="row mr-1 ml-1">
                          <img
                            src={
                              question.answers[0].employee.profile_picture.image_path !== ''
                                ? getUrlWithSpecificRendition(
                                    question.answers[0].employee.profile_picture.base_url,
                                    SCREEN_DENSITY_QUALIFIERS.LDPI,
                                    question.answers[0].employee.profile_picture.image_path
                                  )
                                : getFeatureComponentUrlLdpi(
                                    accessibilityData,
                                    ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
                                    ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                                  )
                            }
                            className="breakouts-profile-image-big"
                          />
                          <div className="ml-3 outer-view mr-3">
                            <div>
                              <div className="breakouts-session-questioner-title-answer">
                                {question.answers[0].employee.employee_name}
                              </div>
                              <div className="breakouts-session-questioner-subtitle-answer">
                                {question.answers[0].employee.position_title}
                              </div>
                              <div className="mt-1 breakouts-session-questioner-date-posted-answer">
                                <div>
                                  {formatDateDDMonthYYYYTimeAMPM(question.answers[0].answer_on)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="breakouts-latest-reply">Latest Reply</div>
                      </div>
                      <HtmlView
                        html={question.answers[0].answer}
                        htmlStyle="new-joinees-higher-levels-questions-questioner-question mt-3 mb-0"
                      />
                    </div>
                  )}
                  <div className="pb-3 pl-3 pr-3">
                    <Divider />
                    <div className="ans-count-answer-block mt-3">
                      <div className="ans-count-view" onClick={() => handleEvent(question)}>
                        {`${question.answers.length} ${
                          question.answers.length === 1 ? 'Answer' : 'Answers'
                        }`}
                      </div>
                      {question.can_answer === 1 && (
                        <CustomButton
                          buttonStyle="breakouts-button-style mr-2 mt-1"
                          onClick={() => handleEvent(question)}>
                          Answer
                        </CustomButton>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          {noQuestionsFoundStatus && (
            <DefaultContentView message={ERROR_MESSAGES.SESSION_NO_QUESTIONS_ASKED} />
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(QuestionTab);
