import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NO_DATA_TEXT,
} from '../../../common/constants/AppConstants';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import BusinessAwardeeView from '../components/BusinessAwardeeView';

const BusinessAwardeesTab = (props) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.SOM_STAR_TAB, props.item, ANALYTICS_ITEM_NAMES.SOM);
  }, [props.item]);

  const getYearView = () => {
    return props.data.map((award, index) => <BusinessAwardeeView key={index} award={award} />);
  };

  const showEmptyListView = () => {
    return (
      <DefaultContentView
        message={NO_DATA_TEXT.NO_DATA_FOUND}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  return (
    <div>{props?.noMatchFlag === false ? <div>{getYearView()}</div> : showEmptyListView()}</div>
  );
};

export default BusinessAwardeesTab;
