import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Modal, Tab } from 'react-bootstrap';
import { Formik } from 'formik';
import { Prompt } from 'react-router';
import * as yup from 'yup';

import {
  REQUEST_TYPE,
  MY_EDITABLE_PROFILE_IDENTIFIERS,
  ERROR_MESSAGES,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  CUSTOM_VALIDATION_TEST_NAMES,
  MESSAGES,
  DEFAULT_ISD_CODE,
  DEFAULT_REGION_CODE,
} from '../../common/constants/AppConstants';
import {
  isValidFacebookSocialUrl,
  isValidInstagramSocialUrl,
  isValidLinkedinSocialUrl,
  isValidTwitterSocialUrl,
} from '../../utils/formValidation';
import {
  saveMyProfileDetailEditTabData,
  selectMyProfileDetailEditTabData,
} from '../../state/TabData';
import { apiRequest, handleError } from '../../services/Service';
import { UPDATE_PROFILE, UPDATE_PROFILE_PICTURE_NEW } from '../../services/ApiUrls';
import { saveEmployeeData, selectEmployeeData } from '../../state/EmployeeData';
import {
  getProfanityFilter,
  showToast,
  trimTrailingCommasAndWhitespaces,
} from '../../utils/common';
import {
  selectMultilineTextSize,
  selectMyProfileDetailEditTabs,
  selectProfinityFilterWarning,
} from '../../state/MasterData';
import { resetLoaderState, saveProgressLoadingState } from '../../state/UIState';
import { FaSave } from 'react-icons/fa';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import CountriesDataList from './../../common/constants/CountryCodes.json';
import MyProfileDetailEditTab from './tabs/MyProfileDetailEditTab';
import MySocialAccountsTab from './tabs/MySocialAccountsTab';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import NavTabs from '../../common/ui/tabs/NavTabs';
import BackButton from '../../common/ui/common_back_button/BackButton';

const MyProfileDetailEditScreen = ({ history }) => {
  const dispatch = useDispatch();
  const maxMultiLineCharLimit = useSelector(selectMultilineTextSize);
  const employeeData = useSelector(selectEmployeeData);
  const profileFormRef = useRef();
  const myProfileDetailEditTabTitles = useSelector(selectMyProfileDetailEditTabs);
  const myProfileDetailEditTabData = useSelector(selectMyProfileDetailEditTabData);
  const [isLoading, setIsLoading] = useState(false);
  const [myProfileData, setMyProfileData] = useState(employeeData);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const profinityFilterWarning = useSelector(selectProfinityFilterWarning);

  useEffect(() => {
    if (employeeData && Object.keys(employeeData).length) {
      setMyProfileData(employeeData);
    }
  }, [employeeData]);

  const getDefaultIsdCode = () => {
    return DEFAULT_ISD_CODE;
  };

  const getRegionCodeFromIsdCode = (isdCode) => {
    return isdCode
      ? CountriesDataList.find((country) => {
          return country.dialCode === isdCode;
        })
          .isoCode.toString()
          .toLowerCase()
      : CountriesDataList.find((country) => {
          return (
            country.abbreviation.toLowerCase() === employeeData.geography.abbreviation.toLowerCase()
          );
        })
      ? CountriesDataList.find((country) => {
          return (
            country.abbreviation.toLowerCase() === employeeData.geography.abbreviation.toLowerCase()
          );
        })
          .isoCode.toString()
          .toLowerCase()
      : DEFAULT_REGION_CODE.toLowerCase();
  };

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.MY_PROFILE_DETAIL_EDIT,
      NAVIGATION_ROUTES.MY_PROFILE_DETAIL_EDIT
    );
  }, []);

  useEffect(() => {
    if (myProfileDetailEditTabTitles.length && myProfileDetailEditTabData === '') {
      dispatch(saveMyProfileDetailEditTabData(myProfileDetailEditTabTitles[0].key));
    }
  }, [dispatch, myProfileDetailEditTabData, myProfileDetailEditTabTitles]);

  useEffect(() => {
    window.onbeforeunload = () => {
      if (profileFormRef.current.dirty) {
        return MESSAGES.UNSAVED_CHANGES;
      }
    };
    return () => (window.onbeforeunload = null);
  }, []);

  const callUpdateProfileApi = useCallback(
    async (formValues) => {
      let params = new URLSearchParams(formValues);
      try {
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        const apiResponse = await apiRequest(UPDATE_PROFILE, REQUEST_TYPE.POST, params);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            const message = apiResponse.response.message;
            if (message) {
              showToast(message);
              dispatch(saveEmployeeData(apiResponse.response.data.employee));
              profileFormRef.current.resetForm();
              history.goBack();
            }
          }
        } else {
          dispatch(resetLoaderState());
        }
      } catch (err) {
        handleError(err, params, UPDATE_PROFILE, NAVIGATION_ROUTES.MY_PROFILE_DETAIL_EDIT);
        dispatch(resetLoaderState());
      }
    },
    [dispatch, history]
  );

  const callUploadProfilePictureApi = async (imageFile, endPoint) => {
    let params = new URLSearchParams();
    if (endPoint === UPDATE_PROFILE_PICTURE_NEW) {
      params.append('profile_picture', imageFile);
    } else {
      params.append('file', imageFile);
    }
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(endPoint, REQUEST_TYPE.POST, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          const message = apiResponse.response.message;
          if (message) {
            showToast(message);
            dispatch(saveEmployeeData(apiResponse.response.data.employee));
            history.goBack();
          }
        }
      }
    } catch (err) {
      handleError(err, params, endPoint, NAVIGATION_ROUTES.MY_PROFILE_DETAIL_EDIT);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const saveProfile = useCallback(
    async (formValues) => {
      logEvent(ANALYTICS_EVENT_TYPES.MY_PROFILE_INFO_UPDATED, '', ANALYTICS_ITEM_NAMES.MY_PROFILE);
      await callUpdateProfileApi(formValues);
    },
    [callUpdateProfileApi]
  );

  const renderTab = (tabKey, formikProps) => {
    switch (tabKey) {
      case MY_EDITABLE_PROFILE_IDENTIFIERS.DETAILS:
        return (
          <MyProfileDetailEditTab
            data={myProfileData}
            formikProps={formikProps}
            callUploadProfilePictureApi={callUploadProfilePictureApi}
          />
        );
      case MY_EDITABLE_PROFILE_IDENTIFIERS.SOCIAL_ACCOUNTS:
        return <MySocialAccountsTab formikProps={formikProps} />;
    }
  };

  const onPressSubmitHandler = async (formValues) => {
    if (
      getProfanityFilter().isProfane(formValues.pronouns) ||
      getProfanityFilter().isProfane(formValues.description)
    ) {
      setShowWarningDialog(true);
    } else {
      setIsLoading(true);
      await saveProfile(formValues);
      setIsLoading(false);
    }
  };

  const profileValidationSchema = yup.object().shape({
    display_name: yup
      .string()
      .max(maxMultiLineCharLimit, ERROR_MESSAGES.DISPLAY_NAME_MAX_CHAR_LIMIT_EXCEEDED),
    description: yup
      .string()
      .max(maxMultiLineCharLimit, ERROR_MESSAGES.DESCRIPTION_MAX_CHAR_LIMIT_EXCEEDED),
    linkedin_url: yup
      .string()
      .url(ERROR_MESSAGES.INVALID_LINKEDIN_LINK)
      .test(
        CUSTOM_VALIDATION_TEST_NAMES.IS_VALID_LINKEDIN_SOCIAL_URL,
        ERROR_MESSAGES.INVALID_LINKEDIN_LINK,
        (val) => {
          if (val === undefined) {
            return true;
          }
          return isValidLinkedinSocialUrl(val);
        }
      ),
    twitter_url: yup
      .string()
      .url(ERROR_MESSAGES.INVALID_TWITTER_LINK)
      .test(
        CUSTOM_VALIDATION_TEST_NAMES.IS_VALID_TWITTER_SOCIAL_URL,
        ERROR_MESSAGES.INVALID_TWITTER_LINK,
        (val) => {
          return isValidTwitterSocialUrl(val);
        }
      ),
    facebook_url: yup
      .string()
      .url(ERROR_MESSAGES.INVALID_FACEBOOK_LINK)
      .test(
        CUSTOM_VALIDATION_TEST_NAMES.IS_VALID_FACEBOOK_SOCIAL_URL,
        ERROR_MESSAGES.INVALID_FACEBOOK_LINK,
        (val) => {
          return isValidFacebookSocialUrl(val);
        }
      ),
    instagram_url: yup
      .string()
      .url(ERROR_MESSAGES.INVALID_INSTAGRAM_LINK)
      .test(
        CUSTOM_VALIDATION_TEST_NAMES.IS_VALID_INSTAGRAM_SOCIAL_URL,
        ERROR_MESSAGES.INVALID_INSTAGRAM_LINK,
        (val) => {
          return isValidInstagramSocialUrl(val);
        }
      ),
    website_url: yup.string().url(ERROR_MESSAGES.INVALID_WEBSITE_LINK),
    mobile_number: yup
      .string()
      .required(ERROR_MESSAGES.UPDATE_PERSONAL_NUMBER)
      .max(16, ERROR_MESSAGES.INVALID_PERSONAL_NUMBER)
      .min(6, ERROR_MESSAGES.INVALID_PERSONAL_NUMBER),
    // .test(
    //   CUSTOM_VALIDATION_TEST_NAMES.IS_VALID_PHONE_NUMBER_FOR_REGION,
    //   ERROR_MESSAGES.INVALID_PERSONAL_NUMBER,
    //   (val, options) => {
    //     return isValidPhoneNumberForRegion(val, options.parent.mobile_isd_code);
    //   }
    // )
    work_phone: yup
      .string()
      .max(16, ERROR_MESSAGES.INVALID_BUSINESS_NUMBER)
      .min(6, ERROR_MESSAGES.INVALID_BUSINESS_NUMBER),
    // .test(
    //   CUSTOM_VALIDATION_TEST_NAMES.IS_VALID_PHONE_NUMBER_FOR_REGION,
    //   ERROR_MESSAGES.INVALID_BUSINESS_NUMBER,
    //   (val, options) => {
    //     if (val === undefined) {
    //       return true;
    //     }
    //     return isValidPhoneNumberForRegion(val, options.parent.work_phone_isd_code);
    //   }
    // )
    extension: yup.string(ERROR_MESSAGES.INVALID_EXTENSION_FORMAT),
    pronouns: yup.string(ERROR_MESSAGES.INVALID_PRONOUNS),
  });

  const warningDialog = () => {
    return (
      <div>
        <div className="common-comment-box-profinity-warning-text text-center imagesReview mt-2 mb-3">
          WARNING!
        </div>
        <div className="common-comment-box-profinity-text text-center imagesReview mt-2 mb-3">
          {profinityFilterWarning}
        </div>
        <div className="text-center">
          <CustomButton
            buttonStyle="common-comment-box-profinity-button mt-2"
            onClick={() => {
              setShowWarningDialog(false);
            }}>
            OK
          </CustomButton>
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      <Modal
        show={showWarningDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowWarningDialog(false)}>
        <Modal.Body>{warningDialog()}</Modal.Body>
      </Modal>
      {employeeData &&
        !!Object.keys(employeeData).length &&
        myProfileData &&
        !!Object.keys(myProfileData).length && (
          <div>
            <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.PROFILE} />
            <Formik
              validationSchema={profileValidationSchema}
              onSubmit={onPressSubmitHandler}
              innerRef={profileFormRef}
              initialValues={{
                display_name: employeeData.display_name,
                date_of_birth: employeeData?.date_of_birth?.date,
                birth_day_visible: employeeData?.date_of_birth?.visibility ? 1 : 0,
                blood_group: employeeData.blood_group?.group,
                blood_group_visible: employeeData?.blood_group?.visibility ? 1 : 0,
                vaccination_status: employeeData?.vaccination?.status,
                vaccination_visible: 1,
                mobile_number_visible: employeeData?.mobile_number?.is_mobile_number_visible
                  ? 1
                  : 0,
                description: employeeData.description,
                linkedin_url: employeeData.social?.linkedin_url,
                twitter_url: employeeData.social?.twitter_url,
                facebook_url: employeeData.social?.facebook_url,
                instagram_url: employeeData.social?.instagram_url,
                website_url: employeeData.social?.website_url,
                open_id: employeeData.social?.open_id,
                mobile_number: employeeData?.mobile_number?.number,
                mobile_region_code: getRegionCodeFromIsdCode(
                  employeeData.mobile_number?.isd_code
                    ? employeeData.mobile_number?.isd_code
                    : getDefaultIsdCode()
                ),
                mobile_isd_code: employeeData.mobile_number?.isd_code
                  ? employeeData.mobile_number?.isd_code
                  : getDefaultIsdCode(),
                pronouns: employeeData.pronouns,
              }}>
              {(formikProps) => (
                <Form
                  noValidate
                  onSubmit={(e) => {
                    e.preventDefault();
                    if (formikProps.submitCount > 0 && !formikProps.isValid) {
                      showToast(ERROR_MESSAGES.FORM_ERRORS);
                    } else {
                      if (!formikProps.isValid) {
                        showToast(ERROR_MESSAGES.FORM_ERRORS);
                      }
                      formikProps.handleSubmit();
                    }
                  }}>
                  <Prompt when={formikProps.dirty} message={MESSAGES.UNSAVED_CHANGES} />
                  <div className="header-view justify-content-between">
                    <BackButton isFullRow={false} />
                    <div className="broadcast-header-row justify-content-end-view">
                      <CustomButton
                        showLoader={isLoading}
                        type="submit"
                        buttonStyle="breakouts-upload-photos-btn">
                        <FaSave size="1.2em" className="mr-2" />
                        SAVE
                      </CustomButton>
                    </div>
                  </div>
                  <div className="my-profile-edit-tab-container">
                    <NavTabs
                      activeKey={myProfileDetailEditTabData}
                      onSelect={(k) => {
                        dispatch(saveMyProfileDetailEditTabData(k));
                      }}>
                      {myProfileDetailEditTabTitles.map((tab, index) => {
                        return (
                          <Tab key={`${index}`} eventKey={tab.key} title={tab.title}>
                            {renderTab(tab.key, formikProps)}
                          </Tab>
                        );
                      })}
                    </NavTabs>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
    </div>
  );
};

export default MyProfileDetailEditScreen;
