import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { GET_SELF_AWARDS } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { saveProgressLoadingState } from '../../state/UIState';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import SelfListItem from './components/SelfListItem';

const SelfScreen = ({ history }) => {
  const dispatch = useDispatch();
  const [cheersList, setCheersList] = useState([]);
  const [monthlyList, setMonthlyList] = useState([]);
  const [eligibleAwardButtonIsDisable, setEligibleAwardButtonIsDisable] = useState(true);
  const [isSelectedLastMonthButton, setIsSelectedLastMonthButton] = useState(false);
  const [isSelectedLastQuarterButton, setIsSelectedLastQuarterButton] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.SELF_SCREEN, NAVIGATION_ROUTES.SELF_SCREEN);
  }, []);

  const callMyReporterApi = useCallback(async () => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(GET_SELF_AWARDS, REQUEST_TYPE.GET, {});
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data[0].feeds.length > 0) {
            setCheersList(apiResponse.response.data[0].feeds);
          }
        }
      }
    } catch (err) {
      handleError(err, {}, GET_SELF_AWARDS, NAVIGATION_ROUTES.SELF_SCREEN);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch]);

  useEffect(() => {
    callMyReporterApi();
  }, [callMyReporterApi]);

  const onRadioButtonPressed = (item, index) => {
    setSelectedIndex(index);
    setEligibleAwardButtonIsDisable(false);
    setSelectedItem(item);
  };

  const renderFilters = (data, index) => {
    return (
      <SelfListItem
        item={data}
        onRadioButtonPressed={onRadioButtonPressed}
        key={`${index}`}
        index={index}
        selectedIndex={selectedIndex}
      />
    );
  };

  const onPressLastMonth = () => {
    setEligibleAwardButtonIsDisable(true);
    setIsSelectedLastQuarterButton(false);
    setIsSelectedLastMonthButton(true);
    setSelectedIndex(null);
    let arr = [];
    cheersList.forEach((element) => {
      if (element.award_frequency === 'Monthly') {
        arr.push(element);
      }
    });
    setMonthlyList(arr);
  };

  const onPressLastQuarter = () => {
    setEligibleAwardButtonIsDisable(true);
    setIsSelectedLastQuarterButton(true);
    setIsSelectedLastMonthButton(false);
    setSelectedIndex(null);
    let arr = [];
    cheersList.forEach((element) => {
      if (element.award_frequency === 'Quarterly') {
        arr.push(element);
      }
    });
    setMonthlyList(arr);
  };

  const onPressViewEligibleAward = () => {
    history.push(NAVIGATION_ROUTES.VIEW_ELIGIBLE_AWARD_SCREEN, {
      selectedItem: selectedItem,
    });
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CHEERS} />
      <BackButton />
      <div className="main-content-container">
        <div className="cheers-select-option-text">What time frame is this award for?</div>
        <div className="cheers-date-view-style mt-2">
          {isSelectedLastMonthButton ? (
            <CustomButton buttonStyle="cheers-selected-button-style mr-3">Last Month</CustomButton>
          ) : (
            <CustomButton
              buttonStyle="cheers-not-selected-button-style mr-3"
              onClick={() => onPressLastMonth()}>
              Last Month
            </CustomButton>
          )}
          {isSelectedLastQuarterButton ? (
            <CustomButton buttonStyle="cheers-selected-button-style">Last Quarter</CustomButton>
          ) : (
            <CustomButton
              buttonStyle="cheers-not-selected-button-style"
              onClick={() => onPressLastQuarter()}>
              Last Quarter
            </CustomButton>
          )}
        </div>
        {monthlyList.length > 0 && (
          <div className="mt-2 cheers-select-option-text">
            Why do you think you should be awarded?
          </div>
        )}
        {monthlyList.length > 0 && monthlyList.map((data, index) => renderFilters(data, index))}
        <CustomButton
          buttonStyle="cheers-button-style mr-3 mt-2"
          onClick={onPressViewEligibleAward}
          disabled={eligibleAwardButtonIsDisable}>
          View eligible award
        </CustomButton>
      </div>
    </div>
  );
};

export default SelfScreen;
