import React, { useState } from 'react';

const CustomCheckbox = ({ checked, onChange, disabled }) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleClick = () => {
    if (!disabled) {
      const newCheckedState = !isChecked;
      setIsChecked(newCheckedState);
      onChange({ target: { checked: newCheckedState } });
    }
  };

  return (
    <div className={`custom-checkbox ${disabled ? 'disabled' : ''}`} onClick={handleClick}>
      <input
        type="checkbox"
        className="attendance-signin-custom-checkbox"
        checked={isChecked}
        readOnly
      />
      <span className="checkbox-custom"></span>
    </div>
  );
};

export default CustomCheckbox;
