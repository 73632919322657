import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { MESSAGES, NAVIGATION_ROUTES, REQUEST_TYPE } from '../../../common/constants/AppConstants';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import { COMMUNITIES_BY_ID } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectCommunityData } from '../../../state/CommunityData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../state/UIState';
import { showToast } from '../../../utils/common';

const LikeCommentDialog = ({ onChangeDialogVisible, flag }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const communityData = useSelector(selectCommunityData);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  const callJoinApi = useCallback(async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const apiResponse = await apiRequest(
        COMMUNITIES_BY_ID + communityData.community_id + '/join',
        REQUEST_TYPE.POST,
        {}
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response) {
            const data = apiResponse.response;
            showToast(data.message);
            onChangeDialogVisible(false);
            if (flag === '1') {
              history.goBack();
            } else if (flag === '2') {
              history.go(-2);
            }
          }
        }
      }
    } catch (err) {
      handleError(
        err,
        {},
        COMMUNITIES_BY_ID + communityData.community_id + '/join',
        NAVIGATION_ROUTES.COMMUNITIES
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [communityData.community_id, dispatch, flag, history, onChangeDialogVisible]);

  return (
    <div>
      <div className="text-center communities-dialog-title">OOPS!</div>
      <div className="text-center communities-dialog-subtitle mt-3">
        {MESSAGES.CANNOT_COMMENT_LIKE}
      </div>
      <div className="d-flex justify-content-center mt-3">
        <div
          className="d-flex justify-content-center align-items-center mr-4"
          onClick={() => {
            onChangeDialogVisible(false);
          }}>
          <div className="common-cursor-pointer communities-cancel-community-button">CANCEL</div>
        </div>
        <CustomButton
          buttonStyle="common-custom-button-quaternary ml-4"
          showLoader={progressLoadingState}
          onClick={() => {
            callJoinApi();
          }}>
          JOIN COMMUNITY
        </CustomButton>
      </div>
    </div>
  );
};

export default LikeCommentDialog;
