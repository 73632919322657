import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  NAVIGATION_ROUTES,
  ACCESSIBILITY_HEADER_COMPONENTS,
  REQUEST_TYPE,
  ERROR_MESSAGES,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ACCESSIBILITY_FEATURE_COMPONENTS,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../common/constants/AppConstants';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { apiRequest, handleError } from '../../services/Service';
import { GET_POLICIES } from '../../services/ApiUrls';
import {
  saveProgressLoadingState,
  saveSearchText,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../state/UIState';
import PolicyListItem from './components/PolicyListItem';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import {
  savePoliciesSearchTextData,
  selectPoliciesSearchTextData,
  savePoliciesSearchResultData,
  selectPoliciesSearchResultData,
  savePoliciesLoadMoreButtonData,
  selectPoliciesLoadMoreButtonData,
  savePoliciesPageData,
  selectPoliciesPageData,
} from '../../state/PoliciesData';
import { selectEmployeeData } from '../../state/EmployeeData';
import AskMlChatWidget from '../ask_ml/AskMlChatWidget';
import { selectAccessData } from '../../state/UserAccessData';
import debounce from 'lodash.debounce';
import ActivityLoader from '../../common/ui/activity_loader/ActivityLoader';
import { generatev4UniqueID } from '../../utils/common';

const PolicySearchScreen = ({ history, location }) => {
  const dispatch = useDispatch();
  const [policy, setPolicy] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(true);
  const savedSearchText = useSelector(selectPoliciesSearchTextData);
  const [searchText, setSearchText] = useState(savedSearchText ?? '');

  const accessibilityData = useSelector(selectAccessibilityData);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const savedSearchResult = useSelector(selectPoliciesSearchResultData);
  const savedLoadMoreButtonState = useSelector(selectPoliciesLoadMoreButtonData);
  const savedPageId = useSelector(selectPoliciesPageData);
  const [isSearchEnabled, setIsSearchEnabled] = useState(true);
  const [showWidget, setShowWidget] = useState(false);
  const employeeConfig = useSelector(selectEmployeeData);
  const accessData = useSelector(selectAccessData);
  const [showChatBot, setShowChatBot] = useState(true);
  const policiesAccessData = accessData?.data?.find((item) => item.feature_key === '_policies');
  const [uniqueUUID, setUniqueUUID] = useState('');

  useEffect(() => {
    const actions = policiesAccessData?.actions;
    setShowChatBot(actions?.includes('_chatbot'));
  }, [policiesAccessData?.actions]);
  const [quickSearchLoading, setQuickSearchLoading] = useState(false);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.POLICY_SEARCH_SCREEN,
      NAVIGATION_ROUTES.POLICY_SEARCH_SCREEN
    );
  }, []);

  const toggleWidget = () => {
    setShowWidget(!showWidget);
  };

  const callPolicyApi = useCallback(
    async (id, loadMore, searchQuery, isQuickSearch = false) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMore === true) {
        const params = {
          page_id: id,
          category_id: 0,
          query: searchQuery,
        };
        try {
          if (id === 1) {
            dispatch(saveShowEmptyContentView(false));
            setPolicy([]);
          }
          if (isQuickSearch) {
            setQuickSearchLoading(true);
          } else {
            dispatch(
              saveProgressLoadingState({
                isProgressLoading: true,
              })
            );
          }
          const apiResponse = await apiRequest(GET_POLICIES, REQUEST_TYPE.GET, params);
          setQuickSearchLoading(false);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (id === 1 && apiResponse.response.data.feeds.length === 0) {
                dispatch(saveShowEmptyContentView(true));
              }
              if (apiResponse.response.data.feeds.length > 0) {
                if (id === 1) {
                  setPolicy(apiResponse.response.data.feeds);
                } else {
                  setPolicy((oldArray) => [...oldArray, ...apiResponse.response.data.feeds]);
                }
                setLoadMoreFlag(true);
                setPageId((prevCount) => prevCount + 1);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(err, params, GET_POLICIES, NAVIGATION_ROUTES.POLICY_SEARCH_SCREEN);
          setQuickSearchLoading(false);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      }
    },
    [dispatch]
  );

  const debouncedCallback = (event) => {
    if (event && event.target.value.length > 2) {
      dispatch(saveSearchText(event.target.value));
      setPageId(1);
      setLoadMoreFlag(true);
      callPolicyApi(1, true, event.target.value, true);
    }
    setSearchText(event.target.value);
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );

  const onInputChange = (event) => {
    setSearchText(event.target.value);
    debounceInputHandler(event);
    if (event.target.value === '') {
      setPolicy([]);
    }
  };

  const callSubmitFunction = (event) => {
    event.preventDefault();
    if (searchText !== '') {
      dispatch(savePoliciesSearchTextData(searchText));
      logEvent(ANALYTICS_EVENT_TYPES.POLICIES_SEARCH, searchText, ANALYTICS_ITEM_NAMES.SEARCH);
      setPolicy([]);
      setPageId(1);
      setLoadMoreFlag(true);
      callPolicyApi(1, true, searchText);
    }
  };

  const resetValue = () => {
    history.replace(NAVIGATION_ROUTES.POLICY_SEARCH_SCREEN);
    setSearchText('');
    dispatch(savePoliciesSearchTextData(''));
    dispatch(savePoliciesLoadMoreButtonData(false));
    dispatch(savePoliciesSearchResultData([]));
    dispatch(savePoliciesPageData(1));
    setIsSearchEnabled(false);
    setPageId(1);
    setIsSearchEnabled(false);
    callPolicyApi(1, true, '');
    setLoadMoreFlag(true);
  };

  const onPressLoadMore = () => {
    callPolicyApi(pageId, loadMoreFlag, searchText);
  };

  const onPressItem = (item) => {
    dispatch(savePoliciesSearchTextData(searchText));
    dispatch(savePoliciesLoadMoreButtonData(loadMoreFlag));
    dispatch(savePoliciesSearchResultData(policy));
    dispatch(savePoliciesPageData(pageId));
    history.push(`${NAVIGATION_ROUTES.POLICY_DETAIL_SCREEN}/${item.policy_id}`);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.SEARCH_NO_RESULTS} />;
  };

  useEffect(() => {
    if (savedSearchText !== '') {
      setSearchText(savedSearchText);
      setPolicy(savedSearchResult);
      setPageId(savedPageId);
      setLoadMoreFlag(savedLoadMoreButtonState);
      // callPolicyApi(1, true, savedSearchText);
    }
  }, [savedSearchText, savedPageId, savedLoadMoreButtonState, savedSearchResult]);

  useEffect(() => {
    if (searchText) {
      callPolicyApi(1, true, searchText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickSearchHandler = () => {
    setIsSearchEnabled(true);
  };

  useEffect(() => {
    if (location?.hash) {
      let hashtag = location?.hash || '';
      setSearchText(hashtag);

      callPolicyApi(1, true, hashtag);
    }
  }, [callPolicyApi, location]);
  return (
    <div className="container">
      {employeeConfig?.gpt_visible == 1 && showChatBot && (
        <div
          className={
            !showWidget
              ? 'd-flex common-cursor-pointer chat-bot-floating-icon p-2 align-items-center justify-content-center'
              : 'd-flex common-cursor-pointer chat-bot-floating-close-icon p-2 align-items-center justify-content-center'
          }>
          <div
            className="position-relative flex-grow-1 d-flex justify-content-center align-items-center"
            onClick={() => {
              toggleWidget();
              if (!showWidget) {
                const id = generatev4UniqueID();
                setUniqueUUID(id);
              }
            }}>
            <img
              className={!showWidget ? 'ask-org-chat-bot-float-icon' : 'ask-org-close-float-icon'}
              src={
                !showWidget
                  ? getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.CHAT_BOT,
                      ACCESSIBILITY_IDENTIFIERS.ASK_ORG
                    )
                  : 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgMzU3IDM1NyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzU3IDM1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnIGlkPSJjbGVhciI+CgkJPHBvbHlnb24gcG9pbnRzPSIzNTcsMzUuNyAzMjEuMywwIDE3OC41LDE0Mi44IDM1LjcsMCAwLDM1LjcgMTQyLjgsMTc4LjUgMCwzMjEuMyAzNS43LDM1NyAxNzguNSwyMTQuMiAzMjEuMywzNTcgMzU3LDMyMS4zICAgICAyMTQuMiwxNzguNSAgICIgZmlsbD0iI0ZGRkZGRiIvPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo='
              }
            />
          </div>
          {showWidget ? <AskMlChatWidget uniqueUUID={uniqueUUID} /> : null}
        </div>
      )}

      <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
        <div
          className={
            isSearchEnabled ? 'search-bar-with-icon search-bar-border' : 'search-bar-with-icon'
          }>
          {isSearchEnabled ? (
            <form className="directory-search-bar" onSubmit={callSubmitFunction}>
              <input
                autoFocus
                className="ml-2 directory-search-bar"
                type="text"
                placeholder="Search.."
                onChange={onInputChange}
                value={searchText}
              />
            </form>
          ) : (
            <div className="directory-search-bar" />
          )}
          {isSearchEnabled ? (
            <div className="gcdo-icon-row mr-2" onClick={resetValue}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
                )}
                className="header-icon"
              />
            </div>
          ) : (
            <div className="gcdo-icon-row mr-2" onClick={onClickSearchHandler}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH
                )}
                className="header-icon"
              />
            </div>
          )}
        </div>
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.POLICIES} />
      <BackButton />
      <div className="main-content-container">
        <ActivityLoader visible={quickSearchLoading} />
        {showEmptyContentView && !policy.length && !progressLoadingState.isProgressLoading ? (
          emptyListView()
        ) : (
          <div>
            {policy.map((item, index) => {
              return <PolicyListItem onPressItem={onPressItem} item={item} key={`${index}`} />;
            })}
            {loadMoreFlag && !!policy.length && (
              <LoadMoreBtn
                onClick={onPressLoadMore}
                isLoading={progressLoadingState.isProgressLoading}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PolicySearchScreen;
