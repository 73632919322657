import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import { store } from '../config/store';
import { getEmployeeData, getOrgDomain, getUserChatGroups } from './ReduxState';
import { removeGroup, saveTotalActiveGroups, saveUserChatGroups } from '../state/ChatData';
import { FIREBASE_DB_NODES, NOTIFICATION_MESSAGE_TYPES } from '../common/constants/AppConstants';

export const uId = (employee) => {
  return `uid-${employee.employee_id}`;
};

export const generatePrivateChatId = (currentUserId, otherUserId) => {
  let aTemp = currentUserId.replace('uid-', '');
  let bTemp = otherUserId.replace('uid-', '');
  let id = '';
  if (parseInt(aTemp) > parseInt(bTemp)) {
    id = otherUserId + '_' + currentUserId;
  } else {
    id = currentUserId + '_' + otherUserId;
  }
  return id;
};

export const getNodeReference = (node) => {
  const domain = getOrgDomain();

  switch (node) {
    case FIREBASE_DB_NODES.USERS:
      return firebase.database().ref(`${domain}/chat/users`);
    case FIREBASE_DB_NODES.GROUP:
      return firebase.database().ref(`${domain}/chat/group`);
    case FIREBASE_DB_NODES.MESSAGES:
      return firebase.database().ref(`${domain}/chat/messages`);
    default:
  }
};

export const createOrUpdateUser = (userObj, callback) => {
  getNodeReference(FIREBASE_DB_NODES.USERS)
    .child(userObj.uid)
    .once('value')
    .then((snapshot) => {
      if (snapshot.exists()) {
        userObj.last_seen_message_timestamp = new Date().getTime();
        getNodeReference(FIREBASE_DB_NODES.USERS)
          .child(userObj.uid)
          .update(userObj)
          .then(() => {
            callback();
          });
      } else {
        getNodeReference(FIREBASE_DB_NODES.USERS)
          .child(userObj.uid)
          .set(userObj)
          .then(() => {
            callback();
          });
      }
    });
};

export const fetchUserGroupsById = (uid, callback) => {
  getNodeReference(FIREBASE_DB_NODES.USERS)
    .child(uid.toString())
    .child('group')
    .on('value', (snapshot) => {
      if (snapshot.exists()) {
        const groups = snapshot.val();
        store.dispatch(saveTotalActiveGroups(groups));
        callback(true, groups);
      } else {
        callback(false, null);
      }
    });
};

export const fetchUserByIdOnce = (uid, callback) => {
  getNodeReference(FIREBASE_DB_NODES.USERS)
    .child(uid)
    .once('value')
    .then((snapshot) => {
      if (snapshot.exists()) {
        const currentUser = snapshot.val();
        callback(true, currentUser);
      } else {
        callback(false, null);
      }
    });
};

export const removeFCMTokenAndDeviceIdOnSignout = (deviceId, userId) => {
  getNodeReference(FIREBASE_DB_NODES.USERS)
    .child(userId)
    .child('deviceIds')
    .child(deviceId)
    .remove()
    .then(() => {});
};

export const updateOnlineOfflineStatus = (status, userId, callback) => {
  getNodeReference(FIREBASE_DB_NODES.USERS)
    .child(userId)
    .update({
      online: status,
      last_seen_online: new Date().getTime(),
    })
    .then(() => {
      callback();
    });
};

export const updateProfilePic = () => {
  let employeeData = getEmployeeData();
  const uid = uId(employeeData);
  let image_url =
    employeeData.profile_picture.image_path !== ''
      ? employeeData.profile_picture.base_url + employeeData.profile_picture.image_path
      : '';
  getNodeReference(FIREBASE_DB_NODES.USERS)
    .child(uid)
    .update({
      image_url: image_url,
    })
    .then(() => {});
};

export const fetchMyGroupsOnly = (groups, callback) => {
  let i = Object.keys(groups).length;
  let sGroupMap = {};
  for (const [key, value] of Object.entries(groups)) {
    if (value) {
      getNodeReference(FIREBASE_DB_NODES.GROUP)
        .child(key)
        .on('value', (snapshot) => {
          if (snapshot.exists()) {
            const groupModel = snapshot.val();
            if (groupModel.group_deleted === false) {
              sGroupMap[groupModel.groupId] = groupModel;
              store.dispatch(saveUserChatGroups(groupModel));
            } else {
              let userChatGroups = getUserChatGroups();
              if (key in userChatGroups) {
                getNodeReference(FIREBASE_DB_NODES.GROUP).child(key).off('value');
                store.dispatch(removeGroup(key));
              }
            }
          }
          i--;
          if (i <= 0) {
            callback(true, sGroupMap);
          }
        });
    } else {
      let userChatGroups = getUserChatGroups();
      if (key in userChatGroups) {
        getNodeReference(FIREBASE_DB_NODES.GROUP).child(key).off('value');
        store.dispatch(removeGroup(key));
      }
      i--;
    }
  }
  if (i <= 0) {
    callback(false, null);
  }
};

export const fetchGroupDetailByIdOnce = (groupId, callback) => {
  getNodeReference(FIREBASE_DB_NODES.GROUP)
    .child(groupId)
    .once('value', (snapshot) => {
      if (snapshot.exists()) {
        const groupModel = snapshot.val();
        callback(true, groupModel);
      } else {
        callback(false, null);
      }
    });
};

export const createOneOnOneChatGroup = (employee, otherUser, callback) => {
  const uid = uId(employee);
  let groupId = generatePrivateChatId(uid, otherUser.uid);
  let millis = new Date().getTime();
  let groupMembers = {};
  const otherUserModel = {
    unread_group_count: 0,
    last_seen_message_timestamp: millis,
    uid: otherUser.uid,
    delete_till: millis,
    active: true,
  };
  groupMembers[otherUserModel.uid] = otherUserModel;
  const adminUserModel = {
    unread_group_count: 0,
    last_seen_message_timestamp: millis,
    uid: uid,
    delete_till: millis,
    active: true,
  };
  groupMembers[adminUserModel.uid] = adminUserModel;
  let timestamp = new Date().getTime();
  const groupObj = {
    group: false,
    name: '',
    image_url: '',
    group_deleted: false,
    created_by: uid,
    members: groupMembers,
    groupId: groupId,
    timestamp: timestamp,
  };
  getNodeReference(FIREBASE_DB_NODES.GROUP)
    .child(groupId)
    .set(groupObj)
    .then(() => {
      callback();
      for (const [key] of Object.entries(groupObj.members)) {
        getNodeReference(FIREBASE_DB_NODES.USERS)
          .child(key)
          .child(FIREBASE_DB_NODES.GROUP)
          .child(groupId)
          .set(true)
          .then(() => {});
      }
    });
};

export const sendMessageToGroup = (
  groupId,
  employee,
  message,
  messageType,
  url,
  replyForwardParams,
  isForwarded,
  docType,
  docName,
  callback
) => {
  const uid = uId(employee);
  let timestamp = new Date().getTime();
  let messageId = getNodeReference(FIREBASE_DB_NODES.MESSAGES).child(groupId).push().key;
  let messageModel = {
    message: message,
    sender_id: uid,
    timestamp: timestamp,
    message_id: messageId,
    message_type: messageType === undefined ? '1' : messageType,
    url: url === undefined ? '' : url,
    is_forwarded: isForwarded,
    type: '1',
  };
  if (messageType === '3') {
    messageModel.doc_type = docType;
    messageModel.doc_name = docName;
  }
  if (Object.keys(replyForwardParams).length > 0) {
    messageModel.reply_forward_params = replyForwardParams;
  }
  getNodeReference(FIREBASE_DB_NODES.GROUP)
    .child(groupId)
    .child('lastMessage')
    .set(messageModel)
    .then(() => {});
  getNodeReference(FIREBASE_DB_NODES.MESSAGES)
    .child(groupId)
    .child(messageId)
    .set(messageModel)
    .then(() => {
      callback(groupId, messageId);
    });
};

export const sendNotificationMessageToGroup = (
  groupId,
  employee,
  message_type,
  members,
  callback
) => {
  const uid = uId(employee);
  let timestamp = new Date().getTime();
  let messageId = getNodeReference(FIREBASE_DB_NODES.MESSAGES).child(groupId).push().key;
  let messageModel = {
    sender_id: uid,
    timestamp: timestamp,
    message_id: messageId,
    message_type: message_type,
    type: '2',
    members: members,
  };
  getNodeReference(FIREBASE_DB_NODES.MESSAGES)
    .child(groupId)
    .child(messageId)
    .set(messageModel)
    .then(() => {
      callback(groupId, messageId);
    });
};

export const updateUnReadCountLastSeenMessageTimestamp = (groupId, userId, callback) => {
  getNodeReference(FIREBASE_DB_NODES.GROUP)
    .child(groupId)
    .child('members')
    .child(userId)
    .once('value', (snapshot) => {
      if (snapshot.exists()) {
        getNodeReference(FIREBASE_DB_NODES.GROUP)
          .child(groupId)
          .child('members')
          .child(userId)
          .update({
            unread_group_count: 0,
            last_seen_message_timestamp: new Date().getTime(),
          })
          .then(() => {
            callback();
          });
      } else {
        callback(false, null);
      }
    });
};

export const fetchMessagesByGroupPagination = (groupId, deleteTill, callback) => {
  getNodeReference(FIREBASE_DB_NODES.MESSAGES)
    .child(groupId)
    .orderByChild('timestamp')
    .startAt(deleteTill)
    .once('value', (snapshot) => {
      let arr = [];
      if (snapshot.exists()) {
        Object.values(snapshot.val()).forEach((value) => {
          arr.push(value);
        });
        callback(arr);
      } else {
        callback(arr);
      }
    });
};

export const createGroup = (groupName, imageUrl, employeeData, selectedEmployeesList, callback) => {
  const newGroup = {
    group: true,
    name: groupName,
    image_url: imageUrl,
    group_deleted: false,
  };
  const uid = uId(employeeData);
  let millis = new Date().getTime();
  const adminUserModel = {
    unread_group_count: 0,
    last_seen_message_timestamp: millis,
    uid: uid,
    delete_till: millis,
    admin: true,
    active: true,
  };
  let groupMembers = {};
  let i = selectedEmployeesList.length;
  let membersArr = [];
  selectedEmployeesList.forEach((element) => {
    const userId = uId(element);
    membersArr.push({
      name: element.first_name + ' ' + element.last_name,
      uid: userId,
    });
    const otherUser = {
      image_url:
        element.profile_picture.image_path !== ''
          ? element.profile_picture.base_url + element.profile_picture.image_path
          : '',
      name: element.first_name + ' ' + element.last_name,
      uid: userId,
      user_location: element?.location?.title,
    };
    const otherUserModel = {
      unread_group_count: 0,
      last_seen_message_timestamp: millis,
      uid: userId,
      delete_till: millis,
      admin: false,
      active: true,
    };
    createOrUpdateUser(otherUser, () => {
      i--;
      groupMembers[otherUserModel.uid] = otherUserModel;
      if (i === 0) {
        groupMembers[adminUserModel.uid] = adminUserModel;
        newGroup.members = groupMembers;
        newGroup.created_by = adminUserModel.uid;
        let groupId = getNodeReference(FIREBASE_DB_NODES.GROUP).push().key;
        newGroup.groupId = groupId;
        newGroup.timestamp = new Date().getTime();
        getNodeReference(FIREBASE_DB_NODES.GROUP)
          .child(groupId)
          .set(newGroup)
          .then(() => {
            callback(groupId);
            for (const [key] of Object.entries(newGroup.members)) {
              getNodeReference(FIREBASE_DB_NODES.USERS)
                .child(key)
                .child(FIREBASE_DB_NODES.GROUP)
                .child(groupId)
                .set(true)
                .then(() => {});
            }
            sendNotificationMessageToGroup(
              groupId,
              employeeData,
              NOTIFICATION_MESSAGE_TYPES.NEW_GROUP,
              [],
              () => {
                sendNotificationMessageToGroup(
                  groupId,
                  employeeData,
                  NOTIFICATION_MESSAGE_TYPES.ADD_MEMBER,
                  membersArr,
                  () => {}
                );
              }
            );
          });
      }
    });
  });
};

export const uploadGroupImage = (blob, callback) => {
  const date = new Date().getTime();
  const task = firebase.storage().ref().child('images').child(`${date}_gallery`).put(blob);
  task.on('state_changed', () => {});
  task
    .then(() => {
      firebase
        .storage()
        .ref()
        .child('images')
        .child(`${date}_gallery`)
        .getDownloadURL()
        .then((downloadUrl) => {
          callback(downloadUrl, null);
        })
        .catch((error) => {
          callback(null, error);
        });
    })
    .catch((error) => {
      callback(null, error);
    });
};

export const addMemberToAGroup = (groupId, userId, callback) => {
  let millis = new Date().getTime();
  const userModel = {
    unread_group_count: 0,
    last_seen_message_timestamp: millis,
    uid: userId,
    delete_till: millis,
    admin: false,
    active: true,
  };
  getNodeReference(FIREBASE_DB_NODES.GROUP)
    .child(groupId)
    .child('members')
    .child(userId)
    .set(userModel)
    .then(() => {});
  getNodeReference(FIREBASE_DB_NODES.USERS)
    .child(userId)
    .child('group')
    .child(groupId)
    .set(true)
    .then(() => {
      callback();
    });
};

export const updateGroupName = (groupId, groupName, callback) => {
  getNodeReference(FIREBASE_DB_NODES.GROUP)
    .child(groupId)
    .child('name')
    .set(groupName)
    .then(() => {
      callback();
    });
};

export const updateGroupImage = (groupId, imageUrl, callback) => {
  getNodeReference(FIREBASE_DB_NODES.GROUP)
    .child(groupId)
    .child('image_url')
    .set(imageUrl)
    .then(() => {
      callback();
    });
};

export const clearChatHistory = (groupId, userId, callback) => {
  getNodeReference(FIREBASE_DB_NODES.GROUP)
    .child(groupId)
    .child('members')
    .child(userId)
    .child('delete_till')
    .set(new Date().getTime())
    .then(() => {
      callback();
    });
};

export const exitMemberFromGroup = (groupId, userId, removed_by_uid, isSelfUserAdmin, callback) => {
  if (isSelfUserAdmin) {
    let anyOtherAdminPresent = false;
    fetchGroupDetailByIdOnce(groupId, (isGroupExist, groupModel) => {
      if (isGroupExist) {
        Object.values(groupModel.members).forEach((element) => {
          if (element.uid !== userId) {
            if (element.admin && element.admin === true) {
              anyOtherAdminPresent = true;
            }
          }
        });
        if (anyOtherAdminPresent) {
          getNodeReference(FIREBASE_DB_NODES.GROUP)
            .child(groupId)
            .child('members')
            .child(userId)
            .update({
              active: false,
              removed_by: removed_by_uid,
            })
            .then(() => {
              callback(true);
            });
        } else {
          callback(false);
        }
      }
    });
  } else {
    getNodeReference(FIREBASE_DB_NODES.GROUP)
      .child(groupId)
      .child('members')
      .child(userId)
      .update({
        active: false,
        removed_by: removed_by_uid,
      })
      .then(() => {
        callback(true);
      });
  }
};

export const uploadDocument = (path, contentType, docName, callback) => {
  const task = firebase
    .storage()
    .ref()
    .child('chat_media')
    .child('documents')
    .child(docName)
    .putString(path, 'base64', { contentType: contentType });
  task.on('state_changed', (taskSnapshot) => {
    callback(null, null, taskSnapshot.totalBytes, taskSnapshot.bytesTransferred);
  });
  task
    .then(() => {
      firebase
        .storage()
        .ref()
        .child('chat_media')
        .child('documents')
        .child(docName)
        .getDownloadURL()
        .then((downloadUrl) => {
          callback(downloadUrl, null, null, null);
        })
        .catch((error) => {
          callback(null, error, null, null);
        });
    })
    .catch((error) => {
      callback(null, error, null, null);
    });
};

export const deleteGroup = (groupId, callback) => {
  fetchGroupDetailByIdOnce(groupId, (isGroupExist, groupModel) => {
    if (isGroupExist) {
      let i = Object.keys(groupModel.members).length;
      Object.values(groupModel.members).forEach((element) => {
        getNodeReference(FIREBASE_DB_NODES.USERS)
          .child(element.uid)
          .child('group')
          .child(groupId)
          .set(false)
          .then(() => {
            i--;
            if (i <= 0) {
              getNodeReference(FIREBASE_DB_NODES.GROUP)
                .child(groupId)
                .child('group_deleted')
                .set(true)
                .then(() => {
                  callback();
                });
            }
          });
      });
    }
  });
};

export const changeAdminStatusOfUser = (groupId, userId, isAdmin, callback) => {
  getNodeReference(FIREBASE_DB_NODES.GROUP)
    .child(groupId)
    .child('members')
    .child(userId)
    .child('admin')
    .set(isAdmin)
    .then(() => {
      callback();
    });
};

export const chatIsToday = (milliseconds) => {
  const date = new Date(milliseconds).toDateString();
  const todayDate = new Date().toDateString();
  if (todayDate === date) {
    return true;
  } else {
    return false;
  }
};

export const chatFormatDateDDMonthYYYY = (milliseconds) => {
  const dateObject = new Date(milliseconds);
  const formattedDateList = dateObject.toDateString().split(' ');
  const formattedDate =
    formattedDateList[2] + ' ' + formattedDateList[1] + ' ' + formattedDateList[3];
  return formattedDate;
};

export const chatFormatTimeAMPM = (milliseconds) => {
  let inputValue = new Date(milliseconds);
  inputValue = inputValue.toTimeString().split(' ')[0];
  let hours = Number(inputValue.slice(0, 2));
  let minutes = Number(inputValue.slice(3, 5));
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  let strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
};

export const uploadImage = (blob, callback) => {
  const date = new Date().getTime();
  const task = firebase
    .storage()
    .ref()
    .child('chat_media')
    .child('images')
    .child(`${date}_image`)
    .put(blob);
  task.on('state_changed', (taskSnapshot) => {
    callback(null, null, taskSnapshot.totalBytes, taskSnapshot.bytesTransferred);
  });
  task
    .then(() => {
      firebase
        .storage()
        .ref()
        .child('chat_media')
        .child('images')
        .child(`${date}_image`)
        .getDownloadURL()
        .then((downloadUrl) => {
          callback(downloadUrl, null, null, null);
        })
        .catch((error) => {
          callback(null, error, null, null);
        });
    })
    .catch((error) => {
      callback(null, error, null, null);
    });
};

export const chatIsYesterday = (milliseconds) => {
  const date = new Date(milliseconds);
  const yesterdayDate = new Date();
  yesterdayDate.setDate(yesterdayDate.getDate() - 1);
  if (yesterdayDate.toDateString() === date.toDateString()) {
    return true;
  } else {
    return false;
  }
};

export const fetchUserGroupsByIdOnce = (uid, callback) => {
  getNodeReference(FIREBASE_DB_NODES.USERS)
    .child(uid)
    .child('group')
    .once('value', (snapshot) => {
      if (snapshot.exists()) {
        const groups = snapshot.val();
        let i = Object.keys(groups).length;
        let sGroupMap = {};
        for (const [key, value] of Object.entries(groups)) {
          if (value) {
            getNodeReference(FIREBASE_DB_NODES.GROUP)
              .child(key)
              .once('value', (snapshot2) => {
                if (snapshot2.exists()) {
                  const groupModel = snapshot2.val();
                  if (groupModel.group_deleted === false) {
                    sGroupMap[groupModel.groupId] = groupModel;
                  }
                }
                i--;
                if (i <= 0) {
                  callback(true, sGroupMap);
                }
              });
          } else {
            i--;
          }
        }
        if (i <= 0) {
          callback(false, null);
        }
      } else {
        callback(false, null);
      }
    });
};

export const deleteMessage = (groupId, messageData, callback) => {
  getNodeReference(FIREBASE_DB_NODES.GROUP)
    .child(groupId)
    .child('lastMessage')
    .once('value', (snapshot) => {
      if (snapshot.exists()) {
        const lastMessageData = snapshot.val();
        if (lastMessageData.message_id === messageData.message_id) {
          getNodeReference(FIREBASE_DB_NODES.GROUP)
            .child(groupId)
            .child('lastMessage')
            .update({ is_deleted: true, is_edited: false })
            .then(() => {});
        }
      }
    });
  getNodeReference(FIREBASE_DB_NODES.MESSAGES)
    .child(groupId)
    .child(messageData.message_id)
    .update({ is_deleted: true, is_edited: false })
    .then(() => callback());
};

export const editMessage = (groupId, messageData, editedText, callback) => {
  getNodeReference(FIREBASE_DB_NODES.GROUP)
    .child(groupId)
    .child('lastMessage')
    .once('value', (snapshot) => {
      if (snapshot.exists()) {
        const lastMessageData = snapshot.val();
        if (lastMessageData.message_id === messageData.message_id) {
          getNodeReference(FIREBASE_DB_NODES.GROUP)
            .child(groupId)
            .child('lastMessage')
            .update({ is_edited: true, message: editedText })
            .then(() => {});
        }
      }
    });
  getNodeReference(FIREBASE_DB_NODES.MESSAGES)
    .child(groupId)
    .child(messageData.message_id)
    .update({ is_edited: true, message: editedText })
    .then(() => callback());
};
