import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import CustomLinkify from '../../../common/ui/custom_linkify/CustomLinkify';

import { chatFormatTimeAMPM, fetchUserByIdOnce } from '../../../utils/ChatHandler';

const PreviousTextModal = (props) => {
  const { isDialogVisible, setIsDialogVisible, item } = props;
  const [userName, setUserName] = useState('');
  useEffect(() => {
    if (item.reply_forward_params) {
      fetchUserByIdOnce(item.reply_forward_params.sender_id, (isExist, user) => {
        if (isExist) {
          setUserName(user.name);
        } else {
          setUserName('');
        }
      });
    }
  }, [item]);

  return (
    <Modal
      onHide={() => setIsDialogVisible((val) => !val)}
      show={isDialogVisible}
      centered
      backdrop="static"
      keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title />
      </Modal.Header>
      <Modal.Body className="px-4 py-3">
        <div>
          {item.reply_forward_params &&
            (!item.reply_forward_params.message_type ||
              item.reply_forward_params.message_type === '1') && (
              <div>
                <div className="d-flex align-items-center justify-content-between mb-2">
                  {userName ? <div className="chat-bold-text">{userName}</div> : <div />}
                  <div className="chat-grey-text chat-base-text-sm">
                    {item.reply_forward_params
                      ? chatFormatTimeAMPM(item.reply_forward_params.timestamp)
                      : ''}
                  </div>
                </div>
                {item.reply_forward_params &&
                (!item.reply_forward_params.message_type ||
                  item.reply_forward_params.message_type === '1') ? (
                  <CustomLinkify>
                    <div className="common-word-break-all">{item.reply_forward_params.message}</div>
                  </CustomLinkify>
                ) : null}
              </div>
            )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PreviousTextModal;
