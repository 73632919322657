import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../common/constants/AppConstants';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../state/UIState';
import {
  formatDateDDMonthYYYY,
  formatSecondsIntoMinutes,
  formatSecondsIntoTimeForVideos,
  getUrlWithSpecificRendition,
} from '../../utils/common';
import { ARTICLES, INCREASE_VIEW_COUNT_EXTERNAL_LINK } from '../../services/ApiUrls';
import { apiRequest } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { selectBtoTitles } from '../../state/MasterData';
import BackButton from '../../common/ui/common_back_button/BackButton';
import PlayIconOverlay from '../../common/ui/play_icon_overlay/PlayIconOverlay';

const BtoSearchScreen = ({ history, location }) => {
  const dispatch = useDispatch();
  const [searchList, setSearchList] = useState([]);
  const loadMoreFlag = useRef(false);
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  const pageId = useRef(1);
  const [searchText, setSearchText] = useState(location?.state?.searchQuery ?? '');
  const accessibilityData = useSelector(selectAccessibilityData);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const btoTabs = useSelector(selectBtoTitles);
  const [isSearchEnabled, setIsSearchEnabled] = useState(true);

  const callInitiativeSearchApi = useCallback(
    async (text) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMoreFlag.current) {
        setLoadMoreBtn(true);
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        try {
          const params = {
            page_id: pageId.current,
            query: text,
            channel_id: btoTabs[0].channel_id,
          };
          const apiResponse = await apiRequest(ARTICLES, REQUEST_TYPE.GET, params);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                setSearchList((oldArray) => [...oldArray, ...apiResponse.response.data.feeds]);
                loadMoreFlag.current = true;
                pageId.current = pageId.current + 1;
              } else {
                loadMoreFlag.current = false;
                setLoadMoreBtn(false);
              }
            }
          }
        } catch (err) {
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      }
    },
    [dispatch]
  );

  const callSubmitFunction = (event) => {
    event?.preventDefault();
    if (searchText !== '') {
      setSearchList([]);
      pageId.current = 1;
      loadMoreFlag.current = true;
      callInitiativeSearchApi(searchText);
    }
  };

  const onPressItem = async (item) => {
    if (item.content_load_type === '1') {
      try {
        const es = await apiRequest(
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          REQUEST_TYPE.GET,
          {}
        );
      } catch (err) {
        //ignore if the view count request is not successful
        console.warn(err);
      }
      window.open(item.url);
    } else {
      history.push(`${NAVIGATION_ROUTES.BTO_DETAIL}/${item.article_id}`);
    }
  };

  const onPressLoadMore = () => {
    callInitiativeSearchApi(searchText);
  };

  const onTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const resetValue = () => {
    history.goBack();
  };

  const onClickSearchHandler = () => {
    setIsSearchEnabled(true);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.SEARCH_NO_RESULTS} />;
  };

  const renderItem = (item) => {
    return (
      <div
        key={`${item.article_id}`}
        className="col-sm-4 mb-2 common-cursor-pointer"
        onClick={() => onPressItem(item)}>
        <div>
          {item?.content_load_type === '3' && <PlayIconOverlay />}
          <img
            src={getUrlWithSpecificRendition(
              item.image.base_url,
              SCREEN_DENSITY_QUALIFIERS.HDPI,
              item.image.image_path
            )}
            className="embed-responsive embed-responsive-16by9"
          />
          <div className="mt-1 d-flex align-items-center">
            <div className="d-flex flex-row justify-content-between w-100 pr-3">
              {!!item?.time_to_read && (
                <div className="broadcast-event-date-new ">
                  {item.time_to_read != 0 ? formatSecondsIntoMinutes(item.time_to_read) : null}
                </div>
              )}
              {!!item?.video_duration &&
                item?.content_load_type === '3' &&
                item?.video_duration > 0 && (
                  <div className="broadcast-event-date-new ">
                    {formatSecondsIntoTimeForVideos(item?.video_duration)}
                  </div>
                )}
              <div className="broadcast-event-date-new ">
                {formatDateDDMonthYYYY(item.pub_datetime)}
              </div>
              {!!item.page_views && (
                <div className="broadcast-event-date-new ">
                  {item.page_views > 1 ? `${item.page_views} views` : `${item.page_views} view`}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="microbiz-title">{item.title}</div>
      </div>
    );
  };

  useEffect(() => {
    if (searchText) {
      callSubmitFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-25">
        <div
          className={
            isSearchEnabled ? 'search-bar-with-icon search-bar-border' : 'search-bar-with-icon'
          }>
          {isSearchEnabled ? (
            <form className="directory-search-bar" onSubmit={callSubmitFunction}>
              <input
                autoFocus
                className="ml-2 directory-search-bar"
                type="text"
                placeholder="Search.."
                onChange={onTextChange}
                value={searchText}
              />
            </form>
          ) : (
            <div className="directory-search-bar" />
          )}
          {isSearchEnabled ? (
            <div className="gcdo-icon-row mr-2" onClick={resetValue}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
                )}
                className="header-icon"
              />
            </div>
          ) : (
            <div className="gcdo-icon-row mr-2" onClick={onClickSearchHandler}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH
                )}
                className="header-icon"
              />
            </div>
          )}
        </div>
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.BTO} />
      <BackButton />
      <div className="header-view d-flex justify-content-between">
        <form className="microbiz-search-form" onSubmit={callSubmitFunction}>
          <input
            autoFocus
            className="ml-3 microbiz-search-bar"
            type="text"
            placeholder="Search.."
            onChange={onTextChange}
            value={searchText}
          />
        </form>
        <img
          src={getHeaderComponentUrl(
            accessibilityData,
            ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
          )}
          className="header-icon mr-3"
          onClick={resetValue}
        />
      </div>
      <div className="main-content-container">
        {showEmptyContentView && !searchList.length && !progressLoadingState.isProgressLoading ? (
          emptyListView()
        ) : (
          <div>
            <div className="row">{searchList.map((item) => renderItem(item))}</div>
            {loadMoreBtn && <LoadMoreBtn onClick={onPressLoadMore} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default BtoSearchScreen;
