import React from 'react';

const SttarEmployeeItem = (props) => {
  return (
    <div
      className="som-employee-view common-cursor-pointer"
      style={{ width: props.width }}
      onClick={props.navigateToEmployee}>
      <div className="som-emp-inner-view">
        <div>
          <div className="som-emp-image-outer">
            <img src={`${props.imageUri}`} alt="image" className="som-emp-image" />
          </div>
        </div>
      </div>
      <div className="som-star-employee-name-text som-emp-name-container">{props.name}</div>
    </div>
  );
};
export default SttarEmployeeItem;
