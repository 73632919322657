import React, { useEffect } from 'react';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import Divider from '../../common/ui/divider/Divider';
import HtmlView from '../../common/ui/html_view/HTMLView';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import InfoImageItem from './components/InfoImageItem';
import InfoLinksItem from './components/InfoLinksItem';

const BreakoutsInfoDetailScreen = ({ location, history }) => {
  const { item } = location.state;

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.BREAKOUTS_INFO_DETAIL_SCREEN,
      NAVIGATION_ROUTES.BREAKOUTS_INFO_DETAIL_SCREEN
    );
  }, []);

  const onPressLink = (linkItem) => {
    let win = window.open(linkItem.url, '_blank');
    win.focus();
  };

  const onPressPdf = (pdfItem) => {
    let win = window.open(pdfItem.url, '_blank');
    win.focus();
  };

  const onPressVideo = (videoItem) => {
    history.push(`${NAVIGATION_ROUTES.FULL_SCREEN_VIDEO}/${videoItem.url}`);
  };

  const renderYoutubeItem = (data, index) => {
    return <InfoLinksItem item={data} isYtUrls={true} onPress={onPressVideo} key={`${index}`} />;
  };

  const renderLinksItem = (data, index) => {
    return <InfoLinksItem item={data} isPdfUrls={false} onPress={onPressLink} key={`${index}`} />;
  };

  const renderPdfItem = (data, index) => {
    return <InfoLinksItem item={data} isPdfUrls={true} onPress={onPressPdf} key={`${index}`} />;
  };

  const renderImageItem = (data, index) => {
    return <InfoImageItem item={data} baseUrl={item.gallery.base_url} key={`${index}`} />;
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.BREAKOUTS} />
      <BackButton />
      <div className="main-content-container">
        <HtmlView html={item.description} htmlStyle="breakout-content-html" />
        {(item.media.pdf.length > 0 ||
          item.media.text.length > 0 ||
          item.media.youtube.length > 0) && (
          <>
            <Divider style="mt-3 mb-3" />
            {item.media.text.length > 0 && (
              <>
                <p className="breakouts-info-title mt-3 mb-3">Media</p>
                {item.media.text.map((data, index) => renderLinksItem(data, index))}
              </>
            )}
            {item.media.pdf.length > 0 && (
              <>
                <p className="breakouts-info-title mt-3 mb-3">Documents</p>
                {item.media.pdf.map((data, index) => renderPdfItem(data, index))}
              </>
            )}
            {item.media.youtube.length > 0 && (
              <>
                <p className="breakouts-info-title mt-3 mb-3">Video</p>

                {item.media.youtube.map((data, index) => renderYoutubeItem(data, index))}
              </>
            )}
          </>
        )}
        {item.gallery.pictures.length > 0 && (
          <>
            <Divider style="mt-3 mb-3" />
            <p className="breakouts-info-title mt-4 mb-3">Images</p>
            <div className="container ml-n3 mr-0">
              <div className="row">
                {item.gallery.pictures.map((data, index) => renderImageItem(data, index))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BreakoutsInfoDetailScreen;
