import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import { showToast } from '../../../../utils/common';
import { apiRequest, handleError } from '../../../../services/Service';
import { UPDATE_CHECKLIST } from '../../../../services/ApiUrls';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../../state/UIState';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import ChecklistSectionItem from '../../components/ChecklistSectionItem';
import CustomButton from '../../../../common/ui/custom_button/CustomButton';

const LowerLevelsChecklistTab = ({
  data,
  setError,
  selectedChecklistItemCount,
  setSelectedChecklistItemCount,
  totalChecklistItemCountRef,
}) => {
  const dispatch = useDispatch();
  const progressLoadingState = useSelector(selectProgressLoadingState);

  const [selectedItems, setSelectedItems] = useState([]);

  const onPressChecklistSaveHandler = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.NEW_JOINEES_LOWER_LEVELS_CHECKLIST_SAVE,
      '',
      ANALYTICS_ITEM_NAMES.NEW_JOINEES_LOWER_LEVELS
    );
    callUpdateChecklistApi();
  };

  useEffect(() => {
    const tempSelectedItems = [];
    if (data) {
      data.map((checklistSection) =>
        checklistSection.items.map((checklistSectionItem) => {
          if (checklistSectionItem.checked) {
            tempSelectedItems.push(checklistSectionItem.item_id);
          }
        })
      );
    }

    setSelectedItems(tempSelectedItems);
  }, [data]);

  const renderChecklistSectionItem = (data) => {
    return (
      <ChecklistSectionItem
        key={data.item_id}
        data={data}
        selectedItems={selectedItems}
        onSelectHandler={setSelectedItems}
      />
    );
  };

  const renderChecklistSection = (data) => {
    return (
      <div className="p-1" key={data.checklist_id}>
        <p className="new-joinees-lower-levels-checklists-section-title">{data.title}</p>
        <div>{data.items.map((checklistItem) => renderChecklistSectionItem(checklistItem))}</div>
      </div>
    );
  };

  const callUpdateChecklistApi = async () => {
    const params = new URLSearchParams();
    params.append('items', selectedItems.join());
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(UPDATE_CHECKLIST, REQUEST_TYPE.POST, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          const message = apiResponse.response.message;
          if (message) {
            showToast(message);
            setSelectedChecklistItemCount(selectedItems.length);
          }
        }
      }
    } catch (err) {
      handleError(err, params, UPDATE_CHECKLIST, NAVIGATION_ROUTES.NEW_JOINEES_LOWER_LEVELS);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      setError(true);
    }
  };

  const loadChecklistProgress = useCallback(() => {
    let tempSelectedChecklistItemCount = 0;
    let tempTotalChecklistItemCount = 0;
    data.map((checklistSection) =>
      checklistSection.items.map((checklistSectionItem) => {
        if (checklistSectionItem.checked) {
          tempSelectedChecklistItemCount += 1;
        }
        tempTotalChecklistItemCount += 1;
      })
    );
    totalChecklistItemCountRef.current = tempTotalChecklistItemCount;
    setSelectedChecklistItemCount(tempSelectedChecklistItemCount);
  }, [data, setSelectedChecklistItemCount, totalChecklistItemCountRef]);

  useEffect(() => {
    if (data) {
      loadChecklistProgress();
    }
  }, [data, loadChecklistProgress]);

  if (data) {
    return (
      <div>
        <div className="header-view d-flex justify-content-between pt-0 mb-3">
          <div className="new-joinees-lower-levels-checklists-progress-text">
            {selectedChecklistItemCount} / {totalChecklistItemCountRef.current} Completed
          </div>
          <CustomButton
            showLoader={progressLoadingState.isProgressLoading}
            onClick={onPressChecklistSaveHandler}>
            SAVE
          </CustomButton>
        </div>
        {data.map((checklistSection) => renderChecklistSection(checklistSection))}
      </div>
    );
  } else {
    return null;
  }
};

export default LowerLevelsChecklistTab;
