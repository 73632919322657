import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import Divider from '../../../common/ui/divider/Divider';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { getUrlWithSpecificRendition } from '../../../utils/common';
import EpisodesListItem from './EpisodesListItem';
import { PODCAST_UPDATE_PLAY_COUNT } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { useDispatch, useSelector } from 'react-redux';
import { saveProgressLoadingState, saveSearchText } from '../../../state/UIState';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';

const PodcastContent = (props) => {
  const { channelId, channelData } = props;
  const history = useHistory();
  const [text, setText] = useState('See more');
  const dispatch = useDispatch();
  const employeeData = useSelector(selectEmployeeData);

  const onPressRead = () => {
    if (text === 'See more') {
      setText('See less');
    } else {
      setText('See more');
    }
  };

  const onPress = (item, isPlay) => {
    if (isPlay) {
      callUpdatePlayCountApi(item.episode_id);
    } else {
      history.push(`${NAVIGATION_ROUTES.PODCASTS_EPISODE_DETAIL}/${item.episode_id}`, {
        isPlay: false,
        channelTitle: channelData.title,
      });
    }
  };

  const renderItem = (item, index) => {
    return (
      <EpisodesListItem
        onPress={onPress}
        item={item}
        key={`${index}`}
        index={channelData.episodes.length - index}
      />
    );
  };

  const callUpdatePlayCountApi = useCallback(
    async (episodeId) => {
      const params = { episode_id: episodeId };
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
          })
        );
        const apiResponse = await apiRequest(PODCAST_UPDATE_PLAY_COUNT, REQUEST_TYPE.GET, params);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.message) {
              history.push(`${NAVIGATION_ROUTES.PODCASTS_EPISODE_DETAIL}/${episodeId}`, {
                isPlay: true,
              });
            }
          }
        }
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      } catch (err) {
        handleError(err, params, PODCAST_UPDATE_PLAY_COUNT, NAVIGATION_ROUTES.PODCASTS_CONTENT);
        //
      }
    },
    [channelId, history]
  );

  const goToProfile = () => {
    if (channelData.host_profile.employee_id) {
      if (channelData.host_profile.employee_id === employeeData.employee_id) {
        history.push(NAVIGATION_ROUTES.MY_PROFILE_DETAIL);
      } else {
        history.push(
          `${NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL}/${channelData.host_profile.employee_id}`
        );
      }
    }
  };

  const handleHtmlClicks = (e) => {
    const target = e.target.getAttribute('href');
    if (target) {
      if (target.startsWith('/') || target.startsWith('?')) {
        if (target.includes(NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL)) {
          logEvent(
            ANALYTICS_EVENT_TYPES.USER_MENTION_CLICK,
            e?.target?.innerText,
            ANALYTICS_ITEM_NAMES.USER_MENTION
          );
          e.stopPropagation();
          e.preventDefault();
          history.push(target);
        } else {
          logEvent(
            ANALYTICS_EVENT_TYPES.HASHTAG_CLICK,
            e?.target?.innerText,
            ANALYTICS_ITEM_NAMES.HASHTAG
          );
          dispatch(saveSearchText(''));
          e.preventDefault();
          history.push(`${NAVIGATION_ROUTES.PODCASTS_SEARCH}${target}`);
        }
      } else {
        e.stopPropagation();
        e.preventDefault();
        window.open(target, '_blank');
      }
    }
  };

  return (
    <div>
      {Object.keys(channelData).length > 0 && (
        <div>
          <div className="row">
            <div className="col-sm-4">
              <img
                src={getUrlWithSpecificRendition(
                  channelData.promo_image.base_url,
                  SCREEN_DENSITY_QUALIFIERS.HDPI,
                  channelData.promo_image.image_path
                )}
                className="embed-responsive embed-responsive-16by9"
              />
            </div>
            <div className="col-sm-8">
              <div className="podcast-title">{channelData.title}</div>
              {text === 'See more' ? (
                <div className="mt-2 podcast-description common-ws-pre-line">
                  {channelData.description.length > 200 ? (
                    <>
                      <HtmlView
                        onClick={handleHtmlClicks}
                        html={channelData.description.substring(0, 200) + '...'}
                        htmlStyle="mt-3 podcast-content-html"
                      />
                      <span
                        className="common-cursor-pointer podcast-see-more-less"
                        onClick={onPressRead}>{`${text}`}</span>
                    </>
                  ) : (
                    <HtmlView
                      onClick={handleHtmlClicks}
                      html={channelData.description}
                      htmlStyle="mt-3 podcast-content-html"
                    />
                  )}
                </div>
              ) : (
                <div className="mt-2 podcast-description common-ws-pre-line">
                  <HtmlView
                    html={channelData.description}
                    onClick={handleHtmlClicks}
                    htmlStyle="mt-3 podcast-content-html"
                  />
                  <span
                    className="common-cursor-pointer podcast-see-more-less"
                    onClick={onPressRead}>{`${text}`}</span>
                </div>
              )}
              <div className="mt-3">
                <div className="podcast-title">Host</div>
                <div className="d-flex align-items-center mt-2">
                  <UserProfilePhoto
                    imageBaseUrl={channelData.host_profile.profile_picture.base_url}
                    imagePath={channelData.host_profile.profile_picture.image_path}
                    employeeId={channelData.host_profile.employee_id}
                    imageClass="common-user-profile-photo-md"
                  />
                  <div className="ml-2" onClick={goToProfile}>
                    <div className="d-flex align-item-center">
                      <p className="podcast-host-name mb-0">{`${channelData.host_profile.first_name} ${channelData.host_profile.last_name}`}</p>
                      {!!channelData.host_profile.pronouns && (
                        <p className="podcast-host-pronoun mb-0 ml-1 align-self-center">
                          {'(' + channelData.host_profile.pronouns + ')'}
                        </p>
                      )}
                    </div>
                    <div className="podcast-host-location">
                      {channelData.host_profile.geography.location.location_title}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Divider style="mt-2" />
          <div className="mt-3 podcast-title">Episodes</div>
          {channelData.episodes.length > 0 &&
            channelData.episodes.map((data, index) => renderItem(data, index))}
        </div>
      )}
    </div>
  );
};

export default PodcastContent;
