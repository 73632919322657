import React, { useEffect, useState } from 'react';
import RulesTab from './tabs/digitalWarriorsTabs/RulesTab';
import ContestsDetailTab from './tabs/contestDetailTab/ContestsDetailTab';
import MakeDigitalHappenTab from './tabs/contestDetailTab/MakeDigitalHappenTab';
import NotWithoutYouTab from './tabs/contestDetailTab/NotWithoutYouTab';
import { Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveAnniversaryContestTabData,
  selectAnniversaryContestTabData,
} from '../../state/TabData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import NavTabs from '../../common/ui/tabs/NavTabs';
import BackButton from '../../common/ui/common_back_button/BackButton';

const ContestsDetailScreen = ({ location }) => {
  const dispatch = useDispatch();
  const { identifier, title, tabs, contestId } = location.state;

  const tempRoutes = [];
  tabs.forEach((item) => {
    tempRoutes.push({ key: `${item.key}`, title: item.title.toUpperCase() });
  });
  const [routes] = useState(tempRoutes);
  const contestTabData = useSelector(selectAnniversaryContestTabData);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.CONTESTS_DETAIL_SCREEN,
      NAVIGATION_ROUTES.CONTESTS_DETAIL_SCREEN
    );
  }, []);

  useEffect(() => {
    if (routes.length && contestTabData === '') {
      dispatch(saveAnniversaryContestTabData(routes[0].key));
    }
  }, [contestTabData, dispatch, routes]);

  const renderTabs = (tab, index) => {
    switch (tab.key) {
      case 'whatsYourConnection':
        return <ContestsDetailTab identifier={identifier} contestId={contestId} title={title} />;
      case 'makingDigitalHappen':
        return <MakeDigitalHappenTab identifier={identifier} contestId={contestId} title={title} />;
      case 'notWithoutYou':
        return <NotWithoutYouTab identifier={identifier} contestId={contestId} title={title} />;
      case 'rules':
        return <RulesTab identifier={identifier} terms={tabs[index].contest_terms} />;
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY} />
      <BackButton />
      <NavTabs
        activeKey={contestTabData}
        onSelect={(k) => dispatch(saveAnniversaryContestTabData(k))}>
        {routes.map((tab, index) => {
          return (
            <Tab key={`${index}`} eventKey={tab.key} title={tab.title}>
              {renderTabs(tab, index)}
            </Tab>
          );
        })}
      </NavTabs>
    </div>
  );
};

export default ContestsDetailScreen;
