import React from 'react';
import { logEventFilters } from '../../../../utils/FirebaseAnalyticsUtils';
import { ANALYTICS_EVENT_TYPES } from '../../../constants/AppConstants';

const MultiSelectButton = (props) => {
  const selectedItems = props.selectedItems;
  const childSelectedItems = props.childSelectedItems;
  const childLookup = props.childLookup;
  const childLookupTag = props.childLookupTag;
  const childLookupParentTag = props.childLookupParentTag;
  const selectedTitles = props.selectedTitles;
  const onSelectHandler = props.onSelectHandler;
  const childSelectedItemsHandler = props.childSelectedItemsHandler;
  const filterTitlesHandler = props.filterTitlesHandler;
  const valueTitle = props.valueTitle;
  const valueId = props.valueId;
  const highLightedButtonStyle = props.highLightedButtonStyle;
  const highLightedTextStyle = props.highLightedTextStyle;
  const unhighLightedButtonStyle = props.unhighLightedButtonStyle;
  const unhighLightedTextStyle = props.unhighLightedTextStyle;
  const filterAnalyticsPrefix = props.filterAnalyticsPrefix;

  const removeAllElementsOfUnselectedParent = () => {
    let filteredChildSelectedTitles = selectedTitles.filter((title) => title !== valueTitle);
    const filteredChildSelectedItems = childSelectedItems.filter((childSelectedId) => {
      const foundParentReferenceNode = childLookup.find(
        (childLookupNode) => childLookupNode[childLookupTag] === childSelectedId
      );
      if (foundParentReferenceNode && foundParentReferenceNode[childLookupParentTag] === valueId) {
        filteredChildSelectedTitles = filteredChildSelectedTitles.filter(
          (title) => title !== foundParentReferenceNode.title
        );
        return false;
      } else {
        return true;
      }
    });
    childSelectedItemsHandler(filteredChildSelectedItems);
    filterTitlesHandler(filteredChildSelectedTitles);
  };

  return (
    <div>
      <div
        onClick={() => {
          if (selectedItems.includes(valueId)) {
            onSelectHandler(selectedItems.filter((id) => id !== valueId));
            filterTitlesHandler(selectedTitles.filter((title) => title !== valueTitle));
            if (childSelectedItems) {
              removeAllElementsOfUnselectedParent();
            }
          } else {
            onSelectHandler([...selectedItems, valueId]);
            filterTitlesHandler([...selectedTitles, valueTitle]);
            logEventFilters(
              ANALYTICS_EVENT_TYPES.FILTER_SELECTED,
              '',
              filterAnalyticsPrefix + '_' + valueTitle.toString().toUpperCase().replace(/ /g, '_')
            );
          }
        }}>
        <div
          className={
            selectedItems.includes(valueId) ? highLightedButtonStyle : unhighLightedButtonStyle
          }>
          <h5
            className={
              selectedItems.includes(valueId) ? highLightedTextStyle : unhighLightedTextStyle
            }>
            {valueTitle}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default MultiSelectButton;
