import { call, put, takeEvery } from 'redux-saga/effects';
import { ACTION_TYPES, REQUEST_TYPE } from '../common/constants/AppConstants';
import { GET_REQUEST_BY_ID, GET_TRAVEL_REQUEST_MASTERS } from '../services/ApiUrls';
import { apiRequest } from '../services/Service';
import {
  saveTravelRequestData,
  saveTravelRequestMasterData,
} from '../state/TravelRequestMasterData';
import { saveProgressLoadingState } from '../state/UIState';

export function* getTravelRequestMasterData(action) {
  try {
    yield put(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    const apiResponse = yield call(
      apiRequest,
      GET_TRAVEL_REQUEST_MASTERS,
      REQUEST_TYPE.GET,
      action.payload
    );
    yield put(
      saveProgressLoadingState({
        isProgressLoading: false,
      })
    );
    if (apiResponse) {
      if (apiResponse.response.status === true) {
        if (Object.keys(apiResponse.response.data).length > 0) {
          yield put(saveTravelRequestMasterData(apiResponse.response.data));
        }
      }
    }
  } catch (e) {
    yield put(
      saveProgressLoadingState({
        isProgressLoading: false,
      })
    );
    yield put(saveTravelRequestMasterData({}));
  }
}

export function* watchTravelRequestMasterData() {
  yield takeEvery(ACTION_TYPES.GET_TRAVEL_REQUEST_MASTER_DATA, getTravelRequestMasterData);
}

export function* getTravelRequestData(action) {
  yield put(
    saveProgressLoadingState({
      isProgressLoading: true,
    })
  );
  try {
    const apiResponse = yield call(apiRequest, GET_REQUEST_BY_ID, REQUEST_TYPE.GET, action.payload);
    yield put(
      saveProgressLoadingState({
        isProgressLoading: false,
      })
    );
    if (apiResponse) {
      if (apiResponse.response.status === true) {
        if (Object.keys(apiResponse.response.request).length > 0) {
          yield put(saveTravelRequestData(apiResponse.response.request));
        }
      }
    }
  } catch (e) {
    yield put(
      saveProgressLoadingState({
        isProgressLoading: false,
      })
    );
    yield put(saveTravelRequestData({}));
  }
}

export function* watchTravelRequestData() {
  yield takeEvery(ACTION_TYPES.GET_TRAVEL_REQUEST_DATA, getTravelRequestData);
}
