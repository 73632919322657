import React from 'react';
import HtmlView from '../../../common/ui/html_view/HTMLView';

const SelfListItem = ({ item, onRadioButtonPressed, index, selectedIndex }) => {
  return (
    <div className="form-check mt-2">
      <input
        type="radio"
        className="form-check-input"
        checked={index === selectedIndex ? true : false}
        onChange={() => {
          onRadioButtonPressed(item, index);
        }}
      />
      <label className="form-check-label cheers-self-desc-text">
        <HtmlView html={item.description} />
      </label>
    </div>
  );
};

export default SelfListItem;
