import React, { useState, useEffect, useCallback } from 'react';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  FEATURE_TYPE,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../../../services/Service';
import { GET_LEADER_BOARD, SUBMIT_LIKE_LEADERS_BOARD } from '../../../../services/ApiUrls';
import { useDispatch, useSelector } from 'react-redux';
import LeadersBoardListItem from '../../components/LeadersBoardListItem';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectShowEmptyContentView,
} from '../../../../state/UIState';
import { useHistory } from 'react-router';
import DefaultContentView from '../../../../common/ui/default_content_view/DefaultContentView';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../../common/ui/activity_loader/ActivityLoader';
import { selectAccessData } from '../../../../state/UserAccessData';

const NominatorsTab = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [cheersList, setCheersList] = useState([]);
  const [employee, setEmployee] = useState({});
  const [loading, setLoading] = useState(true);

  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const accessData = useSelector(selectAccessData);
  const [showLikeButton, setShowLikeButton] = useState(true);
  const [showCommentButton, setShowCommentButton] = useState(true);
  const cheersAccessData = accessData?.data?.find((item) => item.feature_key === '_cheers');

  useEffect(() => {
    if (cheersAccessData) {
      const actions = cheersAccessData?.actions;
      setShowLikeButton(actions?.includes('_like'));
      setShowCommentButton(actions?.includes('_comment'));
    }
  }, [cheersAccessData]);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.NOMINATORS_TAB_VISIT, '', ANALYTICS_ITEM_NAMES.CHEERS);
  }, []);

  const callLeadersBoardApi = useCallback(async () => {
    dispatch(saveShowEmptyContentView(true));
    const params = {
      type: 'nominators',
    };
    try {
      setLoading(true);
      const apiResponse = await apiRequest(GET_LEADER_BOARD, REQUEST_TYPE.GET, params);
      setLoading(false);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setEmployee(apiResponse.response.data.employee);
          if (apiResponse.response.data.leaderboard.length > 0) {
            setCheersList((oldArray) => [...oldArray, ...apiResponse.response.data.leaderboard]);
          }
        }
      }
    } catch (err) {
      handleError(err, params, GET_LEADER_BOARD, NAVIGATION_ROUTES.CHEERS_SCREEN);
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    callLeadersBoardApi();
  }, [callLeadersBoardApi]);

  const updateLikeCount = (topperId) => {
    let arr = cheersList;
    let oldCount = arr.find((element) => element.topper_id === topperId).likes.count;
    let objIndex = arr.findIndex((element) => element.topper_id === topperId);
    let isLiked = arr[objIndex].likes.is_liked;
    arr[objIndex].likes.count = isLiked ? oldCount - 1 : oldCount + 1;
    arr[objIndex].likes.is_liked = !isLiked;
    setCheersList([...arr]);
  };

  const updateLikeCountEmployee = () => {
    let arr = employee;
    let isLiked = arr.likes.is_liked;
    arr.likes.count = isLiked ? arr.likes.count - 1 : arr.likes.count + 1;
    arr.likes.is_liked = !isLiked;
    setEmployee({ ...arr });
  };

  const callLikeApi = async (topperId, type, empType) => {
    const params = new URLSearchParams();
    params.append('type', type);
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: 'Updating Like Count...',
        })
      );
      const apiResponse = await apiRequest(
        SUBMIT_LIKE_LEADERS_BOARD + topperId + '/like',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (empType === 'listNominators') {
            updateLikeCount(topperId);
          } else {
            updateLikeCountEmployee(topperId);
          }
        }
      }
    } catch (err) {
      handleError(
        err,
        params,
        SUBMIT_LIKE_LEADERS_BOARD + topperId + '/like',
        NAVIGATION_ROUTES.CHEERS_SCREEN
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressLikeButton = (item, likeType = 'listNominators') => {
    logEvent(ANALYTICS_EVENT_TYPES.NOMINATIONS_LIKE, item.topper_id, ANALYTICS_ITEM_NAMES.LIKE);
    callLikeApi(item.topper_id, FEATURE_TYPE.CHEERS_NOMINATORS, likeType);
  };

  const onPressCommentButton = (item, likeType = 'listNominators') => {
    logEvent(
      ANALYTICS_EVENT_TYPES.NOMINATIONS_COMMENT,
      item.topper_id,
      ANALYTICS_ITEM_NAMES.COMMENT
    );
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: item.topper_id,
      likeType: FEATURE_TYPE.CHEERS_NOMINATORS,
      fromRoute: NAVIGATION_ROUTES.CHEERS_SCREEN,
      type: likeType,
    });
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_DATA_FOUND} />;
  };

  const header = () => {
    return <div className="cheers-reward-count mb-3">Top 10 Nominators</div>;
  };

  return (
    <div className="main-content-container">
      <ActivityLoader visible={loading} />
      <div>{header()}</div>
      {showEmptyContentView && !cheersList.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          {cheersList.map((item, index) => {
            return (
              <LeadersBoardListItem
                showLikeButton={showLikeButton}
                showCommentButton={showCommentButton}
                item={item}
                onPressCommentButton={onPressCommentButton}
                onPressLikeButton={onPressLikeButton}
                key={`${index}`}
                onPressLikedBy={(item) => {
                  history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
                    referId: item.topper_id,
                    parentType: FEATURE_TYPE.CHEERS_NOMINATORS,
                  });
                }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default React.memo(NominatorsTab);
