import { createSlice } from '@reduxjs/toolkit';

const saveMicrogiveTabDataReducer = (state, action) => {
  state.microgiveMainScreenTab = action.payload;
};

const saveMicrogiveProjectsTabDataReducer = (state, action) => {
  state.microgiveProjectsTab = action.payload;
};

const saveMicrogiveDonationCategoryIdReducer = (state, action) => {
  state.microgiveDonationCategoryId = action.payload;
};

const saveMicrogiveVolunteerCategoryIdReducer = (state, action) => {
  state.microgiveVolunteerCategoryId = action.payload;
};

const savePoliciesTabDataReducer = (state, action) => {
  state.policiesTab = action.payload;
};

const saveBreakoutsTabDataReducer = (state, action) => {
  state.breakoutsTab = action.payload;
};

const saveBreakoutsSessionTabDataReducer = (state, action) => {
  state.breakoutsSessionTab = action.payload;
};

const saveCheersTabDataReducer = (state, action) => {
  state.cheersTab = action.payload;
};

const saveNewjoineeTabDataReducer = (state, action) => {
  state.newjoineeTab = action.payload;
};

const saveOverviewTabDataReducer = (state, action) => {
  state.overviewTab = action.payload;
};

const saveChecklistTabDataReducer = (state, action) => {
  state.checklistTab = action.payload;
};

const saveQuicklinksTabDataReducer = (state, action) => {
  state.quicklinksTab = action.payload;
};

const saveMyinductionTabDataReducer = (state, action) => {
  state.myinduction = action.payload;
};

const saveCheersLeadersBoardTabDataReducer = (state, action) => {
  state.cheersLeadersBoardTabData = action.payload;
};

const saveCheersMyCheersTabDataReducer = (state, action) => {
  state.cheersMyCheersTabData = action.payload;
};

const saveCheersMyPointsTabDataReducer = (state, action) => {
  state.cheersMyPointsTab = action.payload;
};

const saveCheersNominationRequestTabDataReducer = (state, action) => {
  state.cheersNominationRequestTabData = action.payload;
};

const saveAnniversaryTabDataReducer = (state, action) => {
  state.anniversaryTab = action.payload;
};

const saveAnniversaryMainTabDataReducer = (state, action) => {
  state.anniversaryMainTab = action.payload;
};

const saveAnniversaryDigitalWarriorTabDataReducer = (state, action) => {
  state.anniversaryDigitalWarriorTab = action.payload;
};

const saveAnniversaryContestTabDataReducer = (state, action) => {
  state.anniversaryContestTab = action.payload;
};

const saveAnniversaryGeoDataReducer = (state, action) => {
  state.anniversaryGeo = action.payload;
};

const savePollsSurveysTabDataReducer = (state, action) => {
  state.pollsSurveysTab = action.payload;
};

const saveBroadcastTabDataReducer = (state, action) => {
  state.broadcastTab = action.payload;
};

const saveEventTabDataReducer = (state, action) => {
  state.eventTab = action.payload;
};

const saveSomTabDataReducer = (state, action) => {
  state.somTab = action.payload;
};

const savePandemicSupportTabDataReducer = (state, action) => {
  state.pandemicSupportTab = action.payload;
};

const saveWomensdayTabDataReducer = (state, action) => {
  state.womensdayTab = action.payload;
};

const saveContestTabDataReducer = (state, action) => {
  state.contestTab = action.payload;
};
const saveMyProfileDetailTabDataReducer = (state, action) => {
  state.myProfileDetailTab = action.payload;
};

const saveMyProfileDetailEditTabDataReducer = (state, action) => {
  state.myProfileDetailEditTab = action.payload;
};

const saveGcdoTabDataReducer = (state, action) => {
  state.gcdoTab = action.payload;
};

const saveEsgTabDataReducer = (state, action) => {
  state.esgTab = action.payload;
};

const saveMlfTabDataReducer = (state, action) => {
  state.mlfTab = action.payload;
};

const saveMicrobizTabDataReducer = (state, action) => {
  state.microbizTab = action.payload;
};

const saveBtoTabDataReducer = (state, action) => {
  state.btoTab = action.payload;
};

const saveOurCustomerTabDataReducer = (state, action) => {
  state.ourCustomerTab = action.payload;
};

const saveOurCustomerAllTabDataReducer = (state, action) => {
  state.ourCustomerAllTab = action.payload;
};

const saveGalleryTabDataReducer = (state, action) => {
  state.galleryTab = action.payload;
};

const saveFacilityTabDataReducer = (state, action) => {
  state.facilityTab = action.payload;
};

const saveNewJoineesHigherLevelsTabDataReducer = (state, action) => {
  state.newJoineesHigherLevelsTabData = action.payload;
};

const saveCommunitiesTabDataReducer = (state, action) => {
  state.communitiesTab = action.payload;
};

const saveEcardsTabDataReducer = (state, action) => {
  state.ecardsTab = action.payload;
};

const saveGlobalSearchTabDataReducer = (state, action) => {
  state.globalSearchTab = action.payload;
};

const saveDiTabDataReducer = (state, action) => {
  state.diTab = action.payload;
};

const saveIjprTabDataReducer = (state, action) => {
  state.ijprTab = action.payload;
};

const savePodcastTabDataReducer = (state, action) => {
  state.podcastTab = action.payload;
};

const saveWallpaperTabDataReducer = (state, action) => {
  state.wallpaperTab = action.payload;
};

const saveTravelRequestTabDataReducer = (state, action) => {
  state.travelRequestTab = action.payload;
};

const saveIjprBookmarkTabDataReducer = (state, action) => {
  state.ijprBookmarkTab = action.payload;
};

const saveMiHrTabDataReducer = (state, action) => {
  state.miHRTab = action.payload;
};

const saveMiHRTabCategoryFilterIdReducer = (state, action) => {
  state.miHRTabCategoryFilterId = action.payload;
};

const saveEsgCategoryFilterIdReducer = (state, action) => {
  state.esgCategoryFilterId = action.payload;
};

const saveMlfCategoryFilterIdReducer = (state, action) => {
  state.mlfCategoryFilterId = action.payload;
};

const saveNewJoineesTabCategoryFilterIdReducer = (state, action) => {
  state.newJoineesTabCategoryFilterId = action.payload;
};

const saveNewJoineesTabCategoryFilterKeyReducer = (state, action) => {
  state.newJoineesTabCategoryFilterKey = action.payload;
};

const saveNewJoineesTabDataReducer = (state, action) => {
  state.newJoineesTabData = action.payload;
};

const saveNeoTabDataReducer = (state, action) => {
  state.neoTabData = action.payload;
};

const saveVariablePayTabDataReducer = (state, action) => {
  state.variablePayTab = action.payload;
};

const saveEStoreTabDataReducer = (state, action) => {
  state.eStoreTab = action.payload;
};

const resetAllTabsReducer = (state) => {
  state.microgiveMainScreenTab = '';
  state.microgiveProjectsTab = '';
  state.microgiveDonationCategoryId = 0;
  state.microgiveVolunteerCategoryId = 0;
  state.policiesTab = '';
  state.breakoutsTab = '';
  state.breakoutsSessionTab = '';
  state.cheersTab = '';
  state.cheersLeadersBoardTabData = '';
  state.cheersMyCheersTabData = '';
  state.cheersMyPointsTab = '';
  state.cheersNominationRequestTabData = '';
  state.anniversaryTab = '';
  state.anniversaryGeo = '';
  state.anniversaryDigitalWarriorTab = '';
  state.anniversaryContestTab = '';
  state.pollsSurveysTab = '';
  state.broadcastTab = '';
  state.eventTab = '';
  state.somTab = '';
  state.pandemicSupportTab = '';
  state.womensdayTab = '';
  state.contestTab = '';
  state.myProfileDetailTab = '';
  state.myProfileDetailEditTab = '';
  state.galleryTab = '';
  state.gcdoTab = '';
  state.ourCustomerTab = '';
  state.ourCustomerAllTab = '';
  state.facilityTab = '';
  state.newJoineesHigherLevelsTabData = '';
  state.communitiesTab = '';
  state.ecardsTab = '';
  state.globalSearchTab = '';
  state.diTab = '';
  state.ijprTab = '';
  state.microbizTab = '';
  state.btoTab = '';
  state.podcastTab = '';
  state.wallpaperTab = '';
  state.travelRequestTab = '';
  state.ijprBookmarkTab = '';
  state.saveMiHrTabData = '';
  state.miHRTabCategoryFilterId = 0;
  state.newJoineesTabCategoryFilterId = 0;
  state.newJoineesTabCategoryFilterKey = '';
  state.newJoineesTabData = '';
  state.neoTabData = '';
  state.esgCategoryFilterId = 0;
  state.mlfCategoryFilterId = 0;
  state.esgTab = '';
  state.mlfTab = '';
  state.variablePayTab = '';
  state.eStoreTab = '';
};

// REDUCER
const tabDataSlice = createSlice({
  name: 'tabData',
  initialState: {
    microgiveMainScreenTab: '',
    microgiveProjectsTab: '',
    microgiveDonationCategoryId: 0,
    microgiveVolunteerCategoryId: 0,
    policiesTab: '',
    breakoutsTab: '',
    breakoutsSessionTab: '',
    cheersTab: '',
    cheersLeadersBoardTabData: '',
    cheersMyCheersTabData: '',
    cheersMyPointsTab: '',
    cheersNominationRequestTabData: '',
    anniversaryTab: '',
    anniversaryGeo: '',
    anniversaryMainTab: '',
    anniversaryDigitalWarriorTab: '',
    anniversaryContestTab: '',
    pollsSurveysTab: '',
    broadcastTab: '',
    miHRTab: '',
    eventTab: '',
    somTab: '',
    pandemicSupportTab: '',
    womensdayTab: '',
    contestTab: '',
    myProfileDetailTab: '',
    myProfileDetailEditTab: '',
    galleryTab: '',
    gcdoTab: '',
    microbizTab: '',
    btoTab: '',
    ourCustomerTab: '',
    ourCustomerAllTab: '',
    facilityTab: '',
    newJoineesHigherLevelsTabData: '',
    communitiesTab: '',
    ecardsTab: '',
    globalSearchTab: '',
    diTab: '',
    ijprTab: '',
    podcastTab: '',
    wallpaperTab: '',
    travelRequestTab: '',
    ijprBookmarkTab: '',
    miHRTabCategoryFilterId: 0,
    newJoineesTabCategoryFilterId: 0,
    newJoineesTabCategoryFilterKey: '',
    newJoineesTabData: '',
    neoTabData: '',
    esgCategoryFilterId: 0,
    mlfCategoryFilterId: 0,
    esgTab: '',
    mlfTab: '',
    variablePayTab: '',
    eStoreTab: '',
  },
  reducers: {
    saveMicrogiveTabData: saveMicrogiveTabDataReducer,
    saveMicrogiveProjectsTabData: saveMicrogiveProjectsTabDataReducer,
    saveMicrogiveDonationCategoryId: saveMicrogiveDonationCategoryIdReducer,
    saveMicrogiveVolunteerCategoryId: saveMicrogiveVolunteerCategoryIdReducer,
    savePoliciesTabData: savePoliciesTabDataReducer,
    saveBreakoutsTabData: saveBreakoutsTabDataReducer,
    saveBreakoutsSessionTabData: saveBreakoutsSessionTabDataReducer,
    saveOverviewTabData: saveOverviewTabDataReducer,
    saveChecklistTabData: saveChecklistTabDataReducer,
    saveQuicklinksTabData: saveQuicklinksTabDataReducer,
    saveMyinductionTabData: saveMyinductionTabDataReducer,
    saveNewjoineeTabData: saveNewjoineeTabDataReducer,
    saveCheersTabData: saveCheersTabDataReducer,
    saveCheersLeadersBoardTabData: saveCheersLeadersBoardTabDataReducer,
    saveCheersMyCheersTabData: saveCheersMyCheersTabDataReducer,
    saveCheersMyPointsTabData: saveCheersMyPointsTabDataReducer,
    saveCheersNominationRequestTabData: saveCheersNominationRequestTabDataReducer,
    saveAnniversaryTabData: saveAnniversaryTabDataReducer,
    saveAnniversaryGeoData: saveAnniversaryGeoDataReducer,
    saveAnniversaryMainTabData: saveAnniversaryMainTabDataReducer,
    saveAnniversaryDigitalWarriorTabData: saveAnniversaryDigitalWarriorTabDataReducer,
    saveAnniversaryContestTabData: saveAnniversaryContestTabDataReducer,
    savePollsSurveysTabData: savePollsSurveysTabDataReducer,
    saveBroadcastTabData: saveBroadcastTabDataReducer,
    saveEventTabData: saveEventTabDataReducer,
    saveSomTabData: saveSomTabDataReducer,
    savepandemicSupportTabData: savePandemicSupportTabDataReducer,
    saveWomensdayTabData: saveWomensdayTabDataReducer,
    saveContestTabData: saveContestTabDataReducer,
    saveMyProfileDetailTabData: saveMyProfileDetailTabDataReducer,
    saveMyProfileDetailEditTabData: saveMyProfileDetailEditTabDataReducer,
    saveGalleryTabData: saveGalleryTabDataReducer,
    saveGcdoTabData: saveGcdoTabDataReducer,
    saveMicrobizTabData: saveMicrobizTabDataReducer,
    saveBtoTabData: saveBtoTabDataReducer,
    saveOurCustomerTabData: saveOurCustomerTabDataReducer,
    saveOurCustomerAllTabData: saveOurCustomerAllTabDataReducer,
    saveFacilityTabData: saveFacilityTabDataReducer,
    saveNewJoineesHigherLevelsTabData: saveNewJoineesHigherLevelsTabDataReducer,
    saveCommunitiesTabData: saveCommunitiesTabDataReducer,
    saveEcardsTabData: saveEcardsTabDataReducer,
    saveGlobalSearchTabData: saveGlobalSearchTabDataReducer,
    saveDiTabData: saveDiTabDataReducer,
    saveIjprTabData: saveIjprTabDataReducer,
    savePodcastTabData: savePodcastTabDataReducer,
    saveWallpaperTabData: saveWallpaperTabDataReducer,
    saveTravelRequestTabData: saveTravelRequestTabDataReducer,
    saveIjprBookmarkTabData: saveIjprBookmarkTabDataReducer,
    resetAllTabs: resetAllTabsReducer,
    saveMiHrTabData: saveMiHrTabDataReducer,
    saveMiHRTabCategoryFilterId: saveMiHRTabCategoryFilterIdReducer,
    saveNewJoineesTabCategoryFilterId: saveNewJoineesTabCategoryFilterIdReducer,
    saveNewJoineesTabCategoryFilterKey: saveNewJoineesTabCategoryFilterKeyReducer,
    saveNewJoineesTabData: saveNewJoineesTabDataReducer,
    saveNeoTabData: saveNeoTabDataReducer,
    saveEsgCategoryFilterId: saveEsgCategoryFilterIdReducer,
    saveMlfCategoryFilterId: saveMlfCategoryFilterIdReducer,
    saveEsgTabData: saveEsgTabDataReducer,
    saveMlfTabData: saveMlfTabDataReducer,
    saveVariablePayTabData: saveVariablePayTabDataReducer,
    saveEStoreTabData: saveEStoreTabDataReducer,
  },
});

// ACTIONS
const {
  saveMicrogiveTabData,
  saveMicrogiveDonationCategoryId,
  saveMicrogiveVolunteerCategoryId,
  saveMicrogiveProjectsTabData,
  savePoliciesTabData,
  saveBreakoutsTabData,
  saveBreakoutsSessionTabData,
  saveOverviewTabData,
  saveChecklistTabData,
  saveQuicklinksTabData,
  saveMyinductionTabData,
  saveNewjoineeTabData,
  saveCheersTabData,
  saveCheersLeadersBoardTabData,
  saveCheersMyCheersTabData,
  saveCheersMyPointsTabData,
  saveCheersNominationRequestTabData,
  saveAnniversaryTabData,
  saveAnniversaryGeoData,
  saveAnniversaryDigitalWarriorTabData,
  saveAnniversaryContestTabData,
  savePollsSurveysTabData,
  saveBroadcastTabData,
  saveMiHrTabData,
  saveEventTabData,
  saveSomTabData,
  savepandemicSupportTabData,
  saveWomensdayTabData,
  saveContestTabData,
  saveMyProfileDetailTabData,
  saveMyProfileDetailEditTabData,
  saveGalleryTabData,
  saveGcdoTabData,
  saveEsgTabData,
  saveMlfTabData,
  saveOurCustomerTabData,
  saveOurCustomerAllTabData,
  saveFacilityTabData,
  saveNewJoineesHigherLevelsTabData,
  saveCommunitiesTabData,
  saveEcardsTabData,
  saveGlobalSearchTabData,
  resetAllTabs,
  saveAnniversaryMainTabData,
  saveDiTabData,
  saveIjprTabData,
  saveMicrobizTabData,
  saveBtoTabData,
  savePodcastTabData,
  saveWallpaperTabData,
  saveTravelRequestTabData,
  saveIjprBookmarkTabData,
  saveMiHRTabCategoryFilterId,
  saveNewJoineesTabCategoryFilterId,
  saveNewJoineesTabCategoryFilterKey,
  saveNewJoineesTabData,
  saveNeoTabData,
  saveEsgCategoryFilterId,
  saveMlfCategoryFilterId,
  saveVariablePayTabData,
  saveEStoreTabData,
} = tabDataSlice.actions;

// MICROGIVE SELECTORS
const selectMicrogiveTabData = ({ tabData }) => tabData.microgiveMainScreenTab;
const selectMicrogiveDonationCategoryId = ({ tabData }) => tabData.microgiveDonationCategoryId;
const selectMicrogiveVolunteerCategoryId = ({ tabData }) => tabData.microgiveVolunteerCategoryId;
const selectMicrogiveProjectsTabData = ({ tabData }) => tabData.microgiveProjectsTab;

// POLICIES SELECTORS
const selectPoliciesTabData = ({ tabData }) => tabData.policiesTab;

// BREAKOUTS SELECTORS
const selectBreakoutsTabData = ({ tabData }) => tabData.breakoutsTab;
const selectBreakoutsSessionTabData = ({ tabData }) => tabData.breakoutsSessionTab;

// CHEERS SELECTORS
const selectCheersTabData = ({ tabData }) => tabData.cheersTab;
const selectCheersLeadersBoardTabData = ({ tabData }) => tabData.cheersLeadersBoardTabData;
const selectCheersMyCheersTabData = ({ tabData }) => tabData.cheersMyCheersTabData;
const selectCheersMyPointsTabData = ({ tabData }) => tabData.cheersMyPointsTab;
const selectCheersNominationRequestTabData = ({ tabData }) =>
  tabData.cheersNominationRequestTabData;

// ANNIVERSARY SELECTORS
const selectAnniversaryTabData = ({ tabData }) => tabData.anniversaryTab;
const selectAnniversaryGeoData = ({ tabData }) => tabData.anniversaryGeo;
const selectAnniversaryMainTabData = ({ tabData }) => tabData.anniversaryMainTab;
const selectAnniversaryDigitalWarriorTabData = ({ tabData }) =>
  tabData.anniversaryDigitalWarriorTab;
const selectAnniversaryContestTabData = ({ tabData }) => tabData.anniversaryContestTab;

// POLLS & SURVEYS SELECTORS
const selectPollsSurveysTabData = ({ tabData }) => tabData.pollsSurveysTab;

// BROADCAST SELECTORS
const selectBroadcastTabData = ({ tabData }) => tabData.broadcastTab;

// BROADCAST SELECTORS
const selectMiHRTabData = ({ tabData }) => tabData.miHRTab;

// EVENT SELECTORS
const selectEventTabData = ({ tabData }) => tabData.eventTab;

// SOM SELECTORS
const selectSomTabData = ({ tabData }) => tabData.somTab;

// PANDEMIC SUPPORT SELECTORS
const selectpandemicSupportTabData = ({ tabData }) => tabData.pandemicSupportTab;

// WOMENSDAY SELECTORS
const selectWomensdayTabData = ({ tabData }) => tabData.womensdayTab;

// CONTEST SELECTORS
const selectContestTabData = ({ tabData }) => tabData.contestTab;

// FACILITY SELECTORS
const selectFacilityTabData = ({ tabData }) => tabData.facilityTab;

// MY PROFILE SELECTORS
const selectMyProfileDetailTabData = ({ tabData }) => tabData.myProfileDetailTab;
const selectMyProfileDetailEditTabData = ({ tabData }) => tabData.myProfileDetailEditTab;

// GCDO Selector
const selectGcdoTabData = ({ tabData }) => tabData.gcdoTab;

// Microbiz Selector
const selectMicrobizTabData = ({ tabData }) => tabData.microbizTab;

// BTO Selector
const selectBtoTabData = ({ tabData }) => tabData.btoTab;

// OUR CUSTOMER SELECTORS
const selectOurCustomerTabData = ({ tabData }) => tabData.ourCustomerTab;
const selectOurCustomerAllTabData = ({ tabData }) => tabData.ourCustomerAllTab;

// GALLERY SELECTORS
const selectGalleryTabData = ({ tabData }) => tabData.galleryTab;

// NEW JOINEES HIGHER LEVELS SELECTORS
const selectNewJoineesHigherLevelsTabData = ({ tabData }) => tabData.newJoineesHigherLevelsTabData;
const selectNewJoineesCategoryFilterId = ({ tabData }) => tabData.newJoineesTabCategoryFilterId;
const selectNewJoineesCategoryFilterKey = ({ tabData }) => tabData.newJoineesTabCategoryFilterKey;
const selectOverviewTabData = ({ tabData }) => tabData.overviewTab;
const selectChecklistTabData = ({ tabData }) => tabData.checklistTab;
const selectQuickLinksTabData = ({ tabData }) => tabData.quicklinksTab;
const selectMyinductionTabData = ({ tabData }) => tabData.myinduction;
const selectNewjoineeTabData = ({ tabData }) => tabData.newjoineeTab;
// COMMUNITIES SELECTOR
const selectCommunitiesTabData = ({ tabData }) => tabData.communitiesTab;

// ECARDS SELECTORS
const selectEcardsTabData = ({ tabData }) => tabData.ecardsTab;

// GLOBAL SEARCH SELECTORS
const selectGlobalSearchTabData = ({ tabData }) => tabData.globalSearchTab;

// DI SELECTORS
const selectDiTabData = ({ tabData }) => tabData.diTab;

// IJPR SELECTORS
const selectIjprTabData = ({ tabData }) => tabData.ijprTab;

// PODCAST SELECTORS
const selectPodcastTabData = ({ tabData }) => tabData.podcastTab;

// WALLPAPER SELECTORS
const selectWallpaperTabData = ({ tabData }) => tabData.wallpaperTab;

// TRAVEL REQUEST SELECTORS
const selectTravelRequestTabData = ({ tabData }) => tabData.travelRequestTab;

// IJPR BOOKMARK SELECTORS
const selectIjprBookmarkTabData = ({ tabData }) => tabData.ijprBookmarkTab;

// MIHR TAB FILTER CATEGORY SELECTORS
const selectMiHRTabFilterCategoryId = ({ tabData }) => tabData.miHRTabCategoryFilterId;

const selectNewJoineesTabData = ({ tabData }) => tabData.newJoineesTabData;

const selectNeoTabData = ({ tabData }) => tabData.neoTabData;

// ESG TAB FILTER CATEGORY SELECTORS
const selectEsgFilterCategoryId = ({ tabData }) => tabData.esgCategoryFilterId;

// MLF TAB FILTER CATEGORY SELECTORS
const selectMlfFilterCategoryId = ({ tabData }) => tabData.mlfCategoryFilterId;

// ESG TAB DATA
const selectEsgTabData = ({ tabData }) => tabData.esgTab;

// ESG TAB DATA
const selectMlfTabData = ({ tabData }) => tabData.mlfTab;

// VARIABLE PAY SELECTORS
const selectVariablePayTabData = ({ tabData }) => tabData.variablePayTab;

const selectEStoreTabData = ({ tabData }) => {
  return tabData.eStoreTab;
};

const tabDataSliceReducer = tabDataSlice.reducer;

export {
  tabDataSliceReducer,
  saveMicrogiveTabData,
  selectMicrogiveTabData,
  saveMicrogiveDonationCategoryId,
  selectMicrogiveDonationCategoryId,
  saveMicrogiveVolunteerCategoryId,
  selectMicrogiveVolunteerCategoryId,
  saveMicrogiveProjectsTabData,
  selectMicrogiveProjectsTabData,
  savePoliciesTabData,
  selectPoliciesTabData,
  saveBreakoutsTabData,
  selectBreakoutsTabData,
  saveBreakoutsSessionTabData,
  selectBreakoutsSessionTabData,
  saveCheersTabData,
  selectCheersTabData,
  saveNewjoineeTabData,
  selectNewjoineeTabData,
  saveOverviewTabData,
  selectOverviewTabData,
  saveChecklistTabData,
  selectChecklistTabData,
  saveQuicklinksTabData,
  selectQuickLinksTabData,
  saveMyinductionTabData,
  selectMyinductionTabData,
  saveCheersLeadersBoardTabData,
  saveCheersMyCheersTabData,
  selectCheersLeadersBoardTabData,
  selectCheersMyCheersTabData,
  saveCheersMyPointsTabData,
  selectCheersMyPointsTabData,
  saveCheersNominationRequestTabData,
  selectCheersNominationRequestTabData,
  saveAnniversaryTabData,
  selectAnniversaryTabData,
  saveAnniversaryGeoData,
  selectAnniversaryGeoData,
  saveAnniversaryDigitalWarriorTabData,
  selectAnniversaryDigitalWarriorTabData,
  saveAnniversaryContestTabData,
  selectAnniversaryContestTabData,
  savePollsSurveysTabData,
  selectPollsSurveysTabData,
  saveBroadcastTabData,
  selectBroadcastTabData,
  saveEventTabData,
  selectEventTabData,
  saveSomTabData,
  selectSomTabData,
  savepandemicSupportTabData,
  selectpandemicSupportTabData,
  saveWomensdayTabData,
  selectWomensdayTabData,
  saveContestTabData,
  selectContestTabData,
  saveMyProfileDetailTabData,
  selectMyProfileDetailTabData,
  saveMyProfileDetailEditTabData,
  selectMyProfileDetailEditTabData,
  saveGalleryTabData,
  selectGalleryTabData,
  saveGcdoTabData,
  saveEsgTabData,
  selectEsgTabData,
  saveMlfTabData,
  selectMlfTabData,
  selectGcdoTabData,
  saveOurCustomerTabData,
  selectOurCustomerTabData,
  saveOurCustomerAllTabData,
  selectOurCustomerAllTabData,
  saveFacilityTabData,
  selectFacilityTabData,
  saveNewJoineesHigherLevelsTabData,
  selectNewJoineesHigherLevelsTabData,
  saveCommunitiesTabData,
  selectCommunitiesTabData,
  saveEcardsTabData,
  selectEcardsTabData,
  selectGlobalSearchTabData,
  saveGlobalSearchTabData,
  resetAllTabs,
  saveAnniversaryMainTabData,
  selectAnniversaryMainTabData,
  saveDiTabData,
  selectDiTabData,
  saveIjprTabData,
  selectIjprTabData,
  selectMicrobizTabData,
  saveMicrobizTabData,
  selectBtoTabData,
  saveBtoTabData,
  selectPodcastTabData,
  savePodcastTabData,
  selectWallpaperTabData,
  saveWallpaperTabData,
  saveTravelRequestTabData,
  selectTravelRequestTabData,
  selectIjprBookmarkTabData,
  saveIjprBookmarkTabData,
  selectMiHRTabData,
  saveMiHrTabData,
  selectMiHRTabFilterCategoryId,
  saveMiHRTabCategoryFilterId,
  selectNewJoineesCategoryFilterId,
  saveNewJoineesTabCategoryFilterId,
  selectNewJoineesCategoryFilterKey,
  saveNewJoineesTabCategoryFilterKey,
  saveNewJoineesTabData,
  selectNewJoineesTabData,
  saveNeoTabData,
  selectNeoTabData,
  saveEsgCategoryFilterId,
  selectEsgFilterCategoryId,
  saveMlfCategoryFilterId,
  selectMlfFilterCategoryId,
  selectVariablePayTabData,
  saveVariablePayTabData,
  saveEStoreTabData,
  selectEStoreTabData,
};
