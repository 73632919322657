import React, { useEffect } from 'react';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import CommunityMembersListItem from './components/CommunityMembersListItem';

const CommunityTagPeopleScreen = ({ location }) => {
  const { employees } = location.state;

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.COMMUNITY_POST_TAGGED,
      NAVIGATION_ROUTES.COMMUNITY_POST_TAGGED
    );
  }, []);

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.COMMUNITIES} />
      <BackButton />
      <div className="main-content-container">
        <div className="row">
          {employees.length > 0 &&
            employees.map((item, index) => {
              return <CommunityMembersListItem key={`${index}`} item={item} />;
            })}
        </div>
      </div>
    </div>
  );
};

export default CommunityTagPeopleScreen;
