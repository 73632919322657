import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { GET_VOLUNTEER_UPDATES } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../state/UIState';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import UpdatesListItem from './components/UpdatesListItem';

const UpdatesScreen = ({ history, location }) => {
  const dispatch = useDispatch();
  const { initiativeId, initiativeDetailId } = location.state;
  const [updatesList, setUpdatesList] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);

  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.UPDATES_SCREEN, NAVIGATION_ROUTES.UPDATES_SCREEN);
  }, []);

  const callUpdatesApi = useCallback(
    async (id, loadMore) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        const params = {
          page_id: id,
          initiative_detail_id: initiativeDetailId,
        };
        try {
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          const apiResponse = await apiRequest(
            GET_VOLUNTEER_UPDATES + initiativeId + '/updates',
            REQUEST_TYPE.GET,
            params
          );
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                setUpdatesList((oldArray) => [...oldArray, ...apiResponse.response.data.feeds]);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(
            err,
            params,
            GET_VOLUNTEER_UPDATES + initiativeId + '/updates',
            NAVIGATION_ROUTES.UPDATES_SCREEN
          );
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      }
    },
    [dispatch, initiativeDetailId, initiativeId]
  );

  useEffect(() => {
    callUpdatesApi(1, true);
  }, [callUpdatesApi]);

  const onPressCommentButton = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.MICROGIVE_UPDATES_COMMENT,
      item.update_id,
      ANALYTICS_ITEM_NAMES.COMMENT
    );
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: item.update_id,
      likeType: 'MVU',
      fromRoute: NAVIGATION_ROUTES.UPDATES_SCREEN,
    });
  };

  const onPressLoadMore = () => {
    callUpdatesApi(pageId, loadMoreFlag);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.SEARCH_NO_RESULTS} />;
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICROGIVE} />
      <BackButton />
      <div className="main-content-container">
        {showEmptyContentView && !updatesList.length && !progressLoadingState.isProgressLoading ? (
          emptyListView()
        ) : (
          <div>
            <div>
              {updatesList.map((item, index) => {
                return (
                  <UpdatesListItem
                    item={item}
                    onPressCommentButton={onPressCommentButton}
                    key={`${index}`}
                  />
                );
              })}
            </div>
            {loadMoreFlag && (
              <LoadMoreBtn
                onClick={onPressLoadMore}
                isLoading={progressLoadingState.isProgressLoading}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdatesScreen;
