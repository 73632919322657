import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';

import {
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { EMPLOYEES } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { useSelector } from 'react-redux';

const TravelRequestEmployeeDialog = (props) => {
  const { isEmployeeModalVisible, setIsEmployeeModalVisible, onPressEmployeeHandler } = props;
  const [employeeList, setEmployeeList] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const searchTextRef = useRef('');
  const employeeData = useSelector(selectEmployeeData);

  const callEmployeeApi = useCallback(async (pageIdCount, loadMore) => {
    if (loadMore === true) {
      const params = {
        page_id: pageIdCount,
        query: searchTextRef.current,
      };
      try {
        setIsLoading(true);
        const apiResponse = await apiRequest(EMPLOYEES, REQUEST_TYPE.GET, params);
        setIsLoading(false);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.data.feeds) {
              if (apiResponse.response.data.feeds.length > 0) {
                let empList = apiResponse.response.data.feeds;
                setEmployeeList((oldArray) => [...oldArray, ...empList]);
                setLoadMoreFlag(true);
                setPageId((prevCount) => prevCount + 1);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        }
      } catch (err) {
        handleError(err, params, EMPLOYEES, NAVIGATION_ROUTES.TRAVEL_REQUEST);
        setIsLoading(false);
      }
    }
  }, []);

  const renderScreen = useCallback(async () => {
    callEmployeeApi(1, true);
  }, [callEmployeeApi]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const renderEmployee = (item, index) => {
    return (
      <div
        className="col-md-4 col-sm-6 mt-3"
        key={index.toString()}
        onClick={() => {
          onPressEmployeeHandler(item);
        }}>
        <div className="d-flex align-items-center common-cursor-pointer travel-request-choose-profile px-2">
          <UserProfilePhoto
            imageBaseUrl={item.profile_picture.base_url}
            imagePath={item.profile_picture.image_path}
            imageClass="common-user-profile-photo-sm"
            employeeId={item.employee_id}
          />
          <div className="ml-3 d-flex align-items-center">
            <div>
              <div>{`${item.first_name} ${item.last_name}`}</div>
              {item?.location?.title !== '' && <div>{item?.location?.title}</div>}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onPressLoadMore = () => {
    callEmployeeApi(pageId, loadMoreFlag);
  };

  const onSearchSubmitHandler = (event) => {
    event.preventDefault();
    searchTextRef.current = searchText;
    setEmployeeList([]);
    setPageId(1);
    setLoadMoreFlag(true);
    callEmployeeApi(1, true);
  };

  const emptyListView = () => {
    return (
      <div className="flex-grow-1">
        <DefaultContentView message={ERROR_MESSAGES.SEARCH_NO_RESULTS} />
      </div>
    );
  };

  return (
    <Modal
      onHide={() => setIsEmployeeModalVisible((val) => !val)}
      show={isEmployeeModalVisible}
      centered
      backdrop="static"
      size="lg"
      scrollable={true}
      keyboard={false}>
      <Modal.Header className="d-flex align-items-center py-3" closeButton>
        <Modal.Title className="ijp-applied-modal-title">Choose Employee</Modal.Title>
      </Modal.Header>

      <Form onSubmit={onSearchSubmitHandler}>
        <Form.Group className="pt-4 px-4 pb-0">
          <Form.Control
            placeholder="Search"
            onChange={(event) => setSearchText(event.target.value)}
            value={searchText}
          />
        </Form.Group>
      </Form>
      <Modal.Body className="px-4 pt-0 pb-4">
        <ActivityLoader visible={isLoading} />
        <div className="row overflow-hidden">
          {!employeeList.length && !isLoading && emptyListView()}
          {employeeList.map((employee, index) => {
            if (employeeData.employee_code.toString() === employee.employee_code.toString()) {
              return <></>;
            }
            return renderEmployee(employee, index);
          })}
        </div>
        {loadMoreFlag && !!employeeList.length && (
          <LoadMoreBtn onClick={onPressLoadMore} buttonStyle="my-3" isLoading={isLoading} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(TravelRequestEmployeeDialog);
