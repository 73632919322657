import React, { useEffect, useState, useCallback } from 'react';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import BackButton from '../../common/ui/common_back_button/BackButton';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_TITLE,
} from '../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import { apiRequest } from '../../services/Service';
import { CONTESTS_BY_ID, EVENTS_BY_ID, EVENT_FEEDBACK } from '../../services/ApiUrls';
import { saveProgressLoadingState } from '../../state/UIState';
import { formatDateDDMonthYYYY, showToast } from '../../utils/common';
import { Modal } from 'react-bootstrap';
import ActivityLoader from '../../common/ui/activity_loader/ActivityLoader';
import { selectAssetCommentCharacterLimit } from '../../state/MasterData';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';

const FeedbackScreen = (props) => {
  const { event_id, type } = props.location.state;
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [event, setEvent] = useState();
  const [feedback, setFeedBack] = useState('');
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const MAX_CHAR_ALLOWED = useSelector(selectAssetCommentCharacterLimit);
  const isFeedbackEmpty = feedback === null || feedback === undefined || /^\s*$/.test(feedback);

  const getContest = useCallback(async () => {
    setError(false);
    try {
      const apiResponse = await apiRequest(CONTESTS_BY_ID + event_id, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.contest) {
            const data = apiResponse.response.data.contest;
            setEvent(data);
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      setError(true);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, event_id]);

  const getEvent = useCallback(async () => {
    setError(false);
    try {
      const apiResponse = await apiRequest(EVENTS_BY_ID + event_id, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.event) {
            const data = apiResponse.response.data.event;
            setEvent(data);
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      setError(true);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, event_id]);

  useEffect(() => {
    if (type === 'contest') {
      getContest();
    } else {
      getEvent();
    }
  }, [getContest, getEvent, type]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.FEEDBACK_SCREEN, NAVIGATION_ROUTES.FEEDBACK_SCREEN);
  }, []);

  const callSubmitFeedbackApi = async () => {
    try {
      setError(false);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.SUBMIT_FEEDBACK,
        })
      );
      logEvent(
        ANALYTICS_EVENT_TYPES.SUBMIT_FEEDBACK,
        event_id.toString(),
        ANALYTICS_ITEM_NAMES.FEEDBACK
      );
      const params = new URLSearchParams();
      params.append('type', type === 'contest' ? 'C' : 'E');
      params.append('refer_id', event_id);
      params.append('message', feedback);
      const apiResponse = await apiRequest(EVENT_FEEDBACK, REQUEST_TYPE.POST, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (apiResponse.response.status === true) {
        showToast(apiResponse.response.message);
        setShowSuccessDialog(true);
      } else {
        showToast(apiResponse.response.message);
      }
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      setError(false);
    }
  };

  const successDialog = () => {
    return (
      <div className="feedback-success-dialog d-flex flex-column align-items-center justify-content-center w-100">
        <div className="feedback-success-title-text mt-3">Submitted successfully!</div>
        <div className="feedback-success-subtitle-text mt-1 pt-1">
          Thanks for your valuable feedback.
        </div>
        <div className="d-flex flex-row w-100 align-items-center justify-content-center">
          <div
            onClick={() => {
              setShowSuccessDialog(false);
              props.history.goBack();
            }}
            className="feedback-ok-button-container d-flex flex-row align-items-center justify-content-center mt-4 align-self-center mb-3 common-cursor-pointer">
            OK
          </div>
        </div>
      </div>
    );
  };

  return event ? (
    <div className="container">
      <SectionHeader title={type === 'contest' ? SCREEN_TITLE.CONTESTS : SCREEN_TITLE.EVENTS} />
      <BackButton />
      <div className="ml-3">
        <div
          className={
            type === 'event'
              ? event?.registrations?.is_registered === true &&
                event?.registrations?.feedbacks === 1 &&
                event?.registrations?.feedback_provided === 0
                ? 'feedback-title-container p-3 mt-3'
                : 'feedback-title-container-bottom-border p-3 mt-3'
              : event?.participants?.is_registered === true &&
                event?.participants?.feedbacks === 1 &&
                event?.participants?.feedback_provided === 0
              ? 'feedback-title-container p-3 mt-3'
              : 'feedback-title-container-bottom-border p-3 mt-3'
          }>
          <div className="feedback-title-text mb-1">{event?.title}</div>
          <div className="feedback-subtitle-text mb-1">
            Start date: {formatDateDDMonthYYYY(event?.start_date)}
          </div>
          <div className="feedback-subtitle-text mb-1">
            Location: {type === 'event' ? event?.event_location : event?.contest_location}
          </div>
          {type === 'event' ? (
            event?.registrations?.is_registered === true &&
            event?.registrations?.feedbacks === 1 &&
            event?.registrations?.feedback_provided === 0 ? null : event?.registrations
                ?.is_registered === true &&
              event?.registrations?.feedbacks === 1 &&
              event?.registrations?.feedback_provided === 1 ? (
              <div className="feedback-subtitle-text">{`You've already provided your feedback.`}</div>
            ) : event?.registrations?.is_registered === false &&
              event?.registrations?.feedbacks === 1 &&
              event?.registrations?.feedback_provided === 0 ? (
              <div className="feedback-subtitle-text">{`You are not allowed to provide your feedback.`}</div>
            ) : (
              <div className="feedback-subtitle-text">{`Feedback is not available.`}</div>
            )
          ) : event?.participants?.is_registered === true &&
            event?.participants?.feedbacks === 1 &&
            event?.participants?.feedback_provided === 0 ? null : event?.participants
              ?.is_registered === true &&
            event?.participants?.feedbacks === 1 &&
            event?.participants?.feedback_provided === 1 ? (
            <div className="feedback-subtitle-text">{`You've already provided your feedback.`}</div>
          ) : event?.participants?.is_registered === false &&
            event?.participants?.feedbacks === 1 &&
            event?.participants?.feedback_provided === 0 ? (
            <div className="feedback-subtitle-text">{`You are not allowed to provide your feedback.`}</div>
          ) : (
            <div className="feedback-subtitle-text">{`Feedback is not available.`}</div>
          )}
        </div>
        {type === 'event'
          ? event?.registrations?.is_registered === true &&
            event?.registrations?.feedbacks === 1 &&
            event?.registrations?.feedback_provided === 0 && (
              <div className="feedback-textbox-container p-3">
                <div className="row pl-3">
                  <div className="feedback-text-style mr-1">Feedback</div>
                  <div className="feedback-star-text-style">*</div>
                </div>
                <div className="common-comment-box mb-2 mt-1">
                  <textarea
                    name="description"
                    id="description"
                    rows="4"
                    spellCheck="false"
                    value={feedback}
                    onChange={(event) => setFeedBack(event.target.value)}
                  />
                </div>
                <div className="d-flex flex-row align-items-end justify-content-end w-100">
                  <div
                    onClick={() => {
                      if (feedback?.length > MAX_CHAR_ALLOWED) {
                        showToast(`Maximum ${MAX_CHAR_ALLOWED} characters are allowed`);
                      } else {
                        isFeedbackEmpty
                          ? showToast('Feedback should not be empty')
                          : callSubmitFeedbackApi();
                      }
                    }}
                    className="feedback-submit-button-style d-flex flex-row align-items-center justify-content-center common-cursor-pointer">
                    SUBMIT
                  </div>
                </div>
              </div>
            )
          : event?.participants?.is_registered === true &&
            event?.participants?.feedbacks === 1 &&
            event?.participants?.feedback_provided === 0 && (
              <div className="feedback-textbox-container p-3">
                <div className="row pl-3">
                  <div className="feedback-text-style mr-1">Feedback</div>
                  <div className="feedback-star-text-style">*</div>
                </div>
                <div className="common-comment-box mb-2 mt-1">
                  <textarea
                    name="description"
                    id="description"
                    rows="4"
                    spellCheck="false"
                    value={feedback}
                    onChange={(event) => setFeedBack(event.target.value)}
                  />
                </div>
                <div className="d-flex flex-row align-items-end justify-content-end w-100">
                  <div
                    onClick={() => {
                      if (feedback?.length > MAX_CHAR_ALLOWED) {
                        showToast(`Maximum ${MAX_CHAR_ALLOWED} characters are allowed`);
                      } else {
                        isFeedbackEmpty ? showToast('Feedback is empty') : callSubmitFeedbackApi();
                      }
                    }}
                    className="feedback-submit-button-style d-flex flex-row align-items-center justify-content-center common-cursor-pointer">
                    SUBMIT
                  </div>
                </div>
              </div>
            )}
      </div>
      <Modal
        contentClassName="feedback-success-modal mx-auto"
        show={showSuccessDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          null;
        }}>
        <Modal.Body>{successDialog()}</Modal.Body>
      </Modal>
    </div>
  ) : (
    <ActivityLoader visible={!event} />
  );
};

export default FeedbackScreen;
