import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectConfigurations } from '../../../state/MasterData';
import {
  getDeviceScreenDensityQualifierAsPath,
  getYoutubeVideoImageFromUrl,
} from '../../../utils/common';
import { Modal } from 'react-bootstrap';
import { ImPlay2 } from 'react-icons/im';
import { VscChromeClose } from 'react-icons/vsc';
import ReactPlayer from 'react-player';
import Lottie from 'react-lottie';
import { DASHBOARD_BANNER_TYPE, REQUEST_TYPE } from '../../../common/constants/AppConstants';
import axios from 'axios';

const Banner = () => {
  const configurationsData = useSelector(selectConfigurations);
  const [deviceScreenDensityQualifierPath] = useState(getDeviceScreenDensityQualifierAsPath());
  const dashboardBannerConfig = configurationsData?._dashboard_banner_config
    ? JSON.parse(configurationsData._dashboard_banner_config)
    : null;
  const dashboardVideoConfig = configurationsData?._dashboard_video;

  const [homeBannerAnimationData, setHomeBannerAnimationData] = useState('');

  const lottieStyleConfig = { cursor: 'default' };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    if (homeBannerAnimationData === '') {
      if (dashboardBannerConfig && dashboardBannerConfig.type === DASHBOARD_BANNER_TYPE.FESTIVE) {
        const fileBaseUrl = dashboardBannerConfig.animation_file_base_url_web;
        const filePath = dashboardBannerConfig.animation_file_path_web;
        if (fileBaseUrl && filePath) {
          axios({
            method: REQUEST_TYPE.GET,
            url: fileBaseUrl + filePath,
          })
            .then((response) => {
              if (response.status === 200) {
                if (response.data) {
                  setHomeBannerAnimationData(response.data);
                }
              }
            })
            .catch(() => {});
        }
      }
    }
  }, [dashboardBannerConfig, homeBannerAnimationData]);

  if (dashboardBannerConfig?.type === DASHBOARD_BANNER_TYPE.IMAGE) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          background: dashboardBannerConfig ? dashboardBannerConfig.gradient_colors_web : '',
        }}>
        <div className="home-banner-image-container d-flex justify-content-center align-items-center">
          <div className="embed-responsive embed-responsive-16by9">
            <img
              className="embed-responsive-item"
              src={
                dashboardBannerConfig?.image_base_url +
                deviceScreenDensityQualifierPath +
                dashboardBannerConfig?.banner_image
              }
            />
          </div>
        </div>
      </div>
    );
  } else if (dashboardBannerConfig?.type === DASHBOARD_BANNER_TYPE.VIDEO) {
    return (
      <div className="home-banner-container">
        <div className="my-profile-edit-background-container">
          {dashboardVideoConfig?.show_custom_thumbnail_web ? (
            <img className="home-banner-container p-0" src={dashboardVideoConfig?.web_thumbnail} />
          ) : (
            <img
              className="home-banner-container p-0"
              src={getYoutubeVideoImageFromUrl(dashboardVideoConfig?.video_url)}
            />
          )}
          {dashboardVideoConfig?.show_overlay ? (
            <div className="my-profile-edit-foreground-container" onClick={handleShow}>
              <img className="w-100" src={dashboardVideoConfig.custom_play_icon_mobile} />
            </div>
          ) : (
            <div className="my-profile-edit-foreground-container" onClick={handleShow}>
              <ImPlay2 size="7em" color="#fff" />
            </div>
          )}
        </div>

        <Modal
          show={show}
          onHide={() => setShow(false)}
          className="home-fullscreen-modal-dialog fade-scale"
          aria-labelledby="example-custom-modal-styling-title">
          <Modal.Body className="animate-bottom">
            <div
              className="home-fullscreen-modal-dialog-close-button-container"
              onClick={handleClose}>
              <VscChromeClose size="4em" color="#fff" />
            </div>
            <ReactPlayer
              url={dashboardVideoConfig?.video_url}
              width="100%"
              height="100%"
              playing={true}
              controls={true}
              className="home-video-container"
            />
          </Modal.Body>
        </Modal>
      </div>
    );
  } else if (dashboardBannerConfig?.type === DASHBOARD_BANNER_TYPE.FESTIVE) {
    return (
      <div
        className="home-banner-container"
        style={{
          background: dashboardBannerConfig ? dashboardBannerConfig.gradient_colors_web : '',
        }}>
        {homeBannerAnimationData !== '' && (
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: homeBannerAnimationData,
            }}
            isClickToPauseDisabled
            style={lottieStyleConfig}
          />
        )}
      </div>
    );
  } else {
    return null;
  }
};

export default React.memo(Banner);
