import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SUPPORTED_IMAGE_FORMATS,
  SUPPORTED_IMAGE_FORMATS_WEB,
  WARNING_MESSAGES,
} from '../../../common/constants/AppConstants';
import { WOMENS_DAY_GALLERIES, WOMENS_DAY_UPLOAD_GALLERY } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import GalleryTabItem from '../components/GalleryTabItem';
import { Modal } from 'react-bootstrap';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import { useHistory } from 'react-router';
import { showToast } from '../../../utils/common';
import { saveProgressLoadingState } from '../../../state/UIState';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import { selectMaxFileUploadSizeConfig } from '../../../state/MasterData';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { uploadDocuments } from '../../../utils/commonApiRequests';

const GalleryTab = (props) => {
  const history = useHistory();
  const [gallery, setGallery] = useState([]);
  const [baseUrl, setBaseUrl] = useState([]);
  const [error, setError] = useState(false);
  const [isRecent, setRecent] = useState(false);
  const [noOfPhotos, setNoOfPhotos] = useState('');
  const [showImageUploadSuccessDialog, setShowImageUploadSuccessDialog] = useState(false);
  const [showUploadImageDialog, setShowUploadImageDialog] = useState(false);
  const accessibilityData = useSelector(selectAccessibilityData);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const maxFileUploadSizeInMB = useSelector(selectMaxFileUploadSizeConfig);
  const [showNoImagesFound, setShowNoImagesFound] = useState(false);
  const year = props?.year ?? '';
  const selectImagesInputRef = useRef(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagesLength, setSelectedImagesLength] = useState(0);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.WOMENS_DAY_GALLERY_TAB,
      props.item,
      ANALYTICS_ITEM_NAMES.WOMENS_DAY
    );
  }, [props.item]);

  const callUploadImagesApi = useCallback(
    async (imagesArr) => {
      setShowUploadImageDialog(false);
      const params = new URLSearchParams();
      params.append('files', JSON.stringify(imagesArr));
      params.append('year', year);
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
            progressMessage: LOADING_MESSAGES.DEFAULT,
          })
        );
        setError(false);
        const apiResponse = await apiRequest(WOMENS_DAY_UPLOAD_GALLERY, REQUEST_TYPE.POST, params);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            setSelectedImages([]);
            setSelectedImagesLength(0);
            setShowImageUploadSuccessDialog(true);
          }
        }
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      } catch (err) {
        handleError(err, params, WOMENS_DAY_UPLOAD_GALLERY, NAVIGATION_ROUTES.WOMENS_DAY);
        setError(true);
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    },
    [dispatch, year]
  );

  const imageUploadDialog = () => {
    return (
      <div>
        <div className="womens-day-team-view">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_UPLOAD_PHOTO,
              ACCESSIBILITY_IDENTIFIERS.MICROGIVE
            )}
            className="womens-day-upload-icon common-cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              selectImagesInputRef.current.click();
            }}
          />
          <input
            type="file"
            onChange={onFileChange}
            accept={SUPPORTED_IMAGE_FORMATS}
            id="input"
            ref={selectImagesInputRef}
            multiple
            onClick={(event) => {
              event.target.value = null;
            }}
            className="d-none"
          />
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            selectImagesInputRef.current.click();
          }}
          className="text-center womens-day-select-images-toupload womens-day-select-images-toupload-container mt-2 common-cursor-pointer">
          Select images to be uploaded
        </div>
        <div className="text-center womens-day-images-review womens-day-images-review-container mt-2 mb-3">
          All images to be reviewed by MicrolandOne App Admin.
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (selectedImages.length === selectedImagesLength && selectedImagesLength !== 0) {
      callUploadImagesApi(selectedImages);
    }
  }, [callUploadImagesApi, selectedImages, selectedImagesLength]);

  const onFileChange = (event) => {
    let isAllFilesSupported = true;
    let isAnyFileSizeLarge = false;
    [...event.target.files].forEach((element) => {
      if (!SUPPORTED_IMAGE_FORMATS_WEB.includes(element.type)) {
        isAllFilesSupported = false;
      }
      if (Number(element.size) / (1024 * 1024) > maxFileUploadSizeInMB) {
        isAnyFileSizeLarge = true;
      }
    });
    if (!isAllFilesSupported) {
      showToast(WARNING_MESSAGES.FILE_NOT_SUPPORTED);
      setShowUploadImageDialog(false);
    } else if (isAnyFileSizeLarge) {
      showToast(WARNING_MESSAGES.FILE_SIZE_EXCEEDED(maxFileUploadSizeInMB));
      setShowUploadImageDialog(false);
    } else {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      setSelectedImagesLength(event.target.files?.length);
      [...event.target.files].map((image) => {
        uploadDocuments(image, 'womensday')
          .then((response) => {
            setSelectedImages((prev) => [...prev, { filename: response.fileName, type: 'image' }]);
          })
          .catch((message) => {
            showToast(message);

            dispatch(
              saveProgressLoadingState({
                isProgressLoading: false,
              })
            );
          });
      });
    }
  };

  const callGalleryApi = useCallback(
    async (recent) => {
      const params = {
        recent: recent,
        year: year,
      };
      try {
        setLoading(true);
        setError(false);
        setShowNoImagesFound(false);
        const apiResponse = await apiRequest(WOMENS_DAY_GALLERIES, REQUEST_TYPE.GET, params);
        setLoading(false);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            const dataArr = [];
            for (const property in apiResponse.response.data.feeds.pictures) {
              dataArr.push({
                title: property,
                data: apiResponse.response.data.feeds.pictures[property],
              });
            }
            setGallery(dataArr);
            setBaseUrl(apiResponse.response.data.feeds.base_url);
            setNoOfPhotos(apiResponse.response.data.count);
            if (apiResponse?.response?.data?.count == 0) {
              setShowNoImagesFound(true);
            }
          }
        }
      } catch (err) {
        handleError(err, params, WOMENS_DAY_GALLERIES, NAVIGATION_ROUTES.WOMENS_DAY);
        setError(true);
        setLoading(false);
        setShowNoImagesFound(false);
      }
    },
    [year]
  );

  const imageUploadSuccessDialog = () => {
    return (
      <div>
        <div className="womens-day-team-view">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_SUCCESS,
              ACCESSIBILITY_IDENTIFIERS.MICROGIVE
            )}
            className="womens-day-upload-icon mr-4"
          />
        </div>
        <div className="womens-day-images-got-uploaded womens-day-text-center mt-2">
          Images got uploaded
        </div>
        <div className="womens-day-text-center mt-2 womens-day-images-under-review womens-day-images-under-review-container">
          Your Images are under review
        </div>
        <div className="text-center mt-2 womens-day-images-reviewed-by womens-day-images-reviewedby-container">
          All images to be reviewed by MLOne App Admin.
        </div>
        <CustomButton buttonStyle="womensday-button-style mt-3" onClick={onPressViewGallery}>
          View Gallery
        </CustomButton>
      </div>
    );
  };

  const renderScreen = useCallback(async () => {
    callGalleryApi(isRecent);
  }, [callGalleryApi, isRecent]);

  const onPressMore = (item, url) => {
    history.push(NAVIGATION_ROUTES.WOMENS_DAY_GALLERY, {
      data: item.data,
      year,
      baseUrl: url,
    });
  };

  useEffect(() => {
    renderScreen();
  }, []);

  const onPressItem = (item, index) => {
    history.push(NAVIGATION_ROUTES.WOMENS_DAY_GALLERY_FULL_IMAGE, {
      baseUrl: baseUrl,
      cards: item,
      cardIndex: index,
    });
  };

  const renderItem = (item) => {
    return (
      <GalleryTabItem
        item={item}
        baseUrl={baseUrl}
        onPressMore={onPressMore}
        onPressItem={onPressItem}
      />
    );
  };

  const onPressRecent = () => {
    setGallery([]);
    if (isRecent === true) {
      setRecent(false);
      callGalleryApi(false);
    } else {
      setRecent(true);
      callGalleryApi(true);
    }
  };

  const onPressUploadPhotos = () => {
    setShowUploadImageDialog(true);
  };

  const onPressViewGallery = () => {
    callGalleryApi();
    setShowImageUploadSuccessDialog(false);
  };

  return (
    <div className="mainContainer">
      <ActivityLoader visible={loading} />
      <Modal
        show={showUploadImageDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowUploadImageDialog(false)}>
        <Modal.Body>{imageUploadDialog()}</Modal.Body>
      </Modal>
      <Modal
        show={showImageUploadSuccessDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={onPressViewGallery}>
        <Modal.Body>{imageUploadSuccessDialog()}</Modal.Body>
      </Modal>
      <div className="d-flex flex-row-reverse mi-hr-activity-btn-container">
        <CustomButton
          buttonStyle={`btn-load-more common-cursor-pointer`}
          onClick={onPressUploadPhotos}>
          Upload Photos
        </CustomButton>
      </div>
      {gallery.length > 0 && (
        <div className="mt-2">
          <div className="womens-day-date-view-style">
            <div className="womens-day-no-of-photos">{`${noOfPhotos} ${
              noOfPhotos === 1 ? 'Photo' : 'Photos'
            }`}</div>
            <div onClick={onPressRecent} className="womens-day-button-style">
              <div className="womens-day-main-view common-cursor-pointer">
                <div className="womens-day-date-recent-text">{isRecent ? 'Date' : 'Recent'}</div>
                {isRecent ? (
                  <img
                    src={getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.BREAKOUTS_CALENDER_FILTER,
                      ACCESSIBILITY_IDENTIFIERS.BREAKOUTS
                    )}
                    className="womens-day-recent-date-icon"
                  />
                ) : (
                  <img
                    src={getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.BREAKOUTS_RECENT_FILTER,
                      ACCESSIBILITY_IDENTIFIERS.BREAKOUTS
                    )}
                    className="womens-day-recent-date-icon"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {gallery.length > 0 &&
        gallery.map((item, index) => {
          return <div key={item.title}>{renderItem(item, index)}</div>;
        })}
      {showNoImagesFound == true && gallery?.length == 0 && (
        <DefaultContentView message={ERROR_MESSAGES.NO_IMAGES_TO_SHOW} />
      )}
    </div>
  );
};

export default React.memo(GalleryTab);
