import React from 'react';
import Divider from '../../../common/ui/divider/Divider';
import { useSelector } from 'react-redux';
import { getSecuredPdfUrl } from '../../../utils/common';
import { selectWebBaseUrl } from '../../../state/MasterData';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
} from '../../../common/constants/AppConstants';

const EightygCertificateLogListItem = ({ item, downloadImagePath, isFirstItem }) => {
  const webUrl = useSelector(selectWebBaseUrl);

  const onPressDownload = () => {
    window.open(getSecuredPdfUrl(webUrl, item?.encoded_url), '_blank');
  };

  return (
    <div className="col-sm-12 mb-3">
      <div className="microgive-view-style d-flex w-50">
        <div className="row pr-3 justify-content-between w-50">
          <div className="w-50 d-flex align-items-center">
            <div className="microgiver-fy-text">{`FY ${item.financial_year}`}</div>
          </div>

          <div className="d-flex flex-row align-items-center justify-content-between w-50">
            <div
              className="d-flex flex-row align-items-center common-cursor-pointer flex-wrap justify-content-center"
              onClick={() => {
                logEvent(
                  ANALYTICS_EVENT_TYPES.EIGHTYG_DOWNLOAD_CERTIFICATE,
                  '',
                  ANALYTICS_ITEM_NAMES.EIGHTYG,
                  `${item?.certificate_id}`
                );
                onPressDownload();
              }}>
              <img src={downloadImagePath} className="eightyg-download-icon" />
              <div className="eightyg-certificate">Download</div>
            </div>
          </div>
        </div>
      </div>
      {isFirstItem ? null : <Divider style="ml-n3 mt-3 divider-width-style" />}
    </div>
  );
};

export default EightygCertificateLogListItem;
