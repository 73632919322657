import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  FEATURE_TYPE,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../common/constants/AppConstants';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { useDispatch, useSelector } from 'react-redux';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import { AUCTION, COMMENT_BY_ID } from '../../services/ApiUrls';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import { apiRequest, handleError } from '../../services/Service';
import { showToast } from '../../utils/common';
import AuctionItem from './components/AuctionItem';
import debounce from 'lodash.debounce';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import ActivityLoader from '../../common/ui/activity_loader/ActivityLoader';
import { selectAccessData } from '../../state/UserAccessData';

const MiAuctionMainScreen = ({ history, location }) => {
  const dispatch = useDispatch();
  const pageIdRef = useRef(1);
  const loadMoreFlagRef = useRef(true);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  const [auctions, setAuctions] = useState([]);
  const textInputRef = useRef();
  const accessibilityData = useSelector(selectAccessibilityData);
  const searchTextRef = useRef(location?.hash ?? '');
  const [isSearchEnabled, setIsSearchEnabled] = useState(location?.hash ? true : false);
  const inputSearchRef = useRef();
  const [quickSearchLoading, setQuickSearchLoading] = useState(false);
  const accessData = useSelector(selectAccessData);
  const [showLikeButton, setShowLikeButton] = useState(true);
  const [showCommentButton, setShowCommentButton] = useState(true);
  const [showBid, setShowBid] = useState(true);
  const auctionAccessData = accessData?.data?.find((item) => item.feature_key === '_miAuction');

  useEffect(() => {
    const actions = auctionAccessData?.actions;
    setShowLikeButton(actions?.includes('_like'));
    setShowCommentButton(actions?.includes('_comment'));
    setShowBid(actions?.includes('_bid'));
  }, [auctionAccessData?.actions]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.MI_AUCTION, NAVIGATION_ROUTES.MI_AUCTION);
  }, []);

  useEffect(() => {
    if (inputSearchRef.current && location?.hash) {
      inputSearchRef.current.value = location?.hash;
      logEvent(ANALYTICS_EVENT_TYPES.HASHTAG_QUERY, location?.hash, ANALYTICS_ITEM_NAMES.HASHTAG);
    }
  }, [location?.hash, inputSearchRef.current]);

  const callAuctionsList = useCallback(
    async (isQuickSearch = false) => {
      if (isQuickSearch) {
        setQuickSearchLoading(true);
      } else {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
          })
        );
      }
      let params = { page_id: pageIdRef.current };
      if (searchTextRef.current !== '') {
        params = { ...params, query: searchTextRef.current };
      }
      try {
        const apiResponse = await apiRequest(AUCTION, REQUEST_TYPE.GET, params);
        if (Object.keys(apiResponse).length > 0) {
          if (pageIdRef.current === 1) {
            setAuctions([]);
          }
          if (apiResponse.response.status === true) {
            const data = apiResponse.response.feeds;
            if (data.length <= 0) {
              loadMoreFlagRef.current = false;
            }
            if (pageIdRef.current !== 1) {
              setAuctions((prev) => [...prev, ...data]);
            } else {
              setAuctions(data);
            }
          }
        }
        setQuickSearchLoading(false);
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      } catch (err) {
        handleError(err, params, AUCTION, NAVIGATION_ROUTES.MI_AUCTION);
        setQuickSearchLoading(false);
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    callAuctionsList();
  }, [callAuctionsList]);

  const loadMoreHandler = () => {
    if (loadMoreFlagRef.current) {
      pageIdRef.current = pageIdRef.current + 1;

      callAuctionsList();
    }
  };

  const callLikeApi = async (referId) => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
      })
    );
    const params = new URLSearchParams();
    params.append('type', FEATURE_TYPE.MI_AUCTION);
    try {
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + referId + '/like',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            const message = apiResponse.response.message;
            if (message) {
              showToast(message);
              let element = auctions.find((item) => item.item_id === referId);
              element.liked = data.liked;
              element.like_count = data.like_count;
              setAuctions(auctions.map((item) => (referId === item.item_id ? element : item)));
            }
          }
        }
      }
    } catch (err) {
      handleError(err, params, COMMENT_BY_ID + referId + '/like', NAVIGATION_ROUTES.MI_AUCTION);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  const onPressLikeButton = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    logEvent(
      ANALYTICS_EVENT_TYPES.AUCTION_LIKE_CLICK,
      item.item_id.toString(),
      ANALYTICS_ITEM_NAMES.LIKE
    );
    callLikeApi(item.item_id);
  };

  const onPressCommentButton = (e, item) => {
    e.stopPropagation();
    e.preventDefault();
    logEvent(
      ANALYTICS_EVENT_TYPES.AUCTION_COMMENT_CLICK,
      item.item_id.toString(),
      ANALYTICS_ITEM_NAMES.COMMENT
    );
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: item.item_id,
      likeType: FEATURE_TYPE.MI_AUCTION,
      fromRoute: NAVIGATION_ROUTES.MI_AUCTION,
    });
  };

  const renderAuction = (item, index) => {
    return (
      <AuctionItem
        auction={item}
        key={index}
        onPressCommentButton={onPressCommentButton}
        onPressLikeButton={onPressLikeButton}
        showLikeButton={showLikeButton}
        showCommentButton={showCommentButton}
        showBid={showBid}
      />
    );
  };

  const onSearchSubmitHandler = (event) => {
    event.preventDefault();
    logEvent(ANALYTICS_EVENT_TYPES.DIRECTORY_SEARCH_QUERY, '', searchTextRef.current);
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    callAuctionsList(searchTextRef.current);
  };

  const debouncedCallback = (event) => {
    if (event && event.target.value.length > 2) {
      searchTextRef.current = event.target.value;
      pageIdRef.current = 1;
      loadMoreFlagRef.current = true;
      callAuctionsList(true);
    }
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );

  const resetSearchHandler = useCallback(() => {
    textInputRef.current.reset();
    searchTextRef.current = '';
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    callAuctionsList();
    setIsSearchEnabled(false);
  }, [callAuctionsList]);

  const onClickSearchHandler = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.DIRECTORY_SEARCH_CLICKED,
      '',
      ANALYTICS_ITEM_NAMES.DIRECTORY_SEARCH
    );
    setIsSearchEnabled(true);
  };

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right">
        {isSearchEnabled ? (
          <form
            className="directory-search-form"
            onSubmit={onSearchSubmitHandler}
            ref={textInputRef}>
            <input
              autoFocus
              className="ml-3 directory-search-bar"
              type="text"
              placeholder="Search.."
              ref={inputSearchRef}
              onChange={(event) => {
                searchTextRef.current = event.target.value;
              }}
            />
          </form>
        ) : (
          <div />
        )}
        {isSearchEnabled ? (
          <div className="gcdo-icon-row mr-3" onClick={resetSearchHandler}>
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
              )}
              className="header-icon"
            />
          </div>
        ) : (
          <div className="gcdo-icon-row mr-3" onClick={onClickSearchHandler}>
            <img
              src={getHeaderComponentUrl(accessibilityData, ACCESSIBILITY_HEADER_COMPONENTS.SEARCH)}
              className="header-icon"
            />
          </div>
        )}
      </div>

      <SectionHeader title={'MiAUCTION'} />
      <div className="py-3 common-container-md mx-auto">
        <ActivityLoader visible={quickSearchLoading} />
        <div>
          {auctions && auctions.length > 0
            ? auctions.map((item, index) => renderAuction(item, index))
            : !progressLoadingState.isProgressLoading && (
                <DefaultContentView style="mt-3" message={ERROR_MESSAGES.NO_ONGOING_AUCTION} />
              )}
        </div>
      </div>
      {loadMoreFlagRef.current && !!auctions.length && (
        <div className="mb-4 mt-4">
          <LoadMoreBtn
            onClick={loadMoreHandler}
            isLoading={progressLoadingState.isProgressLoading}
          />
        </div>
      )}
    </div>
  );
};

export default MiAuctionMainScreen;
