import React from 'react';
import { useSelector } from 'react-redux';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import CustomCheckBox from '../../../common/ui/custom_checkbox/CustomCheckBox';

const ChecklistSectionItem = (props) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const checklistItemId = props.data.item_id;
  const checklistText = props.data.item_text;
  const selectedItems = props.selectedItems;
  const onSelectHandler = props.onSelectHandler;
  const url = props.data.url ? props.data.url : null;

  return (
    <div className="d-flex align-items-center mt-3 mb-3">
      <div className="mr-3">
        <CustomCheckBox
          value={selectedItems.includes(checklistItemId) ? true : false}
          onValueChange={() => {
            if (selectedItems.includes(checklistItemId)) {
              onSelectHandler(selectedItems.filter((id) => id !== checklistItemId));
            } else {
              onSelectHandler([...selectedItems, checklistItemId]);
            }
          }}
        />
      </div>
      <div
        onClick={() => {
          if (url) {
            window.open(url, '_blank');
          }
        }}>
        <div className="d-flex justify-content-between align-items-center">
          <p className="m-0 new-joinees-lower-levels-checklists-item-text">{checklistText}</p>
          {!!url && (
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_LINK,
                ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
              )}
              className="new-joinees-lower-levels-checklists-item-link-icon common-cursor-pointer ml-2"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChecklistSectionItem;
