import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPandemicSupportTabs } from '../../state/MasterData';
import { selectBaseUrl } from '../../state/UIState';
import { Tab } from 'react-bootstrap';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ACTION_TYPES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  PANDEMIC_SUPPORT_IDENTIFIERS,
} from '../../common/constants/AppConstants';
import AdvisoriesTab from './tabs/AdvisoriesTab';
import GuideTab from './tabs/GuideTab';
import { savepandemicSupportTabData, selectpandemicSupportTabData } from '../../state/TabData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import NavTabs from '../../common/ui/tabs/NavTabs';

const PandemicSupportTabScreen = ({ location }) => {
  const pandemicSupportTabs = useSelector(selectPandemicSupportTabs);
  const pandemicSupportTabData = useSelector(selectpandemicSupportTabData);
  const dispatch = useDispatch();
  const baseUrl = useSelector(selectBaseUrl);
  const [id] = useState(new URLSearchParams(location.search).get('id'));

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.PANDEMIC_SUPPORT, NAVIGATION_ROUTES.PANDEMIC_SUPPORT);
  }, []);

  useEffect(() => {
    if (baseUrl !== '' && id && id !== '') {
      dispatch({ type: ACTION_TYPES.GET_TOKEN, payload: { id: id } });
    }
  }, [baseUrl, dispatch, id]);

  useEffect(() => {
    if (pandemicSupportTabs.length && pandemicSupportTabData === '') {
      dispatch(savepandemicSupportTabData(pandemicSupportTabs[0].key));
    }
  }, [dispatch, pandemicSupportTabData, pandemicSupportTabs]);

  const renderTabs = (tab) => {
    switch (tab.key) {
      case PANDEMIC_SUPPORT_IDENTIFIERS.ADVISORIES:
        return <AdvisoriesTab item={tab} />;
      case PANDEMIC_SUPPORT_IDENTIFIERS.PANDEMIC_GUIDE:
        return <GuideTab />;
      case PANDEMIC_SUPPORT_IDENTIFIERS.CONVO:
        return null;
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.PANDEMIC_SUPPORT} />
      {baseUrl !== '' && (
        <NavTabs
          activeKey={pandemicSupportTabData}
          onSelect={(k) => dispatch(savepandemicSupportTabData(k))}>
          {pandemicSupportTabs &&
            pandemicSupportTabs.map((tab, index) => {
              return (
                <Tab
                  key={`${index}`}
                  eventKey={tab.key}
                  title={tab.title}
                  className="main-content-container">
                  {renderTabs(tab, index)}
                </Tab>
              );
            })}
        </NavTabs>
      )}
    </div>
  );
};

export default PandemicSupportTabScreen;
