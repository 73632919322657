import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
} from '../../../common/constants/AppConstants';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import { selectWebBaseUrl } from '../../../state/MasterData';
import { getSecuredPdfUrlForID } from '../../../utils/common';

const MyIdCardTab = (props) => {
  const [isLoaded, setIsLoaded] = useState(true);
  const webUrl = useSelector(selectWebBaseUrl);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.MY_PROFILE_IDCARD_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.MY_PROFILE
    );
  }, []);

  const downloadIDCard = () => {
    let url = getSecuredPdfUrlForID(webUrl);
    window.open(url, '_blank').focus();
  };

  return (
    <div className="main-content-container">
      <div className="d-flex flex-row-reverse mi-hr-activity-btn-container">
        <CustomButton onClick={downloadIDCard}>Download ID Card</CustomButton>
      </div>
      <ActivityLoader visible={isLoaded} />

      <div className="iframe-container">
        <iframe
          src={getSecuredPdfUrlForID(webUrl)}
          allowFullScreen
          onLoad={() => setIsLoaded(false)}
        />
      </div>
    </div>
  );
};

export default React.memo(MyIdCardTab);
