import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getHeaderComponentUrl } from '../../../utils/accessibility';
import { formatStartAndEndDate } from '../../../utils/common';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import SpeakersAttendeesListItem from './SpeakersAttendeesListItem';

const AgendaTabListItem = ({
  onPressAskViewQues,
  listItem,
  onPressRate,
  onPressItem,
  breakoutId,
}) => {
  const [formattedDate, setFormattedDate] = useState('');

  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    const value = formatStartAndEndDate(listItem.start_datetime, listItem.end_datetime);
    setFormattedDate(value);
  }, [listItem.start_datetime, listItem.end_datetime]);

  return (
    <div
      onClick={() => onPressItem(listItem)}
      className="mt-3 mb-3 common-cursor-pointer material-card p-3">
      <div className="breakouts-agenda-title-text">{listItem.title}</div>
      <div className="breakouts-agenda-date-text">{formattedDate}</div>
      {listItem.speakers.length > 0 && listItem.enable_speaker && (
        <div className="mt-2 breakouts-speakers-text">Speakers</div>
      )}
      {listItem.speakers.length > 0 &&
        listItem.enable_speaker &&
        listItem.speakers.map((item, index) => {
          return <SpeakersAttendeesListItem item={item} key={`${index}`} />;
        })}
      {listItem.enable_rate && (
        <div className="breakouts-rating-view mt-2">
          {Object.keys(listItem.ratings).length > 0 && listItem.ratings.avg_rate !== '' && (
            <div className="new-joinees-higher-levels-card-rate-container breakouts-rating-view mr-2 p-2">
              <div className="breakouts-rate-text mr-2">{listItem.ratings.avg_rate}</div>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.BREAKOUTS_RATE_STAR,
                  ACCESSIBILITY_IDENTIFIERS.BREAKOUTS
                )}
                className="breakouts-rate-icon"
              />
            </div>
          )}
          <CustomButton
            buttonStyle="breakouts-button-style ml-3 mr-2"
            onClick={(e) => {
              e.stopPropagation();
              onPressRate(listItem);
            }}>
            Rate
          </CustomButton>
          {listItem.enable_speaker && (
            <CustomButton
              buttonStyle="breakouts-button-style ml-3"
              onClick={(e) => {
                e.stopPropagation();
                onPressAskViewQues(listItem, breakoutId);
              }}>
              {listItem.question_count !== 0
                ? `Ask/View Questions (${listItem.question_count})`
                : 'Ask/View Questions'}
            </CustomButton>
          )}
        </div>
      )}
    </div>
  );
};

export default AgendaTabListItem;
