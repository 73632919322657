import React, { useEffect } from 'react';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
} from '../../../../common/constants/AppConstants';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';

const RulesTab = ({ terms }) => {
  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.DIGITAL_WARRIOR_RULES_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.DIGITAL_WARRIOR
    );
  }, []);

  return (
    <div className="main-content-container">
      <div className="iframe-container">
        <iframe src={terms} allowFullScreen></iframe>
      </div>
    </div>
  );
};

export default RulesTab;
