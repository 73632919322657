import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../../common/constants/AppConstants';
import { useHistory } from 'react-router';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../../../utils/accessibility';
import {
  formatDateDDMonth,
  formatDateDDMonthYYYY,
  formatSecondsIntoMinutes,
  formatSecondsIntoTimeForVideos,
  formatTimeAMPM,
  getUrlWithSpecificRendition,
} from '../../../../utils/common';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import Divider from '../../../../common/ui/divider/Divider';
import DefaultContentView from '../../../../common/ui/default_content_view/DefaultContentView';
import UserProfilePhoto from '../../../../common/ui/user_profile_photo/UserProfilePhoto';
import ActivityLoader from '../../../../common/ui/activity_loader/ActivityLoader';
import NewJoineesFilterItem from '../../components/NewJoineesFilterItem';
import {
  saveNewJoineesTabCategoryFilterId,
  selectNewJoineesCategoryFilterId,
} from '../../../../state/TabData';
import { apiRequest, handleError } from '../../../../services/Service';
import { INCREASE_VIEW_COUNT_EXTERNAL_LINK } from '../../../../services/ApiUrls';
import PlayIconOverlay from '../../../../common/ui/play_icon_overlay/PlayIconOverlay';
const HigherLevelsSessionTab = (props) => {
  const history = useHistory();
  const accessibilityData = useSelector(selectAccessibilityData);
  const getTabData = props.getTabData;
  const filtersList = props?.tab?.filters ?? [];
  const NewJoineesCategoryFilterId = useSelector(selectNewJoineesCategoryFilterId);
  const dispatch = useDispatch();
  const articleData = props?.data?.[0]?.article ?? {};

  useEffect(() => {
    getTabData(props.tabKey, 1);
  }, [props.tabKey, getTabData]);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.NEW_JOINEES_AGENDA_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.NEW_JOINEES_HIGHER_LEVELS
    );
  }, []);

  const onPress = (item) => {
    props.callNewJoineesApi(item?.filter_key ?? '', 1);
    dispatch(saveNewJoineesTabCategoryFilterId(item?.filter_id));
  };

  const onPressItem = async (item) => {
    if (item.content_load_type === '1') {
      try {
        const es = await apiRequest(
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          REQUEST_TYPE.GET,
          {}
        );
      } catch (err) {
        handleError(
          err,
          {},
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          NAVIGATION_ROUTES.NEW_JOINEES_LOWER_LEVELS
        );
        //ignore if the view count request is not successful
        console.warn(err);
      }
      window.open(item.url);
    } else {
      history.push(`${NAVIGATION_ROUTES.NEW_JOINEES_LOWER_LEVELS}/${item.article_id}`, {
        headerTitle: ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES,
      });
    }
  };

  const _renderArticle = (item) => {
    return (
      <div
        className="new-joinee-article-style common-cursor-pointer"
        onClick={() => onPressItem(item)}>
        <div>
          {item?.content_load_type === '3' && <PlayIconOverlay customizeForNewJoineees={true} />}
          <img
            src={getUrlWithSpecificRendition(
              item?.image?.base_url,
              SCREEN_DENSITY_QUALIFIERS.HDPI,
              item?.image?.image_path
            )}
            className="embed-responsive embed-responsive-16by9"
          />
          <div className="row ml-1 mt-1">
            <div className="d-flex flex-row justify-content-between w-100 pr-3">
              {!!item?.time_to_read && (
                <div className="broadcast-event-date-new ">
                  {item.time_to_read != 0 ? formatSecondsIntoMinutes(item.time_to_read) : null}
                </div>
              )}
              {!!item?.video_duration &&
                item?.content_load_type === '3' &&
                item?.video_duration > 0 && (
                  <div className="broadcast-event-date-new ">
                    {formatSecondsIntoTimeForVideos(item?.video_duration)}
                  </div>
                )}
              <div className="broadcast-event-date-new ">
                {formatDateDDMonthYYYY(item.pub_datetime)}
              </div>
              {!!item.page_views && (
                <div className="broadcast-event-date-new ">
                  {item.page_views > 1 ? `${item.page_views} views` : `${item.page_views} view`}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="broadcast-title">{item?.title}</div>
      </div>
    );
  };

  const renderSessionItem = (data) => {
    if (data?.sessions?.length === 0 && NewJoineesCategoryFilterId === 1) {
      return (
        <div>
          <DefaultContentView message={ERROR_MESSAGES.NO_UPCOMING_SESSIONS} />
        </div>
      );
    } else if (data?.sessions?.length === 0 && NewJoineesCategoryFilterId === 2) {
      return (
        <div>
          <DefaultContentView message={ERROR_MESSAGES.NO_PAST_SESSIONS} />
        </div>
      );
    } else {
      return (
        !!data?.sessions?.length &&
        data?.sessions?.map((data) => {
          return (
            <div
              key={data?.session_id?.toString()}
              className="material-card p-3 mb-3 d-flex flex-column w-100">
              <div className="d-flex flex-row w-100">
                <div
                  onClick={() => {
                    history.push(NAVIGATION_ROUTES.NEWJOINEE_SESSION_DETAILS, {
                      data: data,
                      fromNewJoineesHigherLevelsSessionDetailRoute: true,
                      identifier: ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES,
                    });
                  }}
                  className="d-flex flex-row w-100 new-joinee-top-card-container">
                  <div className="calendar-card mr-3 d-flex justify-content-center align-items-center flex-column">
                    <h5 className="mt-3 mb-n1">
                      {formatDateDDMonth(data.start_datetime).slice(0, 2)}
                    </h5>
                    <h6 className="mb-3">{formatDateDDMonth(data.start_datetime).slice(3, 6)}</h6>
                  </div>
                  <div>
                    <div className="pb-3 new-joinees-title-container">
                      <div className="title_subtitle_container">
                        {' '}
                        <div className="new-joinees-higher-levels-card-main-title mb-1 mr-2 align-self-center">
                          {data.title}
                        </div>
                        <p className="new-joinees-higher-levels-card-sub-title mb-2">
                          {formatTimeAMPM(data.start_datetime)} to{' '}
                          {formatTimeAMPM(data.end_datetime)} (IST)
                        </p>
                      </div>
                    </div>
                  </div>
                  <img
                    src={getHeaderComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_RIGHT_ARROW,
                      ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
                    )}
                    className="anniversary-arrow-icon d-flex align-self-center common-cursor-pointer ml-auto mt-n4"
                  />
                </div>
              </div>
              <div className="d-flex flex-column profile-container">
                {data?.speakers?.length > 1 ? (
                  <div className="new-joinees-higher-levels-card-main-title mr-4 mt-3 mb-3">
                    Speakers
                  </div>
                ) : (
                  <div className="new-joinees-higher-levels-card-main-title mr-4 mt-3 mb-3">
                    Speaker
                  </div>
                )}
                <div
                  className={
                    data?.speakers?.length > 2
                      ? 'speaker-grid-container'
                      : 'd-flex flex-row flex-wrap speaker-conatiner'
                  }>
                  {data?.speakers?.map((speaker, index) => {
                    return (
                      <div
                        key={`${index}`}
                        className={
                          data?.speakers?.length > 2
                            ? 'd-flex flex-row profile-conatiner mb-3'
                            : 'd-flex flex-row w-50'
                        }>
                        <UserProfilePhoto
                          imageBaseUrl={speaker.profile_picture.base_url}
                          imagePath={speaker.profile_picture.image_path}
                          employeeId={speaker.employee_id}
                          imageClass={
                            data?.speakers?.length > 2
                              ? 'common-user-profile-photo-md mb-3'
                              : 'common-user-profile-photo-md'
                          }
                        />
                        <div className="divider-space d-flex flex-row justify-content-between">
                          <div className="pr-2">
                            <p className="new-joinees-higher-levels-card-speaker-title m-0 ml-3">
                              {`${speaker.employee_name}`.length < 30
                                ? `${speaker.employee_name}`
                                : `${speaker.employee_name}`.substring(0, 26) + '...'}
                            </p>
                            <p className="new-joinees-higher-levels-card-speaker-subtitle m-0 ml-3">
                              {speaker.position_title}
                            </p>
                          </div>
                          <div
                            className={`${
                              (index + 1) % 3 !== 0 && index !== data?.speakers?.length - 1
                                ? 'ver-div'
                                : ''
                            }`}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })
      );
    }
  };

  return (
    <div>
      <div className="d-flex flex-row flex-1 justify-content-center ">
        <div className="d-flex flex-row mb-4 mt-3">{_renderArticle(articleData)}</div>
      </div>
      <Divider style="mb-2 mt-2" />
      {!!props?.data?.[0].show_sessions && (
        <div>
          <div className="d-flex flex-row align-self-start new-joinees-schedule-text mb-4 mt-4">
            Schedule
          </div>
          <div>
            {filtersList?.length > 0 && (
              <div className="microgive-category-container ml-n1 mb-4">
                {filtersList?.map((item) => (
                  <NewJoineesFilterItem
                    key={`${item?.filter_id}`}
                    item={item}
                    filterId={NewJoineesCategoryFilterId}
                    onPress={onPress}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      {!!props.data && !!props?.data?.length && (
        <div>
          {props?.data?.map((session) => renderSessionItem(session))}
          <ActivityLoader visible={props.isLoading} />
        </div>
      )}
      {!props.isLoading && !!props.data && !props?.data?.length && (
        <DefaultContentView message={`Sorry, no ${props.tabTitle.toLowerCase()} available`} />
      )}
    </div>
  );
};

export default React.memo(HigherLevelsSessionTab);
