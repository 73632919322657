import React, { useEffect, useState } from 'react';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
} from '../../../common/constants/AppConstants';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';

const NeoAttendeesTab = ({ attendees, loadmoreFlag, callNeoSessionDetailApi }) => {
  const [pageId, setPageId] = useState(1);

  const loadMore = () => {
    if (loadmoreFlag) {
      callNeoSessionDetailApi(pageId + 1);
      setPageId(pageId + 1);
    }
  };

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.NEO_ATTENDEES_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.NEW_JOINEES_HIGHER_LEVELS
    );
  }, []);

  useEffect(() => {
    callNeoSessionDetailApi();
  }, [callNeoSessionDetailApi]);

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_ATTENDEES} />;
  };

  const renderAttendees = (speaker) => {
    return (
      <div>
        <div className="d-flex flex-row mt-2">
          <UserProfilePhoto
            imageBaseUrl={speaker.profile_picture.base_url}
            imagePath={speaker.profile_picture.image_path}
            employeeId={speaker.employee_id}
            imageClass={'common-user-profile-photo-md'}
          />
          <div className="divider-space d-flex flex-row justify-content-between">
            <div className="pr-2">
              <p className="new-joinees-higher-levels-card-speaker-title m-0 ml-3">
                {`${speaker.employee_name}`.length < 30
                  ? `${speaker.employee_name}`
                  : `${speaker.employee_name}`.substring(0, 26) + '...'}
              </p>
              <p className="new-joinees-higher-levels-card-speaker-subtitle m-0 ml-3">
                {speaker.position_title}
              </p>
              {speaker?.attended &&
                (speaker?.attended == 1 ? (
                  <p className="neo-attended-text m-0 ml-3">Attended</p>
                ) : (
                  <p className="neo-not-attended-text m-0 ml-3">Not Attended</p>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="mt-3">
      {attendees?.length === 0
        ? emptyListView()
        : attendees?.map((item, index) => {
            return <div key={`${index}`}>{renderAttendees(item)}</div>;
          })}
      {loadmoreFlag && (
        <div className="mb-4 mt-4">
          <LoadMoreBtn onClick={loadMore} />
        </div>
      )}
    </div>
  );
};

export default NeoAttendeesTab;
