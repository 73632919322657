import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RecipientsTab from '../leaderBoardTabs/RecipientsTab';
import NominatorsTab from '../leaderBoardTabs/NominatorsTab';
import { selectCheersLeadersBoard } from '../../../../state/MasterData';
import { Tab } from 'react-bootstrap';
import {
  saveCheersLeadersBoardTabData,
  selectCheersLeadersBoardTabData,
} from '../../../../state/TabData';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
} from '../../../../common/constants/AppConstants';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import NavTabs from '../../../../common/ui/tabs/NavTabs';

const LeadersBoardTab = () => {
  const dispatch = useDispatch();
  const leadersBoard = useSelector(selectCheersLeadersBoard);
  const leadersBoardTabData = useSelector(selectCheersLeadersBoardTabData);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.LEADERS_BOARD_TAB_VISIT, '', ANALYTICS_ITEM_NAMES.CHEERS);
  }, []);

  useEffect(() => {
    if (leadersBoard.length && leadersBoardTabData === '') {
      dispatch(saveCheersLeadersBoardTabData(leadersBoard[0].key));
    }
  }, [dispatch, leadersBoard, leadersBoardTabData]);

  const renderTabs = (tab) => {
    switch (tab.key) {
      case 'nominators':
        return <NominatorsTab />;
      case 'recipients':
        return <RecipientsTab />;
    }
  };

  return (
    <div>
      <NavTabs
        activeKey={leadersBoardTabData}
        onSelect={(k) => dispatch(saveCheersLeadersBoardTabData(k))}>
        {leadersBoard.map((tab, index) => {
          return (
            <Tab key={`${index}`} eventKey={tab.key} title={tab.title}>
              {renderTabs(tab)}
            </Tab>
          );
        })}
      </NavTabs>
    </div>
  );
};

export default React.memo(LeadersBoardTab);
