import React from 'react';
import { Spinner } from 'react-bootstrap';
import colors from '../../themes/Colors';

const ActivityLoader = (props) => {
  const { visible = false } = props;

  if (visible) {
    return (
      <div className="d-flex justify-content-center p-3">
        <Spinner
          animation="border"
          role="status"
          style={{ color: colors.primary }}
          size={props.size}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default ActivityLoader;
