import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { POST_COMMENT } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { isEmptyText, showToast } from '../../utils/common';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';

const QueryScreen = ({ history }) => {
  const [comment, setComment] = useState('');
  const dispatch = useDispatch();

  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.QUERY_SCREEN, NAVIGATION_ROUTES.QUERY_SCREEN);
  }, []);

  const callCommentApi = async () => {
    const params = new URLSearchParams();
    params.append('comment', comment);
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(POST_COMMENT, REQUEST_TYPE.POST, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          const message = apiResponse.response.message;
          if (message) {
            showToast(message);
            history.goBack();
          }
        }
      }
    } catch (err) {
      handleError(err, params, POST_COMMENT, NAVIGATION_ROUTES.QUERY_SCREEN);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPress = () => {
    if (!isEmptyText(comment)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      callCommentApi();
    }
  };

  const onChangeText = (event) => {
    setComment(event.target.value);
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICROGIVE} />
      <BackButton />
      <div className="main-content-container">
        <div className="form-group">
          <textarea
            className="form-control"
            rows="10"
            id="comment"
            placeholder="Please enter your comments/recommendations/queries"
            onChange={onChangeText}
            value={comment}
          />
        </div>
        <CustomButton
          buttonStyle="microgive-button-style mr-3"
          onClick={onPress}
          showLoader={progressLoadingState.isProgressLoading}>
          Submit
        </CustomButton>
      </div>
    </div>
  );
};

export default QueryScreen;
