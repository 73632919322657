import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { saveShowEmptyContentView, selectShowEmptyContentView } from '../../../state/UIState';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../../services/Service';
import { GALLERIES } from '../../../services/ApiUrls';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import MlDayGalleryImageView from '../components/MlDayGalleryImageView';

const MlDayGalleryTab = ({ tabKey, appliedFilters, tab, date }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [galleries, setGalleries] = useState([]);
  const [loadMoreFlag, setLoadMoreFlag] = useState(true);
  const [pageId, setPageId] = useState(1);
  const [filters, setFilters] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [loading, setLoading] = useState(true);

  const showEmptyContentView = useSelector(selectShowEmptyContentView);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.GALLERY_TAB_VISIT, tabKey, ANALYTICS_ITEM_NAMES.GALLERY);
  }, [tabKey]);

  useEffect(() => {
    setFilters(appliedFilters);
    setPageId(1);
    setLoadMoreFlag(true);
    setGalleries([]);
  }, [appliedFilters]);

  useEffect(() => {
    if (setSelectedDate !== date) {
      setPageId(1);
      setLoadMoreFlag(true);
      setGalleries([]);
      setSelectedDate(date);
    }
  }, [date]);

  const callgalleriesApi = useCallback(
    async (id, loadMore, type, appliedFilters, selectedDate) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        let tempParams = {
          page_id: id,
          type: type,
          recent: true,
        };
        tempParams = { ...tempParams, date: selectedDate };
        if (tab.geo_filter === true && tab.feature === type) {
          if (Object.keys(appliedFilters).length) {
            tempParams = { ...tempParams, ...appliedFilters };
          }
        }
        try {
          setLoading(true);
          const apiResponse = await apiRequest(GALLERIES, REQUEST_TYPE.GET, tempParams);
          setLoading(false);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data) {
                const data = apiResponse.response.data;
                if (data.length > 0) {
                  setGalleries((oldArray) => [...oldArray, ...apiResponse.response.data]);
                  setLoadMoreFlag(true);
                } else {
                  setLoadMoreFlag(false);
                }
              }
            }
          }
        } catch (err) {
          handleError(err, tempParams, GALLERIES, NAVIGATION_ROUTES.GALLERY);
          setLoading(false);
        }
      }
    },
    [dispatch, tab.feature, tab.geo_filter]
  );

  useEffect(() => {
    if (filters !== undefined) {
      callgalleriesApi(pageId, loadMoreFlag, tabKey, filters, selectedDate);
    }
  }, [callgalleriesApi, filters, loadMoreFlag, pageId, tabKey, selectedDate]);

  const emptyListView = () => {
    return <DefaultContentView message={NO_DATA_TEXT.NO_DATA_FOUND} />;
  };

  const onPressMore = (pictures, baseUrl) => {
    history.push(NAVIGATION_ROUTES.GALLERY_COMMON, {
      baseUrl: baseUrl,
      pictures: pictures,
    });
  };

  const renderItem = (item, index) => {
    return (
      <MlDayGalleryImageView key={`${index}`} item={item} index={index} onPressMore={onPressMore} />
    );
  };

  return (
    <div className="main-content-container">
      {showEmptyContentView && !galleries.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          <div>{galleries.map((item, index) => renderItem(item, index))}</div>
          <ActivityLoader visible={loading} />
        </div>
      )}
    </div>
  );
};

export default MlDayGalleryTab;
