import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  ML_DAY_IDENTIFIERS,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
  SUPPORTED_IMAGE_FORMATS,
  SUPPORTED_IMAGE_FORMATS_WEB,
  WARNING_MESSAGES,
} from '../../common/constants/AppConstants';
import { UPLOAD_BREAKOUT_IMAGES } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { saveProgressLoadingState } from '../../state/UIState';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { Modal, Image } from 'react-bootstrap';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { getUrlWithSpecificRendition, showToast } from '../../utils/common';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { selectAnniversaryConfig, selectMaxFileUploadSizeConfig } from '../../state/MasterData';
import { uploadDocuments } from '../../utils/commonApiRequests';

const BreakoutsGalleryScreen = ({ location, history }) => {
  const dispatch = useDispatch();
  const [gallery] = useState(location.state.data);
  const { breakoutId, baseUrl, identifier } = location.state;
  const [showUploadImageDialog, setShowUploadImageDialog] = useState(false);
  const [showImageUploadSuccessDialog, setShowImageUploadSuccessDialog] = useState(false);

  const accessibilityData = useSelector(selectAccessibilityData);
  const maxFileUploadSizeInMB = useSelector(selectMaxFileUploadSizeConfig);
  const selectImagesInputRef = useRef(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagesLength, setSelectedImagesLength] = useState(0);
  const anniversaryConfig = useSelector(selectAnniversaryConfig);
  const selectedAnniversaryConfig = identifier
    ? anniversaryConfig.find((element) => element.identifier === identifier)
    : [];

  useEffect(() => {
    !identifier
      ? trackScreen(
          ANALYTICS_SCREEN_NAMES.BREAKOUT_GALLERY_SCREEN,
          NAVIGATION_ROUTES.BREAKOUT_GALLERY_SCREEN
        )
      : trackScreen(
          ANALYTICS_SCREEN_NAMES.ANNIVERSARY_GALLERY_SCREEN,
          NAVIGATION_ROUTES.BREAKOUT_GALLERY_SCREEN
        );
  }, [identifier]);

  const onPressItem = (item, index) => {
    history.push(NAVIGATION_ROUTES.GALLERY_FULL_IMAGE_SCREEN, {
      baseUrl: baseUrl,
      cards: item,
      cardIndex: index,
      identifier: identifier,
    });
  };

  const callUploadImagesApi = useCallback(
    async (images) => {
      setShowUploadImageDialog(false);
      const params = new URLSearchParams();
      params.append('files', JSON.stringify(images));
      try {
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        const apiResponse = identifier
          ? await apiRequest(identifier + '/upload', REQUEST_TYPE.POST, params)
          : await apiRequest(
              UPLOAD_BREAKOUT_IMAGES + breakoutId + '/upload',
              REQUEST_TYPE.POST,
              params
            );
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            setSelectedImages([]);
            setSelectedImagesLength(0);
            showToast(apiResponse.response.message);
            setShowImageUploadSuccessDialog(true);
          }
        }
      } catch (err) {
        handleError(
          err,
          params,
          UPLOAD_BREAKOUT_IMAGES + breakoutId + '/upload',
          NAVIGATION_ROUTES.BREAKOUT_GALLERY_SCREEN
        );
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    },
    [breakoutId, dispatch, identifier]
  );

  useEffect(() => {
    if (selectedImages.length === selectedImagesLength && selectedImagesLength !== 0) {
      callUploadImagesApi(selectedImages);
    }
  }, [callUploadImagesApi, selectedImages, selectedImagesLength]);

  const imageUploadDialog = () => {
    return (
      <div>
        <div className="breakouts-team-micro-view">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_UPLOAD_PHOTO,
              ACCESSIBILITY_IDENTIFIERS.MICROGIVE
            )}
            className="breakouts-upload-icon common-cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              selectImagesInputRef.current.click();
            }}
          />
          <input
            type="file"
            onChange={onFileChange}
            accept={SUPPORTED_IMAGE_FORMATS}
            id="input"
            ref={selectImagesInputRef}
            multiple
            onClick={(event) => {
              event.target.value = null;
            }}
            className="d-none"
          />
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            selectImagesInputRef.current.click();
          }}
          className="text-center selectImagesToUpload mt-2 common-cursor-pointer">
          Select images to be uploaded
        </div>
        <div className="text-center imagesReview mt-2 mb-3">
          All images to be reviewed by MicrolandOne App Admin.
        </div>
      </div>
    );
  };

  const onPressViewGallery = () => {
    setShowImageUploadSuccessDialog(false);
  };

  const imageUploadSuccessDialog = () => {
    return (
      <div>
        <div className="breakouts-team-micro-view ">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_SUCCESS,
              ACCESSIBILITY_IDENTIFIERS.MICROGIVE
            )}
            className="breakouts-upload-icon mr-4"
          />
        </div>
        <div className="breakouts-images-got-uploaded text-center mt-2">Images got uploaded</div>
        <div className="text-center mt-2 breakouts-images-under-review">
          Your Images are under review
        </div>
        <div className="text-center mt-2 breakouts-images-reviewed-by">
          All images to be reviewed by MLOne App Admin.
        </div>
        <CustomButton buttonStyle="breakouts-button-style mt-3" onClick={onPressViewGallery}>
          View Gallery
        </CustomButton>
      </div>
    );
  };

  const onPressUploadPhotos = () => {
    setShowUploadImageDialog(true);
  };

  const onFileChange = (event) => {
    const images = [...event.target.files];
    let isAllFilesSupported = true;
    let isAnyFileSizeLarge = false;
    [...event.target.files].forEach((element) => {
      if (!SUPPORTED_IMAGE_FORMATS_WEB.includes(element.type)) {
        isAllFilesSupported = false;
      }
      if (Number(element.size) / (1024 * 1024) > maxFileUploadSizeInMB) {
        isAnyFileSizeLarge = true;
      }
    });
    if (!isAllFilesSupported) {
      setShowUploadImageDialog(false);
      showToast(WARNING_MESSAGES.FILE_NOT_SUPPORTED);
    } else if (isAnyFileSizeLarge) {
      setShowUploadImageDialog(false);
      showToast(WARNING_MESSAGES.FILE_SIZE_EXCEEDED(maxFileUploadSizeInMB));
    } else {
      if (identifier && images?.length > (selectedAnniversaryConfig?.images_limit ?? 5)) {
        showToast(
          WARNING_MESSAGES.MAXIMUM_NO_OF_IMAGE_FILES(selectedAnniversaryConfig?.images_limit ?? 5)
        );
        return;
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      setSelectedImagesLength(event.target.files?.length);
      [...event.target.files].map((image) => {
        uploadDocuments(
          image,
          identifier === ML_DAY_IDENTIFIERS.ML_35_IDENTIFIER ? 'anniversary' : 'breakouts'
        )
          .then((response) => {
            setSelectedImages((prev) => [...prev, { filename: response.fileName, type: 'image' }]);
          })
          .catch((message) => {
            showToast(message);

            dispatch(
              saveProgressLoadingState({
                isProgressLoading: false,
              })
            );
          });
      });
    }
  };

  return (
    <div className="container">
      <SectionHeader
        identifier={
          identifier === ML_DAY_IDENTIFIERS.ML_35_IDENTIFIER
            ? ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
            : ACCESSIBILITY_IDENTIFIERS.BREAKOUTS
        }
      />
      <BackButton />
      <div className="main-content-container">
        <Modal
          show={showUploadImageDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => setShowUploadImageDialog(false)}>
          <Modal.Body>{imageUploadDialog()}</Modal.Body>
        </Modal>
        <Modal
          show={showImageUploadSuccessDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => setShowImageUploadSuccessDialog(false)}>
          <Modal.Body>{imageUploadSuccessDialog()}</Modal.Body>
        </Modal>
        <div className="header-view pt-0 mb-2">
          <CustomButton buttonStyle="breakouts-upload-photos-btn" onClick={onPressUploadPhotos}>
            Upload Photos
          </CustomButton>
        </div>
        <div className="row">
          {gallery.map((item, index) => {
            return (
              <div key={`${index}`} className="col-sm-4 mb-3">
                <div
                  onClick={() => {
                    onPressItem(gallery, index);
                  }}>
                  <Image
                    src={getUrlWithSpecificRendition(baseUrl, SCREEN_DENSITY_QUALIFIERS.HDPI, item)}
                    className="breakout-gallery-image"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BreakoutsGalleryScreen;
