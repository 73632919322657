import { all, fork } from 'redux-saga/effects';
import { watchAccessibility } from './AccessibilitySaga';
import { watchToken, watchTokenByEmail } from './AuthorizationSaga';
import { watchCheersPoints } from './CheersSaga';
import { watchEmployee } from './EmployeeSaga';
import { watchNotificationCount } from './NotificationSaga';
import { watchDashboard } from './HomeSaga';
import { watchMenu } from './MenuSaga';
import { watchTravelRequestData, watchTravelRequestMasterData } from './TravelRequestSaga';
import { watchAccess } from './UserAccessDataSaga';

export default function* rootSaga() {
  yield all([
    fork(watchAccessibility),
    fork(watchEmployee),
    fork(watchCheersPoints),
    fork(watchToken),
    fork(watchNotificationCount),
    fork(watchDashboard),
    fork(watchMenu),
    fork(watchTokenByEmail),
    fork(watchTravelRequestMasterData),
    fork(watchTravelRequestData),
    fork(watchAccess),
  ]);
}
