import { createSlice } from '@reduxjs/toolkit';

const saveIjprSelectedFilterDataReducer = (state, action) => {
  state.selectedFilters = action.payload;
};

const saveIjprFormattedSelectedFiltersDataReducer = (state, action) => {
  state.formattedSelectedFilters = action.payload;
};

const saveIjpReferSelectedFilterDataReducer = (state, action) => {
  state.referSelectedFilters = action.payload;
};

const saveIjpReferFormattedSelectedFiltersDataReducer = (state, action) => {
  state.formattedReferSelectedFilters = action.payload;
};

// REDUCER
const ijprDataSlice = createSlice({
  name: 'ijprData',
  initialState: {
    selectedFilters: {},
    formattedSelectedFilters: {},
    referSelectedFilters: {},
    formattedReferSelectedFilters: {},
  },
  reducers: {
    saveIjprSelectedFilterData: saveIjprSelectedFilterDataReducer,
    saveIjprFormattedSelectedFiltersData: saveIjprFormattedSelectedFiltersDataReducer,
    saveIjpReferSelectedFilterData: saveIjpReferSelectedFilterDataReducer,
    saveIjpReferFormattedSelectedFiltersData: saveIjpReferFormattedSelectedFiltersDataReducer,
  },
});

// ACTIONS
const {
  saveIjprSelectedFilterData,
  saveIjprFormattedSelectedFiltersData,
  saveIjpReferSelectedFilterData,
  saveIjpReferFormattedSelectedFiltersData,
} = ijprDataSlice.actions;

// SELECTOR
const selectIjprSelectedFilterData = ({ ijprData }) => ijprData.selectedFilters;
const selectIjprFormattedSelectedFilterData = ({ ijprData }) => ijprData.formattedSelectedFilters;
const selectIjpReferSelectedFilterData = ({ ijprData }) => ijprData.referSelectedFilters;
const selectIjpReferFormattedSelectedFiltersData = ({ ijprData }) =>
  ijprData.formattedReferSelectedFilters;

const ijprDataSliceReducer = ijprDataSlice.reducer;

export {
  ijprDataSliceReducer,
  saveIjprSelectedFilterData,
  selectIjprSelectedFilterData,
  saveIjprFormattedSelectedFiltersData,
  selectIjprFormattedSelectedFilterData,
  saveIjpReferSelectedFilterData,
  selectIjpReferSelectedFilterData,
  saveIjpReferFormattedSelectedFiltersData,
  selectIjpReferFormattedSelectedFiltersData,
};
