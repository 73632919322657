import React from 'react';
import { useSelector } from 'react-redux';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../constants/AppConstants';
import Divider from '../divider/Divider';
import ActivityLoader from '../activity_loader/ActivityLoader';

const CommentPopupMenu = (props) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  const popover = (
    <Popover id="common-comment-popup-popover">
      <div className="common-comment-popup-menu-container">
        <div
          className="d-flex flex-column justify-content-center common-comment-popup-text ml-2 common-cursor-pointer"
          onClick={() => {
            props.editFuncHandler();
          }}>
          Edit
        </div>
        <Divider style="mt-2 mb-2 common-comment-popup-border" />
        <div
          className="d-flex flex-column justify-content-center common-comment-popup-text ml-2 common-cursor-pointer"
          onClick={() => {
            props.deleteFuncHandler();
          }}>
          <div className="d-flex flex-row align-items-center">
            <div>Delete</div>
            <div>
              <ActivityLoader visible={props.showDeleteLoader} size="sm" />
            </div>
          </div>
        </div>
      </div>
    </Popover>
  );

  return (
    <div>
      <OverlayTrigger trigger="click" rootClose placement="left" overlay={popover}>
        <img
          src={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.COMMENTS_MORE_VERTICAL,
            ACCESSIBILITY_IDENTIFIERS.COMMON
          )}
          className="common-comment-popup-more-vertical-icon-size common-cursor-pointer"
        />
      </OverlayTrigger>
    </div>
  );
};

export default CommentPopupMenu;
