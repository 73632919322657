import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  FILTER_IDENTIFIERS,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import Filter from '../../common/ui/filter/Filter';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';
import { EMPLOYEES, MICROGIVE_GALLERY_TAG_PEOPLE } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { showToast } from '../../utils/common';
import { logEvent } from '../../utils/FirebaseAnalyticsUtils';
import debounce from 'lodash.debounce';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';

const TagPeopleScreen = ({ location, history }) => {
  const dispatch = useDispatch();
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [currentSelectedList, setCurrentSelectedList] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState(false);

  const accessibilityData = useSelector(selectAccessibilityData);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  const { picture_id, microgiveInitiativeDetailId, tags, parentRoute } = location.state;
  const alreadySelectedList = tags || [];

  const [searchText, setSearchText] = useState('');
  const pageIdRef = useRef(1);
  const loadMoreFlagRef = useRef(true);
  const appliedFiltersRef = useRef({});

  const buttonText =
    parentRoute &&
    (parentRoute === NAVIGATION_ROUTES.MICRIGIVE_GALLERY_TAGS_DETAIL_SCREEN ||
      parentRoute === NAVIGATION_ROUTES.PROJECT_TABS_SCREEN)
      ? 'Submit'
      : 'Continue';

  const deleteEmployee = (employee) => {
    if (currentSelectedList.includes(employee)) {
      const index = currentSelectedList.indexOf(employee);
      if (index > -1) {
        setCurrentSelectedList(
          currentSelectedList.filter(
            (it) => it?.employee_id !== currentSelectedList[index]?.employee_id
          )
        );
      }
    }
  };

  const callEmployees = useCallback(
    async (text = '') => {
      if (loadMoreFlagRef.current === true) {
        if (pageIdRef.current == 1) {
          setEmployeeData([]);
        }
        let params = { page_id: pageIdRef.current };
        if (text !== '') {
          params = { ...params, ...{ query: text } };
        }
        if (Object.keys(appliedFiltersRef.current).length) {
          params = { ...params, ...appliedFiltersRef.current };
        }
        try {
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          const apiResponse = await apiRequest(EMPLOYEES, REQUEST_TYPE.GET, params);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length) {
            if (apiResponse.response.status) {
              if (apiResponse.response.data.feeds.length > 0) {
                setEmployeeData((oldArray) => [...oldArray, ...apiResponse.response.data.feeds]);
                loadMoreFlagRef.current = true;
                pageIdRef.current = pageIdRef.current + 1;
                setShowLoadMore(true);
              } else {
                loadMoreFlagRef.current = false;
                setShowLoadMore(false);
              }
            }
          }
        } catch (err) {
          handleError(err, params, EMPLOYEES, NAVIGATION_ROUTES.TAG_PEOPLE_SCREEN);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      }
    },
    [dispatch]
  );

  const debouncedCallback = (event) => {
    if (event && event.target.value.length > 2) {
      pageIdRef.current = 1;
      loadMoreFlagRef.current = true;
      callEmployees(event.target.value);
    }
    setSearchText(event.target.value);
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );

  useEffect(() => {
    callEmployees('');
  }, [callEmployees]);

  const tagEmployeefunction = async () => {
    if (picture_id && currentSelectedList.length > 0) {
      currentSelectedList.forEach((element) => {
        logEvent(
          ANALYTICS_EVENT_TYPES.MICROGIVE_GALLERY_TAG_EMPLOYEE,
          element.employee_id,
          ANALYTICS_ITEM_NAMES.TAG_EMPLOYEE
        );
      });

      const finalList = [...alreadySelectedList, ...currentSelectedList];
      const tagArray = [];
      finalList.forEach((it) => tagArray.push(it?.employee_id));
      const tagString = tagArray.join(',');

      try {
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        const params = new URLSearchParams();
        params.append('tags', tagString);
        params.append('picture_id', picture_id);
        const apiResponse = await apiRequest(
          MICROGIVE_GALLERY_TAG_PEOPLE + microgiveInitiativeDetailId + '/addtags',
          REQUEST_TYPE.POST,
          params
        );
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            showToast(apiResponse.response.message);
            if (parentRoute === NAVIGATION_ROUTES.PROJECT_TABS_SCREEN) {
              history.goBack();
            } else {
              history.go(-2);
            }
          }
        }
      } catch (err) {
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    }
  };

  const addEmployee = (employee) => {
    if (currentSelectedList.findIndex((emp) => emp?.employee_id === employee?.employee_id) >= 0) {
      const index = currentSelectedList.findIndex(
        (emp) => emp?.employee_id === employee?.employee_id
      );

      if (index > -1) {
        setCurrentSelectedList(
          currentSelectedList.filter(
            (it) => it?.employee_id !== currentSelectedList[index]?.employee_id
          )
        );
      }
    } else {
      setCurrentSelectedList((prev) => [...prev, employee]);
    }
  };

  const onClickFilterHandler = (selectedFilters) => {
    let tempFilterObject = {};
    Object.keys(selectedFilters).map((filterKey) => {
      if (selectedFilters[filterKey].length) {
        tempFilterObject[filterKey] = selectedFilters[filterKey].join();
      }
    });
    appliedFiltersRef.current = tempFilterObject;
    setEmployeeData([]);
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    callEmployees(searchText);
  };

  const loadMoreHandler = () => {
    callEmployees(searchText);
  };

  const onSearchSubmitHandler = (event) => {
    event.preventDefault();
    setEmployeeData([]);
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    callEmployees(searchText);
  };

  const onClickSearchHandler = () => {
    setIsSearchEnabled(true);
  };

  const resetSearchHandler = useCallback(() => {
    setIsSearchEnabled(false);
    setSearchText('');
    setEmployeeData([]);
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    callEmployees('');
  }, [callEmployees]);

  const showProfiles = () => {
    if (employeeData.length > 0) {
      return (
        <div className="row microgive-emp-images-container-bottom">
          {employeeData.map((profile, index) => {
            return (
              <div key={`${index}`} className="microgive-emp-image-div col-6 col-sm-3 col-lg-2">
                <UserProfilePhoto
                  imageBaseUrl={profile.profile_picture.base_url}
                  imagePath={profile.profile_picture.image_path}
                  employeeId={profile.employee_id}
                  imageClass="common-user-profile-photo-xl"
                />
                <p className="microgive-profile-name">{profile.first_name}</p>
                <p className="microgive-profile-name">{profile.last_name}</p>
                <div className="microgive-check-row">
                  <input
                    type="checkbox"
                    id="employeeCheck"
                    name="employeeCheck"
                    className="microgive-employee-check-box"
                    onChange={() => addEmployee(profile)}
                    checked={
                      currentSelectedList.filter((emp) => emp?.employee_id === profile?.employee_id)
                        ?.length > 0 ||
                      alreadySelectedList.filter((emp) => emp?.employee_id === profile?.employee_id)
                        ?.length > 0
                        ? true
                        : false
                    }
                    disabled={
                      alreadySelectedList.filter((emp) => emp?.employee_id === profile?.employee_id)
                        ?.length > 0
                        ? true
                        : false
                    }
                  />
                  <div>Tag</div>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else
      return (
        <div className="w-100">
          <DefaultContentView
            message={ERROR_MESSAGES.SEARCH_NO_RESULTS}
            iconUri={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
              ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
            )}
          />
        </div>
      );
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICROGIVE} />
      <BackButton />
      <div className="header-view">
        <CustomButton
          buttonStyle="microgive-button-style mr-3"
          disabled={currentSelectedList.length === 0}
          onClick={() => tagEmployeefunction()}
          showLoader={progressLoadingState.isProgressLoading}>
          {buttonText}
        </CustomButton>
      </div>
      <div className={currentSelectedList.length > 0 && 'microgive-emp-images-container-top'}>
        <div className="d-flex flex-wrap mt-3">
          {currentSelectedList.length > 0 &&
            currentSelectedList.map((item, index) => {
              return (
                <div key={`${index}`} className="microgive-emp-image-div col-6 col-sm-3 col-lg-2">
                  <div>
                    <UserProfilePhoto
                      imageBaseUrl={item.profile_picture.base_url}
                      imagePath={item.profile_picture.image_path}
                      imageClass="common-user-profile-photo-xl"
                    />
                    <div className="text-center mt-1">{item.first_name + ' ' + item.last_name}</div>
                  </div>
                  <div
                    className="d-flex position-absolute mr-3 community-bg-view-image"
                    onClick={() => deleteEmployee(item)}>
                    <img
                      src={getFeatureComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.SOM_EMPLOYEE_CANCEL,
                        ACCESSIBILITY_IDENTIFIERS.SOM
                      )}
                      className="header-icon mr-3"
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="header-view d-flex justify-content-between">
        {isSearchEnabled ? (
          <form className="microgive-search-form" onSubmit={onSearchSubmitHandler}>
            <input
              autoFocus
              className="ml-3 microgive-search-bar"
              type="text"
              placeholder="Search.."
              onChange={debounceInputHandler}
            />
          </form>
        ) : (
          <div />
        )}
        {isSearchEnabled ? (
          <div onClick={resetSearchHandler}>
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
              )}
              className="header-icon mr-3"
            />
          </div>
        ) : (
          <div onClick={onClickSearchHandler}>
            <img
              src={getHeaderComponentUrl(accessibilityData, ACCESSIBILITY_HEADER_COMPONENTS.SEARCH)}
              className="header-icon mr-3"
            />
          </div>
        )}
      </div>
      <div className="main-content-container">
        <Filter
          topFilter={FILTER_IDENTIFIERS.GEOGRAPHIES}
          geographies={true}
          locations={true}
          levels={true}
          layers={true}
          serviceTenures={true}
          bloodGroups={true}
          onClick={onClickFilterHandler}
        />
        <div>
          {showProfiles()}
          {showLoadMore && (
            <LoadMoreBtn
              onClick={loadMoreHandler}
              isLoading={progressLoadingState.isProgressLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TagPeopleScreen;
