import React, { memo, useEffect, useState } from 'react';
import TextChatView from './TextChatView';
const PreviousTextChatView = ({ item }) => {
  const [message, setMessage] = useState('');
  const extractUsername = (text) => {
    const regex = /@\[([^[\]]+)\]\([^)]+\)/g;
    const matches = text.match(regex);
    if (matches) {
      return text.replace(regex, (match) => {
        const username = match.match(/@\[([^[\]]+)\]/)[1];
        return username;
      });
    }
    return text;
  };
  useEffect(() => {
    if (item && item.message) {
      setMessage(item.message);
    }
  }, [item]);

  return <div className="chat-base-text-sm chat-limit-two-lines">{extractUsername(message)}</div>;
};

export default memo(PreviousTextChatView);
