import React from 'react';
import SttarEmployeeItem from './StarEmployeeItem';
import { useHistory } from 'react-router-dom';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  NAVIGATION_ROUTES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { saveCommentCountData } from '../../../state/CommentData';
import { getUrlWithSpecificRendition } from '../../../utils/common';

const StarEmployeeView = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const yearBadge = `SOM_BADGE_${props.year}`;
  const accessibilityData = useSelector(selectAccessibilityData);

  return (
    <div className="som-emp-border">
      <div className="som-emp-view-inner-view">
        <img
          src={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS[yearBadge],
            ACCESSIBILITY_IDENTIFIERS.SOM
          )}
          className="som-year-icon-size"
        />
        <div className="som-year-name-text">YEARS</div>
      </div>
      <div className="som-emp-view-row">
        {props.employees.map((item) => {
          return (
            <SttarEmployeeItem
              key={item.star_employee_id}
              employeeId={item.star_employee_id}
              width={
                props.employees.length > 2 ? '32%' : props.employees.length === 2 ? '45%' : '100%'
              }
              imageUri={getUrlWithSpecificRendition(
                item.promo_image.base_url,
                SCREEN_DENSITY_QUALIFIERS.LDPI,
                item.promo_image.image_path
              )}
              name={item.employee.first_name + ' ' + item.employee.last_name}
              navigateToEmployee={() => {
                dispatch(saveCommentCountData(item.comment_count));
                history.push(`${NAVIGATION_ROUTES.SOM_EMPLOYEE_DETAILS}/${item.star_employee_id}`);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default StarEmployeeView;
