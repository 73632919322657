import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveSearchText, selectBaseUrl } from '../../state/UIState';
import { Tab, Modal, Form } from 'react-bootstrap';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import GcdoTab from './tabs/GcdoTab';
import { selectArticleFilters, selectGcdoTitles } from '../../state/MasterData';
import { saveGcdoTabData, selectGcdoTabData } from '../../state/TabData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import NavTabs from '../../common/ui/tabs/NavTabs';
import SmartDeliveryTab from './tabs/SmartDeliveryTab';
import SearchComponent from '../../common/ui/search/SearchComponent';

const GcdoMainScreen = ({ history }) => {
  const gcdoTabs = useSelector(selectGcdoTitles);
  const gcdoTabdata = useSelector(selectGcdoTabData);
  const dispatch = useDispatch();
  const baseUrl = useSelector(selectBaseUrl);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [showNewsDialog, setShowNewsDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState({});
  const dateResponse = useSelector(selectArticleFilters);
  const dateArray = [];
  for (var date in dateResponse) {
    dateArray.push(dateResponse[date].title);
  }
  const tempDates = {};
  const tempClearFilters = {};
  for (var val in gcdoTabs) {
    tempDates[gcdoTabs[val].category_id] = '';
    tempClearFilters[gcdoTabs[val].category_id] = false;
  }
  const [selectedDatesArray, setSelectedDatesArray] = useState({});
  const [clearFiltersArray, setClearFilterArray] = useState({});
  const [showHeaderItems, setShowHeaderItems] = useState(true);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.GCDO, NAVIGATION_ROUTES.GCDO);
  }, []);

  useEffect(() => {
    if (gcdoTabs?.length) {
      if (gcdoTabs.length && gcdoTabdata === '') {
        dispatch(saveGcdoTabData(gcdoTabs[0].category_id));
      } else {
        let tab = gcdoTabs[gcdoTabdata];
        if (tab?.type === 'static') {
          setShowHeaderItems(false);
        } else {
          setShowHeaderItems(true);
        }
      }
    }
  }, [dispatch, gcdoTabdata, gcdoTabs]);

  const clearFilters = () => {
    setSelectedDate((prev) => ({
      ...prev,
      [gcdoTabdata]: '',
    }));
    setSelectedDatesArray((prev) => ({ ...prev, [gcdoTabdata]: '' }));
    setClearFilterArray((prev) => ({ ...prev, [gcdoTabdata]: true }));
  };

  const callDateFilterFunction = () => {
    const value = selectedDate[gcdoTabdata] ? selectedDate[gcdoTabdata] : dateResponse[0].title;
    const tempdate = dateResponse.find((res) => res.title === value).value;
    setSelectedDatesArray((prev) => ({ ...prev, [gcdoTabdata]: tempdate }));
    setClearFilterArray((prev) => ({ ...prev, [gcdoTabdata]: false }));
  };

  const renderTabs = (tab) => {
    switch (tab.category_id) {
      case tab.category_id:
        if (tab?.type === 'static')
          return (
            <SmartDeliveryTab
              item={tab}
              selectedDate={selectedDatesArray}
              clearFilters={clearFiltersArray}
            />
          );
        else
          return (
            <GcdoTab
              item={tab}
              selectedDate={selectedDatesArray}
              clearFilters={clearFiltersArray}
            />
          );
    }
  };

  const CustomYearView = () => {
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Label>Select Year</Form.Label>
            <div className="gcdo-year-container">
              <Form.Control
                as="select"
                custom
                value={selectedDate[gcdoTabdata]}
                onChange={(event) => {
                  setSelectedDate((prev) => ({
                    ...prev,
                    [gcdoTabdata]: event.target.value,
                  }));
                }}>
                {dateArray &&
                  dateArray.map((item) => {
                    return (
                      <option key={item} defaultValue={item === selectedDate[gcdoTabdata]}>
                        {item}
                      </option>
                    );
                  })}
              </Form.Control>
            </div>
          </Form.Group>
        </Form>
        <div className="gcdo-row">
          <div>
            <CustomButton
              buttonStyle="gcdo-calender-button-style"
              onClick={() => {
                setShowNewsDialog(false);
                clearFilters();
              }}>
              CLEAR FILTERS
            </CustomButton>
          </div>
          <div className="gcdo-row">
            <CustomButton
              buttonStyle="gcdo-calender-button-style"
              onClick={() => {
                setShowNewsDialog(false);
              }}>
              CANCEL
            </CustomButton>
            <CustomButton
              buttonStyle="gcdo-calender-button-style"
              onClick={() => {
                setShowNewsDialog(false);
                callDateFilterFunction();
              }}>
              DONE
            </CustomButton>
          </div>
        </div>
      </div>
    );
  };

  const handleSubmitFunction = (searchText) => {
    dispatch(saveSearchText(searchText));
    history.push(NAVIGATION_ROUTES.GCDO_SEARCH, {
      searchQuery: searchText,
    });
  };

  return (
    <div className="container">
      {showHeaderItems && gcdoTabs?.length > 0 && (
        <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
          <SearchComponent handleSubmitFunction={handleSubmitFunction} />
          <div className="gcdo-icon-row mr-3">
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_HEADER_COMPONENTS.CALENDER
              )}
              className="header-icon"
              onClick={() => {
                setShowNewsDialog(true);
              }}
            />
          </div>
        </div>
      )}
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.GCDO} />
      <Modal
        show={showNewsDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowNewsDialog(false)}>
        <Modal.Body>{CustomYearView()}</Modal.Body>
      </Modal>
      {baseUrl !== '' && (
        <NavTabs activeKey={gcdoTabdata} onSelect={(k) => dispatch(saveGcdoTabData(k))}>
          {gcdoTabs &&
            gcdoTabs.map((tab, index) => {
              return (
                <Tab
                  key={`${index}`}
                  eventKey={tab.category_id}
                  title={tab.title}
                  className="main-content-container">
                  {renderTabs(tab, index)}
                </Tab>
              );
            })}
        </NavTabs>
      )}
    </div>
  );
};

export default GcdoMainScreen;
