import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  MICROGIVE_EVENT_TYPE,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { PROJECTS_SEARCH_BY_ID } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { saveMicrogiveProjectsTabData } from '../../state/TabData';
import {
  saveProgressLoadingState,
  saveSearchText,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectSearchText,
  selectShowEmptyContentView,
} from '../../state/UIState';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import ProjectDetailListItem from './components/ProjectDetailListItem';
import debounce from 'lodash.debounce';
import ActivityLoader from '../../common/ui/activity_loader/ActivityLoader';

const ProjectDetailSearchScreen = ({ location, history }) => {
  const dispatch = useDispatch();
  const { projectId } = location.state;
  const [searchList, setSearchList] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const searchData = useSelector(selectSearchText);
  const [searchText, setSearchText] = useState(searchData ?? '');

  const accessibilityData = useSelector(selectAccessibilityData);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [isSearchEnabled, setIsSearchEnabled] = useState(true);
  const [quickSearchLoading, setQuickSearchLoading] = useState(false);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.PROJECT_DETAIL_SEARCH_SCREEN,
      NAVIGATION_ROUTES.PROJECT_DETAIL_SEARCH_SCREEN
    );
  }, []);

  const callProjectsApi = useCallback(
    async (id, loadMore, text, isQuickSearch = false) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        const params = {
          page_id: id,
          query: text,
        };
        try {
          if (isQuickSearch) {
            setQuickSearchLoading(true);
          } else {
            dispatch(
              saveProgressLoadingState({
                isProgressLoading: true,
              })
            );
          }
          const apiResponse = await apiRequest(
            PROJECTS_SEARCH_BY_ID + projectId + '/initiatives',
            REQUEST_TYPE.GET,
            params
          );
          setQuickSearchLoading(false);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.initiatives.length > 0) {
                if (isQuickSearch) {
                  setSearchList(apiResponse.response.data.initiatives);
                } else {
                  setSearchList((oldArray) => [
                    ...oldArray,
                    ...apiResponse.response.data.initiatives,
                  ]);
                }
                setLoadMoreFlag(true);
                setPageId((prevCount) => prevCount + 1);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(
            err,
            params,
            PROJECTS_SEARCH_BY_ID + projectId + '/initiatives',
            NAVIGATION_ROUTES.PROJECT_DETAIL_SEARCH_SCREEN
          );
          setQuickSearchLoading(false);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      }
    },
    [dispatch, projectId]
  );

  const debouncedCallback = (event) => {
    if (event && event.target.value.length > 2) {
      dispatch(saveSearchText(event.target.value));
      setPageId(1);
      setLoadMoreFlag(true);
      callProjectsApi(1, true, event.target.value, true);
    }
    setSearchText(event.target.value);
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );
  const onInputChange = (event) => {
    setSearchText(event.target.value);
    debounceInputHandler(event);
  };

  const callSubmitFunction = (event) => {
    event?.preventDefault();
    if (searchText.trim() !== '') {
      dispatch(saveSearchText(searchText));
      logEvent(
        ANALYTICS_EVENT_TYPES.MICROGIVE_PROJECT_DETAIL_SEARCH,
        searchText,
        ANALYTICS_ITEM_NAMES.SEARCH
      );
      setSearchList([]);
      setPageId(1);
      callProjectsApi(1, true, searchText);
    }
  };

  const onTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const onPress = (item) => {
    dispatch(saveMicrogiveProjectsTabData(''));
    if (item.type === MICROGIVE_EVENT_TYPE.VOLUNTEER) {
      history.push(
        `${NAVIGATION_ROUTES.PROJECT_TABS_SCREEN}/${item.microgive_initiative_detail_id}`,
        {
          title: item.title,
          fromRoute: NAVIGATION_ROUTES.PROJECT_DETAIL_SCREEN,
        }
      );
    } else if (item.type === MICROGIVE_EVENT_TYPE.DONATION) {
      history.push(
        `${NAVIGATION_ROUTES.PROJECT_TABS_SCREEN}/${item.initiative_id}/${item.microgive_initiative_detail_id}`
      );
    }
  };

  const onPressLoadMore = () => {
    callProjectsApi(pageId, loadMoreFlag);
  };

  const resetValue = () => {
    history.goBack();
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.SEARCH_NO_RESULTS} />;
  };

  const onClickSearchHandler = () => {
    setIsSearchEnabled(true);
  };

  useEffect(() => {
    if (searchText) {
      callSubmitFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
        <div
          className={
            isSearchEnabled ? 'search-bar-with-icon search-bar-border' : 'search-bar-with-icon'
          }>
          {isSearchEnabled ? (
            <form className="directory-search-bar" onSubmit={callSubmitFunction}>
              <input
                autoFocus
                className="ml-2 directory-search-bar"
                type="text"
                placeholder="Search.."
                onChange={onInputChange}
                value={searchText}
              />
            </form>
          ) : (
            <div className="directory-search-bar" />
          )}
          {isSearchEnabled ? (
            <div className="gcdo-icon-row mr-2" onClick={resetValue}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
                )}
                className="header-icon"
              />
            </div>
          ) : (
            <div className="gcdo-icon-row mr-2" onClick={onClickSearchHandler}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH
                )}
                className="header-icon"
              />
            </div>
          )}
        </div>
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICROGIVE} />
      <BackButton />
      {pageId === 1 &&
      showEmptyContentView &&
      !searchList.length &&
      !progressLoadingState.isProgressLoading ? (
        emptyListView()
      ) : (
        <div className="main-content-container">
          <ActivityLoader visible={quickSearchLoading} />
          <div className="row">
            {searchList.map((item, index) => {
              return <ProjectDetailListItem key={`${index}`} item={item} onPress={onPress} />;
            })}
          </div>
          {loadMoreFlag && (
            <LoadMoreBtn
              onClick={onPressLoadMore}
              isLoading={progressLoadingState.isProgressLoading}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ProjectDetailSearchScreen;
