import React from 'react';

const YearFilterItem = ({ item, onPress, isSelected }) => {
  return (
    <div>
      {isSelected ? (
        <div className="ourcustomers-categories-selected">{item}</div>
      ) : (
        <div className="ourcustomers-categories" onClick={() => onPress(item)}>
          {item}
        </div>
      )}
    </div>
  );
};

export default YearFilterItem;
