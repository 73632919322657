import { call, put, takeEvery } from 'redux-saga/effects';
import { ACTION_TYPES, REQUEST_TYPE } from '../common/constants/AppConstants';
import { GET_NOTIFICATION_COUNT } from '../services/ApiUrls';
import { apiRequest } from '../services/Service';
import { saveNotificationCount } from '../state/NotificationData';

export function* getNotificationCount(action) {
  try {
    const apiResponse = yield call(
      apiRequest,
      GET_NOTIFICATION_COUNT,
      REQUEST_TYPE.GET,
      action.payload
    );
    if (apiResponse) {
      if (apiResponse.response.status === true) {
        yield put(saveNotificationCount(apiResponse.response.data.count));
      }
    }
  } catch (e) {
    yield put(saveNotificationCount(0));
  }
}

export function* watchNotificationCount() {
  yield takeEvery(ACTION_TYPES.NOTIFICATION_COUNT, getNotificationCount);
}
