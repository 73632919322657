import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { formatDateDDMonthYYYYTimeAMPM, getAwardIcon } from '../../../utils/common';
import { getHeaderComponentUrl } from '../../../utils/accessibility';
import { ACCESSIBILITY_IDENTIFIERS } from '../../../common/constants/AppConstants';
import Divider from '../../../common/ui/divider/Divider';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { selectProgressLoadingState } from '../../../state/UIState';
import HtmlView from '../../../common/ui/html_view/HTMLView';

const NominationRequestListItem = ({ item, onPressReject, onPressApprove, onPressOthers }) => {
  const [iconName] = useState(getAwardIcon(item.award_details.icon_name));

  const accessibilityData = useSelector(selectAccessibilityData);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  return (
    <div className="material-card p-3 mb-3">
      <div className="cheers-date-view-style">
        <UserProfilePhoto
          imageBaseUrl={item.employee.profile_picture.base_url}
          imagePath={item.employee.profile_picture.image_path}
          imageClass="common-user-profile-photo-lg"
          employeeId={item.employee.employee_id}
        />
        <div className="ml-3 col-sm-8 cheers-outer-view">
          <div>
            <div className="cheers-emp-name">{`${item.employee.first_name} ${item.employee.last_name}`}</div>
            <div className="cheers-emp-position">{item.employee.position_title}</div>
          </div>
        </div>
        <div className="cheers-outer-view">
          <div>
            <div className="cheers-frequency">{item.award_details.award_frequency}</div>
            <div className="cheers-date-text">{formatDateDDMonthYYYYTimeAMPM(item.created_on)}</div>
          </div>
        </div>
      </div>
      <Divider style="mt-3" />
      {item.request_type === 'Pending' ? (
        <div>
          <div className="cheers-select-option-text cheers-select-padding">
            Why is this award for?
          </div>
          <div className="mt-2 mb-2 cheers-citation-text">
            <HtmlView html={item.citation} />
          </div>
          {item.citation_text && <div className="cheers-select-option-text">Citation</div>}
          {item.citation_text && (
            <div className="mt-2 mb-2 cheers-citation-text">
              <HtmlView html={item.citation_text} />
            </div>
          )}
        </div>
      ) : (
        <div className="mt-2 mb-2 cheers-citation-text">{item.citation}</div>
      )}
      <div className="cheers-outer-view mt-2 mb-2">
        <div className="cheers-date-view-style">
          <div className="cheers-icon-text-view cheers-outer-view">
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                iconName,
                ACCESSIBILITY_IDENTIFIERS.CHEERS
              )}
              className="cheers-normal-icon"
            />
            <div className="ml-2 cheers-award-title-text">{item.award_details.title}</div>
          </div>
        </div>
        <div className="ml-3 cheers-reward-points-second-text">
          {item.extra_points !== 0
            ? `(${item.points}+${item.extra_points} Points)`
            : `(${item.points} Points)`}
        </div>
      </div>
      <div className="cheers-date-view-style">
        <div className="cheers-given-nominate-text">Nominated By:</div>
        {item.nominations.length > 0 && (
          <div
            className="ml-2 cheers-given-nominate-text common-cursor-pointer"
            onClick={() => {
              onPressOthers(item.nominations);
            }}>
            {` ${item.nominations[0].nominated_by.first_name} ${item.nominations[0].nominated_by.last_name}`}
          </div>
        )}
        {item.nominations.length > 1 && (
          <div
            onClick={() => {
              onPressOthers(item.nominations);
            }}
            className="cheers-given-nominate-text ml-1">
            {item.nominations.length === 2
              ? ` + (${item.nominations.length - 1}) Other`
              : ` + (${item.nominations.length - 1}) Others`}
          </div>
        )}
      </div>
      {item.request_type === 'Pending' && (
        <div className="cheers-team-micro-view">
          <CustomButton
            buttonStyle="cheers-button-style mt-2 mr-3"
            onClick={() => onPressReject(item)}
            showLoader={progressLoadingState.isProgressLoading}>
            Reject
          </CustomButton>
          <CustomButton
            buttonStyle="cheers-button-style mt-2 ml-3"
            onClick={() => onPressApprove(item)}
            showLoader={progressLoadingState.isProgressLoading}>
            Approve
          </CustomButton>
        </div>
      )}
      {(item.request_type === 'Approved' || item.request_type === 'Rejected') && (
        <div>
          {item.request_type === 'Approved' ? (
            <div className="text-center cheers-approved-text">APPROVED</div>
          ) : (
            <div className="text-center cheers-rejected-text">Rejected</div>
          )}
          <div className="mt-2 text-center cheers-request-date-time">
            {formatDateDDMonthYYYYTimeAMPM(item.request_datetime)}
          </div>
        </div>
      )}
    </div>
  );
};

export default NominationRequestListItem;
