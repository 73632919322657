import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrlLdpi } from '../../../utils/accessibility';

import Divider from '../../../common/ui/divider/Divider';
import { getUrlWithSpecificRendition, removeHtmlContent } from '../../../utils/common';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';

const WallItem = (props) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const history = useHistory();

  const onPressItem = (item) => {
    history.push(`${NAVIGATION_ROUTES.THE_WALL_POST_DETAIL}/${item.post_id}`);
  };

  const handleHtmlClicks = (e) => {
    const target = e.target.getAttribute('href');
    if (target && (target.startsWith('/') || target.startsWith('?'))) {
      e.stopPropagation();
      e.preventDefault();
      if (target.includes(NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL)) {
        logEvent(
          ANALYTICS_EVENT_TYPES.USER_MENTION_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.USER_MENTION
        );
        history.push(target);
      } else {
        logEvent(
          ANALYTICS_EVENT_TYPES.HASHTAG_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.HASHTAG
        );
        history.push(target);
      }
    }
  };

  const wallsArray =
    props.feed &&
    props.feed.map((item, index) => {
      return (
        <div key={`${index}`} className="common-cursor-pointer" onClick={() => onPressItem(item)}>
          <div className="global-search-image-row">
            <img
              className="common-user-profile-photo-xl mr-3"
              src={
                item.employee_details &&
                item.employee_details.profile_picture &&
                item.employee_details.profile_picture.base_url &&
                item.employee_details.profile_picture.image_path
                  ? getUrlWithSpecificRendition(
                      item.employee_details.profile_picture.base_url,
                      SCREEN_DENSITY_QUALIFIERS.LDPI,
                      item.employee_details.profile_picture.image_path
                    )
                  : getFeatureComponentUrlLdpi(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
                      ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                    )
              }
            />
            <div>
              <div>
                <div className="common-liked-people-name-text">
                  {item.first_name + ' ' + item.last_name}
                </div>
                <HtmlView
                  html={
                    item.description.length <= 256
                      ? item.description
                      : item.description.substring(0, 256) + '...'
                  }
                  onClick={handleHtmlClicks}
                />
                {removeHtmlContent(item.description).length > 256 && (
                  <div className="thewall-post-read-color">Read More</div>
                )}
              </div>
            </div>
          </div>
          <Divider style="mt-2 mb-2" />
        </div>
      );
    });

  return <div>{wallsArray}</div>;
};

export default WallItem;
