import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { Form, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import * as yup from 'yup';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import Select from 'react-select';

import {
  ERROR_MESSAGES,
  REQUEST_TYPE,
  DEFAULT_ISD_CODE,
  DEFAULT_REGION_CODE,
  ENCODING_TYPE,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  SCREEN_TITLE,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import { showToast } from '../../../utils/common';
import { selectGenderList, selectMultilineTextSize } from '../../../state/MasterData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../state/UIState';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import CustomTextInput from '../../../common/ui/custom_text_input/CustomTextInput';
import { apiRequest, handleError } from '../../../services/Service';
import { ADD_EDIT_TRAVELLER, GET_COUNTRIES } from '../../../services/ApiUrls';
import {
  selectBusSeatList,
  selectFlightFoodList,
  selectFlightSeatList,
  selectTrainSeatList,
} from '../../../state/TravelRequestMasterData';
import { logEvent, trackScreen } from '../../../utils/FirebaseAnalyticsUtils';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';

const AddEditTravellerDialog = ({
  isEdit,
  travellerDetails,
  isAddEditTravellerModalVisible,
  setIsAddEditTravellerModalVisible,
  onAddEditTraveller,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const accessibilityData = useSelector(selectAccessibilityData);
  const maxMultiLineCharLimit = useSelector(selectMultilineTextSize);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const flightSeatList = useSelector(selectFlightSeatList);
  const flightFoodList = useSelector(selectFlightFoodList);
  const trainSeatList = useSelector(selectTrainSeatList);
  const busSeatList = useSelector(selectBusSeatList);
  const [shouldSaveTraveller, setShouldSaveTraveller] = useState(false);
  const [nationalityData, setNationalityData] = useState([]);
  const addEditTravellerFormRef = useRef();
  const genderList = useSelector(selectGenderList);
  const [genderItemList, setGenderItemList] = useState([]);

  const generalInstructionPopover = (
    <Popover>
      <Popover.Content>
        <div className="common-cursor-pointer">
          <p className="mb-0 common-ws-pre-line">
            Select more than one preference. If the desired option is unavailable, the next option
            may be considered.
          </p>
        </div>
      </Popover.Content>
    </Popover>
  );

  useEffect(() => {
    // Analytics
    trackScreen(
      ANALYTICS_SCREEN_NAMES.TRAVEL_REQUEST_ADD_USER,
      NAVIGATION_ROUTES.TRAVEL_REQUEST_ADD_USER
    );
  }, []);

  useEffect(() => {
    if (Object.keys(genderList).length) {
      let genderListFormatted = [];
      for (let item in genderList) {
        genderListFormatted.push({ label: genderList[item], value: item });
      }
      setGenderItemList(genderListFormatted);
    }
  }, [genderList]);

  useEffect(() => {
    if (
      isEdit &&
      travellerDetails &&
      Object.keys(travellerDetails) &&
      Object.keys(travellerDetails).length > 0
    ) {
      addEditTravellerFormRef.current.setValues({
        ...addEditTravellerFormRef.current.initialValues,
        ...travellerDetails,
      });
    }
  }, [isEdit, travellerDetails]);

  useEffect(() => {
    if (
      addEditTravellerFormRef.current &&
      addEditTravellerFormRef.current.values &&
      !addEditTravellerFormRef.current.values.mobile_isd_code
    ) {
      addEditTravellerFormRef.current.setFieldValue('mobile_region_code', DEFAULT_REGION_CODE);
      addEditTravellerFormRef.current.setFieldValue('mobile_isd_code', DEFAULT_ISD_CODE);
    }
  }, [addEditTravellerFormRef.current]);

  const callNationalityApi = useCallback(async () => {
    dispatch(saveProgressLoadingState({ isProgressLoading: true }));
    try {
      const apiResponse = await apiRequest(GET_COUNTRIES, REQUEST_TYPE.GET, {});
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          const data = apiResponse.response.data;
          setNationalityData(data);
        }
      }
    } catch (err) {
      handleError(err, {}, GET_COUNTRIES, NAVIGATION_ROUTES.TRAVEL_REQUEST);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch]);

  useEffect(() => {
    callNationalityApi();
  }, [callNationalityApi]);

  const addEditTravellerValidationSchema = yup.object().shape({
    first_name: yup
      .string()
      .required(ERROR_MESSAGES.UPDATE_FIRST_NAME)
      .max(maxMultiLineCharLimit, ERROR_MESSAGES.FIRST_NAME_CHAR_LIMIT_EXCEEDED),
    last_name: yup
      .string()
      .required(ERROR_MESSAGES.UPDATE_LAST_NAME)
      .max(maxMultiLineCharLimit, ERROR_MESSAGES.LAST_NAME_CHAR_LIMIT_EXCEEDED)
      .nullable(),
    gender: yup.string().required(ERROR_MESSAGES.UPDATE_GENDER),
    mobile_number: yup
      .string()
      .required(ERROR_MESSAGES.UPDATE_CONTACT_NUMBER)
      .max(16, ERROR_MESSAGES.INVALID_CONTACT_NUMBER)
      .min(6, ERROR_MESSAGES.INVALID_CONTACT_NUMBER)
      .nullable(),
  });

  const callAddEditTravellerApi = useCallback(
    async (formValues) => {
      const params = { ...formValues };
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
          })
        );
        const apiResponse = await apiRequest(
          ADD_EDIT_TRAVELLER,
          REQUEST_TYPE.POST,
          JSON.stringify(params),
          ENCODING_TYPE.RAW
        );
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            const message = apiResponse.response.message;
            if (message) {
              showToast(message);
              if (isEdit) {
                setIsAddEditTravellerModalVisible(false);
                history.replace(NAVIGATION_ROUTES.TRAVEL_REQUEST, {
                  isEditTraveller: true,
                  travellerDetails: apiResponse.response.traveller,
                });
              } else {
                setIsAddEditTravellerModalVisible(false);
                onAddEditTraveller(apiResponse.response.traveller);
              }
            }
          }
        }
      } catch (err) {
        handleError(err, params, ADD_EDIT_TRAVELLER, NAVIGATION_ROUTES.TRAVEL_REQUEST);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    },
    [dispatch, history, isEdit, onAddEditTraveller, setIsAddEditTravellerModalVisible]
  );

  const onPressSubmitHandler = async (formValues) => {
    if (isEdit) {
      callAddEditTravellerApi(formValues);
    } else {
      if (shouldSaveTraveller) {
        callAddEditTravellerApi(formValues);
      } else {
        onAddEditTraveller(formValues);
        setIsAddEditTravellerModalVisible(false);
      }
    }
  };

  const onSubmitFormHandler = (event, formikProps) => {
    event.preventDefault();
    if (formikProps.submitCount > 0 && !formikProps.isValid) {
      const formErrors = Object.values(formikProps.errors);
      if (formErrors.length > 0) {
        showToast(formErrors[0]);
      }
    } else {
      if (!formikProps.isValid) {
        showToast(ERROR_MESSAGES.FORM_ERRORS);
      }
      formikProps.handleSubmit();
    }
  };

  return (
    <Modal
      onHide={() => setIsAddEditTravellerModalVisible(false)}
      show={isAddEditTravellerModalVisible}
      centered
      size="lg"
      keyboard={false}>
      <Formik
        validationSchema={addEditTravellerValidationSchema}
        onSubmit={onPressSubmitHandler}
        validateOnMount={true}
        innerRef={addEditTravellerFormRef}
        initialValues={{
          traveller_id: '',
          employee_code: '',
          first_name: '',
          last_name: '',
          gender: '',
          mobile_region_code: DEFAULT_REGION_CODE,
          mobile_isd_code: DEFAULT_ISD_CODE,
          mobile_number: '',
          country_id: '',
          flight_seat_pref: [],
          flight_food_pref: [],
          train_seat_pref: [],
          bus_seat_pref: [],
        }}>
        {(formikProps) => (
          <Form noValidate onSubmit={(event) => onSubmitFormHandler(event, formikProps)}>
            <div>
              <Modal.Header className="d-flex align-items-center py-3" closeButton>
                <Modal.Title>
                  {isEdit
                    ? SCREEN_TITLE.TRAVEL_REQUEST_EDIT_USER
                    : SCREEN_TITLE.TRAVEL_REQUEST_ADD_USER}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-4">
                <div>
                  <div>
                    <CustomTextInput
                      required={true}
                      name="first_name"
                      title="First/Given Name(s)"
                      placeholder="E.g., Jane"
                      errorMessage={formikProps.errors.first_name}
                      value={formikProps.values.first_name}
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                    />
                    <CustomTextInput
                      required={true}
                      name="last_name"
                      title="Last/Family Name"
                      placeholder="E.g., Jane"
                      errorMessage={formikProps.errors.last_name}
                      value={formikProps.values.last_name}
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                    />

                    <Form.Label>
                      Gender<span className="my-profile-required"> (required)</span>
                    </Form.Label>
                    <Select
                      name="gender"
                      className="travel-request-dropdown-style"
                      placeholder="Select an option"
                      isSearchable={false}
                      options={genderItemList}
                      value={genderItemList.find(
                        (option) => option.value === formikProps.values.gender
                      )}
                      onChange={(selectedOption) => {
                        formikProps.setFieldValue('gender', selectedOption.value);
                      }}
                    />
                    <div className="mt-3">
                      <label>
                        Contact Number<span className="my-profile-required"> (required)</span>
                      </label>
                      <div className="d-flex">
                        <div className="my-profile-editable-phone-code">
                          <PhoneInput
                            enableSearch={true}
                            countryCodeEditable={false}
                            country={formikProps.values.mobile_region_code}
                            value={formikProps.values.mobile_isd_code}
                            onChange={(dialCode, country) => {
                              formikProps.setFieldValue('mobile_region_code', country.countryCode);
                              formikProps.setFieldValue('mobile_isd_code', country.dialCode);
                            }}
                          />
                        </div>
                        <div className="my-profile-editable-phone-text-container pl-3">
                          <CustomTextInput
                            required={true}
                            isTitle={false}
                            name="mobile_number"
                            placeholder="E.g., 9999999999"
                            errorMessage={formikProps.errors.mobile_number}
                            value={formikProps.values.mobile_number}
                            type="tel"
                            onBlur={formikProps.handleBlur}
                            onChange={formikProps.handleChange}
                            style="my-profile-edit-editable-text-input"
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <Form.Label>Nationality</Form.Label>
                      <Select
                        name="country_id"
                        className="travel-request-dropdown-style"
                        placeholder="Choose nationality"
                        isSearchable={!!nationalityData.length}
                        getOptionLabel={(option) => option.nationality}
                        getOptionValue={(option) => option.country_id}
                        options={nationalityData}
                        value={nationalityData.find(
                          (option) => option.country_id === formikProps.values.country_id
                        )}
                        onChange={(selectedOption) => {
                          formikProps.setFieldValue('country_id', selectedOption.country_id);
                        }}
                      />
                    </div>
                    <div>
                      <Form.Label className="mt-3">
                        Flight Preferences
                        <OverlayTrigger
                          rootClose
                          trigger="click"
                          placement="right"
                          overlay={generalInstructionPopover}>
                          <img
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_INFO,
                              ACCESSIBILITY_IDENTIFIERS.COMMON
                            )}
                            className="common-user-profile-info-icon common-cursor-pointer ml-2"
                          />
                        </OverlayTrigger>
                      </Form.Label>
                      <div className="d-flex">
                        <Select
                          className="w-100 mr-2"
                          placeholder="Choose seat"
                          isSearchable={false}
                          isMulti={true}
                          options={flightSeatList}
                          getOptionLabel={(option) => option.title}
                          getOptionValue={(option) => option.pref_id}
                          value={formikProps.values.flight_seat_pref}
                          onChange={(selectedOption) => {
                            formikProps.setFieldValue('flight_seat_pref', selectedOption);
                          }}
                        />
                        <Select
                          className="w-100"
                          placeholder="Choose food"
                          isSearchable={false}
                          isMulti={true}
                          options={flightFoodList}
                          getOptionLabel={(option) => option.title}
                          getOptionValue={(option) => option.pref_id}
                          value={formikProps.values.flight_food_pref}
                          onChange={(selectedOptions) => {
                            formikProps.setFieldValue('flight_food_pref', selectedOptions);
                          }}
                        />
                      </div>
                    </div>
                    <Form.Label className="mt-3">
                      Train Preferences
                      <OverlayTrigger
                        rootClose
                        trigger="click"
                        placement="right"
                        overlay={generalInstructionPopover}>
                        <img
                          src={getFeatureComponentUrl(
                            accessibilityData,
                            ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_INFO,
                            ACCESSIBILITY_IDENTIFIERS.COMMON
                          )}
                          className="common-user-profile-info-icon common-cursor-pointer ml-2"
                        />
                      </OverlayTrigger>
                    </Form.Label>
                    <div className="d-flex">
                      <Select
                        className="travel-request-dropdown-style flex-fill mr-2"
                        placeholder="Choose berth"
                        isSearchable={false}
                        isMulti={true}
                        options={trainSeatList}
                        getOptionLabel={(option) => option.title}
                        getOptionValue={(option) => option.pref_id}
                        value={formikProps.values.train_seat_pref}
                        onChange={(selectedOptions) => {
                          formikProps.setFieldValue('train_seat_pref', selectedOptions);
                        }}
                      />
                    </div>
                    <div>
                      <Form.Label className="mt-3">
                        Bus Preferences
                        <OverlayTrigger
                          rootClose
                          trigger="click"
                          placement="right"
                          overlay={generalInstructionPopover}>
                          <img
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_INFO,
                              ACCESSIBILITY_IDENTIFIERS.COMMON
                            )}
                            className="common-user-profile-info-icon common-cursor-pointer ml-2"
                          />
                        </OverlayTrigger>
                      </Form.Label>
                      <Select
                        className="travel-request-dropdown-style flex-fill"
                        placeholder="Choose seat"
                        isSearchable={false}
                        isMulti={true}
                        options={busSeatList}
                        getOptionLabel={(option) => option.title}
                        getOptionValue={(option) => option.pref_id}
                        value={formikProps.values.bus_seat_pref}
                        onChange={(selectedOptions) => {
                          formikProps.setFieldValue('bus_seat_pref', selectedOptions);
                        }}
                      />
                    </div>
                    {!isEdit && (
                      <Form.Check
                        className="mt-3"
                        label="Save this traveller"
                        checked={shouldSaveTraveller}
                        onChange={(event) => {
                          logEvent(
                            ANALYTICS_EVENT_TYPES.TR_ADD_TRAVELLER_SAVE_TRAVELLER_CLICK,
                            event.target.checked.toString(),
                            ANALYTICS_ITEM_NAMES.TRAVEL_REQUEST
                          );
                          setShouldSaveTraveller(event.target.checked);
                        }}
                      />
                    )}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <CustomButton
                  disabled={progressLoadingState.isProgressLoading}
                  onClick={() => {
                    logEvent(
                      ANALYTICS_EVENT_TYPES.TR_ADD_TRAVELLER_CREATE_CLICK,
                      shouldSaveTraveller.toString(),
                      ANALYTICS_ITEM_NAMES.TRAVEL_REQUEST
                    );
                    if (!formikProps.isValid) {
                      const formErrors = Object.values(formikProps.errors);
                      if (formErrors.length > 0) {
                        showToast(formErrors[0]);
                      }
                    } else {
                      formikProps.handleSubmit();
                    }
                  }}
                  buttonMainContainer="d-flex justify-content-end">
                  {isEdit ? 'UPDATE' : 'SUBMIT'}
                </CustomButton>
              </Modal.Footer>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default memo(AddEditTravellerDialog);
