import React from 'react';
import PropTypes from 'prop-types';
import {
  formatDateDDMonthYYYY,
  formatSecondsIntoMinutes,
  formatSecondsIntoTimeForVideos,
  getUrlWithSpecificRendition,
} from '../../../utils/common';
import PlayIconOverlay from '../../../common/ui/play_icon_overlay/PlayIconOverlay';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';

const GridView = (props) => {
  const { feed, onPressItem } = props;

  const articlesArray =
    feed &&
    feed.map((item, index) => (
      <div
        key={index}
        className="col-sm-4 mb-3 common-cursor-pointer"
        onClick={() => onPressItem(item)}>
        <div>
          {item?.content_load_type === '3' && <PlayIconOverlay />}
          <img
            src={getUrlWithSpecificRendition(
              item.base_url,
              SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
              item.image
            )}
            className="img-fluid embed-responsive embed-responsive-16by9"
            alt={item.title} // Add alt text for accessibility
          />
          <div className="row ml-1 mt-1">
            <div className="d-flex flex-row justify-content-between w-100 pr-3">
              {!!item?.time_to_read && (
                <div className="broadcast-event-date-new">
                  {item.time_to_read !== 0 ? formatSecondsIntoMinutes(item.time_to_read) : null}
                </div>
              )}
              {!!item?.video_duration &&
                item?.content_load_type === '3' &&
                item?.video_duration > 0 && (
                  <div className="broadcast-event-date-new">
                    {formatSecondsIntoTimeForVideos(item?.video_duration)}
                  </div>
                )}
              <div className="broadcast-event-date-new">
                {formatDateDDMonthYYYY(item.pub_datetime)}
              </div>
              {!!item.page_views && (
                <div className="broadcast-event-date-new">
                  {item.page_views > 1 ? `${item.page_views} views` : `${item.page_views} view`}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="broadcast-title">{item.title}</div>
      </div>
    ));

  return (
    <div className="container">
      <div className="row">{articlesArray}</div>
    </div>
  );
};

GridView.propTypes = {
  feed: PropTypes.array.isRequired,
  onPressItem: PropTypes.func.isRequired,
};

export default GridView;
