import React from 'react';
import { Modal } from 'react-bootstrap';
import CustomButton from '../../../common/ui/custom_button/CustomButton';

const ChatConfirmModal = (props) => {
  const {
    isDialogVisible,
    setIsDialogVisible,
    title = '',
    subtitle,
    leftButtonText,
    onClickLeftButton,
    rightButtonText,
    onClickRightButton,
  } = props;

  return (
    <Modal
      onHide={() => setIsDialogVisible((val) => !val)}
      show={isDialogVisible}
      centered
      backdrop="static"
      keyboard={false}>
      <Modal.Body className="p-4">
        {!!title && <h5 className="ijp-apply-modal-title text-center">{title}</h5>}
        <p className="ijp-apply-modal-subtitle text-center mb-4">{subtitle}</p>
        <div className="d-flex justify-content-center">
          <CustomButton
            onClick={onClickLeftButton}
            buttonMainContainer="mr-4"
            buttonStyle="common-custom-button-ternary">
            {leftButtonText}
          </CustomButton>
          <CustomButton onClick={onClickRightButton} buttonStyle="common-custom-button-quaternary">
            {rightButtonText}
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChatConfirmModal;
