import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab } from 'react-bootstrap';
import {
  NAVIGATION_ROUTES,
  IJPR_IDENTIFIERS,
  REQUEST_TYPE,
  ANALYTICS_SCREEN_NAMES,
  ACCESSIBILITY_IDENTIFIERS,
  ACCESSIBILITY_FEATURE_COMPONENTS,
} from '../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../services/Service';
import {
  GET_IJP_LANDING_LISTING,
  GET_MY_APPLICATIONS,
  GET_MY_REFERRALS,
  GET_REFER_LANDING_LISTING,
} from '../../services/ApiUrls';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { selectIjprTabs } from '../../state/MasterData';
import { saveIjprBookmarkTabData, saveIjprTabData, selectIjprTabData } from '../../state/TabData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import MyApplicationsTab from './tabs/MyApplicationsTab';
import MyReferralsTab from './tabs/MyReferralsTab';
import ReferralsTab from './tabs/ReferralsTab';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { useHistory } from 'react-router-dom';
import NavTabs from '../../common/ui/tabs/NavTabs';
import { selectAccessData } from '../../state/UserAccessData';

const IjprScreen = () => {
  const dispatch = useDispatch();
  const ijprTabTitles = useSelector(selectIjprTabs);
  const ijprTabData = useSelector(selectIjprTabData);
  const [isLoading, setIsLoading] = useState(false);
  const [ijprData, setIjprData] = useState({});
  const accessibilityData = useSelector(selectAccessibilityData);
  const history = useHistory();

  let tempPageIds = {};
  let tempLoadMoreFlags = {};
  ijprTabTitles.map((tab) => {
    tempPageIds[tab.key] = 1;
    tempLoadMoreFlags[tab.key] = true;
  });
  const pageIdRef = useRef(tempPageIds);
  const loadMoreFlagRef = useRef(tempLoadMoreFlags);
  const accessData = useSelector(selectAccessData);
  const [showSavedJobs, setShowSavedJobs] = useState(true);
  const ijpAccessData = accessData?.data?.find((item) => item.feature_key === '_ijpr');

  useEffect(() => {
    if (ijpAccessData) {
      const actions = ijpAccessData?.actions;
      setShowSavedJobs(actions?.includes('_bookmark'));
    }
  }, [ijpAccessData]);

  useEffect(() => {
    // Analytics
    trackScreen(ANALYTICS_SCREEN_NAMES.IJPR, NAVIGATION_ROUTES.IJPR);
  }, []);

  useEffect(() => {
    if (ijprTabTitles.length && ijprTabData === '') {
      dispatch(saveIjprTabData(ijprTabTitles[0].key));
    }
  }, [dispatch, ijprTabData, ijprTabTitles]);

  const resetPaginationForTab = (tabKey) => {
    pageIdRef.current[tabKey] = 1;
    loadMoreFlagRef.current[tabKey] = true;
  };

  const callIJPApi = useCallback(async (tabType, pageId, meOnly = false) => {
    setIsLoading(true);
    let params = { page_id: pageId };
    try {
      let data = [];
      if (tabType === IJPR_IDENTIFIERS.IJP) {
        const apiResponse = await apiRequest(GET_IJP_LANDING_LISTING, REQUEST_TYPE.GET, {});
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            data = apiResponse.response.data;
          }
        }
      } else if (tabType === IJPR_IDENTIFIERS.REFERRALS) {
        if (meOnly) {
          const apiResponse = await apiRequest(GET_IJP_LANDING_LISTING, REQUEST_TYPE.GET, {});
          if (Object.keys(apiResponse).length) {
            if (apiResponse.response.status) {
              data = apiResponse.response.data;
            }
          }
        } else {
          const apiResponse = await apiRequest(GET_REFER_LANDING_LISTING, REQUEST_TYPE.GET, {});
          if (Object.keys(apiResponse).length) {
            if (apiResponse.response.status) {
              data = apiResponse.response.data;
            }
          }
        }
      } else if (tabType === IJPR_IDENTIFIERS.MY_APPLICATIONS) {
        const apiResponse = await apiRequest(GET_MY_APPLICATIONS, REQUEST_TYPE.GET, params);
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            data = apiResponse.response.data.feeds;
          }
        }
      } else if (tabType === IJPR_IDENTIFIERS.MY_REFERRALS) {
        const apiResponse = await apiRequest(GET_MY_REFERRALS, REQUEST_TYPE.GET, params);
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            data = apiResponse.response.data.feeds;
          }
        }
      }
      setIsLoading(false);
      if (!data.length) {
        loadMoreFlagRef.current[tabType] = false;
      }
      if (pageId === 1) {
        resetPaginationForTab(tabType);
        setIjprData((tabData) => {
          return { ...tabData, ...{ [tabType]: data } };
        });
      } else {
        setIjprData((tabData) => {
          return { ...tabData, ...{ [tabType]: [...tabData[tabType], ...data] } };
        });
      }
    } catch (err) {
      handleError(
        err,
        params,
        tabType === IJPR_IDENTIFIERS.IJP
          ? GET_IJP_LANDING_LISTING
          : tabType === IJPR_IDENTIFIERS.REFERRALS
          ? meOnly
            ? GET_IJP_LANDING_LISTING
            : GET_REFER_LANDING_LISTING
          : tabType === IJPR_IDENTIFIERS.MY_APPLICATIONS
          ? GET_MY_APPLICATIONS
          : tabType === IJPR_IDENTIFIERS.MY_REFERRALS
          ? GET_MY_REFERRALS
          : '',
        NAVIGATION_ROUTES.IJPR
      );
      setIsLoading(false);
    }
  }, []);

  const renderScreen = useCallback(
    async (tabType, pageId, meOnly = false) => {
      callIJPApi(tabType, pageId, meOnly);
    },
    [callIJPApi]
  );

  const paginationHandler = (tab) => {
    if (loadMoreFlagRef.current[tab]) {
      pageIdRef.current[tab] += 1;
      renderScreen(tab, pageIdRef.current[tab]);
    }
  };

  const renderTab = (tab) => {
    switch (tab.key) {
      case IJPR_IDENTIFIERS.REFERRALS:
        return (
          <ReferralsTab
            data={ijprData[IJPR_IDENTIFIERS.REFERRALS]}
            tab={tab}
            getTabData={renderScreen}
            isLoading={isLoading}
            tabKey={IJPR_IDENTIFIERS.REFERRALS}
          />
        );
      case IJPR_IDENTIFIERS.MY_APPLICATIONS:
        return (
          <MyApplicationsTab
            data={ijprData[IJPR_IDENTIFIERS.MY_APPLICATIONS]}
            getTabData={renderScreen}
            isLoading={isLoading}
            tab={tab}
            tabKey={IJPR_IDENTIFIERS.MY_APPLICATIONS}
            loadMoreFlag={loadMoreFlagRef.current[IJPR_IDENTIFIERS.MY_APPLICATIONS]}
            paginationHandler={paginationHandler}
          />
        );
      case IJPR_IDENTIFIERS.MY_REFERRALS:
        return (
          <MyReferralsTab
            data={ijprData[IJPR_IDENTIFIERS.MY_REFERRALS]}
            getTabData={renderScreen}
            isLoading={isLoading}
            tab={tab}
            tabKey={IJPR_IDENTIFIERS.MY_REFERRALS}
            loadMoreFlag={loadMoreFlagRef.current[IJPR_IDENTIFIERS.MY_REFERRALS]}
            paginationHandler={paginationHandler}
          />
        );
      default:
        return null;
    }
  };
  const onPressBookmarkHandler = () => {
    dispatch(saveIjprBookmarkTabData(''));
    history.push(NAVIGATION_ROUTES.IJPR_SAVED_JOBS, {});
  };

  return (
    <div className="container">
      <div>
        <div className="header-view-absolute justify-content-end float-right">
          {showSavedJobs && (
            <div
              className="d-flex align-items-center common-cursor-pointer"
              onClick={onPressBookmarkHandler}>
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_BOOKMARK_OUTLINE_GREY,
                  ACCESSIBILITY_IDENTIFIERS.COMMON
                )}
                className="ijpr-saved-jobs-icon"
              />
              <p className="ijpr-saved-jobs-title mb-0 ml-1">{'Saved Jobs'}</p>
            </div>
          )}
        </div>
        <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.IJPR} />

        <NavTabs
          activeKey={ijprTabData}
          onSelect={(k) => dispatch(saveIjprTabData(k))}
          unmountOnExit>
          {ijprTabTitles.map((tab, index) => {
            return (
              <Tab key={`${index}`} eventKey={tab.key} title={tab.title}>
                {renderTab(tab, index)}
              </Tab>
            );
          })}
        </NavTabs>
      </div>
    </div>
  );
};

export default IjprScreen;
