import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveSearchText, selectBaseUrl } from '../../state/UIState';
import { Tab, Modal, Form } from 'react-bootstrap';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  MLF_CONTENT_IDENTIFIERS,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { selectArticleFilters, selectMlfTabsTitles } from '../../state/MasterData';
import { saveMlfCategoryFilterId, saveMlfTabData, selectMlfTabData } from '../../state/TabData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import NavTabs from '../../common/ui/tabs/NavTabs';
import MlfArticlesTab from './tabs/MlfArticlesTab';
import MlfCommitteeTab from './tabs/MlfCommitteeTab';
import MlfAboutTab from './tabs/MlfAboutTab';
import SearchComponent from '../../common/ui/search/SearchComponent';

const MlfMainScreen = ({ history }) => {
  const mlfTabs = useSelector(selectMlfTabsTitles);
  const mlfTabData = useSelector(selectMlfTabData);
  const dispatch = useDispatch();
  const baseUrl = useSelector(selectBaseUrl);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [showNewsDialog, setShowNewsDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState({});
  const dateResponse = useSelector(selectArticleFilters);
  const dateArray = [];
  for (var date in dateResponse) {
    dateArray.push(dateResponse[date].title);
  }
  const tempDates = {};
  const tempClearFilters = {};
  for (var val in mlfTabs) {
    tempDates[mlfTabs[val].category_id] = '';
    tempClearFilters[mlfTabs[val].category_id] = false;
  }
  const [selectedDatesArray, setSelectedDatesArray] = useState({});
  const [clearFiltersArray, setClearFilterArray] = useState({});
  const [showHeaderItems, setShowHeaderItems] = useState(true);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.MLF, NAVIGATION_ROUTES.MLF);
  }, []);

  useEffect(() => {
    if (mlfTabs?.length) {
      if (mlfTabs?.length && mlfTabData === '') {
        dispatch(saveMlfTabData(mlfTabs[0].category_id));
      } else {
        let tab = mlfTabs[mlfTabData];
        if (tab?.type === MLF_CONTENT_IDENTIFIERS.ARTICLE) {
          setShowHeaderItems(true);
        } else {
          setShowHeaderItems(false);
        }
      }
    }
  }, [dispatch, mlfTabData, mlfTabs]);

  if (mlfTabs.length && mlfTabData === '') {
    dispatch(saveMlfTabData(mlfTabs[0].category_id));
  }

  const clearFilters = () => {
    setSelectedDate((prev) => ({
      ...prev,
      [mlfTabData]: '',
    }));
    setSelectedDatesArray((prev) => ({ ...prev, [mlfTabData]: '' }));
    setClearFilterArray((prev) => ({ ...prev, [mlfTabData]: true }));
  };

  const callDateFilterFunction = () => {
    const value = selectedDate[mlfTabData] ? selectedDate[mlfTabData] : dateResponse[0].title;
    const tempdate = dateResponse.find((res) => res.title === value).value;
    setSelectedDatesArray((prev) => ({ ...prev, [mlfTabData]: tempdate }));
    setClearFilterArray((prev) => ({ ...prev, [mlfTabData]: false }));
  };

  const renderTabs = (tab) => {
    switch (tab.category_id) {
      case tab.category_id:
        if (tab?.type === MLF_CONTENT_IDENTIFIERS.ARTICLE)
          return (
            <MlfArticlesTab
              item={tab}
              selectedDate={selectedDatesArray}
              clearFilters={clearFiltersArray}
            />
          );
        if (tab?.type === MLF_CONTENT_IDENTIFIERS.STATIC) return <MlfAboutTab item={tab} />;
        else if (tab?.type === MLF_CONTENT_IDENTIFIERS.TESTIMONY)
          return (
            <MlfCommitteeTab
              item={tab}
              selectedDate={selectedDatesArray}
              clearFilters={clearFiltersArray}
            />
          );
        break;
    }
  };

  const CustomYearView = () => {
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Label>Select Year</Form.Label>
            <div className="gcdo-year-container">
              <Form.Control
                as="select"
                custom
                value={selectedDate[mlfTabData]}
                onChange={(event) => {
                  setSelectedDate((prev) => ({
                    ...prev,
                    [mlfTabData]: event.target.value,
                  }));
                }}>
                {dateArray &&
                  dateArray.map((item) => {
                    return (
                      <option key={item} defaultValue={item === selectedDate[mlfTabData]}>
                        {item}
                      </option>
                    );
                  })}
              </Form.Control>
            </div>
          </Form.Group>
        </Form>
        <div className="gcdo-row">
          <div>
            <CustomButton
              buttonStyle="gcdo-calender-button-style"
              onClick={() => {
                setShowNewsDialog(false);
                clearFilters();
              }}>
              CLEAR FILTERS
            </CustomButton>
          </div>
          <div className="gcdo-row">
            <CustomButton
              buttonStyle="gcdo-calender-button-style"
              onClick={() => {
                setShowNewsDialog(false);
              }}>
              CANCEL
            </CustomButton>
            <CustomButton
              buttonStyle="gcdo-calender-button-style"
              onClick={() => {
                setShowNewsDialog(false);
                callDateFilterFunction();
              }}>
              DONE
            </CustomButton>
          </div>
        </div>
      </div>
    );
  };

  const handleSubmitFunction = (searchText) => {
    dispatch(saveSearchText(searchText));
    logEvent(ANALYTICS_EVENT_TYPES.MLF_SEARCH_CLICKED, '', ANALYTICS_ITEM_NAMES.MLF);
    history.push(NAVIGATION_ROUTES.MLF_SEARCH, { searchQuery: searchText });
  };

  return (
    <div className="container">
      {showHeaderItems && mlfTabs?.length > 0 && (
        <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
          <SearchComponent handleSubmitFunction={handleSubmitFunction} />
          <div className="gcdo-icon-row mr-3">
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_HEADER_COMPONENTS.CALENDER
              )}
              className="header-icon"
              onClick={() => {
                logEvent(ANALYTICS_EVENT_TYPES.MLF_CALENDAR_FILTER, '', ANALYTICS_ITEM_NAMES.MLF);
                setShowNewsDialog(true);
              }}
            />
          </div>
        </div>
      )}
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MLF} />
      <Modal
        show={showNewsDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowNewsDialog(false)}>
        <Modal.Body>{CustomYearView()}</Modal.Body>
      </Modal>
      {baseUrl !== '' && (
        <NavTabs
          activeKey={mlfTabData}
          onSelect={(k) => {
            dispatch(saveMlfTabData(k));
            dispatch(saveMlfCategoryFilterId(0));
          }}>
          {mlfTabs &&
            mlfTabs.map((tab, index) => {
              return (
                <Tab
                  key={`${index}`}
                  eventKey={tab.category_id}
                  title={tab.title}
                  className="main-content-container">
                  {renderTabs(tab, index)}
                </Tab>
              );
            })}
        </NavTabs>
      )}
    </div>
  );
};

export default MlfMainScreen;
