import React from 'react';
import { useParams } from 'react-router';
import BackButton from '../../ui/common_back_button/BackButton';
import Divider from '../../ui/divider/Divider';
import CustomYoutubePlayer from '../../ui/youtube_video/CustomYoutubePlayer';

const FullScreenVideoScreen = () => {
  const { videoId } = useParams();

  return (
    <div>
      <BackButton />
      <Divider />
      <CustomYoutubePlayer
        containerClass="common-fullscreen-video-container"
        videoId={`${videoId}?autoplay=1`}
      />
    </div>
  );
};

export default FullScreenVideoScreen;
