import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  FEATURE_TYPE,
  LOADING_MESSAGES,
  ML_DAY_IDENTIFIERS,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import CommentItem from '../../../../common/screens/comment/component/CommentItem';
import DefaultContentView from '../../../../common/ui/default_content_view/DefaultContentView';
import Divider from '../../../../common/ui/divider/Divider';
import LoadMoreBtn from '../../../../common/ui/load_more_btn/LoadMoreBtn';
import UserProfilePhoto from '../../../../common/ui/user_profile_photo/UserProfilePhoto';
import {
  COMMENT_BY_ID,
  DELETE_COMMENT,
  EDIT_COMMENT,
  GET_EMPLOYEE_BY_ID,
  LIKE_UNLIKE_COMMENT,
} from '../../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../../services/Service';
import { saveCommentCountData } from '../../../../state/CommentData';
import { selectEmployeeData } from '../../../../state/EmployeeData';
import { selectAnniversaryConfig } from '../../../../state/MasterData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../../state/UIState';
import { isEmptyText, showToast } from '../../../../utils/common';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import { EditorState } from 'draft-js';
import {
  extractDataFromEditorState,
  getCommentMentionsState,
} from '../../../../common/components/hashtagsAndMentionsInput/hashtagUtils';
import CustomCommentBox from '../../../../common/ui/comment_box/CustomCommentBox';

const ChiefMessageTab = ({ identifier }) => {
  const [name, setName] = useState('');
  const [messages, setMessages] = useState([]);
  const [empMessage, setEmpMessage] = useState('');
  const [comments, setComments] = useState([]);
  const dispatch = useDispatch();
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  const [loadMoreFlag, setLoadMoreFlag] = useState(true);
  const [pageId, setPageId] = useState(1);
  const [showDeleteLoader, setShowDeleteLoader] = useState(false);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const history = useHistory();
  const [chiefData, setChiefData] = useState({});
  const [chiefName, setChiefName] = useState('Pradeep Kar');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [image, setImage] = useState('');
  const [mentions, setMentions] = useState([]);
  const mldayCommentId = identifier === ML_DAY_IDENTIFIERS.ML_35_IDENTIFIER ? '35' : '1';

  const employeeData = useSelector(selectEmployeeData);
  const anniversaryConfig = useSelector(selectAnniversaryConfig).find(
    (element) => element.identifier === identifier
  );
  const chiefId = anniversaryConfig.chief_id ?? '';

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ANNIVERSARY_CHIEF_MESSAGE_VISIT,
      identifier,
      ANALYTICS_ITEM_NAMES.ANNIVERSARY
    );
  }, [identifier]);

  useEffect(() => {
    let doj = employeeData.date_of_joining;
    if (doj) {
      const oneDay = 24 * 60 * 60 * 1000;
      const joiningDate = new Date(doj);
      const currentDate = new Date();
      const diffDays = Math.round(Math.abs((currentDate - joiningDate) / oneDay));
      if (diffDays <= 365) {
        setEmpMessage(`${diffDays} Days`);
      } else {
        const year = Math.floor(diffDays / 365);
        const month = Math.floor((diffDays % 365) / 30);
        if (year === 1 && month === 1) {
          setEmpMessage(`${year} year, ${month} month`);
        } else if (year > 1 && month === 1) {
          setEmpMessage(`${year} years, ${month} month`);
        } else if (year === 1 && month > 1) {
          setEmpMessage(` ${year} year, ${month} months`);
        } else {
          setEmpMessage(`${year} years, ${month} months`);
        }
      }
    }
  }, [employeeData.date_of_joining]);

  useEffect(() => {
    if (employeeData?.first_name?.length > 2) {
      setName(employeeData.first_name);
    } else {
      setName(employeeData.first_name + ' ' + employeeData.last_name);
    }
  }, [employeeData.first_name, employeeData.last_name]);

  useEffect(() => {
    if (
      anniversaryConfig &&
      anniversaryConfig.chief_message &&
      anniversaryConfig.chief_message.length > 0
    ) {
      let arr = [];
      anniversaryConfig.chief_message.forEach((element) => {
        let value = element;
        if (value.includes('tenureDays')) {
          const msg = value.replace('tenureDays', empMessage);
          arr.push(msg);
        } else {
          arr.push(element);
        }
      });
      setMessages([...arr]);
    }
  }, [anniversaryConfig, empMessage]);

  const getEmployeeDataById = useCallback(
    async (employeeId) => {
      try {
        const apiResponse = await apiRequest(GET_EMPLOYEE_BY_ID + employeeId, REQUEST_TYPE.GET, {});
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            if (Object.keys(apiResponse.response.data.employee).length) {
              setChiefData(apiResponse.response.data.employee.profile_picture);
              setChiefName(
                apiResponse.response.data.employee.first_name +
                  ' ' +
                  apiResponse.response.data.employee.last_name
              );
            }
          }
        }
      } catch (err) {
        handleError(err, {}, GET_EMPLOYEE_BY_ID + employeeId, NAVIGATION_ROUTES.ANNIVERSARY_SCREEN);
      }
    },
    [dispatch]
  );

  const callComments = useCallback(
    async (pageIdVar) => {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const get_params = {
        type: FEATURE_TYPE.MICROLAND_DAY_34,
        page_id: pageIdVar,
      };
      try {
        const commentApiResponse = await apiRequest(
          COMMENT_BY_ID + mldayCommentId,
          REQUEST_TYPE.GET,
          get_params
        );
        if (Object.keys(commentApiResponse).length > 0) {
          if (commentApiResponse.response.status === true) {
            if (commentApiResponse.response.data.feeds) {
              const commentsData = commentApiResponse.response.data.feeds;
              if (commentsData.length > 0) {
                setComments(commentsData);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
                setLoadMoreBtn(true);
              } else {
                setLoadMoreBtn(false);
                setLoadMoreFlag(false);
              }
            }
          }
        }
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      } catch (err) {
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        handleError(
          err,
          get_params,
          COMMENT_BY_ID + mldayCommentId,
          NAVIGATION_ROUTES.ANNIVERSARY_SCREEN
        );
      }
    },
    [dispatch]
  );

  const renderComments = () => {
    if (comments && comments.length > 0) {
      return comments.map((item) => {
        return (
          <CommentItem
            key={item.comment_id}
            commentId={item.comment_id}
            date={item.comment_on}
            employee={item.employee}
            commentReplies={item.child_comments}
            likeType={FEATURE_TYPE.MICROLAND_DAY_34}
            referId={mldayCommentId}
            callCommentsApi={callComments}
            commentLikeCount={item.like_count}
            commentHasLiked={item.liked}
            onPressWhoLikedHandler={onPressWhoLikedHandler}
            likeUnlikeCommentApi={likeUnlikeCommentApi}
            message={item.comment}
            showDeleteLoader={showDeleteLoader}
            editComment={editCommentFunction}
            deleteComment={(commentId) => {
              deleteCommentFunction(commentId);
            }}
            mentions={item.taggged_employees}
            commentText={item.comment_text}
          />
        );
      });
    } else {
      return <DefaultContentView message={ERROR_MESSAGES.NO_COMMENTS_TO_SHOW} />;
    }
  };

  const sendCommentFunction = async () => {
    const { description, addedMentions } = extractDataFromEditorState(editorState);
    let tempDes = description;
    tempDes = tempDes.replace(/ {2,}(?![\n])/g, ' ');

    addedMentions.map((user) => {
      tempDes = tempDes.replace(user.name, `<rsu>{{${user.employee_id}}}</rsu> `);
    });

    mentions.map((user) => {
      tempDes = tempDes.replace(user.name, `<rsu>{{${user.employee_id}}}</rsu> `);
    });

    if (image != '') {
      tempDes = tempDes + ` <egami>${image}</egami>`;
    }
    if (!isEmptyText(tempDes)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      const formData = new URLSearchParams();
      formData.append('type', FEATURE_TYPE.MICROLAND_DAY_34);
      formData.append('comment', tempDes);
      try {
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        const apiResponse = await apiRequest(
          COMMENT_BY_ID + mldayCommentId,
          REQUEST_TYPE.POST,
          formData
        );
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response) {
              setEditorState(EditorState.createEmpty());
              setImage('');
              dispatch(saveCommentCountData(apiResponse.response.data.comment_count));
              showToast(apiResponse.response.message);
              callComments(1);
            }
          }
        }
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      } catch (err) {
        handleError(
          err,
          formData,
          COMMENT_BY_ID + mldayCommentId,
          NAVIGATION_ROUTES.ANNIVERSARY_SCREEN
        );
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    }
  };

  const getCommentText = (val) => {
    const { description, addedMentions } = extractDataFromEditorState(editorState);
    const editorState2 = getCommentMentionsState(description + val, [
      ...addedMentions,
      ...mentions,
    ]);
    setEditorState(editorState2);
  };

  const undoCommentFunction = (commentId) => {
    deleteCommentApi(commentId, 'undo');
  };

  const showToastWithMessage = (commentId, message) => {
    showToast(
      <div className="d-flex flex-row common-comment-toast-row">
        <div className="common-comment-toast-text">{message}</div>
        <div
          className="common-comment-toast-button"
          onClick={() => {
            undoCommentFunction(commentId);
          }}>
          UNDO
        </div>
      </div>
    );
  };

  const deleteCommentFunction = (commentId) => {
    deleteCommentApi(commentId, 'delete');
  };

  const deleteCommentApi = async (commentId, type) => {
    const formData = new URLSearchParams();
    try {
      setShowDeleteLoader(true);
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + commentId + '/' + DELETE_COMMENT,
        REQUEST_TYPE.POST,
        formData
      );
      setShowDeleteLoader(false);
      if (apiResponse.response.status) {
        setLoadMoreBtn(true);
        setLoadMoreFlag(true);
        setPageId(1);
        setComments([]);
        callComments(1);
        if (type === 'delete') {
          showToastWithMessage(commentId, apiResponse.response.message);
        }
      }
    } catch (err) {
      handleError(
        err,
        formData,
        COMMENT_BY_ID + commentId + '/' + DELETE_COMMENT,
        NAVIGATION_ROUTES.ANNIVERSARY_SCREEN
      );
      setShowDeleteLoader(false);
    }
  };

  const editCommentFunction = async (commentId, comment) => {
    if (!isEmptyText(comment)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      const formData = new URLSearchParams();
      formData.append('comment', comment);
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
            progressMessage: LOADING_MESSAGES.DEFAULT,
          })
        );
        const apiResponse = await apiRequest(
          COMMENT_BY_ID + commentId + '/' + EDIT_COMMENT,
          REQUEST_TYPE.POST,
          formData
        );
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (apiResponse.response.status) {
          showToast(apiResponse.response.message);
          if (apiResponse.response.comments) {
            setLoadMoreBtn(true);
            setLoadMoreFlag(true);
            setPageId(1);
            setComments([]);
            callComments(1);
          }
        }
      } catch (err) {
        handleError(
          err,
          formData,
          COMMENT_BY_ID + commentId + '/' + EDIT_COMMENT,
          NAVIGATION_ROUTES.ANNIVERSARY_SCREEN
        );
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    }
  };

  const likeUnlikeCommentApi = async (commentId) => {
    const apiParams = new URLSearchParams();
    apiParams.append('component_refer_id', mldayCommentId);
    apiParams.append('type', FEATURE_TYPE.MICROLAND_DAY_34);
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
        })
      );
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + commentId + '/' + LIKE_UNLIKE_COMMENT,
        REQUEST_TYPE.POST,
        apiParams
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          const message = apiResponse.response.message;
          if (message) {
            showToast(message);
            callComments(1);
          }
        }
      }
    } catch (err) {
      handleError(
        err,
        apiParams,
        COMMENT_BY_ID + commentId + '/' + LIKE_UNLIKE_COMMENT,
        NAVIGATION_ROUTES.ANNIVERSARY_SCREEN
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressWhoLikedHandler = (commentId) => {
    history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
      referId: mldayCommentId,
      parentType: FEATURE_TYPE.MICROLAND_DAY_34,
      commentId: commentId,
    });
  };

  const loadMore = () => {
    if (loadMoreFlag) {
      callComments(pageId);
    }
  };

  const renderScreen = useCallback(async () => {
    callComments(1);
  }, [callComments]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  useEffect(() => {
    if (chiefId) {
      getEmployeeDataById(chiefId);
    }
  }, [chiefId, getEmployeeDataById]);

  const onMention = (mention) => {
    setMentions((prev) => [...prev, mention]);
  };

  return (
    <div className="main-content-container">
      <div className="anniversary-dear-name-item">{`Dear ${name},`}</div>
      <div className="mt-4">
        {messages.map((item, index) => {
          return (
            <p key={`${index}`} className="anniversary-message-item">
              {item}
            </p>
          );
        })}
      </div>
      {chiefId && (
        <div className="row chief-profile-container">
          <div className="d-flex flex-row pl-3">
            <UserProfilePhoto
              imageBaseUrl={chiefData?.base_url}
              imagePath={chiefData?.image_path}
              employeeId={chiefId || ''}
              imageClass="common-user-profile-photo-md"
            />
          </div>
          <div className="d-flex flex-column">
            <div className="chief-regards-text ml-3">With warm wishes,</div>
            <div className="chief-name-text ml-3 ">{chiefName}</div>
          </div>
        </div>
      )}
      {/* Commenting chief signature for sprint 50 as we need to show the profile picture */}
      {/* <img
        src={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_CEO_SIGNATURE,
          ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
        )}
        className="anniversary-sign-image"
      /> */}
      {(identifier === ML_DAY_IDENTIFIERS.ML_34_IDENTIFIER ||
        identifier === ML_DAY_IDENTIFIERS.ML_35_IDENTIFIER) && (
        <>
          <Divider style="mt-3" />
          <div className="anniversary-event-title mt-4 mb-2">{`Comments`}</div>
          {comments.length > 0 && <div className="mt-4">{renderComments()}</div>}
          {loadMoreBtn && (
            <div className="mb-4 mt-4">
              <LoadMoreBtn onClick={loadMore} isLoading={progressLoadingState.isProgressLoading} />
            </div>
          )}
          <CustomCommentBox
            getCommentText={sendCommentFunction}
            onChangehandler={getCommentText}
            showLoader={progressLoadingState.isProgressLoading}
            setEditorState={setEditorState}
            editorState={editorState}
            enableUserMentions={true}
            enableHashtags={false}
            enableImageUpload={true}
            image={image}
            setImage={setImage}
            onMention={onMention}
          />
        </>
      )}
    </div>
  );
};

export default React.memo(ChiefMessageTab);
