import React from 'react';
import { ImPlay2 } from 'react-icons/im';
import { getUrlWithSpecificRendition, getYoutubeVideoImageFromUrl } from '../../../utils/common';
import { SCREEN_DENSITY_QUALIFIERS } from '../../../common/constants/AppConstants';

const AnniversaryGalleryListItem = ({ item, onPress, baseUrl }) => {
  const thumbnailPath = item?.video_link_path
    ? getYoutubeVideoImageFromUrl(item?.video_link_path)
    : getUrlWithSpecificRendition(
        baseUrl + 'images_ml33/images/',
        SCREEN_DENSITY_QUALIFIERS.HDPI,
        item.promo_image
      );
  return (
    <div className="col-sm-4 mb-3">
      <div className="position-relative">
        <img
          src={thumbnailPath}
          className="anniversary-gallery-image common-cursor-pointer"
          onClick={() => onPress(item)}
        />
        {item?.video_link_path && (
          <div className="my-profile-edit-foreground-container" onClick={() => onPress(item)}>
            <ImPlay2 size="4em" color="#fff" />
          </div>
        )}
        {item?.gallery?.length > 0 && (
          <div
            onClick={() => onPress(item)}
            className="anniversary-gallery-count-container d-flex justify-content-center align-items-center common-cursor-pointer">
            <div className="anniversary-count-title">{`${item?.gallery?.length} Photo${
              item?.gallery?.length === 1 ? '' : 's'
            }`}</div>
          </div>
        )}
      </div>
      <div className="anniversary-title">{item.title}</div>
    </div>
  );
};

export default AnniversaryGalleryListItem;
