import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  MICROGIVE_EVENT_TYPE,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { PROJECTS_SEARCH_BY_DATE } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { saveMicrogiveProjectsTabData } from '../../state/TabData';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../state/UIState';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import ProjectDetailListItem from './components/ProjectDetailListItem';

const ProjectDetailByDateScreen = ({ history, location }) => {
  const dispatch = useDispatch();
  const { projectId, date } = location.state;
  const [searchList, setSearchList] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);

  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.PROJECT_DETAIL_BY_DATE_SCREEN,
      NAVIGATION_ROUTES.PROJECT_DETAIL_BY_DATE_SCREEN
    );
  }, []);

  const callProjectsApi = useCallback(
    async (id, loadMore) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        const params = {
          page_id: id,
          event_date: date,
          type: '',
        };
        try {
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          const apiResponse = await apiRequest(
            PROJECTS_SEARCH_BY_DATE + projectId + '/initiatives',
            REQUEST_TYPE.GET,
            params
          );
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.initiatives.length > 0) {
                setSearchList((oldArray) => [
                  ...oldArray,
                  ...apiResponse.response.data.initiatives,
                ]);
                setLoadMoreFlag(true);
                setPageId((prevCount) => prevCount + 1);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(
            err,
            params,
            PROJECTS_SEARCH_BY_DATE + projectId + '/initiatives',
            NAVIGATION_ROUTES.PROJECT_DETAIL_BY_DATE_SCREEN
          );
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      }
    },
    [date, dispatch, projectId]
  );

  useEffect(() => {
    callProjectsApi(1, true);
  }, [callProjectsApi]);

  const onPress = (item) => {
    dispatch(saveMicrogiveProjectsTabData(''));
    if (item.type === MICROGIVE_EVENT_TYPE.VOLUNTEER) {
      history.push(
        `${NAVIGATION_ROUTES.PROJECT_TABS_SCREEN}/${item.microgive_initiative_detail_id}`,
        {
          title: item.title,
          fromRoute: NAVIGATION_ROUTES.PROJECT_DETAIL_SCREEN,
        }
      );
    } else if (item.type === MICROGIVE_EVENT_TYPE.DONATION) {
      history.push(
        `${NAVIGATION_ROUTES.PROJECT_TABS_SCREEN}/${item.initiative_id}/${item.microgive_initiative_detail_id}`
      );
    }
  };

  const onPressLoadMore = () => {
    callProjectsApi(pageId, loadMoreFlag);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_EVENT_ON_THIS_DAY} />;
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICROGIVE} />
      <BackButton />
      <div className="main-content-container">
        {showEmptyContentView && !searchList.length && !progressLoadingState.isProgressLoading ? (
          emptyListView()
        ) : (
          <div>
            <div className="row">
              {searchList.map((item, index) => {
                return <ProjectDetailListItem key={`${index}`} item={item} onPress={onPress} />;
              })}
            </div>
            {loadMoreFlag && (
              <LoadMoreBtn
                onClick={onPressLoadMore}
                isLoading={progressLoadingState.isProgressLoading}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectDetailByDateScreen;
