import { Box, Step, StepLabel, Stepper } from '@mui/material';
import React from 'react';

const CustomTracker = ({ steps, activeStep }) => {
  return (
    <div>
      <Box sx={{ maxWidth: 400 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel>
                <div className="ml-2">
                  <div className="estore-bold-text-16">{step.label}</div>
                  <div className="estore-regular-text-16">{step.description}</div>
                </div>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </div>
  );
};

export default CustomTracker;
