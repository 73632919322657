import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBaseUrl } from '../../state/UIState';
import { Tab, Modal, Form } from 'react-bootstrap';

import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { selectArticleFilters, selectBtoTitles } from '../../state/MasterData';
import { saveBtoTabData, selectBtoTabData } from '../../state/TabData';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import BtoTab from './tabs/BtoTab';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import FaqTab from './tabs/FaqTab';
import NavTabs from '../../common/ui/tabs/NavTabs';
import SearchComponent from '../../common/ui/search/SearchComponent';

const BtoScreen = ({ history }) => {
  const dispatch = useDispatch();
  const btoTabs = useSelector(selectBtoTitles);
  const btoTabData = useSelector(selectBtoTabData);
  const baseUrl = useSelector(selectBaseUrl);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [showNewsDialog, setShowNewsDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState({});
  const dateResponse = useSelector(selectArticleFilters);
  const dateArray = [];
  for (var date in dateResponse) {
    dateArray.push(dateResponse[date].title);
  }
  const tempDates = {};
  const tempClearFilters = {};
  for (var val in btoTabs) {
    tempDates[btoTabs[val].category_id] = '';
    tempClearFilters[btoTabs[val].category_id] = false;
  }
  const [selectedDatesArray, setSelectedDatesArray] = useState({});
  const [clearFiltersArray, setClearFilterArray] = useState({});

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.BTO, NAVIGATION_ROUTES.BTO);
  }, []);

  useEffect(() => {
    if (btoTabs.length && btoTabData === '') {
      dispatch(saveBtoTabData(btoTabs[0].category_id));
    }
  }, [dispatch, btoTabData, btoTabs]);

  const clearFilters = () => {
    setSelectedDate((prev) => ({
      ...prev,
      [btoTabData]: '',
    }));
    setSelectedDatesArray((prev) => ({ ...prev, [btoTabData]: '' }));
    setClearFilterArray((prev) => ({ ...prev, [btoTabData]: true }));
  };

  const callDateFilterFunction = () => {
    const value = selectedDate[btoTabData] ? selectedDate[btoTabData] : dateResponse[0].title;
    const tempdate = dateResponse.find((res) => res.title === value).value;
    setSelectedDatesArray((prev) => ({ ...prev, [btoTabData]: tempdate }));
    setClearFilterArray((prev) => ({ ...prev, [btoTabData]: false }));
  };

  const renderTabs = (tab) => {
    switch (tab.category_id) {
      case 0:
        return <FaqTab url={tab.url} />;
      default:
        return (
          <BtoTab item={tab} selectedDate={selectedDatesArray} clearFilters={clearFiltersArray} />
        );
    }
  };

  const CustomYearView = () => {
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Label>Select Year</Form.Label>
            <div className="microbiz-year-containe">
              <Form.Control
                as="select"
                custom
                value={selectedDate[btoTabData]}
                onChange={(event) => {
                  setSelectedDate((prev) => ({
                    ...prev,
                    [btoTabData]: event.target.value,
                  }));
                }}>
                {dateArray &&
                  dateArray.map((item) => {
                    return (
                      <option key={item} defaultValue={item === selectedDate[btoTabData]}>
                        {item}
                      </option>
                    );
                  })}
              </Form.Control>
            </div>
          </Form.Group>
        </Form>
        <div className="d-flex justify-content-between">
          <div>
            <CustomButton
              buttonStyle="microbiz-calender-button-style"
              onClick={() => {
                setShowNewsDialog(false);
                clearFilters();
              }}>
              CLEAR FILTERS
            </CustomButton>
          </div>
          <div className="d-flex justify-content-between">
            <CustomButton
              buttonStyle="microbiz-calender-button-style"
              onClick={() => {
                setShowNewsDialog(false);
              }}>
              CANCEL
            </CustomButton>
            <CustomButton
              buttonStyle="microbiz-calender-button-style"
              onClick={() => {
                setShowNewsDialog(false);
                callDateFilterFunction();
              }}>
              DONE
            </CustomButton>
          </div>
        </div>
      </div>
    );
  };

  const handleSubmitFunction = (searchText) => {
    history.push(NAVIGATION_ROUTES.BTO_SEARCH, { searchQuery: searchText });
  };

  const renderHeader = () => {
    switch (btoTabData) {
      case '0':
        return null;
      default:
        return (
          <div className="header-view">
            <SearchComponent handleSubmitFunction={handleSubmitFunction} />
            {/* Calendar feature hid for Bto screen for sprint 44, can be brought back as per requirement*/}
            {/* <div className="header-icon mr-3">
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.CALENDER
                )}
                className="header-icon"
                onClick={() => {
                  setShowNewsDialog(true);
                }}
              />
            </div> */}
          </div>
        );
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.BTO} />
      <Modal
        show={showNewsDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowNewsDialog(false)}>
        <Modal.Body>{CustomYearView()}</Modal.Body>
      </Modal>
      {renderHeader()}
      {baseUrl !== '' && (
        <NavTabs activeKey={btoTabData} unmountOnExit onSelect={(k) => dispatch(saveBtoTabData(k))}>
          {btoTabs &&
            btoTabs.map((tab, index) => {
              return (
                <Tab
                  key={`${index}`}
                  eventKey={tab.category_id}
                  title={tab.title}
                  className="main-content-container">
                  {renderTabs(tab, index)}
                </Tab>
              );
            })}
        </NavTabs>
      )}
    </div>
  );
};

export default BtoScreen;
