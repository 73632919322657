import html2canvas from 'html2canvas';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import Divider from '../../../common/ui/divider/Divider';
import { UPLOAD_BUSINESS_CARD } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../state/UIState';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { formatDateDDMonthYYYY, formatTimeAMPM, getDevicePixelRatio } from '../../../utils/common';
import { logEvent2 } from '../../../utils/FirebaseAnalyticsUtils';
import { saveAs } from 'file-saver';
import { selectEmployeeData } from '../../../state/EmployeeData';

const SelectDateListItem = ({
  item,
  onPressRegister,
  onPressParticipants,
  onPressUpdates,
  onPressMapLink,
  index,
  certificate,
  onEnableConfirmEdit,
  onEnableConfirmCancel,
}) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const dispatch = useDispatch();

  const pixelRatio = getDevicePixelRatio();
  const employeeData = useSelector(selectEmployeeData);
  const empNameStyle = {
    position: 'absolute',
    top: `${320 / pixelRatio}px`,
    left: `${45 / pixelRatio}px`,
    color: '#ed1d23',
    fontSize: `${38 / pixelRatio}px`,
    fontFamily: 'Lato-Black',
    flexWrap: 'wrap',
    overflowWrap: 'break-word',
    width: `${600 / pixelRatio}px`,
    textOverflow: 'ellipsis',
  };
  const longerEmpNameStyle = {
    top: `${302 / pixelRatio}px`,
    left: `${45 / pixelRatio}px`,
    color: '#ed1d23',
    lineHeight: `${32 / pixelRatio}px`,
    fontSize: `${34 / pixelRatio}px`,
  };

  const eventTitle = {
    position: 'absolute',
    top: `${440 / pixelRatio}px`,
    left: `${45 / pixelRatio}px`,
    color: 'black',
    fontSize: `${28 / pixelRatio}px`,
    fontFamily: 'Lato-Black',
    flexWrap: 'wrap',
    lineHeight: `${32 / pixelRatio}px`,
    overflowWrap: 'break-word',
    width: `${620 / pixelRatio}px`,
    textOverflow: 'ellipsis',
  };

  const donationCertificateDate = {
    position: 'absolute',
    bottom: `${54 / pixelRatio}px`,
    left: `${590 / pixelRatio}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#000',
    fontSize: `${26 / pixelRatio}px`,
    fontFamily: 'Lato-Regular',
  };

  const certificateStyle = {
    position: 'absolute',
    height: `${800 / pixelRatio}px`,
    width: `${1200 / pixelRatio}px`,
    left: `${-20000 / pixelRatio}px`,
  };

  const downloadCertificate = async (e) => {
    logEvent2({
      eventType: ANALYTICS_EVENT_TYPES.MICROGIVE_CERTIFICATE_SHARE,
      itemId: item?.initiative_id ?? '',
      itemName: item?.title ?? '',
      categoryId: ANALYTICS_ITEM_NAMES.MICROGIVE,
      categoryName: ANALYTICS_ITEM_NAMES.MICROGIVE,
    });
    e.preventDefault();
    document.getElementById(`download-cheers-certificate-${index}`).classList.add('d-block');
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.GENERATING_BUSINESS_CARD,
      })
    );
    setTimeout(() => {
      html2canvas(document.getElementById(`download-cheers-certificate-${index}`), {
        height: 800 / pixelRatio,
        width: 1200 / pixelRatio,
        useCORS: true,
        onclone: function (clonedDoc) {
          clonedDoc.getElementById(`download-cheers-certificate-${index}`).classList.add('d-block');
        },
      })
        .then((canvas) => {
          document
            .getElementById(`download-cheers-certificate-${index}`)
            .classList.remove('d-block');

          let xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = function () {
            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(xhr.response);
            a.download = 'certificate.jpeg';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            a.remove();
          };
          xhr.open('GET', canvas.toDataURL('image/jpeg')); // This is to download the canvas Image
          xhr.send();
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          // commenting out below code as it is not required
          // uploadBusinessCard(canvas.toDataURL(), e);
        })
        .catch(() => {
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        });
    }, 1000);
  };

  const uploadBusinessCard = async (imageUrl, e) => {
    const params = new URLSearchParams();
    params.append('file', imageUrl);
    params.append('type', '_CHEERS_IMAGE');
    try {
      const apiResponse = await apiRequest(UPLOAD_BUSINESS_CARD, REQUEST_TYPE.POST, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status === true) {
          const message = apiResponse.response.message;
          if (message) {
            e.stopPropagation();
            saveAs(
              apiResponse.response.data.business_card.base_url +
                apiResponse.response.data.business_card.image_path,
              'certificate.jpeg'
            );
          }
        }
      }
    } catch (err) {
      handleError(err, params, UPLOAD_BUSINESS_CARD, NAVIGATION_ROUTES.SELECT_DATE_SCREEN);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };
  tippy('#myDiv', {
    placement: 'bottom-end',
    content:
      'You can download the copy of your certificate of participation post the event date once admin confirms your participation',
  });

  tippy('#disabledCancel', {
    placement: 'bottom-end',
    content: 'Cancellation date is over',
  });

  return (
    <div className="mb-3">
      <div className="microgive-outer-view d-flex flex-row justify-content-between">
        <div className="d-flex flex-column align-items-start">
          <div className="microgive-title-text">
            {/* {`${item?.event_name}`.length < 45
                ? `${item.event_name}`
                : `${item.event_name.substring(0, 44)}` + '...'} */}
            {item.event_name && item.event_name != ''
              ? `${item?.event_name}`.length < 100
                ? `${item.event_name}`
                : `${item.event_name.substring(0, 99)}` + '...'
              : 'Event'}
          </div>
          <div className="mt-1 mb-1">{`${formatDateDDMonthYYYY(item.event_date)}`}</div>
          <div className="mb-2">
            {formatTimeAMPM(item.time_range_from, 'time')} -{' '}
            {formatTimeAMPM(item.time_range_to, 'time')}
          </div>
          <div
            className="row ml-n1 pr-3 common-cursor-pointer align-self-start d-flex flex-direction-row"
            onClick={() => onPressMapLink(item.map_link)}>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_LOCATION_MARKER,
                ACCESSIBILITY_IDENTIFIERS.MICROGIVE
              )}
              className="microgive-location-icon mb-2"
            />
            <div>
              {`${item?.map_link_title}`.length < 100
                ? `${item?.map_link_title}`
                : `${item?.map_link_title.substring(0, 99)}` + '...'}
            </div>
          </div>
        </div>
        <div className="d-flex flex-row align-items-end">
          {item?.show_waitlist && item.is_event_over === false && item?.reg_closed === false && (
            <CustomButton
              buttonStyle="microgive-register-button"
              onClick={() => onPressRegister(item)}
              showLoader={progressLoadingState.isProgressLoading}>
              Join waiting list
            </CustomButton>
          )}
          {item.show_register && item.is_event_over === false && item.reg_closed === false && (
            <CustomButton
              buttonStyle="microgive-register-button"
              onClick={() => onPressRegister(item)}
              showLoader={progressLoadingState.isProgressLoading}>
              Register
            </CustomButton>
          )}

          {(item.reg_closed || item.is_event_over) &&
            item.is_waitlist === false &&
            item.is_registered === false && (
              <CustomButton buttonStyle="microgive-cancel-button">Registration Closed</CustomButton>
            )}

          {item.show_updates && (
            <div className="alignSelf-end">
              <CustomButton
                buttonStyle="microgive-register-button"
                onClick={() => onPressUpdates(item)}>
                Updates
              </CustomButton>
            </div>
          )}
          {item.show_edit && item.is_event_over === false && (
            <div className="ml-3">
              <CustomButton
                buttonStyle="microgive-register-button"
                onClick={() => onEnableConfirmEdit(item)}
                showLoader={progressLoadingState.isProgressLoading}>
                Edit
              </CustomButton>
            </div>
          )}
          {item.show_cancel && item.is_event_over === false && (
            <div className="ml-3">
              <CustomButton
                buttonStyle="microgive-cancel-button"
                onClick={() => onEnableConfirmCancel(item)}
                showLoader={progressLoadingState.isProgressLoading}>
                Cancel
              </CustomButton>
            </div>
          )}
          {item?.show_cancel && item?.is_cancel_button_disabled && (
            <div className="ml-3">
              <CustomButton id="disabledCancel" buttonStyle="microgive-cancel-button" disabled>
                Cancel
              </CustomButton>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex flex-direction-row width-100% justify-content-end mt-n3 mb-2">
        {item?.is_waitlist === false && item?.confirmed_guests != 0 ? (
          <div className="microgive-waitlist-text d-flex">
            {item?.confirmed_guests > 1
              ? `You and your ${item.confirmed_guests} guests are successfully registered.`
              : `You and your ${item.confirmed_guests} guest are successfully registered.`}
          </div>
        ) : (
          <div className="d-flex flex-row align-items-center" />
        )}
        {item?.is_waitlist && item?.confirmed_guests != 0 ? (
          <div className="microgive-waitlist-text d-flex">
            {item?.confirmed_guests > 1
              ? `You and your ${item.confirmed_guests} guests are in the waiting list.`
              : `You and your ${item.confirmed_guests} guest are in the waiting list.`}
          </div>
        ) : (
          <div className="d-flex flex-row align-items-center mb-4" />
        )}
        {item?.is_waitlist === false && item?.confirmed_guests === 0 && item?.is_registered ? (
          <div className="microgive-waitlist-text d-flex">You are now successfully registered.</div>
        ) : (
          <div className="d-flex flex-row align-items-center mb-4" />
        )}
        {item?.is_waitlist && item?.confirmed_guests === 0 ? (
          <div className="microgive-waitlist-text d-flex">You are in the waiting list.</div>
        ) : (
          <div className="d-flex flex-row align-items-center mb-4" />
        )}
      </div>

      <Divider />

      <div className="row microgive-outer-view justify-content-between pl-3 pr-3 pt-2 pb-2">
        {item?.is_event_over ? (
          <div>Event Over</div>
        ) : (
          <div
            className="microgive-participants"
            onClick={() =>
              onPressParticipants(item)
            }>{`${item.participants_count}/${item.max_registrations_allowed} Participants`}</div>
        )}
        <div className="d-flex flex-row align-items-center justify-content-between w-25">
          {certificate?.image_path && item?.show_certificate && item?.is_certificate_available ? (
            <div
              className="d-flex flex-row align-items-center common-cursor-pointer flex-wrap justify-content-center"
              onClick={downloadCertificate}>
              <div className="cheers-download-certificate ml-5 pl-5">Download Certificate</div>
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_DOWNLOAD_CERTIFICATE,
                  ACCESSIBILITY_IDENTIFIERS.CHEERS
                )}
                className="cheers-download-icon ml-2"
              />
            </div>
          ) : (
            <div className="d-flex flex-row align-items-center" />
          )}

          {certificate?.image_path &&
          item?.show_certificate &&
          item?.is_certificate_available === false ? (
            <div className="d-flex flex-row align-items-center common-cursor-pointer flex-wrap justify-content-center">
              <div id="myDiv" className="cheers-download-certificate-disabled ml-5 pl-5">
                Download Certificate
              </div>

              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_DOWNLOAD_ICON_GRAY,
                  ACCESSIBILITY_IDENTIFIERS.COMMON
                )}
                className="cheers-download-icon ml-2"
              />
            </div>
          ) : (
            <div className="d-flex flex-row align-items-center" />
          )}
        </div>
        {certificate?.image_path && item?.show_certificate && item?.is_certificate_available && (
          <div id={`download-cheers-certificate-${index}`} style={{ ...certificateStyle }}>
            <img src={certificate?.base_url + certificate?.image_path} width="100%" height="100%" />
            <div
              style={
                (
                  employeeData?.first_name +
                  ' ' +
                  (employeeData?.middle_name ? employeeData?.middle_name + ' ' : '') +
                  employeeData?.last_name
                ).length > 30
                  ? { ...empNameStyle, ...longerEmpNameStyle }
                  : { ...empNameStyle }
              }>
              {`${
                employeeData?.first_name +
                ' ' +
                (employeeData?.middle_name ? employeeData?.middle_name + ' ' : '') +
                employeeData?.last_name
              }`}
            </div>
            <div style={{ ...donationCertificateDate }}>
              {formatDateDDMonthYYYY(item?.event_date).replace(/ /g, '-')}
            </div>
            <div style={{ ...eventTitle }}>
              {`${item?.event_name}`.length < 45
                ? `${item.event_name}`
                : `${item.event_name.substring(0, 44)}` + '...'}
            </div>
          </div>
        )}
      </div>

      <Divider />
    </div>
  );
};

export default SelectDateListItem;
