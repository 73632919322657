import React from 'react';

const SingleSelectButton = (props) => {
  const selectedItems = props.selectedItems;
  const onSelectHandler = props.onSelectHandler;
  const valueTitle = props.valueTitle;
  const valueId = props.valueId;
  const highLightedButtonStyle = props.highLightedButtonStyle;
  const highLightedTextStyle = props.highLightedTextStyle;
  const unhighLightedButtonStyle = props.unhighLightedButtonStyle;
  const unhighLightedTextStyle = props.unhighLightedTextStyle;
  return (
    <>
      <div
        onClick={() => {
          onSelectHandler([valueId]);
        }}>
        <div
          className={
            selectedItems.includes(valueId) ? highLightedButtonStyle : unhighLightedButtonStyle
          }>
          <div
            className={
              selectedItems.includes(valueId) ? highLightedTextStyle : unhighLightedTextStyle
            }>
            {valueTitle}
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleSelectButton;
