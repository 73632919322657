import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import { CONTESTS_BY_ID } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { saveShowEmptyContentView, selectShowEmptyContentView } from '../../../state/UIState';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { formatDateDDMonthYYYY, getUrlWithSpecificRendition } from '../../../utils/common';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import PlayIconOverlay from '../../../common/ui/play_icon_overlay/PlayIconOverlay';

const ContestTab = (props) => {
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const [contests, setContests] = useState([]);
  const [loading, setLoading] = useState(true);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  const history = useHistory();
  const [dataLength, setDataLength] = useState(0);
  const loadMoreFlag = useRef(true);
  const pageId = useRef(1);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [eventDates, setEventDates] = useState([]);

  useEffect(() => {
    if (pageId.current !== 1) {
      loadMoreFlag.current = false;
      setLoadMoreBtn(false);
    }
  }, [dataLength]);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.CONTESTS_TAB_VISIT,
      props.item.key,
      ANALYTICS_ITEM_NAMES.CONTESTS
    );
  }, [props.item.key]);

  const callContestApi = useCallback(async () => {
    setError(false);
    setLoadMoreBtn(true);
    setLoading(true);
    dispatch(saveShowEmptyContentView(true));
    var tempParams = {
      page_id: pageId.current,
    };
    if (Object.keys(props.appliedFilters).length) {
      tempParams = { ...tempParams, ...props.appliedFilters };
    }
    try {
      const apiResponse = await apiRequest(
        CONTESTS_BY_ID + props.item.key,
        REQUEST_TYPE.GET,
        tempParams
      );
      if (pageId.current === 1) {
        setContests([]);
      }
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data.contests;
            setDataLength(data.length);
            if (data.length > 0) {
              setContests((oldArray) => [...oldArray, ...data]);
            }
            const dates = apiResponse.response.data.dates;
            setEventDates(dates);
          }
        }
      }

      setLoading(false);
    } catch (err) {
      handleError(err, tempParams, CONTESTS_BY_ID + props.item.key, NAVIGATION_ROUTES.CONTESTS);
      setLoading(false);
      setError(true);
    }
  }, [dispatch, props.appliedFilters, props.item.key]);

  useEffect(() => {
    if (eventDates.length > 0) {
      props.getEventDate(eventDates);
    }
  }, [eventDates, props]);

  const renderScreen = useCallback(async () => {
    callContestApi();
  }, [callContestApi]);

  useEffect(() => {
    if (props.appliedFilters) {
      setAppliedFilters(props.appliedFilters);
    }
    pageId.current = 1;
    loadMoreFlag.current = true;
    const reciever = renderScreen();
    return () => reciever;
  }, [props.appliedFilters, renderScreen]);

  const loadMore = () => {
    if (loadMoreFlag.current) {
      pageId.current = pageId.current + 1;
      callContestApi();
    }
  };

  const emptyListView = () => {
    return (
      <DefaultContentView
        message={
          props.item.key === 'upcoming'
            ? NO_DATA_TEXT.NO_UPCOMING_CONTESTS
            : NO_DATA_TEXT.NO_PAST_CONTESTS
        }
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  const onPressItem = (item) => {
    history.push(`${NAVIGATION_ROUTES.CONTESTS}/${item.contest_id}`);
  };

  const contestArray =
    contests &&
    contests.map((item, index) => {
      return (
        <div
          key={`${index}`}
          className="col-sm-4 mb-2 common-cursor-pointer"
          onClick={() => onPressItem(item)}>
          <div>
            {item?.content_load_type === '3' && <PlayIconOverlay />}
            <img
              src={getUrlWithSpecificRendition(
                item.promo_image.base_url,
                SCREEN_DENSITY_QUALIFIERS.HDPI,
                item.promo_image.image_path
              )}
              className="embed-responsive embed-responsive-16by9"
            />
            <div className="row ml-1 mt-1">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.NEWS_CALENDER,
                  ACCESSIBILITY_IDENTIFIERS.NEWS
                )}
                className="contests-calender-style"
              />
              <div className="contests-event-date">
                {formatDateDDMonthYYYY(item.start_date + ' ' + item.start_time)}
              </div>
            </div>
          </div>
          <div className="contests-title">{item.title}</div>
          <div className="contests-summary">{item.summary}</div>
        </div>
      );
    });

  return (
    <div className="main-content-container">
      {showEmptyContentView && !contestArray.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          <div className="row">{contestArray}</div>
          <ActivityLoader visible={loading} />
          {loadMoreBtn && (
            <div className="mb-4 mt-4">
              <LoadMoreBtn onClick={loadMore} isLoading={loading} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ContestTab;
