import React from 'react';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { useSelector } from 'react-redux';

const LikeCommentView = (props) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  return (
    <div className="ceo-chat-like-comment-row">
      {props.showLikeButton && (
        <div className="ceo-chat-icon-row">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              props.question.likes.is_liked
                ? ACCESSIBILITY_FEATURE_COMPONENTS.CEO_CHAT_ACTIVE_LIKE
                : ACCESSIBILITY_FEATURE_COMPONENTS.CEO_CHAT_INACTIVE_LIKE,
              ACCESSIBILITY_IDENTIFIERS.CEO_CHAT
            )}
            alt="image"
            className="ceo-chat-ceo-like-icon ceo-chat-icon-container"
            onClick={(e) => {
              e.stopPropagation();
              props.likeFunction();
            }}
          />
          <div
            className="ceo-chat-icon ceo-chat-iconText"
            onClick={(e) => {
              props.question.question_id === props.likedemployee
                ? props.likedEmployeeCount
                : props.question.likes.count > 0
                ? () => {
                    e.stopPropagation();
                    props.whoLikedFunction();
                  }
                : () => {};
            }}>
            {props.question.question_id === props.likedemployee
              ? props.likedEmployeeCount
              : props.question.likes.count}
          </div>
        </div>
      )}

      {props.showCommentButton && (
        <div
          className="ceo-chat-icon-row"
          onClick={(e) => {
            e.stopPropagation();
            props.commentFunction();
          }}>
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.CEO_CHAT_COMMENT,
              ACCESSIBILITY_IDENTIFIERS.CEO_CHAT
            )}
            alt="image"
            className="ceo-chat-ceo-comment-icon ceo-chat-icon-container"
          />
          <div className="ceo-chat-icon ceo-chat-iconText">{props.question.comment_count}</div>
        </div>
      )}

      <div
        className="ceo-chat-icon-row"
        onClick={(e) => {
          e.stopPropagation();
          props.replyFunction();
        }}>
        <img
          src={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.CEO_CHAT_REPLY,
            ACCESSIBILITY_IDENTIFIERS.CEO_CHAT
          )}
          alt="image"
          className="ceo-chat-ceo-reply-icon ceo-chat-icon-container"
        />
        <div className="ceo-chat-icon ceo-chat-iconText">{props.question.answer_count}</div>
      </div>
    </div>
  );
};

export default LikeCommentView;
