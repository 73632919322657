import { store } from '../config/store';
import { saveCommunityData } from '../state/CommunityData';
import { resetAllTabs, saveMiHrTabData } from '../state/TabData';
import { saveTravelRequestData } from '../state/TravelRequestMasterData';

export const getToken = () => {
  return store.getState().ui.jwtToken;
};

export const getBaseUrl = () => {
  return store.getState().ui.baseUrl;
};

export const getLamdaUrl = () => {
  return store.getState().ui.lamdaUrl;
};

export const getGenericToken = () => {
  return store.getState().ui.genericToken;
};

export const getOrgDomain = () => {
  return store.getState().ui.orgDomain;
};

export const getEmployeeCode = () => {
  return store.getState().employeeData.data.employee_code;
};

export const getAccessibilityData = () => {
  return store.getState().accessibilityData.data;
};

export const getEmployeeData = () => {
  return store.getState().employeeData.data;
};

export const getThirdPartyApps = () => {
  return store.getState().masterData.masterData.third_party_apps;
};

export const getConfigurations = () => {
  return Object.keys(store.getState().masterData.masterData).length > 0
    ? store.getState().masterData.masterData.configurations
    : {};
};

export const getNewJoineesInfo = () => {
  return store.getState().dashboardData.data.joinees_info;
};

export const getEmployeeADID = () => {
  return store.getState().employeeData.data.ad_id;
};

export const getMasterData = () => {
  return Object.keys(store.getState().masterData.masterData).length > 0
    ? store.getState().masterData.masterData
    : {};
};

export const getGreythTargetUrl = () => {
  return store.getState().masterData.masterData.configurations._greythr_target_link;
};

export const dispatchResetAllTabs = () => {
  return store.dispatch(resetAllTabs());
};

export const dispatchResetMiHRTabData = () => {
  return store.dispatch(saveMiHrTabData(''));
};

export const dispatchSaveCommunityData = (data) => {
  return store.dispatch(saveCommunityData(data));
};

export const getUserChatGroups = () => {
  return store.getState().chatData.userChatGroups;
};

export const dispatchSaveTravelRequestData = () => {
  return store.dispatch(saveTravelRequestData({}));
};

export const getProfanityList = () => {
  return store.getState().profanityData.profanityList;
};

export const getEstoreConfig = () => {
  return store.getState().masterData.masterData.estore;
};

export const getAttendanceInfo = () => {
  return store.getState().masterData.masterData.configurations._attendance.web;
};
