import React from 'react';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';

const ChampionListItem = ({ item, onPress }) => {
  return (
    <div className="col-sm-4 mb-3">
      <div className="communities-cross-axis-view common-cursor-pointer" onClick={onPress}>
        <UserProfilePhoto
          imageBaseUrl={item.profile_picture.base_url}
          imagePath={item.profile_picture.image_path}
          imageClass="common-user-profile-photo-lg"
          employeeId={item.employee_id}
        />
        <div className="ml-3 communities-cross-axis-view">
          <div>
            <p className="d-flex align-items-center m-0 p-0">
              <span className="communities-name-text mb-0">{item.employee_name}</span>
              {!!item.pronouns && (
                <span className="communities-pronoun-text ml-1">{'(' + item.pronouns + ')'}</span>
              )}
            </p>
            <div className="mt-1 communities-place-text">{item.location.title}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChampionListItem;
