import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { GET_MLF_TESTMONIALS, INCREASE_VIEW_COUNT_EXTERNAL_LINK } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import {
  formatDateDDMonthYYYY,
  formatSecondsIntoMinutes,
  formatSecondsIntoTimeForVideos,
  getUrlWithSpecificRendition,
} from '../../../utils/common';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import PlayIconOverlay from '../../../common/ui/play_icon_overlay/PlayIconOverlay';

const MlfCommitteeTab = (props) => {
  const [showEmptyContentView, setShowEmptyContentView] = useState(false);
  const [mlfArtclesList, setMlfArtclesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const accessibilityData = useSelector(selectAccessibilityData);
  const history = useHistory();
  const pageId = useRef(1);
  const [testimonyData, setTestimonyData] = useState({});
  const employeeData = useSelector(selectEmployeeData);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.ESG_TAB_VISIT, props?.item?.title, ANALYTICS_ITEM_NAMES.ESG);
  }, [props?.item?.title]);

  const callMlfTestimonialApi = useCallback(async () => {
    setLoading(true);
    setShowEmptyContentView(false);
    const get_params = {
      page_id: 1,
    };
    try {
      const apiResponse = await apiRequest(GET_MLF_TESTMONIALS, REQUEST_TYPE.GET, get_params);
      if (pageId.current === 1) {
        setMlfArtclesList([]);
      }

      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.feeds) {
            const data = apiResponse?.response?.data?.feeds ?? {};
            if (Object.keys(data).length === 0) {
              setShowEmptyContentView(true);
            } else {
              setShowEmptyContentView(false);
            }
            setTestimonyData(data);
          }
        }
      }

      setLoading(false);
      setShowEmptyContentView(false);
    } catch (err) {
      handleError(err, get_params, GET_MLF_TESTMONIALS, NAVIGATION_ROUTES.MLF);
      setLoading(false);
      setShowEmptyContentView(true);
    }
  }, []);

  const renderScreen = useCallback(async () => {
    callMlfTestimonialApi();
  }, [callMlfTestimonialApi]);

  useEffect(() => {
    const reciever = renderScreen();
    return () => reciever;
  }, [renderScreen]);

  const emptyListView = () => {
    return (
      <DefaultContentView
        message={NO_DATA_TEXT.NO_DATA_FOUND}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  const onPressItem = async (item) => {
    if (item.content_load_type === '1') {
      try {
        const es = await apiRequest(
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          REQUEST_TYPE.GET,
          {}
        );
      } catch (err) {
        handleError(
          err,
          {},
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          NAVIGATION_ROUTES.MLF
        );
        //ignore if the view count request is not successful
        console.warn(err);
      }
      window.open(item.url);
    } else {
      history.push(`${NAVIGATION_ROUTES.DEAR_EMPLOYER}/${item.article_id}`, {
        screenIdetifier: ACCESSIBILITY_IDENTIFIERS.ESG,
      });
    }
  };

  const articleArray =
    mlfArtclesList &&
    mlfArtclesList.map((item, index) => {
      return (
        <div
          key={`${index}`}
          className="col-sm-4 mb-2 common-cursor-pointer"
          onClick={() => onPressItem(item)}>
          <div>
            {item?.content_load_type === '3' && <PlayIconOverlay />}
            <img
              src={getUrlWithSpecificRendition(
                item.image.base_url,
                SCREEN_DENSITY_QUALIFIERS.HDPI,
                item.image.image_path
              )}
              className="embed-responsive embed-responsive-16by9"
            />
            <div className="row ml-1 mt-1">
              {/* <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.NEWS_CALENDER,
                  ACCESSIBILITY_IDENTIFIERS.NEWS
                )}
                className="gcdo-calender-style"
              /> */}
              <div className="d-flex flex-row justify-content-between w-100 pr-3">
                {!!item?.time_to_read && (
                  <div className="broadcast-event-date-new ">
                    {item.time_to_read != 0 ? formatSecondsIntoMinutes(item.time_to_read) : null}
                  </div>
                )}
                {!!item?.video_duration &&
                  item?.content_load_type === '3' &&
                  item?.video_duration > 0 && (
                    <div className="broadcast-event-date-new ">
                      {formatSecondsIntoTimeForVideos(item?.video_duration)}
                    </div>
                  )}
                <div className="broadcast-event-date-new ">
                  {formatDateDDMonthYYYY(item.pub_datetime)}
                </div>
                {!!item.page_views && (
                  <div className="broadcast-event-date-new ">
                    {item.page_views > 1 ? `${item.page_views} views` : `${item.page_views} view`}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="gcdo-title">{item.title}</div>
        </div>
      );
    });

  return (
    <div>
      {showEmptyContentView && !loading ? (
        emptyListView()
      ) : (
        <div>
          <div className="row">{articleArray}</div>
          <div>
            <div className="bottom-border-testimobial">
              <HtmlView html={testimonyData?.committee_description} htmlStyle="gcdo-content-html" />
            </div>
            {[...(testimonyData?.testimonials ?? [])].map((testimony, index) => (
              <>
                <div
                  className="ijpr-border-radius mt-5 mb-5 pb-5 bottom-border-testimobial"
                  key={`${index}`}>
                  <div className="flex-nowrap flex-row d-flex">
                    <UserProfilePhoto
                      imageBaseUrl={testimony?.profile_picture?.base_url}
                      imagePath={testimony?.profile_picture?.image_path}
                      imageClass="common-user-profile-photo-lg"
                      employeeId={
                        employeeData.employee_id === testimony?.employee_id
                          ? ''
                          : testimony?.employee_id
                      }
                    />
                    <div className="d-flex flex-column px-3">
                      <div className="cheers-emp-name mt-2">
                        {testimony?.first_name +
                          `${testimony?.middle_name ? ' ' + testimony?.middle_name + ' ' : ' '}` +
                          testimony?.last_name}
                      </div>
                      <div className="mlf-testimony-card-text">
                        {testimony?.position_title ?? 'N/A'}
                      </div>
                      <div className="mlf-testimony-card-text">
                        {testimony?.employee_location ?? 'N/A'}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 pt-3 testimonial-text-container">
                    <HtmlView
                      html={testimony?.testimonials}
                      htmlStyle="gcdo-content-html custom-blockquote-text-bold"
                    />
                  </div>
                </div>
              </>
            ))}
          </div>
          <ActivityLoader visible={loading} />
        </div>
      )}
    </div>
  );
};

export default MlfCommitteeTab;
