import React from 'react';
import { formatDateDDMonthYYYY, formatTimeAMPM, isToday } from '../../../../utils/common';
import HtmlView from '../../html_view/HTMLView';
import UserProfilePhoto from '../../user_profile_photo/UserProfilePhoto';

const WishesItem = ({ date, employee, message }) => {
  return (
    <div className="my-3">
      <div className="d-flex">
        <div className="d-flex">
          <UserProfilePhoto
            imageBaseUrl={employee.profile_picture.base_url}
            imagePath={employee.profile_picture.image_path}
            imageClass="common-user-profile-photo-xxs"
            employeeId={employee.employee_id}
          />
        </div>
        <div className="ml-2 flex-grow-1">
          <div className="d-flex align-items-center">
            <div className="common-comment-item-name-text mr-2">
              {employee.first_name + ' ' + employee.last_name}
            </div>
            <div className="common-comment-item-date-text">
              {isToday(date) ? formatTimeAMPM(date) : formatDateDDMonthYYYY(date)}
            </div>
          </div>
          <div className="d-flex">
            <div className="d-flex">
              <div
                className={`py-2 px-3 my-1 common-comment-item-message-text-outer common-comment-item-other-comment-background`}>
                <HtmlView html={message} htmlStyle="common-comment-item-message-text" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WishesItem;
