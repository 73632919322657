import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  IJPR_TRACK_STYLES,
  NAVIGATION_ROUTES,
} from '../../../common/constants/AppConstants';
import { selectIjprConfigData } from '../../../state/MasterData';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import Divider from '../../../common/ui/divider/Divider';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import HtmlView from '../../../common/ui/html_view/HTMLView';

const MyReferralsTab = (props) => {
  const history = useHistory();
  const { getTabData, tab } = props;
  const accessibilityData = useSelector(selectAccessibilityData);
  const ijprConfigData = useSelector(selectIjprConfigData);

  useEffect(() => {
    getTabData(props.tabKey, 1);
  }, [props.tabKey, getTabData]);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.IJP_MY_REFERRALS_TAB_VISIT,
      tab?.title ?? '',
      ANALYTICS_ITEM_NAMES.IJPR
    );
  }, [tab]);

  const onPressViewMyApplicationHandler = (ijpId, jobCode) => {
    // Analytics
    logEvent(
      ANALYTICS_EVENT_TYPES.IJP_MY_REFERRALS_ITEM_CLICK,
      jobCode.toString(),
      ANALYTICS_ITEM_NAMES.IJPR
    );
    history.push(NAVIGATION_ROUTES.IJP_REFER_DETAIL + '/' + ijpId);
  };

  const renderStageStatusBoxTrackStyle = (value) => {
    if (value === IJPR_TRACK_STYLES.BLUE) {
      return 'ijpr-overview-item-stage-status-blue-track-style';
    } else if (value === IJPR_TRACK_STYLES.AMBER) {
      return 'ijpr-overview-item-stage-status-amber-track-style';
    } else if (value === IJPR_TRACK_STYLES.GREEN) {
      return 'ijpr-overview-item-stage-status-green-track-style';
    } else {
      return 'ijpr-overview-item-stage-status-default-track-style';
    }
  };

  const onPressLinkedinHandler = (url) => {
    window.open(url, '_blank');
  };

  const renderApplication = (item) => {
    return (
      <div className="col-12" key={item.ijp_id.toString()}>
        <div className="row mt-3">
          <div className="col-9 col-sm-11">
            {item.show_indent_status && (
              <div className="ijpr-overview-item-indent-status mb-2">ⓘ {item.indent_status}</div>
            )}
            <h5 className="ijpr-overview-item-title">{item.job_title}</h5>
            <h6 className="ijpr-overview-item-subtitle-grey mb-4">
              {item.job_code} | {item.country_title} - {item.location_title} |{' '}
              {item.minimum_expirence} - {item.maximum_expirence} yrs
            </h6>

            <h6 className="ijpr-overview-item-subtitle-grey mb-3">You’ve referred:</h6>
            <div className="row overflow-hidden">
              {item.referrals.map((referral, index) => (
                <div key={index.toString()} className="col-12 col-sm-6 col-lg-4">
                  <div className="ijpr-overview-item-refer-box p-3 mb-3">
                    <div className="d-flex justify-content-between">
                      <div>
                        <div className="mb-3">
                          <div className="ijpr-overview-item-title">{referral.candidate_name}</div>
                          <div className="ijpr-overview-item-subtitle-grey">
                            {referral.email_id}
                          </div>
                          <div className="ijpr-overview-item-subtitle-grey">
                            {referral.mobile_no}
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="ijpr-overview-item-subtitle-grey">
                            Candidate ID: {referral.candidate_id}
                          </div>
                          <div className="ijpr-overview-item-subtitle-grey">
                            Referred On: {referral.referred_on}
                          </div>
                        </div>
                        {!!referral.track_style && (
                          <div className="mb-3">
                            <div className="ijpr-overview-item-subtitle-grey">STAGE:</div>
                            <div className="d-flex">
                              <div
                                className={`ijpr-overview-item-stage-text ${renderStageStatusBoxTrackStyle(
                                  referral.track_style
                                )} px-2 py-1`}>
                                {referral.stage}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="ijpr-overview-item-subtitle-grey">STATUS:</div>
                        <div className="ijpr-overview-item-subtitle-grey">
                          {referral.stage_status}
                        </div>
                      </div>
                      {referral.linkedin ? (
                        <div
                          className="common-cursor-pointer"
                          onClick={() => onPressLinkedinHandler(referral.linkedin)}>
                          <img
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_LINKEDIN,
                              ACCESSIBILITY_IDENTIFIERS.COMMON
                            )}
                            className="ijpr-overview-item-linkedin-icon"
                          />
                        </div>
                      ) : (
                        <img
                          src={getFeatureComponentUrl(
                            accessibilityData,
                            ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_LINKEDIN_DISABLED,
                            ACCESSIBILITY_IDENTIFIERS.COMMON
                          )}
                          className="ijpr-overview-item-linkedin-icon"
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-3 col-sm-1 d-flex flex-column align-items-end justify-content-between pt-2 common-cursor-pointer">
            <div onClick={() => onPressViewMyApplicationHandler(item.ijp_id, item.job_code)}>
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.IJPR_RIGHT_ARROW,
                  ACCESSIBILITY_IDENTIFIERS.IJPR
                )}
                className="ijpr-overview-item-right-icon"
              />
            </div>
          </div>
        </div>
        <Divider />
      </div>
    );
  };

  const showEmptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.IJPR_NO_REFERRALS} />;
  };

  return (
    <div>
      <ActivityLoader visible={!!props.isLoading} />
      {props.data && (
        <div className="mb-3">
          <div className="ijpr-tab-top-banner mt-3 py-4 px-3">
            <HtmlView html={ijprConfigData.my_referral_banner_html} />
          </div>
          {!props.data.length && !props.isLoading && showEmptyListView()}
          {!!props.data.length && (
            <div>
              <div className="row overflow-hidden">
                {props.data.map((ijp) => renderApplication(ijp))}
              </div>
              {props.loadMoreFlag && (
                <LoadMoreBtn
                  buttonStyle="my-3"
                  onClick={() => props.paginationHandler(props.tabKey)}
                  isLoading={props.isLoading}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(MyReferralsTab);
