import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import { getUrlWithSpecificRendition } from '../../../utils/common';

const ForewordTab = (props) => {
  const history = useHistory();
  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.SOM_FORWARD_TAB, props.item, ANALYTICS_ITEM_NAMES.SOM);
  }, [props.item]);

  const showEmptyListView = () => {
    return (
      <DefaultContentView
        message={NO_DATA_TEXT.NO_DATA_FOUND}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  const renderItem = (item) => {
    return (
      <div
        className="som-foreword-view common-cursor-pointer"
        onClick={() => {
          history.push(NAVIGATION_ROUTES.SOM_FOREWORD, {
            employee: item,
          });
        }}>
        <div className="som-foreword-image-outer">
          <img
            src={getUrlWithSpecificRendition(
              item.promo_image.base_url,
              SCREEN_DENSITY_QUALIFIERS.LDPI,
              item.promo_image.image_path
            )}
            alt="image"
            className="som-forward-load-image"
          />
        </div>
        <div className="som-forward-summary-text som-forward-summary-container">
          <HtmlView html={item.summary.replace(/\n/g, '<br>')} />
        </div>
        <div className="som-foreword-name-text som-forward-name-container">{item.name}</div>
      </div>
    );
  };

  return (
    <div>
      {props.data && props.data.length
        ? props.data.map((item) => {
            return <div key={item.foreword_id}>{renderItem(item)}</div>;
          })
        : showEmptyListView()}
    </div>
  );
};

export default ForewordTab;
