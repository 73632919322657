import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
  SCREEN_TITLE,
} from '../../constants/AppConstants';
import {
  ADD_ANSWER,
  GET_BREAKOUTS_SESSION_QUESTIONS,
  GET_QUESTION_BY_ID,
} from '../../../services/ApiUrls';
import { apiRequest } from '../../../services/Service';
import { checkInternet } from '../../../utils/network';
import SectionHeader from '../../ui/section_header/SectionHeader';
import BackButton from '../../ui/common_back_button/BackButton';
import { useLocation, useParams } from 'react-router-dom';
import {
  formatDateDDMonthYYYY,
  formatDateDDMonthYYYYTimeAMPM,
  formatStartAndEndDate,
  getUrlWithSpecificRendition,
  isEmptyText,
  showToast,
} from '../../../utils/common';
import { getFeatureComponentUrl, getFeatureComponentUrlLdpi } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import HtmlView from '../../ui/html_view/HTMLView';
import Divider from '../../ui/divider/Divider';
import CustomButton from '../../ui/custom_button/CustomButton';
import { selectProgressLoadingState } from '../../../state/UIState';
import ActivityLoader from '../../ui/activity_loader/ActivityLoader';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import useWindowDimensions from '../../../custom_hooks/useWindowDimensions';

const SessionDiscussionScreen = () => {
  const [loading, setLoading] = useState(false);
  const { speakerId, questionId, sessionId } = useParams();
  const accessibilityData = useSelector(selectAccessibilityData);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [answer, setAnswer] = useState('');
  const [questionsData, setQuestionsData] = useState([]);
  const [agendaDetails, setAgendaDetails] = useState({});
  const { width } = useWindowDimensions();

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.BREAKOUTS_SESSION_DISCUSSION_SCREEN_VISIT,
      sessionId,
      ANALYTICS_ITEM_NAMES.BREAKOUTS
    );
  }, [sessionId]);

  const fetchQuestionsAndAnswers = async () => {
    try {
      if (await checkInternet()) {
        setLoading(true);
        const params = {
          page_id: 1,
          session_id: sessionId,
          question_id: questionId,
        };
        const apiResponse = await apiRequest(GET_QUESTION_BY_ID, REQUEST_TYPE.GET, params);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            const feeds = apiResponse.response.data?.feeds;
            if (feeds.length > 0) {
              setAgendaDetails(apiResponse.response.data?.agendaDetails);
              setQuestionsData(feeds);
            }
          } else {
            setLoading(false);
          }
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestionsAndAnswers();
  }, []);

  const postAnswer = async () => {
    if (!isEmptyText(answer)) {
      showToast(ERROR_MESSAGES.EMPTY_ANSWER);
    } else {
      try {
        if (await checkInternet()) {
          setLoading(true);
          const formData = new URLSearchParams();
          formData.append('answer', answer);
          formData.append('question_id', questionId);
          formData.append('session_id', sessionId);
          formData.append('speaker_id', speakerId);
          const apiResponse = await apiRequest(ADD_ANSWER, REQUEST_TYPE.POST, formData);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              setAnswer('');
              fetchQuestionsAndAnswers();
            } else {
              setLoading(false);
            }
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  };

  const onChange = (e) => {
    setAnswer(e.target.value);
  };

  return (
    <div className="container">
      <SectionHeader title={SCREEN_TITLE.BREAKOUTS} />
      <BackButton />
      <ActivityLoader visible={loading} />
      {Object.keys(agendaDetails).length > 0 && (
        <div className="mb-3 ml-1 pt-3">
          <h6 className="new-joinees-higher-levels-card-main-title mb-1">{agendaDetails.title}</h6>
          <p className="new-joinees-higher-levels-card-sub-title mb-3">
            {formatStartAndEndDate(agendaDetails.start_datetime, agendaDetails.end_datetime)}
          </p>
        </div>
      )}
      {questionsData.length > 0 &&
        questionsData.length > 0 &&
        questionsData.map(
          (feed) =>
            feed.questions.length > 0 &&
            feed.questions.map((question, index) => (
              <div className="mb-3" key={index}>
                <div className="material-card">
                  <div className="question-bg-color pl-4 pr-2 pb-1 pt-3">
                    <div className="row justify-content-between pt-1 pr-3">
                      <div className="row mr-1 ml-1 questioner-answerer-block">
                        {question.anonymous ? (
                          <img
                            src={getFeatureComponentUrlLdpi(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
                              ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                            )}
                            className={'breakouts-profile-image-small'}
                          />
                        ) : (
                          <img
                            src={
                              question.employee.profile_picture.image_path !== ''
                                ? getUrlWithSpecificRendition(
                                    question.employee.profile_picture.base_url,
                                    SCREEN_DENSITY_QUALIFIERS.LDPI,
                                    question.employee.profile_picture.image_path
                                  )
                                : getFeatureComponentUrlLdpi(
                                    accessibilityData,
                                    ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
                                    ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                                  )
                            }
                            className="breakouts-profile-image-small"
                          />
                        )}
                        <div className="ml-3 outer-view mr-3">
                          <div>
                            <div className="breakouts-session-questioner-title">
                              {question.anonymous ? 'Anonymous' : question.employee.employee_name}
                            </div>
                            <div className="breakouts-session-questioner-subtitle">
                              {question.anonymous ? 'Hidden' : question.employee.position_title}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-1 breakouts-session-questioner-date-posted">
                        <div>{formatDateDDMonthYYYYTimeAMPM(question.asked_on)}</div>
                        <div className="breakouts-questioner-answerer-line" />
                      </div>
                      <div className="row mr-1 ml-1 questioner-answerer-block d-flex justify-content-end">
                        <div className="ml-3 outer-view mr-3">
                          <div className="breakouts-session-questioner-right-title">
                            {feed.first_name + ' ' + feed.middle_name + ' ' + feed.last_name}
                          </div>
                          <div className="breakouts-session-questioner-subtitle-end">
                            {feed.position_title.length > 40 && width < 1200
                              ? `${feed.position_title.substring(0, 37)}...`
                              : feed.position_title}
                          </div>
                        </div>
                        <img
                          src={
                            feed.profile_picture.image_path !== ''
                              ? getUrlWithSpecificRendition(
                                  feed.profile_picture.base_url,
                                  SCREEN_DENSITY_QUALIFIERS.LDPI,
                                  feed.profile_picture.image_path
                                )
                              : getFeatureComponentUrlLdpi(
                                  accessibilityData,
                                  ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
                                  ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                                )
                          }
                          className={'breakouts-profile-image-small'}
                        />
                      </div>
                    </div>
                    <HtmlView
                      html={question.question}
                      htmlStyle="new-joinees-higher-levels-questions-questioner-question mt-3 mb-3"
                    />
                  </div>
                  {question.answers.map((answer, nestedIndex) => {
                    return (
                      <div key={`${nestedIndex}`}>
                        <Divider style="mb-3" />
                        <div className="row mr-1 ml-1 p-2">
                          {answer.anonymous ? (
                            <img
                              src={getFeatureComponentUrl(
                                accessibilityData,
                                ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
                                ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                              )}
                              className="breakouts-profile-image-small"
                            />
                          ) : (
                            <img
                              src={
                                answer.employee.profile_picture.image_path !== ''
                                  ? getUrlWithSpecificRendition(
                                      answer.employee.profile_picture.base_url,
                                      SCREEN_DENSITY_QUALIFIERS.LDPI,
                                      answer.employee.profile_picture.image_path
                                    )
                                  : getFeatureComponentUrl(
                                      accessibilityData,
                                      ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
                                      ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                                    )
                              }
                              className="breakouts-profile-image-big"
                            />
                          )}
                          <div className="ml-3 outer-view mr-3">
                            <div>
                              <div className="breakouts-session-questioner-title-answer">
                                {answer.anonymous ? 'Anonymous' : answer.employee.employee_name}
                              </div>
                              <div className="breakouts-session-questioner-subtitle-answer">
                                {answer.anonymous ? 'Hidden' : answer.employee.position_title}
                              </div>
                              <div className="mt-1 breakouts-session-questioner-date-posted-answer">
                                <div>{formatDateDDMonthYYYYTimeAMPM(answer.answer_on)}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <HtmlView
                          html={answer.answer}
                          htmlStyle="new-joinees-higher-levels-questions-questioner-question mt-3 mb-0 pb-3 ml-4"
                        />
                      </div>
                    );
                  })}
                  {question.answers.length === 0 && (
                    <div className="no-answers-yet-text pb-3">No Answers yet</div>
                  )}
                </div>
                {question.can_answer === 1 && (
                  <>
                    <div className="form-group mt-3">
                      <textarea
                        className="form-control"
                        rows="5"
                        id="comment"
                        value={answer}
                        placeholder="Type your answer"
                        onChange={onChange}
                      />
                    </div>
                    <CustomButton
                      buttonStyle="breakouts-post-ans-button-style ml-3"
                      showLoader={progressLoadingState.isProgressLoading}
                      onClick={postAnswer}>
                      Submit
                    </CustomButton>
                  </>
                )}
              </div>
            ))
        )}
    </div>
  );
};

export default SessionDiscussionScreen;
