import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_TYPE, SESSION_DETAIL_IDENTIFIERS } from '../../../common/constants/AppConstants';
import {
  GET_BREAKOUTS_SESSION_QUESTIONS,
  SUBMIT_BREAKOUT_RATING,
  SUBMIT_SESSION_RATING,
} from '../../../services/ApiUrls';
import { apiRequest } from '../../../services/Service';
import { selectSessionDetailTabs } from '../../../state/MasterData';
import { checkInternet } from '../../../utils/network';
import QuestionTab from './tabs/QuestionTab';
import MediaTab from './tabs/MediaTab';
import SessionDetailTab from './tabs/SessionDetailTab';
import { Tab } from 'react-bootstrap';
import { showToast } from '../../../utils/common';
import { saveBreakoutsSessionTabData, selectBreakoutsSessionTabData } from '../../../state/TabData';
import SectionHeader from '../../ui/section_header/SectionHeader';
import BackButton from '../../ui/common_back_button/BackButton';
import NavTabs from '../../ui/tabs/NavTabs';
import useWindowDimensions from '../../../custom_hooks/useWindowDimensions';

const SessionDetailScreen = ({ location, history }) => {
  const breakoutId = history?.location?.state?.breakoutId ?? '';
  const session_id = location?.state?.data?.session_id;
  const [data, setData] = useState(location?.state?.data);
  const dispatch = useDispatch();
  const { identifier } = location.state;
  const [tabKey, setTabKey] = useState(
    location && location.state && location.state.tabKey && location.state.tabKey
  );
  const sessionDetailTabData = useSelector(selectSessionDetailTabs);
  const sessionTabData = useSelector(selectBreakoutsSessionTabData);
  const [isProgressLoading, setIsProgressLoading] = useState(false);
  const [fromBreakoutsSessionDetailRoute] = useState(
    location.state.fromBreakoutsSessionDetailRoute ? true : false
  );

  const [questionsFeed, setQuestionsFeed] = useState([]);
  const [noQuestionsFoundStatus, setNoQuestionsFoundStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const { width } = useWindowDimensions();

  let tempRoutes = [];
  for (let tab of sessionDetailTabData) {
    if (fromBreakoutsSessionDetailRoute) {
      if (tab.key !== SESSION_DETAIL_IDENTIFIERS.MEDIA) {
        tempRoutes.push({ key: tab.key, title: tab.title });
      }
    }
  }

  const [routes] = useState(tempRoutes);

  useEffect(() => {
    if (sessionDetailTabData.length && sessionTabData === '' && tabKey) {
      dispatch(saveBreakoutsSessionTabData(tabKey));
    } else if (sessionDetailTabData.length && sessionTabData === '') {
      dispatch(saveBreakoutsSessionTabData(sessionDetailTabData[0].key));
    }
  }, [dispatch, sessionDetailTabData, sessionTabData, tabKey]);

  useEffect(() => {
    // Override the current selected tab if a tapKey is received as props
    let tab_key = location?.state?.tabKey;
    if (tab_key != undefined || tab_key != null || tab_key != '') {
      setTabKey(tab_key);
      dispatch(saveBreakoutsSessionTabData(tab_key));
    }
  }, [location.state]);

  const fetchLatestQuestions = async () => {
    setNoQuestionsFoundStatus(false);
    try {
      if (await checkInternet()) {
        setIsProgressLoading(true);
        setLoading(true);
        const apiResponse = await apiRequest(
          GET_BREAKOUTS_SESSION_QUESTIONS + `?page_id=1&session_id=${session_id}`,
          REQUEST_TYPE.GET
        );
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.data.feeds?.length > 0) {
              setQuestionsFeed(apiResponse?.response?.data?.feeds);
            } else {
              setNoQuestionsFoundStatus(true);
            }
          } else {
            setNoQuestionsFoundStatus(true);
            setIsProgressLoading(false);
            setLoading(false);
          }
        }
        setIsProgressLoading(false);
        setLoading(false);
      } else {
        //
        setIsProgressLoading(false);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setIsProgressLoading(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLatestQuestions();
  }, []);

  const callSubmitSessionRatingApi = useCallback(
    async (sessionId, rating) => {
      try {
        if (await checkInternet()) {
          setIsProgressLoading(true);
          const params = new URLSearchParams();
          params.append('session_id', sessionId);
          params.append('rate', rating);
          let apiResponse = {};
          if (fromBreakoutsSessionDetailRoute) {
            params.append('anonymous', false);
            apiResponse = await apiRequest(SUBMIT_BREAKOUT_RATING, REQUEST_TYPE.POST, params);
          }
          setIsProgressLoading(false);
          if (Object.keys(apiResponse).length) {
            if (apiResponse.response.status) {
              const message = apiResponse.response.message;
              if (message) {
                showToast(message);
                if (fromBreakoutsSessionDetailRoute) {
                  history.goBack();
                }
              }
            }
          }
        } else {
          //
        }
      } catch (err) {
        setIsProgressLoading(false);
      }
    },
    [fromBreakoutsSessionDetailRoute, history]
  );

  const renderTabs = (tab) => {
    switch (tab.key) {
      case SESSION_DETAIL_IDENTIFIERS.DETAIL:
        return (
          <SessionDetailTab
            data={data}
            tabKey={tab.key}
            breakoutId={breakoutId}
            questionsFeed={questionsFeed}
            tabTitle={tab.title}
            isProgressLoading={isProgressLoading}
            fromBreakoutsSessionDetailRoute={fromBreakoutsSessionDetailRoute}
            callSubmitSessionRatingApi={callSubmitSessionRatingApi}
            identifier={identifier}
          />
        );
      case SESSION_DETAIL_IDENTIFIERS.MEDIA:
        return <MediaTab data={data} tabKey={tab.key} tabTitle={tab.title} />;
      case SESSION_DETAIL_IDENTIFIERS.QUESTIONS:
        return (
          <QuestionTab
            data={data}
            tabKey={tab.key}
            breakoutId={breakoutId}
            sessionId={session_id}
            noQuestionsFoundStatus={noQuestionsFoundStatus}
            questionsFeed={questionsFeed}
            tabTitle={tab.title}
            fromBreakoutsSessionDetailRoute={fromBreakoutsSessionDetailRoute}
            identifier={identifier}
            loading={loading}
          />
        );
    }
  };

  return (
    <div className={width > 990 ? 'container' : 'container-session-details'}>
      <SectionHeader identifier={identifier} />
      <BackButton />
      <NavTabs
        activeKey={sessionTabData}
        onSelect={(k) => dispatch(saveBreakoutsSessionTabData(k))}
        unmountOnExit>
        {routes.map((tab, index) => {
          return (
            <Tab key={`${index}`} eventKey={tab.key} title={tab.title}>
              {renderTabs(tab, index)}
            </Tab>
          );
        })}
      </NavTabs>
    </div>
  );
};

export default SessionDetailScreen;
