import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  MICROGIVE_EVENT_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { formatDateDDMonthYYYY, getUrlWithSpecificRendition } from '../../../utils/common';
import PlayIconOverlay from '../../../common/ui/play_icon_overlay/PlayIconOverlay';

const ProjectDetailListItem = ({ item, onPress }) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  return (
    <div className="col-sm-4 mb-3 common-cursor-pointer" onClick={() => onPress(item)}>
      <div>
        {item?.content_load_type === '3' && <PlayIconOverlay />}
        <img
          src={getUrlWithSpecificRendition(
            item.promo_image.base_url,
            SCREEN_DENSITY_QUALIFIERS.HDPI,
            item.promo_image.image_path
          )}
          className="embed-responsive embed-responsive-16by9"
        />
        <div className="row mt-2 justify-content-between">
          <div className="row ml-3">
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_CALENDER,
                ACCESSIBILITY_IDENTIFIERS.MICROGIVE
              )}
              className="microgive-calender-style"
            />
            <div className="microgive-event-date">{`${formatDateDDMonthYYYY(
              item.event_date
            )}`}</div>
          </div>
          <div className="mr-3 microgive-initiative-type">
            <div className="pr-1 pl-1 pt-1 pb-1">
              {item.type === 'V' ? 'Volunteering' : 'Donation'}
            </div>
          </div>
        </div>
        <div className="microgive-title">{item.title}</div>
        <div className="microgive-short-description">{item.short_description}</div>
        <div className="microgive-short-description">
          <div className="microgive-short-description">
            {item.type === MICROGIVE_EVENT_TYPE.VOLUNTEER
              ? item.participants_count > 1
                ? `${item.participants_count} Participants`
                : `${item.participants_count} Participant`
              : item.type === MICROGIVE_EVENT_TYPE.DONATION
              ? item.participants_count > 1
                ? `${item.participants_count} Donors`
                : `${item.participants_count} Donor`
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailListItem;
