import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';

const DocumentChatView = ({ item }) => {
  const [url, setUrl] = useState('');
  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    if (item && item.url) {
      setUrl(item.url);
    }
  }, [item]);

  return (
    <div className="d-flex align-items-center my-1">
      <div className="py-2 px-2 chat-outgoing-reply-container d-flex justify-content-between align-items-center">
        <a
          target="_blank"
          rel="noreferrer"
          href={url}
          className="chat-base-text-sm chat-limit-two-lines mr-2">
          {item.doc_name}
        </a>
        {url !== '' ? (
          <img
            className="chat-doc-icon"
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_PDF_BLACK_OUTLINE,
              ACCESSIBILITY_IDENTIFIERS.COMMON
            )}
          />
        ) : null}
      </div>
    </div>
  );
};

export default memo(DocumentChatView);
