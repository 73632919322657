import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  ANALYTICS_SCREEN_NAMES,
  SCREEN_TITLE,
} from '../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../services/Service';
import { GET_BOOKMARK_JOBS } from '../../services/ApiUrls';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { saveProgressLoadingState } from '../../state/UIState';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import ReferralBookmarkTab from './tabs/ReferralBookmarkTab';
import BackButton from '../../common/ui/common_back_button/BackButton';

const IjprBookmarkScreen = () => {
  const dispatch = useDispatch();
  const [ijprData, setIjprData] = useState([]);
  const pageIdRef = useRef(1);
  const loadMoreFlagRef = useRef(true);

  useEffect(() => {
    // Analytics
    trackScreen(ANALYTICS_SCREEN_NAMES.IJPR_SAVED_JOBS, NAVIGATION_ROUTES.IJPR_SAVED_JOBS);
  }, []);
  const resetPaginationForTab = () => {
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
  };

  const callSavedJobApi = useCallback(
    async (pageId) => {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      let params = {
        page_id: pageId,
      };
      try {
        let data = [];
        const apiResponse = await apiRequest(GET_BOOKMARK_JOBS, REQUEST_TYPE.GET, params);
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            data = apiResponse?.response?.data?.bookmark || [];
          }
        }
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
        if (!data.length) {
          loadMoreFlagRef.current = false;
        }
        if (pageId === 1) {
          resetPaginationForTab();
          setIjprData(data);
        } else {
          setIjprData((prevData) => {
            return [...prevData, ...data];
          });
        }
      } catch (err) {
        handleError(err, params, GET_BOOKMARK_JOBS, NAVIGATION_ROUTES.IJPR);
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    },
    [dispatch]
  );

  const renderScreen = useCallback(
    async (pageId) => {
      callSavedJobApi(pageId);
    },
    [callSavedJobApi]
  );

  const paginationHandler = () => {
    if (loadMoreFlagRef.current) {
      pageIdRef.current += 1;
      renderScreen(pageIdRef.current);
    }
  };

  return (
    <div className="container">
      <div>
        <SectionHeader title={SCREEN_TITLE.IJPR_SAVED_JOBS} />
        <BackButton />
        <ReferralBookmarkTab
          data={ijprData}
          getTabData={renderScreen}
          loadMoreFlag={loadMoreFlagRef.current}
          paginationHandler={paginationHandler}
        />
      </div>
    </div>
  );
};

export default IjprBookmarkScreen;
