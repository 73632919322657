import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ACTION_TYPES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  ANALYTICS_SCREEN_NAMES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../common/constants/AppConstants';
import HtmlView from '../../common/ui/html_view/HTMLView';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { STARS } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { saveSomTabData } from '../../state/TabData';
import { saveProgressLoadingState, selectBaseUrl } from '../../state/UIState';
import { getUrlWithSpecificRendition } from '../../utils/common';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';

const SomMainScreen = ({ history, location }) => {
  const [pageId, setPageId] = useState(1);
  const dispatch = useDispatch();
  const [loadMoreFlag, setLoadMoreFlag] = useState(true);
  const [somData, setSomData] = useState();
  const baseUrl = useSelector(selectBaseUrl);
  const [id] = useState(new URLSearchParams(location.search).get('id'));

  const onPressItem = (item) => {
    dispatch(saveSomTabData(''));
    history.push({
      pathname: `${NAVIGATION_ROUTES.SOM_MAIN}/${item.som_id}`,
      state: { visible_tabs: item?.visible_tabs },
    });
  };

  useEffect(() => {
    if (baseUrl !== '' && id && id !== '') {
      dispatch({ type: ACTION_TYPES.GET_TOKEN, payload: { id: id } });
    }
  }, [baseUrl, dispatch, id]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.SOM_MAIN, NAVIGATION_ROUTES.SOM_MAIN);
  }, []);

  const callStarsApi = useCallback(async () => {
    if (loadMoreFlag) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const get_params = {
        page_id: pageId,
      };
      try {
        const apiResponse = await apiRequest(STARS, REQUEST_TYPE.GET, get_params);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.data.feeds) {
              const data = apiResponse.response.data.feeds;
              if (data.length > 0) {
                setSomData(data);
                setPageId((prevCount) => prevCount + 1);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        }
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      } catch (err) {
        handleError(err, get_params, STARS, NAVIGATION_ROUTES.SOM_MAIN);
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    }
  }, [dispatch, loadMoreFlag, pageId]);

  const renderScreen = useCallback(() => {
    callStarsApi();
  }, [callStarsApi]);

  useEffect(() => {
    if (baseUrl !== '') {
      renderScreen();
    }
  }, [baseUrl, renderScreen]);

  const starsArray =
    somData &&
    somData.map((item, index) => {
      return (
        <div
          key={`${index}`}
          className="col-sm-4 mb-2 common-cursor-pointer"
          onClick={() => onPressItem(item)}>
          <div>
            <img
              src={getUrlWithSpecificRendition(
                item.promo_image.base_url,
                SCREEN_DENSITY_QUALIFIERS.HDPI,
                item.promo_image.image_path
              )}
              className="embed-responsive embed-responsive-16by9"
            />
            <div className="som-title">{item.title}</div>
            <div className="som-short-description">
              <HtmlView html={item.description.replace(/\n/g, '<br>')} />
            </div>
          </div>
        </div>
      );
    });
  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.SOM} />
      <div className="main-content-container">
        <div className="row">{starsArray}</div>
      </div>
    </div>
  );
};

export default SomMainScreen;
