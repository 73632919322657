import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import { ARTICLES, INCREASE_VIEW_COUNT_EXTERNAL_LINK } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { saveEsgCategoryFilterId, selectEsgFilterCategoryId } from '../../../state/TabData';
import { saveShowEmptyContentView, selectShowEmptyContentView } from '../../../state/UIState';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import {
  formatDateDDMonthYYYY,
  formatSecondsIntoMinutes,
  formatSecondsIntoTimeForVideos,
  getUrlWithSpecificRendition,
} from '../../../utils/common';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import EsgContentFilterItem from '../components/EsgContentFilterItem';
import PlayIconOverlay from '../../../common/ui/play_icon_overlay/PlayIconOverlay';

const EsgArticlesTab = (props) => {
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const [esgItemsList, setEsgItemsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  const history = useHistory();
  const [dataLength, setDataLength] = useState(0);
  const loadMoreFlag = useRef(true);
  const pageId = useRef(1);
  const selectedDate = useRef('');
  const dispatch = useDispatch();
  const contentFiltersList = props?.item?.filters ?? [];
  const esgCategoryFilterId = useSelector(selectEsgFilterCategoryId);

  const onPress = (item) => {
    setEsgItemsList([]);
    dispatch(saveEsgCategoryFilterId(item?.filter_id));
    pageId.current = 1;
    loadMoreFlag.current = true;
  };
  useEffect(() => {
    if (pageId.current !== 1) {
      loadMoreFlag.current = false;
      setLoadMoreBtn(false);
    }
  }, [dataLength]);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ESG_TAB_VISIT,
      props?.item?.category_id,
      ANALYTICS_ITEM_NAMES.ESG
    );
  }, [props.item.category_id]);

  const callEsgArticlesApi = useCallback(async () => {
    setLoadMoreBtn(true);
    setLoading(true);
    dispatch(saveShowEmptyContentView(true));
    const get_params = {
      page_id: pageId.current,
      type: props.item.category_id,
      year: selectedDate.current ? selectedDate.current : '',
      channel_id: props.item.channel_id,
      sub_channel_id: props.item.sub_channel_id,
      filter_id: esgCategoryFilterId,
    };
    try {
      const apiResponse = await apiRequest(ARTICLES, REQUEST_TYPE.GET, get_params);
      if (pageId.current === 1) {
        setEsgItemsList([]);
      }
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.feeds) {
            const data = apiResponse.response.data.feeds;
            setDataLength(data.length);
            if (data.length > 0) {
              setEsgItemsList((oldArray) => [...oldArray, ...data]);
            }
          }
        }
      }

      setLoading(false);
    } catch (err) {
      handleError(err, get_params, ARTICLES, NAVIGATION_ROUTES.ESG);
      setLoading(false);
    }
  }, [
    dispatch,
    props.item.category_id,
    props.item.channel_id,
    props.item.sub_channel_id,
    esgCategoryFilterId,
  ]);

  const renderScreen = useCallback(async () => {
    callEsgArticlesApi();
  }, [callEsgArticlesApi]);

  useEffect(() => {
    if (props.selectedDate[props.item.category_id]) {
      selectedDate.current = props.selectedDate[props.item.category_id];
    } else {
      selectedDate.current = '';
    }
    if (props.clearFilters[props.item.category_id]) {
      selectedDate.current = '';
    }
    pageId.current = 1;
    loadMoreFlag.current = true;
    const reciever = renderScreen();
    return () => reciever;
  }, [props.clearFilters, props.item.category_id, props.selectedDate, renderScreen]);

  const loadMore = () => {
    if (loadMoreFlag.current) {
      pageId.current = pageId.current + 1;
      callEsgArticlesApi();
    }
  };

  const emptyListView = () => {
    return (
      <DefaultContentView
        message={NO_DATA_TEXT.NO_DATA_FOUND}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  const onPressItem = async (item) => {
    if (item.content_load_type === '1') {
      try {
        const es = await apiRequest(
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          REQUEST_TYPE.GET,
          {}
        );
      } catch (err) {
        handleError(
          err,
          {},
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          NAVIGATION_ROUTES.ESG
        );
        //ignore if the view count request is not successful
        console.warn(err);
      }
      window.open(item.url);
    } else {
      history.push(`${NAVIGATION_ROUTES.ESG_DETAIL}/${item.article_id}`, {
        screenIdetifier: ACCESSIBILITY_IDENTIFIERS.ESG,
      });
    }
  };

  const articleArray =
    esgItemsList &&
    esgItemsList.map((item, index) => {
      return (
        <div
          key={`${index}`}
          className="col-sm-4 mb-2 common-cursor-pointer"
          onClick={() => onPressItem(item)}>
          <div>
            {item?.content_load_type === '3' && <PlayIconOverlay />}
            <img
              src={getUrlWithSpecificRendition(
                item.image.base_url,
                SCREEN_DENSITY_QUALIFIERS.HDPI,
                item.image.image_path
              )}
              className="embed-responsive embed-responsive-16by9"
            />
            <div className="row ml-1 mt-1">
              {/* <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.NEWS_CALENDER,
                  ACCESSIBILITY_IDENTIFIERS.NEWS
                )}
                className="gcdo-calender-style"
              /> */}
              <div className="d-flex flex-row justify-content-between w-100 pr-3">
                {!!item?.time_to_read && (
                  <div className="broadcast-event-date-new ">
                    {item.time_to_read != 0 ? formatSecondsIntoMinutes(item.time_to_read) : null}
                  </div>
                )}
                {!!item?.video_duration &&
                  item?.content_load_type === '3' &&
                  item?.video_duration > 0 && (
                    <div className="broadcast-event-date-new ">
                      {formatSecondsIntoTimeForVideos(item?.video_duration)}
                    </div>
                  )}
                <div className="broadcast-event-date-new ">
                  {formatDateDDMonthYYYY(item.pub_datetime)}
                </div>
                {!!item.page_views && (
                  <div className="broadcast-event-date-new ">
                    {item.page_views > 1 ? `${item.page_views} views` : `${item.page_views} view`}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="gcdo-title">{item.title}</div>
        </div>
      );
    });

  return (
    <div>
      {contentFiltersList.length > 0 && (
        <div className="microgive-category-container">
          {contentFiltersList.map((item) => (
            <EsgContentFilterItem
              key={item?.filter_id}
              item={item}
              filterId={esgCategoryFilterId}
              onPress={onPress}
            />
          ))}
        </div>
      )}
      {showEmptyContentView && !articleArray?.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          <div className="row">{articleArray}</div>
          <ActivityLoader visible={loading} />
          {loadMoreBtn && (
            <div className="mb-4 mt-4">
              <LoadMoreBtn onClick={loadMore} isLoading={loading} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EsgArticlesTab;
