import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ML_DAY_IDENTIFIERS,
  NAVIGATION_ROUTES,
  THIRTY_ONE_QUESTIONS_CATEGORY_ID,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponent } from '../../../utils/accessibility';
import { resetAllTabs, saveNewJoineesTabCategoryFilterKey } from '../../../state/TabData';

const ModuleLink = (props) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const dispatch = useDispatch();

  const toLinkHandler = () => {
    const route =
      '/' +
      getFeatureComponent(
        accessibilityData,
        ACCESSIBILITY_FEATURE_COMPONENTS.ROUTE,
        props.identifier
      );

    if (props.identifier === ACCESSIBILITY_IDENTIFIERS.THIRTY_ONE_QUESTIONS) {
      return { pathname: route, state: { tabType: THIRTY_ONE_QUESTIONS_CATEGORY_ID } };
    } else if (
      [
        ML_DAY_IDENTIFIERS.ML_35_IDENTIFIER,
        ML_DAY_IDENTIFIERS.ML_34_IDENTIFIER,
        ML_DAY_IDENTIFIERS.ML_33_IDENTIFIER,
        ML_DAY_IDENTIFIERS.ML_32_IDENTIFIER,
        ML_DAY_IDENTIFIERS.ML_31_IDENTIFIER,
        ML_DAY_IDENTIFIERS.ML_30_IDENTIFIER,
      ].includes(props.identifier)
    ) {
      dispatch(resetAllTabs());
      return {
        pathname: `${NAVIGATION_ROUTES.ANNIVERSARY_SCREEN}/${props.identifier}`,
        state: {
          identifier: props.identifier,
          // tabKey:
          //   props.identifier === ML_DAY_IDENTIFIERS.ML_35_IDENTIFIER ? 'celebrations' : 'central',
          fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING,
        },
      };
    } else if (props?.identifier === '_surveys') {
      return { pathname: route, state: { tabKey: 'surveys' } };
    } else if (props?.identifier === '_polls') {
      return { pathname: route, state: { tabKey: 'polls' } };
    } else if (props?.identifier === '_neo') {
      dispatch(saveNewJoineesTabCategoryFilterKey(''));
      return {
        pathname: route,
        state: { tabKey: 'neo', fromRoute: NAVIGATION_ROUTES.NOTIFICATION_LISTING },
      };
    } else {
      return route;
    }
  };

  return (
    <Link className="common-unstyled-achor" to={toLinkHandler}>
      {props.children}
    </Link>
  );
};

export default ModuleLink;
