import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Carousel, Image, Form, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { LinkedinIcon, TwitterIcon, FacebookIcon, EmailIcon } from 'react-share';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  ERROR_MESSAGES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { GET_ECARD, SEND_BUSINESS_CARD } from '../../services/ApiUrls';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import {
  selectBaseUrl,
  saveProgressLoadingState,
  selectProgressLoadingState,
} from '../../state/UIState';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { getDeviceScreenDensityQualifierAsPath, shareImage, showToast } from '../../utils/common';
import { logEvent2, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { Formik } from 'formik';
import * as yup from 'yup';
import CustomTextInput from '../../common/ui/custom_text_input/CustomTextInput';
import { selectSingleLineTextSize } from '../../state/MasterData';
import { apiRequest, handleError } from '../../services/Service';
import BackButton from '../../common/ui/common_back_button/BackButton';

const EcardContentScreen = ({ location }) => {
  const { cardIndex, cards, baseUrl, categoryId, categoryName } = location.state;
  const [index, setIndex] = useState(cardIndex);
  const [deviceScreenDensityQualifierPath] = useState(getDeviceScreenDensityQualifierAsPath());
  const accessibilityData = useSelector(selectAccessibilityData);
  const apiBaseUrl = useSelector(selectBaseUrl);
  const maxSingleLineCharLimit = useSelector(selectSingleLineTextSize);
  const sendEmailFormRef = useRef();
  const dispatch = useDispatch();
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [imageUrl, setImageUrl] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.ECARDS_CONTENT, NAVIGATION_ROUTES.ECARDS_CONTENT);
  }, []);

  const shareCard = (platform, item, url) => {
    logEvent2({
      eventType: ANALYTICS_EVENT_TYPES.E_CARDS_SHARE,
      itemId: item.card_is.toString(),
      itemName: item.picture_path.toString(),
      categoryId: categoryId,
      categoryName: categoryName,
    });
    shareImage(platform, url);
  };

  const sendEmailValidationSchema = yup.object().shape({
    email: yup.string().required(ERROR_MESSAGES.UPDATE_EMAIL).email(ERROR_MESSAGES.INVALID_EMAIL),
    subject: yup.string().required(ERROR_MESSAGES.INVALID_SUBJECT),
  });

  const onPressSubmitHandler = (formValues) => {
    sendEmailApi(formValues);
  };

  const renderModal = () => {
    return (
      <div>
        <div className="modal-header facilities-custom-modal-header d-flex justify-content-center align-items-center">
          <h6 className="modal-title facilities-custom-modal-title w-100 text-center">
            {'Share e-card'}
          </h6>
          <button
            type="button"
            className="close"
            onClick={() => {
              setShowModal(false);
            }}>
            &times;
          </button>
        </div>
        <Formik
          validationSchema={sendEmailValidationSchema}
          onSubmit={onPressSubmitHandler}
          innerRef={sendEmailFormRef}
          initialValues={{
            email: '',
            subject: '',
          }}>
          {(formikProps) => (
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                logEvent2(
                  ANALYTICS_EVENT_TYPES.SHARE_BUSINESS_CARD_EMAIL,
                  '',
                  ANALYTICS_ITEM_NAMES.FACILITY
                );
                if (formikProps.submitCount > 0 && !formikProps.isValid) {
                  const formErrors = Object.values(formikProps.errors);
                  if (formErrors.length > 0) {
                    showToast(formErrors[0]);
                  }
                } else {
                  if (!formikProps.isValid) {
                    showToast(ERROR_MESSAGES.FORM_ERRORS);
                  }
                  formikProps.handleSubmit();
                }
              }}>
              <div>
                <div className="d-flex align-items-center w-100 px-3 mt-3">
                  <h6 className="text-center mr-5">{'To'}</h6>
                  <div className="w-100 ml-1">
                    <CustomTextInput
                      name="email"
                      isTitle={false}
                      maxLength={maxSingleLineCharLimit}
                      style="facilities-text-input w-100"
                      placeholder="Enter email"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      errorMessage={formikProps.errors.email}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center w-100 px-3">
                  <h6 className="text-center mr-3">{'Subject'}</h6>
                  <div className="w-100">
                    <CustomTextInput
                      name="subject"
                      isTitle={false}
                      maxLength={maxSingleLineCharLimit}
                      style="facilities-text-input w-100"
                      placeholder="Enter subject"
                      onChange={formikProps.handleChange}
                      errorMessage={formikProps.errors.subject}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center w-100 px-3">
                  <h6 className="text-center mr-4">{'Body'}</h6>
                  <div className="w-100 facilities-image-input ml-2">
                    <img
                      src={imageUrl}
                      className="facilities-business-card-image px-2 py-1 h-100"
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-end m-3">
                  <button
                    type="button"
                    className="common-custom-button-senary py-2 px-3 text-capitalize"
                    onClick={() => {
                      setShowModal(false);
                    }}>
                    {'Cancel'}
                  </button>
                  <button
                    type="submit"
                    className="common-custom-button-primary py-2 px-3"
                    disabled={progressLoadingState.isProgressLoading}>
                    {'Send'}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  const sendEmailApi = useCallback(
    async (formValues) => {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const formData = new URLSearchParams(formValues);
      formData.append('type', '_E-CARD');
      formData.append('image_path', imageUrl);
      try {
        const apiResponse = await apiRequest(SEND_BUSINESS_CARD, REQUEST_TYPE.POST, formData);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.message) {
              setShowModal(false);
              showToast(apiResponse.response.message);
            }
          }
        }
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      } catch (err) {
        handleError(err, formData, SEND_BUSINESS_CARD, NAVIGATION_ROUTES.ECARDS_CONTENT);
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    },
    [dispatch, imageUrl]
  );

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.ECARDS} />
      <BackButton />
      <div className="common-container-md mx-auto">
        <div className="ecards-gallery-full-container">
          <Carousel
            nextIcon={
              cards.length > 1 && <span aria-hidden="true" className="carousel-control-next-icon" />
            }
            prevIcon={
              cards.length > 1 && <span aria-hidden="true" className="carousel-control-prev-icon" />
            }
            activeIndex={index}
            onSelect={handleSelect}>
            {cards.map((item, index) => {
              return (
                <Carousel.Item key={`${index}`}>
                  <div className="ecards-gallery-full-image">
                    <Image
                      src={baseUrl + deviceScreenDensityQualifierPath + item.picture_path}
                      className="ecards-wallpaper-full-image"
                    />
                    <div className="ecards-carousel-social-icons">
                      <LinkedinIcon
                        size={32}
                        round={true}
                        className="mb-1 common-cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          shareCard(
                            'Linkedin',
                            item,
                            baseUrl + deviceScreenDensityQualifierPath + item.picture_path
                          );
                        }}
                      />
                      <FacebookIcon
                        size={32}
                        round={true}
                        className="mb-1 common-cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          shareCard(
                            'Facebook',
                            item,
                            baseUrl + deviceScreenDensityQualifierPath + item.picture_path
                          );
                        }}
                      />
                      <TwitterIcon
                        size={32}
                        round={true}
                        className="mb-1 common-cursor-pointer"
                        url={baseUrl + deviceScreenDensityQualifierPath + item.picture_path}
                        onClick={(e) => {
                          e.stopPropagation();
                          shareCard(
                            'Twitter',
                            item,
                            baseUrl + deviceScreenDensityQualifierPath + item.picture_path
                          );
                        }}
                      />
                      <EmailIcon
                        size={32}
                        round={true}
                        className="mb-1 common-cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          logEvent2({
                            eventType: ANALYTICS_EVENT_TYPES.E_CARDS_SHARE,
                            itemId: item.card_is.toString(),
                            itemName: item.picture_path.toString(),
                            categoryId: categoryId,
                            categoryName: categoryName,
                          });
                          setShowModal(true);
                          setImageUrl(
                            baseUrl + deviceScreenDensityQualifierPath + item.picture_path
                          );
                        }}
                      />
                      <div
                        onClick={(e) => {
                          logEvent2({
                            eventType: ANALYTICS_EVENT_TYPES.E_CARDS_SHARE,
                            itemId: item.card_is.toString(),
                            itemName: item.picture_path.toString(),
                            categoryId: categoryId,
                            categoryName: categoryName,
                          });
                          e.stopPropagation();
                          window
                            .open(`${apiBaseUrl}${GET_ECARD}${item.card_is}`, '_parent')
                            .focus();
                        }}
                        className="common-cursor-pointer">
                        <img
                          src={getFeatureComponentUrl(
                            accessibilityData,
                            ACCESSIBILITY_FEATURE_COMPONENTS.ECARDS_DOWNLOAD_CARD,
                            ACCESSIBILITY_IDENTIFIERS.ECARDS
                          )}
                          className="ecards-download-icon"
                        />
                      </div>
                    </div>
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      </div>
      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowModal(false)}>
        <Modal.Body>{renderModal()}</Modal.Body>
      </Modal>
    </div>
  );
};

export default EcardContentScreen;
