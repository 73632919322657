import React from 'react';

const ML35GalleryComponent = ({ item, onPress = () => {}, index, baseUrl }) => {
  return (
    <div>
      <div onClick={onPress}>
        <img
          className={`common-cursor-pointer`}
          src={
            baseUrl
              ? baseUrl + 'xxhdpi/' + item
              : item.picture.base_url + 'xxhdpi/' + item.picture.image_path
          }
          style={{ borderRadius: 8 }}
        />
      </div>
    </div>
  );
};

export default ML35GalleryComponent;
