import React, { useEffect, useRef, useState } from 'react';
import CustomButton from '../custom_button/CustomButton';
import Picker from 'emoji-picker-react';
import { Modal } from 'react-bootstrap';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../constants/AppConstants';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { useSelector } from 'react-redux';
import { selectProfinityFilterWarning } from '../../../state/MasterData';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getProfanityFilter } from '../../../utils/common';

const CommentBox = (props) => {
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [showEmojiDiv, setShowEmojiDiv] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const accessibilityData = useSelector(selectAccessibilityData);
  const wrapperRef = useRef(null);
  const profinityFilterWarning = useSelector(selectProfinityFilterWarning);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowEmojiDiv(false);
    }
  };

  useEffect(() => {
    setCommentText(props.commentText);
  }, [props.commentText]);

  const getTextAreaValue = (event) => {
    setCommentText(event.target.value);
    props.onChangehandler(event.target.value);
  };

  const onEmojiClick = (event, emojiObject) => {
    setCommentText(commentText + emojiObject.emoji);
    setChosenEmoji(emojiObject);
    props.onChangehandler(commentText + emojiObject.emoji);
    setShowEmojiDiv(false);
  };

  const warningDialog = () => {
    return (
      <div>
        <div className="common-comment-box-profinity-warning-text text-center imagesReview mt-2 mb-3">
          WARNING!
        </div>
        <div className="common-comment-box-profinity-text text-center imagesReview mt-2 mb-3">
          {profinityFilterWarning}
        </div>
        <div className="text-center">
          <CustomButton
            buttonStyle="common-comment-box-profinity-button mt-2"
            onClick={() => {
              setShowWarningDialog(false);
            }}>
            OK
          </CustomButton>
        </div>
      </div>
    );
  };

  return (
    <div className="common-comment-box my-3">
      <Modal
        show={showWarningDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowWarningDialog(false)}>
        <Modal.Body>{warningDialog()}</Modal.Body>
      </Modal>
      <div ref={wrapperRef} className="common-comment-emoji-icon-container common-cursor-pointer">
        <img
          src={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.EMOJI_SMILE_ICON,
            ACCESSIBILITY_IDENTIFIERS.COMMON
          )}
          id="common-comment-emoji-icon"
          className="common-comment-emoji-icon mr-3"
          onClick={() => {
            setShowEmojiDiv(true);
          }}
        />
        {showEmojiDiv && (
          <div className="common-comment-emoji-div">
            <Picker onEmojiClick={onEmojiClick} />
          </div>
        )}
      </div>
      {props.iconName && props.iconName === 'wishes' && (
        <div
          className="common-comment-wishes-icon-container common-cursor-pointer position-absolute"
          onClick={() => {
            props.openWishDialog();
          }}>
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_WISHES,
              ACCESSIBILITY_IDENTIFIERS.PROFILE
            )}
            className="my-profile-wishes-icon mr-1"
          />
        </div>
      )}
      <textarea
        name="description"
        id="description"
        style={{ color: '#212529', fontSize: 15.008 }}
        rows="4"
        placeholder="Type a comment..."
        spellCheck="false"
        value={commentText}
        onChange={getTextAreaValue}
      />
      <div className="common-submit-btn-container">
        <CustomButton
          buttonStyle="common-submit-btn mt-2"
          onClick={() => {
            if (getProfanityFilter().isProfane(commentText)) {
              setShowWarningDialog(true);
            } else {
              props.getCommentText(commentText);
            }
          }}
          showLoader={props.showLoader}>
          {props.textValue ? props.textValue : 'Submit'}
        </CustomButton>
      </div>
    </div>
  );
};

export default CommentBox;
