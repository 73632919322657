import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoaderState, saveProgressLoadingState } from '../../state/UIState';
import { apiRequestEStore, apiRequestUser } from '../../services/Service';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  BUTTON_TITLES,
  ERROR_MESSAGES,
  MODAL_TITLES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { showToast } from '../../utils/common';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { useHistory } from 'react-router';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import CustomModal from '../../common/ui/custom_modal/CustomModal';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { CUSTOMERS_ME, DELETE_ADDRESS_BY_ID } from '../../services/ApiUrls';
import { selectEStoreConfig } from '../../state/MasterData';

const AddressListScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [addresses, setAddresses] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState({});
  const [focusDefaultAddress, setFocusDefaultAddress] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [deleteAddressId, setDeleteAddressId] = useState(null);
  const [allAddresses, setAllAddresses] = useState({});
  const eStoreConfig = useSelector(selectEStoreConfig);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.MICRO_STORE_ADDRESS_LIST_SCREEN,
      NAVIGATION_ROUTES.MI_STORE_ADDRESS_LIST_SCREEN
    );
  }, []);

  const fetchContent = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequestUser(CUSTOMERS_ME, REQUEST_TYPE.GET);
      if (Object.keys(apiResponse.response).length) {
        const addresses = [];
        let tempDefaultAddress = {};
        apiResponse.response?.addresses.map((address) => {
          if (address.default_shipping) {
            tempDefaultAddress = address;
          } else {
            addresses.push(address);
          }
        });
        setAddresses(addresses);
        setDefaultAddress(tempDefaultAddress);
        setAllAddresses(apiResponse.response);
        setSelectedIndex(null);
        setFocusDefaultAddress(true);
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    fetchContent();
  }, []);

  const confirmDeleteAddress = async () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ESTORE_CONFIRM_DELETE_ADDRESS,
      `${deleteAddressId}`,
      ANALYTICS_ITEM_NAMES.DELETE_ADDRESS
    );
    setShowDeleteWarning(false);
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequestEStore(
        `${DELETE_ADDRESS_BY_ID}${deleteAddressId}`,
        REQUEST_TYPE.DELETE
      );
      if (Object.keys(apiResponse.response)) {
        fetchContent();
        showToast('Address Deleted');
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  const deleteAddress = (addressId) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ESTORE_DELETE_ADDRESS,
      `${addressId}`,
      ANALYTICS_ITEM_NAMES.DELETE_ADDRESS
    );
    setDeleteAddressId(addressId);
    setShowDeleteWarning(true);
  };

  const removeDefaultAddress = () => {
    deleteAddress(defaultAddress.id);
  };

  const makeDefaultAddress = async (addrId) => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      logEvent(
        ANALYTICS_EVENT_TYPES.ESTORE_MAKE_DEFAULT_ADDRESS,
        `${addrId}`,
        ANALYTICS_ITEM_NAMES.ADDRESS
      );
      const addresses = [];
      allAddresses.addresses.map((addr) => {
        if (addr.id === addrId) {
          addresses.push({
            ...addr,
            default_shipping: true,
            default_billing: true,
          });
        } else {
          addresses.push({
            ...addr,
            default_shipping: false,
            default_billing: false,
          });
        }
      });
      const body = {
        customer: {
          ...allAddresses,
          addresses,
        },
      };
      const apiResponse = await apiRequestUser(CUSTOMERS_ME, REQUEST_TYPE.PUT, body);
      if (Object.keys(apiResponse.response).length) {
        fetchContent();
      } else {
        dispatch(resetLoaderState());
      }
    } catch (err) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  const handleSelectedIndex = (index) => {
    setSelectedIndex(index);
    setFocusDefaultAddress(false);
  };

  const editDefaultAddress = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ESTORE_EDIT_DEFAULT_ADDRESS,
      `${defaultAddress.id}`,
      ANALYTICS_ITEM_NAMES.DEFAULT_ADDRESS
    );
    history.push(`${NAVIGATION_ROUTES.MI_STORE_EDIT_ADDRESS_SCREEN}/${defaultAddress.id}`);
  };

  const editAddress = (addressId) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ESTORE_EDIT_ADDRESS,
      `${addressId}`,
      ANALYTICS_ITEM_NAMES.EDIT_ADDRESS
    );
    history.push(`${NAVIGATION_ROUTES.MI_STORE_EDIT_ADDRESS_SCREEN}/${addressId}`);
  };

  const goToAddAddress = () => {
    logEvent(ANALYTICS_EVENT_TYPES.ESTORE_ADD_ADDRESS, `new address`, ANALYTICS_ITEM_NAMES.ADDRESS);
    history.push(NAVIGATION_ROUTES.MI_STORE_ADD_ADDRESS_SCREEN);
  };

  return (
    <div className="container">
      <CustomModal
        visible={showDeleteWarning}
        onClose={() => setShowDeleteWarning(false)}
        description={eStoreConfig.web_modal_messages.delete_address}
        title={MODAL_TITLES.CONFORMATION}
        secondaryButtonTitle={BUTTON_TITLES.NO}
        primaryButtonTitle={BUTTON_TITLES.YES}
        onClickSecondaryButton={() => {
          setShowDeleteWarning(false);
          logEvent(
            ANALYTICS_EVENT_TYPES.ESTORE_CANCEL_DELETE_ADDRESS,
            '',
            ANALYTICS_ITEM_NAMES.DELETE_ADDRESS
          );
        }}
        onClickPrimaryButton={() => confirmDeleteAddress()}
      />
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICRO_STORE} />
      <BackButton backTitle="ADDRESS" />
      <div className="e-store-cart-block">
        {Object.keys(defaultAddress).length == 0 && addresses.length == 0 && (
          <div className="estore-address-items-container">
            <DefaultContentView message={ERROR_MESSAGES.NO_ADDRESSES_FOUND} />
          </div>
        )}
        {Object.keys(defaultAddress).length > 0 && (
          <div className="mb-2">
            <div className="estore-address-items-container">
              <div
                onClick={() => {
                  setFocusDefaultAddress(true);
                  setSelectedIndex(null);
                }}
                className="estore-default-address-container p-2">
                <div>
                  <div className="estore-bold-text">{`${defaultAddress.firstname} ${defaultAddress.lastname}`}</div>
                  <div className="estore-regular-text">{defaultAddress.street}</div>
                  <div className="estore-regular-text">{`${defaultAddress.city}-${defaultAddress.postcode}`}</div>
                  <div className="estore-regular-text">{defaultAddress.region?.region}</div>
                  {focusDefaultAddress && (
                    <div className="d-flex estore-regular-text mt-3">
                      Mobile:
                      <div className="ml-1 estore-bold-text">{defaultAddress.telephone}</div>
                    </div>
                  )}
                </div>
                <div className="estore-cart-select-or-default-button border-radius-4">
                  <div className="estore-bold-text">Default</div>
                </div>
              </div>
            </div>
            <div className="estore-address-item-bottom-buttons">
              <div
                onClick={editDefaultAddress}
                className="estore-addresss-button-block store-right-border-fixed">
                <div className="estore-addresss-button-item-text">EDIT</div>
              </div>
              <div onClick={removeDefaultAddress} className="estore-addresss-button-block">
                <div className="estore-addresss-button-item-text">REMOVE</div>
              </div>
            </div>
          </div>
        )}
        {addresses.length > 0 && (
          <div className="estore-address-items-container">
            <div className="p-3 delivery-estimate-header">
              <div className="estore-bold-text">OTHER ADDRESSES</div>
            </div>
            {addresses.map((address, index) => (
              <div key={index}>
                <div
                  onClick={() => handleSelectedIndex(index)}
                  className="estore-default-address-container p-3">
                  <div>
                    <div className="estore-bold-text">{`${address.firstname} ${address.lastname}`}</div>
                    <div className="estore-regular-text">{address.street.join(', ')}</div>
                    <div className="estore-regular-text">{`${address.city}-${address.postcode}`}</div>
                    <div className="estore-regular-text">{address.region?.region}</div>
                    {selectedIndex === index && (
                      <div className="d-flex estore-regular-text mt-3">
                        Mobile:
                        <div className="ml-1 estore-bold-text">{address.telephone}</div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="estore-address-item-bottom-3-buttons">
                  <div
                    onClick={() => editAddress(address.id)}
                    className="estore-addresss-3-button-block store-right-border-fixed">
                    <div className="estore-addresss-button-item-text">EDIT</div>
                  </div>
                  <div
                    onClick={() => deleteAddress(address.id)}
                    className="estore-addresss-3-button-block store-right-border-fixed">
                    <div className="estore-addresss-button-item-text">REMOVE</div>
                  </div>
                  <div
                    onClick={() => makeDefaultAddress(address.id)}
                    className="estore-addresss-3-button-block">
                    <div className="estore-addresss-button-item-text">MAKE DEFAULT</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="estore-confirm-address-button">
          <div className="estore-cart-place-order-button w-50">
            <CustomButton buttonStyle="estore-add-to-cart-button" onClick={goToAddAddress}>
              ADD NEW ADDRESS
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressListScreen;
