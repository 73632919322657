import React, { useState } from 'react';

import { ACCESSIBILITY_IDENTIFIERS } from '../../../common/constants/AppConstants';
import SectionHeader from '../../ui/section_header/SectionHeader';
import BackButton from '../../ui/common_back_button/BackButton';
import NewJoineeSessionDetailTab from './tabs/NewJoineeSessionDetailTab';

const NewJoineeSessionDetailScreen = ({ location }) => {
  const [data, setData] = useState(location?.state?.data);
  const { identifier } = location.state;
  const [isProgressLoading, setIsProgressLoading] = useState(false);
  const [fromNewJoineesHigherLevelsSessionDetailRoute] = useState(
    location.state.fromNewJoineesHigherLevelsSessionDetailRoute ? true : false
  );

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES} />
      <BackButton />
      <NewJoineeSessionDetailTab
        data={data}
        fromNewJoineesHigherLevelsSessionDetailRoute={fromNewJoineesHigherLevelsSessionDetailRoute}
        identifier={identifier}
        isProgressLoading={isProgressLoading}
      />
    </div>
  );
};

export default NewJoineeSessionDetailScreen;
