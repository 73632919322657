import React, { useEffect, useState } from 'react';
import RulesTab from './tabs/digitalWarriorsTabs/RulesTab';
import NominationsTab from './tabs/digitalWarriorsTabs/NominationsTab';
import ResultsTab from './tabs/digitalWarriorsTabs/ResultsTab';
import EBookTab from './tabs/digitalWarriorsTabs/EBookTab';
import { Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveAnniversaryDigitalWarriorTabData,
  selectAnniversaryDigitalWarriorTabData,
} from '../../state/TabData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import NavTabs from '../../common/ui/tabs/NavTabs';
import BackButton from '../../common/ui/common_back_button/BackButton';

const DigitalWarriorScreen = ({ location }) => {
  const dispatch = useDispatch();
  const { tabs, identifier } = location.state;

  const tempRoutes = [];
  if (tabs && tabs.length > 0) {
    tabs.forEach((item) => {
      tempRoutes.push({ key: `${item.key}`, title: item.title.toUpperCase() });
    });
  }
  const [routes] = useState(tempRoutes);
  const digitalWarriorTabData = useSelector(selectAnniversaryDigitalWarriorTabData);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.DIGITAL_WARRIOR_SCREEN,
      NAVIGATION_ROUTES.DIGITAL_WARRIOR_SCREEN
    );
  }, []);

  useEffect(() => {
    if (routes.length && digitalWarriorTabData === '') {
      dispatch(saveAnniversaryDigitalWarriorTabData(routes[0].key));
    }
  }, [digitalWarriorTabData, dispatch, routes]);

  const renderTabs = (tab, index) => {
    switch (tab.key) {
      case 'rules':
        return <RulesTab identifier={identifier} terms={tabs[index].digital_warrior_terms} />;
      case 'nominations':
        return <NominationsTab identifier={identifier} />;
      case 'results':
        return <ResultsTab identifier={identifier} />;
      case 'ebook':
        return <EBookTab identifier={identifier} ebook={tabs[index].ebook} />;
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY} />
      <BackButton />
      <NavTabs
        activeKey={digitalWarriorTabData}
        onSelect={(k) => dispatch(saveAnniversaryDigitalWarriorTabData(k))}>
        {routes.map((tab, index) => {
          return (
            <Tab key={`${index}`} eventKey={tab.key} title={tab.title}>
              {renderTabs(tab, index)}
            </Tab>
          );
        })}
      </NavTabs>
    </div>
  );
};

export default DigitalWarriorScreen;
