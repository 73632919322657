import React, { useCallback, useEffect, useState } from 'react';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { useDispatch } from 'react-redux';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { apiRequestUser } from '../../services/Service';
import { resetLoaderState, saveProgressLoadingState } from '../../state/UIState';
import { useHistory, useParams } from 'react-router';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { showToast } from '../../utils/common';
import { Form } from 'react-bootstrap';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { CUSTOMERS_ME, FETCH_STATES } from '../../services/ApiUrls';

const EStoreEditAddressScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { addressId } = useParams();
  const [houseStreet, setHouseStreet] = useState('');
  const [houseStreet2, setHouseStreet2] = useState('');
  const [city, setCity] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [state, setState] = useState({});
  const [isDefault, setIsDefault] = useState(false);
  const [allAddresses, setAllAddresses] = useState({});
  const [mobile, setMobile] = useState('');
  // const [countryCode, setCountryCode] = useState(RNLocalize.getCountry());
  const [countryCode, setCountryCode] = useState('IN');
  const [states, setStates] = useState([]);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.MICRO_STORE_EDIT_ADDRESS_SCREEN,
      NAVIGATION_ROUTES.MI_STORE_EDIT_ADDRESS_SCREEN
    );
  }, []);

  const fetchStates = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequestUser(`${FETCH_STATES}${countryCode}`, REQUEST_TYPE.GET);
      if (Object.keys(apiResponse.response).length > 0) {
        if (apiResponse.response.available_regions) {
          setStates(apiResponse.response.available_regions);
        } else {
          setStates([
            {
              code: apiResponse.response.id,
              id: apiResponse.response.id,
              name: apiResponse.response.full_name_locale,
            },
          ]);
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [countryCode, dispatch]);

  useEffect(() => {
    if (countryCode) {
      fetchStates();
    }
  }, [countryCode, fetchStates]);

  const updateAddress = useCallback(async () => {
    try {
      logEvent(
        ANALYTICS_EVENT_TYPES.ESTORE_UPDATE_ADDRESS,
        `${pinCode}, ${houseStreet}, ${houseStreet2}, ${city}`,
        ANALYTICS_ITEM_NAMES.UPDATE_ADDRESS
      );
      if (pinCode && houseStreet && city && mobile && state && countryCode) {
        if (!mobile.match('^([6-9]{1})([0-9]{9})$')) {
          showToast('Please enter valid mobile number');
        } else if (!pinCode.match(/^(\d{6})$/)) {
          showToast('Please enter valid postal code');
        } else {
          dispatch(
            saveProgressLoadingState({
              isProgressLoading: true,
            })
          );
          const addresses = [];
          allAddresses.addresses.map((addr) => {
            if (addr.id == addressId) {
              addresses.push({
                ...addr,
                street: [houseStreet, houseStreet2],
                postcode: pinCode,
                city: city,
                telephone: mobile,
                default_shipping: isDefault,
                default_billing: isDefault,
                country_id: countryCode,
                firstname: allAddresses.firstname,
                lastname: allAddresses.lastname,
                region: {
                  region_code: state.code,
                  region: state.name,
                  region_id: state.id,
                },
                region_id: state.id,
                customer_id: allAddresses.id,
              });
            } else if (isDefault) {
              addresses.push({
                ...addr,
                default_shipping: false,
                default_billing: false,
              });
            } else {
              addresses.push(addr);
            }
          });
          const body = {
            customer: {
              ...allAddresses,
              addresses,
            },
          };
          const apiResponse = await apiRequestUser(CUSTOMERS_ME, REQUEST_TYPE.PUT, body);
          if (Object.keys(apiResponse.response).length) {
            dispatch(resetLoaderState());
            history.goBack();
          } else {
            dispatch(resetLoaderState());
          }
        }
      } else {
        showToast('Please fill all fields');
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    } catch (err) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [
    pinCode,
    houseStreet,
    city,
    mobile,
    state,
    countryCode,
    dispatch,
    allAddresses,
    addressId,
    isDefault,
    houseStreet2,
    history,
  ]);

  const fetchContent = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequestUser(CUSTOMERS_ME, REQUEST_TYPE.GET);
      if (Object.keys(apiResponse.response).length) {
        setAllAddresses(apiResponse.response);
        const address = apiResponse.response.addresses.find((addr) => addr.id == addressId);
        setCity(address.city ?? '');
        setPinCode(address.postcode ?? '');
        setHouseStreet(address.street[0] ?? '');
        setHouseStreet2(address.street[1] ?? '');
        setIsDefault(address.default_billing ?? false);
        setMobile(address.telephone ?? '');
        setCountryCode(address.country_id);
        setState({
          name: address.region.region,
          id: `${address.region.region_id}`,
          code: address.region.region_code,
        });
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [addressId, dispatch]);

  useEffect(() => {
    fetchContent();
  }, [fetchContent]);

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICRO_STORE} />
      <BackButton backTitle="EDIT ADDRESS" />
      <div className="e-store-cart-block">
        <div className="estore-address-items-container p-2">
          <Form.Group>
            <Form.Label className="estore-add-edit-address-label">
              {'Address (House No, Building, Street, Area) *'}
            </Form.Label>
            <Form.Control
              type="text"
              className="estore-add-edit-address-input"
              onChange={(event) => setHouseStreet(event.target.value)}
              value={houseStreet}
            />
            <Form.Label className="estore-add-edit-address-label">
              {'Address line 2 (optional)'}
            </Form.Label>
            <Form.Control
              type="text"
              className="estore-add-edit-address-input"
              onChange={(event) => setHouseStreet2(event.target.value)}
              value={houseStreet2}
            />
            <Form.Label className="estore-add-edit-address-label">{'City/District *'}</Form.Label>
            <Form.Control
              type="text"
              className="estore-add-edit-address-input"
              onChange={(event) => setCity(event.target.value)}
              value={city}
            />
            <Form.Label className="estore-add-edit-address-label">{'Mobile *'}</Form.Label>
            <Form.Control
              type="text"
              className="estore-add-edit-address-input"
              onChange={(event) => setMobile(event.target.value)}
              value={mobile}
            />
            <div className="d-flex flex-row align-items-center estore-add-edit-address-input">
              <div className="w-50 pr-3">
                <Form.Label className="estore-add-edit-address-label">{'Pincode *'}</Form.Label>
                <Form.Control
                  type="text"
                  className="w-100"
                  onChange={(event) => setPinCode(event.target.value)}
                  value={pinCode}
                />
              </div>
              <div className="w-50 pl-3">
                <Form.Label className="estore-add-edit-address-label">{'State *'}</Form.Label>
                <select
                  value={state.code}
                  onChange={(e) => setState(states.find((state) => state.code === e.target.value))}
                  className="w-100 estore-state-picker">
                  {states.map((item, index) => {
                    return (
                      <option value={item.code} key={index}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="estore-mark-default-container">
              <div className="estore-regular-text">Mark default</div>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  checked={isDefault}
                  onChange={() => {
                    setIsDefault(true);
                  }}
                />
                <div className="estore-regular-text">Yes</div>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  className="form-check-input"
                  checked={!isDefault}
                  onChange={() => {
                    setIsDefault(false);
                  }}
                />
                <div className="estore-regular-text">No</div>
              </div>
            </div>
          </Form.Group>
        </div>
        <div className="estore-confirm-address-button">
          <div className="estore-cart-place-order-button w-50">
            <CustomButton buttonStyle="estore-add-to-cart-button" onClick={updateAddress}>
              UPDATE ADDRESS
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EStoreEditAddressScreen;
