import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { FIREBASE_DB_VERSION } from '../common/constants/AppConstants';

const getMasterdata = (domain, callback) => {
  firebase
    .database()
    .ref(`${domain}/${FIREBASE_DB_VERSION}/masterdata/`)
    .once('value', (snapshot) => {
      callback(snapshot.val());
    });
};

const getProfanityListData = (domain, callback) => {
  firebase
    .database()
    .ref(`${domain}`)
    .child('profanity_list')
    .once('value')
    .then((snapshot) => {
      callback(snapshot.val());
    })
    .catch(() => {
      callback(null);
    });
};

export { getMasterdata, getProfanityListData };
