import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEventTabs } from '../../state/MasterData';
import { saveSearchText, selectBaseUrl } from '../../state/UIState';
import { Tab } from 'react-bootstrap';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ACTION_TYPES,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  FILTER_IDENTIFIERS,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import EventTab from './tabs/EventTab';
import { saveEventTabData, selectEventTabData } from '../../state/TabData';
import Filter from '../../common/ui/filter/Filter';
import CalendarDialog from '../../common/ui/calender_dialog/CalendarDialog';
import { getDateInYYYYMMDD } from '../../utils/common';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import NavTabs from '../../common/ui/tabs/NavTabs';
import { selectAccessData } from '../../state/UserAccessData';
import SearchComponent from '../../common/ui/search/SearchComponent';

const EventMainScreen = ({ location, history }) => {
  const eventTabs = useSelector(selectEventTabs);
  const eventTabData = useSelector(selectEventTabData);
  const dispatch = useDispatch();
  const baseUrl = useSelector(selectBaseUrl);
  const [id] = useState(new URLSearchParams(location.search).get('id'));
  const [appliedFilters, setAppliedFilters] = useState({});
  const [eventsDate, setEventsDate] = useState({});
  const [eventDateSelected, setEventDateSelected] = useState();
  const accessData = useSelector(selectAccessData);
  const [showFilterButton, setShowFilterButton] = useState(true);
  const eventsAccessData = accessData?.data?.find((item) => item.feature_key === '_events');

  useEffect(() => {
    if (eventsAccessData) {
      const actions = eventsAccessData?.actions;
      setShowFilterButton(actions?.includes('_filter'));
    }
  }, [eventsAccessData]);

  useEffect(() => {
    if (baseUrl !== '' && id && id !== '') {
      dispatch({ type: ACTION_TYPES.GET_TOKEN, payload: { id: id } });
    }
  }, [baseUrl, dispatch, id]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.EVENTS, NAVIGATION_ROUTES.EVENTS);
  }, []);

  useEffect(() => {
    if (eventTabs.length && eventTabData === '') {
      dispatch(saveEventTabData(eventTabs[0].key));
    }
  }, [dispatch, eventTabData, eventTabs]);

  const checkArrayPresent = (subArray, mainArray) => {
    var result =
      mainArray &&
      subArray.every(function (val) {
        return mainArray.indexOf(val) >= 0;
      });
    return result;
  };

  const getEventDate = (dates, key) => {
    if (!checkArrayPresent(dates, eventsDate[key])) {
      setEventsDate((prev) => ({ ...prev, [key]: dates }));
    }
  };

  const handleSubmitFunction = (searchText) => {
    dispatch(saveSearchText(searchText));
    history.push(NAVIGATION_ROUTES.EVENTS_SEARCH, { searchQuery: searchText });
  };

  const renderTabs = (tab) => {
    switch (tab.key) {
      case tab.key:
        return (
          <EventTab
            item={tab}
            appliedFilters={appliedFilters}
            getEventDate={(val) => {
              getEventDate(val, tab.key);
            }}
          />
        );
    }
  };

  const filterData = useCallback((selectedFilters) => {
    let tempFilterObject = {};
    if (selectedFilters) {
      Object.keys(selectedFilters).map((filterKey) => {
        if (selectedFilters[filterKey].length) {
          tempFilterObject[filterKey] = selectedFilters[filterKey].join();
        }
      });
    }
    setAppliedFilters(tempFilterObject);
  }, []);

  const onEventDateSelected = (date) => {
    logEvent(ANALYTICS_EVENT_TYPES.EVENTS_SHOW_CALENDER, '', ANALYTICS_ITEM_NAMES.EVENTS_CALENDER);
    setEventDateSelected(date);
    let formattedDate = getDateInYYYYMMDD(date);
    history.push(NAVIGATION_ROUTES.EVENTS_DATE, {
      date: formattedDate,
    });
  };

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right">
        <SearchComponent handleSubmitFunction={handleSubmitFunction} />
        <div className="gcdo-icon-row mr-3">
          <CalendarDialog
            markedDates={eventsDate && eventsDate[eventTabData]}
            onDateSelected={onEventDateSelected}
            selectedDate={eventDateSelected}
            popperPosition="bottom-end"
          />
        </div>
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.EVENTS} />

      {showFilterButton && (
        <div className="pt-3 pr-4">
          <Filter
            topFilter={FILTER_IDENTIFIERS.GEOGRAPHIES}
            geographies={true}
            locations={true}
            levels={true}
            layers={true}
            onClick={filterData}
          />
        </div>
      )}
      {baseUrl !== '' && (
        <NavTabs activeKey={eventTabData} onSelect={(k) => dispatch(saveEventTabData(k))}>
          {eventTabs &&
            eventTabs.map((tab, index) => {
              return (
                <Tab
                  key={`${index}`}
                  eventKey={tab.key}
                  title={tab.title}
                  className="main-content-container">
                  {renderTabs(tab, index)}
                </Tab>
              );
            })}
        </NavTabs>
      )}
    </div>
  );
};

export default EventMainScreen;
