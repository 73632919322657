import React, { useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  FEATURE_TYPE,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
  WOMENS_DAY_CONTENT_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';

import { COMMENT_BY_ID, POLLS_BY_ID, WOMENS_DAY_FEEDBACK } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import _ from 'lodash';
import ContestsListItem from '../components/ContestListItem';
import SurveysListItem from '../../mi_hr/components/SurveysListItem';
import PollsListItem from '../components/PollsListItem';
import { saveProgressLoadingState } from '../../../state/UIState';
import { showToast } from '../../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { selectAccessData } from '../../../state/UserAccessData';

const FeedbacksTab = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [hasNoItems, setHasNoItems] = useState(false);
  const [womensDayFeedbackItems, setWomensDayFeedbackItems] = useState([]);
  const accessData = useSelector(selectAccessData);
  const [showLikeButton, setShowLikeButton] = useState(true);
  const [showCommentButton, setShowCommentButton] = useState(true);
  const [showParticipate, setShowParticipate] = useState(true);
  const [showViewResults, setShowViewResults] = useState(true);
  const pollsAccessData = accessData?.data?.find((item) => item.feature_key === '_polls');

  useEffect(() => {
    const actions = pollsAccessData?.actions;
    setShowViewResults(actions?.includes('_viewResults'));
    setShowLikeButton(actions?.includes('_like'));
    setShowCommentButton(actions?.includes('_comment'));
    setShowParticipate(actions?.includes('_participate'));
  }, [pollsAccessData?.actions]);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.WOMENS_DAY_FEEDBACKS_TAB,
      props?.item?.key,
      ANALYTICS_ITEM_NAMES.WOMENS_DAY
    );
  }, [props.item]);

  const onPressViewResult = (item) => {
    history.push(NAVIGATION_ROUTES.POLLS_RESULTS, {
      pollId: item.poll_id,
    });
  };

  const submitVote = async (pollId, optionId) => {
    logEvent(ANALYTICS_EVENT_TYPES.POLLS_VOTE, pollId, ANALYTICS_ITEM_NAMES.POLLS);
    dispatch(saveProgressLoadingState({ isProgressLoading: true }));
    const formData = new URLSearchParams();
    formData.append('option_id', optionId);
    try {
      const apiResponse = await apiRequest(
        POLLS_BY_ID + pollId + '/vote',
        REQUEST_TYPE.POST,
        formData
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          showToast(apiResponse.response.message);
          callFeedbackApi();
        }
      }
    } catch (err) {
      handleError(err, formData, POLLS_BY_ID + pollId + '/vote', NAVIGATION_ROUTES.WOMENS_DAY);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressParticipate = (item) => {
    history.push(NAVIGATION_ROUTES.SURVEYS_PARTICIPATE, {
      surveyId: item.survey_id,
    });
  };
  const onPressContestItem = (item) => {
    history.push(`${NAVIGATION_ROUTES.CONTESTS}/${item.id}`);
  };

  const callFeedbackApi = useCallback(async () => {
    setError(false);
    setHasNoItems(false);
    setLoading(true);
    try {
      const apiResponse = await apiRequest(WOMENS_DAY_FEEDBACK, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse?.response?.data) {
            const data = apiResponse?.response?.data?.contests ?? [];
            if (_.isEmpty(data)) {
              setHasNoItems(true);
            }
            setWomensDayFeedbackItems(data);
          } else {
            setHasNoItems(true);
          }
        }
      }
      setLoading(false);
    } catch (err) {
      handleError(err, {}, WOMENS_DAY_FEEDBACK, NAVIGATION_ROUTES.WOMENS_DAY);
      setLoading(false);
      setHasNoItems(false);
      setError(true);
    }
  }, []);
  useEffect(() => {
    callFeedbackApi();
  }, [callFeedbackApi]);

  const callSurveyLikeApi = async (referId) => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
      })
    );
    const params = new URLSearchParams();
    params.append('type', FEATURE_TYPE.SURVEY);

    try {
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + referId + '/like',
        REQUEST_TYPE.POST,
        params
      );

      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );

      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            const message = apiResponse.response.message;

            if (message) {
              showToast(message);
              let updatedSurveys = womensDayFeedbackItems.map((surveyItem) =>
                surveyItem.survey_id === referId
                  ? {
                      ...surveyItem,
                      liked: data.liked,
                      like_count: data.like_count,
                    }
                  : surveyItem
              );

              setWomensDayFeedbackItems(updatedSurveys);
            }
          }
        }
      }
    } catch (err) {
      handleError(err, params, COMMENT_BY_ID + referId + '/like', NAVIGATION_ROUTES.WOMENS_DAY);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      console.error(err);
    }
  };

  const onPressSurveyLikeButton = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.SURVEY_LIKE,
      item.survey_id.toString(),
      ANALYTICS_ITEM_NAMES.LIKE
    );
    callSurveyLikeApi(item.survey_id);
  };

  const onPressSurveyCommentButton = (item) => {
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: item.survey_id,
      likeType: FEATURE_TYPE.SURVEY,
      fromRoute: NAVIGATION_ROUTES.POLLS_AND_SURVEYS,
    });
  };

  const emptyListView = () => {
    return <DefaultContentView message={NO_DATA_TEXT.NO_DATA_FOUND} />;
  };

  return (
    <div>
      <ActivityLoader visible={loading} />
      {hasNoItems && emptyListView()}
      <div className="row">
        {womensDayFeedbackItems.map((item, index) => {
          switch (item?.type) {
            case WOMENS_DAY_CONTENT_IDENTIFIERS.POLL:
              return (
                <PollsListItem
                  item={item}
                  key={`${index}`}
                  onPressViewResult={onPressViewResult}
                  submitVote={submitVote}
                  showLikeButton={showLikeButton}
                  showCommentButton={showCommentButton}
                  showParticipate={showParticipate}
                  showViewResults={showViewResults}
                />
              );

            case WOMENS_DAY_CONTENT_IDENTIFIERS.SURVEY:
              return (
                <SurveysListItem
                  item={item}
                  key={`${index}`}
                  onPressParticipate={onPressParticipate}
                  onPressLikeButton={onPressSurveyLikeButton}
                  onPressCommentButton={onPressSurveyCommentButton}
                  showLikeButton={showLikeButton}
                  showCommentButton={showCommentButton}
                  showParticipate={showParticipate}
                />
              );

            case WOMENS_DAY_CONTENT_IDENTIFIERS.CONTEST:
              return (
                <>
                  <ContestsListItem
                    index={index}
                    key={`${index}`}
                    item={item}
                    onPress={onPressContestItem}
                  />
                </>
              );
            default:
              break;
          }
        })}
      </div>
    </div>
  );
};

export default FeedbacksTab;
