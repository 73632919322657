import React from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getHeaderComponentUrl } from '../../../utils/accessibility';

const WomensDayListItem = ({ onPressItem, item }) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  return (
    <div>
      <div
        className="mb-3 material-card p-3 common-cursor-pointer"
        onClick={() => onPressItem(item)}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="anniversary-title-style">{item.title}</div>
          </div>
          <img
            src={getHeaderComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_RIGHT_ARROW,
              ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
            )}
            className="anniversary-arrow-icon"
          />
        </div>
      </div>
    </div>
  );
};

export default WomensDayListItem;
