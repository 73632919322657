import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { DI_PLEDGE_STATUS, TAKE_PLEDGE } from '../../services/ApiUrls';
import { apiRequest } from '../../services/Service';
import { updatePledge } from '../../state/EmployeeData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';

const TakeThePledgeScreen = ({ history }) => {
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);

  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [pledgeCount, setPledgeCount] = useState('');

  const onValueChange = () => setIsChecked(!isChecked);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.TAKE_THE_PLEDGE_SCREEN,
      NAVIGATION_ROUTES.TAKE_THE_PLEDGE_SCREEN
    );
  }, []);

  const callPledgeCountApi = useCallback(async () => {
    const apiResponse = await apiRequest(DI_PLEDGE_STATUS, REQUEST_TYPE.GET, {});
    if (Object.keys(apiResponse).length > 0) {
      if (apiResponse.response.status === true) {
        setPledgeCount(apiResponse.response.data.pledge_count);
      }
    }
  }, []);

  useEffect(() => {
    callPledgeCountApi();
  }, [callPledgeCountApi]);

  const callSubmitNominationApi = async () => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const params = new URLSearchParams();
      const apiResponse = await apiRequest(TAKE_PLEDGE, REQUEST_TYPE.POST, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          dispatch(updatePledge(1));
          history.push(NAVIGATION_ROUTES.PLEDGE_THANKS_SCREEN);
        }
      }
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPress = () => {
    callSubmitNominationApi();
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY} />
      <BackButton />
      <div className="main-content-container">
        <div className="anniversary-pledge-header">
          <div className="col-sm-12 pt-3 pb-3">
            <div className="text-center anniversary-pledge-count">{pledgeCount}</div>
            <div className="text-center anniversary-be-next-text">
              Microlanders have taken the pledge. Be the next!!
            </div>
          </div>
        </div>
        <div className="mt-3 anniversary-i-pledge-text">
          I pledge to work with renewed passion in taking Microland towards its digital future. As a
          proud Microlander, I will continue to be driven by optimism, vigilance, hunger to achieve,
          empowerment, curiosity and respect for each other in our journey.
        </div>
        <div className="mt-2 anniversary-i-pledge-text">
          I will be steadfast in exploring best possible solutions for our clients, collaborating
          with colleagues and partners every step of the way.
        </div>
        <div className="mt-2 anniversary-i-pledge-text">
          I pledge to further my digital capabilities to deliver the best for Microland at all
          times.
        </div>
        <div className="form-check mt-3">
          <input
            type="checkbox"
            className="form-check-input"
            checked={isChecked}
            onChange={onValueChange}
          />
          <label className="form-check-label anniversary-agree-tc">I accept</label>
        </div>
        <CustomButton
          buttonStyle="anniversary-take-pledge-btn mr-3"
          onClick={onPress}
          disabled={isChecked ? false : true}
          showLoader={progressLoadingState.isProgressLoading}>
          Take the pledge
        </CustomButton>
      </div>
    </div>
  );
};

export default TakeThePledgeScreen;
