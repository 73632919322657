import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  ACCESSIBILITY_IDENTIFIERS,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { GET_ORG_STRUCTURE } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { saveProgressLoadingState } from '../../state/UIState';
import ConnectingBorder from './components/ConnectingBorder';
import EmployeeOrgView from './components/EmployeeOrgView';
import ManagerView from './components/ManagerView';
import ReportiesView from './components/ReportiesView';

const OrgStructureScreen = ({ location, history }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const employeeCode = location.state.empCode;
  const [orgData, setOrgData] = useState();
  const [managers, setManagers] = useState([]);
  const [reportees, setReportees] = useState([]);
  const [curEmployeeCode, setCurEmployeeCode] = useState(employeeCode);
  const [curEmployee, setCurEmployee] = useState();

  const formatManagerData = (data) => {
    const tempManagers = [];
    for (var i = data.managers.manager_ids.length - 1; i >= 0; i--) {
      const tempData = data.managers.manager_details.find((manager) => {
        return String(manager.employee.employee_code) === String(data.managers.manager_ids[i]);
      });
      tempManagers.push(tempData);
    }
    setManagers(tempManagers);
  };

  const callOrgStructureApi = useCallback(
    async (empCodeVar) => {
      const params = {
        employee_code: empCodeVar,
      };
      try {
        setError(false);
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        const apiResponse = await apiRequest(GET_ORG_STRUCTURE, REQUEST_TYPE.GET, params);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            const data = apiResponse.response.data;
            return data;
          }
        }
      } catch (err) {
        handleError(err, params, GET_ORG_STRUCTURE, NAVIGATION_ROUTES.ORG_STRUCTURE);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        setError(true);
      }
    },
    [dispatch]
  );

  const callManagersApi = useCallback(
    async (empCode) => {
      const data = await callOrgStructureApi(empCode);
      setOrgData(data);
      formatManagerData(data);
      if (data.reportees) {
        setReportees(data.reportees);
      }
      if (data.current_employee) {
        setCurEmployee(data.current_employee);
      }
    },
    [callOrgStructureApi]
  );

  const renderScreen = useCallback(async () => {
    callManagersApi(employeeCode);
  }, [callManagersApi, employeeCode]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const callReportiesApi = async (empCode) => {
    const data = await callOrgStructureApi(empCode);
    if (
      data.managers &&
      data.managers.manager_details &&
      data.managers.manager_details.length > 0
    ) {
      formatManagerData(data);
    } else {
      setManagers([]);
    }
    if (data.reportees && data.reportees.length > 0) {
      setReportees(data.reportees);
    } else {
      setReportees([]);
    }
    if (data.current_employee) {
      setCurEmployee(data.current_employee);
    }
  };

  const renderManager = (item, index) => {
    if (item.employee) {
      return (
        <div
          onClick={() => {
            setCurEmployeeCode(item.employee.employee_code);
            callReportiesApi(item.employee.employee_code);
          }}
          className="common-cursor-pointer">
          <div>
            <ManagerView
              employee={item.employee}
              curEmployeeCode={curEmployeeCode}
              mangerStyle={{ marginLeft: index * 60 }}
              hideBorder={index === 0 ? true : false}
            />
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.PROFILE} />
      <BackButton />
      <div className="mt-3 mb-3">
        {orgData &&
          managers &&
          managers.map((item, index) => {
            return <div key={`${index}`}>{item && renderManager(item, index)}</div>;
          })}
        <div>
          {curEmployee && (
            <div
              style={managers.length > 0 ? { marginLeft: managers.length * 60 } : ''}
              className="d-flex flex-row align-items-center common-profile-manager-container">
              <ConnectingBorder />
              <EmployeeOrgView
                employee={curEmployee[0].employee}
                style="common-profile-org-cur-emp-container"
              />
            </div>
          )}
          {curEmployee && reportees && reportees.length > 0 && (
            <div
              style={managers.length > 0 ? { marginLeft: (managers.length + 1) * 60 } : ''}
              className="common-profile-reportee-container">
              <ReportiesView
                employee={reportees}
                callManagerApi={(empCode) => {
                  setCurEmployeeCode(empCode);
                  callReportiesApi(empCode);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrgStructureScreen;
