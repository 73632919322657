import React from 'react';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
} from '../../../common/constants/AppConstants';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import { navigatePage } from '../../../utils/featureNavigation';

const FavoritesSection = (props) => {
  const { sectionItems } = props;

  const onPressFavoriteItemHandler = (feature) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.HOME_NAVIGATE_FROM_FAVORITE,
      feature.id.toString(),
      ANALYTICS_ITEM_NAMES.HOME
    );
    navigatePage(feature);
  };

  const renderFeature = (feature, index) => {
    return (
      <div
        className={`px-3 ${
          index === sectionItems.length - 1 ? 'home-favorites-item-last' : 'home-favorites-item'
        }`}
        key={feature.id}
        onClick={() => onPressFavoriteItemHandler(feature)}>
        <HtmlView
          htmlStyle={
            feature.isHighlighted
              ? 'header-feature-item-highlighted'
              : 'header-feature-unhighlighted'
          }
          html={`<div>${feature.title}</div>`}
        />
      </div>
    );
  };

  if (sectionItems.length) {
    return (
      <div className="home-favorites-section d-flex align-items-center justify-content-end py-3">
        {sectionItems.map((feature, index) => renderFeature(feature, index))}
      </div>
    );
  } else {
    return null;
  }
};

export default FavoritesSection;
