import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const CustomLightbox = (props) => {
  const defaultStyles = { reactModalStyle: { overlay: { zIndex: 1030 } } };

  return <Lightbox {...{ ...props, ...defaultStyles }} />;
};

export default CustomLightbox;
