import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../../../services/Service';
import { GET_POINTS_SUMMARY, GET_CHEERS_POINTS } from '../../../../services/ApiUrls';
import PointsSummaryListItem from '../../components/PointsSummaryListItem';
import { useHistory } from 'react-router';
import LoadMoreBtn from '../../../../common/ui/load_more_btn/LoadMoreBtn';
import DefaultContentView from '../../../../common/ui/default_content_view/DefaultContentView';
import { useDispatch, useSelector } from 'react-redux';
import { saveShowEmptyContentView, selectShowEmptyContentView } from '../../../../state/UIState';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../../common/ui/activity_loader/ActivityLoader';

const PointsSummaryTab = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [cheersList, setCheersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [availablePoints, setAvailablePoints] = useState({});

  const showEmptyContentView = useSelector(selectShowEmptyContentView);

  const pageId = useRef(1);
  const loadMoreFalg = useRef(true);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.POINTS_SUMMARY_TAB_VISIT, '', ANALYTICS_ITEM_NAMES.CHEERS);
  }, []);

  const getPointsSummary = useCallback(async () => {
    if (loadMoreFalg.current) {
      setLoading(true);
      dispatch(saveShowEmptyContentView(true));
      const params = {
        page_id: pageId.current,
      };
      try {
        const apiResponse = await apiRequest(GET_POINTS_SUMMARY, REQUEST_TYPE.GET, params);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.data.feeds.length > 0) {
              setCheersList((prev) => [...prev, ...apiResponse.response.data.feeds]);
              pageId.current = pageId.current + 1;
              loadMoreFalg.current = true;
            } else {
              loadMoreFalg.current = false;
            }
          }
        }
        setLoading(false);
      } catch (err) {
        handleError(err, params, GET_POINTS_SUMMARY, NAVIGATION_ROUTES.CHEERS_SCREEN);
        setLoading(false);
      }
    } else {
      loadMoreFalg.current = false;
    }
  }, [dispatch]);

  const callAvailablePointsApi = useCallback(async () => {
    const params = {
      type: '',
    };
    try {
      const apiResponse = await apiRequest(GET_CHEERS_POINTS, REQUEST_TYPE.GET, params);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (Object.keys(apiResponse.response.data.feeds).length > 0) {
            setAvailablePoints(apiResponse.response.data.feeds);
          }
        }
      }
    } catch (err) {
      handleError(err, params, GET_CHEERS_POINTS, NAVIGATION_ROUTES.CHEERS_SCREEN);
      //
    }
  }, []);

  useEffect(() => {
    callAvailablePointsApi();
    getPointsSummary();
  }, [callAvailablePointsApi, getPointsSummary]);

  const onPressItem = (item) => {
    if (item.title === 'Redeemed') {
      if (item.details.redeem_amazon) {
        if (item.details.order && Object.keys(item.details.order).length > 0) {
          history.push(NAVIGATION_ROUTES.REWARD_DETAILS_SCREEN, {
            cardNumber: item.details.order.cardnumber,
            orderId: item.details.order.order_id,
            cardPrice: item.details.order.card_price,
            expiry: item.details.order.expiry_date,
            pin: item.details.order.pin_or_url,
            redeemAmazon: item.details.redeem_amazon,
            message: item.details.message,
          });
        } else {
          history.push(NAVIGATION_ROUTES.REWARD_DETAILS_SCREEN, {});
        }
      } else {
        history.push(NAVIGATION_ROUTES.REWARD_DETAILS_SCREEN, {});
      }
    }
  };

  const onPressLoadMore = () => {
    getPointsSummary();
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.POINTS_NO_DATA} />;
  };

  return (
    <div className="main-content-container">
      <div className="cheers-points-summary-upper-view pt-2 pb-2">
        <div className="mr-3 col-sm-4">
          <div className="text-center cheers-available-pts">
            {Object.keys(availablePoints).length > 0 && availablePoints.redeemed}
          </div>
          <div className="text-center cheers-points-text-style">Points</div>
          <div className="text-center cheers-redeem-last-month">Redeemed Last Month</div>
        </div>
        <div className="ml-3 mr-3 col-sm-4">
          <div className="text-center cheers-available-pts">
            {Object.keys(availablePoints).length > 0 && availablePoints.debited}
          </div>
          <div className="text-center cheers-points-text-style">Points</div>
          <div className="text-center cheers-redeem-last-month">Debited Last Month</div>
        </div>
        <div className="ml-3 col-sm-4">
          <div className="text-center cheers-available-pts">
            {Object.keys(availablePoints).length > 0 && availablePoints.credited}
          </div>
          <div className="text-center cheers-points-text-style">Points</div>
          <div className="text-center cheers-redeem-last-month">Credited Last Month</div>
        </div>
      </div>
      <div className="mt-3 cheers-transactions">{`${cheersList.length} Transactions`}</div>
      {showEmptyContentView && !cheersList.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          <div>
            {cheersList.map((item, index) => {
              return (
                <PointsSummaryListItem item={item} onPressItem={onPressItem} key={`${index}`} />
              );
            })}
          </div>
          <ActivityLoader visible={loading} />
          {loadMoreFalg.current && <LoadMoreBtn onClick={onPressLoadMore} isLoading={loading} />}
        </div>
      )}
    </div>
  );
};

export default React.memo(PointsSummaryTab);
