import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import { savePollsSurveysTabData, selectPollsSurveysTabData } from '../../state/TabData';
import { Tab } from 'react-bootstrap';
import PollsTab from './tabs/PollsTab';
import SurveysTab from './tabs/SurveysTab';
import { selectPollSurveysTabs } from '../../state/MasterData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import NavTabs from '../../common/ui/tabs/NavTabs';

const PollsAndSurveysMainScreen = ({ location }) => {
  const dispatch = useDispatch();
  const [tabKey] = useState(
    location && location.state && location.state.tabKey && location.state.tabKey
  );
  const pollsSurveysTabData = useSelector(selectPollsSurveysTabData);
  const pollsSurveysTitles = useSelector(selectPollSurveysTabs);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.POLLS_AND_SURVEYS, NAVIGATION_ROUTES.POLLS_AND_SURVEYS);
  }, []);

  useEffect(() => {
    if (tabKey) {
      dispatch(savePollsSurveysTabData(tabKey));
    }
  }, [dispatch, tabKey]);

  useEffect(() => {
    if (pollsSurveysTitles.length && pollsSurveysTabData === '' && tabKey) {
      dispatch(savePollsSurveysTabData(tabKey));
    } else if (pollsSurveysTitles.length && pollsSurveysTabData === '') {
      dispatch(savePollsSurveysTabData(pollsSurveysTitles[0].key));
    }
  }, [pollsSurveysTabData, pollsSurveysTitles, dispatch, tabKey]);

  const renderTabs = (tab) => {
    switch (tab.key) {
      case 'polls':
        return <PollsTab tabKey={tab.key} />;
      case 'surveys':
        return <SurveysTab tabKey={tab.key} />;
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.POLLS} />
      <NavTabs
        activeKey={pollsSurveysTabData}
        onSelect={(k) => dispatch(savePollsSurveysTabData(k))}
        unmountOnExit>
        {pollsSurveysTitles.map((tab, index) => {
          return (
            <Tab key={`${index}`} eventKey={tab.key} title={tab.title}>
              {renderTabs(tab, index)}
            </Tab>
          );
        })}
      </NavTabs>
    </div>
  );
};

export default PollsAndSurveysMainScreen;
