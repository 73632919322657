import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  NAVIGATION_ROUTES,
  ERROR_MESSAGES,
  REQUEST_TYPE,
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ANALYTICS_SCREEN_NAMES,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';

import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import GalleryImageView from './components/GalleryImageView';
import { apiRequest, handleError } from '../../services/Service';
import { GALLERIES_SEARCH } from '../../services/ApiUrls';
import {
  saveProgressLoadingState,
  saveSearchText,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectSearchText,
  selectShowEmptyContentView,
} from '../../state/UIState';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import debounce from 'lodash.debounce';
import ActivityLoader from '../../common/ui/activity_loader/ActivityLoader';

const GallerySearchScreen = ({ history }) => {
  const [galleryData, setGalleryData] = useState([]);
  const dispatch = useDispatch();
  const pageId = useRef(1);
  const loadMoreFlag = useRef(false);
  const searchData = useSelector(selectSearchText);
  const [searchText, setSearchText] = useState(searchData ?? '');

  const accessibilityData = useSelector(selectAccessibilityData);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [isSearchEnabled, setIsSearchEnabled] = useState(true);
  const [quickSearchLoading, setQuickSearchLoading] = useState(false);

  const onClickSearchHandler = () => {
    setIsSearchEnabled(true);
  };

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.GALLERY_SEARCH, NAVIGATION_ROUTES.GALLERY_SEARCH);
  }, []);

  const callGalleryApi = useCallback(
    async (query = '', isQuickSearch = false) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMoreFlag.current === true) {
        if (isQuickSearch) {
          setQuickSearchLoading(true);
        } else {
          dispatch(
            saveProgressLoadingState({
              isProgressLoading: true,
            })
          );
        }
        const getPrams = {
          page_id: pageId.current,
          query: query,
        };
        try {
          const apiResponse = await apiRequest(GALLERIES_SEARCH, REQUEST_TYPE.GET, getPrams);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (pageId.current === 1) {
                setGalleryData([]);
              }
              if (apiResponse.response.data) {
                const data = apiResponse.response.data;
                if (data.length > 0) {
                  setGalleryData((oldArray) => [...oldArray, ...data]);
                  pageId.current = pageId.current + 1;
                  loadMoreFlag.current = true;
                } else {
                  loadMoreFlag.current = false;
                }
              }
            }
          }
          setQuickSearchLoading(false);
          dispatch(
            saveProgressLoadingState({
              isProgressLoading: false,
            })
          );
        } catch (err) {
          handleError(err, getPrams, GALLERIES_SEARCH, NAVIGATION_ROUTES.GALLERY_SEARCH);
          setQuickSearchLoading(false);
          dispatch(
            saveProgressLoadingState({
              isProgressLoading: false,
            })
          );
        }
      }
    },
    [dispatch, loadMoreFlag, pageId]
  );

  const debouncedCallback = (event) => {
    if (event && event.target.value.length > 2) {
      dispatch(saveSearchText(event.target.value));
      pageId.current = 1;
      loadMoreFlag.current = true;
      callGalleryApi(event.target.value, true);
    }
    setSearchText(event.target.value);
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );
  const onInputChange = (event) => {
    setSearchText(event.target.value);
    debounceInputHandler(event);
  };

  const callSubmitFunction = (event) => {
    event?.preventDefault();
    if (searchText !== '') {
      dispatch(saveSearchText(searchText));
      setGalleryData([]);
      pageId.current = 1;
      loadMoreFlag.current = true;
      callGalleryApi(searchText);
    }
  };

  const onPressLoadMore = () => {
    callGalleryApi(searchText);
  };

  const onTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const resetValue = () => {
    history.goBack();
  };

  const onPressMore = (pictures, baseUrl) => {
    history.push(NAVIGATION_ROUTES.GALLERY_COMMON, {
      baseUrl: baseUrl,
      pictures: pictures,
    });
  };

  const emptyListView = () => {
    return (
      <DefaultContentView
        message={ERROR_MESSAGES.SEARCH_NO_RESULTS}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.GALLERY_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.GALLERY
        )}
      />
    );
  };

  useEffect(() => {
    if (searchText) {
      callSubmitFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
        <div
          className={
            isSearchEnabled ? 'search-bar-with-icon search-bar-border' : 'search-bar-with-icon'
          }>
          {isSearchEnabled ? (
            <form className="directory-search-bar" onSubmit={callSubmitFunction}>
              <input
                autoFocus
                className="ml-2 directory-search-bar"
                type="text"
                placeholder="Search.."
                onChange={onInputChange}
                value={searchText}
              />
            </form>
          ) : (
            <div className="directory-search-bar" />
          )}
          {isSearchEnabled ? (
            <div className="gcdo-icon-row mr-2" onClick={resetValue}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
                )}
                className="header-icon"
              />
            </div>
          ) : (
            <div className="gcdo-icon-row mr-2" onClick={onClickSearchHandler}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH
                )}
                className="header-icon"
              />
            </div>
          )}
        </div>
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.GALLERY} />
      <BackButton />

      <div className="main-content-container">
        <ActivityLoader visible={quickSearchLoading} />
        {showEmptyContentView && !galleryData.length && !progressLoadingState.isProgressLoading ? (
          emptyListView()
        ) : (
          <div>
            <div className="row">
              {galleryData.map((item, index) => {
                return (
                  <GalleryImageView
                    key={`${index}`}
                    item={item}
                    onPressMore={onPressMore}
                    location={item.event_location}
                  />
                );
              })}
            </div>
            {loadMoreFlag.current && <LoadMoreBtn onClick={onPressLoadMore} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default GallerySearchScreen;
