import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectArticleFilters, selectMiHRTitles } from '../../state/MasterData';
import { saveSearchText, selectBaseUrl } from '../../state/UIState';
import { Tab, Modal, Form } from 'react-bootstrap';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ACTION_TYPES,
  ANALYTICS_SCREEN_NAMES,
  MI_HR_CONTENT_IDENTIFIERS,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import {
  saveMiHRTabCategoryFilterId,
  saveMiHrTabData,
  selectMiHRTabData,
} from '../../state/TabData';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import NavTabs from '../../common/ui/tabs/NavTabs';
import MiHRTab from './tabs/MiHRTab';
import HtmlView from '../../common/ui/html_view/HTMLView';
import SearchComponent from '../../common/ui/search/SearchComponent';

const MiHRMainScreen = ({ location, history }) => {
  const miHrTabsSelectorData = useSelector(selectMiHRTitles);
  const [miHRTabs, setMiHRTabs] = useState([]);
  const miHRTabdata = useSelector(selectMiHRTabData);
  const dispatch = useDispatch();
  const baseUrl = useSelector(selectBaseUrl);
  const [id] = useState(new URLSearchParams(location.search).get('id'));
  const accessibilityData = useSelector(selectAccessibilityData);
  const [showNewsDialog, setShowNewsDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState({});
  const dateResponse = useSelector(selectArticleFilters);
  const [showHeaderItems, setShowHeaderItems] = useState(true);
  const [hasFootnote, setHasFootnote] = useState(false);
  const [currentTab, setCurrentTab] = useState({ category_id: 0 });
  const dateArray = [];
  for (var date in dateResponse) {
    dateArray.push(dateResponse[date].title);
  }

  const tempDates = {};
  const tempClearFilters = {};

  for (var val in miHRTabs) {
    tempDates[miHRTabs[val].category_id] = '';
    tempClearFilters[miHRTabs[val].category_id] = false;
  }

  const [selectedDatesArray, setSelectedDatesArray] = useState({});
  const [clearFiltersArray, setClearFilterArray] = useState({});

  useEffect(() => {
    if (miHrTabsSelectorData?.length) {
      let activeTabs = miHrTabsSelectorData.filter((tab) => tab?.is_active);
      setMiHRTabs(activeTabs);
    } else {
      setMiHRTabs([]);
    }
  }, [miHrTabsSelectorData]);

  useEffect(() => {
    if (baseUrl !== '' && id && id !== '') {
      dispatch({ type: ACTION_TYPES.GET_TOKEN, payload: { id: id } });
    }
  }, [baseUrl, dispatch, id]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.MI_HR, NAVIGATION_ROUTES.MI_HR_SUPPORT);
  }, []);

  useEffect(() => {
    if (miHRTabs?.length) {
      if (miHRTabs?.length && miHRTabdata === '') {
        dispatch(saveMiHrTabData(0));
      } else {
        let tab = miHRTabs[miHRTabdata];
        if (
          tab?.type === MI_HR_CONTENT_IDENTIFIERS.ACTIVITY ||
          tab?.type === MI_HR_CONTENT_IDENTIFIERS.CONTEST
        ) {
          setShowHeaderItems(false);
        } else {
          setShowHeaderItems(true);
        }
        if (tab?.footnote === true) {
          setHasFootnote(true);
          setCurrentTab(tab);
        } else {
          setHasFootnote(false);
          setCurrentTab({});
        }
      }
    }
  }, [dispatch, miHRTabs, miHRTabdata]);

  const clearFilters = () => {
    setSelectedDate((prev) => ({
      ...prev,
      [miHRTabdata]: '',
    }));
    setSelectedDatesArray((prev) => ({ ...prev, [miHRTabdata]: '' }));
    setClearFilterArray((prev) => ({ ...prev, [miHRTabdata]: true }));
  };

  const callDateFilterFunction = () => {
    const value = selectedDate[miHRTabdata] ? selectedDate[miHRTabdata] : dateResponse[0].title;
    const tempdate = dateResponse.find((res) => res.title === value).value;
    setSelectedDatesArray((prev) => ({ ...prev, [miHRTabdata]: tempdate }));
    setClearFilterArray((prev) => ({ ...prev, [miHRTabdata]: false }));
  };

  const renderTabs = useCallback(
    (tab, index) => {
      switch (tab.category_id) {
        case tab.category_id:
          return (
            <>
              <MiHRTab
                item={tab}
                key={`${index}`}
                selectedDate={selectedDatesArray}
                clearFilters={clearFiltersArray}
              />
            </>
          );
      }
    },
    [clearFiltersArray, selectedDatesArray]
  );
  const CustomYearView = () => {
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Label>Select Year</Form.Label>
            <div className="broadcast-year-containe">
              <Form.Control
                as="select"
                custom
                value={selectedDate[miHRTabdata]}
                onChange={(event) => {
                  setSelectedDate((prev) => ({
                    ...prev,
                    [miHRTabdata]: event.target.value,
                  }));
                }}>
                {dateArray &&
                  dateArray.map((item) => {
                    return (
                      <option key={item} defaultValue={item === selectedDate[miHRTabdata]}>
                        {item}
                      </option>
                    );
                  })}
              </Form.Control>
            </div>
          </Form.Group>
        </Form>
        <div className="broadcast-row">
          <div>
            <CustomButton
              buttonStyle="broadcast-calender-button-style"
              onClick={() => {
                setShowNewsDialog(false);
                clearFilters();
              }}>
              CLEAR FILTERS
            </CustomButton>
          </div>
          <div className="broadcast-row">
            <CustomButton
              buttonStyle="broadcast-calender-button-style"
              onClick={() => {
                setShowNewsDialog(false);
              }}>
              CANCEL
            </CustomButton>
            <CustomButton
              buttonStyle="broadcast-calender-button-style"
              onClick={() => {
                setShowNewsDialog(false);
                callDateFilterFunction();
              }}>
              DONE
            </CustomButton>
          </div>
        </div>
      </div>
    );
  };

  const renderNavTabs = useCallback(() => {
    return (
      <NavTabs
        activeKey={miHRTabdata}
        onSelect={(k) => {
          if (k === miHRTabdata) {
            return;
          }
          dispatch(saveMiHRTabCategoryFilterId(0));
          dispatch(saveMiHrTabData(k));
        }}>
        {miHRTabs?.length &&
          miHRTabs.map((tab, index) => {
            return (
              <Tab
                key={`${index}`}
                eventKey={index}
                title={tab.title}
                className="main-content-container">
                {renderTabs(tab, index)}
              </Tab>
            );
          })}
      </NavTabs>
    );
  }, [dispatch, miHRTabs, miHRTabdata, renderTabs]);

  const handleSubmitFunction = (searchText) => {
    dispatch(saveSearchText(searchText));
    history.push(NAVIGATION_ROUTES.MI_HR_SEARCH, {
      searchQuery: searchText,
    });
  };

  return (
    <div className="container">
      {showHeaderItems && miHRTabs?.length && (
        <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
          <SearchComponent handleSubmitFunction={handleSubmitFunction} />
          <div className="gcdo-icon-row mr-3">
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_HEADER_COMPONENTS.CALENDER
              )}
              className="header-icon"
              onClick={() => {
                setShowNewsDialog(true);
              }}
            />
          </div>
        </div>
      )}
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MI_HR_SUPPORT} />
      <Modal
        show={showNewsDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowNewsDialog(false)}>
        <Modal.Body>{CustomYearView()}</Modal.Body>
      </Modal>

      {baseUrl !== '' && miHRTabs?.length && renderNavTabs()}

      {hasFootnote && (
        <div className="ijpr-detail-request-help-container d-flex align-items-center p-3 mb-4 ">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_QUERY,
              ACCESSIBILITY_IDENTIFIERS.COMMON
            )}
            className="ijpr-detail-request-help-icon mr-1"
          />
          <div className="ijpr-detail-request-help-title">
            <HtmlView
              html={currentTab?.footnote_config_web}
              htmlStyle="common-comment-item-message-text"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MiHRMainScreen;
