import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  MICROGIVE_EVENT_TYPE,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../../common/constants/AppConstants';
import { GET_CURRENCY_CONVERSION_RATES, GET_INITIATIVE_DETAIL } from '../../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../../services/Service';
import Select from 'react-select';
import { getUrlWithSpecificRendition } from '../../../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import HtmlView from '../../../../common/ui/html_view/HTMLView';
import { Modal } from 'react-bootstrap';
import CustomButton from '../../../../common/ui/custom_button/CustomButton';
import { saveProgressLoadingState, saveSearchText } from '../../../../state/UIState';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import FundraiserDetails from '../../components/FundraiserDetails';
import { selectConfigurations } from '../../../../state/MasterData';
import { selectAccessData } from '../../../../state/UserAccessData';

const DonationDetailsTab = ({ initiativeId, onSetInitiativeDetailId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [event, setEvent] = useState({});
  const [amount, setAmount] = useState();
  const [result, setResult] = useState(0);
  const [currencies, setCurrencies] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [selectedOption2, setSelectedOption2] = useState();
  const [showDonateDialog, setShowDonateDialog] = useState(false);
  const [isProceedButtonDisabled, setIsProceedButtonDisabled] = useState(true);
  const configurations = useSelector(selectConfigurations);
  const accessData = useSelector(selectAccessData);
  const [showDonateButton, setShowDonateButton] = useState(true);
  const microgiveAccessData = accessData?.data.find((item) => item.feature_key === '_microGive');
  const max_amount = configurations?._microgive_config?._max_donation;

  useEffect(() => {
    const actions = microgiveAccessData?.actions;
    setShowDonateButton(actions?.includes('_donate'));
  }, [microgiveAccessData?.actions]);

  const handleHtmlClicks = (e) => {
    const target = e.target.getAttribute('href');
    if (target) {
      if (target.startsWith('/') || target.startsWith('?')) {
        if (target.includes(NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL)) {
          logEvent(
            ANALYTICS_EVENT_TYPES.USER_MENTION_CLICK,
            e?.target?.innerText,
            ANALYTICS_ITEM_NAMES.USER_MENTION
          );
          e.stopPropagation();
          e.preventDefault();
          history.push(target);
        } else {
          logEvent(
            ANALYTICS_EVENT_TYPES.HASHTAG_CLICK,
            e?.target?.innerText,
            ANALYTICS_ITEM_NAMES.HASHTAG
          );
          dispatch(saveSearchText(''));
          e.preventDefault();
          history.push(`${NAVIGATION_ROUTES.DONATION_SEARCH_SCREEN}${target}`, {
            type: MICROGIVE_EVENT_TYPE.DONATION,
          });
        }
      } else {
        e.stopPropagation();
        e.preventDefault();
        window.open(target, '_blank');
      }
    }
  };

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.DONATION_DETAIL_TAB_VISIT,
      initiativeId,
      ANALYTICS_ITEM_NAMES.MICROGIVE
    );
  }, [initiativeId]);

  useEffect(() => {
    let result = (selectedOption?.rate * amount) / selectedOption2?.rate;
    if (isNaN(result)) {
      setResult(0);
    } else {
      setResult(result.toFixed(2));
    }
  }, [selectedOption, selectedOption2, amount]);

  const callDonationDetailApi = useCallback(async () => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(
        GET_INITIATIVE_DETAIL + initiativeId,
        REQUEST_TYPE.GET,
        {}
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setEvent(apiResponse.response.data.event);
          onSetInitiativeDetailId(
            apiResponse.response.data.event.details[0].microgive_initiative_detail_id
          );
        }
      }
    } catch (err) {
      handleError(
        err,
        {},
        GET_INITIATIVE_DETAIL + initiativeId,
        NAVIGATION_ROUTES.DONATION_EVENT_BY_DATE_SCREEN
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch, initiativeId, onSetInitiativeDetailId]);

  useEffect(() => {
    callDonationDetailApi();
  }, [callDonationDetailApi]);

  const callCurrencyRateListApi = async () => {
    try {
      const apiResponse = await apiRequest(GET_CURRENCY_CONVERSION_RATES, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse?.response?.exchange_rates?.length > 0) {
            let pickerData = [];
            apiResponse.response.exchange_rates.forEach((element, index) => {
              if (apiResponse.response.exchange_rates[index].abbreviation === 'INR') {
                setSelectedOption({
                  label: element.abbreviation,
                  value: element.currency_id,
                  rate: element.rate,
                });
                pickerData.unshift({
                  label: element.abbreviation,
                  value: element.currency_id,
                  rate: element.rate,
                });
              } else if (apiResponse.response.exchange_rates[index].abbreviation === 'USD') {
                setSelectedOption2({
                  label: element.abbreviation,
                  value: element.currency_id,
                  rate: element.rate,
                });
                pickerData.unshift({
                  label: element.abbreviation,
                  value: element.currency_id,
                  rate: element.rate,
                });
              } else {
                pickerData.push({
                  label: element.abbreviation,
                  value: element.currency_id,
                  rate: element.rate,
                });
              }
            });
            setCurrencies(pickerData);
          }
        }
      }
    } catch (err) {
      handleError(
        err,
        {},
        GET_CURRENCY_CONVERSION_RATES,
        NAVIGATION_ROUTES.DONATION_EVENT_BY_DATE_SCREEN
      );
      //
    }
  };

  const onPressDonate = () => {
    setShowDonateDialog(true);
    setAmount();
    callCurrencyRateListApi();
  };

  const onPressProceed = () => {
    if (amount === '' || isNaN(amount) || amount == 0 || amount < 0) {
      alert('Please enter a valid amount');
      return;
    }
    history.push(NAVIGATION_ROUTES.SELECT_DONATION_SCREEN, {
      amount: amount,
      currencyId: selectedOption.value,
      abbrivation: selectedOption.label,
      initiativeId: initiativeId,
      item: event,
    });
  };

  const onPress = (value) => {
    const totalAmount = amount ? Math.floor(amount) + value : value;
    if (totalAmount > max_amount) {
      alert('You have reached the maximum donation amount.');
      return;
    }
    setAmount(totalAmount);
    setIsProceedButtonDisabled(false);
  };

  const handleChange = (event) => {
    if (event.target.value > max_amount) {
      alert(`You can't donate more than ${selectedOption.label} ${max_amount}`);
      return;
    }
    if (
      (event?.target?.value?.includes('.') && event?.target?.value?.split('.')?.[1].length > 2) ||
      event?.target?.value?.length > 10
    ) {
      return;
    }
    setAmount(event.target.value);
    if (
      event.target.value === '' ||
      isNaN(event.target.value) ||
      event.target.value == 0 ||
      event.target.value < 0
    ) {
      setIsProceedButtonDisabled(true);
    } else {
      if (event.target.value > max_amount) {
        alert(`You can't donate more than ${selectedOption.label} ${max_amount}`);
        return;
      }
      setAmount(event.target.value);
      setIsProceedButtonDisabled(false);
    }
  };

  const onValueChange = (selectOption) => {
    setSelectedOption(selectOption);
  };
  const onValueChange2 = (selectOption) => {
    setSelectedOption2(selectOption);
  };

  const donateDialog = () => {
    return (
      <div>
        <div className="row ml-3 mr-3 mt-3 justify-content-between">
          <div className="microgive-select-donation-amount-text">Select donation amount</div>
        </div>
        <div className="text-center mt-3 microgive-how-much-text">
          How much would you like to donate?
        </div>
        <div className="row ml-3 mr-3 mt-3">
          <Select
            value={selectedOption}
            onChange={onValueChange}
            options={currencies}
            className="microgive-dropdown"
            defaultValue={selectedOption}
            isSearchable={false}
          />
          <input
            type="number"
            className="ml-3 w-50"
            min={0}
            max={max_amount}
            maxLength={10}
            onChange={handleChange}
            value={amount !== undefined ? `${amount}` : ''}
          />
        </div>
        <div className="microgive-price-buttons mt-3">
          <button
            type="button"
            disabled={amount == max_amount || amount > max_amount - 500}
            className="btn btn-outline-primary"
            onClick={() => onPress(500)}>
            + 500
          </button>
          <button
            type="button"
            className="btn btn-outline-primary ml-3"
            disabled={amount == max_amount || amount > max_amount - 1000}
            onClick={() => onPress(1000)}>
            + 1000
          </button>
          <button
            type="button"
            disabled={amount == max_amount || amount > max_amount - 1500}
            className="btn btn-outline-primary ml-3"
            onClick={() => onPress(1500)}>
            + 1500
          </button>
        </div>
        <div className="ml-3">Equivalent to</div>
        <div className="row ml-3 mr-3 mt-3">
          <Select
            value={selectedOption2}
            onChange={onValueChange2}
            options={currencies}
            className="microgive-dropdown"
            defaultValue={selectedOption2}
            isSearchable={false}
          />
          <input disabled={true} type="text" className="ml-3 w-50" value={result ? result : 0} />
        </div>
        <br />
        <div className="row ml-3 mr-3 mt-3 d-flex justify-content-around">
          <CustomButton
            buttonStyle="microgive-button-style"
            onClick={() => setShowDonateDialog(false)}
            disabled={false}>
            CANCEL
          </CustomButton>
          <CustomButton
            buttonStyle="microgive-button-style"
            onClick={onPressProceed}
            disabled={isProceedButtonDisabled}>
            PROCEED
          </CustomButton>
        </div>
        <br />
      </div>
    );
  };

  return (
    <div className="main-content-container">
      <Modal
        show={showDonateDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowDonateDialog(false)}>
        <Modal.Body>{donateDialog()}</Modal.Body>
      </Modal>
      {showDonateButton && (
        <div className="d-flex flex-row-reverse mi-hr-activity-btn-container">
          <CustomButton buttonStyle="microgive-button-style" onClick={onPressDonate}>
            Donate
          </CustomButton>
        </div>
      )}
      <div>
        {!!Object.keys(event).length && (
          <div>
            <img
              src={getUrlWithSpecificRendition(
                event.promo_image.base_url,
                SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
                event.promo_image.image_path
              )}
              className="embed-responsive embed-responsive-16by9"
            />
            <div className="microgive-title mt-2">{event.title}</div>
            <FundraiserDetails event={event} />
            <HtmlView html={event.description} onClick={handleHtmlClicks} htmlStyle="mt-2 mb-2" />
          </div>
        )}
      </div>
    </div>
  );
};

export default DonationDetailsTab;
