import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { selectProgressLoadingState } from '../../../state/UIState';
import { isEmptyText, showToast } from '../../../utils/common';
import { ERROR_MESSAGES } from '../../../common/constants/AppConstants';
import CustomButton from '../../../common/ui/custom_button/CustomButton';

const TravelRequestTakeActionDialog = (props) => {
  const {
    isActionDialogVisible,
    setIsActionDialogVisible,
    title = 'Why are you taking this action?',
    placeholder = 'Enter your reason(s)',
    onPressSubmitHandler = () => {},
  } = props;
  const [comment, setComment] = useState('');
  const progressLoadingState = useSelector(selectProgressLoadingState);

  return (
    <Modal
      onHide={() => setIsActionDialogVisible(false)}
      show={isActionDialogVisible}
      centered
      keyboard={false}>
      <div>
        <Modal.Body className="p-4">
          <h5 className="ijp-apply-modal-title text-center">{title}</h5>
          <Form.Group className="mt-3 mr-3 ml-3">
            <Form.Control
              as="textarea"
              rows={5}
              placeholder={placeholder}
              onChange={(event) => setComment(event.target.value)}
              value={comment}
            />
          </Form.Group>

          <div className="d-flex justify-content-center mt-3">
            <div
              className="d-flex justify-content-center align-items-center mr-4"
              onClick={() => setIsActionDialogVisible(false)}>
              <div className="common-cursor-pointer communities-cancel-community-button">
                CANCEL
              </div>
            </div>
            <CustomButton
              buttonStyle="common-custom-button-quaternary ml-4"
              showLoader={progressLoadingState.isProgressLoading}
              onClick={() => {
                if (!isEmptyText(comment)) {
                  showToast(ERROR_MESSAGES.EMPTY_REASON);
                } else {
                  onPressSubmitHandler(comment.trim());
                }
              }}>
              SUBMIT
            </CustomButton>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default TravelRequestTakeActionDialog;
