import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  CHAT_LEFT_PANEL_IDENTIFIERS,
  DIALOG_TYPE,
  LOADING_MESSAGES,
  MESSAGES,
  NAVIGATION_ROUTES,
  SUPPORTED_IMAGE_FORMATS,
  WARNING_MESSAGES,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { selectUserChatGroups } from '../../../state/ChatData';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { saveProgressLoadingState } from '../../../state/UIState';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { createGroup, uploadGroupImage } from '../../../utils/ChatHandler';
import { convertBase64, isEmptyText, showToast } from '../../../utils/common';
import BackButton from '../../../common/ui/common_back_button/BackButton';
import Divider from '../../../common/ui/divider/Divider';
import NewChatListItem from './NewChatListItem';

const NewGroupChatSecondStepView = (props) => {
  const {
    groupMemberList,
    setGroupMemberList,
    setSelectedChat,
    setIsNewGroupMode,
    setLeftPanelType,
  } = props;
  const dispatch = useDispatch();
  const [groupId, setGroupId] = useState('');
  const [groupName, setGroupName] = useState('');
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedImageBlob, setSelectedImageBlob] = useState('');
  const accessibilityData = useSelector(selectAccessibilityData);
  const employeeData = useSelector(selectEmployeeData);
  const userChatGroups = useSelector(selectUserChatGroups);
  const addImageFileRef = useRef();

  useEffect(() => {
    if (groupId !== '') {
      if (Object.keys(userChatGroups).length > 0) {
        let groupDetail = userChatGroups[groupId];
        if (groupDetail && Object.keys(groupDetail).length > 0) {
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          showToast(MESSAGES.GROUP_CREATED);
          setSelectedChat({
            chatType: DIALOG_TYPE.GROUP_CHAT,
            groupId: groupId,
            userId: '',
            otherUser: {},
            fromRoute: NAVIGATION_ROUTES.CREATE_GROUP,
          });
          setGroupId('');
          setGroupMemberList([]);
          setLeftPanelType(CHAT_LEFT_PANEL_IDENTIFIERS.DEFAULT_VIEW);
        }
      }
    }
  }, [dispatch, groupId, setGroupMemberList, setLeftPanelType, setSelectedChat, userChatGroups]);

  const renderSelectedMember = (item, index) => {
    return <NewChatListItem key={index.toString()} item={item} onPress={() => {}} />;
  };

  const proceedCreateGroup = (imageUrl) => {
    createGroup(groupName, imageUrl, employeeData, groupMemberList, (gpId) => {
      setGroupId(gpId);
    });
  };

  const onPressCreateGroupHandler = () => {
    if (!isEmptyText(groupName)) {
      showToast(MESSAGES.ENTER_GROUP_NAME);
    } else if (groupName.trim().length < 3) {
      showToast(MESSAGES.GROUP_NAME);
    } else if (selectedImage !== '') {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.CREATING_GROUP,
        })
      );
      uploadGroupImage(selectedImageBlob, (downloadUrl, error) => {
        if (downloadUrl) {
          proceedCreateGroup(downloadUrl);
        } else {
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      });
    } else {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.CREATING_GROUP,
        })
      );
      proceedCreateGroup('');
    }
  };

  const onPressAddGroupImageHandler = (e) => {
    e.stopPropagation();
    addImageFileRef.current.click();
  };

  const onFileChange = (event) => {
    [...event.target.files].forEach((element) => {
      convertBase64(element).then((data) => {
        if (element.size > 5 * 1024 * 1024) {
          showToast(WARNING_MESSAGES.FILE_SIZE_EXCEEDED);
        } else {
          setSelectedImage(data);
          setSelectedImageBlob(element);
        }
      });
    });
  };

  return (
    <div>
      <div className="d-flex px-3 py-2 justify-content-between align-items-center chat-header">
        <div className="d-flex align-items-center">
          <BackButton
            isFullRow={false}
            backTitle=""
            containerStyle="py-0"
            navigateBackFuncHandler={() => {
              setLeftPanelType(CHAT_LEFT_PANEL_IDENTIFIERS.NEW_CHAT_VIEW);
              setIsNewGroupMode(true);
            }}
          />
          <div className="chat-bold-text">Group Info</div>
        </div>
        <div
          onClick={onPressCreateGroupHandler}
          className={`ml-2 ${
            !isEmptyText(groupName)
              ? 'chat-theme-highlighted-text-disabled common-cursor-not-allowed'
              : 'chat-theme-highlighted-text common-cursor-pointer'
          }`}>
          Create
        </div>
      </div>
      <div className="chat-left-panel-container">
        <div className="d-flex p-4">
          <div onClick={onPressAddGroupImageHandler}>
            <img
              src={
                selectedImage !== ''
                  ? selectedImage
                  : getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_CIRCULAR_CAMERA_GREY,
                      ACCESSIBILITY_IDENTIFIERS.COMMON
                    )
              }
              className="chat-group-upload-image-icon common-cursor-pointer"
            />
            <input
              type="file"
              ref={addImageFileRef}
              onChange={onFileChange}
              accept={SUPPORTED_IMAGE_FORMATS}
              id="input"
              className="d-none"
              onClick={(event) => {
                event.target.value = null;
              }}
            />
          </div>
          <input
            autoFocus
            className="chat-group-group-create-textinput px-2 ml-3 flex-grow-1"
            type="text"
            placeholder="Enter a group name"
            onChange={(event) => setGroupName(event.target.value)}
          />
        </div>
        <Divider style="chat-divider-thin" />
        <div className="px-3 pt-3 pb-1 chat-bold-text chat-base-text-sm">
          {groupMemberList.length} {groupMemberList.length === 0 ? 'Member' : 'Members'}
        </div>
        {groupMemberList.map((item, index) => renderSelectedMember(item, index))}
      </div>
    </div>
  );
};

export default React.memo(NewGroupChatSecondStepView);
