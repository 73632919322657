import React, { useEffect, useState } from 'react';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  FEATURE_TYPE,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import { useHistory } from 'react-router-dom';
import DefaultContentView from '../../../../common/ui/default_content_view/DefaultContentView';
import NewJoineeFeedItem from './NewJoineeFeedItem';
import { saveProgressLoadingState } from '../../../../state/UIState';
import { apiRequest, handleError } from '../../../../services/Service';
import { showToast } from '../../../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { COMMENT_BY_ID } from '../../../../services/ApiUrls';
import { selectAccessData } from '../../../../state/UserAccessData';

const FeedbackTab = ({ surveys }) => {
  const history = useHistory();
  const [surveyItem, setSurveyItem] = useState(surveys);
  const dispatch = useDispatch();
  const accessData = useSelector(selectAccessData);
  const [showLikeButton, setShowLikeButton] = useState(true);
  const [showCommentButton, setShowCommentButton] = useState(true);
  const [showParticipate, setShowParticipate] = useState(true);
  const [showViewResults, setShowViewResults] = useState(true);
  const pollsAccessData = accessData?.data?.find((item) => item.feature_key === '_polls');

  useEffect(() => {
    const actions = pollsAccessData?.actions;
    setShowViewResults(actions?.includes('_viewResults'));
    setShowLikeButton(actions?.includes('_like'));
    setShowCommentButton(actions?.includes('_comment'));
    setShowParticipate(actions?.includes('_participate'));
  }, [pollsAccessData?.actions]);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.NEW_JOINEES_FEEDBACK_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.NEW_JOINEES_HIGHER_LEVELS
    );
  }, []);

  const onPressParticipate = (item) => {
    history.push(NAVIGATION_ROUTES.SURVEYS_PARTICIPATE, {
      surveyId: item.survey_id,
    });
  };

  const emptyListView = () => {
    return <DefaultContentView message={NO_DATA_TEXT.NO_DATA_FOUND} />;
  };

  const callLikeApi = async (referId) => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
      })
    );
    const params = new URLSearchParams();
    params.append('type', FEATURE_TYPE.SURVEY);
    try {
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + referId + '/like',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            const message = apiResponse.response.message;
            if (message) {
              showToast(message);
              let element = surveyItem.find((item) => item.survey_id === referId);
              element.liked = data.liked;
              element.like_count = data.like_count;
              setSurveyItem(
                surveyItem.map((item) => (referId === item.survey_id ? element : item))
              );
            }
          }
        }
      }
    } catch (err) {
      handleError(
        err,
        params,
        COMMENT_BY_ID + referId + '/like',
        NAVIGATION_ROUTES.FEEDBACK_SCREEN
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  const onPressLikeButton = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.SURVEY_LIKE,
      item.survey_id.toString(),
      ANALYTICS_ITEM_NAMES.LIKE
    );
    callLikeApi(item.survey_id);
  };

  const onPressCommentButton = (item) => {
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: item.survey_id,
      likeType: FEATURE_TYPE.SURVEY,
      fromRoute: NAVIGATION_ROUTES.POLLS_AND_SURVEYS,
    });
  };

  return (
    <div>
      {!surveyItem.length ? (
        emptyListView()
      ) : (
        <div className="row">
          {surveyItem &&
            surveyItem?.map((survey, index) => (
              <NewJoineeFeedItem
                showLikeButton={showLikeButton}
                showCommentButton={showCommentButton}
                item={survey}
                key={`${index}`}
                onPressParticipate={onPressParticipate}
                onPressCommentButton={onPressCommentButton}
                onPressLikeButton={onPressLikeButton}
                showParticipate={showParticipate}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(FeedbackTab);
