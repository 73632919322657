import React from 'react';

const SuperFilterItem = ({ item, onPress, contestId }) => {
  return (
    <div>
      {contestId === item.contestId ? (
        <div className="anniversary-categories-selected">{item.hashTag}</div>
      ) : (
        <div className="anniversary-categories" onClick={() => onPress(item)}>
          {item.hashTag}
        </div>
      )}
    </div>
  );
};

export default SuperFilterItem;
