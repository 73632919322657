import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  SCREEN_TITLE,
  SUCCESS_MESSAGES,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';

const SurveySuccessScreen = () => {
  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.SURVEY_SUCCESS, NAVIGATION_ROUTES.SURVEY_SUCCESS);
  }, []);

  return (
    <div className="container">
      <SectionHeader title={SCREEN_TITLE.SURVEY} />
      <BackButton />
      <div className="main-content-container">
        <div className="breakouts-team-micro-view ">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.POLLS_SUCCESS,
              ACCESSIBILITY_IDENTIFIERS.POLLS
            )}
          />
        </div>
        <div className="text-center mt-3 polls-success-text">{SUCCESS_MESSAGES.SURVEY_SUCCESS}</div>
      </div>
    </div>
  );
};

export default SurveySuccessScreen;
