import React from 'react';
import { getUrlWithSpecificRendition } from '../../../utils/common';
import { SCREEN_DENSITY_QUALIFIERS } from '../../../common/constants/AppConstants';
import PlayIconOverlay from '../../../common/ui/play_icon_overlay/PlayIconOverlay';

const DonationListItem = ({ item, onPress }) => {
  const getNoOfDaysLeftText = (daysLeft = 1) => {
    if (daysLeft === 0 || daysLeft === 1) {
      return `1 day left`;
    } else {
      return `${daysLeft} days left`;
    }
  };

  return (
    <div className="col-sm-4 mb-3 common-cursor-pointer" onClick={() => onPress(item)}>
      <div>
        {item?.content_load_type === '3' && <PlayIconOverlay />}
        <img
          src={getUrlWithSpecificRendition(
            item.promo_image.base_url,
            SCREEN_DENSITY_QUALIFIERS.HDPI,
            item.promo_image.image_path
          )}
          className="embed-responsive embed-responsive-16by9"
        />
        <div className="microgive-title">{item.title}</div>
        <div className="microgive-short-description">{item.short_description}</div>
        <div className="microgive-row-style mt-2">
          <div className="microgive-fundraiser-list-item-text">
            {`Goal: ₹ ${item?.target_fund?.toLocaleString(
              'en-IN'
            )} | Raised: ₹ ${item?.raised_fund?.toLocaleString('en-IN')}`}
          </div>
          <div className="microgive-fundraiser-list-item-text">
            {getNoOfDaysLeftText(item?.no_of_days_left)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationListItem;
