import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectWomensDayConfiguration } from '../../state/MasterData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';

import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { resetAllTabs } from '../../state/TabData';
import WomensDayListItem from './components/WomensDayListItem';
import _ from 'lodash';

const WomensDayListingScreen = ({ history }) => {
  const dispatch = useDispatch();
  const womensdayConfigObj = useSelector(selectWomensDayConfiguration);
  const [activeWomensDayList, setActiveWomensDayList] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(womensdayConfigObj)) {
      let activeList = [...(womensdayConfigObj?.years ?? [])].filter((year) => year?.is_active);
      setActiveWomensDayList(activeList);
    }
  }, [womensdayConfigObj]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.WOMENS_DAY_LISTING, NAVIGATION_ROUTES.WOMENS_DAY_LISTING);
  }, []);

  const onPressItem = (item) => {
    dispatch(resetAllTabs());
    history.push(`${NAVIGATION_ROUTES.WOMENS_DAY}/${item?.year}`, {
      year: item?.year,
    });
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.WOMENS_DAY} />
      <div className="main-content-container">
        {activeWomensDayList.map((item, index) => {
          return <WomensDayListItem onPressItem={onPressItem} item={item} key={`${index}`} />;
        })}
      </div>
    </div>
  );
};

export default WomensDayListingScreen;
