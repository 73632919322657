// Accessability
export const ACCESSIBILITY_DATA = 'gettemplateS3?type=access&version=6.8.3';

//Localhost URL
export const LOCAL_HOST_URL = 'http://localhost:2000';

// Dashboard
export const DASHBOARD = 'getdashboard';

// Notification
export const GET_NOTIFICATION_COUNT = 'notificationcount';
export const GET_NOTIFICATION_LIST = 'notifications';
export const UPLOAD_MEDIA = 'uploadMedia';

// Authentication
export const SET_DEVICE = 'setdevice';
export const UPDATE_MOBILE = 'employee/updatemobile';
export const VERIFY_MOBILE_OTP = 'employee/verifymobileotp';
export const SEND_EMAIL_OTP = 'employee/sendemailotp';
export const VERIFY_EMAIL_OTP = 'employee/verifyemailotp';
export const SET_PIN = 'employee/setpin';
export const VERIFY_PIN = 'employee/verifypin';
export const SEND_PIN_OTP = 'employee/sendpinotp';
export const VERIFY_PIN_OTP = 'employee/verifypinotp';
export const FINGER_AUTHENTICATION = 'employee/autologin';
export const SIGN_OUT = 'employee/signout';

// Policies
export const GET_POLICY_BY_ID = 'policies/';
export const GET_POLICIES = 'policies';
export const GET_GEOGRAPHIES = 'policies/getgeographies';

export const GET_GEOGRAPHIES_NEW = 'policies/getcategoriesbygeography';
export const GET_SUB_CATEGORIES = 'policies/getsubcategoriesbycategory';
export const GET_POLICIES_BY_SUB_CATEGORY = 'policies/getpoliciesbysubcategory';

// Menu
export const GET_MENU_DATA = 'pd/menu?version=6.8.3';
export const GET_PERSONALIZABLE_MENU_DATA = 'pd/getempfeatureslistv2';
export const SAVE_MENU_PREFERENCES = 'pd/setempfeatureslist';
export const SAVE_MENU_FAVORITES = 'pd/setempfavorites';

// Directory
export const GET_DIRECTORY_DATA = 'employees';
export const GET_EMPLOYEE_BY_ID = 'employees/';
export const GET_VACCINATION_STATUS_BY_ID = 'employees/getVaccinationStatus/';
export const GET_WISHES = 'wishes/';
export const GET_CELEBRATION_MESSAGES = 'celebrationmessages/';
export const SEND_MESSAGE = 'wishes/';
export const GET_MY_WISHES_LIST = 'wishes';
export const GET_HASHTAGS = 'hashtags';

// Celebrations
export const GET_CELEBRATIONS_DATA = 'celebrations/';
export const GET_CELEBRATIONS_DATA_FROM_DATE = 'celebrations/';

// IJPR
export const GET_MY_APPLICATIONS = 'ijps/myjobs';
export const GET_MY_REFERRALS = 'ijps/myreferrals';
export const GET_IJP_LANDING_LISTING = 'ijps/apply/count/get';
export const GET_REFER_LANDING_LISTING = 'ijps/all/count/get';
export const GET_IJP_DETAIL = 'ijps/apply/ijp/get';
export const GET_REFER_DETAIL = 'ijps/ijp/get';
export const GET_IJP_SEARCH_RESULT_LISTING = 'ijps/apply/list/get';
export const GET_REFER_SEARCH_RESULT_LISTING = 'ijps/:id/list/get';
export const GET_IJP_FILTERS = 'ijps/apply/filters/get';
export const GET_REFER_FILTERS = 'ijps/refer/filters/get';
export const APPLY_FOR_IJP = 'ijps/apply';
export const REFER_FOR_IJP = 'ijps/refer';
export const ESCALATE_IJP = 'ijps/escalate';
export const SAVE_JOB = 'ijps/employee';
export const GET_BOOKMARK_JOBS = 'ijps/employee/bookmarks/list';

// New Joinees
export const GET_NEW_JOINEES_LOWER_LEVELS_DATA = 'joinees';
export const GET_NEW_JOINEES_HIGHER_LEVELS_DATA = 'joinees/';
export const GET_ALL_SESSIONS = 'joinees/agendasessions';
export const UPDATE_CHECKLIST = 'joinees/checklist';
export const ASK_SESSION_QUESTION = 'joinees/askquestion';
export const REGISTER_NEW_JOINEE_EVENT = 'joinees/calendar/';
export const SUBMIT_SESSION_RATING = 'joinees/rate';
export const MY_INDUCTION = 'newjoinees/induction';
export const ALL_INDUCTION = 'joinees/induction';
export const JOINERS_FEEDBACK = 'joinees/feedback';
export const NEO_SESSION_DETAIL = 'joinees/neo';
export const NEO_RSVP = 'joinees/neo/attendee/presence';
export const NEO_PICTURE_UPLOAD = 'joinees/upload';

// The Wall
export const GET_WALL = 'graffiti';
export const GET_WALL_BY_ID = 'graffiti/';
export const NEW_POST = 'graffiti/post';
export const NEW_EDIT_POST = 'graffiti/';
export const NEW_CREATE_POST = 'graffiti/post';
export const WALL_FILTERS = 'graffiti/filters';
export const EDIT_THE_WALL_POST = 'editpost';

// Comment
export const COMMENT = 'comment';
export const COMMENT_BY_ID = 'comment/';
export const DELETE_COMMENT = 'deletecomment';
export const EDIT_COMMENT = 'editcomment';
export const LIKE_UNLIKE_COMMENT = 'likeacomment';

// News
export const NEWS = 'news_mod';
export const NEWS_BY_ID = 'news_mod/';

// Articles
export const ARTICLES = 'articles';
export const ARTICLE_BY_ID = 'articles/';
export const INCREASE_VIEW_COUNT_EXTERNAL_LINK = 'articles/increase/view/count/';
export const INCREASE_POLL_VIEW_COUNT_EXTERNAL_LINK = 'polls/increase/view/count/';
export const INCREASE_SURVEY_VIEW_COUNT_EXTERNAL_LINK = 'surveys/increase/view/count/';

// Getwholiked
export const GET_WHO_LIKED = 'employee/getWhoLiked';
export const GET_WHO_LIKED_COMMENTS = 'employee/getcommentlikes';

// Ceo Chat
export const CEO_CHAT = 'cmdchat';
export const CEO_CHAT_ID = 'cmdchat/';
export const CEO_CHAT_ASK_QUESTION = 'cmdchat/askquestion';
export const CEO_CHAT_GET_SESSIONS = 'cmdchat/getsessions';

// ECards
export const ECARDS = 'downloadables';
export const GET_ECARD = 'getECard?ecardid=';

// Events
export const EVENTS = 'events';
export const EVENTS_BY_ID = 'events/';
export const EVENTS_BY_TYPE = 'events/geteventsbytype';

// Facilities
export const FACILITIES = 'facilities';
export const FACILITY_BY_ID = 'facilities/';
export const SEND_BUSINESS_CARD = 'sendbusinesscard';

// Galleries
export const GALLERIES = 'gallaries';
export const GALLERIES_BY_ID = 'galleries/';
export const GALLERIES_SEARCH = 'gallaries/search';

// Our Customers
export const CUSTOMERS = 'customers';
export const CUSTOMERS_BY_ID = 'customers/';
export const CUSTOMER_ACCOLADES = 'customers/accolade/';
export const CUSTOMER_TESTIMONIALS = 'customers/testimonial/';
export const MICRO_VIEW_RESEARCHERS = 'customers/researches';

// Stars
export const STARS = 'stars';
export const STARS_BY_ID = 'stars/';

// Employees
export const EMPLOYEES = 'employees';
export const EMPLOYEES_BY_ID = 'employees/';

// Posh
export const POSH = 'posh';
export const POSH_COMPLAINT = 'posh/complaint';

// Third Party Spps
export const GET_TOKEN = 'gettoken';

// Breakouts
export const GET_BREAKOUTS = 'breakouts';
export const JOIN_BREAKOUTS = 'breakouts/';
export const GET_AGENDA_SESSIONS = 'breakouts/session/';
export const GET_DISCUSSION_LIST = 'breakouts/';
export const GET_UPDATES_LIST = 'breakouts/';
export const GET_INFO_LIST = 'breakouts/';
export const GET_GALLERY_LIST = 'breakouts/gallery/';
export const SUBMIT_BREAKOUT_RATING = 'breakouts/rate';
export const SUBMIT_LIKE_COMMENT_BREAKOUT = 'comment/';
export const SUBMIT_DISCUSSION_COMMENT = 'breakouts/';
export const UPLOAD_BREAKOUT_IMAGES = 'breakouts/';
export const ASK_BREAKOUTS_SESSION_QUESTION = 'breakouts/askquestion';
export const GET_BREAKOUTS_SESSION_QUESTIONS = 'breakouts/questions';
export const GET_BREAKOUTS_ATTENDEES = '/members';
export const ADD_ANSWER = 'breakouts/addanswer';
export const GET_QUESTION_BY_ID = 'breakouts/questionbyid';

// Microgive
export const GET_INITIATIVES = 'microgive/initiatives';
export const GET_SUMMARY = 'microgive/summary';
export const GET_PROJECTS = 'microgive/projects';
export const PROJECTS_SEARCH = 'microgive/projects';
export const GET_INITIATIVE_DETAIL = 'microgive/initiative/';
export const GET_DONORS = 'microgive/initiative/';
export const GET_MICROGIVE_DISCUSSIONS = 'microgive/initiative/';
export const SUBMIT_MICROGIVE_DISCUSSION_COMMENT = 'microgive/initiative/';
export const GET_VOLUNTEER_LOG = 'microgive/volunteer/log';
export const GET_MY_DONATIONS = 'microgive/donation/log';
export const GET_PROJECTS_BY_ID = 'microgive/project/';
export const PROJECTS_SEARCH_BY_ID = 'microgive/project/';
export const PROJECTS_SEARCH_BY_DATE = 'microgive/project/';
export const POST_COMMENT = 'microgive/suggestions/add';
export const INITIATIVE_SEARCH = 'microgive/initiatives';
export const INITIATIVE_BY_DATE = 'microgive/initiatives';
export const GET_CURRENCIES = 'currencies?page_id=';
export const DONATE = 'microgive/donate';
export const GET_SELECT_DATES = 'microgive/initiative/';
export const REGISTER_EVENT = 'microgive/initiative/';
export const GET_PARTICIPANTS = 'microgive/initiative/';
export const GET_INITIATIVE_DETAIL_BY_ID = 'microgive/initiativedetail/';
export const GET_GALLERY_PROJECTS = 'microgive/initiativedetail/';
export const UPLOAD_IMAGES_GALLERY = 'microgive/initiativedetail/';
export const MICROGIVE_GALLERY_TAG_PEOPLE = 'microgive/initiativedetail/';
export const GET_VOLUNTEER_UPDATES = 'microgive/initiative/';
export const GET_CURRENCY_CONVERSION_RATES = 'get/currency/conversion/rates';
export const EDIT_EVENT = 'microgive/initiative/';

// Polls and Surveys
export const GET_POLLS = 'polls';
export const GET_SURVEYS = 'surveys';
export const SURVEY_BY_ID = 'surveys/';
export const POLLS_BY_ID = 'polls/';

// Profile
export const UPDATE_PROFILE = 'employee/update';
export const UPDATE_PROFILE_PICTURE = 'employee/updatepicture';
export const UPDATE_PROFILE_PICTURE_NEW = 'employee/updatepicture';
export const GET_ASSETS = 'employees/entitlements';
export const ASSET_ACKNOWLEDGEMENT = 'employees/entitlements/acknowledgeasset';
export const GET_INSURANCE = 'getinsurancecard';
export const GET_INSURANCE_PAGE = 'getinsurancepage';
export const GET_ID_CARD = 'getidcard';
export const DOWNLOAD_ID_CARD = 'downloadidcard';
export const GET_ORG_STRUCTURE = 'getorgstructure';
export const UPDATE_SETTINGS = 'employee/updatepreference';
export const GET_MY_WISHES = 'employee/wishes';

// Communities
export const COMMUNITIES = 'communities';
export const COMMUNITIES_BY_ID = 'communities/';
export const COMMUNITIES_POST = 'communities/post/';
export const COMMUNITIES_BY_CATEGORY = 'communities/list/1';
export const COMMUNITY_MAINSCREEN_POST = 'communities/interestposts/1';

// Ask Org
export const GET_PUBLIC_REQUESTS = 'askml/tickets/public';
export const GET_MY_REQUESTS = 'askml/tickets';
export const GET_MY_APPROVALS = 'askml/approvals';
export const GET_ASK_ORG_QUESTIONS = 'askml/questions';
export const GET_ASK_ORG_QUESTION_BY_ID = 'askml/question/';
export const GET_NEW_REQUEST_CATEGORIES = 'askml/category';
export const GET_PREFERRED_TIME_AND_LOCATION = 'askml/ticket/masters';
export const GET_TICKET_DETAILS = 'askml/request/';
export const GET_ESCALATION_MATRIX = 'askml/escalate/';
export const UPLOAD_REQUEST_PICTURES = 'askml/ticket/upload';
export const CREATE_NEW_REQUEST = 'askml/ticket/create';
export const REOPEN_REQUEST = 'askml/ticket/reopen';
export const SUBMIT_FEEDBACK = 'askml/feedback';
export const UPDATE_APPROVAL_STATUS = 'askml/updateapproval';
export const SUBMIT_REQUEST_FEEDBACK = 'askml/ticket/feedback';

// Contests
export const CONTESTS = 'contests';
export const CONTESTS_BY_ID = 'contests/';
export const CONTESTS_SEARCH = 'contests/search';

// Womens Day
export const WOMENS_DAY = 'womensday';
export const WOMENS_DAY_DISCUSSION = 'womensday/discussions';
export const WOMENS_DAY_POST_DISCUSSION = 'womensday/discussion/post';
export const WOMENS_DAY_GALLERIES = 'womensday/gallery';
export const WOMENS_DAY_UPLOAD_GALLERY = 'womensday/upload';
export const WOMENS_DAY_ARTICLES = 'womensday/articles';
export const WOMENS_DAY_FEEDBACK = 'womensday/feedback';
export const WOMENS_DAY_SURVEY = 'womensday/survey';
export const GET_WOMENSDAY_COMMITMENT = 'womensday/commitment';
export const COMMITMENT_LIBRARY = 'womensday/library';
// Cheers
export const GET_CHEERS = 'cheers';
export const GET_LEADER_BOARD = 'cheers/leaderboard';
export const GET_MY_REWARDS = 'cheers/myawards';
export const GET_MY_REPORTERS = 'cheers/list/myreportees';
export const GET_SELF_AWARDS = 'cheers/self/awards';
export const GET_PENDING_COUNT = 'cheers/pendingcount';
export const SUBMIT_LIKE_LEADERS_BOARD = 'comment/';
export const GET_CHEERS_POINTS = 'cheers/points';
export const GET_MY_MANAGER_AWARDS = 'cheers/mymanager/awards';
export const SUBMIT_NOMINATON = 'cheers/nominate';
export const GET_PEER_AWARDS = 'cheers/peer/awards';
export const GET_MY_TEAM_AWARDS = 'cheers/myteam/awards';
export const GET_PEER_EMPLOYEE = 'cheers/list/peers';
export const CHEERS_REDEEM_POINTS = 'cheers/reedem';
export const CHEERS_DONATE_POINTS = 'cheers/donate';
export const GET_POINTS_SUMMARY = 'cheers/ledger';
export const GET_APPROVALS = 'cheers/approvals';
export const UPDATE_APPROVAL = 'cheers/updateapproval';
export const SUBMIT_LIKE_COMMENT_CHEERS = 'comment/';
export const GET_CHEERS_DETAIL_BY_ID = 'cheers/';

// Anniversary
export const TAKE_PLEDGE = 'employee/pledge';
export const ML_33_CONTESTS = '/contests';
export const ML_34_POLLS_SURVEYS = '_anniversary_ml34/pollsurveys';

// Pandemic Support
export const PANDEMIC_SUPPORT_GUIDE = 'covidsupport/guide';
export const PANDEMIC_SUPPORT_CONVO = 'covidsupport/discussion';
export const PANDEMIC_SUPPORT_POST_COMMENT = 'covidsupport/discussion/addpost';

export const GET_USER_TOKEN = 'getauthtokenbyid';
export const GET_USER_TOKEN_BY_EMAIL = 'getauthtokenbymail';

//global search
export const SEARCH_FILTER = 'search_filter_v2';
export const GLOBAL_SEARCH_FILTER = 'global_search_filter';

// D&I
export const DI_DISCUSSIONS = 'dni/discussion';
export const DI_DISCUSSION_ADDPOST = 'dni/discussion/addpost';
export const DI_GET_ANNOUNCEMENTS = 'news_mod';
export const DI_TAKE_PLEDGE = 'employee/employeepledge';
export const DI_PLEDGE_STATUS = 'employee/ispledged';
export const DI_GALLERY = 'dni/gallery';
export const DI_CHAMPIONS = 'dni/champions';

// PODCAST
export const PODCAST_CHANNELS_LIST = 'podcasts/channels/list';
export const PODCAST_CHANNEL_BY_ID = 'podcasts/channel/get';
export const PODCAST_EPISODE_BY_ID = 'podcasts/episode/get';
export const PODCAST_UPDATE_PLAY_COUNT = 'podcasts/episode/play';
export const PODCASTS_SEARCH = 'podcasts/episodes/search';

// WALLPAPERS
export const GET_WALLPAPERS = 'wallpapers';
export const UPLOAD_BASE64_WALLPAPERS = 'uploadMedia/base64/image';

// TRAVEL REQUEST
export const GET_TRAVEL_REQUEST_MASTERS = 'travel/dashboard/get';
export const GET_EMPLOYEES_PROJECTS = 'projects/list';
export const GET_COUNTRIES = 'countries/list';
export const GET_TRAVELLER_LIST = 'travel/traveller/list';
export const ADD_EDIT_TRAVELLER = 'travel/traveller/addedit';
export const ADD_EDIT_TRAVEL_REQUEST = 'travel/request/addedit';
export const GET_REQUEST_BY_ID = 'travel/request/get';
export const GET_ALL_REQUESTS = 'travel/requests/list';
export const GET_UPCOMING_REQUESTS = 'travel/requests/upcoming';
export const GET_REQUESTS_FOR_APPROVAL = 'travel/requests/approvals';
export const SUBMIT_REQUEST = 'travel/request/';
export const GET_TRAVELLER_BY_ID = 'travel/traveller';

// BUSINESS CARD
export const UPLOAD_BUSINESS_CARD = 'uploadbusinesscard';
export const GENERATE_BUSINESS_CARD = 'generatebusinesscard/';
export const GENERATE_FACILITY_CARD = 'generatefacilitycard/';

// MI HR
export const GET_MI_HR_CONTESTS = 'mihr/components';
export const GET_MI_HR_ACTIVITIES = 'mihr/activities/get';
export const UPDATE_MI_HR_ACTIVITIES = 'mihr/activities/update';

// Acknowledgement
export const ACKNOWLEDGEMENT = 'employee/save/acknowledgement';

// ESG
export const GET_ESG_CHAMPIONS = 'esg/champians';
export const GET_ESG_CONTESTS = 'get/components';
export const GET_ESG_POLLS_AND_SURVEYS = 'get/components';

// MLF
export const GET_MLF_TESTMONIALS = 'mlf/testimonials';
export const GET_MLF_ABOUT_CONTENT = 'static/getcontent?page_key=mlf_about';

// Public Profile
// export const GET_PUBLIC_PROFILE = 'get/employee/profile/';

// Variable Pay
export const GET_VARIABLE_PAY = 'employees/variable/pay';

//  Emergency Playbook
export const GET_PLAYBOOK = 'playbooks';

//Upcoming Holidays
export const GET_UPCOMING_HOLIDAYS = 'holidays';
export const HOLIDAY_FILTERS = 'holidays/filters';

// User Accsess Control
export const ACCESS_CONTROL = 'get/useraccess';

// Chat GPT
export const CHAT_GPT = 'GPT';

// Mi Auction
export const AUCTION = 'auction';
export const AUCTION_TC = 'auction/terms';
export const PLACE_BID = 'auction/placebid';
export const BIDDERS_LIST = 'auction/bidders/';

// Feedback
export const EVENT_FEEDBACK = 'feedback';
export const MICROGIVE_FEEDBACK = 'microgive/feedback';

// Mi child care
export const GET_MI_CHILD_CARE = 'childcare';
export const GET_MI_CHILD_CARE_CENTRES = 'childcare/getcenters';
export const CHILD_CARE_APPLY = 'childcare/apply';
export const CHILD_CARE_WITHDRAW = 'childcare/withdraw';

// Attendance
export const GET_STATUS = 'attendance/getstatus';
export const LOGIN = 'attendance/login';
export const LOGOUT = 'attendance/logout';

// EStore
export const CUSTOMERS_ME = 'customers/me';
export const FETCH_STATES = 'directory/countries/';
export const DELETE_ADDRESS_BY_ID = 'addresses/';
export const CARTS_MINE_ITEMS = 'carts/mine/items';
export const ESTIMATE_SHIPPING_METHODS = 'carts/mine/estimate-shipping-methods';
export const PAYMENT_INFORMATION = 'carts/mine/payment-information';
export const SHIPPING_INFORMATION = 'carts/mine/shipping-information';
export const GET_PRODUCT_IMAGES = 'product/images';
export const INVENTORY_LEFT = 'inventory/left';
export const GET_ESTORE_POLICY_BY_ID = 'cmsPage/';
export const GET_ORDERS_BY_ID = 'orders/';
export const CARTS_MINE_ORDER = 'carts/mine/order';
export const PRODUCTS_ATTRIBUTES = 'products/attributes';
export const GET_CHILDREN_OF_CUSTOM_CONFIG_PRODUCT = (sku) =>
  `custom-configurable-products/${sku}/children`;
export const PRODUCT_BY_SKU = 'products/';
export const CARTS_MINE = 'carts/mine';
export const PRODUCTS = 'products';
export const GET_CHILDREN_OF_CONFIG_PRODUCT = (sku) => `configurable-products/${sku}/children`;
export const RETURN_ORDER = (id) => `order/${id}/refund`;
export const CANCEL_ORDER = (id) => `orders/${id}/cancel`;
export const SHIPMENTS = 'shipments';
export const CATEGORY_LIST = 'categories/list';
export const MOST_VIEWED_PRODUCTS = 'most-viewed-products';
export const ORDER_INVOICE = 'magento/invoicepdf';
export const STORE_ADDRESS = 'store/address';
