import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../../common/constants/AppConstants';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { GET_PEER_EMPLOYEE } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../state/UIState';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../../utils/accessibility';
import { Form } from 'react-bootstrap';
import debounce from 'lodash.debounce';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';

const PeerEmployeeSelection = ({ isIndividual, awardKey, tags, onEmployeeSelected }) => {
  const dispatch = useDispatch();
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeArray, setEmployeeArray] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [showLoadMore, setShowLoadMore] = useState(false);
  const pageIdRef = useRef(1);
  const loadMoreFlagRef = useRef(true);

  const accessibilityData = useSelector(selectAccessibilityData);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    setEmployeeArray(tags);
  }, [tags]);

  const callEmployees = useCallback(
    async (text = '') => {
      if (loadMoreFlagRef.current) {
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        if (pageIdRef.current == 1) {
          setEmployeeData([]);
        }
        let params = { page_id: pageIdRef.current, award_key: awardKey };
        params = { ...params, ...{ query: text } };
        try {
          const apiResponse = await apiRequest(GET_PEER_EMPLOYEE, REQUEST_TYPE.GET, params);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                if (pageIdRef.current === 1) {
                  setEmployeeData(apiResponse.response.data.feeds);
                } else {
                  setEmployeeData((prev) => [...prev, ...apiResponse.response.data.feeds]);
                }
                loadMoreFlagRef.current = true;
                setShowLoadMore(true);
              } else {
                loadMoreFlagRef.current = false;
                setShowLoadMore(false);
              }
            }
          }

          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        } catch (err) {
          handleError(err, params, GET_PEER_EMPLOYEE, NAVIGATION_ROUTES.PEER_SCREEN);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      } else {
        loadMoreFlagRef.current = false;
      }
    },
    [awardKey, dispatch]
  );

  useEffect(() => {
    callEmployees();
  }, [callEmployees]);

  const addEmployee = (employee) => {
    if (employeeArray.some((person) => person.employee_id === employee.employee_id)) {
      const index = employeeArray.findIndex(
        (person) => person.employee_id === employee.employee_id
      );
      if (index > -1) {
        setEmployeeArray(employeeArray.filter((item) => item !== employeeArray[index]));
      }
    } else {
      setEmployeeArray((prev) => [...prev, employee]);
    }
  };

  const loadMoreHandler = () => {
    if (loadMoreFlagRef.current) {
      pageIdRef.current = pageIdRef.current + 1;
      callEmployees(searchText);
    }
  };

  const debouncedCallback = (event) => {
    if (event && event.target.value.length > 2) {
      pageIdRef.current = 1;
      loadMoreFlagRef.current = true;
      callEmployees(event.target.value);
    }
    setSearchText(event.target.value);
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );

  const onSearchSubmitHandler = (event) => {
    event.preventDefault();
    setEmployeeData([]);
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    callEmployees(searchText);
  };

  const onClickSearchHandler = () => {
    setIsSearchEnabled(true);
  };

  const resetSearchHandler = useCallback(() => {
    setIsSearchEnabled(false);
    setSearchText('');
    setEmployeeData([]);
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    callEmployees('');
  }, [callEmployees]);

  const showProfiles = () => {
    if (employeeData.length > 0) {
      return employeeData.map((profile, index) => {
        return (
          <div key={index} className="anniversary-emp-image-div col-6 col-sm-3 col-lg-2">
            <UserProfilePhoto
              imageBaseUrl={profile.profile_picture.base_url}
              imagePath={profile.profile_picture.image_path}
              employeeId={profile.employee_id}
              imageClass="common-user-profile-photo-xl"
            />
            <div>
              <p className="anniversary-profile-name">{profile.first_name}</p>
              <p className="anniversary-profile-name">{profile.last_name}</p>
            </div>
            {isIndividual === false && (
              <div className="anniversary-check-row">
                <input
                  type="checkbox"
                  id="employeeCheck"
                  name="employeeCheck"
                  className="anniversary-employee-check-box common-cursor-pointer"
                  onChange={() => addEmployee(profile)}
                  checked={
                    employeeArray.some((person) => person.employee_id === profile.employee_id)
                      ? true
                      : false
                  }
                />
                <div>
                  {employeeArray.some((person) => person.employee_id === profile.employee_id)
                    ? 'Selected'
                    : 'Select'}
                </div>
              </div>
            )}
            {isIndividual === true && (
              <Form.Check
                type={'radio'}
                label={
                  employeeArray.some((person) => person.employee_id === profile.employee_id)
                    ? 'Selected'
                    : 'Select'
                }
                checked={
                  employeeArray.some((person) => person.employee_id === profile.employee_id)
                    ? true
                    : false
                }
                onChange={() => {
                  setEmployeeArray([profile]);
                }}
              />
            )}
          </div>
        );
      });
    } else
      return (
        <div className="w-100">
          <DefaultContentView
            message={ERROR_MESSAGES.SEARCH_NO_RESULTS}
            iconUri={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
              ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
            )}
          />
        </div>
      );
  };

  return (
    <div className="mt-3 mb-3">
      <div className="header-view d-flex justify-content-between">
        {isSearchEnabled ? (
          <form className="microgive-search-form" onSubmit={onSearchSubmitHandler}>
            <input
              autoFocus
              className="ml-3 microgive-search-bar"
              type="text"
              placeholder="Search.."
              onChange={debounceInputHandler}
            />
          </form>
        ) : (
          <div />
        )}
        {isSearchEnabled ? (
          <div onClick={resetSearchHandler}>
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
              )}
              className="header-icon mr-3"
            />
          </div>
        ) : (
          <div onClick={onClickSearchHandler}>
            <img
              src={getHeaderComponentUrl(accessibilityData, ACCESSIBILITY_HEADER_COMPONENTS.SEARCH)}
              className="header-icon mr-3"
            />
          </div>
        )}
      </div>
      <div className="anniversary-employee-view mt-2 mb-2">
        <div className="row">{showProfiles()}</div>
        {showLoadMore && (
          <div className="load-more">
            <LoadMoreBtn
              onClick={loadMoreHandler}
              isLoading={progressLoadingState.isProgressLoading}
            />
          </div>
        )}
      </div>
      {employeeArray.length > 0 && (
        <CustomButton buttonStyle="w-100 py-1" onClick={() => onEmployeeSelected(employeeArray)}>
          Continue
        </CustomButton>
      )}
    </div>
  );
};

export default PeerEmployeeSelection;
