import { createSlice } from '@reduxjs/toolkit';

const savePoliciesSearchTextDataReducer = (state, action) => {
  state.searchText = action.payload;
};

const savePoliciesSearchResultDataReducer = (state, action) => {
  state.searchResult = action.payload;
};

const savePoliciesLoadMoreButtonDataReducer = (state, action) => {
  state.loadMoreButtonState = action.payload;
};

const savePoliciesPageDataReducer = (state, action) => {
  state.pageId = action.payload;
};

// REDUCER
const policiesDataSlice = createSlice({
  name: 'policiesData',
  initialState: {
    searchText: '',
    searchResult: [],
    loadMoreButtonState: false,
    pageId: 1,
  },
  reducers: {
    savePoliciesSearchTextData: savePoliciesSearchTextDataReducer,
    savePoliciesSearchResultData: savePoliciesSearchResultDataReducer,
    savePoliciesLoadMoreButtonData: savePoliciesLoadMoreButtonDataReducer,
    savePoliciesPageData: savePoliciesPageDataReducer,
  },
});

// ACTIONS
const {
  savePoliciesSearchTextData,
  savePoliciesSearchResultData,
  savePoliciesLoadMoreButtonData,
  savePoliciesPageData,
} = policiesDataSlice.actions;

const selectPoliciesSearchTextData = ({ policiesData }) => {
  return policiesData.searchText;
};

const selectPoliciesSearchResultData = ({ policiesData }) => {
  return policiesData.searchResult;
};

const selectPoliciesLoadMoreButtonData = ({ policiesData }) => {
  return policiesData.loadMoreButtonState;
};

const selectPoliciesPageData = ({ policiesData }) => {
  return policiesData.pageId;
};

const policiesDataSliceReducer = policiesDataSlice.reducer;

export {
  policiesDataSliceReducer,
  savePoliciesSearchTextData,
  selectPoliciesSearchTextData,
  savePoliciesSearchResultData,
  selectPoliciesSearchResultData,
  savePoliciesLoadMoreButtonData,
  selectPoliciesLoadMoreButtonData,
  savePoliciesPageData,
  selectPoliciesPageData,
};
