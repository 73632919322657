import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import Divider from '../../../common/ui/divider/Divider';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import { GET_UPCOMING_REQUESTS } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { saveShowEmptyContentView } from '../../../state/UIState';
import { getHeaderComponentUrl } from '../../../utils/accessibility';
import { formatDateDDMonthYYYY } from '../../../utils/common';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import { renderRequestStatusBgColor } from '../../../utils/travelRequest';

const UpcomingTravelTab = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [upcomingRequestData, setUpcomingRequestData] = useState([]);
  const [loadMoreFlag, setLoadMoreFlag] = useState(true);
  const [pageId, setPageId] = useState(1);
  const [loading, setLoading] = useState(true);
  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.TRAVEL_REQUEST_UPCOMNG_TRAVEL_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.TRAVEL_REQUEST
    );
  }, []);

  const callAllRequestApi = useCallback(
    async (id, loadMore) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        try {
          setLoading(true);
          const apiResponse = await apiRequest(GET_UPCOMING_REQUESTS, REQUEST_TYPE.GET, {
            page_id: id,
          });
          setLoading(false);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.requests.length > 0) {
                setUpcomingRequestData((oldArray) => [
                  ...oldArray,
                  ...apiResponse.response.requests,
                ]);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(err, id, GET_UPCOMING_REQUESTS, NAVIGATION_ROUTES.TRAVEL_REQUEST);
          setLoading(false);
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    callAllRequestApi(pageId, loadMoreFlag);
  }, [callAllRequestApi, loadMoreFlag, pageId]);

  const onPressLoadMore = () => {
    setPageId((prev) => prev + 1);
  };

  const onPressItem = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.TR_UPCOMING_TRAVEL_TAB_ITEM_CLICK,
      item.request_id.toString(),
      ANALYTICS_ITEM_NAMES.TRAVEL_REQUEST
    );
    history.push(NAVIGATION_ROUTES.TRAVEL_REQUEST_REVIEW + '/' + item.request_id);
  };

  const renderUpcomingRequestView = (item, index) => {
    return (
      <div key={index} className="common-cursor-pointer" onClick={() => onPressItem(item)}>
        <div className="d-flex">
          <div className="flex-fill">
            <h5 className="mb-0 travel-request-purpose">{item.purpose}</h5>
            <p className="mb-0 travel-request-request-id-date">
              {item.request_code ? `${item.request_code} | ` : ''}
              {formatDateDDMonthYYYY(item.start_date, false)} to{' '}
              {formatDateDDMonthYYYY(item.end_date, false)}
            </p>
            <div className="d-flex align-items-center mt-3">
              <div
                className={`travel-request-request-indicator-base mr-2 ${renderRequestStatusBgColor(
                  item.status.identifier
                )}`}
              />
              <p className="mb-0 travel-request-status">{item.status.title}</p>
            </div>
          </div>
          <img
            src={getHeaderComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.POLICIES_RIGHT_ARROW,
              ACCESSIBILITY_IDENTIFIERS.POLICIES
            )}
            className="policy-icon mt-2"
          />
        </div>
        <Divider style="my-3" />
      </div>
    );
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_UPCOMING_REQUESTS_TO_SHOW} />;
  };

  return (
    <div className="p-3">
      <ActivityLoader visible={loading} />
      {upcomingRequestData && (
        <div className="mb-3">
          {!upcomingRequestData.length && !loading && emptyListView()}
          {!!upcomingRequestData.length && (
            <div>
              {upcomingRequestData.length > 0 &&
                upcomingRequestData.map((item, index) => renderUpcomingRequestView(item, index))}
            </div>
          )}
        </div>
      )}
      {loadMoreFlag && !!upcomingRequestData.length && (
        <LoadMoreBtn onClick={onPressLoadMore} buttonStyle="my-3" isLoading={loading} />
      )}
    </div>
  );
};

export default UpcomingTravelTab;
