import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  MICROGIVE_EVENT_TYPE,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import CalendarDialog from '../../common/ui/calender_dialog/CalendarDialog';
import BackButton from '../../common/ui/common_back_button/BackButton';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { GET_PROJECTS_BY_ID } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { saveMicrogiveProjectsTabData } from '../../state/TabData';
import {
  saveProgressLoadingState,
  saveSearchText,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../state/UIState';
import { getDateInYYYYMMDD } from '../../utils/common';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import ProjectDetailListItem from './components/ProjectDetailListItem';
import SearchComponent from '../../common/ui/search/SearchComponent';

const ProjectDetailScreen = ({ history }) => {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [projectsList, setProjectsList] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const [markedDates, setMarkedDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [noMatchFlag, setNoMatchFlag] = useState(false);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.PROJECT_DETAIL_SCREEN,
      NAVIGATION_ROUTES.PROJECT_DETAIL_SCREEN
    );
  }, []);

  const callProjectsApi = useCallback(
    async (id, loadMore) => {
      dispatch(saveShowEmptyContentView(true));
      setNoMatchFlag(false);
      if (loadMore) {
        const params = {
          page_id: id,
        };
        try {
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          const apiResponse = await apiRequest(
            GET_PROJECTS_BY_ID + projectId + '/initiatives',
            REQUEST_TYPE.GET,
            params
          );
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.initiatives.length > 0) {
                setProjectsList((oldArray) => [
                  ...oldArray,
                  ...apiResponse.response.data.initiatives,
                ]);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
                if (apiResponse.response.data.dates.length > 0) {
                  let markedDatas = [];
                  apiResponse.response.data.dates.forEach((element) => {
                    markedDatas.push(element);
                  });
                  setMarkedDates(markedDatas);
                }
              } else {
                if (apiResponse.response.data.initiatives.length === 0 && pageId === 1) {
                  setNoMatchFlag(true);
                }
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(
            err,
            params,
            GET_PROJECTS_BY_ID + projectId + '/initiatives',
            NAVIGATION_ROUTES.PROJECT_DETAIL_SCREEN
          );
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      }
    },
    [dispatch, projectId]
  );

  useEffect(() => {
    callProjectsApi(1, true);
  }, [callProjectsApi]);

  const onPress = (item) => {
    dispatch(saveMicrogiveProjectsTabData(''));
    if (item.type === MICROGIVE_EVENT_TYPE.VOLUNTEER) {
      history.push(
        `${NAVIGATION_ROUTES.PROJECT_TABS_SCREEN}/${item.microgive_initiative_detail_id}`,
        {
          title: item.title,
          fromRoute: NAVIGATION_ROUTES.PROJECT_DETAIL_SCREEN,
        }
      );
    } else if (item.type === MICROGIVE_EVENT_TYPE.DONATION) {
      history.push(
        `${NAVIGATION_ROUTES.PROJECT_TABS_SCREEN}/${item.initiative_id}/${item.microgive_initiative_detail_id}`
      );
    }
  };

  const onPressLoadMore = () => {
    callProjectsApi(pageId, loadMoreFlag);
  };

  const onDateSelected = (date) => {
    setSelectedDate(date);
    let formattedDate = getDateInYYYYMMDD(date);
    history.push(NAVIGATION_ROUTES.PROJECT_DETAIL_BY_DATE_SCREEN, {
      title: formattedDate,
      date: formattedDate,
      projectId: projectId,
    });
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_PROJECT_TO_SHOW} />;
  };

  const handleSubmitFunction = (searchText) => {
    dispatch(saveSearchText(searchText));
    history.push(NAVIGATION_ROUTES.PROJECT_DETAIL_SEARCH_SCREEN, {
      projectId: projectId,
      searchQuery: searchText,
    });
  };

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
        <SearchComponent handleSubmitFunction={handleSubmitFunction} />
        <div className="gcdo-icon-row mr-3">
          <CalendarDialog
            markedDates={markedDates}
            onDateSelected={onDateSelected}
            selectedDate={selectedDate}
            popperPosition="bottom-end"
          />
        </div>
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICROGIVE} />
      <BackButton />
      <div className="main-content-container">
        {pageId === 1 && noMatchFlag ? (
          emptyListView()
        ) : (
          <div>
            <div className="row">
              {projectsList.map((item, index) => {
                return <ProjectDetailListItem key={`${index}`} item={item} onPress={onPress} />;
              })}
            </div>
            {loadMoreFlag && (
              <LoadMoreBtn
                onClick={onPressLoadMore}
                isLoading={progressLoadingState.isProgressLoading}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectDetailScreen;
