import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../services/Service';
import { COMMENT_BY_ID } from '../../services/ApiUrls';
import { saveProgressLoadingState } from '../../state/UIState';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';
import Divider from '../../common/ui/divider/Divider';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';

const TheWallTaggedPeopleScreen = ({ location }) => {
  const params = location.state;
  const [employees, setEmployees] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.THE_WALL_TAGGED_PEOPLE,
      NAVIGATION_ROUTES.THE_WALL_TAGGED_PEOPLE
    );
  }, []);

  const callEmployeeData = useCallback(
    async (commentId) => {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      try {
        const response = await apiRequest(
          COMMENT_BY_ID + commentId + '/tags',
          REQUEST_TYPE.GET,
          {}
        );
        if (Object.keys(response).length > 0) {
          if (response.response.status === true) {
            if (response.response.data.feeds) {
              const data = response.response.data.feeds;
              if (data.length > 0) {
                setEmployees(data);
              }
            }
          }
        }
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      } catch (err) {
        handleError(
          err,
          {},
          COMMENT_BY_ID + commentId + '/tags',
          NAVIGATION_ROUTES.THE_WALL_TAGGED_PEOPLE
        );
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    },
    [dispatch]
  );

  const renderScreen = useCallback(async () => {
    if (params && params.comment_id) {
      callEmployeeData(params && params.comment_id);
    }
    setEmployees(params.employees);
  }, [callEmployeeData, params]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const renderEmployee = (item) => {
    return (
      <div className="thewall-image-row">
        <UserProfilePhoto
          imageBaseUrl={item.profile_picture.base_url}
          imagePath={item.profile_picture.image_path}
          imageClass="common-user-profile-photo-md mb-1 mr-2"
          employeeId={item.employee_id}
        />
        <div className="ml-3">
          <div className="thewall-name-text">{item.first_name + ' ' + item.last_name}</div>
          <div className="thewall-place-text">{item.location.title}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="main-content-container container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.THE_WALL} />
      <BackButton />
      {employees &&
        employees.map((item, index) => {
          return (
            <div key={`${index}`}>
              {renderEmployee(item)}
              <Divider style="mt-2 mb-2" />
            </div>
          );
        })}
    </div>
  );
};

export default TheWallTaggedPeopleScreen;
