import React from 'react';
import { useHistory } from 'react-router-dom';
import { IoArrowBackCircleOutline } from 'react-icons/io5';

import colors from '../../themes/Colors';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  NAVIGATION_ROUTES,
} from '../../constants/AppConstants';
import SearchComponent from '../search/SearchComponent';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { selectCartItemsCount } from '../../../state/EStoreData';

const BackButtonStore = (props) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const cartItemsCount = useSelector(selectCartItemsCount);
  const history = useHistory();
  const {
    backTitle = 'BACK',
    isFullRow = true,
    onSubmit,
    containerStyle = '',
    navigateBackFuncHandler = () => {
      const path = history.location;
      history.goBack();
      setTimeout(() => {
        if (path == history.location) {
          history.replace(NAVIGATION_ROUTES.HOME);
        }
      }, 200);
    },
  } = props;

  return (
    <div className={`${isFullRow ? 'store-common-comment-header-view' : ''} ${containerStyle}`}>
      <div
        className="d-flex align-items-center common-cursor-pointer"
        onClick={navigateBackFuncHandler}>
        <IoArrowBackCircleOutline
          size="2.25em"
          className="mr-2"
          color={colors.silverChaliceLight}
        />
        <span className="common-back-button-title">{backTitle}</span>
      </div>
      <div className="gcdo-header-row store-header-view-absolute justify-content-end float-right w-50">
        <SearchComponent handleSubmitFunction={onSubmit} />
        <div
          className="gcdo-icon-row mr-3"
          onClick={() => {
            history.push(NAVIGATION_ROUTES.MICRO_STORE_CART_SCREEN);
          }}>
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.CART_BLUE,
              ACCESSIBILITY_IDENTIFIERS.MICRO_STORE
            )}
            className="header-icon-estore"
          />
          {cartItemsCount > 0 && <div className="cart-items-count-chip">{cartItemsCount}</div>}
        </div>
      </div>
    </div>
  );
};

export default BackButtonStore;
