import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Tab } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NAVIGATION_ROUTES, REQUEST_TYPE } from '../../../common/constants/AppConstants';
import NavTabs from '../../../common/ui/tabs/NavTabs';

import { ECARDS } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectEcardsTabs } from '../../../state/MasterData';
import { saveEcardsTabData, selectEcardsTabData } from '../../../state/TabData';
import { saveProgressLoadingState } from '../../../state/UIState';
import EcardTab from '../../ecards/tabs/EcardTab';

const ECardModal = (props) => {
  const { isECardModalVisible, setIsECardModalVisible, onPressShareHandler } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const ecardsTabs = useSelector(selectEcardsTabs);
  const ecardsTabdata = useSelector(selectEcardsTabData);
  const tempCards = {};
  for (var val in ecardsTabs) {
    tempCards[ecardsTabs[val].category_id] = [];
  }
  const [ecards, setEcards] = useState(tempCards);
  const [tabKey] = useState(
    location && location.state && location.state.tabKey && location.state.tabKey
  );

  const callECardsApi = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequest(ECARDS, REQUEST_TYPE.GET, {});
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.downloadables) {
            const data = apiResponse.response.data.downloadables;
            setLoading(false);
            if (data.length > 0) {
              const ecardsArray = {};
              for (var i in ecardsTabs) {
                const ecardData = data.find(
                  (card) => card.downloadable_id === Number(ecardsTabs[i].category_id)
                );
                if (ecardData) {
                  ecardsArray[ecardsTabs[i].category_id] = ecardData;
                }
              }
              setEcards(ecardsArray);
            }
          }
        }
      }
    } catch (err) {
      handleError(err, {}, ECARDS, NAVIGATION_ROUTES.CHAT);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch]);

  useEffect(() => {
    callECardsApi();
  }, [callECardsApi]);

  useEffect(() => {
    if (ecardsTabs.length && ecardsTabdata === '' && tabKey) {
      dispatch(saveEcardsTabData(tabKey));
    } else if (ecardsTabs.length && ecardsTabdata === '') {
      dispatch(saveEcardsTabData(ecardsTabs[0].category_id));
    }
  }, [dispatch, ecardsTabdata, ecardsTabs, tabKey]);

  const renderTabs = (tab) => {
    switch (tab.category_id) {
      case tab.category_id:
        return (
          <EcardTab
            data={ecards[tab.category_id]}
            item={tab.category_id}
            loading={loading}
            title={tab.title}
            onlyShareButton={true}
            onPressShare={onPressShareHandler}
          />
        );
    }
  };

  return (
    <Modal
      onHide={() => setIsECardModalVisible((val) => !val)}
      show={isECardModalVisible}
      centered
      size="lg"
      backdrop="static"
      scrollable={true}
      keyboard={false}>
      <Modal.Header className="d-flex align-items-center" closeButton>
        <Modal.Title className="common-section-header-title py-2">E-CARDS</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 pt-0 pb-4">
        <NavTabs
          activeKey={ecardsTabdata}
          onSelect={(k) => dispatch(saveEcardsTabData(k))}
          unmountOnExit>
          {ecardsTabs &&
            ecardsTabs.map((tab, index) => {
              return (
                <Tab
                  key={`${index}`}
                  eventKey={tab.category_id}
                  title={tab.title.toUpperCase()}
                  className="main-content-container">
                  {renderTabs(tab, index)}
                </Tab>
              );
            })}
        </NavTabs>
      </Modal.Body>
    </Modal>
  );
};

export default ECardModal;
