import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { selectShowEmptyContentView } from '../../../state/UIState';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { getUrlWithSpecificRendition } from '../../../utils/common';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';

const FacilityTab = (props) => {
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const accessibilityData = useSelector(selectAccessibilityData);
  const history = useHistory();

  const emptyListView = () => {
    return (
      <DefaultContentView
        message={NO_DATA_TEXT.NO_DATA_FOUND}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  const onPressItem = (item) => {
    history.push(`${NAVIGATION_ROUTES.FACILITY}/${item.facility_id}`);
  };

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.FACILITY_TAB_VISIT, props.item, ANALYTICS_ITEM_NAMES.FACILITY);
  }, [props.item]);

  const facilitiesArray =
    props?.data &&
    props?.data.map((item, index) => {
      return (
        <div
          key={`${index}`}
          className="col-sm-4 mb-2 common-cursor-pointer"
          onClick={() => onPressItem(item)}>
          <div>
            <img
              src={getUrlWithSpecificRendition(
                item?.promo_image?.base_url,
                SCREEN_DENSITY_QUALIFIERS.HDPI,
                item?.promo_image?.image_path
              )}
              className="embed-responsive embed-responsive-16by9"
            />
          </div>
          <div className="facility-title"> {item?.title + ', ' + item?.location.title}</div>
        </div>
      );
    });

  return (
    <div>
      {props?.showEmptyView && showEmptyContentView && facilitiesArray?.length === 0 ? (
        emptyListView()
      ) : (
        <div>
          <div className="row">{facilitiesArray}</div>
        </div>
      )}
    </div>
  );
};

export default FacilityTab;
