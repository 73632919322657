import React, { useEffect, useState, useCallback } from 'react';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  FILTER_IDENTIFIERS,
  NAVIGATION_ROUTES,
  OUR_CUSTOMERS_API_TYPES,
  OUR_CUSTOMERS_DATA_TYPES,
  OUR_CUSTOMERS_LIKE_TYPES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../../services/Service';
import { CUSTOMERS_BY_ID } from '../../../services/ApiUrls';
import Filter from '../../../common/ui/filter/Filter';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import OurCustomerListItem from '../components/OurCustomerListItem';
import { useHistory } from 'react-router';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { resetAllTabs } from '../../../state/TabData';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessData } from '../../../state/UserAccessData';

const OurCustomerTab = ({ type, title }) => {
  const history = useHistory();
  const [customers, setCustomers] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(true);
  const [geographyId, setGeographyId] = useState('');
  const [locationId, setLocationId] = useState('');
  const [regionId, setRegionId] = useState('');
  const [dealYear, setDealYear] = useState('');
  const [loading, setLoading] = useState(true);
  const [dealYearList, setDealYearList] = useState([]);
  const dispatch = useDispatch();
  const accessData = useSelector(selectAccessData);
  const [showFilter, setShowFilter] = useState(true);
  const customerAccessData = accessData?.data?.find((item) => item.feature_key === '_ourCustomers');

  useEffect(() => {
    const actions = customerAccessData?.actions;
    setShowFilter(actions?.includes('_filter'));
  }, [customerAccessData?.actions]);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.OUR_CUSTOMERS_TAB_VISIT,
      type,
      ANALYTICS_ITEM_NAMES.OUR_CUSTOMER
    );
  }, [type]);

  const callOurCustomerApi = useCallback(
    async (id, loadMore, geoId, locationId, dealYear, regionId) => {
      if (loadMore) {
        const params = {
          page_id: id,
          geography_id: geoId,
          location_id: locationId,
          deal_year: dealYear,
          region_id: regionId,
        };
        try {
          setLoading(true);
          const apiResponse = await apiRequest(CUSTOMERS_BY_ID + type, REQUEST_TYPE.GET, params);
          setLoading(false);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data) {
                const data = apiResponse.response.data;
                if (data?.deal_years?.length > 0) {
                  setDealYearList(data?.deal_years);
                }
                if (data.feeds.length > 0) {
                  if (id === 1) {
                    setCustomers(data.feeds);
                  } else {
                    setCustomers((oldArray) => [...oldArray, ...data.feeds]);
                  }
                  setLoadMoreFlag(true);
                } else {
                  setLoadMoreFlag(false);
                }
              }
            }
          }
        } catch (err) {
          handleError(err, params, CUSTOMERS_BY_ID + type, NAVIGATION_ROUTES.OUR_CUSTOMER);
          setLoading(false);
        }
      }
    },
    [type]
  );

  useEffect(() => {
    callOurCustomerApi(pageId, loadMoreFlag, geographyId, locationId, dealYear, regionId);
  }, [callOurCustomerApi, loadMoreFlag, pageId, geographyId, locationId, dealYear, regionId]);

  const filterData = (selectedFilters) => {
    const hasSelectedFilters = Object.keys(selectedFilters).length > 0;
    if (hasSelectedFilters) {
      let filterChanged = false;
      if (selectedFilters.geography_id?.toString() !== geographyId) {
        setGeographyId(selectedFilters.geography_id?.toString() ?? '');
        filterChanged = true;
      }
      if (selectedFilters.location_id?.toString() !== locationId) {
        setLocationId(selectedFilters.location_id?.toString() ?? '');
        filterChanged = true;
      }
      if (selectedFilters.region_id?.toString() !== regionId) {
        setRegionId(selectedFilters.region_id?.toString() ?? '');
        filterChanged = true;
      }
      if (selectedFilters.years?.toString() !== dealYear) {
        setDealYear(selectedFilters.years?.toString() ?? '');
        filterChanged = true;
      }
      if (filterChanged) {
        setPageId(1);
        setLoadMoreFlag(true);
        setCustomers([]);
      }
    } else if (dealYear !== '' || regionId !== '') {
      setGeographyId('');
      setLocationId('');
      setDealYear('');
      setPageId(1);
      setLoadMoreFlag(true);
      setCustomers([]);
      setRegionId('');
    }
  };

  const onPressLoadMore = () => {
    setPageId((prev) => prev + 1);
  };

  const onPressMore = (pictures, baseUrl) => {
    history.push(NAVIGATION_ROUTES.GALLERY_COMMON, {
      baseUrl: baseUrl,
      pictures: pictures.pictures,
    });
  };

  const onPress = (item) => {
    OUR_CUSTOMERS_DATA_TYPES[type] !== 'know'
      ? history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT, {
          customerId: item.id,
          dataType: OUR_CUSTOMERS_DATA_TYPES[type],
          parentName: title,
          apiType: OUR_CUSTOMERS_API_TYPES[type],
          type: type,
          likeType: OUR_CUSTOMERS_LIKE_TYPES[type],
        })
      : dispatch(resetAllTabs());
    history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_ALL_SCREEN, {
      customerId: item.id,
      dataType: OUR_CUSTOMERS_DATA_TYPES[type],
      parentName: title,
      apiType: OUR_CUSTOMERS_API_TYPES[type],
      type: type,
      likeType: OUR_CUSTOMERS_LIKE_TYPES[type],
      headerName: item?.name,
      item: item,
      fromRoute: 'customerKnow',
    });
  };

  const renderItem = (item, index) => {
    return (
      <OurCustomerListItem
        item={item}
        key={`${index}`}
        onPressMore={onPressMore}
        onPress={onPress}
      />
    );
  };

  return (
    <div>
      {showFilter && (
        <div>
          <Filter
            topFilter={FILTER_IDENTIFIERS.GEOGRAPHIES}
            regions={true}
            dealYear={dealYearList.length > 0 ? true : false}
            dealYearList={dealYearList}
            onClick={filterData}
          />
        </div>
      )}
      <div>
        {customers ? (
          <>
            {customers?.length ? (
              <div className="row">{customers?.map((item, index) => renderItem(item, index))}</div>
            ) : loading ? (
              <ActivityLoader visible={loading} />
            ) : (
              <DefaultContentView message={ERROR_MESSAGES.NO_DATA_FOUND} />
            )}
            {loadMoreFlag && <LoadMoreBtn onClick={onPressLoadMore} isLoading={loading} />}
          </>
        ) : (
          <ActivityLoader visible={loading} />
        )}
      </div>
    </div>
  );
};

export default React.memo(OurCustomerTab);
