import React from 'react';
import { useSelector } from 'react-redux';
import { selectAnniversaryDayTabs } from '../../../state/MasterData';
import MlDayGalleryItem from './MlDayGalleryItem';
import { ML_DAY_IDENTIFIERS } from '../../../common/constants/AppConstants';

const MlDayGalleryImageView = ({ item, onPressMore, location, index, onPressLoadMore }) => {
  const anniversaryDayTitles = useSelector(selectAnniversaryDayTabs);
  const itemObj = anniversaryDayTitles.find((tab) => tab.identifier === item.type);
  return (
    <div>
      <div className="my-3">
        <div className="d-flex flex-row justify-content-between align-items-center w-100">
          <h2 style={{ color: '#000', fontSize: '20px', fontFamily: 'ProximaNovaCond-SemiBold' }}>
            {itemObj.title}
          </h2>
        </div>
        <div
          style={{
            borderBottom: '1px solid #E30613',
            width: '100%',
            marginBottom: 10,
          }}
        />
      </div>
      <div>
        {item.data.feeds.pictures && item.data.feeds.pictures.length > 0 ? (
          <div>
            <MlDayGalleryItem
              totalCount={item.data.total_count}
              baseUrl={
                item.type !== ML_DAY_IDENTIFIERS.ML_35_IDENTIFIER
                  ? item.data.feeds.base_url + 'images_ml33/images/'
                  : item.data.feeds.base_url
              }
              item={item.data.feeds.pictures}
              key={`${index}`}
              identifier={item.type}
            />
          </div>
        ) : (
          <div>No pictures available</div>
        )}
      </div>
    </div>
  );
};

export default MlDayGalleryImageView;
