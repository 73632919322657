import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  LOADING_MESSAGES,
  REQUEST_TYPE,
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  NAVIGATION_ROUTES,
  ANALYTICS_SCREEN_NAMES,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../common/constants/AppConstants';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { apiRequest, handleError } from '../../services/Service';
import { COMMENT_BY_ID, CUSTOMERS_BY_ID } from '../../services/ApiUrls';
import { saveProgressLoadingState, saveSearchText } from '../../state/UIState';
import { formatDateDDMonthYYYY, getUrlWithSpecificRendition } from '../../utils/common';
import HtmlView from '../../common/ui/html_view/HTMLView';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { selectAccessData } from '../../state/UserAccessData';

const OurCustomerContentScreen = ({ history, location }) => {
  const { apiType, customerId, dataType, likeType, type, parentName } = location.state;
  const [customer, setCustomer] = useState();
  const dispatch = useDispatch();
  const [liked, setLiked] = useState();
  const [likeCount, setLikeCount] = useState();
  const [commentCount, setCommentCount] = useState();
  const accessibilityData = useSelector(selectAccessibilityData);
  const accessData = useSelector(selectAccessData);
  const [showLike, setShowLike] = useState(true);
  const [showComment, setShowcomment] = useState(true);
  const customerAccessData = accessData?.data?.find((item) => item.feature_key === '_ourCustomers');

  useEffect(() => {
    const actions = customerAccessData?.actions;
    setShowLike(actions?.includes('_like'));
    setShowcomment(actions?.includes('_comment'));
  }, [customerAccessData?.actions]);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.OUR_CUSTOMER_CONTENT,
      NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT
    );
  }, []);

  const getDefaultProfile = () => {
    const defaultProfileUrl = getFeatureComponentUrl(
      accessibilityData,
      ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_DEFAULT_PROFILE,
      ACCESSIBILITY_IDENTIFIERS.COMMON
    );
    return defaultProfileUrl;
  };

  const callCustomerData = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequest(
        CUSTOMERS_BY_ID + apiType + '/' + customerId,
        REQUEST_TYPE.GET,
        {}
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data[dataType]) {
            const data = apiResponse.response.data[dataType];
            setCustomer(data);
            setLiked(data.liked);
            setLikeCount(data.like_count);
            setCommentCount(data.comment_count);
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(
        err,
        {},
        CUSTOMERS_BY_ID + apiType + '/' + customerId,
        NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [apiType, customerId, dataType, dispatch]);

  useEffect(() => {
    callCustomerData();
  }, [callCustomerData]);

  const postLike = async () => {
    logEvent(ANALYTICS_EVENT_TYPES.OUR_CUSTOMERS_LIKE, customer.id, ANALYTICS_ITEM_NAMES.LIKE);
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
      })
    );
    const formData = new URLSearchParams();
    formData.append('type', likeType);
    try {
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + customer.id + '/like',
        REQUEST_TYPE.POST,
        formData
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            setLikeCount(data.like_count);
            setLiked(data.liked);
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(
        err,
        formData,
        COMMENT_BY_ID + customer.id + '/like',
        NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  const onPressMore = (pictures, baseUrl) => {
    history.push(NAVIGATION_ROUTES.GALLERY_COMMON, {
      baseUrl: baseUrl,
      pictures: pictures.pictures,
    });
  };

  const handleHtmlClicks = (e) => {
    const target = e.target.getAttribute('href');
    if (target) {
      if (target.startsWith('/') || target.startsWith('?')) {
        if (target.includes(NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL)) {
          logEvent(
            ANALYTICS_EVENT_TYPES.USER_MENTION_CLICK,
            e?.target?.innerText,
            ANALYTICS_ITEM_NAMES.USER_MENTION
          );
          e.stopPropagation();
          e.preventDefault();
          history.push(target);
        } else {
          logEvent(
            ANALYTICS_EVENT_TYPES.HASHTAG_CLICK,
            e?.target?.innerText,
            ANALYTICS_ITEM_NAMES.HASHTAG
          );
          dispatch(saveSearchText(''));
          e.preventDefault();
          history.push(`${NAVIGATION_ROUTES.OUR_CUSTOMER_KNOW_SEARCH_SCREEN}${target}`, { type });
        }
      } else {
        e.stopPropagation();
        e.preventDefault();
        window.open(target, '_blank');
      }
    }
  };

  const renderHeader = () => {
    return (
      <div className="broadcast-header-row justify-content-end-view">
        {showLike && (
          <>
            <div className="broadcast-icon-row mr-3" onClick={postLike}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  liked
                    ? ACCESSIBILITY_HEADER_COMPONENTS.ACTIVE_LIKE
                    : ACCESSIBILITY_HEADER_COMPONENTS.INACTIVE_LIKE
                )}
                className="header-icon"
              />
            </div>
            <div
              className="mr-3 ourcustomer-like-comment common-cursor-pointer"
              onClick={() => {
                likeCount
                  ? history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
                      referId: customer.id,
                      parentType: likeType,
                    })
                  : () => {};
              }}>
              {likeCount ? likeCount : '0'}
            </div>{' '}
          </>
        )}
        {showComment && (
          <>
            {' '}
            <div
              className="broadcast-icon-row mr-3"
              onClick={() => {
                history.push(NAVIGATION_ROUTES.COMMENTS, {
                  referId: customer.id,
                  likeType: likeType,
                  fromRoute: NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT,
                });
              }}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.COMMENT
                )}
                className="header-icon"
              />
            </div>
            <div
              className="mr-3 ourcustomer-like-comment common-cursor-pointer"
              onClick={() => {
                logEvent(
                  ANALYTICS_EVENT_TYPES.OUR_CUSTOMER_COMMENT,
                  customer.id,
                  ANALYTICS_ITEM_NAMES.COMMENT
                );
                history.push(NAVIGATION_ROUTES.COMMENTS, {
                  referId: customer.id,
                  likeType: likeType,
                  fromRoute: NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT,
                });
              }}>
              {commentCount ? commentCount : '0'}
            </div>
          </>
        )}
      </div>
    );
  };

  const renderOurCustomer = () => {
    return (
      <div>
        <div className="ourcustomers-main">
          <img
            src={getUrlWithSpecificRendition(
              customer.base_url,
              SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
              customer.promo_image
            )}
            className="embed-responsive embed-responsive-16by9"
          />
          {customer.gallary && (
            <div
              onClick={() => onPressMore(customer.gallary, customer.base_url)}
              className="ourcustomers-bg-view-detail">
              <div className="ourcustomers-count-left-text">
                {' '}
                {customer.gallary.pictures.length === 1
                  ? `${customer.gallary.pictures.length} photo`
                  : `${customer.gallary.pictures.length} photos`}
              </div>
            </div>
          )}
        </div>
        <div className="pt-1 pb-1 pl-3 pr-3">
          {customer.date && (
            <div>
              <div className="ourcustomers-flex mt-1">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_CALENDER,
                    ACCESSIBILITY_IDENTIFIERS.MICROGIVE
                  )}
                  className="ourcustomers-calender-style"
                />
                <div className="ourcustomers-event-date">
                  {formatDateDDMonthYYYY(customer.date)}
                </div>
              </div>
              {customer.title && (
                <div className="ourcustomers-title-text mt-1">{customer.title}</div>
              )}
            </div>
          )}
          {customer.name && <div className="ourcustomers-title-text mt-1">{customer.name}</div>}
          {customer.region && <div className="ourcustomer-location-text">{customer.region}</div>}
          <HtmlView html={customer.description} onClick={handleHtmlClicks} htmlStyle="mt-1" />
          {customer.account_owner && (
            <div className="ourcustomers-item row ml-1 mr-1">
              <UserProfilePhoto
                imageBaseUrl={customer.account_owner.profile_picture.base_url}
                imagePath={customer.account_owner.profile_picture.image_path}
                employeeId={customer.account_owner.employee_id}
                imageClass="common-user-profile-photo-md"
              />
              <div className="ml-3 ourcustomers-cross-axis-view">
                <div>
                  <div className="mb-1 ourcustomers-account-ownername">
                    {customer.account_owner.first_name +
                      ' ' +
                      customer.account_owner.middle_name +
                      ' ' +
                      customer.account_owner.last_name}
                  </div>
                  <div className="mt-1 ourcustomer-owner-text">Account Owner</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderAccolades = () => {
    return (
      <div>
        <div className="ourcustomers-cross-axis-view">
          <img
            src={
              customer.company_logo.base_url && customer.company_logo.image_path
                ? getUrlWithSpecificRendition(
                    customer.company_logo.base_url,
                    SCREEN_DENSITY_QUALIFIERS.LDPI,
                    customer.company_logo.image_path
                  )
                : getDefaultProfile()
            }
            className="common-user-profile-photo-lg"
          />
          <div className="ml-3 ourcustomers-cross-axis-view">
            <div>
              <div className="ourcustomers-flex mt-1">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_CALENDER,
                    ACCESSIBILITY_IDENTIFIERS.MICROGIVE
                  )}
                  className="ourcustomers-calender-style"
                />
                <div className="ourcustomers-event-date">
                  {formatDateDDMonthYYYY(customer.date)}
                </div>
              </div>
              <div className="mt-1 ourcustomers-author">{customer.author}</div>
              <div className="mt-1 ourcustomers-accolades-designation">{customer.designation}</div>
              <div className="mt-1 ourcustomer-title">{customer.title}</div>
              <HtmlView
                html={customer.description}
                handleHtmlClicks={handleHtmlClicks}
                htmlStyle="mt-1"
              />
            </div>
          </div>
        </div>
        {customer.tags && (
          <div className="ml-3">
            {customer.tags.map((item, index) => {
              return (
                <div className="ourcustomers-item row ml-3 mr-3" key={`${index}`}>
                  <UserProfilePhoto
                    imageBaseUrl={item.profile_picture.base_url}
                    imagePath={item.profile_picture.image_path}
                    employeeId={item.employee_id}
                    imageClass="common-user-profile-photo-md"
                  />
                  <div className="ml-3 ourcustomers-cross-axis-view">
                    <div>
                      <div className="mb-1 ourcustomers-account-ownername">
                        {item.employee_name}
                      </div>
                      <div className="mt-1 ourcustomer-owner-text">{item.location.title}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="container">
      <SectionHeader title={parentName ? parentName : 'LOADING...'} />
      <div className="header-view justify-content-between">
        <BackButton isFullRow={false} />
        {renderHeader()}
      </div>
      <div className="main-content-container">
        <div>{type !== 'accolades' && customer && renderOurCustomer()}</div>
        <div>{type === 'accolades' && customer && renderAccolades()}</div>
      </div>
    </div>
  );
};

export default OurCustomerContentScreen;
