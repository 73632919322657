import React from 'react';
import { Modal, ModalFooter } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { ACKNOWLEDGEMENT } from '../../../services/ApiUrls';
import { apiRequest } from '../../../services/Service';
import { saveProgressLoadingState } from '../../../state/UIState';
import { REQUEST_TYPE } from '../../constants/AppConstants';
import CustomButton from '../custom_button/CustomButton';
import HtmlView from '../html_view/HTMLView';

export default function MandatoryAcknwoledgementModal({
  show,
  content,
  setShow,
  callbackOnButtonPress,
}) {
  const dispatch = useDispatch();
  const optionsList = content?.available_options ?? [];

  const callAcknowledgementApi = async (option) => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const formData = new URLSearchParams();
      let ack_id = content?.acknowledgement_id ?? '';
      let platform = 'web';
      let option_id = option?.option_id ?? '';
      formData.append('acknowledgement_id', ack_id);
      formData.append('platform', platform);
      formData.append('option_id', option_id);

      const apiResponse = await apiRequest(ACKNOWLEDGEMENT, REQUEST_TYPE.POST, formData);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          const message = apiResponse?.response?.message || '';
          const is_skipped = apiResponse?.response?.is_skipped || false;
          if (message) {
            setShow(false);
            let callbackData = { ...content, message: message, is_skipped: is_skipped };
            callbackOnButtonPress(callbackData);
          }
        }
      }
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  return (
    <Modal
      id="_ack_modal"
      className="_ack_modal"
      size="lg"
      onHide={() => {}}
      show={show}
      centered
      scrollable={true}
      backdrop="static"
      keyboard={false}>
      <Modal.Header className="justify-content-center text-uppercase">
        <Modal.Title>
          {`${content?.title ?? ''}`.length > 50
            ? `${content?.title ?? ''}`.substring(0, 50) + '...'
            : `${content?.title ?? 'Acknowledgement'}`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 py-3">
        <div>
          <div className="overflow-scroll">
            <HtmlView html={content?.content} htmlStyle="mt-3 gcdo-content-html" />
          </div>
        </div>
      </Modal.Body>
      <ModalFooter>
        <div className="d-flex flex-direction-row">
          {optionsList.map((option) => (
            <span className="pl-3" key={option?.option_id}>
              <CustomButton
                onClick={() => {
                  callAcknowledgementApi(option);
                }}>
                {option.option_text}
              </CustomButton>
            </span>
          ))}
        </div>
        <br />
      </ModalFooter>
    </Modal>
  );
}
