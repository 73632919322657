import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectMicrogiveDonation } from '../../state/MasterData';
import DonationDetailsTab from './tabs/donationTabs/DonationDetailsTab';
import DonationDonorsTab from './tabs/donationTabs/DonationDonorsTab';
import DonationDiscussionTab from './tabs/donationTabs/DonationDiscussionTab';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import { Tab } from 'react-bootstrap';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { useParams } from 'react-router-dom';
import NavTabs from '../../common/ui/tabs/NavTabs';
import BackButton from '../../common/ui/common_back_button/BackButton';

const DonationTabsScreen = () => {
  const { initiativeId } = useParams();
  const [initiativeDetailId, setInitiativeDetailId] = useState();
  const [key, setKey] = useState();

  const microgiveTitles = useSelector(selectMicrogiveDonation);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.DONATION_TABS_SCREEN,
      NAVIGATION_ROUTES.DONATION_TABS_SCREEN
    );
  }, []);

  useEffect(() => {
    if (microgiveTitles.length) {
      setKey(microgiveTitles[0].key);
    }
  }, [microgiveTitles]);

  const onSetInitiativeDetailId = (initiativeDetailid) => {
    setInitiativeDetailId(initiativeDetailid);
  };

  const renderTabs = (tab) => {
    switch (tab.key) {
      case 'details':
        return (
          <DonationDetailsTab
            initiativeId={initiativeId}
            onSetInitiativeDetailId={onSetInitiativeDetailId}
          />
        );
      case 'donors':
        return (
          <DonationDonorsTab initiativeId={initiativeId} initiativeDetailId={initiativeDetailId} />
        );
      case 'discussions':
        return <DonationDiscussionTab initiativeId={initiativeId} />;
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICROGIVE} />
      <BackButton />
      <NavTabs activeKey={key} onSelect={(k) => setKey(k)} unmountOnExit>
        {microgiveTitles.map((tab, index) => {
          return (
            <Tab key={`${index}`} eventKey={tab.key} title={tab.title}>
              {renderTabs(tab, index)}
            </Tab>
          );
        })}
      </NavTabs>
    </div>
  );
};

export default DonationTabsScreen;
