import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  NAVIGATION_ROUTES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { formatDateDDMonthYYYY, getUrlWithSpecificRendition } from '../../../utils/common';
import PlayIconOverlay from '../../../common/ui/play_icon_overlay/PlayIconOverlay';

const ContestsListItem = ({ item, index, onPress }) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  return (
    <div
      key={`${index}`}
      className="col-sm-4 mb-2 common-cursor-pointer"
      onClick={() => onPress(item)}>
      <div>
        {item?.content_load_type === '3' && <PlayIconOverlay />}
        <img
          src={getUrlWithSpecificRendition(
            item?.image?.base_url,
            SCREEN_DENSITY_QUALIFIERS.HDPI,
            item?.image?.image_path
          )}
          className="embed-responsive embed-responsive-16by9"
        />
        <div className="row ml-1 mt-1">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.NEWS_CALENDER,
              ACCESSIBILITY_IDENTIFIERS.NEWS
            )}
            className="polls-calender-style"
          />
          <div className="contests-event-date">{formatDateDDMonthYYYY(item?.pub_datetime)}</div>
        </div>
      </div>
      <div className="contests-title">{item.first_text}</div>
      <div className="contests-summary">{item.second_text}</div>
    </div>
  );
};

export default ContestsListItem;
