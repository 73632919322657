const colors = {
  primary: '#EC1C24',
  primaryDisabled: 'rgba(236, 28, 36, 0.4)',
  accent: '#5591B7',

  alizarinCrimson: '#EC1C24',
  gallery: '#F8F6F9',
  galleryDark: '#EBEBEB',
  galleryLight: '#f0f0f0',
  valencia: '#D94B39',
  tuna: '#383839',
  charcoal: '#595959',
  coal: '#2d2d2d',
  silverChaliceLight: '#9E9E9E',
  silverChalice: '#ADADAD',
  seaBuckthorn: '#F79738',
  grey: '#828282',
  alto: '#d5d5d5',
  poloBlue: '#96B2D3',

  blue: 'blue',
  white: 'white',
  black: 'black',
  disabledBlack: 'rgba(0, 0, 0, 0.4)',

  error: 'rgba(200, 0, 0, 0.8)',
  background: '#D0D3D4',
  transparent: 'rgba(0,0,0,0)',

  skyblue: '#8AAAD9',

  darkgrey: '#2C2C2C',
  profileBlue: '#007AFF',

  seashell: '#F1F1F1',
  alabaster: '#FAFAFA',

  ranch: '#F7F6F6',
  vapor: '#E7E7E7',
  timeGrey: '#4D4D4D',
  lynxWhite: '#F7F7F7',
  altoSolid: '#dbdbdb',
  wildSand: '#F6F6F6',
  altoLight: '#D8D6D6',
  gainsboro: '#e1e1e1',
  whisper: '#EAEAEA',
  lightGrey: '#ccc',
  solitude: '#E5EDF8',
  catskillWhite: '#F1F3F8',
  gray: '#8f8f8f',
  linkWater: '#EDF2FA',
  forestGreen: '#25b723',
  tango: '#EC841C',
  silver: '#CCCCCC',
  silverDark: 'BDBDBD',
  linkWaterApprox: '#E5EDF8',
  gold: '#FFD700',
  buttonBlue: '#4472c4',
};

export default colors;
