import React, { useCallback, useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import {
  calculateDurationInHoursAndMinutes,
  convertISTDateToLocalFormat,
  formatDateDDMonthYYYY,
  formatHHMMSSTimeLocalAMPM,
  getUrlWithSpecificRendition,
  showToast,
} from '../../../../utils/common';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../../common/constants/AppConstants';
import Divider from '../../../../common/ui/divider/Divider';
import UserProfilePhoto from '../../../../common/ui/user_profile_photo/UserProfilePhoto';
import HtmlView from '../../../../common/ui/html_view/HTMLView';
import { useDispatch } from 'react-redux';
import { saveProgressLoadingState } from '../../../../state/UIState';
import { apiRequest } from '../../../../services/Service';
import { NEO_RSVP } from '../../../../services/ApiUrls';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';

const NeoAgendaTab = ({ sessionData, callNeoSessionDetailApi }) => {
  const dispatch = useDispatch();
  const [rsvpData, setRsvpData] = useState();

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.NEO_AGENDA_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.NEW_JOINEES_HIGHER_LEVELS
    );
  }, []);

  const callNeoRsvp = useCallback(
    async (response) => {
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
          })
        );
        const formData = new URLSearchParams();
        formData.append('neo_id', sessionData?.neo_id);
        formData.append('response', response);
        const apiResponse = await apiRequest(NEO_RSVP, REQUEST_TYPE.POST, formData);
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
        if (apiResponse.response.status) {
          setRsvpData(apiResponse.response.data);
          showToast(apiResponse.response.message);
          callNeoSessionDetailApi();
        } else {
          showToast(apiResponse.response.error);
        }
      } catch (e) {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
        console.error(e);
        showToast(e);
      }
    },
    [callNeoSessionDetailApi, dispatch, sessionData?.neo_id]
  );
  const renderSessions = (item) => {
    return (
      <div>
        {item?.is_break == 0 ? (
          <>
            <div className="neo-detail-session-card p-3">
              {item?.title && (
                <div className="neo-detail-session-title">{item.title.toUpperCase()}</div>
              )}
              {item?.start_time && item?.end_time ? (
                <div className="d-flex flex-row mt-1">
                  <div className="neo-detail-text mr-1">Timings:</div>
                  <div className="neo-detail-text">
                    {formatHHMMSSTimeLocalAMPM(item?.start_time)} to{' '}
                    {formatHHMMSSTimeLocalAMPM(item?.end_time)}
                    {' ('}
                    {calculateDurationInHoursAndMinutes(item?.start_time, item?.end_time)}
                    {')'}
                  </div>
                </div>
              ) : null}
              <div className="mt-3">
                {item?.speakers && item?.speakers.length > 1 ? (
                  <div className="neo-detail-semi-bold mb-2">SPEAKERS</div>
                ) : (
                  <div className="neo-detail-semi-bold mb-2">SPEAKER</div>
                )}
                <div
                  className={
                    item?.speakers?.length > 2
                      ? 'speaker-grid-container'
                      : 'd-flex flex-row flex-wrap speaker-conatiner'
                  }>
                  {item?.speakers?.map((speaker, index) => {
                    return (
                      <div
                        key={`${index}`}
                        className={
                          item?.speakers?.length > 2
                            ? 'd-flex flex-row profile-conatiner mb-3'
                            : 'd-flex flex-row w-50'
                        }>
                        <UserProfilePhoto
                          imageBaseUrl={speaker.profile_picture.base_url}
                          imagePath={speaker.profile_picture.image_path}
                          employeeId={speaker.employee_id}
                          imageClass={
                            item?.speakers?.length > 2
                              ? 'common-user-profile-photo-md mb-3'
                              : 'common-user-profile-photo-md'
                          }
                        />
                        <div className="divider-space d-flex flex-row justify-content-between">
                          <div className="pr-2">
                            <p className="new-joinees-higher-levels-card-speaker-title m-0 ml-3">
                              {`${speaker.employee_name}`.length < 30
                                ? `${speaker.employee_name}`
                                : `${speaker.employee_name}`.substring(0, 26) + '...'}
                            </p>
                            <p className="new-joinees-higher-levels-card-speaker-subtitle m-0 ml-3">
                              {speaker.position_title}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <Divider style={'my-2'} />
                {item?.summary ? (
                  <HtmlView html={item?.summary} htmlStyle="neo-detail-text" />
                ) : null}
              </div>
            </div>
          </>
        ) : (
          <div className="neo-detail-break-card p-3">
            {item?.title && (
              <div className="neo-detail-session-title">{item.title.toUpperCase()}</div>
            )}
            {item?.start_time && item?.end_time ? (
              <div className="d-flex flex-row mt-1">
                <div className="neo-detail-text mr-1">Timings:</div>
                <div className="neo-detail-text">
                  {formatHHMMSSTimeLocalAMPM(item?.start_time)} to{' '}
                  {formatHHMMSSTimeLocalAMPM(item?.end_time)}
                  {' ('}
                  {calculateDurationInHoursAndMinutes(item?.start_time, item?.end_time)}
                  {')'}
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  };
  return (
    <div className="mb-3">
      {sessionData && (
        <div className="d-flex flex-row mt-3 w-100">
          <Image
            src={getUrlWithSpecificRendition(
              sessionData?.promo_image.base_url,
              SCREEN_DENSITY_QUALIFIERS.HDPI,
              sessionData?.promo_image.image_path
            )}
            className="neo-session-detail-image"
          />
          <div className="w-100">
            {sessionData.title ? (
              <div className="neo-detail-title-text mb-3">{sessionData.title.toUpperCase()}</div>
            ) : null}
            {sessionData?.date ? (
              <div className="neo-detail-sub-title-text mb-2">
                {convertISTDateToLocalFormat(sessionData?.date).toUpperCase()}
              </div>
            ) : null}
            {sessionData?.start_time && sessionData?.end_time ? (
              <div className="d-flex flex-row">
                <div className="neo-detail-semi-bold mr-1">Timings:</div>
                <div className="neo-detail-text">
                  {formatHHMMSSTimeLocalAMPM(sessionData?.start_time)} to{' '}
                  {formatHHMMSSTimeLocalAMPM(sessionData?.end_time)}
                </div>
              </div>
            ) : null}
            {sessionData?.location ? (
              <div className="d-flex flex-row">
                <div className="neo-detail-semi-bold mr-1">Location:</div>
                <div className="neo-detail-text">{sessionData?.location}</div>
              </div>
            ) : null}
            {sessionData?.summary ? (
              <div className="neo-detail-text mt-3 mb-3">{sessionData?.summary}</div>
            ) : null}
            <Divider style={'w-100'} />

            {sessionData?.show_rsvp ? (
              <div style={{ width: '100%' }} className="d-flex justify-content-center mt-3 mb-2">
                <div>
                  <div className="mr-2 neo-detail-confirm-text d-flex align-self-center">
                    Please confirm your presence for this session.
                  </div>
                  <div className="d-flex justify-content-center align-items-center w-100">
                    <div className="align-self-center mt-2">
                      <div className="d-flex flex-row">
                        <div
                          onClick={() => {
                            logEvent(
                              ANALYTICS_EVENT_TYPES.NEO_RSVP_YES,
                              '',
                              ANALYTICS_ITEM_NAMES.NEW_JOINEES_HIGHER_LEVELS
                            );
                            callNeoRsvp(1);
                          }}
                          className="mr-2 neo-detail-yes-btn d-flex align-items-center justify-content-center common-cursor-pointer">
                          YES
                        </div>
                        <div
                          onClick={() => {
                            logEvent(
                              ANALYTICS_EVENT_TYPES.NEO_RSVP_NO,
                              '',
                              ANALYTICS_ITEM_NAMES.NEW_JOINEES_HIGHER_LEVELS
                            );
                            callNeoRsvp(0);
                          }}
                          className="d-flex align-items-center justify-content-center neo-detail-no-btn common-cursor-pointer">
                          NO
                        </div>
                      </div>
                      {sessionData?.response ? (
                        <div className="d-flex flex-row mt-1 w-100">
                          <div className="neo-rsvp-text mr-1"> Latest Status:</div>
                          {sessionData?.response === 'Confirmed' ? (
                            <div className="neo-rsvp-confirm-text mr-1">
                              {sessionData?.response}
                            </div>
                          ) : (
                            <div className="neo-rsvp-reject-text mr-1">{sessionData?.response}</div>
                          )}
                          <div className="neo-rsvp-text mr-1">
                            on {formatDateDDMonthYYYY(sessionData?.response_registered_on)}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}
      {sessionData?.sessions.map((item, index) => {
        return (
          <div className="mt-2" key={`${index}`}>
            {renderSessions(item)}
          </div>
        );
      })}
    </div>
  );
};

export default NeoAgendaTab;
