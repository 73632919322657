import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  NAVIGATION_ROUTES,
} from '../../../common/constants/AppConstants';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessData } from '../../../state/UserAccessData';

const NewJoineeSpeakersListItem = ({ item, fromAllInduction }) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const employeeData = useSelector(selectEmployeeData);
  const history = useHistory();
  const accessData = useSelector(selectAccessData);
  const [showChatButton, setShowChatButton] = useState(true);
  const newJoineesAccessData = accessData?.data?.find((item) => item.feature_key === '_newJoinees');

  useEffect(() => {
    if (newJoineesAccessData) {
      const actions = newJoineesAccessData?.actions;
      setShowChatButton(actions?.includes('_chat'));
    }
  }, [newJoineesAccessData]);

  const handleChatPress = () => {
    history.push(NAVIGATION_ROUTES.CHAT, {
      employee_id: item?.employee_id,
    });
  };

  return (
    <div className={'d-flex flex-row w-100'}>
      <div className={'d-flex flex-row w-100 m-2'}>
        <UserProfilePhoto
          imageBaseUrl={item.profile_picture.base_url}
          imagePath={item.profile_picture.image_path}
          employeeId={item.employee_id}
          imageClass={'common-user-profile-photo-md'}
        />
        <div className="divider-space d-flex flex-row justify-content-between">
          <div>
            <p className="new-joinees-higher-levels-card-speaker-title m-0 ml-3 w-100">
              {`${item.employee_name}`.length < 30
                ? `${item.employee_name}`
                : `${item.employee_name}`.substring(0, 26) + '...'}
            </p>
            <p className="new-joinees-higher-levels-card-speaker-subtitle m-0 ml-3 w-100">
              {item.position_title}
            </p>
          </div>
        </div>
      </div>
      {item.employee_id !== employeeData.employee_id && !fromAllInduction && showChatButton && (
        <img
          onClick={handleChatPress}
          src={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_SMS,
            ACCESSIBILITY_IDENTIFIERS.PROFILE
          )}
          className="new-joinees-chat-button-image d-flex align-self-center ml-auto mr-3 mt-n3"
        />
      )}
    </div>
  );
};

export default NewJoineeSpeakersListItem;
