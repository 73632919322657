import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { formatDateDDMonth, formatTimeAMPM } from '../../../../utils/common';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  NAVIGATION_ROUTES,
} from '../../../constants/AppConstants';
import HtmlView from '../../../ui/html_view/HTMLView';
import Divider from '../../../ui/divider/Divider';
import InfoVideoItem from '../../../../screen/breakouts/components/InfoVideoItem';
import { getFeatureComponentUrl } from '../../../../utils/accessibility';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import NewJoineeSpeakersListItem from '../../../../screen/breakouts/components/NewJoineeSpeakerListItem';

const NewJoineeSessionDetailTab = ({ data }) => {
  const location = useLocation();
  const fromAllInduction = location.state.fromAllInduction;
  const history = useHistory();
  const accessibilityData = useSelector(selectAccessibilityData);

  const onPressPdf = (pdfItem) => {
    let win = window.open(pdfItem.url, '_blank');
    win.focus();
  };

  const onPressVideo = (videoItem) => {
    history.push(`${NAVIGATION_ROUTES.FULL_SCREEN_VIDEO}/${videoItem.url}`);
  };

  const renderYoutubeItem = (data, index) => {
    return <InfoVideoItem item={data} onPress={onPressVideo} key={`${index}`} />;
  };

  return (
    <div className="main-content-container2">
      <div className="material-card-no-hover p-3">
        <div className="d-flex flex-column justify-content-between">
          <div className="d-flex flex-row justify-content-between session-detail-container">
            <div className="d-flex flex-row session-item-container">
              <div className="calendar-card mr-3 d-flex justify-content-center align-items-center flex-column">
                <h5 className="mt-3 mb-n1">
                  {formatDateDDMonth(data?.start_datetime).slice(0, 2)}
                </h5>
                <h6 className="mb-3">{formatDateDDMonth(data?.start_datetime).slice(3, 6)}</h6>
              </div>
              <div>
                <div className="pb-3 new-joinees-title-container mt-1">
                  <h6 className="new-joinees-higher-levels-card-main-title mb-1">{data?.title}</h6>
                  <p className="new-joinees-higher-levels-card-sub-title mb-2">
                    {formatTimeAMPM(data?.start_datetime)} to {formatTimeAMPM(data?.end_datetime)}{' '}
                    (IST)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column w-100">
            {data?.speakers?.length > 1 ? (
              <div className="new-joinees-higher-levels-card-main-title mr-2 mt-1 mb-3">
                Speakers
              </div>
            ) : (
              <div className="new-joinees-higher-levels-card-main-title mr-2 mt-1 mb-3">
                Speaker
              </div>
            )}

            <div className="speaker-list-container">
              {data.speakers.map((item, index) => {
                return (
                  <div
                    className={`speaker-list-item ${
                      (index + 1) % 3 !== 0 && index !== data?.speakers?.length - 1 ? 'ver-div' : ''
                    }`}
                    key={index}>
                    <NewJoineeSpeakersListItem
                      item={item}
                      key={index}
                      fromAllInduction={fromAllInduction}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Divider style="mt-3 mb-3" />
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex flex-column w-50">
            <div className="breakouts-card-main-title mb-3 ">Description</div>
            <HtmlView html={data?.summary} htmlStyle="new-joinees-description-conatiner" />
          </div>
          {(data?.media?.pdf?.length != 0 ||
            data?.media?.text.length != 0 ||
            data?.media?.youtube.length != 0) && (
            <div className="w-50 ml-5 d-flex flex-column">
              <div className="breakouts-card-main-title d-flex align-self-start mb-2">Media</div>
              <Divider />
              <div>
                {(data?.media?.pdf?.length > 0 ||
                  data?.media?.text?.length > 0 ||
                  data?.media?.youtube?.length > 0) && (
                  <>
                    {data?.media?.youtube?.length > 0 && (
                      <>
                        {data?.media?.youtube?.map((data, index) => (
                          <div key={`${index}`} className="yt-div d-flex mt-3 w-100 mr-auto">
                            {renderYoutubeItem(data, index)}
                          </div>
                        ))}
                      </>
                    )}
                    {data?.media?.text?.length > 0 && (
                      <>
                        {!!data?.media?.text?.length &&
                          data?.media?.text?.map((link, index) => (
                            <div className="yt-div d-flex mt-3 w-100 mr-auto" key={index}>
                              <div className="d-flex align-items-center mb-3 common-cursor-pointer">
                                <img
                                  src={getFeatureComponentUrl(
                                    accessibilityData,
                                    ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_LINK_SQUARE,
                                    ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                                  )}
                                  className="new-joinees-higher-levels-card-link-media-attachment-icon mr-2 ml-1"
                                />
                                <a
                                  className="new-joinee-media-link mb-0"
                                  href={link?.url}
                                  target="_blank"
                                  rel="noreferrer">
                                  {link?.label}
                                </a>
                              </div>
                            </div>
                          ))}
                      </>
                    )}
                    {data?.media?.pdf?.length > 0 && (
                      <div>
                        {data?.media?.pdf.map((doc, index) => (
                          <div className="yt-div d-flex w-100 mt-3 mr-auto" key={index}>
                            <div
                              onClick={() => onPressPdf(doc)}
                              className="d-flex align-items-center mb-3 common-cursor-pointer">
                              <img
                                src={getFeatureComponentUrl(
                                  accessibilityData,
                                  ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_PDF_ATTACHMENT,
                                  ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                                )}
                                className="new-joinees-higher-levels-card-pdf-media-attachment-icon mr-1 ml-1"
                              />
                              <p
                                className="new-joinee-media-link mb-0"
                                onClick={() => onPressPdf(doc)}>
                                {doc?.label}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(NewJoineeSessionDetailTab);
