import React from 'react';
import { useHistory } from 'react-router-dom';
import { NAVIGATION_ROUTES } from '../../../common/constants/AppConstants';
import PolicyListItem from '../../policies/components/PolicyListItem';

const PolicyItem = (props) => {
  const history = useHistory();

  const onPressItem = (item) => {
    history.push(`${NAVIGATION_ROUTES.POLICY_DETAIL_SCREEN}/${item.policy_id}`);
  };

  const policyArray =
    props.feed &&
    props.feed.map((item, index) => {
      return <PolicyListItem onPressItem={onPressItem} item={item} key={`${index}`} />;
    });

  return <div>{policyArray}</div>;
};

export default PolicyItem;
