import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackScreen } from '../../../utils/FirebaseAnalyticsUtils';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { resetLoaderState, saveProgressLoadingState } from '../../../state/UIState';
import { apiRequest, handleError } from '../../../services/Service';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import ML35GalleryComponent from './ML35GalleryComponent';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import SectionHeader from '../../../common/ui/section_header/SectionHeader';
import BackButton from '../../../common/ui/common_back_button/BackButton';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';

const ML35PicturesScreen = ({ history }) => {
  const { title, identifier, geography, day_id, fromRoute } = history.location.state;
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [MLDayPictures, setMLDayPictures] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const [showEmptyView, setShowEmptyView] = useState(false);
  const [isOverlayOpen, setOverlayOpen] = useState(false);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [selectedImage, setSelectedImage] = useState();

  const openOverlay = (item) => {
    setSelectedImage(item);
    setOverlayOpen(true);
  };

  const closeOverlay = () => {
    setOverlayOpen(false);
    setSelectedImage();
  };

  const downloadImage = async (imageSrc, imageName = 'downloaded-image') => {
    try {
      const response = await fetch(imageSrc);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = imageName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading the image:', error);
    }
  };

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.ML35_PICTURES_SCREEN,
      NAVIGATION_ROUTES.ML35_PICTURES_SCREEN
    );
  }, []);

  const callGalleryContentApi = useCallback(
    async (pageId) => {
      setError(false);
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
          })
        );
        setShowEmptyView(false);
        const params = { geography, page_id: pageId, day_id };
        const apiUrl =
          fromRoute == 'pictureGalleryNonIndia'
            ? identifier + '/picturegallerynonindia'
            : identifier + '/picturegallery';
        const apiResponse = await apiRequest(apiUrl, REQUEST_TYPE.GET, params);

        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            const tempPictures = apiResponse.response?.data[0]?.pictures ?? [];
            if (tempPictures.length === 0) {
              setLoadMoreEnabled(false);
              setShowEmptyView(true);
            }
            if (pageId > 1) {
              setMLDayPictures((prev) => [...prev, ...tempPictures]);
            } else {
              setMLDayPictures(tempPictures);
            }
          }
        } else {
          setShowEmptyView(true);
        }
        dispatch(resetLoaderState());
      } catch (err) {
        handleError(err, {}, identifier + '/picturegallery', 'ML35Pictures');
        setShowEmptyView(true);
        dispatch(resetLoaderState());
        setError(false);
      }
    },
    [day_id, dispatch, fromRoute, geography, identifier]
  );

  useEffect(() => {
    callGalleryContentApi(1);
  }, [callGalleryContentApi]);

  const loadMore = () => {
    if (loadMoreEnabled) {
      callGalleryContentApi(pageId + 1);
      setPageId(pageId + 1);
    }
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_IMAGES_TO_SHOW} />;
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY} />
      <BackButton backTitle={title ?? 'BACK'} />
      <div>
        {showEmptyView && MLDayPictures.length === 0 ? (
          emptyListView()
        ) : (
          <>
            {isOverlayOpen && selectedImage && (
              <div className="ml-35-image-overlay">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_IMAGE_CLOSE,
                    ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
                  )}
                  className="ml-35-image-close-button"
                  onClick={closeOverlay}
                />
                <img
                  className="ml-35-fullscreen-image"
                  src={
                    selectedImage.picture.base_url + 'xxhdpi/' + selectedImage.picture.image_path
                  }
                />
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_IMAGE_DOWLOAD,
                    ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
                  )}
                  className="ml-35-image-download-icon common-cursor-pointer"
                  onClick={() =>
                    downloadImage(
                      selectedImage.picture.base_url + 'xxhdpi/' + selectedImage.picture.image_path,
                      `Celebration-Image`
                    )
                  }
                />
              </div>
            )}
            <div className={`ml-35-images-wrapper mb-2`}>
              {MLDayPictures.map((item, index) => (
                <ML35GalleryComponent
                  key={index}
                  item={item}
                  index={index}
                  onPress={() => openOverlay(item)}
                />
              ))}
            </div>
            {!loadMoreEnabled && <div className="mb-4" />}
          </>
        )}
        {loadMoreEnabled && (
          <div className="mb-4 mt-4">
            <LoadMoreBtn onClick={loadMore} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ML35PicturesScreen;
