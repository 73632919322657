import React, { useCallback, useEffect, useState } from 'react';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../../../services/Service';
import { GET_DONORS } from '../../../../services/ApiUrls';
import DonorListItem from '../../components/DonorListItem';
import LoadMoreBtn from '../../../../common/ui/load_more_btn/LoadMoreBtn';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../../../state/UIState';
import DefaultContentView from '../../../../common/ui/default_content_view/DefaultContentView';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';

const DonationDonorsTab = ({ initiativeId, initiativeDetailId }) => {
  const dispatch = useDispatch();
  const [donorsList, setDonorsList] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const [totalDonations, setTotalDonations] = useState(0);

  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [noMatchFlag, setNoMatchFlag] = useState(false);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.DONATION_DONOR_TAB_VISIT,
      initiativeId,
      ANALYTICS_ITEM_NAMES.MICROGIVE
    );
  }, [initiativeId]);

  const callDonationDetailApi = useCallback(
    async (id, loadMore) => {
      setNoMatchFlag(false);
      if (loadMore) {
        const params = {
          microgive_initiative_detail_id: initiativeDetailId,
          page_id: id,
        };
        try {
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          const apiResponse = await apiRequest(
            GET_DONORS + initiativeId + '/donors',
            REQUEST_TYPE.GET,
            params
          );
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              setTotalDonations(apiResponse.response.data.total_donations);
              if (apiResponse.response.data.donors.length > 0) {
                setDonorsList((oldArray) => [...oldArray, ...apiResponse.response.data.donors]);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
              } else {
                if (apiResponse.response.data.donors.length === 0 && pageId === 1) {
                  setNoMatchFlag(true);
                }
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(
            err,
            params,
            GET_DONORS + initiativeId + '/donors',
            NAVIGATION_ROUTES.MICRO_GIVE
          );
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      }
    },
    [dispatch, initiativeDetailId, initiativeId]
  );

  useEffect(() => {
    callDonationDetailApi(1, true);
  }, [callDonationDetailApi]);

  const onPressLoadMore = () => {
    callDonationDetailApi(pageId, loadMoreFlag);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.SEARCH_NO_RESULTS} />;
  };

  return (
    <div className="main-content-container">
      {totalDonations > 0 && (
        <div className="microgive-total-donors-text">
          {totalDonations === 1 ? `${totalDonations} Donor` : `${totalDonations} Donors`}
        </div>
      )}
      {pageId === 1 && noMatchFlag ? (
        emptyListView()
      ) : (
        <div>
          {donorsList.map((item, index) => {
            return <DonorListItem key={`${index}`} item={item} />;
          })}
          {loadMoreFlag && (
            <LoadMoreBtn
              onClick={onPressLoadMore}
              isLoading={progressLoadingState.isProgressLoading}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DonationDonorsTab;
