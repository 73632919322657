import React, { useCallback, useEffect, useState } from 'react';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { formatDateDDMonthYYYYTimeAMPM, showToast } from '../../utils/common';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { Modal } from 'react-bootstrap';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { saveProgressLoadingState } from '../../state/UIState';
import { ASSET_ACKNOWLEDGEMENT, GET_ASSETS } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAssetCommentCharacterLimit } from '../../state/MasterData';

const MyAssetDetailScreen = ({ history }) => {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showThankYouDialog, setShowThankYouDialog] = useState(false);
  const [error, setError] = useState(false);
  const [comments, setComments] = useState('');
  const [hasAsset, setHasAsset] = useState();
  const [assetId, setAssetId] = useState(null);
  const [acknowledgedData, setAcknowledgedData] = useState([]);
  const isCommentsEmpty = /^\s*$/.test(comments);
  const MAX_CHAR_ALLOWED = useSelector(selectAssetCommentCharacterLimit);
  const [assetDetails, setAssetDetails] = useState([]);
  const assetType = history?.location?.state?.assetType;
  const listId = history?.location?.state?.assetId;

  const dispatch = useDispatch();
  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.MY_ASSET_DETAIL, NAVIGATION_ROUTES.MY_ASSET_DETAIL);
  }, []);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.MY_ASSET_DETAIL, NAVIGATION_ROUTES.MY_ASSET_DETAIL);
    const storedAcknowledgedData = localStorage.getItem('acknowledgedData');
    if (storedAcknowledgedData) {
      const parsedAcknowledgedData = JSON.parse(storedAcknowledgedData);
      setAcknowledgedData(parsedAcknowledgedData);
    }
  }, []);

  const getAssetDetails = useCallback(async () => {
    try {
      setError(false);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const apiResponse = await apiRequest(GET_ASSETS, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          const data =
            apiResponse?.response?.data?.feed?.entitlements?.[history.location.state.index]
              ?.assets?.[history.location.state.ind]?.details ?? [];
          setAssetDetails(data);
        } else {
          setError(true);
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(err, {}, GET_ASSETS, NAVIGATION_ROUTES.MY_ASSET_DETAIL);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      setError(false);
    }
  }, [dispatch, history.location.state.ind, history.location.state.index]);

  useEffect(() => {
    getAssetDetails();
  }, [getAssetDetails]);

  const callAssetAcknowledgementApi = async () => {
    const _params = new URLSearchParams();
    _params.append('assetId', assetId);
    _params.append('comment', comments);
    _params.append('userHasAsset', hasAsset === true ? 'yes' : 'no');
    _params.append('asset_type', assetType);
    try {
      setError(false);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const apiResponse = await apiRequest(ASSET_ACKNOWLEDGEMENT, REQUEST_TYPE.POST, _params);
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          const data = apiResponse.response.data;
          setAcknowledgedData(data);
          await getAssetDetails();
          setShowThankYouDialog(true);
        } else {
          setError(true);
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(err, _params, ASSET_ACKNOWLEDGEMENT, NAVIGATION_ROUTES.MY_ASSET_DETAIL);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      setError(false);
    }
  };

  const confirmationDialog = () => {
    return (
      <div className="asset-confirmation-dialog">
        <div className="d-flex flex-row align-items-center justify-content-center">
          <div className="confirmation-modal-text mb-2">Confirmation</div>
        </div>
        <div className="common-divider-style mb-3"></div>
        <div className="row">
          <div className="asset-modal-text mr-3">Do you still hold this asset?</div>
          <div className="form-check ml-1 mr-4">
            <input
              className="form-check-input custom-radio"
              type="radio"
              name="flexRadioDefault"
              id="flexCheckDefault2"
              onClick={() => setHasAsset(true)}
              checked={hasAsset === true}
            />
            <label className="radio-yes-no-text" htmlFor="flexRadioDefault1">
              Yes
            </label>
          </div>
          <div className="form-check ml-2">
            <input
              className="form-check-input custom-radio"
              type="radio"
              name="flexRadioDefault"
              id="flexCheckDefault2"
              onClick={() => setHasAsset(false)}
              checked={hasAsset === false}
            />
            <label className="radio-yes-no-text" htmlFor="flexRadioDefault1">
              No
            </label>
          </div>
        </div>
        <div className="asset-confirmation-comments-text mb-2">Comments*</div>
        <div className="common-comment-box mb-2">
          <textarea
            name="description"
            id="description"
            rows="4"
            placeholder="Enter your comments"
            spellCheck="false"
            value={comments}
            onChange={(event) => setComments(event.target.value)}
          />
        </div>
        <div className="asset-button-padding row">
          <CustomButton
            buttonStyle="custom-asset-submit-btn mt-2 mr-4"
            disabled={
              hasAsset === undefined ||
              hasAsset === null ||
              comments === '' ||
              comments === undefined ||
              comments === null ||
              isCommentsEmpty
            }
            onClick={async () => {
              if (comments?.length > MAX_CHAR_ALLOWED) {
                showToast(`Maximum ${MAX_CHAR_ALLOWED} characters are allowed`);
              } else {
                await callAssetAcknowledgementApi();
                setShowConfirmationDialog(false);
                setHasAsset(null);
                setComments('');
              }
            }}>
            Submit
          </CustomButton>
          <CustomButton
            buttonStyle="custom-asset-cancel-btn mt-2"
            onClick={() => {
              setShowConfirmationDialog(false);
              setHasAsset(null);
              setComments('');
            }}>
            Cancel
          </CustomButton>
        </div>
      </div>
    );
  };

  const thankYouDialog = () => {
    return (
      <div className="asset-thankyou-dialog">
        <div className="d-flex flex-row align-items-center justify-content-center">
          <div className="confirmation-modal-text mb-2">Submitted Successfully</div>
        </div>
        <div className="common-divider-style mb-3"></div>
        <div className="asset-thanks-text mb-4">
          Thanks for the response. Assets team will contact you in case they have any query.
        </div>
        <div className="d-flex flex-row align-items-center justify-content-center">
          <CustomButton
            buttonStyle="custom-asset-submit-btn mt-2"
            onClick={() => {
              setShowThankYouDialog(false);
            }}>
            Ok
          </CustomButton>
        </div>
      </div>
    );
  };

  const renderAssetDetail = (assetData, index) => {
    return (
      <div key={index} className="my-profile-asset-detail-container">
        <div
          className={
            (assetData.allowed_to_acknowledge === true ||
              (assetData.last_acknowledged_on && assetData.last_acknowledged_on !== null)) &&
            'asset-detail-text-container mb-4'
          }>
          <div className="asset-text-container row">
            <p className="my-profile-asset-detail-secondary-text mr-1">Asset type: </p>
            <p className="my-profile-asset-detail-main-text">{history.location.state.assetType}</p>
          </div>
          {listId !== 369 && (
            <div className="asset-text-container row">
              <p className="my-profile-asset-detail-secondary-text mr-1">Asset issued on:</p>
              <p className="my-profile-asset-detail-main-text">
                {formatDateDDMonthYYYYTimeAMPM(assetData.asset_issued_on)}
              </p>
            </div>
          )}
          {listId !== 369 && (
            <p className="my-profile-asset-detail-secondary-text">Model: {assetData.model}</p>
          )}
          {listId !== 369 && (
            <p className="my-profile-asset-detail-secondary-text">
              Manufacturer: {assetData.manufacturer}
            </p>
          )}
          <p className="my-profile-asset-detail-secondary-text">
            Serial Number: {assetData.serial_number}
          </p>
          {listId !== 369 && (
            <div className="asset-text-container row">
              <p className="my-profile-asset-detail-secondary-text mr-1">Tag ID:</p>
              <p className="my-profile-asset-detail-main-text">{assetData.AssetNumber}</p>
            </div>
          )}
          {listId !== 369 && (
            <p className="my-profile-asset-detail-secondary-text">
              Issued Location: {assetData.issued_location}
            </p>
          )}
          {listId !== 369 && (
            <p className="my-profile-asset-detail-secondary-text">Add ons: {assetData.add_ons}</p>
          )}
        </div>
        {assetData.allowed_to_acknowledge === true ? (
          <div className="mb-2 d-flex justify-content-between">
            <div className="asset-confirmation-container row">
              <div className="asset-confirmation-text-style mr-1">
                Do you still hold this asset? Please
              </div>
              <div
                className="click-here-text common-cursor-pointer mr-1"
                onClick={() => {
                  setShowConfirmationDialog(true);
                  setAssetId(assetData.AssetId);
                }}>
                click here
              </div>
              <div className="asset-confirmation-text-style">to confirm.</div>
            </div>
            {assetData.last_acknowledged_on && assetData.last_acknowledged_on !== null && (
              <div className="row asset-last-confirmed-container mr-1">
                <div className="asset-confirmation-text-style mr-1">Last confirmed on:</div>
                <div className="asset-last-confirmed-date-style">
                  {formatDateDDMonthYYYYTimeAMPM(assetData.last_acknowledged_on)}
                </div>
              </div>
            )}
          </div>
        ) : (
          assetData.last_acknowledged_on &&
          assetData.last_acknowledged_on !== null && (
            <div className="d-flex flex-row justify-content-end asset-last-confirmed-container mr-1 mb-2 w-100">
              <div className="asset-confirmation-text-style mr-1">Last confirmed on:</div>
              <div className="asset-last-confirmed-date-style">
                {formatDateDDMonthYYYYTimeAMPM(assetData.last_acknowledged_on)}
              </div>
            </div>
          )
        )}
      </div>
    );
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.PROFILE} />
      <BackButton />
      {assetDetails.map((assetData, index) => renderAssetDetail(assetData, index))}
      <Modal
        contentClassName="modal-style"
        show={showConfirmationDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setHasAsset(null);
          setComments('');
          setShowConfirmationDialog(false);
        }}>
        <Modal.Body>{confirmationDialog()}</Modal.Body>
      </Modal>
      <Modal
        contentClassName="modal-style"
        show={showThankYouDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowThankYouDialog(false)}>
        <Modal.Body>{thankYouDialog()}</Modal.Body>
      </Modal>
    </div>
  );
};

export default MyAssetDetailScreen;
