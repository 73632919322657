import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ACTION_TYPES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { selectCurrentPointsData } from '../../state/CheersData';
import { selectEmployeeData } from '../../state/EmployeeData';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';

const PointsRedeemedScreen = ({ location, history }) => {
  const dispatch = useDispatch();
  const { points, data } = location.state;
  const [countText, setCountText] = useState();

  const employeeData = useSelector(selectEmployeeData);
  const accessibilityData = useSelector(selectAccessibilityData);
  const currentPoints = useSelector(selectCurrentPointsData);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.POINTS_REDEEMED_SCREEN,
      NAVIGATION_ROUTES.POINTS_REDEEMED_SCREEN
    );
  }, []);

  useEffect(() => {
    setCountText(currentPoints);
  }, [currentPoints]);

  useEffect(() => {
    dispatch({ type: ACTION_TYPES.GET_CHEERS_POINTS, payload: { type: 'current' } });
  }, [dispatch]);

  const onPressCheersHome = () => {
    history.go(-2);
  };

  const onPressClickHere = () => {
    if (data.order_detail.redeem_amazon) {
      if (data.order_detail.order && Object.keys(data.order_detail.order).length > 0) {
        history.replace(NAVIGATION_ROUTES.REWARD_DETAILS_SCREEN, {
          cardNumber: data.order_detail.order.cardnumber,
          orderId: data.order_detail.order.order_id,
          cardPrice: data.order_detail.order.card_price,
          expiry: data.order_detail.order.expiry_date,
          pin: data.order_detail.order.pin_or_url,
          redeemAmazon: data.order_detail.redeem_amazon,
          message: data.order_detail.message,
        });
      } else {
        history.replace(NAVIGATION_ROUTES.REWARD_DETAILS_SCREEN, {
          redeemAmazon: data.order_detail.redeem_amazon,
          message: data.order_detail.message,
        });
      }
    } else {
      history.navigate(NAVIGATION_ROUTES.REWARD_DETAILS_SCREEN, {});
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CHEERS} />
      <BackButton />
      <div className="main-content-container">
        <div className="cheers-team-micro-view">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_SUCCESS,
              ACCESSIBILITY_IDENTIFIERS.CHEERS
            )}
            className="cheers-success-icon"
          />
        </div>
        <div className="text-center mt-3 cheers-register-message-text">{`${points} Points have been redeemed`}</div>
        <div className="text-center mt-3 cheers-register-message-text">Available Points</div>
        <div className="text-center mt-3 cheers-register-message-text">{countText}</div>
        {employeeData.geography.abbreviation === 'IND' && (
          <div className="cheers-click-here mt-3 text-center" onClick={onPressClickHere}>
            Click here to see
          </div>
        )}
        {employeeData.geography.abbreviation !== 'IND' && (
          <div>
            <div className="mt-3 text-center cheers-voucher-text">
              You will receive an Amazon-E-Gift voucher from Cheers Team shortly.
            </div>
            <div className="mt-3 text-center cheers-any-query ">
              For any queries or clarifications, please raise a ticket in Ask Microland or write to
            </div>
            <div className="mt-3 text-center cheers-email-text">Cheers@microland.com</div>
          </div>
        )}
        <CustomButton buttonStyle="cheers-button-style mt-3" onClick={onPressCheersHome}>
          Cheers Home
        </CustomButton>
      </div>
    </div>
  );
};

export default PointsRedeemedScreen;
