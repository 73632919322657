import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import { GET_POLICIES_BY_SUB_CATEGORY } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getHeaderComponentUrl } from '../../../utils/accessibility';
import { useHistory } from 'react-router';

const SubCategoriesList = ({ onPressItem, item, index, geographyId, categoryId }) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const [policies, setPolicies] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleSunCategory = useCallback(async () => {
    if (item.selected) {
      const params = {
        sub_category_id: item.sub_category_id,
        category_id: categoryId,
        geography_id: geographyId,
      };
      try {
        setLoading(true);
        const apiResponse = await apiRequest(
          GET_POLICIES_BY_SUB_CATEGORY,
          REQUEST_TYPE.GET,
          params
        );
        setLoading(false);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.data.feeds.length > 0) {
              setPolicies(apiResponse.response.data.feeds);
            } else {
              setPolicies([]);
            }
          }
        }
      } catch (err) {
        handleError(err, params, GET_POLICIES_BY_SUB_CATEGORY, NAVIGATION_ROUTES.POLICIES);
        setLoading(false);
      }
    } else {
      setPolicies([]);
    }
  }, [item, geographyId, categoryId]);

  useEffect(() => {
    handleSunCategory();
  }, [item, index, geographyId, categoryId, handleSunCategory]);

  const RenderPolicy = (itemData) => {
    return (
      <div
        className="mb-3 common-cursor-pointer margin-left-policy policy-title-hover divider-policies"
        onClick={() => {
          history.push(`${NAVIGATION_ROUTES.POLICY_DETAIL_SCREEN}/${itemData.policy.policy_id}`);
        }}>
        <div className="policy-date-view-style justify-content-between">
          <p className="policy-title">{itemData.policy.title}</p>
          <img
            src={getHeaderComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_RIGHT_ARROW,
              ACCESSIBILITY_IDENTIFIERS.COMMON
            )}
            className="policy-icon"
          />
        </div>
      </div>
    );
  };

  return (
    <div
      className={`mb-3 common-cursor-pointer ${item.selected ? '' : 'sub-category-title-hover'}`}>
      <div
        className="policy-date-view-style justify-content-between divider-policies"
        onClick={() => {
          onPressItem(item, index);
        }}>
        <p className={item.selected ? 'policy-title-selected' : 'policy-title'}>{item.title}</p>
        <img
          src={getHeaderComponentUrl(
            accessibilityData,
            item.selected
              ? ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_UP_ARROW
              : ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_DOWN_ARROW,
            ACCESSIBILITY_IDENTIFIERS.COMMON
          )}
          className="policy-icon"
        />
      </div>
      {item.selected ? (
        <div style={{ marginTop: 17 }}>
          <ActivityLoader visible={loading} />
          {policies.length > 0 ? (
            policies.map((policy, index) => <RenderPolicy policy={policy} key={index} />)
          ) : (
            <div>
              <p className={`common-default-content-view-text common-ws-pre-line img-center`}>
                {loading ? LOADING_MESSAGES.DEFAULT : NO_DATA_TEXT.NO_POLICIES_FOUND}
              </p>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default SubCategoriesList;
