import { EditorState, convertFromRaw, convertToRaw, genKey } from 'draft-js';
export const getMentionsState = (stateDescription, stateMentions) => {
  let description = stateDescription;

  const entityMap = {};

  const blocks = [];
  description.split('<br />').map((brokenLine) => {
    const ranges = [];
    let line = brokenLine.replace(/\s+/g, ' ');
    line = line.replaceAll(/(<([^>]+)>)/gi, '');

    line = line.replace(/#[0-9a-zA-Z]+/g, (x) => x);

    line = line.replace(/{{/gi, '');
    line = line.replace(/}}/gi, '');
    line = line.charAt(0) === ' ' ? line.slice(1) : line;

    const des = line.split(' ');

    stateMentions.map((employee) => {
      if (line.includes(employee.employee_id)) {
        line = line.replace(employee.employee_id, employee.name);
      }
    });

    stateMentions.map((employee) => {
      if (line.includes(employee.name)) {
        let pos = line.indexOf(employee.name);

        while (pos !== -1) {
          ranges.push({
            offset: pos,
            length: employee.name.length,
            key: 0,
          });
          pos = line.indexOf(employee.name, pos + employee.name.length);
        }

        entityMap[Object.keys(entityMap).length] = {
          type: '#mention',
          mutability: 'IMMUTABLE',
          data: {
            mention: {
              type: 'mention',
              name: employee.name,
              employee_id: employee.employee_id,
            },
          },
        };
      }
    });

    des.map((word, index) => {
      if (word.startsWith('#')) {
        let pos = line.indexOf(word);

        while (pos !== -1) {
          ranges.push({
            offset: pos,
            length: word.length,
            key: 0,
          });
          pos = line.indexOf(word, pos + word.length);
        }

        entityMap[Object.keys(entityMap).length] = {
          type: '#mention',
          mutability: 'IMMUTABLE',
          data: {
            mention: {
              type: 'hashtag',
              name: word,
            },
          },
        };
      }
    });

    blocks.push({
      key: genKey(),
      text: line,
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: ranges,
      data: {},
    });
  });

  const raw = {
    blocks,
    entityMap: entityMap,
  };

  return EditorState.createWithContent(convertFromRaw(raw));
};
const extractUsername = (text) => {
  const regex = /@\[([^[\]]+)\]\([^)]+\)/g;
  const matches = text.match(regex);
  if (matches) {
    return text.replace(regex, (match) => {
      const username = match.match(/@\[([^[\]]+)\]/)[1];
      return `<span style="color: blue;">@${username}</span>`;
    });
  }
  return text;
};

export const getMentionsStateForChat = (stateDescription, stateMentions, editorState) => {
  let description = stateDescription;
  let newState = convertToRaw(editorState.getCurrentContent());
  const entityMap = {};

  const blocks = [];
  description.split('<br />').map((brokenLine) => {
    const ranges = [];
    let line = brokenLine.replace(/\s+/g, ' ');
    line = line.replaceAll(/(<([^>]+)>)/gi, '');
    line = line.replace(/#[0-9a-zA-Z]+/g, (x) => x);
    line = line.replace(/{{/gi, '');
    line = line.replace(/}}/gi, '');
    line = line.charAt(0) === ' ' ? line.slice(1) : line;
    let mentionPattern = /@\[.*?\]\(uid-\d+\)/g;
    let placeholder = '@mention_placeholder';
    let textWithoutMentions = line.replace(mentionPattern, placeholder);
    let des = textWithoutMentions.split(/\s+/);
    des = des.map((word) => (word === placeholder ? line.match(mentionPattern)[0] : word));
    des = des.filter((word) => word !== '');
    if (stateMentions.length > 0) {
      stateMentions.map((employee) => {
        if (line.includes(`@[${employee.name}](${employee.uid})`)) {
          line = line.replace(`@[${employee.name}](${employee.uid})`, `@${employee.name}`);
        }
      });

      stateMentions.map((employee) => {
        if (line.includes(employee.name)) {
          let pos = line.indexOf(employee.name);

          while (pos !== -1) {
            ranges.push({
              offset: pos,
              length: employee.name.length + 1,
              key: 0,
            });
            pos = line.indexOf(employee.name, pos + employee.name.length);
          }

          entityMap[Object.keys(entityMap).length] = {
            type: '#mention',
            mutability: 'IMMUTABLE',
            data: {
              mention: {
                name: employee.name,
                uid: employee.uid,
                avatar: employee.avatar,
              },
            },
          };
        }
      });
    }

    blocks.push({
      key: genKey(),
      text: line,
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: ranges,
      data: {},
    });
  });

  const raw = {
    blocks,
    entityMap: newState.entityMap,
  };

  return EditorState.createWithContent(convertFromRaw(raw));
};
export const getMentionsStateForChatEdit = (stateDescription, stateMentions) => {
  let description = stateDescription;

  const entityMap = {};

  const blocks = [];
  if (typeof description === 'string') {
    description.split('\n').map((brokenLine) => {
      const ranges = [];
      let line = brokenLine.replace(/\s+/g, ' ');
      line = line.replaceAll(/(<([^>]+)>)/gi, '');

      line = line.replace(/#[0-9a-zA-Z]+/g, (x) => x);

      line = line.replace(/{{/gi, '');
      line = line.replace(/}}/gi, '');
      line = line.charAt(0) === ' ' ? line.slice(1) : line;
      let mentionPattern = /@\[.*?\]\(uid-\d+\)/g;
      let placeholder = '@mention_placeholder';
      let textWithoutMentions = line.replace(mentionPattern, placeholder);
      let des = textWithoutMentions.split(/\s+/);
      des = des.map((word) => (word === placeholder ? line.match(mentionPattern)[0] : word));
      des = des.filter((word) => word !== '');
      if (stateMentions.length > 0) {
        stateMentions.map((employee) => {
          let mentionPattern = new RegExp(`@\\[${employee.name}\\]\\(${employee.uid}\\)`, 'g');
          line = line.replace(mentionPattern, `${employee.name}`);
        });

        stateMentions.map((employee) => {
          if (line.includes(employee.name)) {
            let pos = line.indexOf(employee.name);

            while (pos !== -1) {
              ranges.push({
                offset: pos,
                length: employee.name.length + 1,
                key: 0,
              });
              pos = line.indexOf(employee.name, pos + employee.name.length);
            }

            entityMap[Object.keys(entityMap).length] = {
              type: 'mention',
              mutability: 'IMMUTABLE',
              data: {
                mention: {
                  name: employee.name,
                  uid: employee.uid,
                  avatar: employee.avatar,
                },
              },
            };
          }
        });
      }

      blocks.push({
        key: genKey(),
        text: line,
        type: 'unstyled',
        depth: 0,
        inlineStyleRanges: [],
        entityRanges: ranges,
        data: {},
      });
    });
  }

  const raw = {
    blocks,
    entityMap: entityMap,
  };

  return EditorState.createWithContent(convertFromRaw(raw));
};

export const getCommentMentionsState = (stateDescription, stateMentions) => {
  let description = stateDescription;

  const entityMap = {};

  const blocks = [];
  description.split('\n').map((brokenLine) => {
    const ranges = [];
    let line = brokenLine.replace(/\s+/g, ' ');
    line = line.replaceAll(/(<([^>]+)>)/gi, '');

    line = line.replace(/#[0-9a-zA-Z]+/g, (x) => x);

    line = line.replace(/{{/gi, '');
    line = line.replace(/}}/gi, '');
    line = line.charAt(0) === ' ' ? line.slice(1) : line;

    const des = line.split(' ');

    stateMentions.map((employee) => {
      if (line.includes(employee.name)) {
        let pos = line.indexOf(employee.name);

        while (pos !== -1) {
          ranges.push({
            offset: pos,
            length: employee.name.length,
            key: 0,
          });
          pos = line.indexOf(employee.name, pos + employee.name.length);
        }

        entityMap[Object.keys(entityMap).length] = {
          type: '#mention',
          mutability: 'IMMUTABLE',
          data: {
            mention: {
              type: 'mention',
              name: employee.name,
              employee_id: employee.employee_id,
            },
          },
        };
      }
    });

    des.map((word, index) => {
      if (word.startsWith('#')) {
        let pos = line.indexOf(word);

        while (pos !== -1) {
          ranges.push({
            offset: pos,
            length: word.length,
            key: 0,
          });
          pos = line.indexOf(word, pos + word.length);
        }

        entityMap[Object.keys(entityMap).length] = {
          type: '#mention',
          mutability: 'IMMUTABLE',
          data: {
            mention: {
              type: 'hashtag',
              name: word,
            },
          },
        };
      }
    });

    blocks.push({
      key: genKey(),
      text: line,
      type: 'unstyled',
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: ranges,
      data: {},
    });
  });

  const raw = {
    blocks,
    entityMap: entityMap,
  };

  return EditorState.createWithContent(convertFromRaw(raw));
};

export const extractDataFromEditorState = (editorState) => {
  const contentState = editorState.getCurrentContent();

  const raw = convertToRaw(contentState);
  let text = '';
  raw.blocks.map((block, index) => {
    text += `${index > 0 ? '\n' : ''}${block.text}`;
  });
  const addedHashtags = [];
  const addedMentions = [];

  Object.values(raw.entityMap).map((obj) => {
    if (obj.data && obj.data?.mention?.type === 'mention') {
      addedMentions.push(obj.data.mention);
    } else {
      addedHashtags.push(obj.data.mention);
    }
  });
  return { description: text, addedHashtags: addedHashtags, addedMentions: addedMentions };
};
