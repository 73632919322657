import { createSlice } from '@reduxjs/toolkit';

const saveContestDataReducer = (state, action) => {
  state.contests = action.payload;
};

// REDUCER
const anniversaryDataSlice = createSlice({
  name: 'anniversaryData',
  initialState: {
    contests: [],
  },
  reducers: {
    saveContestData: saveContestDataReducer,
  },
});

// ACTIONS
const { saveContestData } = anniversaryDataSlice.actions;

// SELECTOR
const selectContestData = ({ anniversaryData }) => anniversaryData.contests;

const anniversaryDataSliceReducer = anniversaryDataSlice.reducer;

export { anniversaryDataSliceReducer, saveContestData, selectContestData };
