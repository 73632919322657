import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { EMPLOYEES } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { saveProgressLoadingState } from '../../../state/UIState';
import { getHeaderComponentUrl } from '../../../utils/accessibility';

const AllEmployeeAnniversary = ({ onEmployeeSelected, type }) => {
  const dispatch = useDispatch();

  const [employeesList, setEmployeesList] = useState([]);
  const pageIdRef = useRef(1);
  const loadMoreFlagRef = useRef(true);
  const [searchText, setSearchText] = useState('');
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);

  const accessibilityData = useSelector(selectAccessibilityData);

  const getEmployees = useCallback(
    async (text) => {
      if (loadMoreFlagRef.current === true) {
        const params = {
          page_id: pageIdRef.current,
          query: text,
          geography_id: '',
          function_id: '',
          level_id: '',
          location_id: '',
          layer_id: '',
          range: '',
        };
        try {
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          const apiResponse = await apiRequest(EMPLOYEES, REQUEST_TYPE.GET, params);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                setEmployeesList((prev) => [...prev, ...apiResponse.response.data.feeds]);
                pageIdRef.current = pageIdRef.current + 1;
                loadMoreFlagRef.current = true;
              } else {
                loadMoreFlagRef.current = false;
              }
            }
          }
        } catch (err) {
          handleError(err, params, EMPLOYEES, NAVIGATION_ROUTES.ANNIVERSARY_SCREEN);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      } else {
        loadMoreFlagRef.current = false;
      }
    },
    [dispatch]
  );

  useEffect(() => {
    getEmployees();
  }, [getEmployees]);

  const loadMoreHandler = () => {
    getEmployees('');
  };

  const onSearchSubmitHandler = (event) => {
    event.preventDefault();
    setEmployeesList([]);
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    getEmployees(searchText);
  };

  const onTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const onClickSearchHandler = () => {
    setIsSearchEnabled(true);
  };

  const resetSearchHandler = useCallback(() => {
    setIsSearchEnabled(false);
    setSearchText('');
    setEmployeesList([]);
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    getEmployees('');
  }, [getEmployees]);

  const showProfiles = () => {
    return employeesList.map((profile, index) => {
      return (
        <div
          key={`${index}`}
          className="anniversary-emp-image-div col-6 col-sm-3 col-lg-2"
          onClick={() => onEmployeeSelected(profile, type)}>
          <UserProfilePhoto
            imageBaseUrl={profile.profile_picture.base_url}
            imagePath={profile.profile_picture.image_path}
            employeeId={profile.employee_id}
            imageClass="common-user-profile-photo-xl"
          />
          <p className="anniversary-profile-name">{profile.first_name}</p>
          <p className="anniversary-profile-name">{profile.last_name}</p>
        </div>
      );
    });
  };

  return (
    <div className="mt-3 mb-3">
      <div className="header-view d-flex justify-content-between">
        {isSearchEnabled ? (
          <form className="microgive-search-form" onSubmit={onSearchSubmitHandler}>
            <input
              autoFocus
              className="ml-3 microgive-search-bar"
              type="text"
              placeholder="Search.."
              onChange={onTextChange}
              value={searchText}
            />
          </form>
        ) : (
          <div />
        )}
        {isSearchEnabled ? (
          <div onClick={resetSearchHandler}>
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
              )}
              className="header-icon mr-3"
            />
          </div>
        ) : (
          <div onClick={onClickSearchHandler}>
            <img
              src={getHeaderComponentUrl(accessibilityData, ACCESSIBILITY_HEADER_COMPONENTS.SEARCH)}
              className="header-icon mr-3"
            />
          </div>
        )}
      </div>
      <div className="anniversary-employee-view mt-2">
        <div className="row">{showProfiles()}</div>
        <div className="load-more">
          <LoadMoreBtn onClick={loadMoreHandler} />
        </div>
      </div>
    </div>
  );
};

export default AllEmployeeAnniversary;
