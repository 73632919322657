import React from 'react';
import { getUrlWithSpecificRendition } from '../../../utils/common';
import { SCREEN_DENSITY_QUALIFIERS } from '../../../common/constants/AppConstants';

const InfoImageItem = ({ item, baseUrl }) => {
  return (
    <div className="col-sm-4">
      <img
        src={getUrlWithSpecificRendition(
          baseUrl,
          SCREEN_DENSITY_QUALIFIERS.HDPI,
          item.picture_path
        )}
        className="mb-3 embed-responsive embed-responsive-16by9"
      />
    </div>
  );
};

export default InfoImageItem;
