import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import { getEmployeeCode, getMasterData } from './ReduxState';

const trackScreen = async (screenName) => {
  if (Object.keys(getMasterData()).length > 0) {
    firebase.analytics().setCurrentScreen(screenName);
  }
};

const logEvent = async (eventType, itemId, itemName, contentType = '') => {
  let itemObject = {
    item_id: itemId,
    item_name: itemName,
    emp_code: getEmployeeCode(),
    content_type: contentType,
  };
  if (Object.keys(getMasterData()).length > 0) {
    firebase.analytics().logEvent(eventType, itemObject);
  }
};

const logEvent2 = async ({
  eventType,
  itemId,
  itemName,
  categoryId = '',
  categoryName = '',
  contentType = '',
}) => {
  let itemObject = {
    item_id: itemId,
    item_name: itemName,
    emp_code: getEmployeeCode(),
    category_id: categoryId,
    category_name: categoryName,
    content_type: contentType,
  };
  if (Object.keys(getMasterData()).length > 0) {
    firebase.analytics().logEvent(eventType, itemObject);
  }
};

// interaction between employees
const logEvent3 = async (eventType, from, to, contentType = '') => {
  let itemObject = {
    from_employee_id: from,
    to_employee_id: to,
    emp_code: getEmployeeCode(),
    content_type: contentType,
  };
  firebase.analytics().logEvent(eventType, itemObject);
};

const logEventFilters = async (eventType, itemId, itemName, contentType = '') => {
  let itemObject = {
    item_id: itemId,
    item_name: itemName,
    emp_code: getEmployeeCode(),
    filter_from: contentType,
  };
  if (Object.keys(getMasterData()).length > 0) {
    firebase.analytics().logEvent(eventType, itemObject);
  }
};

const setUserId = async () => {
  if (Object.keys(getMasterData()).length > 0) {
    firebase.analytics().setUserId(getEmployeeCode());
  }
};

export { trackScreen, logEvent, logEventFilters, setUserId, logEvent2, logEvent3 };
