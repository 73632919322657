import React from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';

const ProfileContactView = (props) => {
  const {
    isSmsDisabled = false,
    onSmsPress,
    isVideoCallDisabled = false,
    onCallPress,
    isEmailDisabled = false,
    emailRedirect,
    isMeetDisabled = false,
    scheduleMeetRedirect,
  } = props;
  const accessibilityData = useSelector(selectAccessibilityData);

  /**
   * Common component for rendering an action on profile with following attributes
   * @param {*} title
   * @param {*} actionIcon
   * @param {*} onPress
   * @param {*} isDisabled
   * @returns
   */
  const renderAction = (title, actionIcon, onPress, isDisabled = false) => (
    <div onClick={!isDisabled && onPress}>
      <div className="col-4 col-sm-4 d-flex flex-column align-items-center common-profile-contact-icon-border common-cursor-pointer">
        <div className="common-profile-contact-icon-container">
          <img src={actionIcon} className="common-profile-call-icon" />
        </div>
        <div className="common-profile-contact-icon-text">{title}</div>
      </div>
    </div>
  );

  return (
    <div className="d-flex common-profile-contact-row">
      {/* Hiding sms icon temporarily */}
      {/* {renderAction(
        'Message',
        getFeatureComponentUrl(
          accessibilityData,
          isSmsDisabled
            ? ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_SMS_DISABLED
            : ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_SMS,
          ACCESSIBILITY_IDENTIFIERS.PROFILE
        ),
        onSmsPress,
        isSmsDisabled
      )} */}
      {/* Hiding Video icon temporarily */}
      {/* {renderAction(
        'Video',
        getFeatureComponentUrl(
          accessibilityData,
          isVideoCallDisabled
            ? ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_VIDEO_CALL_DISABLED
            : ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_VIDEO_CALL,
          ACCESSIBILITY_IDENTIFIERS.PROFILE
        ),
        onCallPress,
        isVideoCallDisabled
      )} */}
      {renderAction(
        'Email',
        getFeatureComponentUrl(
          accessibilityData,
          isEmailDisabled
            ? ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_EMAIL_DISABLED
            : ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_MAIL_BLUE,
          ACCESSIBILITY_IDENTIFIERS.PROFILE
        ),
        emailRedirect,
        isEmailDisabled
      )}
      {renderAction(
        'Schedule Meeting',
        getFeatureComponentUrl(
          accessibilityData,
          isMeetDisabled
            ? ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_CALENDER_OUTLINE_GREY
            : ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_CALENDER_BLUE,
          ACCESSIBILITY_IDENTIFIERS.PROFILE
        ),
        scheduleMeetRedirect,
        isMeetDisabled
      )}
    </div>
  );
};

export default ProfileContactView;
