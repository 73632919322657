import React from 'react';
import CustomProgressBar from '../../../common/ui/custom_progress_bar/CustomProgressBar';

const FundraiserDetails = ({ event = null }) => {
  const getNoOfDaysLeftText = (daysLeft = 1) => {
    if (daysLeft === 0 || daysLeft === 1) {
      return (
        <div className="microgive-fundraiser-faded-text">
          <span className="microgive-fundraiser-bold-text">1</span> day left
        </div>
      );
    } else {
      return (
        <div className="microgive-fundraiser-faded-text">
          <span className="microgive-fundraiser-bold-text">{daysLeft}</span> days left
        </div>
      );
    }
  };

  return (
    <>
      {event && (
        <div className="microgive-fundraiser-container microgive-row-style">
          <div className="microgive-fundraiser-text-wrapper microgive-fundraiser-faded-text">
            <div className="microgive-fundraiser-bold-text ">{`INR ${event?.raised_fund?.toLocaleString(
              'en-IN'
            )}`}</div>
            <div>
              raised of{' '}
              <span className="microgive-fundraiser-normal-text">{`INR ${event?.target_fund.toLocaleString(
                'en-IN'
              )}`}</span>{' '}
              goal
            </div>
          </div>
          <div className="microgive-progress-bar-wrapper">
            <CustomProgressBar
              visible={true}
              selectedChecklistItemCount={
                event?.raised_fund > event?.target_fund ? event?.target_fund : event?.raised_fund
              }
              totalChecklistItemCount={event?.target_fund}
              progressBarStyle="microgive-polls-progress-bar-style"
              progressBarInnerStyle={'microgive-progress-bar-inner-style'}></CustomProgressBar>
            <div className="microgive-row-style">
              <div className="microgive-fundraiser-faded-text">
                <span className="microgive-fundraiser-bold-text">{event?.no_of_donors}</span> donors
              </div>
              {getNoOfDaysLeftText(event?.no_of_days_left)}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FundraiserDetails;
