import React, { useCallback, useEffect, useState } from 'react';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { useDispatch, useSelector } from 'react-redux';
import { saveProgressLoadingState } from '../../state/UIState';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { AUCTION_TC, PLACE_BID } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import HtmlView from '../../common/ui/html_view/HTMLView';
import { Modal } from 'react-bootstrap';

const MiAuctionTCScreen = ({ history, location }) => {
  const { auctionDetails } = location.state;
  const dispatch = useDispatch();
  const [termsAndConditions, setTermsAndConditions] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.MI_AUCTION_TC);
  }, []);

  const callAuctionsTearmsAndConditions = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequest(AUCTION_TC, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setTermsAndConditions(apiResponse.response.terms);
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(err, {}, AUCTION_TC, NAVIGATION_ROUTES.MI_AUCTION_TC);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    callAuctionsTearmsAndConditions();
  }, [callAuctionsTearmsAndConditions]);

  const placeBid = async () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.AUCTION_MAKE_BID_BUTTON,
      auctionDetails.item_id.toString(),
      'MAKE_BID_TC_SCREEN'
    );
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const formData = new URLSearchParams();
      formData.append('item_id', auctionDetails.item_id);
      formData.append('amount', auctionDetails.next_bid);
      formData.append('currency_id', auctionDetails.currency.currency_id);
      const apiResponse = await apiRequest(PLACE_BID, REQUEST_TYPE.POST, formData);
      if (Object.keys(apiResponse).length > 0 && apiResponse.response?.status) {
        setShowSuccessModal(true);
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  const goToAuctionsList = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.AUCTION_OK_THANKYOU_BUTTON,
      auctionDetails.item_id.toString(),
      'OK_TC_SCREEN'
    );
    history.replace(`${NAVIGATION_ROUTES.MI_AUCTION}/${auctionDetails.item_id}`);
  };

  return (
    <div className="container">
      <SectionHeader title={'MiAUCTION'} />
      <Modal show={showSuccessModal} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div className="auction-thankyou-text">Thank You!!!</div>
            <div className="auction-success-message">
              Your bid of {auctionDetails.currency.symbol} {auctionDetails.next_bid} /- is submitted
              successfully.{' '}
            </div>
            <div
              className="auction-make-bid-button common-cursor-pointer"
              onClick={goToAuctionsList}>
              <div className="thankyou-ok-button-title">OK</div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {Object.keys(auctionDetails).length > 0 && (
        <div className="header-view justify-content-between">
          <BackButton
            itemId={auctionDetails.item_id}
            fromRoute={NAVIGATION_ROUTES.MI_AUCTION_TC}
            isFullRow={false}
          />
        </div>
      )}
      <div className="mi-auction-tc-details">
        <div>
          <div className="auction-thankyou-title">{auctionDetails.item_name}</div>
          <div className="auction-note-title">
            Your bid value:&nbsp;
            <span className="auction-thankyou-title">
              {auctionDetails.currency.symbol} {auctionDetails.next_bid} /-
            </span>
          </div>
        </div>
        <div className="auction-make-bid-button common-cursor-pointer" onClick={placeBid}>
          <div className="make-bid-button-title">I AGREE. PLACE MY BID</div>
        </div>
      </div>
      <div className="auction-terms-and-conditions">Terms and Conditions</div>
      <HtmlView html={termsAndConditions} htmlStyle="mi-auction-description-text" />
    </div>
  );
};

export default MiAuctionTCScreen;
