import React, { useEffect, useState } from 'react';
import ML35GalleryComponent from '../../anniversary/components/ML35GalleryComponent';
import { NAVIGATION_ROUTES } from '../../../common/constants/AppConstants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const MlDayGalleryItem = ({ item, onPress = () => {}, title, baseUrl, totalCount, identifier }) => {
  const [images, setImages] = useState([]);
  const [countLeft, setCountLeft] = useState(0);
  const history = useHistory();

  const gotoPictures = () => {
    history.push(NAVIGATION_ROUTES.ML_DAY_GALLERY_SCREEN, {
      identifier: identifier,
      title: title,
    });
  };

  useEffect(() => {
    if (totalCount < 7) {
      setImages(item);
      setCountLeft(0);
    } else {
      setImages(item.slice(0, 6));
      setCountLeft(totalCount - 6);
    }
  }, [item, totalCount]);
  return (
    <div>
      <div className={`ml-35-images-wrapper mb-2`}>
        {images.map((imageItem, index) => {
          return (
            <div key={`${index}`} style={{ position: 'relative' }}>
              <ML35GalleryComponent
                key={`${index}`}
                item={imageItem}
                index={index}
                fromRoute={'mldayGallery'}
                baseUrl={baseUrl}
              />
              {index === 5 && countLeft > 0 && (
                <div onClick={() => gotoPictures()} className="ml-35-more-photos-bg-view">
                  <div className="breakouts-count-left-text">{`+${countLeft} ${
                    countLeft === 1 ? 'photo' : 'photos'
                  }`}</div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MlDayGalleryItem;
