import React, { useState } from 'react';
import { getHeaderComponentUrl } from '../../../utils/accessibility';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { ACCESSIBILITY_HEADER_COMPONENTS } from '../../../common/constants/AppConstants';

const SearchComponent = ({ handleSubmitFunction }) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [searchText, setSearchText] = useState('');
  const onClickSearchHandler = () => {
    setIsSearchEnabled(true);
  };
  const onTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const resetValue = () => {
    setSearchText('');

    setIsSearchEnabled(false);
  };
  const [active, setActive] = useState('');

  const handleClick = (text) => {
    setActive(text);
  };

  const callSubmitFunction = () => {
    handleSubmitFunction(searchText);
  };
  return (
    <div
      className={
        isSearchEnabled ? 'search-bar-with-icon search-bar-border mr-3' : 'search-bar-with-icon'
      }>
      {isSearchEnabled ? (
        <form className="directory-search-bar" onSubmit={callSubmitFunction}>
          <input
            autoFocus
            className="ml-2 directory-search-bar"
            type="text"
            placeholder="Search.."
            onChange={onTextChange}
            value={searchText}
          />
        </form>
      ) : (
        <div className="directory-search-bar" />
      )}
      {isSearchEnabled ? (
        <div className="gcdo-icon-row mr-2" onClick={resetValue}>
          <img
            src={getHeaderComponentUrl(
              accessibilityData,
              ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
            )}
            className="header-icon"
          />
        </div>
      ) : (
        <div className="gcdo-icon-row mr-2">
          {/* <div style={{ display: 'flex', gap: '10px', paddingRight: '10px' }}>
            {['Video', 'Reels'].map((text, index) => (
              <React.Fragment key={text}>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClick(text);
                  }}
                  style={{
                    color: active === text ? 'darkblue' : 'lightblue',
                    cursor: 'pointer',
                    transition: 'color 0.3s',
                  }}>
                  {text}
                </div>
                {<span style={{ color: 'lightblue', fontSize: '20px' }}>|</span>}{' '}
              </React.Fragment>
            ))}
          </div> */}
          {/* <div className="">Video | Reels</div> */}
          <img
            onClick={onClickSearchHandler}
            src={getHeaderComponentUrl(accessibilityData, ACCESSIBILITY_HEADER_COMPONENTS.SEARCH)}
            className="header-icon"
          />
        </div>
      )}
    </div>
  );
};

export default SearchComponent;
