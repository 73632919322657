import React, { useState, useEffect, useCallback } from 'react';
import {
  ACTION_TYPES,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  FEATURE_TYPE,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../../../services/Service';
import { GET_MY_REWARDS, SUBMIT_LIKE_COMMENT_CHEERS } from '../../../../services/ApiUrls';
import { useDispatch, useSelector } from 'react-redux';
import ReceivedListItem from '../../components/ReceivedListItem';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectShowEmptyContentView,
} from '../../../../state/UIState';
import { useHistory } from 'react-router';
import LoadMoreBtn from '../../../../common/ui/load_more_btn/LoadMoreBtn';
import CustomButton from '../../../../common/ui/custom_button/CustomButton';
import { selectCurrentPointsData } from '../../../../state/CheersData';
import DefaultContentView from '../../../../common/ui/default_content_view/DefaultContentView';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../../common/ui/activity_loader/ActivityLoader';
import { selectAccessData } from '../../../../state/UserAccessData';

const ReceivedTab = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [cheersList, setCheersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const [pageId, setPageId] = useState(1);
  const [countText, setCountText] = useState('');

  const currentPoints = useSelector(selectCurrentPointsData);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const accessData = useSelector(selectAccessData);
  const [showLikeButton, setShowLikeButton] = useState(true);
  const [showCommentButton, setShowCommentButton] = useState(true);
  const cheersAccessData = accessData?.data?.find((item) => item.feature_key === '_cheers');

  useEffect(() => {
    if (cheersAccessData) {
      const actions = cheersAccessData?.actions;
      setShowLikeButton(actions?.includes('_like'));
      setShowCommentButton(actions?.includes('_comment'));
    }
  }, [cheersAccessData]);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.RECIEVED_TAB_VISIT, '', ANALYTICS_ITEM_NAMES.CHEERS);
  }, []);

  useEffect(() => {
    setCountText(`You have ${currentPoints} points in your account`);
  }, [currentPoints]);

  const callMyCheersApi = useCallback(
    async (pageid, loadMore) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        const params = {
          page_id: pageid,
          type: 'received',
        };
        try {
          setLoading(true);
          let apiResponse = await apiRequest(GET_MY_REWARDS, REQUEST_TYPE.GET, params);
          setLoading(false);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                setCheersList((oldArray) => [...oldArray, ...apiResponse.response.data.feeds]);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(err, params, GET_MY_REWARDS, NAVIGATION_ROUTES.CHEERS_SCREEN);
          setLoading(false);
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    callMyCheersApi(1, true);
    dispatch({ type: ACTION_TYPES.GET_CHEERS_POINTS, payload: { type: 'current' } });
  }, [callMyCheersApi, dispatch]);

  const onPressCommentButton = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.CHEERS_RECEIVED_COMMENT,
      item.cheer_employee_award_id,
      ANALYTICS_ITEM_NAMES.COMMENT
    );
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: item.cheer_employee_award_id,
      likeType: FEATURE_TYPE.CHEERS,
      fromRoute: NAVIGATION_ROUTES.CHEERS_SCREEN,
      type: 'received',
    });
  };

  const onPressLikeButton = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.CHEERS_RECEIVED_LIKE,
      item.cheer_employee_award_id,
      ANALYTICS_ITEM_NAMES.LIKE
    );
    callLikeApi(item.cheer_employee_award_id);
  };

  const callLikeApi = async (cheersEmployeeAwardId) => {
    const params = new URLSearchParams();
    params.append('type', FEATURE_TYPE.CHEERS);
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: 'Updating Like Count...',
        })
      );
      const apiResponse = await apiRequest(
        SUBMIT_LIKE_COMMENT_CHEERS + cheersEmployeeAwardId + '/like',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          updateLikeCount(cheersEmployeeAwardId);
        }
      }
    } catch (err) {
      handleError(
        err,
        params,
        SUBMIT_LIKE_COMMENT_CHEERS + cheersEmployeeAwardId + '/like',
        NAVIGATION_ROUTES.CHEERS_SCREEN
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const updateLikeCount = (cheersEmployeeAwardId) => {
    let arr = cheersList;
    let oldCount = arr.find((element) => element.cheer_employee_award_id === cheersEmployeeAwardId)
      .likes.count;
    let objIndex = arr.findIndex(
      (element) => element.cheer_employee_award_id === cheersEmployeeAwardId
    );
    let isLiked = arr[objIndex].likes.is_liked;
    arr[objIndex].likes.count = isLiked ? oldCount - 1 : oldCount + 1;
    arr[objIndex].likes.is_liked = !isLiked;
    setCheersList([...arr]);
  };

  const onPressOthers = (nominationsList) => {
    history.push(NAVIGATION_ROUTES.TAGGED_PEOPLE_SCREEN, {
      nominationsList: nominationsList,
      from: 'rewardCentral',
      title: 'Nominated by',
    });
  };

  const onPressLoadMore = () => {
    callMyCheersApi(pageId, loadMoreFlag);
  };

  const onPressMyPoints = () => {
    history.push(NAVIGATION_ROUTES.MY_POINTS_SCREEN);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_AWARD_TO_SHOW} />;
  };

  return (
    <div className="main-content-container">
      <div className="cheers-outer-view justify-content-between cheers-bottom-view mb-2">
        <div className="cheers-donate-points-text ml-2 mr-2">{countText}</div>
        <CustomButton buttonStyle="cheers-button-style mr-3 mt-1 mb-1" onClick={onPressMyPoints}>
          My points
        </CustomButton>
      </div>
      {cheersList.length > 0 && (
        <div className="cheers-reward-count mb-3">
          {cheersList.length === 1 ? `${cheersList.length} Reward` : `${cheersList.length} Rewards`}
        </div>
      )}
      {showEmptyContentView && !cheersList.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          {cheersList.map((item, index) => {
            return (
              <ReceivedListItem
                showLikeButton={showLikeButton}
                showCommentButton={showCommentButton}
                item={item}
                index={index}
                onPressCommentButton={onPressCommentButton}
                onPressLikeButton={onPressLikeButton}
                onPressOthers={onPressOthers}
                key={`${index}`}
              />
            );
          })}
          <ActivityLoader visible={loading} />
          {loadMoreFlag && <LoadMoreBtn onClick={onPressLoadMore} isLoading={loading} />}
        </div>
      )}
    </div>
  );
};

export default React.memo(ReceivedTab);
