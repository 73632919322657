import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SESSION_DETAIL_IDENTIFIERS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import { GET_AGENDA_SESSIONS, SUBMIT_BREAKOUT_RATING } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { formatDateDDMonthYYYY, showToast } from '../../../utils/common';
import AgendaTabListItem from '../components/AgendaTabListItem';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import ReactStars from 'react-rating-stars-component';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../../state/UIState';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import colors from '../../../common/themes/Colors';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import { saveBreakoutsSessionTabData } from '../../../state/TabData';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';

const AgendaTab = ({ breakoutId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [agendaList, setAgendaList] = useState([]);
  const [showRatingDialog, setShowRatingDialog] = useState(false);
  const [rating, setRating] = useState(0);
  const [selectedSession, setSelectedSession] = useState('');
  const [selectedSessionId, setSelectionSessionId] = useState('');
  const [dates, setDates] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [loading, setLoading] = useState(true);

  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.BREAKOUTS_AGENDA_TAB_VISIT,
      breakoutId,
      ANALYTICS_ITEM_NAMES.BREAKOUTS
    );
  }, [breakoutId]);

  const callAgendaSessionApi = useCallback(
    async (selectedDate) => {
      dispatch(saveShowEmptyContentView(true));
      const params = {
        date: selectedDate,
      };
      try {
        setLoading(true);
        const apiResponse = await apiRequest(
          GET_AGENDA_SESSIONS + breakoutId,
          REQUEST_TYPE.GET,
          params
        );
        setLoading(false);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.data.feeds.length > 0) {
              setAgendaList(apiResponse.response.data.feeds);
              if (dates.length === 0) {
                let pickerData = [];
                apiResponse.response.data.dates.forEach((element) => {
                  const formattedDate = formatDateDDMonthYYYY(element.date);
                  pickerData.push({
                    label: element.title + ' - ' + formattedDate,
                    value: element.date,
                  });
                  if (element.selected) {
                    setSelectedOption({
                      label: element.title + ' - ' + formattedDate,
                      value: element.date,
                    });
                  }
                });
                setDates(pickerData);
              }
            }
          }
        }
      } catch (err) {
        handleError(err, params, GET_AGENDA_SESSIONS + breakoutId, NAVIGATION_ROUTES.BREAKOUTS);
        setLoading(false);
      }
    },
    [breakoutId, dates.length, dispatch]
  );

  useEffect(() => {
    callAgendaSessionApi('');
  }, [callAgendaSessionApi]);

  const onPressRate = (listItem) => {
    setSelectedSession(listItem.title);
    setSelectionSessionId(listItem.session_id);
    setRating(0);
    setShowRatingDialog(true);
  };

  const onPressAskViewQues = (data, breakoutId) => {
    dispatch(saveBreakoutsSessionTabData(''));
    history.push(NAVIGATION_ROUTES.SESSION_DETAILS, {
      data: data,
      breakoutId: breakoutId,
      fromBreakoutsSessionDetailRoute: true,
      tabKey: SESSION_DETAIL_IDENTIFIERS.QUESTIONS,
      identifier: ACCESSIBILITY_IDENTIFIERS.BREAKOUTS,
    });
  };

  const onPressItem = (data, breakoutId) => {
    if (data.enable_speaker) {
      dispatch(saveBreakoutsSessionTabData(''));
      history.push(NAVIGATION_ROUTES.SESSION_DETAILS, {
        data: data,
        breakoutId: breakoutId,
        fromBreakoutsSessionDetailRoute: true,
        identifier: ACCESSIBILITY_IDENTIFIERS.BREAKOUTS,
      });
    }
  };

  const callSubmitRatingApi = async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: 'Submitting rating...',
        })
      );
      const params = new URLSearchParams();
      params.append('session_id', selectedSessionId);
      params.append('rate', Math.floor(rating));
      params.append('anonymous', false);
      const apiResponse = await apiRequest(SUBMIT_BREAKOUT_RATING, REQUEST_TYPE.POST, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          showToast(apiResponse.response.message);
          setShowRatingDialog(false);
          setAgendaList([]);
          callAgendaSessionApi(selectedOption ? selectedOption.value : '');
        }
      }
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressSubmit = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.BREAKOUTS_SUBMIT_SESSION_RATING,
      selectedSessionId.toString(),
      ANALYTICS_ITEM_NAMES.BREAKOUTS
    );
    callSubmitRatingApi();
  };

  const ratingCompleted = (ratingStar) => {
    setRating(ratingStar);
  };

  const ratingDialog = () => {
    return (
      <div>
        <div className="text-center breakouts-session-text">Session</div>
        <div className="text-center mt-2 breakouts-selected-session-text">{selectedSession}</div>
        <hr className="mt-3 mb-3" />
        <div className="text-center breakouts-your-rating-text">Your Rating</div>
        <div className="breakouts-team-micro-view ">
          <ReactStars count={5} onChange={ratingCompleted} size={40} activeColor={colors.gold} />
        </div>
        <CustomButton
          buttonStyle="breakouts-button-style mt-3"
          onClick={onPressSubmit}
          showLoader={progressLoadingState.isProgressLoading}>
          Submit
        </CustomButton>
      </div>
    );
  };

  const onValueChange = (selectOption) => {
    setSelectedOption(selectOption);
    callAgendaSessionApi(selectOption.value);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.AGENDA_MESSAGE} />;
  };

  return (
    <div className="main-content-container">
      <Modal
        show={showRatingDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowRatingDialog(false)}>
        <Modal.Body>{ratingDialog()}</Modal.Body>
      </Modal>
      <ActivityLoader visible={loading} />
      <Select
        value={selectedOption}
        onChange={onValueChange}
        options={dates}
        className="breakouts-dropdown"
        defaultValue={selectedOption}
        isSearchable={false}
      />
      {showEmptyContentView && !agendaList.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          {agendaList.map((item, index) => {
            return (
              <AgendaTabListItem
                key={`${index}`}
                listItem={item}
                breakoutId={breakoutId}
                onPressAskViewQues={onPressAskViewQues}
                onPressRate={onPressRate}
                onPressItem={() => onPressItem(item, breakoutId)}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AgendaTab;
