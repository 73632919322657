import { call, put, takeEvery } from 'redux-saga/effects';
import { ACTION_TYPES, REQUEST_TYPE } from '../common/constants/AppConstants';
import { ACCESS_CONTROL } from '../services/ApiUrls';
import { apiRequest } from '../services/Service';
import { saveAccessData } from '../state/UserAccessData';

export function* getAccess(action) {
  try {
    const apiResponse = yield call(apiRequest, ACCESS_CONTROL, REQUEST_TYPE.GET, action.payload);
    if (apiResponse) {
      if (apiResponse.response.status === true) {
        yield put(saveAccessData(apiResponse.response));
      }
    }
  } catch (e) {
    yield put(saveAccessData({}));
  }
}

export function* watchAccess() {
  yield takeEvery(ACTION_TYPES.GET_ACCESS, getAccess);
}
