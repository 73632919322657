import React from 'react';
import ConnectingBorder from './ConnectingBorder';
import EmployeeOrgView from './EmployeeOrgView';

const ManagerView = (props) => {
  return (
    <div
      style={props.mangerStyle}
      className="d-flex flex-row align-items-center common-profile-manager-container">
      {!props.hideBorder && <ConnectingBorder />}
      <EmployeeOrgView employee={props.employee} style="common-profile-org-manager-container" />
    </div>
  );
};

export default ManagerView;
