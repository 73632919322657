import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNeoSubTabTitles } from '../../../../state/MasterData';
import { saveProgressLoadingState } from '../../../../state/UIState';
import { apiRequest } from '../../../../services/Service';
import { ACCESSIBILITY_IDENTIFIERS, REQUEST_TYPE } from '../../../../common/constants/AppConstants';
import { NEO_SESSION_DETAIL } from '../../../../services/ApiUrls';
import NeoAgendaTab from './NeoAgendaTab';
import NeoDocumentsTab from '../NeoDocumentsTab';
import NeoAttendeesTab from '../NeoAttendeesTab';
import NeoPicturesTab from './NeoPicturesTab';
import NavTabs from '../../../../common/ui/tabs/NavTabs';
import { Tab } from 'react-bootstrap';
import { saveNeoTabData, selectNeoTabData } from '../../../../state/TabData';
import SectionHeader from '../../../../common/ui/section_header/SectionHeader';
import BackButton from '../../../../common/ui/common_back_button/BackButton';
import { useParams } from 'react-router-dom';

const NeoSessionDetailMainScreen = ({ location }) => {
  const [index, setIndex] = useState(0);
  const routes = useSelector(selectNeoSubTabTitles);
  const dispatch = useDispatch();
  const [sessionData, setSessionData] = useState();
  const [sessionDocuments, setSessionDocuments] = useState();
  const [sessionAttendees, setSessionAttendees] = useState();
  const [sessionPictures, setSessionPictures] = useState();
  const [documentLoadmore, setDocumentLoadmore] = useState(true);
  const [attendeeLoadmore, setAttendeeLoadmore] = useState(true);
  const [pictureLoadmore, setPictureLoadmore] = useState(true);
  const backTitle = location?.state?.backTitle ? location?.state?.backTitle : 'BACK';
  const neoTabData = useSelector(selectNeoTabData) || 'agenda';
  const neo_id = useParams().neoId;

  const callNeoSessionDetailApi = useCallback(
    async (page_id = 1) => {
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
          })
        );
        setAttendeeLoadmore(true);
        setDocumentLoadmore(true);
        setPictureLoadmore(true);
        const apiResponse = await apiRequest(NEO_SESSION_DETAIL + '/' + neo_id, REQUEST_TYPE.GET, {
          page_id: page_id,
        });
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            const data = apiResponse.response.data;
            setSessionData(data);
            if (data?.documents?.length === 0) {
              setDocumentLoadmore(false);
            }
            if (data?.attendees?.length === 0) {
              setAttendeeLoadmore(false);
            }
            if (data?.pictures?.length === 0) {
              setPictureLoadmore(false);
            }
            if (page_id > 1) {
              setSessionDocuments((prev) => [...prev, ...data?.documents]);
              setSessionAttendees((prev) => [...prev, ...data?.attendees]);
              setSessionPictures((prev) => [...prev, ...data?.pictures]);
            } else {
              setSessionDocuments(data?.documents);
              setSessionAttendees(data?.attendees);
              setSessionPictures(data?.pictures);
            }
          }
        }
      } catch (err) {
        console.error(err);
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    },
    [dispatch, neo_id]
  );

  useEffect(() => {
    callNeoSessionDetailApi();
  }, [callNeoSessionDetailApi]);

  const renderTabs = (tab) => {
    switch (tab.key) {
      case 'agenda':
        return (
          <NeoAgendaTab
            callNeoSessionDetailApi={callNeoSessionDetailApi}
            sessionData={sessionData}
            neo_id={neo_id}
          />
        );
      case 'documents':
        return (
          <NeoDocumentsTab
            documents={sessionDocuments}
            neo_id={neo_id}
            callNeoSessionDetailApi={callNeoSessionDetailApi}
            loadmoreFlag={documentLoadmore}
          />
        );
      case 'attendees':
        return (
          <NeoAttendeesTab
            neo_id={neo_id}
            attendees={sessionAttendees}
            callNeoSessionDetailApi={callNeoSessionDetailApi}
            loadmoreFlag={attendeeLoadmore}
          />
        );
      case 'pictures':
        return (
          <NeoPicturesTab
            callNeoSessionDetailApi={callNeoSessionDetailApi}
            neo_id={neo_id}
            pictures={sessionPictures}
            loadmoreFlag={pictureLoadmore}
          />
        );
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES} />
      <BackButton backTitle={backTitle ? backTitle : 'BACK'} />
      <NavTabs
        activeKey={neoTabData || 'agenda'}
        onSelect={(k) => {
          dispatch(saveNeoTabData(k));
        }}
        unmountOnExit>
        {routes.map((tab, index) => {
          return (
            <Tab key={`${index}`} eventKey={tab.key} title={tab.title}>
              {renderTabs(tab, index)}
            </Tab>
          );
        })}
      </NavTabs>
    </div>
  );
};

export default NeoSessionDetailMainScreen;
