import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  FILTER_IDENTIFIERS,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import Filter from '../../common/ui/filter/Filter';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';
import { GET_DIRECTORY_DATA, STARS_BY_ID } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import {
  saveProgressLoadingState,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../state/UIState';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import debounce from 'lodash.debounce';

const SomTagPeopleScreen = ({ location, history }) => {
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [error, setError] = useState(false);
  const params = location.state;
  const alreadySelectedList = params?.tags ?? [];
  const textInputRef = useRef();
  const pageIdRef = useRef(1);
  const appliedFiltersRef = useRef({});
  const searchTextRef = useRef('');
  const [dataLength, setDataLength] = useState(0);
  const loadMoreFlagRef = useRef(true);
  const dispatch = useDispatch();
  const [showLoadMore, setShowLoadMore] = useState(false);
  const [currentSelectedList, setCurrentSelectedList] = useState([]);

  useEffect(() => {
    if (pageIdRef.current !== 1) {
      loadMoreFlagRef.current = false;
    }
  }, [dataLength]);

  const addEmployee = (employee) => {
    if (currentSelectedList.findIndex((emp) => emp?.employee_id === employee?.employee_id) >= 0) {
      const index = currentSelectedList.findIndex(
        (emp) => emp?.employee_id === employee?.employee_id
      );

      if (index > -1) {
        setCurrentSelectedList(
          currentSelectedList.filter(
            (it) => it?.employee_id !== currentSelectedList[index]?.employee_id
          )
        );
      }
    } else {
      setCurrentSelectedList((prev) => [...prev, employee]);
    }
  };

  const deleteEmployee = (employee) => {
    if (currentSelectedList.includes(employee)) {
      const index = currentSelectedList.indexOf(employee);
      if (index > -1) {
        setCurrentSelectedList(
          currentSelectedList.filter(
            (it) => it?.employee_id !== currentSelectedList[index]?.employee_id
          )
        );
      }
    }
  };

  const debouncedCallback = (event) => {
    if (event && event.target.value.length > 2) {
      pageIdRef.current = 1;
      loadMoreFlagRef.current = true;
      callEmployees(event.target.value);
    }
    searchTextRef.current = event.target.value;
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );

  const showEmptyListView = () => {
    return (
      <DefaultContentView
        message={NO_DATA_TEXT.DEFAULT}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  const callEmployees = useCallback(
    async (text = '') => {
      if (loadMoreFlagRef.current) {
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        setError(false);
        if (pageIdRef.current == 1) {
          setEmployeeData([]);
        }
        let params = { page_id: pageIdRef.current };

        params = { ...params, ...{ query: text } };

        if (Object.keys(appliedFiltersRef.current).length) {
          params = { ...params, ...appliedFiltersRef.current };
        }
        try {
          const apiResponse = await apiRequest(GET_DIRECTORY_DATA, REQUEST_TYPE.GET, params);
          if (Object.keys(apiResponse).length) {
            if (apiResponse.response.status) {
              const data = apiResponse.response.data.feeds;
              setDataLength(data.length);
              if (pageIdRef.current === 1) {
                setEmployeeData(data);
                setShowLoadMore(true);
              } else {
                setEmployeeData((oldArray) => [...oldArray, ...data]);
                setShowLoadMore(false);
              }
            }
          }
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        } catch (err) {
          handleError(err, params, GET_DIRECTORY_DATA, NAVIGATION_ROUTES.TAG_PEOPLE_SCREEN);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          setError(true);
        }
      } else {
        loadMoreFlagRef.current = false;
      }
    },
    [dispatch]
  );

  const renderScreen = useCallback(async () => {
    callEmployees();
  }, [callEmployees]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.TAG_EMPLOYEE, NAVIGATION_ROUTES.TAG_EMPLOYEE);
  }, []);

  const tagEmployeefunction = async () => {
    setError(false);
    if (params?.picture_id && currentSelectedList?.length > 0) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.DEFAULT,
        })
      );

      currentSelectedList.forEach((element) => {
        logEvent(
          ANALYTICS_EVENT_TYPES.SOM_EVENT_GALLERY_TAG_EMPLOYEE,
          element?.employee_id ?? '',
          ANALYTICS_ITEM_NAMES.TAG_EMPLOYEE
        );
      });

      const finalList = [...alreadySelectedList, ...currentSelectedList];
      const tagArray = [];
      finalList.forEach((it) => tagArray.push(it?.employee_id));
      const tagString = tagArray.join(',');
      const formData = new URLSearchParams();
      formData.append('tags', tagString);
      try {
        await apiRequest(STARS_BY_ID + params.picture_id + '/addtags', REQUEST_TYPE.POST, formData);
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
        history.goBack();
      } catch (err) {
        handleError(
          err,
          formData,
          STARS_BY_ID + params.picture_id + '/addtags',
          NAVIGATION_ROUTES.TAG_PEOPLE_SCREEN
        );
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
        setError(true);
      }
    }
  };

  const showProfiles = () => {
    if (employeeData.length > 0) {
      return (
        <div className="row microgive-emp-images-container-bottom">
          {employeeData.map((profile, index) => {
            return (
              <div
                className="col-6 col-sm-3 col-lg-2 p-3 som-tag-emp-image-div"
                key={profile.employee_id}>
                <UserProfilePhoto
                  imageBaseUrl={profile.profile_picture.base_url}
                  imagePath={profile.profile_picture.image_path}
                  employeeId={profile.employee_id}
                  imageClass="common-user-profile-photo-xl"
                />
                <p className="som-tag-profile-name">{profile.first_name}</p>
                <p className="som-tag-profile-name">{profile.last_name}</p>
                <div className="som-tag-check-row">
                  <input
                    type="checkbox"
                    id="employee-check"
                    name="som-tag-employee-check"
                    className="som-tag-employee-check-box"
                    onChange={() => addEmployee(profile)}
                    checked={
                      currentSelectedList.filter((emp) => emp?.employee_id === profile?.employee_id)
                        ?.length > 0 ||
                      alreadySelectedList.filter((emp) => emp?.employee_id === profile?.employee_id)
                        ?.length > 0
                        ? true
                        : false
                    }
                    disabled={
                      alreadySelectedList.filter((emp) => emp?.employee_id === profile?.employee_id)
                        ?.length > 0
                        ? true
                        : false
                    }
                  />
                  <div>Tag</div>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else
      return (
        <div className="w-100">
          <DefaultContentView
            message={ERROR_MESSAGES.SEARCH_NO_RESULTS}
            iconUri={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
              ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
            )}
          />
        </div>
      );
  };

  const onClickSearchHandler = () => {
    setIsSearchEnabled(true);
  };

  const onClickFilterHandler = (selectedFilters) => {
    let tempFilterObject = {};
    Object.keys(selectedFilters).map((filterKey) => {
      if (selectedFilters[filterKey].length) {
        tempFilterObject[filterKey] = selectedFilters[filterKey].join();
      }
    });
    appliedFiltersRef.current = tempFilterObject;
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    setDataLength(0);
    callEmployees();
  };

  const loadMoreHandler = () => {
    pageIdRef.current = pageIdRef.current + 1;
    callEmployees(searchTextRef.current);
  };

  const callSubmitFunction = (event) => {
    searchTextRef.current = event.target.value;
  };

  const onSearchSubmitHandler = (event) => {
    event.preventDefault();
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    setDataLength(0);
    callEmployees();
  };

  const resetSearchHandler = useCallback(() => {
    textInputRef.current.reset();
    searchTextRef.current = '';
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    setDataLength(0);
    callEmployees();
  }, [callEmployees]);

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.SOM} />
      <div className="header-view justify-content-between">
        <BackButton isFullRow={false} />
        <CustomButton
          buttonStyle="microgive-button-style mr-3"
          onClick={() => tagEmployeefunction()}
          disabled={currentSelectedList.length === 0}
          showLoader={progressLoadingState.isProgressLoading}>
          Continue
        </CustomButton>
      </div>
      <div className={currentSelectedList.length > 0 && 'microgive-emp-images-container-top'}>
        <div className="d-flex flex-wrap mt-3">
          {currentSelectedList.length > 0 &&
            currentSelectedList.map((item, index) => {
              return (
                <div key={`${index}`} className="microgive-emp-image-div col-6 col-sm-3 col-lg-2">
                  <div>
                    <UserProfilePhoto
                      imageBaseUrl={item.profile_picture.base_url}
                      imagePath={item.profile_picture.image_path}
                      imageClass="common-user-profile-photo-xl"
                    />
                    <div className="text-center mt-1">{item.first_name + ' ' + item.last_name}</div>
                  </div>
                  <div
                    className="d-flex position-absolute mr-3 community-bg-view-image"
                    onClick={() => deleteEmployee(item)}>
                    <img
                      src={getFeatureComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.SOM_EMPLOYEE_CANCEL,
                        ACCESSIBILITY_IDENTIFIERS.SOM
                      )}
                      className="header-icon mr-3"
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="header-view d-flex justify-content-between">
        {isSearchEnabled ? (
          <form className="som-tag-search-form" onSubmit={onSearchSubmitHandler} ref={textInputRef}>
            <input
              autoFocus
              className="ml-3 som-tag-search-bar"
              type="text"
              placeholder="Search.."
              onChange={debounceInputHandler}
            />
          </form>
        ) : (
          <div />
        )}
        {isSearchEnabled ? (
          <div onClick={resetSearchHandler}>
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
              )}
              className="header-icon mr-3"
            />
          </div>
        ) : (
          <div onClick={onClickSearchHandler}>
            <img
              src={getHeaderComponentUrl(accessibilityData, ACCESSIBILITY_HEADER_COMPONENTS.SEARCH)}
              className="header-icon mr-3"
            />
          </div>
        )}
      </div>
      <div className="main-content-container">
        <Filter
          topFilter={FILTER_IDENTIFIERS.GEOGRAPHIES}
          geographies={true}
          locations={true}
          levels={true}
          layers={true}
          serviceTenures={true}
          bloodGroups={true}
          onClick={onClickFilterHandler}
        />
        <div>
          {showEmptyContentView &&
          employeeData &&
          !employeeData.length &&
          !progressLoadingState.isProgressLoading ? (
            showEmptyListView()
          ) : (
            <div>
              {showProfiles()}
              {showLoadMore && (
                <LoadMoreBtn
                  onClick={loadMoreHandler}
                  isLoading={progressLoadingState.isProgressLoading}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SomTagPeopleScreen;
