import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getHeaderComponentUrl } from '../../../utils/accessibility';
import { formatDateDDMonthYYYY, getUrlWithSpecificRendition } from '../../../utils/common';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';

const MomentsListItem = ({
  item,
  identifier,
  onPressCommentButton,
  onPressLikeButton,
  onPressTagDetail,
}) => {
  const [text, setText] = useState('Read more');

  const accessibilityData = useSelector(selectAccessibilityData);

  const onPressRead = () => {
    if (text === 'Read more') {
      setText('Read less');
    } else {
      setText('Read more');
    }
  };

  return (
    <div className="material-card p-3 mb-3">
      <div className="anniversary-date-view-style">
        <UserProfilePhoto
          imageBaseUrl={item.employee.profile_picture.base_url}
          imagePath={item.employee.profile_picture.image_path}
          imageClass="common-user-profile-photo-lg"
          employeeId={item.employee.employee_id}
        />
        <div className="ml-3 anniversary-outer-view anniversary-center-view mr-3">
          <div>
            <div className="mb-1 anniversary-name">
              {item.employee.first_name + ' ' + item.employee.last_name}
            </div>
            <div className="mt-1 anniversary-position-text">{item.employee.position_title}</div>
          </div>
        </div>
        <div className="mt-2 anniversary-moment-date">{formatDateDDMonthYYYY(item.created_on)}</div>
      </div>
      {item.image.image_path !== '' && (
        <img
          src={getUrlWithSpecificRendition(
            item.image.base_url + `images_${identifier.split('_')[2]}/images/`,
            SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
            item.image.image_path
          )}
          className="col-sm-4 mx-auto d-block mt-2 mb-2"
        />
      )}
      <div className="mt-3 cheers-date-view-style cheers-outer-view justify-content-between">
        <div>
          {item.tags.length > 0 ? (
            <div className="anniversary-outer-view" onClick={() => onPressTagDetail(item)}>
              {item.tags.length >= 1 && (
                <UserProfilePhoto
                  imageBaseUrl={item.tags[0].profile_picture.base_url}
                  imagePath={item.tags[0].profile_picture.image_path}
                  imageClass="common-user-profile-photo-xs"
                />
              )}
              {item.tags.length >= 2 && (
                <UserProfilePhoto
                  imageBaseUrl={item.tags[1].profile_picture.base_url}
                  imagePath={item.tags[1].profile_picture.image_path}
                  imageClass="common-user-profile-photo-xs microgive-profile-image-small-overlay"
                />
              )}
              {item.tags.length >= 3 && (
                <UserProfilePhoto
                  imageBaseUrl={item.tags[2].profile_picture.base_url}
                  imagePath={item.tags[2].profile_picture.image_path}
                  imageClass="common-user-profile-photo-xs microgive-profile-image-small-overlay"
                />
              )}
              {item.tags.length > 3 && (
                <div className="anniversary-tag-count-text text-center">{`+${
                  item.tags.length - 3
                }`}</div>
              )}
              <div className="ml-2 anniversary-tagged-text">Tagged</div>
            </div>
          ) : (
            <div className="anniversary-hash-tag">{item.hash_tag}</div>
          )}
        </div>
        <div className="cheers-outer-view ml-3 mr-3">
          {item.likes.is_liked ? (
            <div>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_ACTIVE_LIKE,
                  ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
                )}
                className="ml-3 anniversary-like-icon"
              />
            </div>
          ) : (
            <div onClick={() => onPressLikeButton(item)}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_INACTIVE_LIKE,
                  ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
                )}
                className="ml-3 anniversary-like-icon common-cursor-pointer"
              />
            </div>
          )}
          <div className="ml-3 anniversary-likes-count-text">{item.likes.count}</div>
          <div onClick={() => onPressCommentButton(item)}>
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_POST_COMMENT,
                ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
              )}
              className="ml-3 anniversary-like-icon common-cursor-pointer"
            />
          </div>
          <div className="ml-3 anniversary-likes-count-text">{item.comment_count}</div>
        </div>
      </div>
      {item.tags.length > 0 && <div className="mt-2 anniversary-hash-tag">{item.hash_tag}</div>}
      <div className="mt-1">
        <HtmlView
          html={text === 'Read more' ? item.description.substring(0, 80) + '...' : item.description}
        />
        <div className="anniversary-moment-read-text" onClick={onPressRead}>
          {text}
        </div>
      </div>
    </div>
  );
};

export default MomentsListItem;
