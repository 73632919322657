import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMicrogiveProjectVolunteer } from '../../state/MasterData';
import ProjectDetailsTab from './tabs/projectTabs/ProjectDetailsTab';
import ProjectParticipantsTab from './tabs/projectTabs/ProjectParticipantsTab';
import ProjectGalleryTab from './tabs/projectTabs/ProjectGalleryTab';
import { Tab } from 'react-bootstrap';
import { saveMicrogiveProjectsTabData, selectMicrogiveProjectsTabData } from '../../state/TabData';
import { useParams } from 'react-router-dom';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import NavTabs from '../../common/ui/tabs/NavTabs';

const ProjectTabsScreen = () => {
  const dispatch = useDispatch();

  const { microgiveInitiativeDetailId } = useParams();

  const microgiveTitles = useSelector(selectMicrogiveProjectVolunteer);
  const projectsTabData = useSelector(selectMicrogiveProjectsTabData);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.PROJECT_TABS_SCREEN, NAVIGATION_ROUTES.PROJECT_TABS_SCREEN);
  }, []);

  useEffect(() => {
    if (microgiveTitles.length && projectsTabData === '') {
      dispatch(saveMicrogiveProjectsTabData(microgiveTitles[0].key));
    }
  }, [dispatch, microgiveTitles, projectsTabData]);

  const renderTabs = (tab) => {
    switch (tab.key) {
      case 'details':
        return <ProjectDetailsTab microgiveInitiativeDetailId={microgiveInitiativeDetailId} />;
      case 'participants':
        return <ProjectParticipantsTab microgiveInitiativeDetailId={microgiveInitiativeDetailId} />;
      case 'gallery':
        return <ProjectGalleryTab microgiveInitiativeDetailId={microgiveInitiativeDetailId} />;
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICROGIVE} />
      <BackButton />
      <NavTabs
        activeKey={projectsTabData}
        onSelect={(k) => dispatch(saveMicrogiveProjectsTabData(k))}
        unmountOnExit>
        {microgiveTitles.map((tab, index) => {
          return (
            <Tab key={`${index}`} eventKey={tab.key} title={tab.title}>
              {renderTabs(tab, index)}
            </Tab>
          );
        })}
      </NavTabs>
    </div>
  );
};

export default ProjectTabsScreen;
