import React from 'react';
import { useSelector } from 'react-redux';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import Divider from '../../../common/ui/divider/Divider';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';

const NewChatListItem = ({ item, onPress, isEmployeeSelected, isEmployeeAlreadyAdded }) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  return (
    <div onClick={() => onPress(item)}>
      <div className="d-flex p-3 align-items-center justify-content-between common-cursor-pointer chat-sidebar-item">
        <div className={`d-flex align-items-center`}>
          <div className="d-flex mr-2 position-relative">
            <UserProfilePhoto
              imageBaseUrl={item.profile_picture.base_url}
              imagePath={item.profile_picture.image_path}
              imageClass="common-user-profile-photo-xs"
              employeeId={item.employee_id}
            />
            {isEmployeeSelected && (
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.SOM_EMPLOYEE_SELECT,
                  ACCESSIBILITY_IDENTIFIERS.SOM
                )}
                className="chat-selected-employee-close-icon position-absolute"
              />
            )}
          </div>
          <div className="overflow-hidden flex-grow-1">
            <div className="chat-bold-text chat-base-text-sm">
              {item.first_name + ' ' + item.last_name}
            </div>
            <div className="chat-base-text-sm chat-grey-text">{item.location.title}</div>
          </div>
        </div>
        {isEmployeeAlreadyAdded && <div className="chat-base-text-sm">Already added</div>}
      </div>
      <Divider style="chat-divider-thin" />
    </div>
  );
};

export default React.memo(NewChatListItem);
