import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import CommentBox from '../../common/ui/comment_box/CommentBox';
import { WOMENS_DAY_DISCUSSION, WOMENS_DAY_POST_DISCUSSION } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import DiscussionTabItem from '../microgive/components/DiscussionTabItem';
import { getYoutubeIdFromUrl, isEmptyText, showToast } from '../../utils/common';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import CustomYoutubePlayer from '../../common/ui/youtube_video/CustomYoutubePlayer';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import _ from 'lodash';

const WomensDayWatchVideoScreen = ({ location }) => {
  const params = location.state;
  const dispatch = useDispatch();
  const [showLoadMoreFlag, setShowLoadMoreFlag] = useState(true);
  const [discussions, setDiscussions] = useState([]);
  const [showTimerFlag, setShowTimerFlag] = useState(false);
  const [timerCount, setTimerCount] = useState(15);
  const loadMoreFlag = useRef(true);
  const pageId = useRef(1);
  const [commentText, setCommentText] = useState('');
  const accessibilityData = useSelector(selectAccessibilityData);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [year, setYear] = useState('');

  const reverseTimer = (sec) => {
    let timer = sec;
    let interval = setInterval(function () {
      let seconds = Math.floor(timer % 60);

      seconds = seconds < 10 ? '0' + seconds : seconds;
      setTimerCount(seconds);
      if (--timer < 0) {
        timer = sec;
      }
      if (timer === 0) {
        setTimerCount(15);
        setShowTimerFlag(false);
        clearInterval(interval);
      }
    }, 1000);
  };

  const refreshChats = () => {
    loadMoreFlag.current = true;
    pageId.current = 1;
    setDiscussions([]);
    callDiscussionListApi();
    setShowTimerFlag(true);
    reverseTimer(15);
  };

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.WOMENS_DAY_WATCH_VIDEO,
      NAVIGATION_ROUTES.WOMENS_DAY_WATCH_VIDEO
    );
  }, []);

  useEffect(() => {
    if (!_.isEmpty(location)) {
      if (location?.pathname) {
        let itemsArr = location?.pathname?.split('/');
        if (itemsArr.length > 2) {
          itemsArr.pop();
          let year = itemsArr.pop();
          if (!isNaN(year)) {
            setYear(Number(year));
          } else {
            showToast("Women's day celebration year is invalid.");
          }
        }
      }
    }
  }, [location]);

  const callDiscussionListApi = useCallback(async () => {
    if (loadMoreFlag.current) {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const params = {
        page_id: pageId.current,
        year: year,
      };
      try {
        const apiResponse = await apiRequest(WOMENS_DAY_DISCUSSION, REQUEST_TYPE.GET, params);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.data.feeds.length > 0) {
              if (pageId.current === 1) {
                setDiscussions([]);
              }
              setDiscussions((oldArray) => [
                ...apiResponse.response.data.feeds.reverse(),
                ...oldArray,
              ]);
              pageId.current = pageId.current + 1;
              loadMoreFlag.current = true;
            } else {
              loadMoreFlag.current = false;
              setShowLoadMoreFlag(false);
            }
          }
        }
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      } catch (err) {
        handleError(err, params, WOMENS_DAY_DISCUSSION, NAVIGATION_ROUTES.WOMENS_DAY);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    }
  }, [dispatch, year]);

  const renderScreen = useCallback(async () => {
    callDiscussionListApi();
  }, [callDiscussionListApi]);

  useEffect(() => {
    if (year) {
      renderScreen();
    }
  }, [renderScreen, year]);

  const renderItem = (item) => {
    return <DiscussionTabItem item={item} />;
  };

  const sendCommentFunction = async () => {
    if (!isEmptyText(commentText)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      callCommentApi();
    }
  };

  const callCommentApi = async () => {
    const params = new URLSearchParams();
    params.append('description', commentText);
    params.append('year', year);
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.POSTING_DISCUSSION,
        })
      );
      const apiResponse = await apiRequest(WOMENS_DAY_POST_DISCUSSION, REQUEST_TYPE.POST, params);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          showToast(apiResponse.response.message);
          setCommentText('');
          pageId.current = 1;
          loadMoreFlag.current = true;
          callDiscussionListApi();
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(err, params, WOMENS_DAY_POST_DISCUSSION, NAVIGATION_ROUTES.WOMENS_DAY);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  const onPressLoadPrevious = () => {
    callDiscussionListApi();
  };

  return (
    <div className="main-content-container container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.WOMENS_DAY} />
      <BackButton />
      <CustomYoutubePlayer
        videoId={getYoutubeIdFromUrl(params.youtubeUrl)}
        videoUrl={params.youtubeUrl}
      />
      <div>
        {showLoadMoreFlag && (
          <div className="mb-3 mt-3">
            <LoadMoreBtn
              onClick={onPressLoadPrevious}
              btnText="Load Previous"
              isLoading={progressLoadingState.isProgressLoading}
            />
          </div>
        )}
        {discussions.length > 0 &&
          discussions.map((item, index) => {
            return (
              <div key={index} className="womens-day-watch-video-discussion">
                {renderItem(item)}
              </div>
            );
          })}

        {!showTimerFlag && (
          <div className="womens-day-timer-container mb-3">
            <div className="womens-day-refresh-view">
              <div
                onClick={() => {
                  refreshChats();
                }}>
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.WOMENS_DAY_REFRESH,
                    ACCESSIBILITY_IDENTIFIERS.WOMENS_DAY
                  )}
                  className="womens-day-refresh-size refreshIconContainer"
                />
              </div>
            </div>
          </div>
        )}
        {showTimerFlag && (
          <div className="womens-day-timer-container mb-3">
            <div className="womens-day-timer-view">
              <div className="timerCountText">{timerCount}</div>
            </div>
          </div>
        )}
      </div>
      <div className="womens-day-comment-box">
        <CommentBox
          getCommentText={sendCommentFunction}
          onChangehandler={(val) => {
            setCommentText(val);
          }}
          commentText={commentText}
          showLoader={progressLoadingState.isProgressLoading}
        />
      </div>
    </div>
  );
};

export default WomensDayWatchVideoScreen;
