import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import { GET_INFO_LIST } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { saveShowEmptyContentView, selectShowEmptyContentView } from '../../../state/UIState';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import InfoTabItem from '../components/InfoTabItem';

const InfoTab = ({ breakoutId }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [infoList, setInfoList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const [pageId, setPageId] = useState(1);

  const showEmptyContentView = useSelector(selectShowEmptyContentView);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.BREAKOUTS_INFO_TAB_VISIT,
      breakoutId,
      ANALYTICS_ITEM_NAMES.BREAKOUTS
    );
  }, [breakoutId]);

  const callInfoApi = useCallback(
    async (id, loadMore) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        const params = {
          page_id: id,
        };
        try {
          setLoading(true);
          const apiResponse = await apiRequest(
            GET_INFO_LIST + breakoutId + '/info',
            REQUEST_TYPE.GET,
            params
          );
          setLoading(false);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                setInfoList((oldArray) => [...oldArray, ...apiResponse.response.data.feeds]);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(
            err,
            params,
            GET_INFO_LIST + breakoutId + '/info',
            NAVIGATION_ROUTES.BREAKOUTS
          );
          setLoading(false);
        }
      }
    },
    [breakoutId, dispatch]
  );

  useEffect(() => {
    callInfoApi(1, true);
  }, [callInfoApi]);

  const onPressItem = (item) => {
    history.push(NAVIGATION_ROUTES.BREAKOUTS_INFO_DETAIL_SCREEN, {
      infoId: item.info_id,
      item: item,
      title: item.title,
    });
  };

  const onPressLoadMore = () => {
    callInfoApi(pageId, loadMoreFlag);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_REFERENCE_MATERIAL_AVAILABLE} />;
  };

  return (
    <div className="main-content-container">
      {showEmptyContentView && !infoList.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          <div>
            {infoList.map((item, index) => {
              return <InfoTabItem item={item} onPressItem={onPressItem} key={`${index}`} />;
            })}
          </div>
          <ActivityLoader visible={loading} />
          {loadMoreFlag && !!infoList.length && (
            <LoadMoreBtn onClick={onPressLoadMore} isLoading={loading} />
          )}
        </div>
      )}
    </div>
  );
};

export default InfoTab;
