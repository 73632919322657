import React from 'react';
import { useSelector } from 'react-redux';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { formatDateDDMonthYYYY, formatTimeAMPM, isToday } from '../../../utils/common';

const DiscussionTabItem = ({ item }) => {
  const employeeData = useSelector(selectEmployeeData);

  return (
    <div>
      {`${item.employee.employee_id}` === `${employeeData.employee_id}` ? (
        <div className="womens-day-self-view mt-2 mb-2">
          <div className="womens-day-self-item">
            <div className="row justify-content-between">
              <div className="ml-3 womens-day-discuss-emp-name">You</div>
              <div className="mr-3 womens-day-discussion-date">
                {isToday(item.created_on)
                  ? formatTimeAMPM(item.created_on)
                  : formatDateDDMonthYYYY(item.created_on)}
              </div>
            </div>
            <HtmlView html={item.description} htmlStyle="" />
          </div>
        </div>
      ) : (
        <div className="womens-day-other-view mt-2 mb-2">
          <div className="womens-day-other-item">
            <div className="row justify-content-between">
              <div className="ml-3 womens-day-discuss-emp-name">
                {`${item.employee.first_name} ${item.employee.last_name}`}
              </div>
              <div className="mr-3 womens-day-discussion-date">
                {formatDateDDMonthYYYY(item.created_on)}
              </div>
            </div>
            <HtmlView html={item.description} />
          </div>
        </div>
      )}
    </div>
  );
};

export default DiscussionTabItem;
