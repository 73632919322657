import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../../utils/accessibility';

const InfoLinksItem = ({ item, isPdfUrls, onPress, isYtUrls }) => {
  const [icon, setIcon] = useState();

  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    if (isPdfUrls) {
      setIcon(
        getHeaderComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.BREAKOUTS_PDF_ATTACHMENT,
          ACCESSIBILITY_IDENTIFIERS.BREAKOUTS
        )
      );
    } else if (isPdfUrls === false) {
      setIcon(
        getHeaderComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.BREAKOUTS_LINK_SQUARE,
          ACCESSIBILITY_IDENTIFIERS.BREAKOUTS
        )
      );
    } else if (isYtUrls) {
      setIcon(
        getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_VIDEO_PLAY_BUTTON,
          ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
        )
      );
    }
  }, [accessibilityData, isPdfUrls, isYtUrls]);

  return (
    <div className="row ml-0 mt-2 common-cursor-pointer" onClick={() => onPress(item)}>
      <img
        src={icon}
        className={
          isPdfUrls
            ? 'breakouts-normal-icon'
            : isYtUrls
            ? 'breakouts-normal-icon-yt'
            : !isPdfUrls
            ? 'breakouts-normal-icon-link'
            : 'breakouts-normal-icon'
        }
      />

      <div className="ml-3 breakouts-info-link-text align-self-center">{item.label}</div>
    </div>
  );
};

export default InfoLinksItem;
