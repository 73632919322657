import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';

import CustomButton from '../../../common/ui/custom_button/CustomButton';
import { ESCALATE_IJP } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../state/UIState';
import { showToast } from '../../../utils/common';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';

const IjpRequestAssistDialog = (props) => {
  const { isIjpRequestAssistDialogVisible, setIsIjpRequestAssistDialogVisible, ijpData } = props;
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const dispatch = useDispatch();

  const callIjpRequestAssistanceApi = async () => {
    const params = new URLSearchParams();
    params.append('job_code', ijpData.job_code);
    params.append('ijp_id', ijpData.ijp_id);
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const apiResponse = await apiRequest(ESCALATE_IJP, REQUEST_TYPE.POST, params);
      setIsIjpRequestAssistDialogVisible(false);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          const message = apiResponse.response.message;
          if (message) {
            showToast(message);
          }
        }
      }
    } catch (err) {
      handleError(err, params, ESCALATE_IJP, NAVIGATION_ROUTES.IJPR);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressConfirmRequestAssistance = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.IJP_REQUEST_ASSISTANCE_CONFIRM_CLICK,
      ijpData.job_code.toString(),
      ANALYTICS_ITEM_NAMES.IJPR
    );
    callIjpRequestAssistanceApi();
  };

  return (
    <Modal
      onHide={() => setIsIjpRequestAssistDialogVisible((val) => !val)}
      show={isIjpRequestAssistDialogVisible}
      centered
      backdrop="static"
      keyboard={false}>
      <Modal.Body className="p-4">
        <h5 className="ijp-apply-modal-title text-center">Request Assistance</h5>
        <p className="ijp-apply-modal-subtitle text-center mb-4">
          Please click on ‘Request Assistance’ to reach out to WFM Team to assist with your
          application.
        </p>
        <div className="d-flex justify-content-center">
          <CustomButton
            onClick={() => {
              setIsIjpRequestAssistDialogVisible(false);
            }}
            buttonMainContainer="mr-4"
            buttonStyle="common-custom-button-ternary">
            Cancel
          </CustomButton>
          <CustomButton
            disabled={progressLoadingState.isProgressLoading}
            onClick={onPressConfirmRequestAssistance}
            buttonStyle="common-custom-button-quaternary">
            Request Assistance
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default IjpRequestAssistDialog;
