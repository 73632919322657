import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  FEATURE_TYPE,
  NAVIGATION_ROUTES,
} from '../../../common/constants/AppConstants';
import { getHeaderComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { formatDateDDMonthYYYY, getAwardIcon } from '../../../utils/common';
import Divider from '../../../common/ui/divider/Divider';
import colors from '../../../common/themes/Colors';
import { useHistory } from 'react-router';
import HtmlView from '../../../common/ui/html_view/HTMLView';

const GivenListItem = ({
  item,
  onPressCommentButton,
  onPressLikeButton,
  onPressOthers,
  showLikeButton,
  showCommentButton,
}) => {
  const history = useHistory();
  const [showHideDetails, setShowHideDetails] = useState('Show Details');
  const [iconName] = useState(getAwardIcon(item.award_details.icon_name));
  const [colorStyle, setColorStyle] = useState({});
  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    if (item.request_type === 'Approved') {
      setColorStyle({ color: colors.forestGreen });
    } else if (item.request_type === 'Rejected') {
      setColorStyle({ color: colors.primary });
    } else if (item.request_type === 'Pending') {
      setColorStyle({ color: colors.tango });
    }
  }, [item.request_type]);

  const onPressShowDetails = (btnType) => {
    if (btnType === 'Show Details') {
      setShowHideDetails('Hide Details');
    } else if (btnType === 'Hide Details') {
      setShowHideDetails('Show Details');
    }
  };

  return (
    <div className="material-card p-3 mb-3">
      <div className="cheers-outer-view">
        <div className="cheers-icon-text-view cheers-outer-view">
          <img
            src={getHeaderComponentUrl(
              accessibilityData,
              iconName,
              ACCESSIBILITY_IDENTIFIERS.CHEERS
            )}
            className="cheers-normal-icon"
          />
          <div className="ml-2 cheers-award-title-text">{item.award_details.title}</div>
        </div>
        {item.award_details.award_type !== 'Non-Monetary' ? (
          <div className="ml-3 cheers-reward-points-second-text cheers-date-view-style cheers-center-view">{`(${item.points} Points)`}</div>
        ) : (
          <div className="ml-3 cheers-center-view" />
        )}
        <div className="cheers-date-view-style">
          {item?.request_type === 'Approved' && (
            <div className="cheers-date-view-style">
              {showLikeButton && (
                <>
                  {item?.likes?.is_liked ? (
                    <div onClick={() => onPressLikeButton(item)}>
                      <img
                        src={getHeaderComponentUrl(
                          accessibilityData,
                          ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_ACTIVE_LIKE,
                          ACCESSIBILITY_IDENTIFIERS.CHEERS
                        )}
                        className="cheers-normal-icon ml-3 common-cursor-pointer"
                      />
                    </div>
                  ) : (
                    <div onClick={() => onPressLikeButton(item)}>
                      <img
                        src={getHeaderComponentUrl(
                          accessibilityData,
                          ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_INACTIVE_LIKE,
                          ACCESSIBILITY_IDENTIFIERS.CHEERS
                        )}
                        className="cheers-normal-icon ml-3 common-cursor-pointer"
                      />
                    </div>
                  )}
                  <div
                    className="ml-3 likesCountText common-cursor-pointer"
                    onClick={() => {
                      item?.likes?.count > 0
                        ? history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
                            referId: item.cheer_employee_award_id,
                            parentType: FEATURE_TYPE.CHEERS,
                          })
                        : () => {};
                    }}>
                    {item?.likes?.count}
                  </div>
                </>
              )}
              {showCommentButton && (
                <>
                  <div onClick={() => onPressCommentButton(item)}>
                    <img
                      src={getHeaderComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_POST_COMMENT,
                        ACCESSIBILITY_IDENTIFIERS.CHEERS
                      )}
                      className="cheers-normal-icon ml-3 common-cursor-pointer"
                    />
                  </div>
                  <div className="ml-3 commentCountText">{item?.comment_count}</div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="cheers-date-text mt-2">{formatDateDDMonthYYYY(item.created_on)}</div>
      <div className="cheers-outer-view justify-content-between">
        <div className="cheers-award-type mt-2">{`${item.award_details.award_type}`}</div>
        <div style={colorStyle}>{item.request_type}</div>
      </div>
      <Divider style="mt-2 mb-2" />
      {showHideDetails === 'Hide Details' && (
        <div className="cheers-desc-text-style">{item.award_details.description}</div>
      )}
      {showHideDetails === 'Hide Details' && <Divider style="mt-2 mb-2" />}
      {showHideDetails === 'Hide Details' && (
        <div>
          <div className="cheers-date-view-style">
            <div className="cheers-given-nominate-text">Given To:</div>
            {Object.keys(item.employee).length > 0 && (
              <div
                className="cheers-given-nominate-text ml-1 common-cursor-pointer"
                onClick={() => {
                  onPressOthers(item);
                }}>{` ${item.employee.first_name} ${item.employee.last_name}`}</div>
            )}
          </div>
          <div className="cheers-date-view-style">
            <div className="cheers-given-nominate-text">{'Awarded for: '}</div>
            <div className="cheers-given-nominate-text ml-1">
              <HtmlView html={item.citation} />
            </div>
          </div>
          {item.citation_text && <div className="mt-2 cheers-select-option-text">Citation</div>}
          {item.citation_text && (
            <div className="mb-2 cheers-citation-text">
              <HtmlView html={item.citation_text} />
            </div>
          )}
        </div>
      )}
      <div className="cheers-date-view-style">
        {showHideDetails === 'Show Details' && (
          <div className="cheers-given-nominate-text">Given To:</div>
        )}
        {showHideDetails === 'Show Details' && Object.keys(item.employee).length > 0 && (
          <div
            className="cheers-given-nominate-text ml-1 common-cursor-pointer"
            onClick={() => {
              onPressOthers(item);
            }}>{` ${item.employee.first_name} ${item.employee.last_name}`}</div>
        )}
        <div className="cheers-show-hide-view">
          <div
            className="cheers-date-view-style common-cursor-pointer"
            onClick={() => onPressShowDetails(showHideDetails)}>
            <div className="cheers-show-hide-detail">{showHideDetails}</div>
            {showHideDetails === 'Show Details' ? (
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_ARROW_DOWN,
                  ACCESSIBILITY_IDENTIFIERS.CHEERS
                )}
                className="cheers-normal-icon ml-2"
              />
            ) : (
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_ARROW_UP,
                  ACCESSIBILITY_IDENTIFIERS.CHEERS
                )}
                className="cheers-normal-icon ml-2"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GivenListItem;
