import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Popover from 'react-bootstrap/Popover';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import Divider from '../../../common/ui/divider/Divider';
import { TwitterIcon, LinkedinIcon, FacebookIcon, EmailIcon } from 'react-share';
import { saveAs } from 'file-saver';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { shareImage } from '../../../utils/common';

const SharePopupMenu = (props) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const shareCardPopoverRef = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        props.showShareDialog &&
        shareCardPopoverRef.current &&
        !shareCardPopoverRef.current.contains(e.target)
      ) {
        props.onOutsideClick();
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [props]);

  const shareCard = (platform) => {
    shareImage(platform, props.url);
    props.onOutsideClick();
  };

  const popover = (
    <Popover id="facility-content-popover">
      <div className="my-2" ref={shareCardPopoverRef}>
        <div
          className="d-flex align-items-center px-4 common-cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            shareCard('Linkedin');
          }}>
          <p className="mb-0 mr-4 facility-share-card-social-text">{'Linkedin'}</p>
          <LinkedinIcon size={32} round={true} />
        </div>
        <Divider style="common-profile-divider-style mt-2 mb-2" />
        <div
          className="d-flex align-items-center px-4 common-cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            shareCard('Facebook');
          }}>
          <p className="mb-0 mr-3 facility-share-card-social-text">{'Facebook'}</p>
          <FacebookIcon size={32} round={true} />
        </div>
        <Divider style="common-profile-divider-style mt-2 mb-2" />
        <div
          className="d-flex align-items-center px-4 common-cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            shareCard('Twitter');
          }}>
          <p className="mb-0 mr-3 facility-share-card-social-text pr-1">{'Twitter'}</p>
          <TwitterIcon size={32} round={true} className="ml-3" />
        </div>
        <Divider style="common-profile-divider-style mt-2 mb-2" />
        <div
          className="d-flex align-items-center px-4 common-cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            props.onOptionClick(props.url);
          }}>
          <p className="mb-0 mr-5 facility-share-card-social-text">{'Email'}</p>
          <EmailIcon size={32} round={true} />
        </div>
        <Divider style="common-profile-divider-style mt-2 mb-2" />
        <div
          className="d-flex align-items-center px-4 common-cursor-pointer"
          onClick={(e) => {
            e.stopPropagation();
            saveAs(props.url, props.type + '.jpeg');
            props.onOutsideClick();
          }}>
          <p className="mb-0 mr-3 facility-share-card-social-text">{'Download'}</p>
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.ECARDS_DOWNLOAD_CARD,
              ACCESSIBILITY_IDENTIFIERS.ECARDS
            )}
            className="ecards-download-icon"
          />
        </div>
      </div>
    </Popover>
  );

  return (
    <div>
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom"
        overlay={popover}
        show={props.showShareDialog}>
        {props.children}
      </OverlayTrigger>
    </div>
  );
};

export default SharePopupMenu;
