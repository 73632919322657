import { useState } from 'react';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';

const CustomTable = ({ data, isIncentives = false, visibility = true, tabKey }) => {
  const [showToverRevenue, setShowToverRevenue] = useState(false);
  const accessibilityData = useSelector(selectAccessibilityData);
  return (
    <div className={`w-50 p-4 ${isIncentives && visibility ? 'variable-pay-table-block' : ''}`}>
      {visibility && (
        <div>
          {isIncentives ? (
            <div className="vp-currency-note-container">
              {isIncentives && <div className="vp-special-incentives">Special Incentives*</div>}
              {data?.currency_note && <div className="vp-currency-note">{data?.currency_note}</div>}
            </div>
          ) : (
            <div className="vp-currency-note">{data?.currency_note}</div>
          )}
          <table className="variable-pay-table" cellPadding={2} border={1}>
            <tbody>
              {data?.matrix?.map((row, index) => (
                <tr key={index} style={{ backgroundColor: row.is_bg ? '#F9F9F9' : 'white' }}>
                  {row?.row?.map(
                    (cell, cellIndex) =>
                      (!row?.single_cell || (row?.single_cell && cellIndex === 0)) && (
                        <td
                          style={{
                            textAlign: cellIndex === 0 ? row.align : 'center',
                            fontWeight: row.is_bold ? 'bold' : 'normal',
                          }}
                          className="variable-pay-cell"
                          colSpan={row.single_cell ? row.row.length : 1}
                          key={`${index}-${cellIndex}`}>
                          {cell}
                        </td>
                      )
                  )}
                </tr>
              ))}
              {data?.tower_revenue?.visibility && (
                <tr>
                  <td
                    className="variable-pay-cell"
                    colSpan={data?.tower_revenue?.matrix[0].row.length}>
                    <div
                      className="d-flex f-row justify-content-between common-cursor-pointer align-items-center"
                      onClick={() => setShowToverRevenue(!showToverRevenue)}>
                      <p className="m-0">
                        <a style={{ color: 'red' }}>Click here</a>
                        {` ${data?.tower_revenue?.action_text}`}
                      </p>
                      <img
                        className="common-wish-card-right-icon"
                        style={{ transform: `rotate(${showToverRevenue ? '270' : '90'}deg)` }}
                        src={getFeatureComponentUrl(
                          accessibilityData,
                          ACCESSIBILITY_FEATURE_COMPONENTS.DIRECTORY_RIGHT_ARROW,
                          ACCESSIBILITY_IDENTIFIERS.DIRECTORY
                        )}
                      />
                    </div>
                  </td>
                </tr>
              )}
              {data?.tower_revenue?.visibility && showToverRevenue && (
                <tr>
                  <td
                    className="variable-pay-cell"
                    colSpan={data?.tower_revenue?.matrix[0].row.length}>
                    <div className="vp-currency-note-container align-items-center mt-3">
                      <div className="vp-special-incentives">{`Tower-wise ${
                        tabKey === 'payout' ? 'Payout' : 'Revenue'
                      }`}</div>
                      <div className="vp-currency-note">{data?.tower_revenue?.currency_note}</div>
                    </div>
                  </td>
                </tr>
              )}
              {data?.tower_revenue?.visibility &&
                showToverRevenue &&
                data?.tower_revenue.matrix?.map((row, index) => (
                  <tr key={index} style={{ backgroundColor: row.is_bg ? '#F9F9F9' : 'white' }}>
                    {row?.row?.map(
                      (cell, cellIndex) =>
                        (!row?.single_cell || (row?.single_cell && cellIndex === 0)) && (
                          <td
                            style={{
                              textAlign: cellIndex === 0 ? row.align : 'center',
                              fontWeight: row.is_bold ? 'bold' : 'normal',
                            }}
                            className="variable-pay-cell"
                            colSpan={row.single_cell ? row.row.length : 1}
                            key={`${index}-${cellIndex}-tover`}>
                            {cell}
                          </td>
                        )
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="vp-foot-note">
            <HtmlView html={data?.footnote} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomTable;
