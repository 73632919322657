import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_TITLE,
} from '../../common/constants/AppConstants';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { GET_BREAKOUTS, JOIN_BREAKOUTS } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { saveBreakoutsTabData } from '../../state/TabData';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectBaseUrl,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../state/UIState';
import { showToast } from '../../utils/common';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BreakoutsListItem from './components/BreakoutsListItem';

const BreakoutsScreen = ({ history }) => {
  const dispatch = useDispatch();
  const [breakoutsList, setBreakoutsList] = useState([]);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const [pageId, setPageId] = useState(1);
  const baseUrl = useSelector(selectBaseUrl);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.BREAKOUTS, NAVIGATION_ROUTES.BREAKOUTS);
  }, []);

  const callBreakoutsListApi = useCallback(
    async (id, loadMore) => {
      if (loadMore) {
        const params = {
          page_id: id,
        };
        try {
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          const apiResponse = await apiRequest(GET_BREAKOUTS, REQUEST_TYPE.GET, params);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                setBreakoutsList((oldArray) => [...oldArray, ...apiResponse.response.data.feeds]);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(err, params, GET_BREAKOUTS, NAVIGATION_ROUTES.BREAKOUTS);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (baseUrl !== '') {
      callBreakoutsListApi(1, true);
    }
  }, [baseUrl, callBreakoutsListApi]);

  const callBreakoutsJoinApi = async (breakoutId) => {
    const params = new URLSearchParams();
    try {
      dispatch(saveShowEmptyContentView(true));
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: 'Joining Breakout...',
        })
      );
      const apiResponse = await apiRequest(
        JOIN_BREAKOUTS + breakoutId + '/join',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          showToast(apiResponse.response.message);
          setPageId(1);
          setLoadMoreFlag(true);
          setBreakoutsList([]);
          callBreakoutsListApi(1, true);
        } else {
          setPageId(1);
          setLoadMoreFlag(true);
          setBreakoutsList([]);
          callBreakoutsListApi(1, true);
        }
      }
    } catch (err) {
      handleError(err, params, JOIN_BREAKOUTS + breakoutId + '/join', NAVIGATION_ROUTES.BREAKOUTS);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressItem = (item) => {
    if (item.is_member) {
      if (item.approved && !item.rejected) {
        dispatch(saveBreakoutsTabData(''));
        history.push(`${NAVIGATION_ROUTES.BREAKOUTS}/${item.breakout_id}`, {
          title: SCREEN_TITLE.BREAKOUTS,
        });
      }
    }
  };

  const onPressJoinButton = (breakoutId) => {
    callBreakoutsJoinApi(breakoutId);
  };

  const onPressLoadMore = () => {
    callBreakoutsListApi(pageId, loadMoreFlag);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_DATA_FOUND} />;
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.BREAKOUTS} />
      <div className="main-content-container">
        {showEmptyContentView &&
        !breakoutsList.length &&
        !progressLoadingState.isProgressLoading ? (
          emptyListView()
        ) : (
          <div>
            <div className="row">
              {breakoutsList.map((item, index) => {
                return (
                  <BreakoutsListItem
                    key={`${index}`}
                    onPressJoinButton={onPressJoinButton}
                    item={item}
                    onPressItem={onPressItem}
                  />
                );
              })}
            </div>
            {loadMoreFlag && !!breakoutsList.length && (
              <LoadMoreBtn
                onClick={onPressLoadMore}
                isLoading={progressLoadingState.isProgressLoading}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BreakoutsScreen;
