import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
  SUPPORTED_IMAGE_FORMATS,
  SUPPORTED_IMAGE_FORMATS_WEB,
  WARNING_MESSAGES,
} from '../../common/constants/AppConstants';
import { apiRequest } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import TagEmployee from './components/TagEmployee';
import { Modal } from 'react-bootstrap';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { getUrlWithSpecificRendition, isEmptyText, showToast } from '../../utils/common';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { selectMaxFileUploadSizeConfig } from '../../state/MasterData';
import { uploadDocuments } from '../../utils/commonApiRequests';

const AddContestsScreen = ({ location, history }) => {
  const dispatch = useDispatch();
  const { item, identifier } = location.state;

  const [text, setText] = useState('');
  const [tagList, setTagList] = useState([]);
  const [selectedImage, setSelectedImage] = useState({});
  const [showTagEmployeeView, setShowTagEmployeeView] = useState(false);
  const [galleryDialog, setGalleryDialog] = useState(false);
  const [baseUrl, setBaseUrl] = useState('');

  const accessibilityData = useSelector(selectAccessibilityData);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const maxFileUploadSizeInMB = useSelector(selectMaxFileUploadSizeConfig);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.ADD_CONTESTS_SCREEN, NAVIGATION_ROUTES.ADD_CONTESTS_SCREEN);
  }, []);

  const callSubmitNominationApi = async () => {
    let tagString = '';
    if (tagList.length > 0) {
      tagList.forEach((element) => {
        logEvent(
          ANALYTICS_EVENT_TYPES.ANNIVERSARY_CONTESTS_TAG_EMPLOYEE,
          element.employee_id,
          ANALYTICS_ITEM_NAMES.TAG_EMPLOYEE
        );
      });
      if (tagList.length === 1) {
        tagString = tagList[0].employee_id;
      } else {
        var tagArray = [];
        for (var i = 0; i < tagList.length; i++) {
          tagArray.push(tagList[i].employee_id);
        }
        tagString = tagArray.join(',');
      }
    }
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: 'Submitting...',
        })
      );
      const params = new URLSearchParams();
      params.append('description', text);
      params.append('image', JSON.stringify(selectedImage));
      params.append('tags', tagString);
      const apiResponse = await apiRequest(
        identifier + '/' + item.anniv_challenge_id + '/post',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setSelectedImage({});
          history.replace(NAVIGATION_ROUTES.ADD_CONTESTS_THANKS_SCREEN, {
            identifier: identifier,
            title: item.title,
          });
        }
      }
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressSubmit = () => {
    if (!isEmptyText(text)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      callSubmitNominationApi();
    }
  };

  const onTextChange = (event) => {
    setText(event.target.value);
  };

  const onPressAddImage = () => {
    setGalleryDialog(true);
  };

  const onPressTag = () => {
    setShowTagEmployeeView(true);
  };

  const onFileChange = (event) => {
    let isAllFilesSupported = true;
    let isAnyFileSizeLarge = false;
    [...event.target.files].forEach((element) => {
      if (!SUPPORTED_IMAGE_FORMATS_WEB.includes(element.type)) {
        isAllFilesSupported = false;
      }
      if (Number(element.size) / (1024 * 1024) > maxFileUploadSizeInMB) {
        isAnyFileSizeLarge = true;
      }
    });
    if (!isAllFilesSupported) {
      showToast(WARNING_MESSAGES.FILE_NOT_SUPPORTED);
      setSelectedImage({});
      setGalleryDialog(false);
    } else if (isAnyFileSizeLarge) {
      showToast(WARNING_MESSAGES.FILE_SIZE_EXCEEDED(maxFileUploadSizeInMB));
      setSelectedImage({});
      setGalleryDialog(false);
    } else {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      [...event.target.files].map((image, index) => {
        uploadDocuments(image, 'anniversary')
          .then((response) => {
            setBaseUrl(response.base_url);
            setSelectedImage({ filename: response.fileName, type: 'image' });
          })
          .catch((error) => {
            showToast(error.message);
          })
          .finally(() => {
            if ([...event.target.files].length === index + 1) {
              dispatch(
                saveProgressLoadingState({
                  isProgressLoading: false,
                })
              );
            }
          });
      });
    }
  };

  const onEmployeeSelected = (tags) => {
    setTagList([...tags]);
    setShowTagEmployeeView(false);
  };

  const galleryCameraDialog = () => {
    return (
      <div>
        <div className="text-center breakouts-upload-text">Upload Image</div>
        <div className="anniversary-team-micro-view mt-3">
          <input
            type="file"
            onChange={onFileChange}
            accept={SUPPORTED_IMAGE_FORMATS}
            id="input"
            onClick={(event) => {
              event.target.value = null;
              setSelectedImage({});
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY} />
      <BackButton />
      <div className="main-content-container">
        <Modal
          show={galleryDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => setGalleryDialog(false)}>
          <Modal.Body>{galleryCameraDialog()}</Modal.Body>
        </Modal>
        <div className="anniversary-contest-title">Whats your connection?</div>
        {showTagEmployeeView && (
          <TagEmployee onEmployeeSelected={onEmployeeSelected} tags={tagList} />
        )}
        <div className="anniversary-outer-view mt-3 justify-content-between">
          <div className="anniversary-outer-view" onClick={onPressAddImage}>
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_UPLOAD,
                ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
              )}
              className="anniversary-normal-icon"
            />
            <div className="ml-2">ADD IMAGE</div>
          </div>
          <img
            src={getHeaderComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_TAG_EMPLOYEE,
              ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
            )}
            className="ml-3 anniversary-normal-icon"
            onClick={onPressTag}
          />
        </div>
        {Object.keys(selectedImage).length > 0 && (
          <img
            src={getUrlWithSpecificRendition(
              baseUrl,
              SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
              selectedImage.filename
            )}
            className="col-sm-4 mx-auto d-block mt-2 mb-2"
            onClick={onPressTag}
          />
        )}
        <div className="anniversary-outer-view mt-3">
          {tagList.length >= 1 && (
            <UserProfilePhoto
              imageBaseUrl={tagList[0].profile_picture.base_url}
              imagePath={tagList[0].profile_picture.image_path}
              imageClass="common-user-profile-photo-xs"
            />
          )}
          {tagList.length >= 2 && (
            <UserProfilePhoto
              imageBaseUrl={tagList[1].profile_picture.base_url}
              imagePath={tagList[1].profile_picture.image_path}
              imageClass="common-user-profile-photo-xs microgive-profile-image-small-overlay"
            />
          )}
          {tagList.length >= 3 && (
            <UserProfilePhoto
              imageBaseUrl={tagList[2].profile_picture.base_url}
              imagePath={tagList[2].profile_picture.image_path}
              imageClass="common-user-profile-photo-xs microgive-profile-image-small-overlay"
            />
          )}
          {tagList.length > 3 && (
            <div className="anniversary-tag-count-text ">{`+${tagList.length - 3}`}</div>
          )}
          {tagList.length >= 1 && <div className="ml-2 anniversary-tagged-text">Tagged</div>}
        </div>
        <div className="form-group mt-3">
          <textarea
            className="form-control"
            rows="8"
            placeholder="Enter text here..."
            value={text}
            onChange={onTextChange}
          />
        </div>
        <CustomButton
          buttonStyle="anniversary-button mr-3 mt-2"
          onClick={onPressSubmit}
          showLoader={progressLoadingState.isProgressLoading}>
          Submit
        </CustomButton>
      </div>
    </div>
  );
};

export default AddContestsScreen;
