import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectMenuData } from '../../../state/MenuData';
import { removeHtmlContent } from '../../../utils/common';
import CustomButton from '../custom_button/CustomButton';
import { getHeaderComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { ACCESSIBILITY_HEADER_COMPONENTS } from '../../constants/AppConstants';

const SectionHeader = ({
  identifier,
  title,
  containerClass = '',
  onClick = () => {},
  showButton = false,
  buttonTitle = '',
  showSearch = false,
  isSearchEnabled = false,
  onSearchSubmitHandler = () => {},
  callSubmitFunction = () => {},
  resetSearchHandler = () => {},
  onClickSearchHandler = () => {},
  textInputRef,
  buttonStyle = '',
  disabled = false,
  showButtonLoader = false,
}) => {
  const menuData = useSelector(selectMenuData);
  const [sectionTitle, setSectionTitle] = useState('');
  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    if (identifier) {
      if (menuData && Object.keys(menuData).length) {
        const foundFeature = menuData.data.section_list
          .flatMap((section) =>
            section.features.find((feature) => feature.identifier === identifier)
          )
          .filter((e) => e !== undefined);
        if (
          foundFeature &&
          Object.keys(foundFeature).length &&
          Object.keys(foundFeature[0]).length
        ) {
          setSectionTitle(foundFeature[0].title.toUpperCase());
        }
      }
    }
  }, [identifier, menuData]);

  return (
    <div className={`common-section-header-container ${containerClass}`}>
      <h4 className={`common-section-header-title ${showButton && 'pt-1'}`}>
        {identifier ? removeHtmlContent(sectionTitle) : title}
      </h4>
      <div className={`d-flex flex-row align-items-center ${isSearchEnabled && 'w-75'}`}>
        {showSearch && (
          <div className="d-flex w-75">
            {isSearchEnabled && (
              <form
                className="d-flex flex-grow-1 w-60 border border-1 border-dark"
                onSubmit={onSearchSubmitHandler}
                ref={textInputRef}>
                <input
                  autoFocus
                  className="ml-3 directory-search-bar"
                  type="text"
                  placeholder="Search.."
                  onChange={callSubmitFunction}
                />
              </form>
            )}

            {isSearchEnabled ? (
              <div onClick={resetSearchHandler}>
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
                  )}
                  className="header-icon mr-3"
                />
              </div>
            ) : (
              <div onClick={onClickSearchHandler}>
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_HEADER_COMPONENTS.SEARCH
                  )}
                  className="header-icon mr-3"
                />
              </div>
            )}
          </div>
        )}
        {showButton && (
          <div>
            <CustomButton
              buttonStyle={buttonStyle}
              buttonMainContainer="text-nowrap"
              disabled={disabled}
              showLoader={showButtonLoader}
              onClick={onClick}>
              {buttonTitle}
            </CustomButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionHeader;
