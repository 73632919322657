import React, { useState, useRef, useCallback } from 'react';
import { Form, Modal } from 'react-bootstrap';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ERROR_MESSAGES,
  SUPPORTED_IMAGE_FORMATS,
  WARNING_MESSAGES,
} from '../../../common/constants/AppConstants';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import { BiCrop, BiUpload } from 'react-icons/bi';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useDropzone } from 'react-dropzone';
import { getRandomFileName, showToast } from '../../../utils/common';
import { saveProgressLoadingState } from '../../../state/UIState';
import { selectMaxFileUploadSizeConfigProfile } from '../../../state/MasterData';
import { saveEmployeeData, selectEmployeeData } from '../../../state/EmployeeData';
import { uploadDocuments } from '../../../utils/commonApiRequests';

const UploadProfilePhotoModal = ({ showModal, setShowModal }) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const [isLoading, setIsLoading] = useState(false);
  const [profileImage, setProfileImage] = useState();
  const [cropper, setCropper] = useState();
  const [showCroppedPreview, setShowCroppedPreview] = useState(false);
  const [cropData, setCropData] = useState('#');
  const inputFileRef = useRef();
  const dispatch = useDispatch();
  const maxFileUploadSizeInMB = useSelector(selectMaxFileUploadSizeConfigProfile);
  const employeeData = useSelector(selectEmployeeData);

  const getCropDataHandler = async () => {
    if (cropper !== undefined) {
      try {
        await fetch(cropData)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], `${getRandomFileName()}.png`, { type: 'image/png' });
            onDrop([file]);
          });
      } catch (err) {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
        showToast(ERROR_MESSAGES.DEFAULT);
        console.warn('Err', err);
      }
    }
  };

  const cropImageHandler = () => {
    if (cropper !== undefined) {
      setShowCroppedPreview(true);
      setCropData(cropper.getCroppedCanvas().toDataURL());
    }
  };

  const cancelChangesHandler = () => {
    setShowModal(false);
    setCropper();
    setShowCroppedPreview(false);
    setCropData('#');
    setProfileImage();
  };

  const onPressUploadImageHandler = () => {
    inputFileRef.current.click();
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach(async (file) => {
        if (Number(file.size) / (1024 * 1024) > maxFileUploadSizeInMB) {
          showToast(WARNING_MESSAGES.FILE_SIZE_EXCEEDED(maxFileUploadSizeInMB));
        } else {
          dispatch(
            saveProgressLoadingState({
              isProgressLoading: true,
            })
          );
          setShowModal(false);
          uploadDocuments(file, 'profilephoto')
            .then((respose) => {
              const profilePicture = {
                ...employeeData.profile_picture,
                base_url: respose.base_url,
                image_path: respose.fileName,
              };
              const temp = { ...employeeData, profile_picture: profilePicture };
              dispatch(saveEmployeeData(temp));
            })
            .catch((e) => {
              console.error(e);
              showToast(e.message);
            })
            .finally(() => {
              dispatch(
                saveProgressLoadingState({
                  isProgressLoading: false,
                })
              );
              cancelChangesHandler();
            });
        }
      });
    },
    [dispatch, employeeData, maxFileUploadSizeInMB]
  );

  const { getRootProps } = useDropzone({ onDrop, maxFiles: 1 });

  return (
    <div>
      <Modal
        size="lg"
        onHide={() => {
          setShowModal(false);
        }}
        show={showModal}
        backdrop="static"
        keyboard={false}
        centered>
        <Modal.Header closeButton>
          <Modal.Title />
        </Modal.Header>
        <Modal.Body className="m-2">
          {!profileImage && (
            <div>
              <div className="d-flex align-items-center justify-content-center">
                <div className="my-profile-image-popup w-50 pt-4 pb-4">
                  <div
                    className="d-flex align-items-center justify-content-center"
                    {...getRootProps()}>
                    <img
                      src={getFeatureComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_UPLOAD_PHOTO,
                        ACCESSIBILITY_IDENTIFIERS.MICROGIVE
                      )}
                      className="my-profile-edit-upload-photo-icon"
                    />
                  </div>
                  <div className="d-flex align-items-center justify-content-center mt-4 ml-3 mr-3">
                    <div className="my-profile-edit-upload-photo-text">Drop your photo here or</div>
                    <div
                      className="my-profile-edit-upload-photo-browse ml-1 common-cursor-pointer"
                      onClick={onPressUploadImageHandler}>
                      {`browse`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!showCroppedPreview && (
            <Form.Group className="m-0">
              <Form.File
                style={{ display: 'none' }}
                ref={inputFileRef}
                onChange={(e) => {
                  e.preventDefault();
                  let files;
                  if (e.dataTransfer) {
                    files = e.dataTransfer.files;
                  } else if (e.target) {
                    files = e.target.files;
                  }
                  const reader = new FileReader();
                  reader.onload = () => {
                    setProfileImage(reader.result);
                  };
                  reader.readAsDataURL(files[0]);
                }}
                accept={SUPPORTED_IMAGE_FORMATS}
                label="Change photo"
                custom
              />
            </Form.Group>
          )}

          {profileImage && (
            <div>
              {showCroppedPreview ? (
                <img
                  className="mb-4 img-center"
                  style={{ height: 500 }}
                  src={cropData}
                  alt="cropped"
                />
              ) : (
                <Cropper
                  className="mt-4 mb-4"
                  style={{ width: '100%', height: 500 }}
                  initialAspectRatio={1}
                  aspectRatio={1}
                  preview=".img-preview"
                  src={profileImage}
                  viewMode={1}
                  guides={true}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                />
              )}
              {showCroppedPreview ? (
                <div className="d-flex justify-content-center">
                  <CustomButton buttonStyle="mr-3" onClick={cancelChangesHandler}>
                    <AiOutlineCloseCircle size="1.3em" className="mr-1" />
                    Cancel
                  </CustomButton>
                  <CustomButton
                    showLoader={isLoading}
                    buttonStyle="pl-3 pr-3"
                    onClick={getCropDataHandler}>
                    <BiUpload size="1.2em" className="mr-1" />
                    Upload Photo
                  </CustomButton>
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <CustomButton buttonStyle="mr-3" onClick={cancelChangesHandler}>
                    <AiOutlineCloseCircle size="1.3em" className="mr-1" />
                    Cancel
                  </CustomButton>
                  <CustomButton onClick={cropImageHandler}>
                    <BiCrop size="1.2em" className="mr-1 ml-1" />
                    Crop Photo
                  </CustomButton>
                </div>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UploadProfilePhotoModal;
