import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NominationsRequestsTab from './tabs/NominationsRequestsTab';
import { selectCheersNominations } from '../../state/MasterData';
import { Tab } from 'react-bootstrap';
import {
  saveCheersNominationRequestTabData,
  selectCheersNominationRequestTabData,
} from '../../state/TabData';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import NavTabs from '../../common/ui/tabs/NavTabs';

const ViewNominationsScreen = () => {
  const dispatch = useDispatch();
  const cheersNominations = useSelector(selectCheersNominations);
  const nominationRequestTabData = useSelector(selectCheersNominationRequestTabData);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.VIEW_NOMINATIONS_SCREEN,
      NAVIGATION_ROUTES.VIEW_NOMINATIONS_SCREEN
    );
  }, []);

  useEffect(() => {
    if (cheersNominations.length && nominationRequestTabData === '') {
      dispatch(saveCheersNominationRequestTabData(cheersNominations[0].key));
    }
  }, [cheersNominations, dispatch, nominationRequestTabData]);

  const renderTabs = (tab) => {
    switch (tab.key) {
      case 'pending':
        return <NominationsRequestsTab tabKey={tab.key} />;
      case 'all':
        return <NominationsRequestsTab tabKey={tab.key} />;
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CHEERS} />
      <BackButton />
      <NavTabs
        activeKey={nominationRequestTabData}
        onSelect={(k) => {
          dispatch(saveCheersNominationRequestTabData(k));
        }}>
        {cheersNominations.map((tab, index) => {
          return (
            <Tab key={`${index}`} eventKey={tab.key} title={tab.title}>
              {renderTabs(tab)}
            </Tab>
          );
        })}
      </NavTabs>
    </div>
  );
};

export default ViewNominationsScreen;
