import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  NAVIGATION_ROUTES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { getFeatureComponent } from '../../../../utils/accessibility';
import {
  formatDateDDMonthYYYY,
  formatSecondsIntoMinutes,
  formatSecondsIntoTimeForVideos,
  getUrlWithSpecificRendition,
} from '../../../../utils/common';
import PlayIconOverlay from '../../../../common/ui/play_icon_overlay/PlayIconOverlay';

const ClassicText = ({
  itemId,
  itemTitle,
  itemImageBaseUrl,
  itemImagePath,
  itemLoadViaUrlFlag,
  itemExternalUrl,
  sectionIdentifier,
  pageViews,
  timeToRead,
  content_load_type,
  video_duration = 0,
  endDateTime = null,
  active = true,
  question,
  pollId,
  surveyId,
  pubDateTime,
}) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  const toLinkHandler = () => {
    const route =
      '/' +
      getFeatureComponent(
        accessibilityData,
        ACCESSIBILITY_FEATURE_COMPONENTS.ROUTE,
        sectionIdentifier
      );
    switch (route) {
      case NAVIGATION_ROUTES.NEWS:
        if (itemLoadViaUrlFlag) {
          return itemExternalUrl;
        }
        return `${NAVIGATION_ROUTES.NEWS}/${itemId}`;
      case NAVIGATION_ROUTES.DEAR_EMPLOYER:
        return `${NAVIGATION_ROUTES.DEAR_EMPLOYER}/${itemId}`;
      case NAVIGATION_ROUTES.BROADCASTS:
        if (itemLoadViaUrlFlag) {
          return itemExternalUrl;
        }
        return `${NAVIGATION_ROUTES.BROADCASTS}/${itemId}`;
      case NAVIGATION_ROUTES.PANDEMIC_SUPPORT:
        return `${NAVIGATION_ROUTES.DEAR_EMPLOYER}/${itemId}`;
      case NAVIGATION_ROUTES.MLTV:
        return `${NAVIGATION_ROUTES.MLTV}/${itemId}`;
      case NAVIGATION_ROUTES.ANNIVERSARY_DAY_SCREEN:
        if (itemLoadViaUrlFlag) {
          return itemExternalUrl;
        }
        return `${NAVIGATION_ROUTES.ANNIVERSARY_ARTICLE_SCREEN}/${itemId}`;
      case NAVIGATION_ROUTES.MICROBIZ:
        if (itemLoadViaUrlFlag) {
          return itemExternalUrl;
        }
        return `${NAVIGATION_ROUTES.MICROBIZ_DETAIL}/${itemId}`;
      case NAVIGATION_ROUTES.BTO:
        if (itemLoadViaUrlFlag) {
          return itemExternalUrl;
        }
        return `${NAVIGATION_ROUTES.BTO_DETAIL}/${itemId}`;
      case NAVIGATION_ROUTES.EVENTS:
        return `${route}/${itemId}`;
      case NAVIGATION_ROUTES.CONTESTS:
        return `${route}/${itemId}`;
      case NAVIGATION_ROUTES.POLLS_AND_SURVEYS:
        if (sectionIdentifier === '_polls') {
          return `${NAVIGATION_ROUTES.POLLS_NOTIFICATION_SCREEN}/${pollId}`;
        } else {
          return `${NAVIGATION_ROUTES.SURVEYS_NOTIFICATION_SCREEN}/${surveyId}`;
        }
      case NAVIGATION_ROUTES.MI_HR_SUPPORT:
        if (itemLoadViaUrlFlag) {
          return itemExternalUrl;
        }
        return `${NAVIGATION_ROUTES.MI_HR_SUPPORT}/${itemId}`;
    }
  };

  return (
    <Link
      className="col-12 col-sm-6 col-md-4 common-unstyled-achor"
      to={{ pathname: toLinkHandler() }}
      target={itemLoadViaUrlFlag ? '_blank' : '_self'}>
      <div className="pt-3 home-classic-text-container">
        {content_load_type === 3 && <PlayIconOverlay />}
        <img
          className="embed-responsive embed-responsive-16by9 mb-3"
          src={getUrlWithSpecificRendition(
            itemImageBaseUrl,
            SCREEN_DENSITY_QUALIFIERS.HDPI,
            itemImagePath
          )}
        />
        <div className="home-classic-text-title">
          {itemTitle?.length > 70 ? `${itemTitle?.substring(0, 70)}...` : itemTitle}
        </div>
        {question && (
          <div className="home-classic-text-title">
            {question?.length > 70 ? `${question?.substring(0, 70)}...` : question}
          </div>
        )}
        <div className="d-flex flex-row justify-content-between">
          {!!video_duration > 0 && content_load_type === 3 && (
            <div className="article-time-to-read-text">
              {formatSecondsIntoTimeForVideos(video_duration)}
            </div>
          )}
          {endDateTime && (
            <div className="article-time-to-read-text">
              {sectionIdentifier === '_polls' &&
                endDateTime &&
                (active
                  ? 'Till ' + formatDateDDMonthYYYY(endDateTime)
                  : 'Poll Ended on ' + formatDateDDMonthYYYY(endDateTime))}
              {sectionIdentifier === '_surveys' &&
                endDateTime &&
                (active
                  ? 'Till ' + formatDateDDMonthYYYY(endDateTime)
                  : 'Survey Ended on ' + formatDateDDMonthYYYY(endDateTime))}
            </div>
          )}
          <div className="d-flex flex-row justify-content-between w-100">
            {!!timeToRead != 0 ? (
              <div className="article-time-to-read-text">
                {formatSecondsIntoMinutes(timeToRead)}
              </div>
            ) : null}
            {!!pubDateTime != 0 ? (
              <div className="article-time-to-read-text">{formatDateDDMonthYYYY(pubDateTime)}</div>
            ) : null}
            {!!pageViews && (
              <div className="article-time-to-read-text">
                {pageViews > 1 ? `${pageViews} views` : `${pageViews} view`}
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ClassicText;
