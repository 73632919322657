import React, { useState, useEffect } from 'react';
import CustomLinkify from '../../../common/ui/custom_linkify/CustomLinkify';

const TextChatView = ({ message }) => {
  const [textMessage, setTextMessage] = useState(message);
  const extractUsername = (text) => {
    const regex = /@\[([^[\]]+)\]\([^)]+\)/g;
    const matches = text.match(regex);
    if (matches) {
      return text.replace(regex, (match) => {
        const username = match.match(/@\[([^[\]]+)\]/)[1];
        return `<span style="color: #1877F2;">${username}</span>`;
      });
    }
    return text;
  };
  useEffect(() => {
    // Regex to find @[Name](uid-12345) pattern and extract Name
    const replacedMessage = extractUsername(message);

    setTextMessage(replacedMessage.replace(/\n/g, '<br>'));
  }, [message]);
  return (
    <div>
      <CustomLinkify>
        <div className="chat-base-text-sm" dangerouslySetInnerHTML={{ __html: textMessage }} />
        {/* <div className="chat-base-text-sm" >{textMessage} </div> */}
      </CustomLinkify>
    </div>
  );
};

export default TextChatView;
