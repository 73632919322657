import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getFeatureComponentUrl } from '../../../utils/accessibility';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  CELEBRATION_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { formatDateDDMonthYYYY, replaceWhitespacesWithKey } from '../../../utils/common';
import { selectShowEmptyContentView } from '../../../state/UIState';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';

const Tab = (props) => {
  const location = useLocation();
  const accessibilityData = useSelector(selectAccessibilityData);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const getTabData = props.getTabData;

  useEffect(() => {
    if (location.state) {
      if (location.state.isChatbotActionable) {
        if (location.state.chatbotParams.celebrationType !== props.tabKey) {
          getTabData(props.tabKey);
        }
      } else {
        getTabData(props.tabKey);
      }
    } else {
      getTabData(props.tabKey);
    }
  }, [getTabData, location.state, props.tabKey]);

  useEffect(() => {
    logEvent(
      `CELEBRATIONS_${replaceWhitespacesWithKey(props.tabKey.toUpperCase(), '_')}_TAB_VISIT`,
      '',
      ANALYTICS_ITEM_NAMES.CELEBRATIONS
    );
  }, [props.tabKey]);

  const onPressCelebrationProfileHandler = (employeeId) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.CELEBRATION_PROFILE_SELECTED,
      employeeId.toString(),
      ANALYTICS_ITEM_NAMES.CELEBRATION_PROFILE
    );
  };

  const showProfiles = () => {
    return props.data.employees.map((profile) => {
      return (
        <div className="col-6 col-sm-3 col-lg-2 p-3" key={profile.employee_id}>
          <UserProfilePhoto
            imageBaseUrl={profile.profile_picture.base_url}
            imagePath={profile.profile_picture.image_path}
            imageClass="common-user-profile-photo-xl mb-2"
            showBadge={true}
            prependOnClick={() => onPressCelebrationProfileHandler(profile.employee_id)}
            profileData={profile}
          />

          {props.tabKey === CELEBRATION_IDENTIFIERS.NEW_JOINEE && (
            <p className="directory-profile-subtitle">
              {'DOJ ' + formatDateDDMonthYYYY(profile.date_of_joining, false)}
            </p>
          )}
          <p className="directory-profile-name">{profile.first_name}</p>
          <p className="directory-profile-name">{profile.last_name}</p>
          <p className="directory-profile-subtitle">{profile.position_title}</p>
        </div>
      );
    });
  };

  const showEmptyListView = () => {
    return (
      <DefaultContentView
        message={`Sorry, no ${props.tabTitle.toLowerCase()} available`}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  return (
    <div>
      <ActivityLoader visible={props.isLoading} />
      {props.data && (
        <div>
          {showEmptyContentView && !props.data.employees.length && !props.isLoading ? (
            showEmptyListView()
          ) : (
            <div className="row overflow-hidden">{showProfiles()}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(Tab);
