import React, { useState, useEffect } from 'react';

const CustomRadioBtn = (props) => {
  const [radioSelected, setRadioSelected] = useState();

  useEffect(() => {
    if (props.radioSelected) {
      setRadioSelected(props.radioSelected);
    }
  }, [props.radioSelected]);

  return (
    <div>
      {props.products.map((item) => {
        return (
          <div key={item.id} className="form-check ml-1 mt-1">
            <input
              type="radio"
              className="form-check-input"
              checked={radioSelected && item.id === radioSelected.id ? true : false}
              onChange={() => {
                setRadioSelected(item);
                props.getRadioVal(item);
              }}
            />
            <label className="form-check-label">{item.name}</label>
          </div>
        );
      })}
    </div>
  );
};

export default CustomRadioBtn;
