import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FEATURE_TYPE,
  LOADING_MESSAGES,
  REQUEST_TYPE,
  NAVIGATION_ROUTES,
  BUTTONTEXT,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  ANALYTICS_EVENT_TYPES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../common/constants/AppConstants';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import HtmlView from '../../common/ui/html_view/HTMLView';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { EVENTS_BY_ID } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import {
  formatDateDDMonthYYYY,
  formatDateDDthMonthYYYY,
  formatHHMMSSTimeAMPM,
  getUrlWithSpecificRendition,
  showToast,
} from '../../utils/common';
import { useParams } from 'react-router-dom';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { selectAccessData } from '../../state/UserAccessData';

const EventContentScreen = ({ history }) => {
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const [events, setEvents] = useState();
  const [showButton, setShowButton] = useState(false);
  const [buttonText, setButtonText] = useState('');
  const [buttonDisable, setButtonDisable] = useState(false);
  const accessData = useSelector(selectAccessData);
  const [showRegisterButton, setShowRegisterButton] = useState(true);
  const eventsAccessData = accessData?.data?.find((item) => item.feature_key === '_events');

  useEffect(() => {
    if (eventsAccessData) {
      const actions = eventsAccessData?.actions;
      setShowRegisterButton(actions?.includes('_register'));
    }
  }, [eventsAccessData]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.EVENTS_CONTENT, NAVIGATION_ROUTES.EVENTS_CONTENT);
  }, []);

  useEffect(() => {
    setShowButton(false);
    if (events) {
      if (events?.gallary?.pictures?.length > 0) {
        setButtonText(BUTTONTEXT.VIEW_GALLERY);
        setButtonDisable(false);
        setShowButton(true);
      }
      if (events?.registrations?.allowed_register) {
        if (events?.registrations.is_registered) {
          setButtonText(BUTTONTEXT.ALREADY_REGISTER);
          setButtonDisable(true);
          setShowButton(true);
        } else {
          setButtonText(BUTTONTEXT.REGISTER);
          setButtonDisable(false);
          setShowButton(true);
        }
      }
    }
  }, [events]);

  const callEvents = useCallback(async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.FETCH_EVENTS,
        })
      );
      const apiResponse = await apiRequest(EVENTS_BY_ID + eventId, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.event) {
            const data = apiResponse.response.data.event;
            setEvents(data);
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(err, {}, EVENTS_BY_ID + eventId, NAVIGATION_ROUTES.EVENTS_CONTENT);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, eventId]);

  const callPostRegister = async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.EVENT_REGISTER,
      })
    );
    const formData = new URLSearchParams();
    formData.append('type', FEATURE_TYPE.EVENTS);
    try {
      const commentApiResponse = await apiRequest(
        EVENTS_BY_ID + eventId + '/apply',
        REQUEST_TYPE.POST,
        formData,
        {}
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      if (Object.keys(commentApiResponse).length > 0) {
        if (commentApiResponse.response.status === true) {
          showToast(commentApiResponse?.response?.data?.message);
          callEvents();
        }
      }
    } catch (err) {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      handleError(
        err,
        formData,
        EVENTS_BY_ID + eventId + '/apply',
        NAVIGATION_ROUTES.EVENTS_CONTENT
      );
    }
  };

  const callButtonFunction = () => {
    if (events) {
      if (events?.registrations?.allowed_register && !events?.registrations.is_registered) {
        logEvent(ANALYTICS_EVENT_TYPES.EVENTS_REGISTER, events?.event_id, '');
        callPostRegister();
      } else if (events?.gallary.pictures.length > 0) {
        logEvent(ANALYTICS_EVENT_TYPES.EVENTS_VIEW_GALLERY, events?.event_id, '');
        history.push(NAVIGATION_ROUTES.EVENTS_GALLERY, {
          gallary: events?.gallary,
          likes: events?.likes,
          eventId: events?.event_id,
        });
      }
    }
  };

  const renderScreen = useCallback(async () => {
    callEvents();
  }, [callEvents]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  return (
    <div className="main-content-container container">
      <SectionHeader
        containerClass="d-flex flex-row justify-content-between"
        identifier={ACCESSIBILITY_IDENTIFIERS.EVENTS}
      />
      <div className="header-view pt-0 mb-2 mt-3 justify-content-between">
        <div>
          <BackButton isFullRow={false} />
        </div>
        <div className="row pr-3">
          {events && events?.registrations?.allowed_register && showRegisterButton && (
            <div
              className={
                showButton
                  ? 'pr-5 d-flex align-self-center align-items-center'
                  : 'd-flex align-self-center align-items-center'
              }>
              {events?.registrations.max_limit - events?.registrations.total_register === 1
                ? `${
                    events?.registrations.max_limit - events?.registrations.total_register
                  } seat left`
                : `${
                    events?.registrations.max_limit - events?.registrations.total_register
                  } seats left`}
            </div>
          )}
          {showButton && showRegisterButton && (
            <CustomButton
              buttonStyle={
                buttonDisable ? 'event_btn_disabled_style' : 'contests-upload-photos-btn'
              }
              onClick={callButtonFunction}
              disabled={buttonDisable}
              showLoader={progressLoadingState.isProgressLoading}>
              {buttonText}
            </CustomButton>
          )}
        </div>
      </div>
      {events && (
        <div className="mt-3">
          <div>
            <img
              src={getUrlWithSpecificRendition(
                events?.promo_image?.base_url,
                SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
                events?.promo_image?.image_path
              )}
              className="embed-responsive embed-responsive-16by9"
            />
          </div>
          <div className="event-title">{events?.title}</div>
          {events?.start_date && events?.end_date && (
            <div className="event-desccription-text event-desccription-text-container">
              {events?.start_date === events?.end_date ? 'Date' : 'Start date'} :{' '}
              {formatDateDDthMonthYYYY(events?.start_date)}
            </div>
          )}
          {/* Show end date only when the start and end date vary */}
          {!(events?.start_date === events?.end_date) && (
            <div className="event-desccription-text event-desccription-text-container">
              End date : {formatDateDDthMonthYYYY(events?.end_date)}
            </div>
          )}
          {events?.start_time && events?.end_time && (
            <div className="event-desccription-text event-desccription-text-container">
              Timings :{' '}
              {`${formatHHMMSSTimeAMPM(events?.start_time)} - ${formatHHMMSSTimeAMPM(
                events?.end_time
              )}`}
            </div>
          )}
          {events?.registrations?.allowed_register && events?.registrations.last_date && (
            <div className="event-desccription-text event-desccription-text-container">
              Last date of registration : {formatDateDDMonthYYYY(events?.registrations.last_date)}
            </div>
          )}
          {events?.event_location && (
            <div className="event-desccription-text event-desccription-text-container">
              Venue : {events?.event_location}
            </div>
          )}
          {events?.description !== '' && (
            <HtmlView html={events?.description} htmlStyle="mt-3 event-content-html" />
          )}
        </div>
      )}
    </div>
  );
};

export default EventContentScreen;
