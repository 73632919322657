import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ERROR_MESSAGES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { formatDateDDMonthYYYY, getUrlWithSpecificRendition } from '../../../utils/common';
import PlayIconOverlay from '../../../common/ui/play_icon_overlay/PlayIconOverlay';

const PollsListItem = ({ item, onPressViewResult, submitVote }) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  return (
    <div className="col-sm-4 mb-3">
      {item?.content_load_type === '3' && <PlayIconOverlay />}
      <img
        src={getUrlWithSpecificRendition(
          item.promo_image.base_url,
          SCREEN_DENSITY_QUALIFIERS.HDPI,
          item.promo_image.image_path
        )}
        className="embed-responsive embed-responsive-16by9"
      />
      {item.active && item.voted && (
        <div className="mt-1 polls-participated-text ml-auto">
          {ERROR_MESSAGES.POLL_PARTICIPATED}
        </div>
      )}
      <div className="polls-cross-axis-view mt-1">
        <img
          src={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.NEWS_CALENDER,
            ACCESSIBILITY_IDENTIFIERS.NEWS
          )}
          className="polls-calender-style"
        />
        <div className="ml-1 polls-date-text">
          {item.active
            ? `Till ${formatDateDDMonthYYYY(item.end_datetime)}`
            : `Poll ended on ${formatDateDDMonthYYYY(item.end_datetime)}`}
        </div>
      </div>
      <div className="polls-title-text mt-1">{item.question}</div>

      <div>
        {item.active && !item.voted && (
          <div className="polls-category-container">
            {item.results.options &&
              item.results.options.length > 0 &&
              item.results.options.map((itemData, index) => {
                return (
                  <div
                    key={`${index}`}
                    className="polls-categories mt-2"
                    onClick={() => submitVote(item.poll_id, itemData.option_id)}>
                    {itemData.title}
                  </div>
                );
              })}
          </div>
        )}
      </div>

      {!item.active && (
        <CustomButton
          buttonStyle="polls-button-style mr-0 mt-1"
          onClick={() => onPressViewResult(item)}>
          View results
        </CustomButton>
      )}
    </div>
  );
};

export default PollsListItem;
