import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ERROR_MESSAGES,
  FILTER_IDENTIFIERS,
  MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../../common/constants/AppConstants';
import debounce from 'lodash.debounce';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import Filter from '../../../common/ui/filter/Filter';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { EMPLOYEES } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { saveProgressLoadingState, selectShowEmptyContentView } from '../../../state/UIState';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../../utils/accessibility';
import { selectCommunityPostTagPeopleLimit } from '../../../state/MasterData';
import { showToast } from '../../../utils/common';

const TagEmployeeView = ({ onEmployeeSelected, tags }) => {
  const dispatch = useDispatch();
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeArray, setEmployeeArray] = useState([]);
  const [searchText, setSearchText] = useState('');
  const pageIdRef = useRef(1);
  const loadMoreFlagRef = useRef(true);
  const appliedFiltersRef = useRef({});
  const accessibilityData = useSelector(selectAccessibilityData);
  const communityPostTagPeopleLimit = useSelector(selectCommunityPostTagPeopleLimit);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const [showLoadMore, setShowLoadMore] = useState(false);

  useEffect(() => {
    setEmployeeArray(tags);
  }, [tags]);

  const callEmployeesApi = useCallback(
    async (searchTextValue = '') => {
      if (loadMoreFlagRef.current === true) {
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        if (pageIdRef.current == 1) {
          setEmployeeData([]);
        }
        let params = { page_id: pageIdRef.current };
        params = { ...params, ...{ query: searchTextValue } };
        if (Object.keys(appliedFiltersRef.current).length) {
          params = { ...params, ...appliedFiltersRef.current };
        }
        try {
          const apiResponse = await apiRequest(EMPLOYEES, REQUEST_TYPE.GET, params);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length) {
            if (apiResponse.response.status) {
              if (apiResponse.response.data.feeds.length > 0) {
                if (pageIdRef.current === 1) {
                  setEmployeeData(apiResponse.response.data.feeds);
                } else {
                  setEmployeeData((oldArray) => [...oldArray, ...apiResponse.response.data.feeds]);
                }
                setShowLoadMore(true);
                loadMoreFlagRef.current = true;
                pageIdRef.current = pageIdRef.current + 1;
              } else {
                setShowLoadMore(false);
                loadMoreFlagRef.current = false;
              }
            }
          }
        } catch (err) {
          handleError(err, {}, EMPLOYEES, NAVIGATION_ROUTES.TAGGED_PEOPLE_SCREEN);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    callEmployeesApi();
  }, [callEmployeesApi]);

  const addEmployee = (employee) => {
    if (employeeArray.some((person) => person.employee_id === employee.employee_id)) {
      const index = employeeArray.findIndex(
        (person) => person.employee_id === employee.employee_id
      );
      if (index > -1) {
        setEmployeeArray(employeeArray.filter((item) => item !== employeeArray[index]));
      }
    } else {
      if (employeeArray.length === communityPostTagPeopleLimit) {
        showToast(`${MESSAGES.CANNOT_TAG(communityPostTagPeopleLimit)}`);
      } else {
        setEmployeeArray((prev) => [...prev, employee]);
      }
    }
  };

  const onClickFilterHandler = (selectedFilters) => {
    let tempFilterObject = {};
    Object.keys(selectedFilters).map((filterKey) => {
      if (selectedFilters[filterKey].length) {
        tempFilterObject[filterKey] = selectedFilters[filterKey].join();
      }
    });
    appliedFiltersRef.current = tempFilterObject;
    setEmployeeData([]);
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    callEmployeesApi(searchText);
  };

  const loadMoreHandler = () => {
    callEmployeesApi(searchText);
  };

  const debouncedCallback = (event) => {
    if (event && event.target.value.length > 2) {
      pageIdRef.current = 1;
      loadMoreFlagRef.current = true;
      callEmployeesApi(event.target.value);
    }
    setSearchText(event.target.value);
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );

  const onSearchSubmitHandler = (event) => {
    event.preventDefault();
    setEmployeeData([]);
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    callEmployeesApi(searchText);
  };

  const onClickSearchHandler = () => {
    setIsSearchEnabled(true);
  };

  const resetSearchHandler = useCallback(() => {
    setIsSearchEnabled(false);
    setSearchText('');
    setEmployeeData([]);
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    callEmployeesApi();
  }, [callEmployeesApi]);

  const showEmptyListView = () => {
    return (
      <DefaultContentView
        message={ERROR_MESSAGES.DIRECTORY_NO_DATA}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  const showProfiles = () => {
    if (employeeData.length > 0) {
      return (
        <div className="row">
          {employeeData.map((profile, index) => {
            return (
              <div key={`${index}`} className="thewall-emp-image-div col-6 col-sm-3 col-lg-2">
                <UserProfilePhoto
                  imageBaseUrl={profile.profile_picture.base_url}
                  imagePath={profile.profile_picture.image_path}
                  employeeId={profile.employee_id}
                  imageClass="common-user-profile-photo-lg"
                />
                <p className="thewall-profile-name">{profile.first_name}</p>
                <p className="thewall-profile-name">{profile.last_name}</p>
                <div className="thewall-check-row">
                  <input
                    type="checkbox"
                    id="employeeCheck"
                    name="employeeCheck"
                    className="thewall-employee-check-box"
                    onChange={() => addEmployee(profile)}
                    checked={
                      employeeArray.some((person) => person.employee_id === profile.employee_id)
                        ? true
                        : false
                    }
                  />
                  <div>Tag</div>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else
      return (
        <DefaultContentView
          message={ERROR_MESSAGES.SEARCH_NO_RESULTS}
          iconUri={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
            ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
          )}
        />
      );
  };

  return (
    <div className="mt-3 mb-3">
      <div className="header-view d-flex justify-content-between">
        {isSearchEnabled ? (
          <form className="thewall-search-form" onSubmit={onSearchSubmitHandler}>
            <input
              autoFocus
              className="ml-3 thewall-search-bar"
              type="text"
              placeholder="Search.."
              onChange={debounceInputHandler}
            />
          </form>
        ) : (
          <div />
        )}
        {isSearchEnabled ? (
          <div onClick={resetSearchHandler}>
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
              )}
              className="header-icon mr-3"
            />
          </div>
        ) : (
          <div onClick={onClickSearchHandler}>
            <img
              src={getHeaderComponentUrl(accessibilityData, ACCESSIBILITY_HEADER_COMPONENTS.SEARCH)}
              className="header-icon mr-3"
            />
          </div>
        )}
      </div>
      <div className="thewall-body-container">
        <Filter
          topFilter={FILTER_IDENTIFIERS.GEOGRAPHIES}
          geographies={true}
          locations={true}
          levels={true}
          layers={true}
          serviceTenures={true}
          bloodGroups={true}
          onClick={onClickFilterHandler}
        />
      </div>
      <div className="ml-4 mb-3">
        {employeeArray.length < communityPostTagPeopleLimit && (
          <div className="communities-tag-member">{`${employeeArray.length}/${communityPostTagPeopleLimit} selected`}</div>
        )}
        {employeeArray.length === communityPostTagPeopleLimit && (
          <div className="communities-tag-people-limit">{`${employeeArray.length}/${communityPostTagPeopleLimit} selected`}</div>
        )}
      </div>
      {showEmptyContentView &&
      !employeeData.length &&
      !saveProgressLoadingState.isProgressLoading ? (
        showEmptyListView()
      ) : (
        <div>
          <div className="thewall-employee-view mt-2">{showProfiles()}</div>
          {showLoadMore && (
            <div className="load-more">
              <LoadMoreBtn onClick={loadMoreHandler} />
            </div>
          )}
        </div>
      )}
      {employeeArray.length > 0 && (
        <div
          onClick={() => onEmployeeSelected(employeeArray)}
          className="thewall-continue-button mr-3 mt-2 common-cursor-pointer">
          Continue
        </div>
      )}
    </div>
  );
};

export default TagEmployeeView;
