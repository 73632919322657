import React, { useEffect } from 'react';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
} from '../../../common/constants/AppConstants';
import { logEvent2 } from '../../../utils/FirebaseAnalyticsUtils';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import CustomTable from './CustomTable';

const VariablePayTab = ({ data, title, tabKey, template }) => {
  useEffect(() => {
    logEvent2({
      eventType: ANALYTICS_EVENT_TYPES.VARIABLE_PAY_TAB_VISIT,
      itemName: ANALYTICS_ITEM_NAMES.VARIABLE_PAY,
      categoryName: title,
    });
  }, [title]);

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_DATA_FOUND} />;
  };

  const RenderPlanInfo = (title, value) => (
    <div className="d-flex flex-row">
      <div className="vp-detail-title">{title}</div>
      <div className="vp-detail-value">{value}</div>
    </div>
  );

  const hasDetails =
    data?.bonus_plan ||
    data?.role_title ||
    data?.total_on_target_bonus ||
    data?.total_earned ||
    data?.total_payout;

  return (
    <div>
      {hasDetails && (
        <div className="p-4">
          {data?.bonus_plan && RenderPlanInfo('Bonus Plan: ', data.bonus_plan)}
          {data?.role_title && RenderPlanInfo('Role: ', data.role_title)}
          {data?.total_on_target_bonus &&
            RenderPlanInfo('On-Target Bonus: ', data?.total_on_target_bonus)}
          {data?.total_earned && data?.total_payout ? (
            <div>
              {RenderPlanInfo('Bonus Earned (YTD): ', data?.total_earned)}
              {RenderPlanInfo('Bonus Paid (YTD): ', data?.total_payout)}
            </div>
          ) : data?.total_payout ? (
            RenderPlanInfo('Commission Paid (YTD): ', data?.total_payout)
          ) : (
            data?.total_earned && RenderPlanInfo('*Total Payout Earned:', data?.total_earned)
          )}
        </div>
      )}
      {data?.matrix?.length > 0 || data?.incentives?.matrix?.length > 0 ? (
        <div className={`vp-table-container ${hasDetails && 'vp-border-top'}`}>
          <CustomTable data={data} tabKey={tabKey} />
          <CustomTable
            data={data.incentives}
            isIncentives={true}
            visibility={data?.incentives?.visibility}
            tabKey={tabKey}
          />
        </div>
      ) : (
        emptyListView()
      )}
    </div>
  );
};

export default VariablePayTab;
