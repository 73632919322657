import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  createOneOnOneChatGroup,
  createOrUpdateUser,
  fetchGroupDetailByIdOnce,
  fetchUserByIdOnce,
  fetchUserGroupsByIdOnce,
  generatePrivateChatId,
  sendMessageToGroup,
  uId,
} from '../../../utils/ChatHandler';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  CHAT_LEFT_PANEL_IDENTIFIERS,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { EMPLOYEES } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { saveProgressLoadingState } from '../../../state/UIState';
import {
  getFeatureComponentUrl,
  getFeatureComponentUrlLdpi,
  getHeaderComponentUrl,
} from '../../../utils/accessibility';
import { showToast } from '../../../utils/common';
import { selectForwardedMessage } from '../../../state/ChatData';
import NewChatListItem from './NewChatListItem';
import BackButton from '../../../common/ui/common_back_button/BackButton';
import Divider from '../../../common/ui/divider/Divider';
import ForwardMessageListItem from './ForwardMessageListItem';
import UserProfilePhotoForChats from '../../../common/ui/user_profile_photo/UserProfilePhotoForChats';

const ForwardMessageView = (props) => {
  const { setLeftPanelType } = props;
  const dispatch = useDispatch();
  const maxForwardSelectionLimit = 5;
  const accessibilityData = useSelector(selectAccessibilityData);
  const employeeData = useSelector(selectEmployeeData);
  const message = useSelector(selectForwardedMessage);
  const [dialogsList, setDialogsList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployeeList, setSelectedEmployeeList] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [dataLength, setDataLength] = useState(0);
  const loadMoreFlagRef = useRef(true);
  const pageIdRef = useRef(1);
  const textInputRef = useRef();
  const searchTextRef = useRef('');
  const employeeListItemRef = useRef();

  useEffect(() => {
    if (pageIdRef.current !== 1) {
      loadMoreFlagRef.current = false;
    }
  }, [dataLength]);

  const callEmployeeApi = useCallback(async () => {
    const params = {
      page_id: pageIdRef.current,
      query: searchTextRef.current,
    };
    try {
      loadMoreFlagRef.current = false;
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(EMPLOYEES, REQUEST_TYPE.GET, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      loadMoreFlagRef.current = true;
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setDataLength(apiResponse.response.data.feeds.length);
          if (pageIdRef.current === 1) {
            let empList = apiResponse.response.data.feeds;
            if (empList.some((person) => person.employee_id === employeeData.employee_id)) {
              empList = empList.filter((obj) => obj.employee_id !== employeeData.employee_id);
            }
            setEmployeeList(empList);
          } else {
            let empList = apiResponse.response.data.feeds;
            if (empList.some((person) => person.employee_id === employeeData.employee_id)) {
              empList = empList.filter((obj) => obj.employee_id !== employeeData.employee_id);
            }
            setEmployeeList((oldArray) => [...oldArray, ...empList]);
          }
        }
      }
    } catch (err) {
      handleError(err, params, EMPLOYEES, NAVIGATION_ROUTES.CHAT);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch, employeeData.employee_id]);

  useEffect(() => {
    const uid = uId(employeeData);
    fetchUserGroupsByIdOnce(uid, (isGroupExists, sGroupMap) => {
      if (isGroupExists) {
        let chatsArr = [];
        Object.values(sGroupMap).forEach((value) => {
          chatsArr.push(value);
        });
        chatsArr.sort((a, b) => {
          if (a.lastMessage === undefined && b.lastMessage === undefined) {
            return b.timestamp - a.timestamp;
          } else if (a.lastMessage === undefined) {
            return b.lastMessage.timestamp - a.timestamp;
          } else if (b.lastMessage === undefined) {
            return b.timestamp - a.lastMessage.timestamp;
          } else {
            return b.lastMessage.timestamp - a.lastMessage.timestamp;
          }
        });
        setDialogsList(chatsArr);
      }
    });
  }, [employeeData]);

  const onPressForwardListItemHandler = (
    item,
    idParameter = isSearch ? 'employee_id' : 'groupId'
  ) => {
    if (
      selectedEmployeeList.some(
        (member) => member[idParameter].toString() === item[idParameter].toString()
      )
    ) {
      setSelectedEmployeeList(
        selectedEmployeeList.filter(
          (member) => member[idParameter].toString() !== item[idParameter].toString()
        )
      );
    } else {
      if (selectedEmployeeList.length < maxForwardSelectionLimit) {
        setSelectedEmployeeList((prev) => [item, ...prev]);
      } else {
        showToast(`You cannot forward to more than ${maxForwardSelectionLimit} people`);
      }
    }
  };

  const renderUser = (item, index) => {
    return (
      <ForwardMessageListItem
        key={index.toString()}
        isEmployeeSelected={selectedEmployeeList
          .map((element) => element.groupId)
          .includes(item.groupId)}
        item={item}
        onPress={onPressForwardListItemHandler}
      />
    );
  };

  const renderEmployee = (item, index) => {
    return (
      <NewChatListItem
        key={index.toString()}
        isEmployeeSelected={selectedEmployeeList
          .map((element) => element.employee_id)
          .includes(item.employee_id)}
        item={item}
        onPress={onPressForwardListItemHandler}
      />
    );
  };

  const callChangeFunction = (event) => {
    searchTextRef.current = event.target.value;
  };

  const onSearchSubmitHandler = (event) => {
    event.preventDefault();
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    employeeListItemRef.current.scrollTo(0, 0);
    setDataLength(0);
    callEmployeeApi();
  };

  const resetSearchHandler = useCallback(() => {
    textInputRef.current.reset();
    searchTextRef.current = '';
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    employeeListItemRef.current.scrollTo(0, 0);
    setSelectedEmployeeList([]);
    setDataLength(0);
    setIsSearch(false);
    callEmployeeApi();
  }, [callEmployeeApi]);

  const onClickSearchHandler = () => {
    setIsSearch(true);
    setSelectedEmployeeList([]);
  };

  const onScrollEmployeeListItem = () => {
    if (employeeListItemRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = employeeListItemRef.current;
      if (Math.ceil(scrollTop) + clientHeight === scrollHeight) {
        if (loadMoreFlagRef.current) {
          pageIdRef.current = pageIdRef.current + 1;
          callEmployeeApi();
        }
      }
    }
  };

  const deleteEmployee = (employee) => {
    if (selectedEmployeeList.includes(employee)) {
      const index = selectedEmployeeList.indexOf(employee);
      if (index > -1) {
        setSelectedEmployeeList(
          selectedEmployeeList.filter((item) => item !== selectedEmployeeList[index])
        );
      }
    }
  };

  const createForwardMessagePrivateChat = (otherUserObj, gpId, otherUserId) => {
    createOneOnOneChatGroup(employeeData, otherUserObj, () => {
      sendForwardMessageToGroup(gpId, false, otherUserId);
    });
  };

  const sendForwardMessageToGroup = (gpId, isGroup, otherUserId) => {
    if (message) {
      sendMessageToGroup(
        gpId,
        employeeData,
        message.message,
        message.message_type,
        message.url,
        {},
        true,
        message.doc_type,
        message.doc_name,
        () => {
          dispatch(
            saveProgressLoadingState({
              isProgressLoading: false,
            })
          );
          if (isGroup) {
            setLeftPanelType(CHAT_LEFT_PANEL_IDENTIFIERS.DEFAULT_VIEW);
          } else if (isGroup === false) {
            setLeftPanelType(CHAT_LEFT_PANEL_IDENTIFIERS.DEFAULT_VIEW);
          }
        }
      );
    }
  };

  const sendMessageRecentChatSingleSelected = () => {
    if (selectedEmployeeList[0].group) {
      sendForwardMessageToGroup(selectedEmployeeList[0].groupId, selectedEmployeeList[0].group, '');
    } else {
      let members = selectedEmployeeList[0].groupId.split('_');
      let otherUserId = '';
      members.forEach((element) => {
        if (element !== uId(employeeData)) {
          otherUserId = element;
        }
      });
      sendForwardMessageToGroup(
        selectedEmployeeList[0].groupId,
        selectedEmployeeList[0].group,
        otherUserId
      );
    }
  };
  const sendMessageRecentChatMultipleSelected = () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.FORWARDING_MESSAGES,
      })
    );
    let i = selectedEmployeeList.length;
    selectedEmployeeList.forEach((element) => {
      sendMessageToGroup(
        element.groupId,
        employeeData,
        message.message,
        message.message_type,
        message.url,
        {},
        true,
        message.doc_type,
        message.doc_name,
        () => {
          i--;
          if (i === 0) {
            dispatch(
              saveProgressLoadingState({
                isProgressLoading: false,
              })
            );
            setLeftPanelType(CHAT_LEFT_PANEL_IDENTIFIERS.DEFAULT_VIEW);
          }
        }
      );
    });
  };

  const onPressSendHandler = () => {
    if (isSearch) {
      if (selectedEmployeeList.length === 1) {
        const otherUserId = uId(selectedEmployeeList[0]);
        const selfUserId = uId(employeeData);
        const gpId = generatePrivateChatId(selfUserId, otherUserId);
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
            progressMessage: LOADING_MESSAGES.FORWARDING_MESSAGES,
          })
        );
        fetchGroupDetailByIdOnce(gpId, (isGroupExist, groupModel) => {
          if (isGroupExist) {
            sendForwardMessageToGroup(gpId, false, otherUserId);
          } else {
            fetchUserByIdOnce(otherUserId, (isUserExist, otherUserDetail) => {
              if (isUserExist) {
                createForwardMessagePrivateChat(otherUserDetail, gpId, otherUserId);
              } else {
                if (Object.keys(selectedEmployeeList[0]).length > 0) {
                  let userObj = {
                    image_url:
                      selectedEmployeeList[0].profile_picture.image_path !== ''
                        ? selectedEmployeeList[0].profile_picture.base_url +
                          selectedEmployeeList[0].profile_picture.image_path
                        : '',
                    name:
                      selectedEmployeeList[0].first_name + ' ' + selectedEmployeeList[0].last_name,
                    uid: otherUserId,
                    user_location: selectedEmployeeList[0].location.title,
                  };
                  createOrUpdateUser(userObj, () => {
                    createForwardMessagePrivateChat(userObj, gpId, otherUserId);
                  });
                }
              }
            });
          }
        });
      } else {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
            progressMessage: LOADING_MESSAGES.FORWARDING_MESSAGES,
          })
        );
        let i = selectedEmployeeList.length;
        selectedEmployeeList.forEach((element) => {
          const otherUserId = uId(element);
          const selfUserId = uId(employeeData);
          const gpId = generatePrivateChatId(selfUserId, otherUserId);
          fetchGroupDetailByIdOnce(gpId, (isGroupExist, groupModel) => {
            if (isGroupExist) {
              sendMessageToGroup(
                gpId,
                employeeData,
                message.message,
                message.message_type,
                message.url,
                {},
                true,
                message.doc_type,
                message.doc_name,
                () => {
                  i--;
                  if (i === 0) {
                    dispatch(
                      saveProgressLoadingState({
                        isProgressLoading: false,
                      })
                    );
                    setLeftPanelType(CHAT_LEFT_PANEL_IDENTIFIERS.DEFAULT_VIEW);
                  }
                }
              );
            } else {
              fetchUserByIdOnce(otherUserId, (isUserExist, otherUserDetail) => {
                if (isUserExist) {
                  createOneOnOneChatGroup(employeeData, otherUserDetail, () => {
                    sendMessageToGroup(
                      gpId,
                      employeeData,
                      message.message,
                      message.message_type,
                      message.url,
                      {},
                      true,
                      message.doc_type,
                      message.doc_name,
                      () => {
                        i--;
                        if (i === 0) {
                          dispatch(
                            saveProgressLoadingState({
                              isProgressLoading: false,
                            })
                          );
                          setLeftPanelType(CHAT_LEFT_PANEL_IDENTIFIERS.DEFAULT_VIEW);
                        }
                      }
                    );
                  });
                } else {
                  if (Object.keys(element).length > 0) {
                    let userObj = {
                      image_url:
                        element.profile_picture.image_path !== ''
                          ? element.profile_picture.base_url + element.profile_picture.image_path
                          : '',
                      name: element.first_name + ' ' + element.last_name,
                      uid: otherUserId,
                      user_location: element.location.title,
                    };
                    createOrUpdateUser(userObj, () => {
                      createOneOnOneChatGroup(employeeData, userObj, () => {
                        sendMessageToGroup(
                          gpId,
                          employeeData,
                          message.message,
                          message.message_type,
                          message.url,
                          {},
                          true,
                          message.doc_type,
                          message.doc_name,
                          () => {
                            i--;
                            if (i === 0) {
                              dispatch(
                                saveProgressLoadingState({
                                  isProgressLoading: false,
                                })
                              );
                              setLeftPanelType(CHAT_LEFT_PANEL_IDENTIFIERS.DEFAULT_VIEW);
                            }
                          }
                        );
                      });
                    });
                  }
                }
              });
            }
          });
        });
      }
    } else {
      if (selectedEmployeeList.length === 1) {
        sendMessageRecentChatSingleSelected();
      } else {
        sendMessageRecentChatMultipleSelected();
      }
    }
  };

  return (
    <div>
      <div className="d-flex px-3 py-2 justify-content-between align-items-center chat-header">
        <div className="d-flex align-items-center">
          <BackButton
            isFullRow={false}
            backTitle=""
            containerStyle="py-0"
            navigateBackFuncHandler={() => {
              setLeftPanelType(CHAT_LEFT_PANEL_IDENTIFIERS.DEFAULT_VIEW);
            }}
          />
          {!isSearch && <div className="chat-bold-text">Forward</div>}
        </div>
        <div className="flex-grow-1">
          <div className="d-flex justify-content-between">
            {isSearch ? (
              <form className="w-100" onSubmit={onSearchSubmitHandler} ref={textInputRef}>
                <input
                  autoFocus
                  className="chat-search-bar p-2"
                  type="text"
                  placeholder="Search contact..."
                  onChange={(event) => callChangeFunction(event)}
                />
              </form>
            ) : (
              <div />
            )}
            {isSearch ? (
              <div
                className="d-flex align-items-center chat-search-cross-container pr-1"
                onClick={resetSearchHandler}>
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
                  )}
                  className="header-icon"
                />
              </div>
            ) : (
              <div className="pr-1" onClick={onClickSearchHandler}>
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_SEARCH_GREY,
                    ACCESSIBILITY_IDENTIFIERS.COMMON
                  )}
                  className="chat-icon-search common-cursor-pointer"
                />
              </div>
            )}
          </div>
        </div>

        <div
          onClick={onPressSendHandler}
          className={`ml-2 ${
            selectedEmployeeList.length <= 0
              ? 'chat-theme-highlighted-text-disabled common-cursor-not-allowed'
              : 'chat-theme-highlighted-text common-cursor-pointer'
          }`}>
          Send
        </div>
      </div>

      <div
        ref={employeeListItemRef}
        onScroll={() => {
          if (isSearch) {
            onScrollEmployeeListItem();
          }
        }}
        className="chat-left-panel-container">
        <div>
          <div className="d-flex flex-wrap mt-2 chat-employee-selected-outer-container overflow-auto px-3 pt-2">
            {selectedEmployeeList.length > 0 &&
              selectedEmployeeList.map((item, index) => {
                return (
                  <div className="d-flex mr-2" key={`${index}`}>
                    <div className="d-flex align-items-center chat-selected-employee-container mb-2">
                      {isSearch ? (
                        <UserProfilePhotoForChats
                          imageBaseUrl={item.profile_picture.base_url}
                          imagePath={item.profile_picture.image_path}
                          imageClass="common-user-profile-photo-xxs"
                        />
                      ) : (
                        <img
                          src={
                            item.dialogImage
                              ? item.dialogImage
                              : getFeatureComponentUrlLdpi(
                                  accessibilityData,
                                  ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
                                  ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                                )
                          }
                          className="common-user-profile-photo-xxs"
                        />
                      )}
                      here
                      <div className="chat-base-text-sm pt-1 ml-1 mr-2">
                        {isSearch ? item.first_name + ' ' + item.last_name : item.dialogName}
                      </div>
                      <div
                        className="common-cursor-pointer pr-1"
                        onClick={() => deleteEmployee(item)}>
                        <img
                          src={getFeatureComponentUrl(
                            accessibilityData,
                            ACCESSIBILITY_FEATURE_COMPONENTS.SOM_EMPLOYEE_CANCEL,
                            ACCESSIBILITY_IDENTIFIERS.SOM
                          )}
                          className="chat-selected-employee-close-icon"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {selectedEmployeeList.length > 0 && <Divider style="chat-divider-thin mt-2 mb-3" />}
          <div className="d-flex justify-content-between px-3">
            <p className="mb-0 chat-base-text-sm chat-bold-text">
              {isSearch ? 'Other Contacts' : 'Recent Chats'}
            </p>
            <p className="mb-0 chat-base-text-sm chat-grey-text">{`(${selectedEmployeeList.length}/${maxForwardSelectionLimit})`}</p>
          </div>
        </div>
        {isSearch
          ? employeeList.map((item, index) => renderEmployee(item, index))
          : dialogsList.map((item, index) => renderUser(item, index))}
      </div>
    </div>
  );
};

export default ForwardMessageView;
