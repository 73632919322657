import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveProgressLoadingState } from '../../state/UIState';
import { apiRequest, handleError } from '../../services/Service';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { GET_ASK_ORG_QUESTIONS } from '../../services/ApiUrls';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { getConfigurations } from '../../utils/ReduxState';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import AskMlChatWidget from './AskMlChatWidget';
import { selectEmployeeData } from '../../state/EmployeeData';
import { selectAccessData } from '../../state/UserAccessData';
import { generatev4UniqueID } from '../../utils/common';

const AskMlSearchResultsScreen = ({ history }) => {
  const [searchText, setSearchText] = useState(history?.location?.state ?? '');
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const pageIdRef = useRef(1);
  const loadMoreFlag = useRef(true);
  const [searchResultsData, setSearchResultsData] = useState([]);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  const [showEmptyView, setShowEmptyView] = useState(false);
  const [showWidget, setShowWidget] = useState(false);
  const employeeConfig = useSelector(selectEmployeeData);
  const accessData = useSelector(selectAccessData);
  const [showChatBot, setShowChatBot] = useState(true);
  const askOrgAccessData = accessData?.data?.find((item) => item.feature_key === '_askOrg');
  const [uniqueUUID, setUniqueUUID] = useState('');

  useEffect(() => {
    const actions = askOrgAccessData?.actions;
    setShowChatBot(actions?.includes('_chatbot'));
  }, [askOrgAccessData?.actions]);

  const callGetQuestionsApi = useCallback(async () => {
    if (loadMoreFlag.current) {
      setError(false);
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      setLoadMoreBtn(true);
      const params = { page_id: pageIdRef.current, query: searchText };
      try {
        setShowEmptyView(false);
        const apiResponse = await apiRequest(GET_ASK_ORG_QUESTIONS, REQUEST_TYPE.GET, params);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status) {
            const data = apiResponse.response.data.questions;
            if (data?.length === 0) {
              setShowEmptyView(true);
            }
            if (data.length > 0) {
              if (pageIdRef.current === 1) {
                setSearchResultsData([]);
              }
              setSearchResultsData((oldArray) => [...oldArray, ...data]);
              loadMoreFlag.current = true;
            } else {
              loadMoreFlag.current = false;
              setLoadMoreBtn(false);
            }
          }
        } else {
          setShowEmptyView(true);
        }
      } catch (err) {
        handleError(err, params, GET_ASK_ORG_QUESTIONS, NAVIGATION_ROUTES.ASK_ORG_SEARCH_RESULT);
        setShowEmptyView(true);
        console.error(err);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        setError(true);
      }
    }
  }, [dispatch, searchText]);

  const loadMore = () => {
    if (loadMoreFlag) {
      pageIdRef.current = pageIdRef.current + 1;
      callGetQuestionsApi();
    }
  };

  const handleHelpdesk = () => {
    const targetUrl = getConfigurations()._helpdesk_url;
    window.open(targetUrl, '_blank');
  };

  const handleOnClick = (item) => {
    history.push(NAVIGATION_ROUTES.ASK_ORG_RESULTS_DETAIL, {
      questionId: item.question_id,
      questionTitle: item.question,
    });
  };

  useEffect(() => {
    callGetQuestionsApi();
  }, [callGetQuestionsApi]);

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_RESULTS_FOUND} />;
  };

  const toggleWidget = () => {
    setShowWidget(!showWidget);
  };

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
        {employeeConfig?.gpt_visible == 1 && showChatBot && (
          <div
            className={
              !showWidget
                ? 'd-flex common-cursor-pointer chat-bot-floating-icon p-2 align-items-center justify-content-center'
                : 'd-flex common-cursor-pointer chat-bot-floating-close-icon p-2 align-items-center justify-content-center'
            }>
            <div
              className="position-relative flex-grow-1 d-flex justify-content-center align-items-center"
              onClick={() => {
                toggleWidget();
                if (!showWidget) {
                  const id = generatev4UniqueID();
                  setUniqueUUID(id);
                }
              }}>
              <img
                className={!showWidget ? 'ask-org-chat-bot-float-icon' : 'ask-org-close-float-icon'}
                src={
                  !showWidget
                    ? getFeatureComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.CHAT_BOT,
                        ACCESSIBILITY_IDENTIFIERS.ASK_ORG
                      )
                    : 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgMzU3IDM1NyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzU3IDM1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnIGlkPSJjbGVhciI+CgkJPHBvbHlnb24gcG9pbnRzPSIzNTcsMzUuNyAzMjEuMywwIDE3OC41LDE0Mi44IDM1LjcsMCAwLDM1LjcgMTQyLjgsMTc4LjUgMCwzMjEuMyAzNS43LDM1NyAxNzguNSwyMTQuMiAzMjEuMywzNTcgMzU3LDMyMS4zICAgICAyMTQuMiwxNzguNSAgICIgZmlsbD0iI0ZGRkZGRiIvPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo='
                }
              />
            </div>
            {showWidget ? <AskMlChatWidget uniqueUUID={uniqueUUID} /> : null}
          </div>
        )}
        <div className="gcdo-icon-row">
          <CustomButton
            onClick={handleHelpdesk}
            fromRoute={NAVIGATION_ROUTES.ASK_ORG}
            buttonMainContainer="text-nowrap ceo-chat-ask-button"
            isSecondaryButton={true}>
            {'INITIATE REQUEST'}
          </CustomButton>
        </div>
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.ASK_ORG} />
      <BackButton fromRoute={NAVIGATION_ROUTES.ASK_ORG_SEARCH_RESULT} />
      <div className="p-3 ask-org-question-text-container w-100">{searchText}</div>
      {searchResultsData?.length > 0 &&
        searchResultsData.map((item, index) => {
          return (
            <div
              key={`${index}`}
              onClick={() => handleOnClick(item)}
              className="p-3 ask-question-results d-flex flex-row justify-content-between common-cursor-pointer">
              <span>{item?.question}</span>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_RIGHT_ARROW,
                  ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
                )}
                className="anniversary-arrow-icon"
              />
            </div>
          );
        })}
      {showEmptyView && searchResultsData?.length === 0 && emptyListView()}
      {loadMoreBtn && (
        <div className="mb-4 mt-4">
          <LoadMoreBtn onClick={loadMore} />
        </div>
      )}
    </div>
  );
};

export default AskMlSearchResultsScreen;
