import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ERROR_MESSAGES,
  FEATURE_TYPE,
  NAVIGATION_ROUTES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../../utils/accessibility';
import { formatDateDDMonthYYYY, getUrlWithSpecificRendition } from '../../../utils/common';
import PlayIconOverlay from '../../../common/ui/play_icon_overlay/PlayIconOverlay';
import { useHistory } from 'react-router';

const PollsListItem = ({
  item,
  onPressViewResult,
  submitVote,
  onPressLikeButton,
  onPressCommentButton,
  showLikeButton,
  showCommentButton,
  showParticipate,
  showViewResults,
}) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const history = useHistory();

  const renderLikeCommentView = () => {
    return (
      <div className="d-flex align-items-center mt-1">
        {showLikeButton && (
          <>
            {item.liked ? (
              <div onClick={() => onPressLikeButton(item)}>
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_ACTIVE_LIKE,
                    ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                  )}
                  className="poll-like-icon common-cursor-pointer mb-1"
                />
              </div>
            ) : (
              <div onClick={() => onPressLikeButton(item)}>
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_INACTIVE_LIKE,
                    ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                  )}
                  className="poll-like-icon common-cursor-pointer mb-1"
                />
              </div>
            )}
            {item.like_count > 0 ? (
              <div
                className="ml-1 polls-like-comment-count common-cursor-pointer"
                onClick={() => {
                  history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
                    referId: item.poll_id,
                    parentType: FEATURE_TYPE.POLL,
                  });
                }}>
                {item.like_count}
              </div>
            ) : (
              <div className="ml-1 polls-like-comment-count common-cursor-pointer"> 0 </div>
            )}
          </>
        )}
        {showCommentButton && (
          <div
            className="communities-flex ml-3 mr-3 common-cursor-pointer"
            onClick={() => onPressCommentButton(item)}>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_POST_COMMENT,
                ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
              )}
              className="poll-like-icon"
            />
            <div className="ml-1 polls-like-comment-count">
              {item.comment_count > 0 ? item.comment_count : '0'}
            </div>
          </div>
        )}
        {!!item.page_views && (
          <div className="polls-view-count">
            {item.page_views > 1 ? `${item.page_views} views` : `${item.page_views} view`}
          </div>
        )}
      </div>
    );
  };

  const navigateToDetailScreen = () => {
    history.push(`${NAVIGATION_ROUTES.POLLS_NOTIFICATION_SCREEN}/${item?.poll_id}`);
  };

  return (
    <div className="col-sm-4 mb-3">
      {item?.content_load_type === '3' && <PlayIconOverlay />}
      <img
        src={getUrlWithSpecificRendition(
          item.promo_image.base_url,
          SCREEN_DENSITY_QUALIFIERS.HDPI,
          item.promo_image.image_path
        )}
        className="embed-responsive embed-responsive-16by9 common-cursor-pointer"
        onClick={navigateToDetailScreen}
      />
      <div className="polls-title-container p-2">
        <div
          onClick={navigateToDetailScreen}
          className="polls-title-text mt-1 common-cursor-pointer">
          {item.question}
        </div>
        {renderLikeCommentView()}
      </div>
      <div className="d-flex flex-row align-items-center"></div>
      <div
        className={
          (item.active && !item.voted) || !item.active
            ? 'polls-axis-container-view p-2 d-flex flex-row align-items-center justify-content-between'
            : 'polls-axis-container-view p-2 d-flex flex-row align-items-center'
        }>
        <div className="row p-2 pl-3">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.NEWS_CALENDER,
              ACCESSIBILITY_IDENTIFIERS.NEWS
            )}
            className="polls-calender-style"
          />
          <div className="ml-1 polls-date-text">
            {item.active
              ? `Till ${formatDateDDMonthYYYY(item.end_datetime)}`
              : `Poll ended on ${formatDateDDMonthYYYY(item.end_datetime)}`}
          </div>
        </div>
        {item.active && item.voted && (
          <div className="polls-participated-text ml-auto">{ERROR_MESSAGES.POLL_PARTICIPATED}</div>
        )}
        {item.active && !item.voted && showParticipate && (
          <div
            onClick={navigateToDetailScreen}
            className="polls-participate-button-container common-cursor-pointer">
            Participate Now
          </div>
        )}
        {!item.active && showViewResults && (
          <div
            onClick={navigateToDetailScreen}
            className="polls-participate-button-container common-cursor-pointer">
            View results
          </div>
        )}
      </div>
    </div>
  );
};

export default PollsListItem;
