import React, { useEffect, useRef, useState } from 'react';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  LOADING_MESSAGES,
  MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
  SUPPORTED_IMAGE_FORMATS,
  SUPPORTED_IMAGE_FORMATS_WEB,
  WARNING_MESSAGES,
} from '../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { showToast, checkIsValidYoutubeURL, getUrlWithSpecificRendition } from '../../utils/common';
import { COMMUNITIES_BY_ID, GET_DIRECTORY_DATA, GET_HASHTAGS } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { getFeatureComponentUrl, getFeatureComponentUrlLdpi } from '../../utils/accessibility';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import BackButton from '../../common/ui/common_back_button/BackButton';
import Divider from '../../common/ui/divider/Divider';
import TagEmployeeView from './components/TagPeopleView';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';
import { Form } from 'react-bootstrap';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import {
  selectCommunityPostTagPeopleLimit,
  selectMaxFileUploadSizeConfig,
  selectTrendingHashtags,
  selectwallPostConfig,
} from '../../state/MasterData';
import { websiteValidationRegex } from '../../utils/formValidation';
import 'js-video-url-parser/lib/provider/youtube';
import { uploadDocuments } from '../../utils/commonApiRequests';
import CustomMentionEditor from '../../common/components/hashtagsAndMentionsInput/HashtagMentionsInput';
import { EditorState } from 'draft-js';
import { extractDataFromEditorState } from '../../common/components/hashtagsAndMentionsInput/hashtagUtils';

const WritePostScreen = ({ history, location }) => {
  const { communityId } = location.state;
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [links, setLinks] = useState([]);
  const [videoLink, setVideoLink] = useState('');
  const [videoLinks, setVideoLinks] = useState([]);
  const [imageLinks, setImageLinks] = useState([]);
  const [tags, setTags] = useState([]);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [showTagEmployeeView, setShowTagEmployeeView] = useState(false);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const communityPostTagPeopleLimit = useSelector(selectCommunityPostTagPeopleLimit);
  const maxFileUploadSizeInMB = useSelector(selectMaxFileUploadSizeConfig);
  const [imageBaseUrl, setImageBaseUrl] = useState('');
  const wallPostConfig = useSelector(selectwallPostConfig);
  const trendingHashtags = useSelector(selectTrendingHashtags);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [mentionsList, setMentionsList] = useState([]);
  const [hashtagsList, setHashtagsList] = useState([]);
  const [tempHashtag, setTempHashtag] = useState('');
  const [tempMention, setTempMention] = useState('');

  const addImageFileRef = useRef(null);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.WRITE_POST, NAVIGATION_ROUTES.WRITE_POST);
  }, []);

  const onPressWritePost = async () => {
    const { description, addedMentions } = extractDataFromEditorState(editorState);
    let tempDes = description;
    tempDes = tempDes.replace(/ {2,}(?![\n])/g, ' ');

    addedMentions.map((user) => {
      tempDes = tempDes.replace(user.name, `<rsu>{{${user.employee_id}}}</rsu> `);
    });
    if (title === '') {
      showToast(MESSAGES.TITLE_EMPTY);
    } else if (tempDes === '') {
      showToast(MESSAGES.DESCRIPTION_EMPTY);
    } else {
      let tagString = '';
      if (tags.length > 0) {
        var tagArray = [];
        for (var i = 0; i < tags.length; i++) {
          tagArray.push(tags[i].employee_id);
        }
        tagString = tagArray.join(',');
      }
      const formData = new URLSearchParams();
      formData.append('title', title);
      formData.append('description', tempDes);
      formData.append('tags', tagString);
      if (links.length > 0) {
        links.forEach((item, index) => {
          formData.append(`links[${index}]`, `${item}`);
        });
      }
      if (videoLinks.length > 0) {
        videoLinks.forEach((item, index) => {
          formData.append(`video_links[${index}]`, `${item}`);
        });
      }
      formData.append('images', JSON.stringify(imageLinks));
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
            progressMessage: LOADING_MESSAGES.COMMENT_UPDATE,
          })
        );

        const apiResponse = await apiRequest(
          COMMUNITIES_BY_ID + communityId + '/post',
          REQUEST_TYPE.POST,
          formData
        );
        if (Object.keys(apiResponse).length > 0) {
          setImageLinks([]);
          if (apiResponse.response.status === true) {
            history.goBack();
          }
        }
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      } catch (err) {
        handleError(
          err,
          formData,
          COMMUNITIES_BY_ID + communityId + '/post',
          NAVIGATION_ROUTES.WRITE_POST
        );
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    }
  };

  const deleteLink = (item) => {
    if (links.includes(item)) {
      const index = links.indexOf(item);
      if (index > -1) {
        setLinks(links.filter((element) => element !== links[index]));
      }
    }
  };

  const deleteVideoLink = (item) => {
    if (videoLinks.includes(item)) {
      const index = videoLinks.indexOf(item);
      if (index > -1) {
        setVideoLinks(videoLinks.filter((element) => element !== videoLinks[index]));
      }
    }
  };

  const deleteImage = (item) => {
    if (imageLinks.includes(item)) {
      const index = imageLinks.indexOf(item);
      if (index > -1) {
        setImageLinks(imageLinks.filter((element) => element !== imageLinks[index]));
      }
    }
  };

  const deleteEmployee = (employee) => {
    if (tags.includes(employee)) {
      const index = tags.indexOf(employee);
      if (index > -1) {
        setTags(tags.filter((item) => item !== tags[index]));
      }
    }
  };

  const onPressAddLink = () => {
    if (link === '') {
      showToast(MESSAGES.ENTER_URL);
    } else if (!websiteValidationRegex(link)) {
      showToast(MESSAGES.INVALID_URL);
    } else if (links.includes(link)) {
      showToast(MESSAGES.LINK_ADDED);
    } else if (links.length === (wallPostConfig?.web_urls_limit ?? 10)) {
      showToast(WARNING_MESSAGES.MAXIMUM_NO_OF_WEB_URLS(wallPostConfig?.web_urls_limit ?? 10));
      return;
    } else {
      setLinks((prev) => [...prev, link]);
    }
    setLink('');
  };

  const onPressVideoLink = () => {
    if (videoLink === '') {
      showToast(MESSAGES.ENTER_URL);
    } else if (!checkIsValidYoutubeURL(videoLink)) {
      showToast(MESSAGES.INVALID_URL);
    } else if (videoLinks.includes(videoLink)) {
      showToast(MESSAGES.LINK_ADDED);
    } else if (videoLinks.length === (wallPostConfig?.youtube_urls_limit ?? 10)) {
      showToast(
        WARNING_MESSAGES.MAXIMUM_NO_OF_YOUTUBE_URLS(wallPostConfig?.youtube_urls_limit ?? 10)
      );
    } else {
      setVideoLinks((prev) => [...prev, videoLink]);
    }
    setVideoLink('');
  };

  const onEmployeeSelected = (tags) => {
    if (tags.length > 0) {
      if (tags.length > communityPostTagPeopleLimit) {
        showToast(`${MESSAGES.CANNOT_TAG(communityPostTagPeopleLimit)}`);
      } else {
        setTags([...tags]);
        setShowTagEmployeeView(false);
      }
    }
  };

  const onFileChange = (event) => {
    const images = [...event.target.files];
    let isAllFilesSupported = true;
    let isAnyFileSizeLarge = false;
    let isFileAlreadyPresent = false;
    images.forEach((element) => {
      if (!SUPPORTED_IMAGE_FORMATS_WEB.includes(element.type)) {
        isAllFilesSupported = false;
      }
      if (Number(element.size) / (1024 * 1024) > maxFileUploadSizeInMB) {
        isAnyFileSizeLarge = true;
      }
      if (imageLinks.some((item) => item.name === element.name)) {
        isFileAlreadyPresent = true;
      }
    });
    if (!isAllFilesSupported) {
      showToast(WARNING_MESSAGES.FILE_NOT_SUPPORTED);
    } else if (isAnyFileSizeLarge) {
      showToast(WARNING_MESSAGES.FILE_SIZE_EXCEEDED(maxFileUploadSizeInMB));
    } else if (isFileAlreadyPresent) {
      showToast(ERROR_MESSAGES.WALL_FILE_ALREADY_PRESENT);
    } else {
      if (images?.length + imageLinks?.length > (wallPostConfig?.images_limit ?? 10)) {
        showToast(WARNING_MESSAGES.MAXIMUM_NO_OF_IMAGE_FILES(wallPostConfig?.images_limit ?? 10));
        return;
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      images.forEach((element) => {
        uploadDocuments(element, 'communities', 'image')
          .then((response) => {
            setImageBaseUrl(response.base_url);
            setImageLinks((prev) => [...prev, { filename: response.fileName, type: 'image' }]);
          })
          .catch((error) => {
            showToast(error.message);
          })
          .finally(() => {
            dispatch(
              saveProgressLoadingState({
                isProgressLoading: false,
              })
            );
          });
      });
    }
  };

  const renderImage = () => {
    return (
      <div>
        <div
          className="border align-items-center justify-content-center p-3 d-flex common-cursor-pointer rounded"
          onClick={(e) => {
            e.stopPropagation();
            addImageFileRef.current.click();
          }}>
          <input
            type="file"
            ref={addImageFileRef}
            onChange={onFileChange}
            accept={SUPPORTED_IMAGE_FORMATS}
            id="input"
            multiple
            className="d-none"
            onClick={(event) => {
              event.target.value = null;
            }}
          />
          <div className="communities-add-image">Add Image</div>
        </div>
        <div className="mt-3 flex-row">
          {imageLinks.length > 0 &&
            imageLinks.map((element, index) => {
              return (
                <div key={index} className="d-inline-block position-relative mr-2">
                  <img
                    src={getUrlWithSpecificRendition(
                      imageBaseUrl,
                      SCREEN_DENSITY_QUALIFIERS.HDPI,
                      element.filename
                    )}
                    alt="Image"
                    className="community-post-image mb-2"
                  />
                  <img
                    src={getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_CLOSE,
                      ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                    )}
                    className="community-cross-icon common-cursor-pointer"
                    onClick={() => deleteImage(element)}
                  />
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const renderVideo = () => {
    return (
      <div>
        <Form.Group>
          <Form.Label className="communities-label">Video URL</Form.Label>
          <div className="d-flex align-items-center">
            <Form.Control
              type="text"
              onChange={(event) => setVideoLink(event.target.value)}
              value={videoLink}
            />
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_ADD_LINK,
                ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
              )}
              className="header-icon ml-3"
              onClick={onPressVideoLink}
            />
          </div>
        </Form.Group>
        {videoLinks.length > 0 &&
          videoLinks.map((item, index) => {
            return (
              <div key={`${index}`} className="d-flex justify-content-between mb-3">
                <div className="flex-fill">
                  <a
                    className="community-video-link-text"
                    target="_blank"
                    rel="noreferrer"
                    href={item}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}>
                    {item}
                  </a>
                  <Divider style="mt-2" />
                </div>
                <div className="ml-3 pr-1">
                  <img
                    src={getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_CLOSE,
                      ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                    )}
                    onClick={() => deleteVideoLink(item)}
                    className="community-cross-remove-link"
                  />
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const renderUrl = () => {
    return (
      <div>
        <Form.Group>
          <Form.Label className="communities-label">Website URL</Form.Label>
          <div className="d-flex align-items-center">
            <Form.Control
              type="text"
              onChange={(event) => setLink(event.target.value)}
              value={link}
            />
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_ADD_LINK,
                ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
              )}
              className="header-icon ml-3"
              onClick={onPressAddLink}
            />
          </div>
        </Form.Group>
        {links.length > 0 &&
          links.map((item, index) => {
            return (
              <div key={`${index}`} className="d-flex justify-content-between mb-3">
                <div className="flex-fill text-truncate">
                  <a
                    className="community-video-link-text"
                    target="_blank"
                    rel="noreferrer"
                    href={item}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}>
                    {item}
                  </a>
                  <Divider style="mt-2" />
                </div>
                <div className="ml-3 pr-1">
                  <img
                    src={getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_CLOSE,
                      ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                    )}
                    onClick={() => deleteLink(item)}
                    className="community-cross-remove-link"
                  />
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const renderTagEmployee = () => {
    return (
      <div>
        <div
          onClick={() => {
            setShowTagEmployeeView(true);
          }}
          className="d-flex common-cursor-pointer">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_TAG_PEOPLE,
              ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
            )}
            className="header-icon mr-2"
          />
          <div className="communities-tag-people">Tag Member(s)</div>
        </div>
        {showTagEmployeeView && (
          <TagEmployeeView onEmployeeSelected={onEmployeeSelected} tags={tags} />
        )}
        <div className="d-flex flex-wrap mt-3">
          {tags.length > 0 &&
            tags.map((item, index) => {
              return (
                <div key={`${index}`} className="col-6 col-sm-3 col-lg-2 mb-4">
                  <div>
                    <UserProfilePhoto
                      imageBaseUrl={item.profile_picture.base_url}
                      imagePath={item.profile_picture.image_path}
                      imageClass="common-user-profile-photo-lg"
                    />
                    <div className="text-center mt-1">{item.first_name + ' ' + item.last_name}</div>
                  </div>
                  <div
                    className="d-flex position-absolute community-bg-view-image"
                    onClick={() => deleteEmployee(item)}>
                    <img
                      src={getFeatureComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.SOM_EMPLOYEE_CANCEL,
                        ACCESSIBILITY_IDENTIFIERS.SOM
                      )}
                      className="header-icon mr-3"
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  const onChange = (editorState) => {
    setEditorState(editorState);
  };

  const onMentionsSearchChange = async ({ value }) => {
    if (
      value.length > 2 &&
      (tempMention === '' || !value.startsWith(tempMention)) &&
      value.charAt(0) !== ' '
    ) {
      let requestParams = {
        page_id: 1,
        query: value,
        ignore_me: 1,
        community_id: communityId,
      };
      setMentionsList([]);
      try {
        const apiResponse = await apiRequest(GET_DIRECTORY_DATA, REQUEST_TYPE.GET, requestParams);
        const tags = [];
        const feeds = apiResponse.response.data?.feeds;
        if (feeds.length <= 0) {
          setTempMention(value);
        }
        feeds.map((item, index) => {
          const profile = item.profile_picture?.image_path
            ? item.profile_picture.base_url + 'ldpi/' + item.profile_picture.image_path
            : getFeatureComponentUrlLdpi(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
                ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
              );
          tags.push({
            type: 'mention',
            name: `${item.first_name} ${item.last_name} `,
            avatar: profile,
            employee_id: item.employee_id,
          });
          if (feeds.length === index + 1) {
            setMentionsList([...tags]);
          }
        });
      } catch (err) {
        handleError(err, requestParams, GET_DIRECTORY_DATA, NAVIGATION_ROUTES.WRITE_POST);
      }
    } else {
      setMentionsList([]);
    }
  };

  const onHashtagsSearchChange = async ({ value }) => {
    if (value.includes(' ')) {
      setHashtagsList([]);
      return;
    }
    if (value.length > 2 && (tempHashtag === '' || !value.startsWith(tempHashtag))) {
      let requestParams = {
        page_id: 1,
        query: value,
      };
      try {
        const apiResponse = await apiRequest(GET_HASHTAGS, REQUEST_TYPE.GET, requestParams);
        const tags = [];
        const tagsResp = apiResponse.response.data.tags;
        if (tagsResp.length <= 0) {
          setTempHashtag(value);
        }
        tagsResp.map((item, index) => {
          tags.push({ ...item, name: item + ' ', type: 'hashtag', index });
        });
        setHashtagsList([...tags]);
      } catch (err) {
        handleError(err, requestParams, GET_HASHTAGS, NAVIGATION_ROUTES.WRITE_POST);
      }
    } else if (trendingHashtags?.length > 0 && value.length < 3) {
      const tags = [];
      trendingHashtags.map((item, index) => {
        tags.push({ name: item + ' ', type: 'hashtag', index });
      });
      setHashtagsList([...tags]);
    } else {
      setHashtagsList([]);
    }
  };

  const resetFilters = (props) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.HASHTAG_SUGGESTION_TAP,
      0,
      props.name,
      ANALYTICS_ITEM_NAMES.HASHTAG
    );
    setMentionsList([]);
    setHashtagsList([]);
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.COMMUNITIES} />
      <BackButton />
      <div className="main-content-container">
        <div className="d-flex justify-content-center">
          <div className="common-container-md">
            <Form.Group>
              <Form.Label className="communities-label">Post Title</Form.Label>
              <Form.Control
                placeholder={`Max length ${wallPostConfig?.title_limit ?? 500} characters`}
                maxLength={wallPostConfig?.title_limit ?? 500}
                type="text"
                onChange={(event) => setTitle(event.target.value)}
                style={{ color: 'black' }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label className="communities-label">{`Description (Max length ${
                wallPostConfig?.description_limit ?? 1000
              } characters)`}</Form.Label>
              <CustomMentionEditor
                mentions={mentionsList}
                hashtags={hashtagsList}
                editorState={editorState}
                onChange={onChange}
                onMentionsSearchChange={onMentionsSearchChange}
                onHashtagsSearchChange={onHashtagsSearchChange}
                resetFilters={resetFilters}
                enableUserMentions={true}
                enableHashtags={true}
              />
            </Form.Group>
            {renderImage()}
            {renderVideo()}
            {renderUrl()}
            {renderTagEmployee()}
            <CustomButton
              buttonStyle="communities-button-style mr-0"
              onClick={onPressWritePost}
              showLoader={progressLoadingState.isProgressLoading}>
              Publish Post
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WritePostScreen;
