import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import dayjs from 'dayjs';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import 'firebase/compat/performance';
import Navigation from './navigation/Navigation';
import { saveMasterdata, selectChatEnabled } from './state/MasterData';
import { getMasterdata, getProfanityListData } from './utils/FirebaseDBUtils';
import {
  ACTION_TYPES,
  ERROR_MESSAGES,
  FIREBASE_DB_NODES,
  NAVIGATION_ROUTES,
  ORG_NAMES,
} from './common/constants/AppConstants';
import {
  saveBaseUrl,
  saveGenericToken,
  selectBaseUrl,
  saveLamdaUrl,
  saveIsFullScreenLoaderLoading,
  saveOrgDomain,
  selectShowAttendanceModal,
} from './state/UIState';
import 'react-toastify/dist/ReactToastify.css';
import { fetchMyGroupsOnly, fetchUserGroupsById, getNodeReference, uId } from './utils/ChatHandler';
import { selectMenuData } from './state/MenuData';
import { selectEmployeeLoginData } from './state/EmployeeData';
import { selectAccessibilityData } from './state/AccessibilityData';
import { setUserId } from './utils/FirebaseAnalyticsUtils';
import { showToast } from './utils/common';
import Header from './common/ui/header/Header';
import Footer from './common/ui/footer/Footer';
import DownloadAppPopup from './common/ui/download_app_popup/DownloadAppPopup';
import FullScreenLoader from './common/ui/full_screen_loader/FullScreenLoader';
import ProgressDialog from './common/ui/progress_dialog/ProgressDialog';
import { saveProfanityList, selectProfanityList } from './state/ProfanityData';
import AccessDeniedComponent from './common/ui/access_denied/AccessDeniedComponent';
import MandatoryAcknwoledgementModal from './common/ui/acknowledgement/MandatoryAcknowledgementModal';
// import PublicProfile from './common/ui/public_profile/PublicProfile';
import AcknowledgementResponseModal from './common/ui/acknowledgement/AcknowledgementResponseModal';
import AttendanceModal from './common/ui/attendance/AttendanceModal';
import StoreUnAuthorisedError from './common/ui/estore_no_access_modal/StoreUnAuthorisedError';

let utc = require('dayjs/plugin/utc');
let relativeTime = require('dayjs/plugin/relativeTime');
let customParseFormat = require('dayjs/plugin/customParseFormat');

const App = () => {
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);
  const menuData = useSelector(selectMenuData);
  const chatEnabled = useSelector(selectChatEnabled);
  const employeeData = useSelector(selectEmployeeLoginData);
  const baseUrl = useSelector(selectBaseUrl);
  const profanityList = useSelector(selectProfanityList);
  dayjs.extend(utc);
  dayjs.extend(relativeTime);
  dayjs.extend(customParseFormat);
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const [showAcknowledgementModal, setShowAcknowledgementModal] = useState(false);
  const [acknowledgementModalData, setAcknowledgementModalData] = useState({});
  // const [showPublicProfile, setShowPublicProfile] = useState(false);
  // const [publicProfileId, setPublicProfileId] = useState('');
  const [acknowledgementResponse, setAcknowledgementResponse] = useState(null);
  const [showAcknowledgementResponseModal, setShowAcknowledgementResponseModal] = useState(false);
  const showAttendanceModal = useSelector(selectShowAttendanceModal);

  useEffect(() => {
    if (employeeData && Object.keys(employeeData).length > 0) {
      let groupMap = {};
      const uid = uId(employeeData);
      if (chatEnabled && chatEnabled === true) {
        fetchUserGroupsById(uid, (isGroupExists, groups) => {
          if (isGroupExists) {
            fetchMyGroupsOnly(groups, (isExist, sGroupMap) => {
              groupMap = sGroupMap;
            });
          }
        });
      }
      return () => {
        if (chatEnabled && chatEnabled === true) {
          getNodeReference(FIREBASE_DB_NODES.USERS).child(uid).child('group').off('value');
          Object.keys(groupMap).forEach((groupId) => {
            getNodeReference(FIREBASE_DB_NODES.GROUP).child(groupId).off('value');
          });
        }
      };
    }
  }, [chatEnabled, employeeData]);

  // const checkPublicProfile = () => {
  //   if (window.location.href.includes(NAVIGATION_ROUTES.PUBLIC_PROFILE)) {
  //     const publicProfileId = window.location.href.split('/').pop();
  //     setPublicProfileId(publicProfileId);
  //     setShowPublicProfile(true);
  //   }
  // };

  const callbackAfterButtonPress = (callbackData) => {
    setAcknowledgementResponse(callbackData);
    setShowAcknowledgementResponseModal(true);
  };

  const getFirebaseData = useCallback(() => {
    let config = {
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_DATABASE_URL,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID,
      appId: process.env.REACT_APP_API_ID,
    };
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    } else {
      firebase.app();
    }
    firebase.analytics();
    const perf = firebase.performance();
    firebase
      .auth()
      .signInAnonymously()
      .then(() => {
        getMasterdata('microland', (masterdata) => {
          if (masterdata) {
            dispatch(saveLamdaUrl(masterdata.configurations._api_lamda_url));
            dispatch(saveMasterdata(masterdata));
            // do not use generic tokens for api calls
            // dispatch(saveGenericToken(masterdata.generic_token));
            dispatch(saveBaseUrl(masterdata.configurations._api_base_url));
            // checkPublicProfile();
          } else {
            showToast(ERROR_MESSAGES.FIREBASE_DB_ERROR);
          }
        });
      })
      .catch((error) => {
        console.error(error);
        if (error.code === 'auth/operation-not-allowed') {
          console.log('Enable anonymous in your firebase console.');
        }
        showToast(ERROR_MESSAGES.UNAUTHORIZED_USER);
      });
  }, [dispatch]);

  useEffect(() => {}, []);

  useEffect(() => {
    dispatch(saveIsFullScreenLoaderLoading(true));
    getFirebaseData();
  }, [dispatch, getFirebaseData]);

  useEffect(() => {
    if (baseUrl !== '') {
      dispatch({ type: ACTION_TYPES.GET_ACCESSIBILITY, payload: {} });
      getProfanityListData('microland', (profanityListData) => {
        if (profanityListData) {
          dispatch(saveProfanityList(profanityListData));
        }
      });
      // if (!window.location.href.includes(NAVIGATION_ROUTES.PUBLIC_PROFILE)) {
      dispatch({ type: ACTION_TYPES.GET_MENU, payload: {} });
      dispatch({ type: ACTION_TYPES.GET_EMPLOYEE, payload: {} });
      dispatch({ type: ACTION_TYPES.GET_ACCESS, payload: {} });
      // }
      dispatch(saveOrgDomain(ORG_NAMES.MICROLAND));
    }
  }, [baseUrl, dispatch]);

  useEffect(() => {
    if (
      baseUrl !== '' &&
      Object.keys(menuData).length &&
      Object.keys(employeeData).length &&
      Object.keys(accessibilityData).length &&
      profanityList &&
      profanityList.length > 0
    ) {
      dispatch(saveIsFullScreenLoaderLoading(false));
      setUserId();
    }
  }, [accessibilityData, baseUrl, dispatch, employeeData, menuData, profanityList]);

  useEffect(() => {
    if (Object.keys(employeeData).length) {
      if (employeeData?.complete_access_denial == 1) {
        setIsAccessDenied(true);
      } else {
        if (employeeData?.acknowledgement_data?.is_available) {
          setShowAcknowledgementModal(true);
          setAcknowledgementModalData(employeeData?.acknowledgement_data ?? {});
        } else {
          setShowAcknowledgementModal(false);
        }
      }
    }
  }, [employeeData]);

  return (
    <BrowserRouter>
      {/* {showPublicProfile && <PublicProfile publicProfileId={publicProfileId} />} */}
      <FullScreenLoader />
      {/* {baseUrl !== '' && !showPublicProfile && ( */}
      {baseUrl !== '' && (
        <div>
          {isAccessDenied ? (
            <AccessDeniedComponent />
          ) : (
            <>
              <MandatoryAcknwoledgementModal
                show={showAcknowledgementModal}
                content={acknowledgementModalData}
                setShow={setShowAcknowledgementModal}
                callbackOnButtonPress={callbackAfterButtonPress}
              />
              <AcknowledgementResponseModal
                setShowAcknowledgementResponseModal={setShowAcknowledgementResponseModal}
                acknowledgementResponse={acknowledgementResponse}
                showAcknowledgementResponseModal={showAcknowledgementResponseModal}
              />
              <ToastContainer position="bottom-center" autoClose={3000} />
              <Header />
              {showAttendanceModal && <AttendanceModal />}
              <StoreUnAuthorisedError />
              <DownloadAppPopup />
              <Navigation />
              <ProgressDialog />
              <Footer />
            </>
          )}
        </div>
      )}
    </BrowserRouter>
  );
};

export default App;
