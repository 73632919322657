import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import HtmlView from '../../common/ui/html_view/HTMLView';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { DONATE, UPLOAD_BUSINESS_CARD } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectMicroGiveAbout } from '../../state/MasterData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { formatDateDDMonthYYYY, getDevicePixelRatio, showToast } from '../../utils/common';
import { logEvent2, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { Modal } from 'react-bootstrap';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { selectEmployeeData } from '../../state/EmployeeData';

const SelectDonationScreen = ({ history, location }) => {
  const dispatch = useDispatch();
  const { amount, currencyId, abbrivation, initiativeId, item } = location.state;
  const [isChecked, setIsChecked] = useState(false);

  const microGiveAbout = useSelector(selectMicroGiveAbout);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successResponse, setSuccessResponse] = useState({});
  const accessibilityData = useSelector(selectAccessibilityData);
  const employeeData = useSelector(selectEmployeeData);

  const pixelRatio = getDevicePixelRatio();
  const empNameStyle = {
    position: 'absolute',
    top: `${368 / pixelRatio}px`,
    left: `${45 / pixelRatio}px`,
    color: '#ed1d23',
    fontSize: `${38 / pixelRatio}px`,
    fontFamily: 'Lato-Black',
    flexWrap: 'wrap',
    overflowWrap: 'break-word',
    width: `${600 / pixelRatio}px`,
    textOverflow: 'ellipsis',
  };
  const longerEmpNameStyle = {
    top: `${350 / pixelRatio}px`,
    left: `${45 / pixelRatio}px`,
    color: '#ed1d23',
    lineHeight: `${32 / pixelRatio}px`,
    fontSize: `${34 / pixelRatio}px`,
  };

  const eventTitle = {
    position: 'absolute',
    top: `${486 / pixelRatio}px`,
    left: `${45 / pixelRatio}px`,
    color: 'black',
    fontSize: `${28 / pixelRatio}px`,
    fontFamily: 'Lato-Black',
    flexWrap: 'wrap',
    lineHeight: `${32 / pixelRatio}px`,
    overflowWrap: 'break-word',
    width: `${620 / pixelRatio}px`,
    textOverflow: 'ellipsis',
  };

  const donationCertificateDate = {
    position: 'absolute',
    bottom: `${54 / pixelRatio}px`,
    left: `${590 / pixelRatio}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#000',
    fontSize: `${26 / pixelRatio}px`,
    fontFamily: 'Lato-Regular',
  };

  const certificateStyle = {
    position: 'absolute',
    height: `${800 / pixelRatio}px`,
    width: `${1200 / pixelRatio}px`,
    left: `${-20000 / pixelRatio}px`,
  };

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.SELECT_DONATION_SCREEN,
      NAVIGATION_ROUTES.SELECT_DONATION_SCREEN
    );
  }, []);

  const callContributeApi = async () => {
    const params = new URLSearchParams();
    params.append('initiative_id', initiativeId);
    params.append('currency_id', currencyId);
    params.append('amount', amount);
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(DONATE, REQUEST_TYPE.POST, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setSuccessResponse(apiResponse?.response ?? {});
          showToast(apiResponse?.response?.message);
          setShowSuccessModal(true);
        }
      }
    } catch (err) {
      handleError(err, params, DONATE, NAVIGATION_ROUTES.SELECT_DONATION_SCREEN);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressConfirm = () => {
    callContributeApi();
  };
  const downloadCertificate = async (e) => {
    logEvent2({
      eventType: ANALYTICS_EVENT_TYPES.MICROGIVE_CERTIFICATE_SHARE,
      itemId: item?.initiative_id ?? '',
      itemName: item?.title ?? '',
      categoryId: ANALYTICS_ITEM_NAMES.MICROGIVE,
      categoryName: ANALYTICS_ITEM_NAMES.MICROGIVE,
    });
    e.preventDefault();
    document.getElementById(`my-certificate`).classList.add('d-block');
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.GENERATING_BUSINESS_CARD,
      })
    );
    setTimeout(() => {
      html2canvas(document.getElementById(`my-certificate`), {
        height: 800 / pixelRatio,
        width: 1200 / pixelRatio,
        useCORS: true,
        onclone: function (clonedDoc) {
          clonedDoc.getElementById(`my-certificate`).classList.add('d-block');
        },
      })
        .then((canvas) => {
          document.getElementById(`my-certificate`).classList.remove('d-block');

          let xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = function () {
            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(xhr.response);
            a.download = 'certificate.jpeg';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            a.remove();
          };
          xhr.open('GET', canvas.toDataURL('image/jpeg')); // This is to download the canvas Image
          xhr.send();
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          // commenting out below code as it is not required
          // uploadBusinessCard(canvas.toDataURL(), e);
        })
        .catch(() => {
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        });
    }, 1000);
  };

  const uploadBusinessCard = async (imageUrl, e) => {
    const params = new URLSearchParams();
    params.append('file', imageUrl);
    params.append('type', '_CHEERS_IMAGE');
    try {
      const apiResponse = await apiRequest(UPLOAD_BUSINESS_CARD, REQUEST_TYPE.POST, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status === true) {
          const message = apiResponse.response.message;
          if (message) {
            e.stopPropagation();
            saveAs(
              apiResponse.response.data.business_card.base_url +
                apiResponse.response.data.business_card.image_path,
              'certificate.jpeg'
            );
          }
        }
      }
    } catch (err) {
      handleError(err, params, UPLOAD_BUSINESS_CARD, NAVIGATION_ROUTES.SELECT_DONATION_SCREEN);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onValueChange = (event) => setIsChecked(event.target.checked);
  const successDialog = () => (
    <>
      <div className="text-center mt-2 mb-3 microgive-awesome-text">Awesome !</div>
      <div className="text-center mt-3 ">
        {successResponse?.message ?? 'Thank you for your contribution.'}
      </div>
      <br />
      <div className="d-flex justify-content-around">
        <CustomButton
          buttonStyle="microgive-button-style mr-0"
          onClick={() => history.goBack()}
          disabled={isChecked ? false : true}
          showLoader={progressLoadingState.isProgressLoading}>
          Close
        </CustomButton>
        {successResponse?.data?.certificate_available && (
          <div
            className="d-flex justify-content-center align-items-center microgive-download_certificate-container common-cursor-pointer"
            onClick={downloadCertificate}>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_DOWNLOAD_ICON_WHITE,
                ACCESSIBILITY_IDENTIFIERS.COMMON
              )}
              className="microgive-download_certificate-icon"
            />
            <div className="microgive-download_certificate-text">Certificate</div>
          </div>
        )}
      </div>
      <br />

      <div className="hide-certificate">
        <div id={`my-certificate`} style={{ ...certificateStyle }}>
          <img
            src={item?.certificate_image?.base_url + item?.certificate_image?.image_path}
            width="100%"
            height="100%"
          />
          <div
            style={
              (
                employeeData?.first_name +
                ' ' +
                (employeeData?.middle_name ? employeeData?.middle_name + ' ' : '') +
                employeeData?.last_name
              ).length > 30
                ? { ...empNameStyle, ...longerEmpNameStyle }
                : { ...empNameStyle }
            }>
            {`${
              employeeData?.first_name +
              ' ' +
              (employeeData?.middle_name ? employeeData?.middle_name + ' ' : '') +
              employeeData?.last_name
            }`}
          </div>
          <div style={{ ...donationCertificateDate }}>
            {formatDateDDMonthYYYY(new Date().toString()).replace(/ /g, '-')}
          </div>
          <div style={{ ...eventTitle }}>
            {item?.title?.endsWith('.') || item?.title?.endsWith('!') || item?.title?.endsWith('?')
              ? `${item?.title}`
              : `${item?.title}.`}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICROGIVE} />
      <BackButton />
      <Modal
        show={showSuccessModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setShowSuccessModal(false);
          history.goBack();
        }}>
        <Modal.Body>{successDialog()}</Modal.Body>
      </Modal>
      <div className="main-content-container">
        <div className="text-center microgive-you-donating-text">You are donating</div>
        <div className="microgive-abbrevation-view">
          <div className="microgive-abbrevation-text">{`${abbrivation}`}</div>
          <div className="ml-1 microgive-amount-text">{`${amount}`}</div>
        </div>
        <div className="text-center mt-2 mb-3 microgive-towards-text">towards this cause</div>
        <HtmlView html={microGiveAbout} htmlStyle="microgive-html-content ml-3 mr-3" />
        <div className="form-check ml-3 mr-3">
          <input
            type="checkbox"
            className="form-check-input"
            checked={isChecked}
            onChange={onValueChange}
          />
          <label className="form-check-label microgive-agree-tc">
            I agree to the Terms and Conditions
          </label>
        </div>
        <CustomButton
          buttonStyle="microgive-button-style mr-0"
          onClick={onPressConfirm}
          disabled={isChecked ? false : true}
          showLoader={progressLoadingState.isProgressLoading}>
          Confirm
        </CustomButton>
      </div>
    </div>
  );
};

export default SelectDonationScreen;
