import { call, put, takeEvery } from 'redux-saga/effects';
import { ACTION_TYPES, REQUEST_TYPE } from '../common/constants/AppConstants';
import { GET_CHEERS_POINTS } from '../services/ApiUrls';
import { apiRequest } from '../services/Service';
import { saveCurrentPointsData } from '../state/CheersData';

export function* getCheersPoints(action) {
  try {
    const apiResponse = yield call(apiRequest, GET_CHEERS_POINTS, REQUEST_TYPE.GET, action.payload);
    if (apiResponse) {
      if (apiResponse.response.status === true) {
        if (Object.keys(apiResponse.response.data.feeds).length > 0) {
          yield put(saveCurrentPointsData(apiResponse.response.data.feeds.current_balance));
        }
      }
    }
  } catch (e) {
    yield put(saveCurrentPointsData(''));
  }
}

export function* watchCheersPoints() {
  yield takeEvery(ACTION_TYPES.GET_CHEERS_POINTS, getCheersPoints);
}
