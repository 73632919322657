import React from 'react';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';

const ParticipatesListItem = ({ item }) => {
  return (
    <div className="mt-2 mb-2">
      <div className="microgive-outer-view">
        <UserProfilePhoto
          imageBaseUrl={item.profile_picture.base_url}
          imagePath={item.profile_picture.image_path}
          imageClass="common-user-profile-photo-lg"
          employeeId={item.employee_id}
        />
        <div className="ml-3 microgive-outer-view">
          <div>
            <div className="mb-1 microgive-name">
              {item.guest_count == 0
                ? item.name
                : item.guest_count == 1
                ? item.name + ' ' + '(+ ' + item.guest_count + ' guest )'
                : item.name + ' ' + '(+ ' + item.guest_count + ' guests )'}
            </div>
            <div className="mt-1 microgive-position-text">{item.location}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipatesListItem;
