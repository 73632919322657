import React from 'react';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  WISH_TYPES,
} from '../../constants/AppConstants';

const DefaultBirthdayContentView = (props) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  const getSubText = () => {
    switch (props.type) {
      case WISH_TYPES.BIRTHDAY:
        return `Say Happy B'day!!`;
      case WISH_TYPES.ADVANCEMENT:
        return `Say Congratulations!!`;
      case WISH_TYPES.NEW_JOINEE:
        return `Say Welcome Onboard!!`;
      case WISH_TYPES.ANNIVERSARY:
        return `Say Happy Work Anniversary!!`;
      default:
        return `Say Happy B'day!!`;
    }
  };

  return (
    <div className={`default-content-view-container-wishes`}>
      <img
        src={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_CELEBRATION_WISHES,
          ACCESSIBILITY_IDENTIFIERS.PROFILE
        )}
        className="common-default-content-view-icon-md img-center"
      />
      <p className={`common-default-content-view-text-wishes common-ws-pre-line img-center`}>
        {props.message}
      </p>
      <p className={`common-default-content-view-text-wishes-sub common-ws-pre-line img-center`}>
        {getSubText()}
      </p>
    </div>
  );
};

export default DefaultBirthdayContentView;
