import React, { useState, useEffect } from 'react';
import AutoLink from './AutoLink';

function ChatMessage({ text, onRendered }) {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    onRendered(false);

    let currentIndex = 0;
    const interval = setInterval(() => {
      if (currentIndex <= text.length) {
        setDisplayedText(text.substring(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(interval);
        onRendered(true);
      }
    }, 30);

    return () => clearInterval(interval);
  }, [text, onRendered]);

  return (
    <div className="ask-ml-chat-bot-qa-text" style={{ maxWidth: '90%' }}>
      <AutoLink text={displayedText} />
    </div>
  );
}

export default ChatMessage;
