import React from 'react';
import { useHistory } from 'react-router';
import { NAVIGATION_ROUTES } from '../../../common/constants/AppConstants';
import CommunitiesListItem from '../components/CommunitiesListItem';

const CommunityTab = ({ data }) => {
  const history = useHistory();

  const onPressItem = (item) => {
    history.push(`${NAVIGATION_ROUTES.COMMUNITIES_DETAIL}/${item.community_id}`);
  };

  return (
    <div>
      <div className="pt-3 row">
        {data.map((item, index) => {
          return <CommunitiesListItem item={item} onPress={onPressItem} key={`${index}`} />;
        })}
      </div>
    </div>
  );
};
export default React.memo(CommunityTab);
