import React from 'react';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { getFeatureComponentUrl, getFeatureComponentUrlLdpi } from '../../../utils/accessibility';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  NAVIGATION_ROUTES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import { useHistory } from 'react-router-dom';
import { getUrlWithSpecificRendition } from '../../../utils/common';

const EpisodeParticipantsListItem = (props) => {
  const history = useHistory();
  const accessibilityData = useSelector(selectAccessibilityData);
  const employeeData = useSelector(selectEmployeeData);

  const {
    imageBaseUrl,
    imagePath,
    employeeId,
    linkedinUrl,
    imageStyle = {},
    imageClass = '',
    prependOnClick = () => {},
    onClick = (e) => {
      e.stopPropagation();
      prependOnClick();
      if (employeeId) {
        if (employeeId === employeeData.employee_id) {
          history.push(NAVIGATION_ROUTES.MY_PROFILE_DETAIL);
        } else {
          history.push(`${NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL}/${employeeId}`);
        }
      }
    },
  } = props;

  return (
    <div onClick={onClick}>
      <div className="common-user-profile-photo-container">
        <img
          className={`mx-auto d-block ${imageClass}`}
          src={
            imagePath
              ? getUrlWithSpecificRendition(imageBaseUrl, SCREEN_DENSITY_QUALIFIERS.LDPI, imagePath)
              : getFeatureComponentUrlLdpi(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
                  ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                )
          }
          style={imageStyle}
        />
        {linkedinUrl !== '' && (
          <div
            onClick={() => {
              let win = window.open(linkedinUrl, '_blank');
              win.focus();
            }}>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_LINKEDIN,
                ACCESSIBILITY_IDENTIFIERS.COMMON
              )}
              className="podcast-linked-in"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default EpisodeParticipantsListItem;
