import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../../common/constants/AppConstants';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { EMPLOYEES } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../state/UIState';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../../utils/accessibility';
import { Form } from 'react-bootstrap';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import debounce from 'lodash.debounce';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';

const AllEmployee = ({ onEmployeeSelected, selectedEmp }) => {
  const dispatch = useDispatch();
  const [employeesList, setEmployeesList] = useState([]);
  const pageIdRef = useRef(1);
  const loadMoreFlagRef = useRef(true);
  const [searchText, setSearchText] = useState('');
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [employeeObj, setEmployeeObj] = useState({});
  const [showLoadMore, setShowLoadMore] = useState(false);

  const accessibilityData = useSelector(selectAccessibilityData);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    setEmployeeObj(selectedEmp);
  }, [selectedEmp]);

  const getEmployees = useCallback(
    async (text = '') => {
      if (loadMoreFlagRef.current === true) {
        if (pageIdRef.current == 1) {
          setEmployeesList([]);
        }
        const params = {
          page_id: pageIdRef.current,
          query: text,
          geography_id: '',
          function_id: '',
          level_id: '',
          location_id: '',
          layer_id: '',
          range: '',
        };
        try {
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          const apiResponse = await apiRequest(EMPLOYEES, REQUEST_TYPE.GET, params);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                setEmployeesList((prev) => [...prev, ...apiResponse.response.data.feeds]);
                pageIdRef.current = pageIdRef.current + 1;
                loadMoreFlagRef.current = true;
                setShowLoadMore(true);
              } else {
                loadMoreFlagRef.current = false;
                setShowLoadMore(false);
              }
            }
          }
        } catch (err) {
          handleError(err, params, EMPLOYEES, NAVIGATION_ROUTES.ALL_EMPLOYEE_SCREEN);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      } else {
        loadMoreFlagRef.current = false;
      }
    },
    [dispatch]
  );

  useEffect(() => {
    getEmployees();
  }, [getEmployees]);

  const loadMoreHandler = () => {
    getEmployees(searchText);
    pageIdRef.current = pageIdRef.current + 1;
    if (employeesList.length == 0) {
      loadMoreFlagRef.current = false;
    }
  };

  const debouncedCallback = (event) => {
    if (event && event.target.value.length > 2) {
      pageIdRef.current = 1;
      loadMoreFlagRef.current = true;
      getEmployees(event.target.value);
    }
    setSearchText(event.target.value);
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );

  const onSearchSubmitHandler = (event) => {
    event.preventDefault();
    setEmployeesList([]);
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    getEmployees(searchText);
  };

  const onTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const onClickSearchHandler = () => {
    setIsSearchEnabled(true);
  };

  const resetSearchHandler = useCallback(() => {
    setIsSearchEnabled(false);
    setSearchText('');
    setEmployeesList([]);
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    getEmployees('');
  }, [getEmployees]);

  const showProfiles = () => {
    if (employeesList.length > 0) {
      return employeesList.map((profile, index) => {
        return (
          <div key={`${index}`} className="anniversary-emp-image-div col-6 col-sm-3 col-lg-2">
            <UserProfilePhoto
              imageBaseUrl={profile.profile_picture.base_url}
              imagePath={profile.profile_picture.image_path}
              imageClass="common-user-profile-photo-xl"
            />
            <div>
              <p className="anniversary-profile-name">{profile.first_name}</p>
              <p className="anniversary-profile-name">{profile.last_name}</p>
            </div>
            <Form.Check
              type={'radio'}
              label={employeeObj.employee_id === profile.employee_id ? 'Selected' : 'Select'}
              checked={employeeObj.employee_id === profile.employee_id}
              onChange={() => {
                setEmployeeObj(profile);
              }}
            />
          </div>
        );
      });
    } else
      return (
        <div className="w-100">
          <DefaultContentView
            message={ERROR_MESSAGES.SEARCH_NO_RESULTS}
            iconUri={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
              ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
            )}
          />
        </div>
      );
  };

  return (
    <div className="mt-3 mb-3">
      <div className="header-view d-flex justify-content-between">
        {isSearchEnabled ? (
          <form className="microgive-search-form" onSubmit={onSearchSubmitHandler}>
            <input
              autoFocus
              className="ml-3 microgive-search-bar"
              type="text"
              placeholder="Search.."
              onChange={debounceInputHandler}
            />
          </form>
        ) : (
          <div />
        )}
        {isSearchEnabled ? (
          <div onClick={resetSearchHandler}>
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
              )}
              className="header-icon mr-3"
            />
          </div>
        ) : (
          <div onClick={onClickSearchHandler}>
            <img
              src={getHeaderComponentUrl(accessibilityData, ACCESSIBILITY_HEADER_COMPONENTS.SEARCH)}
              className="header-icon mr-3"
            />
          </div>
        )}
      </div>
      <div className="anniversary-employee-view mt-2 mb-2">
        <div className="row">{showProfiles()}</div>
        <div className="load-more">
          {showLoadMore && (
            <LoadMoreBtn
              onClick={loadMoreHandler}
              isLoading={progressLoadingState.isProgressLoading}
            />
          )}
        </div>
      </div>
      {Object.keys(employeeObj).length > 0 && (
        <CustomButton buttonStyle="w-100 py-1" onClick={() => onEmployeeSelected(employeeObj)}>
          Continue
        </CustomButton>
      )}
    </div>
  );
};

export default AllEmployee;
