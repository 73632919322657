import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { selectAnniversaryConfig } from '../../../../state/MasterData';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  MESSAGES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import { apiRequest } from '../../../../services/Service';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import AllEmployeeAnniversary from '../../components/AllEmployeeAnniversary';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../../state/UIState';
import { Modal } from 'react-bootstrap';
import { getFeatureComponentUrl } from '../../../../utils/accessibility';
import CustomButton from '../../../../common/ui/custom_button/CustomButton';
import { showToast } from '../../../../utils/common';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';

const NominationsTab = ({ identifier }) => {
  const dispatch = useDispatch();
  const [showNominationView, setShowNominationView] = useState(true);
  const [text, setText] = useState('');
  const [nominationFor, setNominationFor] = useState('');
  const [beneficiary, setBeneficiary] = useState('');
  const [problemSolved, setProblemSolved] = useState('');
  const [uniqueness, setUniqueness] = useState('');
  const [benefits, setBenefits] = useState('');
  const [nomineeId, setNomineeId] = useState('');
  const [nomineeName, setNomineeName] = useState('Nominee Name');
  const [managerId, setManagerId] = useState('');
  const [managerName, setManagerName] = useState('Manager Reference (Name of the Manager)');
  const [showDialog, setShowDialog] = useState(false);
  const [showNomineeView, setShowNomineeView] = useState(false);
  const [showManagerReferenceView, setShowManagerRefrenceView] = useState(false);

  const anniversaryConfig = useSelector(selectAnniversaryConfig).find(
    (element) => element.identifier === identifier
  );
  const accessibilityData = useSelector(selectAccessibilityData);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.DIGITAL_WARRIOR_NOMINATIONS_TAB_VISIT,
      identifier,
      ANALYTICS_ITEM_NAMES.DIGITAL_WARRIOR
    );
  }, [identifier]);

  useEffect(() => {
    if (anniversaryConfig && anniversaryConfig.config) {
      let startDate = JSON.parse(anniversaryConfig.config).nomination_start_date;
      let startTime = JSON.parse(anniversaryConfig.config).nomination_start_time;
      let endDate = JSON.parse(anniversaryConfig.config).nomination_end_date;
      let endTime = JSON.parse(anniversaryConfig.config).nomination_end_time;

      const todayTimestamp = dayjs();
      const convertedStartDate = `${startDate.split('-')[2]}/${startDate.split('-')[1]}/${
        startDate.split('-')[0]
      }`;
      const convertedStartTimestamp = `${convertedStartDate} ${startTime}:00`;
      const convertedStartUtcTimestamp = dayjs.utc(convertedStartTimestamp).subtract(330, 'minute');
      const convertedStartLocalTimestamp = convertedStartUtcTimestamp.local();
      const convertedEndDate = `${endDate.split('-')[2]}/${endDate.split('-')[1]}/${
        endDate.split('-')[0]
      }`;
      const convertedEndTimestamp = `${convertedEndDate} ${endTime}:00`;
      const convertedEndUtcTimestamp = dayjs.utc(convertedEndTimestamp).subtract(330, 'minute');
      const convertedEndLocalTimestamp = convertedEndUtcTimestamp.local();

      if (todayTimestamp.valueOf() < convertedStartLocalTimestamp.valueOf()) {
        setShowNominationView(false);
        setText('Nominations to open soon');
      } else if (
        todayTimestamp.valueOf() >= convertedStartLocalTimestamp.valueOf() &&
        todayTimestamp.valueOf() <= convertedEndLocalTimestamp.valueOf()
      ) {
        setShowNominationView(true);
      } else {
        setShowNominationView(false);
        setText('Nominations are closed');
      }
    }
  }, [anniversaryConfig]);

  const callSubmitNominationApi = async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: 'Submitting...',
        })
      );
      const params = new URLSearchParams();
      params.append('nominee_id', nomineeId);
      params.append('nomination_for', nominationFor);
      params.append('beneficiary', beneficiary);
      params.append('problem_solved', problemSolved);
      params.append('uniqueness', uniqueness);
      params.append('benefits', benefits);
      params.append('manager_ref_id', managerId);
      const apiResponse = await apiRequest(identifier + '/nominate', REQUEST_TYPE.POST, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          showToast(apiResponse.response.message);
          setBeneficiary('');
          setNominationFor('');
          setNomineeName('Nominee Name');
          setNomineeId('');
          setProblemSolved('');
          setUniqueness('');
          setBenefits('');
          setManagerName('Manager Reference (Name of the Manager)');
          setManagerId('');
          setShowDialog(true);
        }
      }
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressSubmit = () => {
    if (nomineeId === '') {
      showToast(MESSAGES.NOMINEE_NOT_EMPTY);
    } else if (
      (nominationFor === '' || beneficiary === '' || problemSolved === '',
      uniqueness === '',
      benefits === '')
    ) {
      showToast(ERROR_MESSAGES.ENTER_ALL_FIELDS);
    } else if (managerId === '') {
      showToast(ERROR_MESSAGES.MANAGER_REFERENCE);
    } else {
      callSubmitNominationApi();
    }
  };

  const onTextChangeNominationFor = (event) => {
    setNominationFor(event.target.value);
  };

  const onTextChangeBeneficiary = (event) => {
    setBeneficiary(event.target.value);
  };

  const onTextChangeProblemSolved = (event) => {
    setProblemSolved(event.target.value);
  };

  const onTextChangeUniqueness = (event) => {
    setUniqueness(event.target.value);
  };

  const onTextChangeBenefits = (event) => {
    setBenefits(event.target.value);
  };

  const onPressNomineeName = () => {
    setShowNomineeView(true);
  };

  const onPressAddManager = () => {
    setShowManagerRefrenceView(true);
  };

  const onPressOk = () => {
    setShowDialog(false);
  };

  const onPressClose = () => {
    setShowDialog(false);
  };

  const successDialog = () => {
    return (
      <div>
        <div className="d-flex flex-row-reverse">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_CLOSE,
              ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
            )}
            className="microgive-header-icon"
            onClick={onPressClose}
          />
        </div>
        <div className="text-center mt-3 microgive-register-message-text">
          Thank you for your nomination. Results will be announced soon.
        </div>
        <CustomButton buttonStyle="microgive-button-style mt-3" onClick={onPressOk}>
          Ok
        </CustomButton>
      </div>
    );
  };

  const onEmployeeSelected = (selectedEmployee, type) => {
    if (type === 'nominee') {
      setShowNomineeView(false);
      setNomineeId(selectedEmployee.employee_id);
      setNomineeName(selectedEmployee.first_name + ' ' + selectedEmployee.last_name);
    } else {
      setShowManagerRefrenceView(false);
      setManagerId(selectedEmployee.employee_id);
      setManagerName(selectedEmployee.first_name + ' ' + selectedEmployee.last_name);
    }
  };

  return (
    <div className="main-content-container">
      <Modal
        show={showDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowDialog(false)}>
        <Modal.Body>{successDialog()}</Modal.Body>
      </Modal>
      {showNominationView ? (
        <div>
          {showNomineeView && (
            <AllEmployeeAnniversary onEmployeeSelected={onEmployeeSelected} type="nominee" />
          )}
          <div className="form-group">
            <div className="card pl-2 pt-2 pb-2" onClick={onPressNomineeName}>
              {nomineeName}
            </div>
          </div>
          <div className="form-group">
            <label>Nomination for (Name or title of the project)*</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              onChange={onTextChangeNominationFor}
              value={nominationFor}
            />
          </div>
          <div className="form-group">
            <label>Beneficiary (Microland or Client Name)*</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              onChange={onTextChangeBeneficiary}
              value={beneficiary}
            />
          </div>
          <div className="form-group">
            <label>Problem Solved*</label>
            <textarea
              className="form-control"
              rows="8"
              placeholder=""
              value={problemSolved}
              onChange={onTextChangeProblemSolved}
            />
          </div>
          <div className="form-group">
            <label>Uniqueness of the Digital Solution*</label>
            <textarea
              className="form-control"
              rows="8"
              placeholder=""
              value={uniqueness}
              onChange={onTextChangeUniqueness}
            />
          </div>
          <div className="form-group">
            <label>Benefits to Microland or the Client*</label>
            <textarea
              className="form-control"
              rows="8"
              placeholder=""
              value={benefits}
              onChange={onTextChangeBenefits}
            />
          </div>
          {showManagerReferenceView && (
            <AllEmployeeAnniversary onEmployeeSelected={onEmployeeSelected} type="manager" />
          )}
          <div className="card pl-2 pt-2 pb-2" onClick={onPressAddManager}>
            {managerName}
          </div>
          <CustomButton
            buttonStyle="anniversary-button mr-0 mt-3"
            onClick={onPressSubmit}
            showLoader={progressLoadingState.isProgressLoading}>
            Submit Your Nomination
          </CustomButton>
        </div>
      ) : (
        <div>{text}</div>
      )}
    </div>
  );
};

export default NominationsTab;
