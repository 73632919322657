import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MY_PROFILE_IDENTIFIERS,
  REQUEST_TYPE,
  ACCESSIBILITY_IDENTIFIERS,
  ACCESSIBILITY_FEATURE_COMPONENTS,
  NAVIGATION_ROUTES,
  ACTION_TYPES,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
} from '../../common/constants/AppConstants';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import { GET_ASSETS } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import {
  saveShowEmptyContentView,
  selectBaseUrl,
  selectShowEmptyContentView,
} from '../../state/UIState';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { logEvent } from '../../utils/FirebaseAnalyticsUtils';

const MyAssetScreen = ({ history, location }) => {
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);
  const [myProfileData, setMyProfileData] = useState({});
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const baseUrl = useSelector(selectBaseUrl);
  const [id] = useState(new URLSearchParams(location.search).get('id'));

  const callMyProfileApi = useCallback(async (tabType) => {
    setError(false);
    dispatch(saveShowEmptyContentView(true));
    setIsLoading(true);
    try {
      let data = [];
      if (tabType === MY_PROFILE_IDENTIFIERS.PROFILE) {
        //   data = employeeData;
      } else if (tabType === MY_PROFILE_IDENTIFIERS.ASSETS) {
        const apiResponse = await apiRequest(GET_ASSETS, REQUEST_TYPE.GET, {});
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            data = apiResponse.response.data.feed.entitlements;
          }
        }
      }
      setIsLoading(false);
      setMyProfileData((tabData) => {
        return { ...tabData, ...{ [tabType]: data } };
      });
    } catch (err) {
      handleError(err, {}, GET_ASSETS, NAVIGATION_ROUTES.MY_PROFILE_ASSET);
      setIsLoading(false);
      setError(true);
    }
  }, []);

  const renderScreen = useCallback(async () => {
    callMyProfileApi(MY_PROFILE_IDENTIFIERS.ASSETS);
  }, [callMyProfileApi]);

  useEffect(() => {
    if (baseUrl !== '' && id && id !== '') {
      dispatch({ type: ACTION_TYPES.GET_TOKEN, payload: { id: id } });
    }
  }, [baseUrl, dispatch, id]);

  useEffect(() => {
    if (baseUrl) {
      renderScreen();
    }
  }, [baseUrl, renderScreen]);

  const onPressAssetHandler = (assetData) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.MY_PROFILE_ASSET_VIEW,
      assetData.list_id.toString(),
      ANALYTICS_ITEM_NAMES.MY_PROFILE_ASSETS
    );
    history.push(NAVIGATION_ROUTES.MY_ASSET_DETAIL, {
      assetDetailList: assetData.details,
      assetType: assetData.title,
    });
  };

  const renderAssetItem = (assetItemData, index) => {
    if (assetItemData.details.length) {
      return (
        <div
          key={index}
          onClick={() => {
            onPressAssetHandler(assetItemData);
          }}
          className="my-profile-asset-container d-flex align-items-center pt-2 pb-2 pl-2 pr-2">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              assetItemData.icon_name,
              ACCESSIBILITY_IDENTIFIERS.PROFILE
            )}
            className="my-profile-asset-icon mr-3"
          />
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_DOT_INDICATOR,
              ACCESSIBILITY_IDENTIFIERS.PROFILE
            )}
            className="my-profile-asset-indicator-icon mr-3"
          />
          <p className="my-profile-asset-item-title">{assetItemData.title}</p>
        </div>
      );
    }
  };

  const renderAssetSection = (assetSectionData, index) => {
    return (
      assetSectionData.assets.some((assetItem) => assetItem.details.length) && (
        <div key={index} className="my-profile-asset-section-container pt-3 pb-3">
          <p className="my-profile-asset-section-title mb-0">{assetSectionData.title}</p>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 ml-3 mr-3">
            {assetSectionData.assets.map((assetItem, index) => renderAssetItem(assetItem, index))}
          </div>
        </div>
      )
    );
  };

  const showEmptyView = () => {
    return <DefaultContentView message={'You have no assets assigned yet'} />;
  };

  return (
    <div>
      {showEmptyContentView && !isLoading && !Object.keys(myProfileData).length
        ? showEmptyView()
        : !!Object.keys(myProfileData).length &&
          myProfileData['assets'].map((assetSection, index) =>
            renderAssetSection(assetSection, index)
          )}
    </div>
  );
};

export default MyAssetScreen;
