import React from 'react';

const CustomYoutubePlayer = (props) => {
  const { videoUrl, containerClass = '', videoId } = props;

  let videoSrc;
  if (videoUrl?.includes('youtube.com')) {
    videoSrc = `https://www.youtube.com/embed/${videoId}`;
  } else if (videoUrl?.includes('vimeo.com')) {
    const videoId = videoUrl?.split('/').pop();
    videoSrc = `https://player.vimeo.com/video/${videoId}`;
  } else {
    videoSrc = `https://www.youtube.com/embed/${videoId}`;
  }

  return (
    <div className={`embed-responsive embed-responsive-16by9 ${containerClass}`}>
      <iframe
        src={videoSrc}
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
        title="video"
      />
    </div>
  );
};

export default CustomYoutubePlayer;
