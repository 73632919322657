import React, { useEffect, useState, useCallback, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  NAVIGATION_ROUTES,
  LOADING_MESSAGES,
  REQUEST_TYPE,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  FEATURE_TYPE,
  ACCESSIBILITY_HEADER_COMPONENTS,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../services/Service';
import { COMMENT_BY_ID, COMMUNITIES_BY_ID } from '../../services/ApiUrls';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../state/UIState';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import PostListItem from './components/PostListItem';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import { Modal } from 'react-bootstrap';
import LikeCommentDialog from './components/LikeCommentDialog';
import PinnedPostListItem from './components/PinnedPostListItem';
import { useParams } from 'react-router-dom';
import { selectAccessData } from '../../state/UserAccessData';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import debounce from 'lodash.debounce';
import queryString from 'query-string';
import ActivityLoader from '../../common/ui/activity_loader/ActivityLoader';
import { showToast } from '../../utils/common';

const ViewCommunityPosts = ({ history, location }) => {
  const dispatch = useDispatch();

  const { communityId } = useParams();
  const [showDialog, setShowDialog] = useState(false);
  const [pinnedPosts, setPinnedPosts] = useState([]);
  const [postData, setPostData] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);

  const progressLoadingState = useSelector(selectProgressLoadingState);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const [noMatchFlag, setNoMatchFlag] = useState(false);

  const accessData = useSelector(selectAccessData);
  const [showLikeButton, setShowLikeButton] = useState(true);
  const [showCommentButton, setShowCommentButton] = useState(true);
  const [showCreatePostButton, setShowCreatePostButton] = useState(true);
  const textInputRef = useRef();
  const inputSearchRef = useRef();
  const communityAccessData = accessData?.data?.find((item) => item.feature_key === '_communities');
  const searchTextRef = useRef(location?.hash ?? '');
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [searchString, setSearchString] = useState('');
  const query = useMemo(
    () => queryString.parse(location.search, { parseBooleans: true }),
    [location.search]
  );
  const [quickSearchLoading, setQuickSearchLoading] = useState(false);
  const communityHistory = history?.location?.state?.community;

  useEffect(() => {
    if (communityAccessData) {
      const actions = communityAccessData?.actions;
      setShowLikeButton(actions?.includes('_like'));
      setShowCommentButton(actions?.includes('_comment'));
      setShowCreatePostButton(actions?.includes('_post'));
    }
  }, [communityAccessData]);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.VIEW_COMMUNITY_POSTS,
      NAVIGATION_ROUTES.VIEW_COMMUNITY_POSTS
    );
  }, []);

  useEffect(() => {
    setIsSearchEnabled(location?.hash ? true : false);
  }, [location]);

  useEffect(() => {
    setSearchString(query.search ?? '');
    setPageId(1);
    setLoadMoreFlag(1);
  }, [query]);

  const handleHtmlClicks = (e) => {
    const target = e.target.getAttribute('href');
    if (target && (target.startsWith('/') || target.startsWith('?'))) {
      e.stopPropagation();
      e.preventDefault();
      if (target.includes(NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL)) {
        logEvent(
          ANALYTICS_EVENT_TYPES.USER_MENTION_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.USER_MENTION
        );
        history.push(target);
      } else {
        logEvent(
          ANALYTICS_EVENT_TYPES.HASHTAG_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.HASHTAG
        );
        history.replace(target, { community: communityHistory });
      }
    }
  };

  const callPostsApi = useCallback(
    async (id, loadMore, text = '', isQuickSearch = false) => {
      setNoMatchFlag(false);
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        if (isQuickSearch) {
          setQuickSearchLoading(true);
        } else {
          dispatch(
            saveProgressLoadingState({
              isProgressLoading: true,
              progressMessage: LOADING_MESSAGES.FETCH_COMMUNITY_POST,
            })
          );
        }
        const get_params = {
          page_id: id,
          query: text,
        };
        try {
          const response = await apiRequest(
            COMMUNITIES_BY_ID + communityId + '/posts',
            REQUEST_TYPE.GET,
            get_params
          );
          if (Object.keys(response).length > 0) {
            if (response.response.status === true) {
              if (response.response.data.feeds) {
                if (id === 1) setPostData([]);
                const data = response.response.data.feeds;
                const pinnedPostsData = response.response.data.pinned_post;
                setPinnedPosts(pinnedPostsData);
                if (data.length > 0) {
                  if (id === 1) {
                    setPostData(data);
                  } else {
                    setPostData((oldArray) => [...oldArray, ...data]);
                  }
                  setLoadMoreFlag(true);
                } else {
                  if (response.response.data.feeds.length === 0 && id === 1) {
                    setNoMatchFlag(true);
                  }
                  setLoadMoreFlag(false);
                }
              }
            }
          }
          setQuickSearchLoading(false);
          dispatch(
            saveProgressLoadingState({
              isProgressLoading: false,
            })
          );
        } catch (err) {
          handleError(
            err,
            get_params,
            COMMUNITIES_BY_ID + communityId + '/posts',
            NAVIGATION_ROUTES.VIEW_COMMUNITY_POSTS
          );
          setQuickSearchLoading(false);
          dispatch(
            saveProgressLoadingState({
              isProgressLoading: false,
            })
          );
        }
      }
    },
    [communityId, dispatch]
  );

  const debouncedCallback = (event) => {
    searchTextRef.current = event.target.value;
    if (event && event.target.value.length > 2) {
      setPageId(1);
      setLoadMoreFlag(true);
      callPostsApi(1, true, event.target.value, true);
    }
    if (event && event.target.value === '') {
      setPageId(1);
      setLoadMoreFlag(true);
      setPostData([]);
      callPostsApi(1, true, searchTextRef.current);
    }
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );

  useEffect(() => {
    if (!location?.hash) {
      callPostsApi(1, true, searchString);
    }
  }, [callPostsApi, location?.hash, searchString]);

  useEffect(() => {
    if (inputSearchRef.current && location?.hash && isSearchEnabled) {
      searchTextRef.current = location?.hash;
      inputSearchRef.current.value = location?.hash;
      logEvent(ANALYTICS_EVENT_TYPES.HASHTAG_QUERY, location?.hash, ANALYTICS_ITEM_NAMES.HASHTAG);
      callPostsApi(1, true, searchTextRef.current);
    }
  }, [inputSearchRef.current, location, isSearchEnabled]);

  const onPressLoadMore = () => {
    callPostsApi(pageId + 1, loadMoreFlag, searchTextRef.current ?? searchString);
    setPageId(pageId + 1);
  };

  const onSearchSubmitHandler = (event) => {
    event.preventDefault();
    if (searchTextRef.current.length < 3) {
      logEvent(ANALYTICS_EVENT_TYPES.COMMUNITY_SEARCH_QUERY, '', searchTextRef.current);
      setPageId(1);
      setLoadMoreFlag(true);
      setPostData([]);
      callPostsApi(1, true, searchString);
    }
  };

  const resetSearchHandler = useCallback(() => {
    if (location.hash) {
      history.replace(`${NAVIGATION_ROUTES.VIEW_COMMUNITY_POSTS}/${communityId}`, {
        community: communityHistory,
      });
    }
    textInputRef.current.reset();
    searchTextRef.current = '';
    setPageId(1);
    setLoadMoreFlag(true);
    setPostData([]);
    callPostsApi(1, true, searchTextRef.current);
    setIsSearchEnabled(false);
  }, [callPostsApi, location]);

  const onClickSearchHandler = () => {
    logEvent(ANALYTICS_EVENT_TYPES.COMMUNITY_SEARCH_CLICK, '', ANALYTICS_ITEM_NAMES.COMMUNITIES);
    setIsSearchEnabled(true);
  };

  const onPressWritePost = () => {
    history.push(NAVIGATION_ROUTES.WRITE_POST, {
      communityId: communityId,
    });
  };

  const updateLikeCount = (postId, isLiked) => {
    let arr = postData;
    let oldCount = arr.find((element) => element.post_id === postId).like_count;
    let objIndex = arr.findIndex((element) => element.post_id === postId);
    arr[objIndex].like_count = isLiked ? oldCount - 1 : oldCount + 1;
    arr[objIndex].is_liked = !isLiked;
    setPostData([...arr]);
  };

  const onPressLikeButton = (item) => {
    if (communityHistory.is_member && communityHistory.approved) {
      logEvent(
        ANALYTICS_EVENT_TYPES.COMMUNITIES_POST_LIKE,
        item.post_id,
        ANALYTICS_ITEM_NAMES.LIKE
      );
      callLikeApi(item.post_id, item.is_liked);
    } else {
      setShowDialog(true);
    }
  };

  const onPressCommentButton = (item) => {
    if (communityHistory.is_member && communityHistory.approved) {
      logEvent(
        ANALYTICS_EVENT_TYPES.COMMUNITIES_POST_COMMENT,
        item.post_id,
        ANALYTICS_ITEM_NAMES.COMMENT
      );
      history.push(NAVIGATION_ROUTES.COMMENTS, {
        referId: item.post_id,
        likeType: FEATURE_TYPE.COMMUNITY,
        fromRoute: NAVIGATION_ROUTES.VIEW_COMMUNITY_POSTS,
      });
    } else {
      setShowDialog(true);
    }
  };

  const callLikeApi = async (postId, isLiked) => {
    const params = new URLSearchParams();
    params.append('type', FEATURE_TYPE.COMMUNITY);
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
        })
      );
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + postId + '/like',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.message) {
            const message = apiResponse.response.message;
            if (message) {
              showToast(message);
            }
          }
          updateLikeCount(postId, isLiked);
        }
      }
    } catch (err) {
      handleError(
        err,
        params,
        COMMENT_BY_ID + postId + '/like',
        NAVIGATION_ROUTES.VIEW_COMMUNITY_POSTS
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_POST_AVAILABLE} />;
  };

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right">
        <div
          className={
            isSearchEnabled
              ? 'search-bar-with-icon search-bar-border mr-3'
              : 'search-bar-with-icon mr-3'
          }>
          {isSearchEnabled ? (
            <form
              className="directory-search-bar"
              onSubmit={onSearchSubmitHandler}
              ref={textInputRef}>
              <input
                autoFocus
                className="ml-2 directory-search-bar pr-3"
                type="text"
                placeholder="Search.."
                ref={inputSearchRef}
                maxLength={50}
                onChange={debounceInputHandler}
              />
            </form>
          ) : (
            <div className="directory-search-bar" />
          )}

          {isSearchEnabled ? (
            <div className="gcdo-icon-row" onClick={resetSearchHandler}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
                )}
                className="header-icon mr-2"
              />
            </div>
          ) : (
            <div className="gcdo-icon-row" onClick={onClickSearchHandler}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH
                )}
                className="header-icon mr-2"
              />
            </div>
          )}
        </div>
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.COMMUNITIES} />
      <Modal
        show={showDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowDialog(false)}>
        <Modal.Body>
          <LikeCommentDialog onChangeDialogVisible={(value) => setShowDialog(value)} flag={'1'} />
        </Modal.Body>
      </Modal>
      <div className="header-view justify-content-between">
        <BackButton isFullRow={false} />
        {communityHistory.is_member && communityHistory.approved && showCreatePostButton && (
          <CustomButton buttonStyle="communities-button-style mr-0" onClick={onPressWritePost}>
            Write a post
          </CustomButton>
        )}
      </div>
      <div className="main-content-container">
        <ActivityLoader visible={quickSearchLoading} />
        <div className="d-flex justify-content-center">
          <div className="common-container-md">
            {pageId === 1 && noMatchFlag ? (
              emptyListView()
            ) : (
              <div>
                {pinnedPosts.length > 0 && <PinnedPostListItem pinnedPostsData={pinnedPosts[0]} />}
                {postData.map((item, index) => {
                  return (
                    <PostListItem
                      item={item}
                      key={`${index}`}
                      onPressLikeButton={onPressLikeButton}
                      onPressCommentButton={onPressCommentButton}
                      showCommentButton={showCommentButton}
                      showLikeButton={showLikeButton}
                      handleHtmlClicks={handleHtmlClicks}
                    />
                  );
                })}
                {loadMoreFlag && (
                  <LoadMoreBtn
                    onClick={onPressLoadMore}
                    isLoading={progressLoadingState.isProgressLoading}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCommunityPosts;
