import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCartItemsCount, selectSegments } from '../../../state/EStoreData';

const PriceInformationTable = () => {
  const cartItemsCount = useSelector(selectCartItemsCount);
  const segments = useSelector(selectSegments);
  const [grandTotal, setGrandTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [shipping, setShipping] = useState(0);

  useEffect(() => {
    const gTotal = segments.find((segment) => segment.code === 'grand_total')?.value;
    const sTotal = segments.find((segment) => segment.code === 'subtotal')?.value;
    const shippingFee = segments.find((segment) => segment.code === 'shipping')?.value;
    setGrandTotal(gTotal);
    setShipping(shippingFee);
    setSubTotal(sTotal);
  }, [segments]);

  return segments.length > 0 ? (
    <div className="estore-cart-price-details-block">
      <div className="estore-size-color-title-block">
        <div className="estore-size-color-font pb-1">{`PRICE DETAILS (${cartItemsCount} Item${
          cartItemsCount === 1 ? '' : 's'
        })`}</div>
      </div>
      <div className="ecart-prices-list-container">
        <div className="ecart-price-details-item">
          <div className="estore-cart-item-price-text">Sub Total</div>
          <div className="estore-cart-item-price-text">{`\u20b9 ${subTotal}`}</div>
        </div>
        <div className="ecart-price-details-item">
          <div className="estore-cart-item-price-text">Shipping Fee</div>
          <div className="estore-cart-item-price-text">{`\u20b9 ${shipping}`}</div>
        </div>
        <div className="ecart-price-details-item-total">
          <div className="estore-cart-item-price-text-bold">Grand Total</div>
          <div className="estore-cart-item-price-text-bold">{`\u20b9 ${grandTotal}`}</div>
        </div>
      </div>
    </div>
  ) : null;
};

export default PriceInformationTable;
