import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
  SUPPORTED_IMAGE_FORMATS,
  SUPPORTED_IMAGE_FORMATS_WEB,
  WARNING_MESSAGES,
} from '../../common/constants/AppConstants';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { WOMENS_DAY_UPLOAD_GALLERY } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { Modal } from 'react-bootstrap';
import { getUrlWithSpecificRendition, showToast } from '../../utils/common';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { saveProgressLoadingState } from '../../state/UIState';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { selectMaxFileUploadSizeConfig } from '../../state/MasterData';
import { uploadDocuments } from '../../utils/commonApiRequests';
import BackButton from '../../common/ui/common_back_button/BackButton';

const WomensDayGalleryScreen = ({ history, location }) => {
  const [gallery] = useState(location.state.data);
  const year = location.state.year;
  const [showUploadImageDialog, setShowUploadImageDialog] = useState(false);
  const [showImageUploadSuccessDialog, setShowImageUploadSuccessDialog] = useState(false);
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);
  const maxFileUploadSizeInMB = useSelector(selectMaxFileUploadSizeConfig);
  const selectImagesInputRef = useRef(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagesLength, setSelectedImagesLength] = useState(0);

  const onPressItem = (item, index) => {
    history.push(NAVIGATION_ROUTES.WOMENS_DAY_GALLERY_FULL_IMAGE, {
      baseUrl: location.state.baseUrl,
      cards: item,
      cardIndex: index,
    });
  };

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.WOMENS_DAY_GALLERY, NAVIGATION_ROUTES.WOMENS_DAY_GALLERY);
  }, []);

  const renderItem = (item, index) => {
    return (
      <div className="womens-day-main-container">
        <div
          onClick={() => {
            onPressItem(gallery, index);
          }}>
          <img
            className="womens-day-image-item"
            src={getUrlWithSpecificRendition(
              location.state.baseUrl,
              SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
              item
            )}
          />
        </div>
      </div>
    );
  };

  const onPressUploadPhotos = () => {
    setShowUploadImageDialog(true);
  };

  const onPressViewGallery = () => {
    setShowImageUploadSuccessDialog(false);
  };

  const imageUploadDialog = () => {
    return (
      <div>
        <div className="womens-day-team-view">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_UPLOAD_PHOTO,
              ACCESSIBILITY_IDENTIFIERS.MICROGIVE
            )}
            className="womens-day-upload-icon common-cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              selectImagesInputRef.current.click();
            }}
          />
          <input
            type="file"
            onChange={onFileChange}
            accept={SUPPORTED_IMAGE_FORMATS}
            id="input"
            ref={selectImagesInputRef}
            multiple
            onClick={(event) => {
              event.target.value = null;
            }}
            className="d-none"
          />
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            selectImagesInputRef.current.click();
          }}
          className="text-center womens-day-select-images-toupload womens-day-select-images-toupload-container mt-2 common-cursor-pointer">
          Select images to be uploaded
        </div>
        <div className="text-center womens-day-images-review womens-day-images-review-container mt-2 mb-3">
          All images to be reviewed by MicrolandOne App Admin.
        </div>
      </div>
    );
  };

  const onFileChange = (event) => {
    let isAllFilesSupported = true;
    let isAnyFileSizeLarge = false;
    [...event.target.files].forEach((element) => {
      if (!SUPPORTED_IMAGE_FORMATS_WEB.includes(element.type)) {
        isAllFilesSupported = false;
      }
      if (Number(element.size) / (1024 * 1024) > maxFileUploadSizeInMB) {
        isAnyFileSizeLarge = true;
      }
    });
    if (!isAllFilesSupported) {
      showToast(WARNING_MESSAGES.FILE_NOT_SUPPORTED);
      setShowUploadImageDialog(false);
    } else if (isAnyFileSizeLarge) {
      showToast(WARNING_MESSAGES.FILE_SIZE_EXCEEDED(maxFileUploadSizeInMB));
      setShowUploadImageDialog(false);
    } else {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      setSelectedImagesLength(event.target.files?.length);
      [...event.target.files].map((image) => {
        uploadDocuments(image, 'womensday')
          .then((response) => {
            setSelectedImages((prev) => [...prev, { filename: response.fileName, type: 'image' }]);
          })
          .catch((message) => {
            showToast(message);
            dispatch(
              saveProgressLoadingState({
                isProgressLoading: false,
              })
            );
          });
      });
    }
  };

  const imageUploadSuccessDialog = () => {
    return (
      <div>
        <div className="womens-day-team-view">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_SUCCESS,
              ACCESSIBILITY_IDENTIFIERS.MICROGIVE
            )}
            className="womens-day-upload-icon mr-4"
          />
        </div>
        <div className="womens-day-images-got-uploaded text-center mt-2">Images got uploaded</div>
        <div className="text-center mt-2 womens-day-images-under-review womens-day-images-under-review-container">
          Your Images are under review
        </div>
        <div className="text-center mt-2 womens-day-images-reviewed-by womens-day-images-reviewedby-container">
          All images to be reviewed by MLOne App Admin.
        </div>
        <CustomButton buttonStyle="womensday-button-style mt-3" onClick={onPressViewGallery}>
          View Gallery
        </CustomButton>
      </div>
    );
  };

  const callUploadImagesApi = useCallback(
    async (imagesArr) => {
      setShowUploadImageDialog(false);
      const params = new URLSearchParams();
      params.append('files', JSON.stringify(imagesArr));
      params.append('year', year);
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
            progressMessage: LOADING_MESSAGES.DEFAULT,
          })
        );
        const apiResponse = await apiRequest(WOMENS_DAY_UPLOAD_GALLERY, REQUEST_TYPE.POST, params);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            setSelectedImages([]);
            setSelectedImagesLength(0);
            setShowImageUploadSuccessDialog(true);
          }
        }
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      } catch (err) {
        handleError(err, params, WOMENS_DAY_UPLOAD_GALLERY, NAVIGATION_ROUTES.WOMENS_DAY);
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    },
    [dispatch, year]
  );

  useEffect(() => {
    if (selectedImages.length === selectedImagesLength && selectedImagesLength !== 0) {
      callUploadImagesApi(selectedImages);
    }
  }, [callUploadImagesApi, selectedImages, selectedImagesLength]);

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.WOMENS_DAY} />
      <div className="header-view justify-content-between">
        <BackButton isFullRow={false} />
        <CustomButton buttonStyle="common-custom-button-primary" onClick={onPressUploadPhotos}>
          Upload Photos
        </CustomButton>
      </div>
      <div className="womens-day-main-container main-content-container container">
        <Modal
          show={showUploadImageDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => setShowUploadImageDialog(false)}>
          <Modal.Body>{imageUploadDialog()}</Modal.Body>
        </Modal>
        <Modal
          show={showImageUploadSuccessDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => setShowImageUploadSuccessDialog(false)}>
          <Modal.Body>{imageUploadSuccessDialog()}</Modal.Body>
        </Modal>
        <div className="row">
          {gallery.length > 0 &&
            gallery.map((item, index) => {
              return (
                <div key={`${index}`} className="col-sm-6 mb-3">
                  {renderItem(item, index)}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default WomensDayGalleryScreen;
