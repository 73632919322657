import React from 'react';
import {
  ACCESSIBILITY_IDENTIFIERS,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { getUrlWithSpecificRendition } from '../../utils/common';

const ContestGalleryScreen = ({ location, history }) => {
  const params = location.state;
  const baseUrl = params.gallary.base_url;

  const galleryArray =
    params.gallary.pictures &&
    params.gallary.pictures.map((item, index) => {
      return (
        <div key={`${index}`} className="col-sm-4 mb-2">
          <div>
            <img
              src={getUrlWithSpecificRendition(
                baseUrl,
                SCREEN_DENSITY_QUALIFIERS.HDPI,
                item.picture_path
              )}
              className="embed-responsive embed-responsive-16by9"
            />
            <div className="contests-title">{item.picture_caption}</div>
          </div>
        </div>
      );
    });

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CONTESTS} />
      <div className="header-view pt-0 mb-2 mt-3 justify-content-between">
        <BackButton isFullRow={false} />
        <div className="broadcast-header-row justify-content-end-view">
          <div className="contests-icon-row mr-3">
            <div className="contests-icon-text">
              {params.gallary.pictures.length === 1
                ? `${params.gallary.pictures.length} photo`
                : `${params.gallary.pictures.length} photos`}
            </div>
          </div>
        </div>
      </div>
      <div className="row main-content-container">{galleryArray}</div>
    </div>
  );
};

export default ContestGalleryScreen;
