import React, { useCallback, useEffect, useRef, useState } from 'react';
import DefaultContentView from '../../../../common/ui/default_content_view/DefaultContentView';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  REQUEST_TYPE,
  SUPPORTED_IMAGE_FORMATS,
  SUPPORTED_IMAGE_FORMATS_WEB,
  WARNING_MESSAGES,
} from '../../../../common/constants/AppConstants';
import LoadMoreBtn from '../../../../common/ui/load_more_btn/LoadMoreBtn';
import ML35GalleryComponent from '../../../anniversary/components/ML35GalleryComponent';
import CustomButton from '../../../../common/ui/custom_button/CustomButton';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { selectMaxFileUploadSizeConfig } from '../../../../state/MasterData';
import { saveProgressLoadingState } from '../../../../state/UIState';
import { apiRequest } from '../../../../services/Service';
import { showToast } from '../../../../utils/common';
import { NEO_PICTURE_UPLOAD } from '../../../../services/ApiUrls';
import { getFeatureComponentUrl } from '../../../../utils/accessibility';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { uploadDocuments } from '../../../../utils/commonApiRequests';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';

const NeoPicturesTab = ({ callNeoSessionDetailApi, pictures, loadmoreFlag, neo_id }) => {
  const [pageId, setPageId] = useState(1);
  const [showUploadImageDialog, setShowUploadImageDialog] = useState(false);
  const [showImageUploadSuccessDialog, setShowImageUploadSuccessDialog] = useState(false);
  const maxFileUploadSizeInMB = useSelector(selectMaxFileUploadSizeConfig);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagesLength, setSelectedImagesLength] = useState(0);
  const dispatch = useDispatch();
  const selectImagesInputRef = useRef(null);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [isOverlayOpen, setOverlayOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState();

  const openOverlay = (item) => {
    setSelectedImage(item);
    setOverlayOpen(true);
  };

  const closeOverlay = () => {
    setOverlayOpen(false);
    setSelectedImage();
  };

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.NEO_PICTURES_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.NEW_JOINEES_HIGHER_LEVELS
    );
  }, []);

  useEffect(() => {
    callNeoSessionDetailApi();
  }, [callNeoSessionDetailApi]);

  const loadMore = () => {
    if (loadmoreFlag) {
      callNeoSessionDetailApi(pageId + 1);
      setPageId(pageId + 1);
    }
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_IMAGES_TO_SHOW} />;
  };

  const callUploadImagesApi = useCallback(
    async (images) => {
      setShowUploadImageDialog(false);
      try {
        const params = new URLSearchParams();
        params.append('files', JSON.stringify(images));
        params.append('neo_id', neo_id);
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        const apiResponse = await apiRequest(NEO_PICTURE_UPLOAD, REQUEST_TYPE.POST, params);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            setSelectedImages([]);
            setSelectedImagesLength(0);
            showToast(apiResponse.response.message);
            setShowImageUploadSuccessDialog(true);
          }
        }
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      } catch (err) {
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    },
    [dispatch, neo_id]
  );

  useEffect(() => {
    if (selectedImages.length === selectedImagesLength && selectedImagesLength !== 0) {
      callUploadImagesApi(selectedImages);
    }
  }, [callUploadImagesApi, selectedImages, selectedImagesLength]);

  const onFileChange = (event) => {
    const images = [...event.target.files];
    let isAllFilesSupported = true;
    let isAnyFileSizeLarge = false;
    [...event.target.files].forEach((element) => {
      if (!SUPPORTED_IMAGE_FORMATS_WEB.includes(element.type)) {
        isAllFilesSupported = false;
      }
      if (Number(element.size) / (1024 * 1024) > maxFileUploadSizeInMB) {
        isAnyFileSizeLarge = true;
      }
    });
    if (!isAllFilesSupported) {
      showToast(WARNING_MESSAGES.FILE_NOT_SUPPORTED);
      setShowUploadImageDialog(false);
    } else if (isAnyFileSizeLarge) {
      showToast(WARNING_MESSAGES.FILE_SIZE_EXCEEDED(maxFileUploadSizeInMB));
      setShowUploadImageDialog(false);
    } else {
      if (images?.length > 5) {
        showToast(WARNING_MESSAGES.MAXIMUM_NO_OF_IMAGE_FILES(5));
        return;
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      setSelectedImagesLength(event.target.files?.length);
      [...event.target.files].map((image) => {
        uploadDocuments(image, 'joinees')
          .then((response) => {
            setSelectedImages((prev) => [...prev, { filename: response.fileName, type: 'image' }]);
          })
          .catch((message) => {
            showToast(message);

            dispatch(
              saveProgressLoadingState({
                isProgressLoading: false,
              })
            );
          });
      });
    }
  };

  const imageUploadDialog = () => {
    return (
      <div>
        <div className="breakouts-team-micro-view">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_UPLOAD_PHOTO,
              ACCESSIBILITY_IDENTIFIERS.MICROGIVE
            )}
            className="breakouts-upload-icon common-cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              selectImagesInputRef.current.click();
            }}
          />
          <input
            type="file"
            onChange={onFileChange}
            accept={SUPPORTED_IMAGE_FORMATS}
            id="input"
            ref={selectImagesInputRef}
            multiple
            onClick={(event) => {
              event.target.value = null;
            }}
            className="d-none"
          />
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            selectImagesInputRef.current.click();
          }}
          className="text-center selectImagesToUpload mt-2 common-cursor-pointer">
          Select images to be uploaded
        </div>
        <div className="text-center imagesReview mt-2 mb-3">
          All images to be reviewed by MicrolandOne App Admin.
        </div>
      </div>
    );
  };

  const onPressViewGallery = () => {
    setShowImageUploadSuccessDialog(false);
    callNeoSessionDetailApi(1);
  };

  const onPressUploadPhotos = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.NEO_PICTURE_UPLOAD_CLICK,
      '',
      ANALYTICS_ITEM_NAMES.NEW_JOINEES_HIGHER_LEVELS
    );
    setShowUploadImageDialog(true);
  };

  const imageUploadSuccessDialog = () => {
    return (
      <div>
        <div className="breakouts-team-micro-view ">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_SUCCESS,
              ACCESSIBILITY_IDENTIFIERS.MICROGIVE
            )}
            className="breakouts-upload-icon mr-4"
          />
        </div>
        <div className="breakouts-images-got-uploaded text-center mt-2">Images got uploaded</div>
        <div className="text-center mt-2 breakouts-images-under-review">
          Your Images are under review
        </div>
        <div className="text-center mt-2 breakouts-images-reviewed-by">
          All images to be reviewed by MLOne App Admin.
        </div>
        <CustomButton buttonStyle="breakouts-button-style mt-3" onClick={onPressViewGallery}>
          View Gallery
        </CustomButton>
      </div>
    );
  };

  return (
    <div className="mt-3">
      <div className="d-flex flex-row-reverse mi-hr-activity-btn-container">
        <CustomButton
          buttonStyle={`btn-load-more common-cursor-pointer`}
          onClick={onPressUploadPhotos}>
          Upload Photos
        </CustomButton>
      </div>
      <Modal
        show={showUploadImageDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowUploadImageDialog(false)}>
        <Modal.Body>{imageUploadDialog()}</Modal.Body>
      </Modal>
      <Modal
        show={showImageUploadSuccessDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={onPressViewGallery}>
        <Modal.Body>{imageUploadSuccessDialog()}</Modal.Body>
      </Modal>
      <div>
        {pictures?.length === 0 ? (
          emptyListView()
        ) : (
          <>
            {isOverlayOpen && selectedImage && (
              <div className="ml-35-image-overlay">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_IMAGE_CLOSE,
                    ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
                  )}
                  className="ml-35-image-close-button"
                  onClick={closeOverlay}
                />
                <img
                  className="ml-35-fullscreen-image"
                  src={
                    selectedImage.picture.base_url + 'xxhdpi/' + selectedImage.picture.image_path
                  }
                />
              </div>
            )}
            <div className={`ml-35-images-wrapper mb-2`}>
              {pictures?.map((item, index) => (
                <ML35GalleryComponent
                  key={index}
                  item={item}
                  index={index}
                  onPress={() => openOverlay(item)}
                />
              ))}
            </div>
            {!loadmoreFlag && <div className="mb-4" />}
          </>
        )}
        {loadmoreFlag && (
          <div className="mb-4 mt-4">
            <LoadMoreBtn onClick={loadMore} />
          </div>
        )}
      </div>
    </div>
  );
};

export default NeoPicturesTab;
