import React, { useEffect, useRef, useState } from 'react';
import CustomButton from '../custom_button/CustomButton';
import Picker from 'emoji-picker-react';
import { Modal } from 'react-bootstrap';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  REQUEST_TYPE,
  SUPPORTED_IMAGE_FORMATS,
  SUPPORTED_IMAGE_FORMATS_WEB,
  WARNING_MESSAGES,
} from '../../constants/AppConstants';
import { getFeatureComponentUrl, getFeatureComponentUrlLdpi } from '../../../utils/accessibility';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectMaxFileUploadSizeConfig,
  selectProfinityFilterWarning,
  selectTrendingHashtags,
} from '../../../state/MasterData';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getProfanityFilter, showToast } from '../../../utils/common';
import CustomMentionEditor from '../../components/hashtagsAndMentionsInput/HashtagMentionsInput';
import { apiRequest } from '../../../services/Service';
import { GET_DIRECTORY_DATA, GET_HASHTAGS } from '../../../services/ApiUrls';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import {
  extractDataFromEditorState,
  getMentionsState,
} from '../../components/hashtagsAndMentionsInput/hashtagUtils';
import { saveProgressLoadingState } from '../../../state/UIState';
import { uploadDocuments } from '../../../utils/commonApiRequests';

const CustomCommentBox = ({
  editorState,
  setEditorState,
  onChangehandler,
  iconName,
  openWishDialog,
  getCommentText,
  showLoader,
  textValue,
  enableUserMentions = false,
  enableHashtags = false,
  enableImageUpload = false,
  setImage,
  image,
  editMode = false,
  onMention = () => {},
}) => {
  const [showEmojiDiv, setShowEmojiDiv] = useState(false);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const accessibilityData = useSelector(selectAccessibilityData);
  const wrapperRef = useRef(null);
  const profinityFilterWarning = useSelector(selectProfinityFilterWarning);

  const [mentionsList, setMentionsList] = useState([]);
  const [hashtagsList, setHashtagsList] = useState([]);
  const [tempHashtag, setTempHashtag] = useState('');
  const [tempMention, setTempMention] = useState('');
  const trendingHashtags = useSelector(selectTrendingHashtags);
  const addImageFileRef = useRef(null);
  const dispatch = useDispatch();
  const maxFileUploadSizeInMB = useSelector(selectMaxFileUploadSizeConfig);
  const [imageBaseUrl, setImageBaseUrl] = useState('');

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowEmojiDiv(false);
    }
  };

  const onEmojiClick = (event, emojiObject) => {
    onChangehandler(emojiObject.emoji);
    setShowEmojiDiv(false);
  };

  const onMentionsSearchChange = async ({ value }) => {
    if (
      value.length > 2 &&
      (tempMention === '' || !value.startsWith(tempMention)) &&
      value.charAt(0) !== ' '
    ) {
      let requestParams = {
        page_id: 1,
        query: value,
        ignore_me: 1,
      };
      setMentionsList([]);
      const apiResponse = await apiRequest(GET_DIRECTORY_DATA, REQUEST_TYPE.GET, requestParams);
      const tags = [];
      const feeds = apiResponse.response.data?.feeds;
      if (feeds.length <= 0) {
        setTempMention(value);
      }
      feeds.map((item, index) => {
        const profile = item.profile_picture?.image_path
          ? item.profile_picture.base_url + 'ldpi/' + item.profile_picture.image_path
          : getFeatureComponentUrlLdpi(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
              ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
            );
        tags.push({
          type: 'mention',
          name: `${item.first_name} ${item.last_name} `,
          avatar: profile,
          employee_id: item.employee_id,
        });
        if (feeds.length === index + 1) {
          setMentionsList([...tags]);
        }
      });
    } else {
      setMentionsList([]);
    }
  };

  const onHashtagsSearchChange = async ({ value }) => {
    if (value.includes(' ')) {
      setHashtagsList([]);
      return;
    }
    if (value.length > 2 && (tempHashtag === '' || !value.startsWith(tempHashtag))) {
      let requestParams = {
        page_id: 1,
        query: value,
      };
      const apiResponse = await apiRequest(GET_HASHTAGS, REQUEST_TYPE.GET, requestParams);
      const tags = [];
      const tagsResp = apiResponse.response.data.tags;
      if (tagsResp.length <= 0) {
        setTempHashtag(value);
      }
      tagsResp.map((item, index) => {
        tags.push({ ...item, name: item + ' ', type: 'hashtag', index });
      });
      setHashtagsList([...tags]);
    } else if (trendingHashtags?.length > 0 && value.length < 3) {
      const tags = [];
      trendingHashtags.map((item, index) => {
        tags.push({ name: item + ' ', type: 'hashtag', index });
      });
      setHashtagsList([...tags]);
    } else {
      setHashtagsList([]);
    }
  };

  const resetFilters = (props) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.HASHTAG_SUGGESTION_TAP,
      0,
      props.name,
      ANALYTICS_ITEM_NAMES.HASHTAG
    );
    if (props.type === 'mention') {
      onMention(props);
    }
    setMentionsList([]);
    setHashtagsList([]);
  };

  const onFileChange = (event) => {
    const images = [...event.target.files];
    let isAllFilesSupported = true;
    let isAnyFileSizeLarge = false;
    images.forEach((element) => {
      if (!SUPPORTED_IMAGE_FORMATS_WEB.includes(element.type)) {
        isAllFilesSupported = false;
      }
      if (Number(element.size) / (1024 * 1024) > maxFileUploadSizeInMB) {
        isAnyFileSizeLarge = true;
      }
    });
    if (!isAllFilesSupported) {
      showToast(WARNING_MESSAGES.FILE_NOT_SUPPORTED);
    } else if (isAnyFileSizeLarge) {
      showToast(WARNING_MESSAGES.FILE_SIZE_EXCEEDED(maxFileUploadSizeInMB));
    } else {
      if (images?.length > 1) {
        showToast(WARNING_MESSAGES.MAXIMUM_NO_OF_IMAGE_FILES(1));
        return;
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      images.forEach((element) => {
        uploadDocuments(element, 'commentphoto', 'image')
          .then((response) => {
            setImageBaseUrl(response.base_url + 'ldpi/');
            setImage(response.fileName);
          })
          .catch((error) => {
            showToast(error.message);
          })
          .finally(() => {
            dispatch(
              saveProgressLoadingState({
                isProgressLoading: false,
              })
            );
          });
      });
    }
  };

  const warningDialog = () => {
    return (
      <div>
        <div className="common-comment-box-profinity-warning-text text-center imagesReview mt-2 mb-3">
          WARNING!
        </div>
        <div className="common-comment-box-profinity-text text-center imagesReview mt-2 mb-3">
          {profinityFilterWarning}
        </div>
        <div className="text-center">
          <CustomButton
            buttonStyle="common-comment-box-profinity-button mt-2"
            onClick={() => {
              setShowWarningDialog(false);
            }}>
            OK
          </CustomButton>
        </div>
      </div>
    );
  };

  return (
    <div className="common-comment-box my-3">
      <Modal
        show={showWarningDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowWarningDialog(false)}>
        <Modal.Body>{warningDialog()}</Modal.Body>
      </Modal>
      <div ref={wrapperRef} className="common-comment-emoji-icon-container common-cursor-pointer">
        <img
          src={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.EMOJI_SMILE_ICON,
            ACCESSIBILITY_IDENTIFIERS.COMMON
          )}
          id="common-comment-emoji-icon"
          className="common-comment-emoji-icon mr-3"
          onClick={() => {
            setShowEmojiDiv(true);
          }}
        />
        {showEmojiDiv && (
          <div className="common-comment-emoji-div">
            <Picker onEmojiClick={onEmojiClick} />
          </div>
        )}
      </div>
      {iconName && iconName === 'wishes' && (
        <div
          className="common-comment-wishes-icon-container common-cursor-pointer position-absolute"
          onClick={() => {
            openWishDialog();
          }}>
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_WISHES,
              ACCESSIBILITY_IDENTIFIERS.PROFILE
            )}
            className="my-profile-wishes-icon mr-1"
          />
        </div>
      )}
      {enableImageUpload && (
        <div
          className="common-comment-upload-image-icon-container common-cursor-pointer position-absolute"
          onClick={(e) => {
            e.stopPropagation();
            addImageFileRef.current.click();
          }}>
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_OPEN_GALLERY,
              ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
            )}
            className="my-profile-wishes-icon mr-1"
          />
          <input
            type="file"
            ref={addImageFileRef}
            onChange={onFileChange}
            accept={SUPPORTED_IMAGE_FORMATS}
            id="input"
            name={'file'}
            className="d-none"
            onClick={(event) => {
              event.target.value = null;
            }}
          />
        </div>
      )}
      {image && (
        <div className="common-comment-image-container position-absolute">
          <img src={imageBaseUrl + image} className="comment-selected-image" />
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_CLOSE,
              ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
            )}
            className="community-cross-icon common-cursor-pointer"
            onClick={() => setImage('')}
          />
        </div>
      )}
      <CustomMentionEditor
        mentions={mentionsList}
        hashtags={hashtagsList}
        editorState={editorState}
        onChange={setEditorState}
        onMentionsSearchChange={onMentionsSearchChange}
        onHashtagsSearchChange={onHashtagsSearchChange}
        resetFilters={resetFilters}
        enableUserMentions={enableUserMentions}
        enableHashtags={enableHashtags}
        enablePadding={true}
        placeholder="Type Comment..."
        editMode={editMode}
      />
      <div className="common-submit-btn-container">
        <CustomButton
          buttonStyle="common-submit-btn mt-2"
          onClick={() => {
            if (getProfanityFilter().isProfane('')) {
              setShowWarningDialog(true);
            } else {
              getCommentText('');
            }
          }}
          showLoader={showLoader}>
          {textValue ? textValue : 'Submit'}
        </CustomButton>
      </div>
    </div>
  );
};

export default CustomCommentBox;
