import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { GET_MLF_ABOUT_CONTENT } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { saveShowEmptyContentView, selectShowEmptyContentView } from '../../../state/UIState';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import HtmlView from '../../../common/ui/html_view/HTMLView';

const MlfAboutTab = (props) => {
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const [loading, setLoading] = useState(true);
  const accessibilityData = useSelector(selectAccessibilityData);
  const dispatch = useDispatch();
  const [aboutContent, setAboutContent] = useState('');

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.ESG_TAB_VISIT, props?.item?.title, ANALYTICS_ITEM_NAMES.ESG);
  }, [props?.item?.title]);

  const callMlfAboutApi = useCallback(async () => {
    setLoading(true);
    dispatch(saveShowEmptyContentView(true));
    try {
      const apiResponse = await apiRequest(GET_MLF_ABOUT_CONTENT, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse?.response?.data?.content) {
            const data = apiResponse?.response?.data?.content?.description ?? '';
            if (data) {
              setAboutContent(data);
              dispatch(saveShowEmptyContentView(false));
            }
          }
        }
      }

      setLoading(false);
    } catch (err) {
      handleError(err, {}, GET_MLF_ABOUT_CONTENT, NAVIGATION_ROUTES.MLF);
      setLoading(false);
    }
  }, [dispatch]);

  const renderScreen = useCallback(async () => {
    callMlfAboutApi();
  }, [callMlfAboutApi]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const emptyListView = () => {
    return (
      <DefaultContentView
        message={NO_DATA_TEXT.NO_DATA_FOUND}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  return (
    <div>
      {showEmptyContentView && !loading ? (
        emptyListView()
      ) : (
        <div>
          <HtmlView html={aboutContent} htmlStyle="breakout-content-html" />
          <ActivityLoader visible={loading} />
        </div>
      )}
    </div>
  );
};

export default MlfAboutTab;
