import React from 'react';

const DefaultContentView = (props) => {
  const { visible = true, iconSize = 'sm' } = props;

  const getImageSize = () => {
    if (iconSize === 'lg') {
      return 'common-default-content-view-icon-lg';
    } else if (iconSize === 'md') {
      return 'common-default-content-view-icon-md';
    } else if (iconSize === 'xs') {
      return 'common-default-content-view-icon-xs';
    } else {
      return 'common-default-content-view-icon-sm';
    }
  };

  return visible ? (
    <div
      className={`${
        props.iconUri
          ? 'common-default-content-view-container-icon'
          : 'common-default-content-view-container-no-icon'
      } align-items-center justify-content-center`}>
      {props.iconUri && <img src={props.iconUri} className={`${getImageSize()} img-center`} />}
      <p
        className={`common-default-content-view-text common-ws-pre-line ${props.style} img-center`}>
        {props.message}
      </p>
    </div>
  ) : null;
};

export default DefaultContentView;
