import React, { useState, useEffect, useCallback } from 'react';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../../services/Service';
import { GET_SUB_CATEGORIES } from '../../../services/ApiUrls';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import SubCategoriesList from '../components/SubCategoriesList';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { useSelector } from 'react-redux';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';

const CategoryTab = ({ tabKey, geographyId }) => {
  const [subCategories, setSubCategories] = useState([]);
  const [geoId, setGeoId] = useState();
  const [loading, setLoading] = useState(true);
  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.POLICIES_TAB_VISIT, tabKey, ANALYTICS_ITEM_NAMES.POLICIES);
  }, [tabKey]);

  useEffect(() => {
    if (geoId !== geographyId) {
      setGeoId(geographyId);
      setSubCategories([]);
    }
  }, [geoId, geographyId]);

  const callSubCategoriesApi = useCallback(async (categoryId, geoId) => {
    const params = {
      category_id: categoryId,
      geography_id: geoId,
    };
    try {
      setLoading(true);
      const apiResponse = await apiRequest(GET_SUB_CATEGORIES, REQUEST_TYPE.GET, params);
      setLoading(false);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.feeds.length > 0) {
            setSubCategories(apiResponse.response.data.feeds);
          }
        }
      }
    } catch (err) {
      handleError(err, params, GET_SUB_CATEGORIES, NAVIGATION_ROUTES.POLICIES);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (geoId !== undefined) {
      callSubCategoriesApi(tabKey, geoId);
    }
  }, [callSubCategoriesApi, tabKey, geoId]);

  const onPressItem = (item, index) => {
    let tempSubategories = [];
    subCategories.map(async (subCategory, pos) => {
      tempSubategories.push({
        ...subCategory,
        selected: index === pos ? (item.selected ? !item.selected : true) : false,
      });
    });
    setSubCategories([...tempSubategories]);
  };

  return (
    <div className="px-1 py-3">
      <div>
        <ActivityLoader visible={loading} />
        {subCategories && subCategories.length ? (
          subCategories.map((item, index) => (
            <SubCategoriesList
              onPressItem={onPressItem}
              item={item}
              categoryId={tabKey}
              geographyId={geoId}
              index={index}
              key={`${index}`}
            />
          ))
        ) : (
          <DefaultContentView
            message={loading ? LOADING_MESSAGES.DEFAULT : NO_DATA_TEXT.NO_SUB_CATEGORIES_FOUND}
            iconUri={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
              ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
            )}
          />
        )}
      </div>
    </div>
  );
};

export default CategoryTab;
