import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ACCESSIBILITY_FEATURE_COMPONENTS } from '../../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { getFeatureComponent } from '../../../../utils/accessibility';
import { getDeviceScreenDensityQualifierAsPath } from '../../../../utils/common';

const AdjacentColumnsText = ({
  itemId,
  firstText,
  secondText,
  itemImageBaseUrl,
  itemImagePath,
  sectionIdentifier,
}) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const [deviceScreenDensityQualifierPath] = useState(getDeviceScreenDensityQualifierAsPath());

  const toLinkHandler = () => {
    const route =
      '/' +
      getFeatureComponent(
        accessibilityData,
        ACCESSIBILITY_FEATURE_COMPONENTS.ROUTE,
        sectionIdentifier
      );
    return `${route}/${itemId}`;
  };

  return (
    <Link className="col-12 col-sm-6 col-md-4 common-unstyled-achor" to={toLinkHandler}>
      <div className="pt-3 home-classic-text-container">
        <img
          className="home-classic-text-image mb-3"
          src={itemImageBaseUrl + deviceScreenDensityQualifierPath + itemImagePath}
        />
        <div className="home-classic-text-title">
          {secondText?.length > 70 ? `${secondText?.substring(0, 70)}...` : secondText}
        </div>
        <div className="article-time-to-read-text">{'Till ' + firstText}</div>
      </div>
    </Link>
  );
};

export default AdjacentColumnsText;
