import React, { useEffect, useState } from 'react';
import NewJoineesFilterItem from '../../components/NewJoineesFilterItem';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveNeoTabData,
  saveNewJoineesTabCategoryFilterId,
  saveNewJoineesTabCategoryFilterKey,
  selectNewJoineesCategoryFilterId,
  selectNewJoineesCategoryFilterKey,
} from '../../../../state/TabData';
import LoadMoreBtn from '../../../../common/ui/load_more_btn/LoadMoreBtn';
import Divider from '../../../../common/ui/divider/Divider';
import { getHeaderComponentUrl } from '../../../../utils/accessibility';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
} from '../../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DefaultContentView from '../../../../common/ui/default_content_view/DefaultContentView';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import { convertISTDateToLocalFormat, formatHHMMSSTimeLocalAMPM } from '../../../../utils/common';

const NewJoinerNeoTab = ({ data, callNewJoineesApi, tab, loadMoreFlag }) => {
  const filtersList = tab?.filters ?? [];
  const NewJoineesCategoryFilterId = useSelector(selectNewJoineesCategoryFilterId);
  const dispatch = useDispatch();
  const [pageId, setPageId] = useState(1);
  const accessibilityData = useSelector(selectAccessibilityData);
  const filterKey = useSelector(selectNewJoineesCategoryFilterKey);
  const [filterKeyState, setFilterKeyState] = useState(filterKey ?? '');
  const history = useHistory();

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.NEO_SESSION_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.NEW_JOINEES_HIGHER_LEVELS
    );
  }, []);

  const onPress = (item) => {
    setPageId(1);
    dispatch(saveNewJoineesTabCategoryFilterKey(item?.filter_key ?? ''));
    setFilterKeyState(item?.filter_key);
    callNewJoineesApi(item?.filter_key ?? '', 1);
    dispatch(saveNewJoineesTabCategoryFilterId(item?.filter_id));
  };

  const loadMore = () => {
    callNewJoineesApi(filterKeyState, pageId + 1);
    setPageId(pageId + 1);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_NEO_SESSION_FOUND} />;
  };

  return (
    <div className="container">
      <div>
        {filtersList?.length > 0 && (
          <div className="microgive-category-container ml-n1 mb-4">
            {filtersList?.map((item) => (
              <NewJoineesFilterItem
                key={`${item?.filter_id}`}
                item={item}
                filterId={NewJoineesCategoryFilterId}
                onPress={onPress}
              />
            ))}
          </div>
        )}
      </div>
      <div>
        {data?.length === 0
          ? emptyListView()
          : data?.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    dispatch(saveNeoTabData(''));
                    history.push(`${NAVIGATION_ROUTES.NEO_SESSION_DETAIL}/${item.neo_id}`, {
                      neo_id: item.neo_id,
                      backTitle: `SESSION SCHEDULE: ${convertISTDateToLocalFormat(
                        item?.date
                      ).toUpperCase()}`,
                    });
                  }}
                  className="neo-induction-card p-3 mb-2 common-cursor-pointer"
                  key={`${index}`}>
                  <div className="d-flex flex-row justify-content-between">
                    <div className="neo-induction-title-text mb-2">
                      {`SESSION SCHEDULE: ${convertISTDateToLocalFormat(item?.date).toUpperCase()}`}
                    </div>
                    <img
                      src={getHeaderComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_RIGHT_ARROW,
                        ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
                      )}
                      className="anniversary-arrow-icon d-flex align-self-center common-cursor-pointer ml-auto"
                    />
                  </div>
                  <div className="px-3 pt-3 row">
                    <div className="neo-induction-subtitle mr-1">Timings:</div>
                    <div className="neo-induction-text">{`${formatHHMMSSTimeLocalAMPM(
                      item?.start_time
                    )} to ${formatHHMMSSTimeLocalAMPM(item?.end_time)}`}</div>
                  </div>
                  <div className="px-3 row">
                    <div className="neo-induction-subtitle mr-1">Location:</div>
                    <div className="neo-induction-text">{item?.location}</div>
                  </div>

                  <Divider style="mt-3" />
                  <div className="pt-3 d-flex flex-row">
                    {item?.speaker_count ? (
                      <>
                        <div className="neo-induction-text">{`${item?.speaker_count} Speaker${
                          item.speaker_count > 1 ? 's' : ''
                        }`}</div>
                        {item?.attendee_count || item?.picture_count || item?.document_count ? (
                          <div className="neo-induction-vertical-divider" />
                        ) : null}
                      </>
                    ) : null}
                    {item?.attendee_count ? (
                      <>
                        <div className="neo-induction-text">{`${item?.attendee_count} Attendee${
                          item.attendee_count > 1 ? 's' : ''
                        }`}</div>
                        {item?.picture_count || item?.document_count ? (
                          <div className="neo-induction-vertical-divider" />
                        ) : null}
                      </>
                    ) : null}
                    {item?.picture_count ? (
                      <>
                        <div className="neo-induction-text">{`${item?.picture_count} Picture${
                          item.picture_count > 1 ? 's' : ''
                        }`}</div>
                        {item?.document_count ? (
                          <div className="neo-induction-vertical-divider" />
                        ) : null}
                      </>
                    ) : null}
                    {item?.document_count ? (
                      <>
                        <div className="neo-induction-text">{`${item?.document_count} Document${
                          item.document_count > 1 ? 's' : ''
                        }`}</div>
                      </>
                    ) : null}
                  </div>
                </div>
              );
            })}
      </div>
      {loadMoreFlag && (
        <div className="mb-4 mt-4">
          <LoadMoreBtn onClick={loadMore} />
        </div>
      )}
    </div>
  );
};

export default NewJoinerNeoTab;
