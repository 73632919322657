import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Carousel } from 'react-bootstrap';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  FEATURE_TYPE,
  NAVIGATION_ROUTES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import Divider from '../../../common/ui/divider/Divider';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../../utils/accessibility';
import {
  formatDateDDMonthYYYY,
  formatTimeAMPM,
  getUrlWithSpecificRendition,
  isToday,
  removeHtmlContent,
} from '../../../utils/common';
import HtmlView from '../../../common/ui/html_view/HTMLView';

const PostListItem = ({
  item,
  onPressLikeButton,
  onPressCommentButton,
  showLikeButton,
  showCommentButton,
  handleHtmlClicks,
}) => {
  const history = useHistory();
  const accessibilityData = useSelector(selectAccessibilityData);
  const [currentActiveSlideIndex, setCurrentActiveSlideIndex] = useState(0);
  const [textString, setTextString] = useState('');

  useEffect(() => {
    if (item) {
      setTextString(
        removeHtmlContent(item.description).length <= 256
          ? item.description
          : item.short_description + '...'
      );
    }
  }, [item]);

  const toggleReadFlag = (e) => {
    e.stopPropagation();
    history.push({
      pathname: `${NAVIGATION_ROUTES.POST_DETAIL}/${item.post_id}`,
      state: { showLikeButton: showLikeButton, showCommentButton: showCommentButton },
    });
  };

  const onPressCarouselHandler = (e) => {
    e.stopPropagation();
  };

  const renderImages = () => {
    return (
      <Carousel
        onClick={onPressCarouselHandler}
        className="communities-post-carousel-container mt-2"
        activeIndex={currentActiveSlideIndex}
        nextIcon={
          item.images.length > 1 && (
            <span aria-hidden="true" className="carousel-control-next-icon" />
          )
        }
        prevIcon={
          item.images.length > 1 && (
            <span aria-hidden="true" className="carousel-control-prev-icon" />
          )
        }
        onSelect={(selectedIndex) => setCurrentActiveSlideIndex(selectedIndex)}>
        {item.images.map((imageItem, index) => {
          return (
            <Carousel.Item key={`${index}`}>
              <div className="d-flex justify-content-center">
                <img
                  className="communities-post-carousel-image"
                  src={getUrlWithSpecificRendition(
                    imageItem.base_url,
                    SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
                    imageItem.filename
                  )}
                />
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    );
  };

  const renderVideoLinks = () => {
    return (
      <div>
        {item.video_links && item.video_links.length > 0 && (
          <div className="communities-url mt-2">Video URL:</div>
        )}
        {item.video_links && item.video_links.length > 0 && (
          <div>
            <a
              className="community-link-text"
              target="_blank"
              rel="noreferrer"
              href={item.video_links[0].url}
              onClick={(e) => {
                e.stopPropagation();
              }}>
              {item.video_links[0].url}
            </a>
          </div>
        )}
        {item.video_links && item.video_links.length > 1 && (
          <div className="communities-more">{`+${item.video_links.length - 1} more`}</div>
        )}
      </div>
    );
  };

  const renderLinks = () => {
    return (
      <div>
        {item.links && item.links.length > 0 && (
          <div className="communities-url mt-2">Website URL:</div>
        )}
        {item.links && item.links.length > 0 && (
          <div className="text-truncate">
            <a
              className="community-link-text"
              target="_blank"
              rel="noreferrer"
              href={item.links[0].url}
              onClick={(e) => e.stopPropagation()}>
              {item.links[0].url}
            </a>
          </div>
        )}
        {item.links && item.links.length > 1 && (
          <div className="communities-more">{`+${item.links.length - 1} more`}</div>
        )}
      </div>
    );
  };

  return (
    <div className="mb-3 border p-3">
      <div
        onClick={(e) => {
          e.stopPropagation();
          history.push({
            pathname: `${NAVIGATION_ROUTES.POST_DETAIL}/${item.post_id}`,
            state: { showLikeButton: showLikeButton, showCommentButton: showCommentButton },
          });
        }}>
        <div className="communities-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <UserProfilePhoto
              imageBaseUrl={item.employee.profile_picture.base_url}
              imagePath={item.employee.profile_picture.image_path}
              imageClass="common-user-profile-photo-xs"
              employeeId={item.employee.employee_id}
            />
            <div className="d-flex align-items-center ml-3 common-cursor-pointer">
              <p className="communities-post-employee-name mb-0">
                {item.employee.first_name + ' ' + item.employee.last_name}
              </p>
              {!!item.employee.pronouns && (
                <p className="communities-post-employee-pronoun ml-1 mb-0">
                  {'(' + item.employee.pronouns + ')'}
                </p>
              )}
            </div>
          </div>
          <div className="communities-date-text">
            {isToday(item.created_on)
              ? formatTimeAMPM(item.created_on)
              : formatDateDDMonthYYYY(item.created_on)}
          </div>
        </div>
        <div className="d-flex align-items-center mt-3">
          {item.is_pinned && (
            <div className="d-flex align-items-center mr-2">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_PIN,
                  ACCESSIBILITY_IDENTIFIERS.COMMON
                )}
                className="communities-post-pin-icon mr-1"
              />
              <div className="communities-post-marker-text">Pinned</div>
            </div>
          )}
          {item.is_edited && (
            <div className="communities-grey-box px-2 py-1">
              <div className="communities-post-marker-text">Edited</div>
            </div>
          )}
        </div>
        {item.title && <div className="mt-2 communities-title">{item.title}</div>}
        {textString !== '' && (
          <div className="mt-1 communities-description text-break">
            <HtmlView html={textString} onClick={handleHtmlClicks} />
            {removeHtmlContent(item.description).length > 256 && (
              <div onClick={toggleReadFlag} className="communities-read-color">
                Read More
              </div>
            )}
          </div>
        )}
        {renderImages()}
        {renderVideoLinks()}
        {renderLinks()}
      </div>
      <Divider style="mt-2" />
      <div className="mt-2 communities-flex justify-content-between">
        <div className="d-flex align-items-center mt-1">
          {showLikeButton && (
            <>
              {item.is_liked ? (
                <div onClick={() => onPressLikeButton(item)}>
                  <img
                    src={getHeaderComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_ACTIVE_LIKE,
                      ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                    )}
                    className="communities-normal-icon common-cursor-pointer"
                  />
                </div>
              ) : (
                <div onClick={() => onPressLikeButton(item)}>
                  <img
                    src={getHeaderComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_INACTIVE_LIKE,
                      ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                    )}
                    className="communities-normal-icon common-cursor-pointer"
                  />
                </div>
              )}
              {item.like_count > 0 ? (
                <div
                  className="ml-2 communities-count common-cursor-pointer"
                  onClick={() => {
                    history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
                      referId: item.post_id,
                      parentType: FEATURE_TYPE.COMMUNITY,
                    });
                  }}>
                  {item.like_count}
                </div>
              ) : (
                <div className="ml-2 communities-count common-cursor-pointer"> 0</div>
              )}
            </>
          )}
          {showCommentButton && (
            <>
              <div
                className={
                  showLikeButton
                    ? 'communities-flex ml-3 mr-3 communities-width common-cursor-pointer'
                    : 'communities-flex mr-3 communities-width common-cursor-pointer'
                }
                onClick={() => onPressCommentButton(item)}>
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.COMMUNITIES_POST_COMMENT,
                    ACCESSIBILITY_IDENTIFIERS.COMMUNITIES
                  )}
                  className="communities-normal-icon"
                />
                <div className="ml-2 communities-count">
                  {item.comment_count > 0 ? item.comment_count : 0}
                </div>
              </div>
            </>
          )}
        </div>
        <div>
          {item.tags.length > 0 && (
            <div
              className="d-flex align-items-center common-cursor-pointer"
              onClick={() => {
                history.push(NAVIGATION_ROUTES.COMMUNITY_POST_TAGGED, {
                  employees: item.tags,
                });
              }}>
              <div className="d-flex align-items-center">
                {item.tags.length >= 3 ? (
                  <div className="d-flex align-items-center">
                    <div className="communities-image-first position-relative ">
                      {item.tags.length >= 1 && (
                        <UserProfilePhoto
                          imageBaseUrl={item.tags[0].profile_picture.base_url}
                          imagePath={item.tags[0].profile_picture.image_path}
                          imageClass="common-user-profile-photo-xs"
                          employeeId={item.tags[0].employee_id}
                        />
                      )}
                    </div>
                    <div className="communities-image-second position-relative ">
                      {item.tags.length >= 2 && (
                        <UserProfilePhoto
                          imageBaseUrl={item.tags[1].profile_picture.base_url}
                          imagePath={item.tags[1].profile_picture.image_path}
                          imageClass="common-user-profile-photo-xs"
                          employeeId={item.tags[1].employee_id}
                        />
                      )}
                    </div>
                    {item.tags.length >= 3 && (
                      <UserProfilePhoto
                        imageBaseUrl={item.tags[2].profile_picture.base_url}
                        imagePath={item.tags[2].profile_picture.image_path}
                        imageClass="common-user-profile-photo-xs"
                        employeeId={item.tags[2].employee_id}
                      />
                    )}
                  </div>
                ) : item.tags.length >= 2 ? (
                  <div className="d-flex align-items-center">
                    <div className="communities-image-first position-relative ">
                      {item.tags.length >= 1 && (
                        <UserProfilePhoto
                          imageBaseUrl={item.tags[0].profile_picture.base_url}
                          imagePath={item.tags[0].profile_picture.image_path}
                          imageClass="common-user-profile-photo-xs"
                          employeeId={item.tags[0].employee_id}
                        />
                      )}
                    </div>
                    <div>
                      {item.tags.length >= 2 && (
                        <UserProfilePhoto
                          imageBaseUrl={item.tags[1].profile_picture.base_url}
                          imagePath={item.tags[1].profile_picture.image_path}
                          imageClass="common-user-profile-photo-xs"
                          employeeId={item.tags[1].employee_id}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    {item.tags.length >= 1 && (
                      <UserProfilePhoto
                        imageBaseUrl={item.tags[0].profile_picture.base_url}
                        imagePath={item.tags[0].profile_picture.image_path}
                        imageClass="common-user-profile-photo-xs"
                        employeeId={item.tags[0].employee_id}
                      />
                    )}
                  </div>
                )}
              </div>
              {item.tags.length > 3 && (
                <div className="communities-tag-count-text ml-2">{`+${item.tags.length - 3}`}</div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PostListItem;
