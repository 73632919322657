import React, { useCallback, useEffect, useRef, useState } from 'react';
import { saveProgressLoadingState } from '../../state/UIState';
import { apiRequest, handleError } from '../../services/Service';
import { GET_ASK_ORG_QUESTION_BY_ID, SUBMIT_FEEDBACK } from '../../services/ApiUrls';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import BackButton from '../../common/ui/common_back_button/BackButton';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { getConfigurations } from '../../utils/ReduxState';
import HtmlView from '../../common/ui/html_view/HTMLView';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { Carousel, Modal } from 'react-bootstrap';
import { generatev4UniqueID, showToast } from '../../utils/common';
import AskMlChatWidget from './AskMlChatWidget';
import { selectEmployeeData } from '../../state/EmployeeData';
import { selectAccessData } from '../../state/UserAccessData';

const AskOrgSearchResultDetailScreen = ({ history }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [questionDetailData, setQuestionDetailData] = useState({});
  const accessibilityData = useSelector(selectAccessibilityData);
  const [currentIndex, setCurrentIndex] = useState(0);
  const carouselRef = useRef();
  const [showDialog, setShowDialog] = useState(false);
  const [showWidget, setShowWidget] = useState(false);
  const employeeConfig = useSelector(selectEmployeeData);
  const accessData = useSelector(selectAccessData);
  const [showChatBot, setShowChatBot] = useState(true);
  const askOrgAccessData = accessData?.data?.find((item) => item.feature_key === '_askOrg');
  const [uniqueUUID, setUniqueUUID] = useState('');

  useEffect(() => {
    const actions = askOrgAccessData?.actions;
    setShowChatBot(actions?.includes('_chatbot'));
  }, [askOrgAccessData?.actions]);

  const callGetQuestionsDetailByIdApi = useCallback(async () => {
    setError(false);
    dispatch(saveProgressLoadingState({ isProgressLoading: true }));
    try {
      const apiResponse = await apiRequest(
        GET_ASK_ORG_QUESTION_BY_ID + history.location.state.questionId,
        REQUEST_TYPE.GET,
        {}
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          const data = apiResponse.response.data.question;
          setQuestionDetailData(data);
        }
      }
    } catch (err) {
      handleError(
        err,
        {},
        GET_ASK_ORG_QUESTION_BY_ID + history.location.state.questionId,
        NAVIGATION_ROUTES.ASK_ORG_RESULTS_DETAIL
      );
      // eslint-disable-next-line no-console
      console.error(err);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      setError(true);
    }
  }, [dispatch, history.location.state.questionId]);

  useEffect(() => {
    callGetQuestionsDetailByIdApi();
  }, [callGetQuestionsDetailByIdApi]);

  const handleHelpdesk = () => {
    const targetUrl = getConfigurations()._helpdesk_url;
    window.open(targetUrl, '_blank');
  };

  const callSubmitFeedbackApi = useCallback(
    async (feedbackType) => {
      setError(false);
      let params = new URLSearchParams();
      params.append('question_id', history.location.state.questionId);
      params.append('type', feedbackType);
      try {
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        const apiResponse = await apiRequest(SUBMIT_FEEDBACK, REQUEST_TYPE.POST, params);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            const message = apiResponse.response.message;
            if (message) {
              showToast(message);
            }
          }
        }
      } catch (err) {
        handleError(err, params, SUBMIT_FEEDBACK, NAVIGATION_ROUTES.ASK_ORG_RESULTS_DETAIL);
        console.error(err);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        setError(true);
      }
    },
    [dispatch, history.location.state.questionId]
  );

  const requestTicketDialog = () => {
    return (
      <div>
        <div className="ask-org-request-modal-container">
          <div className="d-flex flex-row align-items justify-content-center ask-org-request-modal-title mt-4">
            Request help from the concerned team?
          </div>
          <div className="d-flex justify-content-center slign-items-center mt-4 pt-2">
            <div
              onClick={() => {
                setShowDialog(false);
                history.goBack();
              }}
              className="ask-org-modal-no-container d-flex align-items-center justify-content-center mr-3 common-cursor-pointer">
              NO
            </div>
            <div
              onClick={() => {
                handleHelpdesk();
                setShowDialog(false);
              }}
              className="ask-org-modal-yes-container d-flex align-items-center justify-content-center common-cursor-pointer">
              YES
            </div>
          </div>
        </div>
      </div>
    );
  };

  const toggleWidget = () => {
    setShowWidget(!showWidget);
  };

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
        <div className="gcdo-icon-row">
          <CustomButton
            onClick={handleHelpdesk}
            fromRoute={NAVIGATION_ROUTES.ASK_ORG}
            buttonMainContainer="text-nowrap ceo-chat-ask-button"
            isSecondaryButton={true}>
            {'INITIATE REQUEST'}
          </CustomButton>
        </div>
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.ASK_ORG} />
      <BackButton />
      {employeeConfig?.gpt_visible == 1 && showChatBot && (
        <div
          className={
            !showWidget
              ? 'd-flex common-cursor-pointer chat-bot-floating-icon p-2 align-items-center justify-content-center'
              : 'd-flex common-cursor-pointer chat-bot-floating-close-icon p-2 align-items-center justify-content-center'
          }>
          <div
            className="position-relative flex-grow-1 d-flex justify-content-center align-items-center"
            onClick={() => {
              toggleWidget();
              if (!showWidget) {
                const id = generatev4UniqueID();
                setUniqueUUID(id);
              }
            }}>
            <img
              className={!showWidget ? 'ask-org-chat-bot-float-icon' : 'ask-org-close-float-icon'}
              src={
                !showWidget
                  ? getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.CHAT_BOT,
                      ACCESSIBILITY_IDENTIFIERS.ASK_ORG
                    )
                  : 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgMzU3IDM1NyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzU3IDM1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnIGlkPSJjbGVhciI+CgkJPHBvbHlnb24gcG9pbnRzPSIzNTcsMzUuNyAzMjEuMywwIDE3OC41LDE0Mi44IDM1LjcsMCAwLDM1LjcgMTQyLjgsMTc4LjUgMCwzMjEuMyAzNS43LDM1NyAxNzguNSwyMTQuMiAzMjEuMywzNTcgMzU3LDMyMS4zICAgICAyMTQuMiwxNzguNSAgICIgZmlsbD0iI0ZGRkZGRiIvPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo='
              }
            />
          </div>
          {showWidget ? <AskMlChatWidget uniqueUUID={uniqueUUID} /> : null}
        </div>
      )}
      <Modal
        contentClassName="ask-org-custom-modal"
        show={showDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowDialog(false)}>
        <Modal.Body>{requestTicketDialog()}</Modal.Body>
      </Modal>
      <div className="ask-org-answer-container">
        <div className="p-3 ask-org-question-text-container w-100">
          {history.location.state.questionTitle}
          {questionDetailData?.answers?.length > 1 && (
            <div className="d-flex flex-row align-items-center justify-content-center w-100">
              <img
                onClick={() => {
                  carouselRef.current && carouselRef.current.prev();
                }}
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.ASK_ORG_PREVIOUS_ANSWER,
                  ACCESSIBILITY_IDENTIFIERS.ASK_ORG
                )}
                className="ask-org-pdf-icon common-cursor-pointer mr-3"
              />
              {currentIndex + 1} / {questionDetailData.answers.length}
              <img
                onClick={() => {
                  carouselRef.current && carouselRef.current.next();
                }}
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.ASK_ORG_NEXT_ANSWER,
                  ACCESSIBILITY_IDENTIFIERS.ASK_ORG
                )}
                className="ask-org-pdf-icon common-cursor-pointer ml-3"
              />
            </div>
          )}
        </div>
        <div className="p-3">
          {questionDetailData?.answers < 2 ? (
            questionDetailData?.answers?.map((item, index) => {
              return (
                <div key={`${index}`}>
                  <HtmlView html={item.description} />
                  {item?.pdf?.links.length > 0 &&
                    item?.pdf?.links.map((link, index) => {
                      return (
                        <div key={`${index}`}>
                          <a
                            href={item?.pdf.base_url + link.pdf_path}
                            target="_blank"
                            rel="noreferrer">
                            <div className="d-flex flex-row align-items-center">
                              <img
                                src={getFeatureComponentUrl(
                                  accessibilityData,
                                  ACCESSIBILITY_FEATURE_COMPONENTS.ASK_ORG_PDF_ATTACHMENT,
                                  ACCESSIBILITY_IDENTIFIERS.ASK_ORG
                                )}
                                className="ask-org-pdf-icon common-cursor-pointer mr-1"
                              />
                              <div>{link?.label}</div>
                            </div>
                          </a>
                        </div>
                      );
                    })}
                </div>
              );
            })
          ) : (
            <Carousel
              controls={false}
              ref={carouselRef}
              className="custom-carousel-indicators"
              interval={null}
              onSlid={(slideIndex) => {
                setCurrentIndex(slideIndex);
              }}>
              {questionDetailData?.answers?.map((item, index) => (
                <Carousel.Item key={`${index}`}>
                  <HtmlView html={item.description} />
                  {item?.pdf?.links.length > 0 &&
                    item?.pdf?.links.map((link, index) => {
                      return (
                        <div key={`${index}`}>
                          <a
                            href={item?.pdf?.base_url + link?.pdf_path}
                            target="_blank"
                            rel="noreferrer">
                            <div className="d-flex flex-row align-items-center">
                              <img
                                src={getFeatureComponentUrl(
                                  accessibilityData,
                                  ACCESSIBILITY_FEATURE_COMPONENTS.ASK_ORG_PDF_ATTACHMENT,
                                  ACCESSIBILITY_IDENTIFIERS.ASK_ORG
                                )}
                                className="ask-org-pdf-icon common-cursor-pointer mr-1"
                              />
                              <div>{link?.label}</div>
                            </div>
                          </a>
                        </div>
                      );
                    })}
                </Carousel.Item>
              ))}
            </Carousel>
          )}
        </div>
      </div>
      <div className="ask-org-feedback-container d-flex flex-row align-items-center justify-content-center p-3 mb-4">
        <div className="d-flex flex-row mr-2">Was this helpful?</div>
        <img
          onClick={() => {
            callSubmitFeedbackApi('yes');
            history.push(NAVIGATION_ROUTES.ASK_ORG);
          }}
          src={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.ASK_ORG_SUCCESS,
            ACCESSIBILITY_IDENTIFIERS.ASK_ORG
          )}
          className="ask-org-feedback-icon common-cursor-pointer mr-2"
        />
        <img
          onClick={() => {
            callSubmitFeedbackApi('no');
            setShowDialog(true);
          }}
          src={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.ASK_ORG_CLOSE,
            ACCESSIBILITY_IDENTIFIERS.ASK_ORG
          )}
          className="ask-org-feedback-icon common-cursor-pointer"
        />
      </div>
    </div>
  );
};

export default AskOrgSearchResultDetailScreen;
