import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ERROR_MESSAGES,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { replaceUnicodeSetWithBullet, showToast } from '../../../utils/common';
import ReactStars from 'react-rating-stars-component';
import CustomCheckBox from '../../../common/ui/custom_checkbox/CustomCheckBox';
import colors from '../../../common/themes/Colors';
import CustomRadioBtn from './CustomRadioBtn';
import HtmlView from '../../../common/ui/html_view/HTMLView';

const SurveyQuestionView = (props) => {
  const question = props.sureveyQuestionVal ? props.sureveyQuestionVal : props.question;
  const accessibilityData = useSelector(selectAccessibilityData);

  const [textInputValue, setTextInputValue] = useState(
    question && question.text ? question.text : ''
  );
  const [checkBoxArray, setcheckBoxArray] = useState([]);
  const [radioBtnVal, setRadioBtnVal] = useState();
  const [radioBtnSelected, setRadioBtnSelected] = useState();
  const [ratingVal, setRatingVal] = useState();
  const [checkBoxOptions, setCheckBoxOptions] = useState();
  const [radioBtnOptions, setRadioBtnOptions] = useState();

  useEffect(() => {
    if (question.type === 'rating') {
      if (question.rating === 0) {
        props.getOutputValue(5);
      }
    }
  }, [props, question.rating, question.type]);

  useEffect(() => {
    if (question.type === 'rating') {
      if (question.rating > 0) {
        setRatingVal(question.rating);
      } else {
        setRatingVal(5);
      }
    }
    if (question.type === 'text') {
      setTextInputValue(question.text);
    }
    if (question.type === 'single') {
      const option = question.options.find((data) => data.selected === true);
      setRadioBtnSelected(option ? { id: option.option_id, name: option.option_text } : {});
    }
    if (question.type === 'multiple') {
      setCheckBoxOptions([]);
    }
  }, [props, question.options, question.rating, question.text, question.type]);

  const validateOutput = (output) => {
    if (question.compulsory) {
      if (question.type === 'text') {
        if (output) {
          return false;
        } else {
          return true;
        }
      } else if (question.type === 'single') {
        let selectedArray = output.map((item) => {
          return item.selected;
        });
        if (selectedArray.includes(true)) {
          return false;
        } else {
          return true;
        }
      } else if (question.type === 'multiple') {
        let selectedArray =
          output &&
          output.map((item) => {
            return item.selected;
          });
        if (selectedArray && selectedArray.includes(true)) {
          return false;
        } else {
          return true;
        }
      } else {
        if (ratingVal) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  };

  const getOutputVal = () => {
    if (question.type === 'text') {
      return textInputValue;
    } else if (question.type === 'single') {
      let temp_radioOptions = question.options;
      for (var i = 0; i < temp_radioOptions.length; i++) {
        if (radioBtnSelected && temp_radioOptions[i].option_id === radioBtnSelected.id) {
          temp_radioOptions[i].selected = true;
        } else {
          temp_radioOptions[i].selected = false;
        }
      }
      return temp_radioOptions;
    } else if (question.type === 'multiple') {
      if (checkBoxOptions.length > 0) {
        return checkBoxOptions;
      } else {
        return question.options;
      }
    } else {
      return ratingVal;
    }
  };

  const renderOptions = (type) => {
    switch (type) {
      case 'rating':
        return (
          <div className="mt-2">
            <ReactStars
              count={5}
              onChange={(rating) => {
                setRatingVal(rating);
                props.getOutputValue(rating);
              }}
              size={40}
              activeColor={colors.gold}
              value={5}
            />
          </div>
        );
      case 'text':
        return (
          <div className="mt-2">
            <textarea
              className="form-control"
              rows="10"
              placeholder=""
              onChange={(event) => {
                setTextInputValue(event.target.value);
                props.getOutputValue(event.target.value);
              }}
              value={textInputValue}
            />
          </div>
        );
      case 'single':
        return (
          <div className="mt-2">
            <CustomRadioBtn
              products={question.options.map((item) => ({
                id: item.option_id,
                name: item.option_text,
              }))}
              radioSelected={radioBtnSelected}
              getRadioVal={(val) => {
                setRadioBtnVal(val.id);
                setRadioBtnSelected(val);
                let temp_radioOptions = question.options;
                for (var i = 0; i < temp_radioOptions.length; i++) {
                  temp_radioOptions[i].selected = false;
                }
                temp_radioOptions.find((data) => data.option_id === val.id).selected = true;
                setRadioBtnOptions(temp_radioOptions);
                props.getOutputValue(temp_radioOptions);
              }}
            />
          </div>
        );
      case 'multiple':
        return (
          <div className="mt-3">
            {question.options.map((item) => (
              <div key={item.option_id}>
                <CustomCheckBox
                  containerClassName="ml-1 mt-1"
                  label={item.option_text}
                  value={checkBoxArray && checkBoxArray.includes(item) ? true : false}
                  onValueChange={() => {
                    let temp_options = question.options;
                    if (checkBoxArray && checkBoxArray.includes(item)) {
                      setcheckBoxArray(checkBoxArray.filter((checkVal) => checkVal !== item));
                      temp_options.find(
                        (data) => data.option_id === item.option_id
                      ).selected = false;
                    } else {
                      if (!checkBoxArray) {
                        setcheckBoxArray([]);
                      }
                      setcheckBoxArray((prev) => [...prev, item]);
                      temp_options.find(
                        (data) => data.option_id === item.option_id
                      ).selected = true;
                    }
                    setCheckBoxOptions(temp_options);
                    props.getOutputValue(temp_options);
                  }}
                />
              </div>
            ))}
          </div>
        );
    }
  };

  return (
    <div>
      <div className="polls-question-num-text">
        Question {props.questionNumber}
        {question.compulsory ? '*' : ''}
      </div>
      <div className="polls-question-text mt-2">
        <HtmlView html={question.question} />
      </div>
      {!!question.picture && !!question.picture.image_path && (
        <div className="d-flex">
          <div className="mt-3 col-sm-5 p-0">
            <img
              src={question.picture.base_url + question.picture.image_path}
              className="embed-responsive embed-responsive-16by9"
            />
          </div>
        </div>
      )}
      {renderOptions(question.type)}
      <div className="polls-flex mt-3 justify-content-between">
        {props.questionNumber !== 1 ? (
          <div
            onClick={() => {
              const outputVal = getOutputVal();
              props.onPrev(outputVal);
            }}>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.POLLS_PREV_ARROW,
                ACCESSIBILITY_IDENTIFIERS.POLLS
              )}
              className="polls-prev-next-arrow common-cursor-pointer"
            />
          </div>
        ) : (
          <div className="polls-prev-next-arrow" />
        )}
        {props.questionNumber !== props.noOfQuestions ? (
          <div
            onClick={() => {
              const outputVal = getOutputVal();
              if (validateOutput(outputVal)) {
                showToast(ERROR_MESSAGES.SURVEYS_MANDATORY_FIELD);
              } else {
                props.onNext(outputVal);
              }
            }}>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.POLLS_NEXT_ARROW,
                ACCESSIBILITY_IDENTIFIERS.POLLS
              )}
              className="polls-prev-next-arrow common-cursor-pointer"
            />
          </div>
        ) : (
          <div className="polls-prev-next-arrow" />
        )}
      </div>
    </div>
  );
};

export default SurveyQuestionView;
