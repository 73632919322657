import React from 'react';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';

const SuperListItem = ({ item }) => {
  return (
    <div className="mb-3 anniversary-outer-view">
      <UserProfilePhoto
        imageBaseUrl={item.profile_picture.base_url}
        imagePath={item.profile_picture.image_path}
        imageClass="common-user-profile-photo-lg"
        employeeId={item.employee_id}
      />
      <div className="ml-3 anniversary-outer-view">
        <div>
          <div className="anniversary-user-name">{`${item.rank}. ${item.first_name} ${item.last_name}`}</div>
          <div className="anniversary-user-position">{item.position_title}</div>
          <div className="anniversary-user-position">
            {item.earned_points > 1
              ? `${item.earned_points} points`
              : `${item.earned_points} point`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperListItem;
