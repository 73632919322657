import React from 'react';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../../../utils/accessibility';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  WISH_TYPES,
} from '../../../constants/AppConstants';

const WishCard = (props) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  return (
    <div
      className={`${
        props.wishType === WISH_TYPES.BIRTHDAY || props.wishType === WISH_TYPES.ANNIVERSARY
          ? 'birthday-wish-card-container'
          : 'common-wish-card-container'
      } mt-3 mb-3 p-3`}
      onClick={props.onClick}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              props.somCard
                ? ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_STARS
                : props.wishType === WISH_TYPES.BIRTHDAY ||
                  props.wishType === WISH_TYPES.ANNIVERSARY
                ? ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_CELEBRATION_WISHES
                : ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_CELEBRATION,
              ACCESSIBILITY_IDENTIFIERS.PROFILE
            )}
            className="common-profile-card-image-size"
          />
          <div className="ml-3">
            <p
              className={`common-wish-card-title ${
                (props.wishType === WISH_TYPES.BIRTHDAY ||
                  props.wishType === WISH_TYPES.ANNIVERSARY) &&
                'text-light'
              }`}>
              {props.title}
            </p>
            {props.subTitle && (
              <p
                className={`m-0 common-profile-title-text ${
                  (props.wishType === WISH_TYPES.BIRTHDAY ||
                    props.wishType === WISH_TYPES.ANNIVERSARY) &&
                  'text-light'
                }`}>
                {props.subTitle}
              </p>
            )}
          </div>
        </div>

        <div className="align-items-center">
          <img
            className="common-wish-card-right-icon"
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.DIRECTORY_RIGHT_ARROW,
              ACCESSIBILITY_IDENTIFIERS.DIRECTORY
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default WishCard;
