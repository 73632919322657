import React, { useState, useEffect, useCallback } from 'react';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_TITLE,
} from '../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import { GET_NOTIFICATION_LIST } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import {
  saveSearchText,
  saveShowEmptyContentView,
  selectBaseUrl,
  selectShowEmptyContentView,
} from '../../state/UIState';
import NotificationItem from './components/NotificationItem';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import { notificationListingNavigation } from '../../navigation/NavigationHandler';
import { resetNotificationCount } from '../../state/NotificationData';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { resetAllTabs } from '../../state/TabData';
import { saveCommunityData } from '../../state/CommunityData';
import ActivityLoader from '../../common/ui/activity_loader/ActivityLoader';
import { saveTravelRequestData } from '../../state/TravelRequestMasterData';
import SearchComponent from '../../common/ui/search/SearchComponent';
import { logEvent } from '../../utils/FirebaseAnalyticsUtils';

const NotificationListingScreen = ({ history }) => {
  const dispatch = useDispatch();
  const [notificationData, setNotificationData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadMoreFlag, setLoadMoreFlag] = useState(true);
  const [pageId, setPageId] = useState(1);

  const showEmptyContentView = useSelector(selectShowEmptyContentView);

  const baseUrl = useSelector(selectBaseUrl);

  const callNotificationApi = useCallback(
    async (id, loadMore) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        const params = {
          page_id: id,
        };
        try {
          setLoading(true);
          const apiResponse = await apiRequest(GET_NOTIFICATION_LIST, REQUEST_TYPE.GET, params);
          setLoading(false);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                setNotificationData((oldArray) => [
                  ...oldArray,
                  ...apiResponse.response.data.feeds,
                ]);
                setLoadMoreFlag(true);
                dispatch(resetNotificationCount());
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(err, params, GET_NOTIFICATION_LIST, NAVIGATION_ROUTES.NOTIFICATION_LISTING);
          setLoading(false);
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (baseUrl !== '') {
      callNotificationApi(pageId, loadMoreFlag);
    }
  }, [baseUrl, callNotificationApi, loadMoreFlag, pageId]);

  const onPressItem = (item) => {
    if (Object.keys(item.data).length > 0) {
      dispatch(resetAllTabs());
      dispatch(saveTravelRequestData({}));
      dispatch(saveCommunityData({}));
      notificationListingNavigation(item.data, history);
    }
  };

  const onPressLoadMore = () => {
    setPageId((prev) => prev + 1);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_DATA_FOUND} />;
  };

  const handleSubmitFunction = (searchText) => {
    logEvent(ANALYTICS_EVENT_TYPES.NOTIFICATION_SEARCH, searchText, ANALYTICS_ITEM_NAMES.SEARCH);
    dispatch(saveSearchText(searchText));
    history.push(NAVIGATION_ROUTES.NOTIFICATION_SEARCH_SCREEN, {});
  };

  const renderHeader = () => {
    return (
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
        <SearchComponent handleSubmitFunction={handleSubmitFunction} />
      </div>
    );
  };

  return (
    <div className="container">
      {renderHeader()}
      <SectionHeader title={SCREEN_TITLE.NOTIFICATIONS} />
      <div className="main-content-container">
        {showEmptyContentView && !notificationData.length && !loading ? (
          emptyListView()
        ) : (
          <div>
            <div>
              {notificationData.map((item, index) => {
                return <NotificationItem onPressItem={onPressItem} item={item} key={`${index}`} />;
              })}
            </div>
            <ActivityLoader visible={loading} />
            {loadMoreFlag && <LoadMoreBtn onClick={onPressLoadMore} isLoading={loading} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationListingScreen;
