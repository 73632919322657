import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ACTION_TYPES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../common/constants/AppConstants';
import { ARTICLES, INCREASE_VIEW_COUNT_EXTERNAL_LINK } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import {
  saveProgressLoadingState,
  saveSearchText,
  selectBaseUrl,
  selectProgressLoadingState,
} from '../../state/UIState';
import {
  formatDateDDMonthYYYY,
  formatSecondsIntoMinutes,
  formatSecondsIntoTimeForVideos,
  getUrlWithSpecificRendition,
} from '../../utils/common';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import { Modal, Form } from 'react-bootstrap';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import { selectArticleFilters, selectDearMLTabs } from '../../state/MasterData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import SearchComponent from '../../common/ui/search/SearchComponent';
import PlayIconOverlay from '../../common/ui/play_icon_overlay/PlayIconOverlay';

const DearEmployerMainScreen = ({ history, location }) => {
  const pageId = useRef(1);
  const dispatch = useDispatch();
  const loadMoreFlag = useRef(true);
  const [articles, setArticles] = useState([]);
  const baseUrl = useSelector(selectBaseUrl);
  const [showNewsDialog, setShowNewsDialog] = useState(false);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  const [id] = useState(new URLSearchParams(location.search).get('id'));
  const dateResponse = useSelector(selectArticleFilters);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const dearMLTabData = useSelector(selectDearMLTabs);

  const dateArray = [];
  for (var date in dateResponse) {
    dateArray.push(dateResponse[date].title);
  }

  let tempRoutes = [];
  for (let tab of dearMLTabData) {
    tempRoutes.push({ channel_id: tab.channel_id, sub_channel_id: tab.sub_channel_id });
  }
  const [routes] = useState(tempRoutes);

  const [selectedDate, setSelectedDate] = useState();

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.DEAR_EMPLOYER, NAVIGATION_ROUTES.DEAR_EMPLOYER);
  }, []);

  const onPressItem = async (item) => {
    if (item.content_load_type === '1') {
      try {
        const es = await apiRequest(
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          REQUEST_TYPE.GET,
          {}
        );
      } catch (err) {
        handleError(
          err,
          {},
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          NAVIGATION_ROUTES.DEAR_EMPLOYER
        );
        //ignore if the view count request is not successful
        console.warn(err);
      }
      window.open(item.url);
    } else {
      history.push(`${NAVIGATION_ROUTES.DEAR_EMPLOYER}/${item.article_id}`);
    }
  };

  useEffect(() => {
    if (baseUrl !== '' && id && id !== '') {
      dispatch({ type: ACTION_TYPES.GET_TOKEN, payload: { id: id } });
    }
  }, [baseUrl, dispatch, id]);

  const callArticlesApi = useCallback(
    async (date) => {
      if (loadMoreFlag.current) {
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        setLoadMoreBtn(true);
        const get_params = {
          page_id: pageId.current,
          year: date,
          channel_id: routes[0].channel_id,
          sub_channel_id: routes[0].sub_channel_id,
        };
        try {
          const apiResponse = await apiRequest(ARTICLES, REQUEST_TYPE.GET, get_params);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data) {
                const data = apiResponse.response.data;
                if (data.feeds.length > 0) {
                  if (pageId.current === 1) {
                    setArticles([]);
                  }
                  setArticles((oldArray) => [...oldArray, ...data.feeds]);
                  pageId.current = pageId.current + 1;
                  loadMoreFlag.current = true;
                } else {
                  loadMoreFlag.current = false;
                  setLoadMoreBtn(false);
                }
              }
            }
          }
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        } catch (err) {
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          handleError(err, get_params, ARTICLES, NAVIGATION_ROUTES.DEAR_EMPLOYER);
          console.error(err);
        }
      }
    },
    [dispatch, routes]
  );

  const renderScreen = useCallback(() => {
    callArticlesApi('');
  }, [callArticlesApi]);

  useEffect(() => {
    if (baseUrl !== '') {
      renderScreen();
    }
  }, [baseUrl, renderScreen]);

  const articlesArray =
    articles &&
    articles.map((item, index) => {
      return (
        <div
          key={`${index}`}
          className="col-sm-4 mb-2 common-cursor-pointer"
          onClick={() => onPressItem(item)}>
          <div>
            {item?.content_load_type === '3' && <PlayIconOverlay />}
            <img
              src={getUrlWithSpecificRendition(
                item.image.base_url,
                SCREEN_DENSITY_QUALIFIERS.HDPI,
                item.image.image_path
              )}
              className="embed-responsive embed-responsive-16by9"
            />
            <div className="row ml-1 mt-1">
              {/* <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.NEWS_CALENDER,
                  ACCESSIBILITY_IDENTIFIERS.NEWS
                )}
                className="dear-employer-calender-style"
              /> */}
              <div className="d-flex flex-row justify-content-between w-100 pr-3">
                {!!item?.time_to_read && (
                  <div className="broadcast-event-date-new ">
                    {item.time_to_read != 0 ? formatSecondsIntoMinutes(item.time_to_read) : null}
                  </div>
                )}
                {!!item?.video_duration &&
                  item?.content_load_type === '3' &&
                  item?.video_duration > 0 && (
                    <div className="broadcast-event-date-new ">
                      {formatSecondsIntoTimeForVideos(item?.video_duration)}
                    </div>
                  )}
                <div className="broadcast-event-date-new ">
                  {formatDateDDMonthYYYY(item.pub_datetime)}
                </div>
                {!!item.page_views && (
                  <div className="broadcast-event-date-new ">
                    {item.page_views > 1 ? `${item.page_views} views` : `${item.page_views} view`}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="dear-employer-title">{item.title}</div>
        </div>
      );
    });

  const callFilteredArticles = (date) => {
    pageId.current = 1;
    loadMoreFlag.current = true;
    const tempdate = date ? dateResponse.find((res) => res.title === date).value : '';
    callArticlesApi(tempdate);
    setShowNewsDialog(false);
  };

  const CustomYearView = () => {
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Label>Select Year</Form.Label>
            <div className="dear-employer-year-container">
              <Form.Control
                as="select"
                custom
                value={selectedDate}
                onChange={(event) => {
                  setSelectedDate(event.target.value);
                }}>
                {dateArray &&
                  dateArray.map((item) => {
                    return (
                      <option key={item} defaultValue={item === selectedDate}>
                        {item}
                      </option>
                    );
                  })}
              </Form.Control>
            </div>
          </Form.Group>
        </Form>
        <div className="dear-employer-row">
          <div>
            <CustomButton
              buttonStyle="dear-employer-calender-button-style"
              onClick={() => {
                setSelectedDate('');
                callFilteredArticles('');
              }}>
              CLEAR FILTERS
            </CustomButton>
          </div>
          <div className="dear-employer-row">
            <CustomButton
              buttonStyle="dear-employer-calender-button-style"
              onClick={() => {
                setShowNewsDialog(false);
              }}>
              CANCEL
            </CustomButton>
            <CustomButton
              buttonStyle="dear-employer-calender-button-style"
              onClick={() => {
                callFilteredArticles(selectedDate);
              }}>
              DONE
            </CustomButton>
          </div>
        </div>
      </div>
    );
  };

  const loadMore = () => {
    if (loadMoreFlag) {
      const tempdate = selectedDate
        ? dateResponse.find((res) => res.title === selectedDate).value
        : '';
      callArticlesApi(tempdate);
    }
  };

  const handleSubmitFunction = (searchText) => {
    dispatch(saveSearchText(searchText));
    history.push(NAVIGATION_ROUTES.DEAR_EMPLOYER_SEARCH, { searchQuery: searchText });
  };

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
        <SearchComponent handleSubmitFunction={handleSubmitFunction} />
        <div className="gcdo-icon-row mr-3">
          <img
            src={getHeaderComponentUrl(accessibilityData, ACCESSIBILITY_HEADER_COMPONENTS.CALENDER)}
            className="header-icon"
            onClick={() => {
              setShowNewsDialog(true);
              if (!selectedDate) {
                setSelectedDate(dateResponse && dateResponse[0].title);
              }
            }}
          />
        </div>
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.DEAR_EMPLOYER} />
      <div className="main-content-container">
        <Modal
          show={showNewsDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={() => setShowNewsDialog(false)}>
          <Modal.Body>{CustomYearView()}</Modal.Body>
        </Modal>
        <div className="row">{articlesArray}</div>
        {loadMoreBtn && (
          <div className="mb-4 mt-4">
            <LoadMoreBtn onClick={loadMore} isLoading={progressLoadingState.isProgressLoading} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DearEmployerMainScreen;
