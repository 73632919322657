import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  MICROGIVE_EVENT_TYPE,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import { selectMicrogiveCategories } from '../../../../state/MasterData';
import { useHistory } from 'react-router-dom';
import { apiRequest, handleError } from '../../../../services/Service';
import { GET_INITIATIVES } from '../../../../services/ApiUrls';
import DonationListItem from '../../components/DonationListItem';
import DonationFilterItem from '../../components/DonationFilterItem';
import LoadMoreBtn from '../../../../common/ui/load_more_btn/LoadMoreBtn';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../../state/UIState';
import DefaultContentView from '../../../../common/ui/default_content_view/DefaultContentView';
import {
  saveMicrogiveDonationCategoryId,
  selectMicrogiveDonationCategoryId,
} from '../../../../state/TabData';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';

const DonationTab = ({ onSetDonationMarkedDates, tabKey }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [donationList, setDonationList] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(true);

  const donationCategory = useSelector(selectMicrogiveDonationCategoryId);

  const microGiveCategories = useSelector(selectMicrogiveCategories);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [noMatchFlag, setNoMatchFlag] = useState(false);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.DONATION_TAB_VISIT, tabKey, ANALYTICS_ITEM_NAMES.MICROGIVE);
  }, [tabKey]);

  const onPress = (item) => {
    dispatch(saveMicrogiveDonationCategoryId(item.category_id));
    setDonationList([]);
    setPageId(1);
    setLoadMoreFlag(true);
  };

  const onPressItem = (item) => {
    history.push(`${NAVIGATION_ROUTES.DONATION_TABS_SCREEN}/${item.initiative_id}`);
  };

  const callDonationListApi = useCallback(
    async (id, loadMore, catId) => {
      setNoMatchFlag(false);
      if (loadMore) {
        const params = {
          page_id: id,
          type: MICROGIVE_EVENT_TYPE.DONATION,
          category_id: catId,
        };
        try {
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          const apiResponse = await apiRequest(GET_INITIATIVES, REQUEST_TYPE.GET, params);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.initiatives.length > 0) {
                if (id === 1) {
                  setDonationList(apiResponse.response.data.initiatives);
                } else {
                  setDonationList((oldArray) => [
                    ...oldArray,
                    ...apiResponse.response.data.initiatives,
                  ]);
                }
                setLoadMoreFlag(true);
                if (apiResponse.response.data.dates.length > 0) {
                  let markedDatas = [];
                  apiResponse.response.data.dates.forEach((element) => {
                    markedDatas.push(element);
                  });
                  onSetDonationMarkedDates(markedDatas);
                }
              } else {
                setLoadMoreFlag(false);
              }
            }
            if (apiResponse.response.data.initiatives.length === 0 && pageId === 1) {
              setNoMatchFlag(true);
            }
          }
        } catch (err) {
          handleError(err, params, GET_INITIATIVES, NAVIGATION_ROUTES.MICRO_GIVE);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      }
    },
    [dispatch, onSetDonationMarkedDates]
  );

  useEffect(() => {
    callDonationListApi(pageId, loadMoreFlag, donationCategory);
  }, [callDonationListApi, donationCategory, loadMoreFlag, pageId]);

  const onPressLoadMore = () => {
    setPageId((prev) => prev + 1);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.STAY_TUNED_FOR_UPCOMING_EVENTS} />;
  };

  return (
    <div className="main-content-container">
      <p className="microgive-donate-today-text">
        *Donate today and Microland will partner with you to double your contribution. Let’s come
        together to help twice as many people!
      </p>
      <div className="microgive-category-container">
        {microGiveCategories.map((item, index) => {
          return (
            <DonationFilterItem
              item={item}
              onPress={onPress}
              key={`${index}`}
              categoryId={donationCategory}
            />
          );
        })}
      </div>
      {pageId === 1 && noMatchFlag ? (
        emptyListView()
      ) : (
        <div>
          <div className="row">
            {donationList.map((item, index) => {
              return <DonationListItem item={item} onPress={onPressItem} key={`${index}`} />;
            })}
          </div>
          {loadMoreFlag && (
            <LoadMoreBtn
              onClick={onPressLoadMore}
              isLoading={progressLoadingState.isProgressLoading}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DonationTab;
