import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectProgressLoadingState } from '../../../state/UIState';
import CustomButton from '../../../common/ui/custom_button/CustomButton';

const TravelRequestConfirmDialog = (props) => {
  const {
    isRquestConfirmDialogVisible,
    setIsRquestConfirmDialogVisible,
    title = '',
    subtitle = '',
    onPressFirstButton = () => {},
    onPressSecondButton = () => {},
    firstButtonText = 'No',
    secondButtonText = 'Yes',
    isOnlySecondButtonEnabled = false,
  } = props;
  const progressLoadingState = useSelector(selectProgressLoadingState);

  return (
    <Modal
      onHide={() => setIsRquestConfirmDialogVisible((val) => !val)}
      show={isRquestConfirmDialogVisible}
      centered
      backdrop="static"
      keyboard={false}>
      <Modal.Body className="p-4">
        <h5 className="ijp-apply-modal-title text-center">{title}</h5>
        {!!subtitle && <p className="ijp-apply-modal-subtitle text-center mb-4">{subtitle}</p>}
        <div className="d-flex justify-content-center">
          {!isOnlySecondButtonEnabled && (
            <CustomButton
              disabled={progressLoadingState.isProgressLoading}
              onClick={onPressFirstButton}
              buttonMainContainer="mr-4"
              buttonStyle="common-custom-button-ternary">
              {firstButtonText}
            </CustomButton>
          )}
          <CustomButton
            disabled={progressLoadingState.isProgressLoading}
            onClick={onPressSecondButton}
            buttonStyle="common-custom-button-quaternary">
            {secondButtonText}
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TravelRequestConfirmDialog;
