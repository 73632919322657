import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { selectEmployeeData } from '../../../../state/EmployeeData';
import { getFeatureComponentUrl } from '../../../../utils/accessibility';
import { formatDateDDMonthYYYY, formatTimeAMPM, isToday } from '../../../../utils/common';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../constants/AppConstants';
import CommentPopupMenu from '../../../ui/comment_popup_menu/CommentPopupMenu';
import HtmlView from '../../../ui/html_view/HTMLView';
import UserProfilePhoto from '../../../ui/user_profile_photo/UserProfilePhoto';
import EditCommentBox from './EditCommentBox';
import CustomEditCommentBox from './CustomEditCommentBox';
import { EditorState } from 'draft-js';
import {
  extractDataFromEditorState,
  getCommentMentionsState,
  getMentionsState,
} from '../../../components/hashtagsAndMentionsInput/hashtagUtils';

const CommentReplyItem = (props) => {
  const {
    showLoader,
    replyIndex,
    replyTotalCount,
    replyData,
    replyEditFlag,
    setReplyEditFlag,
    deleteComment,
    editComment,
    showDeleteLoader,
    onPressWhoLikedHandler,
    likeUnlikeCommentApi,
    selectedReplyCommentData,
    setSelectedReplyCommentData,
  } = props;
  const accessibilityData = useSelector(selectAccessibilityData);
  const employeeData = useSelector(selectEmployeeData);
  const [replyEditedText, setReplyEditedText] = useState();
  const loggedInEmployeeId = employeeData.employee_id;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [image, setImage] = useState('');
  const [imageBaseUrl, setImageBaseUrl] = useState('');
  const [prevMentions, setPrevMentions] = useState([]);

  useEffect(() => {
    if (replyData.comment) {
      if (replyData.comment.includes('<img')) {
        const index = replyData.comment.indexOf('<img');
        setReplyEditedText(replyData.comment.substring(0, index));
        const pos = replyData.comment.indexOf('https');
        const imageUrl = replyData.comment.substring(pos, replyData.comment.length - 2);
        const fileNameIndex = imageUrl.lastIndexOf('/');
        setImage(imageUrl.substring(fileNameIndex + 1, imageUrl.length));
        setImageBaseUrl(imageUrl.substring(0, fileNameIndex + 1));
      } else {
        setReplyEditedText(replyData.comment);
      }
      const tempMentions = [];
      Object.values(replyData.taggged_employees).map((user) => {
        if (user) {
          tempMentions.push({
            type: 'mention',
            name: user.full_name + ' ',
            avatar: '',
            employee_id: user.employee_id,
          });
        }
      });
      setPrevMentions(tempMentions);
      const editorState = getMentionsState(replyData.comment_text, tempMentions);
      setEditorState(editorState);
      setReplyEditedText(replyData.comment);
    }
  }, [replyData.comment]);

  const onChangeEditedReplyTextInput = (val) => {
    const { description, addedMentions } = extractDataFromEditorState(editorState);
    const editorState2 = getCommentMentionsState(description + val, [
      ...addedMentions,
      ...prevMentions,
    ]);
    setEditorState(editorState2);
    setReplyEditedText(description + val);
  };

  const onPressSendEditedCommentHandler = () => {
    const { description, addedMentions } = extractDataFromEditorState(editorState);
    let tempDes = description;
    tempDes = tempDes.replace(/ {2,}(?![\n])/g, ' ');

    addedMentions.map((user) => {
      tempDes = tempDes.replace(user.name, `<rsu>{{${user.employee_id}}}</rsu> `);
    });

    prevMentions.map((user) => {
      tempDes = tempDes.replace(user.name, `<rsu>{{${user.employee_id}}}</rsu> `);
    });

    if (image != '') {
      tempDes = tempDes + ` <egami>${image}</egami>`;
    }
    setSelectedReplyCommentData({});
    setReplyEditFlag(false);
    editComment(replyData.comment_id, tempDes);
  };

  const onPressCancelEditHandler = () => {
    setSelectedReplyCommentData({});
    setReplyEditFlag(false);
    setReplyEditedText(replyData.comment);
  };

  const onPressEditCommentHandler = () => {
    setSelectedReplyCommentData(replyData);
    setReplyEditFlag(true);
  };

  const onPressDeleteCommentHandler = () => {
    deleteComment(replyData.comment_id);
  };

  const onPressReplyLikeHandler = () => {
    likeUnlikeCommentApi(replyData.comment_id);
  };

  const onReplyMention = (mention) => {
    setPrevMentions((prev) => [...prev, mention]);
  };

  return (
    <div key={replyData.comment_id.toString()}>
      <div className="d-flex">
        <div>
          <div
            className={`common-comment-reply-item-vertical-line ${
              replyTotalCount - 1 !== replyIndex
                ? 'h-100'
                : 'common-comment-reply-item-vertical-line-last-item'
            }`}
          />
        </div>
        <div className="d-flex mt-3 flex-grow-1">
          <div className="d-flex">
            <div className="common-comment-reply-item-horionztal-line" />
            <UserProfilePhoto
              imageBaseUrl={replyData.employee.profile_picture.base_url}
              imagePath={replyData.employee.profile_picture.image_path}
              employeeId={replyData.employee.employee_id}
              imageClass="common-user-profile-photo-xxs"
            />
          </div>
          <div className="ml-2 flex-grow-1">
            <div className="d-flex align-items-center">
              <p className="common-comment-item-name-text mb-0">
                {replyData.employee.first_name + ' ' + replyData.employee.last_name}
              </p>
              {!!replyData.employee.pronouns && (
                <p className="common-comment-item-pronoun-text ml-1 mb-0">
                  {'(' + replyData.employee.pronouns + ')'}
                </p>
              )}
              <p className="common-comment-item-date-text mb-0 ml-2">
                {isToday(replyData.comment_on)
                  ? formatTimeAMPM(replyData.comment_on)
                  : formatDateDDMonthYYYY(replyData.comment_on)}
              </p>
            </div>

            {replyEditFlag && replyData.comment_id === selectedReplyCommentData.comment_id ? (
              <div className="py-2">
                <CustomEditCommentBox
                  onChangehandler={onChangeEditedReplyTextInput}
                  showLoader={showLoader}
                  commentText={replyEditedText.replace(/<\/?a[^>]*>/g, '')}
                  callCommentFunction={onPressSendEditedCommentHandler}
                  callCancelFunction={onPressCancelEditHandler}
                  setImage={setImage}
                  image={image}
                  editorState={editorState}
                  enableImageUpload={true}
                  setEditorState={setEditorState}
                  enableHashtags={false}
                  enableUserMentions={true}
                  imageBaseUrl={imageBaseUrl}
                  setImageBaseUrl={setImageBaseUrl}
                  onMention={onReplyMention}
                />
              </div>
            ) : (
              <>
                <div className="d-flex">
                  <div className="d-flex">
                    <div
                      className={`py-2 px-3 my-1 common-comment-item-message-text-outer common-comment-item-other-comment-background`}>
                      <HtmlView
                        html={replyData.comment.replace(/\n/gi, '')}
                        htmlStyle="common-comment-item-message-text"
                      />
                    </div>
                    {loggedInEmployeeId === replyData.employee.employee_id && !replyEditFlag && (
                      <div className="px-2">
                        <CommentPopupMenu
                          editFuncHandler={onPressEditCommentHandler}
                          deleteFuncHandler={onPressDeleteCommentHandler}
                          showDeleteLoader={showDeleteLoader}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <div onClick={onPressReplyLikeHandler}>
                    <img
                      src={getFeatureComponentUrl(
                        accessibilityData,
                        replyData.liked
                          ? ACCESSIBILITY_FEATURE_COMPONENTS.CEO_CHAT_ACTIVE_LIKE
                          : ACCESSIBILITY_FEATURE_COMPONENTS.CEO_CHAT_INACTIVE_LIKE,
                        ACCESSIBILITY_IDENTIFIERS.CEO_CHAT
                      )}
                      className="common-comment-item-action-item-icon common-cursor-pointer"
                    />
                  </div>
                  {!!replyData.like_count && (
                    <div
                      className="ml-3 pt-1 common-comment-item-action-item-text common-cursor-pointer"
                      onClick={() => {
                        replyData.like_count > 0
                          ? onPressWhoLikedHandler(replyData.comment_id)
                          : () => {};
                      }}>
                      <div>
                        {replyData.like_count} {replyData.like_count === 1 ? 'Like' : 'Likes'}
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CommentReplyItem);
