import React, { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectEmployeeData } from '../../../state/EmployeeData';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  DIALOG_TYPE,
} from '../../../common/constants/AppConstants';
import { chatFormatTimeAMPM, fetchUserByIdOnce } from '../../../utils/ChatHandler';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import TextChatView from './TextChatView';
import ImageChatView from './ImageChatView';
import PreviousImageChatView from './PreviousImageChatView';
import PreviousTextChatView from './PreviousTextChatView';
import CustomLightbox from '../../../common/ui/custom_lightbox/CustomLightbox';
import PreviousTextModal from './PreviousTextModal';
import { OverlayTrigger } from 'react-bootstrap';
import DocumentChatView from './DocumentChatView';
import PreviousDocumentChatView from './PreviousDocumentChatView';
import UserProfilePhotoForChats from '../../../common/ui/user_profile_photo/UserProfilePhotoForChats';

const IncomingChatCell = ({ item, chatType, message, isDeleted, messageMoreOptionsPopover }) => {
  const [userName, setUserName] = useState('');
  const [userImage, setUserImage] = useState('');
  const [showPreviousImageDialog, setShowPreviousImageDialog] = useState(false);
  const [showPreviousNameDialog, setShowPreviousNameDialog] = useState(false);
  const [showImageDialog, setShowImageDialog] = useState(false);
  const [showMessageOptions, setShowMessageOptions] = useState(false);
  const fullImageUrl = useRef('');
  const accessibilityData = useSelector(selectAccessibilityData);
  const employeeData = useSelector(selectEmployeeData);

  useEffect(() => {
    fetchUserByIdOnce(item.sender_id, (isExist, user) => {
      if (isExist) {
        setUserName(user.name);
        setUserImage(user.image_url);
      } else {
        setUserName('');
        setUserImage('');
      }
    });
  }, [chatType, employeeData, item]);

  const renderCustomView = () => {
    if (!item.message_type || item.message_type === '1') {
      return <TextChatView message={message} />;
    } else if (item.message_type === '2') {
      return (
        <ImageChatView
          item={item}
          setShowImageDialog={(value) => {
            setShowImageDialog(value);
            fullImageUrl.current = item.url;
          }}
        />
      );
    } else if (item.message_type === '3') {
      return <DocumentChatView item={item} />;
    }
  };

  const renderPreviousCustomView = (previousObj) => {
    if (!previousObj.message_type || previousObj.message_type === '1') {
      return (
        <div className="common-cursor-pointer" onClick={() => setShowPreviousNameDialog(true)}>
          <div className="py-2 px-2 my-1 chat-outgoing-reply-container">
            <PreviousTextChatView item={previousObj} />
          </div>
        </div>
      );
    } else if (previousObj.message_type === '2') {
      return (
        <PreviousImageChatView
          item={previousObj}
          setShowPreviousImageDialog={(value) => {
            setShowPreviousImageDialog(value);
            fullImageUrl.current = item.reply_forward_params.url;
          }}
        />
      );
    } else if (previousObj.message_type === '3') {
      return <PreviousDocumentChatView item={previousObj} />;
    }
  };

  const renderEditDateView = () => {
    return (
      <div className="d-flex justify-content-between chat-base-text-sm chat-grey-text position-relative">
        {item.is_forwarded && item.is_forwarded === true ? (
          <div className="mr-2 font-italic">Forwarded</div>
        ) : item.is_edited && item.is_edited === true ? (
          <div className="mr-2">Edited</div>
        ) : (
          <div />
        )}
        <OverlayTrigger
          rootClose
          trigger="click"
          placement="top"
          overlay={messageMoreOptionsPopover(item, {
            name: userName,
          })}>
          <div className="pl-2 position-absolute chat-message-incoming-item-more-options-container common-cursor-pointer">
            {showMessageOptions && (
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.COMMENTS_MORE_VERTICAL,
                  ACCESSIBILITY_IDENTIFIERS.COMMON
                )}
                className="chat-message-item-more-options-icon"
              />
            )}
          </div>
        </OverlayTrigger>
        {chatFormatTimeAMPM(item.timestamp)}
      </div>
    );
  };

  const renderOneToOneIncomingChatCell = () => {
    return (
      <div>
        {isDeleted && isDeleted === true ? (
          <div className={`d-flex justify-content-start`}>
            <div className={`px-3 py-2 mb-2 chat-base-bubble text-break chat-other-bubble`}>
              <div className="d-flex justify-content-between chat-base-text-sm chat-grey-text position-relative">
                This message has been deleted
              </div>
            </div>
          </div>
        ) : (
          <div className={`d-flex justify-content-start`}>
            <div
              onMouseEnter={() => setShowMessageOptions(true)}
              onMouseLeave={() => setShowMessageOptions(false)}
              className={`px-3 py-2 mb-2 chat-base-bubble text-break chat-other-bubble`}>
              {renderEditDateView()}
              {item.reply_forward_params && Object.keys(item.reply_forward_params).length > 0 && (
                <div>{renderPreviousCustomView(item.reply_forward_params)}</div>
              )}
              {renderCustomView()}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderGroupIncomingChatCell = () => {
    return (
      <div className="d-flex">
        <div className="mr-2 d-flex align-items-end">
          <UserProfilePhotoForChats
            imagePath={userImage}
            imageClass="common-user-profile-photo-xxs"
          />
        </div>
        <div className={`d-flex flex-grow-1 justify-content-start`}>
          <div
            onMouseEnter={() => {
              if (!isDeleted) {
                setShowMessageOptions(true);
              }
            }}
            onMouseLeave={() => setShowMessageOptions(false)}
            className={`px-3 py-2 mb-2 chat-base-bubble text-break chat-other-bubble`}>
            <div className="d-flex justify-content-between align-items-center position-relative">
              <div className="chat-bold-text chat-base-text-sm mr-3">{userName}</div>
              <div className="chat-base-text-sm chat-grey-text">
                <OverlayTrigger
                  rootClose
                  trigger="click"
                  placement="top"
                  overlay={messageMoreOptionsPopover(item, {
                    name: userName,
                  })}>
                  <div className="pl-2 position-absolute chat-message-incoming-item-more-options-container common-cursor-pointer">
                    {showMessageOptions && (
                      <img
                        src={getFeatureComponentUrl(
                          accessibilityData,
                          ACCESSIBILITY_FEATURE_COMPONENTS.COMMENTS_MORE_VERTICAL,
                          ACCESSIBILITY_IDENTIFIERS.COMMON
                        )}
                        className="chat-message-item-more-options-icon"
                      />
                    )}
                  </div>
                </OverlayTrigger>
                {chatFormatTimeAMPM(item.timestamp)}
              </div>
            </div>
            {item.is_edited && item.is_edited === true && (
              <div className="mr-2 mt-n1 chat-base-text-sm chat-grey-text">Edited</div>
            )}
            {item.is_forwarded && item.is_forwarded === true && (
              <div className="mr-2 mt-n1 font-italic chat-base-text-sm chat-grey-text">
                Forwarded
              </div>
            )}
            {item.reply_forward_params && Object.keys(item.reply_forward_params).length > 0 && (
              <div>{renderPreviousCustomView(item.reply_forward_params)}</div>
            )}
            {isDeleted && isDeleted === true ? (
              <div className="d-flex justify-content-between chat-base-text-sm chat-grey-text position-relative">
                This message has been deleted
              </div>
            ) : (
              renderCustomView()
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {showPreviousImageDialog && (
        <CustomLightbox
          mainSrc={fullImageUrl.current}
          onCloseRequest={() => setShowPreviousImageDialog(false)}
        />
      )}
      {showImageDialog && (
        <CustomLightbox
          mainSrc={fullImageUrl.current}
          onCloseRequest={() => setShowImageDialog(false)}
        />
      )}
      <PreviousTextModal
        isDialogVisible={showPreviousNameDialog}
        setIsDialogVisible={(value) => setShowPreviousNameDialog(value)}
        item={item}
      />
      <div>
        {chatType === DIALOG_TYPE.ONE_ON_ONE_CHAT
          ? renderOneToOneIncomingChatCell()
          : renderGroupIncomingChatCell()}
      </div>
    </div>
  );
};

export default IncomingChatCell;
