import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../../utils/accessibility';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
} from '../../../constants/AppConstants';
import { formatStartAndEndDate, getYoutubeIdFromUrlParameters } from '../../../../utils/common';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import Divider from '../../../ui/divider/Divider';
import CustomYoutubePlayer from '../../../ui/youtube_video/CustomYoutubePlayer';

const MediaTab = (props) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const data = props.data;

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.NEW_JOINEES_AGENDA_MEDIA_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.NEW_JOINEES_HIGHER_LEVELS
    );
  }, []);

  const onPressPdf = (doc) => {
    window.open(doc.url, '_blank');
  };

  return (
    <div className="main-content-container">
      <div key={data.session_id.toString()} className="material-card p-3 mb-3">
        <div className="pb-3">
          <h6 className="new-joinees-higher-levels-card-main-title mb-1">{data.title}</h6>
          <p className="new-joinees-higher-levels-card-sub-title mb-2">
            {formatStartAndEndDate(data.start_datetime, data.end_datetime)}
          </p>
          <Divider />
        </div>

        {!!data.media.youtube.length &&
          data.media.youtube.map((video, index) => (
            <div className="mb-3" key={index}>
              <div className="pb-3">
                <CustomYoutubePlayer videoId={getYoutubeIdFromUrlParameters(video.url)} />
                <p className="text-center mt-3 mb-0">{video.label}</p>
              </div>
              <Divider />
            </div>
          ))}

        {!!data.media.text.length &&
          data.media.text.map((link, index) => (
            <div className="mb-3" key={index}>
              <div className="d-flex align-items-center pb-3 common-cursor-pointer">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_LINK_SQUARE,
                    ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                  )}
                  className="new-joinees-higher-levels-card-link-media-attachment-icon mr-2"
                />
                <a className="mb-0" href={link.url} target="_blank" rel="noreferrer">
                  {link.label}
                </a>
              </div>
              <Divider />
            </div>
          ))}

        {!!data.media.pdf.length &&
          data.media.pdf.map((doc, index) => (
            <div className="mb-3" key={index}>
              <div className="d-flex align-items-center pb-3 common-cursor-pointer">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_PDF_ATTACHMENT,
                    ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                  )}
                  className="new-joinees-higher-levels-card-pdf-media-attachment-icon mr-2"
                />
                <p className="mb-0" onClick={() => onPressPdf(doc)}>
                  {doc.label}
                </p>
              </div>
              <Divider />
            </div>
          ))}
      </div>
    </div>
  );
};

export default React.memo(MediaTab);
