import React, { useEffect, useState } from 'react';
import ML35GalleryComponent from './ML35GalleryComponent';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  NAVIGATION_ROUTES,
} from '../../../common/constants/AppConstants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';

const ML35PictureGalleryItem = ({
  item,
  onPress = () => {},
  identifier,
  geography,
  day_id,
  title,
}) => {
  const [images, setImages] = useState([]);
  const [countLeft, setCountLeft] = useState(0);
  const history = useHistory();
  const [selectedImage, setSelectedImage] = useState();
  const [isOverlayOpen, setOverlayOpen] = useState(false);
  const accessibilityData = useSelector(selectAccessibilityData);

  const gotoPictures = () => {
    history.push(NAVIGATION_ROUTES.ML35_PICTURES_SCREEN, {
      identifier: identifier,
      geography: geography,
      day_id: day_id,
      fromRoute: 'pictureGalleryNonIndia',
      title: title,
    });
  };

  const openOverlay = (item) => {
    setSelectedImage(item);
    setOverlayOpen(true);
  };

  const closeOverlay = () => {
    setOverlayOpen(false);
    setSelectedImage();
  };

  const downloadImage = async (imageSrc, imageName = 'downloaded-image') => {
    try {
      const response = await fetch(imageSrc);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = imageName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading the image:', error);
    }
  };

  useEffect(() => {
    if (item.length < 7) {
      setImages(item);
      setCountLeft(0);
    } else {
      setImages(item.slice(0, 6));
      setCountLeft(item.length - 6);
    }
  }, [item]);
  return (
    <div>
      {isOverlayOpen && selectedImage && (
        <div className="ml-35-image-overlay">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_IMAGE_CLOSE,
              ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
            )}
            className="ml-35-image-close-button"
            onClick={closeOverlay}
          />
          <img
            className="ml-35-fullscreen-image"
            src={selectedImage.picture.base_url + 'xxhdpi/' + selectedImage.picture.image_path}
          />
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_IMAGE_DOWLOAD,
              ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
            )}
            className="ml-35-image-download-icon common-cursor-pointer"
            onClick={() =>
              downloadImage(
                selectedImage.picture.base_url + 'xxhdpi/' + selectedImage.picture.image_path,
                `Celebration-Image`
              )
            }
          />
        </div>
      )}
      <div className={`ml-35-images-wrapper mb-2`}>
        {images.map((imageItem, index) => {
          return (
            <div key={`${index}`} style={{ position: 'relative' }}>
              <ML35GalleryComponent
                key={`${index}`}
                item={imageItem}
                index={index}
                onPress={() => openOverlay(imageItem)}
              />
              {index === 5 && countLeft > 0 && (
                <div onClick={() => gotoPictures()} className="ml-35-more-photos-bg-view">
                  <div className="breakouts-count-left-text">{`+${countLeft} ${
                    countLeft === 1 ? 'photo' : 'photos'
                  }`}</div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ML35PictureGalleryItem;
