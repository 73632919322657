import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import CommentBox from '../../../../common/ui/comment_box/CommentBox';
import LoadMoreBtn from '../../../../common/ui/load_more_btn/LoadMoreBtn';
import {
  GET_MICROGIVE_DISCUSSIONS,
  SUBMIT_MICROGIVE_DISCUSSION_COMMENT,
} from '../../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../../services/Service';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../../state/UIState';
import { isEmptyText, showToast } from '../../../../utils/common';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import DiscussionTabItem from '../../components/DiscussionTabItem';

const DonationDiscussionTab = ({ initiativeId }) => {
  const dispatch = useDispatch();
  const [discussions, setDiscussions] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const [commentText, setCommentText] = useState('');

  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.DONATION_DISCUSSIONS_TAB_VISIT,
      initiativeId,
      ANALYTICS_ITEM_NAMES.MICROGIVE
    );
  }, [initiativeId]);

  const callDiscussionListApi = useCallback(
    async (id, loadMore) => {
      if (loadMore) {
        const params = {
          page_id: id,
        };
        try {
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          const apiResponse = await apiRequest(
            GET_MICROGIVE_DISCUSSIONS + initiativeId + '/discussions',
            REQUEST_TYPE.GET,
            params
          );
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                setDiscussions((oldArray) => [
                  ...apiResponse.response.data.feeds.reverse(),
                  ...oldArray,
                ]);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(
            err,
            {},
            GET_MICROGIVE_DISCUSSIONS + initiativeId + '/discussions',
            NAVIGATION_ROUTES.MICRO_GIVE
          );
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      }
    },
    [dispatch, initiativeId]
  );

  useEffect(() => {
    callDiscussionListApi(1, true);
  }, [callDiscussionListApi]);

  const handleChange = (value) => {
    setCommentText(value);
  };

  const callCommentApi = async () => {
    const params = new URLSearchParams();
    params.append('description', commentText.trim());
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.POSTING_DISCUSSION,
        })
      );
      const apiResponse = await apiRequest(
        SUBMIT_MICROGIVE_DISCUSSION_COMMENT + initiativeId + '/post',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setCommentText('');
          setPageId(1);
          setDiscussions([]);
          setLoadMoreFlag(true);
          callDiscussionListApi(1, true);
        }
      }
    } catch (err) {
      handleError(
        err,
        {},
        SUBMIT_MICROGIVE_DISCUSSION_COMMENT + initiativeId + '/post',
        NAVIGATION_ROUTES.MICRO_GIVE
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressSend = () => {
    if (!isEmptyText(commentText)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      logEvent(
        ANALYTICS_EVENT_TYPES.DONATION_DISCUSSION_COMMENT,
        commentText,
        ANALYTICS_ITEM_NAMES.COMMENT
      );
      callCommentApi();
    }
  };

  const onPressLoadPrevious = () => {
    callDiscussionListApi(pageId, loadMoreFlag);
  };

  return (
    <div className="main-content-container">
      <div>
        {loadMoreFlag && (
          <LoadMoreBtn
            onClick={onPressLoadPrevious}
            btnText="Load Previous"
            isLoading={progressLoadingState.isProgressLoading}
          />
        )}
        {discussions.map((item, index) => {
          return <DiscussionTabItem key={`${index}`} item={item} />;
        })}
      </div>
      <CommentBox
        getCommentText={onPressSend}
        onChangehandler={handleChange}
        commentText={commentText}
        textValue="Send"
        showLoader={progressLoadingState.isProgressLoading}
      />
    </div>
  );
};

export default DonationDiscussionTab;
