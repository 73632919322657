import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ML_DAY_IDENTIFIERS,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import CustomButton from '../../../../common/ui/custom_button/CustomButton';
import UserProfilePhoto from '../../../../common/ui/user_profile_photo/UserProfilePhoto';
import { apiRequest, handleError } from '../../../../services/Service';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { saveContestData } from '../../../../state/AnniversaryData';
import {
  saveAnniversaryContestTabData,
  saveAnniversaryDigitalWarriorTabData,
  saveSomTabData,
} from '../../../../state/TabData';
import { selectEmployeeData } from '../../../../state/EmployeeData';
import { selectAnniversaryConfig } from '../../../../state/MasterData';
import { getFeatureComponentUrl } from '../../../../utils/accessibility';
import ArticlesListItem from '../../components/ArticlesListItem';
import ContestsListItem from '../../components/ContestsListItem';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../../common/ui/activity_loader/ActivityLoader';
import dayjs from 'dayjs';
import { INCREASE_VIEW_COUNT_EXTERNAL_LINK } from '../../../../services/ApiUrls';
import { handleML35 } from '../../../../utils/featureNavigation';

const CentralTab = ({ identifier, handleChiefsTab }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [contestsList, setContestsList] = useState([]);
  const [articlesList, setArticlesList] = useState([]);
  const [showHideBeforeEventScenario, setShowHideBeforeEventScenario] = useState(false);
  const [showHideAfterEventScenario, setShowHideAfterEventScenario] = useState(false);
  const [reverseTime, setReverseTime] = useState('');
  const [message, setMessage] = useState();
  const [empMessage, setEmpMessage] = useState('');
  const [enabled, setEnabled] = useState(false);
  const [loading, setLoading] = useState(true);

  const reverseInterval = useRef();

  const employeeData = useSelector(selectEmployeeData);
  const accessibilityData = useSelector(selectAccessibilityData);
  const anniversaryConfig = useSelector(selectAnniversaryConfig).find(
    (element) => element.identifier === identifier
  );
  const somButtonConfig = anniversaryConfig.som_button_config;

  const showAfterEventScenario =
    identifier === ML_DAY_IDENTIFIERS.ML_33_IDENTIFIER
      ? JSON.parse(anniversaryConfig.config).showAfterEventScenario
      : true;

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ANNIVERSARY_CENTRAL_TAB_VISIT,
      identifier,
      ANALYTICS_ITEM_NAMES.ANNIVERSARY
    );
  }, [identifier]);

  useEffect(() => {
    let doj = employeeData.date_of_joining;
    if (doj) {
      const oneDay = 24 * 60 * 60 * 1000;
      const joiningDate = new Date(doj);
      const currentDate = new Date();
      const diffDays = Math.round(Math.abs((currentDate - joiningDate) / oneDay));
      if (diffDays <= 365) {
        setEmpMessage(`Proud Microlander since ${diffDays} Days.`);
      } else {
        const year = Math.floor(diffDays / 365);
        const month = Math.floor((diffDays % 365) / 30);
        if (year === 1 && month === 1) {
          setEmpMessage(`Proud Microlander since ${year} year, ${month} month`);
        } else if (year > 1 && month === 1) {
          setEmpMessage(`Proud Microlander since ${year} years, ${month} month`);
        } else if (year === 1 && month > 1) {
          setEmpMessage(`Proud Microlander since ${year} year, ${month} months`);
        } else {
          setEmpMessage(`Proud Microlander since ${year} years, ${month} months`);
        }
      }
    }
  }, [employeeData.date_of_joining]);

  useEffect(() => {
    if (anniversaryConfig && anniversaryConfig.message) {
      setMessage(anniversaryConfig.message.description);
      setEnabled(anniversaryConfig.message.enable);
    }
  }, [anniversaryConfig]);

  const callEventsApi = useCallback(async () => {
    try {
      setLoading(true);
      const apiResponse = await apiRequest(identifier + '/events', REQUEST_TYPE.GET, {});
      setLoading(false);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse?.response?.status === true) {
          if (apiResponse?.response?.data?.events?.contests?.length > 0) {
            setContestsList(apiResponse?.response?.data.events.contests);
            dispatch(saveContestData(apiResponse.response.data.events.contests));
          }
          if (apiResponse.response.data.events.articles) {
            if (
              identifier === ML_DAY_IDENTIFIERS.ML_33_IDENTIFIER ||
              identifier === ML_DAY_IDENTIFIERS.ML_34_IDENTIFIER
            ) {
              let sections = anniversaryConfig.article_sections;
              let articlesData = apiResponse?.response?.data?.events?.articles;
              sections = sections.map((section) => {
                return {
                  ...section,
                  data: articlesData.filter(
                    (item) => item.sub_channel_id == section.sub_channel_id
                  ),
                };
              });
              setArticlesList(sections);
            } else {
              setArticlesList(apiResponse.response.data.events.articles);
            }
          }
        }
      }
    } catch (err) {
      handleError(err, {}, identifier + '/events', NAVIGATION_ROUTES.ANNIVERSARY_SCREEN);
      setLoading(false);
    }
  }, [dispatch, identifier]);

  useEffect(() => {
    callEventsApi();
  }, [callEventsApi]);

  const getMilliSecondDifference = useCallback(() => {
    if (anniversaryConfig.config) {
      let streamingDate = JSON.parse(anniversaryConfig.config).streaming_date;
      let streamingStartTime = JSON.parse(anniversaryConfig.config).streaming_start_time;
      const convertedDate = `${streamingDate.split('-')[2]}/${streamingDate.split('-')[1]}/${
        streamingDate.split('-')[0]
      }`;
      const convertedTimestamp = `${convertedDate} ${streamingStartTime}:00`;
      const convertedUtcTimestamp = dayjs.utc(convertedTimestamp).subtract(330, 'minute');
      const convertedLocalTimestamp = convertedUtcTimestamp.local();
      const todayTimestamp = dayjs();
      const differenceMilliSeconds = convertedLocalTimestamp.diff(todayTimestamp, 'ms');
      return differenceMilliSeconds;
    }
  }, [anniversaryConfig.config]);

  const reverseTimer = useCallback(
    (sec) => {
      let timer = sec;
      reverseInterval.current = setInterval(() => {
        var days = Math.floor(timer / (3600 * 24));
        var hours = Math.floor((timer % (3600 * 24)) / 3600);
        var minutes = Math.floor((timer % 3600) / 60);
        var seconds = Math.floor(timer % 60);
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        setReverseTime(days + ' ' + hours + ' ' + minutes + ' ' + seconds);
        if (--timer < 0) {
          timer = sec;
        }
        if (parseInt(timer) === 0) {
          clearInterval(reverseInterval.current);
          setShowHideBeforeEventScenario(false);
          setShowHideAfterEventScenario(true);
          handleChiefsTab();
        }
      }, 1000);
    },
    [handleChiefsTab]
  );

  useEffect(() => {
    if (anniversaryConfig && anniversaryConfig.config) {
      let enableWatchVideo = JSON.parse(anniversaryConfig.config).enable_watch_video;
      if (enableWatchVideo && enableWatchVideo === true) {
        let milliSeconds = getMilliSecondDifference();

        if (milliSeconds < 0) {
          setShowHideBeforeEventScenario(false);
          setShowHideAfterEventScenario(true);
        } else {
          setShowHideBeforeEventScenario(true);
          setShowHideAfterEventScenario(false);
          reverseTimer(milliSeconds / 1000);
        }
        return () => {
          clearInterval(reverseInterval.current);
        };
      }
    }
  }, [anniversaryConfig, getMilliSecondDifference, reverseTimer]);

  const renderBeforeEventScenario = () => {
    return (
      <div className="d-flex flex-row flex-grow">
        <div className="w-50 anniversary-watch-now pt-2 pb-2 mt-3 flex-fill">
          <div>
            <div className="anniversary-watch-live-text text-center">
              Microland Day - coming soon!
            </div>
            <div className="d-flex">
              <div className="mr-2">
                <div className="text-center anniversary-timer-upper-text">
                  {reverseTime.split(' ')[0]}
                </div>
                <div className="anniversary-timer-lower-text">DAYS</div>
              </div>
              <div className="anniversary-timer-upper-text">:</div>
              <div className="mr-2 ml-2">
                <div className="text-center anniversary-timer-upper-text">
                  {reverseTime.split(' ')[1]}
                </div>
                <div className="anniversary-timer-lower-text">HOURS</div>
              </div>
              <div className="anniversary-timer-upper-text">:</div>
              <div className="mr-2 ml-2">
                <div className="text-center anniversary-timer-upper-text">
                  {reverseTime.split(' ')[2]}
                </div>
                <div className="anniversary-timer-lower-text">MINUTES</div>
              </div>
              <div className="anniversary-timer-upper-text">:</div>
              <div className="ml-2">
                <div className="text-center anniversary-timer-upper-text">
                  {reverseTime.split(' ')[3]}
                </div>
                <div className="anniversary-timer-lower-text">SECONDS</div>
              </div>
            </div>
          </div>
        </div>
        {JSON.parse(anniversaryConfig.config).showTeaserVideo && (
          <div
            className="w-50 anniversary-watch-2-video d-flex flex-row flex-fill justify-content-center align-items-center pt-2 pb-2 mt-3 ml-2 common-cursor-pointer"
            onClick={onPressTeaserVideo}>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_PLAY_VIDEO_RED,
                ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
              )}
              className="anniversary-play-video-icon"
            />
            <div className="ml-3 anniversary-watch-2-live-text">
              {JSON.parse(anniversaryConfig.config)?.teaserVideoTitle}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderAfterEventScenario = () => {
    return (
      <div className="d-flex flex-row flex-grow">
        <div
          className="anniversary-watch-video d-flex flex-row flex-fill justify-content-center align-items-center pt-3 pb-3 mt-2 common-cursor-pointer"
          onClick={onPressWatchNow}>
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_PLAY_VIDEO_WHITE,
              ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
            )}
            className="anniversary-play-video-icon"
          />
          <div className="ml-3 anniversary-watch-live-text">
            {JSON.parse(anniversaryConfig.config)?.stream_title ?? 'Wishes from Microlanders !'}
          </div>
        </div>
        {JSON.parse(anniversaryConfig.config)?.thanks_video_enabled && (
          <div
            className="anniversary-watch-2-video d-flex flex-row flex-fill justify-content-center align-items-center pt-3 pb-3 mt-2 ml-2 common-cursor-pointer"
            onClick={onPressThanksVideo}>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_PLAY_VIDEO_RED,
                ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
              )}
              className="anniversary-play-video-icon"
            />
            <div className="ml-3 anniversary-watch-2-live-text">
              {JSON.parse(anniversaryConfig.config)?.thanks_video_stream_title}
            </div>
          </div>
        )}
      </div>
    );
  };

  const onPressArticle = async (item) => {
    if (item.content_load_type === '1') {
      try {
        const es = await apiRequest(
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          REQUEST_TYPE.GET,
          {}
        );
      } catch (err) {
        handleError(
          err,
          {},
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          NAVIGATION_ROUTES.ANNIVERSARY_SCREEN
        );
        //ignore if the view count request is not successful
        console.warn(err);
      }

      window.open(item.url);
    } else {
      history.push(`${NAVIGATION_ROUTES.ANNIVERSARY_ARTICLE_SCREEN}/${item.article_id}`);
    }
  };

  const onPress = (item) => {
    dispatch(saveAnniversaryDigitalWarriorTabData(''));
    dispatch(saveAnniversaryContestTabData(''));
    if (anniversaryConfig.contests && anniversaryConfig.contests.length > 0) {
      let objIndex = anniversaryConfig.contests.findIndex(
        (element) => element.hash_tag === item.hash_tag
      );
      if (objIndex !== -1) {
        let finalObj = anniversaryConfig.contests.find(
          (element) => element.hash_tag === item.hash_tag
        );
        history.push(finalObj.route, {
          identifier: identifier,
          tabs: finalObj.tabs,
          contestId: item.contest_id,
          title: item.title,
        });
      }
    }
  };

  const renderContestList = (item, index) => {
    return (
      <ContestsListItem key={`${index}`} item={item} identifier={identifier} onPress={onPress} />
    );
  };

  const renderArticlesList = () => {
    if (
      identifier === ML_DAY_IDENTIFIERS.ML_33_IDENTIFIER ||
      identifier === ML_DAY_IDENTIFIERS.ML_34_IDENTIFIER
    ) {
      return (
        <div className="p-3">
          {articlesList.map((item, index) => {
            if (item?.data?.length > 0) {
              return (
                <div key={`${index}_section`}>
                  {index > 0 && <div className="common-divider-style"></div>}
                  <div className="anniversary-event-title mt-4 mb-2">{item.title}</div>
                  <div className="row">
                    {item?.data?.length > 0 &&
                      item.data.map((item, index) => renderArticlesListItem(item, index))}
                  </div>
                </div>
              );
            }
          })}
        </div>
      );
    } else {
      return articlesList.map((item, index) => renderArticlesListItem(item, index));
    }
  };

  const renderArticlesListItem = (item, index) => {
    return (
      <ArticlesListItem
        key={`${index}`}
        item={item}
        identifier={identifier}
        onPress={onPressArticle}
      />
    );
  };

  const onPressWallpapers = () => {
    history.push(NAVIGATION_ROUTES.WALL_PAPERS_SCREEN, {
      identifier: identifier,
    });
  };

  const onPressECards = () => {
    history.push(NAVIGATION_ROUTES.ECARDS, {
      tabKey: anniversaryConfig.ecard_id,
    });
  };

  const onPressWatchNow = () => {
    if (anniversaryConfig.config) {
      history.push(NAVIGATION_ROUTES.WATCH_LIVE_SCREEN, {
        videoId: JSON.parse(anniversaryConfig.config).stream_url.split('=')[1],
        identifier: identifier,
        title: JSON.parse(anniversaryConfig.config)?.stream_title ?? 'Wishes from Microlanders !',
      });
    }
  };

  const onPressThanksVideo = () => {
    if (anniversaryConfig.config) {
      history.push(NAVIGATION_ROUTES.WATCH_LIVE_SCREEN, {
        videoId: JSON.parse(anniversaryConfig.config).thanks_video_stream_url.split('=')[1],
        identifier: identifier,
        title: 'Thank you from Microland !',
      });
    }
  };

  const onPressTeaserVideo = () => {
    if (anniversaryConfig.config) {
      history.push(NAVIGATION_ROUTES.WATCH_LIVE_SCREEN, {
        videoId: JSON.parse(anniversaryConfig.config).teaserVideoUrl.split('=')[1],
        identifier: identifier,
        title: 'Thank you from Microland !',
      });
    }
  };

  const onPressMessage = () => {
    history.push(NAVIGATION_ROUTES.PLEDGE_SCREEN, {
      identifier: identifier,
    });
  };

  const onPressSom = () => {
    dispatch(saveSomTabData(''));
    history.push({
      pathname: `${NAVIGATION_ROUTES.SOM_MAIN}/${somButtonConfig.som_id}`,
      state: {
        show_business_awardee: somButtonConfig.show_business_awardee,
        visible_tabs: somButtonConfig.visible_tabs,
      },
    });
  };

  return (
    <div className="main-content-container">
      <ActivityLoader visible={loading} />
      {(identifier === ML_DAY_IDENTIFIERS.ML_34_IDENTIFIER ||
        identifier === ML_DAY_IDENTIFIERS.ML_35_IDENTIFIER) &&
        somButtonConfig?.show_button === true && (
          <div className="d-flex flex-row-reverse mi-hr-activity-btn-container">
            <CustomButton buttonStyle="pandemic-support-bottom-button" onClick={onPressSom}>
              {somButtonConfig?.som_button_text}
            </CustomButton>
          </div>
        )}
      <div className="material-card p-3">
        {Object.keys(employeeData).length > 0 && (
          <div className="anniversary-outer-view">
            <UserProfilePhoto
              imageBaseUrl={employeeData.profile_picture.base_url}
              imagePath={employeeData.profile_picture.image_path}
              imageClass="common-user-profile-photo-lg"
              employeeId={employeeData.employee_id}
            />
            <div className="ml-3 anniversary-outer-view">
              <div>
                <div className="mb-1 anniversary-user-name">{`${employeeData.first_name} ${employeeData.last_name}`}</div>
                <div className="mt-1 anniversary-user-position">{empMessage}</div>
              </div>
            </div>
          </div>
        )}
        {enabled && enabled === true && (
          <div
            className="mt-2 text-center anniversary-message-text common-cursor-pointer"
            onClick={onPressMessage}>
            {message}
          </div>
        )}
        {showHideBeforeEventScenario && renderBeforeEventScenario()}
        {showHideAfterEventScenario && showAfterEventScenario && renderAfterEventScenario()}
      </div>
      {identifier === ML_DAY_IDENTIFIERS.ML_35_IDENTIFIER && anniversaryConfig.showBanner && (
        <img
          onClick={() => handleML35(true)}
          className={'ml-35-home-banner mt-3 mb-2'}
          src={anniversaryConfig.webBannerImage}
        />
      )}
      <div className="row">
        {contestsList.length > 0 &&
          contestsList.map((data, index) => renderContestList(data, index))}
      </div>
      <div className="row">{articlesList.length > 0 && renderArticlesList()}</div>
      <div className="anniversary-btn-view mt-3">
        {anniversaryConfig && anniversaryConfig.wallpapers_active && (
          <CustomButton
            buttonStyle="anniversary-btn-wallpaper-ecards mr-3"
            onClick={onPressWallpapers}>
            Wallpapers
          </CustomButton>
        )}
        {anniversaryConfig && anniversaryConfig.ecards_active && (
          <CustomButton buttonStyle="anniversary-btn-wallpaper-ecards ml-3" onClick={onPressECards}>
            e-Cards
          </CustomButton>
        )}
      </div>
    </div>
  );
};

export default React.memo(CentralTab);
