import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../common/constants/AppConstants';
import { STARS_BY_ID } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import {
  getDeviceScreenDensityQualifierAsPath,
  getUrlWithSpecificRendition,
} from '../../utils/common';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import { selectProgressLoadingState, selectShowEmptyContentView } from '../../state/UIState';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { useParams } from 'react-router-dom';

const SOMGalleryScreen = ({ history }) => {
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [picture, setPicture] = useState();
  const [baseUrl, setBaseUrl] = useState();
  const { pictureId } = useParams();

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.SOM_GALLERY, NAVIGATION_ROUTES.SOM_GALLERY);
  }, []);

  const callGaleryApi = useCallback(async () => {
    try {
      const apiResponse = await apiRequest(
        STARS_BY_ID + pictureId + '/picture',
        REQUEST_TYPE.GET,
        {}
      );

      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.picture) {
            const data = apiResponse.response.data.picture;
            setBaseUrl(data.base_url);
            setPicture(data);
          }
        }
      }
    } catch (err) {
      handleError(err, {}, STARS_BY_ID + pictureId + '/picture', NAVIGATION_ROUTES.SOM_GALLERY);
      console.error(err);
    }
  }, [pictureId]);

  const renderScreen = useCallback(() => {
    callGaleryApi();
  }, [callGaleryApi]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const showEmptyListView = () => {
    return (
      <DefaultContentView
        message={NO_DATA_TEXT.DEFAULT}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  return (
    <div className="main-content-container container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.SOM} />
      <BackButton containerStyle="pt-3" />
      {picture && baseUrl && (
        <div className="common-container-md mx-auto mt-5">
          <div>
            <img
              src={getUrlWithSpecificRendition(
                baseUrl,
                SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
                picture.picture_path
              )}
              className="embed-responsive embed-responsive-16by9"
            />
          </div>

          <div className="som-gallery-tagged-view">
            <div className="som-gallery-tag-text">
              {picture.tags.length > 0
                ? picture.tags.length === 1
                  ? '1 Person tagged in this photo'
                  : picture.tags.length + ' People tagged in this photo'
                : 'Tag Microlanders'}
            </div>

            <div
              onClick={() => {
                history.push(NAVIGATION_ROUTES.TAG_EMPLOYEE, {
                  picture_id: picture.picture_id,
                  baseUrl: baseUrl,
                  picture: picture,
                  tags: picture.tags,
                });
              }}
              className="common-cursor-pointer">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.SOM_TAG_EMPLOYEE,
                  ACCESSIBILITY_IDENTIFIERS.SOM
                )}
                className="som-tag-icon-size"
              />
            </div>
          </div>
          {showEmptyContentView &&
          picture.tags &&
          !picture.tags.length &&
          !progressLoadingState.isProgressLoading ? (
            showEmptyListView()
          ) : (
            <div>
              {picture.tags.map((item) => {
                return (
                  <div className="som-gallery-row" key={item.employee_id}>
                    <UserProfilePhoto
                      imageBaseUrl={item.profile_picture.base_url}
                      imagePath={item.profile_picture.image_path}
                      employeeId={item.employee_id}
                      imageClass="common-user-profile-photo-sm mr-3"
                    />
                    <div className="ml-2">
                      <div className="som-gallery-employee-name-text">
                        {item.first_name + ' ' + item.middle_name + ' ' + item.last_name + ' '}
                      </div>
                      <div className="som-gallery-employee-pos-text">{item.position_title}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SOMGalleryScreen;
