import React from 'react';
import { useSelector } from 'react-redux';
import { getHeaderComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';

const LeadersBoardListItem = ({
  item,
  onPressCommentButton,
  onPressLikeButton,
  onPressLikedBy,
  showLikeButton,
  showCommentButton,
}) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  return (
    <div className="material-card p-3 mb-3">
      <div className="cheers-date-view-style">
        <UserProfilePhoto
          imageBaseUrl={item.employee.profile_picture.base_url}
          imagePath={item.employee.profile_picture.image_path}
          imageClass="common-user-profile-photo-lg"
          employeeId={item.employee.employee_id}
        />
        <div className="ml-3 cheers-center-view">
          <div className="cheers-emp-name">{`${item.employee.first_name} ${item.employee.last_name}`}</div>
          <div className="cheers-emp-position">{item.employee.position_title}</div>
          <div className="cheers-desc-text">
            {item.type === 'nominator'
              ? `${item.nomination_count} Nominations`
              : `${item.total_points} Points`}
          </div>
          <div className="cheers-date-view-style cheers-center-view justify-content-between">
            <div />
            <div className="cheers-date-view-style">
              {showLikeButton && (
                <>
                  {item.likes.is_liked ? (
                    <div onClick={() => onPressLikeButton(item)}>
                      <img
                        src={getHeaderComponentUrl(
                          accessibilityData,
                          ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_ACTIVE_LIKE,
                          ACCESSIBILITY_IDENTIFIERS.CHEERS
                        )}
                        className="cheers-normal-icon ml-3 common-cursor-pointer"
                      />
                    </div>
                  ) : (
                    <div onClick={() => onPressLikeButton(item)}>
                      <img
                        src={getHeaderComponentUrl(
                          accessibilityData,
                          ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_INACTIVE_LIKE,
                          ACCESSIBILITY_IDENTIFIERS.CHEERS
                        )}
                        className="cheers-normal-icon ml-3 common-cursor-pointer"
                      />
                    </div>
                  )}
                  <div
                    className="ml-3 likesCountText common-cursor-pointer"
                    onClick={() => {
                      item.likes.count > 0 ? onPressLikedBy(item) : () => {};
                    }}>
                    {item.likes.count}
                  </div>
                </>
              )}
              {showCommentButton && (
                <>
                  <div onClick={() => onPressCommentButton(item)}>
                    <img
                      src={getHeaderComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_POST_COMMENT,
                        ACCESSIBILITY_IDENTIFIERS.CHEERS
                      )}
                      className="cheers-normal-icon ml-3 common-cursor-pointer"
                    />
                  </div>
                  <div className="ml-3 commentCountText">{item.comment_count}</div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadersBoardListItem;
