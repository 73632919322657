import React from 'react';

const NominationsListItem = ({ item, index, onPress, canApprove }) => {
  return (
    <div className="material-card p-3 mb-3">
      {(item.key === 'my_team' && canApprove === true) || !item.isEnabled ? (
        <div>
          <div className="text-center cheers-nomin-text-selected">{item.nominations}</div>
          <div className="text-center cheers-description-text-selected">{item.desc}</div>
        </div>
      ) : (
        <div onClick={() => onPress(item, index)} className="common-cursor-pointer">
          <div className="text-center cheers-nomin-text-not-selected">{item.nominations}</div>
          <div className="text-center cheers-description-text-not-selected">{item.desc}</div>
        </div>
      )}
    </div>
  );
};

export default NominationsListItem;
