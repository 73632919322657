import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { EVENTS } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { saveShowEmptyContentView, selectShowEmptyContentView } from '../../state/UIState';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { formatDateDDMonthYYYY, getUrlWithSpecificRendition } from '../../utils/common';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';

const EventDateScreen = ({ history, location }) => {
  const [events, setEvents] = useState([]);
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  const [dataLength, setDataLength] = useState(0);
  const [loading, setLoading] = useState(true);
  const loadMoreFlag = useRef(true);
  const pageId = useRef(1);
  const params = location.state;
  const accessibilityData = useSelector(selectAccessibilityData);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const dispatch = useDispatch();

  useEffect(() => {
    if (pageId.current !== 1) {
      loadMoreFlag.current = false;
      setLoadMoreBtn(false);
    }
  }, [dataLength]);

  const callEventsByDate = useCallback(async () => {
    dispatch(saveShowEmptyContentView(true));
    setLoading(true);
    const event_params = {
      page_id: pageId.current,
      date: params.date,
    };
    try {
      const apiResponse = await apiRequest(EVENTS, REQUEST_TYPE.GET, event_params);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.events) {
            const data = apiResponse.response.data.events;
            if (data.length > 0) {
              setEvents(data);
              pageId.current = pageId.current + 1;
            }
          }
        }
      }
      setLoading(false);
    } catch (err) {
      handleError(err, event_params, EVENTS, NAVIGATION_ROUTES.EVENTS_DATE);
      setLoading(false);
    }
  }, [dispatch, params.date]);

  const renderScreen = useCallback(async () => {
    callEventsByDate();
  }, [callEventsByDate]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const onPressItem = (item) => {
    history.push(`${NAVIGATION_ROUTES.EVENTS}/${item.event_id}`);
  };

  const eventsArray =
    events &&
    events.map((item, index) => {
      return (
        <div
          key={`${index}`}
          className="col-sm-4 mb-2 common-cursor-pointer"
          onClick={() => onPressItem(item)}>
          <div>
            <img
              src={getUrlWithSpecificRendition(
                item.promo_image.base_url,
                SCREEN_DENSITY_QUALIFIERS.HDPI,
                item.promo_image.image_path
              )}
              className="embed-responsive embed-responsive-16by9"
            />
            <div className="row ml-1 mt-1">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.NEWS_CALENDER,
                  ACCESSIBILITY_IDENTIFIERS.NEWS
                )}
                className="event-calender-style"
              />
              <div className="events-event-date">{formatDateDDMonthYYYY(item.start_date)}</div>
            </div>
          </div>
          <div className="event-title">{item.title}</div>
          <div className="events-summary">{item.summary}</div>
        </div>
      );
    });

  const loadMore = () => {
    if (loadMoreFlag.current) {
      pageId.current = pageId.current + 1;
      callEventsByDate();
    }
  };

  const emptyListView = () => {
    return (
      <DefaultContentView
        message={NO_DATA_TEXT.NO_DATA_FOUND}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.EVENTS_DATE, NAVIGATION_ROUTES.EVENTS_DATE);
  }, []);

  return (
    <div className="main-content-container container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.EVENTS} />
      <BackButton />
      {showEmptyContentView && !eventsArray.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          <div className="row">{eventsArray}</div>
          {loadMoreBtn && (
            <div className="mb-4 mt-4">
              <LoadMoreBtn onClick={loadMore} isLoading={loading} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EventDateScreen;
