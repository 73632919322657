import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectMicrogiveProjectDonation } from '../../state/MasterData';
import DonationDonorsTab from './tabs/donationTabs/DonationDonorsTab';
import DonationDetailsProjectTab from './tabs/donationTabs/DonationDetailsProjectTab';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import { Tab } from 'react-bootstrap';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { useParams } from 'react-router-dom';
import NavTabs from '../../common/ui/tabs/NavTabs';

const ProjectDonationTabsScreen = () => {
  const { initiativeId, microgiveInitiativeDetailId } = useParams();
  const [initiativeDetailId] = useState(microgiveInitiativeDetailId);
  const [key, setKey] = useState();

  const projectDonationTitles = useSelector(selectMicrogiveProjectDonation);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.DONATION_TABS_SCREEN,
      NAVIGATION_ROUTES.DONATION_TABS_SCREEN
    );
  }, []);

  useEffect(() => {
    if (projectDonationTitles.length) {
      setKey(projectDonationTitles[0].key);
    }
  }, [projectDonationTitles]);

  const renderTabs = (tab) => {
    switch (tab.key) {
      case 'donors':
        return (
          <DonationDonorsTab initiativeId={initiativeId} initiativeDetailId={initiativeDetailId} />
        );
      case 'projectDetails':
        return <DonationDetailsProjectTab initiativeDetailId={initiativeDetailId} />;
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICROGIVE} />
      <BackButton />
      <NavTabs activeKey={key} onSelect={(k) => setKey(k)} unmountOnExit>
        {projectDonationTitles.map((tab, index) => {
          return (
            <Tab key={`${index}`} eventKey={tab.key} title={tab.title}>
              {renderTabs(tab, index)}
            </Tab>
          );
        })}
      </NavTabs>
    </div>
  );
};

export default ProjectDonationTabsScreen;
