import React, { useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  ENCODING_TYPE,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import CustomTextInput from '../../../common/ui/custom_text_input/CustomTextInput';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import { ADD_EDIT_TRAVEL_REQUEST } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import {
  selectMultilineTextSize,
  selectTravelRequestDomesticTravelJustificationData,
  selectTravelRequestInternationalTravelJustificationData,
} from '../../../state/MasterData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../state/UIState';
import { formatDateDDMonthYYYY, isEmptyText, showToast } from '../../../utils/common';

const TravelRequestJustificationDialog = (props) => {
  const { isJustificationDialogVisible, setIsJustificationDialogVisible, requestData } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [justification, setJustification] = useState('');
  const maxMultiLineCharLimit = useSelector(selectMultilineTextSize);
  const domesticTravelJustificationInstruction = useSelector(
    selectTravelRequestDomesticTravelJustificationData
  );
  const internationalTravelJustificationInstruction = useSelector(
    selectTravelRequestInternationalTravelJustificationData
  );

  const callAddEditRequestApi = useCallback(
    async (formValues) => {
      const params = { ...formValues };
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
          })
        );
        const apiResponse = await apiRequest(
          ADD_EDIT_TRAVEL_REQUEST,
          REQUEST_TYPE.POST,
          JSON.stringify(params),
          ENCODING_TYPE.RAW
        );
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            const message = apiResponse.response.message;
            if (message) {
              showToast(message);
              history.push(
                NAVIGATION_ROUTES.TRAVEL_REQUEST_REVIEW +
                  '/' +
                  apiResponse.response.request.request_id
              );
            }
          }
        }
      } catch (err) {
        handleError(err, params, ADD_EDIT_TRAVEL_REQUEST, NAVIGATION_ROUTES.TRAVEL_REQUEST);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    },
    [dispatch, history]
  );

  return (
    <Modal
      onHide={() => setIsJustificationDialogVisible((val) => !val)}
      show={isJustificationDialogVisible}
      centered
      backdrop="static"
      size="lg"
      scrollable={true}
      keyboard={false}>
      <Modal.Header className="d-flex align-items-center py-3" closeButton>
        <Modal.Title className="ijp-applied-modal-title">Travel Policy</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <div>
          {requestData && Object.keys(requestData) && Object.keys(requestData).length > 0 && (
            <>
              <div>
                <div className="mb-3">
                  <p className="mb-0 travel-request-plan-travel-label-view">Duration</p>
                  <p className="mb-0">
                    {formatDateDDMonthYYYY(requestData.start_date, false)} to{' '}
                    {formatDateDDMonthYYYY(requestData.end_date, false)}
                  </p>
                </div>
                <div className="mb-3 travel-request-employee-box p-3">
                  <div className="mb-3">
                    <p className="mb-0 travel-request-plan-travel-label-view">Domestic Travel</p>
                    <p className="mb-0">
                      <HtmlView
                        html={domesticTravelJustificationInstruction.replace(/\n/g, '<br>')}
                      />
                    </p>
                  </div>
                  <div>
                    <p className="mb-0 travel-request-plan-travel-label-view">
                      International Travel
                    </p>
                    <p className="mb-0">
                      <HtmlView
                        html={internationalTravelJustificationInstruction.replace(/\n/g, '<br>')}
                      />
                    </p>
                  </div>
                </div>
                <CustomTextInput
                  title="Give justification for the late request"
                  value={justification}
                  as="textarea"
                  maxLength={maxMultiLineCharLimit}
                  onChange={(event) => setJustification(event.target.value)}
                  style="my-profile-edit-editable-text-area"
                  placeholder="Enter comments"
                />
              </div>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton
          buttonMainContainer="d-flex justify-content-end"
          onClick={() => {
            if (!isEmptyText(justification)) {
              showToast(ERROR_MESSAGES.PROVIDE_JUSTIFICATION);
            } else {
              setIsJustificationDialogVisible(false);
              callAddEditRequestApi({ ...requestData, justification: justification });
            }
          }}
          showLoader={progressLoadingState.isProgressLoading}>
          Proceed
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};

export default React.memo(TravelRequestJustificationDialog);
