import React, { useRef, useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';

import { chatFormatTimeAMPM } from '../../../utils/ChatHandler';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import ImageChatView from './ImageChatView';
import PreviousImageChatView from './PreviousImageChatView';
import TextChatView from './TextChatView';
import PreviousTextChatView from './PreviousTextChatView';
import CustomLightbox from '../../../common/ui/custom_lightbox/CustomLightbox';
import PreviousTextModal from './PreviousTextModal';
import DocumentChatView from './DocumentChatView';
import PreviousDocumentChatView from './PreviousDocumentChatView';

const OutgoingChatCell = ({ item, messageMoreOptionsPopover, message, isDeleted }) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const [showPreviousImageDialog, setShowPreviousImageDialog] = useState(false);
  const [showPreviousNameDialog, setShowPreviousNameDialog] = useState(false);
  const [showImageDialog, setShowImageDialog] = useState(false);
  const fullImageUrl = useRef('');
  const [showMessageOptions, setShowMessageOptions] = useState(false);

  const renderCustomView = () => {
    if (!item.message_type || item.message_type === '1') {
      return <TextChatView message={message} />;
    } else if (item.message_type === '2') {
      return (
        <ImageChatView
          item={item}
          setShowImageDialog={(value) => {
            setShowImageDialog(value);
            fullImageUrl.current = item.url;
          }}
        />
      );
    } else if (item.message_type === '3') {
      return <DocumentChatView item={item} />;
    }
  };

  const renderPreviousCustomView = (previousObj) => {
    if (!previousObj.message_type || previousObj.message_type === '1') {
      return (
        <div className="common-cursor-pointer" onClick={() => setShowPreviousNameDialog(true)}>
          <div className="py-2 px-2 my-1 chat-outgoing-reply-container">
            <PreviousTextChatView item={previousObj} />
          </div>
        </div>
      );
    } else if (previousObj.message_type === '2') {
      return (
        <PreviousImageChatView
          item={previousObj}
          setShowPreviousImageDialog={(value) => {
            setShowPreviousImageDialog(value);
            fullImageUrl.current = item.reply_forward_params.url;
          }}
        />
      );
    } else if (previousObj.message_type === '3') {
      return <PreviousDocumentChatView item={previousObj} />;
    }
  };

  const renderEditDateView = () => {
    return (
      <div className="d-flex justify-content-between align-items-center chat-base-text-sm chat-grey-text position-relative">
        {item.is_forwarded && item.is_forwarded === true ? (
          <div className="mr-2 font-italic">Forwarded</div>
        ) : item.is_edited && item.is_edited === true ? (
          <div className="mr-2">Edited</div>
        ) : (
          <div />
        )}
        <OverlayTrigger
          rootClose
          trigger="click"
          placement="top"
          overlay={messageMoreOptionsPopover(item, {
            name: 'You',
          })}>
          <div className="pl-2 position-absolute chat-message-item-more-options-container common-cursor-pointer">
            {showMessageOptions && (
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.COMMENTS_MORE_VERTICAL,
                  ACCESSIBILITY_IDENTIFIERS.COMMON
                )}
                className="chat-message-item-more-options-icon"
              />
            )}
          </div>
        </OverlayTrigger>
        {chatFormatTimeAMPM(item.timestamp)}
      </div>
    );
  };

  return (
    <div>
      {showPreviousImageDialog && (
        <CustomLightbox
          mainSrc={fullImageUrl.current}
          onCloseRequest={() => setShowPreviousImageDialog(false)}
        />
      )}
      {showImageDialog && (
        <CustomLightbox
          mainSrc={fullImageUrl.current}
          onCloseRequest={() => setShowImageDialog(false)}
        />
      )}
      <PreviousTextModal
        isDialogVisible={showPreviousNameDialog}
        setIsDialogVisible={(value) => setShowPreviousNameDialog(value)}
        item={item}
      />
      {isDeleted && isDeleted === true ? (
        <div className="d-flex justify-content-end">
          <div
            onMouseEnter={() => setShowMessageOptions(true)}
            onMouseLeave={() => setShowMessageOptions(false)}
            className={`px-3 py-2 mb-2 chat-base-bubble text-break chat-self-bubble`}>
            <div className="d-flex justify-content-between align-items-center chat-grey-text chat-base-text-sm position-relative">
              This message has been deleted
            </div>
          </div>
        </div>
      ) : (
        <div className={`d-flex justify-content-end`}>
          <div
            onMouseEnter={() => setShowMessageOptions(true)}
            onMouseLeave={() => setShowMessageOptions(false)}
            className={`px-3 py-2 mb-2 chat-base-bubble text-break chat-self-bubble`}>
            {renderEditDateView()}
            {item.reply_forward_params && Object.keys(item.reply_forward_params).length > 0 && (
              <div>{renderPreviousCustomView(item.reply_forward_params)}</div>
            )}
            {renderCustomView()}
          </div>
        </div>
      )}
    </div>
  );
};

export default OutgoingChatCell;
