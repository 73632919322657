import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import {
  showToast,
  sortArrayOfObjects,
  trimTrailingCommasAndWhitespaces,
} from '../../../utils/common';
import { UPDATE_SETTINGS } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { saveEmployeeData, selectEmployeeData } from '../../../state/EmployeeData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../state/UIState';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import CustomButton from '../../../common/ui/custom_button/CustomButton';

const MyAppSettingsTab = () => {
  const dispatch = useDispatch();
  let employeeData = useSelector(selectEmployeeData);
  const [preferences, setPreferences] = useState(
    sortArrayOfObjects(employeeData?.preferences, 'title')
  );
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.MY_PROFILE_APP_SETTINGS_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.MY_PROFILE
    );
  }, []);

  useEffect(() => {
    if (preferences.length === 0 && employeeData?.preferences) {
      setPreferences(sortArrayOfObjects(employeeData?.preferences, 'title'));
    }
  }, [employeeData]);

  const callPreferenceUpdateApi = async () => {
    let tempPreferencesString = '';
    preferences.map((preference) => {
      if (preference.on) {
        tempPreferencesString = tempPreferencesString + preference.preference_id.toString() + ',';
      }
    });

    const myNewPreferences = {
      preferences: trimTrailingCommasAndWhitespaces(tempPreferencesString),
    };
    let params = new URLSearchParams(myNewPreferences);
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(UPDATE_SETTINGS, REQUEST_TYPE.POST, params);
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          const message = apiResponse.response.message;
          if (message) {
            showToast(message);
            dispatch(saveEmployeeData(apiResponse.response.data.employee));
            setPreferences(apiResponse.response.data.employee.preferences);
          }
        }
      }
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      handleError(err, params, UPDATE_SETTINGS, NAVIGATION_ROUTES.PROFILE_DETAIL);
    }
  };

  return (
    <div>
      <div className="d-flex flex-row-reverse mi-hr-activity-btn-container">
        <CustomButton
          buttonStyle="my-profile-app-setting-btn"
          onClick={() => {
            callPreferenceUpdateApi();
          }}
          showLoader={progressLoadingState.isProgressLoading}>
          SAVE
        </CustomButton>
      </div>

      <div>
        <h6 className="my-profile-edit-app-setting-header my-3">Show Notifications For</h6>
        <div className="row">
          {preferences.map((preference) => {
            return (
              <div className="col-sm-12" key={preference.preference_id}>
                <Form.Group className="mt-3 d-flex justify-content-between align-items-center">
                  <Form.Check.Label className="m-0">{preference.title}</Form.Check.Label>
                  <Form.Check
                    className="custom-switch custom-switch-md"
                    type="switch"
                    id={`app-setting-custom-switch-${preference.preference_id}`}
                    checked={preference.on}
                    onChange={(event) => {
                      const newPreferences = preferences.map((preferenceItem) => {
                        if (preferenceItem.preference_id === preference.preference_id) {
                          return { ...preferenceItem, ...{ on: event.target.checked } };
                        }
                        return preferenceItem;
                      });
                      setPreferences(newPreferences);
                    }}
                  />
                </Form.Group>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default React.memo(MyAppSettingsTab);
