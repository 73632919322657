import { createSlice } from '@reduxjs/toolkit';

const saveAccessDataReducer = (state, action) => {
  state.access = action.payload;
};

// REDUCER
const accessDataSlice = createSlice({
  name: 'accessData',
  initialState: {
    access: {},
  },
  reducers: {
    saveAccessData: saveAccessDataReducer,
  },
});

// ACTIONS
const { saveAccessData } = accessDataSlice.actions;

const selectAccessData = ({ accessData }) => {
  return accessData.access;
};

const accessDataSliceReducer = accessDataSlice.reducer;

export { accessDataSliceReducer, saveAccessData, selectAccessData };
