import React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router';

import {
  NAVIGATION_ROUTES,
  TRAVEL_REQUEST_ACTION_ROUTES,
  TRAVEL_REQUEST_TAB_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import { saveTravelRequestTabData } from '../../../state/TabData';

const TravelRequestSuccessDialog = (props) => {
  const {
    isSuccessDialogVisible,
    setIsSuccessDialogVisible,
    actionRouteRef,
    bodyMessage = '',
  } = props;
  const history = useHistory();

  return (
    <Modal
      onHide={() => setIsSuccessDialogVisible((val) => !val)}
      show={isSuccessDialogVisible}
      centered
      backdrop="static"
      keyboard={false}>
      <Modal.Body className="p-4">
        <h5 className="ijp-applied-modal-title text-center mb-4">Submitted successfully!</h5>
        {!!bodyMessage && <p className="ijp-applied-modal-subtitle text-center">{bodyMessage}</p>}
        <div className="d-flex justify-content-center">
          <CustomButton
            onClick={() => {
              setIsSuccessDialogVisible(false);
              if (
                actionRouteRef.current === TRAVEL_REQUEST_ACTION_ROUTES.APPROVE ||
                actionRouteRef.current === TRAVEL_REQUEST_ACTION_ROUTES.MODIFICATION ||
                actionRouteRef.current === TRAVEL_REQUEST_ACTION_ROUTES.REJECT
              ) {
                saveTravelRequestTabData(TRAVEL_REQUEST_TAB_IDENTIFIERS.FOR_APPROVAL);
                history.push(NAVIGATION_ROUTES.TRAVEL_REQUEST);
              } else {
                saveTravelRequestTabData(TRAVEL_REQUEST_TAB_IDENTIFIERS.ALL_REQUESTS);
                history.push(NAVIGATION_ROUTES.TRAVEL_REQUEST, {
                  isClearForm: true,
                });
              }
            }}
            buttonStyle="common-custom-button-quaternary">
            OK
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TravelRequestSuccessDialog;
