import React from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { getUrlWithSpecificRendition } from '../../../utils/common';

const CommunitiesListItem = ({ item, onPress }) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  return (
    <div
      className="mb-3 col-6 col-sm-3 col-md-2 text-center common-cursor-pointer"
      onClick={() => onPress(item)}>
      <img
        src={getUrlWithSpecificRendition(
          item?.icon_image?.base_url,
          SCREEN_DENSITY_QUALIFIERS.LDPI,
          item?.icon_image?.image_path
        )}
        className="communities-md"
      />
      <div className="mt-1 communities-title-text text-center">{item.title}</div>
      <div className="d-flex align-items-center justify-content-center">
        {item.open_status === 0 && (
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_LOCKED,
              ACCESSIBILITY_IDENTIFIERS.COMMON
            )}
            className="communities-lock-icon mr-1"
          />
        )}
        {item?.total_members > 1 ? (
          <div className="mt-1 communities-member-text">{item?.total_members} Members</div>
        ) : item?.total_members === 1 ? (
          <div className="mt-1 communities-member-text">{item?.total_members} Member</div>
        ) : (
          <div className="mt-1 communities-member-text">0 Members</div>
        )}
      </div>
    </div>
  );
};

export default CommunitiesListItem;
