import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { getUrlWithSpecificRendition } from '../../utils/common';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import TaggedPeopleListItem from './components/TaggedPeopleListItem';

const MicrogiveGalleryTagsDetailScreen = ({ history, location }) => {
  const { baseUrl, picturePath, pictureId, taggedList, microgiveInitiativeDetailId } =
    location.state;
  const [tagsList] = useState(taggedList);

  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.MICRIGIVE_GALLERY_TAGS_DETAIL_SCREEN,
      NAVIGATION_ROUTES.MICRIGIVE_GALLERY_TAGS_DETAIL_SCREEN
    );
  }, []);

  const onPressTag = () => {
    history.push(NAVIGATION_ROUTES.TAG_PEOPLE_SCREEN, {
      parentRoute: NAVIGATION_ROUTES.MICRIGIVE_GALLERY_TAGS_DETAIL_SCREEN,
      tags: taggedList,
      picture_id: pictureId,
      microgiveInitiativeDetailId: microgiveInitiativeDetailId,
    });
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICROGIVE} />
      <BackButton />
      <div className="common-container-md mx-auto mt-5">
        <img
          src={getUrlWithSpecificRendition(baseUrl, SCREEN_DENSITY_QUALIFIERS.XXXHDPI, picturePath)}
          className="embed-responsive embed-responsive-16by9"
        />
        <div className="row mt-2 ml-1 justify-content-between mr-1">
          <div className="microgive-people-tag-text">{`${tagsList.length} people tagged in this Photo`}</div>
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_TAG_EMPLOYEE,
              ACCESSIBILITY_IDENTIFIERS.MICROGIVE
            )}
            className="microgive-normal-icon"
            onClick={onPressTag}
          />
        </div>
        {tagsList.length > 0 &&
          tagsList.map((item, index) => {
            return <TaggedPeopleListItem key={`${index}`} item={item} />;
          })}
      </div>
    </div>
  );
};

export default MicrogiveGalleryTagsDetailScreen;
