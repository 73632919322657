import CountriesDataList from '../common/constants/CountryCodes.json';
import PhoneUtil from 'google-libphonenumber';
import { SocialLinks } from 'social-links';

const socialLinks = new SocialLinks({ trimInput: false, allowQueryParams: true });

export const genericNameValidationRegex = () => {
  return '[a-zA-Z ]{1,50}';
};

export const emailValidationRegex = () => {
  return '[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}';
};

export const phoneWithCountryCodeValidationRegex = () => {
  return '[0-9]{11,13}';
};

export const isValidLinkedinSocialUrl = (url) => {
  return /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/g.test(url);
};

export const isValidFacebookSocialUrl = (url) => {
  return socialLinks.isValid('facebook', url);
};

export const isValidTwitterSocialUrl = (url) => {
  return socialLinks.isValid('twitter', url);
};

export const isValidInstagramSocialUrl = (url) => {
  return socialLinks.isValid('instagram', url);
};

export const isValidPhoneNumberForRegion = (number, dialCode) => {
  try {
    const phoneUtil = PhoneUtil.PhoneNumberUtil.getInstance();
    const phoneNum = number.length > 16 ? number.slice(0, 16) : number;
    const country = CountriesDataList.find((val) => {
      return val.dialCode === dialCode;
    });
    return phoneUtil.isValidNumberForRegion(
      phoneUtil.parse(phoneNum, country.isoCode),
      country.isoCode
    );
  } catch {
    return false;
  }
};

export const websiteValidationRegex = (url) => {
  var res = url.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );
  if (res == null) {
    return false;
  } else {
    return true;
  }
};
