import React from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { getUrlWithSpecificRendition } from '../../../utils/common';

const ProjectGalleryListItem = ({ item, onPress, baseUrl, onPressShare, onPressTag }) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  return (
    <div className="col-sm-4 mb-3">
      <div>
        <img
          src={getUrlWithSpecificRendition(
            baseUrl,
            SCREEN_DENSITY_QUALIFIERS.HDPI,
            item.picture_path
          )}
          className="breakout-gallery-image common-cursor-pointer"
          onClick={() => onPress(item)}
        />
        <div>
          <div className="row justify-content-between ml-1 mr-1 mt-2">
            <div>
              {item.tags.length > 0 ? (
                <div className="microgive-outer-view" onClick={() => onPress(item)}>
                  <div className="communities-image-first position-relative ">
                    {item.tags.length >= 1 && (
                      <UserProfilePhoto
                        imageBaseUrl={item.tags[0].profile_picture.base_url}
                        imagePath={item.tags[0].profile_picture.image_path}
                        imageClass="common-user-profile-photo-xs"
                      />
                    )}
                  </div>
                  <div className="communities-image-second position-relative ">
                    {item.tags.length >= 2 && (
                      <UserProfilePhoto
                        imageBaseUrl={item.tags[1].profile_picture.base_url}
                        imagePath={item.tags[1].profile_picture.image_path}
                        imageClass="common-user-profile-photo-xs microgive-profile-image-small-overlay"
                      />
                    )}
                  </div>
                  {item.tags.length >= 3 && (
                    <UserProfilePhoto
                      imageBaseUrl={item.tags[2].profile_picture.base_url}
                      imagePath={item.tags[2].profile_picture.image_path}
                      imageClass="common-user-profile-photo-xs microgive-profile-image-small-overlay"
                    />
                  )}
                  {item.tags.length > 3 && (
                    <div className="communities-tag-count-text ml-2">{`+${
                      item.tags.length - 3
                    }`}</div>
                  )}
                  <div className="ml-2">Tagged</div>
                </div>
              ) : (
                <div />
              )}
            </div>
            <div className="microgive-tag-share-view row">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_TAG_EMPLOYEE,
                  ACCESSIBILITY_IDENTIFIERS.MICROGIVE
                )}
                className="microgive-share-image common-cursor-pointer"
                onClick={() => onPressTag(item)}></img>
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_SHARE_IMAGE,
                  ACCESSIBILITY_IDENTIFIERS.MICROGIVE
                )}
                className="microgive-share-image common-cursor-pointer"
                onClick={() => onPressShare(item)}></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectGalleryListItem;
