import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
} from '../../../common/constants/AppConstants';
import { selectPodcastConfigData } from '../../../state/MasterData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import HtmlView from '../../../common/ui/html_view/HTMLView';

const PodcastAboutTab = () => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const podcastConfigData = useSelector(selectPodcastConfigData);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.PODCAST_ABOUT_TAB_VISIT, '', ANALYTICS_ITEM_NAMES.PODCASTS);
  }, []);

  return (
    <div className="main-content-container">
      <div>
        <img
          src={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.PODCASTS_BANNER,
            ACCESSIBILITY_IDENTIFIERS.PODCASTS
          )}
          className="w-100"
        />
        <HtmlView html={podcastConfigData.about_html} htmlStyle="mt-3" />
      </div>
    </div>
  );
};

export default PodcastAboutTab;
