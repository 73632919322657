import React, { useEffect } from 'react';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  SCREEN_TITLE,
} from '../../../common/constants/AppConstants';
import { useHistory } from 'react-router';
import { logEvent, trackScreen } from '../../../utils/FirebaseAnalyticsUtils';

const StoreAccountTab = () => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const history = useHistory();

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.MICRO_STORE_HOME_TAB,
      NAVIGATION_ROUTES.MICRO_STORE_MAIN_SCREEN
    );
  }, []);

  const handleReturnPolicy = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ESTORE_RETURN_POLICY_CLICK,
      5,
      ANALYTICS_ITEM_NAMES.RETURN_POLICY
    );
    history.push(`${NAVIGATION_ROUTES.MICRO_STORE_POLICY_SCREEN}/5`);
  };

  const handlePrivacyPolicy = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ESTORE_PRIVICY_POLICY_CLICK,
      8,
      ANALYTICS_ITEM_NAMES.PRIVICY_POLICY
    );
    history.push(`${NAVIGATION_ROUTES.MICRO_STORE_POLICY_SCREEN}/8`);
  };

  const handleFAQ = () => {
    logEvent(ANALYTICS_EVENT_TYPES.ESTORE_FAQ_CLICK, 7, ANALYTICS_ITEM_NAMES.FAQ);
    history.push(`${NAVIGATION_ROUTES.MICRO_STORE_POLICY_SCREEN}/7`);
  };

  const handleTermsAndConditions = () => {
    logEvent(ANALYTICS_EVENT_TYPES.ESTORE_TC_CLICK, 6, ANALYTICS_ITEM_NAMES.TERMS_AND_CONDITIONS);
    history.push(`${NAVIGATION_ROUTES.MICRO_STORE_POLICY_SCREEN}/6`);
  };

  const handleAddresses = () => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ESTORE_ADDRESS_LIST_CLICK,
      '',
      ANALYTICS_ITEM_NAMES.ADDRESS_LIST
    );
    history.push(NAVIGATION_ROUTES.MI_STORE_ADDRESS_LIST_SCREEN);
  };

  const handleOrderHistory = () => {
    logEvent(ANALYTICS_EVENT_TYPES.ESTORE_ORDERS_LIST_CLICK, '', ANALYTICS_ITEM_NAMES.ORDERS_LIST);
    history.push(NAVIGATION_ROUTES.MI_STORE_ORDER_LIST_SCREEN);
  };

  return (
    <div className="mt-2 ml-2">
      <div className="estore-accounts-tab-item" onClick={handleOrderHistory}>
        <div className="estore-accounts-tab-icon-text">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.ORDER_HISTORY_CART,
              ACCESSIBILITY_IDENTIFIERS.MICRO_STORE
            )}
            className="estore-account-item-icon"
          />
          <div className="ml-3">
            <div className="estore-account-item-title">Order History</div>
            <div className="estore-account-item-description">View your past orders</div>
          </div>
        </div>
        <img
          src={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_RIGHT_ARROW,
            ACCESSIBILITY_IDENTIFIERS.COMMON
          )}
          className="estore-account-item-icon"
        />
      </div>
      <div className="estore-accounts-tab-item" onClick={handleAddresses}>
        <div className="estore-accounts-tab-icon-text">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.ADDRESS_BOOK_LOCATION,
              ACCESSIBILITY_IDENTIFIERS.MICRO_STORE
            )}
            className="estore-account-item-icon"
          />
          <div className="ml-3">
            <div className="estore-account-item-title">Address Book</div>
            <div className="estore-account-item-description">Manage your shipping addresses</div>
          </div>
        </div>
        <img
          src={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_RIGHT_ARROW,
            ACCESSIBILITY_IDENTIFIERS.COMMON
          )}
          className="estore-account-item-icon"
        />
      </div>
      <div className="estore-account-links-block">
        <div onClick={handleReturnPolicy} className="estore-account-link-item">
          {SCREEN_TITLE.RETURN_POLICY}
        </div>
        <div onClick={handleTermsAndConditions} className="estore-account-link-item">
          {SCREEN_TITLE.MI_STORE_TC}
        </div>
        <div onClick={handleFAQ} className="estore-account-link-item">
          {SCREEN_TITLE.MI_STORE_FAQ}
        </div>
        <div onClick={handlePrivacyPolicy} className="estore-account-link-item">
          {SCREEN_TITLE.PRIVACY_POLICY}
        </div>
      </div>
    </div>
  );
};

export default StoreAccountTab;
