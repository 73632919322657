import { createAction, createSlice } from '@reduxjs/toolkit';

const signOutAction = createAction('signout');

const saveCommentCountDataReducer = (state, action) => {
  state.commentCount = action.payload;
};

// REDUCER
const commentDataSlice = createSlice({
  name: 'commentData',
  initialState: {
    commentCount: 0,
  },
  extraReducers: {
    [signOutAction]: (state) => {
      return {
        ...state,
        commentCount: 0,
      };
    },
  },
  reducers: {
    saveCommentCountData: saveCommentCountDataReducer,
  },
});

// ACTIONS
const { saveCommentCountData } = commentDataSlice.actions;

// SELECTOR
const selectCommentCountData = ({ commentData }) => commentData.commentCount;

const commentDataSliceReducer = commentDataSlice.reducer;

export { commentDataSliceReducer, saveCommentCountData, selectCommentCountData };
