import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../../common/constants/AppConstants';
import ActivityLoader from '../../../../common/ui/activity_loader/ActivityLoader';
import DefaultContentView from '../../../../common/ui/default_content_view/DefaultContentView';
import { ML_33_CONTESTS } from '../../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../../services/Service';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { saveShowEmptyContentView, selectShowEmptyContentView } from '../../../../state/UIState';
import { getFeatureComponentUrl } from '../../../../utils/accessibility';
import { formatDateDDMonthYYYY, getUrlWithSpecificRendition } from '../../../../utils/common';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import PlayIconOverlay from '../../../../common/ui/play_icon_overlay/PlayIconOverlay';

const ContestListTab = (identifier) => {
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const [contests, setContests] = useState([]);
  const [loading, setLoading] = useState(true);
  const accessibilityData = useSelector(selectAccessibilityData);
  const history = useHistory();
  const [error, setError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ANNIVERSARY_CONTEST_TAB_VISIT,
      identifier,
      ANALYTICS_ITEM_NAMES.ANNIVERSARY
    );
  }, [identifier]);

  const callContestApi = useCallback(async () => {
    setError(false);
    setLoading(true);
    dispatch(saveShowEmptyContentView(true));
    try {
      const apiResponse = await apiRequest(
        identifier.identifier + ML_33_CONTESTS,
        REQUEST_TYPE.GET
      );

      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data.events;
            if (data.length > 0) {
              setContests(data);
            }
          }
        }
      }

      setLoading(false);
    } catch (err) {
      handleError(
        err,
        {},
        identifier.identifier + ML_33_CONTESTS,
        NAVIGATION_ROUTES.ANNIVERSARY_DAY_SCREEN
      );
      setLoading(false);
      setError(true);
    }
  }, [dispatch]);

  const renderScreen = useCallback(async () => {
    callContestApi();
  }, [callContestApi]);

  useEffect(() => {
    renderScreen();
  }, []);

  const emptyListView = () => {
    return <DefaultContentView message={NO_DATA_TEXT.NO_DATA_FOUND} />;
  };

  const onPressItem = (item) => {
    history.push(`${NAVIGATION_ROUTES.CONTESTS}/${item.contest_id}`);
  };

  const contestArray =
    contests &&
    contests.map((item, index) => {
      return (
        <div
          key={`${index}`}
          className="col-sm-4 mb-2 common-cursor-pointer"
          onClick={() => onPressItem(item)}>
          <div>
            {item?.content_load_type === '3' && <PlayIconOverlay />}
            <img
              src={getUrlWithSpecificRendition(
                item.promo_image.base_url,
                SCREEN_DENSITY_QUALIFIERS.HDPI,
                item.promo_image.image_path
              )}
              className="embed-responsive embed-responsive-16by9"
            />
            <div className="row ml-1 mt-1">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.NEWS_CALENDER,
                  ACCESSIBILITY_IDENTIFIERS.NEWS
                )}
                className="contests-calender-style"
              />
              <div className="contests-event-date">
                {formatDateDDMonthYYYY(item.start_date + ' ' + item.start_time)}
              </div>
            </div>
          </div>
          <div className="contests-title">{item.title}</div>
          <div className="contests-summary">{item.summary}</div>
        </div>
      );
    });

  return (
    <div className="main-content-container">
      {showEmptyContentView && !contestArray.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          <div className="row">{contestArray}</div>
          <ActivityLoader visible={loading} />
        </div>
      )}
    </div>
  );
};

export default ContestListTab;
