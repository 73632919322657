import React, { useCallback, useEffect, useState } from 'react';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../../../services/Service';
import { GET_PARTICIPANTS } from '../../../../services/ApiUrls';
import ParticipatesListItem from '../../components/ParticipatesListItem';
import LoadMoreBtn from '../../../../common/ui/load_more_btn/LoadMoreBtn';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../../../state/UIState';
import DefaultContentView from '../../../../common/ui/default_content_view/DefaultContentView';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';

const ProjectParticipantsTab = ({ microgiveInitiativeDetailId }) => {
  const dispatch = useDispatch();
  const [volunteerList, setVolunteerList] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const [participantCount, setParticipantCount] = useState(0);

  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [noMatchFlag, setNoMatchFlag] = useState(false);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.PROJECTS_PARTICIPANTS_TAB_VISIT,
      microgiveInitiativeDetailId,
      ANALYTICS_ITEM_NAMES.MICROGIVE
    );
  }, [microgiveInitiativeDetailId]);

  const callVolunteerLogApi = useCallback(
    async (id, loadMore) => {
      dispatch(saveShowEmptyContentView(true));
      setNoMatchFlag(false);
      if (loadMore) {
        const params = {
          page_id: id,
        };
        try {
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          const apiResponse = await apiRequest(
            GET_PARTICIPANTS + microgiveInitiativeDetailId + '/participants',
            REQUEST_TYPE.GET,
            params
          );
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.participants.length > 0) {
                setVolunteerList((oldArray) => [
                  ...oldArray,
                  ...apiResponse.response.data.participants,
                ]);
                setParticipantCount(apiResponse?.response?.data?.participant_count);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
              } else {
                if (pageId === 1) {
                  setNoMatchFlag(true);
                }
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(
            err,
            params,
            GET_PARTICIPANTS + microgiveInitiativeDetailId + '/participants',
            NAVIGATION_ROUTES.PROJECT_TABS_SCREEN
          );
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      }
    },
    [dispatch, microgiveInitiativeDetailId]
  );

  useEffect(() => {
    callVolunteerLogApi(1, true);
  }, [callVolunteerLogApi]);

  const onPressLoadMore = () => {
    callVolunteerLogApi(pageId, loadMoreFlag);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.SEARCH_NO_RESULTS} />;
  };

  return (
    <div className="main-content-container">
      {pageId === 1 && noMatchFlag ? (
        emptyListView()
      ) : (
        <div>
          {volunteerList.length > 0 && (
            <div className="microgive-total-donors-text">
              {volunteerList.length > 0 && participantCount === 1
                ? `${participantCount} Participant`
                : `${participantCount} Participants`}
            </div>
          )}
          {volunteerList.map((item, index) => {
            return <ParticipatesListItem key={`${index}`} item={item} />;
          })}
          {loadMoreFlag && (
            <LoadMoreBtn
              onClick={onPressLoadMore}
              isLoading={progressLoadingState.isProgressLoading}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default ProjectParticipantsTab;
