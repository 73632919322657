import React from 'react';
import { getUrlWithSpecificRendition } from '../../../utils/common';
import { SCREEN_DENSITY_QUALIFIERS } from '../../../common/constants/AppConstants';

const ContestsListItem = ({ item, onPress, identifier }) => {
  return (
    <div className="col-sm-4 common-cursor-pointer" onClick={() => onPress(item)}>
      <div className="anniversary-view-style">
        <img
          src={getUrlWithSpecificRendition(
            item.promo_image.base_url + `images_${identifier.split('_')[2]}/images/`,
            SCREEN_DENSITY_QUALIFIERS.HDPI,
            item.promo_image.image_path
          )}
          className="embed-responsive embed-responsive-16by9"
        />
        <div className="anniversary-title">{item.title}</div>
        <div className="anniversary-short-description">Participate now</div>
      </div>
    </div>
  );
};

export default ContestsListItem;
