import { createSlice } from '@reduxjs/toolkit';

const saveDefaultAddressReducer = (state, action) => {
  state.defaultAddress = action.payload;
};

const saveTempAddressReducer = (state, action) => {
  state.tempAddress = action.payload;
};

const saveDeliveryEmailReducer = (state, action) => {
  state.deliveryEmail = action.payload;
};

const saveCustomerTokenReducer = (state, action) => {
  state.customerToken = action.payload;
};

const saveSessionStartTimeReducer = (state, action) => {
  state.sessionStartTime = action.payload;
};

const saveCartItemsCountReducer = (state, action) => {
  state.cartItemsCount = action.payload;
};

const saveSegmentsReducer = (state, action) => {
  state.segments = action.payload;
};

const saveSelectedColorReducer = (state, action) => {
  state.selectedColor = action.payload;
};

const saveSelectedSizeReducer = (state, action) => {
  state.selectedSize = action.payload;
};

const saveSelectedItemSkuReducer = (state, action) => {
  state.selectedItemSku = action.payload;
};

const saveShowUnAuthorisedReducer = (state, action) => {
  state.showUnAuthorised = action.payload;
};

const saveStoreAddressReducer = (state, action) => {
  state.storeAddress = action.payload;
};

const savePickupFromStoreReducer = (state, action) => {
  state.pickupFromStore = action.payload;
};

const saveShowGeoWarningReducer = (state, action) => {
  state.showGeoWarning = action.payload;
};

// REDUCER
const storeDataSlice = createSlice({
  name: 'storeData',
  initialState: {
    defaultAddress: {},
    tempAddress: {},
    deliveryEmail: '',
    customerToken: '',
    sessionStartTime: '',
    cartItemsCount: 0,
    segments: [],
    selectedColor: {},
    selectedSize: {},
    selectedItemSku: '',
    showUnAuthorised: false,
    storeAddress: {},
    pickupFromStore: false,
    showGeoWarning: true,
  },
  reducers: {
    saveDefaultAddress: saveDefaultAddressReducer,
    saveTempAddress: saveTempAddressReducer,
    saveDeliveryEmail: saveDeliveryEmailReducer,
    saveCustomerToken: saveCustomerTokenReducer,
    saveSessionStartTime: saveSessionStartTimeReducer,
    saveCartItemsCount: saveCartItemsCountReducer,
    saveSegments: saveSegmentsReducer,
    saveSelectedColor: saveSelectedColorReducer,
    saveSelectedSize: saveSelectedSizeReducer,
    saveSelectedItemSku: saveSelectedItemSkuReducer,
    saveShowUnAuthorised: saveShowUnAuthorisedReducer,
    saveStoreAddress: saveStoreAddressReducer,
    savePickupFromStore: savePickupFromStoreReducer,
    saveShowGeoWarning: saveShowGeoWarningReducer,
  },
});

// ACTIONS
const {
  saveDefaultAddress,
  saveTempAddress,
  saveDeliveryEmail,
  saveCustomerToken,
  saveSessionStartTime,
  saveCartItemsCount,
  saveSegments,
  saveSelectedColor,
  saveSelectedSize,
  saveSelectedItemSku,
  saveShowUnAuthorised,
  saveStoreAddress,
  savePickupFromStore,
  saveShowGeoWarning,
} = storeDataSlice.actions;

// SELECTOR
const selectDefaultAddress = ({ storeData }) => storeData.defaultAddress;
const selectTempAddress = ({ storeData }) => storeData.tempAddress;
const selectDeliveryEmail = ({ storeData }) => storeData.deliveryEmail;
const selectCustomerToken = ({ storeData }) => storeData.customerToken;
const selectSessionStartTime = ({ storeData }) => storeData.sessionStartTime;
const selectCartItemsCount = ({ storeData }) => storeData.cartItemsCount;
const selectSegments = ({ storeData }) => storeData.segments;
const selectSelectedColor = ({ storeData }) => storeData.selectedColor;
const selectSelectedSize = ({ storeData }) => storeData.selectedSize;
const selectSelectedItemSku = ({ storeData }) => storeData.selectedItemSku;
const selectShowUnAuthorised = ({ storeData }) => storeData.showUnAuthorised;
const selectStoreAddress = ({ storeData }) => storeData.storeAddress;
const selectPickupFromStore = ({ storeData }) => storeData.pickupFromStore;
const selectShowGeoWarning = ({ storeData }) => storeData.showGeoWarning;

const storeDataSliceReducer = storeDataSlice.reducer;

export {
  storeDataSliceReducer,
  saveDefaultAddress,
  saveTempAddress,
  saveDeliveryEmail,
  saveCustomerToken,
  saveSessionStartTime,
  saveCartItemsCount,
  saveSegments,
  saveSelectedColor,
  saveSelectedSize,
  saveSelectedItemSku,
  saveShowUnAuthorised,
  saveStoreAddress,
  savePickupFromStore,
  saveShowGeoWarning,
  selectDefaultAddress,
  selectTempAddress,
  selectDeliveryEmail,
  selectCustomerToken,
  selectSessionStartTime,
  selectCartItemsCount,
  selectSegments,
  selectSelectedColor,
  selectSelectedSize,
  selectSelectedItemSku,
  selectShowUnAuthorised,
  selectStoreAddress,
  selectPickupFromStore,
  selectShowGeoWarning,
};
