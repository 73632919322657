import React, { useCallback, useEffect, useState, useRef } from 'react';
import AnswerView from './components/AnswerView';
import { isToday, formatTimeAMPM, formatDateDDMonthYYYY } from '../../utils/common';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  FEATURE_TYPE,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../services/Service';
import { CEO_CHAT_ID, COMMENT_BY_ID, EMPLOYEES_BY_ID } from '../../services/ApiUrls';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../state/UIState';
import { useDispatch, useSelector } from 'react-redux';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import UserProfilePhoto from '../../common/ui/user_profile_photo/UserProfilePhoto';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import HtmlView from '../../common/ui/html_view/HTMLView';
import BackButton from '../../common/ui/common_back_button/BackButton';

const CeoChatCommentScreen = ({ location, history }) => {
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const accessibilityData = useSelector(selectAccessibilityData);
  const dispatch = useDispatch();
  const { questionId } = location.state;
  const [question, setQuestion] = useState();
  const [comments, setComments] = useState([]);
  const pageId = useRef(1);
  const loadMoreFlag = useRef(true);
  const [employeeDetails, setEmployeeDetails] = useState();

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.CEO_CHAT_COMMENT, NAVIGATION_ROUTES.CEO_CHAT_COMMENT);
  }, []);

  const callComments = useCallback(
    async (questionId) => {
      dispatch(saveShowEmptyContentView(true));
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      if (loadMoreFlag.current) {
        const get_params = {
          type: FEATURE_TYPE.CEO_CHAT,
          page_id: pageId.current,
        };
        try {
          const commentApiResponse = await apiRequest(
            COMMENT_BY_ID + questionId,
            REQUEST_TYPE.GET,
            get_params
          );
          if (Object.keys(commentApiResponse).length > 0) {
            if (commentApiResponse.response.status === true) {
              if (commentApiResponse.response.data.feeds) {
                const commentsData = commentApiResponse.response.data.feeds;
                if (commentsData.length > 0) {
                  if (pageId.current === 1) {
                    setComments([]);
                    setComments(commentsData);
                  } else {
                    setComments((prev) => [...prev, ...commentsData]);
                  }
                  pageId.current = pageId.current + 1;
                  loadMoreFlag.current = true;
                } else {
                  loadMoreFlag.current = false;
                }
              }
            }
          }
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        } catch (err) {
          handleError(
            err,
            get_params,
            COMMENT_BY_ID + questionId,
            NAVIGATION_ROUTES.CEO_CHAT_COMMENT
          );
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      }
    },
    [dispatch]
  );

  const callEmployeeById = useCallback(
    async (employeeId) => {
      try {
        const apiResponse = await apiRequest(EMPLOYEES_BY_ID + employeeId, REQUEST_TYPE.GET, {});
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            setEmployeeDetails(apiResponse.response.data.employee);
          }
        }
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      } catch (err) {
        handleError(err, {}, EMPLOYEES_BY_ID + employeeId, NAVIGATION_ROUTES.CEO_CHAT_COMMENT);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    },
    [dispatch]
  );

  const callQuestionApi = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequest(
        CEO_CHAT_ID + 'question/' + questionId,
        REQUEST_TYPE.GET,
        {}
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.question) {
            const data = apiResponse.response.data.question;
            if (data) {
              setQuestion(data);
            }
            if (data.employee.employee_id) {
              callEmployeeById(data.employee.employee_id);
            }
            callComments(questionId);
          }
        }
      }
    } catch (err) {
      handleError(
        err,
        {},
        CEO_CHAT_ID + 'question/' + questionId,
        NAVIGATION_ROUTES.CEO_CHAT_COMMENT
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [callComments, callEmployeeById, dispatch, questionId]);

  const renderScreen = useCallback(async () => {
    if (questionId) {
      callQuestionApi(questionId);
    }
  }, [callQuestionApi, questionId]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const handleHtmlClicks = (e) => {
    const target = e.target.getAttribute('href');
    if (target && target.startsWith('/')) {
      e.stopPropagation();
      e.preventDefault();
      if (target.includes(NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL)) {
        logEvent(
          ANALYTICS_EVENT_TYPES.USER_MENTION_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.USER_MENTION
        );
        history.push(target);
      } else {
        logEvent(
          ANALYTICS_EVENT_TYPES.HASHTAG_CLICK,
          e.target.innerText,
          ANALYTICS_ITEM_NAMES.HASHTAG
        );
        history.push(target);
      }
    }
  };

  const renderItem = (item) => {
    return (
      <div className="ceo-chat-item-row">
        <div>
          <UserProfilePhoto
            imageBaseUrl={
              item.employee.profile_picture ? item.employee.profile_picture.base_url : ''
            }
            imagePath={
              item.employee.profile_picture ? item.employee.profile_picture.image_path : ''
            }
            employeeId={item.employee.employeeId}
            imageClass="common-user-profile-photo-sm"
          />
        </div>

        <div className="ceo-chat-employee-details">
          <div className="ceo-chat-first-row">
            <div className="d-flex justify-content-between align-items-center">
              <p className="ceo-chat-question-emp-name-text mb-0">
                {item.employee.first_name +
                  ' ' +
                  item.employee.middle_name +
                  ' ' +
                  item.employee.last_name}
              </p>
              {!!item.employee.pronouns && (
                <p className="ceo-chat-question-emp-pronoun-text mb-0 ml-1">
                  {'(' + item.employee.pronouns + ')'}
                </p>
              )}
            </div>
            <div>
              <div className="ceo-chat-ceo-comment-date">
                {isToday(item.comment_on)
                  ? formatTimeAMPM(item.comment_on)
                  : formatDateDDMonthYYYY(item.comment_on)}
              </div>
            </div>
          </div>
          <div className="ceo-chat-comment-row">
            <HtmlView
              onClick={handleHtmlClicks}
              html={item.comment.replace(/\n/g, '<br>')}
              htmlStyle="ceo-chat-ceo-comment-text"
            />
          </div>
        </div>
      </div>
    );
  };

  const showEmptyListView = () => {
    return (
      <DefaultContentView
        message={NO_DATA_TEXT.NO_COMMENTS_FOUND}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CEO_CHAT} />
      <BackButton />
      <div className="main-content-container">
        {question && employeeDetails && (
          <div className="ceo-chat-question-head">
            <AnswerView
              width={50}
              height={50}
              employeeId={employeeDetails.employee_id}
              baseUrl={employeeDetails.profile_picture.base_url}
              imagePath={employeeDetails.profile_picture.image_path}
              employeeName={
                employeeDetails.first_name +
                ' ' +
                employeeDetails.middle_name +
                ' ' +
                employeeDetails.last_name +
                ' '
              }
              askedOn={question.asked_on}
              question={question.question}
              pronouns={employeeDetails.pronouns}
            />
            <div className="ceo-chat-ceo-border" />
            <div className="ceo-chat-reply-view">
              <div className="ceo-chat-reply-text">Comments</div>
              <CustomButton
                buttonStyle="ceo-chat-reply-button common-custom-button-secondary"
                onClick={() => {
                  history.push(NAVIGATION_ROUTES.CEO_ADD_COMMENT, {
                    question: question,
                  });
                }}>
                Add comment
              </CustomButton>
            </div>
          </div>
        )}
        {showEmptyContentView &&
        comments &&
        !comments.length &&
        !progressLoadingState.isProgressLoading ? (
          showEmptyListView()
        ) : (
          <div>
            {comments &&
              comments.map((item) => {
                return <div key={item.comment_id}>{renderItem(item)}</div>;
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CeoChatCommentScreen;
