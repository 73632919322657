import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import CustomButton from '../../../../common/ui/custom_button/CustomButton';
import Divider from '../../../../common/ui/divider/Divider';
import UserProfilePhoto from '../../../../common/ui/user_profile_photo/UserProfilePhoto';
import { GET_SUMMARY } from '../../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../../services/Service';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { selectEmployeeData } from '../../../../state/EmployeeData';
import { selectConfigurations, selectWebBaseUrl } from '../../../../state/MasterData';
import { saveProgressLoadingState } from '../../../../state/UIState';
import { getFeatureComponentUrl } from '../../../../utils/accessibility';
import {
  formatTimeHrsMinsVolunteer,
  shortYearDate,
  getSecuredPdfUrl,
} from '../../../../utils/common';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import EightygCertificateLogListItem from '../../components/80gCertificateLogListItem';

const MicrogiverTab = ({ tabKey }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [feeds, setFeeds] = useState({});
  const [feedList, setFeedList] = useState([]);
  const webUrl = useSelector(selectWebBaseUrl);
  const accessibilityData = useSelector(selectAccessibilityData);
  const employeeData = useSelector(selectEmployeeData);
  const configurationsData = useSelector(selectConfigurations);
  const external_url = configurationsData?._microgive_config?._microgiver_external_url ?? '';
  const downloadImagePath = getFeatureComponentUrl(
    accessibilityData,
    ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_DOWNLOAD_ICON_RED,
    ACCESSIBILITY_IDENTIFIERS.COMMON
  );

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.MICROGIVER_TAB_VISIT, tabKey, ANALYTICS_ITEM_NAMES.MICROGIVE);
  }, [tabKey]);

  const callCurrentSummaryApi = useCallback(async () => {
    const params = {
      current: 1,
    };
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(GET_SUMMARY, REQUEST_TYPE.GET, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setFeeds(apiResponse.response.data.feeds);
          setFeedList(apiResponse.response.data.feeds.eightyg);
        }
      }
    } catch (err) {
      handleError(err, params, GET_SUMMARY, NAVIGATION_ROUTES.MICRO_GIVE);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch]);

  useEffect(() => {
    callCurrentSummaryApi();
  }, [callCurrentSummaryApi]);

  const onPressVolunteer = () => {
    history.push(NAVIGATION_ROUTES.VOLUNTEERING_LOG_SCREEN);
  };

  const onPressMyDonations = () => {
    history.push(NAVIGATION_ROUTES.MY_DONATIONS_SCREEN);
  };

  const onPressClickHere = () => {
    history.push(NAVIGATION_ROUTES.QUERY_SCREEN);
  };

  const onPressClickHereExt = () => {
    window.open(external_url, '_blank', 'noopener,noreferrer');
  };
  const onPressFaq = () => {
    window.open(getSecuredPdfUrl(webUrl, feeds?.faq?.encoded_url), '_blank');
  };
  return (
    <div className="main-content-container">
      <div>
        {!!Object.keys(employeeData).length && (
          <div className="d-flex flex-row justify-content-between w-100">
            <div className="microgive-outer-view">
              <UserProfilePhoto
                imageBaseUrl={employeeData.profile_picture.base_url}
                imagePath={employeeData.profile_picture.image_path}
                imageClass="common-user-profile-photo-lg"
                employeeId={employeeData.employee_id}
              />
              <div className="ml-3">
                <div>
                  <div className="mb-1 microgive-user-name">{`${employeeData.first_name} ${employeeData.last_name}`}</div>
                  <div className="mt-1 microgive-user-position">{`${employeeData.position_title}`}</div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-end align-self-end mb-n3 pb-1">
              <div className="microgive-btn-view">
                <CustomButton
                  buttonStyle="microgive-btn-volunteer-log mr-3"
                  onClick={onPressVolunteer}>
                  Volunteer Log
                </CustomButton>
                <CustomButton
                  buttonStyle="microgive-btn-volunteer-log ml-3"
                  onClick={onPressMyDonations}>
                  My Donations
                </CustomButton>
              </div>
            </div>
          </div>
        )}
        <Divider style="mt-2" />
        <div className="microgive-fy-style">
          {Object.keys(feeds).length > 0 &&
            'FY ' +
              shortYearDate(feeds.financial_year.from) +
              ' -' +
              shortYearDate(feeds.financial_year.to)}
        </div>
        <div className="row pl-3">
          <div>
            <div className="microgive-volunteer-hour">
              {Object.keys(feeds).length > 0 &&
                formatTimeHrsMinsVolunteer(
                  feeds.volunteer.volunteer_hours.total_hours,
                  feeds.volunteer.volunteer_hours.total_minutes
                )}
            </div>
            <div className="microgive-volunteer-hr-text">My Volunteering hours</div>
          </div>
          <div className="ml-3">
            <div className="microgive-microgive-donation-count">
              {Object.keys(feeds).length > 0 && feeds.donation.donation_count}
            </div>
            <div className="microgive-no-time-donated">No. of times donated</div>
          </div>
        </div>
        <Divider style="mt-2" />
        <div className="microgive-outer-view mt-2">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_SHARE_COMMENT,
              ACCESSIBILITY_IDENTIFIERS.MICROGIVE
            )}
            className="microgive-share-faq-icon mr-2"
          />
          <div
            className="microgive-text-underline microgive-click-here-text common-cursor-pointer"
            onClick={() => onPressClickHere()}>
            Click here
          </div>
          <div className="ml-1 microgive-click-here-text">
            to share Comments/Recommendations/Queries on our CSR initiatives
          </div>
        </div>
        <div className="microgive-outer-view mt-2">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_FAQ,
              ACCESSIBILITY_IDENTIFIERS.MICROGIVE
            )}
            className="microgive-share-faq-icon mr-2"
          />
          <div className="microgive-click-here-text">Refer to our</div>
          <div
            className="microgive-text-underline ml-1 mr-1 microgive-click-here-text common-cursor-pointer"
            onClick={() => onPressFaq()}>
            FAQs
          </div>
          <div className="microgive-click-here-text">for clarification.</div>
        </div>
        {external_url && (
          <div className="microgive-outer-view mt-2 d-flex align-items-start">
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_SHARE_COMMENT,
                ACCESSIBILITY_IDENTIFIERS.MICROGIVE
              )}
              className="microgive-share-faq-icon mr-2"
            />
            <span className="microgive-click-here-text">
              <span
                className="microgive-text-underline common-cursor-pointer text-justify mr-1"
                onClick={() => onPressClickHereExt()}>
                Click here
              </span>
              to share details of an external opportunity for a donation/volunteering activity. The
              concerned team will get in touch with you.
            </span>
          </div>
        )}
        {feedList.length > 0 && <Divider style="mt-4" />}
      </div>

      {feedList.length > 0 && (
        <div className="mt-4 mb-5">
          <div className="microgiver-certificate-text-style pb-3">80G Certificates</div>
          <div className="mt-4">
            {feedList?.map((item, index) => {
              return (
                <EightygCertificateLogListItem
                  key={`${index}`}
                  item={item}
                  index={index}
                  downloadImagePath={downloadImagePath}
                  isFirstItem={feedList.length === 1}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default MicrogiverTab;
