import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import ActivityLoader from '../../../../common/ui/activity_loader/ActivityLoader';
import DefaultContentView from '../../../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../../../common/ui/load_more_btn/LoadMoreBtn';
import { apiRequest } from '../../../../services/Service';
import { saveShowEmptyContentView, selectShowEmptyContentView } from '../../../../state/UIState';
import { showToast } from '../../../../utils/common';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import ContestsDetailListItem from '../../components/ContestsDetailListItem';

const MakeDigitalHappenTab = ({ identifier }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [donorsList, setDonorsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.MAKE_DIGITAL_HAPPEN_TAB_VISIT,
      '2',
      ANALYTICS_ITEM_NAMES.CONTESTS_DETAIL
    );
  }, []);

  const callDonationDetailApi = useCallback(
    async (id, loadMore) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        try {
          setLoading(true);
          const params = {
            page_id: id,
          };
          const apiResponse = await apiRequest(
            identifier + '/contest/' + '2' + '/challenges',
            REQUEST_TYPE.GET,
            params
          );
          setLoading(false);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.challenges.length > 0) {
                setDonorsList((oldArray) => [...oldArray, ...apiResponse.response.data.challenges]);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          setLoading(false);
        }
      }
    },
    [dispatch, identifier]
  );

  useEffect(() => {
    callDonationDetailApi(1, true);
  }, [callDonationDetailApi]);

  const onPressTopView = (item) => {
    if (item.completed === false && item.enable_post === false) {
      history.push(NAVIGATION_ROUTES.ADD_CONTESTS_SCREEN, {
        title: 'Making Digital Happen',
        item: item,
        identifier: identifier,
      });
    } else {
      showToast(MESSAGES.ALREADY_SUBMITTED);
    }
  };

  const onPressBottomView = (item) => {
    if (item.is_active === true && item.enable_post === true) {
      history.push(NAVIGATION_ROUTES.ADD_CONTESTS_SCREEN, {
        title: 'Making Digital Happen',
        item: item,
        identifier: identifier,
      });
    }
  };

  const onPressLoadMore = () => {
    callDonationDetailApi(pageId, loadMoreFlag);
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.STAY_TUNED} />;
  };

  return (
    <div className="main-content-container">
      {showEmptyContentView && !donorsList.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          {donorsList.map((item, index) => {
            return (
              <ContestsDetailListItem
                item={item}
                onPressTopView={onPressTopView}
                onPressBottomView={onPressBottomView}
                key={`${index}`}
              />
            );
          })}
          <ActivityLoader visible={loading} />
          {loadMoreFlag && <LoadMoreBtn onClick={onPressLoadMore} isLoading={loading} />}
        </div>
      )}
    </div>
  );
};

export default MakeDigitalHappenTab;
