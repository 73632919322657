import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsFullScreenLoaderLoading } from '../../../state/UIState';
import ActivityLoader from '../activity_loader/ActivityLoader';
import companyLogo from '../../../assets/images/companyLogo.svg';
// import { NAVIGATION_ROUTES } from '../../constants/AppConstants';

const FullScreenLoader = () => {
  const isFullScreenLoaderLoading = useSelector(selectIsFullScreenLoaderLoading);
  // const isPublicProfile = window.location.href.includes(NAVIGATION_ROUTES.PUBLIC_PROFILE);

  if (isFullScreenLoaderLoading) {
    return (
      <div className="common-full-screen-loader-container">
        <div className="d-flex align-items-center">
          <div className="d-flex flex-column">
            {/* {!isPublicProfile && ( */}
            <img src={companyLogo} className="common-full-screen-loader-logo" />
            {/* )} */}
            <ActivityLoader visible={true} />
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default FullScreenLoader;
