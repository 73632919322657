import React, { useEffect, useState } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { SAVE_MENU_FAVORITES } from '../../../../services/ApiUrls';
import { apiRequest } from '../../../../services/Service';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { saveMenuData, selectMenuData } from '../../../../state/MenuData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../../state/UIState';
import { getFeatureComponentUrl, getHighlightedMenuIcon } from '../../../../utils/accessibility';
import { showToast, sortBySequence } from '../../../../utils/common';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ENCODING_TYPE,
  ERROR_MESSAGES,
  REQUEST_TYPE,
} from '../../../constants/AppConstants';
import CustomButton from '../../custom_button/CustomButton';
import Divider from '../../divider/Divider';
import HtmlView from '../../html_view/HTMLView';

const FavoriteModal = (props) => {
  const { isFavoriteModalVisible, setIsFavoriteModalVisible } = props;
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);
  const menuReduxData = useSelector(selectMenuData);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [menuData, setMenuData] = useState({});
  const [key, setKey] = useState();

  useEffect(() => {
    setMenuData(menuReduxData);
  }, [menuReduxData]);

  const callSaveMenuFavoritesApi = async () => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      let favoritesObject = menuData.data.section_list.find((section) => {
        return section.identifier === ACCESSIBILITY_IDENTIFIERS.FAVORITES_SECTION;
      });
      const params = {
        data: {
          features: favoritesObject.features.map((feature, index) => {
            return { feature_id: feature.id, sequence: index + 1 };
          }),
        },
      };
      const apiResponse = await apiRequest(
        SAVE_MENU_FAVORITES,
        REQUEST_TYPE.POST,
        JSON.stringify(params),
        ENCODING_TYPE.RAW
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          const message = apiResponse.response.data;
          if (message) {
            showToast(message);
            dispatch(saveMenuData(menuData));
            setIsFavoriteModalVisible(false);
          }
        }
      }
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressMenuFavoriteSaveHandler = () => {
    callSaveMenuFavoritesApi();
  };

  const returnFavoriteIconIdentifier = (section, feature) => {
    if (section.identifier === ACCESSIBILITY_IDENTIFIERS.FAVORITES_SECTION) {
      return ACCESSIBILITY_FEATURE_COMPONENTS.MENUSCREEN_ADDED_FAVORITE;
    } else {
      let favoritesObject = menuData.data.section_list.find((section) => {
        return section.identifier === ACCESSIBILITY_IDENTIFIERS.FAVORITES_SECTION;
      });
      const isFeatureAlreadyAdded = favoritesObject.features.find(
        (favoriteFeature) => favoriteFeature.identifier === feature.identifier
      );
      if (isFeatureAlreadyAdded) {
        return ACCESSIBILITY_FEATURE_COMPONENTS.MENUSCREEN_ADDED_FAVORITE;
      } else {
        return ACCESSIBILITY_FEATURE_COMPONENTS.MENUSCREEN_ADD_FAVORITE;
      }
    }
  };

  const removeMenuFavorite = (newFeature) => {
    let menu = menuData.data.section_list;
    let favoritesObject = menu.find((section) => {
      return section.identifier === ACCESSIBILITY_IDENTIFIERS.FAVORITES_SECTION;
    });
    if (favoritesObject.features.length <= 0) {
      showToast(ERROR_MESSAGES.MENU_NO_FAVOURITES_TO_REMOVE);
    } else {
      let newFavoriteObject = {
        ...favoritesObject,
        ...{
          features: favoritesObject.features.filter((feature) => {
            return feature.identifier !== newFeature.identifier;
          }),
        },
      };
      const updatedMenu = menu.map((section) => {
        if (section.identifier === ACCESSIBILITY_IDENTIFIERS.FAVORITES_SECTION) {
          return newFavoriteObject;
        } else {
          return section;
        }
      });
      setMenuData({ ...menuData, ...{ data: { section_list: updatedMenu } } });
    }
  };

  const addMenuFavorite = (newFeature) => {
    const menuDataCopy = { ...menuData };
    let favoritesObject = menuDataCopy.data.section_list.find((section) => {
      return section.identifier === ACCESSIBILITY_IDENTIFIERS.FAVORITES_SECTION;
    });
    const isFeatureAlreadyAdded = favoritesObject.features.find(
      (favoriteFeature) => favoriteFeature.identifier === newFeature.identifier
    );
    if (isFeatureAlreadyAdded) {
      removeMenuFavorite(newFeature);
    } else if (favoritesObject.features.length >= 4) {
      showToast(ERROR_MESSAGES.MENU_REACHED_MAXIMUM_FAVOURITES);
    } else {
      let newFavoritesObject = {
        ...favoritesObject,
        ...{ features: [...favoritesObject.features, newFeature] },
      };
      const updatedMenu = menuDataCopy.data.section_list.map((section) => {
        if (section.identifier === ACCESSIBILITY_IDENTIFIERS.FAVORITES_SECTION) {
          return newFavoritesObject;
        } else {
          return section;
        }
      });
      setMenuData({ ...menuDataCopy, ...{ data: { section_list: updatedMenu } } });
    }
  };

  const onPressFavoriteButtonHandler = (section, feature) => {
    if (section.identifier === ACCESSIBILITY_IDENTIFIERS.FAVORITES_SECTION) {
      removeMenuFavorite(feature);
    } else {
      addMenuFavorite(feature);
    }
  };

  const renderAddedFavoritesView = () => {
    return menuData.data.section_list.map((section, sectionIndex) => {
      if (section.identifier === ACCESSIBILITY_IDENTIFIERS.FAVORITES_SECTION) {
        let favoriteSectionList = section.features;
        for (let i = section.features.length; i < 4; i++) {
          favoriteSectionList = [...favoriteSectionList, {}];
        }
        return (
          <div key={sectionIndex.toString()} className="d-flex flex-wrap pt-4 mx-n2">
            {favoriteSectionList.map((feature, featureIndex) => {
              if (feature && !!Object.keys(feature).length) {
                return (
                  <div
                    className={`col-6 col-md-6 col-lg-3 header-feature-item mx-0 mb-4 p-0 ${
                      feature.isHighlighted
                        ? 'header-feature-item-highlighted'
                        : 'header-feature-unhighlighted'
                    }`}
                    key={featureIndex.toString()}>
                    <div
                      onClick={() => onPressFavoriteButtonHandler(section, feature)}
                      className="d-flex align-items-center justify-content-between header-favorite-added-container p-3 mx-2 h-100 common-cursor-pointer">
                      <div className="d-flex align-items-center">
                        <img
                          className="mr-2 header-feature-item-image"
                          src={
                            feature.isHighlighted
                              ? getHighlightedMenuIcon(
                                  accessibilityData,
                                  ACCESSIBILITY_FEATURE_COMPONENTS.MENUSCREEN_ICON_URL,
                                  feature.identifier
                                )
                              : getFeatureComponentUrl(
                                  accessibilityData,
                                  ACCESSIBILITY_FEATURE_COMPONENTS.MENUSCREEN_ICON_URL,
                                  feature.identifier
                                )
                          }
                        />
                        <HtmlView html={feature.title} onClick={() => {}} />
                      </div>
                      <img
                        src={getFeatureComponentUrl(
                          accessibilityData,
                          returnFavoriteIconIdentifier(section, feature),
                          section.identifier
                        )}
                        className="header-favorite-icon ml-2"
                      />
                    </div>
                  </div>
                );
              } else
                return (
                  <div
                    className={`col-6 col-md-6 col-lg-3 header-feature-item mx-0 mb-4 p-0`}
                    key={featureIndex.toString()}>
                    <div className="header-favorite-not-added-container p-3 mx-2 h-100"></div>
                  </div>
                );
            })}
          </div>
        );
      }
    });
  };

  const renderSectionTab = (section) => {
    return (
      <div className="d-flex flex-wrap">
        {section.features.map((feature, featureIndex) => {
          return (
            <div
              className={`col-6 col-md-6 col-lg-3 header-feature-item m-0 p-0 ${
                feature.isHighlighted
                  ? 'header-feature-item-highlighted'
                  : 'header-feature-unhighlighted'
              }`}
              key={featureIndex.toString()}>
              <div
                onClick={() => onPressFavoriteButtonHandler(section, feature)}
                className="d-flex align-items-center justify-content-between common-cursor-pointer p-3 h-100">
                <div className="d-flex align-items-center">
                  <img
                    className="mr-2 header-feature-item-image"
                    src={
                      feature.isHighlighted
                        ? getHighlightedMenuIcon(
                            accessibilityData,
                            ACCESSIBILITY_FEATURE_COMPONENTS.MENUSCREEN_ICON_URL,
                            feature.identifier
                          )
                        : getFeatureComponentUrl(
                            accessibilityData,
                            ACCESSIBILITY_FEATURE_COMPONENTS.MENUSCREEN_ICON_URL,
                            feature.identifier
                          )
                    }
                  />
                  <HtmlView html={feature.title} onClick={() => {}} />
                </div>
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    returnFavoriteIconIdentifier(section, feature),
                    section.identifier
                  )}
                  className="header-favorite-icon ml-2"
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Modal
      onHide={() => setIsFavoriteModalVisible((val) => !val)}
      show={isFavoriteModalVisible}
      centered
      size="xl"
      backdrop="static"
      keyboard={false}>
      <Modal.Body className="p-4">
        {menuData.data && (
          <>
            <div className="header-favorite-title">FAVOURITES</div>
            {renderAddedFavoritesView()}
            <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
              {sortBySequence(menuData.data.section_list).map((section, index) => {
                if (section.identifier !== ACCESSIBILITY_IDENTIFIERS.FAVORITES_SECTION)
                  return (
                    <Tab
                      className="py-3"
                      key={`${index}`}
                      eventKey={section.id}
                      title={section.title}>
                      {renderSectionTab(section, index)}
                    </Tab>
                  );
              })}
            </Tabs>
          </>
        )}
        <Divider style="mb-4" />
        <div className="d-flex justify-content-end">
          <CustomButton
            onClick={() => {
              setIsFavoriteModalVisible(false);
              setMenuData(menuReduxData);
            }}
            buttonMainContainer="mr-3"
            buttonStyle="common-custom-button-quaternary px-3 py-1">
            CANCEL
          </CustomButton>
          <CustomButton
            disabled={progressLoadingState.isProgressLoading}
            onClick={onPressMenuFavoriteSaveHandler}
            buttonStyle="px-3 py-1">
            SAVE
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FavoriteModal;
