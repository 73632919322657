import React, { useEffect, useState, useCallback } from 'react';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  OUR_CUSTOMERS_API_TYPES,
  OUR_CUSTOMERS_DATA_TYPES,
  OUR_CUSTOMERS_LIKE_TYPES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../../services/Service';
import { CUSTOMERS_BY_ID } from '../../../services/ApiUrls';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import OurCustomerListItem from '../components/OurCustomerListItem';
import { useHistory } from 'react-router';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import AccoladesListItem from '../components/AccoladesListItem';

const CustomerSpecificTab = ({ type, title, customerId }) => {
  const history = useHistory();
  const [customers, setCustomers] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(true);
  const [geographyId, setGeographyId] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.OUR_CUSTOMERS_TAB_VISIT,
      type,
      ANALYTICS_ITEM_NAMES.OUR_CUSTOMER
    );
  }, [type]);

  const callOurCustomerApi = useCallback(
    async (id, loadMore, geoId) => {
      if (loadMore) {
        const params = {
          page_id: id,
          geography_id: geoId,
          know_id: customerId,
        };
        try {
          setLoading(true);
          const apiResponse = await apiRequest(CUSTOMERS_BY_ID + type, REQUEST_TYPE.GET, params);
          setLoading(false);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data) {
                const data = apiResponse.response.data;
                if (data.feeds.length > 0) {
                  if (id === 1) {
                    setCustomers(data.feeds);
                  } else {
                    setCustomers((oldArray) => [...oldArray, ...data.feeds]);
                  }
                  setLoadMoreFlag(true);
                } else {
                  setLoadMoreFlag(false);
                }
              }
            }
          }
        } catch (err) {
          handleError(err, params, CUSTOMERS_BY_ID + type, NAVIGATION_ROUTES.OUR_CUSTOMER);
          setLoading(false);
        }
      }
    },
    [type]
  );

  useEffect(() => {
    callOurCustomerApi(pageId, loadMoreFlag, geographyId);
  }, [callOurCustomerApi, loadMoreFlag, pageId, geographyId]);

  const onPressLoadMore = () => {
    setPageId((prev) => prev + 1);
  };

  const onPressMore = (pictures, baseUrl) => {
    history.push(NAVIGATION_ROUTES.GALLERY_COMMON, {
      baseUrl: baseUrl,
      pictures: pictures.pictures,
    });
  };

  const onPress = (item) => {
    OUR_CUSTOMERS_DATA_TYPES[type] === 'research'
      ? history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_RESEARCHERS_CONTENT, {
          customerId: item.id,
          dataType: OUR_CUSTOMERS_DATA_TYPES[type],
          parentName: title,
          apiType: OUR_CUSTOMERS_API_TYPES[type],
          type: type,
          likeType: OUR_CUSTOMERS_LIKE_TYPES[type],
        })
      : OUR_CUSTOMERS_DATA_TYPES[type] !== 'know'
      ? history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT, {
          customerId: item.id,
          dataType: OUR_CUSTOMERS_DATA_TYPES[type],
          parentName: title,
          apiType: OUR_CUSTOMERS_API_TYPES[type],
          type: type,
          likeType: OUR_CUSTOMERS_LIKE_TYPES[type],
        })
      : history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_ALL_SCREEN, {
          customerId: item.id,
          dataType: OUR_CUSTOMERS_DATA_TYPES[type],
          parentName: title,
          apiType: OUR_CUSTOMERS_API_TYPES[type],
          type: type,
          likeType: OUR_CUSTOMERS_LIKE_TYPES[type],
        });
  };

  const renderItem = (item, index) => {
    return type !== 'accolades' ? (
      <OurCustomerListItem
        item={item}
        key={`${index}`}
        onPressMore={onPressMore}
        onPress={onPress}
      />
    ) : (
      <AccoladesListItem item={item} key={`${index}`} onPressMore={onPressMore} onPress={onPress} />
    );
  };

  return (
    <div className="container">
      <div>
        {customers ? (
          <>
            {customers.length ? (
              <div className="row">{customers.map((item, index) => renderItem(item, index))}</div>
            ) : loading ? (
              <ActivityLoader visible={loading} />
            ) : (
              <DefaultContentView message={ERROR_MESSAGES.NO_DATA_FOUND} />
            )}
            {loadMoreFlag && <LoadMoreBtn onClick={onPressLoadMore} isLoading={loading} />}
          </>
        ) : (
          <ActivityLoader visible={loading} />
        )}
      </div>
    </div>
  );
};

export default React.memo(CustomerSpecificTab);
