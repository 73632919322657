import React, { useEffect } from 'react';

import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
} from '../../../../common/constants/AppConstants';
import LoadMoreBtn from '../../../../common/ui/load_more_btn/LoadMoreBtn';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import AllInductionListItem from '../../components/AllInductionListItem';
const AllInductionTab = (props) => {
  const onClickLoadMore = () => {
    props.callNewJoineesApi('', props?.currentPageId + 1);
  };

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.NEW_JOINEES_AGENDA_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.NEW_JOINEES_HIGHER_LEVELS
    );
  }, []);

  const renderItem = () => {
    return props?.data?.map((item, index) => <AllInductionListItem key={`${index}`} item={item} />);
  };

  return (
    <div>
      {renderItem()}
      {props?.dataLength >= 10 && (
        <LoadMoreBtn isLoading={props.isLoading} onClick={onClickLoadMore} />
      )}
    </div>
  );
};

export default React.memo(AllInductionTab);
