import { createSlice } from '@reduxjs/toolkit';

const saveTravelRequestMasterDataReducer = (state, action) => {
  state.travelRequestMasterData = action.payload;
};

const saveTravelRequestDataReducer = (state, action) => {
  state.travelRequestData = action.payload;
};

const saveAllTravelRequestDataReducer = (state, action) => {
  state.allTravelRequestData = action.payload;
};

const saveUpcomingTravelRequestDataReducer = (state, action) => {
  state.upcomingTravelRequestData = action.payload;
};

const saveTravelRequestDataForApprovalReducer = (state, action) => {
  state.travelRequestDataForApproval = action.payload;
};

// REDUCER
const travelRequestMasterDataSlice = createSlice({
  name: 'travelRequestMasterData',
  initialState: {
    travelRequestMasterData: {},
    travelRequestData: {},
    allTravelRequestData: [],
    upcomingTravelRequestData: [],
    travelRequestDataForApproval: [],
  },
  reducers: {
    saveTravelRequestMasterData: saveTravelRequestMasterDataReducer,
    saveTravelRequestData: saveTravelRequestDataReducer,
    saveAllTravelRequestData: saveAllTravelRequestDataReducer,
    saveUpcomingTravelRequestData: saveUpcomingTravelRequestDataReducer,
    saveTravelRequestDataForApproval: saveTravelRequestDataForApprovalReducer,
  },
});

// ACTIONS
const {
  saveTravelRequestMasterData,
  saveTravelRequestData,
  saveAllTravelRequestData,
  saveUpcomingTravelRequestData,
  saveTravelRequestDataForApproval,
} = travelRequestMasterDataSlice.actions;

// SELECTOR
const selectTravelModesList = ({ travelRequestMasterData }) => {
  let data = [];
  if (Object.keys(travelRequestMasterData.travelRequestMasterData).length) {
    data = travelRequestMasterData.travelRequestMasterData.masters.travel_modes;
  }
  return data;
};

const selectFlightClassList = ({ travelRequestMasterData }) => {
  let data = [];
  if (Object.keys(travelRequestMasterData.travelRequestMasterData).length) {
    data = travelRequestMasterData.travelRequestMasterData.masters.flight_class;
  }
  return data;
};

const selectFlightSeatList = ({ travelRequestMasterData }) => {
  let data = [];
  if (Object.keys(travelRequestMasterData.travelRequestMasterData).length) {
    data = travelRequestMasterData.travelRequestMasterData.masters.flight_seat_pref;
  }
  return data;
};

const selectFlightFoodList = ({ travelRequestMasterData }) => {
  let data = [];
  if (Object.keys(travelRequestMasterData.travelRequestMasterData).length) {
    data = travelRequestMasterData.travelRequestMasterData.masters.flight_food_pref;
  }
  return data;
};

const selectTrainCoachList = ({ travelRequestMasterData }) => {
  let data = [];
  if (Object.keys(travelRequestMasterData.travelRequestMasterData).length) {
    data = travelRequestMasterData.travelRequestMasterData.masters.train_coach;
  }
  return data;
};

const selectTrainSeatList = ({ travelRequestMasterData }) => {
  let data = [];
  if (Object.keys(travelRequestMasterData.travelRequestMasterData).length) {
    data = travelRequestMasterData.travelRequestMasterData.masters.train_seat_pref;
  }
  return data;
};

const selectTrainFoodList = ({ travelRequestMasterData }) => {
  let data = [];
  if (Object.keys(travelRequestMasterData.travelRequestMasterData).length) {
    data = travelRequestMasterData.travelRequestMasterData.masters.train_food_pref;
  }
  return data;
};

const selectBusCoachList = ({ travelRequestMasterData }) => {
  let data = [];
  if (Object.keys(travelRequestMasterData.travelRequestMasterData).length) {
    data = travelRequestMasterData.travelRequestMasterData.masters.bus_coach;
  }
  return data;
};

const selectBusSeatList = ({ travelRequestMasterData }) => {
  let data = [];
  if (Object.keys(travelRequestMasterData.travelRequestMasterData).length) {
    data = travelRequestMasterData.travelRequestMasterData.masters.bus_seat_pref;
  }
  return data;
};

const selectPropertyTypeList = ({ travelRequestMasterData }) => {
  let data = [];
  if (Object.keys(travelRequestMasterData.travelRequestMasterData).length) {
    data = travelRequestMasterData.travelRequestMasterData.masters.property_type_pref;
  }
  return data;
};

const selectTravellerData = ({ travelRequestMasterData }) => {
  let data = [];
  if (Object.keys(travelRequestMasterData.travelRequestMasterData).length) {
    data = travelRequestMasterData.travelRequestMasterData.traveller;
  }
  return data;
};

const selectTravelOnBehalfAccessData = ({ travelRequestMasterData }) => {
  let data = [];
  if (Object.keys(travelRequestMasterData.travelRequestMasterData).length) {
    data = travelRequestMasterData.travelRequestMasterData.onbehalf_access;
  }
  return data;
};

const selectTravelAccessData = ({ travelRequestMasterData }) => {
  let data = [];
  if (Object.keys(travelRequestMasterData.travelRequestMasterData).length) {
    data = travelRequestMasterData.travelRequestMasterData.access;
  }
  return data;
};

const selectTravelSpecialTextAccessData = ({ travelRequestMasterData }) => {
  let data = false;
  if (Object.keys(travelRequestMasterData.travelRequestMasterData).length) {
    data = travelRequestMasterData.travelRequestMasterData.show_geo_message;
  }
  return data;
};

const selectTravelRequestDashboardData = ({ travelRequestMasterData }) =>
  travelRequestMasterData.travelRequestMasterData;

const selectTravelRequestData = ({ travelRequestMasterData }) =>
  travelRequestMasterData.travelRequestData;

const selectAllTravelRequestData = ({ travelRequestMasterData }) =>
  travelRequestMasterData.allTravelRequestData;

const selectUpcomingTravelRequestData = ({ travelRequestMasterData }) =>
  travelRequestMasterData.upcomingTravelRequestData;

const selectTravelRequestDataForApproval = ({ travelRequestMasterData }) =>
  travelRequestMasterData.travelRequestDataForApproval;

const travelRequestMasterDataSliceReducer = travelRequestMasterDataSlice.reducer;

export {
  travelRequestMasterDataSliceReducer,
  saveTravelRequestMasterData,
  selectTravelModesList,
  selectFlightClassList,
  selectTrainCoachList,
  selectBusCoachList,
  selectFlightSeatList,
  selectFlightFoodList,
  selectTrainSeatList,
  selectTrainFoodList,
  selectBusSeatList,
  selectPropertyTypeList,
  saveTravelRequestData,
  selectTravelSpecialTextAccessData,
  selectTravelRequestDashboardData,
  selectTravellerData,
  selectTravelOnBehalfAccessData,
  selectTravelAccessData,
  selectTravelRequestData,
  saveAllTravelRequestData,
  selectAllTravelRequestData,
  saveUpcomingTravelRequestData,
  saveTravelRequestDataForApproval,
  selectUpcomingTravelRequestData,
  selectTravelRequestDataForApproval,
};
