import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import Divider from '../../common/ui/divider/Divider';
import { SUBMIT_NOMINATON } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { selectEmployeeData } from '../../state/EmployeeData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { getAwardIcon } from '../../utils/common';
import MyManagerListItem from './components/MyManagerListItem';
import { Modal } from 'react-bootstrap';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';

const ViewEligibleAwardScreen = ({ history, location }) => {
  const dispatch = useDispatch();
  const { selectedItem } = location.state;
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [buttonIsDisable, setButtonIsDisable] = useState(true);
  const [iconName] = useState(getAwardIcon(selectedItem.icon_name));
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [citationId, setCitationId] = useState();

  const employeeData = useSelector(selectEmployeeData);
  const accessibilityData = useSelector(selectAccessibilityData);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.VIEW_ELIGIBLE_AWARD_SCREEN,
      NAVIGATION_ROUTES.VIEW_ELIGIBLE_AWARD_SCREEN
    );
  }, []);

  const callSubmitNominationApi = async () => {
    const params = new URLSearchParams();
    params.append('type', 'self');
    params.append('employees', employeeData.employee_id);
    params.append('award_to', 'IN');
    params.append('award_key', selectedItem.award_key);
    params.append('citation_id', citationId);
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.SUBMIT_NOMINATIONS,
        })
      );
      const apiResponse = await apiRequest(SUBMIT_NOMINATON, REQUEST_TYPE.POST, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setShowSuccessDialog(true);
        }
      }
    } catch (err) {
      handleError(err, params, SUBMIT_NOMINATON, NAVIGATION_ROUTES.VIEW_ELIGIBLE_AWARD_SCREEN);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onRadioButtonPressed = (item, index) => {
    setCitationId(item.citation_id);
    setSelectedIndex(index);
    setButtonIsDisable(false);
  };

  const renderFilters = (data, index) => {
    return (
      <MyManagerListItem
        item={data}
        onRadioButtonPressed={onRadioButtonPressed}
        index={index}
        selectedIndex={selectedIndex}
        key={`${index}`}
      />
    );
  };

  const onPressViewEligibleAward = () => {
    callSubmitNominationApi();
  };

  const onPressNominateAgain = () => {
    setShowSuccessDialog(false);
    history.go(-2);
  };

  const onPressCheersHome = () => {
    setShowSuccessDialog(false);
    history.go(-3);
  };

  const onPressBackDrop = () => {
    setShowSuccessDialog(false);
    history.go(-3);
  };

  const successDialog = () => {
    return (
      <div>
        <div className="cheers-team-micro-view">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_SUCCESS,
              ACCESSIBILITY_IDENTIFIERS.CHEERS
            )}
            className="cheers-success-icon"
          />
        </div>
        <div className="text-center mt-3 cheers-register-message-text">Nomination Submitted</div>
        <CustomButton buttonStyle="cheers-button-style mt-3" onClick={onPressNominateAgain}>
          Nominate Again
        </CustomButton>
        <CustomButton buttonStyle="cheers-button-style mt-3" onClick={onPressCheersHome}>
          Cheers Home
        </CustomButton>
      </div>
    );
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CHEERS} />
      <BackButton />
      <div className="main-content-container">
        <Modal
          show={showSuccessDialog}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={onPressBackDrop}>
          <Modal.Body>{successDialog()}</Modal.Body>
        </Modal>
        <div className="cheers-team-micro-view">
          <div className="cheers-nomination-type">Nomination Type:</div>
          <div className="ml-1 cheers-nomination-text">Self</div>
        </div>
        <div className="cheers-team-micro-view">
          <div className="text-center mt-2 cheers-award-type">
            {selectedItem.award_frequency === 'Monthly' ? 'Last Month' : 'Last Quarter'}
          </div>
        </div>
        <div className="text-center mt-3 cheers-based-on-criteria-text">
          Based on the criteria selected
        </div>
        <div className="cheers-team-micro-view mt-2 mb-2">
          <div className="cheers-outer-view cheers-icon-text-view">
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                iconName,
                ACCESSIBILITY_IDENTIFIERS.CHEERS
              )}
              className="cheers-normal-icon"
            />
            <div className="ml-2 cheers-award-title-text">{selectedItem.title}</div>
          </div>
        </div>
        <div className="text-center mt-2 cheers-reward-points-second-text">{`(${selectedItem.points} Points)`}</div>
        <Divider style="mt-2 mb-2" />
        <div className="cheers-select-option-text">Why is this award for?</div>
        {selectedItem.citations.length > 0 &&
          selectedItem.citations.map((data, index) => renderFilters(data, index))}
        <CustomButton
          buttonStyle="cheers-button-style mr-3 mt-2"
          onClick={onPressViewEligibleAward}
          disabled={buttonIsDisable}
          showLoader={progressLoadingState.isProgressLoading}>
          Submit nomination
        </CustomButton>
      </div>
    </div>
  );
};

export default ViewEligibleAwardScreen;
