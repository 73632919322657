import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TotalPointsTab from './tabs/myPointsTabs/TotalPointsTab';
import PointsSummaryTab from './tabs/myPointsTabs/PointsSummaryTab';
import { selectCheersMyPoints } from '../../state/MasterData';
import { Tab } from 'react-bootstrap';
import { saveCheersMyPointsTabData, selectCheersMyPointsTabData } from '../../state/TabData';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import NavTabs from '../../common/ui/tabs/NavTabs';

const MyPointsScreen = () => {
  const dispatch = useDispatch();
  const cheersMyPoints = useSelector(selectCheersMyPoints);
  const myPointsTabData = useSelector(selectCheersMyPointsTabData);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.MY_POINTS_SCREEN, NAVIGATION_ROUTES.MY_POINTS_SCREEN);
  }, []);

  useEffect(() => {
    if (cheersMyPoints.length && myPointsTabData === '') {
      dispatch(saveCheersMyPointsTabData(cheersMyPoints[0].key));
    }
  }, [cheersMyPoints, dispatch, myPointsTabData]);

  const renderTabs = (tab) => {
    switch (tab.key) {
      case 'totalPoints':
        return <TotalPointsTab />;
      case 'pointsSummary':
        return <PointsSummaryTab />;
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CHEERS} />
      <BackButton />
      <NavTabs activeKey={myPointsTabData} onSelect={(k) => dispatch(saveCheersMyPointsTabData(k))}>
        {cheersMyPoints.map((tab, index) => {
          return (
            <Tab key={`${index}`} eventKey={tab.key} title={tab.title}>
              {renderTabs(tab)}
            </Tab>
          );
        })}
      </NavTabs>
    </div>
  );
};

export default MyPointsScreen;
