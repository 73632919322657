import React, { useState } from 'react';
import Divider from '../../../common/ui/divider/Divider';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { getUrlWithSpecificRendition } from '../../../utils/common';
import { SCREEN_DENSITY_QUALIFIERS } from '../../../common/constants/AppConstants';

const BusinessAwardeeView = (props) => {
  const [isImageLoadFailed, setIsImageLoadFailed] = useState(false);
  return (
    <div className="py-3 mx-auto common-container-lg">
      <img
        src={getUrlWithSpecificRendition(
          props?.award?.promo_image?.base_url,
          SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
          props?.award?.promo_image?.image_path
        )}
        onError={() => setIsImageLoadFailed(true)}
        className={`embed-responsive embed-responsive-16by9 ${
          isImageLoadFailed ? 'fallback-color' : ''
        }`}
      />
      <div className="som-business-award-text py-3">{props?.award?.award_name ?? ''}</div>
      <HtmlView htmlStyle="dear-employer-content-html" html={props?.award?.description ?? ''} />
      <div className="row overflow-hidden justify-between d-flex px-2">
        {props.award.employee.map((profile) => (
          <div className="w-25 mb-3" key={profile.employee_id}>
            <UserProfilePhoto
              imageBaseUrl={profile?.profile_picture?.base_url ?? ''}
              imagePath={profile?.profile_picture?.image_path}
              imageClass="common-user-profile-photo-md mb-2"
              showBadge={true}
              profileData={profile}
            />
            <p className="directory-profile-name">
              {profile?.first_name ?? ''} {profile?.last_name ?? ''}
            </p>
            <p className="business-awardee-position-title">{profile?.position_title}</p>
          </div>
        ))}
      </div>
      <Divider />
    </div>
  );
};

export default BusinessAwardeeView;
