import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import Divider from '../../../common/ui/divider/Divider';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { changeAdminStatusOfUser, uId } from '../../../utils/ChatHandler';
import UserProfilePhotoForChats from '../../../common/ui/user_profile_photo/UserProfilePhotoForChats';

const AddedMembersListItem = ({ item, groupMembers, groupId, removeMember }) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const employeeData = useSelector(selectEmployeeData);
  const [isAdmin, setIsAdmin] = useState(false);
  const [name, setName] = useState('');
  const [isOverFlowShown, setIsOverFlowShown] = useState(false);

  useEffect(() => {
    if (item.id === groupMembers[item.id].uid) {
      if (groupMembers[item.id].admin && groupMembers[item.id].admin === true) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    } else {
      setIsAdmin(false);
    }
  }, [groupMembers, item.id]);

  useEffect(() => {
    let employeeId = uId(employeeData);
    if (item.id === employeeId) {
      setName('You');
    } else {
      setName(item.name);
    }
  }, [employeeData, item.id, item.name]);

  useEffect(() => {
    let employeeId = uId(employeeData);
    if (
      groupMembers[employeeId].admin &&
      groupMembers[employeeId].admin === true &&
      item.id !== employeeId
    ) {
      setIsOverFlowShown(true);
    } else {
      setIsOverFlowShown(false);
    }
  }, [employeeData, groupMembers, item.id]);

  const onPressMoreOptionsHandler = () => (
    <Popover>
      {isOverFlowShown && !isAdmin && (
        <>
          <Popover.Content>
            <div
              className="common-cursor-pointer"
              onClick={() => {
                document.body.click();
                changeAdminStatusOfUser(groupId, item.id, true, () => {});
              }}>
              Make Admin
            </div>
          </Popover.Content>
          <Divider />
        </>
      )}
      {isOverFlowShown && isAdmin && (
        <>
          <Popover.Content>
            <div
              className="common-cursor-pointer"
              onClick={() => {
                document.body.click();
                changeAdminStatusOfUser(groupId, item.id, false, () => {});
              }}>
              Remove Admin
            </div>
          </Popover.Content>
          <Divider />
        </>
      )}
      {isOverFlowShown && (
        <>
          <Popover.Content>
            <div
              className="common-cursor-pointer"
              onClick={() => {
                document.body.click();
                removeMember(item.id, name);
              }}>
              Remove
            </div>
          </Popover.Content>
        </>
      )}
    </Popover>
  );

  return (
    <div className="d-flex justify-content-between align-items-center mb-3">
      <div className="d-flex">
        <UserProfilePhotoForChats
          imagePath={item.image}
          employeeId={item.id.split('-')[1]}
          imageClass="common-user-profile-photo-xs"
        />
        <div className="ml-2">
          <div className="chat-base-text-sm chat-bold-text">{name}</div>
          <div className="chat-base-text-sm chat-grey-text">{item.location}</div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        {isAdmin && <div className="chat-base-text-sm chat-grey-text">Admin</div>}
        {isOverFlowShown && (
          <OverlayTrigger
            rootClose
            trigger="click"
            placement="bottom"
            overlay={onPressMoreOptionsHandler()}>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.COMMENTS_MORE_VERTICAL,
                ACCESSIBILITY_IDENTIFIERS.COMMON
              )}
              className="common-comment-popup-more-vertical-icon-size common-cursor-pointer ml-1"
            />
          </OverlayTrigger>
        )}
      </div>
    </div>
  );
};

export default React.memo(AddedMembersListItem);
