import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ERROR_MESSAGES,
} from '../../../common/constants/AppConstants';
import {
  chatFormatDateDDMonthYYYY,
  chatFormatTimeAMPM,
  chatIsToday,
  fetchUserByIdOnce,
  uId,
} from '../../../utils/ChatHandler';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { getFeatureComponentUrlLdpi } from '../../../utils/accessibility';
import Divider from '../../../common/ui/divider/Divider';
import UserProfilePhotoForChats from '../../../common/ui/user_profile_photo/UserProfilePhotoForChats';

const DialogListItem = ({ item, onPressDialog, isHighlighted }) => {
  const [lastMessage, setLastMessage] = useState('');
  const [dialogImage, setDialogImage] = useState(item.image_url);
  const [dialogName, setDialogName] = useState(item.name);
  const [lastMessageTimeStamp, setLastMessageTimeStamp] = useState('');
  const [unreadCount, setUnreadCount] = useState(0);
  const accessibilityData = useSelector(selectAccessibilityData);
  const employeeData = useSelector(selectEmployeeData);

  useEffect(() => {
    const uid = uId(employeeData);
    if (item.members[uid] && Object.keys(item.members[uid]).length > 0) {
      const memberDetail = item.members[uid];
      if (!memberDetail.active || memberDetail.active === false) {
        setLastMessage(ERROR_MESSAGES.NOT_PART_GROUP);
      } else {
        if (item.lastMessage) {
          let deleteTill = item.members[uid].delete_till;
          if (item.lastMessage.timestamp > deleteTill) {
            if (item.lastMessage.is_deleted && item.lastMessage.is_deleted === true) {
              setLastMessage('This message has been deleted');
            } else if (!item.lastMessage.message_type || item.lastMessage.message_type === '1') {
              if (item.lastMessage.message && item.lastMessage.message !== '') {
                setLastMessage(item.lastMessage.message);
              } else {
                setLastMessage('');
              }
            } else if (item.lastMessage.message_type === '2') {
              setLastMessage('Image');
            } else if (item.lastMessage.message_type === '3') {
              setLastMessage(item.lastMessage.doc_name);
            }
          } else {
            setLastMessage('');
          }
        } else {
          setLastMessage('');
        }
      }
    } else {
      setLastMessage('');
    }
  }, [employeeData, item.lastMessage, item.members]);

  useEffect(() => {
    if (item) {
      if (item.group) {
        setDialogName(item.name);
        if (item.image_url && item.image_url !== '') {
          setDialogImage(item.image_url);
        } else {
          setDialogImage(
            getFeatureComponentUrlLdpi(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_CIRCULAR_GROUP_GREY,
              ACCESSIBILITY_IDENTIFIERS.COMMON
            )
          );
        }
      } else if (item.group === false) {
        let uid = uId(employeeData);
        let arr = item.groupId.split('_');
        const otherUserId = arr.find((id) => id !== uid);
        fetchUserByIdOnce(otherUserId, (isExist, user) => {
          if (isExist) {
            setDialogName(user.name);
            setDialogImage(user.image_url);
          } else {
            setDialogName('');
            setDialogImage(
              getFeatureComponentUrlLdpi(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
                ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
              )
            );
          }
        });
      }
    }
  }, [accessibilityData, employeeData, item]);

  useEffect(() => {
    const uid = uId(employeeData);
    if (item.members[uid].active === true) {
      if (item.lastMessage && item.lastMessage.timestamp && item.lastMessage.timestamp !== '') {
        if (chatIsToday(item.lastMessage.timestamp)) {
          setLastMessageTimeStamp(chatFormatTimeAMPM(item.lastMessage.timestamp));
        } else {
          setLastMessageTimeStamp(chatFormatDateDDMonthYYYY(item.lastMessage.timestamp));
        }
      } else {
        if (chatIsToday(item.timestamp)) {
          setLastMessageTimeStamp(chatFormatTimeAMPM(item.timestamp));
        } else {
          setLastMessageTimeStamp(chatFormatDateDDMonthYYYY(item.timestamp));
        }
      }
    } else {
      setLastMessageTimeStamp('');
    }
  }, [employeeData, item.lastMessage, item.members, item.timestamp]);

  useEffect(() => {
    let uid = uId(employeeData);
    if (item.members && item.members[uid]) {
      if (item.members[uid].unread_group_count !== 0) {
        setUnreadCount(item.members[uid].unread_group_count);
      } else {
        setUnreadCount(0);
      }
    } else {
      setUnreadCount(0);
    }
  }, [employeeData, item.members]);

  const renderGroupImage = () => {
    if (dialogImage !== '') {
      return (
        <UserProfilePhotoForChats
          imagePath={dialogImage}
          imageClass="common-user-profile-photo-xs"
        />
      );
    } else {
      return <UserProfilePhotoForChats imagePath={''} imageClass="common-user-profile-photo-xs" />;
    }
  };

  const renderGroupName = () => {
    if (dialogName !== '') {
      return <div className="chat-bold-text chat-base-text-sm pr-1">{dialogName}</div>;
    } else {
      return <div />;
    }
  };

  const extractUsername = (text) => {
    const regex = /@\[([^[\]]+)\]\([^)]+\)/g;
    const matches = text.match(regex);
    if (matches) {
      return text.replace(regex, (match) => {
        const username = match.match(/@\[([^[\]]+)\]/)[1];
        return username;
      });
    }
    return text;
  };

  return (
    <div className={`${isHighlighted ? 'chat-dialog-highlighted-container' : ''}`}>
      <div onClick={() => onPressDialog(item)}>
        <div className={`d-flex p-3 common-cursor-pointer chat-sidebar-item`}>
          <div className="mr-2">{renderGroupImage()}</div>
          <div className="overflow-hidden flex-grow-1">
            <div className="d-flex justify-content-between">
              {renderGroupName()}
              <div className="chat-base-text-sm chat-grey-text">{lastMessageTimeStamp}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="text-truncate chat-base-text-sm chat-grey-text">
                {extractUsername(lastMessage)}
              </div>
              {unreadCount > 0 && (
                <div className="pl-2">
                  <div className="d-flex justify-content-center chat-count-view">
                    {unreadCount > 99 ? 99 : unreadCount}
                    {unreadCount > 99 && <div>+</div>}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Divider style="chat-divider-thin" />
    </div>
  );
};

export default React.memo(DialogListItem);
