import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFacilitiesTabs } from '../../state/MasterData';
import { saveProgressLoadingState, selectBaseUrl } from '../../state/UIState';
import { Tab } from 'react-bootstrap';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ACTION_TYPES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { saveFacilityTabData, selectFacilityTabData } from '../../state/TabData';
import FacilityTab from './tabs/FacilityTab';
import { apiRequest, handleError } from '../../services/Service';
import { FACILITIES } from '../../services/ApiUrls';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import NavTabs from '../../common/ui/tabs/NavTabs';

const FacilitiesMainScreen = ({ location }) => {
  const facilitiesTabs = useSelector(selectFacilitiesTabs);
  const facilityTabData = useSelector(selectFacilityTabData);
  const dispatch = useDispatch();
  const baseUrl = useSelector(selectBaseUrl);
  const [id] = useState(new URLSearchParams(location.search).get('id'));
  const temp_facilities = {};
  for (var val in facilitiesTabs) {
    temp_facilities[facilitiesTabs[val].category_id] = [];
  }
  const [facilities, setFacilities] = useState(temp_facilities);
  const [showEmptyView, setShowEmptyView] = useState(false);

  const callFacilitiesApi = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      setShowEmptyView(false);
      const apiResponse = await apiRequest(FACILITIES, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.feeds) {
            const data = apiResponse.response.data.feeds;
            if (data?.length === 0) {
              setShowEmptyView(true);
            }
            if (data) {
              var facilitiesArray = {};
              for (var key in data) {
                facilitiesArray[key] = data[key];
              }
              setFacilities(facilitiesArray);
            }
          }
        }
      } else {
        setShowEmptyView(true);
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(err, {}, FACILITIES, NAVIGATION_ROUTES.FACILITY);
      setShowEmptyView(true);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch]);

  const renderScreen = useCallback(() => {
    callFacilitiesApi();
  }, [callFacilitiesApi]);

  useEffect(() => {
    if (baseUrl !== '' && id && id !== '') {
      dispatch({ type: ACTION_TYPES.GET_TOKEN, payload: { id: id } });
    }
  }, [baseUrl, dispatch, id]);

  useEffect(() => {
    if (baseUrl !== '') {
      renderScreen();
    }
  }, [baseUrl, renderScreen]);

  useEffect(() => {
    if (facilitiesTabs.length && facilityTabData === '') {
      dispatch(saveFacilityTabData(facilitiesTabs[0].category_id));
    }
  }, [dispatch, facilityTabData, facilitiesTabs]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.FACILITY, NAVIGATION_ROUTES.FACILITY);
  }, []);

  const renderTabs = (tab) => {
    switch (tab.category_id) {
      case tab.category_id:
        return (
          <FacilityTab
            data={facilities[tab.category_id]}
            item={tab.category_id}
            showEmptyView={showEmptyView}
          />
        );
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.FACILITIES} />
      {baseUrl !== '' && (
        <NavTabs activeKey={facilityTabData} onSelect={(k) => dispatch(saveFacilityTabData(k))}>
          {facilitiesTabs &&
            facilitiesTabs.map((tab, index) => {
              return (
                <Tab
                  key={`${index}`}
                  eventKey={tab.category_id}
                  title={tab.title}
                  className="main-content-container">
                  {renderTabs(tab, index)}
                </Tab>
              );
            })}
        </NavTabs>
      )}
    </div>
  );
};

export default FacilitiesMainScreen;
