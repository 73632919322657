import React from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import Divider from '../../../common/ui/divider/Divider';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl, getFeatureComponentUrlLdpi } from '../../../utils/accessibility';
import { getUrlWithSpecificRendition } from '../../../utils/common';

const TaggedPeopleListItem = ({ item }) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  return (
    <div className="mb-3">
      <div className="microgive-view-style microgive-outer-view ">
        <img
          src={
            item.profile_picture.image_path !== ''
              ? getUrlWithSpecificRendition(
                  item.profile_picture.base_url,
                  SCREEN_DENSITY_QUALIFIERS.LDPI,
                  item.profile_picture.image_path
                )
              : getFeatureComponentUrlLdpi(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
                  ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                )
          }
          className="microgive-profile-image"
        />
        <div className="ml-3 microgive-outer-view">
          <div>
            <div className="mb-1 microgive-name">{`${item.first_name} ${item.last_name}`}</div>
            <div className="mt-1 microgive-position-text">{item.position_title}</div>
          </div>
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default TaggedPeopleListItem;
