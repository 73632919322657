import React, { useCallback, useEffect, useState } from 'react';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { useDispatch, useSelector } from 'react-redux';
import { saveProgressLoadingState } from '../../state/UIState';
import { apiRequest, handleError } from '../../services/Service';
import { GET_PLAYBOOK } from '../../services/ApiUrls';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Divider from '../../common/ui/divider/Divider';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';

const EmergencyPlaybookMainScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const accessibilityData = useSelector(selectAccessibilityData);
  const [playbookLists, setPlaybookLists] = useState([]);
  const [showEmptyView, setShowEmptyView] = useState(false);
  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.EMERGENCY_PLAYBOOK, NAVIGATION_ROUTES.EMERGENCY_PLAYBOOK);
  }, []);

  const callEmergencyPlaybookApi = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    setShowEmptyView(false);
    try {
      const response = await apiRequest(GET_PLAYBOOK, REQUEST_TYPE.GET, {});
      if (Object.keys(response).length > 0) {
        if (response.response.status === true) {
          if (response?.response?.data?.playbooks?.length == 0) {
            setShowEmptyView(true);
          }
          setPlaybookLists(response?.response?.data?.playbooks ?? []);
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(err, {}, GET_PLAYBOOK, NAVIGATION_ROUTES.EMERGENCY_PLAYBOOK);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      setShowEmptyView(true);
    }
  }, [dispatch]);

  useEffect(() => {
    callEmergencyPlaybookApi();
  }, [callEmergencyPlaybookApi]);

  const emptyListView = () => {
    return (
      <DefaultContentView
        message={NO_DATA_TEXT.NO_DATA_FOUND}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.EMERGENCY_PLAYBOOK} />
      <div className="main-content-container">
        {showEmptyView && emptyListView()}
        {playbookLists?.map((it, ind) => (
          <div key={ind + ''} className="column">
            {Array.isArray(it?.media) &&
              it?.media?.map((pdfObject, index) => {
                return (
                  <div
                    className="mb-3"
                    key={index}
                    onClick={() => {
                      logEvent(
                        ANALYTICS_EVENT_TYPES.EMERGENCY_PLAYBOOK_ITEM_CLICK,
                        pdfObject?.label,
                        ANALYTICS_ITEM_NAMES.EMERGENCY_PLAYBOOK
                      );
                      history.push(NAVIGATION_ROUTES.EMERGENCY_PLAYBOOK_CONTENT, {
                        pdfObject: pdfObject,
                      });
                    }}>
                    <a>
                      <div className="new-joinees-lower-levels-overview-pdf-container d-flex flex-row align-items-end">
                        {!!pdfObject?.pdf_url && (
                          <img
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_PDF_ATTACHMENT,
                              ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                            )}
                            className="new-joinees-lower-levels-overview-pdf-icon mb-2"
                          />
                        )}
                        {!!pdfObject?.pdf_url && pdfObject?.label && (
                          <div className="new-joinees-lower-levels-overview-pdf-title align-self-center">
                            {pdfObject.label}
                          </div>
                        )}
                        {!!pdfObject?.pdf_url && pdfObject?.label && (
                          <img
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.MORE_INFO,
                              ACCESSIBILITY_IDENTIFIERS.COMMON
                            )}
                            className="new-joinees-more_info_icon ml-auto align-self-center"
                          />
                        )}
                      </div>
                    </a>
                    <Divider />
                  </div>
                );
              })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EmergencyPlaybookMainScreen;
