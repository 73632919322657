import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  IJPR_TRACK_STYLES,
  NAVIGATION_ROUTES,
} from '../../../common/constants/AppConstants';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import Divider from '../../../common/ui/divider/Divider';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import IjpRequestAssistDialog from '../components/IjpRequestAssistDialog';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';

const MyApplicationsTab = (props) => {
  const history = useHistory();
  const { getTabData, tab } = props;
  const accessibilityData = useSelector(selectAccessibilityData);
  const [isIjpRequestAssistDialogVisible, setIsIjpRequestAssistDialogVisible] = useState(false);
  const [selectedIjpData, setSelectedIjpData] = useState({});

  useEffect(() => {
    getTabData(props.tabKey, 1);
  }, [props.tabKey, getTabData]);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.IJP_MY_APPLICATIONS_TAB_VISIT,
      tab?.title ?? '',
      ANALYTICS_ITEM_NAMES.IJPR
    );
  }, [tab]);

  const onPressViewMyApplicationHandler = (ijpId, jobCode) => {
    // Analytics
    logEvent(
      ANALYTICS_EVENT_TYPES.IJP_MY_APPLICATION_ITEM_CLICK,
      jobCode.toString(),
      ANALYTICS_ITEM_NAMES.IJPR
    );
    history.push(NAVIGATION_ROUTES.IJP_REFER_DETAIL + '/' + ijpId);
  };

  const renderStageStatusBoxTrackStyle = (value) => {
    if (value === IJPR_TRACK_STYLES.BLUE) {
      return 'ijpr-overview-item-stage-status-blue-track-style';
    } else if (value === IJPR_TRACK_STYLES.AMBER) {
      return 'ijpr-overview-item-stage-status-amber-track-style';
    } else if (value === IJPR_TRACK_STYLES.GREEN) {
      return 'ijpr-overview-item-stage-status-green-track-style';
    } else {
      return 'ijpr-overview-item-stage-status-default-track-style';
    }
  };

  const onPressRequestAssistance = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.IJP_REQUEST_ASSISTANCE_CLICK,
      item.job_code.toString(),
      ANALYTICS_ITEM_NAMES.IJPR
    );
    setIsIjpRequestAssistDialogVisible(true);
    setSelectedIjpData(item);
  };

  const renderApplication = (item) => {
    return (
      <div className="col-12" key={item.ijp_id.toString()}>
        <div className="row my-3 ">
          <div className="col-9 col-sm-11">
            {item.show_indent_status && (
              <div className="ijpr-overview-item-indent-status mb-2">ⓘ {item.indent_status}</div>
            )}
            <h5 className="ijpr-overview-item-title">{item.job_title}</h5>
            <h6 className="ijpr-overview-item-subtitle-grey mb-3">
              {item.job_code} | {item.country_title} - {item.location_title} |{' '}
              {item.minimum_expirence} - {item.maximum_expirence} yrs
            </h6>
            <h6 className="ijpr-overview-item-subtitle-grey mb-3 mr-5">
              Applied on: {item.application.applied_on}
            </h6>
            {!!item.application.stage && (
              <div className="mb-3">
                <div className="ijpr-overview-item-subtitle-grey mb-0">STAGE:</div>
                <div className="d-flex">
                  <div
                    className={`ijpr-overview-item-stage-text ${renderStageStatusBoxTrackStyle(
                      item.application.track_style
                    )} px-2 py-1`}>
                    {item.application.stage}
                  </div>
                </div>
              </div>
            )}
            <div className="ijpr-overview-item-subtitle-grey mb-0">STATUS:</div>
            <div className="ijpr-overview-item-subtitle-grey">{item.application.stage_status}</div>
          </div>
          <div className="col-3 col-sm-1 d-flex flex-column align-items-end justify-content-between pt-2">
            <div
              onClick={() => onPressViewMyApplicationHandler(item.ijp_id, item.job_code)}
              className="common-cursor-pointer">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.IJPR_RIGHT_ARROW,
                  ACCESSIBILITY_IDENTIFIERS.IJPR
                )}
                className="ijpr-overview-item-right-icon"
              />
            </div>
            {item.application.escalate && (
              <div>
                <CustomButton
                  buttonStyle="common-custom-button-quaternary"
                  onClick={(e) => {
                    e.stopPropagation();
                    onPressRequestAssistance(item);
                  }}>
                  Request Assistance
                </CustomButton>
              </div>
            )}
          </div>
        </div>
        <Divider />
      </div>
    );
  };

  const showEmptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.IJPR_NO_JOBS_APPLIED} />;
  };

  return (
    <div>
      <ActivityLoader visible={!!props.isLoading} />
      {props.data && (
        <div className="mb-3">
          {!props.data.length && !props.isLoading && showEmptyListView()}
          {!!props.data.length && (
            <div>
              <IjpRequestAssistDialog
                isIjpRequestAssistDialogVisible={isIjpRequestAssistDialogVisible}
                setIsIjpRequestAssistDialogVisible={setIsIjpRequestAssistDialogVisible}
                ijpData={selectedIjpData}
              />
              <div className="row overflow-hidden">
                {props.data.map((ijp) => renderApplication(ijp))}
              </div>
              {props.loadMoreFlag && (
                <LoadMoreBtn
                  buttonStyle="my-3"
                  onClick={() => props.paginationHandler(props.tabKey)}
                  isLoading={props.isLoading}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(MyApplicationsTab);
