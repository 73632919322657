import React, { useEffect, useCallback, useState, useRef } from 'react';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_TITLE,
} from '../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import { SURVEY_BY_ID } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import SurveyQuestionView from './components/SurveyQuestionView';
import { showToast } from '../../utils/common';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import CustomProgressBar from '../../common/ui/custom_progress_bar/CustomProgressBar';
import { Base64 } from 'js-base64';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';

const SurveyParticipateScreen = ({ history, location }) => {
  const { surveyId } = location.state;
  const [survey, setSurvey] = useState();
  const dispatch = useDispatch();
  const [progressBarVisible] = useState(true);
  const [selectedChecklistItemCount, setSelectedChecklistItemCount] = useState(1);
  const [showSurveyQuestion, setShowSurveyQuestion] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [curIndex, setCurIndex] = useState();
  const [curQuestion, setCurQuestion] = useState();
  const [sureveyQuestionVal, setSurveyQuestionVal] = useState([]);
  const [showSubmitBtnFlag, setShowSubmitBtnFlag] = useState(false);
  const lastOutputVal = useRef();
  const curOutput = useRef();
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.SURVEY_PARTICIPATE, NAVIGATION_ROUTES.SURVEY_PARTICIPATE);
  }, []);

  const callSurveyApi = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequest(SURVEY_BY_ID + surveyId, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.survey) {
            const data = apiResponse.response.data.survey;
            setSurvey(data);
            if (data.questions && data.questions.length > 0) {
              if (data.questions.length === 1) {
                setShowSubmitBtnFlag(true);
              }
              setQuestions(data.questions);
              setCurIndex(0);
              setCurQuestion(data.questions[0]);
              setShowSurveyQuestion(true);
            }
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(err, {}, SURVEY_BY_ID + surveyId, NAVIGATION_ROUTES.SURVEYS_PARTICIPATE);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, surveyId]);

  useEffect(() => {
    callSurveyApi();
  }, [callSurveyApi]);

  const validateOutput = (output) => {
    if (curQuestion.compulsory) {
      if (curQuestion.type === 'text') {
        if (output) {
          return true;
        } else {
          return false;
        }
      } else if (curQuestion.type === 'single') {
        if (output) {
          let selectedArray = output.map((item) => {
            return item.selected;
          });
          if (selectedArray.includes(true)) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else if (curQuestion.type === 'multiple') {
        let selectedArray = output.map((item) => {
          return item.selected;
        });
        if (selectedArray.includes(true)) {
          return true;
        } else {
          return false;
        }
      } else {
        if (output) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return true;
    }
  };

  const callNextQuestion = (val) => {
    setShowSurveyQuestion(false);
    getOutputValue(val);
    curOutput.current = undefined;
    if (curIndex + 1 < questions.length) {
      setCurQuestion(questions[curIndex + 1]);
      setCurIndex((prev) => prev + 1);
      setSelectedChecklistItemCount((prev) => prev + 1);
    }
    if (curIndex + 2 === questions.length) {
      setShowSubmitBtnFlag(true);
    }
    setShowSurveyQuestion(true);
  };

  const callPrevQuestion = () => {
    setShowSubmitBtnFlag(false);
    setSelectedChecklistItemCount((prev) => prev - 1);
    if (curIndex - 1 >= 0) {
      setCurQuestion(questions[curIndex - 1]);
      setCurIndex((prev) => prev - 1);
    }
  };

  const callSubmitFunction = async () => {
    const validate_output = curOutput.current
      ? curOutput.current
      : lastOutputVal.current
      ? lastOutputVal.current
      : undefined;
    if (!validateOutput(validate_output)) {
      showToast(ERROR_MESSAGES.SURVEYS_MANDATORY_FIELDS);
    } else {
      logEvent(ANALYTICS_EVENT_TYPES.SURVEY_PARTICIPATE, surveyId, ANALYTICS_ITEM_NAMES.SURVYES);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      let temp_survey = survey;
      temp_survey.questions = sureveyQuestionVal;
      var base64data = Base64.encode(JSON.stringify({ survey: temp_survey }));
      const formData = new URLSearchParams();
      formData.append('data', base64data);
      try {
        const apiResponse = await apiRequest(
          SURVEY_BY_ID + surveyId + '/participate',
          REQUEST_TYPE.POST,
          formData
        );
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            history.replace(NAVIGATION_ROUTES.SURVEY_SUCCESS);
          }
        }
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      } catch (err) {
        handleError(
          err,
          formData,
          SURVEY_BY_ID + surveyId + '/participate',
          NAVIGATION_ROUTES.SURVEYS_PARTICIPATE
        );
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    }
  };
  const getOutputValue = (val) => {
    curOutput.current = val;
    if (curIndex === questions.length - 1) {
      lastOutputVal.current = val;
    }
    let temp_questions = questions;
    if (val) {
      if (curQuestion.type === 'rating') {
        temp_questions.find((data) => data.question_id === curQuestion.question_id).rating = val;
      } else if (curQuestion.type === 'text') {
        temp_questions.find((data) => data.question_id === curQuestion.question_id).text = val;
      } else if (curQuestion.type === 'multiple') {
        temp_questions.find((data) => data.question_id === curQuestion.question_id).options = val;
      } else {
        temp_questions.find((data) => data.question_id === curQuestion.question_id).options = val;
      }
    }
    setSurveyQuestionVal(temp_questions);
  };

  return (
    <div className="container">
      <SectionHeader title={SCREEN_TITLE.SURVEY} />
      <BackButton />
      <div className="main-content-container">
        {survey && (
          <div>
            <CustomProgressBar
              visible={progressBarVisible}
              selectedChecklistItemCount={selectedChecklistItemCount}
              totalChecklistItemCount={survey.questions.length}
              progressBarStyle="polls-progress-bar-style"
              progressBarInnerStyle={
                selectedChecklistItemCount === survey.questions.length
                  ? 'polls-progress-bar-inner-last-style'
                  : 'polls-progress-bar-inner-style'
              }>
              {Math.round((100 / survey.questions.length) * selectedChecklistItemCount)} %
            </CustomProgressBar>
            {showSurveyQuestion && (
              <SurveyQuestionView
                questionNumber={curIndex + 1}
                question={curQuestion}
                onNext={callNextQuestion}
                onPrev={callPrevQuestion}
                noOfQuestions={questions.length}
                sureveyQuestionVal={sureveyQuestionVal.find(
                  (data) => data.question_id === curQuestion.question_id
                )}
                getOutputValue={getOutputValue}
              />
            )}
          </div>
        )}
        {showSubmitBtnFlag && (
          <CustomButton
            buttonStyle="polls-button-style mr-0 mt-3"
            onClick={callSubmitFunction}
            showLoader={progressLoadingState.isProgressLoading}>
            Submit Responses
          </CustomButton>
        )}
      </div>
    </div>
  );
};

export default SurveyParticipateScreen;
