import React from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';

const TestimonialView = (props) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  return (
    <div className="som-testimonial-view">
      <div className="som-star-view">
        <img
          src={getFeatureComponentUrl(
            accessibilityData,
            props.titleFlag
              ? ACCESSIBILITY_FEATURE_COMPONENTS.SOM_TESTIMONIAL_STAR_TRUE
              : ACCESSIBILITY_FEATURE_COMPONENTS.SOM_TESTIMONIAL_STAR_FALSE,
            ACCESSIBILITY_IDENTIFIERS.SOM
          )}
          className="som-star-icon-size som-star-icon-container"
        />
      </div>
      {props.titleFlag && (
        <div className="som-testimonial-title som-title-conatiner">Not Without You Moment</div>
      )}
      <div className="som-testimonial-summary-text som-summary-container">{props.summary}</div>
      <div className="som-testimonial-row">
        <div className="som-tilde-container">~</div>
        <div className="som-testimonial-employee-text">{props.employee}</div>
        <div>,</div>
        <div className="som-testimonial-designation-text">{props.designation}</div>
      </div>
    </div>
  );
};

export default TestimonialView;
