import React, { useEffect } from 'react';

import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { selectWebBaseUrl } from '../../state/MasterData';
import { useSelector } from 'react-redux';
import { getSecuredPdfUrl } from '../../utils/common';

const EmergencyPlaybookContentScreen = ({ history }) => {
  const pdf = history?.location?.state?.pdfObject ?? {};
  const webUrl = useSelector(selectWebBaseUrl);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.EMERGENCY_PLAYBOOK_CONTENT,
      NAVIGATION_ROUTES.EMERGENCY_PLAYBOOK_CONTENT
    );
  }, []);

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.EMERGENCY_PLAYBOOK} />
      <BackButton />
      <div className="main-content-container">
        <div className="iframe-container">
          {Object.keys(pdf).length > 0 && pdf?.pdf_url && (
            <iframe src={getSecuredPdfUrl(webUrl, pdf?.pdf_url)} allowFullScreen />
          )}
        </div>
      </div>
    </div>
  );
};

export default EmergencyPlaybookContentScreen;
