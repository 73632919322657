import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../saga';
import rootReducer from '../state';

const dynamicReducer = {};

const persistedReducer = rootReducer(dynamicReducer);

const sagaMiddleware = createSagaMiddleware();

const middleware = [
  ...getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }),
  sagaMiddleware,
];

const store = configureStore({
  reducer: persistedReducer,
  middleware,
});

sagaMiddleware.run(rootSaga);

export { store };
