import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab } from 'react-bootstrap';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { selectWallpapersTabs } from '../../state/MasterData';
import { saveProgressLoadingState } from '../../state/UIState';
import { saveWallpaperTabData, selectWallpaperTabData } from '../../state/TabData';
import { apiRequest, handleError } from '../../services/Service';
import { GET_WALLPAPERS } from '../../services/ApiUrls';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import WallpaperTab from './tabs/WallpaperTab';
import NavTabs from '../../common/ui/tabs/NavTabs';

const WallpaperScreen = ({ location }) => {
  const dispatch = useDispatch();
  const wallpaperTabs = useSelector(selectWallpapersTabs);
  const wallpaperTabData = useSelector(selectWallpaperTabData);
  const [loading, setLoading] = useState(true);
  const wallpapersObject = {};
  for (let val in wallpaperTabs) {
    wallpapersObject[wallpaperTabs[val].category_id] = [];
  }
  const [wallpaperData, setWallpaperData] = useState(wallpapersObject);
  const [tabKey] = useState(
    location && location.state && location.state.tabKey && location.state.tabKey
  );

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.WALLPAPERS, NAVIGATION_ROUTES.WALLPAPERS);
  }, []);

  const callWallpaperApi = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequest(GET_WALLPAPERS, REQUEST_TYPE.GET, {});
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.wallpapers) {
            const data = apiResponse.response.data.wallpapers;
            setLoading(false);
            if (data.length > 0) {
              const wallpaperArray = {};
              for (var i in wallpaperTabs) {
                const wallpaperMappedData = data.find(
                  (card) => card.category_id === Number(wallpaperTabs[i].category_id)
                );
                if (wallpaperMappedData) {
                  wallpaperArray[wallpaperTabs[i].category_id] = wallpaperMappedData;
                }
              }
              setWallpaperData(wallpaperArray);
            }
          }
        }
      }
    } catch (err) {
      handleError(err, {}, GET_WALLPAPERS, NAVIGATION_ROUTES.WALLPAPERS);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch]);

  useEffect(() => {
    callWallpaperApi();
  }, [callWallpaperApi]);

  useEffect(() => {
    if (wallpaperTabs.length && wallpaperTabData === '' && tabKey) {
      dispatch(saveWallpaperTabData(tabKey));
    } else if (wallpaperTabs.length && wallpaperTabData === '') {
      dispatch(saveWallpaperTabData(wallpaperTabs[0].category_id));
    }
  }, [dispatch, wallpaperTabData, wallpaperTabs, tabKey]);

  const renderTabs = (tab) => {
    switch (tab.category_id) {
      case tab.category_id:
        return (
          <WallpaperTab
            data={wallpaperData[tab.category_id] ?? []}
            categoryId={tab.category_id}
            loading={loading}
            title={tab.title}
          />
        );
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.WALLPAPERS} />
      <NavTabs
        activeKey={wallpaperTabData}
        onSelect={(k) => dispatch(saveWallpaperTabData(k))}
        unmountOnExit>
        {wallpaperTabs &&
          wallpaperTabs.map((tab, index) => {
            return (
              <Tab key={`${index}`} eventKey={tab.category_id} title={tab.title.toUpperCase()}>
                {renderTabs(tab, index)}
              </Tab>
            );
          })}
      </NavTabs>
    </div>
  );
};

export default WallpaperScreen;
