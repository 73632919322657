import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import {
  formatDateDDMonthYYYY,
  formatSecondsIntoMinutes,
  getUrlWithSpecificRendition,
} from '../../../utils/common';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { apiRequest, handleError } from '../../../services/Service';
import { ARTICLES, INCREASE_VIEW_COUNT_EXTERNAL_LINK } from '../../../services/ApiUrls';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import { saveShowEmptyContentView, selectShowEmptyContentView } from '../../../state/UIState';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import { useHistory } from 'react-router-dom';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import { selectAccessData } from '../../../state/UserAccessData';

const AdvisoriesTab = (props) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const pageId = useRef(1);
  const [loadMoreBtn, setLoadMoreBtn] = useState(true);
  const loadMoreFlag = useRef(true);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const history = useHistory();
  const dispatch = useDispatch();
  const accessData = useSelector(selectAccessData);
  const [showLike, setShowLike] = useState(true);
  const [showComment, setShowcomment] = useState(true);
  const advisoriesAccessData = accessData?.data?.find(
    (item) => item.feature_key === '_covidSupport'
  );

  useEffect(() => {
    const actions = advisoriesAccessData?.actions;
    setShowLike(actions?.includes('_like'));
    setShowcomment(actions?.includes('_comment'));
  }, [advisoriesAccessData?.actions]);

  const callArticlesApi = useCallback(async () => {
    dispatch(saveShowEmptyContentView(true));
    if (loadMoreFlag.current) {
      setLoading(true);
      let params = {
        page_id: pageId.current,
        covid: 1,
        dearml: 0,
        channel_id: props.item.channel_id,
        sub_channel_id: props.item.sub_channel_id,
      };
      try {
        const apiResponse = await apiRequest(ARTICLES, REQUEST_TYPE.GET, params);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.data.feeds) {
              const data = apiResponse.response.data.feeds;
              if (data.length > 0) {
                setArticles((oldArray) => [...oldArray, ...data]);
                pageId.current = pageId.current + 1;
                loadMoreFlag.current = true;
                setLoadMoreBtn(true);
              } else {
                loadMoreFlag.current = false;
                setLoadMoreBtn(false);
              }
            }
          }
        }
        setLoading(false);
      } catch (err) {
        handleError(err, params, ARTICLES, NAVIGATION_ROUTES.PANDEMIC_SUPPORT);
        console.error(err);
        setLoading(false);
      }
    }
  }, [props.item.channel_id, props.item.sub_channel_id, dispatch]);

  useEffect(() => {
    callArticlesApi();
  }, [callArticlesApi]);

  const renderItem = (item) => {
    return (
      <div
        onClick={async (e) => {
          e.stopPropagation();
          if (item.content_load_type === '1') {
            try {
              const es = await apiRequest(
                INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
                REQUEST_TYPE.GET,
                {}
              );
            } catch (err) {
              handleError(
                err,
                {},
                INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
                NAVIGATION_ROUTES.PANDEMIC_SUPPORT
              );
              //ignore if the view count request is not successful
              console.warn(err);
            }
            window.open(item.url);
          } else {
            history.push(`${NAVIGATION_ROUTES.DEAR_EMPLOYER}/${item.article_id}`, {
              screenIdetifier: ACCESSIBILITY_IDENTIFIERS.PANDEMIC_SUPPORT,
              showComment: showComment,
              showLike: showLike,
            });
          }
        }}>
        <img
          src={getUrlWithSpecificRendition(
            item.image.base_url,
            SCREEN_DENSITY_QUALIFIERS.HDPI,
            item.image.image_path
          )}
          className="pandemic-support-image common-cursor-pointer"
        />
        <div className="row ml-1 mt-1">
          {/* <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_CALENDER,
              ACCESSIBILITY_IDENTIFIERS.MICROGIVE
            )}
            className="pandemic-support-calender-style"
          /> */}
          <div className="d-flex flex-row justify-content-between w-100 pr-3">
            {!!item?.time_to_read && (
              <div className="broadcast-event-date-new ">
                {item.time_to_read != 0 ? formatSecondsIntoMinutes(item.time_to_read) : null}
              </div>
            )}
            <div className="broadcast-event-date-new ">
              {formatDateDDMonthYYYY(item.pub_datetime)}
            </div>
            {!!item.page_views && (
              <div className="broadcast-event-date-new ">
                {item.page_views > 1 ? `${item.page_views} views` : `${item.page_views} view`}
              </div>
            )}
          </div>
        </div>
        <div className="pandemic-support-article-title">{item.title}</div>
      </div>
    );
  };

  const loadMoreHandler = () => {
    if (loadMoreFlag.current) {
      pageId.current = pageId.current + 1;
      callArticlesApi();
    }
  };

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.PANDEMIC_SUPPORT_ADVISORIES_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.PANDEMIC_SUPPORT
    );
  }, []);

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.PANDEMIC_SUPPORT_NO_ADVISORIES} />;
  };

  return (
    <div>
      {showEmptyContentView && !articles.length && !loading ? (
        emptyListView()
      ) : (
        <div className="row">
          {articles &&
            articles.map((item, index) => {
              return (
                <div key={`${index}`} className="col-sm-4 mb-3">
                  <div>{renderItem(item)}</div>
                </div>
              );
            })}
        </div>
      )}
      <ActivityLoader visible={loading} />
      {loadMoreBtn && (
        <div className="mb-4 mt-4">
          <LoadMoreBtn onClick={loadMoreHandler} isLoading={loading} />
        </div>
      )}
    </div>
  );
};

export default AdvisoriesTab;
