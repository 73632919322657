import React, { useEffect, useState } from 'react';
import { formatDateDDMonthYYYY, getUrlWithSpecificRendition } from '../../../utils/common';
import { Image } from 'react-bootstrap';
import Divider from '../../../common/ui/divider/Divider';
import { SCREEN_DENSITY_QUALIFIERS } from '../../../common/constants/AppConstants';

const GalleryTabItem = (props) => {
  const [images, setImages] = useState([]);
  const [countLeft, setCountLeft] = useState(0);

  useEffect(() => {
    if (props.item.title !== 'recent') {
      if (props.item.data.length < 7) {
        setImages(props.item.data);
        setCountLeft(0);
      } else {
        setImages(props.item.data.slice(0, 6));
        setCountLeft(props.item.data.length - 6);
      }
    } else {
      setImages(props.item.data);
    }
  }, [props.item.data, props.item.title]);

  const renderItem = (imageItem, index) => {
    return (
      <div className="main-container">
        <div
          onClick={() => {
            props.onPressItem(props.item.data, index);
          }}>
          <Image
            className="womens-day-gallery-tab-image common-cursor-pointer"
            src={getUrlWithSpecificRendition(
              props.baseUrl,
              SCREEN_DENSITY_QUALIFIERS.HDPI,
              imageItem
            )}
          />
        </div>
        {index === 5 && props.item.title !== 'recent' && countLeft > 0 && (
          <div
            className="womens-day-more-view womens-day-more-view-container"
            onClick={() => props.onPressMore(props.item, props.baseUrl)}>
            <div className="womens-day-count-left-text">{`+${countLeft} ${
              countLeft === 1 ? 'photo' : 'photos'
            }`}</div>
          </div>
        )}
      </div>
    );
  };

  const galleryImageArray = images.map((item, index) => {
    return (
      <div key={`${index}`} className="col-sm-4 mt-3">
        {renderItem(item, index)}
      </div>
    );
  });

  return (
    <div>
      {props.item.title !== 'recent' && (
        <div className="womens-day-gallery-date womens-day-gallery-date-container">
          {formatDateDDMonthYYYY(props.item.title)}
        </div>
      )}

      <div className="row mt-3">{galleryImageArray}</div>
      <Divider style="mt-3 mb-3" />
    </div>
  );
};

export default GalleryTabItem;
