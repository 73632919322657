import { call, put, takeEvery } from 'redux-saga/effects';
import { ACTION_TYPES, NAVIGATION_ROUTES, REQUEST_TYPE } from '../common/constants/AppConstants';
import { FINGER_AUTHENTICATION } from '../services/ApiUrls';
import { apiRequest, handleError } from '../services/Service';
import { saveEmployeeData, saveEmployeeLoginData, savePledgeCount } from '../state/EmployeeData';

export function* getEmployee(action) {
  try {
    const apiResponse = yield call(
      apiRequest,
      FINGER_AUTHENTICATION,
      REQUEST_TYPE.GET,
      action.payload
    );
    if (apiResponse) {
      if (apiResponse.response.status === true) {
        yield put(saveEmployeeData(apiResponse.response.data.employee));
        yield put(saveEmployeeLoginData(apiResponse.response.data.employee));
        yield put(savePledgeCount(apiResponse.response.data.pledge_count));
      }
    }
  } catch (e) {
    handleError(e, action.payload, FINGER_AUTHENTICATION, NAVIGATION_ROUTES.HOME);
    yield put(saveEmployeeData({}));
    yield put(saveEmployeeLoginData({}));
  }
}

export function* watchEmployee() {
  yield takeEvery(ACTION_TYPES.GET_EMPLOYEE, getEmployee);
}
