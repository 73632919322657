import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { getUrlWithSpecificRendition } from '../../../utils/common';
import CustomYoutubePlayer from '../../../common/ui/youtube_video/CustomYoutubePlayer';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import PlayIconOverlay from '../../../common/ui/play_icon_overlay/PlayIconOverlay';

const EventTab = (props) => {
  const history = useHistory();
  const baseUrl = props.data && Object.keys(props.data).length !== 0 ? props.data.base_url : '';
  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.SOM_EVENT_TAB, props.item, ANALYTICS_ITEM_NAMES.SOM);
  }, [props.item]);

  const renderEventItem = (item) => {
    return (
      <div
        onClick={() => {
          history.push(`${NAVIGATION_ROUTES.SOM_GALLERY}/${item.picture_id}`);
        }}
        className="common-cursor-pointer">
        {item?.content_load_type === '3' && <PlayIconOverlay />}
        <img
          src={getUrlWithSpecificRendition(
            baseUrl,
            SCREEN_DENSITY_QUALIFIERS.HDPI,
            item.picture_path
          )}
          className="embed-responsive embed-responsive-16by9"
        />
        <div className="pt-2 pb-2 som-outer-view justify-content-between">
          <div className="som-event-tag-text">
            {item.tags.length > 0
              ? item.tags.length === 1
                ? '1 Person tagged'
                : item.tags.length + ' People tagged'
              : 'Tag Microlanders'}
          </div>
          <div className="som-event-tagged-view">
            {item.tags.slice(0, 3).map((val) => {
              return (
                <UserProfilePhoto
                  key={val.employee_id}
                  imageBaseUrl={val.profile_picture.base_url}
                  imagePath={val.profile_picture.image_path}
                  employeeId={val.employee_id}
                  imageClass="common-user-profile-photo-xs som-event-image-container"
                />
              );
            })}
            {item.tags.length > 3 && (
              <div className="som-event-image-outer som-event-image-size-style">
                <div className="som-tag-more-text">+{item.tags.length - 3}</div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const eventArray =
    props.data &&
    props.data?.pictures &&
    props.data?.pictures?.map((item, index) => {
      return (
        <div key={`${index}`} className="col-sm-4 mb-3">
          <div>{renderEventItem(item)}</div>
        </div>
      );
    });

  const showEmptyListView = () => {
    return (
      <DefaultContentView
        message={NO_DATA_TEXT.NO_DATA_FOUND}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  return (
    <div>
      {props.data?.pictures?.length || props.youtubeId ? (
        <div>
          <div>
            {!!props.youtubeId && (
              <div className="mb-3">
                <CustomYoutubePlayer videoId={props.youtubeId} />
              </div>
            )}
          </div>
          <div className="row">{props.data && eventArray}</div>
        </div>
      ) : (
        !props.loading && showEmptyListView()
      )}
    </div>
  );
};

export default EventTab;
