import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  NAVIGATION_ROUTES,
} from '../../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { getFeatureComponent } from '../../../../utils/accessibility';
import { formatDateDDMonth } from '../../../../utils/common';

const DynamicColumnsText = ({ data, sectionIdentifier }) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  const toLinkHandler = () => {
    const route =
      '/' +
      getFeatureComponent(
        accessibilityData,
        ACCESSIBILITY_FEATURE_COMPONENTS.ROUTE,
        sectionIdentifier
      );
    switch (route) {
      case NAVIGATION_ROUTES.IJPR:
        return `${NAVIGATION_ROUTES.IJPR}/${data.id}`;
    }
  };

  const renderUpcomingHolidays = () => {
    return (
      <div className="dynamic-text-holiday-container d-flex flex-row">
        <div className="d-flex flex-row">
          <div className="holiday-card mr-3 d-flex justify-content-center align-items-center flex-column">
            <h5 className="mt-3 mb-n1">{formatDateDDMonth(data?.holiday_date).slice(0, 2)}</h5>
            <h6 className="mb-3">
              {formatDateDDMonth(data?.holiday_date).slice(3, 6)}, {data?.holiday_date.slice(2, 4)}
            </h6>
          </div>
          <div className="d-flex flex-column justify-content-center">
            <div className="home-horizontal-icon-small-title">{data?.holiday_title}</div>
            <div className="dynamic-columns-holiday-day-text">{data?.holiday_day}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Link
      className="col-12 col-sm-12 col-md-12 mt-3 common-unstyled-achor-no-pointer"
      to={toLinkHandler}>
      {sectionIdentifier === '_holidays' ? (
        renderUpcomingHolidays()
      ) : (
        <div className="d-flex justify-content-between">
          {Object.keys(data).map(function (key, index) {
            if (key !== 'id') {
              return (
                <div key={index} className="home-dynamic-columns-item-container">
                  <p className="home-dynamic-columns-item-text">{data[key]}</p>
                </div>
              );
            }
          })}
        </div>
      )}
    </Link>
  );
};

export default DynamicColumnsText;
