import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackScreen } from '../../../utils/FirebaseAnalyticsUtils';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  ML_DAY_IDENTIFIERS,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { saveProgressLoadingState } from '../../../state/UIState';
import SectionHeader from '../../../common/ui/section_header/SectionHeader';
import BackButton from '../../../common/ui/common_back_button/BackButton';
import ML35GalleryComponent from '../../anniversary/components/ML35GalleryComponent';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import { apiRequest, handleError } from '../../../services/Service';
import { GALLERIES } from '../../../services/ApiUrls';
import { selectAnniversaryDayTabs } from '../../../state/MasterData';

const MlDayGalleryFullScreen = ({ history }) => {
  const { title, identifier } = history.location.state;
  const [MLDayPictures, setMLDayPictures] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true);
  const dispatch = useDispatch();
  const [baseUrl, setBaseUrl] = useState('');
  const anniversaryDayTitles = useSelector(selectAnniversaryDayTabs);
  const itemObj = anniversaryDayTitles.find((tab) => tab.identifier === identifier);

  const callgalleriesApi = useCallback(
    async (id) => {
      let tempParams = {
        page_id: id,
        type: 'microlanddays',
        recent: true,
      };
      try {
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        const apiResponse = await apiRequest(GALLERIES, REQUEST_TYPE.GET, tempParams);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.data) {
              const data = apiResponse.response.data.find((item) => item.type === identifier);
              if (identifier === ML_DAY_IDENTIFIERS.ML_35_IDENTIFIER && data.data.feeds.base_url) {
                setBaseUrl(data.data.feeds.base_url);
              } else if (data.data.feeds.base_url) {
                setBaseUrl(data.data.feeds.base_url + 'images_ml33/images/');
              }

              if (data.data.feeds.pictures.length === 0) {
                setLoadMoreEnabled(false);
              } else if (id > 1) {
                setMLDayPictures((prev) => [...prev, ...data.data.feeds.pictures]);
              } else {
                setMLDayPictures(data.data.feeds.pictures);
              }
            }
          }
        }
      } catch (err) {
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        handleError(err, tempParams, GALLERIES, NAVIGATION_ROUTES.GALLERY);
      }
    },
    [dispatch, identifier]
  );

  useEffect(() => {
    callgalleriesApi(1);
  }, [callgalleriesApi]);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.ML35_PICTURES_SCREEN,
      NAVIGATION_ROUTES.ML35_PICTURES_SCREEN
    );
  }, []);

  const loadMore = () => {
    if (loadMoreEnabled) {
      callgalleriesApi(pageId + 1);
      setPageId(pageId + 1);
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY} />
      <BackButton backTitle={itemObj.title || 'BACK'} />
      <div>
        <>
          <div className={`ml-35-images-wrapper mb-2`}>
            {MLDayPictures.map((item, index) => (
              <ML35GalleryComponent key={index} item={item} index={index} baseUrl={baseUrl} />
            ))}
          </div>
          {!loadMoreEnabled && <div className="mb-4" />}
        </>
        {loadMoreEnabled && (
          <div className="mb-4 mt-4">
            <LoadMoreBtn onClick={loadMore} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MlDayGalleryFullScreen;
