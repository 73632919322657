import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  ACCESSIBILITY_IDENTIFIERS,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { ALL_INDUCTION } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { saveProgressLoadingState } from '../../../state/UIState';
import { useHistory } from 'react-router';
import { formatDateDDMonthYYYY } from '../../../utils/common';
import Divider from '../../../common/ui/divider/Divider';

const AllInductionListItem = ({ item }) => {
  const employeeId = item?.employee_id;
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const history = useHistory();

  const callAllInductionApi = useCallback(async () => {
    setError(false);
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(ALL_INDUCTION + '/' + employeeId, REQUEST_TYPE.GET, {});
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          const data = apiResponse.response.data;
          if (data) {
            history.push(`${NAVIGATION_ROUTES.NEWJOINEE_INDUCTION}`, {
              headerTitle: ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES,
              inductionData: data,
              fromAllInduction: true,
            });
          }
        }
      }
    } catch (err) {
      handleError(err, {}, ALL_INDUCTION + '/' + employeeId, NAVIGATION_ROUTES.NEWJOINEE_INDUCTION);
      setError(true);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch, employeeId, history]);

  const handleOnPress = async () => {
    await callAllInductionApi();
  };

  return (
    <div
      className="material-card-no-hover p-3 mb-3 common-cursor-pointer"
      onClick={() => {
        handleOnPress();
      }}>
      <div className="cheers-date-view-style">
        <UserProfilePhoto
          imageBaseUrl={item.profile_picture.base_url}
          imagePath={item.profile_picture.image_path}
          imageClass="common-user-profile-photo-lg"
          employeeId={item.employee_id}
        />
        <div className="ml-3 cheers-center-view">
          <div className="cheers-emp-name">{`${item.first_name} ${item.last_name}`}</div>
          <div className="all_induction_emp-position">{item.position_title}</div>
          <div className="all_induction_emp-position">{item.employee_location}</div>
          <div className="all_induction_emp-position">
            Date of Joining:{' '}
            {item?.date_of_joining ? formatDateDDMonthYYYY(item?.date_of_joining) : 'unknown'}
          </div>
        </div>
      </div>
      <Divider style="mt-3" />
      <div className="row mt-3 pl-3">
        <div className="all-induction-session-schedule-text pr-1">Session Schedule: </div>
        <div className="all-induction-schedule-date ">
          {formatDateDDMonthYYYY(item?.session_start_datetime) !==
          formatDateDDMonthYYYY(item?.session_end_datetime)
            ? `${formatDateDDMonthYYYY(item?.session_start_datetime)} - ${formatDateDDMonthYYYY(
                item?.session_end_datetime
              )}`
            : formatDateDDMonthYYYY(item?.session_start_datetime)}
        </div>
      </div>
    </div>
  );
};

export default AllInductionListItem;
