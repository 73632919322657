import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FieldArray, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  TRAVEL_REQUEST_TRAVEL_CATEGORY,
  TRAVEL_REQUEST_TRAVEL_TYPE,
} from '../../../common/constants/AppConstants';
import {
  selectTravelAccessData,
  selectTravellerData,
  selectTravelOnBehalfAccessData,
  selectTravelSpecialTextAccessData,
} from '../../../state/TravelRequestMasterData';
import { showToast } from '../../../utils/common';
import { GET_EMPLOYEES_PROJECTS, GET_TRAVELLER_BY_ID } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectEmployeeData } from '../../../state/EmployeeData';
import {
  selectMultilineTextSize,
  selectTravelRequestSpecialTextData,
} from '../../../state/MasterData';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import CustomTextInput from '../../../common/ui/custom_text_input/CustomTextInput';
import Select from 'react-select';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import AccommodationSubTab from './AccommodationSubTab';
import TravelSubTab from './TravelSubTab';
import TravelRequestEmployeeDialog from './TravelRequestEmployeeDialog';
import { selectAccessData } from '../../../state/UserAccessData';

const PlanTravelContent = (props) => {
  const { isLoading, setIsLoading, submitButtonTypeRef } = props;
  const formikProps = useFormikContext();
  const isPreApprovedRequest =
    formikProps && formikProps.values && formikProps.values.authorization_id ? true : false;
  const employeeData = useSelector(selectEmployeeData);
  const maxMultiLineCharLimit = useSelector(selectMultilineTextSize);
  const onbehalfAccessData = useSelector(selectTravelOnBehalfAccessData);
  const accessData = useSelector(selectTravelAccessData);
  const specialInstructionsTextAccess = useSelector(selectTravelSpecialTextAccessData);
  const travellerData = useSelector(selectTravellerData);
  const specialInstructionsText = useSelector(selectTravelRequestSpecialTextData);
  const [isEmployeeModalVisible, setIsEmployeeModalVisible] = useState(false);
  const [projectPickerItems, setProjectPickerItems] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const userAccessData = useSelector(selectAccessData);
  const [showProceedButton, setShowProceedButton] = useState(true);
  const [showSaveDraft, setShowSaveDraft] = useState(true);
  const travelAccessData = userAccessData?.data?.find((item) => item.feature_key === '_travel');

  useEffect(() => {
    const actions = travelAccessData?.actions;
    setShowProceedButton(actions?.includes('_proceed'));
    setShowSaveDraft(actions?.includes('_saveDraft'));
  }, [travelAccessData?.actions]);

  const callEmployeeProjectsApi = useCallback(
    async (params = { employee_code: employeeData.employee_code }) => {
      try {
        setIsLoading(true);
        const apiResponse = await apiRequest(GET_EMPLOYEES_PROJECTS, REQUEST_TYPE.GET, params);
        setIsLoading(false);
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            let pickerData = [];
            apiResponse.response.data.forEach((element) => {
              pickerData.push({
                label: element.project_title,
                value: element.project_code,
              });
            });
            setProjectPickerItems(pickerData);
          }
        }
      } catch (err) {
        handleError(err, params, GET_EMPLOYEES_PROJECTS, NAVIGATION_ROUTES.TRAVEL_REQUEST);
        setIsLoading(false);
      }
    },
    [employeeData.employee_code, setIsLoading]
  );

  useEffect(() => {
    if (formikProps.values.travel_type === 'P' && formikProps.values.on_behalf === 'G') {
      formikProps.setFieldValue('on_behalf', 'S');
    }
  }, [formikProps]);

  useEffect(() => {
    if (formikProps.values.travel_type === 'P' && formikProps.values.project_code !== '') {
      formikProps.setFieldValue('project_code', '');
    }
  }, [formikProps]);

  const renderTravelTab = () => {
    return (
      <div className={selectedTabIndex === 0 ? '' : 'd-none'}>
        <FieldArray name="tickets">
          {(ticketsFieldArrayProps) => {
            return (
              formikProps.values.tickets.length > 0 &&
              formikProps.values.tickets.map((ticket, ticketIndex) => (
                <TravelSubTab
                  key={ticketIndex.toString()}
                  formikProps={formikProps}
                  ticket={ticket}
                  ticketLength={formikProps.values.tickets.length}
                  ticketIndex={ticketIndex}
                  ticketsFieldArrayProps={ticketsFieldArrayProps}
                  isPreApprovedRequest={isPreApprovedRequest}
                />
              ))
            );
          }}
        </FieldArray>
      </div>
    );
  };

  const renderAccommodationTab = () => {
    return (
      <div className={selectedTabIndex === 1 ? '' : 'd-none'}>
        <FieldArray name="accommodations">
          {(accommodationFieldArrayProps) => {
            return (
              formikProps.values.accommodations.length > 0 &&
              formikProps.values.accommodations.map((accommodation, accommodationIndex) => (
                <AccommodationSubTab
                  key={accommodationIndex.toString()}
                  formikProps={formikProps}
                  accommodationLength={formikProps.values.accommodations.length}
                  accommodationIndex={accommodationIndex}
                  accommodationFieldArrayProps={accommodationFieldArrayProps}
                  isPreApprovedRequest={isPreApprovedRequest}
                />
              ))
            );
          }}
        </FieldArray>
      </div>
    );
  };

  const callGetTravellerByIdApi = useCallback(
    async (employeeCode) => {
      try {
        setIsLoading(true);
        const apiResponse = await apiRequest(
          GET_TRAVELLER_BY_ID + '/' + employeeCode + '/get',
          REQUEST_TYPE.GET,
          {}
        );
        setIsLoading(false);
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            formikProps.setFieldValue('employee', apiResponse.response.traveller);

            const travelTravellers = formikProps.values.tickets.map((travel) => {
              return { ...travel, ...{ travellers: [apiResponse.response.traveller] } };
            });
            const accomodationGuests = formikProps.values.accommodations.map((accomodation) => {
              return { ...accomodation, ...{ travellers: [apiResponse.response.traveller] } };
            });
            formikProps.setFieldValue('tickets', travelTravellers);
            formikProps.setFieldValue('accommodations', accomodationGuests);
          }
        }
      } catch (err) {
        setIsLoading(false);
      }
    },
    [formikProps, setIsLoading]
  );

  useEffect(() => {
    if (Object.keys(employeeData).length > 0) {
      if (formikProps.values.on_behalf === 'S') {
        callEmployeeProjectsApi({
          employee_code: employeeData.employee_code,
        });
      } else if (formikProps.values.on_behalf === 'E' && formikProps.values.employee_code) {
        callEmployeeProjectsApi({
          employee_code: formikProps.values.employee_code,
        });
      } else if (formikProps.values.on_behalf === 'G') {
        callEmployeeProjectsApi({
          employee_code: employeeData.employee_code,
          travel_type: 'O',
          on_behalf: 'G',
        });
      }
    }
  }, [
    callEmployeeProjectsApi,
    employeeData,
    formikProps.values.employee_code,
    formikProps.values.on_behalf,
  ]);

  const onPressEmployeeHandler = (selectedEmployee) => {
    setIsEmployeeModalVisible(false);
    formikProps.setFieldValue('on_behalf', 'E');
    formikProps.setFieldValue('employee_code', selectedEmployee.employee_code);
    callGetTravellerByIdApi(selectedEmployee.employee_code);
  };

  const displayErrorMessage = (errorObject) => {
    let errorArray = Object.values(errorObject);
    for (let i = 0; i < errorArray.length; i++) {
      if (
        errorArray[i] &&
        errorArray[i].length > 0 &&
        (typeof errorArray[i] === 'string' || errorArray[i] instanceof String)
      ) {
        return errorArray[i];
      } else if (errorArray[i] && errorArray[i].length > 0 && Array.isArray(errorArray[i])) {
        for (let j = 0; i < errorArray[i].length; j++) {
          if (errorArray[i][j]) {
            return displayErrorMessage(errorArray[i][j]);
          }
        }
      }
    }
  };

  return (
    <div>
      <TravelRequestEmployeeDialog
        isEmployeeModalVisible={isEmployeeModalVisible}
        setIsEmployeeModalVisible={setIsEmployeeModalVisible}
        onPressEmployeeHandler={onPressEmployeeHandler}
      />
      <Form noValidate onSubmit={() => {}}>
        <div>
          {specialInstructionsTextAccess && (
            <div className="mb-3">
              <div className="travel-request-general-message-box">
                <p className="mb-0 p-3 travel-request-light-grey-text">{specialInstructionsText}</p>
              </div>
            </div>
          )}

          {accessData.personal && (
            <div className="mb-3">
              <Form.Label className="travel-request-plan-travel-label">Travel Type</Form.Label>
              <div className="d-flex">
                {TRAVEL_REQUEST_TRAVEL_TYPE.map((travelType, index) => {
                  return (
                    <Form.Check
                      key={index.toString()}
                      disabled={isPreApprovedRequest}
                      className="w-25"
                      type="radio"
                      label={travelType.label}
                      checked={formikProps.values.travel_type === travelType.value}
                      onChange={() => {
                        formikProps.setFieldValue('travel_type', travelType.value);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          )}

          {formikProps.values.travel_type === 'P' && (
            <div className="mb-3">
              <div className="travel-request-general-message-box">
                <p className="mb-0 p-3 travel-request-light-grey-text">
                  Note: Travel expenses for personal travel will be deducted from your salary.
                </p>
              </div>
            </div>
          )}

          <div className="mb-3">
            <Form.Label className="travel-request-plan-travel-label">Travel Category</Form.Label>
            <div className="d-flex">
              {TRAVEL_REQUEST_TRAVEL_CATEGORY.map((travelCategory, index) => {
                return (
                  <Form.Check
                    key={index.toString()}
                    disabled={isPreApprovedRequest}
                    className="w-25"
                    type="radio"
                    label={travelCategory.label}
                    checked={formikProps.values.travel_range === travelCategory.value}
                    onChange={() => {
                      formikProps.setFieldValue('travel_range', travelCategory.value);
                    }}
                  />
                );
              })}
            </div>
          </div>

          {onbehalfAccessData && (onbehalfAccessData.employee || onbehalfAccessData.guest) && (
            <div className="mb-3">
              <Form.Label className="travel-request-plan-travel-label">On behalf of</Form.Label>
              <div className="d-flex align-items-center">
                {onbehalfAccessData.self && (
                  <Form.Check
                    disabled={isPreApprovedRequest}
                    className="w-25"
                    type="radio"
                    label="Self"
                    checked={formikProps.values.on_behalf === 'S'}
                    onChange={() => {
                      formikProps.setFieldValue('on_behalf', 'S');
                      formikProps.setFieldValue('employee_code', '');
                      formikProps.setFieldValue('employee', {});
                      const travelTravellers = formikProps.values.tickets.map((travel) => {
                        return { ...travel, ...{ travellers: [travellerData] } };
                      });
                      const accomodationGuests = formikProps.values.accommodations.map(
                        (accomodation) => {
                          return {
                            ...accomodation,
                            ...{ travellers: [travellerData] },
                          };
                        }
                      );
                      formikProps.setFieldValue('tickets', travelTravellers);
                      formikProps.setFieldValue('accommodations', accomodationGuests);
                    }}
                  />
                )}
                {onbehalfAccessData.employee && (
                  <Form.Check
                    disabled={isPreApprovedRequest}
                    className="w-25"
                    type="radio"
                    label="Employee"
                    checked={formikProps.values.on_behalf === 'E'}
                    onChange={() => {
                      setIsEmployeeModalVisible(true);
                    }}
                  />
                )}
                {onbehalfAccessData.guest && formikProps.values.travel_type !== 'P' && (
                  <Form.Check
                    disabled={isPreApprovedRequest}
                    className="w-25"
                    type="radio"
                    label="Guest"
                    checked={formikProps.values.on_behalf === 'G'}
                    onChange={() => {
                      formikProps.setFieldValue('on_behalf', 'G');
                      formikProps.setFieldValue('employee_code', '');
                      formikProps.setFieldValue('employee', {});
                    }}
                  />
                )}
              </div>
            </div>
          )}

          <div className="mb-3">
            <Form.Label className="travel-request-plan-travel-label">
              Billing Type{' '}
              <span className="billing-note">
                {'(Note: Billable refers to cases where you invoice the customer for the travel)'}
              </span>
            </Form.Label>
            <div className="d-flex align-items-center">
              <Form.Check
                disabled={isPreApprovedRequest}
                className="w-25"
                type="radio"
                label="Non-Billable"
                checked={formikProps.values.billing_type === 'N'}
                onChange={() => {
                  formikProps.setFieldValue('billing_type', 'N');
                }}
              />
              <Form.Check
                disabled={isPreApprovedRequest}
                className="w-25"
                type="radio"
                label="Billable"
                checked={formikProps.values.billing_type === 'B'}
                onChange={() => {
                  formikProps.setFieldValue('billing_type', 'B');
                }}
              />
            </div>
          </div>

          {formikProps.values.employee_code !== employeeData.employee_code &&
            formikProps.values.employee &&
            Object.keys(formikProps.values.employee) &&
            Object.keys(formikProps.values.employee).length > 0 && (
              <div>
                <div className="d-flex justify-content-between align-items-center p-3 mb-3 travel-request-employee-box">
                  <div className="d-flex align-items-center">
                    <UserProfilePhoto
                      imageBaseUrl={formikProps.values.employee.profile_picture.base_url}
                      imagePath={formikProps.values.employee.profile_picture.image_path}
                      employeeId={formikProps.values.employee.employee_id}
                      imageClass="common-user-profile-photo-sm"
                    />
                    <div className="d-flex flex-column justify-content-center ml-2">
                      <p className="mb-0 travel-request-employee-box-title">
                        {formikProps.values.employee.first_name +
                          ' ' +
                          formikProps.values.employee.last_name}
                      </p>
                      {formikProps.values.employee.geography &&
                        formikProps.values.employee.geography.title && (
                          <p className="mb-0 travel-request-employee-box-subtitle">
                            {formikProps.values.employee.geography.title}
                          </p>
                        )}
                    </div>
                  </div>
                  <p
                    className="mb-0 travel-request-primary-text-semi-bold common-cursor-pointer"
                    onClick={() => setIsEmployeeModalVisible(true)}>
                    Change
                  </p>
                </div>
              </div>
            )}

          {formikProps.values.travel_type === 'O' && (
            <div className="mb-3">
              <Form.Label className="travel-request-plan-travel-label">Project</Form.Label>
              <Select
                className="travel-request-dropdown-style"
                placeholder="Select Project"
                isSearchable={!!projectPickerItems.length}
                options={projectPickerItems}
                value={projectPickerItems.filter((element) => {
                  return (
                    element.value === formikProps.values.project_code && {
                      label: element.project_title,
                      value: element.project_code,
                    }
                  );
                })}
                onChange={(selectedOption) => {
                  formikProps.setFieldValue('project_code', selectedOption.value);
                }}
              />
            </div>
          )}
          <div>
            <CustomTextInput
              name="purpose"
              title="Purpose"
              disabled={isPreApprovedRequest}
              placeholder="Eg. Client meet at New Delhi"
              errorMessage={formikProps.errors.purpose}
              value={formikProps.values.purpose}
              onBlur={formikProps.handleBlur}
              onChange={formikProps.handleChange}
            />
          </div>
          <div className="d-flex align-items-center justify-content-center pt-3">
            {selectedTabIndex === 0 ? (
              <div className="d-flex align-items-center justify-content-center travel-request-selected-type">
                <div className="travel-request-plan-travel-selected-label">Travel</div>
              </div>
            ) : (
              <div
                className="d-flex align-items-center justify-content-center travel-request-un-selected-type common-cursor-pointer"
                onClick={() => setSelectedTabIndex(0)}>
                <div className="travel-request-plan-travel-label">Travel</div>
              </div>
            )}
            {selectedTabIndex === 1 ? (
              <div className="d-flex align-items-center justify-content-center travel-request-selected-type">
                <div className="travel-request-plan-travel-selected-label">Accomodation</div>
              </div>
            ) : (
              <div
                className="d-flex align-items-center justify-content-center travel-request-un-selected-type common-cursor-pointer"
                onClick={() => setSelectedTabIndex(1)}>
                <div className="travel-request-plan-travel-label">Accomodation</div>
              </div>
            )}
          </div>
          <div>
            <div>
              {renderTravelTab()}
              {renderAccommodationTab()}
            </div>
          </div>
          {formikProps.values.modification_reason_required && (
            <div className="mt-3">
              <CustomTextInput
                name="modification_reason"
                title="Why are you modifying?"
                value={formikProps.values.modification_reason}
                placeholder="Enter your reason(s)"
                as="textarea"
                maxLength={maxMultiLineCharLimit}
                errorMessage={formikProps.errors.modification_reason}
                onBlur={formikProps.handleBlur}
                onChange={formikProps.handleChange}
                style="my-profile-edit-editable-text-area"
              />
            </div>
          )}
          <div className="d-flex mt-3 justify-content-end">
            {showSaveDraft && (
              <CustomButton
                buttonStyle="common-custom-button-quinary mr-3"
                onClick={() => {
                  logEvent(
                    ANALYTICS_EVENT_TYPES.TR_PLAN_TRAVEL_SAVE_DRAFT_CLICK,
                    '',
                    ANALYTICS_ITEM_NAMES.TRAVEL_REQUEST
                  );
                  submitButtonTypeRef.current = 1;
                  if (!formikProps.isValid) {
                    showToast(displayErrorMessage(formikProps.errors));
                  } else {
                    formikProps.handleSubmit();
                  }
                }}>
                SAVE AS DRAFT
              </CustomButton>
            )}
            {showProceedButton && (
              <CustomButton
                buttonStyle="travel-request-proceed-btn ml-3"
                onClick={() => {
                  logEvent(
                    ANALYTICS_EVENT_TYPES.TR_PLAN_TRAVEL_PROCEED_CLICK,
                    '',
                    ANALYTICS_ITEM_NAMES.TRAVEL_REQUEST
                  );
                  submitButtonTypeRef.current = 2;
                  if (!formikProps.isValid) {
                    showToast(displayErrorMessage(formikProps.errors));
                  } else {
                    formikProps.handleSubmit();
                  }
                }}>
                PROCEED
              </CustomButton>
            )}
          </div>
        </div>
      </Form>
    </div>
  );
};

export default React.memo(PlanTravelContent);
