import React, { useCallback, useEffect, useRef, useState } from 'react';
import Lottie from 'react-lottie';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { getFeatureComponentUrl, getFeatureComponentUrlXxxHdpi } from '../../utils/accessibility';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import {
  selectChatGptConfig,
  selectMultilineTextSize,
  selectProfinityFilterWarning,
} from '../../state/MasterData';
import { getProfanityFilter, showToast } from '../../utils/common';
import { saveProgressLoadingState } from '../../state/UIState';
import { apiRequest, handleError } from '../../services/Service';
import { selectEmployeeData } from '../../state/EmployeeData';
import ChatMessage from './ChatEffect';
import horizontalDotsLoaderAnimation from '../../assets/files/horizontalDotsLoader.json';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { Modal } from 'react-bootstrap';
import { logEvent } from '../../utils/FirebaseAnalyticsUtils';
import { CHAT_GPT } from '../../services/ApiUrls';

const AskMlChatWidget = ({ uniqueUUID = '' }) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const chatGptConfig = useSelector(selectChatGptConfig);
  const [gptData, setGptdata] = useState([]);
  const [showInputArea, setShowInputArea] = useState(true);
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState('');
  const dispatch = useDispatch();
  const employeeData = useSelector(selectEmployeeData);
  const [chatText, setChatText] = useState('');
  const bodyContainerRef = useRef(null);
  const [isRendered, setIsRendered] = useState(true);
  const verticalLoaderStyleConfig = { cursor: 'default' };
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const profinityFilterWarning = useSelector(selectProfinityFilterWarning);
  const MAX_CHAR_LIMIT = useSelector(selectMultilineTextSize);
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    if (bodyContainerRef.current) {
      bodyContainerRef.current.scrollTop = bodyContainerRef.current.scrollHeight;
    }
  }, [gptData]);

  const togglePrompt = () => {
    setShowPrompt(!showPrompt);
  };

  const handleAsk = useCallback(
    async (q = '') => {
      if (prompt) {
        q = prompt;
      }
      setIsRendered(false);

      if (typeof q === 'string' && q.trim()?.length === 0) {
        showToast('Enter some prompt');
        setPrompt('');
        setShowInputArea(true);
        return;
      }
      const hasProfanity = getProfanityFilter().isProfane(q);
      if (hasProfanity) {
        setPrompt('');
        setShowWarningDialog(true);
        setShowInputArea(true);
        setIsRendered(true);
        return;
      }
      if (Object.keys(chatGptConfig).length === 0) {
        showToast('Chat gpt configurations are empty!');
        setShowInputArea(true);
        return;
      }
      let params = {
        employee_code: employeeData?.employee_id,
        env: uniqueUUID,
        query: `${q}`,
      };
      try {
        setShowInputArea(false);
        setLoading(true);
        setGptdata((data) => [...data, { type: 'ques', data: q, rendered: false }]);
        logEvent(ANALYTICS_EVENT_TYPES.ASK_ML_AI_SEARCH, q, ANALYTICS_ITEM_NAMES.SEARCH);
        const apiResponse = await apiRequest(CHAT_GPT, REQUEST_TYPE.GET, params);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            setLoading(false);

            setGptdata((data) => [
              ...data,
              { type: 'ans', data: apiResponse?.response?.data?.answer?.trim(), rendered: false },
            ]);
          }
        }
        setPrompt('');
      } catch (err) {
        handleError(err, params, CHAT_GPT, NAVIGATION_ROUTES.ASK_ORG);
        setIsRendered(true);
        setShowInputArea(true);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        showToast(ERROR_MESSAGES.DEFAULT);
        setLoading(false);
      }
    },
    [chatGptConfig, dispatch, employeeData?.employee_id, prompt, uniqueUUID]
  );

  useEffect(() => {
    const lastItem = gptData[gptData.length - 1];
    if (lastItem && lastItem.type === 'ans') {
      setShowInputArea(true);
    }
  }, [gptData]);

  const onChangeChatTextHandler = (event) => {
    setChatText(event.target.value);
  };

  const getDefaultProfile = () => {
    const defaultProfileUrl = getFeatureComponentUrl(
      accessibilityData,
      ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_DEFAULT_PROFILE,
      ACCESSIBILITY_IDENTIFIERS.COMMON
    );
    return defaultProfileUrl;
  };

  const renderHeader = () => {
    return (
      <div className="p-3 askml-chatbot-header d-flex flex-row">
        <img
          className="askml-chat-widget-header-icon mr-3 common-cursor-pointer"
          src={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.CHAT_BOT_WHITE,
            ACCESSIBILITY_IDENTIFIERS.ASK_ORG
          )}
        />
        <div>
          <div className="ask-ml-chat-bot-header-title">ASK ML AI</div>
          <div className="ask-ml-chat-bot-header-subtitle">
            Welcome {employeeData?.first_name + ' ' + employeeData?.last_name}
          </div>
        </div>
        {/* Ask ml suggestion prompt commented for sprint 55 release */}
        {/* <div
          className="ml-auto d-flex flex-row justify-content-center align-items-center"
          onClick={() => {
            if (!showInputArea || isRendered === false) {
              null;
            } else {
              togglePrompt();
            }
          }}>
          <img
            className="askml-chat-widget-header-right-icon common-cursor-pointer"
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.QUESTION_MARK_WHITE,
              ACCESSIBILITY_IDENTIFIERS.ASK_ORG
            )}
          />
        </div> */}
      </div>
    );
  };

  const promptModal = () => {
    return (
      <div className="ask-ml-prompt-container">
        <div className="d-flex flex-row justify-content-between p-2 mb-n3">
          <p className="ask-ml-prompt-header">Frequently Asked Questions</p>
          <div>
            <img
              onClick={() => setShowPrompt(false)}
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.DIRECTORY_MODAL_CLOSE,
                ACCESSIBILITY_IDENTIFIERS.DIRECTORY
              )}
              className="my-profile-cross-icon common-cursor-pointer mb-1"
            />
          </div>
        </div>
        <div className="ask-ml-prompt-content-container">
          {chatGptConfig?.category_wise_prompts_list.map((category, index) => (
            <div key={`${index}`}>
              <div className="p-2 ask-ml-prompt-title-container ask-ml-prompt-header">
                {category?.title}
              </div>
              {[...(category?.prompts || [])].map((prompt, index) => (
                <div
                  key={`${index}`}
                  className={
                    category?.prompts?.length - 1 !== index ? 'ask-ml-prompt-border p-2' : 'p-2'
                  }>
                  <div
                    onClick={() => {
                      togglePrompt();
                      handleAsk(prompt);
                    }}
                    className="ask-ml-prompt-text common-cursor-pointer">
                    {prompt}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div
        ref={bodyContainerRef}
        className="ask-ml-chat-body-container"
        style={{
          overflowY: 'auto',
        }}>
        {gptData?.length === 0 ? (
          <div className="d-flex flex-column align-items-center justify-content-center mt-5">
            <img
              src={getFeatureComponentUrlXxxHdpi(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.CHAT_BOT,
                ACCESSIBILITY_IDENTIFIERS.ASK_ORG
              )}
              className="ask-org-chat-widget-bot-icon common-cursor-pointer mt-2 mb-1"
            />
            {chatGptConfig?.welcome_note_lists?.map((item, index) => (
              <div className="mx-2 ask-ml-welcome-note-text-style" key={`${index}`}>
                {item}
              </div>
            ))}
          </div>
        ) : (
          gptData?.map((item, index) => {
            return (
              <div
                key={`${index}`}
                style={{
                  backgroundColor: item?.type === 'ans' ? '#E9E6E6' : '#dbe9f0',
                }}
                className="ask-ml-chat-bubble d-flex flex-row p-3">
                {item?.type === 'ques' && (
                  <img
                    src={
                      employeeData.profile_picture.base_url &&
                      employeeData.profile_picture.image_path
                        ? employeeData.profile_picture.base_url +
                          employeeData.profile_picture.image_path
                        : getDefaultProfile()
                    }
                    className="ask-ml-chat-profile-icon mr-2"
                  />
                )}
                {item?.type === 'ans' && (
                  <img
                    src={getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_ML_ICON,
                      ACCESSIBILITY_IDENTIFIERS.COMMON
                    )}
                    className="ask-ml-chat-profile-icon mr-2"
                  />
                )}
                {item?.type === 'ques' && (
                  <div className="d-flex flex-row align-items-center ask-ml-chat-bot-qa-text">
                    {item?.data || ''}
                  </div>
                )}
                {item?.type === 'ans' && (
                  <ChatMessage text={item?.data || ''} onRendered={setIsRendered} />
                )}
              </div>
            );
          })
        )}
        {!showInputArea && (
          <div
            style={{
              backgroundColor: '#E9E6E6',
            }}
            className="ask-ml-chat-bubble d-flex flex-row p-3">
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_ML_ICON,
                ACCESSIBILITY_IDENTIFIERS.COMMON
              )}
              className="ask-ml-chat-profile-icon"
            />
            <Lottie
              height={24}
              width={24}
              options={{
                loop: true,
                autoplay: true,
                animationData: horizontalDotsLoaderAnimation,
              }}
              isClickToPauseDisabled
              style={{ marginLeft: '10px' }}
            />
          </div>
        )}
      </div>
    );
  };

  const renderChatInput = () => {
    return (
      <div className="chat-input-main-container">
        <div className="d-flex m-2 align-items-center chat-texinput-outer-container">
          <form
            className="directory-search-form"
            onSubmit={(e) => {
              e.preventDefault();
              if (chatText.trim() === '') {
                showToast(ERROR_MESSAGES.EMPTY_TEXT);
              } else if (!showInputArea || isRendered === false) {
                null;
              } else if (chatText > MAX_CHAR_LIMIT) {
                showToast('Maximum characters to be entered is' + MAX_CHAR_LIMIT);
              } else {
                handleAsk(chatText);
                setChatText('');
              }
            }}>
            <input
              value={chatText}
              className="ask-ml-chat-textinput p-2"
              placeholder="Type a message..."
              spellCheck="false"
              maxLength={MAX_CHAR_LIMIT}
              onChange={(event) => onChangeChatTextHandler(event)}
            />
          </form>
          <div className="common-cursor-pointer mr-2">
            {!showInputArea || isRendered === false ? (
              <Lottie
                height={24}
                width={24}
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: horizontalDotsLoaderAnimation,
                }}
                isClickToPauseDisabled
                style={verticalLoaderStyleConfig}
              />
            ) : (
              <img
                onClick={(e) => {
                  e.preventDefault();
                  if (chatText.trim() === '') {
                    showToast(ERROR_MESSAGES.EMPTY_TEXT);
                  } else if (chatText > MAX_CHAR_LIMIT) {
                    showToast('Maximum characters to be entered is' + MAX_CHAR_LIMIT);
                  } else {
                    handleAsk(chatText);
                    setChatText('');
                  }
                }}
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.SEND_COMMENT_BLUE,
                  ACCESSIBILITY_IDENTIFIERS.ASK_ORG
                )}
                className="ask-ml-chat-send-icon"
              />
            )}
          </div>
        </div>
      </div>
    );
  };
  const warningDialog = () => {
    return (
      <div>
        <div className="common-comment-box-profinity-warning-text text-center imagesReview mt-2 mb-3">
          WARNING!
        </div>
        <div className="common-comment-box-profinity-text text-center imagesReview mt-2 mb-3">
          {profinityFilterWarning}
        </div>
        <div className="text-center">
          <CustomButton
            buttonStyle="common-comment-box-profinity-button mt-2"
            onClick={() => {
              setShowWarningDialog(false);
            }}>
            OK
          </CustomButton>
        </div>
      </div>
    );
  };

  return (
    <div
      className="widget-container"
      style={{
        backgroundImage: `url(
      ${getFeatureComponentUrl(
        accessibilityData,
        ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_HEXAGON_WALLPAPER,
        ACCESSIBILITY_IDENTIFIERS.COMMON
      )}
    )`,
      }}>
      <Modal
        show={showWarningDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowWarningDialog(false)}>
        <Modal.Body>{warningDialog()}</Modal.Body>
      </Modal>
      {renderHeader()}
      {renderBody()}
      <div
        style={{
          position: 'absolute',
          bottom: 5,
          width: '100%',
        }}>
        {renderChatInput()}
      </div>
      {showPrompt && promptModal()}
    </div>
  );
};

export default AskMlChatWidget;
