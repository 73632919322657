import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import { selectProgressLoadingState } from '../../../state/UIState';
import { formatDateDDMonthYYYY, getUrlWithSpecificRendition } from '../../../utils/common';
import { SCREEN_DENSITY_QUALIFIERS } from '../../../common/constants/AppConstants';

const BreakoutsListItem = ({ onPressJoinButton, item, onPressItem }) => {
  const [isJoinButtonVisible, setJoinButtonVisible] = useState(true);
  const [isAdminApprovalTextVisible, setAdminApprovalTextVisible] = useState(false);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    if (item.is_member) {
      if (!item.approved && !item.rejected) {
        setJoinButtonVisible(false);
        setAdminApprovalTextVisible(true);
      } else if (item.approved && !item.rejected) {
        setJoinButtonVisible(false);
        setAdminApprovalTextVisible(false);
      } else if (!item.approved && item.rejected) {
        setJoinButtonVisible(true);
        setAdminApprovalTextVisible(false);
      }
    } else {
      setJoinButtonVisible(true);
      setAdminApprovalTextVisible(false);
    }
  }, [item.approved, item.is_member, item.rejected]);

  return (
    <div className="col-sm-4 mb-3 common-cursor-pointer">
      <div>
        <img
          src={getUrlWithSpecificRendition(
            item.promo_image.base_url,
            SCREEN_DENSITY_QUALIFIERS.HDPI,
            item.promo_image.image_path
          )}
          className="embed-responsive embed-responsive-16by9"
          onClick={() => onPressItem(item)}
        />
        <div onClick={() => onPressItem(item)} className="breakouts-title">
          {item.title}
        </div>
        <div className="row justify-content-between">
          <div
            onClick={() => onPressItem(item)}
            className="ml-3 breakouts-short-description">{`${formatDateDDMonthYYYY(
            item.start_date
          )} - ${formatDateDDMonthYYYY(item.end_date)}`}</div>
          {isJoinButtonVisible && (
            <CustomButton
              buttonStyle="breakouts-button-style mr-3"
              onClick={() => onPressJoinButton(item.breakout_id)}
              showLoader={progressLoadingState.isProgressLoading}>
              Join
            </CustomButton>
          )}
        </div>
        {isAdminApprovalTextVisible && (
          <div className="breakouts-waiting-admin-approval-text">
            {'Waiting for admin approval'.toUpperCase()}
          </div>
        )}
      </div>
    </div>
  );
};

export default BreakoutsListItem;
