import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ACTION_TYPES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  NO_DATA_TEXT,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { GLOBAL_SEARCH_FILTER } from '../../services/ApiUrls';
import { apiRequest } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { saveShowEmptyContentView, selectBaseUrl } from '../../state/UIState';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { Tab } from 'react-bootstrap';
import { saveGlobalSearchTabData, selectGlobalSearchTabData } from '../../state/TabData';
import BroadcastItem from './components/BroadcastItem';
import ArticleItem from './components/ArticleItem';
import NewsItem from './components/NewsItem';
import PolicyItem from './components/PolicyItem';
import WallItem from './components/WallItem';
import CeoChatItem from './components/CeoChatItem';
import OurCustomersItem from './components/OurCustomersItem';
import ActivityLoader from '../../common/ui/activity_loader/ActivityLoader';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import NavTabs from '../../common/ui/tabs/NavTabs';
import DirectoryItem from './components/DirectoryItem';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { useHistory } from 'react-router-dom';
import MLTVItem from './components/MLTVItem';

const GlobalSearchMainScreen = ({ location }) => {
  const queryParams = location?.hash ? location.hash : location.state && location.state.searchText;
  const activeTabCategory = location?.state?.activeTab ?? '';
  const baseUrl = useSelector(selectBaseUrl);
  const globalSearchTabData = useSelector(selectGlobalSearchTabData);
  const [id] = useState(new URLSearchParams(location.search).get('id'));
  const dispatch = useDispatch();
  const [allFeeds, setAllfeeds] = useState([]);
  const [feedTypes, setFeedTypes] = useState([]);
  const [query, setQuery] = useState();
  const [loader, setLoader] = useState();
  const [message, setMessage] = useState(null);
  const [isSearchEnabled, setIsSearchEnabled] = useState(true);
  const accessibilityData = useSelector(selectAccessibilityData);
  const inputRef = useRef();
  const loadMoreFlag = useRef(true);
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  const pageId = useRef(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const history = useHistory();

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.GLOBAL_SEARCH, NAVIGATION_ROUTES.GLOBAL_SEARCH);
  }, []);

  useEffect(() => {
    if (baseUrl !== '' && id && id !== '') {
      dispatch({ type: ACTION_TYPES.GET_TOKEN, payload: { id: id } });
    }
  }, [baseUrl, dispatch, id]);

  const updateSearchText = useCallback(() => {
    const updatedState = {
      ...location.state,
      searchText: query,
    };
    const updatedLocation = {
      ...location,
      state: updatedState,
    };
    history.replace(updatedLocation);
  }, [history, location, query]);

  const onTextChange = (event) => {
    setQuery(event.target.value);
  };

  useEffect(() => {
    if (feedTypes && feedTypes.length > 0) {
      if (activeTabCategory !== '') {
        dispatch(saveGlobalSearchTabData(activeTabCategory));
      } else {
        dispatch(saveGlobalSearchTabData(feedTypes[0].category));
      }
    }
  }, [activeTabCategory, dispatch, feedTypes]);

  const loadMore = () => {
    if (loadMoreFlag.current) {
      setLoadingMore(true);
      pageId.current = pageId.current + 1;
      searchFeed(query, pageId.current);
    }
  };

  const searchFeed = useCallback(
    async (query_field, page_id = 1) => {
      if (page_id === 1) {
        dispatch(saveShowEmptyContentView(true));
        setAllfeeds(null);
        setFeedTypes(null);
        setMessage(null);
      }

      let searchQuery;
      if (query_field) {
        searchQuery = query_field;
      }

      let payload = {
        query: searchQuery,
        news: 'YES',
        articles: 'YES',
        policies: 'YES',
        cmdchat: 'YES',
        wall: 'YES',
        customers: 'YES',
        quickSearch: 'NO',
        directory: 'YES',
        broadcasts: 'YES',
        mltv: 'YES',
        page_id: page_id,
      };

      const apiResponse = await apiRequest(GLOBAL_SEARCH_FILTER, REQUEST_TYPE.GET, payload);
      let result = {};
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          result = apiResponse.response;
          if (result.feeds.length > 0) {
            if (page_id === 1) {
              setAllfeeds(result.feeds);
            } else {
              setAllfeeds((prev) => [...prev, ...result.feeds]);
            }
            //setAllfeeds(result.feeds);
            setLoader(false);
            setLoadingMore(false);
            setMessage(null);
            loadMoreFlag.current = true;
            setLoadMoreBtn(true);
            if (result.feeds.length > 0 && page_id === 1) {
              let feedTypesVal = result.feeds.map((feed, index) => {
                return feed.type;
              });
              feedTypesVal = Array.from(new Set(feedTypesVal));
              let temp = [];
              setFeedTypes([]);
              feedTypesVal.forEach((type, index) => {
                try {
                  if (type.indexOf('customer') > -1) {
                    temp[index] = { category: 'customer', name: 'Our Customer' };
                  }
                  if (type.indexOf('chat') > -1) {
                    temp[index] = { category: 'chat', name: 'CEO Chat' };
                  }
                  if (type.indexOf('news') > -1) {
                    temp[index] = { category: 'news', name: 'Microland in The News' };
                  }
                  if (type.indexOf('policy') > -1) {
                    temp[index] = { category: 'policy', name: 'Policies' };
                  }
                  if (type.indexOf('article') > -1) {
                    temp[index] = { category: 'article', name: 'Dear Microlander' };
                  }
                  if (type.indexOf('broadcasts') > -1) {
                    temp[index] = { category: 'broadcasts', name: 'Broadcasts' };
                  }
                  if (type.indexOf('mltv') > -1) {
                    temp[index] = { category: 'mltv', name: 'Microland TV' };
                  }
                  if (type.indexOf('wall') > -1) {
                    temp[index] = { category: 'wall', name: 'The Wall' };
                  }
                  if (type.indexOf('directory') > -1) {
                    temp[index] = { category: 'directory', name: 'Directory' };
                  }
                } catch (error) {
                  //error
                }
              });
              const uniqueFeedType = [
                ...new Map(temp.map((item) => [item['category'], item])).values(),
              ];
              feedTypesVal = uniqueFeedType;
              setFeedTypes((prev) => [...prev, ...feedTypesVal]);
            }
          } else {
            setLoader(false);
            if (page_id === 1) {
              let message = result.status ? 'No results found.' : result.message;
              setAllfeeds(null);
              setFeedTypes(null);
              setMessage(message);
            }
            loadMoreFlag.current = false;
            setLoadMoreBtn(false);
            setLoadingMore(false);
          }
        }
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (queryParams) {
      setQuery(queryParams);
      pageId.current = 1;
      setLoader(true);
      searchFeed(queryParams);
    }
  }, [queryParams, searchFeed]);

  const searchFeedonClick = useCallback(
    (event) => {
      event.preventDefault();
      setLoader(true);
      pageId.current = 1;
      searchFeed(query);
      updateSearchText();
    },
    [query, searchFeed, updateSearchText]
  );

  const filterDataFunc = (feedType) => {
    const data = allFeeds;

    if (data && data.length > 0) {
      const filteredArr = data.filter((feed) => {
        return feed.type.toLowerCase().indexOf(feedType.toLowerCase()) > -1;
      });
      return filteredArr;
    } else {
      return null;
    }
  };

  const resetSearchHandler = useCallback(() => {
    setQuery('');
    inputRef.current.focus();
  }, []);

  const onClickSearchHandler = () => {
    setIsSearchEnabled(true);
  };

  const renderTabs = (tab) => {
    switch (tab.category) {
      case 'news':
        return <NewsItem feed={filterDataFunc(tab.category)} />;
      case 'broadcasts':
        return <BroadcastItem feed={filterDataFunc(tab.category)} />;
      case 'mltv':
        return <MLTVItem feed={filterDataFunc(tab.category)} />;
      case 'article':
        return <ArticleItem feed={filterDataFunc(tab.category)} />;
      case 'policy':
        return <PolicyItem feed={filterDataFunc(tab.category)} />;
      case 'wall':
        return <WallItem feed={filterDataFunc(tab.category)} />;
      case 'customer':
        return <OurCustomersItem feed={filterDataFunc(tab.category)} />;
      case 'chat':
        return <CeoChatItem feed={filterDataFunc(tab.category)} />;
      case 'directory':
        return <DirectoryItem feed={filterDataFunc(tab.category)} />;
      default:
        break;
    }
  };

  const emptyListView = () => {
    return (
      <DefaultContentView
        message={NO_DATA_TEXT.NO_DATA_FOUND}
        iconUri={getFeatureComponentUrl(
          accessibilityData,
          ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
          ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
        )}
      />
    );
  };

  return (
    <div className="container">
      <BackButton />
      {baseUrl !== '' && (
        <div>
          <div>
            <div className="header-view d-flex justify-content-between">
              {isSearchEnabled ? (
                <form className="thewall-search-form" onSubmit={searchFeedonClick}>
                  <input
                    ref={inputRef}
                    autoFocus
                    className="ml-3 thewall-search-bar"
                    type="text"
                    placeholder="Search.."
                    onChange={onTextChange}
                    value={query ? query : ''}
                  />
                </form>
              ) : (
                <div />
              )}
              {isSearchEnabled ? (
                <div onClick={resetSearchHandler}>
                  <img
                    src={getHeaderComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
                    )}
                    className="header-icon mr-3"
                  />
                </div>
              ) : (
                <div onClick={onClickSearchHandler}>
                  <img
                    src={getHeaderComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_HEADER_COMPONENTS.SEARCH
                    )}
                    className="header-icon mr-3"
                  />
                </div>
              )}
            </div>
          </div>
          <div>
            {loader ? (
              <div className="home-loader-outer-view">
                <div className="home-loader-outer-view mt-3">
                  <ActivityLoader visible={true} />
                </div>
              </div>
            ) : message ? (
              emptyListView()
            ) : (
              <div>
                {feedTypes && feedTypes.length > 0 && (
                  <NavTabs
                    activeKey={globalSearchTabData}
                    onSelect={(k) => dispatch(saveGlobalSearchTabData(k))}
                    className="global-search-tabs">
                    {feedTypes.map((tab, index) => {
                      return (
                        <Tab
                          key={`${index}`}
                          eventKey={tab.category}
                          title={tab.name}
                          className="main-content-container">
                          <div>
                            {renderTabs(tab, index)}
                            {loadMoreBtn && (
                              <div className="mb-4 mt-4">
                                <LoadMoreBtn onClick={loadMore} isLoading={loadingMore} />
                              </div>
                            )}
                          </div>
                        </Tab>
                      );
                    })}
                  </NavTabs>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GlobalSearchMainScreen;
