import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ERROR_MESSAGES,
  FILTER_IDENTIFIERS,
  MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../../common/constants/AppConstants';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import Filter from '../../../common/ui/filter/Filter';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { COMMUNITIES_BY_ID } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { selectCommunityData } from '../../../state/CommunityData';
import { selectCommunityPostTagPeopleLimit } from '../../../state/MasterData';
import { saveProgressLoadingState } from '../../../state/UIState';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../../utils/accessibility';
import { showToast } from '../../../utils/common';
import debounce from 'lodash.debounce';

const TagEmployeeView = ({ onEmployeeSelected, tags }) => {
  const dispatch = useDispatch();
  const [isSearchEnabled, setIsSearchEnabled] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeArray, setEmployeeArray] = useState([]);
  const [searchText, setSearchText] = useState('');
  const appliedFiltersRef = useRef({});
  const accessibilityData = useSelector(selectAccessibilityData);
  const communityData = useSelector(selectCommunityData);
  const communityPostTagPeopleLimit = useSelector(selectCommunityPostTagPeopleLimit);
  const pageIdRef = useRef(1);

  useEffect(() => {
    setEmployeeArray(tags);
  }, [tags]);

  const addEmployee = useCallback(
    (employee) => {
      if (employeeArray.some((person) => person.employee_id === employee.employee_id)) {
        const index = employeeArray.findIndex(
          (person) => person.employee_id === employee.employee_id
        );
        if (index > -1) {
          setEmployeeArray(employeeArray.filter((item) => item !== employeeArray[index]));
        }
      } else {
        if (employeeArray.length < communityPostTagPeopleLimit) {
          setEmployeeArray((prev) => [...prev, employee]);
        } else {
          showToast(`${MESSAGES.CANNOT_TAG(communityPostTagPeopleLimit)}`);
        }
      }
    },
    [communityPostTagPeopleLimit, employeeArray]
  );

  const callEmployeesApi = useCallback(
    async (text = '') => {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      if (pageIdRef.current == 1) {
        setEmployeeData([]);
      }
      let params = { type: 'tag' };
      params = { ...params, ...{ query: text } };
      if (Object.keys(appliedFiltersRef.current).length) {
        params = { ...params, ...appliedFiltersRef.current };
      }
      try {
        const apiResponse = await apiRequest(
          COMMUNITIES_BY_ID + communityData.community_id + '/members',
          REQUEST_TYPE.GET,
          params
        );
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            if (apiResponse.response.data.members.length > 0) {
              setEmployeeData(apiResponse.response.data.members);
            }
          }
        }
      } catch (err) {
        handleError(
          err,
          params,
          COMMUNITIES_BY_ID + communityData.community_id + '/members',
          NAVIGATION_ROUTES.COMMUNITIES
        );
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    },
    [communityData.community_id, dispatch]
  );

  useEffect(() => {
    callEmployeesApi('');
  }, [callEmployeesApi]);

  const onClickFilterHandler = (selectedFilters) => {
    let tempFilterObject = {};
    Object.keys(selectedFilters).map((filterKey) => {
      if (selectedFilters[filterKey].length) {
        tempFilterObject[filterKey] = selectedFilters[filterKey].join();
      }
    });
    appliedFiltersRef.current = tempFilterObject;
    setEmployeeData([]);
    setEmployeeArray([]);
    callEmployeesApi(searchText);
  };

  const debouncedCallback = (event) => {
    if (event && event.target.value.length > 2) {
      callEmployeesApi(event.target.value);
    }
    setSearchText(event.target.value);
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );

  const onSearchSubmitHandler = (event) => {
    event.preventDefault();
    setEmployeeData([]);
    callEmployeesApi(searchText);
  };

  const onClickSearchHandler = () => {
    setIsSearchEnabled(true);
  };

  const resetSearchHandler = useCallback(() => {
    setIsSearchEnabled(false);
    setSearchText('');
    setEmployeeData([]);
    callEmployeesApi('');
  }, [callEmployeesApi]);

  const showProfiles = () => {
    if (employeeData.length > 0) {
      return (
        <div className="row">
          {employeeData.map((profile, index) => {
            return (
              <div key={`${index}`} className="col-6 col-sm-3 col-lg-2 mb-4">
                <UserProfilePhoto
                  imageBaseUrl={profile.profile_picture.base_url}
                  imagePath={profile.profile_picture.image_path}
                  employeeId={profile.employee_id}
                  imageClass="common-user-profile-photo-lg"
                />
                <p className="anniversary-profile-name">{profile.first_name}</p>
                <p className="anniversary-profile-name">{profile.last_name}</p>
                <div className="d-flex align-items-center justify-content-center">
                  <input
                    type="checkbox"
                    id="employeeCheck"
                    name="employeeCheck"
                    className="anniversary-employee-check-box"
                    onChange={() => addEmployee(profile)}
                    checked={
                      employeeArray.some((person) => person.employee_id === profile.employee_id)
                        ? true
                        : false
                    }
                  />
                  <div className="text-center">Tag</div>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else
      return (
        <DefaultContentView
          message={ERROR_MESSAGES.SEARCH_NO_RESULTS}
          iconUri={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
            ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
          )}
        />
      );
  };

  return (
    <div className="mt-3 mb-3">
      <div className="header-view d-flex justify-content-between">
        {isSearchEnabled ? (
          <form className="communities-search-form" onSubmit={onSearchSubmitHandler}>
            <input
              autoFocus
              className="ml-3 communities-search-bar"
              type="text"
              placeholder="Search.."
              onChange={debounceInputHandler}
            />
          </form>
        ) : (
          <div />
        )}
        {isSearchEnabled ? (
          <div onClick={resetSearchHandler}>
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
              )}
              className="header-icon mr-3"
            />
          </div>
        ) : (
          <div onClick={onClickSearchHandler}>
            <img
              src={getHeaderComponentUrl(accessibilityData, ACCESSIBILITY_HEADER_COMPONENTS.SEARCH)}
              className="header-icon mr-3"
            />
          </div>
        )}
      </div>
      <div className="anniversary-body-container">
        <Filter
          topFilter={FILTER_IDENTIFIERS.GEOGRAPHIES}
          geographies={true}
          locations={true}
          levels={true}
          layers={true}
          serviceTenures={true}
          bloodGroups={true}
          onClick={onClickFilterHandler}
        />
      </div>
      <div className="ml-4 mb-3">
        {employeeArray.length < communityPostTagPeopleLimit && (
          <div className="communities-tag-member">{`${employeeArray.length}/${communityPostTagPeopleLimit} selected`}</div>
        )}
        {employeeArray.length === communityPostTagPeopleLimit && (
          <div className="communities-tag-people-limit">{`${employeeArray.length}/${communityPostTagPeopleLimit} selected`}</div>
        )}
      </div>
      <div className="anniversary-employee-view mt-2">{showProfiles()}</div>
      {employeeArray.length > 0 && (
        <CustomButton buttonStyle="w-100 py-1" onClick={() => onEmployeeSelected(employeeArray)}>
          Continue
        </CustomButton>
      )}
    </div>
  );
};

export default TagEmployeeView;
