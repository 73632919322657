import { createAction, createSlice } from '@reduxjs/toolkit';

const signOutAction = createAction('signout');

const saveCommunityDataReducer = (state, action) => {
  state.communityData = action.payload;
};

// REDUCER
const communityDataSlice = createSlice({
  name: 'communityData',
  initialState: {
    communityData: {},
  },
  extraReducers: {
    [signOutAction]: (state) => {
      return {
        ...state,
        communityData: {},
      };
    },
  },
  reducers: {
    saveCommunityData: saveCommunityDataReducer,
  },
});

// ACTIONS
const { saveCommunityData } = communityDataSlice.actions;

// SELECTOR
const selectCommunityData = ({ communityData }) => communityData.communityData;

const communityDataSliceReducer = communityDataSlice.reducer;

export { communityDataSliceReducer, communityDataSlice, saveCommunityData, selectCommunityData };
