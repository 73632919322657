import React, { useEffect } from 'react';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';

const PledgeThanksScreen = ({ history }) => {
  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.PLEDGE_THANKS_SCREEN,
      NAVIGATION_ROUTES.PLEDGE_THANKS_SCREEN
    );
  }, []);

  const onPress = () => {
    history.go(-3);
  };

  return (
    <div className="container ">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY} />
      <div className="main-content-container">
        <div className="anniversary-i-pledge-text">
          Thank you for taking this pledge with me and our fellow Microlanders!
        </div>
        <div className="anniversary-i-pledge-text mt-2">
          To mark this 30-year journey, let’s share moments celebrating our colleagues, customers,
          partners, and even family helping out in a time of need. The best moments captured will be
          commemorated further.
        </div>
        <div className="anniversary-i-pledge-text mt-2">
          We’ve had an incredible ride so far. We could not have become who we are, certainly ‘not
          without you’. We’re looking forward to another 30 years and we know one thing. It’s ‘Not
          Without You’!
        </div>
        <CustomButton buttonStyle="anniversary-take-pledge-btn mr-3" onClick={onPress}>
          Next
        </CustomButton>
      </div>
    </div>
  );
};

export default PledgeThanksScreen;
