import React, { useEffect } from 'react';

import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
} from '../../../common/constants/AppConstants';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import CustomTextInput from '../../../common/ui/custom_text_input/CustomTextInput';

const MySocialAccountsTab = ({ formikProps }) => {
  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.MY_PROFILE_SOCIAL_ACCOUNTS_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.MY_PROFILE
    );
  }, []);

  return (
    <div className="common-container-md mt-3 mb-3">
      <CustomTextInput
        name="linkedin_url"
        title="LinkedIn"
        errorMessage={formikProps.errors.linkedin_url}
        placeholder="https://www.linkedin.com/in/xyz"
        type="url"
        value={formikProps.values.linkedin_url}
        onBlur={formikProps.handleBlur}
        onChange={formikProps.handleChange}
        style="my-profile-edit-editable-text-input"
        isValid={
          !!formikProps.submitCount &&
          formikProps.touched.linkedin_url &&
          !formikProps.errors.linkedin_url
        }
        isInvalid={
          !!formikProps.submitCount &&
          formikProps.touched.linkedin_url &&
          !!formikProps.errors.linkedin_url
        }
      />
      <CustomTextInput
        name="twitter_url"
        title="Twitter"
        errorMessage={formikProps.errors.twitter_url}
        placeholder="https://www.twitter.com/xyz"
        type="url"
        value={formikProps.values.twitter_url}
        onBlur={formikProps.handleBlur}
        onChange={formikProps.handleChange}
        style="my-profile-edit-editable-text-input"
        isValid={
          !!formikProps.submitCount &&
          formikProps.touched.twitter_url &&
          !formikProps.errors.twitter_url
        }
        isInvalid={
          !!formikProps.submitCount &&
          formikProps.touched.twitter_url &&
          !!formikProps.errors.twitter_url
        }
      />
      <CustomTextInput
        name="facebook_url"
        title="Facebook"
        errorMessage={formikProps.errors.facebook_url}
        placeholder="https://www.facebook.com/name/"
        type="url"
        value={formikProps.values.facebook_url}
        onBlur={formikProps.handleBlur}
        onChange={formikProps.handleChange}
        style="my-profile-edit-editable-text-input"
        isValid={
          !!formikProps.submitCount &&
          formikProps.touched.facebook_url &&
          !formikProps.errors.facebook_url
        }
        isInvalid={
          !!formikProps.submitCount &&
          formikProps.touched.facebook_url &&
          !!formikProps.errors.facebook_url
        }
      />
      <CustomTextInput
        name="instagram_url"
        title="Instagram"
        errorMessage={formikProps.errors.instagram_url}
        placeholder="https://www.instagram.com/handle-name"
        type="url"
        value={formikProps.values.instagram_url}
        onBlur={formikProps.handleBlur}
        onChange={formikProps.handleChange}
        style="my-profile-edit-editable-text-input"
        isValid={
          !!formikProps.submitCount &&
          formikProps.touched.instagram_url &&
          !formikProps.errors.instagram_url
        }
        isInvalid={
          !!formikProps.submitCount &&
          formikProps.touched.instagram_url &&
          !!formikProps.errors.instagram_url
        }
      />
      <CustomTextInput
        name="website_url"
        title="Website"
        errorMessage={formikProps.errors.website_url}
        placeholder="https://www.microland.com"
        type="url"
        value={formikProps.values.website_url}
        onBlur={formikProps.handleBlur}
        onChange={formikProps.handleChange}
        style="my-profile-edit-editable-text-input"
        isValid={
          !!formikProps.submitCount &&
          formikProps.touched.website_url &&
          !formikProps.errors.website_url
        }
        isInvalid={
          !!formikProps.submitCount &&
          formikProps.touched.website_url &&
          !!formikProps.errors.website_url
        }
      />
    </div>
  );
};

export default React.memo(MySocialAccountsTab);
