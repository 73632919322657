import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { saveCommunitiesTabData } from '../../../../state/TabData';
import { getFeatureComponent, getFeatureComponentUrl } from '../../../../utils/accessibility';
import { getUrlWithSpecificRendition } from '../../../../utils/common';

const HorizontalIconSmall = ({
  itemId,
  itemIconBaseUrl,
  itemIconImagePath,
  itemTitle,
  sectionIdentifier,
  totalMembers,
  data,
}) => {
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);

  const toLinkHandler = () => {
    const route =
      '/' +
      getFeatureComponent(
        accessibilityData,
        ACCESSIBILITY_FEATURE_COMPONENTS.ROUTE,
        sectionIdentifier
      );
    return `${route}/${itemId}`;
  };

  const onItemPressHandler = () => {
    dispatch(saveCommunitiesTabData(''));
  };

  return (
    <Link
      className="home-horizontal-icon-small-container col-6 col-sm-3 col-lg-2 pt-4 common-unstyled-achor"
      to={toLinkHandler}
      onClick={onItemPressHandler}>
      <img
        className="home-horizontal-icon-small-image img-center"
        src={getUrlWithSpecificRendition(
          itemIconBaseUrl,
          SCREEN_DENSITY_QUALIFIERS.LDPI,
          itemIconImagePath
        )}
      />
      <p className="home-horizontal-icon-small-title">{itemTitle}</p>

      {totalMembers > 1 ? (
        <div className="mt-1 communities-member-text dashboard-community-members-text-alignment">
          {totalMembers} Members
        </div>
      ) : totalMembers === 1 ? (
        <div className="mt-1 communities-member-text dashboard-community-members-text-alignment">
          {totalMembers} Member
        </div>
      ) : (
        <div className="mt-1 communities-member-text dashboard-community-members-text-alignment">
          0 Members
        </div>
      )}
    </Link>
  );
};

export default HorizontalIconSmall;
