import React from 'react';
import Divider from '../../../common/ui/divider/Divider';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { formatDateDDMonthYYYY, formatTimeAMPM } from '../../../utils/common';

const TaggedPeopleListItem = ({ item }) => {
  return (
    <div className="col-sm-12 mb-3">
      <div className="microgive-view-style cheers-date-view-style">
        <UserProfilePhoto
          imageBaseUrl={item.nominated_by.profile_picture.base_url}
          imagePath={item.nominated_by.profile_picture.image_path}
          imageClass="common-user-profile-photo-lg"
          employeeId={item.nominated_by.employee_id}
        />
        <div className="ml-3 microgive-outer-view col-sm-6">
          <div>
            <div className="mb-1 microgive-name">{`${item.nominated_by.first_name} ${item.nominated_by.last_name} `}</div>
            <div className="mt-1 microgive-position-text">{item.nominated_by.position_title}</div>
          </div>
        </div>
        <div className="ml-3 microgive-outer-view">
          <div>
            <div className="mb-1 cheers-date-time-text">{formatTimeAMPM(item.nominated_on)}</div>
            <div className="mt-1 cheers-date-time-text">
              {formatDateDDMonthYYYY(item.nominated_on)}
            </div>
          </div>
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default TaggedPeopleListItem;
