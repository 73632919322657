import React from 'react';
import UserProfilePhoto from '../../../../common/ui/user_profile_photo/UserProfilePhoto';

const ProfileIcon = (props) => {
  return (
    <div className="col-6 col-sm-3 col-lg-2 pt-4 pb-2">
      <UserProfilePhoto
        imageBaseUrl={props.profileData.profile_picture.base_url}
        imagePath={props.profileData.profile_picture.image_path}
        imageClass="common-user-profile-photo-xl mb-2"
        profileData={props.profileData}
      />
      <p className="directory-profile-name">{props.profileData.first_name}</p>
      <p className="directory-profile-name">{props.profileData.last_name}</p>
      {props.sectionIdentifier === '_celebrations' && (
        <p className="directory-profile-subtitle">{props.profileData.type}</p>
      )}
      {props.sectionIdentifier === '_directory' && (
        <p className="directory-profile-subtitle">{props.profileData.position_title}</p>
      )}
    </div>
  );
};

export default ProfileIcon;
