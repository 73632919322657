import React from 'react';
import { useSelector } from 'react-redux';
import { selectUACDenialContent } from '../../../state/MasterData';
import HtmlView from '../html_view/HTMLView';

const AccessDeniedComponent = () => {
  const uACDenialContent = useSelector(selectUACDenialContent);
  return <HtmlView html={uACDenialContent} />;
};

export default AccessDeniedComponent;
