import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  FEATURE_TYPE,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import {
  ARTICLES,
  COMMENT_BY_ID,
  INCREASE_VIEW_COUNT_EXTERNAL_LINK,
  WOMENS_DAY_SURVEY,
} from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import {
  formatDateDDMonthYYYY,
  formatSecondsIntoMinutes,
  formatSecondsIntoTimeForVideos,
  getUrlWithSpecificRendition,
  showToast,
} from '../../../utils/common';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import { saveProgressLoadingState } from '../../../state/UIState';
import SurveysListItem from '../../mi_hr/components/SurveysListItem';
import PlayIconOverlay from '../../../common/ui/play_icon_overlay/PlayIconOverlay';
import { selectAccessData } from '../../../state/UserAccessData';

const CentralTab = (props) => {
  const history = useHistory();
  const employeeData = useSelector(selectEmployeeData);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [articles, setArticles] = useState();
  const [loading, setLoading] = useState(true);
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  const pageId = useRef(1);
  const year = props?.year ?? '';
  const dispatch = useDispatch();
  const [survey, setSurvey] = useState({});
  const accessData = useSelector(selectAccessData);
  const [showIwdLike, setShowLike] = useState(true);
  const [showIwdComment, setShowcomment] = useState(true);
  const iwdAccessData = accessData?.data?.find((item) => item.feature_key === '_womensDay');
  const [showLikeButton, setShowLikeButton] = useState(true);
  const [showCommentButton, setShowCommentButton] = useState(true);
  const [showParticipate, setShowParticipate] = useState(true);
  const pollsAccessData = accessData?.data?.find((item) => item.feature_key === '_polls');

  useEffect(() => {
    const actions = pollsAccessData?.actions;
    setShowLikeButton(actions?.includes('_like'));
    setShowCommentButton(actions?.includes('_comment'));
    setShowParticipate(actions?.includes('_participate'));
  }, [pollsAccessData?.actions]);

  useEffect(() => {
    const actions = iwdAccessData?.actions;
    setShowLike(actions?.includes('_like'));
    setShowcomment(actions?.includes('_comment'));
  }, [iwdAccessData?.actions]);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.WOMENS_DAY_CENTRAL_TAB,
      props?.item?.key,
      ANALYTICS_ITEM_NAMES.WOMENS_DAY
    );
  }, [props.item]);

  const callArticlesApi = useCallback(async () => {
    const params = {
      page_id: pageId.current,
      channel_id: props?.item?.channel_id,
      sub_channel_id: props?.item?.sub_channel_id,
    };
    try {
      setLoading(true);
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(ARTICLES, REQUEST_TYPE.GET, params);
      setLoading(false);
      if (pageId.current === 1) {
        setArticles([]);
      }
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse?.response?.status === true) {
          if (apiResponse?.response?.data) {
            const data = apiResponse?.response?.data?.feeds ?? [];
            dispatch(saveProgressLoadingState({ isProgressLoading: false }));
            if (data?.length > 0) {
              setLoadMoreBtn(true);
              setArticles((prevData) => [...prevData, ...data]);
            } else {
              setLoadMoreBtn(false);
            }
          }
        }
      }
    } catch (err) {
      handleError(err, params, ARTICLES, NAVIGATION_ROUTES.WOMENS_DAY);
      setLoading(false);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [props?.item]);

  const callSurveyApi = useCallback(async () => {
    const params = {
      year: year,
    };
    try {
      setLoading(true);
      const apiResponse = await apiRequest(WOMENS_DAY_SURVEY, REQUEST_TYPE.GET, params);
      setLoading(false);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse?.response?.status === true) {
          if (apiResponse?.response?.data) {
            const data = apiResponse?.response?.data?.survey ?? {};
            if (Object.keys(data).length > 0) {
              setSurvey(data);
            }
          }
        }
      }
    } catch (err) {
      handleError(err, params, WOMENS_DAY_SURVEY, NAVIGATION_ROUTES.WOMENS_DAY);
      setLoading(false);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch, year]);

  useEffect(() => {
    callArticlesApi();
    if (year === 2024) {
      callSurveyApi();
    }
  }, [callArticlesApi, callSurveyApi, year]);

  const callSurveyLikeApi = async (referId) => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
      })
    );
    const params = new URLSearchParams();
    params.append('type', FEATURE_TYPE.SURVEY);
    try {
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + referId + '/like',
        REQUEST_TYPE.POST,
        params
      );

      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );

      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            const message = apiResponse.response.message;

            if (message) {
              showToast(message);
              let updatedSurvey = {
                ...survey,
                liked: data.liked,
                like_count: data.like_count,
              };

              setSurvey(updatedSurvey);
            }
          }
        }
      }
    } catch (err) {
      handleError(err, params, COMMENT_BY_ID + referId + '/like', NAVIGATION_ROUTES.WOMENS_DAY);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      console.error(err);
    }
  };

  const onPressParticipate = (item) => {
    history.push(NAVIGATION_ROUTES.SURVEYS_PARTICIPATE, {
      surveyId: item.survey_id,
    });
  };

  const onPressSurveyLikeButton = (item) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.SURVEY_LIKE,
      item.survey_id.toString(),
      ANALYTICS_ITEM_NAMES.LIKE
    );
    callSurveyLikeApi(item.survey_id);
  };

  const onPressSurveyCommentButton = (item) => {
    history.push(NAVIGATION_ROUTES.COMMENTS, {
      referId: item.survey_id,
      likeType: FEATURE_TYPE.SURVEY,
      fromRoute: NAVIGATION_ROUTES.POLLS_AND_SURVEYS,
    });
  };

  const onPressECards = () => {
    history.push(NAVIGATION_ROUTES.ECARDS, { tabKey: props?.config?.ecard_id });
  };

  const onPressArticles = async (item) => {
    if (item.content_load_type === '1') {
      try {
        const es = await apiRequest(
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          REQUEST_TYPE.GET,
          {}
        );
      } catch (err) {
        handleError(
          err,
          {},
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          NAVIGATION_ROUTES.WOMENS_DAY
        );
        //ignore if the view count request is not successful
        console.warn(err);
      }
      window.open(item.url);
    } else {
      history.push(`${NAVIGATION_ROUTES.DEAR_EMPLOYER}/${item.article_id}`, {
        screenIdetifier: ACCESSIBILITY_IDENTIFIERS.WOMENS_DAY,
        showIwdComment: showIwdComment,
        showIwdLike: showIwdLike,
      });
    }
  };

  const onPressCommunities = () => {
    history.push(`${NAVIGATION_ROUTES.COMMUNITIES_DETAIL}/${props.config.community_id}`);
  };

  const onPressLoadMore = () => {
    pageId.current = pageId.current + 1;
    callArticlesApi();
  };

  return (
    <div>
      <div className="row align-items-center pl-3 flex-nowrap">
        <UserProfilePhoto
          imageBaseUrl={employeeData?.profile_picture?.base_url}
          imagePath={employeeData?.profile_picture?.image_path}
          imageClass="common-user-profile-photo-lg"
          employeeId={employeeData.employee_id}
        />
        <div className="ml-3 cheers-outer-view cheers-center-view">
          <div>
            <div className="cheers-emp-name">
              {employeeData?.first_name +
                `${employeeData?.middle_name ? ' ' + employeeData?.middle_name + ' ' : ' '}` +
                employeeData?.last_name}
            </div>
            <div className="cheers-emp-position">{employeeData?.position_title}</div>
            <div className="cheers-desc-text">{employeeData?.department_name}</div>
          </div>
        </div>
      </div>

      {props.eventType === 'starting' && (
        <div className="womens-day-video-view">
          <div className="womens-day-row-distance">
            <div className="womens-day-event-text">Event starts in</div>
          </div>
          <div className="womens-day-row-distance">
            <div className="womens-day-time-text">
              {props.beforeLiveTimer ? props.beforeLiveTimer : ''}
            </div>

            <div>{props?.config?.hashtag}</div>
            {/* note : commented in sprint 44 as this feature can be re-opened.  */}
            {/* <div
              className="womens-day-live-view common-cursor-pointer"
              onClick={() => {
                history.push(
                  `${NAVIGATION_ROUTES.WOMENS_DAY_LISTING}/${year}${NAVIGATION_ROUTES.WOMENS_DAY_WATCH_VIDEO}`,
                  {
                    youtubeUrl: props.config && props.config.stream_url,
                  }
                );
              }}>
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.WOMENS_DAY_WATCH_LIVE,
                  ACCESSIBILITY_IDENTIFIERS.WOMENS_DAY
                )}
                className="womens-day-live-video-size womens-day-live-video-icon-container"
              />
              <div className="womens-day-live-text">WATCH Live</div>
            </div> */}
          </div>
          {props?.config?.before_streaming_note && (
            <div className="before-streaming-note">{props?.config?.before_streaming_note}</div>
          )}
        </div>
      )}
      {props.eventType === 'end' && (
        <div>
          <div className="womens-day-event-text womens-day-live-now-spacing">
            {props?.config?.hashtag}
          </div>
          <div
            className="womens-day-live-now-view common-cursor-pointer"
            onClick={() => {
              history.push(
                `${NAVIGATION_ROUTES.WOMENS_DAY_LISTING}/${year}${NAVIGATION_ROUTES.WOMENS_DAY_WATCH_VIDEO}`,
                {
                  youtubeUrl: props.config && props.config.stream_url,
                }
              );
            }}>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.WOMENS_DAY_LIVE_VIDEO,
                ACCESSIBILITY_IDENTIFIERS.WOMENS_DAY
              )}
              className="womens-day-play-video-size womens-day-play-video-container"
            />
            <div className="womens-day-watch-text">WATCH NOW</div>
          </div>
        </div>
      )}
      {props.eventType === 'live' && (
        <div>
          <div className="d-flex flex-row justify-content-between mb-2">
            <div className="womens-day-eventText">Event has started</div>
            <div className="womens-day-eventText">{props?.config?.hashtag}</div>
          </div>
          <div
            className="womens-day-live-now-view common-cursor-pointer"
            onClick={() => {
              history.push(
                `${NAVIGATION_ROUTES.WOMENS_DAY_LISTING}/${year}${NAVIGATION_ROUTES.WOMENS_DAY_WATCH_VIDEO}`,
                {
                  youtubeUrl: props.config && props.config.stream_url,
                }
              );
            }}>
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.WOMENS_DAY_PLAY_VIDEO,
                ACCESSIBILITY_IDENTIFIERS.WOMENS_DAY
              )}
              className="womens-day-watch-live-icon-size womens-day-watch-live-icon-container"
            />
            <div className="womens-day-watch-text">Live now</div>
          </div>
        </div>
      )}

      <div>
        {!!props?.config?.description && <p className="mt-2 mb-0">{props?.config?.description}</p>}
      </div>
      <div className="womens-day-central-border" />

      <div className="row">
        {props?.config?.show_community_post === true && (
          <div className="col-sm-4">
            <div
              onClick={(e) => {
                e.stopPropagation();
                onPressCommunities();
              }}>
              <img
                src={
                  props.config && props.config.image_base_url + props.config.community_promo_image
                }
                className="womens-day-image common-cursor-pointer"
              />
              <div className="di-title">{props.config && props.config.community_title}</div>
            </div>
          </div>
        )}
        {year === 2024 && Object.keys(survey)?.length > 0 && (
          <>
            <div className="w-100 common-cursor-pointer">
              <SurveysListItem
                item={survey}
                onPressParticipate={onPressParticipate}
                onPressLikeButton={onPressSurveyLikeButton}
                onPressCommentButton={onPressSurveyCommentButton}
                showLikeButton={showLikeButton}
                showCommentButton={showCommentButton}
                showParticipate={showParticipate}
                fromRoute={NAVIGATION_ROUTES.WOMENS_DAY}
              />
            </div>
            <div className="womens-day-central-survey-border w-100 ml-3" />
          </>
        )}
        {articles &&
          articles.length > 0 &&
          articles.map((item) => {
            return (
              <div
                key={`${item.article_id}`}
                className="col-sm-4 mb-2 common-cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  onPressArticles(item);
                }}>
                <div>
                  {item?.content_load_type === '3' && <PlayIconOverlay />}
                  <img
                    src={getUrlWithSpecificRendition(
                      item.image.base_url,
                      SCREEN_DENSITY_QUALIFIERS.HDPI,
                      item.image.image_path
                    )}
                    className="embed-responsive embed-responsive-16by9"
                  />
                  <div className="row ml-1 mt-1">
                    <div className="d-flex flex-row justify-content-between w-100 pr-3">
                      {!!item?.time_to_read && (
                        <div className="broadcast-event-date-new ">
                          {item.time_to_read != 0
                            ? formatSecondsIntoMinutes(item.time_to_read)
                            : null}
                        </div>
                      )}
                      {!!item?.video_duration &&
                        item?.content_load_type === '3' &&
                        item?.video_duration > 0 && (
                          <div className="broadcast-event-date-new ">
                            {formatSecondsIntoTimeForVideos(item?.video_duration)}
                          </div>
                        )}
                      <div className="broadcast-event-date-new ">
                        {formatDateDDMonthYYYY(item.pub_datetime)}
                      </div>
                      {!!item.page_views && (
                        <div className="broadcast-event-date-new ">
                          {item.page_views > 1
                            ? `${item.page_views} views`
                            : `${item.page_views} view`}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="dear-employer-title">{item.title}</div>
              </div>
            );
          })}
      </div>
      {loadMoreBtn && <LoadMoreBtn onClick={onPressLoadMore} />}
      {props.config.ecards_active && (
        <div className="womens-day-bottom-button-container mt-3">
          <CustomButton buttonStyle="womens-day-bottom-button" onClick={onPressECards}>
            e-Cards
          </CustomButton>
        </div>
      )}
    </div>
  );
};

export default CentralTab;
