import React, { useEffect } from 'react';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
} from '../../../../common/constants/AppConstants';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';

const EBookTab = ({ ebook }) => {
  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.DIGITAL_WARRIOR_EBOOK_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.DIGITAL_WARRIOR
    );
  }, []);

  return (
    <div className="main-content-container">
      <div className="iframe-container">{!!ebook && <iframe src={ebook} allowFullScreen />}</div>
    </div>
  );
};

export default EBookTab;
