import React, { useEffect, useState } from 'react';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../common/constants/AppConstants';
import Divider from '../../common/ui/divider/Divider';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { getUrlWithSpecificRendition } from '../../utils/common';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import MomentTagDetailListItem from './components/MomentTagDetailListItem';

const MomentTagDetailsScreen = ({ location }) => {
  const { item, identifier } = location.state;
  const [discussions] = useState(item.tags);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.MOMENT_TAG_DETAIL_SCREEN,
      NAVIGATION_ROUTES.MOMENT_TAG_DETAIL_SCREEN
    );
  }, []);

  const renderFilters = (item, index) => {
    return <MomentTagDetailListItem item={item} key={`${index}`} />;
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY} />
      <div className="main-content-container">
        {item.image.image_path !== '' && (
          <img
            src={getUrlWithSpecificRendition(
              item.image.base_url + 'images_' + identifier.split('_')[2] + '/images/',
              SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
              item.image.image_path
            )}
            className="col-sm-4 mx-auto d-block mb-3"
          />
        )}
        <div className="microgive-people-tag-text">{`${item.tags.length} Person tagged in this photo`}</div>
        <Divider style="mt-2 mb-2" />
        {discussions.length > 0 && discussions.map((data, index) => renderFilters(data, index))}
      </div>
    </div>
  );
};

export default MomentTagDetailsScreen;
