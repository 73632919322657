import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ACCESSIBILITY_IDENTIFIERS,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SESSION_DETAIL_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import {
  ASK_SESSION_QUESTION,
  GET_ALL_SESSIONS,
  ASK_BREAKOUTS_SESSION_QUESTION,
} from '../../../services/ApiUrls';
import { apiRequest } from '../../../services/Service';
import Select from 'react-select';
import CustomButton from '../../ui/custom_button/CustomButton';
import { showToast, isEmptyText } from '../../../utils/common';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../state/UIState';
import SectionHeader from '../../ui/section_header/SectionHeader';
import BackButton from '../../ui/common_back_button/BackButton';

const AskSessionQuestionScreen = ({ location, history }) => {
  const dispatch = useDispatch();
  const { identifier } = location.state;
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [sessionData, setSessionData] = useState([]);
  const [sessionId, setSessionId] = useState();
  const [speakerId, setSpeakerId] = useState();
  const [selectedSpeaker, setSelectedSpeaker] = useState();
  const [selectedSession, setSelectedSession] = useState();
  const [question, setQuestion] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [fromBreakoutsSessionDetailScreen] = useState(
    location.state.fromBreakoutsSessionDetailRoute ? true : false
  );

  const callGetAllSessionsApi = useCallback(async () => {
    try {
      let allSessionsData = [];
      if (fromBreakoutsSessionDetailScreen) {
        allSessionsData = location.state.data;
      }
      setSessionData(allSessionsData);
      setSessionId(allSessionsData[0].session_id);
      setSpeakerId(allSessionsData[0].speakers[0].speaker_id);
      setSelectedSession({
        label: allSessionsData[0].title,
        value: allSessionsData[0].session_id,
      });
      setSelectedSpeaker({
        label: allSessionsData[0].speakers[0].employee_name,
        value: allSessionsData[0].speakers[0].speaker_id,
      });
    } catch (err) {
      //
    }
  }, [fromBreakoutsSessionDetailScreen, location.state.data]);

  useEffect(() => {
    callGetAllSessionsApi();
  }, [callGetAllSessionsApi]);

  const callAskSessionQuestionApi = async () => {
    try {
      const params = new URLSearchParams();
      params.append('session_id', sessionId);
      params.append('speaker_id', speakerId);
      params.append('question', question);
      let apiResponse = {};
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      if (fromBreakoutsSessionDetailScreen) {
        params.append('anonymous', isChecked);
        apiResponse = await apiRequest(ASK_BREAKOUTS_SESSION_QUESTION, REQUEST_TYPE.POST, params);
      }
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          showToast(apiResponse.response.message);
          if (fromBreakoutsSessionDetailScreen) {
            history.go(-1);
            history.replace(NAVIGATION_ROUTES.SESSION_DETAILS, {
              data: location?.state?.data?.[0],
              fromBreakoutsSessionDetailRoute: true,
              tabKey: SESSION_DETAIL_IDENTIFIERS.QUESTIONS,
              identifier: ACCESSIBILITY_IDENTIFIERS.BREAKOUTS,
            });
          }
        }
      }
    } catch (err) {
      //
    }
  };

  const findSessionFromId = (id) => {
    return sessionData.find((session) => {
      return session.session_id === id;
    });
  };

  const getSpeakersfromSessionId = () => {
    if (sessionData.length && sessionId) {
      const selectedSession = sessionData.find((session) => {
        return session.session_id === sessionId;
      });
      const speakers = selectedSession.speakers.map((employee) => {
        return {
          label: employee.employee_name,
          value: employee.speaker_id,
        };
      });
      return speakers;
    }
    return [];
  };

  const onChange = (event) => {
    setQuestion(event.target.value);
  };

  const onPressAskQues = () => {
    if (!isEmptyText(question)) {
      showToast(ERROR_MESSAGES.EMPTY_QUESTION);
    } else {
      callAskSessionQuestionApi();
    }
  };

  const onValueChangeSession = (selectOption) => {
    setSelectedSession(selectOption);
    setSessionId(selectOption.value);
    const session = findSessionFromId(selectOption.value);
    if (session && session.speakers.length) {
      setSelectedSpeaker({
        label: session.speakers[0].employee_name,
        value: session.speakers[0].speaker_id,
      });
      setSpeakerId(session.speakers[0].speaker_id);
    }
  };

  const onValueChangeSpeaker = (selectOption) => {
    setSelectedSpeaker(selectOption);
    setSpeakerId(selectOption.value);
  };

  const onValueChangeCheckbox = (event) => setIsChecked(event.target.checked);

  return (
    <div className="container">
      <SectionHeader identifier={identifier} />
      <BackButton />
      <div className="main-content-container">
        {/* {fromNewJoineesHigherLevelsScreen && (
          <div>
            <div className="breakouts-session-title">Select Session</div>
            <Select
              value={selectedSession}
              onChange={onValueChangeSession}
              options={sessionData.map((session) => {
                return {
                  label: session.title,
                  value: session.session_id,
                };
              })}
              className="breakouts-dropdown"
            />
          </div>
        )} */}
        <div className="breakouts-session-title">Select Speaker</div>
        <Select
          value={selectedSpeaker}
          onChange={onValueChangeSpeaker}
          options={getSpeakersfromSessionId()}
          className="breakouts-dropdown mt-2 breakouts-dropdown-style"
        />
        <div className="form-group mt-3">
          <textarea
            className="form-control"
            rows="10"
            id="comment"
            placeholder="Type your question..."
            onChange={onChange}
          />
        </div>
        {fromBreakoutsSessionDetailScreen && (
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              checked={isChecked}
              onChange={onValueChangeCheckbox}
            />
            <label className="form-check-label breakouts-agree-tc">Ask Anonymously</label>
          </div>
        )}
        <CustomButton
          buttonStyle="breakouts-button-style mr-0 mt-3"
          showLoader={progressLoadingState.isProgressLoading}
          onClick={onPressAskQues}>
          Ask Question
        </CustomButton>
      </div>
    </div>
  );
};

export default AskSessionQuestionScreen;
