import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  FEATURE_TYPE,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  LOADING_MESSAGES,
  ERROR_MESSAGES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { getUrlWithSpecificRendition, showToast } from '../../utils/common';
import { apiRequest, handleError } from '../../services/Service';
import {
  COMMENT_BY_ID,
  FACILITY_BY_ID,
  GENERATE_BUSINESS_CARD,
  GENERATE_FACILITY_CARD,
  SEND_BUSINESS_CARD,
} from '../../services/ApiUrls';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { useParams } from 'react-router-dom';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { Form, Modal } from 'react-bootstrap';
import CustomTextInput from '../../common/ui/custom_text_input/CustomTextInput';
import { selectSingleLineTextSize } from '../../state/MasterData';
import * as yup from 'yup';
import { Formik } from 'formik';
import SharePopupMenu from './components/SharePopupMenu';
import BackButton from '../../common/ui/common_back_button/BackButton';
import HtmlView from '../../common/ui/html_view/HTMLView';
import { selectAccessData } from '../../state/UserAccessData';

const FacilityContentScreen = ({ history }) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  const [error, setError] = useState(false);
  const [facility, setFacility] = useState();
  const dispatch = useDispatch();
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState();
  const { facilityId } = useParams();
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [businessCard, setBusinessCard] = useState('');
  const [facilityCard, setFacilityCard] = useState({});
  const [showModal, setShowModal] = useState(false);
  const maxSingleLineCharLimit = useSelector(selectSingleLineTextSize);
  const sendEmailFormRef = useRef();
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [showFacilitiesShareDialog, setShowFacilitiesShareDialog] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const accessData = useSelector(selectAccessData);
  const [showShareBusinessCard, setShowShareBusinessCar] = useState(true);
  const [showShareFacilityDetails, setShowShareFacilityDetails] = useState(true);
  const [showLikeButton, setShowLikeButton] = useState(true);
  const facilitiesAccessData = accessData?.data?.find((item) => item.feature_key === '_facilities');

  useEffect(() => {
    const actions = facilitiesAccessData?.actions;
    setShowShareBusinessCar(actions?.includes('_shareBcard'));
    setShowShareFacilityDetails(actions?.includes('_shareFD'));
    setShowLikeButton(actions?.includes('_like'));
  }, [facilitiesAccessData?.actions]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.FACILITY_CONTENT, NAVIGATION_ROUTES.FACILITY_CONTENT);
  }, []);

  const emailRedirect = (link) => {
    window.open(link, '_blank').focus();
  };

  const callFacility = useCallback(async () => {
    setError(false);
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    try {
      const apiResponse = await apiRequest(FACILITY_BY_ID + facilityId, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            if (data.facility) {
              setFacility(data.facility);
              setLiked(data.facility.likes.is_liked);
              setLikeCount(data.facility.likes.count);
            }
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(err, {}, FACILITY_BY_ID + facilityId, NAVIGATION_ROUTES.FACILITY_CONTENT);
      setError(true);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, facilityId]);

  const renderScreen = useCallback(async () => {
    await callFacility();
  }, [callFacility]);

  const postLikeFunction = useCallback(async () => {
    logEvent(ANALYTICS_EVENT_TYPES.FACILITY_LIKE, facilityId, ANALYTICS_ITEM_NAMES.LIKE);
    setError(false);
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: 'Updating like...',
      })
    );
    const formData = new URLSearchParams();
    formData.append('type', FEATURE_TYPE.FACILITIES);
    try {
      const commentApiResponse = await apiRequest(
        COMMENT_BY_ID + facilityId + '/like',
        REQUEST_TYPE.POST,
        formData
      );
      if (Object.keys(commentApiResponse).length > 0) {
        if (commentApiResponse.response.status === true) {
          if (commentApiResponse.response.data) {
            const data = commentApiResponse.response.data;
            if (data) {
              setLiked(data.liked);
              setLikeCount(data.like_count);
            }
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(
        err,
        {},
        COMMENT_BY_ID + facilityId + '/like',
        NAVIGATION_ROUTES.FACILITY_CONTENT
      );
      setError(true);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, facilityId]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const shareBusinessOrFacilityCard = async (cardType) => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.GENERATING_BUSINESS_CARD,
        })
      );
      const apiResponse = await apiRequest(
        cardType === 'business'
          ? GENERATE_BUSINESS_CARD + facilityId
          : GENERATE_FACILITY_CARD + facilityId,
        REQUEST_TYPE.GET
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status === true) {
          if (cardType === 'business') {
            const facility = apiResponse.response.data.facility;
            setBusinessCard(facility.base_url + facility.image_path);
            setShowShareDialog(true);
          } else {
            const facility = apiResponse.response.data.facility;
            setFacilityCard(facility.base_url + facility.image_path);
            setShowFacilitiesShareDialog(true);
          }
        }
      }
    } catch (err) {
      handleError(
        err,
        {},
        cardType === 'business'
          ? GENERATE_BUSINESS_CARD + facilityId
          : GENERATE_FACILITY_CARD + facilityId,
        NAVIGATION_ROUTES.FACILITY_CONTENT
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const sendEmailValidationSchema = yup.object().shape({
    email: yup.string().required(ERROR_MESSAGES.UPDATE_EMAIL).email(ERROR_MESSAGES.INVALID_EMAIL),
    subject: yup.string().required(ERROR_MESSAGES.INVALID_SUBJECT),
  });

  const onPressSubmitHandler = (formValues) => {
    sendEmailApi(formValues);
  };

  const renderModal = () => {
    return (
      <div>
        <div className="modal-header facilities-custom-modal-header d-flex justify-content-center align-items-center">
          <h6 className="modal-title facilities-custom-modal-title w-100 text-center">
            {modalTitle}
          </h6>
          <button
            type="button"
            className="close"
            onClick={() => {
              setShowModal(false);
            }}>
            &times;
          </button>
        </div>
        <Formik
          validationSchema={sendEmailValidationSchema}
          onSubmit={onPressSubmitHandler}
          innerRef={sendEmailFormRef}
          initialValues={{
            email: '',
            subject: '',
          }}>
          {(formikProps) => (
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                logEvent(
                  ANALYTICS_EVENT_TYPES.SHARE_BUSINESS_CARD_EMAIL,
                  '',
                  ANALYTICS_ITEM_NAMES.FACILITY
                );
                if (formikProps.submitCount > 0 && !formikProps.isValid) {
                  const formErrors = Object.values(formikProps.errors);
                  if (formErrors.length > 0) {
                    showToast(formErrors[0]);
                  }
                } else {
                  if (!formikProps.isValid) {
                    showToast(ERROR_MESSAGES.FORM_ERRORS);
                  }
                  formikProps.handleSubmit();
                }
              }}>
              <div>
                <div className="d-flex align-items-center w-100 px-3 mt-3">
                  <h6 className="text-center mr-5">{'To'}</h6>
                  <div className="w-100 ml-1">
                    <CustomTextInput
                      name="email"
                      isTitle={false}
                      maxLength={maxSingleLineCharLimit}
                      style="facilities-text-input w-100"
                      placeholder="Enter email"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      errorMessage={formikProps.errors.email}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center w-100 px-3">
                  <h6 className="text-center mr-3">{'Subject'}</h6>
                  <div className="w-100">
                    <CustomTextInput
                      name="subject"
                      isTitle={false}
                      maxLength={maxSingleLineCharLimit}
                      style="facilities-text-input w-100"
                      placeholder="Enter subject"
                      onChange={formikProps.handleChange}
                      errorMessage={formikProps.errors.subject}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center w-100 px-3">
                  <h6 className="text-center mr-4">{'Body'}</h6>
                  <div className="w-100 facilities-image-input ml-2">
                    <img
                      src={imageUrl}
                      className="facilities-business-card-image px-2 py-1 h-100"
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-end m-3">
                  <button
                    type="button"
                    className="common-custom-button-senary py-2 px-3 text-capitalize"
                    onClick={() => {
                      setShowModal(false);
                    }}>
                    {'Cancel'}
                  </button>
                  <button
                    type="submit"
                    className="common-custom-button-primary py-2 px-3"
                    disabled={progressLoadingState.isProgressLoading}>
                    {'Send'}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  const sendEmailApi = useCallback(
    async (formValues) => {
      setError(false);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const formData = new URLSearchParams(formValues);
      formData.append('type', '_BUSINESS_CARD');
      formData.append('image_path', businessCard);
      try {
        const apiResponse = await apiRequest(SEND_BUSINESS_CARD, REQUEST_TYPE.POST, formData);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.message) {
              setShowModal(false);
              showToast(apiResponse.response.message);
            }
          }
        }
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      } catch (err) {
        handleError(err, formData, SEND_BUSINESS_CARD, NAVIGATION_ROUTES.FACILITY_CONTENT);
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: false,
          })
        );
      }
    },
    [dispatch, businessCard]
  );

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.FACILITIES} />
      <div className="header-view justify-content-between">
        <BackButton isFullRow={false} />
        {facility && showLikeButton && (
          <div className="broadcast-header-row justify-content-end">
            <div className="broadcast-icon-row mr-3">
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  liked
                    ? ACCESSIBILITY_HEADER_COMPONENTS.ACTIVE_LIKE
                    : ACCESSIBILITY_HEADER_COMPONENTS.INACTIVE_LIKE
                )}
                className="header-icon"
                onClick={() => {
                  postLikeFunction();
                }}
              />
              <div
                className="facility-icon-text"
                onClick={() => {
                  if (likeCount > 0) {
                    history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
                      referId: facilityId,
                      parentType: FEATURE_TYPE.FACILITIES,
                    });
                  }
                }}>
                {likeCount}
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <div className="main-content-container">
          {facility && (
            <div className="facility-icon-row justify-content-end">
              {showShareBusinessCard && (
                <div className="mr-3">
                  <SharePopupMenu
                    url={businessCard}
                    showShareDialog={showShareDialog}
                    onOutsideClick={() => {
                      setShowShareDialog(false);
                    }}
                    onOptionClick={(url) => {
                      setShowShareDialog(false);
                      setShowModal(true);
                      setImageUrl(url);
                    }}
                    type="BusinessCard">
                    <CustomButton
                      buttonStyle="facility-bottom-button"
                      onClick={() => {
                        shareBusinessOrFacilityCard('business');
                        setModalTitle('Share business card');
                      }}>
                      {'Share business card'}
                    </CustomButton>
                  </SharePopupMenu>
                </div>
              )}
              {showShareFacilityDetails && (
                <SharePopupMenu
                  url={facilityCard}
                  showShareDialog={showFacilitiesShareDialog}
                  onOutsideClick={() => {
                    setShowFacilitiesShareDialog(false);
                  }}
                  onOptionClick={(url) => {
                    setShowFacilitiesShareDialog(false);
                    setShowModal(true);
                    setImageUrl(url);
                  }}
                  type="Facility">
                  <CustomButton
                    buttonStyle="facility-bottom-button"
                    onClick={() => {
                      shareBusinessOrFacilityCard('facility');
                      setModalTitle('Share facility details');
                    }}>
                    {'Share facility details'}
                  </CustomButton>
                </SharePopupMenu>
              )}
            </div>
          )}
          {facility && (
            <div>
              <div>
                <div className="facility-content-text-style facility-content-text-style-container">
                  <HtmlView html={facility.address.replace(/\n/g, '<br>')} />
                </div>
                {!!facility.phone_number && !!facility.phone_isd && (
                  <div className="facility-content-text-style facility-content-phone-num-container">
                    {facility.phone_isd + ' - ' + facility.phone_number}
                  </div>
                )}
                <div className="facility-content-text-style facility-content-map-container">
                  Find on map:
                </div>
                <div
                  className="facility-content-text-style facility-content-link-container"
                  onClick={() => {
                    emailRedirect(facility.link);
                  }}>
                  {facility.link}
                </div>
              </div>
              <div className="row">
                {facility.gallary.pictures.length > 0 &&
                  facility.gallary.pictures.map((item, index) => {
                    return (
                      <div key={`${index}`} className="col-sm-4 mb-3">
                        <div>
                          <img
                            src={getUrlWithSpecificRendition(
                              facility.gallary.base_url,
                              SCREEN_DENSITY_QUALIFIERS.HDPI,
                              item.picture_path
                            )}
                            className="embed-responsive embed-responsive-16by9"
                          />
                        </div>
                        <div className="facility-content-text-style facility-content-picture-container">
                          {facility.picture_caption}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        show={showModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowModal(false)}>
        <Modal.Body>{renderModal()}</Modal.Body>
      </Modal>
    </div>
  );
};

export default FacilityContentScreen;
