import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  MICROGIVE_EVENT_TYPE,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { INITIATIVE_SEARCH } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import {
  saveProgressLoadingState,
  saveSearchText,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectSearchText,
  selectShowEmptyContentView,
} from '../../state/UIState';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import DonationListItem from './components/DonationListItem';
import VolunteerListItem from './components/VolunteerListItem';
import debounce from 'lodash.debounce';
import ActivityLoader from '../../common/ui/activity_loader/ActivityLoader';

const DonationSearchScreen = ({ history, location }) => {
  const dispatch = useDispatch();
  const { type = '' } = location?.state || {};
  const [searchList, setSearchList] = useState([]);
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const searchData = useSelector(selectSearchText);
  const [searchText, setSearchText] = useState(searchData ?? '');

  const accessibilityData = useSelector(selectAccessibilityData);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [isSearchEnabled, setIsSearchEnabled] = useState(true);
  const [quickSearchLoading, setQuickSearchLoading] = useState(false);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.DONATION_SEARCH_SCREEN,
      NAVIGATION_ROUTES.DONATION_SEARCH_SCREEN
    );
  }, []);

  const callInitiativeSearchApi = useCallback(
    async (id, loadMore, text, isQuickSearch = false) => {
      dispatch(saveShowEmptyContentView(true));
      const params = {
        page_id: id,
        type: type,
        query: text,
      };
      try {
        if (isQuickSearch) {
          setQuickSearchLoading(true);
        } else {
          dispatch(
            saveProgressLoadingState({
              isProgressLoading: true,
            })
          );
        }
        const apiResponse = await apiRequest(INITIATIVE_SEARCH, REQUEST_TYPE.GET, params);
        setQuickSearchLoading(false);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.data.initiatives.length > 0) {
              if (id === 1) {
                setSearchList(apiResponse.response.data.initiatives);
              } else {
                setSearchList((oldArray) => [
                  ...oldArray,
                  ...apiResponse.response.data.initiatives,
                ]);
              }
              setLoadMoreFlag(true);
            } else {
              setLoadMoreFlag(false);
            }
          }
        }
      } catch (err) {
        handleError(err, params, INITIATIVE_SEARCH, NAVIGATION_ROUTES.DONATION_SEARCH_SCREEN);
        setQuickSearchLoading(false);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    },
    [dispatch, type]
  );

  const debouncedCallback = (event) => {
    if (event && event.target.value.length > 2) {
      dispatch(saveSearchText(event.target.value));
      setPageId(1);
      setLoadMoreFlag(true);
      callInitiativeSearchApi(1, true, event.target.value, true);
    }
    setSearchText(event.target.value);
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );
  const onInputChange = (event) => {
    setSearchText(event.target.value);
    debounceInputHandler(event);
  };

  const callSubmitFunction = (event) => {
    event?.preventDefault();
    if (searchText !== '') {
      dispatch(saveSearchText(searchText));
      logEvent(
        ANALYTICS_EVENT_TYPES.MICROGIVE_DONATION_SEARCH,
        searchText,
        ANALYTICS_ITEM_NAMES.SEARCH
      );
      setSearchList([]);
      setPageId(1);
      setLoadMoreFlag(true);
      callInitiativeSearchApi(1, true, searchText);
    }
  };

  const onPressItem = (item) => {
    switch (type) {
      case MICROGIVE_EVENT_TYPE.DONATION:
        history.push(`${NAVIGATION_ROUTES.DONATION_TABS_SCREEN}/${item.initiative_id}`);
        break;
      case MICROGIVE_EVENT_TYPE.VOLUNTEER:
        history.push(`${NAVIGATION_ROUTES.VOLUNTEER_TABS_SCREEN}/${item.initiative_id}`);
        break;
      default:
        break;
    }
  };

  const onPressLoadMore = () => {
    callInitiativeSearchApi(pageId + 1, loadMoreFlag, searchText);
    setPageId(pageId + 1);
  };

  const resetValue = () => {
    history.replace(NAVIGATION_ROUTES.DONATION_SEARCH_SCREEN, {
      type,
    });
    setIsSearchEnabled(false);
    setSearchText('');
    setPageId(1);
    callInitiativeSearchApi(1, '');
    setLoadMoreFlag(true);
  };

  useEffect(() => {
    if (location?.hash) {
      let hashtag = location?.hash || '';
      setSearchText(hashtag);
      callInitiativeSearchApi(1, true, hashtag);
    }
  }, [callInitiativeSearchApi, location]);

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.SEARCH_NO_RESULTS} />;
  };

  const renderItem = (item) => {
    switch (type) {
      case MICROGIVE_EVENT_TYPE.DONATION:
        return <DonationListItem item={item} onPress={onPressItem} />;
      case MICROGIVE_EVENT_TYPE.VOLUNTEER:
        return <VolunteerListItem item={item} onPress={onPressItem} />;
    }
  };

  const onClickSearchHandler = () => {
    setIsSearchEnabled(true);
  };

  useEffect(() => {
    if (searchText) {
      callSubmitFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
        <div
          className={
            isSearchEnabled ? 'search-bar-with-icon search-bar-border' : 'search-bar-with-icon'
          }>
          {isSearchEnabled ? (
            <form className="directory-search-bar" onSubmit={callSubmitFunction}>
              <input
                autoFocus
                className="ml-2 directory-search-bar"
                type="text"
                placeholder="Search.."
                onChange={onInputChange}
                value={searchText}
              />
            </form>
          ) : (
            <div className="directory-search-bar" />
          )}
          {isSearchEnabled ? (
            <div className="gcdo-icon-row mr-2" onClick={resetValue}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
                )}
                className="header-icon"
              />
            </div>
          ) : (
            <div className="gcdo-icon-row mr-2" onClick={onClickSearchHandler}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH
                )}
                className="header-icon"
              />
            </div>
          )}
        </div>
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICROGIVE} />
      <BackButton />

      <div className="main-content-container">
        <ActivityLoader visible={quickSearchLoading} />
        {showEmptyContentView && !searchList.length && !progressLoadingState.isProgressLoading ? (
          emptyListView()
        ) : (
          <div>
            <div className="row">{searchList.map((item) => renderItem(item))}</div>
            {loadMoreFlag && (
              <LoadMoreBtn
                onClick={onPressLoadMore}
                isLoading={progressLoadingState.isProgressLoading}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DonationSearchScreen;
