import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveProgressLoadingState } from '../../state/UIState';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { apiRequest, handleError } from '../../services/Service';
import { GET_GEOGRAPHIES_NEW } from '../../services/ApiUrls';
import { savePoliciesTabData, selectPoliciesTabData } from '../../state/TabData';
import { OverlayTrigger, Popover, Tab } from 'react-bootstrap';
import NavTabs from '../../common/ui/tabs/NavTabs';
import CategoryTab from './tabs/CategoryTab';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import {
  savePoliciesLoadMoreButtonData,
  savePoliciesPageData,
  savePoliciesSearchResultData,
  savePoliciesSearchTextData,
} from '../../state/PoliciesData';
import SearchComponent from '../../common/ui/search/SearchComponent';
import { selectEmployeeData } from '../../state/EmployeeData';
import AskMlChatWidget from '../ask_ml/AskMlChatWidget';
import { selectAccessData } from '../../state/UserAccessData';
import { generatev4UniqueID } from '../../utils/common';

const PoliciesScreen = ({ history }) => {
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);
  const [geographies, setGeographies] = useState([]);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const filterDropDownPopoverRef = useRef();
  const policiesTabData = useSelector(selectPoliciesTabData);
  const [showWidget, setShowWidget] = useState(false);
  const employeeConfig = useSelector(selectEmployeeData);
  const accessData = useSelector(selectAccessData);
  const [showChatBot, setShowChatBot] = useState(true);
  const policiesAccessData = accessData?.data?.find((item) => item.feature_key === '_policies');
  const [uniqueUUID, setUniqueUUID] = useState('');

  useEffect(() => {
    const actions = policiesAccessData?.actions;
    setShowChatBot(actions?.includes('_chatbot'));
  }, [policiesAccessData?.actions]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.POLICIES, NAVIGATION_ROUTES.POLICIES);
  }, []);

  const toggleWidget = () => {
    setShowWidget(!showWidget);
  };

  const callGeographyApi = useCallback(async () => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(GET_GEOGRAPHIES_NEW, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.geography.length > 0) {
            setGeographies(apiResponse.response.data.geography);
            const userGeographyId = apiResponse.response.data.employee?.geography?.geography_id;
            const userGeography = apiResponse.response.data.geography.find(
              (geography) => geography.geography_id === userGeographyId
            );
            if (userGeography) setSelectedRegion(userGeography);
            else setSelectedRegion(apiResponse.response.data.geography[0]);
          }
        }
      }
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    } catch (err) {
      handleError(err, {}, GET_GEOGRAPHIES_NEW, NAVIGATION_ROUTES.POLICIES);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch]);

  const renderScreen = useCallback(async () => {
    callGeographyApi();
  }, [callGeographyApi]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const onFilterItemPress = (value) => {
    setShowFilterDropdown(false);
    setSelectedRegion(geographies?.find((geography) => value === geography.geography_id));
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        showFilterDropdown &&
        filterDropDownPopoverRef.current &&
        !filterDropDownPopoverRef.current.contains(e.target)
      ) {
        setShowFilterDropdown(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [showFilterDropdown]);

  const handleSavePolicyData = useCallback(async () => {
    let selectedCategory = await selectedRegion?.categories.find(
      (category) => category.category_id == policiesTabData
    );
    if (
      (policiesTabData === '' && selectedRegion) ||
      (selectedRegion && selectedCategory == undefined)
    ) {
      dispatch(savePoliciesTabData(selectedRegion?.categories[0].category_id));
    }
  }, [dispatch, policiesTabData, selectedRegion]);

  useEffect(() => {
    handleSavePolicyData();
  }, [dispatch, handleSavePolicyData, policiesTabData, selectedRegion]);

  const filterItemsPopover = () => {
    return (
      <Popover className="tr-dropdown-popover p-0">
        <Popover.Content className="p-0">
          <div ref={filterDropDownPopoverRef}>
            {geographies.map((item, index) => {
              return (
                <div
                  key={'filter_status_' + index}
                  className={`common-cursor-pointer tr-dropdown-item-style
                    ${
                      selectedRegion && item.geography_id === selectedRegion.geography_id
                        ? 'tr-dropdown-item-active'
                        : ''
                    }
                  p-2`}
                  onClick={() => onFilterItemPress(item.geography_id)}>
                  {item.title}
                </div>
              );
            })}
          </div>
        </Popover.Content>
      </Popover>
    );
  };

  const renderTabs = (tab) => {
    switch (tab.category_id) {
      default:
        return <CategoryTab tabKey={tab.category_id} geographyId={selectedRegion?.geography_id} />;
    }
  };

  const handleSubmitFunction = (searchText) => {
    dispatch(savePoliciesSearchTextData(searchText));
    dispatch(savePoliciesLoadMoreButtonData(false));
    dispatch(savePoliciesPageData(1));
    dispatch(savePoliciesSearchResultData([]));
    history.push(NAVIGATION_ROUTES.POLICY_SEARCH_SCREEN, { searchQuery: searchText });
  };

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
        {employeeConfig?.gpt_visible == 1 && showChatBot && (
          <div
            className={
              !showWidget
                ? 'd-flex common-cursor-pointer chat-bot-floating-icon p-2 align-items-center justify-content-center'
                : 'd-flex common-cursor-pointer chat-bot-floating-close-icon p-2 align-items-center justify-content-center'
            }>
            <div
              className="position-relative flex-grow-1 d-flex justify-content-center align-items-center"
              onClick={() => {
                toggleWidget();
                if (!showWidget) {
                  const id = generatev4UniqueID();
                  setUniqueUUID(id);
                }
              }}>
              <img
                className={!showWidget ? 'ask-org-chat-bot-float-icon' : 'ask-org-close-float-icon'}
                src={
                  !showWidget
                    ? getFeatureComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.CHAT_BOT,
                        ACCESSIBILITY_IDENTIFIERS.ASK_ORG
                      )
                    : 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4IiB2aWV3Qm94PSIwIDAgMzU3IDM1NyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzU3IDM1NzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnIGlkPSJjbGVhciI+CgkJPHBvbHlnb24gcG9pbnRzPSIzNTcsMzUuNyAzMjEuMywwIDE3OC41LDE0Mi44IDM1LjcsMCAwLDM1LjcgMTQyLjgsMTc4LjUgMCwzMjEuMyAzNS43LDM1NyAxNzguNSwyMTQuMiAzMjEuMywzNTcgMzU3LDMyMS4zICAgICAyMTQuMiwxNzguNSAgICIgZmlsbD0iI0ZGRkZGRiIvPgoJPC9nPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+CjxnPgo8L2c+Cjwvc3ZnPgo='
                }
              />
            </div>
            {showWidget ? <AskMlChatWidget uniqueUUID={uniqueUUID} /> : null}
          </div>
        )}
        <SearchComponent handleSubmitFunction={handleSubmitFunction} />
        {/* <div className="gcdo-icon-row mr-3">
          <img
            src={getHeaderComponentUrl(accessibilityData, ACCESSIBILITY_HEADER_COMPONENTS.SEARCH)}
            className="header-icon common-cursor-pointer"
            onClick={() => {
              dispatch(savePoliciesSearchTextData(''));
              dispatch(savePoliciesLoadMoreButtonData(false));
              dispatch(savePoliciesPageData(1));
              dispatch(savePoliciesSearchResultData([]));
              history.push(NAVIGATION_ROUTES.POLICY_SEARCH_SCREEN);
            }}
          />
        </div> */}
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.POLICIES} />
      <div className="policies-header">
        <div className="d-flex flex-row header-policy-region">
          <div className="d-flex flex-row policy-bg">
            <p className="policy-region">Geography</p>
            <OverlayTrigger
              rootClose
              trigger="click"
              placement="bottom"
              show={showFilterDropdown}
              overlay={filterItemsPopover()}>
              <div
                className="policy-dropdown-button-style"
                onClick={() => setShowFilterDropdown(!showFilterDropdown)}>
                <span className="tr-dropdown-button-text">
                  {!selectedRegion ? `Select Region` : selectedRegion.title}
                </span>
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    showFilterDropdown
                      ? ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_UP_ARROW
                      : ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_DOWN_ARROW,
                    ACCESSIBILITY_IDENTIFIERS.COMMON
                  )}
                  className="region-icon"
                />
              </div>
            </OverlayTrigger>
          </div>
        </div>
        <div>
          {selectedRegion && selectedRegion.categories && selectedRegion.categories.length > 0 && (
            <NavTabs
              activeKey={policiesTabData}
              onSelect={(k) => {
                dispatch(savePoliciesTabData(k));
              }}
              unmountOnExit>
              {selectedRegion.categories.map((tab, index) => {
                return (
                  <Tab key={`${index}`} eventKey={tab.category_id} title={tab.title}>
                    {renderTabs(tab, index)}
                  </Tab>
                );
              })}
            </NavTabs>
          )}
        </div>
      </div>
    </div>
  );
};

export default PoliciesScreen;
