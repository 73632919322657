import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { selectWomensDayConfiguration, selectWomensDayTabsYearWise } from '../../state/MasterData';
import { selectBaseUrl } from '../../state/UIState';
import CentralTab from './tabs/CentralTab';
import ConvoTab from './tabs/ConvoTab';
import CpoMessageTab from './tabs/CpoMessageTab';
import GalleryTab from './tabs/GalleryTab';
import { Tab } from 'react-bootstrap';
import { saveWomensdayTabData, selectWomensdayTabData } from '../../state/TabData';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ACTION_TYPES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import NavTabs from '../../common/ui/tabs/NavTabs';
import _ from 'lodash';
import { showToast, sortBySequence } from '../../utils/common';
import MeetTheSpeakersTab from './tabs/MeetTheSpeakersTab';
import FeedbacksTab from './tabs/FeedbacksTab';
import BackButton from '../../common/ui/common_back_button/BackButton';
import CommitmentTab from './tabs/CommitmentTab';

const WomensDayMainScreen = ({ location }) => {
  const dispatch = useDispatch();
  const [beforeLiveTimer, setBeforeLiveTimer] = useState();
  const [womensDayConfig, setWomensDayConfig] = useState();
  const [womensDayTabs, setWomensDayTabs] = useState([]);
  const womensDayTabsYearwise = useSelector(selectWomensDayTabsYearWise);
  const womensDayTabData = useSelector(selectWomensdayTabData);
  const baseUrl = useSelector(selectBaseUrl);
  const [id] = useState(new URLSearchParams(location.search).get('id'));
  const [eventType, setEventType] = useState();
  const womensdayConfigObj = useSelector(selectWomensDayConfiguration);
  const [year, setYear] = useState('');

  useEffect(() => {
    if (!_.isEmpty(womensdayConfigObj) && !!year) {
      let activeList = _.filter([...(womensdayConfigObj?.years ?? [])], (year) => year?.is_active);
      if (!_.isEmpty(activeList)) {
        let womensDayItem = _.find(activeList, (item) => item?.year === year);
        if (!_.isEmpty(womensDayItem?.contents) && !_.isEmpty(womensDayItem)) {
          setWomensDayConfig(womensDayItem?.contents);
        }
      }
    }
  }, [womensdayConfigObj, year]);

  useEffect(() => {
    if (!_.isEmpty(location)) {
      if (location?.pathname) {
        let year = location?.pathname?.split('/').pop();
        if (!isNaN(year)) {
          setYear(Number(year));
        } else {
          showToast("Women's day celebration year is invalid.");
        }
      }
    }
  }, [location]);

  useEffect(() => {
    if (baseUrl !== '' && id && id !== '') {
      dispatch({ type: ACTION_TYPES.GET_TOKEN, payload: { id: id } });
    }
  }, [baseUrl, dispatch, id]);

  useEffect(() => {
    if (!_.isEmpty(womensDayTabsYearwise) && !!year) {
      let tabs = _.filter([...womensDayTabsYearwise[year]], (items) => items?.active);
      let orderedTabs = sortBySequence(tabs);
      setWomensDayTabs(orderedTabs);
    }
  }, [womensDayTabsYearwise, year]);

  useEffect(() => {
    if (womensDayTabs.length && womensDayTabData === '') {
      dispatch(saveWomensdayTabData(womensDayTabs[0].key));
    }
  }, [dispatch, womensDayTabData, womensDayTabs]);

  const getMilliSecondDifference = useCallback(() => {
    if (womensDayConfig) {
      let streamingDate = womensDayConfig && womensDayConfig.streaming_date;
      let streamingStartTime = womensDayConfig && womensDayConfig.streaming_start_time;

      const todayTimestamp = dayjs();
      const convertedStartDate = `${streamingDate.split('-')[2]}/${streamingDate.split('-')[1]}/${
        streamingDate.split('-')[0]
      }`;
      const convertedStartTimestamp = `${convertedStartDate} ${streamingStartTime}:00`;
      const convertedStartUtcTimestamp = dayjs.utc(convertedStartTimestamp).subtract(330, 'minute');
      const convertedStartLocalTimestamp = convertedStartUtcTimestamp.local();
      const differenceMilliSeconds = convertedStartLocalTimestamp.diff(todayTimestamp, 'ms');
      return differenceMilliSeconds;
    }
  }, [womensDayConfig]);

  const getMilliSecondDifferenceToEnd = useCallback(() => {
    if (womensDayConfig) {
      let streamingDate = womensDayConfig.streaming_date;
      let streaminEndTime = womensDayConfig.streaming_end_time;

      const todayTimestamp = dayjs();
      const convertedEndDate = `${streamingDate.split('-')[2]}/${streamingDate.split('-')[1]}/${
        streamingDate.split('-')[0]
      }`;
      const convertedEndTimestamp = `${convertedEndDate} ${streaminEndTime}:00`;
      const convertedEndUtcTimestamp = dayjs.utc(convertedEndTimestamp).subtract(330, 'minute');
      const convertedEndLocalTimestamp = convertedEndUtcTimestamp.local();
      const differenceMilliSeconds = convertedEndLocalTimestamp.diff(todayTimestamp, 'ms');
      return differenceMilliSeconds;
    }
  }, [womensDayConfig]);

  const hasEventEnded = useCallback(() => {
    if (womensDayConfig) {
      let streamingDate = womensDayConfig.streaming_date;
      let streaminEndTime = womensDayConfig.streaming_end_time;

      const todayTimestamp = dayjs();
      const convertedEndDate = `${streamingDate.split('-')[2]}/${streamingDate.split('-')[1]}/${
        streamingDate.split('-')[0]
      }`;
      const convertedEndTimestamp = `${convertedEndDate} ${streaminEndTime}:00`;
      const convertedEndUtcTimestamp = dayjs.utc(convertedEndTimestamp).subtract(330, 'minute');
      const convertedEndLocalTimestamp = convertedEndUtcTimestamp.local();

      return todayTimestamp.valueOf() > convertedEndLocalTimestamp.valueOf();
    }
  }, [womensDayConfig]);

  const onGoingTimer = (sec) => {
    var timer = sec;
    let interval = setInterval(function () {
      if (!isNaN(timer)) {
        if (--timer < 0) {
          timer = sec;
        }
        let rounded_time = Math.floor(timer);
        if (rounded_time === 0) {
          clearInterval(interval);
          setEventType('end');
        }
      }
    }, 1000);
  };

  const reverseTimer = useCallback(
    (sec) => {
      var timer = sec;
      let interval = setInterval(function () {
        if (!isNaN(timer)) {
          let hours = Math.floor(timer / 3600);
          let minutes = Math.floor(timer / 60) - hours * 60;
          let seconds = Math.floor(timer % 60);
          let h = hours < 10 ? '0' + hours : hours;
          let m = minutes < 10 ? '0' + minutes : minutes;
          let s = seconds < 10 ? '0' + seconds : seconds;
          setBeforeLiveTimer(h + ':' + m + ':' + s);
          if (--timer < 0) {
            timer = sec;
          }
          if (hours === 0 && minutes === 0 && seconds === 0) {
            setEventType('live');
            clearInterval(interval);
            // note : if the user stays on the same screen, does not refresh, then check if the event is over and start the imer in background.
            if (hasEventEnded()) {
              setEventType('end');
            } else {
              let milliSecondsToEnd = getMilliSecondDifferenceToEnd();
              setEventType('live');
              onGoingTimer(milliSecondsToEnd / 1000);
            }
          }
        }
      }, 1000);
    },
    [getMilliSecondDifferenceToEnd, hasEventEnded]
  );

  useEffect(() => {
    if (!_.isEmpty(womensDayConfig)) {
      let milliSeconds = getMilliSecondDifference();
      if (milliSeconds < 0) {
        if (hasEventEnded()) {
          setEventType('end');
        } else {
          let milliSecondsToEnd = getMilliSecondDifferenceToEnd();
          setEventType('live');
          onGoingTimer(milliSecondsToEnd / 1000);
        }
      } else {
        setEventType('starting');
        reverseTimer(milliSeconds / 1000);
      }
    }
  }, [
    getMilliSecondDifference,
    hasEventEnded,
    getMilliSecondDifferenceToEnd,
    reverseTimer,
    womensDayConfig,
  ]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.WOMENS_DAY, NAVIGATION_ROUTES.WOMENS_DAY);
  }, []);

  const renderTabs = (tab) => {
    switch (tab.key) {
      case 'central':
        return (
          <CentralTab
            eventType={eventType}
            config={womensDayConfig}
            beforeLiveTimer={beforeLiveTimer}
            item={tab}
            year={year}
          />
        );
      case 'convo':
        return <ConvoTab config={womensDayConfig} item={tab.key} year={year} />;
      case 'cposMessage':
        return <CpoMessageTab config={womensDayConfig} item={tab.key} year={year} />;
      case 'gallery':
        return <GalleryTab item={tab.key} year={year} />;
      case 'feedback':
        return (
          <FeedbacksTab
            eventType={eventType}
            config={womensDayConfig}
            beforeLiveTimer={beforeLiveTimer}
            item={tab}
            year={year}
          />
        );
      case 'commitment':
        return (
          <CommitmentTab
            eventType={eventType}
            config={womensDayConfig}
            beforeLiveTimer={beforeLiveTimer}
            item={tab}
            year={year}
          />
        );
      case 'speakers':
        return (
          <MeetTheSpeakersTab
            eventType={eventType}
            config={womensDayConfig}
            beforeLiveTimer={beforeLiveTimer}
            item={tab}
            year={year}
          />
        );
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.WOMENS_DAY} />
      <BackButton />
      {baseUrl !== '' && womensDayConfig && (
        <NavTabs activeKey={womensDayTabData} onSelect={(k) => dispatch(saveWomensdayTabData(k))}>
          {womensDayTabs &&
            womensDayTabs.map((tab, index) => {
              return (
                <Tab
                  key={`${index}`}
                  eventKey={tab.key}
                  title={tab.title}
                  className="main-content-container">
                  {renderTabs(tab, index)}
                </Tab>
              );
            })}
        </NavTabs>
      )}
    </div>
  );
};

export default WomensDayMainScreen;
