import React, { useEffect, useState } from 'react';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { selectActivityCountText } from '../../../state/MasterData';

const MiHRActivityComponent = ({ activityList, onSubmitActivityData }) => {
  const [activityDataCopy, setActivityDataCopy] = useState([]);
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const activityText = useSelector(selectActivityCountText);

  const isArrayEqual = (x, y) => {
    return _(x).differenceWith(y, _.isEqual).isEmpty();
  };

  const handleChange = (e, weekIndex, activityIndex, isChecked) => {
    let data = _.cloneDeep(activityDataCopy);
    data[weekIndex].actvities[activityIndex].checked = !isChecked;
    if (isArrayEqual(activityList, data)) {
      setEnableSaveButton(false);
    } else {
      setEnableSaveButton(true);
    }

    setActivityDataCopy(data);
  };

  useEffect(() => {
    setActivityDataCopy(activityList);
  }, [activityList]);

  return (
    <div>
      {activityList?.length > 0 && (
        <div className="d-flex flex-row-reverse mi-hr-activity-btn-container">
          <CustomButton
            buttonStyle={`btn-load-more common-cursor-pointer`}
            onClick={() => onSubmitActivityData(activityDataCopy)}
            disabled={!enableSaveButton}>
            {`SAVE`}
          </CustomButton>
        </div>
      )}
      {activityDataCopy.map((week, weekIndex) => {
        return (
          <div key={`${week?.week_id}`} className="mb-2">
            <span className="new-joinees-lower-levels-checklists-section-title">
              {`${week?.title}`}
            </span>
            {week?.actvities?.map((act, activityIndex) => {
              return (
                <div
                  key={`${act?.activity_id}`}
                  className="d-flex align-items-start mt-3 mb-3 flex-direction-row ">
                  <input
                    type="checkbox"
                    className="mr-3 mt-1"
                    id={`${weekIndex}${activityIndex}`}
                    name={`${weekIndex}${activityIndex}`}
                    onChange={(e) => handleChange(e, weekIndex, activityIndex, act?.checked)}
                    checked={act?.checked}
                  />

                  <span className="m-0 new-joinees-lower-levels-checklists-item-text">
                    {act?.title}
                    {!!act?.show_count && (
                      <span className="activity-count">{` ( ${activityText} ${act?.checked_count} ) `}</span>
                    )}
                  </span>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(MiHRActivityComponent);
