import React from 'react';
import { useSelector } from 'react-redux';

import { getFeatureComponentUrl } from '../../../../utils/accessibility';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ACCESSIBILITY_FEATURE_COMPONENTS,
} from '../../../../common/constants/AppConstants';
import { Modal } from 'react-bootstrap';

const CelebrationMessagesModal = (props) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  const renderConfiguredMessages = (message, index) => {
    return (
      <div
        className="mb-3 p-3 common-wish-celebration-messages-wishes"
        key={index}
        onClick={() => {
          props.onChangehandler(message.toString());
          props.toggleModal();
        }}>
        <div className="">{message}</div>
      </div>
    );
  };

  return (
    <Modal
      size="lg"
      onHide={() => props.toggleModal()}
      show={props.modalVisible}
      backdrop="static"
      keyboard={false}>
      <Modal.Body className="p-4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_WISHES,
                ACCESSIBILITY_IDENTIFIERS.PROFILE
              )}
              className="my-profile-gift-icon"
            />
            <div className="ml-2 my-profile-wish-text">Select your wish</div>
          </div>
          <div onClick={() => props.toggleModal()} className="common-cursor-pointer">
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.DIRECTORY_MODAL_CLOSE,
                ACCESSIBILITY_IDENTIFIERS.DIRECTORY
              )}
              className="my-profile-cross-icon"
            />
          </div>
        </div>
        <div className="mt-3">
          {props.data &&
            props.data.map((message, index) => renderConfiguredMessages(message, index))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CelebrationMessagesModal;
