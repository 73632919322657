import { createSlice } from '@reduxjs/toolkit';

const saveProfanityListReducer = (state, action) => {
  state.profanityList = action.payload;
};

// REDUCER
const profanityDataSlice = createSlice({
  name: 'profanityData',
  initialState: {
    profanityList: [],
  },
  reducers: {
    saveProfanityList: saveProfanityListReducer,
  },
});

// ACTIONS
const { saveProfanityList } = profanityDataSlice.actions;

// SELECTOR
const selectProfanityList = ({ profanityData }) => profanityData.profanityList;

const profanityDataSliceReducer = profanityDataSlice.reducer;

export { profanityDataSliceReducer, saveProfanityList, selectProfanityList };
