import React, { useEffect, useCallback, useState } from 'react';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_TITLE,
} from '../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import { apiRequest, handleError } from '../../services/Service';
import { POLLS_BY_ID } from '../../services/ApiUrls';
import { saveProgressLoadingState } from '../../state/UIState';
import Chart from 'react-apexcharts';
import { formatDateDDMonthYYYY } from '../../utils/common';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import colors from '../../common/themes/Colors';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';

const PollViewResultScreen = ({ location }) => {
  const { pollId } = location.state;
  const [pollsResults, setPollsResults] = useState();
  const dispatch = useDispatch();
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([]);

  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.POLLS_RESULTS, NAVIGATION_ROUTES.POLLS_RESULTS);
  }, []);

  const callPollsApi = useCallback(async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const apiResponse = await apiRequest(POLLS_BY_ID + pollId, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.poll) {
            const data = apiResponse.response.data.poll;
            setPollsResults(data);

            let categories = [];
            let barData = [];
            data.results.options.map((item, index) => {
              categories.push(index + 1);
              barData.push(Math.round(item.percentage * 10) / 10);
            });
            setOptions({
              xaxis: {
                categories: categories,
              },
              fill: {
                colors: [colors.primary],
              },
            });
            setSeries([
              {
                name: 's-1',
                data: barData,
              },
            ]);
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(err, {}, POLLS_BY_ID + pollId, NAVIGATION_ROUTES.POLLS_RESULTS);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, pollId]);

  useEffect(() => {
    callPollsApi();
  }, [callPollsApi]);

  return (
    <div className="container">
      <SectionHeader title={SCREEN_TITLE.POLL} />
      <BackButton />
      <div className="main-content-container">
        {pollsResults && (
          <div>
            <div className="polls-cross-axis-view mt-1">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.NEWS_CALENDER,
                  ACCESSIBILITY_IDENTIFIERS.NEWS
                )}
                className="polls-calender-style"
              />
              <div className="ml-1 polls-date-text">
                {pollsResults.active
                  ? `Till ${formatDateDDMonthYYYY(pollsResults.end_datetime)}`
                  : `Poll ended on ${formatDateDDMonthYYYY(pollsResults.end_datetime)}`}
              </div>
            </div>
            <div className="polls-title-text mt-2">{pollsResults.question}</div>
            <div className="polls-category-container">
              {pollsResults.results.options &&
                pollsResults.results.options.length > 0 &&
                pollsResults.results.options.map((item, index) => {
                  return (
                    <div key={`${index}`} className="polls-categories mt-2">
                      {`${index + 1} - ${item.title}`}
                    </div>
                  );
                })}
            </div>
            <Chart
              className="mt-3"
              options={options}
              series={series}
              type="bar"
              width={500}
              height={320}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PollViewResultScreen;
