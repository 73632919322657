import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectTravelRequestTabs } from '../../state/MasterData';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ACTION_TYPES,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
  TRAVEL_REQUEST_TAB_IDENTIFIERS,
} from '../../common/constants/AppConstants';
import { Tabs, Tab } from 'react-bootstrap';
import { saveTravelRequestTabData, selectTravelRequestTabData } from '../../state/TabData';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import PlanTravelTab from './tabs/PlanTravelTab';
import UpcomingTravelTab from './tabs/UpcomingTravelTab';
import AllRequestTab from './tabs/AllRequestTab';
import ApprovalTab from './tabs/ApprovalTab';

const TravelRequestScreen = () => {
  const dispatch = useDispatch();
  const travelRequestTabs = useSelector(selectTravelRequestTabs);
  const travelRequestTabData = useSelector(selectTravelRequestTabData);

  useEffect(() => {
    if (travelRequestTabs.length && travelRequestTabData === '') {
      dispatch(saveTravelRequestTabData(travelRequestTabs[0].key));
    }
  }, [dispatch, travelRequestTabData, travelRequestTabs]);

  useEffect(() => {
    // Analytics
    trackScreen(ANALYTICS_SCREEN_NAMES.TRAVEL_REQUEST, NAVIGATION_ROUTES.TRAVEL_REQUEST);
  }, []);

  useEffect(() => {
    dispatch({ type: ACTION_TYPES.GET_TRAVEL_REQUEST_MASTER_DATA, payload: {} });
  }, [dispatch]);

  const renderTabs = (tab) => {
    switch (tab.key) {
      case TRAVEL_REQUEST_TAB_IDENTIFIERS.PLAN_TRAVEL:
        return <PlanTravelTab />;
      case TRAVEL_REQUEST_TAB_IDENTIFIERS.UPCOMING_TRAVEL:
        return <UpcomingTravelTab />;
      case TRAVEL_REQUEST_TAB_IDENTIFIERS.ALL_REQUESTS:
        return <AllRequestTab />;
      case TRAVEL_REQUEST_TAB_IDENTIFIERS.FOR_APPROVAL:
        return <ApprovalTab />;
    }
  };

  return (
    <div className="container-xl">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.TRAVEL_REQUEST} />
      <div>
        <Tabs
          activeKey={travelRequestTabData}
          onSelect={(k) => {
            dispatch(saveTravelRequestTabData(k));
          }}
          unmountOnExit>
          {travelRequestTabs.map((tab, index) => {
            return (
              <Tab key={`${index}`} eventKey={tab.key} title={tab.title}>
                {renderTabs(tab, index)}
              </Tab>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default TravelRequestScreen;
