import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  ACCESSIBILITY_IDENTIFIERS,
  ACCESSIBILITY_FEATURE_COMPONENTS,
  NAVIGATION_ROUTES,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';

const MyAssetsTab = (props) => {
  const history = useHistory();
  const accessibilityData = useSelector(selectAccessibilityData);
  const getTabData = props.getTabData;

  useEffect(() => {
    if (!props.data.length) {
      getTabData(props.tabKey);
    }
  }, [getTabData, props.data.length, props.tabKey]);

  const onPressAssetHandler = (assetData, ind, index) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.MY_PROFILE_ASSET_VIEW,
      assetData.list_id.toString(),
      ANALYTICS_ITEM_NAMES.MY_PROFILE_ASSETS
    );
    history.push(NAVIGATION_ROUTES.MY_ASSET_DETAIL, {
      assetDetailList: assetData.details,
      assetType: assetData.title,
      assetId: assetData.list_id,
      index: index,
      ind: ind,
    });
  };

  const renderAssetItem = (assetItemData, ind, index) => {
    if (assetItemData.details?.length) {
      return (
        <div
          key={index}
          onClick={() => {
            onPressAssetHandler(assetItemData, ind, index);
          }}
          className="my-profile-asset-container d-flex align-items-center pt-2 pb-2 pl-2 pr-2">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              assetItemData.icon_name,
              ACCESSIBILITY_IDENTIFIERS.PROFILE
            )}
            className="my-profile-asset-icon mr-3"
          />
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_DOT_INDICATOR,
              ACCESSIBILITY_IDENTIFIERS.PROFILE
            )}
            className="my-profile-asset-indicator-icon mr-3"
          />
          <p className="my-profile-asset-item-title">{assetItemData.title}</p>
        </div>
      );
    }
  };

  const renderAssetSection = (assetSectionData, index) => {
    return (
      assetSectionData.assets.some(
        (assetItem) => assetItem.details && assetItem.details.length && assetItem.list_id !== 370
      ) && (
        <div key={index} className="my-profile-asset-section-container p-3">
          <p className="my-profile-asset-section-title mb-0">{assetSectionData.title}</p>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 ml-3 mr-3">
            {assetSectionData.assets.map((assetItem, ind) =>
              renderAssetItem(assetItem, ind, index)
            )}
          </div>
        </div>
      )
    );
  };

  const showEmptyView = () => {
    return <DefaultContentView message={'You have no assets assigned yet'} />;
  };

  const errorMessageView = () => {
    return <DefaultContentView message={props?.errorMessage} />;
  };

  return (
    <div>
      {props.isLoading && (
        <div className="mt-3">
          <ActivityLoader visible={true} />
        </div>
      )}
      {props.errorMessage && props.errorMessage.trim() !== ''
        ? errorMessageView()
        : props.data.some((assetSection) =>
            assetSection.assets
              .filter(
                (assetItem) =>
                  assetItem.details && assetItem.details.length && assetItem.list_id !== 370
              )
              .some((assetItem) => assetItem.details && assetItem.details.length)
          )
        ? props.data.map((assetSection, index) => renderAssetSection(assetSection, index))
        : !props.isLoading && showEmptyView()}
    </div>
  );
};

export default MyAssetsTab;
