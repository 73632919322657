import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../../common/constants/AppConstants';
import HtmlView from '../../../../common/ui/html_view/HTMLView';
import { GET_INITIATIVE_DETAIL_BY_ID } from '../../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../../services/Service';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { saveProgressLoadingState } from '../../../../state/UIState';
import { getFeatureComponentUrl } from '../../../../utils/accessibility';
import {
  formatDateDDMonthYYYY,
  formatTimeAMPM,
  getUrlWithSpecificRendition,
} from '../../../../utils/common';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';

const ProjectDetailsTab = ({ microgiveInitiativeDetailId }) => {
  const dispatch = useDispatch();
  const [event, setEvent] = useState({});
  const accessibilityData = useSelector(selectAccessibilityData);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.PROJECTS_DETAIL_TAB_VISIT,
      microgiveInitiativeDetailId,
      ANALYTICS_ITEM_NAMES.MICROGIVE
    );
  }, [microgiveInitiativeDetailId]);

  const callDonationDetailApi = useCallback(async () => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(
        GET_INITIATIVE_DETAIL_BY_ID + microgiveInitiativeDetailId,
        REQUEST_TYPE.GET,
        {}
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setEvent(apiResponse.response.data);
        }
      }
    } catch (err) {
      handleError(
        err,
        {},
        GET_INITIATIVE_DETAIL_BY_ID + microgiveInitiativeDetailId,
        NAVIGATION_ROUTES.PROJECT_DETAIL_SCREEN
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch, microgiveInitiativeDetailId]);

  useEffect(() => {
    callDonationDetailApi();
  }, [callDonationDetailApi]);

  return (
    <div className="main-content-container">
      {!!Object.keys(event).length > 0 && (
        <div>
          <img
            src={getUrlWithSpecificRendition(
              event.initiative_details.promo_image.base_url,
              SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
              event.initiative_details.promo_image.image_path
            )}
            className="embed-responsive embed-responsive-16by9"
          />
          <div className="row ml-1 mt-1">
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_CALENDER,
                ACCESSIBILITY_IDENTIFIERS.MICROGIVE
              )}
              className="microgive-calender-style"
            />
            <div className="microgive-event-date">
              {`${formatDateDDMonthYYYY(event.initiative_details.event_date)}, ${formatTimeAMPM(
                event.initiative_details.time_range_from,
                'time'
              )} - ${formatTimeAMPM(event.initiative_details.time_range_to, 'time')}`}
            </div>
          </div>
          <div className="microgive-title">{event.title}</div>
          <HtmlView
            html={event.initiative_details.short_description}
            htmlStyle="mt-2 mb-2 microgive-content-html"
          />
        </div>
      )}
    </div>
  );
};

export default ProjectDetailsTab;
