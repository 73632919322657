import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
} from '../../../common/constants/AppConstants';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import Divider from '../../../common/ui/divider/Divider';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';

const NeoDocumentsTab = ({ documents, callNeoSessionDetailApi, loadmoreFlag }) => {
  const history = useHistory();
  const accessibilityData = useSelector(selectAccessibilityData);
  const [pageId, setPageId] = useState(1);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.NEO_DOCUMENTS_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.NEW_JOINEES_HIGHER_LEVELS
    );
  }, []);

  const loadMore = () => {
    if (loadmoreFlag) {
      callNeoSessionDetailApi(pageId + 1);
      setPageId(pageId + 1);
    }
  };

  useEffect(() => {
    callNeoSessionDetailApi();
  }, [callNeoSessionDetailApi]);

  const handleOnPress = (item) => {
    if (item?.type === 'Y') {
      history.push(`${NAVIGATION_ROUTES.FULL_SCREEN_VIDEO}/${item?.url}`);
    } else {
      window.open(item.url, '_blank');
    }
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_DOCUMENTS_FOUND} />;
  };

  const renderDocuments = (item, index) => {
    return (
      <div>
        <div className="d-flex flex-row py-3 px-1 align-items-center w-100">
          <div>
            {item.type === 'P' ? (
              <img
                onClick={() => {
                  handleOnPress(item);
                }}
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_PDF_ATTACHMENT,
                  ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                )}
                className="new-joinees-lower-levels-overview-pdf-icon mr-2"
              />
            ) : item.type === 'Y' ? (
              <img
                onClick={() => {
                  handleOnPress(item);
                }}
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_VIDEO_PLAY_BUTTON,
                  ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                )}
                className="new-joinees-lower-levels-overview-video-button-image mr-3"
              />
            ) : (
              <img
                onClick={() => {
                  handleOnPress(item);
                }}
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_LINK_SQUARE,
                  ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                )}
                className="new-joinees-neo-media-link-icon mr-3"
              />
            )}
          </div>
          <div
            onClick={() => {
              handleOnPress(item);
            }}
            className="neo-document-label common-cursor-pointer">
            {item?.label}
          </div>
          <img
            onClick={() => {
              handleOnPress(item);
            }}
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.MORE_INFO,
              ACCESSIBILITY_IDENTIFIERS.COMMON
            )}
            className="new-joinees-more_info_icon ml-auto align-self-center"
          />
        </div>
        {(index < documents.length - 1 || loadmoreFlag) && <Divider />}
      </div>
    );
  };

  return (
    <div>
      {documents?.length === 0
        ? emptyListView()
        : documents?.map((item, index) => {
            return <div key={`${index}`}>{renderDocuments(item, index)}</div>;
          })}
      {loadmoreFlag && (
        <div className="mb-4 mt-4">
          <LoadMoreBtn onClick={loadMore} />
        </div>
      )}
    </div>
  );
};

export default NeoDocumentsTab;
