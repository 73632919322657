import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBreakoutsTitles } from '../../state/MasterData';
import AgendaTab from './tabs/AgendaTab';
import DiscussionTab from './tabs/DiscussionTab';
import GalleryTab from './tabs/GalleryTab';
import InfoTab from './tabs/InfoTab';
import UpdatesTab from './tabs/UpdatesTab';
import { Tab } from 'react-bootstrap';
import { saveBreakoutsTabData, selectBreakoutsTabData } from '../../state/TabData';
import { useParams } from 'react-router-dom';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import NavTabs from '../../common/ui/tabs/NavTabs';
import BackButton from '../../common/ui/common_back_button/BackButton';
import AttendeesTab from './tabs/AttendeesTab';

const BreakoutsTabScreen = ({ location }) => {
  const dispatch = useDispatch();
  const [tabKey] = useState(
    location && location.state && location.state.tabKey && location.state.tabKey
  );
  const { breakoutId } = useParams();
  const breakoutTitles = useSelector(selectBreakoutsTitles);
  const breakoutTabData = useSelector(selectBreakoutsTabData);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.BREAKOUTS_TAB_SCREEN,
      NAVIGATION_ROUTES.BREAKOUTS_TAB_SCREEN
    );
  }, []);

  useEffect(() => {
    if (breakoutTitles.length && breakoutTabData === '' && tabKey) {
      dispatch(saveBreakoutsTabData(tabKey));
    } else if (breakoutTitles.length && breakoutTabData === '') {
      dispatch(saveBreakoutsTabData(breakoutTitles[0].key));
    }
  }, [breakoutTabData, breakoutTitles, dispatch, tabKey]);

  const renderTabs = (tab) => {
    switch (tab.key) {
      case 'agenda':
        return <AgendaTab breakoutId={breakoutId} tabKey={tab.key} />;
      case 'discussion':
        return <DiscussionTab breakoutId={breakoutId} tabKey={tab.key} />;
      case 'updates':
        return <UpdatesTab breakoutId={breakoutId} tabKey={tab.key} />;
      case 'info':
        return <InfoTab breakoutId={breakoutId} tabKey={tab.key} />;
      case 'gallery':
        return <GalleryTab breakoutId={breakoutId} tabKey={tab.key} />;
      case 'attendees':
        return <AttendeesTab breakoutId={breakoutId} tabKey={tab.key} />;
    }
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.BREAKOUTS} />
      <BackButton />
      <NavTabs
        activeKey={breakoutTabData}
        onSelect={(k) => dispatch(saveBreakoutsTabData(k))}
        unmountOnExit>
        {breakoutTitles.map((tab, index) => {
          return (
            <Tab key={`${index}`} eventKey={tab.key} title={tab.title}>
              {renderTabs(tab, index)}
            </Tab>
          );
        })}
      </NavTabs>
    </div>
  );
};

export default BreakoutsTabScreen;
