import React from 'react';
import { formatDateDDMonthYYYY } from '../../../utils/common';

const ContestsDetailListItem = ({ item, onPressTopView, onPressBottomView }) => {
  return (
    <div>
      {item.is_active === true && (
        <div className="material-card p-3 mb-3">
          <div onClick={() => onPressTopView(item)}>
            <div className="anniversary-cd-title">{item.title}</div>
            <div className="mt-1 anniversary-cd-desc">{item.description}</div>
            <div className="row pl-3 pr-3 mt-1 justify-content-between anniversary-outer-view">
              <div className="anniversary-end-date">{`Ends on: ${formatDateDDMonthYYYY(
                item.end_date
              )}`}</div>
              {item.completed === true && (
                <div className="anniversary-completed-text">Completed</div>
              )}
            </div>
          </div>
        </div>
      )}
      {item.is_active === false && (
        <div className="mt-2 mb-2 material-card p-3">
          <div onClick={() => onPressBottomView(item)}>
            <div className="anniversary-title-text">{item.title}</div>
            <div className="anniversary-start-date">{`Starts from: ${formatDateDDMonthYYYY(
              item.start_date
            )}`}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContestsDetailListItem;
