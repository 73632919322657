import { createSlice } from '@reduxjs/toolkit';

const saveAccessibilityDataReducer = (state, action) => {
  state.data = action.payload;
};

// REDUCER
const accessibilityDataSlice = createSlice({
  name: 'accessibilityData',
  initialState: {
    data: [],
  },
  reducers: {
    saveAccessibilityData: saveAccessibilityDataReducer,
  },
});

// ACTIONS
const { saveAccessibilityData } = accessibilityDataSlice.actions;

// SELECTOR
const selectAccessibilityData = ({ accessibilityData }) => accessibilityData.data;

const accessibilityDataSliceReducer = accessibilityDataSlice.reducer;

export { accessibilityDataSliceReducer, saveAccessibilityData, selectAccessibilityData };
