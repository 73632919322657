import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  CHAT_LEFT_PANEL_IDENTIFIERS,
  DIALOG_TYPE,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../../common/constants/AppConstants';
import BackButton from '../../../common/ui/common_back_button/BackButton';
import Divider from '../../../common/ui/divider/Divider';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import NewChatListItem from './NewChatListItem';
import { EMPLOYEES } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { selectChatGroupMembersLimit } from '../../../state/MasterData';
import { saveProgressLoadingState } from '../../../state/UIState';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../../utils/accessibility';
import { generatePrivateChatId, uId } from '../../../utils/ChatHandler';
import { showToast } from '../../../utils/common';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';

const NewChatView = (props) => {
  const {
    groupMemberList,
    setGroupMemberList,
    setSelectedChat,
    isNewGroupMode,
    setIsNewGroupMode,
    setLeftPanelType,
  } = props;
  const dispatch = useDispatch();
  const [employeeList, setEmployeeList] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [dataLength, setDataLength] = useState(0);
  const loadMoreFlagRef = useRef(true);
  const pageIdRef = useRef(1);
  const textInputRef = useRef();
  const searchTextRef = useRef('');
  const employeeListItemRef = useRef();
  const accessibilityData = useSelector(selectAccessibilityData);
  const employeeData = useSelector(selectEmployeeData);
  const maxSelected = useSelector(selectChatGroupMembersLimit) - 1;

  useEffect(() => {
    if (pageIdRef.current !== 1) {
      loadMoreFlagRef.current = false;
    }
  }, [dataLength]);

  const callEmployeeApi = useCallback(async () => {
    let paramsObj = { page_id: pageIdRef.current, query: searchTextRef.current };
    const params = isNewGroupMode ? { ...paramsObj } : { ...paramsObj, show_hidden: 1 };
    loadMoreFlagRef.current = false;
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(EMPLOYEES, REQUEST_TYPE.GET, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      loadMoreFlagRef.current = true;
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setDataLength(apiResponse.response.data.feeds.length);
          if (pageIdRef.current === 1) {
            let empList = apiResponse.response.data.feeds;
            if (empList.some((person) => person.employee_id === employeeData.employee_id)) {
              empList = empList.filter((obj) => obj.employee_id !== employeeData.employee_id);
            }
            setEmployeeList(empList);
          } else {
            let empList = apiResponse.response.data.feeds;
            if (empList.some((person) => person.employee_id === employeeData.employee_id)) {
              empList = empList.filter((obj) => obj.employee_id !== employeeData.employee_id);
            }
            setEmployeeList((oldArray) => [...oldArray, ...empList]);
          }
        }
      }
    } catch (err) {
      handleError(err, params, EMPLOYEES, NAVIGATION_ROUTES.CHAT);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch, employeeData.employee_id, isNewGroupMode]);

  const renderScreen = useCallback(() => {
    callEmployeeApi();
  }, [callEmployeeApi]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const onPressNewChatListItemHandler = (item) => {
    if (isNewGroupMode && item?.special_account === 1) {
      showToast('This employee cannot be added to your group.');
      return;
    }

    if (isNewGroupMode) {
      if (
        groupMemberList.some(
          (member) => member.employee_id.toString() === item.employee_id.toString()
        )
      ) {
        setGroupMemberList(
          groupMemberList.filter(
            (member) => member.employee_id.toString() !== item.employee_id.toString()
          )
        );
      } else {
        if (groupMemberList.length < maxSelected) {
          setGroupMemberList((prev) => [item, ...prev]);
        } else {
          showToast(`You cannot add more than ${maxSelected} people to the group`);
        }
      }
    } else {
      const otherUserId = uId(item);
      const selfUserId = uId(employeeData);
      const groupId = generatePrivateChatId(selfUserId, otherUserId);
      setSelectedChat({
        chatType: DIALOG_TYPE.ONE_ON_ONE_CHAT,
        groupId: groupId,
        userId: otherUserId,
        otherUser: item,
        fromRoute: NAVIGATION_ROUTES.NEW_CHAT,
      });
      setLeftPanelType(CHAT_LEFT_PANEL_IDENTIFIERS.DEFAULT_VIEW);
    }
  };

  const renderEmployee = (item, index) => {
    return (
      <NewChatListItem
        key={index.toString()}
        isEmployeeSelected={
          isNewGroupMode &&
          groupMemberList.map((element) => element.employee_id).includes(item.employee_id)
        }
        item={item}
        onPress={onPressNewChatListItemHandler}
      />
    );
  };

  const debouncedCallback = (event) => {
    if (event && event.target.value.length > 2) {
      event.preventDefault();
      pageIdRef.current = 1;
      loadMoreFlagRef.current = true;
      searchTextRef.current = event.target.value;
      employeeListItemRef.current.scrollTo(0, 0);
      setDataLength(0);
      callEmployeeApi();
    } else {
      searchTextRef.current = event.target.value;
    }
  };

  const debounceInputHandler = useCallback(debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME), [
    isNewGroupMode,
  ]);

  const onSearchSubmitHandler = (event) => {
    event.preventDefault();
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    employeeListItemRef.current.scrollTo(0, 0);
    setDataLength(0);
    callEmployeeApi();
  };

  const resetSearchHandler = useCallback(() => {
    textInputRef.current.reset();
    searchTextRef.current = '';
    pageIdRef.current = 1;
    loadMoreFlagRef.current = true;
    employeeListItemRef.current.scrollTo(0, 0);
    setDataLength(0);
    setIsSearch(false);
    callEmployeeApi();
  }, [callEmployeeApi]);

  const onClickSearchHandler = () => {
    setIsSearch(true);
  };

  const onScrollEmployeeListItem = () => {
    if (employeeListItemRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = employeeListItemRef.current;
      if (Math.ceil(scrollTop) + clientHeight === scrollHeight) {
        if (loadMoreFlagRef.current) {
          pageIdRef.current = pageIdRef.current + 1;
          callEmployeeApi();
        }
      }
    }
  };

  const deleteEmployee = (employee) => {
    if (groupMemberList.includes(employee)) {
      const index = groupMemberList.indexOf(employee);
      if (index > -1) {
        setGroupMemberList(groupMemberList.filter((item) => item !== groupMemberList[index]));
      }
    }
  };

  return (
    <>
      <div className="d-flex px-3 py-2 justify-content-between align-items-center chat-header">
        <div className="d-flex align-items-center">
          <BackButton
            isFullRow={false}
            backTitle=""
            containerStyle="py-0"
            navigateBackFuncHandler={() => {
              setLeftPanelType(CHAT_LEFT_PANEL_IDENTIFIERS.DEFAULT_VIEW);
              setIsNewGroupMode(false);
              setGroupMemberList([]);
            }}
          />
          {!isSearch && (
            <div className="chat-bold-text">{isNewGroupMode ? 'Add Members' : 'New Chat'}</div>
          )}
        </div>
        <div className="flex-grow-1">
          <div className="d-flex justify-content-between">
            {isSearch ? (
              <form className="w-100" onSubmit={onSearchSubmitHandler} ref={textInputRef}>
                <input
                  autoFocus
                  className="chat-search-bar p-2"
                  type="text"
                  placeholder="Search contact..."
                  onChange={debounceInputHandler}
                />
              </form>
            ) : (
              <div />
            )}
            {isSearch ? (
              <div
                className="d-flex align-items-center chat-search-cross-container pr-1"
                onClick={resetSearchHandler}>
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
                  )}
                  className="header-icon"
                />
              </div>
            ) : (
              <div className="pr-1" onClick={onClickSearchHandler}>
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_SEARCH_GREY,
                    ACCESSIBILITY_IDENTIFIERS.COMMON
                  )}
                  className="chat-icon-search common-cursor-pointer"
                />
              </div>
            )}
          </div>
        </div>
        {isNewGroupMode && (
          <div
            onClick={() => {
              if (
                isNewGroupMode &&
                (groupMemberList.length < 2 || groupMemberList.length > maxSelected)
              ) {
                showToast(
                  `Number of members should not be less than 2 and more than ${maxSelected}`
                );
              } else {
                setLeftPanelType(CHAT_LEFT_PANEL_IDENTIFIERS.CONFIRM_CHAT_VIEW);
                setIsNewGroupMode(false);
              }
            }}
            className={`ml-2 ${
              groupMemberList.length <= 0
                ? 'chat-theme-highlighted-text-disabled common-cursor-not-allowed'
                : 'chat-theme-highlighted-text common-cursor-pointer'
            }`}>
            Next
          </div>
        )}
      </div>

      <div
        ref={employeeListItemRef}
        onScroll={() => onScrollEmployeeListItem()}
        className="chat-left-panel-container">
        {!isSearch && !isNewGroupMode && (
          <div
            className="common-cursor-pointer chat-sidebar-item"
            onClick={() => {
              setIsNewGroupMode(true);
            }}>
            <div>
              <div className="d-flex px-4 py-3">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_GROUP_THEME,
                    ACCESSIBILITY_IDENTIFIERS.COMMON
                  )}
                  className="chat-new-group-icon mr-3"
                />
                <div className="chat-theme-highlighted-text chat-bold-text">New Group</div>
              </div>
              <Divider style="chat-divider-thin" />
            </div>
          </div>
        )}
        {isNewGroupMode && (
          <div>
            <div className="d-flex flex-wrap mt-2 chat-employee-selected-outer-container overflow-auto px-3 pt-2">
              {groupMemberList.length > 0 &&
                groupMemberList.map((item, index) => {
                  return (
                    <div className="d-flex mr-2" key={`${index}`}>
                      <div className="d-flex align-items-center chat-selected-employee-container mb-2">
                        {!!item.profile_picture && (
                          <UserProfilePhoto
                            imageBaseUrl={item.profile_picture.base_url}
                            imagePath={item.profile_picture.image_path}
                            imageClass="common-user-profile-photo-xxs"
                          />
                        )}
                        <div className="chat-base-text-sm pt-1 ml-1 mr-2">
                          {item.first_name + ' ' + item.last_name}
                        </div>
                        <div
                          className="common-cursor-pointer pr-1"
                          onClick={() => deleteEmployee(item)}>
                          <img
                            src={getFeatureComponentUrl(
                              accessibilityData,
                              ACCESSIBILITY_FEATURE_COMPONENTS.SOM_EMPLOYEE_CANCEL,
                              ACCESSIBILITY_IDENTIFIERS.SOM
                            )}
                            className="chat-selected-employee-close-icon"
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            {groupMemberList.length > 0 && <Divider style="chat-divider-thin mt-2 mb-3" />}
            <div
              className={`chat-base-text-sm chat-bold-text px-3 ${
                groupMemberList.length === maxSelected ? 'chat-theme-highlighted-text' : ''
              } `}>
              {groupMemberList.length}/{maxSelected} selected
            </div>
          </div>
        )}
        {employeeList.length > 0 ? (
          employeeList.map((item, index) => renderEmployee(item, index))
        ) : (
          <DefaultContentView
            message={ERROR_MESSAGES.SEARCH_NO_RESULTS}
            iconUri={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.CELEBRATIONS_SEARCH_FAILED,
              ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
            )}
          />
        )}
      </div>
    </>
  );
};

export default React.memo(NewChatView);
