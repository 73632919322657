import React, { useState, useEffect, useCallback } from 'react';
import { apiRequest, handleError } from '../../../../services/Service';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import LoadMoreBtn from '../../../../common/ui/load_more_btn/LoadMoreBtn';
import DiscussionTabItem from '../../../microgive/components/DiscussionTabItem';
import { useDispatch, useSelector } from 'react-redux';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../../state/UIState';
import { isEmptyText, showToast } from '../../../../utils/common';
import CommentBox from '../../../../common/ui/comment_box/CommentBox';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../../common/ui/activity_loader/ActivityLoader';

const CommentsTab = ({ identifier }) => {
  const dispatch = useDispatch();
  const [discussions, setDiscussions] = useState([]);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const [pageId, setPageId] = useState(1);
  const [commentText, setCommentText] = useState('');
  const [loading, setLoading] = useState(true);

  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ANNIVERSARY_COMMENTS_VISIT,
      identifier,
      ANALYTICS_ITEM_NAMES.ANNIVERSARY
    );
  }, [identifier]);

  const callDiscussionListApi = useCallback(
    async (id, loadMore) => {
      if (loadMore) {
        const params = {
          page_id: id,
        };
        try {
          setLoading(true);
          const apiResponse = await apiRequest(
            identifier + '/discussions',
            REQUEST_TYPE.GET,
            params
          );
          setLoading(false);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                setDiscussions((oldArray) => [
                  ...apiResponse.response.data.feeds.reverse(),
                  ...oldArray,
                ]);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(
            err,
            params,
            identifier + '/discussions',
            NAVIGATION_ROUTES.ANNIVERSARY_SCREEN
          );
          setLoading(false);
        }
      }
    },
    [identifier]
  );

  useEffect(() => {
    callDiscussionListApi(1, true);
  }, [callDiscussionListApi]);

  const callCommentApi = async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.POSTING_DISCUSSION,
        })
      );
      const params = new URLSearchParams();
      params.append('description', commentText.trim());
      const apiResponse = await apiRequest(
        identifier + '/discussion/post',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setCommentText('');
          setPageId(1);
          setDiscussions([]);
          setLoadMoreFlag(true);
          callDiscussionListApi(1, true);
        }
      }
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressSend = () => {
    if (!isEmptyText(commentText)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      logEvent(
        ANALYTICS_EVENT_TYPES.ANNIVERSARY_COMMENT,
        commentText,
        ANALYTICS_ITEM_NAMES.COMMENT
      );
      callCommentApi();
    }
  };

  const handleChange = (value) => {
    setCommentText(value);
  };

  const onPressLoadPrevious = () => {
    callDiscussionListApi(pageId, loadMoreFlag);
  };

  return (
    <div className="main-content-container">
      <ActivityLoader visible={loading} />
      <div>
        {loadMoreFlag && (
          <LoadMoreBtn onClick={onPressLoadPrevious} isLoading={loading} btnText="Load Previous" />
        )}
        {discussions.map((item, index) => {
          return <DiscussionTabItem key={`${index}`} item={item} />;
        })}
      </div>
      <CommentBox
        getCommentText={onPressSend}
        onChangehandler={handleChange}
        commentText={commentText}
        textValue="Send"
        showLoader={progressLoadingState.isProgressLoading}
      />
    </div>
  );
};

export default CommentsTab;
