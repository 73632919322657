import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
} from '../../common/constants/AppConstants';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { apiRequest, handleError } from '../../services/Service';
import { PODCAST_CHANNEL_BY_ID } from '../../services/ApiUrls';
import { saveProgressLoadingState } from '../../state/UIState';
import { useParams } from 'react-router-dom';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import PodcastContent from './components/PodcastContent';
import BackButton from '../../common/ui/common_back_button/BackButton';

const PodcastContentScreen = () => {
  const { channelId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.PODCASTS_CONTENT, NAVIGATION_ROUTES.PODCASTS_CONTENT);
  }, []);

  const [channelData, setChannelData] = useState({});

  const callPodcastApi = useCallback(async () => {
    const params = {
      channel_id: channelId,
    };
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const apiResponse = await apiRequest(PODCAST_CHANNEL_BY_ID, REQUEST_TYPE.GET, params);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setChannelData(apiResponse.response.data.channel);
        }
      }
    } catch (err) {
      handleError(err, params, PODCAST_CHANNEL_BY_ID, NAVIGATION_ROUTES.PODCASTS_CONTENT);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [channelId, dispatch]);

  useEffect(() => {
    callPodcastApi();
  }, [callPodcastApi]);

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.PODCASTS} />
      <BackButton />
      <div className="main-content-container">
        <PodcastContent channelId={channelId} channelData={channelData} />
      </div>
    </div>
  );
};

export default PodcastContentScreen;
