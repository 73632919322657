import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiRequest, handleError } from '../../../../services/Service';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import { selectEmployeeData } from '../../../../state/EmployeeData';
import SuperListItem from '../../components/SuperListItem';
import SuperFilterItem from '../../components/SuperFilterItem';
import DefaultContentView from '../../../../common/ui/default_content_view/DefaultContentView';
import { saveShowEmptyContentView, selectShowEmptyContentView } from '../../../../state/UIState';
import { selectContestData } from '../../../../state/AnniversaryData';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../../common/ui/activity_loader/ActivityLoader';

const SuperTab = ({ identifier }) => {
  const dispatch = useDispatch();
  const [discussions, setDiscussions] = useState([]);
  const [contestsCategories, setContestsCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [contestId, setContestId] = useState('');
  const [employee, setEmployee] = useState({});

  const employeeData = useSelector(selectEmployeeData);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const contests = useSelector(selectContestData);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ANNIVERSARY_SUPER_VISIT,
      identifier,
      ANALYTICS_ITEM_NAMES.ANNIVERSARY
    );
  }, [identifier]);

  useEffect(() => {
    let contestCat = [];
    contestCat.push({ contestId: '', hashTag: '#overall' });
    contests.forEach((element) => {
      contestCat.push({ contestId: element.contest_id, hashTag: element.hash_tag });
    });
    setContestsCategories([...contestCat]);
  }, [contests]);

  const callMomentsApi = useCallback(
    async (catId) => {
      dispatch(saveShowEmptyContentView(true));
      const params = {
        contest_id: catId,
      };
      try {
        setLoading(true);
        const apiResponse = await apiRequest(identifier + '/super30', REQUEST_TYPE.GET, params);
        setLoading(false);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            setEmployee(apiResponse.response.data.employee);
            if (apiResponse.response.data.leaderboard.length > 0) {
              setDiscussions((oldArray) => [...oldArray, ...apiResponse.response.data.leaderboard]);
            }
          }
        }
      } catch (err) {
        handleError(err, params, identifier + '/super30', NAVIGATION_ROUTES.ANNIVERSARY_SCREEN);
        setLoading(false);
      }
    },
    [dispatch, identifier]
  );

  useEffect(() => {
    callMomentsApi('');
  }, [callMomentsApi]);

  const onPress = (item) => {
    setContestId(item.contestId);
    setDiscussions([]);
    callMomentsApi(item.contestId);
  };

  const renderFilters = (data, index) => {
    return <SuperFilterItem item={data} onPress={onPress} key={`${index}`} contestId={contestId} />;
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.SEARCH_NO_RESULTS} />;
  };

  return (
    <div className="main-content-container">
      <ActivityLoader visible={loading} />
      <div className="anniversary-outer-view anniversary-super-bottom-view mb-2">
        <div className="ml-3">
          <div className="anniversary-date-view-style">
            <div className="anniversary-your-position">Your Position</div>
          </div>
          <div className="mt-1 mb-1 anniversary-super-name">
            {Object.keys(employee).length === 0
              ? `${employeeData.first_name} ${employeeData.last_name}`
              : `${employee.first_name} ${employee.last_name}`}
          </div>
          <div className="mb-2 cheers-points-text">
            {Object.keys(employee).length === 0
              ? 'Not participated yet'
              : `${employee.earned_points} Points`}
          </div>
        </div>
      </div>
      <div className="anniversary-date-view-style mb-3">
        {contestsCategories.length > 0 &&
          contestsCategories.map((data, index) => renderFilters(data, index))}
      </div>
      {showEmptyContentView && !discussions.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          {discussions.map((item, index) => {
            return <SuperListItem item={item} key={`${index}`} />;
          })}
        </div>
      )}
    </div>
  );
};

export default SuperTab;
