import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
} from '../../../../common/constants/AppConstants';
import HtmlView from '../../../../common/ui/html_view/HTMLView';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';

const LowerLevelsQuickLinksTab = (props) => {
  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.NEW_JOINEES_QUICK_LINKS_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.NEW_JOINEES_LOWER_LEVELS
    );
  }, []);

  const onPressQuickLinkHandler = (linkData) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.NEW_JOINEES_LOWER_LEVELS_VIEW_QUICK_LINK,
      linkData.link_id,
      ANALYTICS_ITEM_NAMES.NEW_JOINEES_LOWER_LEVELS
    );
    window.open(linkData.web_link, '_blank');
  };

  const renderQuickLink = (data) => {
    return (
      <div
        // component={Link}
        key={data.link_id.toString()}
        className="common-cursor-pointer"
        onClick={() => onPressQuickLinkHandler(data)}>
        <div className="p-3 mb-3 new-joinees-lower-levels-quick-links-container">
          <h6 className="new-joinees-lower-levels-quick-links-title">{data.title}</h6>
          <p className="new-joinees-lower-levels-quick-links-description m-0">
            <HtmlView html={data.description} />
          </p>
        </div>
      </div>
    );
  };

  if (props.data) {
    return <div>{props.data.map((quickLink) => renderQuickLink(quickLink))}</div>;
  } else {
    return null;
  }
};

export default React.memo(LowerLevelsQuickLinksTab);
