import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import BackButton from '../../common/ui/common_back_button/BackButton';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { selectEmployeeData } from '../../state/EmployeeData';
import { selectAnniversaryConfig } from '../../state/MasterData';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';

const PledgeScreen = ({ location, history }) => {
  const { identifier } = location.state;
  const [name, setName] = useState('');
  const [messages, setMessages] = useState([]);
  const [empMessage, setEmpMessage] = useState('');

  const employeeData = useSelector(selectEmployeeData);
  const anniversaryConfig = useSelector(selectAnniversaryConfig).find(
    (element) => element.identifier === identifier
  );

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.PLEDGE_SCREEN, NAVIGATION_ROUTES.PLEDGE_SCREEN);
  }, []);

  useEffect(() => {
    let doj = employeeData.date_of_joining;
    if (doj) {
      const oneDay = 24 * 60 * 60 * 1000;
      const joiningDate = new Date(doj);
      const currentDate = new Date();
      const diffDays = Math.round(Math.abs((currentDate - joiningDate) / oneDay));
      if (diffDays <= 365) {
        setEmpMessage(`${diffDays} Days`);
      } else {
        const year = Math.floor(diffDays / 365);
        const month = Math.floor((diffDays % 365) / 30);
        if (year === 1 && month === 1) {
          setEmpMessage(`${year} year, ${month} month`);
        } else if (year > 1 && month === 1) {
          setEmpMessage(`${year} years, ${month} month`);
        } else if (year === 1 && month > 1) {
          setEmpMessage(`${year} year, ${month} months`);
        } else {
          setEmpMessage(`${year} years, ${month} months`);
        }
      }
    }
  }, [employeeData.date_of_joining]);

  useEffect(() => {
    setName(employeeData.first_name + ' ' + employeeData.last_name);
  }, [employeeData.first_name, employeeData.last_name]);

  useEffect(() => {
    if (
      anniversaryConfig &&
      anniversaryConfig.pledge_message &&
      anniversaryConfig.pledge_message.length > 0
    ) {
      let arr = [];
      anniversaryConfig.pledge_message.forEach((element) => {
        let value = element;
        if (value.includes('tenureDays')) {
          const msg = value.replace('tenureDays', empMessage);
          arr.push(msg);
        } else {
          arr.push(element);
        }
      });
      setMessages([...arr]);
    }
  }, [anniversaryConfig, empMessage]);

  const onPress = () => {
    history.push(NAVIGATION_ROUTES.TAKE_THE_PLEDGE_SCREEN);
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY} />
      <BackButton />
      <div className="main-content-container">
        <div className="anniversary-message-item">{`Dear ${name}`}</div>
        {messages.length > 0 &&
          messages.map((item, index) => {
            return (
              <div key={`${index}`} className="mt-1 mb-1 anniversary-message-item">
                {item}
              </div>
            );
          })}
        <CustomButton buttonStyle="anniversary-take-pledge-btn mr-3" onClick={onPress}>
          Take the pledge
        </CustomButton>
      </div>
    </div>
  );
};

export default PledgeScreen;
