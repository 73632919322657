import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveSearchText, selectBaseUrl } from '../../state/UIState';
import { Tab, Modal, Form } from 'react-bootstrap';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { selectArticleFilters, selectMicrobizTitles } from '../../state/MasterData';
import { saveMicrobizTabData, selectMicrobizTabData } from '../../state/TabData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import MicrobizTab from './tabs/MicrobizTab';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import NavTabs from '../../common/ui/tabs/NavTabs';
import SearchComponent from '../../common/ui/search/SearchComponent';

const MicrobizScreen = ({ history }) => {
  const microbizTabs = useSelector(selectMicrobizTitles);
  const microbizTabData = useSelector(selectMicrobizTabData);
  const dispatch = useDispatch();
  const baseUrl = useSelector(selectBaseUrl);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [showNewsDialog, setShowNewsDialog] = useState(false);
  const [selectedDate, setSelectedDate] = useState({});
  const dateResponse = useSelector(selectArticleFilters);

  const dateArray = [];
  for (var date in dateResponse) {
    dateArray.push(dateResponse[date].title);
  }
  const tempDates = {};
  const tempClearFilters = {};
  for (var val in microbizTabs) {
    tempDates[microbizTabs[val].category_id] = '';
    tempClearFilters[microbizTabs[val].category_id] = false;
  }
  const [selectedDatesArray, setSelectedDatesArray] = useState({});
  const [clearFiltersArray, setClearFilterArray] = useState({});

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.MICROBIZ, NAVIGATION_ROUTES.MICROBIZ);
  }, []);

  useEffect(() => {
    if (microbizTabs.length && microbizTabData === '') {
      dispatch(saveMicrobizTabData(microbizTabs[0].category_id));
    }
  }, [dispatch, microbizTabData, microbizTabs]);

  const clearFilters = () => {
    setSelectedDate((prev) => ({
      ...prev,
      [microbizTabData]: '',
    }));
    setSelectedDatesArray((prev) => ({ ...prev, [microbizTabData]: '' }));
    setClearFilterArray((prev) => ({ ...prev, [microbizTabData]: true }));
  };

  const callDateFilterFunction = () => {
    const value = selectedDate[microbizTabData]
      ? selectedDate[microbizTabData]
      : dateResponse[0].title;
    const tempdate = dateResponse.find((res) => res.title === value).value;
    setSelectedDatesArray((prev) => ({ ...prev, [microbizTabData]: tempdate }));
    setClearFilterArray((prev) => ({ ...prev, [microbizTabData]: false }));
  };

  const renderTabs = (tab) => {
    switch (tab.category_id) {
      case tab.category_id:
        return (
          <MicrobizTab
            item={tab}
            selectedDate={selectedDatesArray}
            clearFilters={clearFiltersArray}
          />
        );
    }
  };

  const CustomYearView = () => {
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Label>Select Year</Form.Label>
            <div className="microbiz-year-containe">
              <Form.Control
                as="select"
                custom
                value={selectedDate[microbizTabData]}
                onChange={(event) => {
                  setSelectedDate((prev) => ({
                    ...prev,
                    [microbizTabData]: event.target.value,
                  }));
                }}>
                {dateArray &&
                  dateArray.map((item) => {
                    return (
                      <option key={item} defaultValue={item === selectedDate[microbizTabData]}>
                        {item}
                      </option>
                    );
                  })}
              </Form.Control>
            </div>
          </Form.Group>
        </Form>
        <div className="d-flex justify-content-between">
          <div>
            <CustomButton
              buttonStyle="microbiz-calender-button-style"
              onClick={() => {
                setShowNewsDialog(false);
                clearFilters();
              }}>
              CLEAR FILTERS
            </CustomButton>
          </div>
          <div className="d-flex justify-content-between">
            <CustomButton
              buttonStyle="microbiz-calender-button-style"
              onClick={() => {
                setShowNewsDialog(false);
              }}>
              CANCEL
            </CustomButton>
            <CustomButton
              buttonStyle="microbiz-calender-button-style"
              onClick={() => {
                setShowNewsDialog(false);
                callDateFilterFunction();
              }}>
              DONE
            </CustomButton>
          </div>
        </div>
      </div>
    );
  };

  const handleSubmitFunction = (searchText) => {
    dispatch(saveSearchText(searchText));
    history.push(NAVIGATION_ROUTES.MICROBIZ_SEARCH, { searchQuery: searchText });
  };

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
        <SearchComponent handleSubmitFunction={handleSubmitFunction} />
        <div className="gcdo-icon-row mr-3">
          <img
            src={getHeaderComponentUrl(accessibilityData, ACCESSIBILITY_HEADER_COMPONENTS.CALENDER)}
            className="header-icon"
            onClick={() => {
              setShowNewsDialog(true);
            }}
          />
        </div>
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICROBIZ} />
      <Modal
        show={showNewsDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowNewsDialog(false)}>
        <Modal.Body>{CustomYearView()}</Modal.Body>
      </Modal>
      {baseUrl !== '' && (
        <NavTabs
          activeKey={microbizTabData}
          unmountOnExit
          onSelect={(k) => dispatch(saveMicrobizTabData(k))}>
          {microbizTabs &&
            microbizTabs.map((tab, index) => {
              return (
                <Tab
                  key={`${index}`}
                  eventKey={tab.category_id}
                  title={tab.title}
                  className="main-content-container">
                  {renderTabs(tab, index)}
                </Tab>
              );
            })}
        </NavTabs>
      )}
    </div>
  );
};

export default MicrobizScreen;
