import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  FILTER_IDENTIFIERS,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { STARS_BY_ID } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectSomTabs } from '../../state/MasterData';
import EventTab from './tabs/EventTab';
import ForewordTab from './tabs/ForewordTab';
import StarTab from './tabs/StarTab';
import { Tab } from 'react-bootstrap';
import Filter from '../../common/ui/filter/Filter';
import EBookTab from './components/EBookTab';
import { saveSomTabData, selectSomTabData } from '../../state/TabData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { useLocation, useParams } from 'react-router-dom';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import NavTabs from '../../common/ui/tabs/NavTabs';
import BackButton from '../../common/ui/common_back_button/BackButton';
import BusinessAwardeesTab from './tabs/BusinessAwardeesTab';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import { selectAccessData } from '../../state/UserAccessData';

const SomTabScreen = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { somId } = useParams();

  const [somEmployees, setSomEmployees] = useState();
  const [businessAwardees, setBusinessAwardees] = useState([]);
  const [somMedia, setSomMedia] = useState();
  const [youtubeUrl, setYoutubeUrl] = useState();
  const [showfilter, setShowFilter] = useState(false);
  const somTabs = useSelector(selectSomTabs);
  const somTabData = useSelector(selectSomTabData);
  const [showBusinessAwardees, setShowBusinessAwardees] = useState(true);

  const visibleTabs = location?.state?.visible_tabs;

  const filteredList = somTabs.filter((item) => visibleTabs?.includes(item?.key_title));
  const visibleSomTabs = filteredList.filter((item) => item.isVisible === true);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [noMatchFlag, setNoMatchFlag] = useState(false);

  const appliedFiltersRef = useRef({});
  const accessData = useSelector(selectAccessData);
  const [showFilterButton, setShowFilterButton] = useState(true);
  const somAccessData = accessData?.data?.find((item) => item.feature_key === '_starsOfML');

  useEffect(() => {
    if (somAccessData) {
      const actions = somAccessData?.actions;
      setShowFilterButton(actions?.includes('_filter'));
    }
  }, [somAccessData]);

  useEffect(() => {
    if (visibleTabs.includes('business')) {
      setShowBusinessAwardees(true);
    } else {
      setShowBusinessAwardees(false);
    }
  }, [visibleTabs]);

  useEffect(() => {
    if (Number(somTabData) === 1) {
      setShowFilter(true);
    } else {
      setShowFilter(false);
    }
  }, [somTabData, showfilter]);

  useEffect(() => {
    if (visibleSomTabs.length && somTabData === '') {
      dispatch(saveSomTabData(visibleSomTabs[0].key));
    }
  }, [dispatch, somTabData, visibleSomTabs]);

  const callStarsApi = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    let tempParams = {};
    tempParams = { ...tempParams, awardee_type: 'tenure' };
    if (Object.keys(appliedFiltersRef.current).length) {
      tempParams = { ...tempParams, ...appliedFiltersRef.current };
    }
    try {
      const apiResponse = await apiRequest(
        STARS_BY_ID + somId + '/employees',
        REQUEST_TYPE.GET,
        tempParams
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            setSomEmployees(data.years);
          }
        }
      }
    } catch (err) {
      handleError(err, tempParams, STARS_BY_ID + somId + '/employees', NAVIGATION_ROUTES.SOM_TAB);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, somId]);

  const callBusinessStarsApi = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    setNoMatchFlag(false);
    let tempParams = {};
    tempParams = { ...tempParams, awardee_type: 'business' };
    if (Object.keys(appliedFiltersRef.current).length) {
      tempParams = { ...tempParams, ...appliedFiltersRef.current };
    }
    try {
      const apiResponse = await apiRequest(
        STARS_BY_ID + somId + '/employees',
        REQUEST_TYPE.GET,
        tempParams
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            setBusinessAwardees(data.years);
          }
        }
        if (apiResponse.response.data.years === 0) {
          setNoMatchFlag(true);
        }
      }
    } catch (err) {
      handleError(err, tempParams, STARS_BY_ID + somId + '/employees', NAVIGATION_ROUTES.SOM_TAB);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, somId]);

  const callMediaApi = useCallback(async () => {
    try {
      const apiResponse = await apiRequest(STARS_BY_ID + somId + '/media', REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.media) {
            const data = apiResponse.response.data.media;
            if (data.youtube.url) {
              setYoutubeUrl(data.youtube.url);
            }
            setSomMedia(data);
          }
        }
      }
    } catch (err) {
      handleError(err, {}, STARS_BY_ID + somId + '/media', NAVIGATION_ROUTES.SOM_TAB);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, somId]);

  const renderScreen = useCallback(() => {
    callStarsApi();
    callMediaApi();
    callBusinessStarsApi();
  }, [callMediaApi, callStarsApi, callBusinessStarsApi]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const renderTabs = (tab) => {
    switch (tab.key) {
      case 1:
        return <StarTab data={somEmployees} item={tab.key} />;
      case 2:
        return (
          <EventTab
            data={somMedia && somMedia.gallery}
            youtubeId={youtubeUrl}
            item={tab.key}
            loading={progressLoadingState.isProgressLoading}
          />
        );
      case 3:
        return <ForewordTab data={somMedia && somMedia.forewords} item={tab.key} />;
      case 4:
        return <EBookTab data={somMedia} item={tab.key} />;
      case 5:
        return (
          <BusinessAwardeesTab data={businessAwardees} item={tab.key} noMatchFlag={noMatchFlag} />
        );
    }
  };

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.SOM_TAB, NAVIGATION_ROUTES.SOM_TAB);
  }, []);

  const filterData = useCallback(
    (selectedFilters) => {
      let tempFilterObject = {};
      if (selectedFilters) {
        Object.keys(selectedFilters).map((filterKey) => {
          if (selectedFilters[filterKey].length) {
            tempFilterObject[filterKey] = selectedFilters[filterKey].join();
          }
        });
      }
      appliedFiltersRef.current = tempFilterObject;
      callStarsApi();
    },
    [callStarsApi]
  );

  useEffect(() => {
    if (showfilter === false) {
      filterData({});
    }
  }, [filterData, showfilter]);

  useEffect(() => {
    if (somTabData === '' && visibleSomTabs.length > 0 && showBusinessAwardees === false) {
      dispatch(saveSomTabData(1));
    }
  }, [dispatch, showBusinessAwardees, somTabData, visibleSomTabs.length]);

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_SOM_TABS} />;
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.SOM} />
      <BackButton />
      {showfilter && showFilterButton && (
        <div className="pt-3">
          <Filter
            topFilter={FILTER_IDENTIFIERS.GEOGRAPHIES}
            geographies={true}
            locations={true}
            levels={true}
            layers={true}
            onClick={filterData}
          />
        </div>
      )}

      {visibleSomTabs.length === 0 ? (
        emptyListView()
      ) : (
        <NavTabs
          activeKey={somTabData}
          onSelect={(k) => {
            dispatch(saveSomTabData(k));
          }}
          unmountOnExit>
          {showBusinessAwardees === false
            ? visibleSomTabs.map((tab, index) => (
                <Tab
                  key={`${index}`}
                  eventKey={tab.key}
                  title={tab.title}
                  className="main-content-container">
                  {renderTabs(tab, index)}
                </Tab>
              ))
            : visibleSomTabs.map((tab, index) => (
                <Tab
                  key={`${index}`}
                  eventKey={tab.key}
                  title={tab.title1}
                  className="main-content-container">
                  {renderTabs(tab, index)}
                </Tab>
              ))}
        </NavTabs>
      )}
    </div>
  );
};

export default SomTabScreen;
