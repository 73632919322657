import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveStoreAddress, selectStoreAddress } from '../../../state/EStoreData';
import { saveProgressLoadingState } from '../../../state/UIState';
import { apiRequestEStore } from '../../../services/Service';
import { REQUEST_TYPE } from '../../../common/constants/AppConstants';
import { STORE_ADDRESS } from '../../../services/ApiUrls';

const StoreAddress = ({ address }) => {
  const storeAddress = useSelector(selectStoreAddress);
  const dispatch = useDispatch();

  const fetchStoreAddress = useCallback(async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      const apiResponse = await apiRequestEStore(STORE_ADDRESS, REQUEST_TYPE.GET);
      if (apiResponse.response) {
        dispatch(saveStoreAddress(apiResponse.response[0]));
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (e) {
      console.log(e);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(storeAddress).length === 0) {
      fetchStoreAddress();
    }
  }, [fetchStoreAddress, storeAddress]);

  return (
    <div>
      <div className="d-flex estore-bold-text">
        {address.firstname} {address.lastname}
      </div>
      <div className="estore-regular-text">{storeAddress.street_1}</div>
      <div className="estore-regular-text">{storeAddress.street_2}</div>
      <div className="estore-regular-text">
        {storeAddress.city} - {storeAddress.postcode}
      </div>
      <div className="estore-regular-text">{storeAddress.region}</div>
      <div className="d-flex estore-regular-text">
        Mobile:
        <div className="ml-1 estore-bold-text">{address.telephone}</div>
      </div>
    </div>
  );
};

export default StoreAddress;
