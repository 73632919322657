import React, { useState, useEffect } from 'react';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ACTION_TYPES,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import SelectedEmployeeListItem from './components/SelectedEmployeeListItem';
import { apiRequest, handleError } from '../../services/Service';
import { CHEERS_DONATE_POINTS } from '../../services/ApiUrls';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { selectCurrentPointsData } from '../../state/CheersData';
import AllEmployee from './components/AllEmployee';
import { Modal } from 'react-bootstrap';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { showToast } from '../../utils/common';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';

const DonatePointsScreen = ({ history }) => {
  const dispatch = useDispatch();

  const [isSelectedOption, setIsSelectedOption] = useState(false);
  const [text, setText] = useState('Select Recipients');
  const [empDetail, setEmpDetail] = useState({});
  const [isFirstViewVisible, setIsFirstViewVisible] = useState(false);
  const [isSecondViewVisible, setIsSecondViewVisible] = useState(false);
  const [buttonIsDisable, setButtonIsDisable] = useState(true);
  const [amount, setAmount] = useState(100);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [currentBalance, setCurretBalance] = useState();
  const [showEmployeeView, setShowEmployeeView] = useState(false);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [selectedEmployee, setSelectedEmployee] = useState({});

  const accessibilityData = useSelector(selectAccessibilityData);
  const currentPoints = useSelector(selectCurrentPointsData);

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.DONATE_POINTS_SCREEN,
      NAVIGATION_ROUTES.DONATE_POINTS_SCREEN
    );
  }, []);

  useEffect(() => {
    setCurretBalance(currentPoints);
  }, [currentPoints]);

  useEffect(() => {
    dispatch({ type: ACTION_TYPES.GET_CHEERS_POINTS, payload: { type: 'current' } });
  }, [dispatch]);

  const onPressButton = () => {
    setIsSelectedOption(true);
    setIsFirstViewVisible(true);
  };

  const onPressPlus = () => {
    if (amount < 1000) {
      setAmount(amount + 100);
    }
  };

  const onPressMinus = () => {
    if (amount > 100) {
      setAmount(amount - 100);
    }
  };

  const callDonatePointsApi = async () => {
    const params = new URLSearchParams();
    params.append('employee_id', empDetail.empId);
    params.append('points', amount);
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: 'Updating Points...',
        })
      );
      const apiResponse = await apiRequest(CHEERS_DONATE_POINTS, REQUEST_TYPE.POST, params);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setShowSuccessDialog(true);
        }
      }
    } catch (err) {
      handleError(err, params, CHEERS_DONATE_POINTS, NAVIGATION_ROUTES.DONATE_POINTS_SCREEN);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressDonateNow = () => {
    if (Object.keys(empDetail).length > 0) {
      if (isNaN(amount)) {
        showToast(ERROR_MESSAGES.ENTER_POINTS);
      } else if (amount === 0) {
        showToast(ERROR_MESSAGES.ENTER_POINTS_MULTIPLE_TEN);
      } else if (amount < 100) {
        showToast(ERROR_MESSAGES.ENTER_POINTS_MULTIPLE_TEN);
      } else if (amount > parseInt(currentBalance)) {
        showToast(ERROR_MESSAGES.NOT_SUFFICIENT_POINTS);
      } else if (amount % 100 !== 0) {
        showToast(ERROR_MESSAGES.POINTS_SHARED);
      } else {
        callDonatePointsApi();
      }
    } else {
      showToast(ERROR_MESSAGES.SELECT_RECIPIENTS);
    }
  };

  const onTextChange = (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      setAmount(parseInt(event.target.value));
    }
  };

  const onPressDonateAgain = () => {
    setShowSuccessDialog(false);
    history.replace(NAVIGATION_ROUTES.DONATE_POINTS_SCREEN, {});
  };

  const onPressCheersHome = () => {
    setShowSuccessDialog(false);
    history.go(-2);
  };

  const successDialog = () => {
    return (
      <div>
        <div className="cheers-team-micro-view">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_SUCCESS,
              ACCESSIBILITY_IDENTIFIERS.CHEERS
            )}
            className="cheers-success-icon"
          />
        </div>
        <div className="text-center mt-3 cheers-register-message-text">Points Donated</div>
        <div className="text-center mt-3 cheers-register-message-text">{`${amount} Points have been donated`}</div>
        <div className="text-center mt-3 cheers-register-message-text">Available Points</div>
        <div className="text-center mt-3 cheers-register-message-text">
          {currentBalance - amount}
        </div>
        <CustomButton buttonStyle="cheers-button-style mt-3" onClick={onPressDonateAgain}>
          Donate Again
        </CustomButton>
        <CustomButton buttonStyle="cheers-button-style mt-3" onClick={onPressCheersHome}>
          Cheers Home
        </CustomButton>
      </div>
    );
  };

  const onEmployeeSelected = (selectedEmployee) => {
    setShowEmployeeView(false);
    setSelectedEmployee(selectedEmployee);
    let obj = {
      name: selectedEmployee.first_name + ' ' + selectedEmployee.last_name,
      baseUrl: selectedEmployee.profile_picture.base_url,
      imagePath: selectedEmployee.profile_picture.image_path,
      positionTitle: selectedEmployee.position_title,
      empId: selectedEmployee.employee_id,
    };
    setEmpDetail(obj);
    setText('1 Recipient has been selected');
    setIsSecondViewVisible(true);
    setButtonIsDisable(false);
    setAmount(100);
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CHEERS} />
      <BackButton />
      <div className="main-content-container">
        <Modal show={showSuccessDialog} aria-labelledby="contained-modal-title-vcenter" centered>
          <Modal.Body>{successDialog()}</Modal.Body>
        </Modal>
        <div className="cheers-donate-points-upper-view">
          <div className="ml-3 cheers-available-donate">Available points to donate:</div>
          <div className="ml-1 cheers-donate-balance">{currentBalance}</div>
        </div>
        <div className="mt-3 cheers-select-option-text">Select an option to donate?</div>
        {isSelectedOption ? (
          <CustomButton buttonStyle="cheers-selected-button-style ml-0 mt-3">
            Donate to peer
          </CustomButton>
        ) : (
          <CustomButton
            buttonStyle="cheers-not-selected-button-style ml-0 mt-3"
            onClick={() => onPressButton()}>
            Donate to peer
          </CustomButton>
        )}
        {showEmployeeView && (
          <AllEmployee onEmployeeSelected={onEmployeeSelected} selectedEmp={selectedEmployee} />
        )}
        {isFirstViewVisible && (
          <div
            className="cheers-date-view-style justify-content-between mt-3"
            onClick={() => {
              setShowEmployeeView(true);
            }}>
            <div className="cheers-person-selected">{text}</div>
            <img
              src={getHeaderComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_TAG_EMPLOYEE,
                ACCESSIBILITY_IDENTIFIERS.CHEERS
              )}
              className="cheers-normal-icon common-cursor-pointer"
            />
          </div>
        )}
        {Object.keys(empDetail).length > 0 && <SelectedEmployeeListItem item={empDetail} />}
        {isSecondViewVisible && (
          <div className="cheers-date-view-style">
            <div className="col-sm-6 text-center cheers-points-donated-text">
              Points to be donated:
            </div>
            <div className="col-sm-6 cheers-team-micro-view">
              {amount > 100 ? (
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_SUBTRACT_POINT,
                    ACCESSIBILITY_IDENTIFIERS.CHEERS
                  )}
                  className="cheers-normal-icon"
                  onClick={() => onPressMinus()}
                />
              ) : (
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_SUBTRACT_POINT_DISABLED,
                    ACCESSIBILITY_IDENTIFIERS.CHEERS
                  )}
                  className="cheers-normal-icon"
                />
              )}
              <input
                type="text"
                className="ml-3 mr-3"
                onChange={onTextChange}
                value={isNaN(amount) ? '' : `${amount}`}
              />
              {amount < 1000 ? (
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_ADD_POINT,
                    ACCESSIBILITY_IDENTIFIERS.CHEERS
                  )}
                  className="cheers-normal-icon"
                  onClick={() => onPressPlus()}
                />
              ) : (
                <img
                  src={getHeaderComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.CHEERS_ADD_POINT_DISABLED,
                    ACCESSIBILITY_IDENTIFIERS.CHEERS
                  )}
                  className="cheers-normal-icon"
                />
              )}
            </div>
          </div>
        )}
        <div className="mt-3 text-center cheers-point-awarded-text">
          Points can only be awarded in denominations of 100, with a minimum of 100 points
        </div>
        <CustomButton
          buttonStyle="cheers-button-style mt-2 mr-3"
          onClick={onPressDonateNow}
          disabled={buttonIsDisable}
          showLoader={progressLoadingState.isProgressLoading}>
          Donate Now
        </CustomButton>
      </div>
    </div>
  );
};

export default DonatePointsScreen;
