import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  NAVIGATION_ROUTES,
} from '../../common/constants/AppConstants';
import HtmlView from '../../common/ui/html_view/HTMLView';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { selectMicrolandValuesContent } from '../../state/MasterData';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
const MicrolandValuesMainScreen = () => {
  const mlValuesContent = useSelector(selectMicrolandValuesContent);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.MICROLAND_VALUES, NAVIGATION_ROUTES.MICROLAND_VALUES);
  }, []);

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.MICROLAND_VALUES} />
      <div>
        {!!mlValuesContent && (
          <HtmlView html={mlValuesContent} htmlStyle="common-comment-item-message-text my-2" />
        )}
      </div>
    </div>
  );
};

export default MicrolandValuesMainScreen;
