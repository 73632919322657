import React from 'react';

const MentionSuggestion = ({ mention, theme, searchValue, isFocused, ...parentProps }) => {
  return (
    <div {...parentProps}>
      <div className={theme?.mentionSuggestionsEntryContainer}>
        <div className={theme?.mentionSuggestionsEntryContainerLeft}>
          <img
            src={mention.avatar}
            className={theme?.mentionSuggestionsEntryAvatar}
            role="presentation"
            alt="img"
          />
        </div>

        <div className={theme?.mentionSuggestionsEntryContainerRight}>
          <div className={theme?.mentionSuggestionsEntryText}>{mention?.name}</div>
          <div className={theme?.mentionSuggestionsEntryTitle}>{mention?.title}</div>
        </div>
      </div>
    </div>
  );
};

export default MentionSuggestion;
