import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { TwitterIcon } from 'react-share';
import { LinkedinIcon, FacebookIcon, EmailIcon } from 'react-share';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
} from '../../../common/constants/AppConstants';
import { shareImageBase64 } from '../../../utils/common';
import { logEvent2 } from '../../../utils/FirebaseAnalyticsUtils';
import { saveProgressLoadingState } from '../../../state/UIState';
import html2canvas from 'html2canvas';

const WallpapersListItem = ({
  item,
  index,
  onPress,
  shareImageUrlOverEmail,
  wallpaperTextColor = '#fff',
}) => {
  const employeeData = useSelector(selectEmployeeData);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [isPortraitMode, setIsPortraitMode] = useState(true);
  const dispatch = useDispatch();

  const shareWallpaper = (platform, e) => {
    logEvent2({
      eventType: ANALYTICS_EVENT_TYPES.WALLPAPERS_SHARE,
      itemId: '',
      itemName: '',
      categoryId: ANALYTICS_ITEM_NAMES.ANNIVERSARY,
      categoryName: ANALYTICS_ITEM_NAMES.ANNIVERSARY,
    });
    downloadOrShareWallpaper(e, 'share', platform);
  };

  const downloadImage = async (e) => {
    logEvent2({
      eventType: ANALYTICS_EVENT_TYPES.WALLPAPERS_DOWNLOAD,
      itemId: '',
      itemName: '',
      categoryId: ANALYTICS_ITEM_NAMES.ANNIVERSARY,
      categoryName: ANALYTICS_ITEM_NAMES.ANNIVERSARY,
    });
    // saveAs(item, leafname);
    downloadOrShareWallpaper(e, 'download');
  };

  const downloadOrShareWallpaper = async (e, type, platform) => {
    var leafname = item.split('\\').pop().split('/').pop();
    e.preventDefault();
    document.getElementById(`wallpaper-download-${index}`).classList.add('d-block');
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    setTimeout(() => {
      html2canvas(document.getElementById(`wallpaper-download-${index}`), {
        useCORS: true,
        onclone: function (clonedDoc) {
          clonedDoc.getElementById(`wallpaper-download-${index}`).classList.add('d-block');
        },
      })
        .then(async (canvas) => {
          document.getElementById(`wallpaper-download-${index}`).classList.remove('d-block');

          let xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = function () {
            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(xhr.response);
            a.download = leafname;
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            a.remove();
          };
          if (type === 'download') {
            xhr.open('GET', canvas.toDataURL('image/jpeg'));
          } else if (platform === 'email') {
            shareImageUrlOverEmail(canvas.toDataURL('image/jpeg'));
          } else {
            await shareImageBase64(platform, canvas.toDataURL('image/jpeg'));
          }
          xhr.send();
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        })
        .catch(() => {
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        });
    }, 1000);
  };

  const getMobileNumber = () => {
    if (
      !!employeeData.emergency_contact &&
      !!employeeData.emergency_contact?.phone?.isd_code &&
      !!employeeData.emergency_contact?.phone?.number
    ) {
      return `+${
        employeeData.emergency_contact.phone.isd_code
      } ${employeeData.emergency_contact?.phone?.number?.slice(
        0,
        5
      )} ${employeeData.emergency_contact?.phone?.number?.slice(
        5,
        employeeData.emergency_contact?.phone?.number.length
      )}`;
    } else if (Object.keys(employeeData?.mobile_number).length > 0) {
      return `+${employeeData.mobile_number?.isd_code} ${employeeData.mobile_number?.number?.slice(
        0,
        5
      )} ${employeeData.mobile_number?.number?.slice(
        5,
        employeeData.mobile_number?.number.length
      )}`;
    } else {
      return '';
    }
  };

  return (
    <div
      onClick={() => onPress(item, index)}
      className="anniversary-wallpaper-item col-sm-4 mb-3 common-cursor-pointer">
      <div
        id={`wallpaper-download-${index}`}
        className="d-flex justify-content-center hide-certificate">
        <img src={item} className="wallpaper-image-screenshot" />
        <div className="anniversary-info-view ml-2 mr-2">
          <div>
            <div
              style={{ color: wallpaperTextColor }}
              className="text-center">{`${employeeData.first_name} ${employeeData.last_name}`}</div>
            <div style={{ color: wallpaperTextColor }} className="text-center pb-1 pt-1">
              {employeeData.email}
            </div>
            <div style={{ color: wallpaperTextColor }} className="text-center">
              Emergency contact
            </div>
            <div style={{ color: wallpaperTextColor }} className="text-center">
              {getMobileNumber()}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <img src={item} className="embed-responsive embed-responsive-16by9" />
        <div className="anniversary-info-view ml-2 mr-2">
          <div>
            <div
              style={{ color: wallpaperTextColor }}
              className="text-center">{`${employeeData.first_name} ${employeeData.last_name}`}</div>
            <div style={{ color: wallpaperTextColor }} className="text-center pb-1 pt-1">
              {employeeData.email}
            </div>
            <div style={{ color: wallpaperTextColor }} className="text-center">
              Emergency contact
            </div>
            <div style={{ color: wallpaperTextColor }} className="text-center">
              {getMobileNumber()}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          isPortraitMode === true
            ? 'ecards-social-icons mb-2'
            : 'ecards-social-icons-landscape mr-1 mb-1 align-items-center w-100 d-flex justify-content-end flex-row position-absolute'
        }`}>
        <LinkedinIcon
          size={32}
          round={true}
          onClick={(e) => {
            e.stopPropagation();
            shareWallpaper('Linkedin', e);
          }}
          className={`${isPortraitMode === true ? 'mb-1' : 'mr-1'}`}
        />
        <FacebookIcon
          size={32}
          round={true}
          onClick={(e) => {
            e.stopPropagation();
            shareWallpaper('Facebook', e);
          }}
          className={`${isPortraitMode === true ? 'mb-1' : 'mr-1'}`}
        />
        <TwitterIcon
          size={32}
          round={true}
          onClick={(e) => {
            e.stopPropagation();
            shareWallpaper('Twitter', e);
          }}
          className={`${isPortraitMode === true ? 'mb-1' : 'mr-1'}`}
        />
        <EmailIcon
          size={32}
          round={true}
          onClick={(e) => {
            e.stopPropagation();
            shareWallpaper('email', e);
          }}
          className={`${isPortraitMode === true ? 'mb-1' : 'mr-1'}`}
        />
        <div
          onClick={async (e) => {
            e.stopPropagation();
            downloadImage(e);
          }}>
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.ECARDS_DOWNLOAD_CARD,
              ACCESSIBILITY_IDENTIFIERS.ECARDS
            )}
            className="ecards-download-icon"
          />
        </div>
      </div>
    </div>
  );
};

export default WallpapersListItem;
