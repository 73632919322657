import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  BLOOD_GROUPS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  SUCCESS_MESSAGES,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import { formatDateDoMMM, showToast } from '../../../utils/common';
import {
  selectGenderList,
  selectMultilineTextSize,
  selectSingleLineTextSize,
} from '../../../state/MasterData';
import CustomTextInput from '../../../common/ui/custom_text_input/CustomTextInput';
import UploadProfilePhotoModal from '../components/UploadProfilePhotoModal';

const MyProfileDetailEditTab = ({ data, formikProps, callUploadProfilePictureApi }) => {
  const maxMultiLineCharLimit = useSelector(selectMultilineTextSize);
  const maxSingleLineCharLimit = useSelector(selectSingleLineTextSize);
  const [showUploadPhotoModal, setShowUploadPhotoModal] = useState(false);
  const accessibilityData = useSelector(selectAccessibilityData);
  const todayDate = new Date().toJSON().slice(0, 10);
  const genderListData = useSelector(selectGenderList);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.MY_PROFILE_DETAILS_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.MY_PROFILE
    );
  }, []);

  useEffect(() => {
    if (Object.keys(genderListData).length) {
      let genderListFormatted = [];
      let genderListMapperFormatted = {};

      for (let item in genderListData) {
        genderListFormatted.push(genderListData[item]);
        genderListMapperFormatted[genderListData[item]] = item;
      }
    }
  }, [genderListData]);

  const onPersonalPhoneCountryCodePress = (country) => {
    formikProps.setFieldValue('mobile_region_code', country.countryCode);
    formikProps.setFieldValue('mobile_isd_code', country.dialCode);
  };

  const onPressChangePhotoHandler = () => {
    setShowUploadPhotoModal(true);
  };

  const pronounPopover = (
    <Popover>
      <Popover.Content>
        <div className="common-cursor-pointer">
          <p className="mb-0 common-ws-pre-line">
            This is an initiative that is taken to ensure that we are inclusive in our actions and
            is an attempt to respect every individual’s identity. This is your platform to express
            how you would like to be addressed by others.{'\n\n'}Examples : He/Him, She/Her,
            They/Them or any other pronouns that you would like Microlanders to use while addressing
            you.{'\n\n'}Please note that this is voluntary.
          </p>
        </div>
      </Popover.Content>
    </Popover>
  );

  return (
    <div className="common-container-md mt-3 mb-3">
      <UploadProfilePhotoModal
        callUploadProfilePictureApi={callUploadProfilePictureApi}
        showModal={showUploadPhotoModal}
        setShowModal={setShowUploadPhotoModal}
      />
      <div>
        <div className="my-profile-edit-background-container">
          <div className="my-profile-edit-image-container d-flex flex-column justify-content-center align-items-center">
            <div className="position-relative">
              <img
                className="common-user-profile-photo-xxl"
                src={
                  data.profile_picture.image_path
                    ? data.profile_picture.base_url + data.profile_picture.image_path
                    : getFeatureComponentUrl(
                        accessibilityData,
                        ACCESSIBILITY_FEATURE_COMPONENTS.PROFILE_DEFAULT_PICTURE,
                        ACCESSIBILITY_IDENTIFIERS.PROFILE
                      )
                }
              />
              <div
                className="position-absolute common-profile-edit-camera-container common-cursor-pointer"
                onClick={onPressChangePhotoHandler}>
                <img
                  className="common-profile-edit-camera-size"
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_CIRCULAR_CAMERA_RED,
                    ACCESSIBILITY_IDENTIFIERS.COMMON
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {Object.keys(formikProps.values).length > 0 && (
        <div>
          <div>
            <div>
              <div>
                <label>{'Preferred Pronoun'}</label>
                <OverlayTrigger
                  rootClose
                  trigger="click"
                  placement="right"
                  overlay={pronounPopover}>
                  <img
                    src={getFeatureComponentUrl(
                      accessibilityData,
                      ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_INFO,
                      ACCESSIBILITY_IDENTIFIERS.COMMON
                    )}
                    className="common-user-profile-info-icon common-cursor-pointer ml-2"
                  />
                </OverlayTrigger>
              </div>
              <CustomTextInput
                name="pronouns"
                isTitle={false}
                maxLength={12}
                value={formikProps.values.pronouns}
                onBlur={formikProps.handleBlur}
                onChange={formikProps.handleChange}
                style="my-profile-edit-editable-text-input"
                isValid={
                  !!formikProps.submitCount &&
                  formikProps.touched.pronouns &&
                  !formikProps.errors.pronouns
                }
                isInvalid={
                  !!formikProps.submitCount &&
                  formikProps.touched.pronouns &&
                  !!formikProps.errors.pronouns
                }
                placeholder="Eg. He/Him, She/Her, They/Them"
              />
            </div>
            <CustomTextInput
              name="description"
              title="About Me"
              errorMessage={formikProps.errors.description}
              value={formikProps.values.description}
              as="textarea"
              maxLength={maxMultiLineCharLimit}
              onBlur={formikProps.handleBlur}
              onChange={formikProps.handleChange}
              style="my-profile-edit-editable-text-area"
              isValid={
                !!formikProps.submitCount &&
                formikProps.touched.description &&
                !formikProps.errors.description
              }
              isInvalid={
                !!formikProps.submitCount &&
                formikProps.touched.description &&
                !!formikProps.errors.description
              }
              placeholder="E.g. I’m a software engineer with five years of experience in automation. I have built and deployed 12+ applications for the top 500 fortune companies."
            />
            <div>
              <label>
                Phone<span className="my-profile-required"> (required)</span>
              </label>
              <div className="row">
                <div className="col-11">
                  <div className="row">
                    <div className="my-profile-editable-phone-input-container my-profile-editable-phone-code pr-0 pl-3">
                      <PhoneInput
                        enableSearch={true}
                        countryCodeEditable={false}
                        country={formikProps.values.mobile_region_code}
                        // disableDropdown={true}
                        // disabled={true}
                        value={formikProps.values.mobile_isd_code}
                        onChange={(dialCode, country) => {
                          onPersonalPhoneCountryCodePress(country);
                        }}
                      />
                    </div>
                    <div className="my-profile-editable-phone-text-container mb-0 pl-3">
                      <CustomTextInput
                        required
                        name="mobile_number"
                        isTitle={false}
                        // disabled={true}
                        errorMessage={formikProps.errors.mobile_number}
                        value={formikProps.values.mobile_number}
                        type="tel"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        style="my-profile-edit-editable-text-input"
                        isValid={
                          !!formikProps.submitCount &&
                          formikProps.touched.mobile_number &&
                          !formikProps.errors.mobile_number
                        }
                        isInvalid={
                          !!formikProps.submitCount &&
                          formikProps.touched.mobile_number &&
                          !!formikProps.errors.mobile_number
                        }
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="col-1 d-flex align-items-center justify-content-end my-profile-edit-eye-icon-container"
                  onClick={() => {
                    if (formikProps.values.mobile_number_visible) {
                      showToast(SUCCESS_MESSAGES.PHONE_NUMBER_HIDDEN_SUCCESS);
                    } else {
                      showToast(SUCCESS_MESSAGES.PHONE_NUMBER_VISIBLE_SUCCESS);
                    }
                    const isPhoneVisible = formikProps.values.mobile_number_visible ? 0 : 1;
                    formikProps.setFieldValue('mobile_number_visible', isPhoneVisible);
                  }}>
                  <img
                    className="common-user-profile-visible-icon"
                    src={getFeatureComponentUrl(
                      accessibilityData,
                      formikProps.values.mobile_number_visible
                        ? ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_OPEN_EYE_RED
                        : ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_CLOSE_EYE_RED,
                      ACCESSIBILITY_IDENTIFIERS.COMMON
                    )}
                  />
                </div>
              </div>
            </div>
            <CustomTextInput
              name="display_name"
              title="Nick Name"
              maxLength={maxSingleLineCharLimit}
              value={formikProps.values.display_name}
              onBlur={formikProps.handleBlur}
              onChange={formikProps.handleChange}
              style="my-profile-edit-editable-text-input"
              isValid={
                !!formikProps.submitCount &&
                formikProps.touched.display_name &&
                !formikProps.errors.display_name
              }
              isInvalid={
                !!formikProps.submitCount &&
                formikProps.touched.display_name &&
                !!formikProps.errors.display_name
              }
            />
            <div className="row d-flex flex-direction-row justify-content-center align-items-center">
              <div className="col-11">
                <CustomTextInput
                  name="date_of_birth"
                  title="Birthday"
                  readOnly={true}
                  disabled={true}
                  max={todayDate}
                  value={
                    formikProps.values.date_of_birth
                      ? formatDateDoMMM(formikProps.values.date_of_birth)
                      : ''
                  }
                  onChange={formikProps.handleChange}
                  style="my-profile-edit-non-editable-text-input"
                  isValid={
                    !!formikProps.submitCount &&
                    formikProps.touched.date_of_birth &&
                    !formikProps.errors.date_of_birth
                  }
                />
              </div>
              <div
                className="col-1 d-flex align-items-end justify-content-end mt-3"
                onClick={() => {
                  if (formikProps.values.birth_day_visible) {
                    showToast(SUCCESS_MESSAGES.BIRTHDAY_HIDDEN_SUCCESS);
                  } else {
                    showToast(SUCCESS_MESSAGES.BIRTHDAY_VISIBLE_SUCCESS);
                  }
                  const isBirthdayVisible = formikProps.values.birth_day_visible ? 0 : 1;
                  formikProps.setFieldValue('birth_day_visible', isBirthdayVisible);
                }}>
                <img
                  className="common-user-profile-visible-icon"
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    formikProps.values.birth_day_visible
                      ? ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_OPEN_EYE_RED
                      : ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_CLOSE_EYE_RED,
                    ACCESSIBILITY_IDENTIFIERS.COMMON
                  )}
                />
              </div>
            </div>
            <div className="row d-flex align-items-center">
              <div className="col-11">
                <CustomTextInput
                  name="blood_group"
                  title="Blood Group"
                  as="select"
                  value={formikProps.values.blood_group}
                  onChange={formikProps.handleChange}
                  style="my-profile-edit-editable-text-input"
                  isValid={
                    !!formikProps.submitCount &&
                    formikProps.touched.blood_group &&
                    !formikProps.errors.blood_group
                  }>
                  {BLOOD_GROUPS.map((bloodGroup, index) => (
                    <option value={bloodGroup} key={index}>
                      {bloodGroup}
                    </option>
                  ))}
                </CustomTextInput>
              </div>
              <div
                className="col-1 d-flex align-items-end justify-content-end mt-3"
                onClick={() => {
                  if (formikProps.values.blood_group_visible) {
                    showToast(SUCCESS_MESSAGES.BLOOD_GROUP_HIDDEN_SUCCESS);
                  } else {
                    showToast(SUCCESS_MESSAGES.BLOOD_GROUP_VISIBLE_SUCCESS);
                  }
                  const bloodGroupVisible = formikProps.values.blood_group_visible ? 0 : 1;
                  formikProps.setFieldValue('blood_group_visible', bloodGroupVisible);
                }}>
                <img
                  className="common-user-profile-visible-icon"
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    formikProps.values.blood_group_visible
                      ? ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_OPEN_EYE_RED
                      : ACCESSIBILITY_FEATURE_COMPONENTS.COMMON_CLOSE_EYE_RED,
                    ACCESSIBILITY_IDENTIFIERS.COMMON
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(MyProfileDetailEditTab);
