import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  MESSAGES,
} from '../../../common/constants/AppConstants';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import WishesListItem from '../components/WishesListItem';
import { Modal, Form } from 'react-bootstrap';
import { getHeaderComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';

const MyWishesTab = (props) => {
  const getTabData = props.getTabData;
  const [showYearDialog, setShowYearDialog] = useState(false);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [selectedYear, setSelectedYear] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [filterYears, setFilterYears] = useState([]);
  const [yearOccurances, setYearOccurances] = useState([]);

  useEffect(() => {
    if (!props.data.length) {
      getTabData();
    }
  }, [getTabData, props.data.length]);

  useEffect(() => {
    if (props.data) {
      setFilteredData(props.data);
    }
    if (props.filterYears) {
      setFilterYears(props.filterYears);
      let tempArray = [];
      let tempData = [];
      props.data.map((wish, index) => {
        if (!tempArray.includes(wish.year)) {
          tempData.push(index);
          tempArray.push(wish.year);
        }
        if (index == props.data.length - 1) {
          setYearOccurances(tempData);
        }
      });
    }
  }, [props.data]);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.MY_PROFILE_WISHES_TAB_VISIT,
      '',
      ANALYTICS_ITEM_NAMES.MY_PROFILE
    );
  }, []);

  const emptyListView = () => {
    return <DefaultContentView message={MESSAGES.NO_WISHES} />;
  };

  const renderItem = (item, index, showYear) => {
    return <WishesListItem showYear={showYear} item={item} key={`${index}`} />;
  };

  const clearFilters = () => {
    if (props.data) {
      setFilteredData(props.data);
    }
  };

  const customYearView = () => {
    return (
      <div>
        <Form>
          <Form.Group controlId="exampleForm.SelectCustom">
            <Form.Label>Select Year</Form.Label>
            <div className="mt-2 mb-2">
              <Form.Control
                as="select"
                custom
                value={selectedYear}
                onChange={(event) => {
                  setSelectedYear(event.target.value ?? '');
                }}>
                {filterYears.length > 0 &&
                  filterYears.map((item) => {
                    return (
                      <option key={item} defaultValue={item === selectedYear}>
                        {item}
                      </option>
                    );
                  })}
              </Form.Control>
            </div>
          </Form.Group>
        </Form>
        <div className="d-flex justify-content-between">
          <div>
            <CustomButton
              buttonStyle="broadcast-calender-button-style"
              onClick={() => {
                setShowYearDialog(false);
                clearFilters();
              }}>
              CLEAR FILTERS
            </CustomButton>
          </div>
          <div className="d-flex align-items-end justify-content-end">
            <CustomButton
              buttonStyle="anniversary-calender-button-style"
              onClick={() => {
                setShowYearDialog(false);
              }}>
              CANCEL
            </CustomButton>
            <CustomButton
              buttonStyle="anniversary-calender-button-style"
              onClick={async () => {
                setFilteredData(props.data.filter((item) => item.year == selectedYear));
                setShowYearDialog(false);
              }}>
              DONE
            </CustomButton>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Modal
        show={showYearDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowYearDialog(false)}>
        <Modal.Body>{customYearView()}</Modal.Body>
      </Modal>
      <div className="d-flex flex-row-reverse mi-hr-activity-btn-container">
        <img
          src={getHeaderComponentUrl(accessibilityData, ACCESSIBILITY_HEADER_COMPONENTS.CALENDER)}
          className="header-icon mt-1"
          onClick={() => {
            if (selectedYear == '' && filterYears.length > 0) setSelectedYear(filterYears[0]);
            setShowYearDialog(true);
          }}
        />
      </div>
      <ActivityLoader visible={props.isLoading} />
      <div className="py-3 mx-auto common-container-md">
        {!filteredData.length && props?.noMatchFlag ? (
          emptyListView()
        ) : (
          <div>
            {filteredData &&
              filteredData.map((item, index) =>
                renderItem(item, index, yearOccurances.includes(index))
              )}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(MyWishesTab);
