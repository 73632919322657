import React from 'react';
import { useSelector } from 'react-redux';

import { uId } from '../../../utils/ChatHandler';
import { selectEmployeeData } from '../../../state/EmployeeData';
import IncomingChatCell from '../chatviews/IncomingChatCell';
import OutgoingChatCell from '../chatviews/OutgoingChatCell';
import NotificationCell from '../chatviews/NotificationCell';

const ChatMessageListItem = ({ item, chatType, messageMoreOptionsPopover }) => {
  const employeeData = useSelector(selectEmployeeData);

  if (item.type === '2') {
    return <NotificationCell item={item} />;
  } else if (item.sender_id === uId(employeeData)) {
    return (
      <OutgoingChatCell
        item={item}
        message={item.message}
        messageMoreOptionsPopover={messageMoreOptionsPopover}
        isDeleted={item.is_deleted}
      />
    );
  } else if (item.sender_id !== uId(employeeData)) {
    return (
      <IncomingChatCell
        item={item}
        chatType={chatType}
        message={item.message}
        messageMoreOptionsPopover={messageMoreOptionsPopover}
        isDeleted={item.is_deleted}
      />
    );
  } else {
    return null;
  }
};

export default React.memo(ChatMessageListItem);
