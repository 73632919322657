import React from 'react';
import { useHistory } from 'react-router-dom';
import { IoArrowBackCircleOutline } from 'react-icons/io5';

import colors from '../../themes/Colors';
import { NAVIGATION_ROUTES } from '../../constants/AppConstants';

const BackButton = (props) => {
  const history = useHistory();
  const {
    fromRoute = '',
    backTitle = 'BACK',
    isFullRow = true,
    containerStyle = '',
    navigateBackFuncHandler = () => {
      const path = history.location;
      if (fromRoute === NAVIGATION_ROUTES.ASK_ORG_SEARCH_RESULT) {
        history.push(NAVIGATION_ROUTES.ASK_ORG, {
          preserve: true,
        });
      } else if (fromRoute === NAVIGATION_ROUTES.MI_AUCTION_TC) {
        history.replace(`${NAVIGATION_ROUTES.MI_AUCTION}/${props.itemId}`, {
          fromRoute: NAVIGATION_ROUTES.MI_AUCTION,
        });
      } else {
        history.goBack();
      }
      setTimeout(() => {
        if (path == history.location) {
          history.replace(NAVIGATION_ROUTES.HOME);
        }
      }, 200);
    },
  } = props;

  return (
    <div className={`${isFullRow ? 'common-comment-header-view' : ''} ${containerStyle}`}>
      <div
        className="d-flex align-items-center common-cursor-pointer"
        onClick={navigateBackFuncHandler}>
        <IoArrowBackCircleOutline
          size="2.25em"
          className="mr-2"
          color={colors.silverChaliceLight}
        />
        <span className="common-back-button-title">{backTitle}</span>
      </div>
    </div>
  );
};

export default BackButton;
