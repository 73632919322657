import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../../services/Service';
import { GET_ESG_CHAMPIONS } from '../../../services/ApiUrls';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../state/UIState';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { useHistory } from 'react-router-dom';
import ChampionListItem from '../components/ChampionListItem';

const EsgChampionsTab = (props) => {
  const [employees, setEmployees] = useState([]);
  const loadMoreFlag = useRef(true);
  const pageId = useRef(1);
  const history = useHistory();
  const dispatch = useDispatch();
  const employeeData = useSelector(selectEmployeeData);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.ESG_TAB_VISIT, props?.item?.title, ANALYTICS_ITEM_NAMES.ESG);
  }, [props?.item?.title]);

  const callMembersData = useCallback(async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
      })
    );
    let params = { page_id: pageId.current };
    try {
      const response = await apiRequest(GET_ESG_CHAMPIONS, REQUEST_TYPE.GET, params);
      if (Object.keys(response).length > 0) {
        if (response.response.status === true) {
          if (response.response.data.feeds) {
            const data = response.response.data.feeds;
            if (data.length > 0) {
              if (pageId.current === 1) {
                setEmployees(data);
              } else {
                setEmployees((prevEmployees) => [...prevEmployees, ...data]);
              }
              loadMoreFlag.current = true;
            } else {
              loadMoreFlag.current = false;
            }
          } else {
            loadMoreFlag.current = false;
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(err, params, GET_ESG_CHAMPIONS, NAVIGATION_ROUTES.ESG);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    callMembersData();
  }, [callMembersData]);

  const onPressLoadMore = () => {
    if (loadMoreFlag.current) {
      pageId.current = pageId.current + 1;
      callMembersData();
    }
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.ESG_EMPTY} />;
  };

  const onEmployeeClick = (e, employeeId) => {
    e.stopPropagation();
    if (employeeId) {
      if (employeeId === employeeData.employee_id) {
        history.push(NAVIGATION_ROUTES.MY_PROFILE_DETAIL);
      } else {
        history.push(`${NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL}/${employeeId}`);
      }
    }
  };

  return (
    <div>
      {!employees.length && !progressLoadingState.isProgressLoading && emptyListView()}
      <div className="row mb-3">
        {employees.length > 0 &&
          employees.map((item, index) => {
            return (
              <ChampionListItem
                key={`${index}`}
                item={item}
                onPress={(e) => onEmployeeClick(e, item.employee_id)}
              />
            );
          })}
      </div>
      {loadMoreFlag.current && employees.length > 0 && <LoadMoreBtn onClick={onPressLoadMore} />}
    </div>
  );
};

export default EsgChampionsTab;
