import React from 'react';
import { useSelector } from 'react-redux';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { formatDateDDMonthYYYY, formatTimeAMPM, isToday } from '../../../utils/common';

const DiscussionTabItem = ({ item }) => {
  const employeeData = useSelector(selectEmployeeData);

  const getUserProfilePic = () => {
    return (
      <UserProfilePhoto
        imageBaseUrl={item?.employee?.profile_picture?.base_url}
        imagePath={item?.employee?.profile_picture?.image_path}
        imageClass="common-user-profile-photo-xs microgive-profile-image-xs"
        employeeId={item?.employee?.employee_id}
      />
    );
  };
  return (
    <div>
      {`${item.employee.employee_id}` === `${employeeData.employee_id}` ? (
        <div className="microgive-self-view mb-3 align-items-center">
          <div className="microgive-item-image">
            <div className="row align-items-center justify-content-end">
              <div className="mr-3 microgive-discussion-date-text">
                {isToday(item.created_on)
                  ? formatTimeAMPM(item.created_on)
                  : formatDateDDMonthYYYY(item.created_on)}
              </div>
              <div className="mr-4 microgive-discussion-emp-name">You</div>
            </div>
            <div className="microgive-self-item-bubble">
              <HtmlView html={item.description} />
            </div>
          </div>
          {getUserProfilePic()}
        </div>
      ) : (
        <div className="microgive-other-view mt-2 mb-2 align-items-center">
          {getUserProfilePic()}
          <div className="ml-2">
            <div className="row justify-content-start align-items-center">
              <div className="d-flex align-items-center ml-4">
                <p className="microgive-discussion-emp-name mb-0">
                  {`${item.employee.first_name} ${item.employee.last_name}`}
                </p>
                {/* {!!item.employee.pronouns && (
                  <p className="microgive-discussion-emp-pronoun ml-1 mb-0">
                    {'(' + item.employee.pronouns + ')'}
                  </p>
                )} */}
              </div>
              <div className="mr-3 microgive-discussion-date-text">
                {formatDateDDMonthYYYY(item.created_on)}
              </div>
            </div>
            <div className="microgive-other-item">
              <HtmlView html={item.description} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DiscussionTabItem;
