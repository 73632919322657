import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab } from 'react-bootstrap';

import {
  ACCESSIBILITY_IDENTIFIERS,
  NAVIGATION_ROUTES,
  PODCAST_TAB_IDENTIFIERS,
  REQUEST_TYPE,
} from '../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../services/Service';
import { PODCAST_CHANNELS_LIST } from '../../services/ApiUrls';
import { savePodcastTabData, selectPodcastTabData } from '../../state/TabData';
import { selectPodcastTabs } from '../../state/MasterData';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import PodcastAboutTab from './tabs/PodcastAboutTab';
import PodcastChannelsTab from './tabs/PodcastChannelsTab';
import NavTabs from '../../common/ui/tabs/NavTabs';
import SearchComponent from '../../common/ui/search/SearchComponent';
import { saveSearchText } from '../../state/UIState';

const PodcastScreen = ({ location, history }) => {
  const dispatch = useDispatch();
  const podcastTabTitles = useSelector(selectPodcastTabs);
  const podcastTabData = useSelector(selectPodcastTabData);
  const [isLoading, setIsLoading] = useState(false);

  const [podcastData, setPodcastData] = useState({});

  let tempPageIds = {};
  let tempLoadMoreFlags = {};
  podcastTabTitles.map((tab) => {
    tempPageIds[tab.key] = 1;
    tempLoadMoreFlags[tab.key] = true;
  });
  const pageIdRef = useRef(tempPageIds);
  const loadMoreFlagRef = useRef(tempLoadMoreFlags);

  useEffect(() => {
    if (podcastTabTitles.length && podcastTabData === '') {
      let activeTab = location?.state?.tabKey
        ? podcastTabTitles.findIndex((tab) => tab.key === location?.state?.tabKey)
        : 0;
      dispatch(savePodcastTabData(podcastTabTitles[activeTab].key));
    }
  }, [dispatch, podcastTabData, podcastTabTitles]);

  const resetPaginationForTab = (tabKey) => {
    pageIdRef.current[tabKey] = 1;
    loadMoreFlagRef.current[tabKey] = true;
  };

  const callPodcastApi = useCallback(async (tabType, pageId) => {
    setIsLoading(true);
    try {
      let data = [];
      let params = { page_id: pageId };
      if (tabType === PODCAST_TAB_IDENTIFIERS.CHANNELS) {
        try {
          const apiResponse = await apiRequest(PODCAST_CHANNELS_LIST, REQUEST_TYPE.GET, params);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status) {
              data = apiResponse.response.data.feeds;
            }
          }
        } catch (err) {
          handleError(err, params, PODCAST_CHANNELS_LIST, NAVIGATION_ROUTES.PODCASTS);
        }
      }
      setIsLoading(false);
      if (!data.length) {
        loadMoreFlagRef.current[tabType] = false;
      }
      if (pageId === 1) {
        resetPaginationForTab(tabType);
        setPodcastData((tabData) => {
          return { ...tabData, ...{ [tabType]: data } };
        });
      } else {
        setPodcastData((tabData) => {
          return { ...tabData, ...{ [tabType]: [...tabData[tabType], ...data] } };
        });
      }
    } catch (err) {
      setIsLoading(false);
    }
  }, []);

  const renderScreen = useCallback(
    async (tabType, pageId) => {
      callPodcastApi(tabType, pageId);
    },
    [callPodcastApi]
  );

  const paginationHandler = (tab) => {
    if (loadMoreFlagRef.current[tab]) {
      pageIdRef.current[tab] += 1;
      renderScreen(tab, pageIdRef.current[tab]);
    }
  };

  const renderTab = (tab) => {
    switch (tab.key) {
      case PODCAST_TAB_IDENTIFIERS.ABOUT:
        return <PodcastAboutTab />;
      case PODCAST_TAB_IDENTIFIERS.CHANNELS:
        return (
          <PodcastChannelsTab
            data={podcastData[PODCAST_TAB_IDENTIFIERS.CHANNELS]}
            getTabData={renderScreen}
            isLoading={isLoading}
            tabKey={PODCAST_TAB_IDENTIFIERS.CHANNELS}
            loadMoreFlag={loadMoreFlagRef.current[PODCAST_TAB_IDENTIFIERS.CHANNELS]}
            paginationHandler={paginationHandler}
          />
        );
      default:
        return null;
    }
  };

  const handleSubmitFunction = (searchText) => {
    dispatch(saveSearchText(searchText));
    history.push(NAVIGATION_ROUTES.PODCASTS_SEARCH, { searchQuery: searchText });
  };

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right">
        <SearchComponent handleSubmitFunction={handleSubmitFunction} />
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.PODCASTS} />
      <NavTabs
        activeKey={podcastTabData}
        onSelect={(k) => dispatch(savePodcastTabData(k))}
        unmountOnExit>
        {podcastTabTitles.map((tab, index) => {
          return (
            <Tab key={`${index}`} eventKey={tab.key} title={tab.title}>
              {renderTab(tab, index)}
            </Tab>
          );
        })}
      </NavTabs>
    </div>
  );
};

export default PodcastScreen;
