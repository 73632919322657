import React, { useEffect } from 'react';
import HtmlView from '../../../common/ui/html_view/HTMLView';
import { trackScreen } from '../../../utils/FirebaseAnalyticsUtils';
import { ANALYTICS_SCREEN_NAMES, NAVIGATION_ROUTES } from '../../../common/constants/AppConstants';

export default function PolicyTab({ data }) {
  useEffect(() => {
    // Analytics
    trackScreen(
      ANALYTICS_SCREEN_NAMES.MI_CHILD_CARE_COST_DETAILS_TAB,
      NAVIGATION_ROUTES.MI_CHILD_CARE
    );
  }, []);

  return (
    <div>
      <HtmlView html={data?.body || 'No Data'} htmlStyle="breakout-content-html" />
    </div>
  );
}
