import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import CustomButton from '../../../common/ui/custom_button/CustomButton';

const ChatEditGroupDetailModal = (props) => {
  const {
    isDialogVisible,
    setIsDialogVisible,
    leftButtonText,
    onClickLeftButton,
    rightButtonText,
    groupTextValue,
    onClickRightButton,
  } = props;
  const [newGroupName, setNewGroupName] = useState('');

  useEffect(() => {
    setNewGroupName(groupTextValue);
  }, [groupTextValue]);

  return (
    <Modal
      onHide={() => setIsDialogVisible((val) => !val)}
      show={isDialogVisible}
      centered
      backdrop="static"
      keyboard={false}>
      <Modal.Body className="p-4">
        <div className="ijp-apply-modal-title text-center mb-4">Edit Group Name</div>
        <input
          value={newGroupName}
          autoFocus
          className="w-100 px-3 py-2 mb-4"
          type="text"
          placeholder="Enter a group name"
          onChange={(event) => setNewGroupName(event.target.value)}
        />
        <div className="d-flex justify-content-center">
          <CustomButton
            onClick={onClickLeftButton}
            buttonMainContainer="mr-4"
            buttonStyle="common-custom-button-ternary">
            {leftButtonText}
          </CustomButton>
          <CustomButton
            onClick={() => onClickRightButton(newGroupName)}
            buttonStyle="common-custom-button-quaternary">
            {rightButtonText}
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ChatEditGroupDetailModal;
