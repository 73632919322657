import React from 'react';
import { useSelector } from 'react-redux';
import UserProfilePhoto from '../../../common/ui/user_profile_photo/UserProfilePhoto';
import { selectAccessibilityData } from '../../../state/AccessibilityData';

const EmployeeOrgView = (props) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  return (
    <div
      className={`common-profile-org-emp-container d-flex flex-row align-items-center pr-3 pl-3 ${props.style}`}>
      <UserProfilePhoto
        imageBaseUrl={props.employee.profile_picture.base_url}
        imagePath={props.employee.profile_picture.image_path}
        imageClass="common-user-profile-photo-md"
        showBadge={true}
        employeeId={props.employee.employee_id}
      />
      <div className="nameContainer ml-3">
        <div className="d-flex align-items-center">
          <p className="common-profile-org-emp-name-text mb-0">
            {props.employee.first_name +
              ' ' +
              props.employee.middle_name +
              ' ' +
              props.employee.last_name}
          </p>
          {!!props.employee.pronouns && (
            <p className="common-profile-org-emp-pronoun-text mb-0 ml-1">
              {'(' + props.employee.pronouns + ')'}
            </p>
          )}
        </div>
        <div className="common-profile-org-emp-designation-text">
          {props.employee.position_title}
        </div>
      </div>
    </div>
  );
};

export default EmployeeOrgView;
