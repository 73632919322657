import { createAction, createSlice } from '@reduxjs/toolkit';
import { LOADING_MESSAGES } from '../common/constants/AppConstants';

const signOutAction = createAction('signout');

const saveOnBoardingReducer = (state, action) => {
  state.onBoarding = action.payload;
};

const saveSearchTextReducer = (state, action) => {
  state.searchText = action.payload;
};

const saveTravellerReducer = (state, action) => {
  state.traveller = action.payload;
};

const saveWallSearchTextReducer = (state, action) => {
  state.wallSearchText = action.payload;
};

const saveTermsConditionsReducer = (state, action) => {
  state.termsConditions = action.payload;
};

const saveFingerPrintActiveReducer = (state, action) => {
  state.isFingerPrintActive = action.payload;
};

const saveBiometricPermissionAskedReducer = (state, action) => {
  state.isBiometricPermissionAsked = action.payload;
};

const saveJwtTokenReducer = (state, action) => {
  state.jwtToken = action.payload;
};

const saveBaseUrlReducer = (state, action) => {
  state.baseUrl = action.payload;
};

const saveLamdaUrlReducer = (state, action) => {
  state.lamdaUrl = action.payload;
};

const saveAppStateReducer = (state, action) => {
  state.appState = action.payload;
};

const saveMicroUnivCookieStatusReducer = (state, action) => {
  state.clearMicroUnivCookie = action.payload;
};

const saveOrgDomainReducer = (state, action) => {
  state.orgDomain = action.payload;
};

const saveGenericTokenReducer = (state, action) => {
  state.genericToken = action.payload;
};

const saveTempEmailReducer = (state, action) => {
  state.tempEmail = action.payload;
};

const saveShowProfileReducer = (state, action) => {
  state.showProfile = action.payload;
};

const saveShowProfileDialogReducer = (state, action) => {
  state.showProfileDialog = action.payload;
};

const saveShowGotoHomeButtonReducer = (state, action) => {
  state.showGotoHomeButton = action.payload;
};

const toggleIsMenuFavoritesEnabledReducer = (state, action) => {
  state.isMenuFavoritesEnabled = !state.isMenuFavoritesEnabled;
};

const saveOrientationReducer = (state, action) => {
  state.orientation = action.payload;
};

const saveIsWelcomeMessageSpokenReducer = (state, action) => {
  state.isWelcomeMessageSpoken = action.payload;
};

const saveIsFullScreenLoaderLoadingReducer = (state, action) => {
  state.isFullScreenLoaderLoading = action.payload;
};

const saveProgressLoadingStateReducer = (state, action) => {
  const { isProgressLoading = false, progressMessage = LOADING_MESSAGES.DEFAULT } = action.payload;
  state.progressLoadingState = {
    isProgressLoading: isProgressLoading,
    progressMessage: progressMessage,
  };
};

const saveProfileDetailModalStateReducer = (state, action) => {
  const {
    isProfileDetailModalVisible = false,
    selectedProfile = state.profileDetailModalState.selectedProfile,
    getDataFromApi = false,
    employeeId = state.employeeId,
  } = action.payload;
  state.profileDetailModalState = {
    isProfileDetailModalVisible: isProfileDetailModalVisible,
    selectedProfile: selectedProfile,
    getDataFromApi: getDataFromApi,
    employeeId: employeeId,
  };
};

const saveShowEmptyContentViewReducer = (state, action) => {
  state.showEmptyContentView = action.payload;
};

const saveEmailReducer = (state, action) => {
  state.email = action.payload;
};

const saveIsTabLoadingReducer = (state, action) => {
  state.isTabLoading = action.payload;
};

const setShowAttendanceModalReducer = (state, action) => {
  state.showAttendanceModal = action.payload;
};

const resetLoaderStateReducer = (state, action) => {
  state.isTabLoading = false;
  state.isPullToRefresh = false;
  state.progressLoadingState = {};
};

// REDUCER
const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    onBoarding: true,
    termsConditions: true,
    isFingerPrintActive: false,
    isBiometricPermissionAsked: false,
    searchText: '',
    traveller: {},
    wallSearchText: '',
    jwtToken: '',
    baseUrl: '',
    lamdaUrl: '',
    appState: '',
    clearMicroUnivCookie: false,
    orgDomain: '',
    genericToken: '',
    tempEmail: '',
    showProfile: true,
    showProfileDialog: true,
    showGotoHomeButton: true,
    isMenuFavoritesEnabled: false,
    orientation: 'portrait',
    isWelcomeMessageSpoken: false,
    isFullScreenLoaderLoading: false,
    progressLoadingState: {},
    profileDetailModalState: {
      isProfileDetailModalVisible: false,
      selectedProfile: null,
      getDataFromApi: false,
      employeeId: null,
    },
    showEmptyContentView: false,
    email: '',
    isTabLoading: false,
    showAttendanceModal: false,
  },
  extraReducers: {
    [signOutAction]: (state) => {
      return {
        ...state,
        jwtToken: '',
      };
    },
  },
  reducers: {
    saveOnBoarding: saveOnBoardingReducer,
    saveSearchText: saveSearchTextReducer,
    saveTraveller: saveTravellerReducer,
    saveWallSearchText: saveWallSearchTextReducer,
    saveTermsConditions: saveTermsConditionsReducer,
    saveFingerPrintActive: saveFingerPrintActiveReducer,
    saveBiometricPermissionAsked: saveBiometricPermissionAskedReducer,
    saveJwtToken: saveJwtTokenReducer,
    saveBaseUrl: saveBaseUrlReducer,
    saveLamdaUrl: saveLamdaUrlReducer,
    saveAppState: saveAppStateReducer,
    saveMicroUnivCookieStatus: saveMicroUnivCookieStatusReducer,
    saveOrgDomain: saveOrgDomainReducer,
    saveGenericToken: saveGenericTokenReducer,
    saveTempEmail: saveTempEmailReducer,
    saveShowProfile: saveShowProfileReducer,
    saveShowProfileDialog: saveShowProfileDialogReducer,
    saveShowGotoHomeButton: saveShowGotoHomeButtonReducer,
    toggleIsMenuFavoritesEnabled: toggleIsMenuFavoritesEnabledReducer,
    saveOrientation: saveOrientationReducer,
    saveIsWelcomeMessageSpoken: saveIsWelcomeMessageSpokenReducer,
    saveIsFullScreenLoaderLoading: saveIsFullScreenLoaderLoadingReducer,
    saveProgressLoadingState: saveProgressLoadingStateReducer,
    saveProfileDetailModalState: saveProfileDetailModalStateReducer,
    saveShowEmptyContentView: saveShowEmptyContentViewReducer,
    saveEmail: saveEmailReducer,
    saveIsTabLoading: saveIsTabLoadingReducer,
    resetLoaderState: resetLoaderStateReducer,
    setShowAttendanceModal: setShowAttendanceModalReducer,
  },
});

// ACTIONS
const {
  saveOnBoarding,
  saveSearchText,
  saveTraveller,
  saveWallSearchText,
  saveTermsConditions,
  saveFingerPrintActive,
  saveBiometricPermissionAsked,
  saveJwtToken,
  saveBaseUrl,
  saveAppState,
  saveMicroUnivCookieStatus,
  saveOrgDomain,
  saveGenericToken,
  saveTempEmail,
  saveShowProfile,
  saveShowProfileDialog,
  saveShowGotoHomeButton,
  toggleIsMenuFavoritesEnabled,
  saveOrientation,
  saveIsWelcomeMessageSpoken,
  saveIsFullScreenLoaderLoading,
  saveProgressLoadingState,
  saveProfileDetailModalState,
  saveLamdaUrl,
  saveShowEmptyContentView,
  saveEmail,
  saveIsTabLoading,
  resetLoaderState,
  setShowAttendanceModal,
} = uiSlice.actions;

// SELECTOR
const selectOnBoarding = ({ ui }) => ui.onBoarding;
const selectSearchText = ({ ui }) => ui.searchText;
const selectTraveller = ({ ui }) => ui.traveller;
const selectWallSearchText = ({ ui }) => ui.wallSearchText;
const selectTermsConditions = ({ ui }) => ui.termsConditions;
const selectFingerPrintActive = ({ ui }) => ui.isFingerPrintActive;
const selectBiometricPermissionAsked = ({ ui }) => ui.isBiometricPermissionAsked;
const selectJwtToken = ({ ui }) => ui.jwtToken;
const selectBaseUrl = ({ ui }) => ui.baseUrl;
const selectAppState = ({ ui }) => ui.appState;
const selectMicroUnivCookieStatus = ({ ui }) => ui.clearMicroUnivCookie;
const selectOrgDomain = ({ ui }) => ui.orgDomain;
const selectGenericToken = ({ ui }) => ui.genericToken;
const selectTempEmail = ({ ui }) => ui.tempEmail;
const selectShowProfile = ({ ui }) => ui.showProfile;
const selectShowProfileDialog = ({ ui }) => ui.showProfileDialog;
const selectShowGotoHomeButton = ({ ui }) => ui.showGotoHomeButton;
const selectIsMenuFavoritesEnabled = ({ ui }) => ui.isMenuFavoritesEnabled;
const selectOrientation = ({ ui }) => ui.orientation;
const selectIsWelcomeMessageSpoken = ({ ui }) => ui.isWelcomeMessageSpoken;
const selectIsFullScreenLoaderLoading = ({ ui }) => ui.isFullScreenLoaderLoading;
const selectProgressLoadingState = ({ ui }) => ui.progressLoadingState;
const selectProfileDetailModalState = ({ ui }) => ui.profileDetailModalState;
const selectLamdaUrl = ({ ui }) => ui.lamdaUrl;
const selectShowEmptyContentView = ({ ui }) => ui.showEmptyContentView;
const selectEmail = ({ ui }) => ui.email;
const selectIsTabLoading = ({ ui }) => ui.isTabLoading;
const selectShowAttendanceModal = ({ ui }) => ui.showAttendanceModal;

const uiSliceReducer = uiSlice.reducer;

export {
  uiSliceReducer,
  saveOnBoarding,
  selectOnBoarding,
  saveSearchText,
  selectSearchText,
  saveTraveller,
  selectTraveller,
  saveWallSearchText,
  selectWallSearchText,
  saveTermsConditions,
  selectTermsConditions,
  saveFingerPrintActive,
  saveBiometricPermissionAsked,
  selectFingerPrintActive,
  selectBiometricPermissionAsked,
  saveJwtToken,
  selectJwtToken,
  saveBaseUrl,
  selectBaseUrl,
  saveAppState,
  selectAppState,
  saveMicroUnivCookieStatus,
  selectMicroUnivCookieStatus,
  saveOrgDomain,
  selectOrgDomain,
  saveGenericToken,
  selectGenericToken,
  saveTempEmail,
  selectTempEmail,
  saveShowProfile,
  saveShowProfileDialog,
  selectShowProfile,
  selectShowProfileDialog,
  saveShowGotoHomeButton,
  selectShowGotoHomeButton,
  toggleIsMenuFavoritesEnabled,
  selectIsMenuFavoritesEnabled,
  saveOrientation,
  selectOrientation,
  saveIsWelcomeMessageSpoken,
  selectIsWelcomeMessageSpoken,
  saveProgressLoadingState,
  selectProgressLoadingState,
  saveLamdaUrl,
  selectLamdaUrl,
  saveProfileDetailModalState,
  selectProfileDetailModalState,
  saveShowEmptyContentView,
  selectShowEmptyContentView,
  saveIsFullScreenLoaderLoading,
  selectIsFullScreenLoaderLoading,
  saveEmail,
  selectEmail,
  saveIsTabLoading,
  selectIsTabLoading,
  resetLoaderState,
  selectShowAttendanceModal,
  setShowAttendanceModal,
};
