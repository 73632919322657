import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_TITLE,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../common/constants/AppConstants';
import {
  selectIjprFormattedSelectedFilterData,
  selectIjprSelectedFilterData,
} from '../../state/IjprData';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import { getFeatureComponentUrl } from '../../utils/accessibility';
import { showToast } from '../../utils/common';
import { GET_IJP_SEARCH_RESULT_LISTING } from '../../services/ApiUrls';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import Divider from '../../common/ui/divider/Divider';
import IjprFilter from './components/IjprFilter';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import BackButton from '../../common/ui/common_back_button/BackButton';
import debounce from 'lodash.debounce';
import ActivityLoader from '../../common/ui/activity_loader/ActivityLoader';

const IjprSearchResultScreen = (props) => {
  const { history, location } = props;
  const dispatch = useDispatch();
  const accessibilityData = useSelector(selectAccessibilityData);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const selectedFilterDataRedux = useSelector(selectIjprSelectedFilterData);
  const formattedSelectedFilterDataRedux = useSelector(selectIjprFormattedSelectedFilterData);
  const [ijprListingData, setIjprListingData] = useState([]);
  const [totalIjprListingDataCount, setTotalIjprListingDataCount] = useState(0);
  const [dataLength, setDataLength] = useState(0);
  const [searchText, setSearchText] = useState(
    location && location.state && location.state.searchQueryText
      ? location.state.searchQueryText
      : ''
  );
  const searchTextRef = useRef(
    location && location.state && location.state.searchQueryText
      ? location.state.searchQueryText
      : ''
  );
  const appliedFiltersRef = useRef({});
  const loadMoreFlagRef = useRef(true);
  const pageIdRef = useRef(1);
  const [quickSearchLoading, setQuickSearchLoading] = useState(false);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.IJPR_SEARCH_RESULT, NAVIGATION_ROUTES.IJPR_SEARCH_RESULT);
  }, []);

  const callIjprListingApi = useCallback(
    async (text = '', isQuickSearch = false) => {
      if (isQuickSearch) {
        setQuickSearchLoading(true);
      } else {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
          })
        );
      }
      let params = { page_id: pageIdRef.current };
      if (searchTextRef.current) {
        params = { ...params, ...{ query: text } };
      }
      if (Object.keys(appliedFiltersRef.current).length) {
        params = { ...params, ...appliedFiltersRef.current };
      }
      try {
        const apiResponse = await apiRequest(
          GET_IJP_SEARCH_RESULT_LISTING,
          REQUEST_TYPE.GET,
          params
        );
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        setQuickSearchLoading(false);
        if (Object.keys(apiResponse).length) {
          if (apiResponse.response.status) {
            const data = apiResponse.response.data.feeds;
            setTotalIjprListingDataCount(apiResponse.response.data.totalCount);
            setDataLength(data.length);
            if (pageIdRef.current === 1) {
              setIjprListingData(data);
            } else {
              setIjprListingData((oldArray) => [...oldArray, ...data]);
            }
          }
        }
      } catch (err) {
        handleError(
          err,
          params,
          GET_IJP_SEARCH_RESULT_LISTING,
          NAVIGATION_ROUTES.IJPR_SEARCH_RESULT
        );
        setQuickSearchLoading(false);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    },
    [dispatch]
  );

  const debouncedCallback = (event) => {
    searchTextRef.current = event.target.value;
    if (event && event.target.value.length > 2) {
      pageIdRef.current = 1;
      loadMoreFlagRef.current = true;
      callIjprListingApi(event.target.value, true);
    }
    setSearchText(event.target.value);
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );
  const onInputChange = (event) => {
    setSearchText(event.target.value);
    debounceInputHandler(event);
  };

  const renderScreen = useCallback(() => {
    callIjprListingApi(searchTextRef.current);
  }, [callIjprListingApi]);

  const filterActionHandler = useCallback(
    (selectedFilters) => {
      let tempFilterObject = {};
      Object.keys(selectedFilters).map((filterKey) => {
        if (selectedFilters[filterKey].length) {
          tempFilterObject[filterKey] = selectedFilters[filterKey].join();
        }
      });
      appliedFiltersRef.current = tempFilterObject;
      pageIdRef.current = 1;
      loadMoreFlagRef.current = true;
      setDataLength(0);
      renderScreen();
    },
    [renderScreen]
  );

  useEffect(() => {
    if (Object.keys(selectedFilterDataRedux) && Object.keys(selectedFilterDataRedux).length) {
      filterActionHandler(formattedSelectedFilterDataRedux);
    } else {
      renderScreen();
    }
  }, [
    filterActionHandler,
    formattedSelectedFilterDataRedux,
    renderScreen,
    selectedFilterDataRedux,
  ]);

  const onPressSearchHandler = (event) => {
    event.preventDefault();
    if (!searchText.trim().length) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      logEvent(
        ANALYTICS_EVENT_TYPES.IJP_SEARCH_QUERY,
        searchText.toString(),
        ANALYTICS_ITEM_NAMES.IJPR
      );
      pageIdRef.current = 1;
      searchTextRef.current = searchText;
      loadMoreFlagRef.current = true;
      setDataLength(0);
      callIjprListingApi(searchTextRef.current);
    }
  };

  const onPressIjprSeachResultListItem = (ijpId, jobCode) => {
    logEvent(
      ANALYTICS_EVENT_TYPES.IJP_SEARCH_RESULT_JOB_POST_CLICK,
      jobCode.toString(),
      ANALYTICS_ITEM_NAMES.IJPR
    );
    history.push(NAVIGATION_ROUTES.IJPR_DETAIL + '/' + ijpId);
  };

  const renderIjprListItem = (item, index) => {
    return (
      <div key={index} onClick={() => onPressIjprSeachResultListItem(item.ijp_id, item.job_code)}>
        {index === 0 && <Divider />}
        <div className="d-flex py-3 justify-content-between common-cursor-pointer">
          <div>
            <h5 className="ijp-search-item-title">{item.job_title}</h5>
            <p className="ijp-search-item-subtitle mb-0">
              {' '}
              {item.job_code} | {item.country_title} - {item.location_title} |{' '}
              {item.minimum_expirence} - {item.maximum_expirence} yrs
            </p>
          </div>
          <div className="d-flex align-items-center">
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.IJPR_RIGHT_ARROW,
                ACCESSIBILITY_IDENTIFIERS.IJPR
              )}
              className="ijp-search-item-right-icon"
            />
          </div>
        </div>
        <Divider />
      </div>
    );
  };

  useEffect(() => {
    if (pageIdRef.current !== 1) {
      loadMoreFlagRef.current = false;
    }
  }, [dataLength]);

  const loadMoreHandler = () => {
    if (loadMoreFlagRef.current) {
      pageIdRef.current = pageIdRef.current + 1;
      callIjprListingApi(searchTextRef.current);
    }
  };

  const showEmptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.SEARCH_NO_RESULTS} />;
  };

  return (
    <div className="container">
      <SectionHeader title={SCREEN_TITLE.IJP_APPLY} />
      <BackButton />
      <div className="py-3">
        <IjprFilter onClickApply={filterActionHandler} />
        <div>
          <form onSubmit={onPressSearchHandler}>
            <div className="ijp-tab-search-outer-container d-flex justify-content-center align-items-center mb-3">
              <img
                className="ijp-search-bar-left-icon ml-2"
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.IJPR_SEARCH,
                  ACCESSIBILITY_IDENTIFIERS.IJPR
                )}
              />
              <input
                value={searchText}
                autoFocus
                className="py-2 ijp-search-bar-input px-2"
                type="text"
                maxLength={100}
                placeholder="Search by keywords"
                onChange={onInputChange}
              />
              <CustomButton buttonStyle="py-2 px-3" onClick={onPressSearchHandler}>
                Search
              </CustomButton>
            </div>
          </form>
        </div>
        <div className="ijp-search-result-text mb-3">
          {totalIjprListingDataCount} {totalIjprListingDataCount === 1 ? 'Job' : 'Jobs'} found
        </div>
        {!ijprListingData.length && !progressLoadingState.isProgressLoading ? (
          showEmptyListView()
        ) : (
          <div>
            <ActivityLoader visible={quickSearchLoading} />
            {ijprListingData.map((ijprListItem, index) => renderIjprListItem(ijprListItem, index))}
            {loadMoreFlagRef.current && !!ijprListingData.length && (
              <LoadMoreBtn
                buttonStyle="my-3"
                onClick={loadMoreHandler}
                showLoader={progressLoadingState.isProgressLoading}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default IjprSearchResultScreen;
