import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
} from '../../../common/constants/AppConstants';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import PodcastListItem from '../components/PodcastListItem';

const PodcastChannelsTab = (props) => {
  const { data, getTabData, isLoading, tabKey, loadMoreFlag, paginationHandler } = props;
  const history = useHistory();

  useEffect(() => {
    getTabData(tabKey, 1);
  }, [tabKey, getTabData]);

  useEffect(() => {
    logEvent(ANALYTICS_EVENT_TYPES.PODCAST_CHANNELS_TAB_VISIT, '', ANALYTICS_ITEM_NAMES.PODCASTS);
  }, []);

  const showEmptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_PODCAST_YET} />;
  };

  const onPress = (item) => {
    history.push(`${NAVIGATION_ROUTES.PODCASTS_CONTENT}/${item.channel_id}`);
  };

  const renderItem = (item, index) => {
    return <PodcastListItem onPress={onPress} item={item} key={`${index}`} />;
  };

  return (
    <div className="main-content-container">
      <ActivityLoader visible={!!isLoading} />
      {props.data && (
        <div className="mb-3">
          {!data.length && !isLoading && showEmptyListView()}
          {!!data.length && (
            <div>
              <div className="row overflow-hidden">
                {data.map((item, index) => renderItem(item, index))}
              </div>
              {loadMoreFlag && (
                <LoadMoreBtn
                  buttonStyle="my-3"
                  onClick={() => paginationHandler(props.tabKey)}
                  isLoading={isLoading}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PodcastChannelsTab;
