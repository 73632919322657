import React, { useEffect, useState } from 'react';

const ImageChatView = ({ item, setShowImageDialog }) => {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (item && item.url) {
      setImageUrl(item.url);
    }
  }, [item]);

  return (
    <div onClick={() => setShowImageDialog(true)} className="chat-image-chat-view-container">
      <div className="common-cursor-pointer " key={item.message_id}>
        {imageUrl !== '' ? <img className="w-100" src={imageUrl} /> : <div />}
      </div>
    </div>
  );
};

export default ImageChatView;
