import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  FEATURE_TYPE,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../constants/AppConstants';
import {
  COMMENT_BY_ID,
  DELETE_COMMENT,
  EDIT_COMMENT,
  LIKE_UNLIKE_COMMENT,
  GET_WHO_LIKED_COMMENTS,
} from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { isEmptyText, showToast } from '../../../utils/common';
import { saveCommentCountData } from '../../../state/CommentData';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../state/UIState';
import BackButton from '../../ui/common_back_button/BackButton';
import CommentItem from './component/CommentItem';
import LoadMoreBtn from '../../ui/load_more_btn/LoadMoreBtn';
import DefaultContentView from '../../ui/default_content_view/DefaultContentView';
import { EditorState } from 'draft-js';
import {
  extractDataFromEditorState,
  getCommentMentionsState,
} from '../../components/hashtagsAndMentionsInput/hashtagUtils';
import CustomCommentBox from '../../ui/comment_box/CustomCommentBox';
import TheWallPostListItem from '../../../screen/wall/components/TheWallPostListItem';
import { logEvent } from 'firebase/analytics';

const CommentScreenWall = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = location.state;
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  const [loadMoreFlag, setLoadMoreFlag] = useState(true);
  const [pageId, setPageId] = useState(1);
  const [comments, setComments] = useState([]);
  const [showDeleteLoader, setShowDeleteLoader] = useState(false);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [image, setImage] = useState('');
  const [mentions, setMentions] = useState([]);
  const [showEmptyView, setShowEmptyView] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  const [item, setItemData] = useState(params.item);

  const callComments = useCallback(
    async (pageIdVar) => {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      setShowEmptyView(false);
      const get_params = {
        type: params.likeType,
        page_id: pageIdVar,
      };
      const commentApiResponse = await apiRequest(
        COMMENT_BY_ID + params.referId,
        REQUEST_TYPE.GET,
        get_params
      );
      if (Object.keys(commentApiResponse).length > 0) {
        if (commentApiResponse.response.status === true) {
          if (commentApiResponse.response.data.feeds) {
            const commentsData = commentApiResponse.response.data.feeds;
            if (commentsData?.length === 0) {
              setShowEmptyView(true);
            }
            if (commentsData.length > 0) {
              setComments(commentsData);
              setPageId((prev) => prev + 1);
              setLoadMoreFlag(true);
              setLoadMoreBtn(true);
            } else {
              setLoadMoreBtn(false);
              setLoadMoreFlag(false);
            }
          }
        }
      } else {
        setShowEmptyView(true);
      }
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    },
    [dispatch, params.likeType, params.referId]
  );

  const renderScreen = useCallback(async () => {
    callComments(1);
  }, [callComments]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const loadMore = () => {
    if (loadMoreFlag) {
      callComments(pageId);
    }
  };

  const sendCommentFunction = async () => {
    const { description, addedMentions } = extractDataFromEditorState(editorState);
    let tempDes = description;
    tempDes = tempDes.replace(/ {2,}(?![\n])/g, ' ');

    addedMentions.map((user) => {
      tempDes = tempDes.replace(user.name, `<rsu>{{${user.employee_id}}}</rsu> `);
    });

    mentions.map((user) => {
      tempDes = tempDes.replace(user.name, `<rsu>{{${user.employee_id}}}</rsu> `);
    });

    if (image != '') {
      tempDes = tempDes + ` <egami>${image}</egami>`;
    }

    if (!isEmptyText(tempDes)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      try {
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        const formData = new URLSearchParams();
        formData.append('type', params.likeType);
        formData.append('comment', tempDes);
        const apiResponse = await apiRequest(
          COMMENT_BY_ID + params.referId,
          REQUEST_TYPE.POST,
          formData
        );
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response) {
              setEditorState(EditorState.createEmpty());
              setImage('');
              dispatch(saveCommentCountData(apiResponse.response.data.comment_count));
              let element = item;
              element.comment_count = apiResponse.response.data.comment_count;
              setItemData(element);
              showToast(apiResponse.response.message);
              callComments(1);
            }
          }
        }
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      } catch (err) {
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    }
  };

  const getCommentText = (val) => {
    const { description, addedMentions } = extractDataFromEditorState(editorState);
    const editorState2 = getCommentMentionsState(description + val, [
      ...addedMentions,
      ...mentions,
    ]);
    setEditorState(editorState2);
  };

  const undoCommentFunction = (commentId) => {
    deleteCommentApi(commentId, 'undo');
  };

  const showToastWithMessage = (commentId, message) => {
    showToast(
      <div className="d-flex flex-row common-comment-toast-row">
        <div className="common-comment-toast-text">{message}</div>
        <div
          className="common-comment-toast-button"
          onClick={() => {
            undoCommentFunction(commentId);
          }}>
          UNDO
        </div>
      </div>
    );
  };

  const deleteCommentFunction = (commentId) => {
    deleteCommentApi(commentId, 'delete');
  };

  const deleteCommentApi = async (commentId, type) => {
    try {
      setShowDeleteLoader(true);
      const formData = new URLSearchParams();
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + commentId + '/' + DELETE_COMMENT,
        REQUEST_TYPE.POST,
        formData
      );
      setShowDeleteLoader(false);
      if (apiResponse.response.status) {
        setLoadMoreBtn(true);
        setLoadMoreFlag(true);
        setPageId(1);
        setComments([]);
        callComments(1);
        if (type === 'delete') {
          showToastWithMessage(commentId, apiResponse.response.message);
        }
      }
    } catch (err) {
      setShowDeleteLoader(false);
    }
  };

  const editCommentFunction = async (commentId, comment, mentions, image) => {
    if (!isEmptyText(comment)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
            progressMessage: LOADING_MESSAGES.DEFAULT,
          })
        );
        const formData = new URLSearchParams();
        formData.append('comment', comment);
        const apiResponse = await apiRequest(
          COMMENT_BY_ID + commentId + '/' + EDIT_COMMENT,
          REQUEST_TYPE.POST,
          formData
        );
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (apiResponse.response.status) {
          showToast(apiResponse.response.message);
          if (apiResponse.response.comments) {
            setLoadMoreBtn(true);
            setLoadMoreFlag(true);
            setPageId(1);
            setComments([]);
            callComments(1);
          }
        }
      } catch (err) {
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    }
  };

  const likeUnlikeCommentApi = async (commentId) => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
        })
      );
      const apiParams = new URLSearchParams();
      apiParams.append('component_refer_id', params.referId);
      apiParams.append('type', params.likeType);
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + commentId + '/' + LIKE_UNLIKE_COMMENT,
        REQUEST_TYPE.POST,
        apiParams
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          const message = apiResponse.response.message;
          if (message) {
            showToast(message);
            callComments(1);
          }
        }
      }
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  useEffect(() => {
    renderWallPost();
  }, [item]);

  const onPressWhoLikedHandler = (commentId) => {
    history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
      referId: params.referId,
      parentType: params.likeType,
      commentId: commentId,
    });
  };

  const onMention = (mention) => {
    setMentions((prev) => [...prev, mention]);
  };

  const callLikeApi = async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
      })
    );
    const param = new URLSearchParams();
    param.append('type', FEATURE_TYPE.WALL);
    try {
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + params.referId + '/like',
        REQUEST_TYPE.POST,
        param
      );
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data) {
            const data = apiResponse.response.data;
            const message = apiResponse.response.message;
            setLikeCount(data.like_count);
            if (message) {
              showToast(message);
              let element = item;
              element.liked = data.liked;
              element.like_count = data.like_count;
              setItemData(element);
            }
          }
        }
      }
    } catch (err) {
      handleError(err, {}, COMMENT_BY_ID + params.referId + '/like', NAVIGATION_ROUTES.THE_WALL);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  };

  const onPressLikeButton = (item) => {
    // logEvent(
    //   ANALYTICS_EVENT_TYPES.THE_WALL_LIKE,
    //   item.post_id.toString(),
    //   ANALYTICS_ITEM_NAMES.LIKE
    // );
    callLikeApi(params.item.post_id);
  };

  const renderWallPost = (item, index) => {
    return (
      <TheWallPostListItem
        // key={index.toString()}
        item={item || params.item}
        onPressLikeButton={onPressLikeButton}
        onPressCommentButton={() => {}}
        showLikeButton={true}
        showCommentButton={true}
      />
    );
  };

  const renderComments = () => {
    if (comments && comments.length > 0) {
      return comments.map((item) => {
        return (
          <CommentItem
            key={item.comment_id}
            commentId={item.comment_id}
            date={item.comment_on}
            employee={item.employee}
            commentReplies={item.child_comments}
            likeType={params.likeType}
            referId={params.referId}
            callCommentsApi={callComments}
            commentLikeCount={item.like_count}
            commentHasLiked={item.liked}
            onPressWhoLikedHandler={onPressWhoLikedHandler}
            likeUnlikeCommentApi={likeUnlikeCommentApi}
            message={item.comment}
            showDeleteLoader={showDeleteLoader}
            editComment={editCommentFunction}
            deleteComment={(commentId) => {
              deleteCommentFunction(commentId);
            }}
            mentions={item.taggged_employees}
            commentText={item.comment_text}
          />
        );
      });
    } else {
      return (
        showEmptyView &&
        comments?.length === 0 && (
          <DefaultContentView message={ERROR_MESSAGES.NO_COMMENTS_TO_SHOW} />
        )
      );
    }
  };

  return (
    <div className="container">
      <BackButton />
      <div className="py-3">{renderWallPost()}</div>
      <div>{renderComments()}</div>
      {loadMoreBtn && (
        <div className="mb-4 mt-4">
          <LoadMoreBtn onClick={loadMore} isLoading={progressLoadingState.isProgressLoading} />
        </div>
      )}
      <CustomCommentBox
        getCommentText={sendCommentFunction}
        onChangehandler={getCommentText}
        showLoader={progressLoadingState.isProgressLoading}
        setEditorState={setEditorState}
        editorState={editorState}
        enableUserMentions={true}
        enableHashtags={false}
        enableImageUpload={true}
        image={image}
        setImage={setImage}
        onMention={onMention}
      />
    </div>
  );
};

export default CommentScreenWall;
